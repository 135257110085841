<script>
import { Bar } from 'vue-chartjs';

import { diagramPattern } from '@/core/helpers';

export default {
  extends: Bar,
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      chartdata: {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Maj',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: this.data.capacity.label,
            lineTension: 0.5,
            data: this.data.capacity.values,
            borderColor: 'rgba(251, 235, 221, 0.9)',
            borderWidth: 1,
            backgroundColor: 'rgba(251, 235, 221, 0.7)',
            tooltips: this.data.capacity.values,
            type: 'line',
            pointRadius: 0,
            fill: 'origin',
            stack: 'stack 4',
            order: 5,
            yAxisID: 'y-bars',
          },
          {
            label: this.data.currentYear.label,
            backgroundColor: '#27645C',
            borderColor: '#27645C',
            borderWidth: 1,
            barPercentage: 1,
            data: this.data.currentYear.values,
            tooltips: this.data.currentYear.values,
            categoryPercentage: 0.8,
            order: 3,
            stack: 'stack1',
            yAxisID: 'y-bars',
          },
        ],
      },
      options: {
        legendCallback: function (chart) {
          return chart;
        },
        legend: {
          display: true,
          position: 'top',
          align: 'center',
          labels: {
            usePointStyle: false,
            boxWidth: 10,
            fontSize: 11,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              id: 'y-bars',
              stacked: false,
              gridLines: {
                display: true,
              },
              ticks: {
                callback: function (value, index, values) {
                  return value.toLocaleString('sv-SE', {
                    maximumFractionDigits: 0,
                  });
                },
                min: 0,
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: '#fff',
          titleFontColor: '#000',
          bodyFontColor: '#000',
          bodySpacing: 6,
          borderColor: '#000',
          borderWidth: 1,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label +=
                data.datasets[tooltipItem.datasetIndex].tooltips[
                  tooltipItem.index
                ];
              return label;
            },
          },
        },
        maintainAspectRatio: false,
      },
    };
  },
  computed: {},
  watch: {
    fullscreen: function (newValue) {
      this.$nextTick(() => {
        this.options.legend.labels.boxWidth = newValue ? 20 : 10;
        this.options.legend.labels.fontSize = newValue ? 12 : 8;
        this.options.scales.yAxes[0].display = newValue;
        this.renderChart(this.chartdata, this.options);
      });
    },
  },
  mounted() {
    if (this.data.normalYear) {
      this.chartdata.datasets.push({
        label: this.data.normalYear.label,
        backgroundColor: diagramPattern(),
        borderColor: '#27645C',
        barPercentage: 1,
        borderWidth: 1,
        data: this.data.normalYear.values,
        tooltips: this.data.normalYear.values,
        categoryPercentage: 0.8,
        order: 4,
        stack: 'stack2',
        yAxisID: 'y-bars',
      });
    }
    for (let i = 0; i < this.options.scales.yAxes.length; i++) {
      this.options.scales.yAxes[i].ticks.min = 0;
    }
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
