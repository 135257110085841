<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <h1>{{ $t('Businessboard.addCourse') }}</h1>

      <form
        noValidate
        @submit.prevent="createCourse">
        <div class="content__input-group">
          <div class="input-group">
            <div class="input">
              <label
                for="courseName"
                class="input__label">{{ $t('Businessboard.courseName') }}*</label>
              <input
                id="courseName"
                class="input__input"
                v-model="courseName"
                type="text"
                required="true"
                name="courseName" />
            </div>
            <div class="input">
              <label
                for="gitName"
                class="input__label">{{ $t('Businessboard.gitName') }}*</label>
              <input
                id="gitName"
                class="input__input"
                v-model="gitName"
                type="text"
                required="true"
                name="gitName" />
            </div>
          </div>
          <div class="input-group--inline user__actions">
            <router-link to="/businessboard/data">
              <base-button :text="$t('cancel')" />
            </router-link>
            <base-button
              :text="$t('Businessboard.addCourse')"
              type="submit"
              v-element-loader="{ loaderKey: 'create-course' }"
              :disabled="!canCreate" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'CreateCourse',
  components: {
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  props: {},
  data() {
    return {
      courseName: '',
      gitName: '',
    };
  },
  methods: {
    createCourse() {
      this.$store
        .dispatch('businessboard/createCourse', {
          name: this.courseName,
          gitName: this.gitName,
        })
        .then(() => {
          this.$router.push({ path: '/businessboard/data' });
          this.$toasted.success(this.$t('Businessboard.courseCreated'));
        });
    },
  },
  computed: {
    canCreate() {
      return this.courseName && this.gitName;
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.golfoffice {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.golfoffice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  .content__input-group {
    width: 100%;
    @include media('medium') {
      width: calc((100% / 1.2) - (1rem * 2));
    }
    .input__label {
      width: 100%;
    }
    .user__actions {
      justify-content: flex-start;
      @include media('small') {
        justify-content: flex-end;
        // width: 70%;
      }

      a button {
        margin-left: 0;
      }

      button {
        margin-left: 1.25rem;
      }
    }
  }
}
</style>
