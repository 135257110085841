<template>
  <div>
    <div class="selectData--div">
      <div>
        <div class="section">
          <div class="display--flex">
            <div
              class="radio"
              @click="e => copyMemberQuantityClicked('quantityBudgeted')">
              <use-svg
                v-if="displayBudgetOrActual === true"
                class="selected--icon"
                svg="bb-selected-icon" />
            </div>
            <label> Budgeterat </label>
          </div>
          <div
            :class="[
              'amount--div',
              { 'amount--div--hidden': !displayBudgetOrActual },
            ]">
            <div>
              <input
                type="text"
                inputmode="numeric"
                :value="0"
                @blur="
                  e => changeMemberValues(e.target.value, 'quantityBudgeted')
                " />
              %
            </div>
          </div>
        </div>
        <div class="section">
          <div class="display--flex">
            <div
              class="radio"
              @click="e => copyMemberQuantityClicked('quantityActual')">
              <use-svg
                v-if="displayBudgetOrActual === false"
                class="selected--icon"
                svg="bb-selected-icon" />
            </div>
            <label>Utfall</label>
          </div>
          <div
            :class="[
              'amount--div',
              { 'amount--div--hidden': displayBudgetOrActual },
            ]">
            <div>
              <input
                type="text"
                inputmode="numeric"
                :value="0"
                @blur="
                  e => changeMemberValues(e.target.value, 'quantityActual')
                " />
              %
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UseSvg from '@/components/use-svg';

export default {
  components: { UseSvg },

  props: {
    fee: {
      type: Array || Object,
      required: false,
      default: () => [],
    },
    copyOfPreviousYear: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      displayBudgetOrActual: true,
    };
  },
  mounted() {},
  methods: {
    changeMemberValues(percentage, quantityType) {
      let year = this.$store.state.businessboard.membershipTypeYears.years.find(
        y =>
          y.year === this.$store.state.businessboard.membershipTypeYearCopy.year
      );
      this.$store.state.businessboard.membershipTypeYearCopy = {
        ...year,
        types: year.types.map(type => ({
          ...type,
          quantity: (type[quantityType] * (1 + percentage / 100) || 0).toFixed(
            0
          ),
          ageGroups: type.ageGroups.map(ageGrp => ({
            ...ageGrp,
            quantity: (
              ageGrp[quantityType] * (1 + percentage / 100) || 0
            ).toFixed(0),
          })),
        })),
      };
    },

    copyMemberQuantityClicked(quantityType) {
      this.displayBudgetOrActual = !this.displayBudgetOrActual;
      this.changeMemberValues(0, quantityType);
    },
  },
};
</script>

<style lang="scss" scoped>
.selectData--div {
  display: flex;

  > div:first-child {
    width: 100%;
  }
}

.section {
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 0.3rem;

  .radio {
    margin-left: 1.5rem;
  }
}
.amount--div {
  width: 100%;
  margin-top: -0.3rem;
  padding: 0.3rem;
  visibility: visible;
  div {
    float: right;
    input {
      width: 3rem;
    }
  }
}
.amount--div--hidden {
  visibility: hidden;
}
.radio {
  width: 21px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #000;
  cursor: pointer;
  margin: 2px 0;
  align-items: center;
  justify-content: center;
  display: flex;
}
.selected--icon {
  width: 15px;
  height: 16px;
}

.display--flex {
  display: inline-flex;
  gap: 1rem;
  align-items: center;
}
</style>
