<template>
  <modal-window
    :show="showModal"
    :title="$t('missingLicenseTitle')">
    <div class="info">
      <h2>{{ $t('missingLicenseTitle') }}</h2>
      <span class="description">{{
        $t('missingLicense', { licenseType })
      }}</span>
      <p
        v-if="previewDescription"
        class="description">
        {{ previewDescription }}
      </p>
      <span>
        {{ $t('contactToUpgrade') }}
      </span>
      <div>
        <img
          class="image"
          v-if="imageUrl"
          :src="imageUrl"
          alt="missing license" />
      </div>
      <base-button
        @click="toggleModal"
        background-color="bb-primary"
        :text="$t('close')">
      </base-button>
    </div>
  </modal-window>
</template>

<script>
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import ModalWindow from '@/components/modal/modal.vue';
import BaseButton from '@/components/form-elements/base-button.vue';

export default {
  name: 'MissingLicense',
  props: {
    licenseType: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: false,
    },
    previewDescription: {
      type: String,
      required: false,
    },
  },
  mixins: [LocalizationMixin],
  components: {
    ModalWindow,
    BaseButton,
  },
  data: function () {
    return {
      showModal: true,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style scoped>
.missing-license {
  display: flex;
  align-self: center;
}

.info {
  padding: 20px;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.description {
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  color: #27645c;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  color: #333;
  margin-bottom: 0;
}

.image {
  margin-top: 20px;
  max-height: 350px;
}
</style>
