<template>
  <div
    class="drill-down-row"
    @click.stop="toggleChildren"
    :key="row.values.join('_')"
    :style="{
      backgroundColor: getBackgroundColor(indentLevel),
      color: getTextColor(),
      gridTemplateColumns: `370px repeat(${row.values.length}, ${
        200 - indentLevel
      }px)`,
      border: `2px solid ${getBackgroundColor(indentLevel)}`,
    }">
    <div class="drill-down-label">
      <div :style="{ marginLeft: `${indentLevel * 10}px` }">
        <span
          v-if="row.children && row.children.length"
          class="arrow"
          :class="{
            'arrow-down': showChildren,
            'arrow-white': row.drillDownType === 1,
          }"></span>
        <span
          v-else
          class="arrow-placeholder"></span>
        {{ row.label }}
      </div>
    </div>
    <div
      v-for="(value, index) in row.values"
      :key="index"
      class="drill-down-value"
      :style="{ gridColumnStart: 2 + index, gridColumnEnd: 3 + index }">
      {{ formatNumber(value) }}
    </div>
    <div
      class="children"
      v-if="showChildren"
      style="grid-column: 1 / -1">
      <drill-down-row
        v-for="(childRow, childIndex) in row.children"
        :key="`child_${childIndex}`"
        :row="childRow"
        :indentLevel="indentLevel + 1"></drill-down-row>
    </div>
  </div>
</template>
<script>
import DrillDownRow from './drilldown-row.vue';
export default {
  name: 'DrillDownRow',
  props: {
    row: {
      type: Object,
      required: true,
    },
    indentLevel: {
      type: Number,
      default: 0,
    },
  },
  components: {
    DrillDownRow,
  },
  data() {
    return {
      showChildren: false,
    };
  },
  methods: {
    getBackgroundColor(indentLevel) {
      if (this.row.drillDownType === 1) {
        return '#27645C';
      }
      if (this.row.drillDownType === 2) {
        return '#DEE8F7';
      }
      const colors = ['#F0F5F5', '#E1ECEC', '#EBF1F1', '#F5F7F7', '#FFFFFF'];
      return colors[indentLevel % colors.length];
    },
    toggleChildren() {
      if (this.row.children.length > 0) this.showChildren = !this.showChildren;
    },
    getTextColor() {
      if (this.row.drillDownType === 1) {
        return '#FFFFFF';
      }
      if (this.row.drillDownType === 2) {
        return '#4B2D88';
      } else {
        return '#000000';
      }
    },
    formatNumber(value) {
      return value.toLocaleString('fr-FR').replace(/,/g, ' ');
    },
  },
};
</script>
<style scoped>
.drill-down-row {
  cursor: pointer;
  display: grid;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  transition: transform 0.2s ease;
  font-family: 'Hind Siliguri', sans-serif;
  padding: 10px;
  margin-left: -10px;
  margin-right: 10px;
  margin-top: 3px;
  /* note: keep the padding and margin-left/right to the same value to keep them aligned */
}

.drill-down-row:hover {
  font-weight: 600;
  transform: translate3d(0, -2px, 0);
}

.drill-down-label {
  display: flex;
  align-items: center;
  width: 350px; /* if you increase offset in the drilldown-list, add same offset here */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: start;
  white-space: nowrap;
}

.drill-down-label:hover {
  overflow: visible;
  z-index: 1;
  white-space: wrap;
}

.arrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid black;
  margin-right: 10px;
  margin-left: 10px;
}

.arrow-down {
  border-top: none;
  border-bottom: 7px solid black;
}

.arrow-placeholder {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.children {
  margin-top: 10px;
}

.arrow-white {
  border-top-color: white;
}

.arrow-down.arrow-white {
  border-bottom-color: white;
}
</style>
