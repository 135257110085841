import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  statistics: {},
};

const mutations = {
  statistics (state, payload) {
    state.statistics = payload;
  },
};

const getters = {
  statistics: state => {
    return state.statistics;
  },
};

const actions = {
  fetchStatistics: async(context, params) => {
    let path = 'ontagstatistics';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('statistics', data.result);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
