/* Store module to handle Clubs */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';
import { pad2 } from '@/core/helpers.js';

const state = {
  transactions: {
    rows: [],
    columns: [],
  },
  transaction: {},
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
};

const mutations = {
  transactions (state, payload) {
    state.transactions.rows = payload.rows;
    state.transactions.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
  transaction (state, payload) {
    state.transaction = payload;
  },
  clearTransaction (state, payload) {
    state.transaction = {};
  },
};

const getters = {
  transactions: state => {
    return state.transactions;
  },
  transaction: state => {
    return state.transaction;
  },
  pagination: (state) => {
    return state.pagination;
  },
  currentPage: (state) => {
    return state.pagination.currentPage;
  },
  nextPage: (state) => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: (state) => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
};

const actions = {
  fetchTransactions: async (context, params) => {
    let path = 'transactions';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params).map(k => {
        if(params[k] !== undefined) {
          return esc(k) + '=' + esc(params[k]);
        }
      }).filter(q => q !== undefined).join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('transactions', data.result);
    }
  },
  fetchTransaction: async (context, params) => {
    let path = `transactions/${params.id}`;

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('transaction', data.result);
    }
  },
  exportTransactions: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'transactions-export-transactions', { root: true });
    let path = 'transactions/export';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params).map(k => {
        if(params[k] !== undefined) {
          return esc(k) + '=' + esc(params[k]);
        }
      }).filter(q => q !== undefined).join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'transactions-export-transactions', { root: true });

    let date = new Date();
    const filename = 'transactions-' + date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2(date.getDate()) + pad2(date.getHours()) + pad2(date.getMinutes()) + pad2(date.getSeconds());

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
