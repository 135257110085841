import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueI18n from 'vue-i18n';
import router from './router';
import store from './store';
import VueCookies from 'vue-cookies-reactive';
import interceptors from '@/core/interceptors';
import '@/core/startup/global-registrations';
import { languages, defaultLocale } from '@/core/i18n/index';
import * as VueGoogleMaps from 'vue2-google-maps';

import '@/assets/styles/main.scss';

Vue.config.productionTip = false;

// if (process.env.NODE_ENV === 'development') {
//   const VueAxe = require('vue-axe').default;
//   Vue.use(VueAxe, { allowConsoleClears: false });
// }

Vue.use(VueAxios, axios);

Vue.use(VueCookies);
Vue.$cookies.config(
  process.env.VUE_APP_COOKIE_EXPIRES,
  process.env.VUE_APP_COOKIE_PATH,
  process.env.VUE_APP_COOKIE_DOMAIN,
  process.env.VUE_APP_COOKIE_SECURE,
  process.env.VUE_APP_COOKIE_SAMESITE
);

Vue.use(VueI18n);
const i18n = new VueI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'sv',
  messages: Object.assign(languages),
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAPS_SECRET,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    /// / If you want to set the version, you can do so:
    // v: '3.26',
  },

  /// / If you intend to programmatically custom event listener code
  /// / (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  /// / instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  /// / you might need to turn this on.
  // autobindAllEvents: false,

  /// / If you want to manually install components, e.g.
  /// / import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  /// / Vue.component('GmapMarker', GmapMarker)
  /// / then disable the following:
  // installComponents: true,
});

interceptors();
store.dispatch('subscribeToResponsiveEvents');

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
