<template>
  <div class="ontag">
    <header>
      <div class="header__left-section">
        <router-link :to="{ path: '/ontag/orderscorecard' }">
          <button>
            <use-svg
              svg="arrow-right"
              :class="'svg--90'"
              class="qa-svg" />
          </button>
        </router-link>
        <p v-if="this.scorecardType == 1">Beställning av scorekort - 3 delat</p>
        <p v-if="this.scorecardType == 2">Beställning av scorekort - 4 delat</p>
        <p v-if="this.scorecardType == 3">
          Beställning av scorekort - Klubbspecifika, 3 delat
        </p>
        <p v-if="this.scorecardType == 4">
          Beställning av scorekort - Klubbspecifika, 4 delat
        </p>
      </div>
      <div class="header__right-section">
        <p>
          <span>Pris: {{ calculatePrice() }} kr</span><br />
          <span>Exklusive moms och frakt</span>
          <!-- <span>Se hur vi har räknat</span> -->
        </p>
      </div>
    </header>
    <div class="three-step-header">
      <div class="three-step-header__step">
        <div
          class="three-step-header__number"
          :class="{
            'three-step-header__number--inprogress': stepOneInProgress,
            'three-step-header__number--done': stepOneDone,
          }">
          1
        </div>
        Antal och Leveransdatum
      </div>
      <div class="three-step-header__step">
        <div
          class="three-step-header__number"
          :class="{
            'three-step-header__number--inprogress': stepTwoInProgress,
            'three-step-header__number--done': stepTwoDone,
          }">
          2
        </div>
        Leveransuppgifter
      </div>
      <div class="three-step-header__step">
        <div
          class="three-step-header__number"
          :class="{
            'three-step-header__number--inprogress': stepThreeInProgress,
          }">
          3
        </div>
        Bekräftelse
      </div>
    </div>
    <form @submit.prevent="saveOrder">
      <div
        class="makeorder_step1"
        :class="{ 'makeorder--hidden': step1hidden }">
        <div class="ontag__content">
          <h2 v-if="this.scorecardType == 1">Antal 3 delade standardkort</h2>
          <h2 v-else-if="this.scorecardType == 2">
            Antal 4 delade standardkort
          </h2>
          <h2 v-else-if="this.scorecardType == 3">
            Antal klubbspecifika scorekort, 3 delade
          </h2>
          <h2 v-else-if="this.scorecardType == 4">
            Antal klubbspecifika scorekort, 4 delade
          </h2>
          <p v-if="this.scorecardType == 1 || this.scorecardType == 2">
            Ange tusental. 1000 st scorekort/låda
          </p>
          <p v-else-if="this.scorecardType == 3 || this.scorecardType == 4">
            Ange tusental. 2000 st scorekort/låda
          </p>
          <div class="value-container">
            <div
              v-if="scorecardType == 1 || scorecardType == 2"
              class="value-button"
              :class="{ 'value-button--disabled': quantity == 1000 }"
              id="decrease"
              @click="decreaseValue"
              value="Decrease Value">
              -
            </div>
            <div
              v-else
              class="value-button"
              :class="{ 'value-button--disabled': quantity == 2000 }"
              id="decrease"
              @click="decreaseValue"
              value="Decrease Value">
              -
            </div>
            <input
              disabled
              type="number"
              id="number"
              v-model="quantity" />
            <div
              class="value-button"
              id="increase"
              @click="increaseValue"
              value="Increase Value">
              +
            </div>
          </div>
        </div>
        <div
          v-if="scorecardType == 1 || scorecardType == 2"
          class="ontag__content">
          <h2>Leveransdatum</h2>
          <p>Normal leveranstid 1-3 dagar vid beställning före kl. 11.00.</p>
          <p>
            <input
              :checked="true"
              type="radio"
              name="deliveryweek-option"
              :value="0"
              v-model="deliveryWeek" />
            Så snabbt som möjligt (2-5 dagars leveranstid)
          </p>
          <p>
            <input
              type="radio"
              name="deliveryweek-option"
              :value="100"
              v-model="deliveryWeek"
              @click="getCurrentWeek" />
            Specifik vecka
          </p>
          <div
            v-if="deliveryWeek != 0 && deliveryWeek != undefined"
            class="select-week-container">
            <div class="select-week">
              <label for="year">År</label><br />
              <select
                id="year"
                v-model="selectedYear">
                <option :value="currentYear">{{ currentYear }}</option>
                <option :value="nextYear">{{ nextYear }}</option>
              </select>
            </div>
            <div class="select-week">
              <label for="week">Vecka</label><br />
              <select
                v-model="deliveryWeekSpecific"
                id="week"
                v-if="selectedYear === currentYear">
                <option
                  v-for="(week, key) in weeksCurrentYear"
                  :value="week"
                  :key="key">
                  {{ week }}
                </option>
              </select>
              <select
                id="week"
                v-else
                v-model="deliveryWeek">
                <option
                  v-for="(week, key) in weeksNextYear"
                  :value="week"
                  :key="key">
                  {{ week }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-else-if="scorecardType == 3 || scorecardType == 4">
          <div class="ontag__content">
            <h2>Lack på scorekort</h2>
            <p>Ange vilken ytbeläggning som ska vara på scorekorten</p>
            <div class="deliveryweek-optioncontainer">
              <input
                class="deliveryweek-option"
                type="radio"
                name="surface-option"
                :value="1"
                v-model="surface" />
              Matt lack
            </div>
            <div class="deliveryweek-optioncontainer">
              <input
                class="deliveryweek-option"
                type="radio"
                name="surface-option"
                :value="2"
                v-model="surface" />
              Blank lack
            </div>
          </div>
          <div class="ontag__content">
            <h2>Leveransvecka</h2>
            <div
              v-for="week in deliveryweeks"
              :key="week.id"
              class="deliveryweek-optioncontainer">
              <label
                v-if="week.deadline >= today"
                class="deliveryweek-optionlabel">
                <input
                  class="deliveryweek-option"
                  type="radio"
                  name="deliveryweek-option"
                  :value="week.id"
                  v-model="deliveryWeekSpecificId"
                  @click="getCurrentWeek" />
                Vecka {{ week.week }} - {{ week.year }}
              </label>
            </div>
            <div v-if="user.superUser">
              <div
                v-for="week in deliveryweeks"
                :key="week.id"
                class="deliveryweek-optioncontainer">
                <label
                  v-if="week.deadline < today"
                  class="deliveryweek-optionlabel">
                  <input
                    class="deliveryweek-option"
                    type="radio"
                    name="deliveryweek-option"
                    :value="week.id"
                    v-model="deliveryWeekSpecificId"
                    @click="getCurrentWeek" />
                  Vecka {{ week.week }} - {{ week.year }} (deadline passerad)
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="button_container">
          <base-button
            type="button"
            @click="toggleHideStep2"
            :disabled="
              (deliveryWeek == undefined &&
                deliveryWeekSpecific == 0 &&
                deliveryWeekSpecificId == '') ||
              ((surface == 0 || deliveryWeekSpecificId == '') &&
                (scorecardType == 3 || scorecardType == 4)) ||
              (deliveryWeek == 100 && deliveryWeekSpecific == 0)
            "
            :text="'Nästa'"
            class="button--right" />
        </div>
      </div>
      <div
        class="makeorder_step2"
        :class="{ 'makeorder--hidden': step2hidden }">
        <div class="ontag__content">
          <h2>Kontaktuppgifter</h2>
          <label for="name">Kontaktperson</label>
          <input
            pattern="^[^-\s][a-zåäöA-ZÅÄÖ\s]+"
            title="Kan endast innehålla bokstäver"
            type="text"
            id="name"
            v-model="contactPerson"
            :class="{
              input__invalid: !/^[^-\s][a-zåäöA-ZÅÄÖ\s]+$/.test(
                this.contactPerson
              ),
            }" />
          <div
            v-if="!/^$|^[a-zåäöA-ZÅÄÖ\s]+$/.test(this.contactPerson)"
            class="input__error--message">
            Namnet kan endast innehålla bokstäver
          </div>
          <label for="name">Telefonnummer</label>
          <input
            pattern="[0-9]+"
            title="Använd endast siffror (inga specialtecken eller mellanrum)"
            type="text"
            id="name"
            :class="{ input__invalid: !/[0-9]/.test(this.phoneNumber) }"
            v-model="phoneNumber" />
          <div
            v-if="!/^(\s*|\d+)$/.test(this.phoneNumber)"
            class="input__error--message">
            Endast siffror (inga specialtecken eller mellanrum)
          </div>
          <label for="name">E-post</label>
          <input
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            title="Epostens format är felaktigt"
            :class="{ input__invalid: /^$|\s+/.test(this.email) }"
            type="email"
            id="name"
            v-model="email"
            required="true"
            @input="checkEmailValidity" />
          <div
            v-if="failedEmailValidiation && this.email"
            class="input__error--message">
            {{ $t('GolfOffice.emailMustInclude') }}
          </div>
        </div>
        <div class="ontag__content">
          <h2>Leveransadress</h2>
          <label for="company_name">Företagsnamn</label>
          <input
            :class="{
              input__invalid: !/[0-9a-zåäöA-ZÅÄÖ\S]+$/.test(
                this.deliveryAddress.companyName
              ),
            }"
            type="text"
            id="company_name"
            v-model="deliveryAddress.companyName" />
          <label for="address">Adress</label>
          <input
            :class="{
              input__invalid: !/[0-9a-zåäöA-ZÅÄÖ\S]+$/.test(
                this.deliveryAddress.postalAddress
              ),
            }"
            type="text"
            id="address"
            v-model="deliveryAddress.postalAddress" />
          <label for="zip_code">Postnummer</label>
          <input
            :class="{
              input__invalid: !/[0-9a-zåäöA-ZÅÄÖ\S]+$/.test(
                this.deliveryAddress.postalCode
              ),
            }"
            type="text"
            id="zip_code"
            v-model="deliveryAddress.postalCode" />
          <label for="city">Postort</label>
          <input
            :class="{
              input__invalid: !/[0-9a-zåäöA-ZÅÄÖ\S]+$/.test(
                this.deliveryAddress.city
              ),
            }"
            type="text"
            id="city"
            v-model="deliveryAddress.city" />
        </div>
        <div class="ontag__content">
          <h2>Fakturaadress</h2>
          <label for="company_name_invoice">Företagsnamn</label>
          <input
            :class="{
              input__invalid: !/[0-9a-zåäöA-ZÅÄÖ\S]+$/.test(
                this.invoiceAddress.companyName
              ),
            }"
            type="text"
            id="company_name_invoice"
            v-model="invoiceAddress.companyName" />
          <label for="organisation_nr">Org. Nummer</label>
          <input
            :class="{
              input__invalid: !/[0-9a-zåäöA-ZÅÄÖ\S]+$/.test(
                this.invoiceAddress.orgNumber
              ),
            }"
            type="text"
            id="organisation_nr"
            v-model="invoiceAddress.orgNumber" />
          <label for="address_invoice">Adress</label>
          <input
            :class="{
              input__invalid: !/[0-9a-zåäöA-ZÅÄÖ\S]+$/.test(
                this.invoiceAddress.postalAddress
              ),
            }"
            type="text"
            id="address_invoice"
            v-model="invoiceAddress.postalAddress" />
          <label for="zip_code_invoice">Postnummer</label>
          <input
            :class="{
              input__invalid: !/[0-9a-zåäöA-ZÅÄÖ\S]+$/.test(
                this.invoiceAddress.postalCode
              ),
            }"
            type="text"
            id="zip_code_invoice"
            v-model="invoiceAddress.postalCode" />
          <label for="city_invoice">Postort</label>
          <input
            :class="{
              input__invalid: !/[0-9a-zåäöA-ZÅÄÖ\S]+$/.test(
                this.invoiceAddress.city
              ),
            }"
            type="text"
            id="city_invoice"
            v-model="invoiceAddress.city" />
          <label for="email_invoice">E-post för faktura</label>
          <input
            type="email"
            id="email_invoice"
            v-model="invoiceAddress.email" />
        </div>
        <div class="ontag__content">
          <h2>Kommentar</h2>
          <textarea
            name=""
            id=""
            v-model="comment"></textarea>
        </div>
        <div
          v-if="user.superUser"
          class="ontag__content">
          <h2>Kommentar från tietoevry</h2>
          <textarea
            name=""
            id=""
            v-model="adminComment"></textarea>
          <!-- TODO - connect this to endpoint -->
          <label
            v-if="scorecardType == 3 || scorecardType == 4"
            for="haveChange">Innehåller ändring
            <input
              v-model="hasChanges"
              id="haveChange"
              type="checkbox" />
          </label>
        </div>
        <div class="button_container">
          <label
for="termsConsent">Jag godkänner
            <a
              :href="terms.termsUrl"
              target="_blank">villkoren</a>
            <input
              id="termsConsent"
              type="checkbox"
              v-model="termsConsent" />
          </label>
          <base-button
            type="button"
            @click="toggleHideStep2"
            :disabled="quantity <= 0"
            :text="'Tillbaka'"
            class="button--right" />
          <base-button
            type="submit"
            :disabled="!canSaveOrder"
            :text="'Slutför beställning'"
            class="button--right" />
        </div>
      </div>
      <div
        class="makeorder_step3"
        :class="{ 'makeorder--hidden': step3hidden }">
        <div class="ontag__content">
          <div class="confirmation">
            <h1>Tack för din beställning!</h1>
            <h2>Ditt ordernummer är {{ orderID }}</h2>
            <p>Ett mail har skickats till din angivna mail</p>
          </div>
        </div>
        <router-link :to="{ path: '/ontag/orderscorecard' }">
          <base-button
            type="button"
            :text="'Tillbaka till GolfOffice'" />
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';

export default {
  name: 'ScorecardMakeOrder',
  components: {
    UseSvg,
    BaseButton,
  },

  data() {
    return {
      // scorecardType: 0,
      stepOneInProgress: true,
      stepOneDone: false,
      stepTwoInProgress: false,
      stepTwoDone: false,
      stepThreeInProgress: false,
      step1hidden: false,
      step2hidden: true,
      step3hidden: true,
      showWeekOption: false,
      failedEmailValidiation: false,
      quantity:
        this.scorecardType === 1 || this.scorecardType === 2 ? 1000 : 2000,
      surface: 0,
      deliveryWeek: undefined,
      deliveryWeekSpecific: 0,
      deliveryWeekSpecificId: '',
      contactPerson: '',
      phoneNumber: '',
      email: '',
      deliveryAddress: {
        companyName: '',
        postalAddress: '',
        postalCode: '',
        city: '',
      },
      invoiceAddress: {
        companyName: '',
        orgNumber: '',
        postalAddress: '',
        postalCode: '',
        city: '',
        email: '',
        terpId: '',
      },
      comment: '',
      adminComment: '',
      currentWeek: 0,
      currentYear: new Date().getFullYear(),
      nextYear: new Date().getFullYear() + 1,
      weeksCurrentYear: [],
      weeksNextYear: Array.from({ length: 53 - 1 }, (_, i) => 0 + 1 + i),
      selectedYear: new Date().getFullYear(),
      selectedWeek: this.currentWeek,
      termsConsent: false,
      hasChanges: false,
      orderID: 0,
    };
  },
  props: {
    scorecardType: Number,
  },
  methods: {
    increaseValue() {
      let value = parseInt(document.getElementById('number').value, 10);
      value = isNaN(value) ? 0 : value;
      if (this.scorecardType === 1 || this.scorecardType === 2) {
        value = value + 1000;
      } else {
        value = value + 2000;
      }
      document.getElementById('number').value = value;
      this.quantity = value;
    },
    decreaseValue() {
      let value = parseInt(document.getElementById('number').value, 10);
      value = isNaN(value) ? 0 : value;
      if (
        value !== 1000 &&
        (this.scorecardType === 1 || this.scorecardType === 2)
      ) {
        value = value - 1000;
      } else if (value !== 2000) {
        value = value - 2000;
      }
      document.getElementById('number').value = value;
      this.quantity = value;
    },
    calculatePrice() {
      let price;
      if (this.scorecardType === 1) {
        if (this.quantity <= 19000) {
          price =
            this.quantity * this.scorecardorder.priceSettings.threePartPriceOne;
        } else if (this.quantity > 19000 && this.quantity <= 29000) {
          price =
            this.quantity * this.scorecardorder.priceSettings.threePartPriceTwo;
        } else if (this.quantity > 29000 && this.quantity <= 39000) {
          price =
            this.quantity *
            this.scorecardorder.priceSettings.threePartPriceThree;
        } else {
          price =
            this.quantity *
            this.scorecardorder.priceSettings.threePartPriceFour;
        }
      } else if (this.scorecardType === 2) {
        if (this.quantity <= 19000) {
          price =
            this.quantity * this.scorecardorder.priceSettings.fourPartPriceOne;
        } else if (this.quantity > 19000 && this.quantity <= 29000) {
          price =
            this.quantity * this.scorecardorder.priceSettings.fourPartPriceTwo;
        } else if (this.quantity > 29000 && this.quantity <= 39000) {
          price =
            this.quantity *
            this.scorecardorder.priceSettings.fourPartPriceThree;
        } else {
          price =
            this.quantity * this.scorecardorder.priceSettings.fourPartPriceFour;
        }
      } else if (this.scorecardType === 3) {
        if (this.quantity <= 19000) {
          price =
            this.quantity * this.scorecardorder.priceSettings.fourPartPriceOne +
            this.scorecardorder.priceSettings.repeatPrice;
        } else if (this.quantity > 19000 && this.quantity <= 29000) {
          price =
            this.quantity * this.scorecardorder.priceSettings.fourPartPriceTwo +
            this.scorecardorder.priceSettings.repeatPrice;
        } else if (this.quantity > 29000 && this.quantity <= 39000) {
          price =
            this.quantity *
              this.scorecardorder.priceSettings.fourPartPriceThree +
            this.scorecardorder.priceSettings.repeatPrice;
        } else {
          price =
            this.quantity *
              this.scorecardorder.priceSettings.fourPartPriceFour +
            this.scorecardorder.priceSettings.repeatPrice;
        }
      } else if (this.scorecardType === 4) {
        if (this.quantity <= 19000) {
          price =
            this.quantity * this.scorecardorder.priceSettings.fourPartPriceOne +
            this.scorecardorder.priceSettings.repeatPrice;
        } else if (this.quantity > 19000 && this.quantity <= 29000) {
          price =
            this.quantity * this.scorecardorder.priceSettings.fourPartPriceTwo +
            this.scorecardorder.priceSettings.repeatPrice;
        } else if (this.quantity > 29000 && this.quantity <= 39000) {
          price =
            this.quantity *
              this.scorecardorder.priceSettings.fourPartPriceThree +
            this.scorecardorder.priceSettings.repeatPrice;
        } else {
          price =
            this.quantity *
              this.scorecardorder.priceSettings.fourPartPriceFour +
            this.scorecardorder.priceSettings.repeatPrice;
        }
      }
      return price;
    },
    getCurrentWeek() {
      let currentdate = new Date();
      var oneJan = new Date(currentdate.getFullYear(), 0, 1);
      var numberOfDays = Math.floor(
        (currentdate - oneJan) / (24 * 60 * 60 * 1000)
      );
      this.currentWeek = Math.ceil(
        (currentdate.getDay() + 1 + numberOfDays) / 7
      );

      // pupulate current year weeks
      let weeksCurrentYear = Array.from(
        { length: 52 - this.currentWeek },
        (_, i) => this.currentWeek + 1 + i
      );
      this.weeksCurrentYear = weeksCurrentYear;
    },
    toggleHideStep2() {
      this.step2hidden = !this.step2hidden;
      this.step1hidden = !this.step1hidden;
      this.stepOneInProgress = !this.stepOneInProgress;
      this.stepOneDone = !this.stepOneDone;
      this.stepTwoInProgress = !this.stepTwoInProgress;
    },
    toggleHideStep3() {
      this.step2hidden = !this.step2hidden;
      this.step3hidden = !this.step3hidden;
      this.stepTwoDone = !this.stepTwoDone;
      this.stepThreeInProgress = !this.stepThreeInProgress;
    },
    checkEmailValidity() {
      if (!this.email.includes('@')) {
        this.failedEmailValidiation = true;
      } else {
        this.failedEmailValidiation = false;
      }
    },

    saveOrder() {
      if (confirm('Är du säker på att du vill lägga beställningen?')) {
        this.$store
          .dispatch('scorecardOrder/createScorecardOrder', {
            quantity: this.quantity,
            surface: this.surface,
            deliveryWeekId: this.deliveryWeekSpecificId,
            deliveryWeek:
              this.deliveryWeekSpecific !== 0
                ? this.deliveryWeekSpecific
                : this.deliveryWeek,
            contactPerson: this.contactPerson,
            phoneNumber: this.phoneNumber,
            email: this.email,
            deliveryAddress: {
              companyName: this.deliveryAddress.companyName,
              postalAddress: this.deliveryAddress.postalAddress,
              postalCode: this.deliveryAddress.postalCode,
              city: this.deliveryAddress.city,
            },
            invoiceAddress: {
              companyName: this.invoiceAddress.companyName,
              orgNumber: this.invoiceAddress.orgNumber,
              postalAddress: this.invoiceAddress.postalAddress,
              postalCode: this.invoiceAddress.postalCode,
              city: this.invoiceAddress.city,
              email: this.invoiceAddress.email,
            },
            comment: this.comment,
            adminComment: this.adminComment,
            scorecardType: this.scorecardType,
            hasChanges: this.hasChanges,
          })
          .then(() => {
            const orderID = this.$store.state.scorecardOrder.scorecardorderid;
            this.orderID = orderID;
            this.toggleHideStep3();
          });
      }
    },
  },
  computed: {
    today() {
      let today = new Date();
      today.setUTCHours(0, 0, 0, 0);
      let now = today.toISOString().split('.')[0];
      return now;
    },
    scorecardorder() {
      return this.$store.getters['scorecardOrder/scorecardorder'];
    },
    scorecardorderaddresses() {
      return this.$store.getters['scorecardOrder/scorecardorderaddresses'];
    },
    deliveryweeks() {
      return this.$store.getters['scorecardOrder/deliveryweeks'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    canSaveOrder() {
      if (this.termsConsent === true) {
        return (
          this.contactPerson &&
          this.phoneNumber &&
          this.email &&
          this.deliveryAddress.companyName &&
          this.deliveryAddress.postalAddress &&
          this.deliveryAddress.postalCode &&
          this.deliveryAddress.city &&
          this.invoiceAddress.companyName &&
          this.invoiceAddress.orgNumber &&
          this.invoiceAddress.postalAddress &&
          this.invoiceAddress.postalCode &&
          this.invoiceAddress.city &&
          this.failedEmailValidiation === false
        );
      } else {
        return false;
      }
    },
    terms() {
      return this.$store.getters['scorecardOrder/terms'];
    },
    orders() {
      return this.$store.getters['scorecardOrder/scorecardallorders'];
    },
  },
  created() {
    this.$store.dispatch('scorecardOrder/fetchScorecardAllOrders', {
      id: this.$route.params.id,
    });
    this.$store.dispatch('scorecardOrder/fetchScorecardOrder');
    this.$store.dispatch('scorecardOrder/fetchTerms');
    this.$store
      .dispatch('scorecardOrder/fetchScorecardOrderAddresses')
      .then(() => {
        const addresses =
          this.$store.state.scorecardOrder.scorecardorderaddresses;
        this.deliveryAddress.companyName =
          addresses.deliveryAddress.companyName;
        this.deliveryAddress.postalAddress =
          addresses.deliveryAddress.postalAddress;
        this.deliveryAddress.postalCode = addresses.deliveryAddress.postalCode;
        this.deliveryAddress.city = addresses.deliveryAddress.city;
        this.invoiceAddress.companyName = addresses.invoiceAddress.companyName;
        this.invoiceAddress.orgNumber = addresses.invoiceAddress.orgNumber;
        this.invoiceAddress.postalAddress =
          addresses.invoiceAddress.postalAddress;
        this.invoiceAddress.postalCode = addresses.invoiceAddress.postalCode;
        this.invoiceAddress.city = addresses.invoiceAddress.city;
        this.invoiceAddress.email = addresses.invoiceAddress.email;
      });
    this.$store.dispatch('scorecardOrder/fetchDeliveryWeeks');
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 4.375rem 0 1rem 0;
  @include media('small') {
    margin: 4.5rem 0rem 1rem 15.7rem;
  }
}
header {
  width: 100%;
  height: 50px;
  background-color: color(blue);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;

  .header__left-section {
    display: flex;
    flex-direction: row;

    button {
      margin: 15px 30px auto 0px;
      background: none;
      color: white;
      border: none;

      .svg--90 {
        margin: auto 30px auto 0px;
      }
    }
  }

  .header__right-section {
    p {
      margin: 8px;
    }
  }
}

.three-step-header {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 3px color(gray) solid;
  flex-wrap: wrap;
  margin-bottom: 50px;

  .three-step-header__step {
    padding: 40px 0px;
    display: flex;
    flex-direction: row;
    line-height: 55px;

    .three-step-header__number {
      background-color: color(gray);
      height: 50px;
      width: 50px;
      border-radius: 50px;
      text-align: center;
      line-height: 50px;
      margin-right: 10px;
    }
    .three-step-header__number--inprogress {
      background-color: transparent;
      border: 2px color(blue) solid;
    }
    .three-step-header__number--done {
      background-color: color(blue);
      color: white;
    }
  }
}

.ontag__content {
  width: 85%;
  margin: auto;
  margin-bottom: 10px;

  .value-container {
    display: flex;
    flex-direction: row;

    .value-button {
      display: inline-block;
      width: 50px;
      height: 50px;
      text-align: center;
      vertical-align: middle;
      padding: 10px 0;
      background: color(blue);
      color: white;
      font-size: 25px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .value-button--disabled {
      background: #0399e469;
    }

    .value-button:hover {
      cursor: pointer;
    }
  }

  input[type='radio'] {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }

  .deliveryweek-option {
    margin-bottom: 5px;
  }

  .deliveryweek-optioncontainer {
    // height: 40px;
  }
}

form #decrease {
  margin-right: 10px;
  border-radius: 50px;
}

form #increase {
  margin-left: 10px;
  border-radius: 50px;
}

form #input-wrap {
  margin: 0px;
  padding: 0px;
}

input#number {
  text-align: center;
  border: 1px solid black;
  margin: 0px;
  width: 100px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-week-container {
  display: flex;
  flex-direction: row;

  .select-week {
    margin-right: 10px;
  }

  select {
    min-width: 70px;
  }
}

.button_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 7.5%;
}
.button--right {
  border-radius: 10px;
  width: 200px;
  margin-left: 20px;
}

.makeorder_step2 {
  input {
    width: 250px;
    height: 25px;
    background-color: color(gray-lighter);
    border: 1px solid color(gray);
    margin: 10px 0 20px;
  }

  .input__invalid {
    border-color: rgb(252, 122, 122);
  }
  textarea {
    background-color: color(gray-lighter);
    border: 1px solid color(gray);
    margin: 10px 0 20px;
    resize: none;
    width: 500px;
    height: 150px;
  }

  .input__error--message {
    margin-bottom: 10px;
    padding-top: 0px;
  }
}

.makeorder_step3 {
  height: 70vh;
  .button {
    margin: 0px auto 200px;
    border-radius: 10px;
    width: 200px;
  }
  .confirmation {
    text-align: center;
  }
}
.makeorder--hidden {
  display: none;
}

#haveChange,
#termsConsent {
  width: 30px;
  margin-left: 20px;
}
</style>
