<template>
  <div class="ontag">
    <div class="ontag--content">
      <h1>Inställningar scorekortsbeställning</h1>
      <h2 class="title-variation">Leveransveckor för repeatorder </h2>
      <section>
        <list-table
        :items="scorecardordersettings.deliveryWeeks.rows"
        :headers="scorecardordersettings.deliveryWeeks.columns">
        </list-table>
        <router-link class="link" :to="{ path: '/ontag/orderscorecard/create-deliveryweek'}">
          <base-button
          :text="'Skapa leveransvecka'"/>
        </router-link>
      </section>
      <h2 class="title-variation">Prisinställningar</h2>
      <section>
        <form @submit.prevent="saveChanges">
          <h3>Standardscorekort</h3>
          <table>
            <thead>
              <tr>
                <th>Kundpris</th>
              </tr>
              <tr>
                <td>Standardscorekort utpris</td>
              </tr>
              <!-- <th>1 000 - 19 000</th>
              <th>20 000 - 29 000</th>
              <th>30 000 - 39 000</th>
              <th>> 40 000</th> -->
            </thead>
            <tbody>
              <tr>
                <td>3-delat</td>
                <td><input
                type="number"
                min="0.01"
                step=".01"
                v-model="scorecardordersettings.priceSettings.threePartPriceOne"
                required/> kr/st exkl. moms</td>
              </tr>
              <tr>
                <td>4-delat</td>
                <td><input
                min="0.01"
                step=".01"
                type="number"
                v-model="scorecardordersettings.priceSettings.fourPartPriceOne"/> kr/st exkl. moms</td>
              </tr>
            </tbody><br>
            <h3>Klubbunika</h3>
            <thead>
              <tr>
                <th>Kundpris</th>
              </tr>
              <tr>
                <td>Klubbunika/egen design utpris</td>
              </tr>
              <!-- <th>1 000 - 19 000</th>
              <th>20 000 - 29 000</th>
              <th>30 000 - 39 000</th>
              <th>> 40 000</th> -->
            </thead>
            <tbody>
              <tr>
                <td>3-delat</td>
                <td><input
                type="number"
                min="0.01"
                step=".01"
                v-model="scorecardordersettings.priceSettings.threePartPriceCustomerSpecific"
                required/> kr/st exkl. moms</td>
              </tr>
              <tr>
                <td>4-delat</td>
                <td><input
                min="0.01"
                step=".01"
                type="number"
                v-model="scorecardordersettings.priceSettings.fourPartPriceCustomerSpecific"/> kr/st exkl. moms</td>
              </tr>
            </tbody><br>
            <tbody>
              <tr>
                <th>Inköpspris</th>
              </tr>
              <tr>
                <td>Klubbunika/egen design inköpspris</td>
              </tr>
              <!-- <th>1 000 - 19 000</th>
              <th>20 000 - 29 000</th>
              <th>30 000 - 39 000</th>
              <th>> 40 000</th> -->
              <tr>
                <td>3-delat</td>
                <td><input
                type="number"
                min="0.01"
                step=".01"
                v-model="scorecardordersettings.priceSettings.threePartCostPriceCustomerSpecific"
                required/> kr/st exkl. moms</td>
              </tr>
              <tr>
                <td>4-delat</td>
                <td><input
                min="0.01"
                step=".01"
                type="number"
                v-model="scorecardordersettings.priceSettings.fourPartCostPriceCustomerSpecific"/> kr/st exkl. moms</td>
              </tr>
              <tr>
                <td>Tryckkostnad</td>
                <td><input
                min="0.01"
                step=".01"
                type="number"
                v-model="scorecardordersettings.priceSettings.printingCost"/> kr/beställning exkl. moms</td>
              </tr>
            </tbody><br>
            <base-button
            :disabled="scorecardordersettings.priceSettings.fourPartPriceOne == 0 || scorecardordersettings.priceSettings.threePartPriceOne == 0"
            :text="$t('save')"
            type="submit"
            v-element-loader="{ loaderKey: 'change-price' }" />
          </table>
        </form><br>

        <h3>Fraktpriser</h3>
        <form @submit.prevent="saveFreightChanges">
          <table>
            <tr>
              <td class="bold">
                Fraktpåslag
              </td>
              <td>
                <input
                min="0.01"
                step=".01"
                type="number"
                v-model="scorecardordershippingprice.freight"> %
              </td>
            </tr>
          <br>
            <tr>
              <td class="bold">Kolli</td>
            </tr>
            <tr>
              <td>Används vid 1-6 lådor scorekort</td>
            </tr>
            <tr>
              <td>Antal lådor per kolli:</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Pris ex. moms/kolli</td>
              <td>
                <input
                min="0.01"
                step=".01"
                type="number"
                v-model="scorecardordershippingprice.packagePriceExVAT"> kr
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td class="bold">Halvpall</td>
            </tr>
            <tr>
              <td>Används vid 7-16 lådor scorekort</td>
            </tr>
            <tr>
              <td>Postnummer</td>
              <td>Pris per halvpall ex. moms</td>
            </tr>
            <tr>
              <td>10-19</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip10to19PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>20-27</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip20to27PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>28-39</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip28to39PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>40-51</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip40to51PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>52-57</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip52to57PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>58-61</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip58to61PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>62</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip62PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>63-76</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip63to76PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>77-82</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip77to82PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>83-88</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip83to88PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>89-93</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip89to93PriceHalf"> kr
              </td>
            </tr>
            <tr>
              <td>94-98</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip94to98PriceHalf"> kr
              </td>
            </tr><br>
            <tr>
              <td class="bold">Helpall</td>
            </tr>
            <tr>
              <td>Används vid 17-54 lådor scorekort</td>
            </tr>
            <tr>
              <td>Postnummer</td>
              <td>Pris per helpall ex. moms</td>
            </tr>
            <tr>
              <td>10-19</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip10to19PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>20-27</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip20to27PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>28-39</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip28to39PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>40-51</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip40to51PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>52-57</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip52to57PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>58-61</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip58to61PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>62</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip62PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>63-76</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip63to76PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>77-82</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip77to82PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>83-88</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip83to88PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>89-93</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip89to93PriceFull"> kr
              </td>
            </tr>
            <tr>
              <td>94-98</td>
              <td>
                <input
                min="0.1"
                step=".1"
                type="number"
                v-model="scorecardordershippingprice.zip94to98PriceFull"> kr
              </td>
            </tr>
          </table>
          <base-button
            :disabled="scorecardordersettings.priceSettings.fourPartPriceOne == 0 || scorecardordersettings.priceSettings.threePartPriceOne == 0"
            :text="$t('save')"
            type="submit"
            v-element-loader="{ loaderKey: 'change-price' }" />
        </form>
        <h3>Ändringslogg</h3>
        <list-table
        :items="scorecardordersettings.priceSettings.changeLog.rows"
        :headers="scorecardordersettings.priceSettings.changeLog.columns"
        :key="componentKey">
        </list-table>

      </section>
      <h2 class="title-variation">Redigera informationstext </h2>
      <section class="edit-info">
        <form @submit.prevent="saveInfoSettings">
          <label for="info-heading">Rubrik</label><br>
          <input
          v-model="title"
          type="text"
          id="info-heading"><br>
          <label for="info-text">Ange meddelande</label><br>
          <textarea
          v-model="information"
          cols="50"
          rows="5"
          id="info-text"/><br>
          <label for="info-show">Ska texten visas för klubben?</label><br>
          <input
          v-model="showInformationText"
          type="checkbox"
          id="info-show">
          <base-button
          :text="$t('save')"
          type="submit"
          v-element-loader="{ loaderKey: 'post-info' }" />
        </form>
      </section>
      <h2 class="title-variation">Redigera villkor</h2>
      <section>
        <input
          hidden
          type="file"
          id="terms"
          name="terms"
          accept=".pdf"
          @change="e => addFile(e)">
        <base-button
        @click="chooseFile('terms')"
        :text="$t('uploadTerms')"
        class="button--terms"
        :button-size-small="true"
        v-element-loader="{ loaderKey: 'post-terms' }">
        <use-svg svg="export-icon" />
        </base-button>
        <a :href="terms.termsUrl" target="_blank">
        <base-button
        :text="$t('showTerms')"
        class="button--terms"
        :button-size-small="true">
        </base-button>
        </a>
        <h3>Historik, villkor</h3>
        <list-table
        :items="termsHistory.rows"
        :headers="termsHistory.columns"
        :key="componentKey">
        </list-table>
      </section>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import UseSvg from '@/components/use-svg';

export default {
  name: 'ScorecardOrderSettings',
  components: {
    ListTable,
    BaseButton,
    UseSvg,
  },
  data() {
    return {
      componentKey: 0,
      showInformationText: false,
      title: '',
      information: '',
    };
  },
  directives: {
    elementLoader,
  },

  methods: {
    saveChanges() {
      if(confirm('Är du säker på att du vill ändra priserna?')) {
        this.$store.dispatch('scorecardOrder/patchScorecardOrderSettings', {
          threePartPriceOne: this.scorecardordersettings.priceSettings.threePartPriceOne,
          fourPartPriceOne: this.scorecardordersettings.priceSettings.fourPartPriceOne,
          threePartPriceCustomerSpecific: this.scorecardordersettings.priceSettings.threePartPriceCustomerSpecific,
          threePartCostPriceCustomerSpecific: this.scorecardordersettings.priceSettings.threePartCostPriceCustomerSpecific,
          fourPartPriceCustomerSpecific: this.scorecardordersettings.priceSettings.fourPartPriceCustomerSpecific,
          fourPartCostPriceCustomerSpecific: this.scorecardordersettings.priceSettings.fourPartCostPriceCustomerSpecific,
          repeatPrice: this.scorecardordersettings.priceSettings.repeatPrice,
          printingCost: this.scorecardordersettings.priceSettings.printingCost,
        }).then(() => {
          this.$store.dispatch('scorecardOrder/fetchScorecardOrderSettings');
        });
      }
    },
    saveFreightChanges() {
      if(confirm('Är du säker på att du vill ändra fraktpriserna?')) {
        this.$store.dispatch('scorecardOrder/postScorecardOrderShippingPrice', {
          freight: this.scorecardordershippingprice.freight,
          packagePriceExVAT: this.scorecardordershippingprice.packagePriceExVAT,
          zip10to19PriceHalf: this.scorecardordershippingprice.zip10to19PriceHalf,
          zip20to27PriceHalf: this.scorecardordershippingprice.zip20to27PriceHalf,
          zip28to39PriceHalf: this.scorecardordershippingprice.zip28to39PriceHalf,
          zip40to51PriceHalf: this.scorecardordershippingprice.zip40to51PriceHalf,
          zip52to57PriceHalf: this.scorecardordershippingprice.zip52to57PriceHalf,
          zip58to61PriceHalf: this.scorecardordershippingprice.zip58to61PriceHalf,
          zip62PriceHalf: this.scorecardordershippingprice.zip62PriceHalf,
          zip63to76PriceHalf: this.scorecardordershippingprice.zip63to76PriceHalf,
          zip77to82PriceHalf: this.scorecardordershippingprice.zip77to82PriceHalf,
          zip83to88PriceHalf: this.scorecardordershippingprice.zip83to88PriceHalf,
          zip89to93PriceHalf: this.scorecardordershippingprice.zip89to93PriceHalf,
          zip94to98PriceHalf: this.scorecardordershippingprice.zip94to98PriceHalf,
          zip10to19PriceFull: this.scorecardordershippingprice.zip10to19PriceFull,
          zip20to27PriceFull: this.scorecardordershippingprice.zip20to27PriceFull,
          zip28to39PriceFull: this.scorecardordershippingprice.zip28to39PriceFull,
          zip40to51PriceFull: this.scorecardordershippingprice.zip40to51PriceFull,
          zip52to57PriceFull: this.scorecardordershippingprice.zip52to57PriceFull,
          zip58to61PriceFull: this.scorecardordershippingprice.zip58to61PriceFull,
          zip62PriceFull: this.scorecardordershippingprice.zip62PriceFull,
          zip63to76PriceFull: this.scorecardordershippingprice.zip63to76PriceFull,
          zip77to82PriceFull: this.scorecardordershippingprice.zip77to82PriceFull,
          zip83to88PriceFull: this.scorecardordershippingprice.zip83to88PriceFull,
          zip89to93PriceFull: this.scorecardordershippingprice.zip89to93PriceFull,
          zip94to98PriceFull: this.scorecardordershippingprice.zip94to98PriceFull,
        }).then(() => {
          this.$store.dispatch('scorecardOrder/fetchScorecardOrderSettings');
          this.$store.dispatch('scorecardOrder/fetchScorecardOrderShippingPrice');
        });
      }
    },
    saveInfoSettings() {
      this.$store.dispatch('scorecardOrder/postSettingsClubInfo', {
        showInformationText: this.showInformationText,
        title: this.title,
        information: this.information,
      });
    },
    chooseFile(value) {
      document.getElementById(value).click();
    },
    async addFile(event) {
      let terms;
      terms = event.target.files[0];
      if(terms) {
        this.$store.dispatch('scorecardOrder/addTerms', { terms: terms }).then(() => {
          this.$store.dispatch('scorecardOrder/fetchTerms');
          alert('Nya villkor uppladdade');
          // TODO: fixa så att listan för historik uppdateras
        });
      }
    },
  },

  computed: {
    scorecardordersettings() {
      return this.$store.getters['scorecardOrder/scorecardordersettings'];
    },
    settingsclubinfo() {
      return this.$store.getters['scorecardOrder/settingsclubinfo'];
    },
    terms() {
      return this.$store.getters['scorecardOrder/terms'];
    },
    termsHistory() {
      return this.$store.getters['scorecardOrder/termshistory'];
    },
    scorecardordershippingprice() {
      return this.$store.getters['scorecardOrder/scorecardordershippingprice'];
    },
  },

  created() {
    this.$store.dispatch('scorecardOrder/fetchTermsHistory').then(() => {
      this.$store.dispatch('scorecardOrder/fetchScorecardOrderSettings');
      this.$store.dispatch('scorecardOrder/fetchTerms');
      this.$store.dispatch('scorecardOrder/fetchScorecardOrderShippingPrice');
      this.$store.dispatch('scorecardOrder/fetchSettingsClubInfo').then(() => {
        this.information = this.$store.state.scorecardOrder.settingsclubinfo.information;
        this.title = this.$store.state.scorecardOrder.settingsclubinfo.title;
        this.showInformationText = this.$store.state.scorecardOrder.settingsclubinfo.showInformationText;
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.changeLog {
  min-width: 50%;
  text-align: left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=checkbox] {
  height: 25px;
  width: 25px;
}

.link {
  display: inline-block;
  margin-top: 20px;
}

.edit-info {
  input, textarea {
    margin-bottom: 20px;
  }
}

.button--terms {
  display: inline-block !important;
  margin-right: 10px;
}

table {
  th {
    text-align: left;
  }
  tr {
    td {
      input {
        width: 75px;
      }
    }
  }
  .first {
    text-align: right;
    padding-right: 25px;
  }

  .bold {
    font-weight: bold;
  }
}
</style>
