<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <span
          v-if="fundingTodo.externalId"
          class="h1">Update project funding and budget</span>
        <span
          v-else
          class="h1">Budget/Funding</span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
      </div>
      <template v-if="fundingTodos">
        <template v-if="fundingTodo.externalId">
          <a @click="showNone"> &lt; Back to list </a>
          <section>
            <p>Status: {{ fundingTodo.status }}</p>
            <p>Order no. {{ fundingTodo.orderNumber }}</p>
            <p>Customer in TERP: {{ fundingTodo.customer }}</p>
            <p>Club name: {{ fundingTodo.clubName }}</p>
            <p>Project no. in TERP: {{ fundingTodo.terpId }}</p>
            <table class="budgetTable">
              <thead>
                <th>Year</th>
                <th>Month</th>
                <th>Type</th>
                <th>Value</th>
                <th>Currency</th>
              </thead>
              <tr
                v-for="(funding, i) in fundingTodo.budgets"
                v-bind:key="i">
                <td>{{ funding.year }}</td>
                <td>{{ funding.month }}</td>
                <td>{{ funding.type }}</td>
                <td>{{ funding.value }}</td>
                <td>{{ funding.currency }}</td>
              </tr>
            </table>

            <p>TE order comment</p>
            <br />
            <br />
            <label>Comment (Mandatory input if Delete or Pause)</label>
            <br />
            <textarea v-model="fundingTodo.comment"></textarea>
            <br />
            <p>Forecast done by:</p>
            <p>Forecast paused by:</p>
            <p>Forecast deleted by:</p>
            <p>Forecast cancelled</p>
            <template v-if="handleTask == true && fundingTodo.externalId">
              <div class="input-group--inline">
                <a @click="showNone"> &lt; Previous </a>
                <base-button
                  class="button--background-gray-dark"
                  @click="showNone"
                  text="Cancel">
                </base-button>
                <base-button
                  class="button--background-red"
                  :disabled="setDisabledPauseButton(id)"
                  @click="setAsPause(true)"
                  :text="
                    'Pause' + (id < fundingTodos.length - 1 ? ' and next' : '')
                  ">
                </base-button>
                <base-button
                  class="button--background-red"
                  :disabled="setDisabledDeleteButton(id)"
                  @click="setAsDelete(true)"
                  :text="
                    'Delete' + (id < fundingTodos.length - 1 ? ' and next' : '')
                  ">
                </base-button>
                <base-button
                  class="button--background-blue"
                  :disabled="setDisabledDoneButton(id)"
                  @click="setAsDone(true)"
                  :text="
                    'Done' + (id < fundingTodos.length - 1 ? ' and next' : '')
                  ">
                </base-button>
              </div>
            </template>
            <template v-if="handleTask == false && fundingTodo.externalId">
              <div class="input-group--inline">
                <a @click="showNone"> &lt; Previous </a>
                <base-button
                  class="button--background-gray-dark"
                  @click="showNone"
                  text="Cancel">
                </base-button>
                <base-button
                  class="button--background-red"
                  :disabled="setDisabledPauseButton(id)"
                  @click="setAsPause(false)"
                  :text="'Pause'">
                </base-button>
                <base-button
                  class="button--background-red"
                  :disabled="setDisabledDeleteButton(id)"
                  @click="setAsDelete(false)"
                  :text="'Delete'">
                </base-button>
                <base-button
                  class="button--background-blue"
                  :disabled="setDisabledDoneButton(id)"
                  @click="setAsDone(false)"
                  :text="'Done'">
                </base-button>
              </div>
            </template>
          </section>
        </template>
        <template v-else>
          <section class="todos_top-container">
            <input
              type="text"
              placeholder="Search" />
            <base-button
              text="Handle tasks"
              @click="handleTasks(0)">
            </base-button>
            No of events in list: {{ fundingTodos.length }}
            <br />
            Sum of revenue in the list: {{ sumOfRevenue() }} kr
            <br />
            Sum of cost in the list: {{ sumOfCost() }} kr
          </section>
          <table>
            <thead>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Status"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'status')"
                  :options="filterValues('status')">
                </multi-select>
              </th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Projectnumber"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'terpId')"
                  :options="filterValues('terpId')">
                </multi-select>
              </th>
              <th>Customer</th>
              <th>Club name</th>
              <th>Order no.</th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Delivery week"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'deliveryWeek')"
                  :options="filterValues('deliveryWeek')">
                </multi-select>
              </th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Delivery year"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'deliveryYear')"
                  :options="filterValues('deliveryYear')">
                </multi-select>
              </th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Revenue month"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'revenueMonth')"
                  :options="filterValues('revenueMonth')">
                </multi-select>
              </th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Revenue year"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'revenueYear')"
                  :options="filterValues('revenueYear')">
                </multi-select>
              </th>
              <th>Revenue</th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Cost month"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'costMonth')"
                  :options="filterValues('costMonth')">
                </multi-select>
              </th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Cost year"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'costYear')"
                  :options="filterValues('costYear')">
                </multi-select>
              </th>
              <th>Cost</th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Last update"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'lastChanged')"
                  :options="filterValues('lastChanged')">
                </multi-select>
              </th>
              <th></th>
            </thead>
            <tr
              v-for="(fundingTodo, i) in fundingTodos"
              v-bind:key="i">
              <td>{{ fundingTodo.status }}</td>
              <td>{{ fundingTodo.terpId }}</td>
              <td>{{ fundingTodo.customer }}</td>
              <td>{{ fundingTodo.clubName }}</td>
              <td>{{ fundingTodo.orderNumber }}</td>
              <td>{{ fundingTodo.deliveryWeek }}</td>
              <td>{{ fundingTodo.deliveryYear }}</td>
              <td>{{ fundingTodo.revenueMonth }}</td>
              <td>{{ fundingTodo.revenueYear }}</td>
              <td>{{ fundingTodo.revenue }}</td>
              <td>{{ fundingTodo.costMonth }}</td>
              <td>{{ fundingTodo.costYear }}</td>
              <td>{{ fundingTodo.cost }}</td>
              <td>
                {{
                  fundingTodo.lastChanged == '' ||
                  fundingTodo.lastChangedBy == ''
                    ? ''
                    : fundingTodo.lastChanged +
                      ' by ' +
                      fundingTodo.lastChangedBy
                }}
              </td>
              <td @click="showFundingTodo(fundingTodo.externalId, i)">
                <use-svg
                  svg="arrow-right"
                  class="svg--90-negative" />
              </td>
            </tr>
          </table>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import MultiSelect from '@/components/form-elements/multi-select';
import UseSvg from '@/components/use-svg';

export default {
  name: 'OnTagCourseGuides',
  components: {
    BaseButton,
    MultiSelect,
    UseSvg,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      filter: {
        status: ['Paused', 'To do'],
        revenueMonth: [],
        revenueYear: [],
        costMonth: [],
        costYear: [],
        lastChanged: [],
      },
      sortBy: 'changedDate',
      handleTask: false,
    };
  },
  computed: {
    _fundingTodos() {
      return this.$store.getters['todo/fundingTodos'];
    },
    fundingTodos() {
      let et = this._fundingTodos;
      for (let f of [
        'status',
        'revenueMonth',
        'revenueYear',
        'costMonth',
        'costYear',
        'lastChanged',
      ]) {
        if (this.filter[f].length > 0) {
          et = et.filter(a => {
            return this.filter[f].includes(a[f]);
          });
        }
      }
      return et.sort((a, b) =>
        this.sortBy in a
          ? this.sortBy in b
            ? a[this.sortBy] > b[this.sortBy]
              ? 1
              : -1
            : 1
          : -1
      );
    },
    fundingTodo() {
      return this.$store.getters['todo/fundingTodo'];
    },
    isLoading() {
      return this.$store.getters['todo/isLoading'];
    },
  },
  created() {
    if (this.id) {
      this.$store.dispatch('todo/getFundingTodo', {
        id: this.fundingTodos[this.id].externalId,
      });
    } else {
      this.$store.dispatch('todo/getFundingTodos');
    }
  },
  methods: {
    handleTasks(id) {
      if (id < this.fundingTodos.length - 1) {
        this.showFundingTodo(this.fundingTodos[id].externalId, id);
        this.handleTask = true;
      } else {
        this.showFundingTodo(this.fundingTodos[id].externalId, id);
        this.handleTask = false;
        this.$store.dispatch('todo/getFundingTodos');
      }
    },
    toggleModal() {
      this.isLoading = !this.isLoading;
    },
    setAsDone(next) {
      this.$store
        .dispatch('todo/patchFundingTodo', {
          id: this.fundingTodos[this.id].externalId,
          status: 'Done',
          comment: this.fundingTodos[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.fundingTodo.externalId = false;
            this.$store.dispatch('todo/getFundingTodos');
          }
        });
    },
    setAsPause(next) {
      this.$store
        .dispatch('todo/patchFundingTodo', {
          id: this.fundingTodos[this.id].externalId,
          status: 'Paused',
          comment: this.fundingTodos[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.fundingTodo.externalId = false;
            this.$store.dispatch('todo/getFundingTodos');
          }
        });
    },
    setAsDelete(next) {
      this.$store
        .dispatch('todo/patchFundingTodo', {
          id: this.fundingTodos[this.id].externalId,
          status: 'Deleted',
          comment: this.fundingTodos[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.fundingTodo.externalId = false;
            this.$store.dispatch('todo/getFundingTodos');
          }
        });
    },
    sumOfRevenue() {
      if (this.fundingTodos) {
        let myArray = this.fundingTodos.map(revenues => revenues.revenue);
        let sumOfArray = myArray.reduce((pv, cv) => pv + cv, 0);
        return sumOfArray.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
    },
    sumOfCost() {
      if (this.fundingTodos) {
        let myArray = this.fundingTodos.map(costs => costs.cost);
        let sumOfArray = myArray.reduce((pv, cv) => pv + cv, 0);
        return sumOfArray.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
    },
    showFundingTodo(id, i) {
      this.$store.dispatch('todo/getFundingTodo', { id: id });
      this.id = i;
    },

    setDisabledDeleteButton(id) {
      let status = this.fundingTodos[id].status.toLowerCase();
      if (
        status === 'deleted' ||
        status === 'cancelled' ||
        !this.fundingTodo.comment
      ) {
        return true;
      }
    },
    setDisabledPauseButton(id) {
      let status = this.fundingTodos[id].status.toLowerCase();
      if (
        status === 'cancelled' ||
        status === 'paused' ||
        !this.fundingTodo.comment
      ) {
        return true;
      }
    },
    setDisabledDoneButton(id) {
      let status = this.fundingTodos[id].status.toLowerCase();
      if (status === 'done' || status === 'cancelled') {
        return true;
      }
    },
    setDisabledTodoButton(id) {
      let status = this.fundingTodos[id].status.toLowerCase();
      if (
        status === 'to do' ||
        status === 'cancelled' ||
        !this.fundingTodo.comment
      ) {
        return true;
      }
    },
    showNone() {
      this.$store.dispatch('todo/getFundingTodos');
      this.fundingTodo.externalId = '';
      this.handleTask = false;
    },
    filterValues(prop) {
      return [...new Set(this._fundingTodos.map(a => a[prop]))].map(a => {
        return {
          displayName: a,
          value: a,
          // checked: this.filter[prop].includes(a)
        };
      });
    },
    filterResults(e, prop) {
      this.filter[prop] = e.options
        .filter(option => option.checked)
        .map(o => o.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.todos_top-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 12px;
  margin-bottom: 12px;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: auto;
  th {
    padding: 0 0.625rem;
    text-align: left;
    background-color: color(blue-light);
  }
  tr {
    &.pointer {
      cursor: auto;
    }
    &:nth-child(even) {
      background-color: color(gray-lighter);
    }
    &.checked {
      background-color: color(blue-light);
    }
  }
  td {
    padding: 0.625rem;
    &:last-child {
      padding-right: 0;
      cursor: pointer;
    }
  }
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
  margin-bottom: 20px;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 20px;
}
.ontag {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.ontag__content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  &.dashboard {
    background-color: transparent;
    border-radius: 0;
  }
}
.input-group--inline {
  button {
    margin: 0.5rem;
  }
}
</style>
