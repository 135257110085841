import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  accounts: [],
  groups: [],
  categories: [],
  isLoading: false,
};

const mutations = {
  isLoading(state, payload) {
    state.isLoading = payload;
  },
  accounts(state, payload) {
    state.accounts = payload;
  },
  groups(state, payload) {
    state.groups = payload;
  },
  categories(state, payload) {
    state.categories = payload;
  },
};

const getters = {
  isLoading: state => {
    return state.isLoading;
  },
  accounts: state => {
    return state.accounts;
  },
  groups: state => {
    return state.groups;
  },
  categories: state => {
    return state.categories;
  },
};

const actions = {
  fetchAccounts: async (context, companyId) => {
    await fetch(context, companyId + '/accounts', 'accounts');
  },
  fetchGroups: async context => {
    await fetch(context, 'groups', 'groups');
  },
  fetchCategories: async context => {
    await fetch(context, 'categories', 'categories');
  },
  patchAccount: async (context, params) => {
    await patch(
      context,
      params.accountId + '/connect/' + params.groupId + '/account'
    );
  },
  patchGroup: async (context, params) => {
    await patch(context, 'group', params);
  },
  patchAuto: async (context, companyId) => {
    await patch(context, companyId + '/automatic');
    await fetch(context, companyId + '/accounts', 'accounts');
  },
};
async function fetch(context, path, commit) {
  path = 'businessboard/balancereport/' + path;
  context.commit('isLoading', true);
  let response = {};
  try {
    response = await Vue.axios.get(path);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: `Fel vid hämtning: ${
        error.response.data.message || 'Felmeddelande saknas'
      }`,
      request: path,
    });
  }
  if (response && response.status === 200) {
    const data = response.data;
    context.commit(commit, data);
  }
  context.commit('isLoading', false);
}
async function patch(context, path, data) {
  path = 'businessboard/balancereport/' + path;
  context.commit('isLoading', true);
  let response = {};
  try {
    response = await Vue.axios.patch(path, data);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: `Fel vid hämtning: ${
        error.response.data.message || 'Felmeddelande saknas'
      }`,
      request: path,
    });
  }
  if (response && response.status === 200) {
  }
  context.commit('isLoading', false);
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
