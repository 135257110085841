<template>
  <div
    v-if="show"
    class="chart_settings_modal">
    <div class="header">
      {{ `${$t('settingsFor')} ${$t(titleTextTranslationKey)}` }}
    </div>

    <hr />

    <!-- Year dropdowns -->
    <div class="column">
      <!-- Current year -->
      <select-year
        :labelTranslationKey="'currentYear'"
        :options="options"
        :selectedIndex="
          options.findIndex(o => o.value === this.selectedCurrentYear)
        "
        :setting="'currentYear'"
        @optionSelected="this.yearSelected" />

      <!-- Comparissonyear -->
      <select-year
        :labelTranslationKey="'comparisonYear'"
        :options="options"
        :selectedIndex="
          options.findIndex(o => o.value === this.selectedComparisonYear)
        "
        :setting="'comparisonYear'"
        @optionSelected="this.yearSelected" />

      <hr />
    </div>

    <div class="modal__content--actions">
      <!-- TODO: should be base-button -->
      <button @click="updateFilter(false)">{{ $t('showTemporarily') }}</button>
      <info-icon :description="$t('showTemporarilyInfo')">
        <template #icon>
          <use-svg svg="bb-info-icon" />
        </template>
      </info-icon>
      <button
        class="btn_primary"
        v-if="showSaveAndPublish"
        @click="updateFilter(true)"
        :disabled="!canSave">
        {{ $t('saveAndPublish') }}
      </button>
      <br />
      <button @click="toggleModal">{{ $t('cancel') }}</button>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import InfoIcon from '@/components/businessboard/tooltip-icon';
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

import SelectYear from './select-year';

export default {
  name: 'Modal',
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
    InfoIcon,
    SelectYear,
  },
  props: {
    show: VueTypes.bool.def(false),
    titleTextTranslationKey: VueTypes.string,
    options: VueTypes.arrayOf(
      VueTypes.shape({ value: VueTypes.number, displayName: VueTypes.number })
    ),
    selected: VueTypes.shape({
      currentYear: VueTypes.number,
      comparisonYear: VueTypes.number,
    }),
  },
  data() {
    return {
      formats: ['JPG', 'PNG'],
      canSave: true,
      selectedCurrentYear: this.selected?.currentYear,
      selectedComparisonYear: this.selected?.comparisonYear,
    };
  },
  computed: {
    showHeaders() {
      return this.$store.getters['businessboardReportingTool/settings']
        .showHeaders;
    },
    fileFormat() {
      return this.$store.getters['businessboardReportingTool/settings']
        .fileFormat;
    },
    user() {
      return this.$store.getters['user/user'];
    },
    showSaveAndPublish() {
      return !(
        this.user.roles?.BusinessBoard &&
        this.user.roles.BusinessBoard.some(
          e => e.value === 'BusinessBoardBoardMember'
        )
      );
    },
  },
  mounted() {},
  methods: {
    yearSelected(e) {
      switch (e.setting) {
        case 'currentYear':
          this.selectedCurrentYear = e.value;
          break;
        case 'comparisonYear':
          this.selectedComparisonYear = e.value;
          break;
      }
    },
    toggleModal() {
      this.$emit('close');
    },
    updateFilter(persist) {
      this.toggleModal();

      this.$emit('updateSettings', {
        persist: persist,
        currentYear: this.selectedCurrentYear,
        comparisonYear: this.selectedComparisonYear,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.columns {
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: calc(100vh - 21.5rem);
}
.columns:hover {
  overflow-y: scroll;
  max-height: calc(100vh - 21.5rem);
}
.column {
  .column_section {
    display: inline-block;
    color: #555f61;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }
  strong {
    line-height: 40px;
  }
  span {
    margin: 0.5rem;
  }
}
input[type='radio'] {
  margin: 1rem;
}
.course {
  cursor: pointer;
  display: flex;
  padding: 8px;
  border-radius: 3px;
  gap: 0.5rem;
  p {
    line-height: 20px;
    margin-top: 0.2rem;
    color: #131516;
    font-weight: 500;
  }
  &_selected {
    background-color: #eaf6f7;
  }
  &_checkbox {
    width: 20px;
    height: 20px;
    display: inline-flex;
    border: 1px solid #131516;
    border-radius: 2px;
    svg {
      margin: auto;
    }
  }
}
.chart_settings_modal {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 8px;
  .header {
    margin: 1rem 0;
    display: flex;
    span {
      color: #0e3746;
    }
    .info_icon {
      margin-left: 0.5rem;
    }
  }
  button {
    padding: 0.5rem;
    border: 1px solid #0e3746;
    border-radius: 3px;
    background: white;
    margin: 0.5rem;
  }
  .btn_primary {
    background-color: #0e3746;
    color: white;
    float: right;
  }
  .btn_reticent {
    border: 0;
  }
}
.drillDownChoice {
  display: grid;
  grid-auto-flow: column;
  background: #eaf6f7;
  border-radius: 3px;
  font-weight: 400;
  padding: 4px;
  .choice {
    margin: auto 0;
    text-align: center;
    border: 1px solid #eaf6f7;
    padding: 4px;
    border-radius: 3px;
  }
  .selected {
    background: #abded1;
    border-color: #0e3746;
    font-weight: 500;
    div {
      display: inline-block;
      margin: auto;
      vertical-align: middle;
    }
  }
}
.show-diagram-header-div {
  gap: 1rem;
  display: flex;
  margin-left: 0.5rem;
}

.button-green-hover {
  &:hover {
    background-color: #27645c;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}
</style>
