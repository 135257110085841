<template>
  <div class="modal--buttons">
    <base-button
      backgroundColor="white"
      text="Avbryt"
      @click="closeModal()" />
    <base-button
      backgroundColor="bb-primary"
      :text="$t('saveAndPublish')"
      @click="saveAndPublish()" />
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'save-publish-and-close-modal-buttons',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
  },
  props: {},

  data() {
    return {};
  },
  methods: {
    saveAndPublish() {
      this.$emit('saveAndPublish');
    },

    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal--buttons {
  display: flex;
  justify-content: space-between;
  margin-top: measure(spacing-xl);
}
</style>
