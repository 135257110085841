<template>
  <div class="ontag">
    <div
      class="ontag__content voucher"
      v-if="voucher">
      <h1 v-if="id">{{ $t('edit') }} {{ $t('voucher') }}</h1>
      <h1 v-else>
        {{ $t('create') }} {{ $t('voucher') }}: {{ $t(`${typeName}`) }}
      </h1>
      <p>Du skapar din voucher genom att ladda upp en bild.</p>
      <div class="voucher__edit">
        <div class="voucher__left">
          <div class="voucher__edit--top">
            <div class="voucher__name">
              <div class="input">
                <label
                  for="voucher-name"
                  class="input__label label__inline first-letter">{{ $t('voucher') }} {{ $t('name') }}*</label>
                <input
                  type="text"
                  name="voucher-name"
                  id="voucher-name"
                  class="input__input"
                  required
                  v-model="voucher.name"
                  :disabled="voucher.standard" />
              </div>
            </div>
            <div class="voucher__tabs">
              <button
                class="voucher__tab"
                :class="{ 'voucher__tab--on': !digitalVoucher }"
                @click="digitalVoucher = false">
                <img
                  v-if="!digitalVoucher"
                  src="../../assets/images/voucher-icon_blue.svg"
                  alt="Paper voucher active" />
                <img
                  v-else
                  src="../../assets/images/voucher-icon_grey.svg"
                  alt="Paper voucher inactive" />
                Voucher
              </button>
              <button
                v-if="typeName !== 'neutral' && typeName !== 'competition'"
                class="voucher__tab"
                :class="{ 'voucher__tab--on': digitalVoucher }"
                @click="digitalVoucher = true">
                <img
                  v-if="digitalVoucher"
                  src="../../assets/images/phone-blue.svg"
                  alt="Digital voucher active" />
                <img
                  v-else
                  src="../../assets/images/phone-grey.svg"
                  alt="Digital voucher inactive" />
                Digital voucher
              </button>
            </div>
          </div>
          <div class="voucher__result">
            <div class="voucher__result--image">
              <template v-if="!digitalVoucher">
                <template v-if="voucher.paperImageUrl">
                  <div class="voucher__horizontal-size">
                    &lt;---- 960 px ----&gt;
                  </div>
                  <div class="voucher__wrapper-flex">
                    <span class="voucher__vertical-size">
                      &lt;---- 1200 px ----&gt;</span>
                    <div class="voucher__wrapper">
                      <div
                        :class="[
                          'voucher__layout--text_upper',
                          'voucher__layout--text',
                          { 'voucher__layout--text_hover': hover === 'upper' },
                        ]">
                        {{ voucher.upperText }}
                      </div>
                      <div class="voucher__layout--image image-paper">
                        <div
                          @mouseover="hoverOn('paper')"
                          @mouseleave="hoverOn('')"
                          :class="['voucher__layout--image-overlay-wrapper']">
                          <template v-if="hover === 'paper'">
                            <base-button
                              :text="$t('remove')"
                              class="button--has-icon"
                              :button-size-small="true"
                              @click="removeImage('paper')">
                              <use-svg svg="trash-icon" />
                            </base-button>
                            <input
                              hidden
                              type="file"
                              id="file_upload_paper"
                              name="file_upload_paper"
                              @change="
                                e =>
                                  addFile('paperImageUrl', 'paperImageKey', e)
                              " />
                            <base-button
                              @click="chooseFile('paper')"
                              :text="$t('upload')"
                              class="button--has-icon"
                              :button-size-small="true">
                              <use-svg svg="export-icon" />
                            </base-button>
                          </template>
                        </div>
                        <div>
                          <img
                            :src="
                              voucher.paperImageKey
                                ? voucher.paperImageUrl + voucher.paperImageKey
                                : voucher.paperImageUrl
                            " />
                        </div>
                      </div>
                      <div
                        :class="[
                          'voucher__layout--text_lower',
                          'voucher__layout--text',
                          { 'voucher__layout--text_hover': hover === 'lower' },
                        ]">
                        {{ voucher.lowerText }}
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="voucher__wrapper">
                    <div :class="'voucher__layout--image'">
                      <div :class="['voucher__layout--image-overlay-wrapper']">
                        <input
                          hidden
                          type="file"
                          id="file_upload_paper"
                          name="file_upload_paper"
                          @change="
                            e => addFile('paperImageUrl', 'paperImageKey', e)
                          " />
                        <base-button
                          @click="chooseFile('paper')"
                          :text="$t('upload')"
                          class="button--has-icon"
                          :button-size-small="true">
                          <use-svg svg="export-icon" />
                        </base-button>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <template v-if="voucher.digitalImageUrl">
                  <div class="voucher__horizontal-size">
                    &lt;---- 576 px ----&gt;
                  </div>
                  <div class="voucher__wrapper-flex">
                    <span class="voucher__vertical-size">
                      &lt;---- 720 px ----&gt;</span>
                    <div class="voucher__wrapper">
                      <div class="voucher__layout--image image-digital">
                        <div
                          @mouseover="hoverOn('digital')"
                          @mouseleave="hoverOn('')"
                          :class="['voucher__layout--image-overlay-wrapper']">
                          <template v-if="hover === 'digital'">
                            <base-button
                              :text="$t('remove')"
                              class="button--has-icon"
                              :button-size-small="true"
                              @click="removeImage('digital')">
                              <use-svg svg="trash-icon" />
                            </base-button>
                            <input
                              hidden
                              type="file"
                              id="file_upload_digital"
                              name="file_upload_digital"
                              @change="
                                e =>
                                  addFile(
                                    'digitalImageUrl',
                                    'digitalImageKey',
                                    e
                                  )
                              " />
                            <base-button
                              @click="chooseFile('digital')"
                              :text="$t('upload')"
                              class="button--has-icon"
                              :button-size-small="true">
                              <use-svg svg="export-icon" />
                            </base-button>
                          </template>
                        </div>
                        <img
                          :src="
                            voucher.digitalImageKey
                              ? voucher.digitalImageUrl +
                                voucher.digitalImageKey
                              : voucher.digitalImageUrl
                          " />
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="voucher__wrapper">
                    <div :class="'voucher__layout--image'">
                      <div :class="['voucher__layout--image-overlay-wrapper']">
                        <input
                          hidden
                          type="file"
                          id="file_upload_digital"
                          name="file_upload_digital"
                          @change="
                            e =>
                              addFile('digitalImageUrl', 'digitalImageKey', e)
                          " />
                        <base-button
                          @click="chooseFile('digital')"
                          :text="$t('upload')"
                          class="button--has-icon"
                          :button-size-small="true">
                          <use-svg svg="export-icon" />
                        </base-button>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <div class="voucher__result--action">
              <!-- TODO: Fix test print -->
              <modal-window :show="showTestPrintModal">
                <div class="modal__header">
                  {{ $t('testPrint') }}
                </div>
                <div class="modal__content">
                  <!-- v-if="selectedIndexPrinter !== undefined" -->
                  <custom-select
                    :required="false"
                    @optionSelected="setPrinter"
                    :selected="selectedIndexPrinter"
                    :options="printers" />
                </div>
                <div class="modal__content--actions">
                  <base-button
                    class="button--background-gray-dark"
                    :text="$t('cancel')"
                    @click="toggleTestPrintModal">
                  </base-button>
                  <base-button
                    :text="$t('testPrint')"
                    @click="testPrint" />
                </div>
              </modal-window>
            </div>
          </div>
        </div>
        <div class="voucher__right">
          <div class="voucher__settings">
            <template v-if="!digitalVoucher">
              <div
                class="input"
                @mouseover="hoverOn('upper')"
                @mouseleave="hoverOn('')">
                <div class="label-container">
                  <label
                    for="voucher-upperText"
                    class="input__label first-letter">
                    {{ $t('dynamic text upper') }}
                  </label>
                  <div
                    class="info-svg-container"
                    @click="toggleShowInfobox">
                    <use-svg svg="help-icon" />
                  </div>
                  <div
                    v-if="showInfobox"
                    class="info-box">
                    <div>
                      <button @click="toggleShowInfobox">stäng</button>
                    </div>
                    Ange en dynamisk text, till exempel en hälsning till
                    spelaren eller dagens tid och datum. Taggarna
                    <span v-if="typeName !== 'neutral'">[Name],</span> [Time]
                    och/eller [Date] finns att använda sig utav.
                  </div>
                </div>
                <input
                  type="text"
                  name="voucher-upperText"
                  id="voucher-upperText"
                  class="input__input"
                  required
                  v-model="voucher.upperText" />
              </div>
              <div
                class="input"
                @mouseover="hoverOn('lower')"
                @mouseleave="hoverOn('')">
                <div class="label-container">
                  <label
                    for="voucher-lowerText"
                    class="input__label first-letter">
                    {{ $t('dynamic text lower') }}
                  </label>
                  <div
                    class="info-svg-container"
                    @click="toggleShowInfobox2">
                    <use-svg svg="help-icon" />
                  </div>
                  <div
                    v-if="showInfobox2"
                    class="info-box">
                    <div>
                      <button @click="toggleShowInfobox2">stäng</button>
                    </div>
                    Ange en dynamisk text, till exempel en hälsning till
                    spelaren eller dagens tid och datum. Taggarna [Name], [Time]
                    och/eller [Date] finns att använda sig utav.
                  </div>
                </div>
                <input
                  type="text"
                  name="voucher-lowerText"
                  id="voucher-lowerText"
                  class="input__input"
                  required
                  v-model="voucher.lowerText" />
              </div>
            </template>
            <template v-else>
              <div class="input">
                <label
                  for="voucher-webLink"
                  class="input__label first-letter">
                  {{ $t('webLink') }}
                  <use-svg svg="help-icon" />
                </label>
                <input
                  type="text"
                  name="voucher-webLink"
                  id="voucher-webLink"
                  class="input__input"
                  required
                  v-model="voucher.webLink" />
              </div>
              <div class="input-group">
                <label
                  for="showOnSharedScorecard"
                  class="input__label">Visa vid delning av scorekort</label>
                <toggle-switch
                  label="Active"
                  input-id="showOnSharedScorecard"
                  type="switch"
                  :toggled="voucher.showOnSharedScorecard"
                  @toggle="setShowOnSharedScorecard" />
              </div>
            </template>
          </div>
          <div class="voucher__actions">
            <base-button
              :text="$t('cancel')"
              class="button--background-gray-dark"
              @click="$router.push({ name: `${typeName}-voucher` })" />
            <base-button
              v-if="id"
              :text="$t('save')"
              v-element-loader="{ loaderKey: 'voucher-edit-voucher' }"
              @click="saveVoucher"
              :disabled="!canCreate" />
            <base-button
              v-else
              :text="$t('create')"
              v-element-loader="{ loaderKey: 'voucher-create-voucher' }"
              @click="createVoucher"
              :disabled="!canCreate" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import ModalWindow from '@/components/modal/modal';
import UseSvg from '@/components/use-svg';
import elementLoader from '@/directives/element-loader';
import ToggleSwitch from '@/components/form-elements/switch';

export default {
  name: 'VoucherEdit',
  components: {
    BaseButton,
    CustomSelect,
    ModalWindow,
    UseSvg,
    ToggleSwitch,
    // AddableDateFilter
  },
  props: {
    type: Number,
    typeName: String,
    id: String,
    copyOfVoucher: Object,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      showInfobox: false,
      showInfobox2: false,
      choosenTemplate: '',
      digitalVoucher: false,
      hover: false,
      alternativeLayout: false,
      showTestPrintModal: false,
      selectedIndexPrinter: 0,
      selectedPrinter: '',
      selectedTemplateIndex: undefined,
      localCopyOfVoucher: this.copyOfVoucher,
    };
  },
  methods: {
    toggleShowInfobox() {
      this.showInfobox = !this.showInfobox;
      if (this.showInfobox2 === true) {
        this.showInfobox2 = false;
      }
    },
    toggleShowInfobox2() {
      this.showInfobox2 = !this.showInfobox2;
      if (this.showInfobox === true) {
        this.showInfobox = false;
      }
    },
    toggleTestPrintModal() {
      this.showTestPrintModal = !this.showTestPrintModal;
    },
    hoverOn(element) {
      this.hover = element;
    },
    chooseFile(value) {
      if (value === 'paper') {
        document.getElementById('file_upload_paper').click();
      }
      if (value === 'digital') {
        document.getElementById('file_upload_digital').click();
      }
    },
    addFile(value, key, event) {
      let image;
      if (value === 'paperImageUrl') {
        image = event.target.files[0];
        if (image) {
          this.$store.dispatch('voucher/addImage', {
            image: image,
            box: value,
            key: key,
            type: 'paper',
          });
        }
      }
      if (value === 'digitalImageUrl') {
        image = event.target.files[0];
        if (image) {
          this.$store.dispatch('voucher/addImage', {
            image: image,
            box: value,
            key: key,
            type: 'digital',
          });
        }
      }
    },
    setShowOnSharedScorecard(boolean) {
      this.voucher.showOnSharedScorecard = boolean;
    },
    setPrinter(printer) {
      this.selectedPrinter = printer.value;
    },
    testPrint() {
      if (this.type === 3) {
        this.$store.dispatch('printers/printNeutralVoucher', {
          course: this.course,
        });
      }
    },
    createVoucher() {
      this.voucher.type = this.type;
      this.$store
        .dispatch('voucher/createVoucher', {
          type: this.voucher.type,
          name: this.voucher.name,
          paperImageUrl: this.voucher.paperImageUrl,
          paperImageKey: this.voucher.paperImageKey,
          digitalImageUrl: this.voucher.digitalImageUrl,
          digitalImageKey: this.voucher.digitalImageKey,
          upperText: this.voucher.upperText,
          lowerText: this.voucher.lowerText,
          webLink: this.voucher.webLink,
          showOnSharedScorecard: this.voucher.showOnSharedScorecard,
          courseIds: [],
          competitionIds: [],
          categories: [],
        })
        .then(() => {
          this.$router.push({ name: `${this.typeName}-voucher` });
          this.$toasted.success(this.$t('voucherCreated'));
          this.$store.dispatch('voucher/fetchVouchers', {
            type: this.type,
            search: '',
          });
        });
    },
    saveVoucher() {
      let categories = this.voucher.competitionCategories.map(
        category => category.value
      );
      if (this.voucher.categories) {
        categories = [...this.voucher.categories];
      }
      let competitionIds = this.voucher.competitions.map(
        competition => competition.value
      );
      if (this.voucher.competitionIds) {
        competitionIds = [...this.voucher.competitionIds];
      }
      this.$store
        .dispatch('voucher/editVoucher', {
          id: this.id,
          type: this.voucher.type,
          name: this.voucher.name,
          paperImageUrl: this.voucher.paperImageUrl,
          paperImageKey: this.voucher.paperImageKey,
          digitalImageUrl: this.voucher.digitalImageUrl,
          digitalImageKey: this.voucher.digitalImageKey,
          upperText: this.voucher.upperText,
          lowerText: this.voucher.lowerText,
          webLink: this.voucher.webLink,
          showOnSharedScorecard: this.voucher.showOnSharedScorecard,
          courseIds: this.voucher.courses.map(course => course.value),
          competitionIds: competitionIds,
          categories: categories,
        })
        .then(() => {
          this.$router.push({ name: `${this.typeName}-voucher` });
          this.$toasted.success(this.$t('voucherSaved'));
        });
    },
  },
  computed: {
    voucher: {
      get() {
        return this.$store.getters['voucher/voucher'];
      },
      set(val) {
        return val;
      },
    },
    canCreate() {
      return this.voucher.name;
    },
    printers() {
      return this.$store.getters['printers/printers'].rows.map(printer => {
        return {
          value: printer.id,
          displayName: printer.printerName,
        };
      });
    },
  },
  mounted() {
    if (this.id) {
      this.$store.commit('voucher/copyVoucher', this.id);
      if (this.$store.getters['voucher/vouchers'].length === 0) {
        this.$store.dispatch('voucher/fetchVoucher', { id: this.id });
      }
    } else {
      this.$store.commit('voucher/voucher', {});
    }
    if (!this.id && this.localCopyOfVoucher) {
      this.voucher.name = this.localCopyOfVoucher.name;
      this.voucher.paperImageUrl = this.localCopyOfVoucher.paperImageUrl;
      this.voucher.paperImageKey = this.localCopyOfVoucher.paperImageKey;
      this.voucher.digitalImageUrl = this.localCopyOfVoucher.digitalImageUrl;
      this.voucher.digitalImageKey = this.localCopyOfVoucher.digitalImageKey;
      this.voucher.upperText = this.localCopyOfVoucher.upperText;
      this.voucher.lowerText = this.localCopyOfVoucher.lowerText;
      this.voucher.webLink = this.localCopyOfVoucher.webLink;
    }
  },
  beforeDestroy() {
    this.voucher = {};
  },
};
</script>
<style scoped lang="scss">
.input .input__label {
  width: 160px;
}

.label-container {
  display: flex;
  flex-direction: row;
  position: relative;

  .info-svg-container {
    cursor: pointer;
  }
}
.info-box {
  background-color: rgb(189, 189, 189);
  border: 1px solid color(gray);
  border-radius: 5px;
  padding: 5px;
  z-index: 1;
  position: absolute;
  max-width: 300px;
  box-shadow: 1px 1px 5px;
  bottom: 20px;
  left: 200px;
  button {
    border: none;
    margin: 0px 0px 10px auto;
    font-size: 12px;
    background-color: transparent;
    justify-content: right;
    display: flex;
    z-index: 2;
  }
}
.voucher__edit {
  display: flex;
}
.voucher__left,
.voucher__right {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.voucher__right {
  margin-left: 2rem;
  margin-top: 5.7rem;
}
.voucher__edit--top {
  display: flex;
  width: 100%;
}
.voucher__name {
  width: 60%;
}
.voucher__tabs {
  display: flex;
  margin-top: auto;
  margin-left: auto;
  margin-right: 0.5rem;
}
.voucher__tab {
  @include reset-button;
  background-color: color(gray-light);
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  font-size: font(tiny);
  height: 4.125rem;
  margin-top: auto;
  padding: 0.5rem;
  text-align: center;
  &:first-child {
    margin-right: 0.5rem;
  }
  &.voucher__tab--on {
    background-color: color(gray-lighter);
    border-color: color(gray-light);
    border-style: solid;
    border-width: 2px 2px 0 2px;
    height: 4.5rem;
  }
  img {
    height: 1.625rem;
    margin-bottom: 0.5rem;
  }
}
.voucher__layout--text {
  overflow: hidden;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  text-align: center;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0);
  // margin: 25px 0px;
  z-index: 5;
}
.voucher__layout--text_hover {
  background-color: white;
  border-color: black;
}
.voucher__layout--text_upper {
  top: 0px;
  font-size: 0.6vw;
}
.voucher__layout--text_lower {
  bottom: 0px;
  font-size: 0.6vw;
}
.voucher__result--image {
  background-color: color(gray);
  padding: 2rem 3rem;
  position: relative;
  border-radius: 0.125rem;
  // height: 41.0625rem;
  img {
    height: 100%;
    width: 100%;
  }
  .voucher__result--image__overlay {
    background-color: rgba(172, 172, 172, 0.9);
    width: 19.5rem;
    height: 48.625rem;
    position: absolute;
    z-index: 1;
  }
  .voucher__result--customized-wrapper {
    position: absolute;
    display: flex;
    left: 3.75rem;
    right: 3.75rem;
    top: 1.25rem;
    justify-content: space-evenly;

    &.alternative-layout {
      right: initial;
      justify-content: flex-start;
      width: 33%;
    }

    .voucher__result--customized-image {
      width: 100%;
      height: 5rem;
      position: relative;
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      &.highlight {
        background-color: color(white);
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
      }
    }
  }
  .voucher__result--info-wrapper {
    position: absolute;
    top: 6.25rem;
    width: 19.5rem;

    &.alternative-layout {
      top: initial;
      bottom: 1.5rem;
      width: 19.5rem;
    }

    .voucher__result--info-header {
      text-align: center;
      font-size: 14px;
      &.highlight {
        background-color: white;
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
        position: absolute;
        width: 100%;
      }
    }
    .voucher__result--info-text {
      width: 48%;
      padding: 0 0.3125rem;
      word-break: break-word;
      font-size: 0.59375rem;
      resize: none;
      overflow: hidden;
      border: none;
      height: 150px;
      &.highlight {
        background-color: white;
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
        position: absolute;
        width: 50%;
      }
    }
  }
}
.voucher__result--action {
  display: flex;
  margin-top: 0.5rem;
  button {
    margin-left: auto;
  }
}
.voucher__settings {
  width: 100%;
}
.voucher__layout {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  @include media('to-medium') {
    display: block;
  }
}
.voucher-switch {
  display: flex;
  margin: 2rem 0;
  .input__label {
    flex-basis: 35%;
  }
}

.voucher__vertical-size {
  writing-mode: vertical-rl;
  font-size: 20px;
  margin: auto 0px;
  margin-left: auto;
  transform: rotate(180deg);
}

.voucher__horizontal-size {
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  left: +20px;
}

@media only screen and (max-width: 900px) {
  .voucher__horizontal-size,
  .voucher__vertical-size {
    font-size: 14px;
  }
}

.voucher__wrapper-flex {
  display: flex;
  flex-direction: row;
  // height: auto;
}
.voucher__wrapper {
  max-height: 500px;
  height: auto;
  // margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  aspect-ratio: 4/5;
  border: 1px dotted black;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  @include media('to-medium') {
    width: 100%;
  }
  p {
    margin: 0 0 0.5rem 0;
    @include media('to-medium') {
      margin: 0.5rem 0;
    }
  }
}
.voucher__layout--image {
  display: inline-block;
  position: relative;
  background-position-x: 2rem;
  img {
    display: block;
    max-height: 35rem;
    max-width: 100%;
    width: auto;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .voucher__layout--image-overlay-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    button {
      margin-right: 0.3rem;
      padding: 0 0.5rem;
    }
  }
}
.voucher__layout--image-overlay {
  background-color: rgba(249, 249, 249, 0.5);
}
.voucher__layout--info {
  display: flex;
  justify-content: space-between;
}
input#voucher-header {
  width: 100%;
}
.voucher__connections {
  display: flex;
  justify-content: flex-start;
  min-height: 15rem;
  border-top: 1px solid color(gray-dark);
  > div {
    flex: 1 1 auto;
    margin: 0 1.25rem 0.625rem 0;
    padding-top: 0.625rem;
    &:nth-child(2) {
      padding-left: 1.25rem;
      border-left: 1px solid color(gray-dark);
    }
  }
}
.voucher__actions {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 1rem;
  }
}
</style>
