<template>
  <div class="legend">
    <div
      class="year"
      v-for="year in years"
      :key="year.year">
      <div class="row bold">
        <div>
          <use-svg
            svg="bb-arrow-icon"
            :class="{ 'svg--180-negative': year.value < 0 }" />
        </div>
        <div>
          <template v-if="year.percentage > 0">+</template>
          {{ year.percentage.toLocaleString() }}% /
          <template v-if="year.value > 0">+</template>
          {{ year.value.toLocaleString('sv-SE') }} {{ unit }}
        </div>
      </div>
      <div class="row regular">
        <div></div>
        <div>{{ $t('comparedTo') }} {{ year.year }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '../../../mixins/localization-mixin.vue';

export default {
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
  },
  props: {
    years: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: function () {
        this.$t('denomination');
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.legend {
  margin: -1rem 1rem 0 1rem;
  .bold {
    color: #131516;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
  }

  .regular {
    color: #131516;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  .year {
    margin: 0.6rem auto;
    font-size: 14px;
    line-height: 18px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 50%));
    flex-wrap: wrap;
    border-bottom: 1px solid #dadedf;
    padding: 1rem;
    justify-content: space-between;
    .row {
      display: grid;
      grid-template-columns: 2rem auto;
      grid-gap: 0.5rem;
      white-space: nowrap;
      svg {
        display: block;
        margin: auto;
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
