<template>
  <div class="piechart-wrapper">
    <pie-chart
      style="width: 55%"
      :data="data"
      :labels="labels"
      :percentage="percentage"
      :borderWidth="borderWidth"
      :colors="this.colors"
      :unitTranslationKey="unitTranslationKey" />
    <legend-pie
      :labels="labels"
      :colors="this.colors" />
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

import PieChart from '@/components/charts/pie-chart';
import LegendPie from '@/components/charts/legend-pie';
import { COLORS } from '@/components/charts/pie-chart-colors';

export default {
  name: 'GolfOfficeFrontEndPieChartWithLegend',

  mixins: [LocalizationMixin],

  components: { PieChart, LegendPie },

  props: {
    labels: VueTypes.arrayOf(VueTypes.string).isRequired,
    data: VueTypes.arrayOf(VueTypes.number).isRequired,
    unitTranslationKey: VueTypes.string.def('piece'),
    percentage: VueTypes.arrayOf(VueTypes.number),
    borderWidth: VueTypes.number.def(1),
    colors: VueTypes.arrayOf(VueTypes.string).def(COLORS),
    legend: VueTypes.shape({
      position: VueTypes.string,
      labels: VueTypes.shape({ usePointStyle: VueTypes.bool }),
    }).def(undefined),
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.piechart-wrapper {
  display: flex;
}
</style>
