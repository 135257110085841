<script>
import { Pie } from 'vue-chartjs';

export default {
  extends: Pie,
  props: {
    labels: {
      type: Array,
      default: () => { return []; },
    },
    data: {
      type: Object,
      default: () => {},
    },
    tooltips: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      chartdata: {
        labels: this.labels,
        datasets: [{
          data: this.data.currentYear,
          tooltips: this.tooltips.currentYear,
          backgroundColor: ['#4B2D87', '#133846', '#F18565', '#24655D', '#C4DDE0', '#FDEADC', '#F18565'],
        }],
      },
      options: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var percentage = parseFloat((dataset.data[tooltipItem.index] / dataset._meta[Object.keys(dataset._meta)[0]].total * 100).toFixed(0));
              return percentage + '% ' + data.labels[tooltipItem.index] + ': ' + dataset.tooltips[tooltipItem.index];
            },
          },
        },
        maintainAspectRatio: false,
      },
    };
  },
  mounted () {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
