<template>
  <div class="ontag">
    <div class="ontag--content">
      <h1 class="first-letter">{{ $t('hole sponsors') }}</h1>
      <div style="position: relative">
        Skapa mervärde för sponsorer och låt dem synas med klickbar logga i ontag appen.
        <div
          class="info-svg-container"
          :title="$t('OnTag.help')"
          @click="showInfoBox = !showInfoBox">
            <use-svg
            svg="help-icon" />
          </div>
          <div
          v-if="showInfoBox"
          class="info-box">
            <div>
              <button @click="showInfoBox = false">
                stäng
              </button>
            </div>
            <h4>För att lägga till en bild/logga.</h4>
        Välj slinga och hål ni vill koppla loggan till.<br/>
        Klicka på <strong>Välj fil-knappen</strong> för att välja logga från dator.<br/>
        För bästa resultat.<br/>
        <em>Storlek B 410 H 148px.<br/>
        Filformat jpg, png, tif</em>
        <h4>Gör bild/logga klickbar.</h4>
        Genom att ange en webbadress blir loggan klickbar i appen.<br/>
        Länken öppnas i ett nytt fönster.<br/>
        Format på webbadress.<br/>
        https://www.ontagscorecard.com/<br/>
        <h4>Återgå till standardlogga</h4>
        För att återgå till att använda GolfOffice standard logga, markera de hål ni vill ändra och klickar sedan på Använd standardlogga-knappen.
        <p>
          Glöm inte klicka på <strong>Spara</strong> efter ändring.
        </p>
          </div>

      </div>
      <section class="holesponsors">
        <div class="holesponsors__list" v-if="holesponsors.length > 0">
          <holesponsors-list-table
            :items="holesponsors"/>
        </div>
      </section>
      <div class="holesponsor__actions sticky sticky__bottom">
        <base-button
          :text="$t('save')"
          v-element-loader="{ loaderKey: 'holesponsors-save-holesponsors' }"
          @click="save" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import HolesponsorsListTable from '@/components/holesponsors-list-table';
import elementLoader from '@/directives/element-loader';
import UseSvg from '@/components/use-svg';

export default {
  name: 'HoleSponsors',
  components: {
    BaseButton,
    HolesponsorsListTable,
    UseSvg,
  },
  directives: {
    elementLoader,
  },
  data() {
    return{
      showInfoBox: false,
    };
  },
  methods: {
    save () {
      this.$store.dispatch('holesponsors/save', this.holesponsors).then((data) => {
        if (data === true) {
          this.$toasted.success(this.$t('holesponsorsSaved'));
        } else {

        }
      });
    },
  },
  computed: {
    holesponsors () {
      return this.$store.getters['holesponsors/holesponsors'];
    },
  },
  created() {
    this.$store.dispatch('holesponsors/fetchHolesponsors');
  },
};
</script>
<style lang="scss" scoped>
summary {
  text-decoration: underline;
  cursor: pointer;
}
details {
  margin: 2rem 0;
}
.info-box {
  bottom: unset;
  left: unset;
  max-width: 400px;
}
</style>
