<template>
  <div class="legend">
    <div
      class="year"
      v-for="year in years"
      :key="year.year">
      <div class="row">
        <div>
          <use-svg
            svg="bb-arrow-icon"
            :class="{ 'svg--180-negative': year.value < 0 }" />
        </div>
        <div>
          <template v-if="year.value > 0">+</template>{{ year.value.toLocaleString() }} {{ unit }} /
          <template v-if="year.percentage > 0">+</template>{{ year.percentage.toLocaleString() }}%
        </div>
      </div>
      <div class="row">
        <div>
          <use-svg
            v-if="year.year == normalYear"
            svg="bb-star-icon"
            color="#F08565" />
        </div>
        <div>{{ $t('comparedTo') }} {{ year.year }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
  },
  props: {
    years: {
      type: Array,
      default: () => [],
    },
    normalYear: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: function () {
        this.$t('denomination');
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.legend {
  .year {
    margin: 0.6rem auto;
    font-size: 14px;
    line-height: 20px;
    justify-content: space-between;
    .row {
      display: grid;
      grid-template-columns: 2rem auto;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
}
</style>
