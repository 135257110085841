<template>
  <div class="addable-custom-select">
    <label class="addable-custom-select__label input__label">{{label}}</label>
    <div class="addable-custom-select--selected" v-if="localSelected.length > 0">
      <template>
        <div class="addable-custom-select--selected-wrapper" v-for="(s, index) in localSelected" :key="index">
          <div class="addable-custom-select--selected_item">{{s.displayName}}</div>
          <div class="addable-custom-select--remove" @click="toggleRemoveModal(s)">
            <use-svg
              svg="trash-icon" />
          </div>
        </div>
        <modal-window :show="showRemoveModal">
          <div class="modal__content">
            <h2>{{ $t('deleteConnection', { displayName: optionToRemove.displayName }) }}?</h2>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="toggleRemoveModal()">
            </base-button>
            <base-button
              :text="$t('yes')"
              @click="remove()"/>
          </div>
        </modal-window>
      </template>
    </div>
    <div
      v-for="(select, index) in localSelects"
      :key="index"
      class="input input--has-icon">
      <custom-select
        id="role"
        @optionSelected="e => selectOption(e, index)"
        :required="false"
        :options="select">
      </custom-select>
      <div class="addable-custom-select--remove" @click="removeLocalSelect(index)">
        <use-svg
          svg="trash-icon" />
      </div>
      <modal-window :show="isConnected">
        <div class="modal__header">
          {{ $t('courseConnected') }}
        </div>
        <div class="modal__content">
          <p>{{ $t('courseConnectedWarning') }}?</p>
        </div>
        <div class="modal__content--actions">
          <base-button
            class="button--background-gray-dark"
            :text="$t('cancel')"
            @click="closeConnectedModal(optionToAdd)">
          </base-button>
          <base-button
            :text="$t('addCourse')"
            @click="emitOption(optionToAdd)"/>
        </div>
      </modal-window>
    </div>
    <base-button
      @click="addCustomSelect"
      :text="$t('add')"
      class="button--has-icon addable-custom-select__button"
      :button-size-small="true"
      :disabled="isDisabled">
      <use-svg svg="plus-icon" />
    </base-button>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import ModalWindow from '@/components/modal/modal';

export default {
  name: 'AddableCustomSelect',
  components: {
    BaseButton,
    ModalWindow,
    CustomSelect,
    UseSvg,
  },
  props: {
    options: {
      type: Array,
    },
    label: String,
    selected: {
      type: Array,
    },
  },
  data() {
    return {
      showRemoveModal: false,
      localOptions: this.options,
      localSelects: [],
      localSelected: this.selected,
      optionToRemove: {},
      optionToAdd: {},
      isConnected: false,
      close: false,
    };
  },
  methods: {
    addCustomSelect () {
      this.localSelects.push(this.localOptions);
    },
    selectOption (e, index) {
      if (e && e.informationText.length > 0) {
        this.showConnectedModal(e);
      } else {
        this.emitOption(e, index);
      }
    },
    emitOption (selects, index) {
      if(selects.length > 0) {
        this.localSelected.push({
          'value': this.optionToAdd.value,
          'displayName': this.optionToAdd.displayName,
          'informationText': this.optionToAdd.informationText,
        });
        this.localOptions = this.localOptions.filter(option => option.displayName !== this.optionToAdd.displayName);
      } else {
        this.localSelected.push({
          'value': selects.value,
          'displayName': selects.displayName,
          'informationText': selects.informationText,
        });
        this.localOptions = this.localOptions.filter(option => option.displayName !== selects.displayName);
      }
      this.localSelects.splice(index, 1);
      this.isConnected = false;
      this.$emit('selectedConnections', this.localSelected);
    },
    remove() {
      this.localOptions.push(this.optionToRemove);
      this.localSelected = this.localSelected.filter(sel => sel.displayName !== this.optionToRemove.displayName);
      this.$emit('selectedConnections', this.localSelected);
      this.toggleRemoveModal();
      this.optionToRemove = {};
    },
    removeLocalSelect(index) {
      this.localSelects.splice(index, 1);
    },
    toggleRemoveModal(s) {
      if(s) {
        this.optionToRemove = s;
      }
      this.showRemoveModal = !this.showRemoveModal;
    },
    showConnectedModal(s) {
      this.isConnected = true;
      this.close = false;
      if (s) {
        this.optionToAdd = s;
      } else {
        this.optionToAdd = {};
      }
    },
    closeConnectedModal() {
      let removeUnUsedSelectIndex = this.localSelects[0].findIndex(sel => sel.displayName === this.optionToAdd.displayName);
      this.removeLocalSelect(removeUnUsedSelectIndex);
      this.close = true;
      this.isConnected = false;
    },
  },
  computed: {
    isDisabled() {
      return this.localOptions.length === 0;
    },
  },
  watch: {
    selected: function(newVal, oldVal) {
      if(newVal) {
        this.localSelected = this.selected;
        if (this.localSelected.length === 0) {
          this.localOptions = this.options;
        } else {
          if (this.localSelected.length !== this.options.length) {
            this.localSelected.map(select => {
              if (this.localOptions.length === 0) {
                this.localOptions = this.options.filter(option => option.value !== select.value);
              } else {
                this.localOptions = this.localOptions.filter(option => option.value !== select.value);
              }
            });
          }
        }
      }
    },
  },
  mounted() {
    // If we already have selected, filter out these from localOptions
    if (this.localSelected.length > 0) {
      this.localSelected.map(select => {
        this.localOptions = this.localOptions.filter(option => option.value !== select.value);
      });
    }
  },
};
</script>
<style lang="scss">
.addable-custom-select {
  .input {
    margin-bottom: 0.5rem;
    .select__wrapper {
      width: 100%;
    }
  }
}
.addable-custom-select__label {
  display: block;
  padding: 0.2rem;
}
.addable-custom-select--selected-wrapper {
  display: flex;
  align-items: center;
}
.addable-custom-select--selected_item {
    border: 0.0625rem solid #D8D8D8;
    border-radius: 0.125rem;
    background-color: #F9F9F9;
    padding: 0.375rem 0.375rem 0.375rem 0.9375rem;
    width: 95.5%;
    margin-right: 0.6rem;
    margin-bottom: 0.5rem;
}
.addable-custom-select--remove {
  cursor: pointer;
  color: color(gray);
}
.addable-custom-select__button {
  margin-top: 1rem;
  stroke: color(white);
}
</style>
