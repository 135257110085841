<template>
  <div class="wrapper">
    <div>
      <p class="info-text">Välj kolumner</p>
      <p class="info-text">Öka / minska</p>
    </div>
    <div
      v-for="(fee, index) in feesReference"
      :key="index + 'fee'">
      <div>
        <modify-field-using-input :fee="fee" />
      </div>
    </div>
    <div class="display-flex">
      <div
        class="select-checkbox"
        @click="
          e => {
            displayMemberAmount = !displayMemberAmount;
            resetMemberQuantity();
          }
        ">
        <use-svg
          v-if="displayMemberAmount"
          svg="bb-selected-icon" />
      </div>
      <label
        class="member-label"
        style="margin-right: 0.3rem">
        Antal medlemmar</label>
      <info-icon description="Endast aktiva åldersgrupper kopieras">
        <template #icon>
          <use-svg svg="bb-info-icon" />
        </template>
      </info-icon>
    </div>
    <div>
      <div>
        <modify-field-using-radio
          v-if="displayMemberAmount"
          :display-modify-field="displayMemberModifyField" />
      </div>
    </div>
  </div>
</template>

<script>
import modifyFieldUsingInput from './modifyFieldUsingInput.vue';
import ModifyFieldUsingRadio from './modify-field-using-radio.vue';
import UseSvg from '@/components/use-svg';
import InfoIcon from '@/components/businessboard/tooltip-icon';

export default {
  components: {
    modifyFieldUsingInput,
    ModifyFieldUsingRadio,
    UseSvg,
    InfoIcon,
  },
  props: {
    feesReference: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      displayMemberAmount: false,
      displayMemberModifyField: false,
    };
  },

  computed: {},
  mounted() {},
  methods: {
    resetMemberQuantity() {
      let year = this.$store.state.businessboard.membershipTypeYears.years.find(
        y =>
          y.year === this.$store.state.businessboard.membershipTypeYearCopy.year
      );
      this.$store.state.businessboard.membershipTypeYearCopy = {
        ...year,
        types: year.types.map(type => ({
          ...type,
          quantity: (type['quantityBudgeted'] || 0).toFixed(0),
          ageGroups: type.ageGroups.map(ageGrp => ({
            ...ageGrp,
            quantity: (ageGrp['quantityBudgeted'] || 0).toFixed(0),
          })),
        })),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-left: 0.5rem;
  background-color: #fff;
  height: auto;
}

.member-label {
  margin-left: 0.5rem;
}
.info-text {
  display: inline-flex;
  font-weight: 600;
  color: #000;
}
.info-text:last-child {
  position: relative;
  float: right;
}
.select-checkbox {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 4px;
  margin-left: 0.3rem;
}

.display-flex {
  display: flex;
  margin-top: 2rem;
}
</style>
