<template>
  <div>
    <h4>
      {{ $t('MemberCalculation.addSlashRemoveFee') }}
    </h4>
    <div class="display-flex">
      <input
        class="input-style"
        id="feeColumnName"
        v-model="feeColumnName"
        name="feeColumnName"
        type="text" />
      <base-button
        backgroundColor="white"
        :text="$t('add')"
        @click="createFeeColumn()">
        <use-svg
          class="plus-icon"
          svg="plus-icon"
          stroke="black" />
      </base-button>
    </div>
    <div class="divider" />
    <template v-for="(fee, index) in membershipTypeYears.feeColumns">
      <div :key="index">
        <div class="border">
          <div class="display-flex">
            <p>
              <template v-if="fee.isEditing">
                <input v-model="fee.name" />
              </template>
              <template v-else>
                {{ fee.name }}
              </template>
            </p>
            <div class="display-flex-icons rounded">
              <div
                class="hovering edit-icon"
                @click="editFeeColumn(fee)">
                <use-svg
                  :svg="fee.isEditing ? 'plus-icon' : 'bb-edit-icon'"
                  color="white"
                  stroke="black" />
              </div>
              <div
                class="hovering delete-icon"
                @click="deleteFeeColumn(fee)">
                <use-svg
                  svg="bb-trash-icon"
                  color="white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'manage-fees',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    UseSvg,
  },
  props: {},
  data() {
    return {
      feeColumnName: '',
    };
  },
  computed: {
    membershipTypeYears() {
      return this.$store.getters['businessboard/membershipTypeYears'];
    },
  },

  methods: {
    createFeeColumn() {
      this.$store.dispatch('businessboard/createMembershipFeeColumn', {
        name: this.feeColumnName,
      });
      this.fetch();
    },
    editFeeColumn(fee) {
      if (fee.isEditing) {
        fee.isEditing = false;
        this.$store.dispatch('businessboard/patchMembershipFeeColumn', {
          id: fee.id,
          name: fee.name,
        });
        this.fetch();
      } else {
        this.$set(fee, 'isEditing', true);
      }
    },
    deleteFeeColumn(fee) {
      this.$store.dispatch('businessboard/deleteMembershipFeeColumn', {
        ...fee,
      });
      this.fetch();
    },
    fetch() {
      this.$store.dispatch('businessboard/fetchMembershipTypes');
    },
  },
};
</script>

<style lang="scss" scoped>
.display-flex {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;

  width: 100%;
}

.display-flex-icons {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;

  width: 100%;
}
.divider {
  margin-bottom: measure(spacing-l);
}
.border {
  border-top: solid 1px #dadedf;
  border-bottom: solid 1px #dadedf;
}

.input-style {
  border: solid 1px #000;

  height: 41px;
}

.rounded {
  padding: measure(spacing-xs);
  margin-left: auto;

  border-radius: 8px;
  border: solid 1px #c1c7c9;

  width: 20%;
  height: 30px;
}

.hovering {
  &:hover {
    border-radius: 4px;
    background-color: #eee;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  }
}
.edit-icon {
  position: relative;
  margin: 0 auto;
}
.delete-icon {
  position: relative;
  margin: 0 auto;
}

.plus-icon {
  width: 15px;
  height: 15px;
}
</style>
