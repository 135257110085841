<template>
  <div class="period_button">
    <modal-window
      :show="showModal"
      size="biggest">
      <div class="modal__header">
        <p>{{ $t('editBudgetView') }}</p>
      </div>
      <div class="add_column">
        <base-button
          :text="$t('addColumn')"
          @click="addColumn" />
      </div>
      <div class="table">
        <div
          class="column"
          v-for="(column, i) in columns"
          :key="i">
          <div class="radio__row">
            <div class="period_grid">
              <span
                @click="removeColumn(i)"
                style="float: right">
                <use-svg svg="trash-icon"></use-svg>
              </span>
              {{ $t('year') }}
              <div class="period_input">
                <custom-select
                  @optionSelected="e => selectYear(i, e)"
                  :selected="
                    financialYears.map(y => y.value).indexOf(column.yearValue)
                  "
                  :options="financialYears">
                </custom-select>
              </div>
              <template v-if="column.selectedYear">
                {{ $t('type') }}
                <div class="period_input">
                  <select
                    class="select"
                    v-model="column.type">
                    <option
                      v-if="column.selectedYear === currentYear"
                      value="forecast">
                      {{ $t('outcomeSlashForecast') }}
                    </option>
                    <option
                      v-if="column.selectedYear < currentYear"
                      value="not_budget">
                      {{ $t('outcome') }}
                    </option>
                    <option value="budget">
                      {{ $t('Businessboard.budget') }}
                    </option>
                    <option
                      v-if="column.selectedYear > currentYear"
                      value="forecast">
                      {{ $t('forecast') }}
                    </option>
                  </select>
                </div>
                <template v-if="column.type === 'budget'">
                  <div
                    v-for="company in companies"
                    :key="company.value">
                    {{ company.displayName }}
                    <custom-select
                      @optionSelected="e => selectBudget(i, company, e)"
                      :selected="
                        budgetOptions(company.value, column.yearValue)
                          .map(b => b.value)
                          .indexOf(column.budget[company.value])
                      "
                      :options="budgetOptions(company.value, column.yearValue)">
                    </custom-select>
                  </div>
                </template>
                <div class="period_input">{{ $t('period') }}</div>
                <div class="period_input">
                  <select
                    class="select"
                    v-model="column.period">
                    <option value="fiscal">{{ $t('fiscalYear') }}</option>
                    <option value="not_fiscal">
                      {{ $t('notFiscalYear') }}
                    </option>
                  </select>
                </div>

                <div
                  class="period_grid2"
                  v-if="column.period === 'not_fiscal'">
                  <div class="period_input">
                    {{ $t('Businessboard.yearStart') }}
                  </div>
                  <div class="period_input">
                    {{ $t('Businessboard.yearEnd') }}
                  </div>
                  <div class="period_input">
                    <custom-select
                      @optionSelected="e => selectStartMonth(i, e)"
                      :selected="column.startMonth - 1"
                      :options="settingsmonths">
                    </custom-select>
                  </div>
                  <div class="period_input">
                    <custom-select
                      @optionSelected="e => selectEndMonth(i, e)"
                      :selected="column.endMonth - 1"
                      :options="settingsmonths">
                    </custom-select>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="modal__content--actions">
        <base-button
          class="button--background-gray-dark"
          :text="$t('cancel')"
          @click="toggleModal" />
        <base-button
          :text="$t('show')"
          @click="changeFilter" />
        <base-button
          v-if="showSaveAndPublish"
          :text="$t('saveAndPublish')"
          @click="saveFilter"
          :disabled="!canSave" />
      </div>
    </modal-window>
    <base-button
      backgroundColor="bb-primary"
      :text="$t('show')"
      @click="toggleModal">
    </base-button>
  </div>
</template>

<script>
import ModalWindow from '@/components/modal/modal';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'Modal',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    ModalWindow,
    CustomSelect,
    UseSvg,
  },
  props: {
    companyId: {
      type: String,
      default: '0',
    },
    companies: {
      type: Array,
      default: () => [],
    },
    financialYears: {
      type: Array,
      default: () => [],
    },
    storedFilter: {
      type: Object,
    },
  },
  data() {
    return {
      showModal: false,
      defaultFilter: true,
      financialYearFilter: false,
      periodFilter: false,
      periodStart: 1,
      periodEnd: 12,
      typesSelect: {},
      columns: [
        {
          selectedYear: false,
          type: false,
          budget: {},
          period: false,
          startMonth: false,
          endMonth: false,
        },
      ],
    };
  },
  computed: {
    settingsmonths() {
      return this.$store.getters['businessboard/settingsmonths'];
    },
    companyBudgetSelect() {
      return this.$store.getters['businessboardBudget/companyBudgetSelect'];
    },
    companyForecastSelect() {
      return this.$store.getters['businessboardBudget/companyForecastSelect'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    filter() {
      return {
        columns: this.columns.map(c => ({
          year: c.yearValue,
          type: { budget: 1, not_budget: 2, forecast: 3 }[c.type],
          financialYearFilter: c.period === 'fiscal',
          periodFilter: c.period === 'not_fiscal',
          fromMonth: c.startMonth,
          toMonth: c.endMonth,
          companies:
            c.type === 'forecast'
              ? this.companies.reduce((result, company) => {
                  if (
                    this.forecastOptions(company.value, c.yearValue).length > 0
                  ) {
                    result.push({
                      companyId: company.value,
                      budgetId: this.forecastOptions(
                        company.value,
                        c.yearValue
                      )[0].value,
                    });
                    return result;
                  }
                }, [])
              : Object.entries(c.budget).map(([key, value]) => ({
                  companyId: key,
                  budgetId: value,
                })),
        })),
      };
    },
    canSave() {
      return true;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    showSaveAndPublish() {
      return !(
        this.user.roles.BusinessBoard &&
        this.user.roles.BusinessBoard.some(
          e => e.value === 'BusinessBoardBoardMember'
        )
      );
    },
  },
  mounted() {
    this.$store.dispatch('businessboard/fetchSettingsMonths');
    for (let company of this.companies) {
      this.$store.dispatch('businessboardBudget/fetchCompanyBudgetSelect', {
        id: company.value,
      });
      this.$store.dispatch('businessboardBudget/fetchCompanyForecastSelect', {
        id: company.value,
      });
    }
    let filter = this.storedFilter;
    if (filter && Object.keys(filter).length !== 0) {
      this.columns = [];
      for (let column of filter.columns) {
        let budget = {};
        for (let company of column.companies) {
          budget[company.companyId] = company.budgetId;
        }
        this.columns.push({
          yearValue: column.year,
          selectedYear: new Date(column.year.split(';')[1]).getFullYear(),
          type: ['', 'budget', 'not_budget', 'forecast'][column.type],
          budget: budget,
          period: column.financialYearFilter ? 'fiscal' : 'not_fiscal',
          startMonth: column.fromMonth,
          endMonth: column.toMonth,
        });
      }
    }
  },
  methods: {
    budgetOptions(companyValue, yearValue) {
      if (!this.companyBudgetSelect[companyValue]) return [];
      let year = this.financialYears.find(y => y.value === yearValue);
      if (year)
        return this.companyBudgetSelect[companyValue].filter(b =>
          b.displayName.endsWith(', ' + year.displayName)
        );
      return this.companyBudgetSelect[companyValue];
    },
    forecastOptions(companyValue, yearValue) {
      if (!this.companyForecastSelect[companyValue]) return [];
      let year = this.financialYears.find(y => y.value === yearValue);
      if (year)
        return this.companyForecastSelect[companyValue].filter(b =>
          b.displayName.endsWith(', ' + year.displayName)
        );
      return this.companyForecastSelect[companyValue];
    },
    addColumn() {
      if (this.columns.length >= 6) return;
      this.columns.push({
        selectedYear: false,
        yearValue: '',
        type: false,
        budget: {},
        period: false,
        startMonth: 1,
        endMonth: 12,
      });
    },
    removeColumn(i) {
      this.columns.splice(i, 1);
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    selectYear(i, e) {
      this.columns[i].yearValue = e.value;
      this.columns[i].selectedYear = new Date(
        e.value.split(';')[1]
      ).getFullYear();
    },
    selectBudget(i, c, e) {
      this.columns[i].budget[c.value] = e.value;
    },
    selectStartMonth(i, e) {
      this.columns[i].startMonth = e.value;
    },
    selectEndMonth(i, e) {
      this.columns[i].endMonth = e.value;
    },
    changeFilter() {
      this.toggleModal();
      this.$emit('changeFilter', this.filter);
    },
    saveFilter() {
      this.toggleModal();
      this.$emit('saveFilter', this.filter);
    },
  },
};
</script>
<style lang="scss" scoped>
.period_grid {
  margin-top: 0.5rem;
  justify-content: center;
  height: 30rem;
  overflow-y: scroll;
}
.period_grid2 {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 10rem 10rem;
  justify-content: center;
  height: 4rem;
}
.period_input {
  padding: 0 0.5rem 0 0.5rem;
  input,
  select {
    background-color: #f9f9f9;
    border-radius: 2px;
    padding: 0.3rem;
    border: 1px solid #d8d8d8;
  }
}
.period_button {
  margin: 0 0 1rem auto;
  display: flex;
  justify-content: flex-end;
}
.radio__row {
  padding: 0.5rem;
  margin: 0.5rem auto 0.5rem auto;
  text-align: left;
}

.table {
  display: grid;
  grid-template-columns: repeat(6, auto);
  overflow-x: scroll;
}
.column {
  width: 25rem;
}
.add_column {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}
</style>
