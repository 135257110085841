<template>
  <div class="chart_settings">
    <div
      v-if="showModal"
      class="chart_settings_modal">
      <div class="header">
        <span>{{ $t('settingsForTemplates') }}</span>
      </div>
      <div class="columns">
        <hr />
      </div>
      <div class="modal__content--actions">
        <div
          :class="['course', { course_selected: showHeaders }]"
          @click="toggleDiagramHeader()">
          <div class="course_checkbox">
            <use-svg
              v-show="showHeaders"
              svg="bb-selected-icon" />
          </div>
          <p>{{ $t('showTitle') }}</p>
        </div>
        <div class="header">
          <span>{{ $t('imageFormat') }}</span>
          <info-icon
            description="Välj JPG för vit bakgrund, PNG för genomskinlig bakgrund">
            <template #icon>
              <use-svg svg="bb-info-icon" />
            </template>
          </info-icon>
        </div>
        <hr />
        <div
          v-for="(format, index) in formats"
          :key="format"
          :class="['course', { course_selected: fileFormat == index }]"
          @click="changePictureFormat(index)">
          <div class="course_checkbox">
            <use-svg
              v-show="fileFormat == index ? true : false"
              svg="bb-selected-icon" />
          </div>
          <p>{{ format }}</p>
        </div>

        <br />
        <button
          class="btn_primary"
          v-if="showSaveAndPublish"
          @click="saveFilter">
          {{ $t('saveAndPublish') }}
        </button>
        <button @click="toggleModal">{{ $t('cancel') }}</button>
      </div>
    </div>
    <base-button
      backgroundColor="bb-primary"
      class="button-green-hover"
      :text="$t('templateSettings')"
      @click="showModal = !showModal">
      <use-svg svg="bb-gear-icon" />
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import InfoIcon from '@/components/businessboard/tooltip-icon';
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '../../../mixins/localization-mixin.vue';

export default {
  name: 'Modal',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    UseSvg,
    InfoIcon,
  },
  props: {},
  data() {
    return {
      showModal: false,
      formats: ['JPG', 'PNG'],
    };
  },
  computed: {
    showHeaders() {
      return this.$store.getters['businessboardReportingTool/settings']
        .showHeaders;
    },
    fileFormat() {
      return this.$store.getters['businessboardReportingTool/settings']
        .fileFormat;
    },
    user() {
      return this.$store.getters['user/user'];
    },
    showSaveAndPublish() {
      return !(
        this.user.roles.BusinessBoard &&
        this.user.roles.BusinessBoard.some(
          e => e.value === 'BusinessBoardBoardMember'
        )
      );
    },
  },
  mounted() {},
  methods: {
    changePictureFormat(fileFormat) {
      this.$store.commit('businessboardReportingTool/settings', {
        showHeaders: this.showHeaders,
        fileFormat: fileFormat.toString(),
      });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    saveFilter() {
      this.$store.dispatch('businessboardReportingTool/updateSettings', {
        showHeaders: this.showHeaders,
        fileFormat: this.fileFormat.toString(),
      });
      this.toggleModal();
    },
    toggleDiagramHeader() {
      this.$store.commit('businessboardReportingTool/settings', {
        showHeaders: !this.showHeaders,
        fileFormat: this.fileFormat,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.period_input {
  padding: 0 0.5rem 0 0.5rem;
  input,
  select {
    background-color: #f9f9f9;
    border-radius: 2px;
    padding: 0.3rem;
    border: 1px solid #d8d8d8;
  }
}
.radio__row {
  padding: 0.5rem 0;
  text-align: left;
}
.columns {
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: calc(100vh - 21.5rem);
}
.columns:hover {
  overflow-y: scroll;
  max-height: calc(100vh - 21.5rem);
}
.column {
  .column_section {
    display: inline-block;
    color: #555f61;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }
  strong {
    line-height: 40px;
  }
  span {
    margin: 0.5rem;
  }
}
input[type='radio'] {
  margin: 1rem;
}
.course {
  cursor: pointer;
  display: flex;
  padding: 8px;
  border-radius: 3px;
  gap: 0.5rem;
  p {
    line-height: 20px;
    margin-top: 0.2rem;
    color: #131516;
    font-weight: 500;
  }
  &_selected {
    background-color: #eaf6f7;
  }
  &_checkbox {
    width: 20px;
    height: 20px;
    display: inline-flex;
    border: 1px solid #131516;
    border-radius: 2px;
    svg {
      margin: auto;
    }
  }
}
.chart_settings_modal {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 8px;
  .header {
    margin: 1rem 0;
    display: flex;
    span {
      color: #0e3746;
    }
    .info_icon {
      margin-left: 0.5rem;
    }
  }
  button {
    padding: 0.5rem;
    border: 1px solid #0e3746;
    border-radius: 3px;
    background: white;
    margin: 0.5rem;
  }
  .btn_primary {
    background-color: #0e3746;
    color: white;
    float: right;
  }
  .btn_reticent {
    border: 0;
  }
}
.drillDownChoice {
  display: grid;
  grid-auto-flow: column;
  background: #eaf6f7;
  border-radius: 3px;
  font-weight: 400;
  padding: 4px;
  .choice {
    margin: auto 0;
    text-align: center;
    border: 1px solid #eaf6f7;
    padding: 4px;
    border-radius: 3px;
  }
  .selected {
    background: #abded1;
    border-color: #0e3746;
    font-weight: 500;
    div {
      display: inline-block;
      margin: auto;
      vertical-align: middle;
    }
  }
}
.show-diagram-header-div {
  gap: 1rem;
  display: flex;
  margin-left: 0.5rem;
}

.button-green-hover {
  &:hover {
    background-color: #27645c;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}
</style>
