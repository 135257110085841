<template>
  <div class="ontag">
    <div class="order-step-2">
      <div
        class="cancel"
        @click="cancelOrder">
        &lt; {{ $t('backToStartPage') }}
      </div>
      <div class="order-step-2__main-container">
        <div class="order-step-2__left-section">
          <div class="order-step-2__order-details">
            <h3>{{ $t('yourOrder') }}</h3>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th style="text-transform: uppercase">{{ $t('item') }}</th>
                  <th style="text-transform: uppercase">{{ $t('amount') }}</th>
                  <th style="text-transform: uppercase">
                    {{ $t('priceSlashAmount') }}
                  </th>
                  <th style="text-transform: uppercase">{{ $t('sum') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-if="scorecardType === 1">
                    <div class="order-step-2__scorecard-img">
                      <img
                        src="../../assets/images/sc-3p-2023-3.png"
                        alt="3 delat scorekort" />
                    </div>
                  </td>
                  <td v-else-if="scorecardType === 2">
                    <div class="order-step-2__scorecard-img">
                      <img
                        src="../../assets/images/sc-4p-2023-3.png"
                        alt="4 delat scorekort" />
                    </div>
                  </td>
                  <td v-if="scorecardType === 1">
                    <strong>{{ $t('standardCard') }}</strong>
                    <br />
                    {{ $t('threeParted') }}<br />
                    110 x 273 mm
                  </td>
                  <td v-else-if="scorecardType === 2">
                    <strong>{{ $t('standardCard') }}</strong>
                    <br /><br />
                    {{ $t('fourParted') }} <br />
                    110 x 362 mm
                  </td>
                  <td>
                    {{ (quantity * 1000).toLocaleString() }} {{ $t('piece')
                    }}<br />
                    /
                    <strong v-if="quantity > 1">
                      {{ quantity }} {{ $t('boxes') }}
                    </strong>
                    <strong v-else-if="quantity === 1">
                      {{ quantity }} {{ $t('box') }}
                    </strong>
                  </td>
                  <td>{{ unitPrice }} {{ $t('crownsSlashAmount') }}</td>
                  <td>
                    {{ (unitPrice * (quantity * 1000)).toLocaleString() }} kr
                  </td>
                </tr>
                <!-- <tr v-else-if="scorecardType === 2">
                  <td>
                    <div class="order-step-2__scorecard-img">
                      <img src="../../assets/images/SC-4-delat.jpg" alt="">
                    </div>
                  </td>
                  <td>
                    <strong>Standardkort</strong><br><br>
                    4-delat <br>
                    110 x 362 mm
                  </td>
                  <td>
                    {{ quantity * 1000 }} ex<br>
                    / {{ quantity }} låda
                  </td>
                  <td>
                    {{ unitPrice }} kr/st
                  </td>
                  <td>
                    {{ scorecardorder.priceSettings.fourPartPriceOne * (quantity * 1000)}} kr
                  </td>
                </tr> -->
              </tbody>
            </table>
            <div class="order-step-2__order-sum">
              <div
                v-if="postalCodeValid"
                class="order-step-2__order-sum-row">
                <span>
                  {{ $t('shippingToZipCode') }}
                  {{
                    this.deliveryAddress.postalCode
                      .replace(/\W/g, '')
                      .replace(/(...)/, '$1 ')
                  }}
                </span>
                <span> {{ customerShippingCost.toLocaleString() }} kr </span>
              </div>
              <div
                v-else-if="!postalCodeValid"
                class="order-step-2__order-sum-row">
                <span>{{ $t('enterValidZipCode') }}</span>
              </div>
              <div class="order-step-2__order-sum-row">
                <span>
                  <strong>{{ $t('sum') }}</strong> {{ $t('vatExcluded') }}
                </span>
                <span
                  v-if="postalCodeValid"
                  class="font-size-16 order-step-2--bold">
                  {{ totalCost.toLocaleString() }} kr
                </span>
                <span
v-else>{{ (unitPrice * (quantity * 1000)).toLocaleString() }}
                  {{ $t('sek') }}</span>
              </div>
            </div>
            <div class="order-step-2__order-details-bottom">
              <p v-if="deliveryWeek === 0">
                {{ $t('sentBy') }} 2-5 {{ $t('weekdays') }}
              </p>
              <p v-else>{{ $t('deliveryInWeek') }} {{ deliveryWeek }}</p>
              <p class="font-grey">{{ $t('signingInformation') }}</p>
              <br />
              <div v-if="comment !== ''">
                <p style="text-transform: uppercase">
                  {{ $t('deliveryComment') }}
                </p>
              </div>
              <p
                class="font-grey"
                v-if="comment !== ''">
                "{{ comment }}"
              </p>
            </div>
          </div>
        </div>
        <div class="order-step-2__right-section">
          <div class="order-step-2__step-container">
            <div
              @click="$router.go(-1)"
              class="order-step-2__step order-step-2__step--done">
              <img
                src="../../assets/images/check-mark.svg"
                alt="" />
              <span>{{ $t('yourOrder') }}</span>
            </div>
            <div class="order-step-2__step order-step-2__step--active">
              <strong>2</strong> <span>{{ $t('yourInfo') }}</span>
            </div>
            <div class="order-step-2__step">
              <strong>3</strong> {{ $t('confirmation') }}
            </div>
          </div>
          <form
            @submit.prevent="saveOrder()"
            id="order"></form>
          <div class="order-step-2__address-container">
            <h2>{{ $t('contactInfo') }}</h2>
            <div class="order-step-2__input-container">
              <label for="contact-person">{{ $t('contactPerson') }}*</label>
              <input
                required
                form="order"
                type="text"
                v-model="contactPerson"
                id="contact-person" />
            </div>
            <div class="order-step-2__input-container">
              <label for="phone-number">{{ $t('phoneNumber') }}*</label>
              <input
                required
                form="order"
                type="text"
                v-model="phoneNumber"
                id="phone-number" />
            </div>
            <div class="order-step-2__input-container">
              <label for="email">{{ $t('email') }}*</label>
              <input
                required
                form="order"
                type="email"
                v-model="email"
                id="email" />
            </div>
            <h2>{{ $t('deliveryAddress') }}</h2>
            <div class="order-step-2__input-container">
              <label for="company-name">{{ $t('companyName') }}*</label>
              <input
                required
                form="order"
                type="text"
                v-model="deliveryAddress.companyName"
                id="company-name" />
            </div>
            <div class="order-step-2__input-container">
              <label for="delivery-address">{{ $t('address') }}*</label>
              <input
                required
                form="order"
                type="text"
                v-model="deliveryAddress.postalAddress"
                id="delivery-address" />
            </div>
            <div class="order-step-2__input-container">
              <label for="postal-code">{{ $t('zipCode') }}*</label>
              <input
                required
                form="order"
                oninvalid="this.setCustomValidity('Ange ett giltligt postnummer (5 siffror)')"
                oninput="this.setCustomValidity('')"
                @keyup="calculateShippingCost()"
                type="number"
                min="10000"
                max="99999"
                onin
                v-model="deliveryAddress.postalCode"
                id="postal-code" />
            </div>
            <div class="order-step-2__input-container">
              <label for="delivery-city">{{ $t('locality') }}*</label>
              <input
                required
                form="order"
                type="text"
                v-model="deliveryAddress.city"
                id="delivery-city" />
            </div>
            <div class="order-step-2__input-container--checkbox">
              <input
                type="checkbox"
                id="new-standard-address"
                v-model="makeNewStandardDeliveryAddress" />
              <label for="new-standard-address">{{
                $t('makeNewStandardAddress')
              }}</label>
            </div>
            <div class="order-step-2__input-container--textarea">
              <label for="comment">{{ $t('deliveryComment') }}</label>
              <div
                v-if="!comment == ''"
                class="order-step-2__comment-warning">
                <div class="info-svg"></div>
                <p>{{ $t('deliveryCommentInformation') }}</p>
              </div>
              <textarea
                @keyup="textareaCountdown"
                maxlength="200"
                v-model="comment"
                id="comment"></textarea>
              <span class="order-step-2__textarea-word-count">
                {{ remainingCount }}/{{ maxCount }}
              </span>
              <div v-if="user.superUser">
                <label for="adminComment">
                  {{ $t('commentFrom') }} tietoevry
                </label>
                <textarea
                  v-model="adminComment"
                  id="adminComment"></textarea>
              </div>
            </div>
            <h2>{{ $t('invoiceAddress') }}*</h2>
            <div
              v-for="(address, index) in scorecardorderaddresses.invoiceAddress"
              :key="index">
              <!-- dont show address without ID, though there should be no invoiceaddresses witohut ID. -->
              <div
                class="order-step-2__input-container--radio"
                v-if="address.id">
                <input
                  :value="address.id"
                  v-model="checkedInvoiceAddress"
                  :checked="index === 0 && !addNewInvoiceAddress"
                  type="radio"
                  name="invoice-address" />
                <div
                  @click="checkedInvoiceAddress = address.id"
                  class="order-step-2__selected-radio-container">
                  <div class="order-step-2__edit-invoice-address">
                    <button
                      :disabled="checkedInvoiceAddress !== address.id"
                      v-if="editInvoiceAddressOpen !== index"
                      @click="editInvoiceAddress(index)">
                      <img src="../../assets/images/pen.svg" />
                      <span>{{ $t('edit') }}</span>
                    </button>
                  </div>
                  <div
                    v-if="editInvoiceAddressOpen !== index"
                    class="order-step-2__selected-radio-content">
                    <strong v-if="address.companyName !== ''">{{
                      address.companyName
                    }}</strong>
                    <span v-else>{{ $t('enterCompanyName') }}</span>
                    <span>{{ address.orgNumber }}</span>
                    <br />
                    <span> {{ address.reference }}</span>
                    <span> {{ address.postalAddress }}</span>
                    <span>
                      {{
                        address.postalCode
                          .replace(/\W/g, '')
                          .replace(/(...)/, '$1 ')
                      }}
                    </span>
                    <span> {{ address.city }} </span><br />
                    <span> {{ address.email }}</span>
                  </div>
                  <div
                    v-if="editInvoiceAddressOpen === index"
                    class="order-step-2__selected-radio-content-edit">
                    <form @submit.prevent="saveEditedInvoiceAddress(address)">
                      <div class="order-step-2__input-container">
                        <label for="edit-company-name">
                          {{ $t('companyName') }}*
                        </label>
                        <input
                          type="text"
                          v-model="address.companyName"
                          id="edit-company-name" />
                      </div>
                      <div class="order-step-2__input-container">
                        <label for="edit-org-nr">{{
                          $t('organizationNumber')
                        }}</label>
                        <input
                          disabled
                          type="text"
                          v-model="address.orgNumber"
                          id="edit-org-nr" />
                      </div>
                      <div class="order-step-2__input-container">
                        <label for="edit-reference">
                          {{ $t('reference') }}*
                        </label>
                        <input
                          required
                          type="text"
                          v-model="address.reference"
                          id="edit-reference" />
                      </div>
                      <div class="order-step-2__input-container">
                        <label for="edit-postal-address">
                          {{ $t('streetAddress') }}*
                        </label>
                        <input
                          type="text"
                          v-model="address.postalAddress"
                          id="edit-postal-address" />
                      </div>
                      <div class="order-step-2__input-container">
                        <label for="edit-postal-code">
                          {{ $t('zipCode') }}*
                        </label>
                        <input
                          type="text"
                          v-model="address.postalCode"
                          id="edit-postal-code" />
                      </div>
                      <div class="order-step-2__input-container">
                        <label for="edit-city">{{ $t('locality') }}*</label>
                        <input
                          type="text"
                          v-model="address.city"
                          id="edit-city" />
                      </div>
                      <div class="order-step-2__input-container">
                        <label for="edit-email">{{ $t('email') }}</label>
                        <input
                          type="text"
                          v-model="address.email"
                          id="edit-emial" />
                      </div>
                      <div class="order-step-2__add-invoice-button-container">
                        <button
                          @click.prevent="cancelEditInvoiceAddress"
                          class="order-step-2__add-invoice-address-button">
                          {{ $t('cancel') }}
                        </button>
                        <button
                          type="submit"
                          class="order-step-2__add-invoice-address-button-save">
                          {{ $t('save') }}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <span
              v-if="isAddressLoading"
              class="element--is-loading"></span><br />
            <button
              v-if="!addNewInvoiceAddress"
              @click="addNewInvoiceAddress = !addNewInvoiceAddress"
              class="order-step-2__add-invoice-address-button">
              <use-svg
                width="35"
                svg="plus-icon"
                stroke="black" />
              <span>{{ $t('addInvoiceAddress') }}</span>
            </button>
            <div v-if="addNewInvoiceAddress">
              <div class="order-step-2__input-container--radio">
                <input
                  name="invoice-address"
                  type="radio"
                  :checked="addNewInvoiceAddress" />
                <div class="order-step-2__selected-radio-container">
                  <form @submit.prevent="saveInvoiceAddress">
                    <div class="order-step-2__input-container">
                      <label for="add-company-name">
                        {{ $t('companyName') }}*
                      </label>
                      <input
                        v-model="newInvoiceAddress.companyName"
                        required
                        type="text"
                        id="add-company-name" />
                    </div>
                    <div class="order-step-2__input-container">
                      <label for="add-org-nr">
                        {{ $t('organizationNumber') }}
                        *
                      </label>
                      <input
                        required
                        v-model="newInvoiceAddress.orgNr"
                        @change="handleOrgNrChange"
                        type="text"
                        id="add-org-nr"
                        pattern="\d{6}-\d{4}"
                        title="Var vänlig ange korrekt format: XXXXXX-XXXX"
                        maxlength="11" />
                      <div
                        v-if="duplicateOrgNr"
                        class="order-step-2__duplicate-org-number">
                        {{ $t('duplicateOrganizationNumber') }}
                      </div>
                    </div>
                    <div class="order-step-2__input-container">
                      <label for="add-reference">{{ $t('reference') }}*</label>
                      <input
                        v-model="newInvoiceAddress.reference"
                        required
                        type="text"
                        id="add-reference" />
                    </div>
                    <div class="order-step-2__input-container">
                      <label for="add-address">
                        {{ $t('streetAddress') }}*
                      </label>
                      <input
                        v-model="newInvoiceAddress.address"
                        required
                        type="text"
                        id="add-address" />
                    </div>
                    <div class="order-step-2__input-container">
                      <label for="add-postal-code">{{ $t('zipCode') }}*</label>
                      <input
                        required
                        v-model="newInvoiceAddress.postalCode"
                        type="text"
                        id="add-postal-code" />
                    </div>
                    <div class="order-step-2__input-container">
                      <label for="add-city">{{ $t('locality') }}*</label>
                      <input
                        v-model="newInvoiceAddress.city"
                        required
                        type="text"
                        id="add-city" />
                    </div>
                    <div class="order-step-2__input-container">
                      <label for="add-email">{{ $t('emailForInvoice') }}</label>
                      <input
                        v-model="newInvoiceAddress.email"
                        type="text"
                        id="add-email" />
                    </div>
                    <div class="order-step-2__add-invoice-button-container">
                      <button
                        type="reset"
                        @click="addNewInvoiceAddress = !addNewInvoiceAddress"
                        class="order-step-2__add-invoice-address-button">
                        {{ $t('cancel') }}
                      </button>
                      <button
                        :disabled="duplicateOrgNr"
                        type="submit"
                        class="order-step-2__add-invoice-address-button-save">
                        {{ $t('save') }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <p>* {{ $t('mandatoryFields') }}</p>
            <div class="order-step-2__accept-terms-container">
              <input
                v-model="acceptTerms"
                type="checkbox" />
              <span>
                {{ $t('approve') }}
                <a
                  :href="terms.termsUrl"
                  target="_blank">
                  {{ $t('terms') }}
                </a>
                *
              </span>
            </div>
            <div class="order-step-2__save-order-container">
              <router-link
                class="order-step-2__cancel-order-button"
                :to="{ path: '/ontag/orderscorecard/order' }">
                {{ $t('cancel') }}
              </router-link>
              <button
                type="submit"
                form="order"
                :disabled="formDisabled"
                class="order-step-2__save-order-button">
                {{ $t('finishOrder') }}
              </button>
              <span
                v-if="isLoading"
                class="element--is-loading"></span>
            </div>
            <p class="order-step-2__route-back">
              <router-link :to="{ path: '/ontag/orderscorecard/standard' }">
                &lt; {{ $t('backToOrder') }}
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="order-step-2__background-wave">
        <img
          src="../../assets/images/wave-background.svg"
          alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'ScorecardStandardOrder',
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
  },

  data() {
    return {
      scorecardType: undefined,
      quantity: 1,
      deliveryWeek: undefined,
      deliveryAddress: {},
      invoiceAddress: [],
      invoiceAddressId: undefined,
      comment: '',
      maxCount: 200,
      remainingCount: 200,
      adminComment: '',
      addNewInvoiceAddress: false,
      newInvoiceAddress: {
        companyName: '',
        orgNr: '',
        address: '',
        postalCode: '',
        city: '',
        email: '',
        reference: '',
      },
      postalCode: 0,
      postalCodeFull: 0,
      postalCodeValid: false,
      customerShippingCost: 0,
      internalShippingCost: 0,
      kolli: 1,
      totalCost: undefined,
      checkedInvoiceAddress: 0,
      editInvoiceAddressOpen: undefined,
      makeNewStandardDeliveryAddress: false,
      contactPerson: '',
      phoneNumber: '',
      email: '',
      acceptTerms: false,
      orderID: 0,
      unitPrice: 0,
      isLoading: false,
      duplicateOrgNr: false,
      isAddressLoading: false,
    };
  },

  methods: {
    textareaCountdown() {
      this.remainingCount = this.maxCount - this.comment.length;
    },
    handleOrgNrChange() {
      let inputValue = this.newInvoiceAddress.orgNr;

      for (let i = 0; i < this.invoiceAddress.length; i++) {
        const address = this.invoiceAddress[i];
        if (address.orgNumber === inputValue) {
          this.duplicateOrgNr = true;
          break;
        } else {
          this.duplicateOrgNr = false;
        }
      }
    },
    toggleCollapse(item) {
      if (!this.collapsed.includes(item)) {
        this.collapsed.push(item);
      } else {
        this.collapsed.pop(item);
      }
    },
    calculateShippingCost() {
      this.postalCode = this.deliveryAddress.postalCode;
      this.postalCodeFull = this.deliveryAddress.postalCode;
      if (this.postalCode !== undefined) {
        this.postalCode = this.postalCode.slice(0, 2);
      }
      let cost = 0;
      let internalCost = 0;
      let halfPallet = false;
      let fullPallet = false;
      let numberOfFullPallets = 0;
      let count = this.quantity;
      let loopCount = this.quantity;

      if (count > 0) {
        while (count !== 0) {
          if (count % 54 === 0) {
            numberOfFullPallets = numberOfFullPallets + 1;
            loopCount = this.quantity - numberOfFullPallets * 54;
          }
          count = count - 1;
        }
      }

      if (loopCount >= 7 && loopCount <= 16) {
        halfPallet = true;
      } else if ((loopCount >= 17 && loopCount <= 54) || loopCount === 0) {
        fullPallet = true;
      }

      if (loopCount === 1 || loopCount === 2) {
        this.kolli = 1;
      } else if (loopCount === 3 || loopCount === 4) {
        this.kolli = 2;
      } else if (loopCount === 5 || loopCount === 6) {
        this.kolli = 3;
      } else if (loopCount === 0) {
        this.kolli = 0;
      }

      if (loopCount >= 0 && loopCount <= 6) {
        let kolliPrice =
          this.scorecardordershippingprice.packagePriceExVAT * this.kolli;
        if (numberOfFullPallets > 0) {
          if (this.postalCode >= 10 && this.postalCode <= 19) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip10to19PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip10to19PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip10to19PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 20 && this.postalCode <= 27) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip20to27PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip20to27PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip20to27PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 28 && this.postalCode <= 39) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip28to39PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip28to39PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip28to39PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 40 && this.postalCode <= 51) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip40to51PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip40to51PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip40to51PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 52 && this.postalCode <= 57) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip52to57PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip52to57PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip52to57PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 58 && this.postalCode <= 61) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip58to61PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip58to61PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip58to61PriceFull *
                numberOfFullPallets;
          } else if (parseInt(this.postalCode) === 62) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip62PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip62PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip62PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 63 && this.postalCode <= 76) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip63to76PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip63to76PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip63to76PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 77 && this.postalCode <= 82) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip77to82PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip77to82PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip77to82PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 83 && this.postalCode <= 88) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip83to88PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip83to88PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip83to88PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 89 && this.postalCode <= 93) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip89to93PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip89to93PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip89to93PriceFull *
                numberOfFullPallets;
          } else if (this.postalCode >= 94 && this.postalCode <= 98) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip94to98PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip94to98PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
            internalCost =
              kolliPrice +
              this.scorecardordershippingprice.zip94to98PriceFull *
                numberOfFullPallets;
          }
        } else {
          cost =
            kolliPrice +
            (kolliPrice / 100) * this.scorecardordershippingprice.freight;
          internalCost = kolliPrice;
        }
      } else if (this.postalCode >= 10 && this.postalCode <= 19 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip10to19PriceHalf +
          this.scorecardordershippingprice.zip10to19PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip10to19PriceHalf +
            this.scorecardordershippingprice.zip10to19PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip10to19PriceHalf +
          this.scorecardordershippingprice.zip10to19PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 10 && this.postalCode <= 19 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip10to19PriceFull +
          this.scorecardordershippingprice.zip10to19PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip10to19PriceFull +
            this.scorecardordershippingprice.zip10to19PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip10to19PriceFull +
          this.scorecardordershippingprice.zip10to19PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 20 && this.postalCode <= 27 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip20to27PriceHalf +
          this.scorecardordershippingprice.zip20to27PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip20to27PriceHalf +
            this.scorecardordershippingprice.zip20to27PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip20to27PriceHalf +
          this.scorecardordershippingprice.zip20to27PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 20 && this.postalCode <= 27 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip20to27PriceFull +
          this.scorecardordershippingprice.zip20to27PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip20to27PriceFull +
            this.scorecardordershippingprice.zip20to27PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip20to27PriceFull +
          this.scorecardordershippingprice.zip20to27PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 28 && this.postalCode <= 39 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip28to39PriceHalf +
          this.scorecardordershippingprice.zip28to39PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip28to39PriceHalf +
            this.scorecardordershippingprice.zip28to39PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip28to39PriceHalf +
          this.scorecardordershippingprice.zip28to39PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 28 && this.postalCode <= 39 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip28to39PriceFull +
          this.scorecardordershippingprice.zip28to39PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip28to39PriceFull +
            this.scorecardordershippingprice.zip28to39PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip28to39PriceFull +
          this.scorecardordershippingprice.zip28to39PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 40 && this.postalCode <= 51 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip40to51PriceHalf +
          this.scorecardordershippingprice.zip40to51PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip40to51PriceHalf +
            this.scorecardordershippingprice.zip40to51PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip40to51PriceHalf +
          this.scorecardordershippingprice.zip40to51PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 40 && this.postalCode <= 51 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip40to51PriceFull +
          this.scorecardordershippingprice.zip40to51PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip40to51PriceFull +
            this.scorecardordershippingprice.zip40to51PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip40to51PriceFull +
          this.scorecardordershippingprice.zip40to51PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 52 && this.postalCode <= 57 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip52to57PriceHalf +
          this.scorecardordershippingprice.zip52to57PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip52to57PriceHalf +
            this.scorecardordershippingprice.zip52to57PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip52to57PriceHalf +
          this.scorecardordershippingprice.zip52to57PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 52 && this.postalCode <= 57 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip52to57PriceFull +
          this.scorecardordershippingprice.zip52to57PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip52to57PriceFull +
            this.scorecardordershippingprice.zip52to57PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip52to57PriceFull +
          this.scorecardordershippingprice.zip52to57PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 58 && this.postalCode <= 61 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip58to61PriceHalf +
          this.scorecardordershippingprice.zip58to61PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip58to61PriceHalf +
            this.scorecardordershippingprice.zip58to61PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip58to61PriceHalf +
          this.scorecardordershippingprice.zip58to61PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 58 && this.postalCode <= 61 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip58to61PriceFull +
          this.scorecardordershippingprice.zip58to61PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip58to61PriceFull +
            this.scorecardordershippingprice.zip58to61PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip58to61PriceFull +
          this.scorecardordershippingprice.zip58to61PriceFull *
            numberOfFullPallets;
      } else if (parseInt(this.postalCode) === 62 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip62PriceHalf +
          this.scorecardordershippingprice.zip62PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip62PriceHalf +
            this.scorecardordershippingprice.zip62PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip62PriceHalf +
          this.scorecardordershippingprice.zip62PriceFull * numberOfFullPallets;
      } else if (parseInt(this.postalCode) === 62 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip62PriceFull +
          this.scorecardordershippingprice.zip62PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip62PriceFull +
            this.scorecardordershippingprice.zip62PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip62PriceFull +
          this.scorecardordershippingprice.zip62PriceFull * numberOfFullPallets;
      } else if (this.postalCode >= 63 && this.postalCode <= 76 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip63to76PriceHalf +
          this.scorecardordershippingprice.zip63to76PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip63to76PriceHalf +
            this.scorecardordershippingprice.zip63to76PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip63to76PriceHalf +
          this.scorecardordershippingprice.zip63to76PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 63 && this.postalCode <= 76 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip63to76PriceFull +
          this.scorecardordershippingprice.zip63to76PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip63to76PriceFull +
            this.scorecardordershippingprice.zip63to76PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip63to76PriceFull +
          this.scorecardordershippingprice.zip63to76PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 77 && this.postalCode <= 82 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip77to82PriceHalf +
          this.scorecardordershippingprice.zip77to82PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip77to82PriceHalf +
            this.scorecardordershippingprice.zip77to82PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip77to82PriceHalf +
          this.scorecardordershippingprice.zip77to82PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 77 && this.postalCode <= 82 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip77to82PriceFull +
          this.scorecardordershippingprice.zip77to82PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip77to82PriceFull +
            this.scorecardordershippingprice.zip77to82PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip77to82PriceFull +
          this.scorecardordershippingprice.zip77to82PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 83 && this.postalCode <= 88 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip83to88PriceHalf +
          this.scorecardordershippingprice.zip83to88PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip83to88PriceHalf +
            this.scorecardordershippingprice.zip83to88PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip83to88PriceHalf +
          this.scorecardordershippingprice.zip83to88PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 83 && this.postalCode <= 88 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip83to88PriceFull +
          this.scorecardordershippingprice.zip83to88PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip83to88PriceFull +
            this.scorecardordershippingprice.zip83to88PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip83to88PriceFull +
          this.scorecardordershippingprice.zip83to88PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 89 && this.postalCode <= 93 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip89to93PriceHalf +
          this.scorecardordershippingprice.zip89to93PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip89to93PriceHalf +
            this.scorecardordershippingprice.zip89to93PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip89to93PriceHalf +
          this.scorecardordershippingprice.zip89to93PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 89 && this.postalCode <= 93 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip89to93PriceFull +
          this.scorecardordershippingprice.zip89to93PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip89to93PriceFull +
            this.scorecardordershippingprice.zip89to93PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip89to93PriceFull +
          this.scorecardordershippingprice.zip89to93PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 94 && this.postalCode <= 98 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip94to98PriceHalf +
          this.scorecardordershippingprice.zip94to98PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip94to98PriceHalf +
            this.scorecardordershippingprice.zip94to98PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip94to98PriceHalf +
          this.scorecardordershippingprice.zip94to98PriceFull *
            numberOfFullPallets;
      } else if (this.postalCode >= 94 && this.postalCode <= 98 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip94to98PriceFull +
          this.scorecardordershippingprice.zip94to98PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip94to98PriceFull +
            this.scorecardordershippingprice.zip94to98PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
        internalCost =
          this.scorecardordershippingprice.zip94to98PriceFull +
          this.scorecardordershippingprice.zip94to98PriceFull *
            numberOfFullPallets;
      }
      this.customerShippingCost = Math.round(cost);
      this.internalShippingCost = internalCost;
      this.validatePostalCode();
    },

    calculateTotalCost() {
      let cost = parseFloat(this.unitPrice) * (this.quantity * 1000);
      cost = cost + parseInt(this.customerShippingCost);
      this.totalCost = Math.round(cost);
      this.scorecardorder.totalCost = this.totalCost;
    },
    editInvoiceAddress(index) {
      this.editInvoiceAddressOpen = index;
    },

    cancelEditInvoiceAddress() {
      this.editInvoiceAddressOpen = undefined;
      this.$store
        .dispatch('scorecardOrder/fetchScorecardOrderAddresses')
        .then(() => {
          this.invoiceAddress = this.scorecardorderaddresses.invoiceAddress;
        });
    },
    saveEditedInvoiceAddress(address) {
      this.scorecardorderaddresses.invoiceAddress.forEach(element => {
        if (address.id === element.id) {
          this.$store
            .dispatch('scorecardOrder/patchScorecardOrderInvoiceAddress', {
              id: address.id,
              companyName: address.companyName,
              orgNumber: address.orgNumber,
              postalAddress: address.postalAddress,
              postalCode: address.postalCode,
              city: address.city,
              email: address.email,
              reference: address.reference,
            })
            .then(() => {
              this.$store
                .dispatch('scorecardOrder/fetchScorecardOrderAddresses')
                .then(() => {
                  this.invoiceAddress =
                    this.scorecardorderaddresses.invoiceAddress;
                  this.editInvoiceAddressOpen = undefined;
                });
            });
        }
      });
    },
    saveInvoiceAddress() {
      this.isAddressLoading = true;
      this.$store
        .dispatch('scorecardOrder/createScorecardOrderInvoiceAddress', {
          companyName: this.newInvoiceAddress.companyName,
          orgNumber: this.newInvoiceAddress.orgNr,
          postalAddress: this.newInvoiceAddress.address,
          postalCode: this.newInvoiceAddress.postalCode,
          city: this.newInvoiceAddress.city,
          email: this.newInvoiceAddress.email,
          reference: this.newInvoiceAddress.reference,
        })
        .then(() => {
          this.$store.dispatch('scorecardOrder/fetchScorecardOrderAddresses');
        })
        .then(() => {
          setTimeout(() => {
            let lastIndex = this.invoiceAddress[this.invoiceAddress.length - 1];
            this.checkedInvoiceAddress = lastIndex.id;
          }, 800);
          this.isAddressLoading = false;
        });
      this.addNewInvoiceAddress = false;
    },
    validatePostalCode() {
      if (
        this.deliveryAddress.postalCode > 9999 &&
        this.deliveryAddress.postalCode < 100000
      ) {
        this.postalCodeValid = true;
        this.calculateTotalCost();
      } else {
        this.postalCodeValid = false;
      }
    },
    cancelOrder() {
      if (confirm(this.$t('confirmCancel'))) {
        this.restoreState();
        this.$router.push('/ontag/orderscorecard/order');
      }
    },

    restoreState() {
      this.scorecardordertempstate.scorecardType = 0;
      this.scorecardordertempstate.quantity = 1;
      this.scorecardordertempstate.deliveryWeek = undefined;
      this.scorecardordertempstate.unitPrice = 0;
      this.scorecardordertempstate.totalCost = 0;
      this.scorecardordertempstate.customerShippingCost = 0;
    },
    saveOrder() {
      if (this.acceptTerms) {
        this.isLoading = true;
        if (this.makeNewStandardDeliveryAddress) {
          this.$store
            .dispatch('scorecardOrder/patchScorecardOrderDeliveryAddress', {
              companyName: this.deliveryAddress.companyName,
              postalAddress: this.deliveryAddress.postalAddress,
              postalCode: this.deliveryAddress.postalCode,
              city: this.deliveryAddress.city,
            })
            .then(() => {
              this.$store.dispatch(
                'scorecardOrder/fetchScorecardOrderAddresses'
              );
            })
            .then(() => {
              this.deliveryAddress =
                this.scorecardorderaddresses.deliveryAddress;
            });
        }
        this.$store
          .dispatch('scorecardOrder/createScorecardOrder', {
            quantity: this.quantity,
            surface: 0,
            deliveryWeek: this.deliveryWeek,
            deliveryWeekId: '',
            contactPerson: this.contactPerson,
            phoneNumber: this.phoneNumber,
            email: this.email,
            customerShippingPrice: this.customerShippingCost,
            shippingCost: this.internalShippingCost,
            deliveryAddress: this.deliveryAddress,
            invoiceAddressId: this.checkedInvoiceAddress,
            comment: this.comment,
            adminComment: this.adminComment,
            scorecardType: this.scorecardType,
            hasChanges: false,
            unitPrice: this.unitPrice,
            totalPrice: this.totalCost,
          })
          .then(() => {
            this.restoreState();
            this.isLoading = false;
            this.$router.push('/ontag/orderscorecard/confirmation');
          });
      }
    },
  },

  watch: {
    postalCode() {
      this.validatePostalCode();
    },
    scorecardorderaddresses() {
      if (this.scorecardorderaddresses.invoiceAddress !== undefined) {
        this.invoiceAddress = this.scorecardorderaddresses.invoiceAddress;
      }
    },
    checkedInvoiceAddress() {
      this.calculateTotalCost();
    },

    'newInvoiceAddress.orgNr': function (newValue, oldValue) {
      const cleanedValue = newValue.replace(/[^0-9]/g, '');
      const formattedOrgNr = cleanedValue.replace(/(\d{6})(\d{4})/, '$1-$2');

      if (cleanedValue !== formattedOrgNr) {
        this.newInvoiceAddress.orgNr = formattedOrgNr;
      }
    },
  },

  computed: {
    scorecardordertempstate() {
      return this.$store.getters['scorecardOrder/scorecardordertempstate'];
    },
    scorecardorder() {
      return this.$store.getters['scorecardOrder/scorecardorder'];
    },
    scorecardorderaddresses() {
      return this.$store.getters['scorecardOrder/scorecardorderaddresses'];
    },
    scorecardordershippingprice() {
      return this.$store.getters['scorecardOrder/scorecardordershippingprice'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    terms() {
      return this.$store.getters['scorecardOrder/terms'];
    },
    formDisabled() {
      return (
        !this.acceptTerms ||
        this.addNewInvoiceAddress ||
        this.editInvoiceAddressOpen !== undefined ||
        this.checkedInvoiceAddress === 0 ||
        this.duplicateOrgNr
      );
    },
  },
  mounted() {
    this.$store.dispatch('scorecardOrder/fetchTerms');
    this.$store.dispatch('scorecardOrder/fetchScorecardOrderAddresses');
    this.$store.dispatch('scorecardOrder/fetchScorecardOrder');
    this.scorecardType = this.scorecardordertempstate.scorecardType;
    this.quantity = this.scorecardordertempstate.quantity;
    this.deliveryAddress = this.scorecardorderaddresses.deliveryAddress;
    this.invoiceAddress = this.scorecardorderaddresses.invoiceAddress;

    if (this.scorecardorderaddresses.deliveryAddress.postalCode) {
      this.postalCode =
        this.scorecardorderaddresses.deliveryAddress.postalCode.slice(0, 2);
      this.postalCodeFull =
        this.scorecardorderaddresses.deliveryAddress.postalCode;
      if (this.postalCodeFull > 9999 && this.postalCodeFull < 100000) {
        this.postalCodeValid = true;
      }
    }

    this.kolli = this.scorecardorder.kolli;
    this.$store
      .dispatch('scorecardOrder/fetchScorecardOrderShippingPrice')
      .then(() => {
        this.calculateShippingCost();
        this.calculateTotalCost();
      });

    this.deliveryWeek = this.scorecardordertempstate.deliveryWeek;
    this.unitPrice = this.scorecardordertempstate.unitPrice;
    this.totalCost = this.scorecardordertempstate.totalCost;
    this.customerShippingCost =
      this.scorecardordertempstate.customerShippingCost;
  },
};
</script>

<style lang="scss" scoped>
html {
  scroll-padding-top: 100px;
}
.content {
  margin: 4.375rem 0 1rem 0;
  @include media('small') {
    margin: 3.3rem 0rem 1rem 15.7rem;
  }
}
.order-step-2 {
  // background-color: color(info-background);
  background: color(info-background);
  padding: measure(spacing-xxl) measure(spacing-xl);

  &__main-container {
    display: flex;
    flex-direction: column-reverse;
    margin: measure(spacing-l) auto 0px;
    max-width: 1200px;
    font-size: 14px;

    @include media('large') {
      flex-direction: row;
    }
  }

  &__left-section {
    width: 100%;
    margin-top: measure(spacing-l);
    @include media('large') {
      margin-top: 0px;
      width: 60%;
    }
    h3 {
      padding: measure(spacing-xl) measure(spacing-m) measure(spacing-xl);
      margin: 0;
    }
  }
  &__order-details {
    background-color: white;
    padding: 16px;
    position: sticky;
    top: 100px;

    @include media('large') {
      min-width: 550px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      thead {
        background-color: color(dis);
        text-align: left;
        tr {
          :first-child {
            border-radius: 3px 0 0 3px;
          }
          :last-child {
            text-align: right;
          }
          :last-child {
            border-radius: 0 3px 3px 0;
          }
          th {
            padding: 10px 14px;
            font-weight: unset;
          }
        }
      }
      tbody {
        tr {
          :last-child {
            text-align: right;
          }
          td {
            padding: 40px 16px;
          }
        }
      }
    }
  }

  &__scorecard-img {
    width: 80%;
    min-width: 150px;

    img {
      width: 100%;
    }
  }

  &__order-sum {
    margin: 40px 16px;
    border-top: 1px solid color(tussilago);
    border-bottom: 1px solid color(tussilago);
  }

  &__order-sum-row {
    padding: measure(spacing-m) 0px;
    display: flex;
    justify-content: space-between;
  }

  &__order-details-bottom {
    padding: 0px measure(spacing-m);
  }
  &__right-section {
    width: 80%;
    margin: 0px 18px;

    @include media('large') {
      width: 40%;
    }
  }

  &__step-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__step {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    width: 32%;
    text-align: center;
    padding: measure(spacing-xs);
    border-radius: 2px;

    img {
      margin-right: 5px;
    }

    :first-child {
      border: none;
    }
  }
  &__step--active {
    border: 1px solid black;
  }

  &__step--done {
    cursor: pointer;
    :nth-child(2) {
      opacity: 0.5;
    }
  }

  &__address-container {
    background-color: white;
    padding: 42px 38px;
    margin-top: 30px;
    border-radius: 3px;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    line-height: 24px;
    margin-bottom: measure(spacing-m);

    input {
      font-size: 16px;
      padding: 8px;
      border-radius: 3px;
      border: 1px solid #d9d9d9;
    }
    input:hover {
      border: 1px solid #b6b6b6;
    }
    input:disabled[type='text'] {
      border: none;
      background-color: transparent;
    }
  }
  &__duplicate-org-number {
    display: flex;
    margin-top: 5px;
    padding: 10px;
    flex-direction: column;
    background-color: #ff8447;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &__input-container--checkbox {
    display: flex;
    margin-bottom: measure(spacing-m);

    input {
      margin-right: measure(spacing-m);
      width: 18px;
      accent-color: black;
    }
  }

  &__input-container--textarea {
    line-height: 24px;
    margin-bottom: measure(spacing-xl);
    textarea {
      width: 100%;
      height: 80px;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      resize: none;
      padding: measure(spacing-xs);
    }
  }

  &__textarea-word-count {
    color: color(middleGray);
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
  }

  &__input-container--radio {
    display: flex;
    align-items: flex-start;
    margin-bottom: measure(spacing-l);
    input[type='radio'] {
      margin-right: measure(spacing-m);
      width: 18px;
      height: 18px;
      accent-color: black;
    }
    input[type='radio']:checked + .order-step-2__selected-radio-container {
      border: 1px solid black;
      border-radius: 3px;
      padding: measure(spacing-m) measure(spacing-l);
      width: 100%;
      display: flex;
      flex-direction: column;
      opacity: 1;
      cursor: auto;
    }
  }
  &__selected-radio-container {
    cursor: pointer;
    opacity: 0.4;
    border: 1px solid black;
    border-radius: 3px;
    padding: measure(spacing-m) measure(spacing-l);
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 21px;
    font-size: 16px;

    p {
      margin: 0;
    }
  }
  &__selected-radio-container:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__selected-radio-content {
    display: flex;
    flex-direction: column;
  }

  &__selected-radio-content-edit {
  }

  &__edit-invoice-address {
    display: flex;
    justify-content: flex-end;

    button {
      border: none;
      background-color: transparent;
    }
    button:hover:not([disabled]) {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    button:disabled {
      color: black;
    }
    img {
      margin-right: measure(spacing-xs);
    }
  }

  &__add-invoice-address-button {
    border: 2px solid black;
    border-radius: 3px;
    background-color: transparent;
    font-size: 16px;
    padding: measure(spacing-s);
    margin-top: measure(spacing-s);
  }

  &__add-invoice-address-button-save {
    background-color: color(jord);
    border: 2px solid color(jord);
    border-radius: 3px;
    color: white;
    padding: measure(spacing-s) measure(spacing-m);
    margin-top: measure(spacing-s);
    margin-left: measure(spacing-xxs);
  }

  &__add-invoice-button-container {
    display: flex;
    justify-content: flex-end;
  }

  &__comment-warning {
    background-color: color(info-background);
    border: 1px solid color(soluppgang);
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: measure(spacing-s) measure(spacing-m);
    margin-bottom: measure(spacing-s);

    .info-svg {
      margin-right: measure(spacing-xs);
      background-color: color(soluppgang);
      height: 2.5vh;
      width: 2.5vw;
      mask: url(../../assets/images/info.svg) no-repeat center / contain;
      -webkit-mask: url(../../assets/images/info.svg) no-repeat center / contain;
    }
    p {
      margin: 0 0 0 measure(spacing-s);
    }
  }

  &__accept-terms-container {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin: measure(spacing-xl) 0px;

    input {
      width: 18px;
      height: 18px;
      margin-right: measure(spacing-m);
    }

    a {
      color: #0399e4;
    }
    a:hover {
      color: #0081c1;
      cursor: pointer;
    }
  }

  &__save-order-container {
    display: flex;

    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__save-order-button {
    background-color: color(jord);
    border: 2px solid color(jord);
    border-radius: 3px;
    color: white;
    padding: measure(spacing-s) measure(spacing-m);
    margin-top: measure(spacing-s);
    margin-left: measure(spacing-xxs);
    width: 65%;
  }

  &__cancel-order-button {
    text-align: center;
    border: 2px solid black;
    border-radius: 3px;
    background-color: transparent;
    font-size: 16px;
    padding: measure(spacing-s);
    margin-top: measure(spacing-s);
    width: 30%;
  }

  &__route-back {
    a {
      cursor: pointer;
    }
  }

  &__background-wave {
    width: calc(100% + 60px);
    margin: 0px 0px -40px -30px;
    max-height: 200px;
    img {
      margin-bottom: -20px;
      margin-top: 20px;
      width: 100%;
      overflow: hidden;
    }
  }
}

.order-step-2--bold {
  font-weight: bold;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

.font-size-16 {
  font-size: 16px;
}

.font-grey {
  color: color(middleGray);
}
.cancel:hover {
  cursor: pointer;
  font-weight: bold;
}
</style>
