<template>
  <div
    class="coursedata"
    :disabled="isLoading">
    <div
      v-if="!initialLoadCompleted"
      class="element--is-loading"></div>
    <h1
      v-if="initialLoadCompleted && !hasHoles"
      class="coursedata-header">
      Course guides
    </h1>
    <course-data-holes-empty-view
      v-if="initialLoadCompleted && isEmpty"
      @submit="selectCourseGuideHoleSetup"
      :selected-item="currentlySelectedHoleSetup" />
    <te-stepper
      v-else-if="initialLoadCompleted && !hasHoles"
      :items="stepItems"
      class="courseguide-setup-stepper"
      :current-item="currentItem">
      <course-data-holes-create-groups-view
        v-if="currentItem && currentItem.id == 'hole-setup-2'"
        :item="currentItem"
        :groups="groups"
        @progress="this.groupsProgressChanged"
        @done="this.continue"
        @cancel="this.cancel">
      </course-data-holes-create-groups-view>
      <course-data-holes-add-par-view
        v-else-if="currentItem && currentItem.id == 'hole-setup-3'"
        :item="currentSubItem"
        :par="par"
        :group="currentGroup"
        @changed="this.groupParChanged"
        @done="this.continue"
        @cancel="this.cancel" />
    </te-stepper>
    <course-guides
      v-else-if="initialLoadCompleted"
      :groups="this.holes"
      :loops="this.loops" />
  </div>
</template>

<script>
import CourseDataHolesEmptyView from './holes-empty.vue';
import CourseGuides from './course-guides-manage.vue';
import CourseDataHolesAddParView from './holes-add-par.vue';
import CourseDataHolesCreateGroupsView from './holes-create-groups.vue';
import TeStepper from './components/te-stepper.vue';
import {
  StepperDataModel,
  GroupStepperBridge,
  GroupsDataModel,
} from './holes-datamodel.mjs';
export default {
  name: 'CourseDataHolesView',
  emits: ['submit'],
  components: {
    CourseDataHolesEmptyView,
    CourseDataHolesAddParView,
    CourseGuides,
    TeStepper,
    CourseDataHolesCreateGroupsView,
  },
  computed: {
    courseGuideHoleSetup() {
      return this.$store.getters['coursedata/courseguidesettings']
        .courseGuideHoleSetup;
    },
    currentGroup() {
      return GroupStepperBridge.getCurrenParGroup(
        this.groupDataModel,
        this.stepperDataModel
      );
    },
    stepItems() {
      return this.stepperDataModel.items;
    },
    holes() {
      return this.$store.getters['coursedata/holes'];
    },
    loops() {
      return this.$store.getters['coursedata/loops'];
    },
    groups() {
      return this.groupDataModel.groups;
    },
    currentItem() {
      return this.stepperDataModel.currentItem;
    },
    currentSubItem() {
      return this.stepperDataModel.currentSubItem;
    },
    isLoading() {
      return this.$store.getters['coursedata/isLoading'];
    },
    isEmpty() {
      return this.currentlySelectedHoleSetup === 0;
    },
    hasHoles() {
      if (!this.holes) {
        return false;
      }
      if (this.holes.length === 0) {
        return false;
      }
      if (this.holes[0].holes.length === 0) {
        return false;
      }
      return true;
    },
  },
  watch: {
    currentlySelectedHoleSetup(n, old) {
      if (n !== old) {
        this.updateStepper();
      }
    },
    courseGuideHoleSetup(n, old) {
      this.currentlySelectedHoleSetup = n;
    },
    // loops: {
    //   handler: function (newVal) {
    //     console.log('watch loops', newVal);
    //     if (newVal) {
    //       console.log('do something?')
    //     }
    //   },
    //   deep: true,
    // },
    holes: {
      handler: function (newVal) {
        if (newVal) {
          this.populateGroupModel(newVal);
        }
      },
      deep: true,
    },
  },
  data() {
    let s =
      this.$store.getters['coursedata/courseguidesettings']
        .courseGuideHoleSetup ?? -1;
    let stepperDataModel = new StepperDataModel();
    let groupDataModel = new GroupsDataModel();
    return {
      initialLoadCompleted: false,
      stepperDataModel: stepperDataModel,
      groupDataModel: groupDataModel,
      par: [3, 4, 5, 6],
      currentlySelectedHoleSetup: s,
    };
  },
  methods: {
    convertHolesToGroupHoles(holes) {
      if (!holes) {
        return;
      }
      if (!holes.loops) {
        return;
      }
      if (holes.loops.length === 0) {
        return;
      }
      if (holes.loops[0].holeGroups.length === 0) {
        return;
      }
      if (holes.loops[0].holeGroups[0].holes.length === 0) {
        return;
      }
      let array = [];
      for (let h in holes.loops[0].holeGroups[0].holes) {
        let hole = holes.loops[0].holeGroups[0].holes[h];
        array.push({
          _id: Date.now().toString(36) + Math.random().toString(36),
          holeNumber: h + 1,
          par: hole.par,
        });
      }
      return array;
    },
    updateStepper() {
      this.groupDataModel.resetGroups(this.currentlySelectedHoleSetup, []);
      if (this.currentlySelectedHoleSetup === 1) {
        let g = GroupsDataModel.createStandardGroup();
        this.groupDataModel.addGroup(g);
        let items = StepperDataModel.createStandardStepperItems();
        this.stepperDataModel.resetItems(items);
        GroupStepperBridge.updateGroupsParProgress(
          this.groupDataModel,
          this.stepperDataModel,
          this.stepperDataModel.items[1]
        );
        GroupStepperBridge.insertStepperParItems(
          this.groupDataModel,
          this.stepperDataModel,
          this.stepperDataModel.items[1]
        );
        this.stepperDataModel.updateCurrentItemsReferences();
      } else if (this.currentlySelectedHoleSetup === 2) {
        let items = StepperDataModel.createCustomStepperItems();
        this.stepperDataModel.resetItems(items);
        GroupStepperBridge.updateGroupsParProgress(
          this.groupDataModel,
          this.stepperDataModel,
          this.stepperDataModel.items[2]
        );
        GroupStepperBridge.insertStepperParItems(
          this.groupDataModel,
          this.stepperDataModel,
          this.stepperDataModel.items[2]
        );
        GroupStepperBridge.updateGroupsSetupProgress(
          this.groupDataModel,
          this.stepperDataModel,
          this.stepperDataModel.items[1]
        );
        this.stepperDataModel.updateCurrentItemsReferences();
      } else {
        this.stepperDataModel.resetItems([]);
      }
    },
    selectCourseGuideHoleSetup(val) {
      this.$store
        .dispatch('coursedata/patchCourseGuideSettings', {
          courseGuideHoleSetup: val,
        })
        .then(() => {})
        .finally(() => {
          this.currentlySelectedHoleSetup = val;
        });
    },
    populateGroupModel(holes) {
      // let groupHoles = this.convertHolesToGroupHoles(holes);
      // if (groupHoles) {
      //   this.groupDataModel.resetGroups(this.currentlySelectedHoleSetup, groupHoles);
      // }
    },
    groupsProgressChanged(progress, sets, item) {},
    groupParChanged(group, item) {
      GroupStepperBridge.updateGroupsParProgress(
        this.groupDataModel,
        this.stepperDataModel,
        item
      );
    },
    continue(g) {
      if (this.currentlySelectedHoleSetup === 2) {
        GroupStepperBridge.updateGroupsParProgress(
          this.groupDataModel,
          this.stepperDataModel,
          this.stepperDataModel.items[2]
        );
        GroupStepperBridge.insertStepperParItems(
          this.groupDataModel,
          this.stepperDataModel,
          this.stepperDataModel.items[2]
        );
      }
      this.stepperDataModel.moveToNextStep();
      if (this.stepperDataModel.isDone()) {
        this.completed();
      }
    },
    completed() {
      let groups = this.groupDataModel.groups;
      this.$store
        .dispatch('coursedata/postCreateHoleGroupsAndHoles', {
          holeGroups: groups,
        })
        .then(() => {})
        .finally(() => {});
    },
    cancel() {
      if (this.stepperDataModel.getCurrentItemIndex() > 1) {
        this.stepperDataModel.moveToPreviousStep();
      } else {
        this.selectCourseGuideHoleSetup(0);
      }
    },
    go() {
      this.$router.push({ name: `coursedata-loops` });
    },
  },
  created() {
    this.$store.dispatch('coursedata/fetchInitalLoad').then(() => {
      this.updateStepper();
      this.initialLoadCompleted = true;
    });
  },
};
</script>
<style lang="scss">
.coursedata {
  .coursedata-header {
    color: var(--Default-Black, #000);
    padding: 0px 10px;
    /* Heading 1 */
    font-family: 'Tietoevry Sans 1';
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
  }
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
  .coursedata-hole-add-par {
    padding: 20px;
  }
  .courseguide-setup-stepper {
    padding: 20px;
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
  .element--is-loading {
    width: 100%;
    height: calc(100vh - 72px - 100px);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
