<template>
  <div>
    <div
      v-if="isLoading"
      class="skeleton animate-pulse"
      :style="{ height: height, width: width, borderRadius: shape }"></div>
  </div>
</template>

<script>
import store from '@/store';

export default {
  name: 'Skeleton',
  props: {
    height: {
      type: String,
      default: '100px',
    },
    width: {
      type: String,
      default: '300px',
    },
    shape: {
      type: String,
      default: '0rem',
    },
    loaderKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    isLoading() {
      const activeLoaders = store.getters['ui/activeLoaders'];
      return activeLoaders.includes(this.loaderKey);
    },
  },
};
</script>

<style lang="scss">
.skeleton {
  background-color: color(gray);
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
