var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hide)?_c('div',[(!_vm.user.username && _vm.position === 'menu')?_c('div',{staticClass:"side-navigation"},_vm._l((_vm.staticPages),function(page,i){return _c('tree-navigation',{key:i,attrs:{"nodes":page.subPages,"depth":1}})}),1):_c('div',[(_vm.position === 'menu')?[(_vm.pages.length > 0)?_c('div',{staticClass:"side-navigation",class:{ 'side-navigation__is-loading': _vm.menuIsLoading }},_vm._l((_vm.pages),function(page,i){return _c('tree-navigation',{key:i,attrs:{"nodes":page.subPages,"depth":1}})}),1):_vm._e()]:_vm._e(),(_vm.user.username && _vm.position === 'header')?_c('div',[_c('div',{staticClass:"side-navigation-nav--info",class:{ 'side-navigation-nav--info__open': _vm.showInfo },on:{"click":_vm.toggleInfo}},[_c('div',{staticClass:"side-navigation-nav--user"},[(_vm.user.club && _vm.user.club.name)?_c('span',{staticClass:"side-navigation-nav--user__name"},[_c('strong',[_vm._v(_vm._s(_vm.user.club.name))])]):_vm._e(),(_vm.selectedClub && _vm.selectedClub != 'Ej valt')?_c('span',{staticClass:"side-navigation-nav--user__name"},[_c('strong',[_vm._v(_vm._s(_vm.selectedClub))])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")])]),_c('div',{staticClass:"side-navigation-nav--user__logo"},[(_vm.user.club && _vm.user.club.logoUri)?_c('img',{attrs:{"src":_vm.user.club.logoUri}}):_vm._e()]),_c('div',{staticClass:"side-navigation-nav--info__arrow",class:{ 'side-navigation-nav--info__rotate': _vm.showInfo }})]),(_vm.showInfo)?_c('div',{staticClass:"side-navigation-nav--box"},[_c('div',{staticClass:"side-navigation-nav--box__content"},[_c('ul',[(
                  _vm.user.superUser ||
                  (_vm.user.roles.GolfOffice &&
                    _vm.user.roles.GolfOffice.some(
                      e => e.value === 'BusinessBoardAdmin'
                    ))
                )?_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedClub),expression:"selectedClub"}],staticClass:"side-navigation--golfclub-select",attrs:{"list":"clubs","name":"golfclub","id":"golfclub-select"},domProps:{"value":(_vm.selectedClub)},on:{"click":() => {
                      _vm.selectedClub = '';
                    },"change":_vm.selectGolfclub,"input":function($event){if($event.target.composing)return;_vm.selectedClub=$event.target.value}}}),_c('datalist',{attrs:{"id":"clubs"}},_vm._l((_vm.clubs),function(club){return _c('option',{key:club.value},[_vm._v(" "+_vm._s(`${club.displayName}`)+" ")])}),0)]):_vm._e(),_vm._l((_vm.userInfo[0].subPages),function(info,index){return _c('li',{key:index,on:{"click":_vm.toggleInfo}},[_c('router-link',{attrs:{"to":info.routeUrl}},[_c('strong',[_vm._v(_vm._s(_vm.$t(info.name)))])])],1)}),_c('li',{on:{"click":_vm.logOut}},[_c('router-link',{attrs:{"to":_vm.hasEconomicLicense ? '/businessboard' : '/'}},[_c('strong',{staticClass:"first-letter"},[_vm._v(_vm._s(_vm.$t('logOut')))])])],1)],2)])]):_vm._e()]):_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }