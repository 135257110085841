<template>
  <div class="ontag">
    <div class="ontag__content">
      <h1 class="first-letter">Avståndsmarkeringar</h1>
      <div class="greenmarkings">
        <div class="choose_course">
          <span>1. Välj slinga</span>
          <custom-select
            :selected="0"
            :options="courseSelect"
            @optionSelected="selectCourse" />
        </div>
        <div
          v-show="courseDistancemarkings"
          class="holes_area">
          <span>2. Välj hål</span>
          <div>
            <div
              v-for="i in courseDistancemarkings.holeDistances"
              :key="i.id"
              :class="holeClass(i.id)"
              @click="selectHole(i.id)">
              {{ i.holeNameNumber }}
            </div>
            <div class="hole_legend">
              <span class="dot_marked">Markerade</span>
              <span class="dot_unmarked">Omarkerade hål</span>
              <div
                v-if="timestamp"
                class="last-changed">
                Senast ändrad:<br />{{ timeFormat(timestamp) }}
              </div>
            </div>
          </div>
        </div>
        <div class="map_area">
          <span>3. Dra markeringarna till kartan</span>
          <div class="map_items">
            <div class="map_tags">
              <strong>Framkant</strong>
              <div class="tag_bg tag_front">
                <img
                  class="tag_icon tag_grab"
                  draggable="true"
                  @dragstart="dragging = 'front'"
                  @dragend="e => dropMarker('front', e)"
                  v-show="!shouldShow(markers.front)"
                  :src="require('@/assets/images/tag_front.svg')" />
                <img
                  class="tag_icon tag_faded"
                  v-if="shouldShow(markers.front)"
                  :src="require('@/assets/images/tag_blank_front.svg')" />
                <img
                  class="tag_hand tag_remove"
                  v-if="!shouldShow(markers.front)"
                  :src="require('@/assets/images/tag_hand.svg')" />
                <button
                  v-else
                  class="tag_remove"
                  @click="toggleMarker('front')">
                  Rensa
                </button>
              </div>
              <strong>Center</strong>
              <div class="tag_bg tag_center">
                <img
                  class="tag_icon tag_grab"
                  draggable="true"
                  @dragstart="dragging = 'center'"
                  @dragend="e => dropMarker('center', e)"
                  v-if="!shouldShow(markers.center)"
                  :src="require('@/assets/images/tag_center.svg')" />
                <img
                  class="tag_icon tag_faded"
                  v-if="shouldShow(markers.center)"
                  :src="require('@/assets/images/tag_blank_center.svg')" />
                <img
                  class="tag_hand tag_remove"
                  v-if="!shouldShow(markers.center)"
                  :src="require('@/assets/images/tag_hand.svg')" />
                <button
                  v-else
                  class="tag_remove"
                  @click="toggleMarker('center')">
                  Rensa
                </button>
              </div>
              <strong>Bakkant</strong>
              <div class="tag_bg tag_back">
                <img
                  class="tag_icon tag_grab"
                  draggable="true"
                  @dragstart="dragging = 'back'"
                  @dragend="e => dropMarker('back', e)"
                  v-if="!shouldShow(markers.back)"
                  :src="require('@/assets/images/tag_back.svg')"
                  @drop="e => dropMarker('back', e)" />
                <img
                  class="tag_icon tag_faded"
                  v-if="shouldShow(markers.back)"
                  :src="require('@/assets/images/tag_blank_back.svg')" />
                <img
                  class="tag_hand tag_remove"
                  v-if="!shouldShow(markers.back)"
                  :src="require('@/assets/images/tag_hand.svg')" />
                <button
                  v-else
                  class="tag_remove"
                  @click="toggleMarker('back')">
                  Rensa
                </button>
              </div>
              <button
                class="button--background-white"
                @click="removeMarkers()">
                Rensa alla
              </button>
            </div>
            <div class="map">
              <GmapMap
                id="gmap"
                @mouseover="e => dropMarker('over', e)"
                :center="center"
                :zoom="zoom"
                :options="{
                  streetViewControl: false,
                  fullscreenControl: false,
                }"
                map-type-id="hybrid"
                style="width: 100%; height: 30rem; top: 1rem">
                <GmapMarker
                  :position="markers.front"
                  :clickable="true"
                  :draggable="true"
                  :visible="shouldShow(markers.front)"
                  @click="center = markers.front"
                  :icon="require('@/assets/images/tag_front.svg')"
                  @dragend="update('front', $event.latLng)" />
                <GmapMarker
                  :position="markers.center"
                  :clickable="true"
                  :draggable="true"
                  :visible="shouldShow(markers.center)"
                  @click="center = markers.center"
                  :icon="require('@/assets/images/tag_center.svg')"
                  @dragend="update('center', $event.latLng)" />
                <GmapMarker
                  :position="markers.back"
                  :clickable="true"
                  :draggable="true"
                  :visible="shouldShow(markers.back)"
                  @click="center = markers.back"
                  :icon="require('@/assets/images/tag_back.svg')"
                  @dragend="update('back', $event.latLng)" />
              </GmapMap>
              <div
                class="map_geoloc"
                v-element-loader="{ loaderKey: 'courses-load-geolocation' }"
                @click="gotoGeo()">
                <img :src="require('@/assets/images/compass.svg')" />
                Visa min position
              </div>
              <div
                class="map_header"
                v-show="courseName">
                Slinga <strong>{{ courseName }}</strong>
                <br />
                Hål <strong>{{ selectedHoleNameNumber }}</strong>
              </div>
            </div>
            <div
              v-if="!courseDistancemarkings"
              class="overlay"></div>
          </div>
        </div>
      </div>
      <div>
        <div
          v-if="courseDistancemarkings"
          style="float: right">
          <div>
            <toggle-switch
              class="greenmarking-switch"
              type="switch"
              :toggled="courseDistancemarkings.useDistanceMarking"
              @toggle="toggleUseDistanceMarking" />
            <span>Aktivera avståndsmarkering för vald slinga</span>
          </div>
          <div class="input--flex">
            <base-button
              class="button--background-white"
              v-element-loader="{ loaderKey: 'courses-save-distancemarking' }"
              @click="saveHole"
              text="Spara" />
            <base-button
              class="button--background-gray-dark"
              v-element-loader="{ loaderKey: 'courses-save-distancemarking' }"
              @click="saveHole(true)"
              text="Spara och gå vidare till nästa hål" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthsLabelsShort } from '@/core/consts';

import CustomSelect from '@/components/form-elements/custom-select';
import ToggleSwitch from '@/components/form-elements/switch';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import EventBus from '@/core/event-bus.js';

export default {
  name: 'GreenMarking',
  components: {
    ToggleSwitch,
    CustomSelect,
    BaseButton,
  },
  props: {},
  directives: {
    elementLoader,
  },
  data() {
    return {
      courseName: '',
      zoom: 4.5,
      center: { lat: 63, lng: 13.5 },
      markers: {
        front: { lat: 0, lng: 0 },
        center: { lat: 0, lng: 0 },
        back: { lat: 0, lng: 0 },
      },
      rotation: 0,
      selectedLayer: 'google',
      selectedHole: 0,
      selectedHoleNameNumber: '',
      geolocPosition: undefined,
      showMobileFlow: false,
      currentStep: 0,
      allowArrival: false,
      showMarked: false,
      holes18: true,
      holes9: true,
      courseDistancemarkings: false,
      dragging: '',
      dropping: '',
    };
  },
  methods: {
    selectHole(e) {
      this.selectedHole = e;
      if (this.courseDistancemarkings.holeDistances) {
        let hole = this.courseDistancemarkings.holeDistances.find(
          h => h.id === this.selectedHole
        );
        if (hole) {
          this.selectedHoleNameNumber = hole.holeNameNumber;
          this.markers = {
            front: { lng: hole.greenFrontLon, lat: hole.greenFrontLat },
            center: { lng: hole.greenCenterLon, lat: hole.greenCenterLat },
            back: { lng: hole.greenBackLon, lat: hole.greenBackLat },
          };
          if (hole.greenCenterLon !== 0 || hole.greenCenterLat !== 0) {
            this.center = {
              lng: hole.greenCenterLon,
              lat: hole.greenCenterLat,
            };
            if (this.zoom < 10) this.zoom = 17;
          }
        }
      }
    },
    selectCourse(course) {
      this.courseName = course.displayName;
      this.$store
        .dispatch('courses/fetchCourseDistancemarkings', {
          courseId: course.value,
        })
        .then(() => {
          this.courseDistancemarkings =
            this.$store.state.courses.courseDistancemarkings;
          this.selectHole(this.courseDistancemarkings.holeDistances[0].id);
        });
    },
    holeClass(holeId) {
      if (
        this.courseDistancemarkings &&
        this.courseDistancemarkings.holeDistances
      ) {
        let hole = this.courseDistancemarkings.holeDistances.find(
          h => h.id === holeId
        );
        if (
          hole &&
          hole.greenFrontLon !== 0 &&
          hole.greenFrontLat !== 0 &&
          hole.greenCenterLon !== 0 &&
          hole.greenCenterLat !== 0 &&
          hole.greenBackLon !== 0 &&
          hole.greenBackLat !== 0
        ) {
          if (holeId === this.selectedHole) {
            return 'hole done_hole selected';
          }
          return 'hole done_hole';
        }
      }
      if (holeId === this.selectedHole) return 'hole selected';
      return 'hole';
    },
    update(pos, loc) {
      this.markers[pos].lat = loc.lat();
      this.markers[pos].lng = loc.lng();
    },
    show() {
      this.currentStep = 0;
      this.showMobileFlow = !this.showMobileFlow;
    },
    mark() {
      if (!('geolocation' in navigator)) {
        this.errorStr = 'Geolocation is not available.';
        return;
      }
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.geolocPosition = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          this.showMarked = true;
          this.markers[['front', 'center', 'back'][this.currentStep - 1]] =
            this.geolocPosition;
        },
        err => {
          this.errorStr = err.message;
        }
      );
    },
    next() {
      this.currentStep++;
      this.showMarked = false;
    },
    toggleUseDistanceMarking(e) {
      if (this.selectedHole) {
        this.$store
          .dispatch('courses/useDistancemarking', {
            id: this.courseDistancemarkings.id,
            useDistanceMarking: e,
          })
          .then(() => {
            this.courseDistancemarkings.useDistanceMarking = e;
          });
      }
    },
    saveHole(next = false) {
      if (this.courseDistancemarkings.holeDistances) {
        let hole = this.courseDistancemarkings.holeDistances.find(
          h => h.id === this.selectedHole
        );
        hole.greenFrontLon = this.markers.front.lng;
        hole.greenFrontLat = this.markers.front.lat;
        hole.greenCenterLon = this.markers.center.lng;
        hole.greenCenterLat = this.markers.center.lat;
        hole.greenBackLon = this.markers.back.lng;
        hole.greenBackLat = this.markers.back.lat;
        this.$store
          .dispatch('courses/patchDistancemarking', {
            hole: hole,
            courseId: this.courseDistancemarkings.id,
          })
          .then(() => {
            this.currentStep = 0;
            this.showMobileFlow = false;
            this.$store
              .dispatch('courses/fetchCourseDistancemarkings', {
                courseId: this.courseDistancemarkings.id,
              })
              .then(() => {
                this.courseDistancemarkings =
                  this.$store.state.courses.courseDistancemarkings;
                if (next) {
                  let holeIndex =
                    this.courseDistancemarkings.holeDistances.findIndex(
                      h => h.id === this.selectedHole
                    );
                  if (
                    holeIndex + 1 <
                    this.courseDistancemarkings.holeDistances.length
                  ) {
                    this.selectHole(
                      this.courseDistancemarkings.holeDistances[holeIndex + 1]
                        .id
                    );
                  }
                }
              });
          });
      }
    },
    distance(markers) {
      let lon1 = markers.lng;
      let lat1 = markers.lat;
      let lon2 = this.geolocPosition.lng;
      let lat2 = this.geolocPosition.lat;
      // https://stackoverflow.com/a/21623206
      var p = 0.017453292519943295;
      var c = Math.cos;
      var a =
        0.5 -
        c((lat2 - lat1) * p) / 2 +
        (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

      return 12.742 * Math.asin(Math.sqrt(a));
    },
    shouldShow(c) {
      if (c.lng === 0.0 && c.lat === 0.0) return false;
      return true;
    },
    toggleMarker(p) {
      if (this.markers[p].lng === 0 && this.markers[p].lat === 0) {
        this.markers[p] = { lng: this.center.lng, lat: this.center.lat };
      } else {
        this.markers[p] = { lng: 0.0, lat: 0.0 };
      }
    },
    removeMarkers() {
      this.markers['front'] = { lng: 0.0, lat: 0.0 };
      this.markers['center'] = { lng: 0.0, lat: 0.0 };
      this.markers['back'] = { lng: 0.0, lat: 0.0 };
    },
    dropMarker(p, e) {
      if (p === 'over' && this.dropping) {
        this.markers[this.dropping] = {
          lng: e.latLng.lng(),
          lat: e.latLng.lat(),
        };
        this.dropping = '';
      }
      this.dragging = '';
    },
    timeFormat(d) {
      if (!d) {
        return;
      }

      d = new Date(d);

      return `${d.getDate()} ${this.$t(
        `MonthsShort.${MonthsLabelsShort[d.getMonth()]}`
      ).toLowerCase()} ${d.getFullYear()}, ${('0' + d.getHours()).slice(-2)}:${(
        '0' + d.getMinutes()
      ).slice(-2)}`;
    },
    gotoGeo() {
      this.$store.dispatch(
        'ui/toggleActiveLoaders',
        'courses-load-geolocation',
        { root: true }
      );
      if (!('geolocation' in navigator)) {
        this.errorStr = 'Geolocation is not available.';
        return;
      }
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.center = { lng: pos.coords.longitude, lat: pos.coords.latitude };
          this.$store.dispatch(
            'ui/toggleActiveLoaders',
            'courses-load-geolocation',
            { root: true }
          );
        },
        err => {
          this.$store.dispatch(
            'ui/toggleActiveLoaders',
            'courses-load-geolocation',
            { root: true }
          );
          EventBus.$emit('toast', { type: 'error', msg: err.message });
        }
      );
    },
  },
  computed: {
    courseSelect() {
      var courses = this.$store.getters['courses/courseSelect'];
      if (courses.length > 1) {
        courses.unshift({ displayName: '', value: 0 });
      }
      return courses;
    },
    holeSelect() {
      let holes = [];
      if (
        this.courseDistancemarkings &&
        this.courseDistancemarkings.holeDistances
      ) {
        for (let hole of this.courseDistancemarkings.holeDistances) {
          holes.push({ value: hole.id, displayName: hole.holeName });
        }
      }
      return holes;
    },
    timestamp() {
      if (
        this.courseDistancemarkings &&
        this.courseDistancemarkings.holeDistances &&
        this.selectedHole
      ) {
        let hole = this.courseDistancemarkings.holeDistances.find(
          h => h.id === this.selectedHole
        );
        if (hole && 'lastChanged' in hole) {
          return hole.lastChanged;
        }
      }
      return '';
    },
  },
  created() {
    this.$store.dispatch('courses/fetchCourseSelect').then(() => {
      this.selectCourse(this.courseSelect[0]);
    });
  },
  mounted() {
    var gmap = document.getElementById('gmap');
    gmap.addEventListener(
      'dragover',
      event => {
        event.preventDefault();
      },
      false
    );
    gmap.addEventListener('drop', event => {
      this.dropping = this.dragging;
      event.preventDefault();
    });
  },
};
</script>
<style lang="scss" scoped>
.hole {
  background-color: #ebebeb;
  display: inline-block;
  width: 2.6rem;
  height: 2rem;
  text-align: center;
  margin: 0.3rem;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  vertical-align: middle;
  line-height: 2rem;
}
.hole:hover {
}
.done_hole {
  background-color: #595959;
  color: white;
}
.saved_hole {
}
.selected {
  font-weight: bold;
  border: 0.2rem solid #dba76a;
  width: 3rem;
  height: 2.4rem;
  line-height: 2rem;
  margin: 0.1rem;
}
.mobile-flow {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: white;
  text-align: center;
}
.mobile-flow-title {
  background-color: color(blue);
  color: white;
  margin-top: 4rem;
  margin-bottom: 1rem;
  padding: 1rem;
}
.bottom {
  position: absolute;
  bottom: 3rem;
  width: 100%;
}
.step {
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  text-align: center;
  border: 3px solid color(gray-light);
  border-radius: 50%;
  margin: 0.3rem;
  background-color: color(gray-light);
  vertical-align: middle;
  line-height: 2.66rem;
}
.step.done {
  border-color: color(blue);
  background-color: color(blue);
  color: white;
}
.step.current {
  background-color: white;
  border-color: color(blue);
}
.hr {
  display: inline-block;
  width: 1.5rem;
  height: 0px;
  border: 1px solid color(gray-light);
  margin: 0.1rem;
  margin-top: 1.4rem;
}
.hr.done {
  border-color: color(blue);
}
ol > li::marker,
.step-highlight {
  font-weight: bold;
  color: color(blue);
}
.marked {
  color: color(active-color);
}
.mobile {
  @include media('small') {
    display: none;
  }
}
.nomobile {
  @include media('to-small') {
    display: none;
  }
}
.mobile-flow-btn {
  display: block;
  width: 60%;
  margin: 1rem auto;
  justify-content: center;
  height: 2.6rem;
  padding: 0 measure(half);
  color: color(white);
  border-radius: 0.6rem;
  background-color: color(blue);
  border: 0;
}
.mobile-flow-btn:disabled {
  background-color: color(blue-light);
}
.mobile-flow-btn.abort {
  background-color: color(red);
}
.mobile-flow-btn.back {
  background-color: color(white);
  color: color(blue);
  border: 2px solid color(blue);
}
.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}
.map_pins {
  display: grid;
  grid-template-columns: auto;
  text-align: center;
}
.tag {
  position: relative;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  padding: 0.5rem;
  cursor: pointer;
  &_icon {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 2rem;
    top: 1.4rem;
  }
  &_grab {
    cursor: grab;
  }
  &_hand {
    width: 1rem;
  }
  &_text {
    bottom: 0.5rem;
    width: 100%;
    color: white;
  }
  &_bg {
    background: #105f87;
    border-radius: 6px;
    width: 8rem;
    height: 7rem;
    margin: auto;
    position: relative;
    > button {
      background-color: white;
      border: 1px solid black;
      color: black;
      border-radius: 2px;
    }
  }
  &_front {
    background: #105f87;
  }
  &_center {
    background: #304834;
  }
  &_back {
    background: #683780;
  }
  &_faded {
    opacity: 0.4;
  }
  &_remove {
    position: absolute;
    bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    left: 1.5rem;
    right: 1.5rem;
  }
}
.input--flex {
  padding: 0.2rem;
  vertical-align: bottom;
}

.last-changed {
  font-style: italic;
}

.choose_course {
  grid-area: courses;
  display: grid;
  grid-template-columns: 7rem auto;
  background-color: white;
  padding: 0.5rem;
}
.map_area {
  grid-area: map;
  position: relative;
}
.map_items {
  display: grid;
  grid-template-columns: 10rem auto;
}
.holes_area {
  grid-area: holes;
  display: grid;
  grid-template-columns: 7rem auto;
}
.greenmarkings {
  display: grid;
  grid-template-columns: auto 38rem;
  grid-template-rows: 9rem auto;
  grid-template-areas:
    'courses holes'
    'map map';
  gap: 1rem;
  > div {
    background-color: white;
    padding: 1rem;
  }
}
.ontag__content {
  background-color: #f7f7f7;
}
.dot_marked::before,
.dot_unmarked::before {
  margin-right: 0.2rem;
  margin-left: 0.3rem;
  display: inline-block;
  content: '';
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
.dot_marked::before {
  background-color: #595959;
}
.dot_unmarked::before {
  background-color: #ebebeb;
}
.hole_legend {
  display: grid;
  grid-template-columns: 6rem auto 8rem;
  align-items: center;
  font-size: 0.8rem;
  height: 2rem;
}
.map {
  position: relative;
}
.map_geoloc {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  background-color: white;
  border: 1px solid black;
  border-radius: 2px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  > img {
    margin-right: 0.5rem;
  }
}
.map_header {
  position: absolute;
  width: 20rem;
  background-color: #f1ece3;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 1rem;
  text-align: center;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 0 0 2rem 2rem;
  strong {
    font-size: 1.1rem;
  }
  padding: 0.3rem;
  line-height: 1.5rem;
}
.map_tags {
  text-align: center;
  > strong {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
  }
}
.button--background-gray-dark {
  background-color: #363636;
  margin: 1rem;
}
.button--background-white {
  background-color: #fff;
  color: #363636;
  border: 1px solid #363636;
  margin: 1rem;
}
.greenmarking-switch {
  display: inline-block;
  margin: 0.8rem 0.5rem 0rem 1rem;
}
</style>
