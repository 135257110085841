<template>
  <div class="onservice">
    <div class="onservice--content">
      <h1 class="first-letter">{{ $t('articles') }}</h1>
      <section class="articles--actions">
        <base-button
          class="articles--actions__category"
          :text="$t('addCategory')"
          @click="$router.push({ path: '/onservice/store/category/create' })"/>
      </section>
      <section class="article--category" v-for="category in result" :key="category.id">
        <div class="category__title">
          <h2 class="category__title--title">{{ category.name }}</h2>
          <div class="category__edit">
            <button
              v-if="!category.isGreenFee"
              class="button button--icon-only no-border transparent"
              @click="$router.push({ path: `/onservice/store/category/edit/${category.id}` })">
              <use-svg svg="edit-icon" />
            </button>
            <button
              v-if="!category.isGreenFee"
              class="button button--icon-only no-border transparent"
              @click="deleteCategory(category.id)">
              <use-svg svg="trash-icon" />
            </button>
          </div>
        </div>
        <div class="category__articles">
          <list-table-articles
            :items="category.articles.rows"
            :headers="category.articles.columns">
          </list-table-articles>
        </div>
        <div class="category__action">
          <base-button
            :text="$t('addArticle')"
            @click="addArticle(category.id)"/>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import ListTableArticles from '@/components/list-table/list-table-articles';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
export default {
  name: 'OnServiceArticles',
  components: {
    ListTableArticles,
    BaseButton,
    UseSvg,
  },
  computed: {
    result () {
      return this.$store.getters['articles/categories'];
    },
  },
  mounted () {
    this.$store.dispatch('articles/fetchArticles');
  },
  methods: {
    deleteCategory (id) {
      this.$store.dispatch('articles/deleteCategory', id);
    },
    addArticle (id) {
      const categoryIndex = this.getCategoryIndex(id);
      this.$router.push({ name: `onservice-create-article`, params: { categoryId: id, select: this.result, selected: categoryIndex } });
    },
    getCategoryIndex (id) {
      return this.result.findIndex(c => c.id === id);
    },
  },
};
</script>
<style lang="scss">
.onservice {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.onservice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.articles--actions {
  display: flex;
}
.articles--actions__article {
  margin-left: auto;
}
.articles--actions__category {
  margin-left: auto;
}
.article--category {
  margin: 1rem 0;
}
.category__title {
  background-color: color(gray-light);
  display: flex;
}
.category__title--title {
  margin: 0;
  padding: 0.875rem;
}
.category__edit {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 1rem;
  button {
    color: color(gray-dark);
  }
}
.category__action {
  margin-top: 0.5rem;
}
.article--edit {
  text-align: center;
}
.article--edit button {
  background-color: transparent;
  border: none;
  color: color(gray-dark);
}
</style>
