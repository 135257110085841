var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"ageGroup-wrapper row-height"},[_vm._m(0),_c('td',[_c('div',{staticClass:"display-div"},[_c('p',{staticClass:"ageGroup"},[_vm._v(_vm._s(_vm.getAgeGroupReference(_vm.ageGroup))+" år")])])]),_vm._m(1),_vm._m(2),(_vm.ageGroup)?_c('td',[_c('div',{staticClass:"display-div"},[_c('div',{staticClass:"display-flex"},[_c('input',{attrs:{"type":"text","aria-label":"Budgeteratantal","placeholder":_vm.ageGroup ? _vm.ageGroup.quantityBudgeted : 0},domProps:{"value":_vm.ageGroup.quantityBudgeted ? _vm.ageGroup.quantityBudgeted : 0},on:{"change":event =>
              _vm.updateAgeGroupQuantity(
                event.target.value,
                _vm.ageGroup,
                _vm.categoryReference
              )}}),_c('p',{staticClass:"input-unit"},[_vm._v("st")])])]),_c('div',[(_vm.showPreviousYear)?_c('p',{staticClass:"previous-year-amount"},[_vm._v(" "+_vm._s(_vm.previousYearData ? _vm.previousYearData.quantityBudgeted + ' st' : 0 + ' st')+" ")]):_vm._e()])]):_vm._e(),_vm._l((_vm.feeReference),function(fee,index){return [_c('td',{key:'fee' + index},[_c('div',{staticClass:"display-div"},[_c('div',{staticClass:"display-flex"},[(_vm.checkId(fee))?[_c('input',{attrs:{"type":"text","aria-label":"Avgifter","placeholder":_vm.findAgeGroupFeeAmount(fee)},domProps:{"value":_vm.findAgeGroupFeeAmount(fee)},on:{"change":event =>
                  _vm.updateAgeGroupFeeAmount(
                    event.target.value,
                    _vm.findAgeGroupFeeId(fee),
                    _vm.ageGroup
                  )}})]:[_c('input',{attrs:{"type":"text","aria-label":"Avgifter","placeholder":0},domProps:{"value":0},on:{"change":event =>
                  _vm.updateAgeGroupFeeAmount(
                    event.target.value,
                    fee.id,
                    _vm.ageGroup
                  )}})],_c('p',{staticClass:"input-unit"},[_vm._v("kr")])],2)]),_c('div',[(_vm.showPreviousYear)?_c('p',{staticClass:"previous-year-amount"},[_vm._v(" "+_vm._s(_vm.findAgeGroupFeeAmountFromPreviousYear(fee) + ' kr')+" ")]):_vm._e()])])]}),_vm._m(3)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"display-div"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"display-div"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"display--div"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"display-div"})])
}]

export { render, staticRenderFns }