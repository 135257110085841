<template>
  <div class="businessboard">
    <download-modal
      :selectedImageFormat="copyAndDownloadSettings().fileFormat"
      @close="() => (this.showModal = false)"
      :show="this.showModal" />

    <div class="businessboard--content">
      <div>
        <last-updated
          :last-update="this.lastMemberDataImport"
          :show-member="true"
          :show-game="false"
          :show-economy="false" />
      </div>

      <div class="businessboard--header">
        <h1 class="header">
          {{ $t('Businessboard.membershipCategoriesGroups') }}
          <span
            v-if="isLoading"
            class="element--is-loading" />
        </h1>

        <div class="ingress">
          {{ $t('MemberCalculation.descriptionOfTheCategoryView') }}
        </div>
      </div>

      <div v-if="membershipCalculationCategories">
        <settings-modal
          :show="showSettingsModal"
          :titleTextTranslationKey="'MemberCalculation.diagramAndTable'"
          :options="yearOptions"
          :selected="{
            currentYear: membershipCalculationCategories?.settings.currentYear,
            comparisonYear:
              membershipCalculationCategories?.settings.comparisonYear,
          }"
          @close="showSettingsModal = false"
          @updateSettings="args => updateMembershipCalculationSettings(args)" />

        <div class="dashboard-buttons">
          <base-button
            style="margin-right: 10px"
            backgroundColor="bb-primary"
            class="button-green-hover"
            :text="`${$t('Businessboard.settings')} ${$t(
              'diagram'
            ).toLowerCase()}`"
            @click="showSettingsModal = !showSettingsModal">
            <use-svg svg="bb-gear-icon" />
          </base-button>

          <base-button
            @click="copyData"
            backgroundColor="white"
            :text="$t(buttonTextLabel)">
            <use-svg svg="bb-copy-icon" />
          </base-button>
        </div>

        <section
          v-if="membershipCalculationCategories.hasPremium"
          class="businessboard--table">
          <membershipcategories-dashboard-diagrams
            :data="membershipCalculationCategories.diagrams"
            :settings="membershipCalculationCategories.settings"
            :legends="this.membershipCalculationCategories.legends" />

          <div class="list-table-area">
            <list-table-game-calculation
              :isLoading="isLoading"
              :items="membershipCalculationCategories.rows"
              :headers="membershipCalculationCategories.columns" />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { toPng, toJpeg, toBlob } from 'html-to-image';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

import BaseButton from '@/components/form-elements/base-button';
import DownloadModal from '@/components/businessboard/download-modal';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import ListTableGameCalculation from '@/components/list-table/list-table-game-calculation';
import MembershipcategoriesDashboardDiagrams from '@/components/businessboard/membershipcategories-dashboard-diagrams';
import SettingsModal from '@/components/businessboard/settings-modal/settings-modal';
import UseSvg from '@/components/use-svg';

import { legends } from '@/views/businessboard/consts';

const buttonText = ref('');
const selectedDiagramId = ref('');

export default {
  name: 'MembershipCalculationCategories',

  mixins: [LocalizationMixin],

  provide() {
    return {
      lastUpdate: () => {
        // eslint-disable-next-line no-unused-vars
        return this.lastMemberDataImport;
      },
      openModal: diagramId => {
        this.showModal = true;
        selectedDiagramId.value = diagramId;
      },
      closeModal: () => (this.showModal = false),
      download: fileFormat => {
        this.showModal = false;
        this.download(fileFormat);
      },
      copy: diagramId => {
        this.showModal = false;
        this.copy(diagramId);
      },
      fileFormat: () => this.copyAndDownloadSettings().fileFormat,
      showHeaders: () => this.copyAndDownloadSettings().showHeaders,
    };
  },

  components: {
    UseSvg,
    ListTableGameCalculation,
    MembershipcategoriesDashboardDiagrams,
    DownloadModal,
    BaseButton,
    SettingsModal,
    LastUpdated,
  },

  data() {
    return {
      isLoading: true,
      showModal: false,
      showSettingsModal: false,
      buttonTextLabel: buttonText,
    };
  },

  computed: {
    yearOptions() {
      const options =
        this.membershipCalculationCategories.settings.availableYears.map(y => ({
          value: y,
          displayName: y,
        }));
      return options.reverse();
    },
    membershipCalculationCategories() {
      const fetchedData =
        this.$store.getters['businessboard/membershipcalculationcategories'];
      return {
        ...fetchedData,
        diagrams: {
          ...fetchedData?.diagrams,
          members: {
            ...fetchedData?.diagrams?.members,
            colorKeys: ['bb-sec2', 'purple-light'],
          },
        },
        legends,
      };
    },
    businessBoardSettings() {
      return this.$store.getters['businessboard/settings'];
    },
    lastMemberDataImport() {
      // TODO: enums for .type (src/components/last-updated/last-updated.vue)
      return this.$store.getters['businessboard/lastUpdate'].filter(
        u => u.type === 2
      );
    },
  },

  methods: {
    copyAndDownloadSettings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    updateButtonText: text => {
      this.copyButtonText = text;
    },
    async getMembershipCalculation() {
      if (this.businessBoardSettings.hasGolfBox) {
        this.$store.dispatch(
          'businessboard/fetchMembershipCalculationGbCategories'
        );
      } else {
        this.$store.dispatch(
          'businessboard/fetchMembershipCalculationCategories'
        );
      }
    },
    updateMembershipCalculationSettings: function (args) {
      if (this.businessBoardSettings.hasGolfBox) {
        this.$store.dispatch('businessboard/updateCategoriesGbSettings', {
          ...args,
        });
      } else {
        this.$store.dispatch('businessboard/updateCategoriesSettings', {
          ...args,
        });
      }
    },

    copyData() {
      let text = '';
      for (const header of this.membershipCalculationCategories.columns) {
        text += header.displayName.replace(/\r?\n|\r/g, ' ');

        for (const item of this.membershipCalculationCategories.rows) {
          text += '\t' + item[header.value]?.replace(/\r?\n|\r/g, ' ');
        }

        text += '\n';
      }

      navigator.clipboard.writeText(text).then(
        function () {
          buttonText.value = 'copiedToClipboard';
        },
        function (err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
    },

    copy(diagramId) {
      if (diagramId === '') {
        return;
      }

      toBlob(document.getElementById(`diagram_${diagramId}`))
        .then(function (blob) {
          navigator.clipboard.write([
            // eslint-disable-next-line no-undef
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
        })
        .catch(function (error) {
          console.error('Oops, something went wrong!', error);
        });
    },

    download(fileFormat) {
      if (selectedDiagramId.value === '') {
        return;
      }
      const toFile = fileFormat === '0' ? toJpeg : toPng;

      const downloadOptions =
        fileFormat === '0'
          ? { backgroundColor: 'white' }
          : { backgroundColor: 'transparent' };

      const diagramElement = document.getElementById(
        `diagram_${selectedDiagramId.value}`
      );

      toFile(diagramElement, downloadOptions)
        .then(function (dataUrl) {
          var link = document.createElement('a');

          link.download = `${diagramElement.getAttribute(
            'headingtranslationkey'
          )}_${selectedDiagramId.value}.${fileFormat === '0' ? 'jpg' : 'png'}`;

          link.href = dataUrl;

          link.click();
        })
        .catch(function (error) {
          console.error('Oops, something went wrong!', error);
        });
    },
  },

  created() {
    this.$store.dispatch('businessboard/fetchSettings').then(() => {
      this.getMembershipCalculation().then(() => {
        Promise.allSettled([
          this.$store.dispatch('businessboard/fetchLastUpdate'),
          this.$store.dispatch('businessboardReportingTool/fetchSettings'),
        ]).then(() => (this.isLoading = false));
        buttonText.value = 'copyTableData';
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.businessboard {
  flex-grow: 1;
  width: 100%;

  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }

  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }

  .businessboard--header {
    height: 3rem;
    margin: 1rem 0;

    .header {
      line-height: 0.5;
    }

    .ingress {
      font-size: 20px;
    }

    .element--is-loading {
      margin-left: 0.5rem;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
      background-size: 2rem 2rem;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .businessboard--content {
    background-color: color(gray-light);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;

    .dashboard-buttons {
      margin-top: 2rem;
      display: flex;

      .businessboard--table {
        margin-bottom: 2rem;
      }
    }

    .list-table-area {
      padding: 1rem;
      margin-top: 1rem;

      background: color(white);

      border-radius: 8px;
    }
  }
}
</style>
