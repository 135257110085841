<template>
  <div class="te-input">
    <label :for="name">{{ this.title }}</label>
    <input
      class="te-input-field"
      :error="error"
      :required="required"
      :type="type"
      :data-invalid="invalid"
      :autocomplete="autocomplete"
      :name="name"
      v-bind:value="localValue"
      @input="e => updateListeners(e.target.value)"
      :placeholder="placeholder" />
    <div class="te-input-error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'TeInputField',
  props: {
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    required: {
      type: String,
    },
    type: {
      type: String,
    },
    pattern: {
      type: String,
    },
    error: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
  },
  watch: {
    value: {
      handler: function (newVal) {
        this.localValue = newVal;
      },
      deep: true,
    },
  },
  methods: {
    updateListeners(val) {
      if (this.pattern) {
        let r = new RegExp(this.pattern, 'gi');
        this.invalid = !r.test(val);
      }
      this.localValue = val;
      this.$emit('changed', this.localValue, this.name);
    },
  },
  data() {
    return {
      localValue: this.value,
      invalid: false,
    };
  },
};
</script>

<style lang="scss">
.te-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  gap: 4px;
  label {
    color: var(--text-text-primary, #1e2224);
    /* Heading 5 */
    font-family: 'Tietoevry sans 1';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  .te-input-field {
    all: unset;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    padding: 10px var(--paddings-padding-s, 8px);
    overflow: hidden;
    color: var(--text-text-secondary, #313538);

    text-overflow: ellipsis;
    white-space: nowrap;
    /* Paragraph text */
    font-family: 'Tietoevry sans 1';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    border-radius: 4px;
    outline: 1px solid var(--dividers-borders-border-selector-primary, #a9aaac);
    background: var(--surface-surface-form-default, #fff);
  }
  .te-input-field::placeholder {
    color: var(--text-text-tertiary, #64676a);
  }
  .te-input-field:hover:not([disabled]):not([data-invalid]),
  .te-input-field:focus-visible:not([disabled]):not([data-invalid]) {
    outline-color: var(--dividers-borders-border-selector-hover, #1861f2);
  }
  .te-input-field:focus-visible:not([disabled]):not([data-invalid]) {
    box-shadow: 0px 0px 0px 4px rgba(24, 97, 242, 0.4);
  }
  .te-input-field:active:not([disabled]) {
    outline-color: var(--blue-blue-2, #0c4fd4);
  }
  .te-input-field:disabled {
    opacity: 0.4;
  }
  .te-input-field[data-invalid] {
    outline-color: var(--surface-surface-form-warning, rgba(216, 47, 51, 1));
  }
  .te-input-field:focus-visible[data-invalid]:not([disabled]) {
    box-shadow: 0px 0px 0px 4px rgba(216, 47, 51, 0.4);
  }
  .te-input-error {
    color: var(--surface-surface-form-warning, #d82f33);
    /* Paragraph text */
    font-family: 'Tietoevry sans 1';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .te-input-error {
    display: none;
  }
  .te-input-field[data-invalid] + .te-input-error:not(:empty) {
    display: block;
  }
}
</style>
