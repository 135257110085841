<template>
  <div class="onservice">
    <div class="onservice--content">
      <router-link
to="/onservice/store/articles">&laquo; {{ $t('back') }}</router-link>
      <h1 v-if="id === ''">{{ $t('Create category') }}</h1>
      <h1 v-else>{{ $t('Edit category') }}</h1>
      <section>
        <input-type
          :label="$t('name')"
          type="text"
          ref="categoryName"
          input-id="categoryName"
          :required="true" />
        <input-type
          :label="$t('vat')"
          type="number"
          ref="categoryVat"
          input-id="categoryVat"
          :required="false" />
        <div class="input-group input--flex">
          <label
            for="categoryActive"
            class="input__label first-letter">{{ $t('active') }}</label>
          <toggle-switch
            id="categoryActive"
            name="categoryActive"
            input-id="categoryActive"
            type="switch"
            :toggled="categoryActive"
            @toggle="toggleCategoryActive" />
        </div>
      </section>
      <section class="article--actions">
        <base-button
          :text="$t('cancel')"
          class="button--background-gray-dark"
          @click="$router.push('/onservice/store/articles')" />
        <base-button
          v-element-loader="{ loaderKey: 'store-create-category' }"
          text="Spara"
          @click="createCategory" />
      </section>
    </div>
  </div>
</template>

<script>
import InputType from '@/components/form-elements/input';
import ToggleSwitch from '@/components/form-elements/switch';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
export default {
  name: 'OnServiceEditCategory',
  components: {
    InputType,
    ToggleSwitch,
    BaseButton,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      categoryActive: false,
    };
  },
  methods: {
    toggleCategoryActive(boolean) {
      this.categoryActive = boolean;
    },
    createCategory() {
      if (this.id === '') {
        this.$store
          .dispatch('articles/createCategory', {
            name: this.$refs.categoryName.dataValue,
            vat: this.$refs.categoryVat.dataValue,
            active: this.categoryActive,
          })
          .then(() => {
            this.$router.push({ path: '/onservice/store/articles' });
          });
      } else {
        this.$store
          .dispatch('articles/editCategory', {
            id: this.id,
            name: this.$refs.categoryName.dataValue,
            vat: this.$refs.categoryVat.dataValue,
            active: this.categoryActive,
          })
          .then(() => {
            this.$router.push({ path: '/onservice/store/articles' });
          });
      }
    },
  },
  mounted() {
    if (this.category) {
      this.$refs.categoryName.dataValue = this.category.name
        ? this.category.name
        : '';
      this.$refs.categoryVat.dataValue = this.category.vat
        ? this.category.vat
        : '';
      this.categoryActive =
        this.category.active !== undefined ? this.category.active : true;
    }
  },
  computed: {
    category() {
      return this.$store.getters['articles/category'](this.id);
    },
  },
};
</script>
<style lang="scss">
.onservice {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.onservice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.article--actions {
  display: flex;
  margin-left: auto;
}
.article--actions button:not(:first-child) {
  margin-left: 1rem;
}
</style>
