<template>
  <div class="ontag">
    <div class="ontag--content">
      <h1>{{ $t('print') }} {{ $t('neutral') }} {{ $t('scorecard') }}</h1>
      <p>Här gör ni era val för att skriva ut ett neutralt scorekort. Dvs ett scorekort utan spelare och tid. Lämpligt tex att placera i receptionen när den är obemannad.</p>
      <div class="print-neutral-scorecards">
        <h2 class="title-variation">
          <use-svg svg="print-icon"/>
          {{ $t('print')}}
        </h2>
        <p>Nedan väljer ni slinga, antal och vilka tee som ska skrivas ut på mallen, vilken skrivare ni önskar utskriften till samt hur många neutrala kort vill ni skriva ut vid detta tillfälle.</p>
        <div>
          <custom-select
            v-if="selectedCourseIndex != null"
            :label="$t('loop')"
            :options="courseSelect"
            :selected="selectedCourseIndex"
            @optionSelected="e => selectOption(e)"
            :disabled="courseSelect.length === 1" />
          <div>
            <span>{{ $t('tee') }}*</span>
            <div
              v-if="tees.length > 0 || courseTees"
              class="input-group">
              <template v-for="(t, index) in courseTees">
                <div :key="index" class="input checkbox">
                  <div class="checkbox--group">
                    <input
                      type="checkbox"
                      :id="'tee-' + index"
                      name="'tee-' + index"
                      :value="t.value"
                      v-model="tees">
                    <label :for="'tee-' + index">
                      <span>
                        <span></span>
                      </span>
                        {{t.displayName}}
                    </label>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <custom-select
            v-if="selectedNeutralPrinterIndex != null"
            :selected="selectedNeutralPrinterIndex"
            @optionSelected="e => selectPrinter(e)"
            :options="printersOptions"
            :label="$t('printer')" />
          <div class="input">
            <label
              for="amount"
              class="input__label">{{ $t('amount') }}*</label>
            <input
              type="number"
              name="amount"
              id="amount"
              class="input__input"
              v-model="quantity">
          </div>
        </div>
        <div class="print-neutral-scorecards__actions">
          <base-button
            :text="$t('printOut')"
            @click="print"
            :disabled="!canIPrint" />
        </div>
      </div>
      <modal-window :show="neutralPrinted">
        <div class="modal__content">
          <h2>{{ $t('neutralPrinted') }} {{ neutralPrintedStatus }}</h2>
        </div>
        <div class="modal__content--actions">
          <base-button
            text="Ok"
            @click="toggleNeutralPrinted()">
          </base-button>
        </div>
      </modal-window>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import CustomSelect from '@/components/form-elements/custom-select';
import BaseButton from '@/components/form-elements/base-button';
import ModalWindow from '@/components/modal/modal';

export default {
  name: 'PrintNeutralScorecards',
  components: {
    UseSvg,
    CustomSelect,
    BaseButton,
    ModalWindow,
  },
  data() {
    return {
      tees: [],
      quantity: undefined,
      selectedCourse: '',
      selectedPrinter: '',
      selectedNeutralPrinterIndex: undefined,
      selectedCourseIndex: undefined,
      neutralPrinted: false,
      neutralPrintedStatus: '',
    };
  },
  methods: {
    selectOption(e, index) {
      this.selectedCourse = e.value;
      this.tees = [];
      this.$store.dispatch('courses/fetchCourseTees', { courseId: e.value });
    },
    selectPrinter(e) {
      this.selectedPrinter = e.value;
    },
    print() {
      this.$store.dispatch('printers/printNeutralScoreCards', {
        course: this.selectedCourse,
        tees: this.tees,
        printer: this.selectedPrinter,
        quantity: parseInt(this.quantity),
      }).then((data) => {
        this.toggleNeutralPrinted();
        this.neutralPrintedStatus = 'skrevs ut';
        if (data !== true) {
          this.neutralPrintedStatus = 'skrevs ej ut';
        }
      });
    },
    selectedNeutralPrinter(printer) {
      if (printer) {
        this.selectedNeutralPrinterIndex = this.printersOptions.findIndex(o => o.displayName === printer.displayName);
        this.selectedPrinter = this.printersOptions.find(o => o.displayName === printer.displayName).value;
      }
    },
    selectedCourseOption(course) {
      if (course) {
        this.selectedCourseIndex = this.courseSelect.findIndex(o => o.displayName === course.displayName);
        this.selectedCourse = this.courseSelect.find(o => o.displayName === course.displayName).value;
        this.$store.dispatch('courses/fetchCourseTees', { courseId: this.selectedCourse });
      }
    },
    toggleNeutralPrinted () {
      this.neutralPrinted = !this.neutralPrinted;
    },
  },
  computed: {
    canIPrint() {
      return (this.selectedCourse.length > 0) && (this.selectedNeutralPrinterIndex > -1) && (this.tees.length > 0) && this.quantity;
    },
    courseSelect() {
      return this.$store.getters['courses/courseSelect'];
    },
    courseTees() {
      if (!this.selectedCourse) {
        return [];
      } else {
        return this.$store.getters['courses/courseTees'];
      }
    },
    printersOptions() {
      return this.$store.getters['printers/printersOptions'];
    },
    defaultPrinter() {
      return this.$store.getters['printers/defaultPrinter'];
    },
  },
  created() {
    this.$store.dispatch('courses/fetchCourseSelect').then(() => {
      if(this.courseSelect.length === 1) {
        this.selectedCourseOption(this.courseSelect[0]);
      } else {
        this.selectedCourseIndex = -1;
      }
    });
    this.$store.dispatch('printers/fetchPrintersOptions').then(() => {
      this.$store.dispatch('printers/fetchDefaultPrinter', null).then(() => {
        if(this.defaultPrinter) {
          if(this.printersOptions && this.printersOptions.length > 0) {
            this.selectedNeutralPrinter(this.defaultPrinter.neutralPrinter);
          }
        }
      });
    });
  },
};
</script>
<style lang="scss">
.print-neutral-scorecards {
  .select__wrapper,
  .input .input__input {
    width: 100%;
    @include media('medium') {
      width: 40%;
    }
    @include media('large') {
      width: 20%;
    }
  }
}
</style>
