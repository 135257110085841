<template>
  <div class="addable-date-filter">
    <label class="addable-date-filter__label input__label">{{ label }}</label>
    <div class="addable-date-filter--selected">
      <template>
        <div
          class="addable-custom-select--selected-wrapper"
          v-for="(s, index) in localSelected"
          :key="index">
          <div class="addable-custom-select--selected_item">
            {{ s.displayName }} {{ formatDateAndTime(s.date) }}
          </div>
          <div
            class="addable-custom-select--remove"
            @click="toggleRemoveModal(s)">
            <use-svg svg="trash-icon" />
          </div>
        </div>
        <modal-window :show="showRemoveModal">
          <div class="modal__content">
            <h2>
              Do you want to delete the connection to '{{
                optionToRemove.displayName
              }}' and use Standard Scorekortet istället?
            </h2>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              text="Cancel"
              @click="toggleRemoveModal()">
            </base-button>
            <base-button
              text="Yes"
              @click="remove()" />
          </div>
        </modal-window>
      </template>
    </div>
    <modal-window
      :big-modal="false"
      :show="showModal">
      <div class="modal__header">
        {{ modalTitle }}
      </div>
      <div class="modal__content">
        <div class="addable-date-filter__wrapper">
          <div class="addable-date-filter__show">{{ $t('show') }}:</div>
          <div class="addable-date-filter__select">
            <custom-select
              id="year"
              key="year"
              :label="$t('year')"
              @optionSelected="e => selectYear(e)"
              :required="false"
              :selected="selectedYearIndex"
              :options="localOptions">
            </custom-select>
          </div>
          <div class="addable-date-filter__select">
            <custom-select
              id="month"
              key="month"
              :label="$t('month')"
              @optionSelected="e => selectMonth(e)"
              :required="false"
              :options="values"
              :disabled="values.length === 0"
              ref="monthCustomSelect">
            </custom-select>
          </div>
        </div>
      </div>
      <div
        class="addable-date-filter__content"
        v-if="list.length > 0">
        <template v-for="(item, index) in list">
          <div
            class="addable-date-filter__item"
            :key="index"
            @click="selectItem(item, index)">
            <span>{{ item.displayName }}
              <span
                v-if="item && item.competitions && item.competitions.length > 0"><use-svg svg="connected-icon" /></span></span>
            <span>{{ formatDateAndTime(item.date) }}</span>
          </div>
        </template>
      </div>
      <div class="modal__content--actions">
        <base-button
          class="button--background-gray-dark"
          :text="$t('cancel')"
          @click="toggleModal()">
        </base-button>
      </div>
    </modal-window>
    <base-button
      @click="toggleModal"
      :text="$t('add')"
      class="button--has-icon addable-date-filter__button"
      :button-size-small="true">
      <use-svg svg="plus-icon" />
    </base-button>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import ModalWindow from '@/components/modal/modal';

export default {
  name: 'AddableDateFilter',
  components: {
    BaseButton,
    ModalWindow,
    CustomSelect,
    UseSvg,
  },
  props: {
    options: {
      type: Array,
    },
    label: String,
    selected: {
      type: Array,
    },
    modalTitle: String,
  },
  data() {
    return {
      showModal: false,
      currentYear: '',
      localOptions: this.options,
      selectedYearIndex: null,
      selectedYear: '',
      selectedMonth: '',
      values: [],
      list: [],
      localSelected: this.selected,
      optionToRemove: {},
      showRemoveModal: false,
    };
  },
  methods: {
    selectYear(e) {
      if (e.values !== this.values) {
        this.list = [];
        this.values = e.values;
        if (this.$refs && this.$refs.monthCustomSelect) {
          this.$refs.monthCustomSelect.resetSelect(this.values);
        }
      }
    },
    selectMonth(e) {
      this.selectedMonth = e.displayName;
      this.list = e.values;
    },
    selectItem(item, index) {
      this.localSelected.push({
        value: item.value,
        displayName: item.displayName,
        date: item.date,
        year: this.selectedYear.displayName,
        month: this.selectedMonth,
      });

      this.localOptions.map(o => {
        o.values.map(v => {
          if (v.values && v.values.length > 0) {
            v.values.map(c => {
              if (c.displayName === item.displayName) v.values.splice(index, 1);
            });
          }
        });
      });

      this.$emit('selectedConnections', this.localSelected);
      this.toggleModal();
    },
    remove() {
      this.localSelected = this.localSelected.filter(
        sel => sel.displayName !== this.optionToRemove.displayName
      );
      this.localOptions.map(y => {
        if (y.displayName === this.optionToRemove.year) {
          y.values.map(m => {
            if (m.displayName === this.optionToRemove.month) {
              m.values.push({
                value: this.optionToRemove.value,
                displayName: this.optionToRemove.displayName,
                informationText: this.optionToRemove.informationText,
                date: this.optionToRemove.date,
              });
            }
          });
        }
      });

      this.$emit('selectedConnections', this.localSelected);
      this.toggleRemoveModal();
      this.optionToRemove = {};
    },
    toggleModal() {
      this.list = [];
      this.showModal = !this.showModal;
    },
    toggleRemoveModal(s) {
      if (s) {
        this.optionToRemove = s;
      }
      this.showRemoveModal = !this.showRemoveModal;
    },
    formatDateAndTime(date) {
      const dateString = new Date(date);
      return dateString.getDate() + '/' + (dateString.getMonth() + 1);
    },
  },
  created() {
    if (this.localOptions) {
      // Get current year and that year as default in dropdown
      this.currentYear = new Date().getFullYear().toString();
      let selectedIndex = this.localOptions.findIndex(
        o => o.displayName === this.currentYear
      );
      this.selectedYearIndex = selectedIndex;
      this.selectedYear = this.localOptions.find(
        c => c.displayName === this.currentYear
      );
      this.selectYear(this.selectedYear);
    }
  },
};
</script>
<style lang="scss">
.addable-date-filter {
  .input {
    .select__wrapper {
      width: 100%;
    }
  }
}
.addable-date-filter__label {
  display: block;
  padding: 0.2rem;
}
.addable-date-filter__wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid color(gray);
  > div {
    flex-grow: 1;
    margin-right: 1.25rem;
  }
}
.addable-date-filter__show {
  margin-bottom: 0.625rem;
  margin-right: 0 !important;
}
.addable-date-filter__select {
  width: 100%;
  text-align: left;
  .select.is-open .select__list {
    max-height: 300px;
    overflow: auto;
  }
  .select__wrapper {
    margin-bottom: 0;
  }
}
.addable-date-filter__content {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid color(gray);
  margin: 0 1.125rem 1.125rem 1.125rem;
  padding-bottom: 1.125rem;
}
.addable-date-filter__item {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: color(blue-light);
    cursor: pointer;
  }

  svg {
    color: color(gray-dark);
  }
}
// .addable-date-filter--selected-wrapper {
//   display: flex;
//   align-items: center;
// }
// .addable-date-filter--selected_item {
//     border: 0.0625rem solid #D8D8D8;
//     border-radius: 0.125rem;
//     background-color: #F9F9F9;
//     padding: 0.375rem 0.375rem 0.375rem 0.9375rem;
//     height: 2.0625rem;
//     width: 95.5%;
//     margin-right: 0.6rem;
//     margin-bottom: 0.5rem;
// }
// .addable-date-filter--remove {
//   cursor: pointer;
//   color: color(gray);
// }
.addable-date-filter__button {
  margin-top: 1rem;
  stroke: color(white);
}
</style>
