<template>
  <div
    class="nav"
    v-if="!hide">
    <div>
      <router-link :to="hasEconomicLicense ? '/businessboard' : '/'">
        <img
          :src="
            getLogo(
              hasEconomicLicense
                ? 'bb-logo-colored.svg'
                : 'golfoffice-laying.svg'
            )
          "
          :class="hasEconomicLicense ? 'nav--logo-bb' : 'nav--logo'"
          :alt="
            hasEconomicLicense ? 'Business board logo' : 'Golf office logo'
          " />
      </router-link>
    </div>
    <div class="nav--club">
      <side-navigation position="header" />
    </div>
    <div
      class="nav--mobile"
      v-if="mq('to-small')">
      <button
        v-if="showMenu"
        @click="toggleMobileMenu"
        class="nav--mobile--button nav--mobile--close">
        <span class="visually-hidden">{{ $t('closeMenu') }}</span>
        X
      </button>
      <button
        v-else
        @click="toggleMobileMenu"
        class="nav--mobile--button">
        <span class="visually-hidden">{{ $t('openMenu') }}</span>
        <use-svg
          aria-hidden="true"
          svg="menu-icon" />
      </button>
      <div
        class="overlay"
        v-if="showMenu">
        <div class="overlay--content">
          <side-navigation @close="toggleMobileMenu"></side-navigation>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import SideNavigation from '@/components/navigation/side-navigation';
import mq from '@/mixins/mq.js';
import { BusinessBoardVersion } from '@/core/consts';
// import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'MainNavigation',
  components: {
    UseSvg,
    SideNavigation,
  },
  mixins: [mq],
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    businessBoardSettings() {
      return this.$store.getters['businessboard/settings'];
    },
    hasEconomicLicense() {
      if (!this.businessBoardSettings) {
        return false;
      }
      return (
        this.businessBoardSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicBasic ||
        this.businessBoardSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicPlus
      );
    },
    user() {
      return this.$store.getters['user/user'];
    },
    loggedIn() {
      return this.$store.getters['user/loggedIn'];
    },
  },
  methods: {
    toggleMobileMenu() {
      this.showMenu = !this.showMenu;
    },
    getLogo(name) {
      let path = '';
      try {
        path = require(`../../assets/images/${name}`);
      } catch (e) {
        console.error(e);
      }
      return path;
    },
  },
};
</script>

<style lang="scss">
.nav {
  background-color: color(white);
  height: 4.475rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 0 1rem;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 4.375rem;
  .overlay--content {
    height: 100%;
    padding-bottom: 5rem;

    > div {
      height: 100%;
    }
    .side-navigation {
      height: 100%;
      width: 100%;
      position: relative;
      top: 0;
      width: 100%;

      @include media('to-small') {
        display: block;
        border-right: none;
        overflow-y: scroll;
      }
    }
  }
}
.nav--mobile {
  @include media('to-small') {
    display: flex;
    margin-left: auto;
  }
  @include media('small') {
    display: none;
  }
}
.nav--mobile--button {
  @include reset-button;
}
.nav--mobile--close {
  font-size: 2.5rem;
}
.nav--logo {
  height: 100%;
  width: 8rem;
  margin-right: 5rem;
}
.nav--logo-bb {
  height: 1.5rem;
  margin-top: 1.5rem;
  margin-right: 5rem;
}
.nav--club {
  display: flex;
  margin-left: auto;
  display: none;
  position: relative;
  align-items: center;
  @include media('small') {
    display: flex;
  }
}
</style>
