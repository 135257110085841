import { render, staticRenderFns } from "./dot-and-doughnut-diagram.vue?vue&type=template&id=5647d264&scoped=true"
import script from "./dot-and-doughnut-diagram.vue?vue&type=script&lang=js"
export * from "./dot-and-doughnut-diagram.vue?vue&type=script&lang=js"
import style0 from "./dot-and-doughnut-diagram.vue?vue&type=style&index=0&id=5647d264&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5647d264",
  null
  
)

export default component.exports