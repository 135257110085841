<template>
  <div
    class="ts-select-cards"
    :size="size">
    <div
      v-if="error"
      style="color: red">
      {{ error }}
    </div>
    <te-select-card
      v-else
      v-for="item in localItems"
      :key="item.id"
      v-on:click.native="select(item)"
      :title="item.title"
      :description="item.description"
      :selected="item.selected"
      :size="size"
      :theme="theme"
      :disabled="item.disabled"></te-select-card>
  </div>
</template>

<script>
import TeSelectCard from './te-select-card.vue';
export default {
  name: 'TeSelectCards',
  emits: ['select'],
  components: { TeSelectCard },
  props: {
    mutliselect: {
      type: Boolean,
    },
    items: {
      type: Array,
    },
    size: {
      type: String,
    },
    theme: {
      type: String,
    },
  },
  watch: {
    items: {
      handler: function (newVal, oldVal) {
        for (let i of this.localItems) {
          for (let j of newVal) {
            if (i.id === j.id) {
              i.selected = j.selected;
            }
          }
        }
      },
      deep: true,
    },
  },
  data() {
    let error = null;
    if (this.hasUndefined(this.items)) {
      error =
        'Card items is missing data, must have properties id, title, description, selection and disabled';
    }
    return {
      error: error,
      localItems: this.items,
    };
  },
  methods: {
    hasUndefined(arr) {
      for (let i of arr) {
        if (i.selected === undefined) {
          return true;
        }
        if (i.disabled === undefined) {
          return true;
        }
      }
      return false;
    },
    select: function (item) {
      for (let i of this.localItems) {
        if (item.id === i.id) {
          i.selected = !i.selected;
        } else if (this.mutliselect !== true) {
          i.selected = false;
        }
      }
      this.$emit(
        'select',
        this.localItems.filter(i => {
          return i.selected;
        }),
        this.localItems
      );
    },
  },
};
</script>
<style lang="scss">
.ts-select-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
}
.ts-select-cards[size='small'] {
  gap: 14px;
}
</style>
