import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
  scorecardorder: {},
  scorecardordertempstate: {
    scorecardType: 0,
    quantity: 1,
    deliveryWeek: undefined,
    unitPrice: 0,
    surfaceType: 0,
    changeOfSurface: false,
  },
  scorecardorderid: '',
  scorecardorderhistory: {},
  scorecardorderaddresses: {},
  scorecardallorders: {
    rows: [],
    columns: [],
  },
  scorecardstandardorders: {
    rows: [],
    columns: [],
  },
  scorecardcustomerorders: {
    rows: [],
    columns: [],
  },
  scorecardspecificorder: {},
  scorecardordersettings: {
    deliveryWeeks: {},
    priceSettings: {
      threePartPriceOne: 0,
      threePartPriceTwo: 0,
      threePartPriceThree: 0,
      threePartPriceFour: 0,
      fourPartPriceOne: 0,
      fourPartPriceTwo: 0,
      fourPartPriceThree: 0,
      fourPartPriceFour: 0,
      repeatPrice: 0,
    },
  },
  scorecardordershippingprice: {},
  deliveryweek: {},
  deliveryweeks: [],
  settingsclubinfo: {},
  terms: {},
  termshistory: {},
  marknextorderchanged: false,
};

const mutations = {
  scorecardordersettings(state, payload) {
    state.scorecardordersettings = payload;
  },
  scorecardordershippingprice(state, payload) {
    state.scorecardordershippingprice = payload;
  },
  deliveryweek(state, payload) {
    state.deliveryweek = payload;
  },
  scorecardorder(state, payload) {
    state.scorecardorder = payload;
  },
  scorecardordertempstate(state, payload) {
    state.scorecardordertempstate = payload;
  },
  scorecardorderid(state, payload) {
    state.scorecardorderid = payload;
  },
  scorecardorderhistory(state, payload) {
    state.scorecardorderhistory = payload;
  },
  scorecardorderaddresses(state, payload) {
    state.scorecardorderaddresses = payload;
  },
  scorecardallorders(state, payload) {
    state.scorecardallorders.rows = payload.rows;
    state.scorecardallorders.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
    state.scorecardallorders = payload;
  },
  scorecardstandardorders(state, payload) {
    state.scorecardstandardorders.rows = payload.rows;
    state.scorecardstandardorders.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
    state.scorecardstandardorders = payload;
  },
  scorecardcustomerorders(state, payload) {
    state.scorecardcustomerorders.rows = payload.rows;
    state.scorecardcustomerorders.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
    state.scorecardcustomerorders = payload;
  },
  scorecardspecificorder(state, payload) {
    state.scorecardspecificorder = payload;
  },
  deliveryweeks(state, payload) {
    state.deliveryweeks = payload;
  },
  settingsclubinfo(state, payload) {
    state.settingsclubinfo = payload;
  },
  marknextorderchanged(state, payload) {
    state.marknextorderchanged = payload;
  },
  terms(state, payload) {
    Vue.set(state.terms, 'termsUrl', payload.url);
    Vue.set(state.terms, 'termsKey', payload.keyValue);
  },
  termshistory(state, payload) {
    state.termsHistory = payload;
  },
};

const getters = {
  pagination: state => {
    return state.pagination;
  },
  currentPage: state => {
    return state.pagination.currentPage;
  },
  nextPage: state => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: state => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
  scorecardorder: state => {
    return state.scorecardorder;
  },
  scorecardordertempstate: state => {
    return state.scorecardordertempstate;
  },
  scorecardorderid: state => {
    return state.scorecardorderid;
  },
  scorecardorderhistory: state => {
    return state.scorecardorderhistory;
  },
  scorecardorderaddresses: state => {
    return state.scorecardorderaddresses;
  },
  scorecardallorders: state => {
    return state.scorecardallorders;
  },
  scorecardstandardorders: state => {
    return state.scorecardstandardorders;
  },
  scorecardcustomerorders: state => {
    return state.scorecardcustomerorders;
  },
  scorecardspecificorder: state => {
    return state.scorecardspecificorder;
  },
  scorecardordersettings: state => {
    return state.scorecardordersettings;
  },
  scorecardordershippingprice: state => {
    return state.scorecardordershippingprice;
  },
  deliveryweek: state => {
    return state.deliveryweek;
  },
  deliveryweeks: state => {
    return state.deliveryweeks;
  },
  settingsclubinfo: state => {
    return state.settingsclubinfo;
  },
  terms: state => {
    return state.terms;
  },
  markNextOrderChanged: state => {
    return state.marknextorderchanged;
  },
  termshistory: state => {
    return state.termsHistory;
  },
};

const actions = {
  fetchScorecardOrder: async context => {
    await fetch(context, 'scorecardorder', 'scorecardorder');
  },
  createScorecardOrder: async (context, params) => {
    await create(
      context,
      params,
      'scorecardorder',
      'add-order',
      'scorecardorderid'
    );
  },
  fetchScorecardOrderHistory: async context => {
    await fetch(context, 'scorecardorder/history', 'scorecardorderhistory');
  },
  fetchScorecardOrderAddresses: async context => {
    await fetch(context, 'scorecardorder/addresses', 'scorecardorderaddresses');
  },
  createScorecardOrderDeliveryAddress: async (context, params) => {
    await create(
      context,
      params,
      'scorecardorder/deliveryaddress',
      'add-delivery-address',
      'scorecardorderaddresses'
    );
  },
  patchScorecardOrderDeliveryAddress: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardorder/deliveryaddress',
      'edit-delivery-address',
      'scorecardorderaddresses'
    );
  },
  createScorecardOrderInvoiceAddress: async (context, params) => {
    await create(
      context,
      params,
      'scorecardorder/addinvoiceaddress',
      'add-invoice-address',
      'scorecardorderaddresses'
    );
  },
  patchScorecardOrderInvoiceAddress: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardorder/invoiceaddress',
      'edit-invoice-address',
      'scorecardorderaddresses'
    );
  },
  fetchScorecardAllOrders: async (context, params) => {
    let path = 'scorecardorder/orders';
    if (params.id) path += '/' + params.id;
    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => {
          if (params[k] !== undefined) {
            if (Array.isArray(params[k])) {
              return params[k].map(a => esc(k) + '=' + esc(a)).join('&');
            }
            return esc(k) + '=' + esc(params[k]);
          }
        })
        .filter(q => q !== undefined)
        .join('&');
      path += '?' + query;
    }
    let response = {};
    try {
      response = await Vue.axios.get(path, { params: params });
      context.commit('scorecardallorders', response.data.result);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  fetchScorecardStandardOrders: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'scorcardorders-fetch-order', {
      root: true,
    });
    let path = 'scorecardorder/orders/standard';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => {
          if (params[k] !== undefined) {
            return esc(k) + '=' + esc(params[k]);
          }
        })
        .filter(q => q !== undefined)
        .join('&');
      path += '?' + query;
    }
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'scorcardorders-fetch-order', {
      root: true,
    });

    if (response && response.data) {
      const data = response.data;
      context.commit('scorecardstandardorders', data.result);
    }
    // await fetch(context, 'scorecardorder/orders/standard', 'scorecardstandardorders')
  },
  fetchScorecardCustomerOrders: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'scorcardorders-fetch-order', {
      root: true,
    });
    let path = 'scorecardorder/orders/customer';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => {
          if (params[k] !== undefined) {
            return esc(k) + '=' + esc(params[k]);
          }
        })
        .filter(q => q !== undefined)
        .join('&');
      path += '?' + query;
    }
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'scorcardorders-fetch-order', {
      root: true,
    });

    if (response && response.data) {
      const data = response.data;
      context.commit('scorecardcustomerorders', data.result);
    }
  },
  fetchScorecardSpecificOrder: async (context, params) => {
    await fetch(
      context,
      'scorecardorder/orders/' + params.id,
      'scorecardspecificorder'
    );
  },
  patchScorecardSpecificOrder: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardorder/orders/' + params.id,
      'edit-order',
      'patch-scorecardspecificorder'
    );
  },
  patchScorecardShipOrder: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardorder/orders/trackingnumber/' + params.id,
      'edit-order',
      'patch-scorecardshiporder'
    );
  },
  postScorecardOrderExportStandard: async (context, params) => {
    await create(
      context,
      params,
      'scorecardorder/orders/export/standard',
      'export-orders',
      'exportmarkedorders'
    );
  },
  postScorecardOrderExportCustomer: async (context, params) => {
    await create(
      context,
      params,
      'scorecardorder/orders/export/customer',
      'export-orders',
      'exportmarkedorders'
    );
  },
  patchScorecardOrderConfirm: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardorder/orders/status',
      'confirm-order',
      'patch-confirmmarkedorders'
    );
  },
  patchScorecardOrderInvoiced: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardorder/orders/invoiced',
      'confirm-invoiced',
      'patch-invoicedmarkedorders'
    );
  },
  patchScorecardOrderCancel: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardorder/orders/' + params.id + '/cancel',
      'cancel-order',
      'patch-cancelorder'
    );
  },
  patchScorecardOrderStatus: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardorder/orders/status',
      'status-order'
    );
  },
  fetchScorecardOrderSettings: async context => {
    await fetch(context, 'scorecardordersettings', 'scorecardordersettings');
  },
  patchScorecardOrderSettings: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardordersettings/price',
      'patch-scorecardordersettings'
    );
  },
  postScorecardOrderShippingPrice: async (context, params) => {
    await create(
      context,
      params,
      'scorecardorder/updateshippingprice',
      'update-shippingprice',
      'scorecardordershippingprice'
    );
  },
  fetchScorecardOrderShippingPrice: async context => {
    await fetch(
      context,
      'scorecardorder/shippingprice',
      'scorecardordershippingprice'
    );
  },
  postDeliveryWeek: async (context, params) => {
    await create(
      context,
      params,
      'scorecardordersettings/deliveryweek',
      'add-week',
      'scorecardordersettings'
    );
  },
  patchDeliveryWeek: async (context, params) => {
    await patch(
      context,
      params,
      'scorecardordersettings/deliveryweek/' + params.id,
      'add-week',
      'scorecardordersettings'
    );
  },
  createDeliveryWeek: async (context, params) => {
    await create(
      context,
      params,
      'scorecardordersettings/deliveryweek',
      'create-deliveryweek',
      'scorecardordersettings'
    );
  },
  fetchDeliveryWeek: async (context, params) => {
    await fetch(
      context,
      'scorecardordersettings/deliveryweek/' + params.id,
      'deliveryweek'
    );
  },
  deleteDeliveryWeek: async (context, params) => {
    await remove(context, 'scorecardordersettings/deliveryweek/' + params.id);
  },
  fetchDeliveryWeeks: async context => {
    await fetch(context, 'scorecardorder/deliveryweeks', 'deliveryweeks');
  },
  fetchMarkNextOrderChanged: async context => {
    await fetch(
      context,
      'scorecardorder/marknextorderchanged',
      'marknextorderchanged'
    );
  },
  fetchSettingsClubInfo: async (context, params) => {
    let path = 'scorecardordersettings/information';

    let response = {};
    try {
      response = await Vue.axios.get(path, { params: params });
      context.commit('settingsclubinfo', response.data);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  postSettingsClubInfo: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'post-info', { root: true });

    try {
      let response = await Vue.axios.post(
        'scorecardordersettings/information',
        params
      );
      context.dispatch('ui/toggleActiveLoaders', 'post-info', { root: true });
      context.commit('settingsclubinfo', response.data);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: 'scorecardordersettings/information',
      });
    }
  },
  addTerms: async (context, params) => {
    // context.dispatch('ui/toggleActiveLoaders', 'post-terms', { root: true })
    let path = 'scorecardordersettings/terms';
    if (params.type) path += '?type=' + params.type;
    let formData = new FormData();
    formData.append('terms', params.terms);

    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('terms', {
        url: data.result.url,
        keyValue: data.result.key,
      });
    }
  },
  fetchTerms: async context => {
    await fetch(context, 'scorecardordersettings/terms', 'terms');
  },
  fetchTermsHistory: async context => {
    await fetch(context, 'scorecardordersettings/termslist', 'termshistory');
  },
};

async function patch(context, params, path, loader, commit) {
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  let response = {};
  try {
    response = await Vue.axios.patch(path, params);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
  const data = response.data;
  if (!data.success) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: data.message,
      request: path,
    });
  }
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  if (commit && response && response.data) {
    const data = response.data;
    context.commit(commit, data.result);
  }
}
async function fetch(context, path, commit) {
  try {
    let response = await Vue.axios.get(path);
    context.commit(commit, response.data.result);
  } catch (error) {
    console.error(error, error.response.data);
    // EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path })
  }
}
async function create(context, params, path, loader, commit) {
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  try {
    let response = await Vue.axios.post(path, params);
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
    context.commit(commit, response.data.result);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
}
async function remove(context, path, loader, commit) {
  if (loader)
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });

  let response = {};
  try {
    response = await Vue.axios.delete(path);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
  if (loader)
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  if (response && response.data && response.data.success) {
    EventBus.$emit('toast', {
      type: 'success',
      msg: response.data.message,
      request: path,
    });
  }
  if (commit && response && response.data) {
    const data = response.data;
    context.commit(commit, data.result);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
