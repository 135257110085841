<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <span class="h1">OnTag Course Guides</span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
      </div>
      <template v-if="courseguides">
        <template v-if="loopId">
          <router-link :to="{ path: '/ontag/course-guides' }">
            &lt; Tillbaka
          </router-link>
          <section>
            <h2>{{ courseguides.clubName }}</h2>
            <h3>{{ courseguides.courseName }}</h3>
            <table>
              <th></th>
              <th>GIT</th>
              <th>Banguide</th>
              <tr
                v-for="hole in courseguides.holes"
                :key="hole.name">
                <td :class="hole.diff ? 'highlight' : ''">{{ hole.name }}</td>
                <td>{{ hole.courseGuide }}</td>
                <td>{{ hole.git }}</td>
              </tr>
            </table>
          </section>
        </template>
        <template v-else>
          <list-table
            :items="courseguides.rows"
            :headers="courseguides.columns">
          </list-table>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';

export default {
  name: 'OnTagCourseGuides',
  components: {
    ListTable,
  },
  props: {
    clubId: {
      type: String,
    },
    loopId: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    courseguides() {
      return this.$store.getters['ontagCourseguides/courseguides'];
    },
  },
  created() {
    if (!this.loopId) {
      this.$store
        .dispatch('ontagCourseguides/get')
        .then(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.$store
        .dispatch('ontagCourseguides/get', {
          clubId: this.clubId,
          loopId: this.loopId,
        })
        .then(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  methods: {},
};
</script>
<style lang="scss">
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
  margin-bottom: 20px;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 20px;
}
.ontag {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.ontag__content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  &.dashboard {
    background-color: transparent;
    border-radius: 0;
  }
}

.title {
  font-size: 1.1rem;
}
.left-margin {
  margin-left: 0.7rem;
}
.highlight {
  background-color: chocolate;
}
</style>
