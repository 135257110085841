var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"button",class:[
    _vm.hasIconClass,
    _vm.iconToRightClass,
    _vm.iconOnlyClass,
    _vm.backgroundColorClass,
    _vm.alignTextClass,
    _vm.smallButtonSize,
    _vm.modifierClasses,
  ],attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.clickEvent($event)}}},[_vm._t("default"),_c('span',[_vm._v(_vm._s(_vm.text))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }