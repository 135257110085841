// import * as util from 'util';

function clampInt(value, min, max) {
  value = Math.round(value);
  return Math.min(Math.max(value, min), max);
}
let verbose = false;

export class StepperDataModel {
  constructor(items) {
    if (items === undefined || items === null) {
      items = [];
    }
    this.items = items;
    this.currentItem = this.getCurrentyUnfinishedItem();
    this.currentSubItem = this.getCurrentlyUnfinishedSubItem();
  }
  resetItems(newItems) {
    this.items.splice(0, this.items.length);
    for (let i of newItems) {
      this.items.push(i);
    }
    if (verbose) {
      // console.log("resetItems", JSON.parse(JSON.stringify(this.items)));
    }
    this.updateCurrentItemsReferences();
  }
  updateCurrentItemsReferences() {
    this.currentItem = this.getCurrentyUnfinishedItem();
    this.currentSubItem = this.getCurrentlyUnfinishedSubItem();
  }
  getParentItem(id) {
    if (verbose) {
      // console.log("getParentItem", JSON.parse(JSON.stringify(this.items)));
    }
    for (let item of this.items) {
      if (item.items) {
        for (let subItem of item.items) {
          if (subItem.id === id) {
            return item;
          }
        }
      }
    }
    return null;
  }
  getSubItemsAccumulatedProgress(item) {
    if (verbose) {
      // console.log("getSubItemsAccumulatedProgress", JSON.parse(JSON.stringify(this.items)));
    }
    let progress = 0;
    if (item.items && item.items.length > 0) {
      for (let subItem of item.items) {
        progress += subItem.progress;
      }
      return clampInt(progress / item.items.length, 0, 100);
    }
    return 0;
  }
  updateParentProgress(id) {
    if (verbose) {
      // console.log("updateParentProgress", id, JSON.parse(JSON.stringify(this.items)));
    }
    let item = this.getParentItem(id);
    if (item) {
      let progress = this.getSubItemsAccumulatedProgress(item);
      item.progress = progress;
    }
  }
  updateProgress(id, progress) {
    if (progress === undefined || progress === null || isNaN(progress)) {
      return;
    }
    if (verbose) {
      // console.log("updateProgress", progress, JSON.parse(JSON.stringify(this.items)));
    }
    let flatItems = StepperDataModel.flattenItems(this.items);
    for (let item of flatItems) {
      if (item.id === id) {
        item.progress = progress;
      }
    }
    this.updateParentProgress(id);
  }
  getCurrentlyUnfinishedSubItem() {
    if (verbose) {
      // console.log("getCurrentSubItem", JSON.parse(JSON.stringify(this.items)));
    }
    if (this.currentItem && this.currentItem.items) {
      for (let subItem of this.currentItem.items) {
        if (subItem.progress !== 100) {
          return subItem;
        }
      }
    }
    return null;
  }
  getCurrentyUnfinishedItem() {
    if (verbose) {
      // console.log("getCurrentItem", JSON.parse(JSON.stringify(this.items)));
    }
    for (let item of this.items) {
      if (item.progress !== 100) {
        return item;
      }
    }
  }
  addSubItems(id, items) {
    if (verbose) {
      // console.log("addSubItems", JSON.parse(JSON.stringify(this.items)));
    }
    for (let item of this.items) {
      if (item.id === id) {
        if (item.items === undefined || item.items === null) {
          item.items = items;
        } else {
          item.items.splice(0, item.items.length);
          for (let i of items) {
            item.items.push(i);
          }
        }
      }
    }
  }
  isDone() {
    if (verbose) {
      // console.log("isDone", JSON.parse(JSON.stringify(this.items)));
    }
    let flatItems = StepperDataModel.flattenItems(this.items);
    for (let item of flatItems) {
      if (item.progress !== 100) {
        return false;
      }
    }
    return true;
  }
  moveToPreviousStep() {
    // console.log("moveToPreviousItem - before",this.currentItem,this.currentSubItem);
    let subItem = this.getPreviousSubItem();
    if(subItem) {
      this.currentSubItem = subItem;
    } else {
      let item = this.getPreviousItem();
      if(item) {
        this.currentItem = item;
        this.currentSubItem = item.items ? this.items.items[0] : null;
      }
    }
    // console.log("moveToPreviousItem - after",this.currentItem,this.currentSubItem);
  }
  moveToNextStep() {
    if (verbose) {
      // console.log("moveToNextStep - before",this.currentItem,this.currentSubItem);
    }
    if(!this.currentItem && !this.currentItem.progress !== 100) {
      return;
    }
    let subItem = this.getNextSubItem();
    if(subItem) {
      this.currentSubItem = subItem;
    } else {
      let item = this.getNextItem();
      if(item) {
        this.currentItem = item;
        this.currentSubItem = item.items ? item.items[0] : null;   
      }
    }
    // console.log("moveToNextStep - after",this.currentItem,this.currentSubItem);
  }
  canGoForward() {
   return this.getCurrentItemIndex() < this.items.length - 1 || this.getCurrentSubItemIndex() < this.currentItem.items.length - 1;
  }
  getPreviousSubItem() {
    if(this.currentItem && this.currentItem.items) {
      let index = this.currentItem.items.findIndex(i => i.id === this.currentSubItem.id);
      if (index === -1) {
        return null;
      }
      if(index === 0) {
        return null;
      }
      return this.currentItem.items[index - 1];
    }
    return null;
  }
  getPreviousItem() {
    let index = this.items.findIndex(i => i.id === this.currentItem.id);
    if (index === -1) {
      return null;
    }
    if(index === 0) {
      return null;
    }
    return this.items[index - 1];
  }
  getNextSubItem() {
    if(this.currentItem && this.currentItem.items) {
      let index = this.currentItem.items.findIndex(i => i.id === this.currentSubItem.id);
      if (index === -1) {
        return null;
      }
      if(index + 1 >= this.currentItem.items.length) {
        return null;
      }
      return this.currentItem.items[index + 1];
    }
    return null;
  }
  getNextItem() {
    let index = this.items.findIndex(i => i.id === this.currentItem.id);
    if (index === -1) {
      return null;
    }
    if(this.items.length -1 === index) {
      return null;
    }
    return this.items[index + 1];
  }
  getCurrentItemIndex() {
    return this.items.findIndex(i => i.id === this.currentItem.id);
  }
  getCurrentSubItemIndex() {
    if(!this.currentItem || !this.currentItem.items) {
      return -1;
    }
    return this.currentItem.items.findIndex(i => i.id === this.currentSubItem.id);
  }
  static createCustomStepperItems() {
    if (verbose) {
      // console.log("createCustomStepperItems");
    }
    return [{
      id: "hole-setup-1",
      title: 'Setup',
      progress: 100,
      description: 'Select hole setup',
    },
    {
      id: "hole-setup-2",
      title: 'Setup',
      progress: 0,
      description: 'Create group(s)',
      explaination: 'create-groups',
    },
    {
      id: "hole-setup-3",
      title: 'Add par',
      progress: 0,
      description: 'Add par',
      items: [],
    },
    ];
  }
  static createStandardStepperItems() {
    if (verbose) {
      // console.log("createStandardStepperItems");
    }
    return [
      {
        id: "hole-setup-1",
        title: 'Setup',
        progress: 100,
        description: 'Select hole setup',
      },
      {
        id: "hole-setup-3",
        title: 'Add par',
        progress: 0,
        description: 'Add par to holes',
        items: [],
      },
    ];
  }
  static flattenItems(items) {
    if (verbose) {
      // console.log("flattenItems");
    }
    if (!items) {
      return [];
    }
    let flatItems = [];
    for (let item of items) {
      flatItems.push(item);
      if (item.items) {
        flatItems = flatItems.concat(this.flattenItems(item.items));
      }
    }
    return flatItems;
  }
}
export class GroupsDataModel {
  constructor(courseGuideHoleSetup, groups) {
    if (groups === undefined || groups === null) {
      groups = [];
    }
    this.courseGuideHoleSetup = courseGuideHoleSetup;
    this.groups = groups;
  }
  addGroup(group) {
    this.groups.push(group);
  }
  resetGroups(courseGuideHoleSetup, newGroups) {
    this.courseGuideHoleSetup = courseGuideHoleSetup;
    this.groups.splice(0, this.groups.length);
    for (let i of newGroups) {
      this.groups.push(i);
    }
  }
  createGroup() {
    let group = {
      name: "",
      shortName: "",
      numberOfHoles: 0,
      holes: [],
    };
    this.groups.push(group);
    return group;
  }
  removeGroup(group) {
    console.warn("NEED TO ADD AN ID TO A GROUP");
    let index = this.groups.indexOf(group);
    if (index > -1) {
      this.groups.splice(index, 1);
    }
  }
  static getGroupItemId(group) {
    if (group.id) {
      return "item-group-" + group.id;
    }
    if (group.name) {
      return "item-group-" + group.name;
    }
  }
  static createHolesArray(holes, randomPar) {
    let holesArray = [];
    if (holes === 0) {
      return [];
    }
    for (let i = 1; i <= holes; i++) {
      holesArray.push({
        _id: Date.now().toString(36) + Math.random().toString(36),
        holeNumber: i,
        par: randomPar && Math.random() < 0.5 ? 5 : 0,
      });
    }
    return holesArray;
  }
  static getGroupParProgress(group) {
    let progress = 0;
    if (!group.holes || group.holes.length === 0) {
      return 0;
    }
    for (let hole of group.holes) {
      if (hole.par > 0) {
        progress += 100;
      }
    }
    return Math.round(progress / group.holes.length);
  }
  static getGroupsParProgress(groups) {
    let progress = 0;
    if (groups.length === 0) {
      return 0;
    }
    for (let group of groups) {
      progress += this.getGroupParProgress(group);
    }
    return Math.round(progress / groups.length);
  }
  static getGroupSetupProgress(group) {
    let progress = 0;
    if (group.name) {
      progress += 100 / 3;
    }
    if (group.shortName) {
      progress += 100 / 3;
    }
    if (group.numberOfHoles) {
      progress += 100 / 3;
    }
    return Math.round(progress);
  }
  static getGroupsSetupProgress(groups) {
    if (groups.length === 0) {
      return 0;
    }
    let progress = 0;
    for (let group of groups) {
      progress += GroupsDataModel.getGroupSetupProgress(group);
    }
    return Math.round(progress / groups.length);
  }
  static createStandardGroup(holes) {
    return {
      name: "Standard 18 holes",
      shortName: "S",
      numberOfHoles: 18,
      holes: holes ?? this.createHolesArray(18),
    };
  }
  static createStepperParItem(group) {
    let p = this.getGroupParProgress(group);
    if (group.name === "Standard 18 holes") {
      return {
        id: GroupsDataModel.getGroupItemId(group),
        title: group.name,
        shortTitle: group.name,
        progress: p,
        description: "Add par to standard 18 holes",
      };
    }
    return {
      id: GroupsDataModel.getGroupItemId(group),
      title: 'Group ' + group.name,
      shortTitle: 'Group ' + group.shortName,
      progress: p,
      description: 'Add par to all holes in group',
    };
  }
}

export class GroupStepperBridge {
  static updateGroupParProgress(groupsDataModel, stepperDataModel) {
    for (let group of groupsDataModel.groups) {
      let p = GroupsDataModel.getGroupParProgress(group);
      stepperDataModel.updateProgress(GroupsDataModel.getGroupItemId(group), p);
    }
  }
  static updateGroupsParProgress(groupsDataModel, stepperDataModel, item) {
    GroupStepperBridge.updateGroupParProgress(groupsDataModel, stepperDataModel);
    let p = GroupsDataModel.getGroupsParProgress(groupsDataModel.groups);
    stepperDataModel.updateParentProgress(item.id, p);
  }
  static updateGroupsSetupProgress(groupsDataModel, stepperDataModel, item) {
    let p = GroupsDataModel.getGroupsSetupProgress(groupsDataModel.groups);
    stepperDataModel.updateProgress(item.id, p);
  }
  static insertStepperParItems(groupsDataModel, stepperDataModel, item) {
    if(!item) {
      // console.log("sending in wrong item?")
      return;
    }
    let items = [];
    for (let group of groupsDataModel.groups) {
      items.push(GroupsDataModel.createStepperParItem(group));
    }
    stepperDataModel.addSubItems(item.id, items);
  }
  static getCurrenParGroup(groupsDataModel, stepperDataModel) {
    let item = stepperDataModel.currentSubItem;
    if (item) {
      for (let group of groupsDataModel.groups) {
        if (item.id === GroupsDataModel.getGroupItemId(group)) {
          return group;
        }
      }
    }
    return null;
  }
}
// let items = StepperDataModel.createCustomStepperItems();
// let stepper = new StepperDataModel(items);
// let group1 = { name: "East", shortName: "", numberOfHoles: 12, holes: GroupsDataModel.createHolesArray(12, true) };
// let group2 = { name: "East", shortName: "E", numberOfHoles: 12, holes: GroupsDataModel.createHolesArray(12, false) };
// let gm = new GroupsDataModel(2, [group1,group2]);

// GroupStepperBridge.updateGroupsParProgress(gm, stepper, items[2]);
// GroupStepperBridge.insertStepperParItems(gm, stepper, items[2]);
// GroupStepperBridge.updateGroupsSetupProgress(gm, stepper, items[1]);

// function logDetailed(data) {
// //   console.log(util.inspect(data, { showHidden: false, depth: null, colors: true }));
// }
// logDetailed(stepper);
// logDetailed(stepper.getCurrentItem())
// logDetailed(stepper.getCurrentSubItem())
