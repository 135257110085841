<template>
  <div class="info_icon" @mouseover="isHovering = true" @mouseleave="isHovering = false">
    <slot name="icon"></slot>
    <div v-if="isHovering" class="info_icon_description">{{ description }}
      <slot name="description"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BBInfoIcon',
  components: {
  },
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.info_icon {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  &_description {
    position: absolute;
    top: 27px;
    transform: translateX(-50%);
    background-color: color(bb-primary);
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 6px 10px;
    z-index: 12;
  }
  &:hover {
    &:before {
      content: '';
      position: absolute;
      left: 2px;
      top: 22px;
      z-index: 10;
      border-width: 0 0 1px 1px;
      width: 10px;
      height: 10px;
      transform: rotate(135deg);
      background-color: color(bb-primary);
    }
  }
}
</style>
