<template>
  <div
    v-if="isModalOpen"
    class="modal--wrapper">
    <div class="modal--content">
      <div class="modal--header">
        <p class="breadcrumb">
          {{ $t('MemberCalculation.memberFeeTitle') }}
        </p>
        <h2 class="modal--title">
          {{ $t('MemberCalculation.addNewYear') }}
        </h2>
      </div>
      <div class="modal--body">
        <dropdown
          class="dropdown--text"
          :dropdown-heading="$t('chooseNewYear')"
          dropdown-sub-heading=""
          :dropdown-options="removeYearsThatExist(membershipTypeYears.years)"
          @selectedValue="e => newYear(e)" />

        <dropdown
          class="dropdown--text"
          :dropdown-heading="$t('copyData')"
          :dropdown-sub-heading="$t('copyDataFromYear')"
          :dropdown-options="getExistingYears(membershipTypeYears.years)"
          @selectedValue="e => saveCopyData(e)" />

        <select-and-modify-data
          v-if="choosenYear > 0 && modifiedPreviousYearData"
          :fees-reference="membershipTypeYears.feeColumns" />

        <save-publish-and-close-modal-buttons
          :is-modal-open="isModalOpen"
          @saveAndPublish="e => saveAndPublish()"
          @closeModal="e => closeModal()" />
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '../../dropdown/dropdown.vue';
import savePublishAndCloseModalButtons from '../../save-publish-and-close-modal-buttons/save-publish-and-close-modal-buttons.vue';
import SelectAndModifyData from '@/components/select-and-modify-data/select-and-modify-data.vue';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  components: {
    Dropdown,
    SelectAndModifyData,
    savePublishAndCloseModalButtons,
  },
  mixins: [LocalizationMixin],
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      choosenYear: [],
    };
  },
  computed: {
    membershipTypeYears() {
      return this.$store.getters['businessboard/membershipTypeYears'];
    },
    modifiedPreviousYearData: {
      get() {
        return this.$store.state.businessboard.membershipTypeYearCopy;
      },
      set(value) {
        this.$store.state.businessboard.membershipTypeYearCopy = value;
      },
    },
    populateYears() {
      const yearsObj = [];
      const startYear = 2022;
      const currentYear = new Date().getFullYear();

      yearsObj.push({ year: startYear });
      // StopYear must be 3 years from current year
      const stopYear = currentYear + 3;

      for (let i = startYear + 1; i <= stopYear; i++) {
        yearsObj.push({ year: i });
      }
      return yearsObj;
    },
  },
  created() {},
  methods: {
    removeYearsThatExist(years) {
      const populateYearObj = this.populateYears;
      let result = [];
      if (years !== undefined) {
        for (const populateYear of populateYearObj) {
          const obj = years.find(item => item.year === populateYear.year);
          if (!obj) {
            result.push({
              year: populateYear.year,
            });
          }
        }
      } else {
        result = populateYearObj;
      }
      return result;
    },

    getExistingYears(years) {
      const result = [];
      if (years !== undefined) {
        for (const year of years) {
          if (year.year > 0) {
            result.push(year);
          }
        }
      }
      result.push({ year: this.$t('noYear') });
      return result;
    },

    newYear(data) {
      this.choosenYear = data.value;
    },

    saveCopyData(yearToCopy) {
      this.modifiedPreviousYearData = this.membershipTypeYears.years.find(
        year => year.year === yearToCopy.value
      );
    },

    saveAndPublish() {
      let newYear = {
        year: this.choosenYear,
        copyFrom: {
          yearToCopy:
            this.modifiedPreviousYearData?.year > 0
              ? this.modifiedPreviousYearData
              : null,
        },
      };
      this.$store.dispatch('businessboard/createMembershipYear', {
        ...newYear,
      });
      this.closeModal();
    },

    closeModal() {
      this.choosenYear = 0;
      this.modifiedPreviousYearData = false;
      this.$emit('closeModal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal--wrapper {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;
  border-radius: 8px;
}

.modal--content {
  width: 21rem;
  margin-left: 2rem;
}
.modal--header {
  background-color: #fff;
}
.modal--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #131516;
}

.action--buttons {
  display: flex;
  gap: 1rem;
  margin: 2rem 0rem 0rem 1rem;
}

.breadcrumb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7c80;
}

.dropdown--text {
  margin-bottom: 6rem;
}
</style>
