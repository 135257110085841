<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div class="businessboard--header">
        <span
          class="h1"
          v-if="budgets.length > 0">{{ budgets[0].companyName }}</span>
        <router-link
          :to="{
            path: '/businessboard/data/budget/' + companyId + '/create-budget',
          }">
          <base-button
            class="button--background-blue"
            :text="$t('createBudget')">
          </base-button>
        </router-link>
        <base-button
          backgroundColor="bb-primary"
          :text="$t('show')"
          @click="showModal = true">
        </base-button>
      </div>
      <div v-if="budgets && budgets.length > 0">
        <div
          v-for="(budget, i) in filter(budgets)"
          v-bind:key="'budget' + i"
          class="businessboard--category">
          <div class="businessboard--title">
            <div class="title">
              <span
                v-if="displayedBudgets.includes(budget.id)"
                @click="pinBudget(budget.id)"
                title="Keep open"
                :style="
                  'cursor:pointer;opacity:' +
                  (settings.businessBoardPinnedBudgets?.includes(budget.id) ??
                  false
                    ? '1'
                    : '.5')
                ">
                <use-svg svg="pin-icon" />
              </span>
              {{ budget.name }}
            </div>
            <div>{{ budget.financialYear }}</div>
            <div>{{ budget.status }}</div>
            <input
              hidden
              type="checkbox"
              :id="'budget' + i"
              :value="budget.id"
              v-model="displayedBudgets" />
            <label :for="'budget' + i">
              <use-svg
                svg="arrow-right"
                :class="{
                  'svg--180-negative': displayedBudgets.includes(budget.id),
                }" />
            </label>
          </div>
          <list-table
            v-if="displayedBudgets.includes(budget.id)"
            :items="budget.budgets.rows"
            :headers="budget.budgets.columns"
            :companyId="companyId"
            @variationResults="getVariatedOrderResults">
          </list-table>
        </div>
      </div>
      <div v-else>
        {{ $t('noBudgetInfo') }}
      </div>
    </div>
    <modal-window :show="showModal">
      <div class="modal__header">
        {{ $t('show') }}
      </div>
      <div class="modal__content">
        <div class="modal__content__group">
          <h3>{{ $t('Businessboard.fiscalYear') }}</h3>
          <div
            v-for="(year, i) in forFilterYears"
            v-bind:key="'year' + i">
            <input
              checked
              type="checkbox"
              :id="'year' + i"
              :value="year"
              v-model="filters.years" />
            <label :for="'year' + i">
              {{ year }}
            </label>
          </div>
        </div>
        <div class="modal__content__group">
          <h3>{{ $t('status') }}</h3>
          <div
            v-for="(status, i) in forFilterStatuses"
            v-bind:key="'status' + i">
            <input
              checked
              type="checkbox"
              :id="'status' + i"
              :value="status"
              v-model="filters.statuses" />
            <label :for="'status' + i">
              {{ status }}
            </label>
          </div>
        </div>
      </div>
      <div class="modal__content--actions">
        <base-button
          :text="$t('cancel')"
          class="button--background-gray-dark"
          @click="showModal = false" />
        <base-button
          :text="$t('show')"
          @click="showModal = false" />
      </div>
    </modal-window>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table-businessboard';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import ModalWindow from '@/components/modal/modal';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'Budget',
  mixins: [LocalizationMixin],
  components: {
    ListTable,
    BaseButton,
    UseSvg,
    ModalWindow,
  },
  props: {
    companyId: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      showModal: false,
      filters: {
        years: [],
        statuses: [
          this.$t('BudgetCategory.established'),
          this.$t('BudgetCategory.published'),
          this.$t('BudgetCategory.draft'),
        ],
      },
    };
  },
  methods: {
    getVariatedOrderResults(value) {
      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length > 0) {
          this.filters['Filter.' + value.filter.id] = checked.map(a => a.value);
        } else {
          delete this.filters['Filter.' + value.filter.id];
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('businessboardBudget/fetchBudgetList', {
        id: this.$route.params.id,
        ...this.filters,
      });
    },
    filter(budgets) {
      if (this.filters.years.length > 0) {
        budgets = budgets.filter(budget =>
          this.filters.years.includes(budget.financialYear)
        );
      }
      if (this.filters.statuses.length > 0) {
        budgets = budgets.filter(budget =>
          this.filters.statuses.includes(budget.status)
        );
      }
      return budgets;
    },
    pinBudget(id) {
      if (
        this.settings.businessBoardPinnedBudgets === undefined ||
        Object.keys(this.settings.businessBoardPinnedBudgets).length === 0
      ) {
        this.settings.businessBoardPinnedBudgets = [id];
      } else {
        let index = this.settings.businessBoardPinnedBudgets.indexOf(id);
        if (index >= 0) {
          this.settings.businessBoardPinnedBudgets.splice(index, 1);
        } else {
          this.settings.businessBoardPinnedBudgets.push(id);
        }
      }
      this.$store.dispatch('users/setSettings', this.settings);
    },
  },
  computed: {
    budgets() {
      return this.$store.getters['businessboardBudget/budgetList'];
    },
    forFilterYears() {
      return [...new Set(this.budgets.map(b => b.financialYear))].sort();
    },
    forFilterStatuses() {
      let b = new Set(this.budgets.map(b => b.status));
      return new Set(
        [
          this.$t('BudgetCategory.archived'),
          this.$t('BudgetCategory.established'),
          this.$t('BudgetCategory.published'),
          this.$t('BudgetCategory.draft'),
        ].filter(x => b.has(x))
      );
    },
    displayedBudgets: {
      get() {
        return this.$store.getters['businessboardBudget/displayedBudgets'];
      },
      set(value) {
        this.$store.commit('businessboardBudget/displayedBudgets', value);
      },
    },
    settings() {
      return this.$store.getters['users/settings'];
    },
  },
  watch: {
    settings(newSettings) {
      if (this.displayedBudgets.length === 0) {
        this.displayedBudgets = newSettings.businessBoardPinnedBudgets;
      }
    },
  },
  mounted() {
    this.companyId = this.$route.params.id;
    this.$store.dispatch('businessboardBudget/fetchBudgetList', {
      id: this.companyId,
      'Filter.financialYear': this.financialYear,
    });
    this.$store.dispatch('users/getSettings');
  },
};
</script>

<style lang="scss" scoped>
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
}
.businessboard--header {
  margin: 1rem 0 0;
  display: flex;
  .h1 {
    flex: 100;
  }
  .button {
    margin: 1rem;
  }
}
.businessboard--category {
  padding-bottom: 3rem;
}
.businessboard--title {
  background-color: color(bb-primary);
  color: color(white);
  display: grid;
  grid-template-columns: 60% 20% 17% 3%;
  padding: 1rem;
  .title {
    font-size: 1.2rem;
  }
  .title--button {
    background-color: transparent;
    border: none;
    color: white;
  }
}
.modal__content {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
  &__group {
    text-align: left;
    div {
      margin: 0.5rem;
    }
  }
}
</style>
