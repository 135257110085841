import { render, staticRenderFns } from "./manage-age-groups.vue?vue&type=template&id=338106c4&scoped=true"
import script from "./manage-age-groups.vue?vue&type=script&lang=js"
export * from "./manage-age-groups.vue?vue&type=script&lang=js"
import style0 from "./manage-age-groups.vue?vue&type=style&index=0&id=338106c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338106c4",
  null
  
)

export default component.exports