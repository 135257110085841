<template>
  <div class="ontag">
    <div class="ontag--content" v-if="printers">
      <div>
        <span class="h1">{{ $t('allPrinters') }}</span>
        <span v-if="isLoading" class="element--is-loading"></span>
      </div>
      <p>Här ser ni klubbens tillgängliga skrivare som är hittade på nätverket. För att redigera skrivaren namn klicka på pilen till höger.</p>
        <div class="ontag--utilitites input-group--inline">
          <div class="pagination-amount">
            <span class="first-letter">{{ $t('show') }}</span>
              <custom-select
                v-if="selectedIndexPageSize !== undefined"
                :required="false"
                @optionSelected="setPageSize"
                :selected="selectedIndexPageSize"
                :options="pageSizeOptions"/>
            <span class="row-lines">{{ $t('lines') }}</span>
          </div>
          <div class="search-wrapper">
            <div class="input">
              <label
                for="searchQuery"
                class="input__label label__inline first-letter">{{ $t('search') }}</label>
              <input
                id="searchQuery"
                class="input__input input__inline search-input"
                @keyup.enter="getVariatedPrintersResults"
                v-model="searchQuery"
                type="text"
                name="searchQuery"/>
            </div>
            <base-button
              class="search-button"
              :text="$t('search')"
              :button-size-small="true"
              @click="getVariatedPrintersResults"
              v-element-loader="{ loaderKey: 'printers-fetch-all' }" />
            <base-button
              class="refresh-button"
              :text="$t('Refresh')"
              :button-size-small="true"
              @click="refreshPrinters"
              v-element-loader="{ loaderKey: 'printers-refresh' }" />
          </div>
          <!-- TODO: Add export when it's time to (not in FAS 1) -->
          <!-- <base-button
            class="button--icon-to-left"
            text="Export to excel"
            :button-size-small="true"
            @click="exportToExcel">
            <use-svg svg="export-icon"/>
          </base-button> -->
        </div>
        <div class="ontag--printers" v-if="printers && printers.rows && printers.columns">
          <list-table
            :items="printers.rows"
            :headers="printers.columns"
            @variationResults="getVariatedPrintersResults"
            :hide-in-mobile="['serialNumber', 'macAddress', 'model', 'message']">
          </list-table>
          <results-pagination
            :first-row-on-page="pagination.firstRowOnPage"
            :last-row-on-page="pagination.lastRowOnPage"
            :row-count="pagination.rowCount"
            :page-size="pagination.pageSize"
            :page-count="pagination.pageCount"
            :current-page="pagination.currentPage"
            :next-page="getNextPage()"
            :prev-page="getPrevPage()"
            store="printers"
            @currentPage="getVariatedPrintersResults"/>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import ResultsPagination from '@/components/results-pagination';
import CustomSelect from '@/components/form-elements/custom-select';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'AllPrinters',
  components: {
    ListTable,
    BaseButton,
    ResultsPagination,
    CustomSelect,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      isLoading: true,
      searchQuery: '',
      currentPage: 0,
      sort: {},
      selectedIndexPageSize: undefined,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
    };
  },
  methods: {
    getPrevPage () {
      return this.$store.getters['printers/previousPage'];
    },
    getNextPage () {
      return this.$store.getters['printers/nextPage'];
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedPrintersResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(o => parseInt(o.value) === size);
      this.selectedIndexPageSize = selectedSize;
    },
    getVariatedPrintersResults (value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length < value.filter.options.length && checked.length > 0) {
          if (checked[0].value === '1') {
            this.active = true;
          } else {
            this.active = false;
          }
        } else {
          this.active = undefined;
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('printers/fetchAllPrinters', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.active': this.active,
        'Filter.numberOfHoles': this.numberOfHoles,
        pageSize: this.pageSize,
        page: this.searchQuery.length > 0 ? '1' : this.currentPage,
      });
    },
    refreshPrinters () {
      this.$store.dispatch('printers/refreshPrinters').then((boolean) => {
        if (boolean) {
          this.getVariatedPrintersResults({});
        }
      });
    },
  },
  computed: {
    printers () {
      return this.$store.getters['printers/printers'];
    },
    pagination () {
      return this.$store.getters['printers/pagination'];
    },
  },
  created() {
    this.$store.dispatch('printers/fetchAllPrinters', {
      pageSize: this.pageSize,
      page: this.currentPage,
    }).then(() => {
      this.selectedPageSize(this.pagination.pageSize);
    }).finally(() => { this.isLoading = false; });
  },
};
</script>
<style lang="scss">
.element--is-loading {
  margin-left: .5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 20px;
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
}
</style>
