import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  isLoading: false,
  budgetResultUnitTotal: {},
  budgetResultUnitUnit: {},
};

const mutations = {
  isLoading(state, payload) {
    state.isLoading = payload;
  },
  budgetResultUnitTotal(state, payload) {
    state.budgetResultUnitTotal = payload;
  },
  budgetResultUnitUnit(state, payload) {
    state.budgetResultUnitUnit = payload;
  },
};

const getters = {
  isLoading: state => {
    return state.isLoading;
  },
  budgetResultUnitTotal: state => {
    return state.budgetResultUnitTotal;
  },
  budgetResultUnitUnit: state => {
    return state.budgetResultUnitUnit;
  },
};

const actions = {
  fetchBudgetResultUnitTotal: async (context, params) => {
    await fetch(
      context,
      'budgetresultunit-total',
      params,
      'budgetResultUnitTotal'
    );
  },
  fetchBudgetResultUnitUnit: async (context, params) => {
    await fetch(
      context,
      'budgetresultunit-unit',
      params,
      'budgetResultUnitUnit'
    );
  },
};
async function fetch(context, path, params, commit) {
  path = 'businessboard/drilldown/' + path;
  context.commit('isLoading', true);
  let response = {};
  try {
    response = await Vue.axios.post(path, params);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: `Fel vid hämtning: ${
        error.response.data.message || 'Felmeddelande saknas'
      }`,
      request: path,
    });
  }
  if (response && response.status === 200) {
    const data = response.data;
    context.commit(commit, data);
  }
  context.commit('isLoading', false);
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
