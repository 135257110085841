var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"businessboard"},[_c('download-modal',{attrs:{"selectedImageFormat":_vm.copyAndDownloadSettings().fileFormat,"show":this.showModal},on:{"close":() => (this.showModal = false)}}),_c('div',{staticClass:"businessboard--content"},[_c('div',[_c('last-updated',{attrs:{"last-update":this.lastMemberDataImport,"show-member":true,"show-game":false,"show-economy":false}})],1),_c('div',{staticClass:"businessboard--header"},[_c('h1',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t('Businessboard.membershipCategoriesGroups'))+" "),(_vm.isLoading)?_c('span',{staticClass:"element--is-loading"}):_vm._e()]),_c('div',{staticClass:"ingress"},[_vm._v(" "+_vm._s(_vm.$t('MemberCalculation.descriptionOfTheCategoryView'))+" ")])]),(_vm.membershipCalculationCategories)?_c('div',[_c('settings-modal',{attrs:{"show":_vm.showSettingsModal,"titleTextTranslationKey":'MemberCalculation.diagramAndTable',"options":_vm.yearOptions,"selected":{
          currentYear: _vm.membershipCalculationCategories?.settings.currentYear,
          comparisonYear:
            _vm.membershipCalculationCategories?.settings.comparisonYear,
        }},on:{"close":function($event){_vm.showSettingsModal = false},"updateSettings":args => _vm.updateMembershipCalculationSettings(args)}}),_c('div',{staticClass:"dashboard-buttons"},[_c('base-button',{staticClass:"button-green-hover",staticStyle:{"margin-right":"10px"},attrs:{"backgroundColor":"bb-primary","text":`${_vm.$t('Businessboard.settings')} ${_vm.$t(
            'diagram'
          ).toLowerCase()}`},on:{"click":function($event){_vm.showSettingsModal = !_vm.showSettingsModal}}},[_c('use-svg',{attrs:{"svg":"bb-gear-icon"}})],1),_c('base-button',{attrs:{"backgroundColor":"white","text":_vm.$t(_vm.buttonTextLabel)},on:{"click":_vm.copyData}},[_c('use-svg',{attrs:{"svg":"bb-copy-icon"}})],1)],1),(_vm.membershipCalculationCategories.hasPremium)?_c('section',{staticClass:"businessboard--table"},[_c('membershipcategories-dashboard-diagrams',{attrs:{"data":_vm.membershipCalculationCategories.diagrams,"settings":_vm.membershipCalculationCategories.settings,"legends":this.membershipCalculationCategories.legends}}),_c('div',{staticClass:"list-table-area"},[_c('list-table-game-calculation',{attrs:{"isLoading":_vm.isLoading,"items":_vm.membershipCalculationCategories.rows,"headers":_vm.membershipCalculationCategories.columns}})],1)],1):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }