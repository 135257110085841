<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <div>
        <span class="h1">{{ $t('GolfOffice.clubManagement') }}</span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
      </div>
      <p>
        Här finner ni klubbar i systemet. Ni ser kontaktuppgifter, vilka
        produkter de kör med nya OnTag m.m.
      </p>
      <div
        style="display: flex; justify-content: end"
        v-if="
          user.roles.GolfOffice &&
          user.roles.GolfOffice.some(e => e.value === 'BusinessBoardAdmin')
        ">
        <base-button
          :text="$t('ExportBusinessBoardUsers')"
          :button-size-small="true"
          v-element-loader="{ loaderKey: 'clubs-export-businessboardusers' }"
          @click="exportBusinessBoardUsers">
          <use-svg svg="export-icon" />
        </base-button>
      </div>
      <div
        class="golfoffice--utilitites input-group--inline"
        v-if="clubs">
        <div class="pagination-amount">
          <span>{{ $t('show') }}</span>
          <custom-select
            v-if="selectedIndexPageSize !== undefined"
            :required="false"
            @optionSelected="setPageSize"
            :selected="selectedIndexPageSize"
            :options="pageSizeOptions" />
          <span class="row-lines">{{ $t('lines') }}</span>
        </div>
        <div class="search-wrapper">
          <div class="input">
            <label
              for="searchQuery"
              class="input__label label__inline">{{ $t('search') }}</label>
            <input
              id="searchQuery"
              class="input__input input__inline search-input"
              @search="getVariatedClubResults"
              @keydown="getVariatedClubResults"
              v-model="searchQuery"
              type="search"
              name="searchQuery" />
          </div>
          <base-button
            class="search-button"
            :text="$t('search')"
            :button-size-small="true"
            @click="getVariatedClubResults" />
          <base-button
            :text="$t('Export')"
            :button-size-small="true"
            v-element-loader="{ loaderKey: 'clubs-export-clubs' }"
            @click="exportClubs">
            <use-svg svg="export-icon" />
          </base-button>
        </div>
        <!-- TODO: Add export when it's time to (not in FAS 1)
        <base-button
          class="button--icon-to-left"
          text="Export to excel"
          :button-size-small="true"
          @click="exportToExcel">
          <use-svg svg="export-icon"/>
        </base-button> -->
        <router-link :to="{ path: '/clubs/create-club' }">
          <base-button
            :text="$t('GolfOffice.addClub')"
            :button-size-small="true" />
        </router-link>
      </div>
      <list-table
        :items="clubs.rows"
        :headers="clubs.columns"
        @variationResults="getVariatedClubResults"
        :hide-in-mobile="['lastLogin']">
      </list-table>
      <results-pagination
        :first-row-on-page="pagination.firstRowOnPage"
        :last-row-on-page="pagination.lastRowOnPage"
        :row-count="pagination.rowCount"
        :page-size="pagination.pageSize"
        :page-count="pagination.pageCount"
        :current-page="pagination.currentPage"
        :next-page="getNextPage()"
        :prev-page="getPrevPage()"
        store="clubs"
        @currentPage="getVariatedClubResults" />
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import ResultsPagination from '@/components/results-pagination';
import CustomSelect from '@/components/form-elements/custom-select';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'Clubs',
  components: {
    ListTable,
    BaseButton,
    UseSvg,
    CustomSelect,
    ResultsPagination,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      isLoading: true,
      searchQuery: '',
      sort: {},
      currentPage: 0,
      filters: {},
      selectedIndexPageSize: undefined,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
    };
  },
  computed: {
    clubs() {
      return this.$store.getters['clubs/clubs'];
    },
    pagination() {
      return this.$store.getters['clubs/pagination'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
  },
  methods: {
    // TODO: Add export when it's time to (not in FAS 1)
    // exportToExcel() {
    // },
    getPrevPage() {
      return this.$store.getters['users/previousPage'];
    },
    getNextPage() {
      return this.$store.getters['users/nextPage'];
    },
    getVariatedClubResults(value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length > 0) {
          this.filters['Filter.' + value.filter.id] = checked.map(a => a.value);
          // localStorage.filters = this.filters['Filter.' + value.filter.id] = checked.map(a => a.value)
        } else {
          delete this.filters['Filter.' + value.filter.id];
          // localStorage.removeItem('filters')
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      const searchParams = {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        pageSize: this.pageSize,
        page: this.currentPage,
        ...this.filters,
      };
      localStorage.clubsParams = JSON.stringify(searchParams);

      this.$store.dispatch('clubs/fetchClubs', searchParams);
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedClubResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(
        o => parseInt(o.value) === size
      );
      this.selectedIndexPageSize = selectedSize;
    },
    exportClubs() {
      this.$store.dispatch('clubs/exportClubs', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        ...this.filters,
      });
    },
    exportBusinessBoardUsers() {
      this.$store.dispatch('businessboard/exportUsers');
    },
  },
  created() {
    let searchParams = {
      sortColumn: this.sort.id,
      sortDirection: this.sort.direction,
      search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
      'Filter.active': this.active,
      'Filter.onService': this.onService,
      'Filter.onCourse': this.onCourse,
      'Filter.dynaMaster': this.dynaMaster,
      'Filter.onTagVersion': this.onTag,
      'Filter.digitalScorecards': this.digitalScorecards,
      'Filter.voucher': this.voucher,
      'Filter.bookingSystem': this.bookingSystem,
      pageSize: this.pageSize,
      page: this.currentPage,
    };
    if (localStorage.clubsParams) {
      searchParams = JSON.parse(localStorage.clubsParams);
      this.filters = Object.keys(searchParams)
        .filter(key => key.includes('Filter'))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: searchParams[key],
          });
        }, {});
    }
    this.$store
      .dispatch('clubs/fetchClubs', searchParams)
      .then(() => {
        this.selectedPageSize(this.pagination.pageSize);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>
<style lang="scss">
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 20px;
}
.golfoffice {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.golfoffice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  .golfoffice--utilitites {
    justify-content: flex-end;
    margin: 1.25rem 0 0;
    @include media('to-medium') {
      display: block;
    }

    .select__wrapper {
      width: auto;
      padding: 0 0.625rem;

      button {
        @include media('to-medium') {
          margin: 0;
        }
      }
    }

    .search-wrapper {
      display: flex;

      @include media('to-small') {
        display: block;
      }

      button {
        margin: 0 0 0 0.625rem;
        @include media('to-small') {
          margin: 0;
        }
      }
    }

    .select__trigger {
      margin-left: 0 !important;
    }

    button {
      margin-left: 0.625rem;
      @include media('to-medium') {
        margin: 0.6rem 0;
      }
    }

    .pagination-amount {
      display: flex;
      align-items: baseline;
      flex: 1;
    }
  }
}
</style>
