<template>
  <transition :name="transition">
    <div
      v-if="show"
      class="modal c-modal"
      @click="e => close(e)">
      <div :class="['modal__container', size]">
        <div :class="wrapperClass">
          <slot>Modal Content</slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: 'modal__wrapper',
    },
    transition: {
      type: String,
      default: 'c-modal__transition',
    },
    size: {
      type: String,
      default: 'big',
    },
  },

  watch: {
    show: function (newVal, oldVal) {
      if (newVal) {
        document.querySelector('body').classList.add('t-no-scroll');
      } else {
        document.querySelector('body').classList.remove('t-no-scroll');
      }
    },
  },

  methods: {
    close: function (e) {
      if (e.target.classList.contains('c-modal')) {
        this.$emit('close-modal');
      }
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  text-align: left;
  overflow: scroll;
}
.modal__container {
  transition: all 0.3s ease;
  position: relative;
  top: 9.375rem;
  width: 95%;
  max-width: 60rem;
  margin: auto;

  &.biggest {
    @include media('medium') {
      width: 90%;
      max-width: unset;
    }
  }
  &.big {
    @include media('medium') {
      width: 60%;
    }
  }
  &.small {
    @include media('medium') {
      width: 30%;
    }
  }
  &.fit {
    width: fit-content;
  }
}

.modal__wrapper {
  padding: 1.25rem;
  background-color: color(white);
  text-align: center;
  font-size: 1rem;
  border: 1px solid #131516;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  @include media('medium') {
    padding: 0;
    padding-bottom: 1.125rem;
  }

  .modal__content {
    padding: 1.125rem;
    display: flex;
    justify-content: center;
    color: color(black);
  }

  .modal__content--actions {
    display: flex;
    padding: 0 1.125rem;

    @include media('small') {
      justify-content: center;
    }

    button {
      margin: 0 1rem;
    }

    .select__wrapper {
      width: 30%;
    }
  }
}

.modal__header {
  background-color: color(ontag-blue);
  color: color(white);
  padding: 0.635rem 0;
}

.modal__transition-enter {
  opacity: 0;
}

.modal__transition-leave-active {
  opacity: 0;
}

.modal__transition-enter .c-modal__container,
.modal__transition-leave-active .c-modal__container {
  transform: scale(2);
}
</style>
