<template>
  <div class="ontag">
    <!-- Header block -->
    <div class="ontag__content">
      <h1>Beställ Scorekort</h1>
      <p>Se över ert lager och lägg er beställning här nedan.</p>
    </div>

    <!-- info-text block -->
    <div
      v-if="settingsclubinfo.showInformationText"
      class="ontag__content ontag__content--green">
      <span>
        <p class="exclemation">!</p>
        {{ settingsclubinfo.title }}
      </span>
      <p>{{ settingsclubinfo.information }}</p>
    </div>

    <!-- order scorecards block -->
    <div class="ontag__content-wrapper">
      <div class="ontag__content ontag__content--narrow">
        <h2 class="h-scorecard-type">Standardkort, 3-delat</h2>
        <div class="info-container">
          <p>
            Mått: 110X273 mm<br />
            1000 styck per låda
          </p>
          <div>
            <img src="../../assets/images/SC-3-delat.jpg" />
          </div>
        </div>
        <span class="button-container">
          <router-link
            :to="{ path: '/ontag/orderscorecard/make-order-3-piece' }">
            <base-button :text="'Beställ scorekort'"> </base-button>
          </router-link>
          <base-button
            :text="'Mer info'"
            @click="showModal3piece">
          </base-button>
        </span>
      </div>
      <div class="ontag__content ontag__content--narrow">
        <h2 class="h-scorecard-type">Standardkort, 4-delat</h2>
        <div class="info-container">
          <p>
            Mått: 110X362 mm<br />
            1000 styck per låda
          </p>
          <br />
          <div>
            <img src="../../assets/images/SC-4-delat.jpg" />
          </div>
        </div>
        <span class="button-container">
          <router-link
            :to="{ path: '/ontag/orderscorecard/make-order-4-piece' }">
            <base-button :text="'Beställ scorekort'"> </base-button>
          </router-link>
          <base-button
            :text="'Mer info'"
            @click="showModal4piece">
          </base-button>
        </span>
      </div>
    </div>
    <div
      v-if="this.scorecardorder.clubScorecardSize != 0"
      class="ontag__content">
      <h2 class="h-scorecard-type">Klubbspecifika scorekort - Repeat order</h2>
      <span class="button-container">
        <router-link
          v-if="this.scorecardorder.clubScorecardSize == 1"
          :to="{
            path: '/ontag/orderscorecard/make-order-clubspecific-3-piece',
          }">
          <base-button :text="'Beställ scorekort'"> </base-button>
        </router-link>
        <router-link
          v-else-if="this.scorecardorder.clubScorecardSize == 2"
          :to="{
            path: '/ontag/orderscorecard/make-order-clubspecific-4-piece',
          }">
          <base-button :text="'Beställ scorekort'"> </base-button>
        </router-link>
        <base-button
          :text="'Mer info'"
          @click="showModalClubSpecific">
        </base-button>
      </span>
    </div>
    <div
      v-else
      class="ontag__content">
      <h2>Klubbspecifika scorekort</h2>
      <p>
        Du har tidigare inte beställt några klubbspecifika scorekort. Kontakta
        oss så hjälper vi er att klubbifiera era scorekort!
      </p>
      <span class="button-container">
        <a href="contact-form"></a>
        <base-button
          :text="'kontakta oss'"
          @click="scrollToForm">
        </base-button>
        <!-- <base-button
        :text="'Mer info'"
        :backgroundColor="'white'"
        class="button--color-black">
        </base-button> -->
      </span>
    </div>

    <!-- number of cards ordered -->
    <div class="ontag__content-wrapper">
      <div class="ontag__content ontag__content--narrow">
        <h3>Totalt beställda scorekort 2022</h3>
        <h1>{{ scorecardorder.orderCount }} st</h1>
      </div>
      <div class="ontag__content ontag__content--narrow">
        <h3>Totalt beställda scorekort 2021</h3>
        <h1>{{ scorecardorder.orderCountLastYear }} st</h1>
      </div>
    </div>

    <!-- Order history -->
    <div class="ontag__content">
      <h2>Orderhistorik</h2>
      <p>Text</p>
      <list-table
        v-if="showMoreHistory"
        :headers="scorecardorderhistory.columns"
        :items="scorecardorderhistory.rows">
      </list-table>
      <list-table
        v-else
        :headers="scorecardorder.orders.columns"
        :items="scorecardorder.orders.rows">
      </list-table>
      <base-button
        :text="!showMoreHistory ? 'Läs in fler ordrar' : 'Dölj ordrar'"
        class="button--center"
        @click="toggleShowMoreHitory">
      </base-button>
    </div>
    <!-- terms -->
    <div class="ontag__content">
      <h2>Villkor</h2>
      <a
        :href="terms.termsUrl"
        target="_blank">
        <base-button
          :text="$t('showTerms')"
          class="button--terms"
          :button-size-small="true">
        </base-button>
      </a>
    </div>
    <!-- Q&A -->
    <div class="ontag__content">
      <h2>Frågor och svar</h2>
      <div class="ontag__qa-conatiner">
        <template>
          <collapsible
            :isOpen="false"
            class="qa-collapsible">
            <div slot="trigger">
              <div
                class="customTrigger"
                @click="toggleHide">
                <h3>Vilka leveransveckor finns 2022?</h3>
              </div>
            </div>
            <div class="answer">
              <p>
                Vecka 10, deadline för beställning 2022-02-18<br />
                Vecka 14, deadline för beställning 2022-03-18 (Påsk)<br />
                Vecka 18, deadline för beställning 2022-04-13<br />
                Vecka 21, deadline för beställning 2022-05-13 (Kristi
                Himmelsfärd)<br />
                Vecka 25, deadline för beställning 2022-06-03 (Midsommar)<br />
                Vecka 36, deadline för beställning 2022-08-19
              </p>
            </div>
          </collapsible>
        </template>
        <template>
          <collapsible
            :isOpen="false"
            class="qa-collapsible">
            <div slot="trigger">
              <div
                class="customTrigger"
                @click="toggleHide">
                <h3>När kan jag ha mina beställda standardkort på plats?</h3>
              </div>
            </div>
            <div class="answer">
              <p>
                Normal leveranstid är 1-2 dgr om ni lägger er beställning innan
                kl. 11,00.
              </p>
            </div>
          </collapsible>
        </template>
        <template>
          <collapsible
            :isOpen="false"
            class="qa-collapsible">
            <div slot="trigger">
              <div
                class="customTrigger"
                @click="toggleHide">
                <h3>Vi behöver göra en ändring.</h3>
              </div>
            </div>
            <div class="answer">
              <p>
                Kontakta monica.karlsson@tietoevry.com eller
                michael.dammert@tietoevry.com
              </p>
            </div>
          </collapsible>
        </template>
        <template>
          <collapsible
            :isOpen="false"
            class="qa-collapsible">
            <div slot="trigger">
              <div
                class="customTrigger"
                @click="toggleHide">
                <h3>Vi vill byta färg på vouchern</h3>
              </div>
            </div>
            <div class="answer">
              <p>Kontakta monica.karlsson@tietoevry.com</p>
            </div>
          </collapsible>
        </template>
      </div>
    </div>

    <modal-window
      :show="showModal"
      @close-modal="toggleHideModal">
      <div class="modal__wrapper">
        <div class="modal__header">
          Förhandsgranskning -
          <span v-if="this.show3PieceInfo">3-delat standardkort</span>
          <span v-else-if="this.show4PieceInfo">4-delat standardkort</span>
          <span
v-else-if="this.showClubSpecificInfo">Klubbspecifika scorekort</span>
        </div>
        <div class="modal__sc-img">
          <img
            v-if="show3PieceInfo"
            src="../../assets/images/SC-3-delat.jpg" />
          <img
            v-else-if="show4PieceInfo"
            src="../../assets/images/SC-4-delat.jpg" />
        </div>
        <div class="modal__content">
          <h2>Information</h2>
          <p v-if="show3PieceInfo">
            3-delat scorekort<br /><br />
            Mått 110X273 mm<br /><br />
            1 000 styck per låda
          </p>
          <p v-else-if="show4PieceInfo">
            4-delat scorekort<br /><br />
            Mått 110X362 mm<br /><br />
            1 000 styck per låda
          </p>
          <p v-else-if="showClubSpecificInfo">
            Klubbspecifikt scorekort<br /><br />
            Mått
            <span
v-if="this.scorecardorder.clubScorecardSize == 1">110X273 mm</span>
            <span
v-else-if="this.scorecardorder.clubScorecardSize == 2">110X362 mm</span><br /><br />
            1000 styck per låda
          </p>
        </div>
        <div class="modal__content">
          <h2>Styckpris, exklusive moms och frakt</h2>
          <table class="price-table">
            <thead>
              <th>Intervall</th>
              <th>Pris</th>
            </thead>
            <tbody>
              <tr>
                <td>1 000 - 19 000</td>
                <td
                  v-if="
                    show3PieceInfo || this.scorecardorder.clubScorecardSize == 1
                  ">
                  {{ scorecardorder.priceSettings.threePartPriceOne }} kr/st
                </td>
                <td
                  v-else-if="
                    show4PieceInfo || this.scorecardorder.clubScorecardSize == 2
                  ">
                  {{ scorecardorder.priceSettings.fourPartPriceOne }} kr/st
                </td>
              </tr>
              <tr>
                <td>20 000 - 29 000</td>
                <td
                  v-if="
                    show3PieceInfo || this.scorecardorder.clubScorecardSize == 1
                  ">
                  {{ scorecardorder.priceSettings.threePartPriceTwo }} kr/st
                </td>
                <td
                  v-else-if="
                    show4PieceInfo || this.scorecardorder.clubScorecardSize == 2
                  ">
                  {{ scorecardorder.priceSettings.fourPartPriceTwo }} kr/st
                </td>
              </tr>
              <tr>
                <td>30 000 - 39 000</td>
                <td
                  v-if="
                    show3PieceInfo || this.scorecardorder.clubScorecardSize == 1
                  ">
                  {{ scorecardorder.priceSettings.threePartPriceThree }} kr/st
                </td>
                <td
                  v-else-if="
                    show4PieceInfo || this.scorecardorder.clubScorecardSize == 2
                  ">
                  {{ scorecardorder.priceSettings.fourPartPriceThree }} kr/st
                </td>
              </tr>
              <tr>
                <td>> 40 000</td>
                <td
                  v-if="
                    show3PieceInfo || this.scorecardorder.clubScorecardSize == 1
                  ">
                  {{ scorecardorder.priceSettings.threePartPriceFour }} kr/st
                </td>
                <td
                  v-else-if="
                    show4PieceInfo || this.scorecardorder.clubScorecardSize == 2
                  ">
                  {{ scorecardorder.priceSettings.fourPartPriceFour }} kr/st
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="showClubSpecificInfo"
          class="modal__content">
          <h2>Startavgift för tryck</h2>
          <p>{{ scorecardorder.priceSettings.repeatPrice }} kr</p>
        </div>
        <hr />
        <base-button
          class="button--background-blue button--right"
          :text="'stäng'"
          @click="toggleHideModal">
        </base-button>
      </div>
    </modal-window>
  </div>
</template>

<script>
// import UseSvg from '@/components/use-svg'
import BaseButton from '@/components/form-elements/base-button';
import ListTable from '@/components/list-table/list-table-businessboard';
import 'vue-collapsible-component/lib/vue-collapsible.css';
import Collapsible from 'vue-collapsible-component';
import ModalWindow from '@/components/modal/modal';

export default {
  name: 'ScorecardOrderOld',
  components: {
    // UseSvg,
    BaseButton,
    ListTable,
    Collapsible,
    ModalWindow,
  },

  data() {
    return {
      isOpen: false,
      showModal: false,
      showMoreHistory: false,
      show3PieceInfo: false,
      show4PieceInfo: false,
      showClubSpecificInfo: false,
      scorecardType: 0,
    };
  },
  methods: {
    toggleHide() {
      this.isOpen = !this.isOpen;
    },
    showModal3piece() {
      this.show3PieceInfo = true;
      this.showModal = true;
    },
    showModal4piece() {
      this.show4PieceInfo = true;
      this.showModal = true;
    },
    showModalClubSpecific() {
      this.showClubSpecificInfo = true;
      this.showModal = true;
    },
    toggleHideModal() {
      this.showModal = !this.showModal;
      if (this.show3PieceInfo === true) {
        this.show3PieceInfo = false;
      } else if (this.show4PieceInfo === true) {
        this.show4PieceInfo = false;
      } else if (this.showClubSpecificInfo === true) {
        this.showClubSpecificInfo = false;
      }
    },
    toggleShowMoreHitory() {
      this.showMoreHistory = !this.showMoreHistory;
    },
    scrollToForm() {
      window.FreshworksWidget('open', 'ticketForm');
    },
    loadWidget() {
      window.fwSettings = { widget_id: 44000003424 };
      const n = function () {
        n.q.push(arguments);
      };
      n.q = [];
      window.FreshworksWidget = n;
      let fdwidgetScript = document.createElement('script');
      fdwidgetScript.id = 'widgetScript';
      fdwidgetScript.setAttribute(
        'src',
        'https://widget.freshworks.com/widgets/44000003424.js'
      );
      fdwidgetScript.onload = this.fillWidget();
      document.head.appendChild(fdwidgetScript);
    },
    fillWidget() {
      let name = this.user.firstName + ' ' + this.user.lastName;
      window.FreshworksWidget('identify', 'ticketForm', {
        type: '\u00D6vrigt',
        subject:
          'Scorekortsfr\u00E5ga via GolfOffice' +
          (this.user.club === undefined ? '' : ' från ' + this.user.club.name),
        name: name,
        email: this.user.email,
        custom_fields: {
          cf_klubbnamn: this.user.club === undefined ? '' : this.user.club.name,
        },
      });
      window.FreshworksWidget('hide', 'ticketForm', ['type', 'subject']);
    },
  },
  computed: {
    scorecardorder() {
      return this.$store.getters['scorecardOrder/scorecardorder'];
    },
    scorecardorderhistory() {
      return this.$store.getters['scorecardOrder/scorecardorderhistory'];
    },
    scorecardallorders() {
      return this.$store.getters['scorecardOrder/scorecardallorders'];
    },
    settingsclubinfo() {
      return this.$store.getters['scorecardOrder/settingsclubinfo'];
    },
    terms() {
      return this.$store.getters['scorecardOrder/terms'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    scorecardorderaddresses() {
      return this.$store.getters['scorecardOrder/scorecardorderaddresses'];
    },
  },
  watch: {
    // if this page is reloaded user object is not loaded yet. wait for it before loading widget
    user() {
      this.loadWidget();
    },
  },
  created() {
    this.$store.dispatch('scorecardOrder/fetchScorecardOrder');
    this.$store.dispatch('scorecardOrder/fetchScorecardOrderHistory');
    this.$store.dispatch('scorecardOrder/fetchScorecardAllOrders');
    this.$store.dispatch('scorecardOrder/fetchSettingsClubInfo');
    this.$store.dispatch('scorecardOrder/fetchTerms');
    this.$store.dispatch('scorecardOrder/fetchScorecardOrderAddresses');
  },
  mounted() {
    if (Object.keys(this.user).length > 0) {
      if (document.getElementById('widgetScript') === null) {
        this.loadWidget();
      } else {
        // the widgetscript has been loaded before, but probably unmounted on destroy
        window.FreshworksWidget('boot');
        window.FreshworksWidget('show');
        this.fillWidget();
      }
    }
  },
  destroyed() {
    // unmount widget when leaving the page
    window.FreshworksWidget('destroy');
    // also remove script?
    // document.getElementById('widgetScript').outerHTML = '';
  },
};
</script>

<style lang="scss" scoped>
.ontag {
  &__content {
    margin-bottom: 20px;
    h2 {
      font-weight: bolder;
    }
  }

  &__content--green {
    background-color: #33b595;

    span {
      font-weight: bold;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__content--narrow {
    width: 100%;

    @include media('medium') {
      width: 49%;
    }
  }

  &__qa-conatiner {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
.exclemation {
  background-color: black;
  width: 20px;
  display: inline-block;
  color: #33b595;
  text-align: center;
  border-radius: 10px;
  padding-top: 1px;
}

// .h-scorecard-type {
//   background-color: color(blue);
//   color: white;
//   margin: 0px;
//   padding: 20px 30px;
//   border-radius: 10px;
//   font-weight: normal !important;
// }

.info-container {
  display: flex;
  flex-direction: row;

  p {
    margin: 30px 10% 30px 0px;
  }
  div {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    img {
      max-width: 100%;
    }
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  button {
    margin-right: 10px;
  }
}

.button {
  width: 200px;
  border-radius: 10px;
}
.button--color-black {
  color: black;
}

.button--center {
  margin: 10px auto;
}

.button--right {
  margin-left: auto;
  margin-right: 0;
}

.qa-collapsible {
  margin-bottom: 10px;
  background-color: rgb(235, 235, 235);
  border-radius: 3px;
  padding-left: 10px;
}

.customTrigger {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.qa-svg {
  margin-right: 10px;
  margin-top: 30px;
  width: 30px;
  height: 15px;
}

.textarea-container {
  width: 50%;

  textarea {
    width: 100%;
    height: 200px;
    background-color: rgb(245, 245, 245);
    resize: none;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 3px;
    margin-bottom: 10px;
  }
}

// modal styling
.modal__wrapper {
  background-color: color(gray-light);
  text-align: left;

  .modal__header {
    text-align: center;
  }
  .modal__sc-img {
    width: 70%;
    margin: 10px auto;

    img {
      max-width: 100%;
    }
  }

  .modal__content {
    background-color: #ffffff;
    width: 60%;
    margin: 0 auto 10px;
    justify-content: left;
    flex-direction: column;
    border-radius: 5px;

    h2 {
      margin: 0;
      font-weight: bold;
    }

    .price-table {
      text-align: right;
      border: none;
      border-collapse: collapse;

      thead {
        background-color: #d8d8d8;
      }
      th,
      td {
        height: 40px;
      }
      td:first-child {
        width: 150px;
      }

      td:nth-child(2),
      th:nth-child(2) {
        padding-right: 50px;
      }

      tr:nth-child(2n) {
        background-color: color(gray-light);
      }
    }
  }

  hr {
    box-sizing: border-box;
  }

  .button--right {
    margin-right: 40px;
  }
}
</style>
