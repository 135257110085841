<template>
  <div class="close--button">
    <base-button
      backgroundColor="bb-primary"
      :text="$t('done')"
      @click="closeModal()" />
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';

export default {
  name: 'close-modal-button',
  components: {
    BaseButton,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit('closeModal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.close--button {
  float: right;
  margin: 1rem;
}
</style>
