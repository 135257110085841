<template>
  <div class="expand-collapse">
    <div :class="`expand-collapse__fold fold__level-${depth}`">
      <template v-if="node.subPages && node.subPages.length > 0">
        <button
          :id="id"
          :aria-expanded="isExpanded ? true : false"
          :aria-controls="isExpanded ? contentId : null"
          class="expand-collapse__label"
          type="button"
          @click="toggleExpand">
          <span class="expand-collapse__title">
            <slot name="title"></slot>
          </span>
          <span
            class="expand-collapse__icon"
            :class="{ 'expand-collapse__rotate': isExpanded }">
            <span
class="visually-hidden">{{ isExpanded ? $t('close') : $t('open') }}
            </span>
          </span>
        </button>
      </template>
      <template v-else>
        <slot name="title"></slot>
      </template>
      <div
        :id="contentId"
        class="expand-collapse__content"
        v-if="isExpanded"
        :aria-hidden="!isExpanded ? true : false"
        :aria-labelledby="id">
        <div class="expand-collapse__content-inner">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalizationMixin from '../mixins/localization-mixin.vue';
export default {
  name: 'ExpandCollapse',
  mixins: [LocalizationMixin],
  props: {
    id: {
      type: String,
      default: '',
    },
    contentId: {
      type: String,
      default: '',
    },
    node: {
      type: Object,
      default: () => {},
    },
    depth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
<style lang="scss" scoped>
.expand-collapse {
  list-style: none;
  margin: 0;

  .expand-collapse__label {
    display: flex;
    width: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    padding: 0;
    justify-content: space-between;
    outline: none;
    font-weight: bold;
    margin: 0;
    text-align: left;
  }
  .expand-collapse__fold {
    padding: 8px 0 7px 0;
    &.fold__level-2 {
      border-bottom: 1px solid color(gray-light);
    }
  }
  .expand-collapse__icon {
    color: #0067a5;
    transition: transform 0.2s ease;
  }
  .expand-collapse__rotate {
    transform: rotate(180deg);
  }
  .expand-collapse__icon {
    border: 1px solid color(black);
    border-width: 0 0 2px 2px;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    right: 10px;

    &.expand-collapse__rotate {
      transform: rotate(135deg);
    }
  }
  .expand-collapse__button {
    margin-top: 20px;
  }
  .expand-collapse__title {
    display: flex;
    align-items: center;
    span {
      margin-left: 0.5rem;
    }
  }
}
</style>
