var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diagram-area"},[_c('div',[_c('dot-chart',{staticClass:"dot-chart",attrs:{"amount":_vm.data.totalMembers,"label-translation-key":'members'}}),_c('render-change',{staticClass:"render-change",attrs:{"change":{
        value: _vm.data.comparedToBudget.num,
        percentage: _vm.data.comparedToBudget.percentage,
        comparisonLabel: `${_vm.$t('Businessboard.budget').toLowerCase()} ${
          _vm.settings.currentYear
        }`,
      }}}),(_vm.data.comparedToLastMonth)?_c('render-change',{staticClass:"render-change",attrs:{"change":{
        value: _vm.data.comparedToLastMonth.num,
        percentage: _vm.data.comparedToLastMonth.percentage,
        comparisonLabel: `${_vm.$t(
          `${
            _vm.data.dataMonth
              ? _vm.$t(`MonthsShort.${_vm.monthTranslationKeys[_vm.data.dataMonth - 2]}`)
              : ''
          }`
        ).toLowerCase()} ${_vm.settings.currentYear}`,
      }}}):_vm._e(),(_vm.data.comparedToCurrentMonthLastYear)?_c('render-change',{staticClass:"render-change",attrs:{"change":{
        value: _vm.data.comparedToCurrentMonthLastYear.num,
        percentage: _vm.data.comparedToCurrentMonthLastYear.percentage,
        comparisonLabel: `${_vm.$t(
          `${
            _vm.data.dataMonth
              ? _vm.$t(`MonthsShort.${_vm.monthTranslationKeys[_vm.data.dataMonth - 1]}`)
              : ''
          }`
        ).toLowerCase()} ${_vm.settings.comparisonYear}`,
      }}}):_vm._e()],1),_c('div',[_c('h3',[_vm._v(" "+_vm._s(` ${_vm.$t('GameCalculation.distribution')} ${_vm.$t('MemberCalculation.junior')}/ ${_vm.$t('MemberCalculation.senior')}`)+" ")]),_c('div',[_c('donut-chart-filterable',{attrs:{"styles":{ height: '10rem', width: '10rem' },"colors":_vm.data.colorKeys.map(c => _vm.colors[c]),"data":{
          labels: [
            _vm.$t('MemberCalculation.junior'),
            _vm.$t('MemberCalculation.senior'),
          ],
          values: [
            _vm.data.juniorMembersInPercentage,
            100 - _vm.data.juniorMembersInPercentage,
          ],
        },"display-legend":false}})],1),_c('render-change',{staticClass:"render-change",attrs:{"change":{
        value: _vm.data.juniorMembersComparedToLastYear.num,
        percentage: _vm.data.juniorMembersComparedToLastYear.percentage,
        comparisonLabel: _vm.settings.comparisonYear.toString(),
      },"legend":{
        label: `${_vm.$t('MemberCalculation.junior')} ${
          _vm.data.juniorMembersInPercentage
        }%`,
        color: _vm.data.colorKeys[0],
      }}}),_c('render-change',{staticClass:"render-change",attrs:{"change":{
        value: _vm.data.seniorMembersComparedToLastYear.num,
        percentage: _vm.data.seniorMembersComparedToLastYear.percentage,
        comparisonLabel: _vm.settings.comparisonYear.toString(),
      },"legend":{
        label: `${_vm.$t('MemberCalculation.senior')} ${
          100 - _vm.data.juniorMembersInPercentage
        }%`,
        color: _vm.data.colorKeys[1],
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }