<template>
  <div class="businessboard">
    <!-- <help-text :helpTextData="helpText" change this later /> -->
    <div class="businessboard--content">
      <div class="businessboard--header">
        <div>
          <span class="h1">{{ $t('GreenFee.greenFeeSim') }}</span>
          <span
            v-if="isLoading"
            class="element--is-loading"></span>
        </div>
        <base-button
          @click="createSimulation"
          class="button--background-blue"
          :text="$t('GreenFee.createSimulation')">
        </base-button>
      </div>
      <div class="businessboard--category">
        <section
          v-for="(item, index) in greenfeesimulation"
          :key="index">
          <div>
            <div class="businessboard--title">
              <div class="title-left">
                <input
                  class="input--simulation-title"
                  :placeholder="$t('GreenFee.enterName')"
                  type="text"
                  v-model="item.name"
                  @blur="
                    e => {
                      if (item.name) saveChanges(item, index);
                    }
                  " />
                <use-svg
                  svg="edit-icon"
                  color="white" />
              </div>
              <div>
                <button
                  class="title--button"
                  @click="deleteSimulation(item.id)">
                  <use-svg
                    svg="trash-icon"
                    color="white" />
                </button>
                <button
                  class="title--button"
                  v-on:click="toggleHide(item)">
                  <use-svg
                    svg="arrow-right"
                    :class="{ 'svg--180-negative': item.showSimulation }" />
                </button>
              </div>
            </div>
            <div
              class="businessboard--simulation-container"
              v-if="item.showSimulation">
              <div class="box1">
                <div class="sub-heading">{{ $t('GreenFee.dataPeriod') }}</div>
                <div class="select-container">
                  <custom-select
                    :selected="year(item.from)"
                    :options="years"
                    @optionSelected="e => selectYear(item, e.value, index)"
                    class="select">
                  </custom-select>
                  <custom-select
                    :selected="month(item.from)"
                    :options="months(item.selectedYear)"
                    @optionSelected="e => selectFromMonth(item, e.value, index)"
                    class="select">
                  </custom-select>
                  <p>-</p>
                  <custom-select
                    :selected="month(item.to)"
                    :options="months(item.selectedYear)"
                    @optionSelected="e => selectToMonth(item, e.value, index)"
                    class="select">
                  </custom-select>
                </div>
                <div class="sub-heading">{{ $t('comment').toLowerCase() }}</div>
                <textarea
                  v-model="item.comment"
                  @blur="
                    e => {
                      if (item.comment) saveChanges(item, index);
                    }
                  ">
                </textarea>
                <div class="created">
                  <div>
                    {{ $t('GreenFee.created') }} {{ item.createdAtString }}
                  </div>
                  <div>
                    {{ $t('GreenFee.edited') }} {{ item.updatedAtString }}
                  </div>
                </div>
              </div>
              <div class="box2">
                <div class="sub-heading">
                  <div class="calc-measure">
                    <div>{{ $t('GreenFee.calcMeasure') }}</div>
                    <div>
                      <input
                        type="radio"
                        value="1"
                        :name="item.id"
                        v-model="item.unit"
                        @change="item.value = ''" />
                      <label for="measure-kr">{{ $t('crowns') }}</label>
                      <input
                        type="radio"
                        value="2"
                        :name="item.id"
                        v-model="item.unit"
                        @change="item.value = ''" />
                      <label for="measure-percent">{{
                        $t('percentage')
                      }}</label>
                    </div>
                  </div>
                  <div class="increase">
                    <div>{{ $t('GreenFee.increaseSlashDecrease') }}</div>
                    <div class="increase-row">
                      <input
                        type="number"
                        class="increase-input"
                        v-model="item.value"
                        @blur="e => saveChanges(item, index)" />
                      <div
                        class="calc-unit"
                        v-if="item.unit == 1">
                        {{ $t('denomination') }}
                      </div>
                      <div
                        class="calc-unit"
                        v-if="item.unit == 2">
                        %
                      </div>
                      <button
                        v-on:click="saveChanges(item, index)"
                        class="update-simulation-button">
                        <use-svg
                          svg="update-icon"
                          color="white"
                          class="arrows" />
                      </button>
                    </div>
                  </div>
                </div>
                <table>
                  <tr>
                    <td class="bg-color-grey">
                      <strong v-if="item.unit == 1">{{
                        $t('percentage')
                      }}</strong>
                      <strong v-if="item.unit == 2">{{ $t('crowns') }}</strong>
                    </td>
                    <td class="bg-color-grey">
                      {{ item.changeValue }}
                      <span v-if="item.unit == 1">%</span>
                      <span v-if="item.unit == 2">{{
                        $t('denomination')
                      }}</span>
                    </td>
                    <td class="bg-color-green">
                      <strong>{{ $t('GreenFee.newAverage') }}</strong>
                    </td>
                    <td class="bg-color-green">
                      {{ item.newGreenFee }} {{ $t('denomination') }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bg-color-white">
                      <strong>{{ $t('GreenFee.currentAverage') }}</strong>
                    </td>
                    <td class="bg-color-white">
                      {{ item.currentGreenFee }} {{ $t('denomination') }}
                    </td>
                    <td class="bg-color-lightgreen">
                      <strong>{{ $t('roundsAmount') }}</strong>
                    </td>
                    <td class="bg-color-lightgreen">
                      {{ item.numberOfRounds > 0 ? '+' : ''
                      }}{{ item.numberOfRounds }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bg-color-grey">
                      <strong>{{ $t('GreenFee.currentCoverage') }}</strong>
                    </td>
                    <td class="bg-color-grey">
                      {{ item.currentCoveringPercent }}%
                    </td>
                    <td class="bg-color-green">
                      <strong>{{ $t('percentage') }}</strong>
                    </td>
                    <td class="bg-color-green">{{ item.percent }}%</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthsLabelsShort } from '@/core/consts';

import CustomSelect from '@/components/form-elements/custom-select';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
// import helpText from '../../components/help-text/help-text.vue';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'GreenfeeSimulation',
  mixins: [LocalizationMixin],
  components: {
    CustomSelect,
    BaseButton,
    UseSvg,
    // helpText,
  },

  data() {
    return {
      isLoading: true,
      pickedUnit: '',
      years: {},
      validMonths: {},
    };
  },
  computed: {
    greenfeesimulation() {
      return this.$store.getters['businessboard/greenfeesimulation'];
    },
    period() {
      return this.$store.getters['businessboard/greenfeesimulationperiod'];
    },
    monthNames() {
      return [''].concat(
        MonthsLabelsShort.map(l => this.$t(`MonthsShort.${l}`))
      );
    },
    helpText() {
      return [
        {
          title: '',
          content: this.$t('GreenFee.info'),
        },
      ];
    },
  },
  methods: {
    saveChanges(item, index) {
      let fromDate = new Date(item.from);
      let toDate = new Date(item.to);
      if (item.selectedYear) {
        fromDate.setYear(item.selectedYear);
        toDate.setYear(item.selectedYear);
        item.from = fromDate.toLocaleDateString('sv-SE');
        item.to = toDate.toLocaleDateString('sv-SE');
      }
      if (item.selectedFromMonth) {
        fromDate.setMonth(item.selectedFromMonth);
        item.from = fromDate.toLocaleDateString('sv-SE');
      }
      if (item.selectedToMonth) {
        toDate.setMonth(item.selectedToMonth);
        item.to = toDate.toLocaleDateString('sv-SE');
      }
      this.$store
        .dispatch('businessboard/postGreenfeesimulation', item)
        .then(response => {
          let newItem = response.data.result;
          newItem.showSimulation = item.showSimulation;
          this.$set(this.greenfeesimulation, index, newItem);
        });
    },
    month(d) {
      let dt = new Date(d);
      return dt.getMonth();
    },
    year(d) {
      let dt = new Date(d);
      return dt.getFullYear();
    },
    selectYear(item, y, idx) {
      this.$set(item, 'selectedYear', y);
      if (
        (item.selectedFromMonth && item.selectedToMonth) ||
        (item.from && item.to)
      ) {
        this.saveChanges(item, idx);
      }
    },
    toggleHide(item) {
      this.$set(item, 'showSimulation', !item.showSimulation);
    },
    createSimulation() {
      this.greenfeesimulation.unshift({
        unit: 1,
        showSimulation: true,
      });
    },
    deleteSimulation(id) {
      if (confirm(this.$t('GreenFee.removePrompt'))) {
        this.$store
          .dispatch('businessboard/deleteGreenfeesimulation', id)
          .then(() => {
            this.$store.dispatch('businessboard/fetchGreenfeesimulation');
          });
      }
    },
    selectFromMonth(item, v, idx) {
      this.$set(item, 'selectedFromMonth', v);
      if (item.selectedToMonth || item.to) {
        this.saveChanges(item, idx);
      }
    },
    selectToMonth(item, v, idx) {
      this.$set(item, 'selectedToMonth', v);
      if (item.selectedFromMonth || item.from) {
        this.saveChanges(item, idx);
      }
    },
    months(y) {
      if (y in this.validMonths) {
        return this.validMonths[y];
      }
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(x => {
        return { value: x, displayName: this.monthNames[x] };
      });
    },
  },
  created() {
    this.$store
      .dispatch('businessboard/fetchGreenfeesimulationPeriod')
      .then(() => {
        //  const period = this.$store.state.businessboard.greenfeesimulationperiod;
        let d1 = new Date(this.period.fromDate);
        let d2 = new Date(this.period.toDate);
        let fromYear = d1.getFullYear();
        let toYear = d2.getFullYear();
        for (let y = fromYear; y <= toYear; y++) {
          this.years[y] = {
            value: y,
            displayName: y,
          };
          this.validMonths[y] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
            x => {
              return { value: x, displayName: this.monthNames[x] };
            }
          );
        }

        let fromMonth = d1.getMonth();
        let toMonth = d2.getMonth();
        this.validMonths[fromYear] = this.validMonths[fromYear].filter(
          x => x.value >= fromMonth
        );
        this.validMonths[toYear] = this.validMonths[toYear].filter(
          x => x.value <= toMonth
        );
      });
    this.$store
      .dispatch('businessboard/fetchGreenfeesimulation')
      .then(() => {
        for (let i = 0; i < 3 && i < this.greenfeesimulation.length; i++) {
          this.$set(this.greenfeesimulation[i], 'showSimulation', true);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>
<style lang="scss" scoped>
.businessboard--header {
  height: 3rem;
  margin: 1rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .button--background-blue {
    width: 15%;
    height: 1.7rem;
    min-width: 160px;
    margin-top: 1rem;
  }
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.businessboard {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}

.title--button {
  background-color: transparent;
  border: none;
  color: white;
}

.businessboard--title {
  background-color: color(bb-primary);
  color: color(white);
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  margin-top: 20px;
  .title-left {
    width: 20%;
  }
  .input--simulation-title {
    color: white;
    border: none;
    border-bottom: 0.1rem solid white;
    background-color: transparent;
    width: 80%;
  }

  input::placeholder {
    color: white;
  }

  .title {
    font-size: 1.2rem;
    flex: 1;
  }
  .button {
    color: white;
  }
  .button::before {
    content: '+';
  }
}

.businessboard--category {
  // margin: 1rem 0;
  // border: 1px solid black;

  .businessboard--simulation-container {
    display: flex;
    margin-bottom: 30px;
    border: 1px solid black;
    .box1 {
      padding: 1rem;
      width: 33%;
      border-right: 1px solid black;
      .sub-heading {
        padding: 0 0 0.1rem 0;
      }
      .select-container {
        display: flex;
        justify-content: space-between;
        text-align: center;
        .select {
          width: 30%;
          margin-top: 10px;
        }
      }
      textarea {
        width: 100%;
        height: 7em;
        resize: none;
        position: relative;
      }

      .created {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.8rem;
        margin-top: 1rem;
      }
    }
  }
  .box2 {
    padding: 1rem;
    width: 66%;
    .sub-heading {
      display: flex;
      flex-direction: row;
      justify-content: left;
      width: 60%;
      .calc-measure {
        input {
          margin-top: 1rem;
          margin-right: 0.5rem;
          transform: scale(1.5);
        }
        label {
          margin-right: 1rem;
        }
      }
      .increase {
        margin-left: 2rem;
        .increase-row {
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          .calc-unit {
            background-color: color(gray);
            padding: 3px;
          }
          .increase-input {
            width: 3rem;
            margin-right: 0.5rem;
          }
          .update-simulation-button {
            background-color: color(blue);
            border: none;
            color: white;
            margin-left: 0.5rem;
            border-radius: 3px;
          }
          .update-simulation-button:active {
            background-color: #006ea5;
          }
          .update-simulation-button .arrows {
            width: 0.9rem;
            margin-top: 2px;
          }
          .update-simulation-button:hover .arrows {
            transform: rotate(180deg);
            transition-duration: 1s;
          }
        }
      }
    }
    table {
      width: 100%;
      margin-top: 2.5rem;
      border-collapse: collapse;
      tr {
        height: 2.5rem;
        td:nth-child(2n) {
          width: 15%;
        }
        td:nth-child(2n -1) {
          width: 35%;
          padding-left: 2rem;
        }
        td:nth-child(2n) {
          text-align: right;
          padding-right: 2rem;
        }
        .bg-color-grey {
          background-color: color(gray);
        }
        .bg-color-white {
          background-color: white;
        }
        .bg-color-green {
          background-color: #c5dee0;
        }
        .bg-color-lightgreen {
          background-color: #dcf0f2;
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
