<template>
  <div class="golf-office">
    <div class="golf-office--content">
      <div>
        <router-link to="/">&laquo; {{ $t('back') }}</router-link>
      </div>
      <h1>{{ $t('forgotPassword') }}</h1>
      <p>{{ $t('GolfOffice.resetPasswordInfo') }}</p>
      <div class="content__input">
        <div class="content__input-group">
          <input-type
            :label="$t('email')"
            input-id="email"
            v-model="email"
            @input="checkEmailValidity" />
          <div
            v-if="failedEmailValidiation && this.email"
            class="input__error--message">
            {{ $t('GolfOffice.errorMail') }}
          </div>
          <!-- Honeypot below -->
          <input-type
            class="ohnohoney"
            label="Website Email"
            input-id="website-email"
            v-model="websiteEmail" />
        </div>
        <div class="content__input-actions">
          <base-button
            :text="$t('resetPassword')"
            @click="forgotPassword"
            :disabled="email.length <= 0 || failedEmailValidiation" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputType from '@/components/form-elements/input';
import BaseButton from '../components/form-elements/base-button';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
export default {
  name: 'ForgotPassword',
  mixins: [LocalizationMixin],
  components: {
    InputType,
    BaseButton,
  },
  data() {
    return {
      email: '',
      websiteEmail: '',
      failedEmailValidiation: false,
    };
  },
  methods: {
    forgotPassword() {
      if (this.email.length > 0) {
        if (this.websiteEmail.length > 0) {
          this.$router.push({ path: '/' });
        } else {
          this.$store
            .dispatch('user/forgotPassword', { email: this.email })
            .then(() => {
              this.$toasted.success(this.$t('resetMessage'));
            });
        }
      }
    },
    checkEmailValidity() {
      if (!this.email.includes('@')) {
        this.failedEmailValidiation = true;
      } else {
        this.failedEmailValidiation = false;
      }
    },
    toLogin() {
      this.$router.push({ path: '/' });
    },
  },
};
</script>
<style lang="scss" scoped>
.golf-office {
  flex-grow: 1;
}
.golf-office--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.content__input-actions {
  display: flex;
  button {
    margin-left: auto;
  }
}
</style>
