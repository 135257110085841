import { mapGetters } from 'vuex';

const mixin = {
  computed: {
    ...mapGetters(['breakpoints', 'screenOrientation']),
  },
  methods: {
    mq (key) {
      return this.breakpoints[key];
    },
  },
};

export default mixin;
