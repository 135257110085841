<script>
import VueTypes from 'vue-types';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import { Pie } from 'vue-chartjs';

import { COLORS } from './pie-chart-colors';

export default {
  extends: Pie,

  mixins: [LocalizationMixin],

  props: {
    labels: VueTypes.arrayOf(VueTypes.string).isRequired,
    data: VueTypes.arrayOf(VueTypes.number).isRequired,
    unitTranslationKey: VueTypes.string.def('piece'),
    percentage: VueTypes.arrayOf(VueTypes.number),
    borderWidth: VueTypes.number.def(1),
    colors: VueTypes.arrayOf(VueTypes.string).def(COLORS),
    legend: VueTypes.shape({
      position: VueTypes.string,
      labels: VueTypes.shape({ usePointStyle: VueTypes.bool }),
    }).def(undefined),
  },

  computed: {
    translatedUnit: function () {
      return this.unitTranslationKey;
    },
  },

  methods: {
    render() {
      this.renderChart(
        {
          labels: this.labels,
          unit: this.$t(this.unitTranslationKey).toLowerCase(),
          datasets: [
            {
              percentage: this.percentage,
              data: this.data,
              backgroundColor: this.colors,
              borderWidth: this.borderWidth,
            },
          ],
        },
        {
          legend: {
            display: !!this.legend,
            position: this.legend?.position || 'rigth',
            onClick: undefined,
            labels: {
              usePointStyle: this.legend?.usePointStyle || true,
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return `${data.labels[tooltipItem.index]}: ${
                  data.datasets[0].percentage[tooltipItem.index]
                }% - ${data.datasets[0].data[tooltipItem.index]} ${data.unit}`;
              },
            },
          },
          maintainAspectRatio: false,
        }
      );
    },
  },
  watch: {
    data() {
      this.render();
    },
  },

  mounted() {
    this.render();
  },
};
</script>
