<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <h1>{{ $t('Businessboard.editCourse') }}</h1>
      <form noValidate @submit.prevent="createCourse">
        <div class="content__input-group">
          <div class="input-group">
            <div class="input">
              <label
                for="courseName"
                class="input__label">{{ $t('Businessboard.courseName') }}*</label>
              <input
                id="courseName"
                class="input__input"
                v-model="course.courseName"
                type="text"
                required="true"
                name="courseName"/>
            </div>
            <div class="input">
              <label
                for="gitName"
                class="input__label">{{ $t('Businessboard.gitName') }}*</label>
              <input
                id="gitName"
                class="input__input"
                v-model="course.gitName"
                type="text"
                required="true"
                name="gitName"/>
                </div>
            <div class="input-group">
              <label
                for="isActive"
                class="input__label">{{ $t('active') }}</label>
              <toggle-switch
                label="Active"
                input-id="isActive"
                type="switch"
                :toggled="course.active"
                @toggle="toggle"/>
            </div>
          </div>
          <div class="input-group--inline user__actions">
            <router-link :to="'/data/course/edit-course/' + this.id">
              <base-button
              :text="$t('cancel')"/>
            </router-link>
              <base-button
                :text="$t('Businessboard.editCourse')"
                type="submit"
                v-element-loader="{ loaderKey: 'create-course' }"
                :disabled="!canCreate"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import ToggleSwitch from '@/components/form-elements/switch';

export default {
  name: 'CreateCourse',
  components: {
    BaseButton,
    ToggleSwitch,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      course: {},
    };
  },
  methods: {
    createCourse() {
      this.course.name = this.course.courseName;
      this.course.inactive = !this.course.active;
      this.$store.dispatch('businessboard/coursePatch', this.course).then(() => {
        this.$router.push({ path: '/businessboard/data' });
      });
    },
    toggle() {
      this.course.active = !this.course.active;
    },
  },
  computed: {
    canCreate() {
      return this.course.courseName && this.course.gitName;
    },
  },
  created() {
    if (this.id !== '0') {
      let course = this.$store.state.businessboard.data.courses.rows.find(r => r.id === this.id);
      if (course) {
        this.course = course;
      } else {
        this.$toasted.error('Course not found');
      }
    }
  },
};
</script>
<style lang="scss" scoped>
  .golfoffice {
    // margin: 1rem;
    flex-grow: 1;
    width: 100%;
    @include media('small') {
      width: calc((100% / 2) - (1rem * 1));
    }
    @include media('large') {
      width: calc((100% / 3) - (1rem * 2));
    }
  }
  .golfoffice--content {
    background-color: color(white);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    .content__input-group {
      width: 100%;
      @include media('medium') {
        width: calc((100% / 1.2) - (1rem * 2));
      }
      .input__label {
        width: 100%;
      }
      .user__actions {
        justify-content: flex-start;
        @include media('small') {
          justify-content: flex-end;
          // width: 70%;
        }

        a button {
          margin-left: 0;
        }

        button {
          margin-left: 1.25rem;
        }
      }
    }
  }
</style>
