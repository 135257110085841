<template>
  <svg
    viewBox="18 -36 73 73"
    width="100%"
    height="100%"
    transform="rotate(-90)">
    <text
      x="0"
      y="-54"
      dominant-baseline="middle"
      text-anchor="middle"
      font-size="14px"
      font-weight="600"
      transform="rotate(90)"
      font-family="SoraRegular">
      {{ percentage(sum1, sum2) }}
    </text>
    <text
      v-if="subtitle !== ''"
      x="0"
      y="-46"
      dominant-baseline="middle"
      text-anchor="middle"
      font-size="4px"
      font-weight="400"
      transform="rotate(90)"
      font-family="SoraRegular">
      {{ subtitle }}
    </text>

    <path
      :d="'M 85,0 a30,30 0 1,1 0,-0.1'"
      :style="
        'fill:none; stroke:' +
        colors[0] +
        '; stroke-width:11.8; stroke-dasharray: 2 2'
      " />
    <path
      :d="'M 85,0 a30,30 0 ' + arcPosition(percentage1(sum1, sum2)[0], 30)"
      :style="'fill:none; stroke:' + colors[1] + '; stroke-width:12'" />
    <path
      :d="'M 88,0 a33,33 0 ' + arcPosition(percentage1(sum1, sum2)[1], 33)"
      :style="'fill:none; stroke:' + colors[1] + '; stroke-width:12.4'" />
  </svg>
</template>
<script>
export default {
  props: {
    sum1: {
      type: Number,
      default: 0.0,
    },
    sum2: {
      type: Number,
      default: 0.0,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Array,
      default: () => ['#C1C7C9', '#FBEBDD'],
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  computed: {},
  methods: {
    percentage(a, b) {
      let d = a / b;
      let percentage = Math.round(100 * d);
      if (percentage > 100) {
        percentage = 100;
      }
      return percentage + '%';
    },

    percentage1(a, b) {
      let d = a / b;
      if (d < 1) {
        return [Math.max(0, 100 * d), 0];
      }
      return [99.99, 100 * d - 100];
    },
    arcPosition(p, q) {
      if (p > 100 || p < 0) {
        return '';
      }
      let a = 1;
      let b = 1;
      let c = -0.1;
      let d = 0;

      let r = (p / 100) * 2 * Math.PI;
      c = q * Math.cos(r) - q;
      d = q * Math.sin(r);
      if (p === '100' || p === '-100') {
        d = 0.001;
      }
      if (p < 50 && p > -50) {
        a = 0;
      }
      if (p < 0) {
        b = 0;
      }
      return `${a},${b} ${c},${d}`;
    },
  },
};
</script>
