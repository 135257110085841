<template>
  <div class="ontag">
    <div class="ontag__content">
      <router-link
to="/ontag/orderscorecard/settings">&laquo; {{ $t('back') }}</router-link>
      <h1>Redigera leveransvecka</h1>
      <form
        class="flex"
        noValidate
        @submit.prevent="editDeliveryWeek">
        <h3>Vecka *</h3>
        <input
          type="number"
          v-model="deliveryweek.week"
          required="true" />
        <div>
          <input
            type="radio"
            name="year"
            :value="currentYear"
            v-model="deliveryweek.year" />
          {{ currentYear }}
        </div>
        <div>
          <input
            type="radio"
            name="year"
            :value="nextYear"
            v-model="deliveryweek.year" />
          {{ nextYear }}
        </div>
        <label for="year">Deadline</label>
        <input
          name="deadline"
          class="input__input"
          type="date"
          :min="date"
          required="true"
          v-model="deadlineDate" />
        <label for="year">Deadline för ändring</label>
        <input
          name="deadlineForChange"
          class="input__input"
          type="date"
          :min="date"
          required="true"
          v-model="deadlineDateForChange" />
        <label for="scorecard-forecast">Prognos antal scorekort</label>
        <input
          name="scorecard-forecast"
          type="number"
          step="1"
          pattern="^[-/d]/d*$"
          v-model="deliveryweek.scorecardForecast"
          required="false" />
        <div>
          <label
for="transaction-purchase">Transaktionsmånad inköp/frakt:</label>
          <div>
            <input
              type="radio"
              name="transaction-year-purchase-1"
              :value="currentYear"
              v-model="deliveryweek.purchaseYear" />
            {{ currentYear }}
          </div>
          <div>
            <input
              type="radio"
              name="transaction-year-purchase-2"
              :value="nextYear"
              v-model="deliveryweek.purchaseYear" />
            {{ nextYear }}
          </div>
          <select
            name="transaction-purchase"
            v-model="deliveryweek.purchaseMonth">
            <option value="1">Januari</option>
            <option value="2">Februari</option>
            <option value="3">Mars</option>
            <option value="4">April</option>
            <option value="5">Maj</option>
            <option value="6">Juni</option>
            <option value="7">Juli</option>
            <option value="8">Augusti</option>
            <option value="9">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        <div>
          <label
for="transaction-cost">Transaktionsmånad fakturering/intäkt:</label>
          <div>
            <input
              type="radio"
              name="transaction-year-revenue-1"
              :value="currentYear"
              v-model="deliveryweek.revenueYear" />
            {{ currentYear }}
          </div>
          <div>
            <input
              type="radio"
              name="transaction-year-revenue-2"
              :value="nextYear"
              v-model="deliveryweek.revenueYear" />
            {{ nextYear }}
          </div>
          <select
            name="transaction-cost"
            v-model="deliveryweek.revenueMonth">
            <option value="">--Välj månad--</option>
            <option value="1">Januari</option>
            <option value="2">Februari</option>
            <option value="3">Mars</option>
            <option value="4">April</option>
            <option value="5">Maj</option>
            <option value="6">Juni</option>
            <option value="7">Juli</option>
            <option value="8">Augusti</option>
            <option value="9">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        <div class="input-group--inline user__actions">
          <router-link to="/ontag/orderscorecard/settings">
            <base-button :text="$t('cancel')"> </base-button>
          </router-link>
          <base-button
            :text="$t('save')"
            type="submit"
            v-element-loader="{ loaderKey: 'create-deliveryweek' }"
            :disabled="!canCreate" />
          <router-link to="/ontag/orderscorecard/settings">
            <base-button
              :text="$t('delete')"
              @click="deleteDeliveryweek"
              class="button--background-red" />
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import { formatDateForInputs } from '@/core/helpers.js';

export default {
  name: 'EditDeliveryWeek',
  components: {
    BaseButton,
  },

  directives: {
    elementLoader,
  },

  props: {
    id: {
      type: String,
      default: '0',
    },
  },

  data() {
    return {
      date: new Date().toISOString().slice(0, 10),
      currentYear: new Date().getFullYear(),
      nextYear: new Date().getFullYear() + 1,
      purchaseMonth: 0,
      revenueMonth: 0,
      purchaseYear: 0,
      scorecardForecast: 0,
      revenueYear: 0,
      deadlineDate: undefined,
      deadlineDateForChange: undefined,
    };
  },
  methods: {
    editDeliveryWeek() {
      this.$store
        .dispatch('scorecardOrder/patchDeliveryWeek', {
          id: this.deliveryweek.id,
          week: this.deliveryweek.week,
          year: this.deliveryweek.year,
          deadline: this.deadlineDate,
          deadlineForChange: this.deadlineDateForChange,
          purchaseMonth: this.deliveryweek.purchaseMonth,
          revenueMonth: this.deliveryweek.revenueMonth,
          purchaseYear: this.deliveryweek.purchaseYear,
          revenueYear: this.deliveryweek.revenueYear,
          scorecardForecast: this.deliveryweek.scorecardForecast,
        })
        .then(() => {
          this.$router.push({ path: '/ontag/orderscorecard/settings' });
        });
    },
    canDelete() {
      if (this.deliveryweek.hasOrders) {
        this.$toasted.error(
          'Deliveryweeks with active orders can not be deleted'
        );
        return false;
      } else {
        return true;
      }
    },
    deleteDeliveryweek(id) {
      if (this.canDelete()) {
        if (confirm('Vill du ta bort denna leveransvecka?')) {
          this.$store
            .dispatch('scorecardOrder/deleteDeliveryWeek', { id: this.id })
            .then(() => {
              this.$store.dispatch(
                'scorecardOrder/fetchScorecardOrderSettings'
              );
            });
        }
      }
    },
    formattedDeadlineDate() {
      const dateObject = new Date(this.deliveryweek.deadline);
      const formattedDate = dateObject.toLocaleDateString().split('T')[0];
      this.deadlineDate = formattedDate;
    },
    formattedDeadlineDateForChange() {
      const dateObject = new Date(this.deliveryweek.deadlineForChange);
      const formattedDate = dateObject.toLocaleDateString().split('T')[0];
      this.deadlineDateForChange = formattedDate;
    },
  },

  computed: {
    deliveryweek() {
      return this.$store.getters['scorecardOrder/deliveryweek'];
    },
    deadline: {
      get: function () {
        return formatDateForInputs(this.deliveryweek.deadline);
      },
      set: function (newValue) {
        this.deliveryweek.deadline = newValue;
        return this.deliveryweek.deadline;
      },
    },
    canCreate() {
      if (this.deliveryweek.week > 0 && this.deliveryweek.week < 53) {
        return (
          this.deliveryweek.week &&
          this.deliveryweek.year &&
          this.deliveryweek.deadline
        );
      } else {
        return false;
      }
    },
  },
  created() {
    this.$store
      .dispatch('scorecardOrder/fetchDeliveryWeek', { id: this.id })
      .then(() => {
        this.formattedDeadlineDate();
        this.formattedDeadlineDateForChange();
      });
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 10px;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  margin-bottom: 15px;
}

.flex > label,
input,
select {
  margin-bottom: 0.4rem;
  margin-top: 0.2rem;
}
</style>
