<template>
  <div class="businessboard">
    <span
      v-if="isLoading"
      class="element--is-loading">
    </span>
    <div class="reports-header">
      <h1>{{ $t('reportTool') }}</h1>
      <p>{{ $t('hereAreAllReportTemplates') }}</p>
    </div>
    <last-updated
      :last-update="latestUpdates"
      :show-member="!hasEconomicLicense"
      :show-game="!hasEconomicLicense"
      :show-economy="true"
      :show-warning="haveNonConnectedAccounts" />
    <div class="reports-buttons">
      <global-template-settings
        @saveTemplateFilter="saveTemplateFilter"
        :showHeaders="settings.showHeaders"
        :fileFormat="settings.fileFormat" />
      <base-button
        @click="openNewTemplateCreation()"
        backgroundColor="white"
        class="button-white-hover"
        :text="$t('createNewTemplate')">
        <use-svg svg="black-plus-icon" />
      </base-button>
    </div>
    <h2 class="template-text">{{ $t('templates') }}</h2>
    <div
      class="create-reports-template"
      @dblclick="toggleHide()"
      v-if="showTemplateCreation">
      <div class="businessboard--template">
        <div class="businessboard-template--title">
          <div class="template-head">
            <div class="template-head-left">
              <input
                class="input--template-title"
                :placeholder="templateName ? templateName : $t('newTemplate')"
                v-model="templateName"
                type="text"
                ref="templateName" />
              <base-button
                @click="createNewTemplate()"
                :text="$t('save')"
                :class="templateName ? 'button-active' : 'button-inactive'"
                backgroundColor="white">
                <use-svg svg="black-plus-icon" />
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <reports-template
        v-for="mall in templates"
        :key="mall.externalId"
        :templateName="mall.name"
        :templateId="mall.externalId"
        ref="refTemplates"
        :template="mall" />
    </div>

    <div
      v-if="showScrollToTop"
      class="scroll_to_top"
      @click="scrollToTop">
      <img
        src="@/components/svg/bb-scroll-top-arrow.svg"
        class=""
        alt="" />
    </div>
  </div>
</template>

<script>
import { MonthsLabelsShort, BusinessBoardVersion } from '@/core/consts';

import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import ReportsTemplate from '@/components/reports-templates/reports-template.vue';
import GlobalTemplateSettings from '@/components/reports-templates/settings-modal/templates-settings';
import localizationMixin from '@/mixins/localization-mixin.vue';
import LastUpdated from '@/components/last-updated/last-updated.vue';

export default {
  name: 'BusinessboardReports',
  mixins: [localizationMixin],
  components: {
    BaseButton,
    UseSvg,
    GlobalTemplateSettings,
    ReportsTemplate,
    LastUpdated,
  },
  props: {},
  data() {
    return {
      showTemplateCreation: false,
      hideTemplate: false,
      templateName: '',
      showMembersSection: true,
      showGamesSection: false,
      showEconomySection: true,
      showScrollToTop: false,
      showLastUpdate: false,
      templateNameCreated: false,
      editId: '',
      showAllDiagrams: false,
    };
  },
  computed: {
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
    templates() {
      return this.$store.getters['businessboardReportingTool/templates'];
    },
    businessBoardSettings() {
      return this.$store.getters['businessboard/settings'];
    },
    hasEconomicLicense() {
      if (!this.businessBoardSettings) {
        return false;
      }
      return (
        this.businessBoardSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicBasic ||
        this.businessBoardSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicPlus
      );
    },
    showDiagramHeader() {
      return this.$store.getters[
        'businessboardReportingTool/showDiagramHeader'
      ];
    },
    latestUpdates() {
      return this.$store.getters['businessboard/lastUpdate'];
    },
    haveNonConnectedAccounts() {
      return this.$store.getters['businessboard/havenonconnectedaccounts'];
    },
  },
  methods: {
    ChangeDiagramView(isShowAllDiagrams) {
      this.showAllDiagrams = isShowAllDiagrams;
    },
    saveTemplateFilter(settings) {
      this.$store.dispatch('businessboardReportingTool/updateSettings', {
        showHeaders: settings.showHeaders,
        fileFormat: settings.fileFormat.toString(),
      });
    },
    openNewTemplateCreation() {
      this.showTemplateCreation = true;
      this.templateName = '';
      this.editId = '';
      this.$nextTick(() => {
        this.$refs.templateName.focus();
      });
    },
    createNewTemplate() {
      if (this.editId.length === 0) {
        if (this.templateName.length > 1) {
          this.$store
            .dispatch('businessboardReportingTool/createTemplate', {
              name: this.templateName,
            })
            .then(res => {
              if (res.externalId.length > 0) {
                this.editId = res.externalId;
                this.showTemplateCreation = false;
                this.$refs.refTemplates[
                  this.$refs.refTemplates.length - 1
                ].scrollToAndOpen();
              }
            });
        }
      } else {
        this.editTemplateName();
      }
    },

    deleteTemplate() {
      this.$store.dispatch('businessboardReportingTool/deleteTemplate', {
        id: this.editId,
      });
    },
    editFocusOnTemplateName() {
      this.$nextTick(() => {
        this.$refs.templateName.focus();
      });
    },
    editTemplateName() {
      this.$store.dispatch('businessboardReportingTool/updateTemplate', {
        name: this.templateName,
        id: this.editId,
      });
    },
    hideTemplateCreation() {
      this.showTemplateCreation = false;
    },
    toggleHide() {
      this.showTemplateCreation = false;
    },
    timeFormat(d) {
      if (!d) {
        return;
      }

      d = new Date(d);

      return `${d.getDate()} ${this.$t(
        `MonthsShort.${MonthsLabelsShort[d.getMonth()]}`
      ).toLowerCase()} ${d.getFullYear()}, ${('0' + d.getHours()).slice(-2)}:${(
        '0' + d.getMinutes()
      ).slice(-2)}`;
    },
    scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.showScrollToTop = true;
      } else {
        this.showScrollToTop = false;
      }
    },
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollFunction);
    this.$store.dispatch('businessboardReportingTool/fetchSettings');
    this.$store.dispatch('businessboardReportingTool/fetchTemplates');
    this.$store.dispatch('businessboard/fetchSettings');
    this.$store.dispatch('businessboard/fetchLastUpdate');
    this.$store.dispatch('businessboard/fetchHaveNonConnectedAccounts');
  },
  mounted() {},
  unmounted() {
    window.removeEventListener('scroll', this.scrollFunction);
  },
};
</script>

<style lang="scss" scoped>
@import '../../components/reports-templates/scss/shared.scss';

.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}

.businessboard {
  flex-grow: 1;
  width: 100%;

  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }

  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}

.create-reports-template {
  width: 100%;
}
.template-text {
  margin-left: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.reports-header {
  margin-left: 2rem;

  h1 {
    font-weight: 600;
  }
}

.reports-buttons {
  margin-bottom: 7rem;
  margin-left: 2rem;
  display: flex;
  gap: 2rem;
}

.title--button {
  background-color: transparent;
  border: none;
  color: black;
}

.businessboard--template {
  background-color: white;
  padding: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.businessboard-template--title {
  background-color: #fff;
  color: #000;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  margin-top: 20px;
  padding: 2rem;

  .template-head {
    width: 100%;
  }

  .template-head-left {
    width: 40%;
    display: flex;
  }

  .template-head-right {
    float: right;
    gap: 2rem;
    display: flex;
    margin-top: -3rem;
  }

  .input--template-title {
    color: #000;
    border: none;
    border: 0.1rem solid white;
    background-color: transparent;
    width: 80%;
    font-size: medium;
    font-weight: 600;
    margin-right: 0.5rem;
    &:hover {
      border: #000 solid 2px;
    }
  }

  input::placeholder {
    font-size: medium;
    color: gray;
    font-weight: 500;
  }
}

.businessboard--template {
  margin: 1rem 2rem;
  width: 100%;

  p {
    font-weight: 600;
  }

  .businessboard---reports-container {
    display: flex;
    margin-bottom: 30px;
  }
}
</style>
