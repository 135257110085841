<template>
  <div class="period_button">
    <modal-window :show="showModal">
      <div class="modal__header">
        <p>{{ $t('show') }}</p>
      </div>
      <div class="">
        <div class="radio__row">
          <input
            class="radio__input"
            id="defaultFilter"
            name="filter"
            type="radio"
            :checked="defaultFilter" />
          <label
            for="defaultFilter"
            class="radio__label">
            {{ $t('Businessboard.defaultFilter') }}</label>
        </div>
        <div class="radio__row">
          <input
            class="radio__input"
            id="periodFilter"
            name="filter"
            type="radio"
            :checked="periodFilter" />
          <label
            for="periodFilter"
            class="radio__label">
            {{ $t('Businessboard.periodFilter') }}</label>
          <div class="period_grid">
            <div class="period_input">{{ $t('year') }}</div>
            <div class="period_input">{{ $t('Businessboard.yearStart') }}</div>
            <div class="period_input">{{ $t('Businessboard.yearEnd') }}</div>
            <div class="period_input input">
              <input
                class="input__input input__inline"
                type="number"
                v-model="periodYear" />
            </div>
            <div class="period_input">
              <custom-select
                @optionSelected="selectStartMonth"
                :selected="periodStart - 1"
                :options="settingsmonths">
              </custom-select>
            </div>
            <div class="period_input">
              <custom-select
                @optionSelected="selectEndMonth"
                :selected="periodEnd - 1"
                :options="settingsmonths">
              </custom-select>
            </div>
          </div>
        </div>
        <div class="radio__row">
          <input
            class="radio__input"
            id="financialYearFilter"
            name="filter"
            type="radio"
            :checked="financialYearFilter" />
          <label
            for="financialYearFilter"
            class="radio__label">
            {{ $t('Businessboard.currentFinancialYearFilter') }}</label>
        </div>
      </div>
      <div class="modal__content--actions">
        <base-button
          class="button--background-gray-dark"
          :text="$t('cancel')"
          @click="toggleModal" />
        <base-button
          :text="$t('save')"
          :disabled="!canSave"
          @click="changePeriod" />
      </div>
    </modal-window>
    <base-button
      backgroundColor="bb-primary"
      :text="$t('show')"
      @click="toggleModal">
    </base-button>
  </div>
</template>

<script>
import ModalWindow from '@/components/modal/modal';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'Modal',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    ModalWindow,
    CustomSelect,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      showModal: false,
      defaultFilter: true,
      financialYearFilter: false,
      periodFilter: false,
      periodStart: 1,
      periodEnd: 12,
      periodYear: new Date().getFullYear(),
    };
  },
  computed: {
    settingsmonths() {
      return this.$store.getters['businessboard/settingsmonths'];
    },
    canSave() {
      return true;
    },
  },
  mounted() {
    this.$store.dispatch('businessboard/fetchSettingsMonths');
    let filter = this.$store.state.businessboard.periodFilter;
    if (Object.keys(filter).length !== 0) {
      this.defaultFilter = filter['Filter.DefaultFilter'];
      this.financialYearFilter = filter['Filter.FinancialYearFilter'];
      this.periodFilter = filter['Filter.PeriodFilter'];
      this.periodStart = filter['Filter.FromMonth'];
      this.periodEnd = filter['Filter.ToMonth'];
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    selectStartMonth(e) {
      this.periodStart = e.value;
    },
    selectEndMonth(e) {
      this.periodEnd = e.value;
    },
    changePeriod() {
      this.defaultFilter = document.getElementById('defaultFilter').checked;
      this.financialYearFilter = document.getElementById(
        'financialYearFilter'
      ).checked;
      this.periodFilter = document.getElementById('periodFilter').checked;
      this.toggleModal();
      this.$emit('changeFilter', {
        'Filter.DefaultFilter': this.defaultFilter,
        'Filter.FinancialYearFilter': this.financialYearFilter,
        'Filter.PeriodFilter': this.periodFilter,
        'Filter.FromMonth': this.periodStart,
        'Filter.ToMonth': this.periodEnd,
        'Filter.year': this.periodYear,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.period_grid {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  justify-content: center;
  height: 4rem;
}
.period_input {
  padding: 0 0.5rem 0 0.5rem;
}
.period_button {
  margin: 0 0 1rem auto;
  display: flex;
  justify-content: flex-end;
}
.radio__row {
  padding: 0.5rem;
  margin: 0.5rem auto 0.5rem auto;
  width: 50%;
  text-align: left;
}
</style>
