<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <span class="h1">OnTag Dashboard</span>
        <span v-if="isLoading" class="element--is-loading"></span>
      </div>
      <section class="wrapper">
        <div class="wrapper--container">
            <div v-if="user.superUser">
              <h2>Statistik för scorekort</h2>
              <div class="flex">
                <div class="bar" v-if="digitalPrintData">
                  <span class="title">Digitala scorekort</span>
                  <bar-chart
                  :styles="{height: '16rem'}"
                  :labels="['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']"
                  :data='digitalPrintData.data'
                  :tooltips='digitalPrintData.tooltips'/>
                </div>
                <div class="totalCount">
                  <h2>Totalt antal digitala scorekort</h2>
                  <div>
                    <p>
                      <span>
                        <span>innevarande år:</span>
                        <span>{{digitalPrintTotalCurrentY}} st</span>
                      </span>
                    </p>
                    <p>
                      <span>
                        <span>föregående år: </span>
                        <span>{{digitalPrintTotalLastY}} st</span>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="bar" v-if="paperPrintData && (paperPrintTotalCurrentY > 0 || paperPrintTotalLastY > 0)">
                  <span class="title">Utskrivna scorekort</span>
                  <bar-chart
                  :styles="{height: '16rem'}"
                  :labels="['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']"
                  :data='paperPrintData.data'
                  :tooltips='paperPrintData.tooltips'/>
                </div>
                <div class="totalCount" v-if="paperPrintData && (paperPrintTotalCurrentY > 0 || paperPrintTotalLastY > 0)">
                  <h2>Totalt antal utskrivna scorekort</h2>
                  <div>
                    <p>
                      <span>
                        <span>innevarande år:</span>
                        <span>{{paperPrintTotalCurrentY}} st</span>
                      </span>
                    </p>
                    <p>
                      <span>
                        <span>föregående år:</span>
                        <span>{{paperPrintTotalLastY}} st</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <modal-window :show="showDeleteMarkedPrinterJobsModal">
              <div class="modal__header">
                {{ $t('deleteMarkedPrinterJobs') }}
              </div>
              <div class="modal__content">
                <div>
                  <h2>{{ $t('deleteMarkedPrinterJobsQuestion') }}?</h2>
                </div>
              </div>
              <div class="modal__content--actions">
                <base-button
                  class="button--background-gray-dark"
                  :text="$t('cancel')"
                  @click="toggleDeleteMarkedPrinterJobsModal">
                </base-button>
                <base-button
                  :text="$t('deleteMarkedPrinterJobs')"
                  @click="deleteMarkedPrinterJobs"/>
              </div>
            </modal-window>
            <modal-window :show="showEmptyPrinterQueueModal">
              <div class="modal__header">
                {{ $t('emptyPrinterQueue') }}
              </div>
              <div class="modal__content">
                <div>
                  <h2>{{ $t('emptyPrinterQueueQuestion') }}?</h2>
                </div>
              </div>
              <div class="modal__content--actions">
                <base-button
                  class="button--background-gray-dark"
                  :text="$t('cancel')"
                  @click="toggleEmptyPrinterQueueModal">
                </base-button>
                <base-button
                  :text="$t('emptyPrinterQueue')"
                  @click="emptyPrinterQueue"/>
              </div>
            </modal-window>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import ModalWindow from '@/components/modal/modal';
import BarChart from '@/components/charts/ontag-bar-chart';

export default {
  name: 'Ontag',
  components: {
    BaseButton,
    ModalWindow,
    BarChart,
  },
  data () {
    return {
      isLoading: true,
      searchQuery: '',
      currentPage: 0,
      active: undefined,
      showDeleteMarkedPrinterJobsModal: false,
      showEmptyPrinterQueueModal: false,
      allSelected: [],
      selectedPrinterIndex: undefined,
      sort: {},
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
      paperPrintData: false,
      paperPrintTotalCurrentY: false,
      paperPrintTotalLastY: false,
      digitalPrintData: false,
      digitalPrintTotalCurrentY: false,
      digitalPrintTotalLastY: false,
    };
  },
  computed: {
    printersOptions () {
      return this.$store.getters['printers/printersOptions'];
    },
    printerQueue () {
      return this.$store.getters['printerQueue/printerQueue'];
    },
    pagination () {
      return this.$store.getters['printerQueue/pagination'];
    },
    statistics () {
      return this.$store.getters['ontagStatistics/statistics'];
    },
    user () {
      return this.$store.getters['user/user'];
    },
  },
  created () {
    this.$store.dispatch('ontagStatistics/fetchStatistics').then(() => {
      this.parse(this.$store.state.ontagStatistics.statistics);
    });
    this.$store.dispatch('printers/fetchPrintersOptions').then(() => {
      this.$store.dispatch('printers/fetchAllPrinters').then(() => {
        this.fetchPrinterQueue(this.$store.getters['printers/printersOptionsById']().value);
        this.selectedPrinterIndex = this.printersOptions.findIndex(options => options.value === this.$store.getters['printers/printersOptionsById']().value);
      });
    }).finally(() => {
      this.isLoading = false;
    });
  },
  methods: {
    fetchPrinterQueue (id) {
      this.$store.dispatch('printerQueue/fetchPrinterQueue', {
        id: id,
        pageSize: this.pagination.pageSize,
        page: 0,
      });
    },
    getVariatedPrinterQueueResults (value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length < value.filter.options.length && checked.length > 0) {
          if (checked[0].value === '1') {
            this.active = true;
          } else {
            this.active = false;
          }
        } else {
          this.active = undefined;
        }
      }

      if (value.pageSize) this.pagination.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('printerQueue/fetchPrinterQueue', {
        id: this.printersOptions[this.selectedPrinterIndex].value,
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.status': this.active,
        pageSize: this.pagination.pageSize,
        page: this.searchQuery.length > 0 ? '1' : this.currentPage,
      });
    },
    deleteMarkedPrinterJobs() {
      this.$store.dispatch('printerQueue/deleteMarkedPrinterQueues', {
        ids: this.allSelected,
      }).then(() => {
        this.toggleDeleteMarkedPrinterJobsModal();
        this.allSelected = [];
        this.currentPage = 1;
        if (this.$refs && this.$refs.printerQueueListTable) this.$refs.printerQueueListTable.resetSelectAll();
        this.fetchPrinterQueue(this.printersOptions[this.selectedPrinterIndex].value);
      });
    },
    emptyPrinterQueue() {
      this.$store.dispatch('printerQueue/deletePrinterQueue', {
        id: this.$route.params.id,
      }).then(() => {
        this.toggleEmptyPrinterQueueModal();
      });
    },
    toggleDeleteMarkedPrinterJobsModal () {
      this.showDeleteMarkedPrinterJobsModal = !this.showDeleteMarkedPrinterJobsModal;
    },
    toggleEmptyPrinterQueueModal () {
      this.showEmptyPrinterQueueModal = !this.showEmptyPrinterQueueModal;
    },
    getPrinterQueuesSelected(value) {
      this.allSelected = value;
    },
    setSelectedPrinterIndex (printer) {
      this.selectedPrinterIndex = this.printersOptions.findIndex(options => options.value === printer.value);
      this.fetchPrinterQueue(printer.value);
    },
    getPrevPage () {
      return this.$store.getters['users/previousPage'];
    },
    getNextPage () {
      return this.$store.getters['users/nextPage'];
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedPrinterQueueResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(o => parseInt(o.value) === size);
      this.selectedIndexPageSize = selectedSize;
    },
    parse2(fields, data) {
      var d = {};
      var t = {};
      d.currentYear = fields.map(function (y) {
        var v = data.currentYearData.find(x => x.name === y);
        return v ? v.value : 0;
      });
      t.currentYear = fields.map(function (y) {
        var v = data.currentYearData.find(x => x.name === y);
        return v ? v.displayValue : '';
      });
      if (data.lastYearData) {
        d.lastYear = fields.map(function (y) {
          var v = data.lastYearData.find(x => x.name === y);
          return v ? v.value : 0;
        });
        t.lastYear = fields.map(function (y) {
          var v = data.lastYearData.find(x => x.name === y);
          return v ? v.displayValue : '';
        });
      }
      return { data: d, tooltips: t };
    },
    parse(statistics) {
      this.paperPrintData = this.parse2(
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], statistics.paperPrintData);
      this.paperPrintTotalCurrentY = this.statistics.paperPrintTotalData.currentYearData[0].value;
      this.paperPrintTotalLastY = this.statistics.paperPrintTotalData.lastYearData[0].value;
      this.digitalPrintData = this.parse2(
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], statistics.digitalPrintData);
      this.digitalPrintTotalCurrentY = this.statistics.digitalPrintTotalData.currentYearData[0].value;
      this.digitalPrintTotalLastY = this.statistics.digitalPrintTotalData.lastYearData[0].value;
    },
  },
};
</script>
<style lang="scss" scoped>
.h1 {
    display: inline-block;
    font-size: font(h1);
    font-weight: bold;
    vertical-align: top;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .element--is-loading {
    margin-left: .5rem;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 20px;
  }
.ontag {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.ontag__content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  &.dashboard {
    background-color: transparent;
    border-radius: 0;
  }
}
.wrapper {
  display: flex;
  width: 100%;
}
.wrapper--container {
  background-color: color(white);
  box-shadow: 0.125rem 0.125rem 0.4375rem 0.125rem rgba(0, 0, 0, 0.1);
  padding: 0 0.75rem;
  width: 100%;
}
.flex {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
}
.bar {
    width: 50%;
    height: 19rem;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 5px 10px 8px #ccc;
    margin: 10px;
    padding: 1rem;
  }

.totalCount {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
  height: 19rem;
  text-align: center;
}

.totalCount > div {

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 24px;

  // :first-child {
  //   span:first-child {
  //     color: color(blue-dark)
  //   }
  // }

  :nth-child(2) {
    span:nth-child(2) {
      color: color(blue) !important;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    :first-child {
        :nth-child(2) {
          color: color(blue-dark)
      }
    }
    :nth-child(2) {
      font-weight: bolder;
    }

    span {
    display: flex;
    flex-direction: column;
    text-align: center;
     :first-child {
      margin-bottom: 20px;
     }
    }
  }
}

  .title {
    font-size: 1.1rem;
  }
</style>
