import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  templates: [],
  lastUpdate: [],
  isLoading: false,
  settings: {
    showHeaders: false,
    fileFormat: '1',
  },
};

const mutations = {
  templates(state, payload) {
    state.templates = payload;
  },
  diagram(state, payload) {
    const templateIndex = state.templates.findIndex(
      c => c.externalId === payload.externalTemplateId
    );
    const diagramIndex = state.templates[templateIndex].diagrams.findIndex(
      c => c.typeOfDiagram === payload.typeOfDiagram
    );
    if (diagramIndex === -1) {
      state.templates[templateIndex].diagrams.push(payload);
    }
    Vue.set(state.templates[templateIndex].diagrams, diagramIndex, payload);
  },
  diagramIncludedInTemplate(state, payload) {
    const templateIndex = state.templates.findIndex(
      c => c.externalId === payload.externalTemplateId
    );
    const diagramIndex = state.templates[templateIndex].diagrams.findIndex(
      c => c.typeOfDiagram === payload.typeOfDiagram
    );
    Vue.set(
      state.templates[templateIndex].diagrams[diagramIndex],
      'includedInTemplate',
      payload.includedInTemplate
    );
  },
  isLoading(state, payload) {
    state.isLoading = payload;
  },
  newTemplate(state, payload) {
    state.templates.push(payload);
  },
  deleteTemplate(state, payload) {
    const templateIndex = state.templates.findIndex(
      c => c.externalId === payload.id
    );
    state.templates.splice(templateIndex, 1);
  },
  editTemplate(state, payload) {
    const templateIndex = state.templates.findIndex(
      c => c.externalId === payload.externalId
    );
    state.templates.splice(templateIndex, 1, payload);
  },
  lastUpdate(state, payload) {
    state.lastUpdate = payload;
  },
  settings(state, payload) {
    state.settings = payload;
  },
};

const getters = {
  templates: state => {
    return state.templates;
  },
  isLoading: state => {
    return state.isLoading;
  },
  lastUpdate: state => {
    return state.lastUpdate;
  },
  pictureFormat: state => state.fileFormat,
  settings: state => state.settings,
};

const actions = {
  fetchSettings: async context => {
    const path = 'businessboard/reportingtool/settings';
    context.commit('isLoading', true);
    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: `Fel vid hämtning av inställningar: ${
          error.response.data.message || 'Felmeddelande saknas'
        }`,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('settings', data);
    }
  },
  updateSettings: async (context, params) => {
    const path = 'businessboard/reportingtool/settings';
    context.commit('isLoading', true);
    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: `Fel vid sparning av inställningar: ${
          error.response.data.message || 'Felmeddelande saknas'
        }`,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('settings', data);
    }
    context.commit('isLoading', false);
  },
  fetchTemplates: async context => {
    const path = 'businessboard/reportingtool/templates';
    context.commit('isLoading', true);
    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('templates', data.templates);
    }
    context.commit('isLoading', false);
  },
  createTemplate: async (context, params) => {
    const path = 'businessboard/reportingtool/template';
    context.commit('isLoading', true);
    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.status === 200) {
      EventBus.$emit('toast', {
        type: 'success',
        msg: response.data.name + ' har skapats',
      });
      context.commit('newTemplate', response.data);
      context.commit('isLoading', false);
      return Promise.resolve(response.data);
    }
    context.commit('isLoading', false);
  },
  updateTemplate: async (context, params) => {
    const path = 'businessboard/reportingtool/' + params.id;
    context.commit('isLoading', true);
    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.status === 200) {
      EventBus.$emit('toast', {
        type: 'success',
        msg: response.data.name + ' har ändrats',
      });
      context.commit('editTemplate', response.data);
    }
    context.commit('isLoading', false);
  },
  deleteTemplate: async (context, params) => {
    const path = 'businessboard/reportingtool/' + params.id;
    context.commit('isLoading', true);
    let response = {};
    try {
      response = await Vue.axios.delete(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.status === 200) {
      // success, fetch templates again?
      EventBus.$emit('toast', {
        type: 'success',
        msg: 'Mallen har tagits bort',
      });
      context.commit('deleteTemplate', params);
    }
    context.commit('isLoading', false);
  },
  postDiagram: async (context, params) => {
    const path =
      'businessboard/reportingtool/' +
      params.templateId +
      '/' +
      params.diagramType;
    context.commit('isLoading', true);
    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: `Fel vid sparning: ${
          error.response.data.message || 'Felmeddelande saknas'
        }`,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('diagram', data.diagram);
    }
    context.commit('isLoading', false);
  },

  includeDiagram: async (context, params) => {
    const path =
      'businessboard/reportingtool/includediagram/' +
      params.templateId +
      '/' +
      params.diagramType;
    context.commit('isLoading', true);
    let response = {};
    try {
      response = await Vue.axios.patch(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: 'Ett fel uppstod i inkluderingen av diagrammet',
        request: path,
      });
    }
    if (response && response.status === 200) {
      if (!response.data.includedInTemplate) {
        EventBus.$emit('toast', {
          type: 'success',
          msg: 'Diagrammet har exkluderats. Du hittar det under alla tillgängliga diagram',
          request: path,
        });
      }
      response.data.externalTemplateId = params.templateId;
      context.commit('diagramIncludedInTemplate', response.data);
    }
    context.commit('isLoading', false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
