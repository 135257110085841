<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <h1>{{ $t('Businessboard.addCompany') }}</h1>
      {{ $t('Company.inputInfoBookingSystem') }}
      <form @submit.prevent="createCompany">
        <div class="content__input-group">
          <div class="input-group">
            <div class="input">
              <label
                for="companyName"
                class="input__label">{{ $t('Businessboard.companyName') }}*</label>
              <input
                id="companyName"
                class="input__input"
                v-model="companyName"
                type="text"
                required="true"
                name="companyName" />
            </div>
            <div class="input">
              <label
                for="orgNumber"
                class="input__label">{{ $t('Businessboard.orgNumber') }}*</label>
              <input
                v-if="!businessBoardSettings.hasGolfBox"
                pattern="[0-9]{6}-\d{4}"
                title="Org Nr. XXXXXX-XXXX"
                id="orgNumber"
                class="input__input"
                v-model="orgNumber"
                type="text"
                required="true"
                placeholder="123456-1234"
                name="orgNumber" />

              <input
                v-if="businessBoardSettings.hasGolfBox"
                title="Org Nbr."
                id="orgNumber"
                class="input__input"
                v-model="orgNumber"
                type="text"
                required="true"
                name="orgNumber" />
            </div>
          </div>
          <div class="input-group--inline user__actions">
            <router-link to="/businessboard/data">
              <base-button :text="$t('cancel')"> </base-button>
            </router-link>
            <base-button
              :text="$t('Businessboard.addCompany')"
              type="submit"
              v-element-loader="{ loaderKey: 'create-company' }"
              :disabled="!canCreate" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'CreateCourse',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  props: {},
  data() {
    return {
      companyName: '',
      orgNumber: '',
    };
  },
  methods: {
    createCompany() {
      this.$store
        .dispatch('businessboard/createCompany', {
          companyName: this.companyName,
          organizationNumber: this.orgNumber,
        })
        .then(() => {
          this.$router.push({ path: '/businessboard/data' });
          this.$toasted.success(this.$t('Businessboard.companyCreated'));
        });
    },
  },
  computed: {
    businessBoardSettings() {
      return this.$store.getters['businessboard/settings'];
    },
    canCreate() {
      return (
        (this.companyName &&
          this.orgNumber &&
          this.orgNumber.match(/^\d{6}-\d{4}$/)) ||
        this.businessBoardSettings.hasGolfBox
      );
    },
    title() {
      return this.businessBoardSettings.hasGolfBox
        ? 'Org Nbr.'
        : 'Org Nr. XXXXXX-XXXX';
    },
  },
  mounted() {
    this.$store.dispatch('businessboard/fetchSettings');
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.golfoffice {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.golfoffice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  .content__input-group {
    width: 100%;
    @include media('medium') {
      width: calc((100% / 1.2) - (1rem * 2));
    }

    .user__actions {
      justify-content: flex-start;
      @include media('small') {
        justify-content: flex-end;
        // width: 70%;
      }

      a button {
        margin-left: 0;
      }

      button {
        margin-left: 1.25rem;
      }
    }
  }
}
</style>
