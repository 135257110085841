<template>
  <div>
    <table class="list-table">
      <membership-calculation-table-header
        :headers="membershipTypes.feeColumns"
        :categories="membershipTypes.categories"
        :table-year="membershipYear.year"
        @show-table="e => showTable(e)" />
      <tbody>
        <template v-for="(type, index) in membershipYear.types">
          <content-row-membershiptype
            v-show="show"
            :key="'contentrow' + index"
            :type-name="type.displayName"
            :type-data="type"
            :category-reference="membershipTypes.categories"
            :age-group-reference="membershipTypes.ageGroups"
            :fee-reference="membershipTypes.feeColumns"
            :previous-year-data="findSameTypeFromPreviousYear(type)"
            :show-previous-year="showPreviousYear"
            @update-quantity="e => updateQuantityMembershipType(e)"
            @update-type-fee-amount="e => updateFeeAmount(e, type.id)" />

          <template v-for="(ageGroup, ageGroupIndex) in type.ageGroups">
            <content-row-agegroup
              v-show="show"
              :key="`contentrowagegroup_${type.displayName}_${ageGroupIndex}`"
              :type-data="type"
              :age-group="ageGroup"
              :category-reference="type.id"
              :age-group-reference="membershipTypes.ageGroups"
              :fee-reference="membershipTypes.feeColumns"
              :previous-year-data="
                findSameTypeFromPreviousYear(type)?.ageGroups.find(
                  g => g.ageGroupId === ageGroup.ageGroupId
                )
              "
              :show-previous-year="showPreviousYear"
              @update-ageGroup-quantity="e => updateQuantityAgeGroup(e)"
              @update-agegroup-fee-amount="
                e => updateFeeAmount(e, type.id, ageGroup.ageGroupId)
              " />
          </template>
        </template>

        <discount-row
          v-show="show"
          :year-data="membershipYear"
          :fee-reference="membershipTypes.feeColumns"
          :previous-year-data="previousYearData?.discountBudgeted"
          :discount-name="$t('MemberCalculation.discountBudget')"
          :discount-data="membershipYear.discountBudgeted"
          :show-previous-year="showPreviousYear"
          :budgeted="true"
          @update-discount="e => updateDiscountAmount(e)" />

        <discount-row
          v-show="show"
          :year-data="membershipYear"
          :fee-reference="membershipTypes.feeColumns"
          :previous-year-data="previousYearData?.discountActual"
          :discount-name="$t('MemberCalculation.discountOutcome')"
          :discount-data="membershipYear.discountActual"
          :show-previous-year="showPreviousYear"
          :budgeted="false"
          @update-discount="e => updateDiscountAmount(e)" />
      </tbody>
      <tfoot>
        <membership-calculation-table-footer
          v-show="show"
          :total-amount-current-year="calculateTotalBudget(membershipYear)"
          :total-amount-previous-year="calculateTotalBudget(previousYearData)"
          :fee-reference="membershipTypes.feeColumns"
          :show-previous-year="showPreviousYear" />
      </tfoot>
    </table>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import ContentRowAgegroup from './table-content/content-row-agegroup';
import ContentRowMembershiptype from './table-content/content-row-membershiptype';
import DiscountRow from './table-content/discount-row';
import MembershipCalculationTableFooter from './table-footer/membership-calculation-table-footer';
import MembershipCalculationTableHeader from './table-header/membership-calculation-table-header';

export default {
  components: {
    ContentRowAgegroup,
    ContentRowMembershiptype,
    DiscountRow,
    MembershipCalculationTableFooter,
    MembershipCalculationTableHeader,
  },
  props: {
    membershipTypes: VueTypes.object.def({}),
    membershipYear: VueTypes.object.def({}),
    showPreviousYear: VueTypes.bool.def(false),
  },
  data() {
    return {
      show: false,
      previousYearData: null,
    };
  },

  computed: {
    getPreviousYearData() {
      const yearsCopy = [...this.membershipTypes.years];
      const allYearsSorted = yearsCopy.sort((a, b) => a.year - b.year);
      const findPreviousYear = allYearsSorted.find(
        year => year.year === this.membershipYear.year - 1
      );
      return findPreviousYear || null;
    },
  },

  mounted() {
    this.previousYearData = this.getPreviousYearData;
  },

  methods: {
    showTable(show) {
      this.show = show;
    },
    calculateTotalBudget(yearData) {
      if (yearData !== null) {
        let totalAmount = 0;
        for (const type of yearData.types) {
          totalAmount += type.quantityBudgeted;
        }
        return totalAmount;
      } else {
        return 0;
      }
    },
    findSameTypeFromPreviousYear(type) {
      if (type !== null && this.previousYearData !== null) {
        const previousYearType = this.previousYearData.types.find(
          previousYearType => previousYearType.displayName === type.displayName
        );
        if (previousYearType !== null) {
          return previousYearType;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    updateQuantityMembershipType(e) {
      this.updateQuantity('businessboard/patchMembershipType', {
        id: e.id,
        membershipCategoryId: e.categoryId,
        quantity: {
          year: this.membershipYear.year,
          quantity: e.quantity,
        },
        aliases: e.aliases,
        ageGroups: e.ageGroups,
      });
    },

    updateQuantityAgeGroup(e) {
      this.updateQuantity('businessboard/patchMembershipAgeGroup', {
        id: e.id,
        quantity: {
          quantity: e.quantity,
          year: this.membershipYear?.year,
        },
        fromAge: e.fromAge,
        toAge: e.toAge,
        membershipTypeId: e.categoryReference,
      });
    },

    updateQuantity(path, obj) {
      this.$store.dispatch(path, obj);
      // .then(() => this.$store.dispatch('businessboard/fetchMembershipTypes'));
    },

    updateFeeAmount(e, typeId, ageGroupId) {
      this.$store.dispatch('businessboard/patchMembershipFeeValue', {
        feeColumnReference: e.feeColumnReference,
        membershipTypeId: typeId,
        ageGroupId: ageGroupId,
        amount: e.amount,
        year: this.membershipYear.year,
      });
    },
    updateDiscountAmount(e) {
      const discountAmountObj = {
        feeColumnReference: e.feeColumnReference,
        amount: e.amount,
        budgeted: e.budgeted,
        year: this.membershipYear.year,
      };
      this.$store.dispatch('businessboard/patchMembershipDiscount', {
        ...discountAmountObj,
      });
    },
  },
  watch: {
    membershipYear() {
      this.previousYearData = this.getPreviousYearData;
    },
  },
};
</script>
<style lang="scss" scoped>
tbody {
  background-color: #f0f5f5;
}
.list-table {
  font-size: font(h4);
  border-collapse: collapse;
  margin-bottom: measure(spacing-m);
  width: 100%;
}
</style>
