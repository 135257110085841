<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <router-link to="/clubs/">&laquo; {{ $t('back') }}</router-link>
      </div>
      <div class="golfoffice--top">
        <h1 v-if="id">{{ $t('GolfOffice.editClub') }}</h1>
        <h1 v-else>
          {{ $t('GolfOffice.addClub') }}
        </h1>
      </div>
      <div class="club__information">
        <h2 class="title-variation">
          <use-svg svg="print-icon" />
          {{ $t('GolfOffice.customerInformation') }}
        </h2>
        <p>
          Här ser ni klubbens kontaktuppgifter samt om de är aktiva eller inte.
          Logotype kan laddas upp.
        </p>
        <div class="input-group club-switch">
          <label
            for="voucher"
            class="input__label">
            {{ $t('active') }}
          </label>
          <toggle-switch
            input-id="active"
            type="switch"
            :toggled="club.active"
            @toggle="toggleActivity" />
        </div>
        <div class="input">
          <label
            for="scorecard-name"
            class="input__label">
            {{ $t('clubName') }}
          </label>
          <input
            type="text"
            name="scorecard-name"
            id="scorecard-name"
            class="input__input"
            v-model="club.name" />
        </div>
        <div>
          <custom-select
            :required="false"
            :label="$t('region')"
            @optionSelected="setRegion"
            :selected="selectedRegionIndex"
            :options="regions" />
        </div>
        <div class="input">
          <label
            for="phone-number"
            class="input__label">
            {{ $t('phoneNumber') }}
          </label>
          <input
            type="text"
            name="phone-number"
            id="phone-number"
            class="input__input"
            v-model="club.phoneNumber" />
        </div>
        <div class="input">
          <label
            for="email"
            class="input__label">
            {{ $t('emailPrimary') }}
          </label>
          <input
            type="text"
            name="email"
            id="email"
            class="input__input"
            v-model="club.email" />
        </div>
        <div class="input">
          <label
            for="emailProductInfo"
            class="input__label">
            {{ $t('emailProductInfo') }}
          </label>
          <input
            type="text"
            name="emailProductInfo"
            id="emailProductInfo"
            class="input__input"
            v-model="club.emailProductInfo" />
        </div>
        <div class="input">
          <label
            for="homePageUrl"
            class="input__label">
            {{ $t('homePageUrl') }}
          </label>
          <input
            type="text"
            name="homePageUrl"
            id="homePageUrl"
            class="input__input"
            v-model="club.homePageUrl" />
        </div>
        <div class="input">
          <label
            for="information"
            class="input__label">
            {{ $t('information') }}
          </label>
          <input
            type="text"
            name="information"
            id="information"
            class="input__input"
            v-model="club.information" />
        </div>
        <div class="input">
          <custom-select
            :required="false"
            :label="$t('language')"
            @optionSelected="setLanguage"
            :selected="club.language"
            :options="languages" />
        </div>
        <div class="input">
          <custom-select
            :required="false"
            :label="$t('country')"
            @optionSelected="setCountry"
            :selected="club.country"
            :options="countries" />
        </div>
        <div>
          <template v-if="club && club.logoUrl && club.logoKey">
            <div class="club__logotype">
              <p>{{ $t('logotype') }}</p>
              <div class="club__layout--image image-1">
                <div
                  @mouseover="hoverOn('logotype')"
                  @mouseleave="hoverOn('')"
                  :class="['club__layout--image-overlay-wrapper']">
                  <template v-if="hover === 'logotype'">
                    <base-button
                      :text="$t('remove')"
                      class="button--has-icon"
                      :button-size-small="true"
                      @click="removeLogotype('logo')">
                      <use-svg svg="trash-icon" />
                    </base-button>
                    <input
                      hidden
                      type="file"
                      id="logotype"
                      name="logotype"
                      @change="e => addFile(e)" />
                    <base-button
                      @click="chooseFile('logotype')"
                      :text="$t('upload')"
                      class="button--has-icon"
                      :button-size-small="true">
                      <use-svg svg="export-icon" />
                    </base-button>
                  </template>
                </div>
                <img :src="club.logoUrl + club.logoKey" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="club__logotype">
              <p>{{ $t('logotype') }}</p>
              <div :class="'club__layout--image'">
                <div :class="['club__layout--image-overlay-wrapper']">
                  <input
                    hidden
                    type="file"
                    id="logotype"
                    name="logotype"
                    @change="e => addFile(e)" />
                  <base-button
                    @click="chooseFile('logotype')"
                    :text="$t('upload')"
                    class="button--has-icon"
                    :button-size-small="true">
                    <use-svg svg="export-icon" />
                  </base-button>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div>
          <template
            v-if="club && club.logoTransparentUrl && club.logoTransparentKey">
            <div class="club__logotype">
              <p>{{ $t('logotypeTransparent') }}</p>
              <div class="club__layout--image image-1">
                <div
                  @mouseover="hoverOn('logotypeTransparent')"
                  @mouseleave="hoverOn('')"
                  :class="['club__layout--image-overlay-wrapper']">
                  <template v-if="hover === 'logotypeTransparent'">
                    <base-button
                      :text="$t('remove')"
                      class="button--has-icon"
                      :button-size-small="true"
                      @click="removeLogotype('logoTransparent')">
                      <use-svg svg="trash-icon" />
                    </base-button>
                    <input
                      hidden
                      type="file"
                      id="logotypeTransparent"
                      name="logotypeTransparent"
                      @change="e => addFile(e)" />
                    <base-button
                      @click="chooseFile('logotypeTransparent')"
                      :text="$t('upload')"
                      class="button--has-icon"
                      :button-size-small="true">
                      <use-svg svg="export-icon" />
                    </base-button>
                  </template>
                </div>
                <img :src="club.logoTransparentUrl + club.logoTransparentKey" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="club__logotype">
              <p>{{ $t('logotypeTransparent') }}</p>
              <div :class="'club__layout--image'">
                <div :class="['club__layout--image-overlay-wrapper']">
                  <input
                    hidden
                    type="file"
                    id="logotypeTransparent"
                    name="logotypeTransparent"
                    @change="e => addFile(e)" />
                  <base-button
                    @click="chooseFile('logotypeTransparent')"
                    :text="$t('upload')"
                    class="button--has-icon"
                    :button-size-small="true">
                    <use-svg svg="export-icon" />
                  </base-button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="club__integrations"
        v-if="club.onTagVersion > 0">
        <h2 class="title-variation">
          <use-svg svg="print-icon" />
          {{ $t('scorecard') }}
        </h2>
        <p>Här ställer man in scorekort</p>
        <div class="address_region">
          <h3>{{ $t('invoiceAddress') }}</h3>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">
              {{ $t('companyName') }}
            </label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              class="input__input"
              v-model="club.invoiceAddress.companyName" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">
              {{ $t('orgNumber') }}
            </label>
            <input
              type="text"
              name="orgNumber"
              id="orgNumber"
              class="input__input"
              v-model="club.invoiceAddress.orgNumber" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">
              {{ $t('postalAddress') }}
            </label>
            <input
              type="text"
              name="postalAddress"
              id="postalAddress"
              class="input__input"
              v-model="club.invoiceAddress.postalAddress" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">
              {{ $t('postalCode') }}
            </label>
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              class="input__input"
              v-model="club.invoiceAddress.postalCode" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">
              {{ $t('city') }}
            </label>
            <input
              type="text"
              name="city"
              id="city"
              class="input__input"
              v-model="club.invoiceAddress.city" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">
              {{ $t('emailInvoice') }}
            </label>
            <input
              type="text"
              name="email"
              id="email"
              class="input__input"
              v-model="club.invoiceAddress.email" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">
              {{ $t('terpId') }}
            </label>
            <input
              type="text"
              name="terpId"
              id="terpId"
              class="input__input"
              v-model="club.invoiceAddress.terpId" />
          </div>
        </div>
        <div class="address_region">
          <h3>{{ $t('clubSpecificScoreCard') }}</h3>
          <div class="input">
            <custom-select
              :required="false"
              :label="$t('scoreCardSize')"
              @optionSelected="setScoreCardSize"
              :selected="selectedScoreCardSizeIndex"
              :options="scoreCardSizes" />
          </div>
          <div>
            <div class="club__logotype">
              <p>{{ $t('scoreCardProof') }}</p>
              <div class="input-group--inline">
                <template
                  v-if="
                    club && club.scoreCardProofUrl && club.scoreCardProofKey
                  ">
                  <base-button
                    text="Visa"
                    class="button--has-icon"
                    :button-size-small="true"
                    @click="
                      openLink(club.scoreCardProofUrl + club.scoreCardProofKey)
                    ">
                    <use-svg svg="print-icon" />
                  </base-button>
                  <base-button
                    :text="$t('remove')"
                    class="button--has-icon"
                    :button-size-small="true"
                    @click="removeLogotype('scoreCardProof')">
                    <use-svg svg="trash-icon" />
                  </base-button>
                </template>
                <input
                  hidden
                  type="file"
                  id="scoreCardProof"
                  name="scoreCardProof"
                  accept=".png"
                  @change="e => addFile(e)" />
                <base-button
                  @click="chooseFile('scoreCardProof')"
                  :text="$t('upload')"
                  class="button--has-icon"
                  :button-size-small="true">
                  <use-svg svg="export-icon" />
                </base-button>
              </div>
              <p>Filnamn som innehåller bokstäverna å, ä och ö fungerar ej</p>
            </div>
          </div>
          <div class="input checkbox">
            <span
              v-if="
                club &&
                club.scorecardProofUpdatedAt &&
                club.scorecardProofUpdatedBy
              ">
              Korrektur uppladdad av: {{ club.scorecardProofUpdatedBy }},
              {{ club.scorecardProofUpdatedAt }}
            </span>
            <div class="club__scorecard-preview">
              <span
                v-if="isScorecardLoading"
                class="element--is-loading"></span>
              <div
                v-if="
                  club.scoreCardProofUrl === 'none' ||
                  club.scoreCardProofUrl === '' ||
                  club.scoreCardProofUrl === undefined
                ">
                <p>Inger korrektur uppladdat</p>
                <img src="../../assets/images/sc-4p-blank.png" />
              </div>
              <img
                v-else
                :src="club.scoreCardProofUrl + club.scoreCardProofKey" />
            </div>
            <div class="checkbox--group">
              <input
                type="checkbox"
                id="markNextOrderAsChanged"
                v-model="club.markNextOrderAsChanged" />
              <label for="markNextOrderAsChanged">
                <span><span></span></span>
                Markera nästa beställning som ändring
              </label>
            </div>
          </div>
          <div class="input">
            <custom-select
              :required="false"
              :label="$t('scorecardSurface')"
              @optionSelected="setScoreCardSurface"
              :selected="selectedScoreCardSurfaceIndex"
              :options="surfaceTypes" />
          </div>
        </div>
      </div>
      <div class="club__integrations">
        <h2 class="title-variation">
          <use-svg svg="print-icon" />
          {{ $t('integrations') }}
        </h2>
        <p>
          Här ställer man in om klubben kör Git classic, Git Online, Sweetspot
          eller eventuella framtida varianter.
        </p>
        <custom-select
          :required="false"
          :label="$t('bookingSystem')"
          @optionSelected="setBookingSystem"
          :selected="selectedBookingSystemIndex"
          :options="bookingSystems" />
        <custom-select
          :required="false"
          :label="$t('courseInformationSystem')"
          @optionSelected="setCourseInformationSystem"
          :selected="selectedCourseInformationSystemIndex"
          :options="courseSystems" />
        <div class="input">
          <label
            for="git-club-id"
            class="input__label">
            {{ $t('clubId') }} (GUID)
          </label>
          <input
            type="text"
            name="clubId"
            id="clubId"
            class="input__input"
            v-model="club.gitClubId" />
        </div>
        <div class="input">
          <label
            for="print-endpoint-address"
            class="input__label">
            {{ $t('printEndpointAddress') }}
          </label>
          <input
            type="text"
            name="printEndpointAddress"
            id="printEndpointAddress"
            class="input__input"
            v-model="club.printEndpoint" />
        </div>
      </div>
      <div class="club__licenses">
        <h2 class="title-variation first-letter">
          <use-svg svg="print-icon" />
          <span>{{ $tc('license', 2) }}</span>
        </h2>
        <p>
          Här ställer ni in om klubben kör OnTag classig eller cloud samt datum
          på hur länge det gäller. Här aktiveras digitala scorekort, voucher och
          om man har terminal etc. Längre ner ställer ni in om betalningen går
          emot PayEx eller mot SGFs avtal.
        </p>
        <hr />
        <!-- Hide for now -->
        <!-- <custom-select
          :required="false"
          label="Golfterminal Modulo"
          @optionSelected="setGolfTerminalModuloLicense"
          :options="[{value: '123', displayName: 'OnTagCloud'}, {value: '456', displayName: 'OnTag Classic'}, {value: '', displayName: 'None'}]"/>
        <div class="input">
          <label
            for="terminalEndDate"
            class="input__label">End date for Golfterminal Modulo License</label>
          <input
            id="terminalEndDate"
            class="input__input"
            v-model="club.terminalEndDate"
            type="date"
            required="false"
            name="terminalEndDate"/>
        </div>
        <hr> -->
        <custom-select
          :required="false"
          label="OnTag"
          @optionSelected="setOnTagLicense"
          :selected="selectedOnTagVersionIndex"
          :options="onTagVersions" />
        <div
          v-if="club.onTagVersion"
          class="input">
          <label
            for="onTagVersionEndDate"
            class="input__label">
            {{ $t('endDate') }} {{ $t('for') }} OnTag
            {{ $tc('license', 1) }}
          </label>
          <input
            id="onTagVersionEndDate"
            class="input__input"
            v-model="onTagEndDate"
            type="date"
            required="false"
            name="onTagVersionEndDate" />
        </div>
        <hr />
        <div class="input-group club-switch">
          <label
            for="voucher"
            class="input__label">
            OnTag {{ $t('digital') }} {{ $t('scorecard') }}
          </label>
          <toggle-switch
            :disabled="club.onTagVersion == 0 || club.onTagVersion == 3"
            input-id="digitalScorecards"
            type="switch"
            :toggled="
              club.onTagVersion == 0
                ? false
                : club.onTagVersion == 3
                ? true
                : club.digitalScorecards
            "
            @toggle="toggleDigitalScorecards" />
        </div>
        <div
          class="input"
          v-if="
            (club.onTagVersion == 1 || club.onTagVersion == 2) &&
            club.digitalScorecards
          ">
          <label
            for="digitalScorecardsEndDate"
            class="input__label">
            {{ $t('endDate') }} {{ $t('for') }} OnTag {{ $t('digital') }}
            {{ $t('scorecard') }}
          </label>
          <input
            id="digitalScorecardsEndDate"
            class="input__input"
            v-model="digitalScorecardsEndDate"
            type="date"
            required="false"
            name="digitalScorecardsEndDate" />
        </div>
        <hr />
        <div class="input-group club-switch">
          <label
            for="voucher"
            class="input__label">
            OnTag {{ $t('voucher') }}
          </label>
          <toggle-switch
            :disabled="club.onTagVersion == 0 || club.onTagVersion == 3"
            input-id="voucher"
            type="switch"
            :toggled="
              club.onTagVersion == 0 || club.onTagVersion == 3
                ? false
                : club.voucher
            "
            @toggle="toggleVoucher" />
        </div>
        <div
          class="input"
          v-if="
            (club.onTagVersion == 1 || club.onTagVersion == 2) && club.voucher
          ">
          <label
            for="voucherEndDate"
            class="input__label">
            {{ $t('endDate') }} {{ $t('for') }} OnTag {{ $t('voucher') }}
          </label>
          <input
            id="voucherEndDate"
            class="input__input"
            v-model="voucherEndDate"
            type="date"
            required="false"
            name="voucherEndDate" />
        </div>
        <hr />
        <div class="input-group club-switch">
          <label
            for="appPrint"
            class="input__label">
            {{ $t('appPrint') }}
          </label>
          <toggle-switch
            :disabled="club.onTagVersion == 0 || club.onTagVersion == 3"
            input-id="appPrint"
            type="switch"
            :toggled="
              club.onTagVersion == 0 || club.onTagVersion == 3
                ? false
                : club.appPrint
            "
            @toggle="toggleAppPrint" />
        </div>
        <div
          class="input"
          v-if="
            (club.onTagVersion == 1 || club.onTagVersion == 2) && club.voucher
          ">
          <label
            for="appPrintEndDate"
            class="input__label">
            {{ $t('endDate') }} {{ $t('for') }} {{ $t('appPrint') }}
          </label>
          <input
            id="appPrintEndDate"
            class="input__input"
            v-model="club.appPrintEndDate"
            type="date"
            required="false"
            name="appPrintEndDate" />
        </div>
        <hr />
        <custom-select
          :required="false"
          id="courseGuideLicense"
          label="Course guide"
          @optionSelected="setCourseGuideLicense"
          :selected="selectedCourseGuideLicensesIndex"
          :options="courseGuideLicenses" />
        <template v-if="club.courseGuideLicense">
          <div class="input">
            <label
              for="courseGuideEndDate"
              class="input__label">
              {{ $t('endDate') }} {{ $t('for') }} Course guide
              {{ $tc('license', 1) }}
            </label>
            <input
              id="courseGuideEndDate"
              class="input__input"
              v-model="courseGuideEndDate"
              type="date"
              required="false"
              name="courseGuideEndDate" />
          </div>
        </template>
        <hr />
        <div class="input-group club-switch">
          <label
            for="onService"
            class="input__label">
            {{ $t('onService') }}
          </label>
          <toggle-switch
            input-id="onService"
            type="switch"
            :toggled="club.onService"
            @toggle="toggleOnService" />
        </div>
        <div
          class="input"
          v-if="club.onService">
          <label
            for="onServiceEndDate"
            class="input__label">
            {{ $t('endDate') }} {{ $t('for') }} {{ $t('onService') }}
            {{ $tc('license', 1) }}
          </label>
          <input
            id="onServiceEndDate"
            class="input__input"
            v-model="onServiceEndDate"
            type="date"
            required="false"
            name="onServiceEndDate" />
        </div>
        <hr />
        <div class="input-group club-switch">
          <label
            for="onCourse"
            class="input__label">
            {{ $t('onCourse') }}
          </label>
          <toggle-switch
            input-id="onCourse"
            type="switch"
            :toggled="club.onCourse"
            @toggle="toggleOnCourse" />
        </div>
        <div
          class="input"
          v-if="club.onCourse">
          <label
            for="onCourseEndDate"
            class="input__label">
            {{ $t('endDate') }} {{ $t('for') }} {{ $t('onCourse') }}
            {{ $tc('license', 1) }}
          </label>
          <input
            id="onCourseEndDate"
            class="input__input"
            v-model="onCourseEndDate"
            type="date"
            required="false"
            name="onCourseEndDate" />
        </div>
        <hr />
        <custom-select
          :required="false"
          id="businessboardLicense"
          label="Businessboard"
          @optionSelected="setBusinessboardLicense"
          :selected="selectedBusinessboardVersionIndex"
          :options="businessboardVersions" />
        <template v-if="club.businessboardVersion">
          <div class="input">
            <label
              for="onTagBusinessboardEndDate"
              class="input__label">
              {{ $t('endDate') }} {{ $t('for') }} Businessboard
              {{ $tc('license', 1) }}
            </label>
            <input
              id="businessboardVersionEndDate"
              class="input__input"
              v-model="businessboardEndDate"
              type="date"
              required="false"
              name="businessboardEndDate" />
          </div>
        </template>
        <hr />
        <div class="input-group club-switch">
          <label
            for="dynaMaster"
            class="input__label">
            {{ $t('dynaMaster') }}
          </label>
          <toggle-switch
            input-id="dynaMaster"
            type="switch"
            :toggled="club.dynaMaster"
            @toggle="toggleDynaMaster" />
        </div>
        <div
          class="input"
          v-if="club.dynaMaster">
          <label
            for="dynaMasterEndDate"
            class="input__label">
            {{ $t('endDate') }} {{ $t('for') }} {{ $t('dynaMaster') }}
            {{ $tc('license', 1) }}
          </label>
          <input
            id="dynaMasterEndDate"
            class="input__input"
            v-model="dynaMasterEndDate"
            type="date"
            required="false"
            name="dynaMasterEndDate" />
        </div>
      </div>
      <section class="payment">
        <h2 class="title-variation first-letter">
          <use-svg svg="print-icon" />
          <span>{{ $tc('payments', 1) }}</span>
        </h2>
        <div class="input">
          <label
            for="payExMerchantName"
            class="input__label first-letter">
            {{ $t('payExMerchantName') }}
          </label>
          <input
            type="text"
            name="payExMerchantName"
            id="payExMerchantName"
            class="input__input"
            v-model="club.payExMerchantName" />
        </div>
        <custom-select
          v-if="paymentProvider !== undefined"
          class="first-letter"
          id="paymentProvider"
          :required="false"
          :label="$t('paymentProvider')"
          @optionSelected="setPaymentProvider"
          :selected="selectedPaymentProviderIndex"
          :options="paymentProvider" />
        <template v-if="selectedPaymentProviderIndex !== undefined">
          <section v-if="selectedPaymentProviderIndex === 0">
            <h3>PayEx</h3>
            <h4>Rest API</h4>
            <div class="input">
              <label
                for="payExBaseAddress"
                class="input__label first-letter">
                {{ $t('payExBaseAddress') }}
              </label>
              <input
                type="text"
                name="payExBaseAddress"
                id="payExBaseAddress"
                class="input__input"
                v-model="club.payExBaseAddress" />
            </div>
            <div class="input">
              <label
                for="payExMerchantId"
                class="input__label first-letter">
                {{ $t('payExMerchantId') }}
              </label>
              <input
                type="text"
                name="payExMerchantId"
                id="payExMerchantId"
                class="input__input"
                v-model="club.payExMerchantId" />
            </div>
            <div class="input">
              <label
                for="payExAuthenticationToken"
                class="input__label first-letter">
                {{ $t('payExAuthenticationToken') }}
              </label>
              <input
                type="text"
                name="payExAuthenticationToken"
                id="payExAuthenticationToken"
                class="input__input"
                v-model="club.payExAuthenticationToken" />
            </div>
          </section>
          <section v-else>
            <h3>Svenska Golfförbundet</h3>
            <h4>Partner API</h4>
            <div class="input">
              <label
                for="sgfClientId"
                class="input__label first-letter">
                {{ $t('sgfClientId') }}
              </label>
              <input
                type="text"
                name="sgfClientId"
                id="sgfClientId"
                class="input__input"
                v-model="club.sgfClientId" />
            </div>
            <div class="input">
              <label
                for="sgfClientSecret"
                class="input__label first-letter">
                {{ $t('sgfClientSecret') }}
              </label>
              <input
                type="text"
                name="sgfClientSecret"
                id="sgfClientSecret"
                class="input__input"
                v-model="club.sgfClientSecret" />
            </div>
          </section>
        </template>
      </section>
      <div
        class="club__licenses"
        v-if="club && club.changeLog && club.changeLog.rows.length > 0">
        <h2 class="title-variation">
          <use-svg svg="print-icon" />
          Change log
        </h2>
        <p>
          Här ser man förändringar. Vilket värde som ändrades av vem och när de
          gjordes.
        </p>
        <list-table
          v-if="club && club.changeLog"
          :items="club.changeLog.rows"
          :headers="club.changeLog.columns">
        </list-table>
      </div>
      <div class="club__actions sticky sticky__bottom">
        <base-button
          v-if="id"
          :text="$t('save')"
          @click="saveClub"
          v-element-loader="{ loaderKey: 'golfoffice-club-edit' }"
          :disabled="!canCreate" />
        <base-button
          v-else
          :text="$t('create')"
          @click="saveClub"
          v-element-loader="{ loaderKey: 'golfoffice-club-save' }"
          :disabled="!canCreate" />
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import ToggleSwitch from '@/components/form-elements/switch';
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import { formatDateForInputs } from '@/core/helpers.js';
import elementLoader from '@/directives/element-loader';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'EditClub',
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
    ListTable,
    BaseButton,
    CustomSelect,
    ToggleSwitch,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: String,
  },
  data() {
    return {
      selectedBookingSystemIndex: undefined,
      selectedCourseInformationSystemIndex: undefined,
      selectedOnTagVersionIndex: undefined,
      selectedCourseGuideLicenseIndex: undefined,
      selectedBusinessboardVersionIndex: undefined,
      // selectedBusinessboardIndex: undefined,
      selectedPaymentProviderIndex: undefined,
      selectedRegionIndex: undefined,
      selectedScoreCardSizeIndex: undefined,
      selectedLanguageIndex: undefined,
      selectedCountryIndex: undefined,
      selectedScoreCardSurfaceIndex: undefined,
      hover: false,
      markNextOrderAsChanged: false,
      correctionUploadBy: undefined,
      correctionUploadDate: undefined,
      isScorecardLoading: false,
    };
  },
  methods: {
    toggleActivity(boolean) {
      this.club.active = boolean;
    },
    toggleDigitalScorecards(boolean) {
      this.club.digitalScorecards = boolean;
      if (!this.club.digitalScorecards) this.club.digitalScorecardsEndDate = '';
    },
    toggleVoucher(boolean) {
      this.club.voucher = boolean;
      if (!this.club.voucher) this.club.voucherEndDate = '';
    },
    toggleAppPrint(boolean) {
      this.club.appPrint = boolean;
      if (!this.club.appPrint) this.club.appPrintEndDate = '';
    },
    toggleOnService(boolean) {
      this.club.onService = boolean;
      if (!this.club.onService) this.club.onServiceEndDate = '';
    },
    toggleOnCourse(boolean) {
      this.club.onCourse = boolean;
      if (!this.club.onCourse) this.club.onCourseEndDate = '';
    },
    toggleDynaMaster(boolean) {
      this.club.dynaMaster = boolean;
      if (!this.club.dynaMaster) this.club.dynaMasterEndDate = '';
    },
    setScoreCardSize(e) {
      this.club.scoreCardSize = e.value;
    },
    setLanguage(e) {
      this.club.language = e.value;
    },
    setCountry(e) {
      this.club.country = e.value;
    },
    setScoreCardSurface(e) {
      this.club.scoreCardSurfaceType = e.value;
    },
    setBookingSystem(system) {
      this.club.bookingSystem = system.value;
    },
    setCourseInformationSystem(system) {
      this.club.courseSystem = system.displayName;
    },
    // Hide for now
    // setGolfTerminalModuloLicense() {
    //   console.log('setGolfTerminalModuloLicense')
    // },
    setOnTagLicense(license) {
      this.selectedOnTagVersion(license.value);
      this.club.onTagVersion = license.value;
    },
    setCourseGuideLicense(license) {
      this.selectedCourseGuideLicense(license.value);
      this.club.courseGuideLicense = license.value;
      if (!license.value) this.club.courseGuideEndDate = '';
    },
    setMarkNextOrderHasChanges() {
      this.club.markNextOrderAsChanged = this.markNextOrderAsChanged;
    },
    setBusinessboardLicense(license) {
      this.selectedBusinessboardVersion(license.value);
      this.club.businessboardVersion = license.value;
    },
    setPaymentProvider(provider) {
      this.selectedPaymentProvider(provider.value);
      this.club.paymentProvider = provider.value;
    },
    setRegion(region) {
      this.selectedRegion(region.value);
      this.club.regionId = region.value;
    },
    chooseFile(value) {
      document.getElementById(value).click();
    },
    openLink(href) {
      window.open(href);
    },
    addFile(event) {
      let image;
      image = event.target.files[0];
      let type = 'Logo';
      if (event.target.id === 'logotypeTransparent') {
        type = 'LogoTransparent';
      } else if (event.target.id === 'scoreCardProof') {
        type = 'ScoreCardProof';
      }
      if (image) {
        this.isScorecardLoading = true;
        this.$store
          .dispatch('clubs/addLogotype', { image: image, type: type })
          .then(() => {
            this.isScorecardLoading = false;
          });
      }
    },
    hoverOn(element) {
      this.hover = element;
    },
    removeLogotype(type) {
      this.club[type + 'Url'] = '';
      this.club[type + 'Key'] = '';
    },
    saveClub() {
      let path = 'clubs/saveClub';
      if (this.id) {
        path = 'clubs/patchClub';
      }

      if (this.club.onTagVersion === 0) {
        this.club.digitalScorecards = false;
        this.club.digitalScorecardsEndDate = '';
        this.club.voucher = false;
        this.club.voucherEndDate = '';
      }
      if (this.club.onTagVersion === 3) {
        this.club.digitalScorecards = true;
        this.club.digitalScorecardsEndDate = this.club.onTagEndDate;
        this.club.voucher = false;
        this.club.voucherEndDate = '';
      }

      this.$store.dispatch(path, this.club).then(() => {
        this.$router.push({ path: '/clubs' });
        if (this.id) {
          // TODO: Fix translation
          this.$toasted.success('Club saved');
        } else {
          // TODO: Fix translation
          this.$store.dispatch('clubs/clubsSelect', null);
          this.$toasted.success('Club added');
        }
      });
    },

    selectedBookingSystem(system) {
      this.selectedBookingSystemIndex = this.bookingSystems.findIndex(
        t => t.value === system
      );
    },
    selectedCourseInformationSystem(system) {
      this.selectedCourseInformationSystemIndex = this.courseSystems.findIndex(
        t => t.displayName === system
      );
    },
    selectedOnTagVersion(version) {
      this.selectedOnTagVersionIndex = this.onTagVersions.findIndex(
        t => t.value === version
      );
    },
    selectedCourseGuideLicense(version) {
      this.selectedCourseGuideLicensesIndex =
        this.courseGuideLicenses.findIndex(t => t.value === version);
    },
    selectedPaymentProvider(provider) {
      this.selectedPaymentProviderIndex = this.paymentProvider.findIndex(
        p => p.value === provider
      );
    },
    selectedRegion(region) {
      this.selectedRegionIndex = this.regions.findIndex(
        p => p.value === region
      );
    },
    selectedBusinessboardVersion(version) {
      this.selectedBusinessboardVersionIndex =
        this.businessboardVersions.findIndex(s => s.value === version);
    },
    selectedScoreCardSize(e) {
      this.selectedScoreCardSizeIndex = this.scoreCardSizes.findIndex(
        s => s.value === e
      );
    },
    selectedLanguage(e) {
      this.selectedLanguageIndex = this.languages.findIndex(s => s.value === e);
    },
    selectedCountry(e) {
      this.selectedCountryIndex = this.countries.findIndex(s => s.value === e);
    },
    selectedScoreCardSurface(e) {
      this.selectedScoreCardSurfaceIndex = this.surfaceTypes.findIndex(
        s => s.value === e
      );
    },
  },
  computed: {
    canCreate() {
      return this.club.name;
    },
    club() {
      return this.$store.getters['clubs/club'];
    },
    bookingSystems() {
      return this.$store.getters['clubs/bookingSystems'];
    },
    courseSystems() {
      return this.$store.getters['clubs/courseSystems'];
    },
    onTagVersions() {
      return this.$store.getters['clubs/onTagVersions'];
    },
    courseGuideLicenses() {
      return this.$store.getters['clubs/courseGuideLicenses'];
    },
    paymentProvider() {
      return this.$store.getters['clubs/paymentProvider'];
    },
    businessboardVersions() {
      return this.$store.getters['clubs/businessboardVersions'];
    },
    regions() {
      return this.$store.getters['clubs/regions'];
    },
    onTagEndDate: {
      get: function () {
        return formatDateForInputs(this.club.onTagEndDate);
      },
      set: function (newValue) {
        this.club.onTagEndDate = newValue;
        return this.club.onTagEndDate;
      },
    },
    businessboardEndDate: {
      get: function () {
        return formatDateForInputs(this.club.businessboardEndDate);
      },
      set: function (newValue) {
        this.club.businessboardEndDate = newValue;
        return this.club.businessboardEndDate;
      },
    },
    digitalScorecardsEndDate: {
      get: function () {
        return formatDateForInputs(this.club.digitalScorecardsEndDate);
      },
      set: function (newValue) {
        this.club.digitalScorecardsEndDate = newValue;
        return this.club.digitalScorecardsEndDate;
      },
    },
    voucherEndDate: {
      get: function () {
        return formatDateForInputs(this.club.voucherEndDate);
      },
      set: function (newValue) {
        this.club.voucherEndDate = newValue;
        return this.club.voucherEndDate;
      },
    },
    courseGuideEndDate: {
      get: function () {
        return formatDateForInputs(this.club.courseGuideEndDate);
      },
      set: function (newValue) {
        this.club.courseGuideEndDate = newValue;
        return this.club.courseGuideEndDate;
      },
    },
    onServiceEndDate: {
      get: function () {
        return formatDateForInputs(this.club.onServiceEndDate);
      },
      set: function (newValue) {
        this.club.onServiceEndDate = newValue;
        return this.club.onServiceEndDate;
      },
    },
    onCourseEndDate: {
      get: function () {
        return formatDateForInputs(this.club.onCourseEndDate);
      },
      set: function (newValue) {
        this.club.onCourseEndDate = newValue;
        return this.club.onCourseEndDate;
      },
    },
    dynaMasterEndDate: {
      get: function () {
        return formatDateForInputs(this.club.dynaMasterEndDate);
      },
      set: function (newValue) {
        this.club.dynaMasterEndDate = newValue;
        return this.club.dynaMasterEndDate;
      },
    },
    scoreCardSizes() {
      return [
        { value: 0, displayName: 'Ingen' },
        { value: 1, displayName: '110x273' },
        { value: 2, displayName: '110x362' },
      ];
    },
    // todo: Send from backend
    languages() {
      return [
        { value: 0, displayName: 'Swe' },
        { value: 1, displayName: 'Eng' },
      ];
    },
    countries() {
      // todo: Send from backend
      return [
        { value: 0, displayName: 'Sverige' },
        { value: 1, displayName: 'Norge' },
        { value: 2, displayName: 'Finland' },
        { value: 3, displayName: 'Danmark' },
      ];
    },
    surfaceTypes() {
      return [
        { value: 1, displayName: 'Matt' },
        { value: 2, displayName: 'Blank' },
      ];
    },
  },
  mounted() {
    if (this.id) {
      this.$store.dispatch('clubs/fetchClub', { id: this.id }).then(() => {
        this.$store
          .dispatch('clubs/fetchBookingSystems')
          .then(() => this.selectedBookingSystem(this.club.bookingSystem));
        this.$store
          .dispatch('clubs/fetchCourseSystems')
          .then(() =>
            this.selectedCourseInformationSystem(this.club.courseSystem)
          );
        this.$store
          .dispatch('clubs/fetchOnTagVersions')
          .then(() => this.selectedOnTagVersion(this.club.onTagVersion));
        this.$store
          .dispatch('clubs/fetchCourseGuideLicenses')
          .then(() =>
            this.selectedCourseGuideLicense(this.club.courseGuideLicense)
          );
        this.$store
          .dispatch('clubs/fetchPaymentProvider')
          .then(() => this.selectedPaymentProvider(this.club.paymentProvider));
        this.$store
          .dispatch('clubs/fetchRegions')
          .then(() => this.selectedRegion(this.club.regionId));
        this.$store
          .dispatch('clubs/fetchBusinessboardVersions')
          .then(() =>
            this.selectedBusinessboardVersion(this.club.businessboardVersion)
          );
        this.selectedScoreCardSize(this.club.scoreCardSize);
        this.selectedScoreCardSurface(this.club.scoreCardSurfaceType);
      });
    } else {
      this.$store.commit('clubs/club', {});
      if (this.club) {
        this.$store.dispatch('clubs/fetchBookingSystems');
        this.$store.dispatch('clubs/fetchCourseSystems');
        this.$store.dispatch('clubs/fetchOnTagVersions');
        this.$store.dispatch('clubs/fetchCourseGuideLicenses');
        this.$store.dispatch('clubs/fetchPaymentProvider');
        this.$store.dispatch('clubs/fetchRegions');
        this.$store.dispatch('clubs/fetchBusinessboardVersions');
      }
    }
  },
  created() {
    if (
      this.$store.state.user.user.roles.GolfOffice.some(
        e => e.value === 'BusinessBoardAdmin'
      )
    ) {
      this.$router.replace({ path: this.id + '/bb' });
    }
  },
};
</script>
<style lang="scss">
.club-switch {
  display: flex;
  .input__label {
    flex-basis: 15%;
  }
}
.club__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  button {
    margin-left: 1rem;
  }
}
.golfoffice--top {
  display: flex;
  justify-content: space-between;
  align-items: center h1 {
    margin-top: 0;
  }
}
.club__logotype {
  height: 100%;
  width: 30%;

  @include media('to-medium') {
    width: 100%;
  }

  p {
    margin: 0 0 0.5rem 0;
    @include media('to-medium') {
      margin: 0.5rem 0;
    }
  }
}
button {
  margin-right: 0.3rem;
  padding: 0 0.5rem;
}
.club__layout--image {
  border: 1px solid color(gray);
  height: 7rem;
  display: flex;
  justify-content: center;
  position: relative;
  img {
    align-self: center;
    height: 80%;
  }
  .club__layout--image-overlay-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.club__layout--image-overlay {
  background-color: rgba(249, 249, 249, 0.5);
}

.club__scorecard-preview {
  width: 400px;

  img {
    width: 100%;
  }
}

.input-group--inline {
  gap: 12px;
}
</style>
