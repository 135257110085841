<template>
  <transition name="te-popover">
    <div
      v-if="show"
      :class="getClasses()"
      :style="getStyle()"
      @click="doThis">
      <div class="te-popover-wrapper">
        <div class="te-popover-title">{{ title }}</div>
        <div class="te-popover-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TePopover',
  watch: {
    show: function (newValue) {
      this.localShow = newValue;
    },
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: null,
    },

    show: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'right',
    },
  },
  created() {
    window.addEventListener('resize', this.windowResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize);
  },
  methods: {
    windowResize(e) {
      this.$forceUpdate();
    },
    doThis(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
    getClasses() {
      if (this.target) {
        return ['te-popover', 'te-popover-' + this.position, 'te-popover-abs'];
      }
      return ['te-popover', 'te-popover-' + this.position];
    },
    getStyle() {
      if (!this.target) {
        return '';
      } else {
        let el = document.querySelector('[popover-id="' + this.target + '"]');

        if (!el) {
          return '';
        }
        let bbox = el.getBoundingClientRect();
        if (this.position === 'top') {
          return `bottom: ${bbox.top}px; left: ${
            bbox.left + bbox.width / 2
          }px;`;
        } else if (this.position === 'bottom') {
          return `top: ${bbox.top + bbox.height}px; left: ${
            bbox.left + bbox.width / 2
          }px;`;
        } else if (this.position === 'left') {
          return `top: ${bbox.top + bbox.height / 2}px; left: ${bbox.left}px;`;
        } else if (this.position === 'right') {
          return `top: ${bbox.top + bbox.height / 2}px; left: ${
            bbox.left + bbox.width
          }px;`;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.te-popover-enter-active,
.te-popover-leave-active {
  transition: opacity 0.1s ease-in-out;
  .te-popover-wrapper {
    transition: transform 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
.te-popover-enter,
.te-popover-leave-to {
  opacity: 0;
  .te-popover-wrapper {
    transform-origin: center left;
    transform: scale(0.5);
  }
}
.te-popover {
  font-family: 'Tietoevry sans 1';
  z-index: 9999;
  position: absolute;
}
.te-popover-wrapper {
  min-width: 160px;
  max-width: 260px;
  gap: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: var(--Paddings-Padding-M, 20px);
  background-color: #fff;
  box-shadow: 0px 6px 12px 0px rgba(16, 52, 82, 0.4);
  border-radius: var(--Border-radius-Border-radius-S, 6px);
  text-align: left;
  &::after {
    content: '';
    position: absolute;
    border-width: 10px;
    border-style: solid;
  }
}
.te-popover-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: 24px;
  color: var(--Text-Text-Primary, #1e2224);
}
.te-popover-content {
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 20px;
  color: var(--Text-Text-Primary, #1e2224);
}
.te-popover.te-popover-top {
  bottom: 100%;
  left: 50%;
  margin-bottom: 20px;
  transform: translateX(-50%);
  .te-popover-wrapper {
    transform-origin: bottom center;
    &::after {
      top: 100%;
      left: 50%;
      margin-left: -10px;
      border-color: white transparent transparent transparent;
    }
  }
}
.te-popover.te-popover-top.te-popover-abs {
  bottom: unset;
  left: unset;
}
.te-popover.te-popover-bottom {
  top: 100%;
  left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  .te-popover-wrapper {
    transform-origin: top center;
    &::after {
      bottom: 100%;
      left: 50%;
      margin-left: -10px;
      border-color: transparent transparent white transparent;
    }
  }
}
.te-popover.te-popover-bottom.te-popover-abs {
  top: unset;
  left: unset;
}
.te-popover.te-popover-left {
  right: 100%;
  top: 50%;
  margin-right: 20px;
  transform: translateY(-50%);
  .te-popover-wrapper {
    transform-origin: center right;
    &::after {
      top: 50%;
      left: 100%;
      margin-top: -10px;
      border-color: transparent transparent transparent white;
    }
  }
}
.te-popover.te-popover-left.te-popover-abs {
  right: unset;
  top: unset;
  transform: translateY(-50%) translateX(-100%);
  margin-left: -20px;
}
.te-popover.te-popover-right {
  left: 100%;
  top: 50%;
  margin-left: 20px;
  transform: translateY(-50%);
  .te-popover-wrapper {
    transform-origin: center left;
    &::after {
      top: 50%;
      right: 100%;
      margin-top: -10px;
      border-color: transparent white transparent transparent;
    }
  }
  .te-popover.te-popover-left.te-popover-abs {
    left: unset;
    top: unset;
  }
}
</style>
