var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drill-down-grid"},[_c('div',{staticClass:"row",style:({
      gridTemplateColumns: `370px ${'200px '.repeat(
        _vm.drillDownList.header.columns.length
      )}`,
      display: 'grid',
      gridAutoFlow: 'column',
    })},[_c('div',{staticClass:"drill-down-title"},[_c('span',{staticStyle:{"cursor":"pointer","margin-right":"0.5rem"},attrs:{"title":_vm.$t('copyData')},on:{"click":_vm.copyTableData}},[_c('use-svg',{attrs:{"svg":"bb-copy-icon2"}})],1),_c('span',[_vm._v(_vm._s(_vm.drillDownList.title ?? 'Title'))])]),_vm._l((_vm.drillDownList.header.columns),function(header,index){return _c('div',{key:index,staticClass:"drill-down-header"},[_vm._v(" "+_vm._s(header)+" ")])})],2),_vm._l((_vm.drillDownList.rows),function(row,index){return _c('div',{key:index},[_c('drill-down-row',{attrs:{"row":row,"indentLevel":0}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }