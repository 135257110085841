<!-- Printer Queue for users other than Evry -->
<template>
  <div class="ontag">
    <div class="ontag--content print-queue">
      <div>
        <span class="h1">{{ $t('printerQueue') }}</span>
        <span v-if="isLoading" class="element--is-loading"></span>
      </div>
      <p>Här kommer jobben snabbt igenom. Vid eventuella fel så hittar men de här med tidpunkt och eventuella felmeddelanden såsom slut på papper tex. Varje skrivare har sin egen printerkö i molnet. Ni kan markera de meddelandena som ni vill ta bort och knappen ta bort markerade tänds. eller ta bort hela kön direkt med knappen töm printerkö.</p>
      <p class="first-letter">{{ $t('printer') }}: <i>{{ printer.printerName }}</i></p>
        <div class="ontag--utilitites input-group--inline">
          <div class="pagination-amount">
            <span class="first-letter">{{ $t('show') }}</span>
              <custom-select
                v-if="selectedIndexPageSize !== undefined"
                :required="false"
                @optionSelected="setPageSize"
                :selected="selectedIndexPageSize"
                :options="pageSizeOptions"/>
            <span class="row-lines">{{ $t('lines') }}</span>
          </div>
          <div class="search-wrapper">
            <div class="input">
              <label
                for="searchQuery"
                class="input__label label__inline first-letter">{{ $t('search') }}</label>
              <input
                id="searchQuery"
                class="input__input input__inline search-input"
                @keyup.enter="getVariatedPrinterQueueResults"
                v-model="searchQuery"
                type="text"
                name="searchQuery"/>
            </div>
            <base-button
              class="search-button"
              :text="$t('search')"
              :button-size-small="true"
              @click="getVariatedPrinterQueueResults" />
          </div>
          <base-button
            class="button--icon-to-left"
            :text="$t('deleteMarkedPrinterJobs')"
            :button-size-small="true"
            @click="toggleDeleteMarkedPrinterJobsModal"
            :disabled="allSelected.length === 0">
            <use-svg svg="trash-icon"/>
          </base-button>
          <modal-window :show="showDeleteMarkedPrinterJobsModal">
            <div class="modal__header">
              {{ $t('deleteMarkedPrinterJobs') }}
            </div>
            <div class="modal__content">
              <div>
                <h2>{{ $t('deleteMarkedPrinterJobsQuestion') }}?</h2>
              </div>
            </div>
            <div class="modal__content--actions">
              <base-button
                class="button--background-gray-dark"
                :text="$t('cancel')"
                @click="toggleDeleteMarkedPrinterJobsModal">
              </base-button>
              <base-button
                :text="$t('deleteMarkedPrinterJobs')"
                @click="deleteMarkedPrinterJobs"/>
            </div>
          </modal-window>
          <base-button
            class="button--icon-to-left"
            :text="$t('emptyPrinterQueue')"
            :button-size-small="true"
            @click="toggleEmptyPrinterQueueModal">
            <use-svg svg="export-icon"/>
          </base-button>
          <modal-window :show="showEmptyPrinterQueueModal">
            <div class="modal__header">
              {{ $t('emptyPrinterQueue') }}
            </div>
            <div class="modal__content">
              <div>
                <h2>{{ $t('emptyPrinterQueueQuestion') }}?</h2>
              </div>
            </div>
            <div class="modal__content--actions">
              <base-button
                class="button--background-gray-dark"
                :text="$t('cancel')"
                @click="toggleEmptyPrinterQueueModal">
              </base-button>
              <base-button
                :text="$t('emptyPrinterQueue')"
                @click="emptyPrinterQueue"/>
            </div>
          </modal-window>
        </div>
        <list-table
          :items="printerQueue.rows"
          :headers="printerQueue.columns"
          @variationResults="getVariatedPrinterQueueResults"
          :use-cursor="false"
          @allSelected="getPrinterQueuesSelected"
          :page-size="pagination.pageSize"
          ref="printerQueueListTable">
        </list-table>
        <results-pagination
          :first-row-on-page="pagination.firstRowOnPage"
          :last-row-on-page="pagination.lastRowOnPage"
          :row-count="pagination.rowCount"
          :page-size="pagination.pageSize"
          :page-count="pagination.pageCount"
          :current-page="pagination.currentPage"
          :next-page="getNextPage()"
          :prev-page="getPrevPage()"
          store="printerQueue"
          @currentPage="getVariatedPrinterQueueResults"/>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import ResultsPagination from '@/components/results-pagination';
import CustomSelect from '@/components/form-elements/custom-select';
import ModalWindow from '@/components/modal/modal';

export default {
  name: 'PrinterQueue',
  components: {
    ListTable,
    BaseButton,
    UseSvg,
    ResultsPagination,
    CustomSelect,
    ModalWindow,
  },
  data() {
    return {
      isLoading: true,
      selectedIndexPageSize: undefined,
      searchQuery: '',
      sort: {},
      currentPage: 0,
      active: undefined,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
      showDeleteMarkedPrinterJobsModal: false,
      showEmptyPrinterQueueModal: false,
      allSelected: [],
    };
  },
  methods: {
    getPrevPage () {
      return this.$store.getters['users/previousPage'];
    },
    getNextPage () {
      return this.$store.getters['users/nextPage'];
    },
    getVariatedPrinterQueueResults (value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length < value.filter.options.length && checked.length > 0) {
          if (checked[0].value === '1') {
            this.active = true;
          } else {
            this.active = false;
          }
        } else {
          this.active = undefined;
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('printerQueue/fetchPrinterQueue', {
        id: this.$route.params.id,
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.status': this.active,
        pageSize: this.pageSize,
        page: this.searchQuery.length > 0 ? '1' : this.currentPage,
      });
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedPrinterQueueResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(o => parseInt(o.value) === size);
      this.selectedIndexPageSize = selectedSize;
    },
    deleteMarkedPrinterJobs() {
      this.$store.dispatch('printerQueue/deleteMarkedPrinterQueues', {
        ids: this.allSelected,
      }).then(() => {
        this.toggleDeleteMarkedPrinterJobsModal();
        this.allSelected = [];
        this.currentPage = 1;
        if (this.$refs && this.$refs.printerQueueListTable) this.$refs.printerQueueListTable.resetSelectAll();
        this.fetchPrinterQueue();
      });
    },
    emptyPrinterQueue() {
      this.$store.dispatch('printerQueue/deletePrinterQueue', {
        id: this.$route.params.id,
      }).then(() => {
        this.toggleEmptyPrinterQueueModal();
      });
    },
    toggleDeleteMarkedPrinterJobsModal () {
      this.showDeleteMarkedPrinterJobsModal = !this.showDeleteMarkedPrinterJobsModal;
    },
    toggleEmptyPrinterQueueModal () {
      this.showEmptyPrinterQueueModal = !this.showEmptyPrinterQueueModal;
    },
    fetchPrinterQueue() {
      this.$store.dispatch('printerQueue/fetchPrinterQueue', {
        id: this.$route.params.id,
        pageSize: this.pageSize,
        page: this.currentPage,
      }).then(() => {
        this.selectedPageSize(this.pagination.pageSize);
      }).finally(() => { this.isLoading = false; });
    },
    getPrinterQueuesSelected(value) {
      this.allSelected = value;
    },
    fetchPrinter () {
      this.$store.dispatch('printers/fetchPrinter', {
        id: this.$route.params.id,
      });
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.fetchPrinterQueue();
      this.fetchPrinter();
    },
  },
  computed: {
    printerQueue () {
      return this.$store.getters['printerQueue/printerQueue'];
    },
    pagination () {
      return this.$store.getters['printerQueue/pagination'];
    },
    printer () {
      return this.$store.getters['printers/printer'];
    },
  },
  created () {
    this.fetchPrinterQueue();
    this.fetchPrinter();
  },
};
</script>
<style lang="scss">
.element--is-loading {
  margin-left: .5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 20px;
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
}
.print-queue {
  td:last-child {
    text-align: left !important;
  }
}
</style>
