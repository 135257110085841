<template>
  <div>
    <div class="action-buttons-div">
      <base-button
        background-color="bb-primary"
        :text="$t('connectAccounts')"
        @click="isModalOpen = true">
      </base-button>
    </div>
    <div
      v-if="isModalOpen"
      class="modal--wrapper">
      <div class="modal--content">
        <div class="modal--header">
          <h2 class="modal--title">{{ $t('connectAccounts') }}</h2>
        </div>
        <div class="modal--body">
          <div>
            <div class="header row">
              <div>{{ $t('start') }}</div>
              <div>{{ $t('end') }}</div>
              <div>{{ $t('category') }}</div>
              <div>{{ $t('group') }}</div>
            </div>
            <div
              v-for="group in groups"
              :key="group.id"
              class="data row">
              <div>
                {{ group.accountNumberStart }}
              </div>
              <div>
                {{ group.accountNumberEnd }}
              </div>
              <div>
                {{ group.balanceAccountCategoryName }}
              </div>
              <div>
                {{ group.name }}
              </div>
            </div>

            <div style="display: flex; gap: 0.5rem">
              <base-button
                background-color="white"
                :text="$t('cancel')"
                @click="isModalOpen = false">
              </base-button>
              <base-button
                background-color="bb-primary"
                :text="$t('connect')"
                @click="connect">
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'auto-connect-accounts',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
  },
  props: {
    companyId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      newGroupName: '',
      drag: false,
      isModalOpen: false,
    };
  },
  watch: {},
  computed: {
    groups() {
      return this.$store.getters['BusinessBoardBalanceSheet/groups'];
    },
  },
  methods: {
    connect() {
      this.$store
        .dispatch('BusinessBoardBalanceSheet/patchAuto', this.companyId)
        .then(() => {
          this.isModalOpen = false;
        });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.modal--wrapper {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 50rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;
  border-radius: 8px;
}

.modal--content {
  width: 45rem;
  margin-left: 2rem;
}
.modal--header {
  background-color: #fff;
  margin-left: 2rem;
}
.modal--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #131516;
}
.breadcrumb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7c80;
}
.delete-icon {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;
  justify-content: center;
}
.rounded {
  padding: measure(spacing-xs);
  margin-left: auto;

  border-radius: 8px;
  border: solid 1px #c1c7c9;

  height: 30px;
}
.buttons {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.header {
  background: #373d3f;
  color: var(--white, #fff);
  padding: 22px 16px;
  font-size: 17px;
}
.data {
  background: var(--tabellgrn1, #f0f5f5);
  color: #000;
  padding: 12px 16px;
  font-size: 16px;
}
.row {
  display: grid;
  grid-template-columns: 5rem 5rem 15rem 20rem;
  border-radius: 3px;
  font-weight: 600;
  vertical-align: middle;
  margin: 2px;
  .subtle {
    color: var(--gr1, #131516);
    font-size: 14px;
    font-weight: 300;
  }
}
</style>
