<template>
  <div class="legend">
    <div
      :class="['row', { hidden: hidden[i] }]"
      v-for="(_, i) in legend.labels.length"
      :key="i">
      <div :style="'background: ' + colors[i] + ';'"></div>
      <div style="white-space: pre; line-height: 20px">
        {{ ['Gäst', 'Medlem', 'X-faktor'][i] }}, {{ legend.tooltips[i] }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    legend: {
      labels: [],
      values: [],
      tooltips: [],
    },
  },
  data() {
    return {
      hidden: [false, false, false, false],
    };
  },
  computed: {
    colors() {
      if (this.legend.labels.length === 4) {
        return ['#0E3746', '#00A99D', '#ABDED1', '#4B2D88'];
      }
      return [
        '#0E3746',
        '#27645C',
        '#00A99D',
        '#ABDED1',
        '#F08565',
        '#4B2D88',
        '#BAAAF9',
        '#C5DEE0',
        '#555F61',
        '#FBEBDD',
      ];
    },
  },
  methods: {
    click(i) {
      this.$set(this.hidden, i, !this.hidden[i]);
      this.$emit('click', i);
    },
  },
};
</script>
<style lang="scss" scoped>
.legend {
  padding: 0.5rem;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin: 6rem 0 0 0;
  max-height: 20rem;
  flex-wrap: wrap;
  .row {
    cursor: pointer;
    margin: 0.5rem;
    display: grid;
    grid-template-columns: 15px auto;
    div:first-child {
      justify-self: center;
      margin-right: 10px;
      margin-top: 3px;
      border-radius: 2px;
      width: 15px;
      height: 15px;
    }
    .line {
      width: 15px;
      margin-top: 5px;
    }
  }
  .hidden {
    opacity: 0.5;
  }
}
</style>
