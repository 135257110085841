<template>
  <div class="chart_settings">
    <div
      v-if="showModal"
      class="chart_settings_modal">
      <div class="header">
        <span>{{ $t('tableSettings') }}</span>
      </div>
      <div class="drilldown">
        {{ $t('drillDown') }}
        <info-icon>
          <template #icon>
            <use-svg svg="bb-info-icon" />
          </template>
          <template #description>
            <img src="../../assets/images/drilldown_help.webp" />
          </template>
        </info-icon>
        <div
          v-show="false"
          class="drilldown-info"
          @click="showDrillDownInfo = !showDrillDownInfo">
          <div
            class="drilldown-info-box"
            v-if="showDrillDownInfo">
            {{ $t('drillDownInfo') }}
          </div>
          <img src="../../assets/images/drilldown_help.webp" />
          <use-svg
            height="20"
            width="20"
            svg="help-icon">
          </use-svg>
        </div>
        <div class="drillDownChoice">
          <div
            :class="['choice', { selected: drillDownRadioValue == d[0] }]"
            v-for="d in drillDownRadioValues"
            :key="d[0]"
            @click="drillDownRadioValue = d[0]">
            {{ d[1] }}
            <div v-if="drillDownRadioValue == d[0]">
              <use-svg svg="bb-selected-icon" />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="add_column">
          <button
            @click="addColumn"
            :disabled="columns.length == 6">
            + {{ $t('addColumn') }}
          </button>
        </div>
        <hr />
        <div
          class="column"
          v-for="(column, i) in columns"
          :key="i">
          <div @click="toggleColumn(i)">
            <strong>{{ $t('column') }} {{ i + 1 }}</strong>
            <span style="float: right">
              <use-svg
                svg="arrow-right"
                :class="{ 'svg--180-negative': showColumn[i] }" />
            </span>
            <span
              @click="removeColumn(i)"
              style="float: right">
              <info-icon :description="$t('delete')">
                <template #icon>
                  <use-svg svg="bb-trash-icon" />
                </template>
              </info-icon>
            </span>
          </div>
          <div v-show="showColumn[i]">
            <div class="radio__row">
              <div class="period_grid">
                <span class="column_section">{{ $t('addColumn') }}</span>
                <div class="period_input">
                  <custom-select
                    @optionSelected="e => selectYear(i, e)"
                    :selected="
                      financialYears.map(y => y.value).indexOf(column.yearValue)
                    "
                    :options="financialYears">
                  </custom-select>
                </div>
                <template v-if="column.selectedYear">
                  <span class="column_section">{{ $t('type') }}</span>
                  <div class="period_input">
                    <custom-select
                      @optionSelected="e => changeType(i, e)"
                      :selected="
                        columnType(column)
                          .map(y => y.value)
                          .indexOf(column.type)
                      "
                      :options="columnType(column)">
                    </custom-select>
                  </div>
                  <span class="column_section">{{ $t('period') }}</span>
                  <div class="period_input">
                    <custom-select
                      @optionSelected="e => changePeriod(i, e)"
                      :selected="
                        columnPeriod(column)
                          .map(y => y.value)
                          .indexOf(column.period)
                      "
                      :options="columnPeriod(column)">
                    </custom-select>
                  </div>

                  <div
                    class="period_grid2"
                    v-if="column.period === 'not_fiscal'">
                    <span class="column_section">{{
                      $t('chooseStartEndMonth')
                    }}</span>
                    <div class="selectMonth">
                      <div
                        v-for="i in 12"
                        :key="i"
                        :class="{
                          selected:
                            column.startMonth == i || column.endMonth == i,
                          highlighted:
                            i > column.startMonth && i < column.endMonth,
                        }"
                        @click="selectMonth(column, i)">
                        {{ months[i] }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="modal__content--actions">
        <button @click="changeFilter">{{ $t('showTemporarily') }}</button>
        <info-icon :description="$t('showTemporarilyInfo')">
          <template #icon>
            <use-svg svg="bb-info-icon" />
          </template>
        </info-icon>
        <button
          class="btn_primary"
          v-if="showSaveAndPublish"
          @click="saveFilter"
          :disabled="!canSave">
          {{ $t('saveAndPublish') }}
        </button>
        <br />
        <button @click="toggleModal">{{ $t('cancel') }}</button>
      </div>
    </div>
    <base-button
      backgroundColor="bb-primary"
      :text="$t('tableSettings')"
      @click="showModal = !showModal">
      <use-svg svg="bb-gear-icon" />
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select-bb';
import UseSvg from '@/components/use-svg';
import InfoIcon from '@/components/businessboard/tooltip-icon';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'Modal',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    CustomSelect,
    UseSvg,
    InfoIcon,
  },
  props: {
    companyId: {
      type: String,
      default: '0',
    },
    financialYears: {
      type: Array,
      default: () => [],
    },
    drillDownRadioValues: {
      type: Array,
      default: () => [],
    },
    storedFilter: {
      type: Object,
    },
  },
  data() {
    return {
      showModal: false,
      defaultFilter: true,
      financialYearFilter: false,
      periodFilter: false,
      typesSelect: {},
      showColumn: [true, false, false, false, false, false],
      columns: [
        {
          selectedYear: false,
          type: false,
          budget: {},
          period: false,
          startMonth: 0,
          endMonth: 0,
        },
      ],
      drillDownRadioValue: this.drillDownRadioValues[0][0],
      showDrillDownInfo: false,
    };
  },
  computed: {
    settingsmonths() {
      return this.$store.getters['businessboard/settingsmonths'];
    },
    companyBudgetSelect() {
      return this.$store.getters['businessboardBudget/companyBudgetSelect'];
    },
    companyForecastSelect() {
      return this.$store.getters['businessboardBudget/companyForecastSelect'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    months() {
      return [
        '',
        this.$t('Months.January'),
        this.$t('Months.February'),
        this.$t('Months.March'),
        this.$t('Months.April'),
        this.$t('Months.May'),
        this.$t('Months.June'),
        this.$t('Months.July'),
        this.$t('Months.August'),
        this.$t('Months.September'),
        this.$t('Months.October'),
        this.$t('Months.November'),
        this.$t('Months.December'),
      ];
    },
    filter() {
      return {
        drillDown: this.drillDownRadioValue,
        columns: this.columns.map(c => ({
          year: c.yearValue,
          type: {
            budget: 2,
            not_budget: 1,
            forecast: 3,
            established_budget: 2,
          }[c.type],
          period: { fiscal: 1, not_fiscal: 2, accumulated: 3 }[c.period],
          fromMonth: c.startMonth,
          toMonth: c.endMonth,
        })),
      };
    },
    canSave() {
      return true;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    showSaveAndPublish() {
      return !(
        this.user.roles.BusinessBoard &&
        this.user.roles.BusinessBoard.some(
          e => e.value === 'BusinessBoardBoardMember'
        )
      );
    },
  },
  mounted() {
    this.$store.dispatch('businessboard/fetchSettingsMonths');
  },
  watch: {
    storedFilter: function (newVal, oldVal) {
      let filter = newVal;
      if (this.drillDownRadioValues.map(v => v[0]).includes(filter.drillDown)) {
        this.drillDownRadioValue = filter.drillDown;
      } else {
        this.drillDownRadioValue = this.drillDownRadioValues[0][0];
      }
      if (filter && Object.keys(filter).length !== 0) {
        this.columns = [];
        for (let column of filter.columns) {
          if (column.fromMonth > 0 && column.toMonth === 0) {
            // column.toMonth = column.fromMonth
          }
          this.columns.push({
            yearValue: column.year,
            selectedYear: new Date(column.year.split(';')[1]).getFullYear(),
            type: ['', 'not_budget', 'budget', 'forecast'][column.type],
            period: ['', 'fiscal', 'not_fiscal', 'accumulated'][column.period],
            startMonth: column.fromMonth,
            endMonth: column.toMonth,
          });
        }
      }
    },
  },
  methods: {
    budgetOptions(companyValue, yearValue) {
      if (!this.companyBudgetSelect[companyValue]) return [];
      let year = this.financialYears.find(y => y.value === yearValue);
      if (year)
        return this.companyBudgetSelect[companyValue].filter(b =>
          b.displayName.endsWith(', ' + year.displayName)
        );
      return this.companyBudgetSelect[companyValue];
    },
    forecastOptions(companyValue, yearValue) {
      if (!this.companyForecastSelect[companyValue]) return [];
      let year = this.financialYears.find(y => y.value === yearValue);
      if (year)
        return this.companyForecastSelect[companyValue].filter(b =>
          b.displayName.endsWith(', ' + year.displayName)
        );
      return this.companyForecastSelect[companyValue];
    },
    addColumn() {
      if (this.columns.length >= 6) return;
      this.columns.push({
        selectedYear: false,
        yearValue: '',
        type: false,
        budget: {},
        period: false,
        startMonth: 0,
        endMonth: 0,
      });
    },
    getDefaultButtonText() {
      return this.$t('tableSettings');
    },
    removeColumn(i) {
      this.columns.splice(i, 1);
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    selectYear(i, e) {
      this.columns[i].yearValue = e.value;
      this.columns[i].selectedYear = new Date(
        e.value.split(';')[1]
      ).getFullYear();
    },
    selectBudget(i, c, e) {
      this.columns[i].budget[c.value] = e.value;
    },
    selectStartMonth(i, e) {
      this.columns[i].startMonth = e.value;
    },
    selectEndMonth(i, e) {
      this.columns[i].endMonth = e.value;
    },
    changeFilter() {
      this.toggleModal();
      this.$emit('changeFilter', this.filter);
    },
    saveFilter() {
      this.toggleModal();
      this.$emit('saveFilter', this.filter);
    },
    columnType(column) {
      let l = [];
      if (column.selectedYear <= this.currentYear) {
        l.push({ value: 'not_budget', displayName: this.$t('outcome') });
      }
      l.push({ value: 'budget', displayName: this.$t('Businessboard.budget') });
      if (column.selectedYear >= this.currentYear) {
        l.push({ value: 'forecast', displayName: this.$t('forecast') });
      }
      return l;
    },
    changeType(i, e) {
      this.columns[i].type = e.value;
      if (e.value === 'forecast') {
        this.columns[i].budget = {};
        this.companies.forEach(element => {
          let op = this.forecastOptions(
            element.value,
            this.columns[i].yearValue
          );
          if (op.length > 0) {
            this.columns[i].budget[element.value] = op[0].value;
          }
        });
      } else if (e.value === 'established_budget') {
        this.columns[i].budget = {};
        this.companies.forEach(element => {
          let op = this.budgetOptions(
            element.value,
            this.columns[i].yearValue
          ).find(el => el.status === 'Established');
          if (op) {
            this.columns[i].budget[element.value] = op[0].value;
          }
        });
      }
    },
    columnPeriod(column) {
      return [
        { value: 'accumulated', displayName: this.$t('accumulated') },
        { value: 'fiscal', displayName: this.$t('fiscalYear') },
        { value: 'not_fiscal', displayName: this.$t('notFiscalYear') },
      ];
    },
    changePeriod(i, e) {
      this.columns[i].period = e.value;
    },
    selectMonth(c, i) {
      if (c.endMonth === i) {
        c.endMonth = 0;
      } else if (c.startMonth === i && c.endMonth === 0) {
        c.startMonth = 0;
      } else if (c.startMonth > 0 && c.endMonth > 0) {
        c.startMonth = i;
        c.endMonth = 0;
      } else {
        if (c.startMonth === 0 || i < c.startMonth) {
          c.startMonth = i;
        } else {
          c.endMonth = i;
        }
      }
    },
    toggleColumn(i) {
      this.$set(this.showColumn, i, !this.showColumn[i]);
    },
  },
};
</script>
<style lang="scss" scoped>
.period_grid {
  margin-top: 0.5rem;
}
.period_grid2 {
  margin-top: 0.5rem;
}
.period_input {
  padding: 0 0.5rem 0 0.5rem;
  input,
  select {
    background-color: #f9f9f9;
    border-radius: 2px;
    padding: 0.3rem;
    border: 1px solid #d8d8d8;
  }
}
.radio__row {
  padding: 0.5rem;
  margin: 0.5rem auto 0.5rem auto;
  text-align: left;
}
.columns {
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: calc(100vh - 21.5rem);
}
.columns:hover {
  overflow-y: scroll;
  max-height: calc(100vh - 21.5rem);
}
.column {
  .column_section {
    display: inline-block;
    color: #555f61;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }
  strong {
    line-height: 40px;
  }
  span {
    margin: 0.5rem;
  }
}
.add_column {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}
input[type='radio'] {
  margin: 1rem;
}
.chart_settings_modal {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 8px;
  .header {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: auto 3rem;
    span {
      color: #0e3746;
    }
  }
  button {
    padding: 0.5rem;
    border: 1px solid #0e3746;
    border-radius: 3px;
    background: white;
    margin: 0.5rem;
  }
  .btn_primary {
    background-color: #0e3746;
    color: white;
    float: right;
  }
  .btn_reticent {
    border: 0;
  }
}
.drillDownChoice {
  display: grid;
  grid-auto-flow: column;
  background: #eaf6f7;
  border-radius: 3px;
  font-weight: 400;
  padding: 4px;
  .choice {
    margin: auto 0;
    text-align: center;
    border: 1px solid #eaf6f7;
    padding: 4px;
    border-radius: 3px;
  }
  .selected {
    background: #abded1;
    border-color: #0e3746;
    font-weight: 500;
    div {
      display: inline-block;
      margin: auto;
      vertical-align: middle;
    }
  }
}
.selectMonth {
  display: grid;
  grid-template-columns: auto auto auto;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px;
  div {
    padding: 4px;
    font-size: 14px;
    line-height: 23px;
    color: #656565;
    text-align: center;
    border-radius: 16px;
    margin: 3px;
    cursor: pointer;
  }
  .selected {
    background-color: #abded1;
    font-weight: bold;
  }
  .highlighted {
    background-color: #eaf6f7;
  }
}
</style>
