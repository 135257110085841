<template>
  <div
    class="multi-select"
    :id="id"
    :class="{ 'is-open': isOpen }">
    <button
      @click="toggleSelect()"
      @keydown="triggerKeyPress($event)"
      ref="trigger"
      class="multi-select__trigger"
      aria-haspopup="listbox"
      aria-expanded="false">
      <div class="multi-select__trigger--icon">
        <span class="multi-select--label">{{ label }}</span>
        <div
          v-for="(option, index) in optionsInternal"
          :key="index"
          class="svg--container">
          <use-svg
            stroke="black"
            svg="filter-icon"
            :class="[option.checked ? 'svg--checked' : 'svg--unchecked']" />
        </div>
      </div>
    </button>
    <div
      class="multi-select-list__wrapper"
      :class="{ 'is-active': selectedIndex === 0 }">
      <ul
        class="multi-select__list"
        @keydown="listKeyPress($event)"
        :aria-activedescendant="id + '-option-' + selectedIndex"
        ref="list"
        role="listbox"
        tabindex="-1">
        <li
          class="multi-select__option"
          v-for="(option, index) in optionsInternal"
          :class="{ 'is-active': index === selectedIndex }"
          :key="index"
          :aria-selected="option.checked ? 'true' : 'false'"
          :aria-labelledby="id + '-label-' + index"
          :id="id + '-option-' + index"
          role="option">
          <div class="input checkbox">
            <div class="checkbox--group">
              <input
                @click="selectItem(index, option.value)"
                type="checkbox"
                :id="id + '-checkbox-' + index"
                :value="option.value"
                :checked="option.checked ? true : false" />
              <label
                :class="{ 'is-active': option.checked }"
                :id="id + '-label-' + index">
                <span><span></span></span>
                {{ option.displayName }}
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import clickOutside from '@/mixins/click-outside';

export default {
  name: 'MultiSelect',
  components: {
    UseSvg,
  },
  mixins: [clickOutside],
  props: {
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
    },
    iconName: {
      type: String,
    },
    useIcon: {
      type: Boolean,
    },
  },
  data() {
    return {
      isOpen: false,
      readyToOpen: true,
      selectedIndex: 0,
      scrollPos: 0,
      optionsInternal: [],
    };
  },
  watch: {
    options(newOptions) {
      this.optionsInternal = newOptions;
    },
  },
  computed: {
    maxIndex() {
      return this.options.length - 1;
    },
  },
  methods: {
    filterIconStyle() {
      if (this.options.checked) {
        return 'svg1';
      } else {
        return 'svg2';
      }
    },
    /* Handle select list toggle */
    toggleSelect() {
      if (this.isOpen) {
        this.isOpen = false;
        this.$refs.trigger.setAttribute('aria-expanded', 'false');
        this.$nextTick(() => {
          this.$refs.trigger.focus();
        });
        this.readyToOpen = false;
        setTimeout(() => {
          this.readyToOpen = true;
        }, 200);
      } else if (!this.isOpen) {
        if (this.readyToOpen) {
          this.isOpen = true;
          this.$refs.trigger.setAttribute('aria-expanded', 'true');
          this.$nextTick(() => {
            this.$refs.list.focus();
          });
        }
      }
    },
    /* Handle option click */
    selectItem(index) {
      let option = this.optionsInternal[index];
      /* Check or uncheck */
      if (option.checked) {
        option.checked = false;
      } else {
        option.checked = true;
      }
      this.$emit('optionSelected', {
        id: this.id,
        options: this.optionsInternal,
      });
    },
    /**
     * Handle trigger keypress
     */
    triggerKeyPress(event) {
      switch (event.keyCode) {
        /* Arrow down, Escape, Space */
        case 40:
        case 27:
        case 32:
          event.preventDefault();
          this.toggleSelect();
          break;
        default:
          break;
      }
    },
    /**
     * Handle list keypress
     */
    listKeyPress(event) {
      switch (event.keyCode) {
        case 40 /* Arrow down */:
          event.preventDefault();
          if (this.selectedIndex !== this.maxIndex) {
            this.selectedIndex = this.selectedIndex + 1;
            this.scroll('down');
          }
          break;
        case 38 /* Arrow up */:
          event.preventDefault();
          if (this.selectedIndex !== 0) {
            this.selectedIndex = this.selectedIndex - 1;
            this.scroll('up');
          }
          break;
        case 32 /* Space */:
          event.preventDefault();
          this.selectItem(this.selectedIndex);
          break;
        case 27: /* Escape */
        case 13 /* Enter */:
          this.toggleSelect();
          break;
        default:
          break;
      }
    },
    scroll(direction) {
      if (direction === 'up') {
        this.$refs.list.scrollTop -= 33;
      } else {
        this.$refs.list.scrollTop += 33;
      }
      this.scrollPos = this.$refs.list.offsetTop;
    },
  },
  created() {
    this.$on('click-outside', function () {
      if (this.isOpen) {
        this.toggleSelect();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.multi-select {
  display: inline-flex;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;

  &.is-open {
    z-index: 10;
    .multi-select-list__wrapper {
      display: block;
      z-index: 1;
    }
  }

  .multi-select__trigger {
    border: none;
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;

    .multi-select__trigger--icon {
      display: flex;
      align-items: center;

      .svg--container {
        position: relative;
      }
      .svg--unchecked {
        color: black;
        position: absolute;
        top: -5px;
        left: 0;
      }
      .svg--checked {
        color: color(active-color);
        position: absolute;
        top: -5px;
        left: 0;
        z-index: 5;
      }

      .multi-select--label {
        font-weight: bold;
        margin-right: 0.325rem;
      }
    }
  }
  .multi-select-list__wrapper {
    display: none;
    position: relative;
    border: 0.0625rem solid color(gray);
    background-color: color(gray-lighter);
    position: absolute;
    box-shadow: 0.125rem 0.125rem 0.4375rem 0.125rem rgba(0, 0, 0, 0.1);

    &:before {
      content: '';
      position: absolute;
      left: 0.8125rem;
      top: -6px;
      z-index: 1;
      border: 0.0625rem solid color(gray);
      border-width: 0 0 0.0625rem 0.0625rem;
      width: 0.625rem;
      height: 0.625rem;
      transform: rotate(135deg);
      background-color: color(gray-lighter);
    }
    &.is-active {
      &:before {
        background-color: #ecf3f8;
      }
    }

    .multi-select__label {
      display: block;
      padding: 0.625rem 0.9375rem 0 0.9375rem;
      font-weight: 600;
    }

    .multi-select__list {
      max-height: 25rem;
      overflow-y: auto;
      margin: 0;
      padding: 0;
      list-style: none;
      left: -1px;
      outline: none;
      background-color: color(gray-lighter);
      scrollbar-width: thin; /* [1] */
      .multi-select__option {
        padding: 0.625rem 0.625rem 0.625rem 0.25rem;
        border-top: 0.0625rem solid color(gray);

        &:hover,
        &.is-active {
          background-color: #ecf3f8;
        }
        &:first-child {
          border-top: none;
        }
      }
    }
  }
}
</style>
