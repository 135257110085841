<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <h1>{{ $t('Businessboard.resultUnits') }}</h1>
      <p></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ResultUnitLandingPage',
};
</script>
<style lang="scss" scoped>
  .landing-page-image {
    display: flex;
    width: 100%;
  }
  .h1 {
    display: inline-block;
    font-size: font(h1);
    font-weight: bold;
    vertical-align: top;
  }
  .businessboard {
    flex-grow: 1;
    width: 100%;
    @include media('small') {
      width: calc((100% / 2) - (1rem * 1));
    }
    @include media('large') {
      width: calc((100% / 3) - (1rem * 2));
    }
  }
  .businessboard--content {
    background-color: color(white);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
  }
</style>
