<template>
  <div class="ontag">
    <div class="order-confirmation">
      <div class="order-confirmation__top">
        <div class="order-confirmation__top-left">
          <router-link :to="{ path: '/ontag/orderscorecard/order' }">
            &lt; {{ $t('backToStartPage') }}
          </router-link>
          <span
            v-if="isLoading"
            class="element--is-loading"></span>
        </div>
        <div class="order-confirmation__step-container">
          <div class="order-confirmation__step order-confirmation__step--done">
            <img
              src="../../assets/images/check-mark.svg"
              alt="" />
            <span>{{ $t('yourOrder') }}</span>
          </div>
          <div class="order-confirmation__step order-confirmation__step--done">
            <img
              src="../../assets/images/check-mark.svg"
              alt="" />
            <span>{{ $t('yourInfo') }}</span>
          </div>
          <div
            class="order-confirmation__step order-confirmation__step--active">
            <strong>3</strong> {{ $t('confirmation') }}
          </div>
        </div>
      </div>

      <div
        v-if="!isLoading"
        class="order-confirmation__main-container">
        <h1>
          {{ $t('handlingOrder') }}
        </h1>
        <div>
          <p>{{ $t('mailCopy') }}</p>
          <p
            v-if="
              order.comment !== '' &&
              order.scorecardType !== 3 &&
              order.scorecardType !== 4
            ">
            {{ $t('commentManualInfo') }}<br />
            {{ $t('onConfirmInfo') }}
          </p>
          <p v-else-if="order.scorecardType === 3 || order.scorecardType === 4">
            {{ $t('processingOrder') }}
          </p>
        </div>
        <div class="order-confirmation__order-info-container">
          <div class="order-confirmation__order-info-row">
            <span style="text-transformation: uppercase">{{
              $t('orderNumber')
            }}</span>
            <span>
              <strong>{{ order.orderId }}</strong>
            </span>
          </div>
          <div class="order-confirmation__order-info-row">
            <span style="text-transformation: uppercase">{{
              $t('orderDone')
            }}</span>
            <span>{{ order.orderDateText }}</span>
          </div>
          <div class="order-confirmation__order-info-row">
            <span style="text-transformation: uppercase">{{
              $t('delivery')
            }}</span>
            <div>
              <div
                v-if="order.scorecardType !== 3 && order.scorecardType !== 4">
                <span v-if="deliveryWeek === 0">
                  {{ $t('sentBy') }} 5 {{ $t('weekdays') }}
                </span>
                <span v-else>{{ $t('sendWeek') }} {{ deliveryWeek }}</span>
              </div>
              <div v-else>
                <span>{{ $t('deliveryInWeek') }} {{ deliveryWeek }}</span>
              </div>
              <br />
              <span class="order-confirmation__font-gray">
                {{ $t('signingInformation') }}
              </span>
            </div>
          </div>
          <div
            v-if="hasComment"
            class="order-confirmation__order-info-row">
            <span style="text-transformation: uppercase">{{
              $t('deliveryComment')
            }}</span>
            <div>
              <span>
                {{ comment }}
              </span>
              <br />
              <p class="order-confirmation__font-gray">
                {{ $t('deliveryCommentInformation') }}
              </p>
            </div>
          </div>
        </div>
        <div class="order-confirmation__order-details">
          <table>
            <thead>
              <tr>
                <th></th>
                <th style="text-transform: uppercase">{{ $t('item') }}</th>
                <th style="text-transform: uppercase">{{ $t('amount') }}</th>
                <th style="text-transform: uppercase">
                  {{ $t('priceSlashAmount') }}
                </th>
                <th style="text-transform: uppercase">{{ $t('sum') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="order.scorecardType === 1">
                <td>
                  <div class="order-confirmation__scorecard-img">
                    <img
                      src="../../assets/images/sc-3p-2023-3.png"
                      alt="3 delat scorekort" />
                  </div>
                </td>
                <td>
                  <strong>{{ $t('standardCard') }}</strong>
                  <br />
                  {{ $t('threeParted') }}<br />
                  110 x 273 mm
                </td>
                <td>
                  {{ (order.quantity * 1000).toLocaleString() }} {{ $t('piece')
                  }}<br />
                  / {{ order.quantity }} st
                  <span v-if="order.quantity > 1">{{ $t('boxes') }}</span>
                  <span v-else>{{ $t('box') }}</span>
                </td>
                <td>{{ order.unitPrice }} {{ $t('crownsSlashAmount') }}</td>
                <td>
                  {{
                    (order.unitPrice * (order.quantity * 1000)).toLocaleString()
                  }}
                  kr
                </td>
              </tr>
              <tr v-else-if="order.scorecardType === 2">
                <td>
                  <div class="order-confirmation__scorecard-img">
                    <img
                      src="../../assets/images/sc-4p-2023-3.png"
                      alt="4 delat scorekort" />
                  </div>
                </td>
                <td>
                  <strong>{{ $t('standardCard') }}</strong>
                  <br /><br />
                  {{ $t('fourParted') }} <br />
                  110 x 362 mm
                </td>
                <td>
                  {{ (order.quantity * 1000).toLocaleString() }} {{ $t('piece')
                  }}<br />
                  / {{ order.quantity }} st
                  <span v-if="order.quantity > 1">{{ $t('boxes') }}</span>
                  <span v-else>{{ $t('box') }}</span>
                </td>
                <td>{{ order.unitPrice }} {{ $t('crownsSlashAmount') }}</td>
                <td>
                  {{
                    (order.unitPrice * (order.quantity * 1000)).toLocaleString()
                  }}
                  kr
                </td>
              </tr>
              <tr v-else-if="order.scorecardType === 3">
                <td>
                  <img
                    class="order-confirmation__scorecard-img"
                    :src="scorecardorder.scorecardProofUrl"
                    alt="" />
                </td>
                <td>
                  <strong>{{ $t('yourDesign') }}</strong>
                  <br /><br />
                  {{ $t('threeParted') }} <br />
                  110 x 273 mm<br />
                  {{ $t('inside') }}: {{ $t('mat') }}, {{ $t('white') }}<br />
                  {{ $t('outside') }}:
                  <span v-if="order.surface === 1">{{
                    $t('mat') + ' ' + $t('scorecardSurface')
                  }}</span>
                  <span v-else-if="order.surface === 2">{{
                    $t('glossy') + ' ' + $t('scorecardSurface')
                  }}</span>
                </td>
                <td>
                  {{ (order.quantity * 1000).toLocaleString() }} {{ $t('piece')
                  }}<br />
                  / {{ order.quantity }} {{ $t('piece') }}
                  <span v-if="order.quantity > 1">{{ $t('boxes') }}</span>
                  <span v-else>{{ $t('box') }}</span>
                </td>
                <td>{{ order.unitPrice }} {{ $t('crownsSlashAmount') }}</td>
                <td>
                  {{
                    (order.unitPrice * (order.quantity * 1000)).toLocaleString()
                  }}
                  kr
                </td>
              </tr>
              <tr v-else-if="order.scorecardType === 4">
                <td>
                  <img
                    class="order-confirmation__scorecard-img"
                    :src="scorecardorder.scorecardProofUrl"
                    alt="" />
                </td>
                <td>
                  <strong>{{ $t('yourDesign') }}</strong>
                  <br /><br />
                  {{ $t('fourParted') }} <br />
                  110 x 362 mm<br />
                  {{ $t('inside') }}: {{ $t('mat') }}, {{ $t('white') }}<br />
                  {{ $t('outside') }}:
                  <span v-if="order.surface === 1">{{
                    $t('mat') + ' ' + $t('scorecardSurface')
                  }}</span>
                  <span v-else-if="order.surface === 2">{{
                    $t('glossy') + ' ' + $t('scorecardSurface')
                  }}</span>
                </td>
                <td>
                  {{ (order.quantity * 1000).toLocaleString() }} {{ $t('piece')
                  }}<br />
                  / {{ order.quantity }} {{ $t('piece') }}
                  <span v-if="order.quantity > 1">{{ $t('boxes') }}</span>
                  <span v-else>{{ $t('box') }}</span>
                </td>
                <td>{{ order.unitPrice }} {{ $t('crownsSlashAmount') }}</td>
                <td>
                  {{
                    (order.unitPrice * (order.quantity * 1000)).toLocaleString()
                  }}
                  kr
                </td>
              </tr>
            </tbody>
          </table>
          <div class="order-confirmation__order-sum">
            <div class="order-confirmation__order-sum-row">
              <span>
                {{ $t('shipping') }}
              </span>
              <span>
                {{ order.customerShippingPrice.toLocaleString() }} kr
              </span>
            </div>
            <div class="order-confirmation__order-sum-row">
              <span>
                <strong>{{ $t('sum') }}</strong> {{ $t('vatExcluded') }}
              </span>
              <span>
                <strong>{{ order.totalPrice.toLocaleString() }} kr</strong>
              </span>
            </div>
          </div>
          <div class="order-confirmation__delivery-info-container">
            <div class="order-confirmation__info-column">
              <h4>{{ $t('deliveryAddress') }}</h4>
              <span>{{ order.deliveryCompanyName }}</span>
              <span>{{ order.deliveryPostalAddress }}</span>
              <span>{{
                order.deliveryPostalCode
                  .replace(/\W/g, '')
                  .replace(/(...)/, '$1 ')
              }}</span>
              <span>{{ order.deliveryCity }}</span>
            </div>
            <div class="order-confirmation__info-column">
              <h4>{{ $t('yourContactInfo') }}</h4>
              <span>{{ order.contactPerson }}</span>
              <span>{{ order.phoneNumber }}</span>
              <span>{{ order.email }}</span>
            </div>
            <div class="order-confirmation__info-column">
              <h4>{{ $t('invoiceAddress') }}</h4>
              <span>{{ order.invoiceCompanyName }}</span>
              <span>{{ order.orgNumber }}</span>
              <span>{{ order.invoicePostalAddress }}</span>
              <span>{{
                order.invoicePostalCode
                  .replace(/\W/g, '')
                  .replace(/(...)/, '$1 ')
              }}</span>
              <span>{{ order.invoiceCity }}</span>
              <span>{{ order.invoiceEmail }}</span>
            </div>
          </div>
        </div>
        <div class="order-confirmation__background-wave">
          <img
            src="../../assets/images/wave-background.svg"
            alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalizationMixin from '../../mixins/localization-mixin.vue';
export default {
  name: 'ScorecardOrderConfirmation',
  mixins: [LocalizationMixin],
  components: {},

  data() {
    return {
      order: {
        comment: '',
        orderId: '',
        orderDateText: '',
        scorecardType: 0,
        quantity: 0,
        unitPrice: 0,
        customerShippingPrice: 0,
        totalPrice: 0,
        deliveryCompanyName: '',
        deliveryPostalAddress: '',
        deliveryPostalCode: '',
        deliveryCity: '',
        contactPerson: '',
        phoneNumber: '',
        email: '',
        invoiceCompanyName: '',
        orgNumber: '',
        invoicePostalAddress: '',
        invoicePostalCode: '',
        invoiceCity: '',
        invoiceEmail: '',
        surface: 0,
      },
      customerShippingCost: 0,
      totalCost: 0,
      isLoading: true,
      hasComment: false,
      comment: '',
      deliveryWeek: 0,
    };
  },
  methods: {
    setCosts() {
      this.customerShippingCost = this.scorecardorder.customerShippingCost;
      this.totalCost = this.order.totalPrice;
    },
  },
  computed: {
    scorecardallorders() {
      return this.$store.getters['scorecardOrder/scorecardallorders'];
    },
    scorecardorder() {
      return this.$store.getters['scorecardOrder/scorecardorder'];
    },
  },

  mounted() {
    this.setCosts();
    this.$store.dispatch('scorecardOrder/fetchScorecardOrder').then(() => {
      this.$store
        .dispatch('scorecardOrder/fetchScorecardAllOrders', {
          id: this.$route.params.id,
        })
        .then(() => {
          let orderId = parseInt(
            this.$store.state.scorecardOrder.scorecardorderid
          );
          this.scorecardorder.orders.forEach(order => {
            if (order.orderId === orderId) {
              this.order = order;
            }
            this.scorecardallorders.rows.forEach(order => {
              if (order.orderId === orderId) {
                this.deliveryWeek = order.deliveryWeek;
                if (order.comment !== '') {
                  this.hasComment = true;
                  this.comment = order.comment;
                }
              }
            });
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    });
  },
};
</script>

<style lang="scss" scoped>
.element--is-loading {
  margin-top: 0px;
}
.content {
  margin: 4.375rem 0 1rem 0;
  @include media('small') {
    margin: 3.3rem 0rem 1rem 15.7rem;
  }
}

.order-confirmation {
  padding: measure(spacing-xxl) measure(spacing-xl);

  &__top {
    display: flex;
    justify-content: space-between;
  }
  &__top-left {
    display: flex;
    align-items: center;
  }
  &__step-container {
    width: 40%;
    display: flex;
    justify-content: space-between;
  }

  &__step {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    width: 32%;
    text-align: center;
    padding: measure(spacing-xs);
    border-radius: 2px;

    img {
      margin-right: 5px;
    }

    :first-child {
      border: none;
    }
  }
  &__step--active {
    border: 1px solid black;
  }

  &__step--done {
    :nth-child(2) {
      opacity: 0.5;
    }
  }

  &__scorecard-img {
    max-width: 250px;
    min-width: 150px;

    img {
      width: 100%;
    }
  }

  &__main-container {
    background-color: white;
    padding: 64px 54px;
    margin-top: measure(spacing-l);
    border-radius: 3px;
  }

  &__order-info-container {
    margin-top: measure(spacing-xxl);
    font-size: 16px;
  }

  &__order-info-row {
    display: flex;
    margin: measure(spacing-m) 0px;
    span:first-child {
      min-width: 25%;
      padding-right: measure(spacing-m);
    }
  }

  &__comment-warning {
    background-color: color(info-background);
    border: 1px solid color(soluppgang);
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: measure(spacing-s) measure(spacing-m);
    margin-bottom: measure(spacing-s);
    .info-svg {
      margin-right: measure(spacing-xs);
      background-color: color(soluppgang);
      height: 28px;
      width: 28px;
      mask: url(../../assets/images/info.svg) no-repeat center / contain;
      -webkit-mask: url(../../assets/images/info.svg) no-repeat center / contain;
    }

    p {
      margin: 0 0 0 measure(spacing-s);
    }
  }

  &__order-details {
    background-color: white;
    padding: 16px;

    table {
      border-collapse: collapse;
      width: 100%;
      thead {
        background-color: color(dis);
        text-align: left;
        tr {
          :first-child {
            border-radius: 3px 0 0 3px;
          }
          :last-child {
            text-align: right;
          }
          :last-child {
            border-radius: 0 3px 3px 0;
          }
          th {
            padding: 10px 14px;
            font-weight: unset;
          }
        }
      }
      tbody {
        tr {
          :last-child {
            text-align: right;
          }
          td {
            padding: 40px 16px;
          }
        }
      }
    }
  }

  &__order-sum {
    margin: 40px 16px;
    border-top: 1px solid color(tussilago);
    border-bottom: 1px solid color(tussilago);
  }

  &__order-sum-row {
    padding: measure(spacing-m);
    display: flex;
    justify-content: flex-end;

    :nth-child(2) {
      width: 15%;
      text-align: right;
      margin-left: measure(spacing-l);
    }
  }

  &__delivery-info-container {
    display: flex;
    justify-content: space-between;
    padding: measure(spacing-m);
  }

  &__info-column {
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: measure(spacing-s);
    }
  }
  &__background-wave {
    width: calc(100% + 140px);
    margin: 0px 0px -80px -60px;
    max-height: 200px;
    img {
      margin-bottom: -20px;
      width: 100%;
    }
  }

  &__font-gray {
    color: color(middleGray);
    font-size: 14px;
  }
}
</style>
