/* Store module to handle Courses */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  courses: {
    rows: [],
    columns: [],
  },
  course: {},
  courseTees: [],
  courseSelect: [],
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
};

const mutations = {
  courses (state, payload) {
    state.courses.rows = payload.rows;
    state.courses.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
  course (state, payload) {
    state.course = payload;
  },
  editCourse (state, payload) {
    state.courses = [
      ...state.courses.rows.filter(course => course.id !== payload.id), payload,
    ];
  },
  courseSelect (state, payload) {
    state.courseSelect = payload;
  },
  courseTees (state, payload) {
    state.courseTees = payload;
  },
  courseDistancemarkings (state, payload) {
    state.courseDistancemarkings = payload;
  },

};

const getters = {
  courses: state => {
    return state.courses;
  },
  course: state => {
    return state.course;
  },
  courseSelect: state => {
    return state.courseSelect;
  },
  courseTees: state => {
    return state.courseTees;
  },
  courseDistancemarkings: state => {
    return state.courseDistancemarkings;
  },
  pagination: (state) => {
    return state.pagination;
  },
  currentPage: (state) => {
    return state.pagination.currentPage;
  },
  nextPage: (state) => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: (state) => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
};

const actions = {
  fetchCourses: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'courses-fetch-courses', { root: true });
    let path = 'courses';

    if(params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params).map(k => {
        if(params[k] !== undefined) {
          return esc(k) + '=' + esc(params[k]);
        }
      }).filter(q => q !== undefined).join('&');
      path += '?' + query;
    }
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'courses-fetch-courses', { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('courses', data.result);
    }
  },
  fetchCourse: async (context, params) => {
    // const currentCourseId = context.getters['course'].id
    // if (currentCourseId === params.id) {
    //   return
    // }
    context.commit('course', {});
    const path = 'courses/' + params.id;
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('course', data.result);
    }
  },
  fetchCourseSelect: async (context, params) => {
    const path = 'courses/select';
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('courseSelect', data.result);
    }
  },
  fetchCourseTees: async (context, params) => {
    const path = `courses/${params.courseId}/tees`;
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('courseTees', data.result);
    }
  },
  fetchCourseDistancemarkings: async (context, params) => {
    const path = `courses/${params.courseId}/distancemarkings`;
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('courseDistancemarkings', data.result);
    }
  },
  patchDistancemarking: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'courses-save-distancemarking', { root: true });
    const path = 'courses/' + params.courseId + '/' + params.hole.id + '/distancemarking';

    let response = {};
    try {
      response = await Vue.axios.patch(path, params.hole);
      EventBus.$emit('toast', { type: 'success', 'msg': 'Avståndsmarkering har sparats', 'request': path });
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'courses-save-distancemarking', { root: true });
    if(response && response.status === 200) {
      // const data = response.data
      // context.commit('courseDistancemarkings', data.result)
    }
  },
  useDistancemarking: async (context, params) => {
    const path = 'courses/' + params.id + '/usedistancemarking';

    try {
      await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
  },
  editCourse: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'courses-save-course', { root: true });
    const path = 'courses/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'courses-save-course', { root: true });
    if(response && response.status === 200) {
      const data = response.data;
      context.commit('editCourse', data.result);
    }
  },
  refreshCourse: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'courses-save-course', { root: true });
    const path = 'courses/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'courses-save-course', { root: true });
    if(response && response.status === 200) {
      return true;
    }

    return false;
  },
  refreshCourses: async (context, params) => {
    let path = 'courses/refresh';
    context.dispatch('ui/toggleActiveLoaders', 'courses-refresh-courses', { root: true });
    let response = {};

    try {
      response = await Vue.axios.post(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'courses-refresh-courses', { root: true });

    if (response && response.data) {
      if (response.data.success === true) {
        return true;
      }
    }

    return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
