import axios from 'axios';
import store from '../store/';
import router from '@/router';

let isAlreadyFetchingToken = false;
let subscribers = [];

export default function setup() {
  axios.interceptors.request.use(
    config => {
      const token = store.getters['user/token'];
      config.baseURL = process.env.VUE_APP_API_BASE_URL;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      return response;
    },
    function (error) {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (status !== 401) {
        return Promise.reject(error);
      }

      if (config.url === 'authentication/authenticate') {
        return Promise.reject(error);
      }

      if (!isAlreadyFetchingToken) {
        isAlreadyFetchingToken = true;
        store
          .dispatch('user/refreshToken')
          .then(token => {
            isAlreadyFetchingToken = false;
            onTokenFetched(token);
          })
          .catch(() => {
            isAlreadyFetchingToken = false;
            onTokenFetched(null);
            store.commit('user/user', {});
            router.push({ path: '/' }).catch(err => {
              console.error(err);
            });
          });
      }
      return new Promise((resolve, reject) => {
        addSubscriber(token => {
          if (token) {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          } else {
            reject(error);
          };
        });
      });
    }
  );
}

function onTokenFetched(token) {
  subscribers = subscribers.filter(callback => callback(token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}
