var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"voucher"},[_c('div',{staticClass:"voucher__standard__title",on:{"click":_vm.expand}},[_c('h2',[_vm._v(" "+_vm._s(_vm.item.name)+" "),(
            _vm.item &&
            ((_vm.item && _vm.item.courses && _vm.item.courses.length > 0) ||
              (_vm.item && _vm.item.categories && _vm.item.categories.length > 0) ||
              (_vm.item && _vm.item.competitions && _vm.item.competitions.length > 0))
          )?_c('span',[_c('use-svg',{attrs:{"svg":"connected-icon"}})],1):_vm._e()]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('edited'))+" "+_vm._s(_vm.formatDateAndTime(_vm.item.lastChanged))+" ")]),(!_vm.item.standard && _vm.isExpanded)?_c('use-svg',{staticClass:"svg--180-negative voucher__arrow-down",attrs:{"svg":"arrow-right"}}):(!_vm.item.standard)?_c('use-svg',{staticClass:"voucher__arrow-up",attrs:{"svg":"arrow-right"}}):_vm._e()],1),(_vm.item.standard || _vm.isExpanded)?_c('div',{staticClass:"voucher__holder"},[_c('div',{staticClass:"voucher__content"},[_c('div',{staticClass:"voucher__content--image"},[_c('div',{class:[
              'voucher__result--customized-wrapper',
              {
                'alternative-layout':
                  _vm.item.template && _vm.item.template.alternativeLayout,
              },
            ]},[_c('div',{staticClass:"voucher__result--customized-image"},[(_vm.item.paperImageUrl)?[_c('div',{staticClass:"voucher__result--customized-text voucher__result--customized-text-upper"},[_vm._v(" "+_vm._s(_vm.item.upperText)+" ")]),_c('img',{attrs:{"src":_vm.item.paperImageKey
                      ? _vm.item.paperImageUrl + _vm.item.paperImageKey
                      : _vm.item.paperImageUrl}}),_c('div',{staticClass:"voucher__result--customized-text voucher__result--customized-text-lower"},[_vm._v(" "+_vm._s(_vm.item.lowerText)+" ")])]:[(_vm.item.digitalImageUrl)?[_c('img',{attrs:{"src":_vm.item.digitalImageKey
                        ? _vm.item.digitalImageUrl + _vm.item.digitalImageKey
                        : _vm.item.digitalImageUrl}})]:_vm._e()]],2)])]),_c('div',{staticClass:"voucher__content--first"},[_c('div',{staticClass:"info__bottom"},[_c('button',{staticClass:"info__bottom",on:{"click":_vm.editVoucher}},[_c('use-svg',{attrs:{"svg":"edit-icon"}})],1),(!_vm.item.standard)?_c('button',{staticClass:"info__bottom",on:{"click":_vm.toggleDeleteVoucherModal}},[_c('use-svg',{attrs:{"svg":"trash-icon"}})],1):_vm._e(),_c('modal-window',{attrs:{"show":_vm.showDeleteVoucherModal}},[_c('div',{staticClass:"modal__header"},[_vm._v(" "+_vm._s(_vm.$t('deleteVoucher'))+" ")]),_c('div',{staticClass:"modal__content"},[(_vm.item.courses.length > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('deleteVoucherConnected', { itemName: _vm.item.name }))+"? ")]):_c('p',[_vm._v(" "+_vm._s(_vm.$t('deleteVoucherSure', { itemName: _vm.item.name }))+"? ")])]),_c('div',{staticClass:"modal__content--actions"},[_c('base-button',{staticClass:"button--background-gray-dark",attrs:{"text":_vm.$t('cancel')},on:{"click":_vm.toggleDeleteVoucherModal}}),_c('base-button',{attrs:{"text":_vm.$t('deleteVoucher')},on:{"click":function($event){return _vm.deleteVoucher(_vm.item.id)}}})],1)])],1)]),(_vm.item.standard)?_c('div',{staticClass:"voucher__content--second"},[_c('div',{staticClass:"info__content"},[_c('p',[_vm._v(_vm._s(_vm.$t('OnTag.voucherInfo')))])])]):_vm._e(),(!_vm.item.standard)?_c('div',{staticClass:"voucher__form"},[(
              _vm.item &&
              _vm.item.courses &&
              _vm.courses.length > 0 &&
              (_vm.typeName === 'friendly' || _vm.typeName === 'neutral')
            )?_c('div',[_c('addable-custom-select',{attrs:{"options":_vm.courses,"label":_vm.$t('addCourses'),"selected":_vm.item.courses ? _vm.item.courses : []},on:{"selectedConnections":_vm.setCourses}})],1):_vm._e(),(
              _vm.item && _vm.competitions.length > 0 && _vm.typeName === 'competition'
            )?_c('div',[_c('addable-date-filter',{attrs:{"options":_vm.competitions,"label":_vm.$t('addCompetitions'),"modal-title":_vm.$t('addCompetitions'),"selected":_vm.item.competitions ? _vm.item.competitions : []},on:{"selectedConnections":_vm.setCompetitions}})],1):_vm._e(),(_vm.item && _vm.categories.length > 0 && _vm.typeName === 'competition')?_c('div',[_c('addable-custom-select',{attrs:{"label":_vm.$t('addCategories'),"options":_vm.categories,"selected":_vm.item.competitionCategories ? _vm.item.competitionCategories : []},on:{"selectedConnections":_vm.setCategories}})],1):_vm._e()]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }