/* Store module to handle Businessboard */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';
import { Mutex } from 'async-mutex';

const languageMutex = new Mutex();
const state = {
  data: {},
  membershipcategories: {},
  membershipcategory: [],
  course: {},
  coursegb: {},
  lastUpdate: [],
  companyaccountconnections: {},
  companyhistory: {},
  competitionPlayers: {},
  companybudgethistory: {},
  resultgroupselect: [],
  financialyearselect: [],
  resultgroupinclude: [],
  resultgroupaccounts: {},
  settings: {},
  settingsmonths: [],
  settingsyears: [],
  datasource: [],
  courseimportype: [],
  gameCalculation: [],
  incomestatement: {},
  incomestatementFilter: {},
  incomestatementCompanyFilter: {},
  resultunitFilter: {},
  resultunitcalculation: {},
  periodFilter: {},
  dashboard: {},
  dashboardIncomestatement: {},
  dashboardGamecalculation: {},
  greenfeesimulationperiod: {},
  financialdatatoken: {},
  greenfeesimulation: [],
  membershipTypeYears: {},
  membershipcalculationtypes: {},
  membershipcalculationcategories: {},
  membershipcalculationincomes: {},
  membershipcalculationinprogress: true,
  listTableCheckedItems: [],
  language: 0,
  havenonconnectedaccounts: false,
  membershipCategorySettingsModalModel: {},
  membershipTypeYearCopy: undefined,
  resultunits: {},
};

const mutations = {
  data(state, payload) {
    state.data = payload;
  },
  membershipcategories(state, payload) {
    state.membershipcategories = payload;
  },
  membershipcategory(state, payload) {
    state.membershipcategory = payload;
  },
  lastUpdate(state, payload) {
    state.lastUpdate = payload;
  },
  course(state, payload) {
    state.course = payload;
  },
  coursegb(state, payload) {
    state.coursegb = payload;
  },
  datasource(state, payload) {
    state.datasource = payload;
  },
  courseimportype(state, payload) {
    state.courseimportype = payload;
  },
  companyaccountconnections(state, payload) {
    state.companyaccountconnections = payload;
  },
  companyhistory(state, payload) {
    state.companyhistory = payload;
  },
  companybudgethistory(state, payload) {
    state.companybudgethistory = payload;
  },
  competitionplayers(state, payload) {
    state.competitionPlayers = payload;
  },
  resultgroupselect(state, payload) {
    state.resultgroupselect = payload;
  },
  financialyearselect(state, payload) {
    state.financialyearselect = payload;
  },
  resultgroupinclude(state, payload) {
    state.resultgroupinclude = payload;
  },
  resultgroupaccounts(state, payload) {
    state.resultgroupaccounts = payload;
  },
  financialdatatoken(state, payload) {
    state.financialdatatoken = payload;
  },
  settings(state, payload) {
    state.settings = payload;
  },
  settingsmonths(state, payload) {
    state.settingsmonths = payload;
  },
  settingsyears(state, payload) {
    state.settingsyears = payload;
  },
  havenonconnectedaccounts(state, payload) {
    state.havenonconnectedaccounts = payload;
  },
  gameCalculation(state, payload) {
    state.gameCalculation = payload;
  },
  incomestatement(state, payload) {
    state.incomestatement = payload;
  },
  incomestatementFilter(state, payload) {
    state.incomestatementFilter = payload;
  },
  incomestatementCompanyFilter(state, payload) {
    state.incomestatementCompanyFilter = payload;
  },
  resultunitFilter(state, payload) {
    state.resultunitFilter = payload;
  },
  resultunitcalculation(state, payload) {
    state.resultunitcalculation = payload;
  },
  dashboard(state, payload) {
    state.dashboard = payload;
  },
  dashboardIncomestatement(state, payload) {
    state.dashboardIncomestatement = payload;
  },
  dashboardGamecalculation(state, payload) {
    state.dashboardGamecalculation = payload;
  },
  greenfeesimulation(state, payload) {
    state.greenfeesimulation = payload;
  },
  greenfeesimulationperiod(state, payload) {
    state.greenfeesimulationperiod = payload;
  },
  membershipTypeYears(state, payload) {
    state.membershipTypeYears = payload;
  },
  membershiptypes(state, payload) {
    state.membershiptypes = payload;
  },
  membershipcalculationtypes(state, payload) {
    state.membershipcalculationtypes = payload;
  },
  membershipcalculationcategories(state, payload) {
    state.membershipcalculationcategories = payload;
  },
  membershipcalculationincomes(state, payload) {
    state.membershipcalculationincomes = payload;
  },
  membershipcalculationinprogress(state, payload) {
    state.membershipcalculationinprogress = payload;
  },
  listTableCheckedItems(state, payload) {
    state.listTableCheckedItems = payload;
  },
  language(state, payload) {
    state.language = payload;
  },
  membershipCategorySettingsModalModel(state, payload) {
    state.membershipCategorySettingsModalModel = payload;
  },
  membershipTypeYearCopy(state, payload) {
    state.membershipTypeYearCopy = payload;
  },
  resultunits(state, payload) {
    state.resultunits = payload;
  },
};

const getters = {
  data: state => {
    return state.data;
  },
  membershipcategories: state => {
    return state.membershipcategories;
  },
  membershipcategory: state => {
    return state.membershipcategory;
  },
  lastUpdate: state => {
    return state.lastUpdate;
  },
  course: state => {
    return state.course;
  },
  coursegb: state => {
    return state.coursegb;
  },
  competitionPlayers: state => {
    return state.competitionPlayers;
  },
  datasource: state => {
    return state.datasource;
  },
  courseimportype: state => {
    return state.courseimportype;
  },
  companyaccountconnections: state => {
    return state.companyaccountconnections;
  },
  companyhistory: state => {
    return state.companyhistory;
  },
  companybudgethistory: state => {
    return state.companybudgethistory;
  },
  resultgroupselect: state => {
    return state.resultgroupselect;
  },
  financialyearselect: state => {
    return state.financialyearselect;
  },
  resultgroupinclude: state => {
    return state.resultgroupinclude;
  },
  resultgroupaccounts: state => {
    return state.resultgroupaccounts;
  },
  financialdatatoken: state => {
    return state.financialdatatoken;
  },
  settings: state => {
    return state.settings;
  },
  havenonconnectedaccounts: state => {
    return state.havenonconnectedaccounts;
  },
  settingsmonths: state => {
    return state.settingsmonths;
  },
  settingsyears: state => {
    return state.settingsyears;
  },
  language: state => {
    return state.language;
  },
  gameCalculation: state => {
    return state.gameCalculation;
  },
  incomestatement: state => {
    return state.incomestatement;
  },
  incomestatementFilter: state => {
    return state.incomestatementFilter;
  },
  incomestatementCompanyFilter: state => {
    return state.incomestatementCompanyFilter;
  },
  dashboardIncomestatement: state => {
    return state.dashboardIncomestatement;
  },
  dashboardGamecalculation: state => {
    return state.dashboardGamecalculation;
  },
  resultunitFilter: state => {
    return state.resultunitFilter;
  },
  resultunitcalculation: state => {
    return state.resultunitcalculation;
  },
  dashboard: state => {
    return state.dashboard;
  },
  greenfeesimulation: state => {
    return state.greenfeesimulation;
  },
  greenfeesimulationperiod: state => {
    return state.greenfeesimulationperiod;
  },
  membershipTypeYears: state => {
    return state.membershipTypeYears;
  },
  membershipcalculationtypes: state => {
    return state.membershipcalculationtypes;
  },
  membershipcalculationcategories: state => {
    return state.membershipcalculationcategories;
  },
  membershipcalculationincomes: state => {
    return state.membershipcalculationincomes;
  },
  membershipcalculationinprogress: state => {
    return state.membershipcalculationinprogress;
  },
  resultunits: state => {
    return state.resultunits;
  },
};

const actions = {
  updateTypesSettings: async (context, params) => {
    const path = 'businessboard/membershipcalculation/v2';
    let response = {};

    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: `Fel vid sparning av inställningar: ${
          error.response.data.message || 'Felmeddelande saknas'
        }`,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('membershipcalculationtypes', data.result);
    }
  },
  updateTypesSettingsGb: async (context, params) => {
    const path = 'businessboard/membershipcalculation/golfbox';
    let response = {};

    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: `Error when saving settings: ${
          error.response.data.message || 'Error message missing'
        }`,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('membershipcalculationtypes', data.result);
    }
  },
  updateCategoriesSettings: async (context, params) => {
    const path = 'businessboard/membershipcalculation/categories/v2';
    let response = {};

    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: `Fel vid sparning av inställningar: ${
          error.response.data.message || 'Felmeddelande saknas'
        }`,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('membershipcalculationcategories', data.result);
    }
  },
  // For GolfBox
  updateCategoriesGbSettings: async (context, params) => {
    const path = 'businessboard/membershipcalculation/categories/golfbox';
    let response = {};

    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: `Fel vid sparning av inställningar: ${
          error.response.data.message || 'Felmeddelande saknas'
        }`,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('membershipcalculationcategories', data.result);
    }
  },

  fetchData: async context => {
    await fetch(context, 'businessboard/data', 'data');
  },
  fetchMembershipCategories: async context => {
    await fetch(
      context,
      'businessboard/membershipcategories',
      'membershipcategories'
    );
  },
  fetchMembershipCategory: async (context, params) => {
    await fetch(
      context,
      'businessboard/membershipcategory/' + params.id,
      'membershipcategory'
    );
  },
  fetchCompetitionPlayers: async (context, params) => {
    await fetch(
      context,
      'businessboard/competitionplayers/' + params.id,
      'competitionplayer'
    );
  },
  createMembershipCategory: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/membershipcategory',
      'create-category',
      'data'
    );
  },
  deleteMembershipCategory: async (context, params) => {
    await remove(
      context,
      'businessboard/membershipcategory/' + params.id,
      params.loader
    );
  },
  patchMembershipCategory: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/membershipcategory/' + params.id,
      'patch-category'
    );
  },
  fetchLastUpdate: async (context, params = {}) => {
    const path = 'businessboard/updates';
    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: 'Ett fel uppstod med uppdaterad data',
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('lastUpdate', data);
    }
  },
  createCourse: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/course',
      'create-course',
      'data'
    );
  },
  coursePatch: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/course/' + params.id,
      'create-course',
      'course'
    );
  },
  fetchCourse: async (context, params) => {
    await fetch(context, 'businessboard/course/' + params.id, 'course');
  },
  fetchCourseGb: async (context, params) => {
    await fetch(
      context,
      'businessboard/golfbox/gamespercourse/' + params.id,
      'coursegb'
    );
  },
  courseImportCsv: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'import-course', { root: true });

    let path = 'businessboard/course/' + params.id + '/import/csv';

    let formData = new FormData();
    formData.append('file', params.file);
    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'import-course', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      if (data.success) {
        EventBus.$emit('toast', {
          type: 'success',
          msg: data.message,
          request: path,
        });
      }
    }
  },
  courseImportExcel: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'import-course', { root: true });

    let path = 'businessboard/course/import/excel';

    let formData = new FormData();
    formData.append('file', params.file);
    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'import-course', { root: true });
    if (response && response.status === 200) {
      const data = response.data;
      if (data.success) {
        EventBus.$emit('toast', {
          type: 'success',
          msg: data.message,
          request: path,
        });
      }
    }
  },
  courseImportGb: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'import-course', { root: true });
    let path = 'businessboard/golfbox/gamespercourse/import';

    let formData = new FormData();
    formData.append('file', params.file);
    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'import-course', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      if (data.success) {
        EventBus.$emit('toast', {
          type: 'success',
          msg: data.message,
          request: path,
        });
      }
    }
  },
  competitionPlayersImport: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'import-course', { root: true });
    let path =
      'businessboard/golfbox/competitionplayers/' + params.id + '/import';

    let formData = new FormData();
    formData.append('file', params.file);
    formData.append('year', params.year);
    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'import-course', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      if (data.success) {
        EventBus.$emit('toast', {
          type: 'success',
          msg: data.message,
          request: path,
        });
      }
    }
  },
  fetchDatasource: async context => {
    await fetch(context, 'businessboard/datasource', 'datasource');
  },
  fetchCourseImportType: async context => {
    await fetch(context, 'businessboard/courseimport', 'courseimportype');
  },

  fetchLanguage: async context => {
    return languageMutex.runExclusive(async () => {
      if (Vue.$cookies?.isKey('language')) {
        var cookieLanguage = Vue.$cookies.get('language');
        context.commit('language', cookieLanguage);
      } else {
        // If 'language' is not in the cookie, fetch it from the API.
        await fetch(context, 'businessboard/language', 'language');
        Vue.$cookies && Vue.$cookies.set('language', context.state.language);
      }
    });
  },
  courseRevert: async (context, params) => {
    await remove(
      context,
      'businessboard/course/data/' + params.id,
      'revert-course',
      'course'
    );
  },
  courseGbRevert: async (context, params) => {
    await remove(
      context,
      'businessboard/golfbox/gamespercourse/' + params.id,
      'revert-course',
      'course'
    );
  },
  createCompany: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/company',
      'create-company',
      'data'
    );
  },
  companyPatch: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/company/' + params.id,
      'create-company'
    );
  },
  companyImport: async (context, params) => {
    // month is optional
    const path = `businessboard/company/${params.id}/import${
      params.month ? '/' + params.month : ''
    }`;
    const loader = 'import-company';

    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });

    let formData = new FormData();
    formData.append('file', params.file);
    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
      return { success: false };
    }
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      if (data.success) {
        EventBus.$emit('toast', {
          type: 'success',
          msg: data.message,
          request: path,
        });
      }
    }
    return {
      success: response.data.success,
      status: response.status,
      resultCode: response.data.resultCode,
      financialYearMonthRange: response.data.financialYearMonthRange,
    };
  },
  budgetImport: async (context, params) => {
    const path = 'businessboard/company/' + params.id + '/budget/import';
    const loader = 'import-company';

    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });

    let formData = new FormData();
    formData.append('file', params.file);
    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
      return false;
    }
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      if (data.success) {
        EventBus.$emit('toast', {
          type: 'success',
          msg: data.message,
          request: path,
        });
      }
    }
    return response.data.success;
  },
  fetchCompanyAccountConnections: async (context, params) => {
    let path = 'businessboard/company/' + params.id + '/accountconnections';

    let response = {};
    try {
      response = await Vue.axios.get(path, { params: params });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    const data = response.data;
    context.commit('companyaccountconnections', data.result);
  },
  patchCompanyAccountConnections: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/company/accountconnections/' + params.id,
      'patch-accountconnections'
    );
  },
  fetchCompanyHistory: async (context, params) => {
    await fetch(
      context,
      'businessboard/company/' + params.id + '/history',
      'companyhistory'
    );
  },
  fetchCompanyBudgetHistory: async (context, params) => {
    await fetch(
      context,
      'businessboard/company/' + params.id + '/budgethistory',
      'companybudgethistory'
    );
  },
  fetchResultgroupSelect: async context => {
    await fetch(
      context,
      'businessboard/resultgroupselect',
      'resultgroupselect'
    );
  },
  fetchFinancialYearSelect: async (context, params) => {
    try {
      let response = await Vue.axios.post(
        'businessboard/company/' + params.id + '/financialyears',
        params.model
      );
      context.commit('financialyearselect', response.data.result);
    } catch {}
  },
  fetchResultgroupInclude: async context => {
    await fetch(
      context,
      'businessboard/resultgroupinclude',
      'resultgroupinclude'
    );
  },
  createGroup: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/resultgroup',
      'create-group',
      'data'
    );
  },
  deleteGroup: async (context, params) => {
    await remove(
      context,
      'businessboard/resultgroup/' + params.id,
      params.loader
    );
  },
  patchGroup: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/resultgroup/' + params.id,
      'patch-group'
    );
  },
  patchResultunit: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/resultunit/' + params.id,
      'patch-resultunit'
    );
  },
  patchResultunitBudget: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/resultunit/' + params.id + '/budget',
      'patch-resultunit'
    );
  },
  fetchFinancialDataToken: async (context, params) => {
    await fetch(
      context,
      'businessboard/company/' + params.id + '/token/' + params.code,
      'financialdatatoken'
    );
  },
  importFromApi: async (context, params) => {
    try {
      let response = await Vue.axios.post(
        'businessboard/company/' + params.id + '/importapi',
        params.model
      );
      return {
        success: response.data.success,
        status: response.status,
        resultCode: response.data.resultCode,
      };
    } catch {
      return false;
    }
  },
  fetchResultgroupAccounts: async (context, params) => {
    await fetch(
      context,
      'businessboard/resultgroup/' + params.id + '/accounts',
      'resultgroupaccounts'
    );
  },
  fetchSettings: async context => {
    await fetch(context, 'businessboard/settings', 'settings');
  },
  fetchSettingsMonths: async context => {
    await fetch(
      context,
      'businessboard/settings/monthselect',
      'settingsmonths'
    );
  },
  fetchSettingsYears: async context => {
    await fetch(context, 'businessboard/settings/yearselect', 'settingsyears');
  },
  fetchHaveNonConnectedAccounts: async context => {
    await fetch(
      context,
      'businessboard/company/havenonconnectedaccounts',
      'havenonconnectedaccounts'
    );
  },
  patchSettings: async (context, params) => {
    await patch(context, params, 'businessboard/settings', 'patch-settings');
  },
  patchSettingsSlottime: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/settings/slottime/' + params.id
    );
  },
  fetchSettingsDeviatingfinancialyear: async (context, params) => {
    await fetch(
      context,
      'businessboard/settings/deviatingfinancialyear/' + params.id,
      'financialyear'
    );
  },
  patchSettingsDeviatingfinancialyear: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/settings/deviatingfinancialyear/' + params.id,
      'add-year',
      'settings'
    );
  },
  postSettingsDeviatingfinancialyear: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/settings/deviatingfinancialyear',
      'add-year',
      'settings'
    );
  },
  deleteSettingsDeviatingfinancialyear: async (context, params) => {
    await remove(
      context,
      'businessboard/settings/deviatingfinancialyear/' + params.id,
      params.loader
    );
  },
  patchSettingsFinancialdata: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/settings/financialdata/' + params.id,
      'financialdata'
    );
  },
  patchSettingsCourseImport: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/settings/courseimport/' + params.id,
      'courseimportype'
    );
  },
  patchSettingsGameSetting: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/settings/gamesetting/' + params.id,
      'gamesetting'
    );
  },
  fetchGameCalculation: async (context, params) => {
    let path = 'businessboard/gamecalculation';

    let response = {};
    try {
      response = await Vue.axios.get(path, { params: params });
      context.commit('gameCalculation', response.data.result);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  fetchIncomestatement: async (context, params) => {
    let path = 'businessboard/incomestatement';
    if (params.id) path += '/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
      context.commit('incomestatement', response.data.result);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  postIncomestatementFilter: async (context, params) => {
    let path = 'businessboard/incomestatement/filter';
    await Vue.axios.post(path, params);
  },
  postIncomestatementCompanyFilter: async (context, params) => {
    let path = 'businessboard/incomestatement/' + params.id + '/filter';
    await Vue.axios.post(path, params);
  },
  fetchIncomestatementFilter: async (context, params) => {
    let path = 'businessboard/incomestatement/filter';
    let response = await Vue.axios.get(path);
    context.commit('incomestatementFilter', response.data);
  },
  fetchIncomestatementCompanyFilter: async (context, id) => {
    let path = 'businessboard/incomestatement/' + id + '/filter';
    let response = await Vue.axios.get(path);
    context.commit('incomestatementCompanyFilter', response.data);
  },
  postIncomestatementDashboard: async (context, params) => {
    let path = 'businessboard/dashboard/incomestatement';
    try {
      let response = await Vue.axios.post(path, params);
      context.commit('dashboardIncomestatement', response.data);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  postGamecalculationDashboard: async (context, params) => {
    let path = 'businessboard/dashboard/gamecalculation';
    try {
      let response = await Vue.axios.post(path, params);
      context.commit('dashboardGamecalculation', response.data);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  postIncomestatementDashboardFilter: async (context, params) => {
    let path = 'businessboard/dashboard/filter/5';
    try {
      await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  postGamecalculationDashboardFilter: async (context, params) => {
    let path = 'businessboard/dashboard/filter/6';
    try {
      await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  postComment: async (context, params) => {
    let path = 'businessboard/dashboard/comment';
    try {
      await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  postResultunitFilter: async (context, params) => {
    let path = 'businessboard/resultunit/filter';
    await Vue.axios.post(path, params);
  },
  fetchResultunitFilter: async (context, params) => {
    let path = 'businessboard/resultunit/filter';
    let response = await Vue.axios.get(path);
    context.commit('resultunitFilter', response.data);
  },
  fetchResultUnitCalculation: async (context, params) => {
    let path = 'businessboard/resultunitcalculation';
    if (params.id) path += '/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
      context.commit('resultunitcalculation', response.data.result);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  fetchDashboard: async (context, params) => {
    let path = 'businessboard/dashboard';
    let response = {};
    try {
      response = await Vue.axios.get(path, { params: params });
      context.commit('dashboard', response.data.result);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  fetchGreenfeesimulation: async context => {
    await fetch(
      context,
      'businessboard/greenfeesimulation',
      'greenfeesimulation'
    );
  },
  postGreenfeesimulation: async (context, params) => {
    return Vue.axios.post('businessboard/greenfeesimulation', params);
  },
  deleteGreenfeesimulation: async (context, id) => {
    await remove(context, 'businessboard/greenfeesimulation/' + id);
  },
  fetchGreenfeesimulationPeriod: async context => {
    await fetch(
      context,
      'businessboard/greenfeesimulation/period',
      'greenfeesimulationperiod'
    );
  },
  fetchMembershipTypes: async context => {
    context.commit('membershipcalculationinprogress', true);
    await fetch(
      context,
      'businessboard/membershiptypes/v2',
      'membershipTypeYears'
    ).then(() => {
      context.commit('membershipcalculationinprogress', false);
    });
  },
  patchMembershipType: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/membershiptype/' + params.id,
      'patch-membershiptypes'
    );
  },
  patchMembershipDiscount: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/membershipdiscount',
      'patch-membershipdiscount'
    );
  },
  createMembershipFeeColumn: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/membershipfeecolumn',
      'create-feecolumn',
      'membershiptypes'
    );
  },
  deleteMembershipFeeColumn: async (context, params) => {
    await remove(
      context,
      'businessboard/membershipfeecolumn/' + params.id,
      params.loader,
      'membershiptypes'
    );
  },
  patchMembershipFeeColumn: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/membershipfeecolumn/' + params.id,
      'patch-feecolumn'
    );
  },
  patchMembershipAgeGroup: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/membershipagegroup/' + params.id,
      'patch-agegroup'
    );
  },
  patchMembershipFeeValue: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/membershipfeevalue',
      'patch-feevalue'
    );
  },
  postMembershipAgeGroup: async (context, params) => {
    return createAgeGroup(
      context,
      params,
      'businessboard/membershipagegroup',
      'create-agegroup',
      'membershiptypes'
    );
  },
  deleteMembershipAgeGroup: async (context, params) => {
    await remove(
      context,
      'businessboard/membershipagegroup/' + params.id,
      params.loader,
      'membershiptypes'
    );
  },
  fetchMembershipCalculationTypes: async context => {
    await fetch(
      context,
      'businessboard/membershipcalculation/v2',
      'membershipcalculationtypes'
    );
  },
  fetchMembershipCalculationTypesGb: async context => {
    await fetch(
      context,
      'businessboard/membershipcalculation/golfbox',
      'membershipcalculationtypes'
    );
  },
  fetchMembershipCalculationCategories: async context => {
    await fetch(
      context,
      'businessboard/membershipcalculation/categories/v2',
      'membershipcalculationcategories'
    );
  },
  // for GolfBox
  fetchMembershipCalculationGbCategories: async context => {
    await fetch(
      context,
      'businessboard/membershipcalculation/categories/golfbox',
      'membershipcalculationcategories'
    );
  },
  fetchMembershipCalculationIncomes: async (context, params) => {
    let path = 'businessboard/membershipcalculation/income';

    let response = {};
    try {
      response = await Vue.axios.get(path, { params: params });
      context.commit('membershipcalculationincomes', response.data.result);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  patchDistributionTemplate: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/settings/distributiontemplate/' + params.id,
      'add-distributiontemplate'
    );
  },
  postDistributionTemplate: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/settings/distributiontemplate',
      'add-distributiontemplate'
    );
  },
  deleteDistributionTemplate: async (context, params) => {
    await remove(
      context,
      'businessboard/settings/distributiontemplate/' + params.id,
      'delete-distributiontemplate'
    );
  },

  createMembershipYear: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/membershipyear',
      'create-membershipyear',
      'membershipyear'
    ).then(() => {
      context.dispatch('fetchMembershipTypes');
      state.membershipTypeYearCopy = undefined;
    });
  },

  fetchResultUnits: async (context, params) => {
    let response = await Vue.axios.get(
      'businessboard/resultunits/' + params.id
    );
    context.commit('resultunits', response.data);
  },
  exportUsers: async context => {
    try {
      let response = await Vue.axios.get('users/businessboardexport', {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `users_${new Date().toISOString()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  },
};
async function patch(context, params, path, loader, commit) {
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  let response = {};
  try {
    response = await Vue.axios.patch(path, params);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
  const data = response.data;
  if (!data.success) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: data.message,
      request: path,
    });
  }
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  if (commit && response && response.data) {
    const data = response.data;
    context.commit(commit, data.result);
  }
}
async function fetch(context, path, commit) {
  try {
    let response = await Vue.axios.get(path);
    context.commit(commit, response.data.result);
  } catch (error) {
    console.error(error);
  }
}
async function createAgeGroup(context, params, path, loader, commit) {
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  try {
    let response = await Vue.axios.post(path, params);
    if (response.data.success) {
      context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
      context.commit(commit, response.data.result);
      return true;
    } else {
      EventBus.$emit('toast', {
        type: 'error',
        msg: response.data.message,
        request: path,
      });
    }
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
}

async function create(context, params, path, loader, commit) {
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  try {
    let response = await Vue.axios.post(path, params);
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
    if (commit) context.commit(commit, response.data.result);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
}

async function remove(context, path, loader, commit) {
  if (loader)
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });

  let response = {};
  try {
    response = await Vue.axios.delete(path);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
  if (loader)
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  if (response && response.data && response.data.success) {
    EventBus.$emit('toast', {
      type: 'success',
      msg: response.data.message,
      request: path,
    });
  }
  if (commit && response && response.data) {
    const data = response.data;
    context.commit(commit, data.result);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
