<template>
  <div :class="{ 'template-diagrams': showAllDiagrams || includeDiagram }">
    <div class="header">
      <diagram-header
        :elementID="templateId + '_economy-company'"
        :include="includeDiagram"
        :chosenFormat="fileFormat"
        :diagram="diagram"
        :templateId="templateId"
        :showDiagram="showAllDiagrams || includeDiagram">
        <template #title>
          {{ companyName }}<br />
          {{ $t('revenue') }}
          <br />
          {{ $t('costs') }}
        </template>

        <template #settings>
          <modal-filter
            :titleText="$t('ReportTool.totalRevenueAndCost')"
            :comparisonYearCount="1"
            :showPeriod="true"
            :storedFilter="diagram?.settings || '{}'"
            @changeFilter="e => toggleDiagram(e)"
            @saveFilter="e => saveFilter(e)" />
        </template>
      </diagram-header>
    </div>
    <div
      v-if="diagram && (showAllDiagrams || includeDiagram)"
      class="diagram"
      :id="templateId + '_economy-company'">
      <div :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
        <h1
          v-if="settings.showHeaders"
          class="pieChart-title">
          {{ companyName }}
        </h1>

        <div class="section">
          <div class="divide-charts">
            <h4>{{ $t('revenue') }}</h4>
            <chart-legend
              :diagramData="diagram"
              :companyName="companyName">
            </chart-legend>
            <bar-chart
              v-if="!isLoading"
              :styles="{ height: '12rem' }"
              :data="
                diagram.data.companies.find(d => d.companyName === companyName)
                  .totalDiagram.income
              " />
            <table-chart-loader v-if="isLoading" />
          </div>
          <div class="divider"></div>
          <div class="divide-charts">
            <h4>
              {{ $t('costs') }}
            </h4>
            <div class="legend">
              <chart-legend
                :diagramData="diagram"
                :companyName="companyName">
              </chart-legend>
            </div>
            <bar-chart
              v-if="!isLoading"
              :styles="{ height: '12rem' }"
              :data="
                diagram.data.companies.find(d => d.companyName === companyName)
                  .totalDiagram.costs
              " />
            <table-chart-loader v-if="isLoading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthsLabelsShort } from '@/core/consts';

import ModalFilter from '@/components/reports-templates/settings-modal/gamecalculation';
import DiagramHeader from '@/components/reports-templates/diagram-header';
import BarChart from '@/components/charts/bar-chart-reports-economy';
import ChartLegend from '@/components/reports-templates/legend/economy-total2';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import TableChartLoader from '@/components/reports-templates/table-chart-loader';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixin],
  data: function () {
    return {};
  },
  components: {
    ModalFilter,
    DiagramHeader,
    BarChart,
    ChartLegend,
    TableChartLoader,
  },
  props: {
    companyName: {
      type: String,
      default: function () {
        this.$t('Businessboard.companies');
      },
    },
    diagram: {},
    templateId: String,
    include: Boolean,
    showAllDiagrams: Boolean,
  },
  computed: {
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
    fileFormat() {
      return this.settings.fileFormat;
    },
    includeDiagram() {
      return this.diagram?.includedInTemplate || false;
    },
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    activeYear() {
      return JSON.parse(this.diagram.settings).Years[0];
    },
    months() {
      return [''].concat(
        MonthsLabelsShort.map(l => this.$t(`MonthsShort.${l}`))
      );
    },
  },
  methods: {
    toggleDiagram(e) {
      this.diagramToRender = e.show;
      this.$store.dispatch('businessboardReportingTool/postDiagram', {
        templateId: this.templateId,
        settings: e,
        diagramType: 22,
      });
    },
    saveFilter(e) {
      e.save = true;
      this.toggleDiagram(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
.pieChart {
  width: 20rem;
  height: 20rem;
}

.section {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 16px;
  margin-left: 2rem;
  display: flex;
  place-content: center;
  text-align: center;
  h4 {
    font-size: 18px;
    margin: 10px 0;
  }
  span {
    font-size: 16px;
    font-weight: 500;
  }
}

.legend {
  align-self: end;
  .year {
    flex-flow: flex-column;
  }
}
.pieChart-title {
  margin-left: 4rem;
  text-align: center;
}

.chart-year {
  font-size: medium;
  font-weight: 600;
}

.divide-charts {
  display: flex;
  flex-direction: column;
}
.divider {
  border-left: 1px solid #c1c7c9;
  margin: 0px 2rem;
}

.border-left {
  border-left: 1px solid #dadedf;
}
</style>
