/* Store module to handle Clients */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';
import { pad2 } from '@/core/helpers.js';

const state = {
  clientOptions: [],
  clients: {
    rows: [],
    columns: [],
  },
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
};

const mutations = {
  clientOptions (state, payload) {
    state.clientOptions = payload;
  },
  clients (state, payload) {
    state.clients.rows = payload.rows;
    state.clients.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
};

const getters = {
  clients: state => {
    return state.clients;
  },
  clientOptions: state => {
    return state.clientOptions;
  },
  pagination: (state) => {
    return state.pagination;
  },
  nextPage: (state) => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: (state) => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
};

const actions = {
  fetchClients: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'clients-fetch-clients', { root: true });
    let path = 'clients';

    if(params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params).map(k => {
        if(params[k] !== undefined) {
          return esc(k) + '=' + esc(params[k]);
        }
      }).filter(q => q !== undefined).join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'clients-fetch-clients', { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('clients', data.result);
    }
  },
  fetchAllClients: async (context, params) => {
    let path = 'clients/select';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params).map(k => {
        if(params[k] !== undefined) {
          return esc(k) + '=' + esc(params[k]);
        }
      }).filter(q => q !== undefined).join('&');
      path += '?' + query;
    }

    context.dispatch('ui/toggleActiveLoaders', 'clients-fetch-all', { root: true });
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'clients-fetch-all', { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('clientOptions', data.result);
    }
  },
  createClient: async (context, params) => {
    let path = 'clients';

    let response = {};
    try {
      response = await Vue.axios.post(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.status === 200) {
      return true;
    }

    return false;
  },
  createIoT: async (context, params) => {
    let path = 'clients/iot';
    context.dispatch('ui/toggleActiveLoaders', 'clients-create-iot', { root: true });

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'clients-create-iot', { root: true });

    if (response && response.status === 200) {
      return true;
    }

    return false;
  },
  downloadClient: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'clients-download-client', { root: true });
    let path = 'clients/download';

    let response = {};
    try {
      response = await Vue.axios.get(path, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'clients-download-client', { root: true });

    let date = new Date();
    const filename = 'OnTagClient-' + date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2(date.getDate()) + pad2(date.getHours()) + pad2(date.getMinutes()) + pad2(date.getSeconds());

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.msi`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
