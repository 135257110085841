<template>
  <div :class="{ 'template-diagrams': showAllDiagrams || includeDiagram }">
    <div class="header">
      <diagram-header
        :elementID="templateId + '_member-total'"
        :include="includeDiagram"
        @includeDiagram="e => (includeDiagram = e)"
        :chosenFormat="fileFormat"
        :diagram="diagram"
        :templateId="templateId"
        :showDiagram="showAllDiagrams || includeDiagram">
        <template #title>
          {{
            $t('ReportTool.totalMemberAmount') +
            ' & ' +
            $t('ReportTool.totalMemberPerCategory')
          }}
        </template>

        <template #settings>
          <modal-filter
            :titleText="
              $t('ReportTool.totalMemberAmount') +
              ' & ' +
              $t('ReportTool.totalMemberPerCategory')
            "
            :showMemberTotal="true"
            :storedFilter="diagram?.settings || '{}'"
            @changeFilter="e => toggleDiagram(e)"
            @saveFilter="e => saveFilter(e)" />
        </template>
      </diagram-header>
    </div>
    <div
      v-if="showAllDiagrams || includeDiagram"
      :id="templateId + '_member-total'">
      <div
        class="diagram_layout"
        :class="{ 'opaque-bg': settings.fileFormat === '0' }">
        <h1 v-if="settings.showHeaders">
          {{ $t('ReportTool.totalMemberAmount') }}
        </h1>
        <h1 v-if="settings.showHeaders">
          {{ showCategories ? $t('ReportTool.totalMemberPerCategory') : '' }}
        </h1>

        <div>
          <div class="circles">
            <div class="circle_div">
              <div class="circle circle_big circle_purple">
                <div>
                  <h1>
                    {{ diagram.data.currentTotal.toLocaleString('sv-SE') }}
                  </h1>
                  <p>{{ $t('ReportTool.members').toLowerCase() }}</p>
                </div>
              </div>
              <div class="legend">
                <reports-years-legend
                  :totalPeriodData="diagram.data.comparePeriodData"
                  :budget="diagram.data.compareBudgetData"
                  :unit="$t('piece')" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="border-left categories"
          v-if="showCategories">
          <h4>
            {{ $t('comparedTo') }} {{ compareYearMonth }} {{ compareYear }}
          </h4>
          <categories
            :categories="diagram.data.membersData"
            :unit="$t('piece')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthsLabelsShort } from '@/core/consts';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

import ModalFilter from '@/components/reports-templates/settings-modal/gamecalculation';
import DiagramHeader from '@/components/reports-templates/diagram-header';
import ReportsYearsLegend from '@/components/reports-templates/legend/reports-legend-years-member';
import Categories from '@/components/reports-templates/legend/reports-legend-categories';
import { COLORS } from '../../charts/pie-chart-colors';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixin],
  data: function () {
    return {
      COLORS,
      months: [''].concat(
        MonthsLabelsShort.map(l => this.$t(`MonthsShort.${l}`))
      ),
    };
  },
  components: {
    ModalFilter,
    DiagramHeader,
    ReportsYearsLegend,
    Categories,
  },
  props: {
    templateId: String,
    include: Boolean,
    showAllDiagrams: Boolean,
    diagram: {},
  },
  computed: {
    isLoading() {
      return false;
    },
    fileFormat() {
      return this.settings.fileFormat;
    },
    includeDiagram() {
      return this.diagram?.includedInTemplate || false;
    },
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    activeYear() {
      return JSON.parse(this.diagram.settings).Years[0];
    },
    compareYear() {
      return JSON.parse(this.diagram.settings).Years[1];
    },
    showCategories() {
      return JSON.parse(this.diagram.settings).ShowXFactor;
    },
    compareYearMonth() {
      return this.months[JSON.parse(this.diagram.settings).CompareYearMonth];
    },
  },
  methods: {
    toggleDiagram(e) {
      this.diagramToRender = e.show;
      this.$store.dispatch('businessboardReportingTool/postDiagram', {
        templateId: this.templateId,
        settings: e,
        diagramType: 1,
      });
    },
    saveFilter(e) {
      e.save = true;
      this.toggleDiagram(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';

.pieChart {
  width: 20rem;
  height: 20rem;
}

.chart-year {
  font-size: medium;
  font-weight: 600;
}

.divide-charts {
  display: grid;
  grid-template-columns: auto auto;
}

.diagram_layout {
  display: grid;
  grid-template-columns: 30rem auto;
  margin: 2rem;
}
.border-left {
  border-left: 1px solid #dadedf;
}
.circles {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  h4 {
    text-align: center;
  }

  .circle {
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    margin: 0.5rem;
    text-align: center;
    font-family: 'SoraRegular';
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    &_big {
      font-size: 24px;
      width: 18rem;
      height: 18rem;
    }

    &_purple {
      background-color: #4b2d88;
      color: white;
    }

    div {
      margin-top: -1rem;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      margin-top: -2rem;
    }
  }

  .circle_div {
    display: flex;
    flex-flow: column;
  }
}

.categories {
  h4 {
    text-align: right;
  }
}
</style>
