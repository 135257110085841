import Vue from 'vue';
import { chain, forEach } from 'lodash';

function register (context, action) {
  forEach(context.keys(), fileName => {
    const componentConfig = context(fileName);
    const name = fileName.split('/').pop().replace(/\.\w+$/, '');
    action(name, componentConfig);
  });
}

function registerComponents () {
  register(require.context('@/components/svg', false, /\w+\.(vue)$/), (name, componentConfig) => {
    const componentName = chain(name).camelCase().upperFirst().value();

    Vue.component(componentName, componentConfig.default || componentConfig);
  });
}

registerComponents();
