<template>
  <wrapper
    width="40rem"
    :title="title"
    :breadcrumb="$t('connectedAccountsToGroup')"
    :isOpen="isModalOpen">
    <template slot="open">
      <base-button
        background-color="white"
        :text="$t('show') + ' (' + accounts.length + ' )'"
        @click="isModalOpen = true">
      </base-button>
    </template>
    <template slot="content">
      <div class="row header">{{ $t('connectedAccounts') }}</div>
      <template
        v-for="(value, key) in Object.groupBy(
          accounts,
          ({ balanceAccountCompanyName }) => balanceAccountCompanyName
        )">
        <div
          class="header2 row"
          :key="key">
          {{ key }}
        </div>
        <div
          class="data row"
          v-for="(account, i) in value"
          :key="i">
          {{ account.name }}
        </div>
      </template>
    </template>
    <template slot="close">
      <base-button
        background-color="white"
        :text="$t('close') + ' + x'"
        @click="isModalOpen = false">
      </base-button>
    </template>
  </wrapper>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import Wrapper from './wrapper.vue';

export default {
  name: 'modal-membership-types-settings',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    Wrapper,
  },
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      newGroupName: '',
      drag: false,
      isModalOpen: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    addGroup(name) {},
    changeName(item, e) {
      item.membershipCategoryName = e.target.value;
      this.patchItem(item);
    },
    changeExclude(item) {
      item.exclude = !item.exclude;
      this.patchItem(item);
    },
    changeOrder: function (e) {
      let item = e.moved.element;
      item.order = e.moved.newIndex;
      this.patchItem(item);
    },
    deleteGroup(id) {},
    patchItem(item) {},
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.header {
  background: #373d3f;
  color: var(--white, #fff);
  padding: 18px 16px;
  font-size: 17px;
}
.header2 {
  background: #777;
  color: var(--white, #fff);
  padding: 16px 16px;
  font-size: 17px;
}
.data {
  background: var(--tabellgrn1, #f0f5f5);
  color: #000;
  padding: 12px 16px;
  font-size: 16px;
}
.row {
  display: grid;
  border-radius: 3px;
  font-weight: 600;
  vertical-align: middle;
  margin: 2px;
  .subtle {
    color: var(--gr1, #131516);
    font-size: 14px;
    font-weight: 300;
  }
}
</style>
