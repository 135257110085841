/* Store module to handle Clubs */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  terminals: {
    rows: [],
    columns: [],
  },
  terminal: {},
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
};

const mutations = {
  terminals (state, payload) {
    state.terminals.rows = payload.rows;
    state.terminals.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
  terminal (state, payload) {
    state.terminal = payload;
  },
  createTerminal (state, payload) {
    state.terminals.rows.push(payload);
  },
  editTerminal (state, payload) {
    state.terminal = payload;
  },
  deleteTerminal (state, id) {
    Vue.delete(state.terminals.rows, state.terminals.rows.findIndex(terminal => terminal.id === id));
  },
  clearTerminal (state, payload) {
    state.terminal = {};
  },
  setImageOnTerminal (state, payload) {
    let url = payload.url;
    let keyValue = payload.keyValue;
    Vue.set(state.terminal.settings, 'logoUrl', url);
    Vue.set(state.terminal.settings, 'logoKey', keyValue);
  },
};

const getters = {
  terminals: state => {
    return state.terminals;
  },
  terminal: state => {
    return state.terminal;
  },
  pagination: (state) => {
    return state.pagination;
  },
  currentPage: (state) => {
    return state.pagination.currentPage;
  },
  nextPage: (state) => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: (state) => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
};

const actions = {
  fetchTerminals: async (context, params) => {
    let path = 'terminals';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params).map(k => {
        if(params[k] !== undefined) {
          return esc(k) + '=' + esc(params[k]);
        }
      }).filter(q => q !== undefined).join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('terminals', data.result);
    }
  },
  fetchTerminal: async (context, params) => {
    let path = 'terminals/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('terminal', data.result);
    }
  },
  createTerminal: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'terminals-create-terminal', { root: true });
    let path = 'terminals';

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'terminals-create-terminal', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('createTerminal', data.result);
    }
  },
  editTerminal: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'terminals-edit-terminal', { root: true });
    let path = `terminals/${params.id}`;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'terminals-edit-terminal', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('editTerminal', data.result);
    }
  },
  deleteTerminal: async (context, params) => {
    const path = 'terminals/' + params.id;
    let response = {};
    try {
      response = await Vue.axios.delete(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
      throw(new Error(error.response.data.message));
    }

    if(response && response.status === 200) {
      context.commit('deleteTerminal', params.id);
    }
  },
  addImage: async (context, params) => {
    const path = 'terminals/image';
    let formData = new FormData();
    formData.append('image', params.image);

    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('setImageOnTerminal', {
        url: data.result.url,
        keyValue: data.result.key,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
