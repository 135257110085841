<template>
  <div class="diagram-box">
    <div class="diagram-header">
      <div>
        <h2 v-if="this.showHeadersSetting">{{ $t(headingTranslationKey) }}</h2>
      </div>
      <diagram-kebab-menu
        v-if="withDiagramTools"
        :diagramId="diagramId"
        :enlargeOption="false" />
    </div>
    <div
      :id="`diagram_${diagramId}`"
      :headingTranslationKey="`${$t(headingTranslationKey)}`"
      :class="[
        'diagram-contentarea',
        this.chosenFileFormat === '0' ? 'opaque' : '',
      ]">
      <!-- The diagram -->
      <div>
        <slot />
      </div>
    </div>

    <div class="comment-section">
      <comment
        @postedComment="postedComment"
        :comment="comment || null"
        :companyId="(comment && comment.companyId) || ''"
        :section="diagramId"
        :lastUpdate="this.lastUpdate()"
        :canEdit="showSaveAndPublish" />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

import Comment from '@/components/businessboard/comment';
import DiagramKebabMenu from '@/components/businessboard/diagram-kebab-menu';
import { commentTypeDefinition } from '@/components/businessboard/consts';

export default {
  name: 'GolfOfficeFrontEndDiagramBox',

  mixins: [LocalizationMixin],

  inject: ['lastUpdate', 'fileFormat', 'showHeaders'],

  components: { Comment, DiagramKebabMenu },

  props: {
    legends: VueTypes.array,
    labels: VueTypes.array,
    headingTranslationKey: VueTypes.string.isRequired,
    withDiagramTools: VueTypes.bool.def(false),
    comment: commentTypeDefinition,
    diagramId: VueTypes.number,
  },

  data() {
    return {
      chosenFileFormat: this.fileFormat(),
      showHeadersSetting: this.showHeaders(),
    };
  },

  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    showSaveAndPublish() {
      return !(
        this.user.roles?.BusinessBoard &&
        this.user.roles.BusinessBoard.some(
          e => e.value === 'BusinessBoardBoardMember'
        )
      );
    },
  },

  mounted() {},

  methods: {
    postedComment: () => '',
  },
};
</script>

<style lang="scss" scoped>
.diagram-box {
  display: flex;
  flex-direction: column;

  padding: measure('container-distance');

  background-color: color(white);

  border-radius: measure('spacing-xs');

  box-sizing: border-box;

  .diagram-contentarea {
    flex-grow: 1;
  }

  .opaque {
    background-color: color(white);
  }
}

.diagram-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.comment-section {
  position: relative;
  bottom: 0;
}
</style>
