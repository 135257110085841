<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div>
        <router-link
          :to="{ path: '/businessboard/data/forecast/' + companyId }">
          &laquo;
          {{ $t('back') }}
        </router-link>
        <!--base-button
          :disabled="budget.status === 'Established'"
          @click="deleteBudget"
          class="inline-right-button button--background-red"
          :text="$t('delete')">
        </base-button-->
      </div>
      <div class="businessboard--header">
        <span>
          <span class="h1">
            {{ $t('Businessboard.editForecast') }}
            | {{ budget.companyName }}
            <template v-if="budget.resultUnitName">
              |
              {{ budget.resultUnitName }}
            </template>
            <base-button
              :text="$t('Export')"
              :button-size-small="true"
              @click="
                exportForecast(
                  budget.companyName,
                  budget.name,
                  budget.financialYear
                )
              ">
              <use-svg svg="export-icon" />
            </base-button>
          </span>
        </span>
        <div class="status-block">
          <button
            @click="showNotes = !showNotes"
            class="status-block__notes-button">
            <span>
              <img
                src="@/components/svg/message.svg"
                class="svg"
                alt="" />
              <span>
                {{ $t('Budget.notes') }}
              </span>
            </span>
          </button>
          <div
            class="status-block__notes-box"
            v-if="showNotes">
            <span>
              {{ $t('Budget.notes') }}
            </span>
            <textarea v-model="note" />
            <div class="status-block__button-container">
              <base-button
                @click="cancelNote"
                class="button--reset"
                :text="$t('cancel')"></base-button>
              <base-button
                :text="$t('save')"
                @click="saveNote"></base-button>
            </div>
          </div>
        </div>
      </div>
      <div class="name-section">
        <div class="name-section__block">
          <label for="year">
            {{ $t('year') }}
          </label>
          <input
            id="year"
            class="name-section__block--year"
            type="text"
            disabled
            :value="budget.financialYear" />
        </div>
        <div class="name-section__block name-section__block--select">
          <label for="pre-outcome">
            {{ $t('Businessboard.preOutcome') }}
          </label>
          <select
            v-model="preOutcomeYear"
            id="pre-outcome">
            <option
              value=""
              selected>
              {{ $t('Budget.chooseOutcome') }}
            </option>
            <option
              v-for="year in budgetResultsYearSelect"
              :key="year.value"
              :value="year.value">
              {{ year.displayName }}
            </option>
          </select>
        </div>
      </div>
      <forecast-category
        class="budget-category"
        v-for="(group, index) in budgetGroups"
        :key="index"
        :budgetGroup="group"
        :index="index"
        :preBudgetId="preBudgetId"
        :preBudget="previousBudget"
        :dataDate="budget.compareFinancialYear"
        :preOutcomeYear="preOutcomeYear"
        :preOutcome="previousOutcome"
        @updatedGroups="updateGroups" />
      <div class="computed-result">
        <div>
          <span>
            {{ $t('Budget.calcResult') }}
          </span>
          <span>{{ computedResult }} {{ $t('denomination') }}</span>
        </div>
      </div>
    </div>
    <base-button
      class="save-budget"
      :text="$t('save')"
      @click="save()" />
    <div
      v-if="showSpinner"
      class="saving-spinner"></div>
  </div>
</template>

<script>
import ForecastCategory from '@/components/forecast-category';
import BaseButton from '@/components/form-elements/base-button';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import UseSvg from '@/components/use-svg';
export default {
  components: {
    ForecastCategory,
    BaseButton,
    UseSvg,
  },
  name: 'EditBudget',
  mixins: [LocalizationMixin],
  props: {
    id: {
      type: String,
      default: '0',
    },

    companyId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      budgetName: '',
      status: '',
      budgetGroups: [],
      showNotes: false,
      note: '',
      tempNote: '',
      preBudgetId: '',
      preOutcomeYear: '',
      statusOK: false,
      statusArr: [],
      establishedStatus: false,
      isLoading: true,
      showSpinner: false,
    };
  },

  methods: {
    updateGroups(group) {
      let index = this.budgetGroups.findIndex(
        budgetGroup => budgetGroup.name === group.name
      );
      this.budgetGroups[index] = group;
      this.$store.dispatch('businessboardBudget/fetchForecast', {
        id: this.$route.params.id,
      });
    },
    saveNote() {
      this.tempNote = this.note;
      this.showNotes = !this.showNotes;
    },
    cancelNote() {
      if (this.note !== '') {
        this.note = this.tempNote;
      } else {
        this.note = this.budget.message;
      }
      this.showNotes = !this.showNotes;
    },
    save() {
      // for beforeRouteLeave
      this.budget.budgetGroups = this.budgetGroups;
      this.budget.name = this.budgetName;
      this.budget.status = this.status;
      this.budget.message = this.note;

      this.showSpinner = true;
      this.$store
        .dispatch('businessboardBudget/patchForecast', {
          id: this.$route.params.id,
          name: this.budgetName,
          status: this.status,
          budgetGroups: this.budgetGroups,
          message: this.note,
          compareFinancialYear: this.preOutcomeYear,
          compareBudgetId: this.preBudgetId,
        })
        .then(() => {
          this.showSpinner = false;
        });
    },
    deleteBudget() {
      if (confirm(this.$t('Budget.deleteWarning'))) {
        this.$store
          .dispatch('businessboardBudget/removeForecast', { id: this.id })
          .then(() => {
            this.$router.push({
              path: '/businessboard/data/forecast/' + this.companyId,
            });
          });
      }
    },
    exportForecast(companyName, forecastName, financialYear) {
      this.showSpinner = true;
      this.$store
        .dispatch('businessboardBudget/exportForecast', {
          budgetId: this.id,
          fileName:
            companyName + '_' + forecastName + '_' + financialYear + '.xlsx',
        })
        .then(() => {
          this.showSpinner = false;
        });
    },
  },

  computed: {
    budget() {
      return this.$store.getters['businessboardBudget/forecast'];
    },
    previousBudget() {
      return this.$store.getters['businessboardBudget/previousBudget'];
    },
    previousOutcome() {
      return this.$store.getters['businessboardBudget/previousOutcome'];
    },
    budgetResultsYearSelect() {
      return this.$store.getters['businessboardBudget/budgetResultsYearSelect'];
    },
    computedResult() {
      let sum = 0;
      if (!this.budget.budgetGroups) return sum;
      this.budget.budgetGroups.forEach(element => {
        sum += (element.isCostGroup ? -1 : 1) * element.value;
      });
      return sum.toLocaleString();
    },
  },
  watch: {
    preBudgetId() {
      this.isLoading = true;
      this.$store
        .dispatch('businessboardBudget/fetchPreviousBudget', {
          id: this.id,
          compareId: this.preBudgetId,
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    preOutcomeYear() {
      this.isLoading = true;
      this.$store
        .dispatch('businessboardBudget/fetchPreviousOutcome', {
          id: this.preBudgetId,
          financialYear: this.preOutcomeYear,
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  created() {
    this.$store.commit('businessboardBudget/previousBudget', {});
    this.$store.commit('businessboardBudget/previousOutcome', {});
    this.isLoading = true;
    this.companyId = this.$route.params.companyId;
    this.$store
      .dispatch('businessboardBudget/fetchForecast', {
        id: this.$route.params.id,
      })
      .then(() => {
        this.thisBudget = this.budget;
        this.budgetName = this.budget.name;
        this.budgetGroups = this.budget.budgetGroups;
        this.status = this.budget.status;
        this.note = this.budget.message;
        this.tempNote = this.budget.message;
        this.preOutcomeYear = this.budget.compareFinancialYear;
        this.preBudgetId = this.budget.compareBudgetId;
      })
      .then(() => {
        this.$store.dispatch(
          'businessboardBudget/fetchBudgetResultsYearSelect'
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  beforeRouteLeave: function (to, from, next) {
    if (
      this.status !== '' &&
      (this.budgetGroups !== this.budget.budgetGroups ||
        this.budgetName !== this.budget.name ||
        this.status !== this.budget.status ||
        this.note !== this.budget.message)
    ) {
      if (confirm(this.$t('Budget.saveWarning3')) === true) {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.businessboard--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .status-block {
    margin: auto 0;
    position: relative;
    span {
      margin-right: 10px;
    }

    select {
      border: none;
    }

    &__status-warning {
      color: red;
      max-width: 400px;
    }

    &__button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        height: 30px;
      }
    }

    &__notes-button {
      border: none;
      background-color: white;
      color: color(blue);
      margin: 10px 30px;
      :hover {
        color: color(blue-dark);
      }
      :active {
        color: color(blue);
      }
      span,
      img {
        vertical-align: middle;
      }
    }

    &__notes-box {
      border: 1px solid #efefef;
      background-color: white;
      position: absolute;
      padding: 10px;
      box-shadow: 4px 4px 4px 0px #00000026;
      z-index: 2;

      textarea {
        border: 1px solid #bfbfbf;
        border-radius: 2px;
        height: 200px;
        width: 300px;
      }
    }
    &__button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        height: 30px;
      }
    }

    &__status-section {
      span,
      img,
      select {
        vertical-align: middle;
      }
    }
  }
}

.name-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  &__block {
    display: flex;
    flex-direction: column;

    input,
    select {
      height: 40px;
      border: 1px solid color(gray);
    }
  }

  &__block--input {
    width: 30%;
  }
  &__block--select {
    width: 20%;
  }

  &__block--year {
    border: none !important;
  }
}

.budget-category {
  margin-bottom: 20px;
}

.save-budget {
  position: fixed;
  bottom: 50px;
  right: 50px;
}
.svg {
  height: 18px;
  margin: 0 5px 0 10px;
}
.inline-right-button {
  float: right;
}
.established-status-warning {
  font-size: 20px;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
  color: red;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.element--is-loading-before:before,
.element--is-loading-after:after {
  position: fixed;
  bottom: 50px;
  right: 50px;
}
.saving-spinner {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 5rem 5rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.computed-result {
  background-color: #27645c;
  color: #ffffff;
  padding: 15px;
  > div {
    width: 30%;
    > span {
      font-size: 24px;
      font-weight: bold;
    }
    > span:last-child {
      font-size: 18px;
      line-height: 24px;
      float: right;
    }
  }
}
.businessboard--header {
  .button {
    margin-top: 0.5rem;
    width: 10rem;
  }
}
</style>
