/* Store module to handle OnService system message */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  systemMessages: {
    rows: [],
    columns: [],
  },
  systemMessage: {},
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
};

const mutations = {
  systemMessages(state, payload) {
    state.systemMessages.rows = payload.rows;
    state.systemMessages.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
  systemMessage (state, payload) {
    state.systemMessage = payload;
  },
  createSystemMessage (state, payload) {
    state.systemMessages.rows.push(payload);
  },
  saveSystemMessage (state, payload) {
    state.systemMessage = payload;
  },
  deleteSystemMessage (state, id) {
    Vue.delete(state.systemMessages.rows, state.systemMessages.rows.findIndex(message => message.id === id));
  },
};

const getters = {
  systemMessages: state => {
    return state.systemMessages;
  },
  systemMessage: state => {
    return state.systemMessage;
  },
  pagination: (state) => {
    return state.pagination;
  },
  currentPage: (state) => {
    return state.pagination.currentPage;
  },
  nextPage: (state) => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: (state) => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
};

const actions = {
  fetchSystemMessages: async (context, params) => {
    let path = 'systemmessages';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params).map(k => {
        if(params[k] !== undefined) {
          return esc(k) + '=' + esc(params[k]);
        }
      }).filter(q => q !== undefined).join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('systemMessages', data.result);
    }
  },
  fetchSystemMessage: async (context, params) => {
    let path = 'systemmessages/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('systemMessage', data.result);
    }
  },
  createSystemMessage: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'onservice-create-system-message', { root: true });
    let path = 'systemmessages';

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'onservice-create-system-message', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('createSystemMessage', data.result);
    }
  },
  saveSystemMessage: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'onservice-save-system-message', { root: true });
    let path = `systemmessages/${params.id}`;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'onservice-save-system-message', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('saveSystemMessage', data.result);
    }
  },
  deleteSystemMessage: async (context, params) => {
    const path = 'systemmessages/' + params.id;
    let response = {};
    try {
      response = await Vue.axios.delete(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
      throw(new Error(error.response.data.message));
    }

    if(response && response.status === 200) {
      context.commit('deleteSystemMessage', params.id);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
