<template>
  <div>
    {{ text }}
    {{
      `${$t('MonthsShort.Jan').toLowerCase()} -
       ${$t(`MonthsShort.${monthTo}`).toLowerCase()}`
    }}
    {{ actualYear }}
  </div>
</template>

<script>
import LocalizationMixin from '@/mixins/localization-mixin.vue';
export default {
  mixins: [LocalizationMixin],
  props: {
    text: {
      type: String,
      default: '',
    },
    actualYear: {
      type: Number,
      required: true,
    },
    monthTo: {
      type: Number,
      required: true,
    },
  },
};
</script>
