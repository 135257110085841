<template>
  <div class="bar-chart-loader">
    <div
      class="bar"
      style="animation-duration: 2s"></div>
    <div
      class="bar"
      style="animation-duration: 2.4s"></div>
    <div
      class="bar"
      style="animation-duration: 2.8s"></div>
    <div
      class="bar"
      style="animation-duration: 3.2s"></div>
    <div
      class="bar"
      style="animation-duration: 3.6s"></div>
  </div>
</template>

<script>
export default {
  name: 'BarChartLoader',
};
</script>

<style scoped>
@keyframes pulsate {
  0% {
    height: 20%;
    background-color: #f3f3f3;
  }
  50% {
    height: 80%;
    background-color: #24655d;
  }
  100% {
    height: 20%;
    background-color: #f3f3f3;
  }
}

.bar-chart-loader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 30rem;
  width: 15rem;
  margin: 3rem;
  padding-bottom: 2rem;
}

.bar {
  width: 10%;
  animation: pulsate infinite;
}
</style>
