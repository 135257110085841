<template>
  <button
    class="te-select-card"
    :size="size"
    :theme="theme">
    <div class="te-select-card-icon tietoevry-icons-add"></div>
    <div class="te-select-card-title">{{ this.title }}</div>
    <div class="te-select-card-description">{{ this.description }}</div>
  </button>
</template>

<script>
export default {
  name: 'TeSelectCard',
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    size: {
      type: String,
    },
    theme: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.te-select-card {
  font-family: 'Tietoevry sans 1';
  transition: outline 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  position: relative;
  display: flex;
  width: 188px;
  margin-top: 14px;
  border-radius: var(--border-radius-xl-60-px, 12px);
  background-color: #fff;
  box-shadow: 0px 9px 24px 0px rgba(20, 75, 110, 0.12);
  padding: var(--paddings-padding-l, 32px) var(--paddings-padding-m, 16px);
  flex-direction: column;
  align-items: center;
  gap: var(--paddings-padding-m, 16px);
  border: none;
  outline: 1px solid transparent;
  .te-select-card-icon {
    color: var(--gray-gray-5);
    width: 80px;
    height: 80px;
    font-size: 80px;
    user-select: none;
  }
  .te-select-card-title {
    align-self: stretch;
    color: var(--text-text-cta-tertiary, #282c2f);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    user-select: none;
  }
  .te-select-card-description {
    display: flex;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: var(--text-text-tertiary, #64676a);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    user-select: none;
  }
}
.te-select-card:hover:not([disabled]) {
  outline-color: var(--dividers-borders-border-selector-hover, #1861f2);
  box-shadow: 0px 9px 24px 0px rgba(20, 75, 110, 0.12);
}
.te-select-card:focus-visible:not([disabled]) {
  outline-color: var(--dividers-borders-border-selector-hover, #1861f2);
  box-shadow: 0px 0px 0px 4px rgba(24, 97, 242, 0.4),
    0px 9px 24px 0px rgba(20, 75, 110, 0.14);
}
.te-select-card:active:not([disabled]) {
  outline-color: var(--blue-blue-2, #0c4fd4);
  box-shadow: 0px 9px 24px 0px rgba(20, 75, 110, 0.12);
}
.te-select-card:disabled {
  opacity: 0.4;
}
.te-select-card[selected] {
  margin-top: 14px;
  outline: 3px solid var(--dividers-borders-border-selector-hover, #1861f2);
  /* Modal shadow */
  box-shadow: 0px 9px 24px 0px rgba(20, 75, 110, 0.12);
  .te-select-card-icon {
    color: var(--blue-blue-3);
  }
  .te-select-card-title {
    color: var(--blue-blue-3);
  }
  .te-select-card-description {
    color: var(--blue-blue-3);
  }
}
.te-select-card[selected]:before {
  transition: all 0.15s ease-in-out;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -17px;
  font-family: 'golf-specific-icons' !important;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 28px;
  content: '\ea03';
  background-color: #fff;
  color: var(--primitives-blue-blue-3);
  width: 28px;
  height: 28px;
  border-radius: 14px;
}
.te-select-card[selected]:focus-visible:not([disabled]) {
  box-shadow: 0px 0px 0px 7px rgba(24, 97, 242, 0.4),
    0px 9px 24px 0px rgba(20, 75, 110, 0.14);
}
.te-select-card[selected]:hover:before,
.te-select-card[selected]:focus-visible:before,
.te-select-card[selected]:active:before {
  content: '\ea02';
}
.te-select-card[selected]:hover:not([disabled]),
.te-select-card[selected]:focus-visible:not([disabled]),
.te-select-card[selected]:active:not([disabled]) {
  background-color: var(--surface-surface-canvas, #f6f6f7);
}
.te-select-card:active:hover:not([disabled]) {
  background-color: var(--surface-surface-canvas, #f6f6f7);
}
.te-select-card[size='small'] {
  border-radius: 6.366px;
  width: auto;
  min-width: 32px;
  padding: var(--border-radius-xl-60-px, 12px) var(--paddings-padding-m, 16px);
  gap: var(--paddings-padding-m, 16px);
  box-shadow: 0px 4px 9px 0px rgba(20, 75, 110, 0.12);
  .te-select-card-icon {
    display: none;
  }
  .te-select-card-title {
    color: var(--text-text-cta-tertiary, #282c2f);
    text-align: center;
    /* Basic Heading 5 */
    font-family: 'Tietoevry sans 1';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  .te-select-card-description {
    display: none;
  }
  &:before {
    display: none;
  }
  &[selected] {
    outline: 1px solid var(--dividers-borders-border-selector-hover, #1861f2);
    box-shadow: 0px 4px 9px 0px rgba(20, 75, 110, 0.12);
    background: var(--surface-surface-cta-primary, #1861f2);
  }
  &[selected] .te-select-card-title {
    color: #fff;
  }
  &:hover:not([disabled]) {
    box-shadow: none;
  }
  &[selected]:hover,
  &[selected]:focus-visible,
  &[selected]:active {
    background: var(--surface-surface-cta-primary, #1861f2);
  }
  &[selected]:focus-visible:not([disabled]) {
    box-shadow: 0px 0px 0px 4px rgba(24, 97, 242, 0.4),
      0px 4px 9px 0px rgba(0, 0, 0, 0.12);
  }
}
.te-select-card[theme='onwhite'] {
  outline: 1px solid var(--dividers-borders-border-selector-primary, #a9aaac);
  box-shadow: none;
}
</style>
