<template>
  <div
    v-if="isSettingsModalOpen"
    class="modal--wrapper">
    <div class="modal--content">
      <div class="modal--header">
        <p class="breadcrumb">
          {{ $t('MemberCalculation.memberFeeTitle') }}
        </p>
        <h2 class="modal--title">
          {{ $t('Businessboard.settings') }}
        </h2>
      </div>
      <div class="modal--body">
        <manage-fees class="margin--to--content--below" />
        <manage-age-groups />
      </div>
    </div>
    <close-modal-button
      :isModalOpen="isSettingsModalOpen"
      @closeModal="e => closeModal(e)" />
  </div>
</template>

<script>
import ManageFees from '../../manage-fees/manage-fees.vue';
import ManageAgeGroups from '../../manage-age-groups/manage-age-groups.vue';
import closeModalButton from '../../close-modal-button/close-modal-button.vue';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'modal-membership-types-settings',
  mixins: [LocalizationMixin],
  components: {
    ManageFees,
    ManageAgeGroups,
    closeModalButton,
  },
  props: {
    isSettingsModalOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    closeModal(e) {
      this.$emit('closeModal', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal--wrapper {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 30rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 1rem;
  border-radius: 8px;
}

.modal--content {
  padding: 1rem 2rem;
  max-height: 85vh;
  overflow-y: scroll;
}
.modal--header {
  background-color: #fff;
}
.modal--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #131516;
}

.action--buttons {
  display: flex;
  gap: 1rem;
  margin: 2rem 0rem 0rem 1rem;
}

.breadcrumb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7c80;
}
.margin--to--content--below {
  margin-bottom: 4rem;
}
</style>
