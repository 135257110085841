var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider-container"},[_c('div',{staticClass:"slider"}),_c('button',{class:{
      isChecked: this.sliderChecked,
      notChecked: !this.sliderChecked,
    },on:{"click":function($event){return _vm.sliderState()}}},[_c('use-svg',{class:{
        visibleSvg: this.sliderChecked,
        hiddenSvg: !this.sliderChecked,
      },attrs:{"svg":"bb-slider-check-mark","color":"white","stroke":""}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }