import colors from '@/assets/styles/_variables.scss';

export function URLParams(query) {
  const decodeURLParams = search => {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    return hashes.reduce((params, hash) => {
      const split = hash.indexOf('=');

      if (split < 0) {
        return Object.assign(params, {
          [hash]: null,
        });
      }
      const key = hash.slice(0, split);
      const val = hash.slice(split + 1);

      return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
  };

  return decodeURLParams(query);
}

export function pad2(n) {
  return n < 10 ? '0' + n : n;
}

export function currentDateTime() {
  let date = new Date();
  return (
    date.getFullYear().toString() +
    pad2(date.getMonth() + 1) +
    pad2(date.getDate()) +
    '-' +
    pad2(date.getHours()) +
    pad2(date.getMinutes()) +
    pad2(date.getSeconds())
  );
}

export function chunkArray(data, size) {
  return Array.from({ length: Math.ceil(data.length / size) }, (v, i) =>
    data.slice(i * size, i * size + size)
  );
}

/**
 * Check if object is empty
 * Return true if empty, false if not
 * @param {*} obj
 */
export function checkIfEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function paginate(data, pageSize, pageNumber) {
  --pageNumber;
  return data.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
}

export function generatePageRange(currentPage, lastPage, separator = '...') {
  const delta = 2;
  const range = [];
  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(lastPage - 1, currentPage + delta);
    i += 1
  ) {
    range.push(i);
  }
  if (currentPage - delta > 2) {
    range.unshift(separator);
  }
  if (currentPage + delta < lastPage - 1) {
    range.push(separator);
  }
  range.unshift(1);
  if (lastPage !== 1) {
    range.push(lastPage);
  }
  return range;
}

/**
 * Validate password
 * At least 8 characters
 * At least one cap
 * At least one digit
 * At least one special character
 * @param {*} password
 */
export function validatePassword(p) {
  let errors = [];
  if (p.length < 8) {
    errors.push('Your password must be at least 8 characters');
  }
  if (p.search(/[A-Z]/) < 0) {
    errors.push('Your password must contain at least one capital letter');
  }
  if (p.search(/[0-9]/) < 0) {
    errors.push('Your password must contain at least one digit');
  }
  if (p.search(/[!@#$%^&*]/) < 0) {
    errors.push('Your password must contain at least one special character');
  }
  if (errors.length > 0) {
    return errors;
  }
  return true;
}
/**
 * Format date to YYYY-MM-DD for input date
 * @param {*} date
 */
export function formatDateForInputs(date) {
  if (date === undefined) {
    return;
  }
  let d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export const diagramPattern = (color = colors['bb-primary']) => {
  var patternCanvas = document.createElement('canvas');
  const pctx = patternCanvas.getContext('2d', { antialias: false });

  const CANVAS_SIDE_LENGTH = 4;
  const WIDTH = CANVAS_SIDE_LENGTH;
  const HEIGHT = CANVAS_SIDE_LENGTH;
  const DIVISIONS = 10;

  patternCanvas.width = WIDTH;
  patternCanvas.height = HEIGHT;
  pctx.fillStyle = color;

  // Top line
  pctx.beginPath();
  pctx.moveTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), 0);
  pctx.lineTo(0, 0);
  pctx.lineTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Middle line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), HEIGHT);
  pctx.lineTo(0, HEIGHT);
  pctx.lineTo(0, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), 0);
  pctx.lineTo(WIDTH, 0);
  pctx.lineTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Bottom line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.fill();

  return pctx.createPattern(patternCanvas, 'repeat');
};
