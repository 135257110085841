/* Store module to handle todo-menu */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  holes: [],
  loops: [],
  teecolors: [],
  dict: {
    physicalHoles: {},
    loopHoles: {},
    groups: {},
    holeGroups: {},
    tee: {},
    teecolors: {},
  },
  surroundings: '',
  courseguidesettings: {},
  isLoading: false,
  isRendering: false,
  isRenderingHoles: [],
};

const mutations = {
  holes(state, payload) {
    if (!payload || payload.length === 0) {
      state.dict.groups = {};
      state.dict.physicalHoles = {};
      state.dict.holeGroups = {};
      state.holes = payload;
    } else {
      let groups = {};
      let holes = {};
      let holeGroups = {};
      for (let g of payload) {
        groups[g.id] = g;
        for (let h of g.holes) {
          holes[h.id] = h;
          holeGroups[h.id] = g;
          if (h.greenPoints) {
            try {
              h.greenPoints = JSON.parse(h.greenPoints);
            } catch (error) {
              console.error('error parsing greenPoints');
            }
          }
          if (h.teePoints) {
            try {
              h.teePoints = JSON.parse(h.teePoints);
            } catch (error) {
              console.error('error parsing teePoints');
            }
          }
        }
      }
      state.dict.groups = groups;
      state.dict.physicalHoles = holes;
      state.dict.holeGroups = holeGroups;
      state.holes = payload;
    }
  },
  surroundings(state, payload) {
    state.surroundings = payload;
  },
  loops(state, payload) {
    let holes = {};
    let tees = {};
    let loops = {};
    for (let l of payload) {
      loops[l.id] = l;
      for (let h of l.loopHoles) {
        holes[h.id] = h;
      }
      for (let t of l.tees) {
        t.elementId = state.dict.teecolors[t.externalTeeId];
        tees[t.id] = t;
      }
    }
    state.dict.loopHoles = holes;
    state.dict.tees = tees;
    state.loops = loops;
    state.loops = payload;
  },
  teecolors(state, payload) {
    state.alltees = payload;
    let tees = {};
    for (let t of payload) {
      tees[t.gitId] = t.color;
    }
    state.dict.teecolors = tees;
    for (let l in state.dict.loops) {
      for (let t in state.dict.loops[l].tees) {
        state.dict.loops[l].tees[t].elementId =
          state.dict.teecolors[state.dict.loops[l].tees[t].externalTeeId];
      }
    }
    for (let l in state.loops) {
      for (let t in state.loops[l].tees) {
        state.loops[l].tees[t].elementId =
          state.dict.teecolors[state.loops[l].tees[t].externalTeeId];
      }
    }
    for (let t in state.dict.tees) {
      state.dict.tees[t].elementId = state.dict.teecolors[t.externalTeeId];
    }
  },
  courseguidesettings(state, payload) {
    state.courseguidesettings = payload;
  },
  isLoading(state, payload) {
    state.isLoading = payload;
  },
  isRendering(state, payload) {
    state.isRendering = payload;
  },
  isRenderingHoles(state, payload) {
    state.isRenderingHoles = payload;
  },
};

const getters = {
  holes: state => {
    return state.holes;
  },
  loops: state => {
    return state.loops;
  },
  teeColorMap: state => {
    return state.dict.teecolors;
  },
  physicalHolesDict: state => {
    return state.dict.physicalHoles;
  },
  loopHolesDict: state => {
    return state.dict.loopHoles;
  },
  groupHolesDict: state => {
    return state.dict.holeGroups;
  },
  groupDict: state => {
    return state.dict.groups;
  },
  teesDict: state => {
    return state.dict.tees;
  },
  courseguidesettings: state => {
    return state.courseguidesettings;
  },
  isLoading: state => {
    return state.isLoading;
  },
  isRendering: state => {
    return state.isRendering;
  },
  isRenderingHoles: state => {
    return state.isRenderingHoles;
  },
};

const actions = {
  fetchInitalLoad: async (context, params) => {
    await fetch(context, 'courseguide/getholes', 'holes');
    await fetch(context, 'clubs/courseguidesettings', 'courseguidesettings');
    await fetch(context, 'courseguide/getloops', 'loops');
    await fetch(context, 'courseguide/getalltees', 'teecolors');
    await fetch(context, 'mapping/getcoursesurroundings', 'surroundings');
  },
  // fetchCourseGuides: async (context, params) => {
  //   // if(params && params.clubId && params.loopId) {
  //   //   await fetch(context, `courseguides/${params.clubId}/${params.loopId}`, 'courseguides');
  //   // }
  //   await fetch(context, 'courseguides', 'courseguides');
  // },
  fetchHoles: async (context, params) => {
    await fetch(context, 'courseguide/getholes', 'holes');
  },
  fetchLoops: async (context, params) => {
    await fetch(context, 'courseguide/getloops', 'loops');
  },
  fetchTeeColors: async (context, params) => {
    await fetch(context, 'courseguide/getalltees', 'teecolors');
  },
  fetchCourseGuideSettings: async (context, params) => {
    await fetch(context, 'clubs/courseguidesettings', 'courseguidesettings');
  },
  patchCourseGuideSettings: async (context, params) => {
    await patch(context, 'clubs/courseguidesettings', params);
  },
  deleteCourseGuideGroup: async (context, params) => {
    await del(context, `/courseguide/holegroup/${params.holeGroupId}`);
    await fetch(context, 'courseguide/getholes', 'holes');
    await fetch(context, 'courseguide/getloops', 'loops');
  },
  fetchSurroundings: async (context, params) => {
    await fetch(context, 'mapping/getcoursesurroundings', 'surroundings');
  },
  postPubishCourseGuide: async (context, params) => {
    await post(context, `courseguide/publishguide/${params.loopId}`, params);
    await fetch(context, 'courseguide/getloops', 'loops');
  },
  postDataExportAll: async context => {
    await fetchBG(context, 'courseguide/getholes', 'holes');
    await fetchBG(context, 'mapping/getcoursesurroundings', 'surroundings');
    let holesToRender = [];
    for (let group of state.holes) {
      for (let hole of group.holes) {
        if (hole.geometry && hole.surroundings) {
          holesToRender.push(hole.id);
        }
      }
    }
    context.commit('isRenderingHoles', holesToRender);
    for (let group of state.holes) {
      for (let hole of group.holes) {
        if (hole.geometry && hole.surroundings) {
          let data = {
            id: hole.id,
            frame: hole.geometry,
            elements: hole.surroundings,
            surroundings: state.surroundings,
          };
          await postMapRender(
            context,
            process.env.VUE_APP_MAPTOOL_URL + '/rendermapimage',
            data
          );
          const index = holesToRender.indexOf(hole.id);
          if (index > -1) {
            holesToRender.splice(index, 1);
          }
          context.commit('isRenderingHoles', holesToRender);
        }
      }
    }
    await fetchBG(context, 'courseguide/getloops', 'loops');
    await fetchBG(context, 'courseguide/getholes', 'holes');
  },
  postCreateHoleGroupsAndHoles: async (context, params) => {
    for (let g of params.holeGroups) {
      for (let h of g.holes) {
        h.holeNumber = `${h.holeNumber}`;
      }
    }
    await post(context, 'courseguide/createholegroupsandholes', params);
    await fetch(context, 'courseguide/getholes', 'holes');
  },
  putUpdateholegroup: async (context, params) => {
    for (let h of params.holes) {
      h.holeNumber = `${h.holeNumber}`;
    }
    await put(context, 'courseguide/updateholegroup', params);
    await fetch(context, 'courseguide/getholes', 'holes');
  },
  putUpdateLoop: async (context, params) => {
    await put(context, 'courseguide/updateloop', params);
    await fetch(context, 'courseguide/getloops', 'loops');
  },
};
async function fetchBG(context, path, commit) {
  context.commit('isRendering', true);
  let response = {};
  try {
    response = await Vue.axios.get(path);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
  if (response && response.status === 200) {
    const data = response.data;
    context.commit(commit, data.result);
  }
  context.commit('isRendering', false);
}
function createErrorMessage(message, error) {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    return message + ' ' + error.response.data.message;
  }
  return message;
}
async function postMapRender(context, path, body) {
  context.commit('isRendering', true);
  let token = context.rootState.user.token;
  try {
    await Vue.axios.post(path, body, {
      headers: {
        Authentication: 'Bearer ' + token,
      },
    });
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: createErrorMessage('Unable to render map', error),
      request: path,
    });
  }
  context.commit('isRendering', false);
}
async function fetch(context, path, commit) {
  context.commit('isLoading', true);
  let response = {};
  try {
    response = await Vue.axios.get(path);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: createErrorMessage(`Unable to read fetch ${path}`, error),
      request: path,
    });
  }
  if (response && response.status === 200) {
    const data = response.data;
    context.commit(commit, data.result);
  }
  context.commit('isLoading', false);
}
async function del(context, path, commit) {
  context.commit('isLoading', true);
  try {
    await Vue.axios.delete(path);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: createErrorMessage(`Unable to delete ${path}`, error),
      request: path,
    });
  }
  context.commit('isLoading', false);
}
async function post(context, path, body) {
  context.commit('isLoading', true);
  try {
    await Vue.axios.post(path, body);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: createErrorMessage(`Unable to post ${path}`, error),
      request: path,
    });
  }
  context.commit('isLoading', false);
}
async function put(context, path, body) {
  context.commit('isLoading', true);
  try {
    await Vue.axios.put(path, body);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: createErrorMessage(`Unable to put ${path}`, error),
      request: path,
    });
  }
  context.commit('isLoading', false);
}
async function patch(context, path, body) {
  context.commit('isLoading', true);
  try {
    await Vue.axios.patch(path, body);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: createErrorMessage(`Unable to patch ${path}`, error),
      request: path,
    });
  }
  context.commit('isLoading', false);
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
