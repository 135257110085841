<template>
  <div class="ontag">
    <div class="ontag--content">
      <a @click="$router.go(-1)">&laquo; {{ $t('back') }}</a>
      <h1>{{ $t('OnTag.orderDetails') }}</h1>
      <h2 style="margin-top: 0">
        beställnings id: {{ specificorder.orderId }}
      </h2>
      <div class="contact-information">
        Kontaktperson: <strong>{{ specificorder.contactPerson }}</strong>
        <br />
        Epost: <strong>{{ specificorder.email }}</strong>
        <br />
        Telefonnummer: <strong>{{ specificorder.phoneNumber }}</strong>
      </div>
      <br />
      <div class="input">
        <label
          for="order-company"
          class="input__label">
          {{ $t('clubName') }}
        </label>
        <input
          type="text"
          name="order-company"
          id="order-company"
          class="input__input"
          v-model="specificorder.clubName"
          disabled />
      </div>
      <div class="input">
        <label
          for="order-date"
          class="input__label">
          {{ $t('OnTag.ScorecardOrder.orderDate') }}
        </label>
        <input
          type="text"
          name="order-company"
          id="order-company"
          class="input__input"
          v-model="specificorder.orderDateText"
          disabled />
      </div>
      <div class="input">
        <label
          for="order-export-date"
          class="input__label">
          {{ $t('OnTag.ScorecardOrder.orderExportDate') }}
        </label>
        <input
          type="text"
          name="order-export-date"
          id="order-export-date"
          class="input__input"
          v-model="specificorder.exportDateText"
          disabled />
      </div>
      <div class="input">
        <label
          for="order-scorecardTypeText"
          class="input__label">
          {{ $t('OnTag.ScorecardOrder.scorecardTypeText') }}
        </label>
        <input
          type="text"
          name="order-scorecardTypeText"
          id="order-scorecardTypeText"
          class="input__input"
          v-model="specificorder.scorecardTypeText"
          disabled />
      </div>
      <div class="input">
        <label
          for="order-quantity"
          class="input__label">
          {{ $t('OnTag.ScorecardOrder.quantity') }}
        </label>
        <input
          type="text"
          name="order-quantity"
          id="order-quantity"
          class="input__input"
          v-model="specificorder.quantity"
          disabled />
      </div>

      <div v-if="!isPrintingHouse">
        <div class="input">
          <label
            for="order-customerShippingPrice"
            class="input__label">
            {{ $t('OnTag.ScorecardOrder.customerShippingPrice') }}
          </label>
          <input
            type="text"
            name="order-customerShippingPrice"
            id="order-customerShippingPrice"
            class="input__input"
            v-model="specificorder.customerShippingPrice"
            disabled />
        </div>
        <div class="input">
          <label
            for="order-shippingCost"
            class="input__label">
            Fraktkostnad
          </label>
          <input
            type="text"
            name="order-shippingCost"
            id="order-shippingCost"
            class="input__input"
            v-model="specificorder.shippingCost"
            disabled />
        </div>
        <div class="input">
          <label
            for="order-printingCost"
            class="input__label">
            Tryckkostnad
          </label>
          <input
            type="text"
            name="order-printingCost"
            id="order-printingCost"
            class="input__input"
            v-model="specificorder.printingCost"
            disabled />
        </div>
        <div class="input">
          <label
            for="order-statusText"
            class="input__label">
            {{ $t('OnTag.ScorecardOrder.statusText') }}
          </label>
          <input
            type="text"
            name="order-statusText"
            id="order-statusText"
            class="input__input"
            v-model="specificorder.statusText"
            disabled />
        </div>
        <div class="input">
          <label
            for="order-unitprice"
            class="input__label">
            {{ $t('OnTag.ScorecardOrder.unitPrice') }}
          </label>
          <input
            type="text"
            name="order-unitprice"
            id="order-unitprice"
            class="input__input"
            v-model="this.unitPrice"
            disabled />
        </div>
      </div>

      <div class="input">
        <label
          for="order-deliveryAddress"
          class="input__label">
          {{ $t('OnTag.ScorecardOrder.deliveryAddress') }}
        </label>
        <input
          type="text"
          name="order-deliveryAddress"
          id="order-deliveryAddress"
          class="input__input"
          v-model="this.deliveryadress"
          disabled />
      </div>

      <div v-if="!isPrintingHouse">
        <div class="input">
          <label
            for="order-invoiceAddress"
            class="input__label">
            {{ $t('OnTag.ScorecardOrder.orgNumber') }}
          </label>
          <input
            type="text"
            name="order-invoiceAddress"
            id="order-invoiceAddress"
            class="input__input"
            v-model="specificorder.invoiceAddress.orgNumber"
            disabled />
        </div>
        <div class="input">
          <label
            for="order-invoiceAddress"
            class="input__label">
            {{ $t('emailInvoice') }}
          </label>
          <input
            type="text"
            name="order-invoiceAddress"
            id="order-invoiceAddress"
            class="input__input"
            v-model="specificorder.invoiceAddress.email"
            disabled />
        </div>
        <div class="input">
          <label
            for="order-customerComment"
            class="input__label">
            {{ $t('OnTag.ScorecardOrder.customerComment') }}
          </label>
          <input
            type="text"
            name="order-customerComment"
            id="order-customerComment"
            class="input__input"
            v-model="specificorder.comment"
            disabled />
        </div>
        <div class="input">
          <label
            for="order-terpID"
            class="input__label">
            Terp ID
          </label>
          <input
            type="text"
            name="order-terpID"
            id="order-terpID"
            class="input__input"
            v-model="specificorder.invoiceAddress.terpId"
            disabled />
        </div>
      </div>

      <div
        v-if="specificorder.isClubSpecific"
        class="input">
        <label
          for="order-hasChanges"
          class="input__label">
          Lack
        </label>
        <input
          type="text"
          name="order-hasChanges"
          id="order-hasChanges"
          class="input__input"
          :value="specificorder.surface === 2 ? $t('Blank') : 'Matt'"
          disabled />
      </div>

      <div
        v-if="specificorder.isClubSpecific"
        class="input">
        <label
          for="order-hasChanges"
          class="input__label">
          Ändring av lack
        </label>
        <input
          type="text"
          name="order-hasChanges"
          id="order-hasChanges"
          class="input__input"
          :value="specificorder.surfaceChange ? 'Ja' : 'Nej'"
          disabled />
      </div>

      <div
        v-if="specificorder.isClubSpecific"
        class="input">
        <label
          for="order-hasChanges"
          class="input__label">
          Ändring av layout
        </label>
        <input
          type="text"
          name="order-hasChanges"
          id="order-hasChanges"
          class="input__input"
          :value="specificorder.hasChanges ? 'Ja' : 'Nej'"
          disabled />
      </div>

      <div v-if="!isPrintingHouse">
        <div
          v-if="specificorder.isClubSpecific"
          class="input">
          <label
            for="order-changeCost"
            class="input__label">
            Kostnad för ändring
          </label>
          <input
            type="text"
            name="order-changeCost"
            id="order-changeCost"
            class="input__input"
            :value="specificorder.changeCost + ' kr/exkl. moms'"
            disabled />
        </div>
        <div
          v-if="specificorder.isClubSpecific"
          class="input">
          <label
            for="order-changePrice"
            class="input__label">
            Pris för ändring
          </label>
          <input
            type="text"
            name="order-changePrice"
            id="order-changePrice"
            class="input__input"
            :value="specificorder.changePrice + ' kr/exkl. moms'"
            disabled />
        </div>
      </div>

      <div class="input">
        <label
          for="order-adminComment"
          class="input__label">
          Kommentar från tietoevry
        </label>
        <input
          maxlength="200"
          type="text"
          name="order-adminComment"
          id="order-adminComment"
          class="input__input"
          v-model="adminComment"
          :disabled="isPrintingHouse" />
      </div>

      <div v-if="!isPrintingHouse">
        <div
          class="input"
          v-if="specificorder.combinedTodoStatus != ''">
          <label
            for="order-combinedTodoStatus"
            class="input__label">
            Status på CombinedTodo
          </label>
          <input
            type="text"
            name="order-combinedTodoStatus"
            id="order-combinedTodoStatus"
            class="input__input"
            v-model="specificorder.combinedTodoStatus"
            disabled />
        </div>
        <div
          class="input"
          v-if="specificorder.costTodoStatus != ''">
          <label
            for="order-costTodoStatus"
            class="input__label">
            Status på EventTodo
          </label>
          <input
            type="text"
            name="order-costTodoStatus"
            id="order-costTodoStatus"
            class="input__input"
            v-model="specificorder.costTodoStatus"
            disabled />
        </div>
        <div
          class="input"
          v-if="specificorder.fundingTodoStatus != ''">
          <label
            for="order-fundingTodoStatus"
            class="input__label">
            Status på Revenue/Cost
          </label>
          <input
            type="text"
            name="order-fundingTodoStatus"
            id="order-fundingTodoStatus"
            class="input__input"
            v-model="specificorder.fundingTodoStatus"
            disabled />
        </div>
      </div>
      <div class="input">
        <div class="">
          <label
            for="order-trackingNumber"
            class="input__label label__inline">
            Spårningsnummer
          </label>
          <span class="input__label--tracking-link">
            <a
              target="_blank"
              :href="
                'https://www.dhl.com/se-en/home/tracking.html?tracking-id=' +
                trackingNumber
              ">https://www.dhl.com/se-en/home/tracking.html?tracking-id={{
                trackingNumber
              }}</a>
          </span>
        </div>
        <div>
          <input
            type="text"
            maxlength="200"
            name="order-trackingNumber"
            id="order-trackingNumber"
            class="input__input"
            :disabled="specificorder.status != 4 && !isPrintingHouse"
            v-model="trackingNumber" />
          <br />
          <div class="input-group--inline">
            <base-button
              :text="$t('markAsShipped')"
              type="submit"
              :disabled="
                specificorder.status != 4 ||
                this.trackingNumber === '' ||
                this.trackingNumber === 0 ||
                this.trackingNumber === undefined
              "
              @click="shipOrder"
              v-element-loader="{ loaderKey: 'edit-order' }" />
            <base-button
              v-if="specificorder.status == 7"
              :text="$t('markAsNotShipped')"
              type="submit"
              :disabled="specificorder.status != 7"
              @click="unshipOrder"
              v-element-loader="{ loaderKey: 'edit-order' }" />
          </div>
        </div>
      </div>
      <div
        v-if="
          specificorder.shippedBy != undefined && specificorder.shippedBy != ''
        ">
        <p>
          Markerad som skickad:
          {{ specificorder.shippedDateText + ' av ' + specificorder.shippedBy }}
        </p>
      </div>
      <div
        v-if="
          specificorder.exportedBy != undefined &&
          specificorder.exportedBy != ''
        ">
        <p>
          Manuell export:
          {{ specificorder.exportDateText + ' av ' + specificorder.exportedBy }}
        </p>
      </div>
      <div
        v-if="
          specificorder.confirmedBy != undefined &&
          specificorder.confirmedBy != ''
        ">
        <p>
          Bekräftad:
          {{ specificorder.confirmedDate + ' av ' + specificorder.confirmedBy }}
        </p>
      </div>
      <div
        v-if="
          specificorder.canceledBy != undefined &&
          specificorder.canceledBy != ''
        ">
        <p>
          Annullerad:
          {{ specificorder.canceledDate + ' av ' + specificorder.canceledBy }}
        </p>
      </div>
      <div
        v-if="!isPrintingHouse"
        class="ontag--utilitites input-group--inline">
        <base-button
          :text="'Spärra'"
          type="submit"
          @click="showLockOrderModal = true"
          class="button--background-red"
          :disabled="[1, 4, 5].includes(specificorder.status)" />
        <modal-window :show="showLockOrderModal">
          <div class="modal__header">Spärra order</div>
          <div class="modal__content">
            <div>
              <h2>Är du säker på att du vill spärra beställningen?</h2>
            </div>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="showLockOrderModal = false">
            </base-button>
            <base-button
              :text="'Bekräfta'"
              @click="lockOrder"
              class="button--background-red"
              v-element-loader="{ loaderKey: 'status-order' }" />
          </div>
        </modal-window>

        <base-button
          :text="'Frisläpp'"
          type="submit"
          @click="showReleaseOrderModal = true"
          :disabled="specificorder.status != 1" />
        <modal-window :show="showReleaseOrderModal">
          <div class="modal__header">Frisläpp order</div>
          <div class="modal__content">
            <div>
              <h2>Är du säker på att du vill frisläppa beställningen?</h2>
            </div>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="showReleaseOrderModal = false">
            </base-button>
            <base-button
              :text="'Bekräfta'"
              @click="releaseOrder"
              class="button--background-red"
              v-element-loader="{ loaderKey: 'status-order' }" />
          </div>
        </modal-window>

        <base-button
          :text="$t('save')"
          type="submit"
          @click="saveOrder"
          v-element-loader="{ loaderKey: 'edit-order' }" />
        <base-button
          :text="'Exportera'"
          type="submit"
          @click="toggleExportModal"
          :disabled="specificorder.status !== 3" />
        <modal-window :show="showExportModal">
          <div class="modal__header">Markera som exporterad</div>
          <div class="modal__content">
            <div>
              <h2>Är du säker på att du vill markera ordern som exporterad?</h2>
            </div>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="toggleExportModal">
            </base-button>
            <base-button
              :text="'Bekräfta'"
              @click="exportOrder"
              v-element-loader="{ loaderKey: 'export-orders' }" />
          </div>
        </modal-window>
        <base-button
          :text="'Annullera'"
          type="submit"
          @click="toggleCancelOrderModal"
          class="button--background-red"
          :disabled="specificorder.status == 5" />
        <modal-window :show="showCancelOrderModal">
          <div class="modal__header">Annullera order</div>
          <div class="modal__content">
            <div>
              <h2
                v-if="specificorder.status === 1 || specificorder.status === 2">
                Är du säker på att du vill annullera beställningen? Leverantören
                aviseras inte av systemet och du måste kontakta denna och
                meddela att beställningen skall annulleras
              </h2>
              <h2 v-else>
                Är du säker på att du vill annullera beställningen?
              </h2>
            </div>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="toggleCancelOrderModal">
            </base-button>
            <base-button
              :text="'Bekräfta'"
              @click="cancelOrder"
              class="button--background-red"
              v-element-loader="{ loaderKey: 'cancel-order' }" />
          </div>
        </modal-window>
      </div>
    </div>
  </div>
</template>

<script>
// import UseSvg from '@/components/use-svg'
import BaseButton from '@/components/form-elements/base-button';
import ModalWindow from '@/components/modal/modal';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'EditOrder',

  components: {
    // UseSvg
    BaseButton,
    ModalWindow,
  },
  directives: {
    elementLoader,
  },

  props: {
    id: {
      type: String,
      default: '0',
    },
  },

  data() {
    return {
      deliveryadress: '',
      invoiceAddress: '',
      adminComment: '',
      trackingNumber: '',
      unitPrice: '',
      showCancelOrderModal: false,
      showConfirmOrderModal: false,
      showInvoicedModal: false,
      showExportModal: false,
      showLockOrderModal: false,
      showReleaseOrderModal: false,
    };
  },

  watch: {
    'specificorder.adminComment': function (newVal) {
      this.adminComment = newVal;
    },
  },

  methods: {
    saveOrder() {
      this.$store
        .dispatch('scorecardOrder/patchScorecardSpecificOrder', {
          id: this.specificorder.id,
          adminComment: this.adminComment,
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
    shipOrder() {
      this.$store
        .dispatch('scorecardOrder/patchScorecardShipOrder', {
          id: this.specificorder.id,
          trackingNumber: this.trackingNumber,
          status: 7,
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
    unshipOrder() {
      this.$store
        .dispatch('scorecardOrder/patchScorecardShipOrder', {
          id: this.specificorder.id,
          trackingNumber: null,
          status: 4,
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
    toggleConfirmModal() {
      this.showConfirmOrderModal = !this.showConfirmOrderModal;
    },
    exportOrder() {
      if (
        this.specificorder.scorecardType === 1 ||
        this.specificorder.scorecardType === 2
      ) {
        this.$store
          .dispatch('scorecardOrder/postScorecardOrderExportStandard', {
            ids: [this.specificorder.id],
            scorecardType: 'standard',
            value: true,
          })
          .then(() => {
            this.$router.go(-1);
          });
      } else {
        this.$store
          .dispatch('scorecardOrder/postScorecardOrderExportCustomer', {
            ids: [this.specificorder.id],
            scorecardType: 'customer',
            value: true,
          })
          .then(() => {
            this.$router.go(-1);
          });
      }
    },
    confirmOrder() {
      if (this.specificorder.status === 2) {
        this.$store
          .dispatch('scorecardOrder/patchScorecardOrderConfirm', {
            ids: [this.specificorder.id],
            scorecardType: '273x110',
            value: false,
          })
          .then(() => {
            this.$router.go(-1);
          });
      } else {
        this.$store
          .dispatch('scorecardOrder/patchScorecardOrderConfirm', {
            ids: [this.specificorder.id],
            scorecardType: '273x110',
            value: true,
          })
          .then(() => {
            this.$router.go(-1);
          });
      }
    },
    toggleExportModal() {
      this.showExportModal = !this.showExportModal;
    },
    toggleInvoicedModal() {
      this.showInvoicedModal = !this.showInvoicedModal;
    },
    invoiceOrder() {
      if (this.specificorder.invoiced === true) {
        this.$store
          .dispatch('scorecardOrder/patchScorecardOrderInvoiced', {
            ids: [this.specificorder.id],
            scorecardType: '273x110',
            value: false,
          })
          .then(() => {
            this.$router.go(-1);
          });
      } else {
        this.$store
          .dispatch('scorecardOrder/patchScorecardOrderInvoiced', {
            ids: [this.specificorder.id],
            scorecardType: '273x110',
            value: true,
          })
          .then(() => {
            this.$router.go(-1);
          });
      }
    },
    toggleCancelOrderModal() {
      this.showCancelOrderModal = !this.showCancelOrderModal;
    },
    cancelOrder() {
      this.$store
        .dispatch('scorecardOrder/patchScorecardOrderStatus', {
          id: this.specificorder.id,
          ScorecardStatus: 5,
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
    lockOrder() {
      this.$store
        .dispatch('scorecardOrder/patchScorecardOrderStatus', {
          id: this.specificorder.id,
          ScorecardStatus: 1,
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
    releaseOrder() {
      this.$store
        .dispatch('scorecardOrder/patchScorecardOrderStatus', {
          id: this.specificorder.id,
          ScorecardStatus: 3,
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
  },

  computed: {
    specificorder() {
      return this.$store.getters['scorecardOrder/scorecardspecificorder'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    isPrintingHouse() {
      return (
        this.user.roles.OnTag &&
        this.user.roles.OnTag.some(e => e.value === 'PrintingHouse')
      );
    },
  },
  mounted() {
    this.$store
      .dispatch('scorecardOrder/fetchScorecardSpecificOrder', { id: this.id })
      .then(() => {
        this.trackingNumber = this.specificorder.trackingNumber;
        this.adminComment = this.specificorder.adminComment;
        this.unitPrice = `${this.specificorder.unitPrice} kr/st`;
        this.deliveryadress = `${this.specificorder.deliveryCompanyName}, ${this.specificorder.deliveryPostalAddress}, ${this.specificorder.deliveryPostalCode}, ${this.specificorder.deliveryCity}`;
        if (!this.isPrintingHouse) {
          this.invoiceAddress = `${this.specificorder.deliveryCompanyName}, ${this.specificorder.invoiceAddress.postalAddress}, ${this.specificorder.invoiceAddress.postalCode}, ${this.specificorder.invoiceAddress.city}`;
        }
      });
  },
};
</script>

<style lang="scss" scoped>
.contact-information {
  h3 {
    display: inline;
  }
}
.input__label--tracking-link {
  margin-left: 24px;
  font-size: 14px;
  font-style: italic;
  a {
    color: color(ontag-blue);
  }
}
</style>
