<template>
  <modal :show="this.show">
    <div class="download_modal_content">
      <div class="download_modal_header">
        <div class="strong">{{ $t('chooseFormatForDownload') }}</div>

        <div
          class="close"
          @click="closeModal">
          ✕
        </div>
      </div>

      <!-- unstrict equal as index is an int where chosenFormat is a string -->
      <div
        v-for="(format, index) in this.fileFormats"
        :class="['option', { option_selected: chosenFormat == index }]"
        :key="`key_${format.label}`"
        @click="chosenFormat = index.toString()">
        <span class="strong">{{ format.label }}</span>
        <span class="subtitle">{{
          `(${$t(format.descriptionTranslationKey)})`
        }}</span>
        <div class="checkbox">
          <img
            v-show="chosenFormat == index"
            src="@/components/svg/bb-selected-icon.svg"
            alt="" />
        </div>
      </div>

      <div class="download-button-div">
        <base-button
          @click="handleDownloadClicked"
          class="downloadButton"
          :text="$t('download')"
          backgroundColor="bb-primary">
          <img
            src="@/components/svg/bb-download-icon.svg"
            class="download-white-icon"
            alt="" />
        </base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import VueTypes from 'vue-types';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

import Modal from '@/components/modal/modal';
import BaseButton from '@/components/form-elements/base-button';

export default {
  name: 'GolfOfficeFrontEndDownloadModal',

  inject: ['closeModal', 'download'],

  mixins: [LocalizationMixin],

  components: { Modal, BaseButton },

  props: {
    show: VueTypes.bool.def(false),
    fileFormats: VueTypes.arrayOf(
      VueTypes.shape({
        label: VueTypes.string,
        descriptionTranslationKey: VueTypes.string,
      })
    ).def(() => [
      { label: 'JPG', descriptionTranslationKey: 'whiteBackground' },
      { label: 'PNG', descriptionTranslationKey: 'transparentBackground' },
    ]),
    selectedImageFormat: VueTypes.string,
  },

  data() {
    return {
      chosenFormat: this.selectedImageFormat,
    };
  },

  mounted() {},

  methods: {
    handleDownloadClicked() {
      this.download(this.chosenFormat);
    },
  },
};
</script>

<style lang="scss" scoped>
.strong {
  font-weight: bold;
}

.download_modal {
  &_content {
    text-align: left;

    .option {
      cursor: pointer;
      display: flex;
      gap: 5px;
      padding: 1rem;

      .checkbox {
        margin-left: auto;
      }
    }

    .option_selected {
      background: color(white-modal);
    }
  }

  &_header {
    background-color: color(green-fog);
    font-weight: 500;
    font-size: 16px;
    display: flex;
    padding: 1rem;

    .close {
      margin-left: auto;
      cursor: pointer;
    }

    border-radius: 3px 3px 0 0;
  }
}
.downloadButton {
  margin: 0 1rem;
  margin-top: 0.5rem;

  ::v-deep .button {
    border-radius: 3px;
  }
}

.download-button-div {
  display: flex;
  flex-flow: column;
}

.download-white-icon {
  margin-right: 0.625rem;
  margin-bottom: 0.125rem;
  filter: invert(100%);
}
</style>
