import colors from '@/assets/styles/_variables.scss';

export const legends = [
  { style: `background: ${colors['bb-primary']}`, type: 'box' },
  {
    style: `background: ${colors.white}; border: 2px solid ${colors['bb-primary']}`,
    type: 'box',
  },
  { style: `background: ${colors['bb-sec6']}`, type: 'box' },
  { style: `border-top: 2px solid ${colors['bb-sec2']}`, type: 'line' },
];
