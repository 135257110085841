<template>
  <div class="course-guide">
    <course-guides-mapping-tool
      v-if="showMappingTool"
      @close="hideMaps()" />
    <te-modal :show="modalHolePlacementVisible">
      <template v-slot:header>Hole placement</template>
      <template v-slot:content></template>
      <template v-slot:actions>
        <button
          class="te-btn te-btn-md te-btn-primary"
          @click="hideModal('loopRequiresPlacement')">
          Set hole order
        </button>
      </template>
    </te-modal>
    <div class="course-guide-main">
      <h1>Course guides</h1>
      <te-tabbar
        class="course-guide-tabbar"
        :items="tabItems"
        :currentItem="selectedTabItem"
        @select="currentItemChanged">
        <template v-slot:buttons>
          <button
            class="te-btn te-btn-sm te-btn-primary"
            :disabled="!hasAnyPhysicalHoles"
            @click="showMaps">
            Mapping tool
          </button>
        </template>
        <template v-slot:content>
          <course-guides-holes
            :class="
              selectedTabItem && selectedTabItem.id == 1 ? 'visible' : 'hidden'
            "
            :groups="localGroups" />
          <course-guide-loops
            :class="
              selectedTabItem && selectedTabItem.id == 2 ? 'visible' : 'hidden'
            "
            :loops="localLoops"
            :editLoops="editLoops"
            :dropProperties="dropProperties"
            @editLoopChanged="editLoopChanged"
            @showMappingTool="showMaps"
            @loopChanged="loopChanged"
            @loopSelectionChanged="loopSelectionChanged" />
        </template>
      </te-tabbar>
    </div>
    <div
      v-if="this.selectedTabItem !== null && this.selectedTabItem.id == 2"
      :class="[
        'course-guide-left-side',
        this.editLoops ? 'course-guide-left-side-scrollable' : '',
      ]">
      <h3
        v-if="editLoops"
        :class="[sideScrollTop ? '' : 'scrolling-border']">
        Physical holes
      </h3>
      <div
        @scroll.passive="onScroll"
        v-if="editLoops"
        class="groups-list-container"
        popover-id="set-hole-order">
        <course-guides-groups-list
          class="course-guide-group-list"
          :groups="this.localGroups"
          :loop="this.selectedLoop"
          @courseDataDragChanged="this.dragChanged">
        </course-guides-groups-list>
      </div>
      <te-popover
        class="course-guide-main-popover-help"
        target="set-hole-order"
        :show="dropHolesPopoverVisible"
        title="Drag and drop"
        position="left">
        Drag and drop the physical holes into the placeholders to set the hole
        order for this guide.
        <video
          width="100%"
          autoplay
          loop
          muted
          controls>
          <source
            src="/coursedataimages/drop-holes.mp4"
            type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <button
          class="te-btn te-btn-md te-btn-primary"
          style="align-self: flex-end"
          @click="hidePopover('set-hole-order')">
          Got it
        </button>
      </te-popover>
      <div
        v-if="!editLoops && selectedLoop !== null"
        class="course-guide-steps">
        <h3>Couse guide setup process</h3>
        <te-stepper
          :items="stepItems"
          :currentItem="selectedStepItem"
          stepStyle="vertical">
          <div v-if="selectedStepItem && selectedStepItem.id == 1">
            Something
          </div>
          <div v-if="selectedStepItem && selectedStepItem.id == 2">
            <button
              @click="startPlaceHoles(!editLoops)"
              class="te-btn te-btn-sm te-btn-primary">
              Place holes
              <span
                class="tietoevry-icons-arrow-small-right"
                style="font-size: 130%"></span>
            </button>
          </div>
          <div v-if="selectedStepItem && selectedStepItem.id == 3">
            <button
              @click="showMaps"
              class="te-btn te-btn-sm te-btn-primary">
              Open mapping tool
              <span
                class="tietoevry-icons-arrow-small-right"
                style="font-size: 130%"></span>
            </button>
          </div>
          <div v-if="selectedStepItem && selectedStepItem.id == 4">
            <div style="margin-bottom: 10px">
              Publishing feature is currently disabled
            </div>
            <button
              :disabled="canPublish() === false"
              @click="publishGuide()"
              class="te-btn te-btn-sm te-btn-primary">
              Publish
              <span
                class="tietoevry-icons-arrow-small-right"
                style="font-size: 130%"></span>
            </button>
            <div class="coursedata-last-published">
              {{ currentCourseGuideTimeStamp() }}
            </div>
          </div>
        </te-stepper>
      </div>
    </div>
  </div>
</template>

<script>
import CourseGuidesMappingTool from './course-guides-map-overlay.vue';
import CourseGuideLoops from './course-guides-loops.vue';
import CourseGuidesHoles from './course-guides-holes.vue';
import CourseGuidesGroupsList from './course-guides-group-list.vue';
import TeStepper from './components/te-stepper.vue';
import TePopover from './components/te-popover.vue';
import TeTabbar from './components/te-tabbar.vue';
import TeModal from './components/te-modal.vue';
import EventBus from '@/core/event-bus.js';
export default {
  name: 'CourseGuides',
  components: {
    CourseGuideLoops,
    TePopover,
    TeTabbar,
    TeModal,
    TeStepper,
    CourseGuidesGroupsList,
    CourseGuidesHoles,
    CourseGuidesMappingTool,
  },
  watch: {
    loops: {
      handler: function (newVal, oldVal) {
        this.localLoops = newVal;
        this.hasAnyPhysicalHoles = this.hasPhysicalHoles(newVal);
        this.determineProgress();
      },
      deep: true,
    },
    groups: {
      handler: function (newVal, oldVal) {
        this.localGroups = newVal;
        this.determineProgress();
      },
      deep: true,
    },
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    loops: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let hasAnyPhysicalHoles = false;
    if (this.loops && this.loops.length > 0) {
      hasAnyPhysicalHoles = this.hasPhysicalHoles(this.loops);
    }
    return {
      modalHolePlacementVisible: false,
      dropHolesPopoverVisible: false,
      hasAnyPhysicalHoles: hasAnyPhysicalHoles,
      showMappingTool: window.location.pathname.includes('mappingtool'),
      localLoops: this.loops,
      localGroups: this.groups,
      selectedLoop: null,
      dropProperties: null,
      editLoops: false,
      selectedTabItem: null,
      sideScrollTop: true,
      tabItems: [
        {
          id: 1,
          title: 'Hole data',
        },
        {
          id: 2,
          title: 'Guides',
        },
      ],
      selectedStepItem: null,
      stepItems: [
        {
          id: 2,
          title: 'Place physical holes in loops',
          progress: 0,
          description: 'Drag your psysical holes into your existing loops',
        },
        {
          id: 3,
          title: 'Map your course',
          progress: 0,
          description: 'Draw your course and surroundings in our map tool',
        },
        {
          id: 4,
          title: 'Publish course guides',
          progress: 0,
          description: 'Confirm and save',
        },
      ],
    };
  },
  computed: {
    physicalHolesDict() {
      return this.$store.getters['coursedata/physicalHolesDict'];
    },
  },
  methods: {
    hasPhysicalHoles(loops) {
      if (!loops) {
        return false;
      }
      for (let l of loops) {
        for (let h of l.loopHoles) {
          if (h.physicalHole !== 0) {
            return true;
          }
        }
      }
      return false;
    },
    canPublish() {
      return false;
      // if(this.selectedLoop === null) {
      //   return false;
      // }
      // return true;
    },
    currentCourseGuideTimeStamp() {
      if (this.selectedLoop === null) {
        return '';
      }
      if (this.selectedLoop.lastPublished) {
        return 'Pubished ' + this.selectedLoop.lastPublished;
      }
      return 'Never published';
    },
    hideMaps() {
      if (window.location.pathname.includes('mappingtool')) {
        history.pushState({}, null, '/coursedata/holes');
      }
      this.showMappingTool = false;
      this.$store.dispatch('coursedata/fetchHoles');
      this.$store.dispatch('coursedata/fetchLoops');
    },
    showMaps() {
      if (!this.$route.name.includes('mappingtool')) {
        history.pushState({}, null, '/coursedata/holes/mappingtool');
      }
      if (window.chrome === undefined) {
        alert(
          'This feature is not supported in your browser. Please use Chrome or Edge.'
        );
      } else {
        this.showMappingTool = true;
      }
    },
    hideModal(id) {
      this.modalHolePlacementVisible = false;
      this.editLoops = true;
    },
    showModal(id) {
      this.modalHolePlacementVisible = true;
    },
    hidePopover(id) {
      if (id === 'set-hole-order') {
        localStorage.setItem('set-hole-order-popover-shown', true);
      }
      this.dropHolesPopoverVisible = false;
    },
    showPopover(id) {
      this.dropHolesPopoverVisible = !this.dropHolesPopoverVisible;
    },
    currentItemChanged(item) {
      this.selectedTabItem = item;
    },
    loopChanged(data) {
      this.localLoops = data;
      this.determineProgress();
    },
    publishGuide() {
      if (this.selectedLoop) {
        this.$store.dispatch('coursedata/postPubishCourseGuide', {
          loopId: this.selectedLoop.id,
        });
      } else {
        EventBus.$emit('toast', {
          type: 'error',
          msg: 'Unknown loop, unable to publish guide. Please select loop and try again.',
        });
      }
    },
    editLoopChanged(b) {
      if (b && !localStorage.getItem('set-hole-order-popover-shown')) {
        this.showPopover('set-hole-order');
      }
      this.editLoops = b;
      for (let t of this.tabItems) {
        t.disabled = b;
      }
      this.determineProgress();
    },
    startPlaceHoles(b) {
      // this.showModal();
      this.selectedTabItem = this.tabItems[1];
      this.localLoops = this.loops;
      this.editLoops = true;
    },
    loopSelectionChanged(data) {
      if (data) {
        this.selectedLoop = this.localLoops.find(l => l.id === data);
      } else {
        this.selectedLoop = null;
      }
      this.determineProgress();
    },
    determineProgress() {
      if (this.localGroups.length > 0) {
        this.stepItems[0].progress = 100;
      }
      if (this.selectedLoop) {
        let p1 = 0;
        let p2 = 0;
        let v = 100 / this.selectedLoop.loopHoles.length;
        for (let h of this.selectedLoop.loopHoles) {
          if (h.physicalHoleId !== 0) {
            p1 += v;
            if (
              this.physicalHolesDict[h.physicalHoleId] &&
              this.physicalHolesDict[h.physicalHoleId].geometry
            ) {
              p2 += v;
            }
          }
        }
        this.stepItems[0].progress = Math.floor(p1);
        this.stepItems[1].progress = Math.floor(p2);
      }
      let s = null;
      for (let step of this.stepItems) {
        if (step.progress !== 100) {
          s = step;
          break;
        }
      }
      if (s === null) {
        this.selectedStepItem = this.stepItems[this.stepItems.length - 1];
      } else {
        this.selectedStepItem = s;
      }
    },
    dragChanged(data) {
      if (data == null) {
        this.dropProperties = null;
        return;
      }
      this.dropProperties = data;
    },
    onScroll(e) {
      this.sideScrollTop = e.target.scrollTop === 0;
    },
  },
  mounted() {
    this.selectedTabItem = this.tabItems[1];
    this.determineProgress();
  },
};
</script>

<style lang="scss">
.hidden {
  display: none;
}
.course-guide-main-popover-help {
  .te-popover-content {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}
.course-guide-tabbar {
  .te-tabbar-items {
    padding-right: 18px;
  }
}
.scrolling-border {
  z-index: 1;
  box-shadow: 0px 5px 5px -5px #575757;
}
.course-guide {
  padding-left: 20px;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 72px);
  max-width: 100%;
  max-height: calc(100vh - 72px - 100px);
  display: flex;
  flex-direction: row;
  gap: 10px;
  h1 {
    color: var(--Default-Black, #000);
    padding: 0px 10px;
    /* Heading 1 */
    font-family: 'Tietoevry Sans 1';
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
  }
  .course-guide-main {
    display: flex;
    box-sizing: border-box;

    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: flex-start;
    flex: 1;
  }
  .course-guide-left-side {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    h3 {
      color: var(--Text-Text-Primary, #1e2224);
      /* Basic Heading 2 */
      font-family: 'Tietoevry Sans 1';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    width: 272px;
  }
  .groups-list-container {
    transition: box-shadow 0.2s ease-in-out;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 0 18px 18px 18px;
  }
  .course-guide-left-side-scrollable {
    overflow: hidden;
    background-color: #fff;
    h3 {
      display: flex;
      margin: 0;
      padding: 20px 18px 20px 18px;
    }
  }
  .coursedata-last-published {
    margin-top: 10px;
    color: var(--Text-Text-Primary, #1e2224);
  }
  .course-guide-steps {
    h3 {
      color: var(--Default-Black, #000);
      font-family: 'Tietoevry Sans 1';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin: 0;
      padding: 0;
    }
    margin-top: 18px;
    display: flex;
    padding: var(--Paddings-Padding-M, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Paddings-Padding-M, 16px);
    border-radius: 12px;
    background: var(--Surface-Surface-Primary, #fff);
  }
}
</style>
