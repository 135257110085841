<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <h1>{{ $t('Businessboard.addCategory') }}</h1>
      <form noValidate @submit.prevent="createCategory">
        <div class="content__input-group">
          <div class="input-group">
            <div class="input">
              <label
                for="categoryName"
                class="input__label">{{ $t('Businessboard.categoryName') }}*</label>
              <input
                id="categoryName"
                class="input__input"
                v-model="categoryName"
                type="text"
                required="true"
                name="categoryName"/>
            </div>
            <!-- <div class="exclude-select">
              <p>Exkludera</p>
              <select v-model="selectedOption">
                <option :value="true">Ja</option>
                <option :value="false">Nej</option>
              </select>
            </div> -->
          </div>
          <div class="input-group--inline user__actions">
            <router-link to="/businessboard/data">
              <base-button
              :text="$t('cancel')">
              </base-button>
            </router-link>
              <base-button
                :text="$t('save')"
                type="submit"
                v-element-loader="{ loaderKey: 'create-category' }"
                :disabled="!canCreate"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'EditCategory',
  components: {
    BaseButton,
  },

  directives: {
    elementLoader,
  },

  data() {
    return {
      categoryName: '',
      selectedOption: false,
    };
  },

  methods: {
    createCategory() {
      this.$store.dispatch('businessboard/createMembershipCategory', {
        name: this.categoryName,
        exclude: this.selectedOption,
      }).then(() => {
        this.$router.push({ path: '/businessboard/data' });
      });
    },
  },

  computed: {
    canCreate() {
      return this.categoryName;
    },
  },
};

</script>

<style lang="scss" scoped>
.exclude-select {
  width: 100%;

  p {
    margin-bottom: 3.2px;
  }
  select {
    width: 100%;
    border-color: color(gray);
    height: 33px;
    background-color: color(gray-lighter);
  }
}
</style>
