<template>
  <div class="ontag">
    <div
      class="ontag__content ontag__printer"
      v-if="printer">
      <div>
        <router-link to="/ontag/printers/all-printers">
          &laquo; {{ $t('back') }}
        </router-link>
      </div>
      <h1 class="first-letter">
        {{ $t('printerConfiguration') }}
      </h1>
      <p>
        Namnge skrivarna så tydligt ni kan för att minska missförstånd. Tex
        Reception, Kansli, Terminal, Shop vänster, Shop höger osv. Ni sparar
        inställningen när ni klickat spara nere till höger på sidan. På denna
        sida finner ni även djupare info om skrivaren samt senast kontakt, antal
        utskrivna m.m.
      </p>
      <div>
        <!-- <custom-select
            v-if="selectedIndexClub !== undefined && clubs.rows.length > 0"
            label="Club"
            id="club"
            @optionSelected="setSelectedClub"
            :selected="selectedIndexClub"
            :options="clubs.rows"
            :required="false">
          </custom-select> -->
        <div class="input">
          <label
            for="printer-name"
            class="input__label first-letter">
            {{ $t('club') }}
          </label>
          <input
            type="text"
            class="input__input"
            disabled
            v-model="printer.clubName" />
        </div>
        <div class="input">
          <label
            for="printer-name"
            class="input__label first-letter">
            {{ $t('printerName') }}
          </label>
          <input
            type="text"
            name="printer-name"
            id="printer-name"
            class="input__input"
            required
            v-model="printer.printerName"
            @change="changedInput('printerName', $event.target.value)" />
        </div>
        <div class="input">
          <label
            for="default-printer-for"
            class="input__label first-letter">
            {{ $t('defaultPrinter') }} {{ $t('for') }}
          </label>
          <input
            type="text"
            name="default-printer-for"
            id="default-printer-for"
            class="input__input"
            required
            v-model="printer.defaultPrinter"
            readonly />
        </div>
        <div class="input">
          <label
            for="serial-number"
            class="input__label first-letter">
            {{ $t('serialNumber') }}
          </label>
          <input
            type="text"
            name="serial-number"
            id="serial-number"
            class="input__input"
            required
            v-model="printer.serialNumber"
            readonly />
        </div>
        <div class="input">
          <label
            for="mac-address"
            class="input__label first-letter">
            {{ $t('macAddress') }}
          </label>
          <input
            type="text"
            name="mac-address"
            id="mac-address"
            class="input__input"
            required
            v-model="printer.macAddress"
            readonly />
        </div>
        <div class="input">
          <label
            for="ip-address"
            class="input__label first-letter">
            {{ $t('ipAddress') }}
          </label>
          <a
            :href="'http://' + printer.ipAddress"
            class="underline"
            target="_blank"
            rel="noopener noreferrer">
            {{ printer.ipAddress }}
          </a>
        </div>
        <div
          v-if="printer.status"
          class="printer__status first-letter">
          {{ $t('status') }}
          <p>
            <template v-if="printer.status === 1">
              <use-svg svg="circle-ok" />
              <span class="first-letter">
                {{ $t('ok') }}
              </span>
              <span class="first-letter">
                {{ $t('online') }} {{ $t('since') }}
                {{ formatDateAndTime(printer.lastChanged) }}
              </span>
            </template>
            <template v-if="printer.status === 0">
              <use-svg svg="circle-error" />
              <span class="first-letter">
                {{ $t('error') }}
              </span>
              <span class="first-letter">
                {{ $t('offline') }} {{ $t('since') }}
                {{ formatDateAndTime(printer.lastChanged) }}
              </span>
            </template>
          </p>
        </div>
        <!-- TODO: FIX BELOW -->
        <div class="printer__last-active first-letter">
          {{ $t('lastContactPrinter') }}
          <p>
            {{ formatDateAndTime(printer.lastActive) }}
          </p>
        </div>
        <!-- TODO: FIX BELOW -->
        <!-- <div class="printer__labels first-letter">
            {{ $t('numberOfLabels') }}
            <p>
{{ printer.printQuantity }}
</p>
          </div> -->
        <!-- TODO: FIX BELOW -->
        <div class="printer__courses first-letter">
          <div>
            {{ $t('connectedCourses') }}
            <ul>
              <li
                v-for="(course, i) in printer.connectedCourses"
                :key="i">
                {{ course }}
              </li>
            </ul>
          </div>
        </div>
        <div class="input">
          <label
            for="comment"
            class="input__label first-letter">
            {{ $t('comment').toLowerCase() }}
          </label>
          <textarea
            type="text"
            name="comment"
            id="comment"
            class="input__textarea"
            v-model="printer.comment"
            @change="changedInput('comment', $event.target.value)" />
        </div>
        <expand-collapse-variant
          icon="cogs-icon"
          v-if="user.superUser === true">
          <template slot="title">
            {{ $t('advancedSettings') }}
          </template>
          <template slot="content">
            <!-- TODO: FIX ALL BELOW -->
            <div>
              <div class="input">
                <label
                  for="feed"
                  class="input__label">
                  Feed
                </label>
                <input
                  type="text"
                  name="feed"
                  id="feed"
                  class="input__input"
                  required
                  v-model="printer.paperFeed"
                  @change="changedInput('paperFeed', $event.target.value)" />
              </div>
              <div class="input">
                <label
                  for="backing"
                  class="input__label">
                  Backing
                </label>
                <input
                  type="text"
                  name="backing"
                  id="backing"
                  class="input__input"
                  required
                  v-model="printer.reversePaperFeed"
                  @change="
                    changedInput('reversePaperFeed', $event.target.value)
                  " />
              </div>
              <div class="input">
                <label
                  for="intensity"
                  class="input__label">
                  Intensity
                </label>
                <input
                  type="text"
                  name="intensity"
                  id="intensity"
                  class="input__input"
                  required
                  v-model="printer.intensity"
                  @change="changedInput('intensity', $event.target.value)" />
              </div>
              <div class="input">
                <label
                  for="intensity"
                  class="input__label">
                  Speed
                </label>
                <input
                  type="text"
                  name="printSpeed"
                  id="printSpeed"
                  class="input__input"
                  required
                  v-model="printer.printSpeed"
                  @change="
                    changedInput('printSpeed', $event.target.value, true)
                  " />
              </div>
              <div
                v-if="
                  printer &&
                  printer.logs &&
                  printer.logs.rows &&
                  printer.logs.columns &&
                  printer.logs.rows.length > 0
                ">
                <p>Log</p>
                <list-table
                  :items="printer.logs.rows"
                  :headers="printer.logs.columns"
                  :use-cursor="false">
                </list-table>
              </div>
            </div>
            <div class="printer__labels first-letter">
              {{ $t('numberOfPrintsToday') }}
              <p>
                {{ printer.printStatistics }}
              </p>
            </div>
            <div class="printer__labels first-letter">
              {{ $t('numberOfTemplatesToday') }}
              <p>
                {{ printer.templateStatistics }}
              </p>
            </div>
            <div class="printer__labels first-letter">
              {{ $t('numberOfVouchersToday') }}
              <p>
                {{ printer.voucherStatistics }}
              </p>
            </div>
          </template>
        </expand-collapse-variant>
        <div class="input-group--inline printer__actions">
          <base-button
            :text="$t('restartPrinter')"
            @click="restartPrinter" />
          <!-- TODO: Fix testprint modal -->
          <base-button
            :text="$t('testPrint')"
            @click="toggleTestPrintModal" />
          <modal-window :show="showTestPrintModal">
            <div class="modal__header">
              {{ $t('testPrint') }}
            </div>
            <div class="modal__content">
              <!-- v-if="selectedIndexPrinter !== undefined" -->
              <!-- <custom-select
                  :required="false"
                  @optionSelected="setPrinter"
                  :selected="selectedIndexPrinter"
                  :options="printers"/> -->
            </div>
            <div class="modal__content--actions">
              <base-button
                class="button--background-gray-dark"
                text="Cancel"
                @click="toggleTestPrintModal">
              </base-button>
              <base-button
                :text="$t('testPrint')"
                @click="testPrint" />
            </div>
          </modal-window>
          <base-button
            :text="$t('save')"
            type="submit"
            @click="savePrinter"
            :disabled="!canISave" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
// import CustomSelect from '@/components/form-elements/custom-select'
import UseSvg from '@/components/use-svg';
import ExpandCollapseVariant from '@/components/expand-collapse-variant';
import ListTable from '@/components/list-table/list-table';
import ModalWindow from '@/components/modal/modal';

export default {
  name: 'PrinterEdit',
  components: {
    BaseButton,
    // CustomSelect,
    UseSvg,
    ExpandCollapseVariant,
    ListTable,
    ModalWindow,
  },
  props: {
    id: String,
  },
  data() {
    return {
      clubName: '',
      clubId: '',
      selectedIndexClub: undefined,
      selectedIndexSpeed: undefined,
      showTestPrintModal: false,
      params: {},
    };
  },
  methods: {
    setSelectedClub(option) {
      this.clubId = option.value;
      this.clubName = option.displayName;
    },
    selectedClub(club) {
      let index = this.clubs.rows.findIndex(c => c.displayName === club);
      this.selectedIndexClub = index;
    },
    testPrint() {
      // eslint-disable-next-line no-console
      console.log('testPrint');
    },
    savePrinter() {
      this.$store
        .dispatch('printers/patchPrinter', { id: this.id, data: this.printer })
        .then(() => {
          this.$router.push({ path: '/ontag/printers/all-printers' });
          this.$toasted.success('Printer saved');
        });
      this.params = {};
    },
    toggleTestPrintModal() {
      this.showTestPrintModal = !this.showTestPrintModal;
    },
    restartPrinter() {
      this.$store.dispatch('printers/restartPrinter', { id: this.id });
    },
    formatDateAndTime(dateAndTime) {
      const dateString = new Date(dateAndTime).toLocaleDateString('sv-SE');
      const timeString = new Date(dateAndTime).toLocaleTimeString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return dateString + ' ' + timeString;
    },
    changedInput(input, value, number = false) {
      if (number === true) {
        value = parseInt(value, 10);
      }
      this.params[input] = value;
    },
  },
  computed: {
    printer() {
      return this.$store.getters['printers/printer'];
    },
    clubs() {
      return this.$store.getters['clubs/clubs'];
    },
    canISave() {
      return this.printer.printerName && this.printer.clubName;
    },
    user() {
      return this.$store.getters['user/user'];
    },
  },
  mounted() {
    if (this.id) {
      this.$store
        .dispatch('printers/fetchPrinter', { id: this.id })
        .then(() => {
          if (this.$store.getters['user/user'].superUser === true) {
            this.$store.dispatch('clubs/fetchClubs', null).then(() => {
              this.selectedClub(this.printer.clubName);
            });
          }
        });
    }
  },
};
</script>
<style lang="scss">
.ontag__printer {
  .input .input__textarea {
    height: 12.5rem;
    resize: none;
  }
  .printer__actions {
    justify-content: flex-start;
    @include media('small') {
      justify-content: flex-end;
      width: 100%;

      button {
        margin-left: 1.25rem;
      }
    }
  }
  .expand-collapse-variant__content-inner {
    .input .input__input,
    .select__wrapper {
      width: 100%;
      @include media('medium') {
        width: 40%;
      }
      @include media('large') {
        width: 20%;
      }
    }
  }
  .printer__status {
    p {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;

      .svg {
        margin-right: 0.2rem;
      }

      span {
        margin-left: 0.2rem;
      }
    }
  }
  .printer__last-active {
    p {
      margin-top: 0.2rem;
    }
  }
  .printer__labels {
    p {
      margin-top: 0.2rem;
    }
  }
  .printer__courses {
    p {
      margin-top: 0.2rem;
    }
  }
}
</style>
