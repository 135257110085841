<template>
  <div class="onservice">
    <div class="onservice--content">
      <h1>Transaktioner</h1>
      <section class="transactions--dates">
        <div class="input">
          <label
            for="activeFrom"
            class="input__label">Från</label>
          <input
            id="activeFrom"
            class="input__input"
            v-model="transactionFrom"
            type="date"
            required="false"
            name="activeFrom" />
        </div>
        <div class="input">
          <label
            for="activeTo"
            class="input__label">Till</label>
          <input
            id="activeTo"
            class="input__input"
            v-model="transactionTo"
            type="date"
            required="false"
            name="activeTo" />
        </div>
        <div class="active--update">
          <base-button
            text="Uppdatera"
            :button-size-small="true"
            @click="getDateResults">
            <use-svg svg="update-icon" />
          </base-button>
        </div>
      </section>
      <section class="transactions--actions">
        <div class="pagination-amount input-group--inline">
          <span>{{ $t('show') }}</span>
          <custom-select
            :required="false"
            @optionSelected="setPageSize"
            :selected="selectedIndexPageSize"
            :options="pageSizeOptions" />
          <span class="row-lines">{{ $t('lines') }}</span>
        </div>
        <div class="transactions--export">
          <div class="input">
            <label
              for="searchQuery"
              class="input__label label__inline">{{ $t('search') }}</label>
            <input
              id="searchQuery"
              class="input__input input__inline search-input"
              @keyup.enter="getVariatedResults"
              v-model="searchQuery"
              type="text"
              name="searchQuery" />
          </div>
          <base-button
            class="search-button"
            :text="$t('search')"
            :button-size-small="true"
            @click="getVariatedResults" />
          <base-button
            :text="$t('Export')"
            :button-size-small="true"
            v-element-loader="{ loaderKey: 'transactions-export-transactions' }"
            @click="exportTransactions">
            <use-svg svg="export-icon" />
          </base-button>
        </div>
      </section>
      <section v-if="result.rows">
        <list-table
          :items="result.rows"
          :headers="result.columns"
          @variationResults="getVariatedResults">
        </list-table>
        <results-pagination
          :first-row-on-page="pagination.firstRowOnPage"
          :last-row-on-page="pagination.lastRowOnPage"
          :row-count="pagination.rowCount"
          :page-size="pagination.pageSize"
          :page-count="pagination.pageCount"
          :current-page="pagination.currentPage"
          :next-page="getNextPage()"
          :prev-page="getPrevPage()"
          store="transactions"
          @currentPage="getVariatedResults" />
      </section>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import ResultsPagination from '@/components/results-pagination';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import UseSvg from '@/components/use-svg';
import elementLoader from '@/directives/element-loader';
export default {
  name: 'OnServiceTransactions',
  components: {
    ListTable,
    ResultsPagination,
    BaseButton,
    CustomSelect,
    UseSvg,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      selectedIndexPageSize: 1,
      searchQuery: '',
      sort: {},
      currentPage: 0,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
      transactionFrom: '',
      transactionTo: '',
    };
  },
  computed: {
    result() {
      return this.$store.getters['transactions/transactions'];
    },
    pagination() {
      return this.$store.getters['transactions/pagination'];
    },
  },
  created() {
    const today = new Date();
    const firstDay = new Date();
    // firstDay.setMonth(0)
    firstDay.setDate(1);
    this.transactionTo = today.toISOString().slice(0, 10);
    this.transactionFrom = firstDay.toISOString().slice(0, 10);
    this.$store
      .dispatch('transactions/fetchTransactions', {
        fromDate: this.transactionFrom,
        toDate: this.transactionTo,
        pageSize: 20,
        page: 0,
      })
      .then(() => {
        this.selectedPageSize(this.pagination.pageSize);
      });
  },
  methods: {
    toggleArticleChangePrice(boolean) {
      this.articleChangePrice = boolean;
    },
    toggleArticleChangePriceLoggedOut(boolean) {
      this.articleChangePriceLoggedOut = boolean;
    },
    toggleArticleActive(boolean) {
      this.articleActive = boolean;
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(
        o => parseInt(o.value) === size
      );
      this.selectedIndexPageSize = selectedSize;
    },
    getPrevPage() {
      return this.$store.getters['users/previousPage'];
    },
    getNextPage() {
      return this.$store.getters['users/nextPage'];
    },
    getVariatedResults(value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (
          checked.length < value.filter.options.length &&
          checked.length > 0
        ) {
          if (checked[0].value === '1') {
            value.filter.id === 'active'
              ? (this.active = true)
              : (this.active = false);
            value.filter.id === 'onService'
              ? (this.onService = true)
              : (this.onService = false);
            value.filter.id === 'onTag'
              ? (this.onTag = true)
              : (this.onTag = false);
            value.filter.id === 'digitalScorecards'
              ? (this.digitalScorecards = true)
              : (this.digitalScorecards = false);
            value.filter.id === 'voucher'
              ? (this.voucher = true)
              : (this.voucher = false);
          }
        } else {
          this.active = undefined;
          this.onService = undefined;
          this.onTag = undefined;
          this.digitalScorecards = undefined;
          this.voucher = undefined;
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('transactions/fetchTransactions', {
        fromDate: this.transactionFrom,
        toDate: this.transactionTo,
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.status': this.status,
        pageSize: this.pageSize,
        page: this.currentPage,
      });
    },
    getDateResults() {
      this.$store
        .dispatch('transactions/fetchTransactions', {
          fromDate: this.transactionFrom,
          toDate: this.transactionTo,
          pageSize: 20,
          page: 0,
        })
        .then(() => {
          this.selectedPageSize(this.pagination.pageSize);
        });
    },
    exportTransactions() {
      this.$store.dispatch('transactions/exportTransactions', {
        fromDate: this.transactionFrom,
        toDate: this.transactionTo,
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.status': this.status,
        pageSize: this.pageSize,
        page: this.currentPage,
      });
    },
  },
};
</script>
<style lang="scss">
.onservice {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.onservice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.transactions--dates {
  display: flex;
  .input {
    margin-right: 0.5rem;
  }
  .active--update {
    display: flex;
    margin-bottom: 1rem;
    margin-top: auto;
  }
}
.transactions--actions {
  display: flex;
}
.transactions--export {
  display: flex;
  margin-left: auto;
  margin-top: 1rem;
  button {
    margin-left: 0.5rem;
  }
}
.pagination-amount {
  display: flex;
  align-items: baseline;
  flex: 1;
}
</style>
