<template>
  <div class="mapping-tool-wrapper">
    <iframe
      v-if="toolUrl && authorized"
      class="mapping-tool"
      :src="toolUrl"
      title="Golfoffice mapping tool"></iframe>
    <div
      v-if="error"
      class="map-auth-error">
      <p>{{ error }}</p>
      <button
        class="te-btn te-btn-primary te-btn-md"
        @click="closeMap">
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouseGuidesMappingTool',
  data() {
    return {
      authorized: true,
      error: null,
      toolUrl: `${process.env.VUE_APP_MAPTOOL_URL}?token=${this.$store.getters['user/token']}`,
    };
  },
  emits: ['close'],
  methods: {
    closeMap(e) {
      this.$emit('close', e);
    },
    iframeEvent(event) {
      if (event.data.action === 'closeMap') {
        this.closeMap();
      } else if (event.data.action === 'getToken') {
        this.$store.dispatch('user/refreshToken').then(() => {
          let token = this.$store.getters['user/token'];
          event.source.postMessage({
              action: 'setToken',
              id: event.data.id,
              token: token,
            },
            process.env.VUE_APP_MAPTOOL_URL
          );
        });
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.iframeEvent, false);
  },
  beforeDestroy() {
    if(this.refresher) {
      clearInterval(this.refresher);
    }
    window.removeEventListener('message', this.iframeEvent);
  },
};
</script>
<style lang="scss">
.mapping-tool-wrapper {
  position: absolute;
  top: 0px;
  z-index: 99999;
  left: 0px;
  background-color: rgba($color: #000000, $alpha: 0.8);
  width: 100vw;
  margin: 0 !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .mapping-tool-toolbar {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .mapping-tool {
    box-sizing: border-box;
    padding: 5px 5px 5px 5px;
    border: none;
    width: 100%;
    height: 100%;
    //height: calc(100vh - 72px);
    //width: 100vw;
  }
  .map-auth-error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    align-items: center;
  }
}
</style>
