<template>
  <div class="ontag">
    <div class="order-scorecard">
      <div
        v-if="settingsclubinfo.showInformationText"
        class="order-scorecard__club-info-container">
        <span class="order-scorecard__club-info-title">
          <div class="info-svg"></div>
          {{ settingsclubinfo.title }}
        </span>
        <p class="order-scorecard__club-info-text">
          {{ settingsclubinfo.information }}
        </p>
      </div>
      <header class="order-scorecard__header">
        <h1>Beställ scorekort</h1>
        <h3>
          Här kan du beställa nya scorekort och se dina tidigare beställningar
        </h3>
      </header>
      <div class="order-scorecard__type-block">
        <div class="order-scorecard__type">
          <router-link
            v-if="scorecardorder.clubScorecardSize == 0"
            :to="{ path: '/ontag/orderscorecard/clubspecificnone' }">
            <button class="order-scorecard__type-img">
              <img
                src="../../assets/images/scorecard-clubspecific-example.png"
                alt="" />
            </button>
          </router-link>
          <router-link
            v-else
            :to="{ path: '/ontag/orderscorecard/clubspecific' }">
            <button class="order-scorecard__type-img">
              <div class="order-scorecard__type-img-container">
                <img
                  class="order-scorecard__type-img-clubspecific"
                  :src="scorecardorder.scorecardProofUrl" />
              </div>
            </button>
          </router-link>
          <div class="order-scorecard__type-description">
            <h3>Scorekort med egen design</h3>
            <p>Skapa er egen design på utsidan av scorekortet</p>
          </div>
        </div>
        <div class="order-scorecard__type">
          <router-link :to="{ path: '/ontag/orderscorecard/standard' }">
            <button class="order-scorecard__type-img">
              <div class="order-scorecard__type-img-container">
                <img
                  class="order-scorecard__type-img-standard"
                  src="../../assets/images/sc-4p-2023.jpg" />
              </div>
            </button>
          </router-link>
          <div class="order-scorecard__type-description">
            <h3>Standardscorekort</h3>
            <p>Utsidan av scorekortet är designad av OnTag</p>
          </div>
        </div>
      </div>
      <div class="order-scorecard__orders-block">
        <h2 class="bold">Tidigare beställningar</h2>
        <div class="order-scorecard__orders-block-columns">
          <div class="order-scorecard__years">
            <collapsible
              class="order-scorecard__collapse"
              :isOpen="false"
              v-for="(year, index) in scorecardorder.scorecardStatistics"
              :key="index">
              <div
                @click="toggleCollapse(index)"
                slot="trigger"
                class="order-scorecard__year-button">
                <span class="order-scorecard__year">{{ year.year }}</span>
                <span
                  v-if="!yearsExpanded.includes(index)"
                  class="order-scorecard__expand">
                  +</span>
                <span
                  v-else
                  class="order-scorecard__expand">
                  -
                </span>
              </div>
              <div class="order-scorecard__year-expanded">
                <div>
                  <span class="bold">Beställda scorekort</span><br />
                  <span>
                    {{ (year.orderedScorecards * 1000).toLocaleString() }}
                    st</span>
                </div>
                <div>
                  <span class="bold">Utskrivna scorekort</span><br />
                  <span>{{ year.printedScorecards }} st</span>
                </div>
              </div>
            </collapsible>
          </div>
          <div class="order-scorecard__orders-list">
            <span
              v-if="isLoading"
              class="element--is-loading"></span>
            <collapsible
              class="order-scorecard__order-collapse"
              :class="{ 'order-open': yearsExpanded.includes(order.id) }"
              :isOpen="false"
              v-for="order in ordersShowing"
              :key="order.id">
              <div
                slot="trigger"
                class="order-scorecard__order-collapse-trigger"
                @click="toggleCollapse(order.id)">
                <span>{{ order.orderDateText }}</span>
                <span class="bold">{{ order.scorecardTypeText }}</span>
                <span>{{ order.quantity }} st</span>
                <span>Best.nr {{ order.orderId }}</span>
                <button>
                  <span v-if="!yearsExpanded.includes(order.id)">
                    Se detaljer
                  </span>
                  <span v-else> Dölj detaljer </span>
                  <use-svg
                    svg="arrow-right"
                    :class="{
                      'svg--180-negative': yearsExpanded.includes(order.id),
                      'svg-margin': yearsExpanded.includes(order.id),
                    }" />
                </button>
              </div>
              <div class="order-scorecard__order-details">
                <table>
                  <thead>
                    <tr>
                      <th>ARTIKEL</th>
                      <th>ANTAL</th>
                      <th>PRIS/ST</th>
                      <th>SUMMA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom">
                      <td>
                        {{ order.scorecardTypeText }} {{ order.scorecardType
                        }}<br />
                        <span v-if="(order.scorecardType = 1)">110X273 mm</span>
                        <span
v-else-if="(order.scorecardType = 2)">110X362 mm</span>
                      </td>
                      <td>
                        {{ (order.quantity * 1000).toLocaleString() }} ex <br />
                        / {{ order.quantity }} st lådor
                      </td>
                      <td>{{ order.unitPrice }} kr/st</td>
                      <td>
                        {{
                          (
                            order.unitPrice *
                            (order.quantity * 1000)
                          ).toLocaleString()
                        }}
                        kr
                      </td>
                    </tr>
                    <tr class="sum_rows">
                      <td></td>
                      <td></td>
                      <td>Frakt</td>
                      <td>
                        {{ order.customerShippingPrice.toLocaleString() }} kr
                      </td>
                    </tr>
                    <tr class="border-bottom sum_rows">
                      <td></td>
                      <td></td>
                      <td><strong>Summa</strong> {{ $t('vatExcluded') }}</td>
                      <td>
                        <strong>
                          {{ order.totalPrice.toLocaleString() }} kr
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="order-scorecard__delivery-details">
                  <div class="order-scorecard__delivery-column">
                    <span><strong>Leveransadress</strong></span>
                    <span>{{ order.deliveryCompanyName }}</span>
                    <span>{{ order.deliveryPostalAddress }}</span>
                    <span>{{ order.deliveryPostalCode }}</span>
                    <span>{{ order.deliveryCity }}</span>
                  </div>
                  <div class="order-scorecard__delivery-column">
                    <span><strong>Dina kontaktuppgifter</strong></span>
                    <span>{{ order.contactPerson }}</span>
                    <span>{{ order.phoneNumber }}</span>
                    <span>{{ order.email }}</span>
                  </div>
                  <div class="order-scorecard__delivery-column">
                    <span><strong>Fakturaadress</strong></span>
                    <span>{{ order.invoiceCompanyName }}</span>
                    <span>{{ order.orgNumber }}</span>
                    <span>{{ order.invoicePostalAddress }}</span>
                    <span>{{ order.invoicePostalCode }}</span>
                    <span>{{ order.invoiceCity }}</span>
                    <span>{{ order.invoiceEmail }}</span>
                  </div>
                </div>
                <div
                  v-if="order.scorecardChanged"
                  class="order-scorecard__alert-box">
                  <div class="info-svg"></div>
                  <p>
                    Den här versionen har utgått och går inte att beställa igen.
                    För att beställa senaste versionen, klicka på
                    Standardscorekort högre upp på sidan.
                  </p>
                </div>
                <router-link
                  v-else
                  :to="{ path: '/ontag/orderscorecard/standard' }">
                  <button
                    @click="repeatOrder(order)"
                    class="order-scorecard__repeat-order-button">
                    Beställ igen
                  </button>
                </router-link>
              </div>
            </collapsible>
            <div class="order-scorecard__show-all-container">
              <button
                class="order-scorecard__show-all-button"
                @click="toggleShowAllOrder">
                <span v-if="!showAllOrders">Visa fler</span>
                <span v-else-if="showAllOrders">Visa färre</span>
                <use-svg
                  svg="arrow-right"
                  :class="{
                    'svg--180-negative': showAllOrders,
                    'svg-margin': showAllOrders,
                  }" />
              </button>
            </div>
            <p class="order-scorecard__bottom-p">
              Beställningar gjorda senare än 2024-01-22 visas här
            </p>
          </div>
        </div>
      </div>
      <div class="order-scorecard__FAQ-block">
        <h2 class="bold">Frågor och svar</h2>
        <p>
          Vilka är leveransveckorna för klubbunika scorekort och när behöver jag
          beställa våra scorekort?
        </p>
        <collapsible
          v-for="(year, index) in years"
          :key="index"
          class="order-scorecard__faq-collapse"
          :isOpen="false">
          <div
            @click="toggleCollapse(`FAQ${index + 1}`)"
            slot="trigger"
            class="order-scorecard__faq-collapse-trigger">
            <strong>Vilka leveransveckor finns {{ year }}? </strong>
            <use-svg
              svg="arrow-right"
              :class="{
                'svg--180-negative': yearsExpanded.includes(`FAQ${index + 1}`),
              }" />
          </div>
          <div class="order-scorecard__faq-collapse-content">
            <table class="order-scorecard__week-table">
              <thead>
                <tr>
                  <th>LEVERANSVECKA</th>
                  <th>SISTA DATUM FÖR BESTÄLLNING</th>
                  <th>DEADLINE FÖR BESTÄLLNING MED ÄNDRAD ELLER NY DESIGN</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="week in deliveryweeks.filter(w => w.year === year)"
                  :key="week.id">
                  <td>v. {{ week.week }}, {{ week.year }}</td>
                  <td>
                    {{ new Date(week.deadline).toLocaleDateString('sv-SE') }}
                  </td>
                  <td>
                    {{
                      new Date(week.deadlineForChange).toLocaleDateString(
                        'sv-SE'
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </collapsible>
        <collapsible
          class="order-scorecard__faq-collapse"
          :isOpen="false">
          <div
            @click="toggleCollapse(`FAQ${index + 1}`)"
            slot="trigger"
            class="order-scorecard__faq-collapse-trigger">
            <strong>Jag vill göra en ändring/ ta fram ny layout på vårt scorekort,
              hur gör jag?</strong>
            <use-svg
              svg="arrow-right"
              :class="{
                'svg--180-negative': yearsExpanded.includes(`FAQ${index + 1}`),
              }" />
          </div>
          <div class="order-scorecard__faq-collapse-content">
            <p>Så här gör du en ändring på ert scorekort.</p>
            <p>
              Skicka in följande information till oss via kontakta
              oss-funktionen här nedanför till höger:
            </p>
            <br />
            <ul>
              <li>Antal scorekort</li>
              <li>Önskad leveransvecka</li>
              <li>Beskrivning eller skiss på ändringen</li>
              <li>
                Grafiskt material på nya delar (tex. logga eller bakgrundsbild)
                i hög upplösning, minst 300 dpi. Godkända format JPG, PDF, EPS
                eller Photoshop-format.
              </li>
            </ul>

            <p>
              För att ta fram en ny design kan ni använda en av våra PPT-mallar
              som stöd. Dessa visar scorekorten i rätt storlek och underlättar
              för att se helheten.
            </p>

            <p>
              Ni hittar mallarna och mer info om hur man gör en ändring på
              följande sida.
            </p>

            <p>
              <a
href="/ontag/orderscorecard/clubspecific/changedesign ">Skapa eller ändra scorekortsdesign</a>
            </p>
          </div>
        </collapsible>
        <collapsible
          class="order-scorecard__faq-collapse"
          :isOpen="false">
          <div
            @click="toggleCollapse(`FAQ${index + 1}`)"
            slot="trigger"
            class="order-scorecard__faq-collapse-trigger">
            <strong>Våra scorekort känns klibbiga och fastnar ibland i skrivaren. Vad
              ska jag göra?</strong>
            <use-svg
              svg="arrow-right"
              :class="{
                'svg--180-negative': yearsExpanded.includes(`FAQ${index + 1}`),
              }" />
          </div>
          <div class="order-scorecard__faq-collapse-content">
            <p>
              Det kan hända att scorekorten upplevs klippiga. Detta kan bero på
              lacken som används vid tillverkningen. För att undvika att
              scorekorten sitter ihop kan ni göra följande:
            </p>
            <br />
            <ol>
              <li>
                Öppna lådan korrekt och ta upp ett gäng scorekort i handen.
                Notera att scorekorten sitter ihop med varandra (nästan som ett
                dragspel). Låt dem sitta ihop med de övriga i lådan.
              </li>
              <li>
                Släpp ner scorekorten ett och ett i lådan igen, på detta sätt
                luftar/delar ni på scorekorten och undviker att de klibbar ihop
                i skrivaren.
              </li>
            </ol>
          </div>
        </collapsible>
        <div class="order-scorecard__background-wave">
          <img
            src="../../assets/images/wave-background.svg"
            alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
// import BaseButton from '@/components/form-elements/base-button'
// import ListTable from '@/components/list-table/list-table-businessboard'
import 'vue-collapsible-component/lib/vue-collapsible.css';
import Collapsible from 'vue-collapsible-component';
// import ModalWindow from '@/components/modal/modal'
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'ScorecardOrder',
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
    // BaseButton,
    // ListTable,
    Collapsible,
    // ModalWindow,
  },

  data: function () {
    return {
      isLoading: true,
      isOpen: false,
      scorecardType: 0,
      yearsExpanded: [],
      showAllOrders: false,
      ordersShowing: [],
    };
  },
  methods: {
    toggleShowAllOrder() {
      this.showAllOrders = !this.showAllOrders;
      if (this.showAllOrders) {
        this.ordersShowing = [];
        this.scorecardorder.orders.forEach(order => {
          this.ordersShowing.push(order);
        });
      } else {
        this.ordersShowing = this.ordersShowing.slice(0, 3);
      }
    },

    toggleCollapse(key) {
      if (!this.yearsExpanded.includes(key)) {
        this.yearsExpanded.push(key);
      } else {
        this.yearsExpanded.pop(key);
      }
    },
    repeatOrder(order) {
      if (order.scorecardTypeText === 'Standard, 4-delat') {
        this.scorecardordertempstate.scorecardType = 2;
      } else if (order.scorecardTypeText === 'Standard, 3-delat') {
        this.scorecardordertempstate.scorecardType = 1;
      }
      this.scorecardordertempstate.quantity = order.quantity;
    },
    scrollToForm() {
      window.FreshworksWidget('open', 'ticketForm');
    },
    loadWidget() {
      window.fwSettings = { widget_id: 44000003424 };
      const n = function () {
        n.q.push(arguments);
      };
      n.q = [];
      window.FreshworksWidget = n;
      let fdwidgetScript = document.createElement('script');
      fdwidgetScript.id = 'widgetScript';
      fdwidgetScript.setAttribute(
        'src',
        'https://widget.freshworks.com/widgets/44000003424.js'
      );
      fdwidgetScript.onload = this.fillWidget();
      document.head.appendChild(fdwidgetScript);
    },
    fillWidget() {
      let name = this.user.firstName + ' ' + this.user.lastName;
      window.FreshworksWidget('identify', 'ticketForm', {
        type: '\u00D6vrigt',
        subject:
          'Scorekortsfr\u00E5ga via GolfOffice' +
          (this.user.club === undefined ? '' : ' från ' + this.user.club.name),
        name: name,
        email: this.user.email,
        custom_fields: {
          cf_klubbnamn: this.user.club === undefined ? '' : this.user.club.name,
        },
      });
      window.FreshworksWidget('hide', 'ticketForm', ['type', 'subject']);
    },
  },
  computed: {
    scorecardordertempstate() {
      return this.$store.getters['scorecardOrder/scorecardordertempstate'];
    },
    deliveryweeks() {
      return this.$store.getters['scorecardOrder/deliveryweeks'];
    },
    scorecardorder() {
      return this.$store.getters['scorecardOrder/scorecardorder'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    settingsclubinfo() {
      return this.$store.getters['scorecardOrder/settingsclubinfo'];
    },
    years() {
      return [2024];
    },
  },
  watch: {
    // if this page is reloaded user object is not loaded yet. wait for it before loading widget
    user() {
      this.loadWidget();
    },
  },
  created() {
    this.$store.dispatch('scorecardOrder/fetchSettingsClubInfo');
    this.$store
      .dispatch('scorecardOrder/fetchScorecardOrder')
      .then(() => {
        this.scorecardorder.orders.forEach(order => {
          this.ordersShowing.push(order);
        });
      })
      .finally(() => {
        this.ordersShowing = this.ordersShowing.slice(0, 3);
        this.isLoading = false;
      });
  },
  mounted() {
    this.$store.dispatch('scorecardOrder/fetchDeliveryWeeks');
    if (Object.keys(this.user).length > 0) {
      if (document.getElementById('widgetScript') === null) {
        this.loadWidget();
      } else {
        // the widgetscript has been loaded before, but probably unmounted on destroy
        window.FreshworksWidget('boot');
        window.FreshworksWidget('show');
        this.fillWidget();
      }
    }
  },
  destroyed() {
    // unmount widget when leaving the page
    window.FreshworksWidget('destroy');
    // also remove script?
    // document.getElementById('widgetScript').outerHTML = '';
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 4.375rem 0 1rem 0;
  @include media('small') {
    margin: 3.3rem 0rem 1rem 15.7rem;
  }
}
.ontag {
  .order-scorecard {
    background: linear-gradient(color(lightSand) 70%, white 70%);

    &__club-info-container {
      background-color: color(sandbunker);
      margin-top: 20px;
      padding: measure(spacing-m) 64px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &__club-info-title {
      font-size: 20px;
      display: flex;
      align-items: center;

      .info-svg {
        margin-right: measure(spacing-m);
        background-color: color(solnedgang);
        height: 30px;
        width: 30px;
        mask: url(../../assets/images/info.svg) no-repeat center / contain;
        -webkit-mask: url(../../assets/images/info.svg) no-repeat center /
          contain;
      }
    }
    &__header {
      background-color: color(lightSand);
      text-align: center;
      padding: 20px;
      margin: measure(spacing-s);
    }

    &__type {
      margin: 20px;
      width: 40%;
      max-width: 512px;
    }

    &__type-block {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      background: linear-gradient(color(lightSand) 30%, white 30%);
    }

    &__type-img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      overflow: hidden;
    }

    &__type-img-clubspecific {
      width: 90%;
    }
    &__type-img-standard {
      margin-top: 30px;
    }
    &__type-img {
      height: 246px;
      width: 100%;
      border-radius: 3px;
      border: none;
      background-color: transparent;
      padding: 0px;

      :hover {
        transition: 0.3s;
        transform: scale(1.01);
      }

      :active {
        transform: scale(1.02);
      }

      img {
        max-width: 100%;
      }
    }

    &__orders-block {
      background-color: color(lightSand);
      padding: 60px 100px;
    }

    &__collapse {
      border-bottom: 1px solid color(tussilago);
      width: 240px;
      line-height: 24px;
    }

    &__orders-block-columns {
      display: flex;
      justify-content: space-between;
    }

    &__years {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
    }

    &__year-button {
      border: none;
      background-color: transparent;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
    }

    &__expand {
      font-size: 30px;
    }
    &__year-expanded {
      line-height: 30px;
      padding: measure(spacing-s) 0px measure(spacing-l);
    }
    &__orders-list {
      position: relative;
      width: 75%;
      height: fit-content;
      background-color: white;
      padding: measure(spacing-s) 30px;
      border-radius: 3px;
    }
    &__order-collapse {
      border: 1px solid color(grus-00);
      margin: measure(spacing-s);
      border-radius: 3px;
      box-sizing: border-box;
    }

    .order-open {
      border: 1px solid color(tussilago);
    }

    &__order-collapse-trigger {
      padding: 14px;
      display: flex;
      justify-content: space-between;
      button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        text-decoration: underline;
        color: color(link);
        span {
          margin-right: measure(spacing-s);
        }
        .svg {
          margin-top: 5px;
        }
        .svg-margin {
          margin-top: -5px;
        }
      }
    }

    &__show-all-container {
      border-bottom: 1px solid color(tussilago);
      margin: measure(spacing-s);
      border-radius: 3px;
      padding: 14px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
    }

    &__show-all-button {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      color: color(link);
      span {
        margin-right: measure(spacing-s);
      }
    }

    &__order-details {
      border-radius: 3px;
      margin-top: 30px;
      background-color: color(gray-lighter);
      margin: 0px measure(spacing-s) measure(spacing-s);
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          background-color: color(dis);
          text-align: left;

          tr {
            border-radius: 3px;
            :last-child {
              text-align: right;
            }
          }
          th {
            padding: 18px measure(spacing-s);
          }
        }
        tbody {
          line-height: 28px;
          tr {
            td {
              padding: 20px measure(spacing-s);
            }
            :last-child {
              text-align: right;
            }
          }
          .sum_rows {
            td {
              padding: 8px measure(spacing-s);
            }
          }
        }
      }
    }

    &__delivery-details {
      display: flex;
      justify-content: space-between;
      margin: measure(spacing-l) measure(spacing-xl) 0px measure(spacing-s);
      margin-top: 20px;
      margin-right: 50px;
      font-size: 12px;
    }

    &__delivery-column {
      display: flex;
      flex-direction: column;
      line-height: 20px;
      :first-child {
        padding-bottom: 8px;
      }
    }

    &__repeat-order-button {
      border: none;
      background-color: color(jord);
      color: white;
      padding: measure(spacing-s) 20px;
      border-radius: 3px;
      margin: measure(spacing-s);
    }

    &__alert-box {
      display: flex;
      align-items: flex-start;
      background-color: color(info-background);
      border: 1px solid #dba76a;
      padding: 14px;
      margin-top: measure(spacing-s);
      border-radius: 3px;

      .info-svg {
        margin-right: measure(spacing-xs);
        background-color: color(soluppgang);
        height: 34px;
        width: 34px;
        mask: url(../../assets/images/info.svg) no-repeat center / contain;
        -webkit-mask: url(../../assets/images/info.svg) no-repeat center /
          contain;
      }
      p {
        margin: 0 0 0 measure(spacing-s);
      }
    }

    &__FAQ-block {
      background-color: white;
      padding: 40px 0;
      width: 100%;
      border-radius: 3px;

      h2,
      p {
        text-align: center;
      }
    }

    &__faq-collapse {
      max-width: 800px;
      margin-bottom: measure(spacing-m);
      margin: measure(spacing-m) auto;
      p {
        padding-top: measure(spacing-m);
      }
    }

    &__faq-collapse-trigger {
      background-color: color(lightSand);
      display: flex;
      align-items: center;
      padding: measure(spacing-l) measure(spacing-xl);
      display: flex;
      justify-content: space-between;
    }

    &__faq-collapse-content {
      border-bottom: 1px solid color(lightSand);

      p {
        margin: 0px;
      }
    }

    &__bottom-p {
      padding-left: measure(spacing-s);
    }

    &__background-wave {
      width: 100%;
      max-height: 200px;
      img {
        margin-bottom: -20px;
        width: 100%;
        overflow: hidden;
      }
    }
    &__week-table {
      width: 100%;
      margin: 0 auto;
      position: relative;
      border-collapse: collapse;
      border-spacing: 0;
      background-color: #fafafa;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      :first-child {
        padding-left: measure(spacing-s);
      }
      :last-child {
        padding-right: measure(spacing-s);
      }
      thead {
        border-left: measure(spacing-s) solid color(dis);
        border-right: measure(spacing-s) solid color(dis);
        background-color: color(dis);
        font-size: 12px;
        color: color(gray-darkest);
        tr {
          text-align: left;
          :last-child {
            text-align: right;
            max-width: 50px;
          }
          th {
            padding: measure(spacing-m) 0;
          }
        }
      }

      tbody {
        border-left: measure(spacing-s) solid transparent;
        border-right: measure(spacing-s) solid transparent;
        border-bottom: measure(spacing-s) solid transparent;
        font-size: 16px;
        min-width: 8rem;
        font-weight: bold;

        tr {
          :last-child {
            text-align: right;
          }
          td {
            padding: measure(spacing-m) 0;
          }
        }
      }
    }
  }

  .bold {
    font-weight: bold;
  }
  .border-bottom {
    border-bottom: 1px solid color(tussilago);
  }

  h1,
  h2 {
    font-weight: 900;
  }
  body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  ul,
  ol {
    margin: 0 0 20px 20px;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    color: #0077c0;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}
</style>
