<template>
  <div class="coursedata-hole-group">
    <div class="names">
      <te-input-field
        name="group-name"
        title="Name of group"
        v-bind:value="localGroup.name"
        :pattern="namePattern"
        :error="nameError"
        autocomplete="nope"
        type="text"
        @changed="this.nameChanged"
        placeholder=""></te-input-field>
      <te-input-field
        class="short-name"
        name="short-group-name"
        title="Short name"
        :pattern="shortNamePattern"
        :error="shortNameError"
        autocomplete="nope"
        type="text"
        v-bind:value="localGroup.shortName"
        @changed="this.shortNameChanged"
        placeholder=""></te-input-field>
    </div>
    <div
      v-if="!group.id"
      class="holes-in-group">
      <div class="holes-in-group-title">Number of holes in group</div>
      <te-select-cards
        v-bind:items="numberOfHoles"
        @select="this.selectionChanged"
        size="small"
        theme="onwhite"></te-select-cards>
    </div>
  </div>
</template>

<script>
import TeSelectCards from './components/te-select-cards.vue';
import TeInputField from './components/te-input-field.vue';
export default {
  name: 'CourseDataHoleGroupView',
  components: {
    TeSelectCards,
    TeInputField,
  },
  props: {
    group: {
      type: Object,
    },
    nameError: {
      type: String,
      default: null,
    },
    shortNameError: {
      type: String,
      default: null,
    },
    shortNamePattern: {
      type: String,
      default: null,
    },
    namePattern: {
      type: String,
      default: null,
    },
  },
  emits: ['changed'],
  watch: {
    group: {
      handler: function (newVal) {
        this.localGroup = newVal;
      },
      deep: true,
    },
  },
  data() {
    let arr = [];
    if (this.group) {
      for (let i = 1; i <= 18; i++) {
        arr.push({
          id: i,
          title: `${i}`,
          description: `${i}`,
          disabled: false,
          selected: this.group.numberOfHoles === i,
        });
      }
    }
    return {
      numberOfHoles: arr,
      localGroup: this.group,
    };
  },
  methods: {
    selectionChanged(selection, all) {
      if (!selection[0]) {
        this.localGroup.numberOfHoles = 0;
      } else {
        this.localGroup.numberOfHoles = selection[0].id;
      }
      this.localGroup.holes = this.createHoles(this.localGroup.numberOfHoles);
      this.updateListeners();
    },
    createHoles(holes) {
      let holesArray = [];
      if (holes === 0) {
        return [];
      }
      for (let i = 1; i <= holes; i++) {
        holesArray.push({
          _id: Date.now().toString(36) + Math.random().toString(36),
          holeNumber: i,
          par: 0,
        });
      }
      return holesArray;
    },
    updateListeners() {
      this.$emit('changed', this.localGroup);
    },
    nameChanged(val) {
      this.localGroup.name = val;
      this.updateListeners();
    },
    shortNameChanged(val) {
      this.localGroup.shortName = val;
      this.updateListeners();
    },
  },
};
</script>

<style lang="scss">
.coursedata-hole-group {
  font-family: 'Tietoevry sans 1';
  .names {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    .te-input {
      align-self: start;
    }
    .short-name input {
      width: 70px;
    }
  }
  .holes-in-group {
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
    justify-content: flex-start;
    .ts-select-cards {
      justify-content: flex-start;
      gap: 8px;
      .te-select-card {
        width: 34px;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .holes-in-group-title {
      color: var(--text-text-primary, #1e2224);
      /* Heading 5 */
      font-family: Tietoevry Sans 1;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
}
</style>
