<template>
  <div class="ontag">
    <div class="ontag--content">
      <h1>Beställningar, kundunika scorekort</h1>
      <div class="ontag--utilitites input-group--inline">
        <div class="pagination-amount">
          <span>{{ $t('show') }}</span>
          <custom-select
            v-if="selectedIndexPageSize !== undefined"
            :required="false"
            @optionSelected="setPageSize"
            :selected="selectedIndexPageSize"
            :options="pageSizeOptions" />
          <span class="row-lines">{{ $t('lines') }}</span>
        </div>
        <div class="search-wrapper">
          <div class="input">
            <label
              for="searchQuery"
              class="input__label label__inline first-letter">{{ $t('search') }}
            </label>
            <input
              id="searchQuery"
              class="input__input input__inline search-input"
              @keydown="getVariatedOrderResults"
              @search="getVariatedOrderResults"
              v-model="searchQuery"
              type="search"
              name="searchQuery" />
          </div>
          <base-button
            class="search-button"
            :text="$t('search')"
            :button-size-small="true"
            @click="getVariatedOrderResults" />
        </div>
        <base-button
          :text="'Exportera'"
          :button-size-small="true"
          @click="toggleExportMarkedOrdersModal"
          :disabled="!activeButtons.export" />
        <modal-window :show="showExportMarkedOrdersModal">
          <div class="modal__header">Exportera ordrar</div>
          <div class="modal__content">
            <div>
              <h2>Vill du exportera de markerade ordrarna?</h2>
            </div>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="toggleExportMarkedOrdersModal">
            </base-button>
            <base-button
              :text="'Exportera'"
              @click="exportMarkedOrders"
              v-element-loader="{ loaderKey: 'export-orders' }" />
          </div>
        </modal-window>
      </div>
      <div class="total-count">
        <p>
          Totalt antal: <strong>{{ scorecardcustomerorders.sum }}</strong>
        </p>
      </div>
      <list-table
        :headers="scorecardcustomerorders.columns"
        :items="scorecardcustomerorders.rows"
        @allSelected="getOrdersSelected"
        @variationResults="getVariatedOrderResults">
      </list-table>
      <results-pagination
        :first-row-on-page="pagination.firstRowOnPage"
        :last-row-on-page="pagination.lastRowOnPage"
        :row-count="pagination.rowCount"
        :page-size="pagination.pageSize"
        :page-count="pagination.pageCount"
        :current-page="pagination.currentPage"
        :next-page="getNextPage()"
        :prev-page="getPrevPage()"
        store="clubs"
        @currentPage="getVariatedOrderResults" />
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import ModalWindow from '@/components/modal/modal';
import elementLoader from '@/directives/element-loader';
import ResultsPagination from '@/components/results-pagination';
import CustomSelect from '@/components/form-elements/custom-select';

export default {
  name: 'scorecardcustomerorders',
  components: {
    ListTable,
    BaseButton,
    ModalWindow,
    ResultsPagination,
    CustomSelect,
  },
  directives: {
    elementLoader,
  },

  data() {
    return {
      searchQuery: '',
      selectedIndexPageSize: undefined,
      sort: {},
      allSelected: [],
      filters: {},
      showExportMarkedOrdersModal: false,
      selectedStatuses: [],
      activeButtons: {
        export: false,
        confirm: false,
      },
      currentPage: 0,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
    };
  },
  methods: {
    toggleExportMarkedOrdersModal() {
      this.showExportMarkedOrdersModal = !this.showExportMarkedOrdersModal;
    },
    exportMarkedOrders() {
      this.$store
        .dispatch('scorecardOrder/postScorecardOrderExportCustomer', {
          ids: this.allSelected,
          scorecardType: 'customer',
          value: true,
        })
        .then(() => {
          this.toggleExportMarkedOrdersModal();
          this.allSelected = [];
          this.fetchOrders();
        });
    },
    confirmMarkedOrders() {
      this.$store
        .dispatch('scorecardOrder/patchScorecardOrderConfirm', {
          ids: this.allSelected,
          scorecardType: 'customer',
          value: true,
        })
        .then(() => {
          this.toggleConfirmMarkedOrdersModal();
          this.allSelected = [];
          this.fetchOrders();
        });
    },
    fetchOrders() {
      this.$store.dispatch('scorecardOrder/fetchScorecardCustomerOrders');
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedOrderResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(
        o => parseInt(o.value) === size
      );
      this.selectedIndexPageSize = selectedSize;
    },
    getPrevPage() {
      return this.$store.getters['scorecardOrder/previousPage'];
    },
    getNextPage() {
      return this.$store.getters['scorecardOrder/nextPage'];
    },
    getVariatedOrderResults(value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length > 0) {
          this.filters['Filter.' + value.filter.id] = checked.map(a => a.value);
          // localStorage.filters = this.filters['Filter.' + value.filter.id] = checked.map(a => a.value)
        } else {
          delete this.filters['Filter.' + value.filter.id];
          // localStorage.removeItem('filters')
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;
      this.$store.dispatch('scorecardOrder/fetchScorecardCustomerOrders', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        pageSize: this.pageSize,
        page: this.currentPage,
        ...this.filters,
      });
    },

    getOrdersSelected(value) {
      this.allSelected = value;
    },
  },

  watch: {
    allSelected() {
      this.selectedStatuses = [];
      this.allSelected.forEach(item => {
        const match = this.scorecardcustomerorders.rows.filter(
          post => post.id === item
        );
        this.selectedStatuses = [...this.selectedStatuses, match[0].status];
      });
    },

    selectedStatuses() {
      if (this.selectedStatuses.length === 0) {
        this.activeButtons.confirm = false;
        this.activeButtons.export = false;
      } else if (this.selectedStatuses.every(status => status === 1)) {
        this.activeButtons.confirm = true;
      } else if (this.selectedStatuses.every(status => status === 3)) {
        this.activeButtons.export = true;
      } else {
        this.activeButtons.confirm = false;
        this.activeButtons.export = false;
      }
    },
  },

  computed: {
    scorecardcustomerorders() {
      return this.$store.getters['scorecardOrder/scorecardcustomerorders'];
    },
    pagination() {
      return this.$store.getters['scorecardOrder/pagination'];
    },
  },

  created() {
    this.$store
      .dispatch('scorecardOrder/fetchScorecardCustomerOrders', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.invoiced': this.invoiced,
        'Filter.comment': this.comment,
        'Filter.adminComment': this.adminComment,
        'Filter.status': this.status,
        'Filter.deliveryWeek': this.deliveryWeekText,
        pageSize: this.pageSize,
        page: this.currentPage,
      })
      .then(() => {
        this.selectedPageSize(this.pagination.pageSize);
      });
    this.$store.dispatch('scorecardOrder/fetchScorecardCustomerOrders', {
      id: this.$route.params.id,
    });
  },
};
</script>
<style lang="scss" scoped>
.total-count {
  display: inline-block;
  margin-left: auto;
  margin-right: 20px;
  font-size: 20px;
}

.pagination-amount {
  display: flex;
  align-items: baseline;
  flex: 1;
}
</style>
