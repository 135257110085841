<template>
  <div class="ontag">
    <div class="ontag--content" v-if="clients">
      <h1>{{ $t('All clients') }}</h1>
      <p v-if="user.superUser === true">Här finner ni alla klientinstallationer som är ute hos klubbarna. Ni kan se status m.m.</p>
      <p v-else>Här finner ni ert klient-id och nyckel. Obs endast en installation per klubb med samma id. Behöver ni fler kontakta supporten.</p>
        <div class="ontag--utilitites input-group--inline">
          <div class="pagination-amount">
            <span>Show</span>
              <custom-select
                v-if="selectedIndexPageSize !== undefined"
                :required="false"
                @optionSelected="setPageSize"
                :selected="selectedIndexPageSize"
                :options="pageSizeOptions"/>
            <span>lines</span>
          </div>
          <div class="search-wrapper">
            <div class="input">
              <label
                for="searchQuery"
                class="input__label label__inline">{{ $t('search') }}</label>
              <input
                id="searchQuery"
                class="input__input input__inline search-input"
                @keyup.enter="getVariatedClientsResults"
                v-model="searchQuery"
                type="text"
                name="searchQuery"/>
            </div>
            <base-button
              class="search-button"
              :text="$t('search')"
              :button-size-small="true"
              v-element-loader="{ loaderKey: 'clients-fetch-clients' }"
              @click="getVariatedClientResults" />
            <base-button
              v-if="user.superUser"
              class="create-button"
              :text="$t('create')"
              :button-size-small="true"
              @click="createClient" />
            <base-button
              v-if="user.superUser"
              class="create-button"
              :text="$t('createIoT')"
              :button-size-small="true"
              @click="toggleCreateIoTModal" />
            <base-button
              class="button--icon-to-left"
              :text="$t('Download client')"
              :button-size-small="true"
              v-element-loader="{ loaderKey: 'clients-download-client' }"
              @click="downloadClient">
              <use-svg svg="export-icon"/>
            </base-button>
          </div>
        </div>
        <div class="ontag--clients" v-if="clients && clients.rows && clients.columns">
          <list-table
            :items="clients.rows"
            :headers="clients.columns"
            @variationResults="getVariatedClientResults">
          </list-table>
          <results-pagination
            :first-row-on-page="pagination.firstRowOnPage"
            :last-row-on-page="pagination.lastRowOnPage"
            :row-count="pagination.rowCount"
            :page-size="pagination.pageSize"
            :page-count="pagination.pageCount"
            :current-page="pagination.currentPage"
            :next-page="getNextPage()"
            :prev-page="getPrevPage()"
            store="client"
            @currentPage="getVariatedClientResults"/>
      </div>
      <modal-window :show="showCreateIoTModal">
        <div class="modal__header">
          {{ $t('createIoT') }}
        </div>
        <div class="modal__content">
          <div>
            <label
              for="createIoTName"
              class="input__label label__inline capitalize">{{ $t('name') }}</label>
            <input
              id="createIoTName"
              class="input__input input__inline iot-input"
              v-model="createIoTName"
              type="text"
              name="createIoTName"/>
          </div>
        </div>
        <div class="modal__content--actions">
          <base-button
            class="button--background-gray-dark"
            :text="$t('cancel')"
            @click="toggleCreateIoTModal">
          </base-button>
          <base-button
            :text="$t('createIoT')"
            :disabled="createIoTName === ''"
            v-element-loader="{ loaderKey: 'clients-create-iot' }"
            @click="createIoT"/>
        </div>
      </modal-window>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import ResultsPagination from '@/components/results-pagination';
import CustomSelect from '@/components/form-elements/custom-select';
import UseSvg from '@/components/use-svg';
import elementLoader from '@/directives/element-loader';
import ModalWindow from '@/components/modal/modal';
export default {
  name: 'OnTagClients',
  components: {
    ListTable,
    BaseButton,
    ResultsPagination,
    CustomSelect,
    UseSvg,
    ModalWindow,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 0,
      sort: {},
      selectedIndexPageSize: undefined,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
      showCreateIoTModal: false,
      createIoTName: '',
    };
  },
  methods: {
    getPrevPage () {
      return this.$store.getters['clients/previousPage'];
    },
    getNextPage () {
      return this.$store.getters['clients/nextPage'];
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedClientResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(o => parseInt(o.value) === size);
      this.selectedIndexPageSize = selectedSize;
    },
    getVariatedClientResults (value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length < value.filter.options.length && checked.length > 0) {
          if (checked[0].value === '1') {
            this.active = true;
          } else {
            this.active = false;
          }
        } else {
          this.active = undefined;
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('clients/fetchClients', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.active': this.active,
        pageSize: this.pageSize,
        page: this.currentPage,
      });
    },
    createClient () {
      this.$store.dispatch('clients/createClient').then((boolean) => {
        if (boolean) {
          this.getVariatedClientResults({});
        }
      });
    },
    toggleCreateIoTModal () {
      this.showCreateIoTModal = !this.showCreateIoTModal;
    },
    createIoT () {
      this.$store.dispatch('clients/createIoT', { name: this.createIoTName }).then((boolean) => {
        if (boolean) {
          this.toggleCreateIoTModal();
          this.getVariatedClientResults({});
        }
      });
    },
    downloadClient () {
      this.$store.dispatch('clients/downloadClient');
    },
  },
  computed: {
    clients () {
      return this.$store.getters['clients/clients'];
    },
    pagination () {
      return this.$store.getters['clients/pagination'];
    },
    user () {
      return this.$store.getters['user/user'];
    },
  },
  created() {
    this.$store.dispatch('clients/fetchClients', {
      pageSize: this.pageSize,
      page: this.currentPage,
    }).then(() => {
      this.selectedPageSize(this.pagination.pageSize);
    });
  },
};
</script>
<style lang="scss">
.iot-input {
  margin: 1rem;
  padding: .2rem .4rem;
}
</style>
