<template>
  <div
    :class="'side-navigation__level-' + getDepth(depth)">
    <expand-collapse
      id="expand-collapse"
      content-id="expand_collapse_content"
      v-for="(node, i) in nodes"
      :key="i"
      :node="node"
      :depth="depth">
      <template slot="title" v-if="node.subPages && node.subPages.length > 0">
        <img
          v-if="depth === 1 && getLogo(node.name)"
          :src="getLogo(node.name)"
          class="navigation-logo" />
        <span
          :class="`navigation__level-${depth}`">
          {{node.name}}
        </span>
      </template>
      <template slot="title" v-else>
        <router-link
          :to="getRoute(node)"
          :class="`navigation-link navigation__level-${depth}`">
          {{node.name}}
        </router-link>
      </template>

      <template slot="content" v-if="node.subPages && node.subPages.length > 0">
        <tree-navigation
          :nodes="node.subPages"
          :route="getRoute(node)"
          :depth="depth + 1" />
      </template>

    </expand-collapse>
  </div>
</template>

<script>
import ExpandCollapse from '../expand-collapse';
export default {
  name: 'TreeNavigation',
  components: {
    ExpandCollapse,
  },
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    route: {
      type: String,
      default: '',
    },
    depth: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    getDepth (depth) {
      let num = 'one';
      switch (depth) {
        case 2:
          num = 'two';
          break;
        case 3:
          num = 'three';
          break;
        default:
          return 'one';
      }
      return num;
    },
    getRoute (node) {
      return this.route + `${node.routeUrl ? node.routeUrl : '/' + node.name.toLowerCase()}`;
    },
    getLogo (name) {
      try {
        return require(`../../assets/images/${name.toLowerCase()}-no-text.svg`);
      } catch(e) {
        console.error(e);
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.side-navigation__level-one {
  // font-weight: bold;
  margin: 0;
  padding: 0.5rem 0 0.4375rem 0;
  // border-bottom: 0.0625rem solid color(gray-light);
}
.side-navigation__level-two {
  padding-left: 1rem;
}
.side-navigation__level-three {
  padding-left: 1.5rem;
}
.navigation-logo {
  height: 1.5rem;
}
.side-navigation__level-two .expand-collapse__title span {
  margin-left: 0;
}
.router-link-active::before {
  background-color: color(blue);
  content: '';
  width: 0.3rem;
  height: 1.25rem;
  position: absolute;
  left: 0.2rem;
}
.navigation-link {
  display: inline-block;
}
.navigation-link:hover,
.navigation-link:active {
  text-decoration: none;
}
.navigation-link:hover::before,
.navigation-link:active::before {
  background-color: rgba($color: #000000, $alpha: .3);
  content: '';
  width: 100%;
  height: 1.25rem;
  padding: 0.3rem 0;
  margin-top: -0.3rem;
  position: absolute;
  left: 0;
}
.navigation__level-1,
.navigation__level-2 {
  @include semi-bold();
}
</style>
