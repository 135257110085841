/* Store module to handle Clubs */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  categories: [],
};

const mutations = {
  categories (state, payload) {
    payload.map(category => {
      category.displayName = category.name;
      category.value = category.id;
    });
    state.categories = payload;
  },
  article (state, payload) {
    const categoryIndex = state.categories.findIndex(c => c.id === payload.categoryId);
    state.categories[categoryIndex].articles.rows.push(payload);
  },
  updateArticle (state, payload) {
    const categoryIndex = state.categories.findIndex(c => c.id === payload.categoryId);
    const articleIndex = state.categories[categoryIndex].articles.rows.findIndex(a => a.id === payload.id);
    state.categories[categoryIndex].articles.rows[articleIndex] = payload;
  },
  removeArticle (state, payload) {
    const categoryIndex = state.categories.findIndex(c => c.id === payload.categoryId);
    Vue.delete(state.categories[categoryIndex].articles.rows, state.categories[categoryIndex].articles.rows.findIndex(a => a.id === payload.id));
  },
};

const getters = {
  categories: state => {
    return state.categories;
  },
  category: state => id => {
    for (const category of state.categories) {
      if (category.id === id) {
        return category;
      }
    }

    return {
      active: true,
    };
  },
  article: state => id => {
    for (const category of state.categories) {
      for (const article of category.articles.rows) {
        if (article.id === id) {
          return article;
        }
      }
    }

    return {};
  },
};

const actions = {
  fetchArticle: async (context, params) => {
    let path = `articles/${params}`;

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('updateArticle', data.result);

      return true;
    }

    return false;
  },
  fetchArticles: async (context, params) => {
    let path = 'articles';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params).map(k => {
        if(params[k] !== undefined) {
          return esc(k) + '=' + esc(params[k]);
        }
      }).filter(q => q !== undefined).join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('categories', data.result);
    }
  },
  createCategory: async (context, params) => {
    let path = 'articles/category';
    context.dispatch('ui/toggleActiveLoaders', 'store-create-category', { root: true });
    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'store-create-category', { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('categories', data.result);
      return true;
    }

    return false;
  },
  editCategory: async (context, params) => {
    let path = `articles/category/${params.id}`;
    context.dispatch('ui/toggleActiveLoaders', 'store-create-category', { root: true });
    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'store-create-category', { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('categories', data.result);
      return true;
    }

    return false;
  },
  deleteCategory: async (context, params) => {
    let path = `articles/category/${params}`;

    let response = {};
    try {
      response = await Vue.axios.delete(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.data) {
      context.dispatch('fetchArticles');
    }
  },
  createArticle: async (context, params) => {
    let path = 'articles';
    context.dispatch('ui/toggleActiveLoaders', 'store-create-article', { root: true });
    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'store-create-article', { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('article', data.result);
      return true;
    }

    return false;
  },
  editArticle: async (context, params) => {
    let path = `articles/${params.id}`;
    context.dispatch('ui/toggleActiveLoaders', 'store-create-article', { root: true });

    let response = {};
    try {
      response = await Vue.axios.patch(path, params.params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'store-create-article', { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('updateArticle', data.result);
      return true;
    }

    return false;
  },
  deleteArticle: async (context, params) => {
    let path = `articles/${params.id}`;
    context.dispatch('ui/toggleActiveLoaders', 'store-delete-article', { root: true });

    let response = {};
    try {
      response = await Vue.axios.delete(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.data) {
      context.commit('removeArticle', params);

      return response.data.success;
    }

    return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
