<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div class="businessboard--header">
        <span class="h1">{{
          $t('Businessboard.membershipCalculationIncomes')
        }}</span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
        <!-- <help-text :helpTextData="helpText" /> Change this later -->
      </div>
      <template v-if="!isLoading">
        <div v-if="membershipCalculationIncomes.hasPremium">
          <modal-filter @changeFilter="changeFilter" />
          <section class="businessboard--table">
            <list-table
              :isLoading="isLoading"
              :items="membershipCalculationIncomes.rows"
              :headers="membershipCalculationIncomes.columns">
            </list-table>
          </section>
        </div>
        <div v-else>
          <missing-license licenseType="Businessboard Premium" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table-game-calculation';
import ModalFilter from '@/components/modal/modal-filter-membershipcalculation';
import MissingLicense from '@/components/businessboard/missing-license.vue';
// import helpText from '../../components/help-text/help-text.vue';
export default {
  name: 'MembershipCalculationCategories',
  components: {
    ListTable,
    ModalFilter,
    MissingLicense,
    // helpText,
  },

  data: function () {
    return {
      isLoading: true,
      helpText: [
        {
          title: '',
          content: this.$t('Businessboard.budgetAndMemberInfo'),
        },
      ],
    };
  },

  methods: {
    changeFilter(filter) {
      this.isLoading = true;
      this.$store.state.businessboard.periodFilter = filter;
      this.$store
        .dispatch('businessboard/fetchMembershipCalculationIncomes', filter)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  computed: {
    membershipCalculationIncomes() {
      return this.$store.getters['businessboard/membershipcalculationincomes'];
    },
  },

  mounted() {
    this.$store
      .dispatch(
        'businessboard/fetchMembershipCalculationIncomes',
        this.$store.state.businessboard.periodFilter
      )
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.businessboard--header {
  height: 3rem;
  margin: 1rem 0;
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.businessboard {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.businessboard--table {
  margin-bottom: 2rem;
}
</style>
