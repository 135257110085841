<template>
  <div class="legend">
    <div class="head">{{ year1 }}</div>
    <div class="head">{{ year2 }}</div>
    <div></div>
    <div
      class="item"
      :style="'background:' + colors[0]"></div>
    <div
      class="item"
      :style="'background: #FFF;border: 2px solid ' + colors[0]"></div>
    <div class="text">
      {{ companyName }}
      {{ $t('outcome') }}
    </div>

    <div
      class="line"
      style="border-top: 2px solid #00a99d"></div>
    <div></div>
    <div class="text">
      {{ companyName }}
      {{ $t('outcome') }}
    </div>

    <div
      class="line"
      style="border-top: 1px dashed #f08565"></div>
    <div></div>
    <div class="text">
      {{ $t('forecast') }}
    </div>

    <div
      class="item"
      style="background: #fbebdd"></div>
    <div></div>
    <div class="text">
      {{ $t('Businessboard.budget') }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    diagramData: {
      type: Object,
    },
    companyName: {
      type: String,
    },
  },
  data() {
    return {
      year1: this.parseSettingData().Years[0],
      year2: this.parseSettingData().Years[1],
      colors: ['#0D3746', '#00A99D', '#ABDED1', '#F08565', '#4B2D88', '#'],
    };
  },
  watch: {
    diagramData() {
      this.year1 = this.parseSettingData().Years[0];
      this.year2 = this.parseSettingData().Years[1];
    },
  },
  mounted() {},
  computed: {},
  methods: {
    parseSettingData() {
      return JSON.parse(this.diagramData.settings);
    },
  },
};
</script>
<style lang="scss" scoped>
.legend {
  margin-left: auto;
  width: 30rem;
  padding: 1rem;
  font-size: 12px;
  display: grid;
  grid-template-columns: 4rem 4rem auto;
  font-weight: 400;
  font-size: 18px;
  justify-items: center;
  .head {
    font-weight: 600;
  }
  .line {
    width: 25px;
    margin: auto;
  }
  .item {
    border-radius: 2px;
    width: 25px;
    height: 15px;
  }
  .text {
    justify-self: left;
  }
  > div {
    margin: 5px;
  }
}
</style>
