<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div class="businessboard--header">
        <span
          class="h1"
          v-if="budgets.length > 0">
          {{ budgets[0].companyName }}
        </span>
      </div>
      <div v-if="budgets && budgets.length > 0">
        <div
          v-for="(budget, i) in budgets"
          v-bind:key="'budget' + i"
          class="businessboard--category">
          <div class="businessboard--title">
            <div class="title">
              {{ $t('forecastFor') }} {{ budget.financialYear }}
            </div>
            <input
              hidden
              type="checkbox"
              :id="'budget' + i"
              v-model="budget.isHidden" />
            <label :for="'budget' + i">
              <use-svg
                svg="arrow-right"
                :class="{ 'svg--180-negative': budget.isHidden }" />
            </label>
          </div>
          <div
            v-if="budget.isHidden"
            class="button--rows">
            <base-button
              @click="addResultUnitsToForecast(budget.id)"
              :text="$t('Businessboard.addResultUnitsToForecast')"
              v-element-loader="{ loaderKey: 'add-result-units' }">
            </base-button>
            <info-icon :description="$t('Businessboard.addResultUnitsInfo')">
              <template
                class="info-icon"
                #icon>
                <use-svg svg="bb-info-icon" />
              </template>
            </info-icon>
          </div>
          <skeleton
            height="500px"
            width="100%"
            loaderKey="add-result-units" />
          <list-table
            v-if="budget.isHidden && !isLoading('add-result-units')"
            :items="budget.budgets.rows"
            :headers="budget.budgets.columns"
            :companyId="companyId"
            @variationResults="getVariatedOrderResults">
          </list-table>
        </div>
      </div>
      <div v-else>
        {{ $t('BudgetCategory.noForecast') }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import store from '@/store';
import ListTable from '@/components/list-table/list-table-businessboard';
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '@/mixins/localization-mixin';
import InfoIcon from '@/components/businessboard/tooltip-icon';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import Skeleton from '../../components/skeleton.vue';

export default {
  name: 'Budget',
  mixins: [LocalizationMixin],
  components: {
    ListTable,
    UseSvg,
    InfoIcon,
    BaseButton,
    Skeleton,
  },
  directives: {
    elementLoader,
  },
  props: {
    companyId: {
      type: String,
      default: '0',
    },
  },
  data: function () {
    return {
      showModal: false,
      filters: {
        years: [],
        statuses: [
          this.$t('BudgetCategory.established'),
          this.$t('BudgetCategory.published'),
          this.$t('BudgetCategory.draft'),
        ],
      },
    };
  },
  methods: {
    getVariatedOrderResults(value) {
      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length > 0) {
          this.filters['Filter.' + value.filter.id] = checked.map(a => a.value);
        } else {
          delete this.filters['Filter.' + value.filter.id];
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('businessboardBudget/fetchForecastList', {
        id: this.$route.params.id,
        ...this.filters,
      });
    },
    addResultUnitsToForecast(id) {
      this.$store
        .dispatch('businessboardBudget/patchResultUnitsToForecast', {
          id: id,
        })
        .then(() => {
          this.fetchForecastList();
        });
    },
    // isLoading(loadingKey) {
    //    const activeLoaders = store.getters['ui/activeLoaders'];
    //    return activeLoaders.includes(loadingKey);
    // },
    fetchForecastList() {
      this.$store.dispatch('businessboardBudget/fetchForecastList', {
        id: this.companyId,
        'Filter.financialYear': this.financialYear,
      });
    },
    isLoading(loaderKey) {
      const activeLoaders = store.getters['ui/activeLoaders'];
      return activeLoaders.includes(loaderKey);
    },
  },
  computed: {
    budgets() {
      return _.orderBy(
        this.$store.getters['businessboardBudget/forecastList'],
        'financialYear'
      );
    },
  },

  mounted() {
    this.companyId = this.$route.params.id;
    this.fetchForecastList();
  },
};
</script>

<style lang="scss" scoped>
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
}
.businessboard--header {
  margin: 1rem 0 0;
  display: flex;
  .h1 {
    flex: 100;
  }
}
.businessboard--category {
  padding-bottom: 3rem;
}
.businessboard--title {
  background-color: color(bb-primary);
  color: color(white);
  display: grid;
  grid-template-columns: 97% 3%;
  padding: 1rem;
  .title {
    font-size: 1.2rem;
  }
  .title--button {
    background-color: transparent;
    border: none;
    color: white;
  }
}
.modal__content {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
  &__group {
    text-align: left;
    div {
      margin: 0.5rem;
    }
  }
}
.button {
  background-color: color(bb-primary);
  margin-left: 0.5rem;
  display: block;
}

.button--rows {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 5.75rem;
  gap: 0.7rem;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
