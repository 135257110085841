<template>
  <div
    v-if="comment"
    class="comment_filled">
    <div class="author">
      <div class="quote">
        <use-svg svg="bb-quote-icon" />
      </div>
      {{ ` ${timeFormat(comment.updateTime)} ${$t('of')} ${comment.author}` }}
    </div>
    <div
      class="warning"
      v-if="showWarning">
      <use-svg
        class="warning-icon"
        svg="bb-warning-icon" />
      {{ $t('dataUpdatedSinceComment') }}
    </div>
    <template v-if="isEditing">
      <textarea
        v-model="comment.text"
        @change="postComment">
      </textarea>
    </template>
    <template v-else>
      <div class="comment_text">{{ comment.text }}</div>
    </template>
    <div
      class="kebab"
      v-if="canEdit">
      <template v-if="showMenu">
        <div @click="isEditing = true">
          <info-icon description="Redigera">
            <template #icon>
              <use-svg
                svg="bb-edit-icon"
                color="white"
                stroke="black" />
            </template>
          </info-icon>
        </div>
        <div @click="deleteComment">
          <info-icon :description="$t('delete')">
            <template #icon>
              <use-svg svg="bb-trash-icon" />
            </template>
          </info-icon>
        </div>
        <div
          @click="
            showMenu = false;
            isEditing = false;
          ">
          ✕
        </div>
      </template>
      <template v-else>
        <div @click="showMenu = true">
          <use-svg svg="bb-kebab-icon" />
        </div>
      </template>
    </div>
  </div>

  <div
    v-else
    class="comment_unfilled">
    <div class="quote">
      <use-svg svg="bb-quote-icon" />
    </div>
    <textarea
      :placeholder="$t('addComment')"
      v-model="text"
      @change="postComment">
    </textarea>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import { MonthsLabelsShort } from '@/core/consts';

import UseSvg from '@/components/use-svg';
import InfoIcon from '@/components/businessboard/tooltip-icon';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'Comment',
  mixins: [LocalizationMixin],

  inject: ['lastUpdate'],

  components: {
    UseSvg,
    InfoIcon,
  },

  props: {
    comment: VueTypes.shape({
      author: VueTypes.string,
      updateTime: VueTypes.string,
      text: VueTypes.string,
      id: VueTypes.string,
    }),
    lastUpdate: VueTypes.array,
    section: VueTypes.number,
    companyId: VueTypes.string,
    canEdit: VueTypes.bool.def(false),
    postedComment: VueTypes.func.def(() => {}),
  },

  data() {
    return {
      isHovering: false,
      showMenu: false,
      isEditing: false,
      text: '',
    };
  },

  computed: {
    showWarning() {
      return (
        new Date(this.comment.updateTime).getTime() <
        new Date(this.lastUpdate).getTime()
      );
    },
  },

  methods: {
    timeFormat(d) {
      if (!d) {
        return;
      }

      d = new Date(d);

      return `${d.getDate()} ${this.$t(
        `MonthsShort.${MonthsLabelsShort[d.getMonth()]}`
      ).toLowerCase()} ${d.getFullYear()}`;
    },
    deleteComment() {
      if (confirm(this.$t('removeCommentConfirmation'))) {
        this.comment.text = '';
        this.postComment();
      }
    },
    postComment() {
      let comment = {
        id: this.comment ? this.comment.id : '',
        author:
          this.$store.state.user.user.firstName +
          ' ' +
          this.$store.state.user.user.lastName,
        text: this.comment ? this.comment.text : this.text,
        section: this.section,
        companyId:
          this.companyId === 'consolidatedaccounts' ? '' : this.companyId,
      };
      this.$store.dispatch('businessboard/postComment', comment).then(() => {
        this.comment.updateTime = new Date();
        this.comment.author = comment.author;
        this.isEditing = false;
        this.$emit('postedComment');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.quote {
  display: inline-block;
  margin-right: measures(spacing-xs);
  vertical-align: top;
}

textarea {
  border: 1px solid color(gray-dark);
  border-radius: 3px;
  padding: measures(spacing-xs);
  width: 100%;
  overflow: auto;
}

.comment_unfilled {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 2rem auto;
}

.comment_filled {
  margin-top: 3rem;
  position: relative;
  display: grid;
  grid-template-rows: min-content min-content auto;

  .author {
    font-size: 14px;
    color: color(gray-darker);
    margin: 1rem 0;
  }

  textarea {
    height: 18rem;
  }

  .kebab {
    background-color: white;
    position: absolute;
    top: 0px;
    right: 0px;
    border: 1px solid color(gray-dark);
    border-radius: 8px;
    display: grid;
    grid-auto-flow: column;

    div,
    svg {
      cursor: pointer;
    }

    > div {
      width: 1.5rem;
      text-align: center;
      width: 28px;
      height: 28px;
      text-align: center;
      vertical-align: middle;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;
      display: flex;
    }
  }
}

.warning {
  display: flex;

  padding: 0.5rem;
  margin-bottom: 1rem;

  background-color: color(error-light);
  border-radius: 3px;

  &-icon {
    margin-right: 0.5rem;
  }
}

.comment_text {
  white-space: pre-wrap;
  max-height: 9rem;
  overflow-y: hidden;
  border-left: 1px solid color(gray);
  padding: 0 0.5rem;
}

.comment_text:hover {
  overflow-y: scroll;
}

.title {
  font-weight: 700;
  font-size: 14px;
}

.toggle {
  margin-left: auto;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  .course_checkbox {
    height: 20px;
    width: 20px;
    border: 1px solid color(black);
    border-radius: 50%;
    display: inline-flex;
    margin-right: 5px;
    svg {
      margin: auto;
    }
  }
}

.button-hover {
  &:hover {
    background-color: color(white);
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}
</style>
