import breakpoints from '@/assets/styles/_variables.scss';
import _ from 'lodash';

const state = {
  breakpoints: {
    'to-small': false,
    'small': false,
    'to-medium': false,
    'medium': false,
    'to-large': false,
    'large': false,
    'to-x-large': false,
    'x-large': false,
  },
  screenOrientation: window.innerHeight > window.innerWidth ? 'landscape' : 'portrait',
};

const mutations = {
  set_responsive_data (state, payload) {
    _.set(state, payload.path, payload.value);
  },
};

const getters = {
  breakpoints: state => state.breakpoints,
  screenOrientation: state => state.screenOrientation,
};

const actions = {
  subscribeToResponsiveEvents ({ commit }) {
    _.forOwn(breakpoints, (mediaQuery, key) => {
      const mql = window.matchMedia(mediaQuery);
      const callback = ({ matches }) => {
        commit('set_responsive_data', { path: ['breakpoints', key], value: matches });
      };
      mql.addListener(callback);
      callback(mql);
    });
    window.addEventListener('orientationchange', e => {
      commit('set_responsive_data', { path: 'screenOrientation', value: window.innerHeight > window.innerWidth ? 'landscape' : 'portrait' });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
