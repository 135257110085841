<template>
  <div :class="{ 'template-diagrams': showAllDiagrams || includeDiagram }">
    <div class="header">
      <diagram-header
        :elementID="templateId + '_game-month-rounds'"
        :include="includeDiagram"
        @includeDiagram="e => (includeDiagram = e)"
        :chosenFormat="fileFormat"
        :diagram="diagram"
        :templateId="templateId"
        :showDiagram="showAllDiagrams || includeDiagram">
        <template #title>
          {{ $t('roundsPerMonth') }}
        </template>

        <template #settings>
          <modal-filter
            :titleText="$t('roundsPerMonth')"
            :comparisonYearCount="1"
            :showDisplaySettings="true"
            :showCourses="true"
            :storedFilter="diagram?.settings || '{}'"
            @changeFilter="e => toggleDiagram(e)"
            @saveFilter="e => saveFilter(e)" />
        </template>
      </diagram-header>
    </div>
    <div
      class="diagram"
      :id="templateId + '_game-month-rounds'">
      <div v-if="diagram && (showAllDiagrams || includeDiagram)">
        <game-month-rounds
          v-if="JSON.parse(diagram.settings)['Show'] === 0"
          :diagram="diagram"
          :fileFormat="fileFormat" />
        <game-month-rounds2
          v-if="JSON.parse(diagram.settings)['Show'] === 1"
          :diagram="diagram"
          :fileFormat="fileFormat" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalFilter from '@/components/reports-templates/settings-modal/gamecalculation';
import DiagramHeader from '@/components/reports-templates/diagram-header';
import GameMonthRounds from '@/components/reports-templates/game-month-rounds';
import GameMonthRounds2 from '@/components/reports-templates/game-month-rounds2';

export default {
  name: 'BusinessboardReports',
  components: {
    ModalFilter,
    DiagramHeader,
    GameMonthRounds,
    GameMonthRounds2,
  },
  props: {
    diagram: {},
    templateId: String,
    include: Boolean,
    showAllDiagrams: Boolean,
  },

  data() {
    return {};
  },
  computed: {
    fileFormat() {
      return this.$store.getters['businessboardReportingTool/settings']
        .fileFormat;
    },
    includeDiagram() {
      return this.diagram?.includedInTemplate || false;
    },
  },
  methods: {
    toggleDiagram(e) {
      this.diagramToRender = e.show;
      this.$store.dispatch('businessboardReportingTool/postDiagram', {
        templateId: this.templateId,
        settings: e,
        diagramType: 13,
      });
    },
    saveFilter(e) {
      e.save = true;
      this.toggleDiagram(e);
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
</style>
