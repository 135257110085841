<template>
  <div class="voucher">
    <div
      class="voucher__standard__title"
      @click="expand">
      <h2>
        {{ item.name }}
        <span
          v-if="
            item &&
            ((item && item.courses && item.courses.length > 0) ||
              (item && item.categories && item.categories.length > 0) ||
              (item && item.competitions && item.competitions.length > 0))
          "><use-svg
svg="connected-icon"/></span>
      </h2>
      <span>
        {{ $t('edited') }} {{ formatDateAndTime(item.lastChanged) }}
      </span>
      <use-svg
        v-if="!item.standard && isExpanded"
        class="svg--180-negative voucher__arrow-down"
        svg="arrow-right" />
      <use-svg
        v-else-if="!item.standard"
        class="voucher__arrow-up"
        svg="arrow-right" />
    </div>
    <div
      class="voucher__holder"
      v-if="item.standard || isExpanded">
      <div class="voucher__content">
        <div class="voucher__content--image">
          <div
            :class="[
              'voucher__result--customized-wrapper',
              {
                'alternative-layout':
                  item.template && item.template.alternativeLayout,
              },
            ]">
            <div class="voucher__result--customized-image">
              <template v-if="item.paperImageUrl">
                <div
                  class="voucher__result--customized-text voucher__result--customized-text-upper">
                  {{ item.upperText }}
                </div>
                <img
                  :src="
                    item.paperImageKey
                      ? item.paperImageUrl + item.paperImageKey
                      : item.paperImageUrl
                  " />
                <div
                  class="voucher__result--customized-text voucher__result--customized-text-lower">
                  {{ item.lowerText }}
                </div>
              </template>
              <template v-else>
                <template v-if="item.digitalImageUrl">
                  <img
                    :src="
                      item.digitalImageKey
                        ? item.digitalImageUrl + item.digitalImageKey
                        : item.digitalImageUrl
                    " />
                </template>
              </template>
            </div>
          </div>
        </div>
        <div class="voucher__content--first">
          <div class="info__bottom">
            <button
              class="info__bottom"
              @click="editVoucher">
              <use-svg svg="edit-icon" />
            </button>
            <button
              class="info__bottom"
              @click="toggleDeleteVoucherModal"
              v-if="!item.standard">
              <use-svg svg="trash-icon" />
            </button>
            <modal-window :show="showDeleteVoucherModal">
              <div class="modal__header">
                {{ $t('deleteVoucher') }}
              </div>
              <div class="modal__content">
                <p v-if="item.courses.length > 0">
                  {{ $t('deleteVoucherConnected', { itemName: item.name }) }}?
                </p>
                <p v-else>
                  {{ $t('deleteVoucherSure', { itemName: item.name }) }}?
                </p>
              </div>
              <div class="modal__content--actions">
                <base-button
                  class="button--background-gray-dark"
                  :text="$t('cancel')"
                  @click="toggleDeleteVoucherModal">
                </base-button>
                <base-button
                  :text="$t('deleteVoucher')"
                  @click="deleteVoucher(item.id)" />
              </div>
            </modal-window>
          </div>
        </div>
        <div
          class="voucher__content--second"
          v-if="item.standard">
          <div class="info__content">
            <p>{{ $t('OnTag.voucherInfo') }}</p>
          </div>
        </div>
        <div
          class="voucher__form"
          v-if="!item.standard">
          <div
            v-if="
              item &&
              item.courses &&
              courses.length > 0 &&
              (typeName === 'friendly' || typeName === 'neutral')
            ">
            <addable-custom-select
              :options="courses"
              :label="$t('addCourses')"
              :selected="item.courses ? item.courses : []"
              @selectedConnections="setCourses" />
          </div>
          <!-- TODO: Add and verify this when BE has done their part -->
          <div
            v-if="
              item && competitions.length > 0 && typeName === 'competition'
            ">
            <addable-date-filter
              :options="competitions"
              :label="$t('addCompetitions')"
              :modal-title="$t('addCompetitions')"
              :selected="item.competitions ? item.competitions : []"
              @selectedConnections="setCompetitions" />
          </div>
          <!-- TODO: Test and verify categories when BE is done with connections -->
          <div
            v-if="item && categories.length > 0 && typeName === 'competition'">
            <addable-custom-select
              :label="$t('addCategories')"
              :options="categories"
              :selected="
                item.competitionCategories ? item.competitionCategories : []
              "
              @selectedConnections="setCategories" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import ModalWindow from '@/components/modal/modal';
import BaseButton from '@/components/form-elements/base-button';
import AddableCustomSelect from '@/components/addable-custom-select/addable-custom-select';
import AddableDateFilter from '@/components/addable-date-filter/addable-date-filter';

export default {
  name: 'Voucher',
  components: {
    UseSvg,
    ModalWindow,
    BaseButton,
    AddableCustomSelect,
    AddableDateFilter,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    type: Number,
    typeName: String,
  },
  data() {
    return {
      isExpanded: false,
      showDeleteVoucherModal: false,
    };
  },
  methods: {
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    formatDateAndTime(dateAndTime) {
      const dateString = new Date(dateAndTime).toLocaleDateString('sv-SE');
      const timeString = new Date(dateAndTime).toLocaleTimeString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return dateString + ' ' + timeString;
    },
    editVoucher() {
      this.$router.push({
        name: `edit-${this.typeName}-voucher`,
        params: { id: this.item.id, typeName: this.typeName, type: this.type },
      });
    },
    toggleDeleteVoucherModal() {
      this.showDeleteVoucherModal = !this.showDeleteVoucherModal;
    },
    deleteVoucher(id) {
      this.$store
        .dispatch('voucher/deleteVoucher', {
          id: id,
        })
        .then(() => {
          this.$toasted.success('Voucher was deleted');
          this.toggleDeleteVoucherModal();
          this.isExpanded = false;
        });
    },
    duplicateVoucher(item) {
      this.$router.push({
        name: `create-${this.typeName}-voucher`,
        params: {
          type: this.type,
          typeName: this.typeName,
          copyOfVOucher: {
            name: this.item.name,
            paperImageUrl: this.item.paperImageUrl,
            paperImageKey: this.item.paperImageKey,
            digitalImageUrl: this.item.digitalImageUrl,
            digitalImageKey: this.item.digitalImageKey,
            upperText: this.item.upperText,
            lowerText: this.item.lowerText,
            webLink: this.item.webLink,
          },
        },
      });
    },
    setCourses(selectedCourses) {
      let voucher = this.item;
      voucher.courseIds = selectedCourses.map(course => course.value);
      this.$store.dispatch('voucher/editVoucher', voucher).then(() => {
        this.$emit('fetch', this.item.id);
      });
    },
    // TODO: Verify this when BE has done their part
    setCategories(selectedCategories) {
      let voucher = this.item;
      voucher.categories = selectedCategories.map(category => category.value);
      voucher.competitionIds = voucher.competitions.map(
        competition => competition.value
      );
      this.$store.dispatch('voucher/editVoucher', voucher);
    },
    // TODO: Verify this when BE has done their part
    setCompetitions(selectedCompetitions) {
      let voucher = this.item;
      voucher.competitionIds = selectedCompetitions.map(
        competition => competition.value
      );
      voucher.categories = voucher.competitionCategories.map(
        category => category.value
      );
      this.$store.dispatch('voucher/editVoucher', voucher);
    },
  },
  computed: {
    courses() {
      return this.$store.getters['voucher/courses'];
    },
    categories() {
      return this.$store.getters['voucher/categories'];
    },
    // TODO: Add and verify this when BE has done their part
    competitions() {
      return this.$store.getters['voucher/competitions'];
    },
  },
  mounted() {
    if (this.typeName === 'friendly' || this.typeName === 'neutral') {
      this.$store.dispatch('voucher/fetchCourses', { id: this.item.id });
    } else {
      this.$store.dispatch('voucher/fetchCompetitions', { id: this.item.id });
      this.$store.dispatch('voucher/fetchCategories', { id: this.item.id });
    }
  },
};
</script>

<style lang="scss">
.voucher {
  margin-bottom: 1rem;
}
.voucher__holder {
  display: flex;
  width: 100%;
  background-color: color(gray-lighter);

  .voucher__result--customized-wrapper {
    display: flex;
    left: 0.5rem;
    right: 0.5rem;
    top: 0.5rem;
    justify-content: space-evenly;
    &.alternative-layout {
      right: initial;
      width: 40%;
    }

    .voucher__result--customized-text-upper {
      font-size: 0.41rem;
      width: 100%;
      text-align: center;
      top: 0px;
      position: absolute;
    }

    .voucher__result--customized-text-lower {
      font-size: 0.41rem;
      width: 100%;
      text-align: center;
      bottom: 0px;
      position: absolute;
    }
    .voucher__result--customized-image {
      background-color: white;
      position: relative;
      width: 14.4375rem;
      // height: 20.25rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      aspect-ratio: 4/5;
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        margin: auto;
        // margin: auto;
        // margin-bottom: -4px;
      }
      &.highlight {
        background-color: color(white);
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
      }
    }
  }
  .voucher__result--info-wrapper {
    position: absolute;
    top: 2.5rem;
    width: 6.9rem;
    font-size: 0.4rem;
    &.alternative-layout {
      top: initial;
      bottom: 1rem;
    }
    .voucher__result--info-header {
      text-align: center;
      // pixels because rem below 6px doesn't work?
      font-size: 5px;
      &.highlight {
        background-color: white;
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
        position: absolute;
        width: 100%;
      }
    }
    .voucher__result--info-text {
      width: 48%;
      padding: 0.1875rem 0.3125rem 0 0.3125rem;
      word-break: break-word;
      resize: none;
      overflow: hidden;
      border: none;
      height: 60px;
      // pixels because rem below 6px doesn't work?
      font-size: 3.5px;
      &.highlight {
        background-color: white;
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
        position: absolute;
        width: 50%;
      }
    }
  }
}
.voucher__standard__title {
  cursor: pointer;
  background-color: color(gray-light);
  padding: 1rem;
  @include body-tiny;
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    flex: 1;

    svg {
      color: color(gray-dark);
    }
  }
  span {
    margin-right: 0.5rem;
  }
  svg {
    color: color(gray-dark);
  }
  .voucher__arrow-up {
    position: relative;
    top: 0.125rem;
  }
  .voucher__arrow-down {
    position: relative;
    top: -0.25rem;
  }
}
.voucher__content {
  display: flex;
  padding: 0.5rem;
  width: 100%;
}
.voucher__form {
  padding: 0.5rem;
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  > div {
    flex: 1 1 auto;
    margin: 0 1.25rem 0.625rem 0;

    &:first-child {
      padding-left: 1.25rem;
      border-left: 1px solid color(gray-dark);
    }

    &:nth-child(2) {
      padding-left: 1.25rem;
      border-left: 1px solid color(gray-dark);
    }
    .addable-custom-select .input .select__wrapper {
      margin-bottom: 0.5rem;
    }
  }
}
.voucher__content--image {
  background-color: color(gray);
  padding: 0.5rem;
  position: relative;
  img {
    width: 6.875rem;
    height: 18.875rem;
  }
}
.voucher__content--first {
  display: flex;
  width: 25%;
  flex-direction: column;
  padding: 0.5rem;
  .info__content {
    ul {
      margin: 0;
      padding-left: 1.25rem;
    }
  }
  .info__bottom {
    @include body-tiny;
    display: flex;
    justify-content: flex-end;
    color: color(gray-darkest);
    color: color(gray-dark);
    border: none;
    background: transparent;
    margin-top: auto;
  }
}
.voucher__content--second {
  padding: 0.5rem;
  width: 65%;

  p {
    margin-top: 0;
  }
}
</style>
