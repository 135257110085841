var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"selectData--div"},[_c('div',[_c('div',{staticClass:"section"},[_c('div',{staticClass:"display--flex"},[_c('div',{staticClass:"radio",on:{"click":e => _vm.copyMemberQuantityClicked('quantityBudgeted')}},[(_vm.displayBudgetOrActual === true)?_c('use-svg',{staticClass:"selected--icon",attrs:{"svg":"bb-selected-icon"}}):_vm._e()],1),_c('label',[_vm._v(" Budgeterat ")])]),_c('div',{class:[
            'amount--div',
            { 'amount--div--hidden': !_vm.displayBudgetOrActual },
          ]},[_c('div',[_c('input',{attrs:{"type":"text","inputmode":"numeric"},domProps:{"value":0},on:{"blur":e => _vm.changeMemberValues(e.target.value, 'quantityBudgeted')}}),_vm._v(" % ")])])]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"display--flex"},[_c('div',{staticClass:"radio",on:{"click":e => _vm.copyMemberQuantityClicked('quantityActual')}},[(_vm.displayBudgetOrActual === false)?_c('use-svg',{staticClass:"selected--icon",attrs:{"svg":"bb-selected-icon"}}):_vm._e()],1),_c('label',[_vm._v("Utfall")])]),_c('div',{class:[
            'amount--div',
            { 'amount--div--hidden': _vm.displayBudgetOrActual },
          ]},[_c('div',[_c('input',{attrs:{"type":"text","inputmode":"numeric"},domProps:{"value":0},on:{"blur":e => _vm.changeMemberValues(e.target.value, 'quantityActual')}}),_vm._v(" % ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }