<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <span class="h1">OnTag Dashboard</span>
        <span v-if="isLoading" class="element--is-loading"></span>
      </div>
      <section class="status-boxes">
        <div class="status-boxes__box" v-for="(box, i) in printersOptions" :key="i">
          <div class="status-boxes__box--container">
            <div class="box__row box__type">
              <span class="box__type--type">Skrivare:</span>
            </div>
            <div class="box__row box__status">
              <h3 class="box__status--name">{{ box.displayName }}</h3>
              <div v-if="box.status === true" class="box__status--container">
                <img src="../../assets/images/oval-green.svg" alt="" class="box__status--image">
                <span class="box__status--text">Ok</span>
              </div>
              <div v-else class="box__status--container">
                <img src="../../assets/images/oval-grey.svg" alt="" class="box__status--image">
                <span class="box__status--text">Fel</span>
              </div>
            </div>
            <div v-if="box.status !== true" class="box__status--error">
              <p>{{ box.message }}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="status-boxes">
        <div class="status-boxes__box" v-for="(box, i) in clientOptions" :key="i">
          <div class="status-boxes__box--container">
            <div class="box__row box__type">
              <span class="box__type--type">Klient:</span>
            </div>
            <div class="box__row box__status">
              <h4 class="box__status--name">{{ box.displayName }}</h4>
              <div v-if="box.status === true" class="box__status--container">
                <img src="../../assets/images/oval-green.svg" alt="" class="box__status--image">
                <span class="box__status--text">Ok</span>
              </div>
              <div v-else class="box__status--container">
                <img src="../../assets/images/oval-grey.svg" alt="" class="box__status--image">
                <span class="box__status--text">Fel</span>
              </div>
            </div>
            <div v-if="box.status !== true" class="box__status--error">
              <p>{{ box.message }}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="printer-queue">
        <div class="printer-queue--container">
          <div class="select-printer">
            <p class="first-letter">{{ $t('printerQueue') }}:</p>
            <custom-select
              v-if="selectedPrinterIndex !== undefined"
              :required="false"
              @optionSelected="setSelectedPrinterIndex"
              :selected="selectedPrinterIndex"
              :options="printersOptions"
              class="select-printer__select"/>
          </div>
            <div class="ontag--utilitites input-group--inline">
              <div class="search-wrapper ontag--utilitites__utility">
                <div class="input">
                  <label
                    for="searchQuery"
                    class="input__label label__inline first-letter">{{ $t('search') }}</label>
                  <input
                    id="searchQuery"
                    class="input__input input__inline search-input"
                    @keyup.enter="getVariatedPrinterQueueResults"
                    v-model="searchQuery"
                    type="text"
                    name="searchQuery"/>
                </div>
                <base-button
                  class="search-button"
                  :text="$t('search')"
                  :button-size-small="true"
                  @click="getVariatedPrinterQueueResults" />
              </div>
              <base-button
                class="button--icon-to-left ontag--utilitites__utility"
                :text="$t('deleteMarkedPrinterJobs')"
                :button-size-small="true"
                @click="toggleDeleteMarkedPrinterJobsModal"
                :disabled="allSelected.length === 0">
                <use-svg svg="trash-icon"/>
              </base-button>
              <base-button
                class="button--icon-to-left ontag--utilitites__utility"
                :text="$t('emptyPrinterQueue')"
                :button-size-small="true"
                @click="toggleEmptyPrinterQueueModal">
                <use-svg svg="export-icon"/>
              </base-button>
            </div>
            <list-table
              :items="printerQueue.rows"
              :headers="printerQueue.columns"
              @variationResults="getVariatedPrinterQueueResults"
              :use-cursor="false"
              @allSelected="getPrinterQueuesSelected"
              :page-size="100"
              ref="printerQueueListTable">
            </list-table>
            <results-pagination
              :first-row-on-page="pagination.firstRowOnPage"
              :last-row-on-page="pagination.lastRowOnPage"
              :row-count="pagination.rowCount"
              :page-size="pagination.pageSize"
              :page-count="pagination.pageCount"
              :current-page="pagination.currentPage"
              :next-page="getNextPage()"
              :prev-page="getPrevPage()"
              store="printerQueue"
              @currentPage="getVariatedPrinterQueueResults"/>
            <br>
            <div>
              <h4>Statistik för scorekort</h4>
              <div class="flex">
                <div class="bar" v-if="paperPrintData">
                  <span class="title">Utskrivna scorekort</span>
                  <bar-chart
                  :styles="{height: '16rem'}"
                  :labels="['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']"
                  :data='paperPrintData.data'
                  :tooltips='paperPrintData.tooltips'/>
                </div>
                <div v-if="paperPrintData" class="totalCount">
                  <h3>Totalt antal utskrivna scorekort</h3>
                    <p>
                        <span>Innevarande år:</span>
                        <span>{{paperPrintTotalCurrentY}} st</span>
                    </p>
                    <p>
                        <span>Föregående år:</span>
                        <span>{{paperPrintTotalLastY}} st</span>
                    </p>
                </div>
                <div class="bar" v-if="digitalPrintData">
                  <span class="title">Digitala scorekort</span>
                  <bar-chart
                  :styles="{height: '16rem'}"
                  :labels="['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']"
                  :data='digitalPrintData.data'
                  :tooltips='digitalPrintData.tooltips'/>
                </div>
                <div v-if="digitalPrintData" class="totalCount">
                  <h3>Totalt antal digitala scorekort</h3>
                    <p>
                        <span>Innevarande år:</span>
                        <span>{{digitalPrintTotalCurrentY}} st</span>
                    </p>
                    <p>
                        <span>Föregående år: </span>
                        <span>{{digitalPrintTotalLastY}} st</span>
                    </p>
                </div>
              </div>
            </div>
            <modal-window :show="showDeleteMarkedPrinterJobsModal">
              <div class="modal__header">
                {{ $t('deleteMarkedPrinterJobs') }}
              </div>
              <div class="modal__content">
                <div>
                  <h2>{{ $t('deleteMarkedPrinterJobsQuestion') }}?</h2>
                </div>
              </div>
              <div class="modal__content--actions">
                <base-button
                  class="button--background-gray-dark"
                  :text="$t('cancel')"
                  @click="toggleDeleteMarkedPrinterJobsModal">
                </base-button>
                <base-button
                  :text="$t('deleteMarkedPrinterJobs')"
                  @click="deleteMarkedPrinterJobs"/>
              </div>
            </modal-window>
            <modal-window :show="showEmptyPrinterQueueModal">
              <div class="modal__header">
                {{ $t('emptyPrinterQueue') }}
              </div>
              <div class="modal__content">
                <div>
                  <h2>{{ $t('emptyPrinterQueueQuestion') }}?</h2>
                </div>
              </div>
              <div class="modal__content--actions">
                <base-button
                  class="button--background-gray-dark"
                  :text="$t('cancel')"
                  @click="toggleEmptyPrinterQueueModal">
                </base-button>
                <base-button
                  :text="$t('emptyPrinterQueue')"
                  @click="emptyPrinterQueue"/>
              </div>
            </modal-window>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import CustomSelect from '@/components/form-elements/custom-select';
import ModalWindow from '@/components/modal/modal';
import ResultsPagination from '@/components/results-pagination';
import BarChart from '@/components/charts/ontag-bar-chart';

export default {
  name: 'Ontag',
  components: {
    ListTable,
    BaseButton,
    UseSvg,
    CustomSelect,
    ModalWindow,
    ResultsPagination,
    BarChart,
  },
  data () {
    return {
      isLoading: true,
      searchQuery: '',
      currentPage: 0,
      active: undefined,
      showDeleteMarkedPrinterJobsModal: false,
      showEmptyPrinterQueueModal: false,
      allSelected: [],
      selectedPrinterIndex: undefined,
      sort: {},
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
      paperPrintData: false,
      paperPrintTotalCurrentY: false,
      paperPrintTotalLastY: false,
      digitalPrintData: false,
      digitalPrintTotalCurrentY: false,
      digitalPrintTotalLastY: false,
    };
  },
  computed: {
    printersOptions () {
      return this.$store.getters['printers/printersOptions'];
    },
    clientOptions () {
      return this.$store.getters['clients/clientOptions'];
    },
    clients () {
      return this.$store.getters['clients/clients'];
    },
    printerQueue () {
      return this.$store.getters['printerQueue/printerQueue'];
    },
    pagination () {
      return this.$store.getters['printerQueue/pagination'];
    },
    statistics () {
      return this.$store.getters['ontagStatistics/statistics'];
    },
    user () {
      return this.$store.getters['user/user'];
    },
  },
  created () {
    this.$store.dispatch('ontagStatistics/fetchStatistics')
      .then(() => {
        this.parse(this.$store.state.ontagStatistics.statistics);
      });
    this.$store.dispatch('printers/fetchPrintersOptions')
      .then(() => {
        this.$store.dispatch('printers/fetchAllPrinters')
          .then(() => {
            this.fetchPrinterQueue(this.$store.getters['printers/printersOptionsById']().value);
            this.selectedPrinterIndex = this.printersOptions.findIndex(options => options.value === this.$store.getters['printers/printersOptionsById']().value);
          });
      })
      .finally(() => {
        this.isLoading = false;
        this.$store.dispatch('clients/fetchAllClients').then(() => {
          // this.selectedPageSize(this.pagination.pageSize)
        });
        this.$store.dispatch('ontagStatistics/fetchStatistics').then(() => {
          this.parse(this.$store.state.ontagStatistics.statistics);
        });
        this.$store.dispatch('printers/fetchPrintersOptions').then(() => {
          this.$store.dispatch('printers/fetchAllPrinters').then(() => {
            this.fetchPrinterQueue(this.$store.getters['printers/printersOptionsById']().value);
            this.selectedPrinterIndex = this.printersOptions.findIndex(options => options.value === this.$store.getters['printers/printersOptionsById']().value);
          });
        });
      });
  },
  methods: {
    fetchPrinterQueue (id) {
      this.$store.dispatch('printerQueue/fetchPrinterQueue', {
        id: id,
        pageSize: this.pagination.pageSize,
        page: 0,
      });
    },
    getVariatedPrinterQueueResults (value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length < value.filter.options.length && checked.length > 0) {
          if (checked[0].value === '1') {
            this.active = true;
          } else {
            this.active = false;
          }
        } else {
          this.active = undefined;
        }
      }

      if (value.pageSize) this.pagination.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('printerQueue/fetchPrinterQueue', {
        id: this.printersOptions[this.selectedPrinterIndex].value,
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.status': this.active,
        pageSize: this.pagination.pageSize,
        page: this.searchQuery.length > 0 ? '1' : this.currentPage,
      });
    },
    deleteMarkedPrinterJobs() {
      this.$store.dispatch('printerQueue/deleteMarkedPrinterQueues', {
        ids: this.allSelected,
      }).then(() => {
        this.toggleDeleteMarkedPrinterJobsModal();
        this.allSelected = [];
        this.currentPage = 1;
        if (this.$refs && this.$refs.printerQueueListTable) this.$refs.printerQueueListTable.resetSelectAll();
        this.fetchPrinterQueue(this.printersOptions[this.selectedPrinterIndex].value);
      });
    },
    emptyPrinterQueue() {
      this.$store.dispatch('printerQueue/deletePrinterQueue', {
        id: this.$route.params.id,
      }).then(() => {
        this.toggleEmptyPrinterQueueModal();
      });
    },
    toggleDeleteMarkedPrinterJobsModal () {
      this.showDeleteMarkedPrinterJobsModal = !this.showDeleteMarkedPrinterJobsModal;
    },
    toggleEmptyPrinterQueueModal () {
      this.showEmptyPrinterQueueModal = !this.showEmptyPrinterQueueModal;
    },
    getPrinterQueuesSelected(value) {
      this.allSelected = value;
    },
    setSelectedPrinterIndex (printer) {
      this.selectedPrinterIndex = this.printersOptions.findIndex(options => options.value === printer.value);
      this.fetchPrinterQueue(printer.value);
    },
    getPrevPage () {
      return this.$store.getters['users/previousPage'];
    },
    getNextPage () {
      return this.$store.getters['users/nextPage'];
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedPrinterQueueResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(o => parseInt(o.value) === size);
      this.selectedIndexPageSize = selectedSize;
    },
    parse2(fields, data) {
      var d = {};
      var t = {};
      d.currentYear = fields.map(function (y) {
        var v = data.currentYearData.find(x => x.name === y);
        return v ? v.value : 0;
      });
      t.currentYear = fields.map(function (y) {
        var v = data.currentYearData.find(x => x.name === y);
        return v ? v.displayValue : '';
      });
      if (data.lastYearData) {
        d.lastYear = fields.map(function (y) {
          var v = data.lastYearData.find(x => x.name === y);
          return v ? v.value : 0;
        });
        t.lastYear = fields.map(function (y) {
          var v = data.lastYearData.find(x => x.name === y);
          return v ? v.displayValue : '';
        });
      }
      return { data: d, tooltips: t };
    },
    parse(statistics) {
      this.paperPrintData = this.parse2(
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], statistics.paperPrintData);
      this.paperPrintTotalCurrentY = this.statistics.paperPrintTotalData.currentYearData[0].value;
      this.paperPrintTotalLastY = this.statistics.paperPrintTotalData.lastYearData[0].value;
      this.digitalPrintData = this.parse2(
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], statistics.digitalPrintData);
      this.digitalPrintTotalCurrentY = this.statistics.digitalPrintTotalData.currentYearData[0].value;
      this.digitalPrintTotalLastY = this.statistics.digitalPrintTotalData.lastYearData[0].value;
    },
  },
};
</script>
<style lang="scss">
.h1 {
    display: inline-block;
    font-size: font(h1);
    font-weight: bold;
    vertical-align: top;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .element--is-loading {
    margin-left: .5rem;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 20px;
  }
.ontag {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.ontag__content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  &.dashboard {
    background-color: transparent;
    border-radius: 0;
  }
}
.status-boxes {
  display: flex;
  margin-bottom: 1.5rem;
}
.status-boxes__box {
  flex-direction: column;
  margin-right: 2rem;
}
.status-boxes__box--container {
  background-color: color(white);
  box-shadow: 0.125rem 0.125rem 0.4375rem 0.125rem rgba(0, 0, 0, 0.1);
  padding: 0 0.75rem;
}
.box__row {
  display: flex;
}
.box__type--type {
  @include body-tiny();
  padding-top: 0.7rem;
}
.box__type--name {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.box__status {
  display: flex;
  margin-top: auto;
  margin-bottom: 0.5rem;
}
.box__status--name {
  margin-right: 2rem;
}
.box__status--container {
  position: relative;
}
.box__status--image {
  margin-top: 0.3rem;
  width: 3rem;
  height: 3rem;
}
.box__status--text {
  @include body-tiny();
  position: absolute;
  top: 1.4rem;
  left: 1rem;
}
.box__status--error {
  @include body-small();
  padding: 0.1rem 0;
  position: relative;
}
.box__status--error::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: -0.75rem;
  right: -0.75rem;
  bottom: 0;
  border-top: 1px solid color(gray);
}
.printer-queue {
  display: flex;
  width: 100%;
}
.printer-queue--container {
  background-color: color(white);
  box-shadow: 0.125rem 0.125rem 0.4375rem 0.125rem rgba(0, 0, 0, 0.1);
  padding: 0 0.75rem;
  width: 100%;
}
.select-printer {
  display: flex;
}
.select-printer__select {
  min-width: 10rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.search-wrapper {
  display: flex;
  margin-left: auto;
}
.ontag--utilitites {
  display: flex;
}
.ontag--utilitites__utility {
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
}
.flex {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
}
.bar {
    width: 50%;
    height: 19rem;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 5px 10px 8px #ccc;
    margin: 15px;
    padding: 1rem;
  }

.totalCount {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
  text-align: center;
  padding-left: 30px;
}

.totalCount > p {
  display: flex;
  justify-content: space-between;
  width: 17rem;
    span:nth-child(2n) {
      color: #0399E4;
    }
}

.totalCount > div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 24px;
  p {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    :first-child {
        :nth-child(2) {
          color: color(blue-dark)
      }
    }
    :nth-child(2) {
      font-weight: bolder;
    }

    span {
    display: flex;
    flex-direction: column;
    text-align: center;
     :first-child {
      margin-bottom: 20px;
     }
    }
  }
}

  .title {
    font-size: 1.1rem;
  }
</style>
