<template>
  <div class="te-tabbar">
    <div class="te-tabbar-items">
      <button
        v-for="item in localItems"
        :key="item.id"
        :disabled="item.disabled"
        :class="[
          'te-tabbar-item',
          localCurrentItem && item.id === localCurrentItem.id
            ? 'te-tabbar-item-selected'
            : '',
        ]"
        @click="select(item)">
        <div class="te-tabbar-item-title">{{ item.title }}</div>
      </button>
      <!--div class="te-tabbar-item-spacer"></div-->
      <div class="te-tabbar-buttons">
        <slot name="buttons"></slot>
      </div>
    </div>
    <div class="te-tabbar-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeTabbar',
  emits: ['select'],
  watch: {
    items: {
      handler: function (newVal, oldVal) {
        this.localItems = newVal;
      },
      deep: true,
    },
    currentItem: {
      handler: function (newVal, oldVal) {
        this.localCurrentItem = newVal;
      },
      deep: true,
    },
  },
  props: {
    items: {
      type: Array,
    },
    currentItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      localItems: this.items,
      localCurrentItem: this.currentItem,
    };
  },
  methods: {
    select(item) {
      this.localCurrentItem = item;
      this.$emit('select', item);
    },
  },
};
</script>

<style lang="scss">
.te-tabbar {
  font-family: 'Tietoevry sans 1';
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  min-height: 0;
  width: 100%;
  .te-tabbar-items {
    display: flex;
    flex-direction: row;
    gap: 2px;
    .te-tabbar-item {
      all: unset;
      cursor: pointer;
      color: var(--Gray-Gray-1, #64676a);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      align-self: end;
      .te-tabbar-item-title {
        padding: 3px var(--Paddings-Padding-M, 16px);
      }
    }
    .te-tabbar-item[disabled] {
      .te-tabbar-item-title {
        opacity: 0.5;
      }
      cursor: default;
    }
    .te-tabbar-item:not([disabled]):hover::after {
      background: var(--Surface-Surface-Selector-Selected, #1861f2);
      cursor: default;
    }
    .te-tabbar-item::after {
      content: '';
      transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
      display: block;
      border-radius: 2px;
      margin-top: 1px;
      margin-bottom: 1px;
      height: 2px;
      flex-shrink: 0;
      align-self: stretch;
      background: var(--Gray-Gray-8, #ddddde);
    }
    .te-tabbar-item-selected {
      color: var(--Surface-Surface-Selector-Selected, #1861f2);
    }
    .te-tabbar-item-selected::after {
      height: 4px;
      margin-top: 0px;
      margin-bottom: 0px;
      background: var(--Surface-Surface-Selector-Selected, #1861f2);
    }
    .te-tabbar-item-spacer {
      height: 2px;
      flex: 1;
      flex-shrink: 0;
      margin-top: 1px;
      margin-bottom: 1px;
      border-radius: 2px;
      background-clip: border-box;
      background: var(--Gray-Gray-8, #ddddde);
      align-self: end;
    }
    .te-tabbar-buttons {
      flex: 1;
      margin-top: 1px;
      margin-bottom: 1px;
      border-radius: 2px;
      align-self: end;
      text-align: right;
      padding-bottom: 2px;
      border-bottom: 2px solid var(--Gray-Gray-8, #ddddde);
    }
    .te-tabbar-item-spacer {
      height: 2px;
      flex: 1;
      flex-shrink: 0;
      margin-top: 1px;
      margin-bottom: 1px;
      border-radius: 2px;
      background-clip: border-box;
      background: var(--Gray-Gray-8, #ddddde);
      align-self: end;
    }
    .te-tabbar-buttons {
      flex: 1;
      margin-top: 1px;
      margin-bottom: 1px;
      border-radius: 2px;
      align-self: end;
      text-align: right;
      padding-bottom: 2px;
      border-bottom: 2px solid var(--Gray-Gray-8, #ddddde);
    }
    .te-tabbar-item-spacer {
      height: 2px;
      flex: 1;
      flex-shrink: 0;
      margin-top: 1px;
      margin-bottom: 1px;
      align-self: stretch;
      border-radius: 2px;
      background-clip: border-box;
      background: var(--Gray-Gray-8, #ddddde);
      align-self: end;
    }
  }
  .te-tabbar-content {
    box-sizing: border-box;
    display: block;
    min-height: 0;
    flex-direction: column;
    flex: 1;
  }
}
</style>
