<template>
  <div class="businessboard">
    <span
      v-if="isLoading"
      class="element--is-loading"></span>
    <!-- <help-text :helpTextData="helpText" /> Change this later -->
    <modal-filter @changeFilter="changeFilter" />
    <div class="flex">
      <div
        class="bar"
        v-if="greenFeeData">
        <span class="title">{{ $t('greenFeeRevenue') }}</span>
        <span class="span">
          {{ dashboardData.greenFeeData.period }}
          {{ dashboardData.greenFeeData.currentYear }}
        </span>
        <bar-chart
          :styles="{ height: '16rem' }"
          :labels="greenFeeDataMonths"
          :data="yearStartMonth === 1 ? greenFeeData.data : greenFeeData.data"
          :tooltips="greenFeeData.tooltips" />
      </div>
      <div
        class="pie"
        v-if="incomeData">
        <span class="title">{{ $t('distributionIncome') }}</span>
        <span class="span">
          {{ dashboardData.incomeData.period }}
          {{ dashboardData.incomeData.currentYear }}
        </span>
        <pie-filterable
          :styles="{ height: '16rem' }"
          :labels="incomeData.labels"
          :data="incomeData.data"
          :tooltips="incomeData.tooltips" />
      </div>

      <div
        class="bar"
        v-if="gameCalculationData">
        <span class="title">{{ $t('GameCalculation.title') }}</span>
        <span class="span">
          {{ dashboardData.gameCalculationData.period }}
          {{ dashboardData.gameCalculationData.currentYear }}
        </span>
        <bar-horizontal
          :styles="{ height: '16rem' }"
          :labels="[
            'RevPATT',
            'CostPATT',
            $t('memberRevenueSlashCourse'),
            $t('AvgGreenFeeSlashCourse'),
          ]"
          :data="gameCalculationData.data"
          :tooltips="gameCalculationData.tooltips" />
      </div>

      <div
        class="pie"
        v-if="playedRoundsData">
        <span class="title">{{ $t('GameCalculation.playedRounds') }}</span>
        <span class="span">
          {{ dashboardData.playedRoundsData.period }}
          {{ dashboardData.playedRoundsData.currentYear }}
        </span>
        <pie-chart
          :styles="{ height: '16rem' }"
          :labels="[$t('totalGuests'), $t('totalMembers'), $t('xFactor')]"
          :data="playedRoundsData.data.currentYear"
          :tooltips="playedRoundsData.tooltips.currentYear"
          :legend="{ position: 'right' }"
          :colors="customColors"
          :borderWidth="10" />
      </div>
    </div>
    <div
      class="counter"
      v-if="counterData">
      <table>
        <tr>
          <th>{{ $t('playedRoundsTotal') }}</th>
          <th>{{ $t('memberRoundsAmount') }}</th>
          <th>{{ $t('greenfeeRoundsAmount') }}</th>
          <th>{{ $t('coveragePercentage') }}</th>
        </tr>
        <tr :key="counter">
          <td>{{ this.formatCounter(counterData.data.currentYear[0]) }}</td>
          <td>{{ this.formatCounter(counterData.data.currentYear[1]) }}</td>
          <td>{{ this.formatCounter(counterData.data.currentYear[2]) }}</td>
          <td>{{ this.formatCounter(counterData.data.currentYear[3]) }}%</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import BarHorizontal from '@/components/charts/bar-chart-horizontal';
import BarChart from '@/components/charts/bar-chart';
import PieChart from '@/components/charts/pie-chart';
import PieFilterable from '@/components/charts/pie-chart-filterable';
import ModalFilter from '@/components/modal/modal-filter-fiscal-withyear';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
// import helpText from '../../components/help-text/help-text.vue';

import colors from '@/assets/styles/_variables.scss';

export default {
  name: 'BusinessboardDashboard',
  mixins: [LocalizationMixin],
  components: {
    BarHorizontal,
    BarChart,
    PieChart,
    PieFilterable,
    ModalFilter,
    // helpText,
  },
  props: {},
  data() {
    return {
      counter: 0,
      isLoading: true,
      greenFeeData: false,
      gameCalculationData: false,
      playedRoundsData: false,
      incomeData: false,
      counterData: false,
      customColors: [
        colors['bb-sec2'],
        colors['bb-primary'],
        colors['bb-sec4'],
      ],
      helpText: [
        {
          title: 'Dashboard',
          content: 'diagramInfo1',
        },
        {
          title: '',
          content: 'diagramInfo2',
        },
        {
          title: '',
          content: 'diagramInfo3',
        },
      ],
    };
  },
  computed: {
    percentageValues() {
      const sum = this.playedRoundsData.data.currentYear.reduce(
        (acc, current) => acc + current
      );
      return this.playedRoundsData.data.currentYear
        .map(d => (d / sum) * 100)
        .toLocaleString('sv-SE', { maximumFractionDigits: 0 });
    },
    dashboardData() {
      return this.$store.getters['businessboard/dashboard'];
    },
    yearStartMonth() {
      if (this.$store.state.businessboard.settings.useCalenderYear) return 1;
      return this.$store.state.businessboard.settings.financialYearStartMonth;
    },
    greenFeeDataMonths() {
      let months = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
      ];
      if (this.yearStartMonth > 1) {
        months = [].concat(
          months.slice(this.yearStartMonth - 1),
          months.slice(1, this.yearStartMonth - 1)
        );
      }
      return months.map(i => this.$t('MonthsShort.' + i));
    },
  },
  methods: {
    changeFilter(filter) {
      this.greenFeeData = false;
      this.gameCalculationData = false;
      this.playedRoundsData = false;
      this.incomeData = false;
      this.isLoading = true;
      this.counter = 0;
      this.$store.state.businessboard.periodFilter = filter;
      this.$store
        .dispatch('businessboard/fetchDashboard', filter)
        .finally(() => {
          this.parse(this.$store.state.businessboard.dashboard);
          this.isLoading = false;
          requestAnimationFrame(this.countup);
        });
    },
    countup() {
      this.counter += 1;
      if (this.counter < 100) {
        requestAnimationFrame(this.countup);
      }
    },
    formatCounter(val) {
      return (~~((val * this.counter) / 100))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    parse2(fields, data) {
      var d = {};
      var t = {};
      d.currentYear = fields.map(function (y) {
        var v = data.currentYearData.find(x => x.name === y);
        return v ? v.value : 0;
      });
      t.currentYear = fields.map(function (y) {
        var v = data.currentYearData.find(x => x.name === y);
        return v ? v.displayValue : '';
      });
      if (data.lastYearData) {
        d.lastYear = fields.map(function (y) {
          var v = data.lastYearData.find(x => x.name === y);
          return v ? v.value : 0;
        });
        t.lastYear = fields.map(function (y) {
          var v = data.lastYearData.find(x => x.name === y);
          return v ? v.displayValue : '';
        });
      }
      if (data.budget) {
        d.budget = fields.map(function (y) {
          var v = data.budget.find(x => x.name === y);
          return v ? v.value : 0;
        });
        t.budget = fields.map(function (y) {
          var v = data.budget.find(x => x.name === y);
          return v ? v.displayValue : '';
        });
      }
      return { data: d, tooltips: t };
    },
    parse(dashBoardData) {
      let months = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
      ];
      if (this.yearStartMonth > 1) {
        months = [].concat(
          months.slice(this.yearStartMonth - 1),
          months.slice(1, this.yearStartMonth - 1)
        );
      }
      this.greenFeeData = this.parse2(months, dashBoardData.greenFeeData);
      this.gameCalculationData = this.parse2(
        ['RevPATT', 'CostPatt', 'MemberIncomePerRound', 'GuestIncomePerRound'],
        dashBoardData.gameCalculationData
      );
      this.playedRoundsData = this.parse2(
        ['TotalGuests', 'TotalMembers', 'XFactor'],
        dashBoardData.playedRoundsData
      );
      this.incomeData = {
        labels: dashBoardData.incomeData.currentYearData.map(x => x.name),
        data: {
          currentYear: dashBoardData.incomeData.currentYearData.map(
            x => x.value
          ),
        },
        tooltips: {
          currentYear: dashBoardData.incomeData.currentYearData.map(
            x => x.displayValue
          ),
        },
      };
      this.counterData = this.parse2(
        [
          'PlayedRoundsTotal',
          'MemberRoundNumbers',
          'GreenFeeRoundNumber',
          'CoveringPercent',
        ],
        dashBoardData.counterData
      );
    },
  },
  created() {
    this.$store
      .dispatch(
        'businessboard/fetchDashboard',
        this.$store.state.businessboard.periodFilter
      )
      .then(() => {
        this.parse(this.$store.state.businessboard.dashboard);
        requestAnimationFrame(this.countup);
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.$store.dispatch('businessboard/fetchSettings');
  },
};
</script>
<style lang="scss" scoped>
.counter {
  td,
  th {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  table {
    width: 100%;
    margin-top: 2rem;
    border: none;
    border-collapse: collapse;
  }
  table td + td,
  table th + th {
    border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
  }
  th {
    font-weight: normal;
    font-size: 1.1rem;
  }
  td {
    font-weight: bold;
    font-size: 3rem;
    color: color(bb-primary);
    text-align: center;
  }
}
.title {
  font-size: 1.1rem;
}
.span {
  font-size: 0.7rem;
  float: right;
}
.flex {
  display: flex;
  flex-flow: row wrap;
}
.bar {
  width: 40rem;
  height: 19rem;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 5px 10px 8px #ccc;
  margin: 10px;
  padding: 1rem;
}
.pie {
  width: 30rem;
  height: 19rem;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 5px 10px 8px #ccc;
  margin: 10px;
  padding: 1rem;
}
.businessboard--header {
  height: 3rem;
  margin: 1rem 0;
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.businessboard {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.businessboard--table {
  margin-bottom: 2rem;
}
</style>
