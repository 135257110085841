<template>
  <div class="list-table__wrapper">
    <table class="list-table">
      <thead>
        <tr>
          <template v-for="(header, index) in headers">
            <th
              :key="index"
              v-if="
                hideInMobile.includes(header.value) && mq('to-large')
                  ? false
                  : true
              ">
              <component
                :is="header.order ? 'button' : 'div'"
                :class="[
                  { 'button list-table__button': header.order },
                  { 'list-table__div': !header.order },
                ]"
                @click.stop="header.order && sortResults(header.value)">
                <div v-if="header.checkbox">
                  <div
                    class="input checkbox"
                    @click="selectAll">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="selectAll"
                        name="selectAll"
                        v-model="allSelected" />
                      <label
for="selectAll"><span><span></span></span></label>
                    </div>
                  </div>
                </div>
                <div v-else-if="header.order">
                  <span>{{ header.displayName }}</span>
                  <use-svg svg="unsorted-icon" />
                </div>
                <multi-select
                  v-else-if="header.filter"
                  :use-icon="true"
                  icon-name="filter-icon"
                  :label="header.displayName"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, header.value)"
                  :options="header.filterValues">
                </multi-select>
                <span v-else>{{ header.displayName }}</span>
              </component>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="index"
          :class="[
            {
              datePast: isInThePast(new Date(item.deadline)),
              checked: selected.find(s => s === item.id),
            },
            { pointer: addCursor },
          ]"
          @mouseover="useCursor ? (addCursor = true) : (addCursor = false)"
          @mouseout="useCursor ? (addCursor = false) : (addCursor = false)">
          <template v-for="(header, indexColumn) in headers">
            <td
              v-if="
                hideInMobile.includes(header.value) && mq('to-large')
                  ? false
                  : true
              "
              :class="[header.routerLink ? 'router-link' : header.value]"
              :key="indexColumn">
              <template v-if="header.value === 'checkbox'">
                <div class="input checkbox">
                  <div class="checkbox--group">
                    <input
                      type="checkbox"
                      :value="item.id"
                      v-model="selected"
                      @change="select" />
                    <label
:for="item.id"><span><span></span></span></label>
                  </div>
                </div>
              </template>
              <template v-else-if="header.value === 'status'">
                <template v-if="item.status === 1">
                  <use-svg svg="circle-ok" />
                  <span class="status--text">{{ item.statusText }}</span>
                </template>
                <template v-else-if="item.status === 0">
                  <use-svg svg="circle-error" />
                  <span class="status--text">{{ item.statusText }}</span>
                </template>
              </template>
              <template v-else-if="header.value === 'active'">
                <template v-if="item.active">
                  <use-svg svg="circle-ok" />
                  <span class="status--text">{{ item.activeText }}</span>
                </template>
                <template v-else>
                  <use-svg svg="circle-error" />
                  <span class="status--text">{{ item.activeText }}</span>
                </template>
              </template>
              <template v-else-if="header.routerLink">
                <template
                  v-if="header.value === '/courseguides/edit-course-guide/'">
                  <router-link :to="header.value + item.clubId + '/' + item.id">
                    <use-svg
                      svg="arrow-right"
                      class="svg--90-negative" />
                  </router-link>
                </template>
                <template v-else>
                  <router-link :to="header.value + item.id">
                    <use-svg
                      svg="arrow-right"
                      class="svg--90-negative" />
                  </router-link>
                </template>
              </template>
              <template
                v-else-if="
                  header.value === 'lastLogin' ||
                  header.value === 'activeChanged' ||
                  header.value === 'statusChanged' ||
                  header.value === 'printTime' ||
                  header.value === 'date'
                ">
                {{
                  item[header.value]
                    ? formatDateAndTime(item[header.value])
                    : ''
                }}
              </template>
              <template v-else-if="header.input">
                <div class="input">
                  <input
                    v-if="header.inputMaxLength === 0"
                    :id="header.value + '-input-' + index"
                    class="input__input"
                    v-model="item[header.value]"
                    type="number"
                    :name="header.value + '-input-' + index"
                    @input="e => changedInput(true)" />
                  <input
                    v-else
                    :id="header.value + '-input-' + index"
                    class="input__input"
                    v-model="item[header.value]"
                    type="text"
                    :name="header.value + '-input-' + index"
                    maxlength="3"
                    @input="e => changedInput(true)" />
                  <span
                    v-if="header.unit"
                    class="input__unit">{{ header.unit }}</span>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </td>
          </template>
        </tr>
        <slot />
      </tbody>
    </table>
  </div>
</template>

<script>
import mq from '@/mixins/mq.js';
import UseSvg from '@/components/use-svg';
import MultiSelect from '@/components/form-elements/multi-select';
export default {
  name: 'ListTable',
  mixins: [mq],
  components: {
    UseSvg,
    MultiSelect,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    hideInMobile: {
      type: Array,
      default: () => [],
    },
    useCursor: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      allSelected: false,
      selected: [],
      checked: false,
      sortDirection: 'asc',
      addCursor: true,
      max: 3,
    };
  },
  methods: {
    isInThePast(date) {
      const today = new Date();

      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    selectAll() {
      this.selected = [];
      if (!this.allSelected) {
        let visibleItems = this.items.slice(0, this.pageSize);
        for (let i in visibleItems) {
          this.selected.push(visibleItems[i].id);
        }
      }
      this.$emit('allSelected', this.selected);
    },
    select() {
      if (this.selected.length === this.items.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
      this.$emit('allSelected', this.selected);
    },
    resetSelectAll() {
      this.allSelected = false;
    },
    formatDateAndTime(dateAndTime) {
      const dateString = new Date(dateAndTime).toLocaleDateString('sv-SE');
      const timeString = new Date(dateAndTime).toLocaleTimeString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return dateString + ' ' + timeString;
    },
    // TODO: Add routeToLink on tr-click
    routeToLink(url, id) {
      this.$router.push({ path: `${url}${id}` });
    },
    changedInput(value) {
      this.$emit('changedInput', value);
    },
    sortResults(column) {
      if (this.sortDirection === 'desc') {
        this.sortDirection = 'asc';
      } else {
        this.sortDirection = 'desc';
      }
      this.$emit('variationResults', {
        sort: { id: column, direction: this.sortDirection },
      });
    },
    filterResults(e, column) {
      this.$emit('variationResults', {
        filter: { id: column, options: e.options },
      });
    },
  },
  watch: {
    items() {
      this.selected = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.list-table__wrapper {
  overflow-x: auto;
}
.list-table {
  font-size: font(h4);
  border-collapse: collapse;
  width: 100%;
  thead {
    background-color: color(gray-light);
    th {
      text-align: left;
      padding: 0 0.625rem;
      &:first-child {
        padding-left: 0.8rem;
      }
      &:last-child {
        padding-right: 0.8rem;
      }
      .list-table__button {
        border: none;
        background-color: color(gray-light);
        min-height: 2.625rem;
        padding: 0;
        span {
          font-weight: bold;
          margin-right: 0.325rem;
          position: relative;
          top: -2px;
        }
      }
      .list-table__div {
        min-height: 2.625rem;
        span {
          font-weight: bold;
          margin-right: 0.325rem;
          position: relative;
          top: 10px;
        }
      }
    }
  }
  tbody {
    tr {
      &.pointer {
        cursor: auto;
      }
      &.datePast {
        background-color: color(gray-dark) !important;
        opacity: 0.3;
      }
      &:nth-child(even) {
        background-color: color(gray-lighter);
      }
      &.checked {
        background-color: color(blue-light);
      }
      td {
        padding: 0.625rem;
        text-align: left;
        &:first-child {
          padding-left: 0.8rem;
        }
        &:last-child {
          padding-right: 0.8rem;
          &.router-link {
            text-align: right;
          }
        }
        .input {
          display: flex;
          align-items: baseline;
          margin-bottom: 0;

          .input__unit {
            margin-left: 10px;
          }

          .input__input {
            width: 80%;
            @include media('large') {
              width: 40%;
            }
          }
        }
        .svg {
          margin-right: 0.2rem;
        }
        .status--text {
          vertical-align: super;
        }
      }
    }
  }
}
</style>
