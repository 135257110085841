<template>
  <div class="dropdown">
    <div v-if="dropdownHeading">
      <h3 class="dropdown-heading">{{ dropdownHeading }}</h3>
    </div>
    <div class="dropdown-content">
      <h4 class="dropdown-subHeading">{{ dropdownSubHeading }}</h4>
      <div class="period-input">
        <custom-select
          @optionSelected="selectedValueInDropdown"
          :selected="dropdownOptions[0].year"
          :options="formattedDropdownOptions">
        </custom-select>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/form-elements/custom-select-bb';

export default {
  name: 'Dropdown',
  components: {
    CustomSelect,
  },

  props: {
    dropdownHeading: {
      type: String,
      required: false,
      default: '',
    },
    dropdownSubHeading: {
      type: String,
      required: false,
      default: '',
    },
    dropdownOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    formattedDropdownOptions() {
      return this.dropdownOptions.map(m => ({
        value: m.year,
        displayName:
          m.year === this.currentYear
            ? `${this.$t('currentYear2')} (${this.currentYear})`
            : m.year.toString(),
      }));
    },
  },
  methods: {
    selectedValueInDropdown(e) {
      this.$emit('selectedValue', e);
    },
  },
};
</script>
;

<style lang="scss" scoped>
.dropdown-heading {
  margin: 1rem 0.5rem;
}

.dropdown-subHeading {
  margin: 0.5rem 0.5rem;
}

.period-input {
  padding: 0 0.5rem 0 0.5rem;
  input,
  select {
    border-radius: 2px;
    padding: 0.3rem;
    border: 1px solid #d8d8d8;

    background-color: #f9f9f9;
  }
}
</style>
