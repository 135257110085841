<template>
  <div class="ontag">
    <div class="ontag__content scorecard">
      <h1 class="first-letter">{{ $t('scorecard') }} {{ $t(`${typeName}`) }}</h1>
      <p v-if="typeName === 'friendly'">Här skapar ni och väljer ert kort ni önskar att ha på sällskapsrundor. Vanligast är att ha en standard som används på samtliga sällskapsrundor oavsett om man har olika slingor. Ni kan dock slinganpassa korten och ha olika layouter om ni så önskar.</p>
      <p v-else-if="typeName === 'competition'">Här skapar ni och väljer ert kort ni önskar att ha på Tävling. Vanligast är att ha en standard som används på samtliga. Ni kan dock anpassa korten och ha olika layouter om ni så önskar till respektive tävling. Ni kan i förväg bygga ett speciellt scorekort och koppla det till en speciell tävling eller kategori.</p>
      <p v-else>Här skapar ni och väljer ert kort ni önskar att ha på neutral rundor. Mer kommer.</p>
      <div class="scorecard__standard">
        <score-card
          v-if="standardScoreCard"
          :item="standardScoreCard"
          :type="type"
          :type-name="typeName"/>
      </div>
      <hr>
      <div class="scorecard-list__actions">
        <div class="search-wrapper">
          <div class="input">
            <label
              for="searchQuery"
              class="input__label label__inline">{{ $t('search') }}</label>
            <input
              id="searchQuery"
              class="input__input input__inline search-input"
              @keyup.enter="getVariatedUserResults"
              v-model="searchQuery"
              type="text"
              name="searchQuery"/>
          </div>
          <base-button
            class="search-button"
            :text="$t('search')"
            :button-size-small="true"
            v-element-loader="{ loaderKey: 'scorecard-fetch-scorecards' }"
            @click="getVariatedUserResults" />
        </div>
        <div class="scorecard__create">
          <base-button
            :text="$t('createScorecard')"
            @click="$router.push({ name: `create-${typeName}-scorecard`, params: { type: type, typeName: typeName }})"
            :button-size-small="true"/>
        </div>
      </div>
      <div class="scorecard__list" v-if="scorecards.length > 0">
        <h3>{{ $t('customizedScorecards') }}</h3>
        <score-card-list-table
          :items="scorecards"
          :type="type"
          :type-name="typeName"
          @fetch="fetch" />
      </div>
    </div>
  </div>
</template>

<script>
import ScoreCard from '@/components/score-card';
import BaseButton from '@/components/form-elements/base-button';
import ScoreCardListTable from '@/components/score-card-list-table/score-card-list-table';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'ScoreCardList',
  components: {
    ScoreCard,
    BaseButton,
    ScoreCardListTable,
  },
  props: {
    type: Number,
    typeName: String,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    getVariatedUserResults() {
      this.$store.dispatch('scorecard/fetchScorecards', {
        type: this.type,
        search: this.searchQuery.length > 0 ? this.searchQuery : '',
      });
    },
    fetch() {
      this.scorecards = {};
      this.standardScoreCard = {};
      this.$store.dispatch('scorecard/fetchScorecards', {
        type: this.type,
        search: this.searchQuery.length > 0 ? this.searchQuery : '',
      });
    },
  },
  computed: {
    scorecards: {
      get: function () {
        return this.$store.getters['scorecard/scorecards'].filter(scorecard => !scorecard.standard);
      },
      set: function (val) {
        return val;
      },
    },
    standardScoreCard: {
      get: function () {
        return this.$store.getters['scorecard/scorecards'].find(scorecard => scorecard.standard === true);
      },
      set: function (val) {
        return val;
      },
    },
  },
  watch: {
    type: function () {
      this.fetch();
    },
  },
  created () {
    this.fetch();
  },
  beforeDestroy () {
    this.scorecards = {};
  },
};
</script>
<style lang="scss">
  .scorecard__standard {
    margin-top: 1rem;
  }
  hr {
    width: 100%;
    border-top: 1px solid color(gray);
  }
  .scorecard-list__actions {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-start;
    input {
      margin-right: 1rem;
    }
    .search-wrapper {
      display: flex;
      button {
        margin-left: 0;
      }
    }
    .scorecard__create button {
      margin-left: 1rem;
    }
  }
  .scorecard__list h3 {
    margin-top: 0;
  }
</style>
