<template>
  <div class="diagram-area">
    <div>
      <dot-chart
        class="dot-chart"
        :amount="data.totalMembers"
        :label-translation-key="'members'" />
      <render-change
        class="render-change"
        :change="{
          value: data.comparedToBudget.num,
          percentage: data.comparedToBudget.percentage,
          comparisonLabel: `${$t('Businessboard.budget').toLowerCase()} ${
            settings.currentYear
          }`,
        }" />

      <render-change
      v-if="data.comparedToLastMonth"
        class="render-change"
        :change="{
          value: data.comparedToLastMonth.num,
          percentage: data.comparedToLastMonth.percentage,
          comparisonLabel: `${$t(
            `${
              data.dataMonth
                ? $t(`MonthsShort.${monthTranslationKeys[data.dataMonth - 2]}`)
                : ''
            }`
          ).toLowerCase()} ${settings.currentYear}`,
        }" />

      <render-change
      v-if="data.comparedToCurrentMonthLastYear"
        class="render-change"
        :change="{
          value: data.comparedToCurrentMonthLastYear.num,
          percentage: data.comparedToCurrentMonthLastYear.percentage,
          comparisonLabel: `${$t(
            `${
              data.dataMonth
                ? $t(`MonthsShort.${monthTranslationKeys[data.dataMonth - 1]}`)
                : ''
            }`
          ).toLowerCase()} ${settings.comparisonYear}`,
        }" />
    </div>

    <div>
      <h3>
        {{
          `
          ${$t('GameCalculation.distribution')}
          ${$t('MemberCalculation.junior')}/
          ${$t('MemberCalculation.senior')}`
        }}
      </h3>

      <div>
        <donut-chart-filterable
          :styles="{ height: '10rem', width: '10rem' }"
          :colors="data.colorKeys.map(c => colors[c])"
          :data="{
            labels: [
              $t('MemberCalculation.junior'),
              $t('MemberCalculation.senior'),
            ],
            values: [
              data.juniorMembersInPercentage,
              100 - data.juniorMembersInPercentage,
            ],
          }"
          :display-legend="false" />
      </div>

      <render-change
        class="render-change"
        :change="{
          value: data.juniorMembersComparedToLastYear.num,
          percentage: data.juniorMembersComparedToLastYear.percentage,
          comparisonLabel: settings.comparisonYear.toString(),
        }"
        :legend="{
          label: `${$t('MemberCalculation.junior')} ${
            data.juniorMembersInPercentage
          }%`,
          color: data.colorKeys[0],
        }" />

      <render-change
        class="render-change"
        :change="{
          value: data.seniorMembersComparedToLastYear.num,
          percentage: data.seniorMembersComparedToLastYear.percentage,
          comparisonLabel: settings.comparisonYear.toString(),
        }"
        :legend="{
          label: `${$t('MemberCalculation.senior')} ${
            100 - data.juniorMembersInPercentage
          }%`,
          color: data.colorKeys[1],
        }" />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

import RenderChange from '@/components/businessboard/member-calculation/render-change';
import DonutChartFilterable from '@/components/charts/donut-chart-filterable';
import DotChart from '@/components/charts/dot-chart';
import { commentTypeDefinition } from '@/components/businessboard/consts';

import colors from '@/assets/styles/_variables.scss';

export default {
  name: 'GolfOfficeFrontEndDotAndDoughnutDiagram',

  components: {
    RenderChange,
    DonutChartFilterable,
    DotChart,
  },

  mixins: [LocalizationMixin],

  props: {
    data: VueTypes.shape({
      dataMonth: VueTypes.number,
      comparedToBudget: VueTypes.shape({
        num: VueTypes.number,
        percentage: VueTypes.number,
      }),
      comparedToCurrentMonthLastYear: VueTypes.shape({
        num: VueTypes.number,
        percentage: VueTypes.number,
      }),
      comparedToLastMonth: VueTypes.shape({
        num: VueTypes.number,
        percentage: VueTypes.number,
      }),
      diagramId: VueTypes.number,
      juniorMembersComparedToLastYear: VueTypes.shape({
        num: VueTypes.number,
        percentage: VueTypes.number,
      }),
      juniorMembersInPercentage: VueTypes.number,
      seniorMembersComparedToLastYear: VueTypes.shape({
        num: VueTypes.number,
        percentage: VueTypes.number,
      }),
      totalMembers: VueTypes.number,
      colorKeys: VueTypes.arrayOf(VueTypes.string),
      comment: commentTypeDefinition,
    }),
    settings: VueTypes.shape({
      currentYear: VueTypes.number,
      comparisonYear: VueTypes.number,
      imageFormat: VueTypes.number,
      persist: VueTypes.bool,
      availableYears: VueTypes.arrayOf(VueTypes.number),
    }),
  },

  data() {
    return {
      colors,
    };
  },

  computed: {
    monthTranslationKeys: () => [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.diagram-area {
  display: grid;
  grid-template-columns: 3fr 2fr;
}
.render-change {
  margin-bottom: measure('spacing-l');
}
.dot-chart {
  margin-bottom: measure('spacing-xxl');
}
.donut-chart {
  margin-bottom: measure('spacing-l');
}
</style>
