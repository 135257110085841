<template>
  <div class="onservice">
    <div class="onservice--content">
      <div>
        <router-link to="/onservice/terminals">&laquo; {{ $t('back') }}</router-link>
      </div>
      <section class="onservice--terminal" >
        <h1 class="first-letter">{{ $t('createTerminal') }}</h1>
        <form noValidate @submit.prevent="createTerminal">
          <div class="content__input-group">
            <div class="input-group">
              <template>
                <div class="input-group">
                  <template>
                    <custom-select
                      :label="$t('club')"
                      id="club"
                      @optionSelected="selectedClub"
                      :options="clubs">
                    </custom-select>
                  </template>
                  <template>
                    <custom-select
                      class="first-letter"
                      :label="$t('type')"
                      id="type"
                      @optionSelected="selectedType"
                      :options="terminalTypeOptions">
                    </custom-select>
                  </template>
                  <div class="input">
                    <label
                      for="name"
                      class="input__label first-letter">{{ $t('name') }}*</label>
                    <input
                      id="name"
                      class="input__input"
                      v-model="name"
                      type="text"
                      required="true"
                      name="name"/>
                  </div>
                </div>
              </template>
            </div>
            <div class="input-group--inline user__actions">
              <router-link to="/onservice/terminals">
                <base-button
                :text="$t('cancel')">
                </base-button>
              </router-link>
                <base-button
                  class="first-letter"
                  :text="$t('createTerminal')"
                  type="submit"
                  v-element-loader="{ loaderKey: 'create-terminal' }"
                  :disabled="!canCreate"/>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/form-elements/custom-select';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
export default {
  name: 'OnServiceCreateTerminal',
  components: {
    CustomSelect,
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      type: 0,
      clubName: '',
      clubId: '',
      terminalTypeOptions: [
        {
          displayName: 'MultiQ',
          value: 0,
        },
        {
          displayName: 'Modulo',
          value: 1,
        },
      ],
    };
  },
  computed: {
    canCreate() {
      return this.name &&
        this.clubId;
    },
    clubs() {
      return this.$store.getters['clubs/clubsSelect'];
    },
  },
  methods: {
    selectedClub(option) {
      this.clubId = option.value;
      this.clubName = option.displayName;
    },
    selectedType(option) {
      this.type = option.value;
    },
    createTerminal() {
      this.$store.dispatch('terminals/createTerminal', {
        name: this.name,
        clubId: this.clubId,
        type: this.type,
      }).then(() => {
        this.$router.push({ path: '/onservice/terminals/' });
      });
    },
  },
};
</script>
<style lang="scss">
.onservice__terminal {
  display: flex;
  flex-direction: column;
}
.onservice--functions-list {
  margin-left: 0.5rem;
  padding-left: 1rem;

  li.status--true {
    list-style-image: url('~@/assets/images/li-circle-ok.svg');
  }
  li.status--false {
    list-style-image: url('~@/assets/images/li-circle-error.svg');
  }
}
.onservice--functions-list li:not(:last-child) {
  padding-bottom: 0.75rem;
}
.onservice--terminal__button {
  margin-left: auto;
}
.input--column {
  flex-direction: column;
  width: 33%;
}
.status--active {
  color: color(active-color);
  margin-left: 0.5rem;
}
.content__input-group {
  width: 100%;
  @include media('medium') {
    width: calc((100% / 1.2) - (1rem * 2));
  }

  .user__actions {
    justify-content: flex-start;
    @include media('small') {
      justify-content: flex-end;
      // width: 70%;
    }

    a button {
      margin-left: 0;
    }

    button {
      margin-left: 1.25rem;
    }
  }
}
</style>
