<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <h1>Redigera avgift</h1>
      <form noValidate @submit.prevent="editFeeColumn">
        <div class="content__input-group">
              <div class="input-group">
                <div class="input">
                  <label
                    for="feeColumnName"
                    class="input__label">Avgift*</label>
                  <input
                    id="feeColumnName"
                    class="input__input"
                    type="text"
                    required="true"
                    name="feeColumnName"
                    v-model="feeColumn.name"/>
                </div>
          </div>
          <div class="input-group--inline user__actions">
            <router-link to="/businessboard/data">
              <base-button
              :text="$t('cancel')">
              </base-button>
            </router-link>
              <base-button
                :text="$t('Businessboard.editFeeColumn')"
                type="submit"
                v-element-loader="{ loaderKey: 'patch-feecolumn' }"
                :disabled="!canCreate"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'EditCategory',
  components: {
    BaseButton,
  },
  directives: {
    elementLoader,
  },

  props: {
    id: {
      type: String,
      default: '0',
    },
  },

  data() {
    return {
      feeColumn: {},
    };
  },

  methods: {
    editFeeColumn() {
      this.$store.dispatch('businessboard/patchMembershipFeeColumn', {
        id: this.id,
        name: this.feeColumn.name,
      }).then(() => {
        this.$router.push({ path: '/businessboard/data' });
      });
    },
  },

  computed: {
    canCreate() {
      return this.feeColumn.name;
    },
  },

  created() {
    this.feeColumn = this.$store.state.businessboard.membershiptypes.feeColumns.find(r => r.id === this.id);
  },
};

</script>

<style lang="scss" scoped>
  .golfoffice {
    // margin: 1rem;
    flex-grow: 1;
    width: 100%;
    @include media('small') {
      width: calc((100% / 2) - (1rem * 1));
    }
    @include media('large') {
      width: calc((100% / 3) - (1rem * 2));
    }
  }
  .golfoffice--content {
    background-color: color(white);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;

    button {
      margin-left: 1.25rem;
    }
  }
</style>
