<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div>
        <router-link
:to="{ path: '/businessboard/data/budget/' + companyId }">&laquo; {{ $t('back') }}</router-link>
        <base-button
          :disabled="budget.status === 'Established'"
          @click="deleteBudget"
          class="inline-right-button button--background-red"
          :text="$t('delete')">
        </base-button>
        <base-button
          v-if="budget.status == 'Established' && !editConfirmed"
          class="inline-right-button"
          text="Aktivera redigering"
          @click="showEditConfirmation = true" />
        <base-button
          class="inline-right-button"
          text="Exportera"
          @click="exportBudget" />
      </div>
      <div class="businessboard--header">
        <span>
          <span
class="h1">{{ $t('Businessboard.editBudget') }} | {{ budget.companyName }}
            <template v-if="budget.resultUnitName">
              | {{ budget.resultUnitName }}</template>
          </span>
          <span
            v-if="isLoading"
            class="element--is-loading"></span>
        </span>
        <div class="status-block">
          <button
            @click="showNotes = !showNotes"
            class="status-block__notes-button">
            <span>
              <img
                src="@/components/svg/message.svg"
                class="svg"
                alt="" />
              <span>{{ $t('Budget.notes') }}</span>
            </span>
          </button>
          <div
            class="status-block__notes-box"
            v-if="showNotes">
            <span>{{ $t('Budget.notes') }}</span>
            <textarea v-model="note" />
            <div class="status-block__button-container">
              <base-button
                @click="cancelNote"
                class="button--reset"
                :text="$t('cancel')"></base-button>
              <base-button
                :text="$t('save')"
                @click="saveNote"></base-button>
            </div>
          </div>
          <span class="status-block__status-section">
            <span>{{ $t('status') }}:</span>
            <img
              v-if="status == 'Draft'"
              src="@/components/svg/draft.svg"
              class="svg"
              alt="" />
            <img
              v-if="status == 'Established'"
              src="@/components/svg/established.svg"
              class="svg"
              alt="" />
            <img
              v-if="status == 'Published'"
              src="@/components/svg/published.svg"
              class="svg"
              alt="" />
            <img
              v-if="status == 'Archived'"
              src="@/components/svg/archived.svg"
              class="svg"
              alt="" />
            <select
              v-model="status"
              :disabled="budget.parentBudgetName">
              <option
                v-for="(status, index) in budgetStatuses"
                :key="index"
                :value="status.value">
                {{ status.displayName }}
              </option>
            </select>
          </span>
          <div
            v-if="budget.parentBudgetName === '' && establishedStatus"
            class="status-block__status-warning">
            {{ $t('Budget.exists') }} {{ budget.companyName }},
            {{ budget.financialYear }}.
            {{ $t('Budget.replaceWith', { name: this.budget.name }) }}
            <span
class="status-block__button-container"><base-button
                :text="$t('yes')"
                @click="setStatus('yes')" />
              <base-button
                :text="$t('no')"
                @click="setStatus('no')" />
            </span>
          </div>
        </div>
      </div>
      <div
        class="established-status-warning"
        v-if="budget.status == 'Established'">
        <template v-if="editConfirmed">{{ $t('Budget.saveWarning') }}</template>
        <template v-else>{{ $t('Budget.locked') }}</template>
      </div>
      <div class="name-section">
        <div class="name-section__block name-section__block--input">
          <label for="name">{{ $t('Budget.name') }}</label>
          <input
            v-if="budget.parentBudgetName"
            disabled
            v-model="budget.parentBudgetName" />
          <input
            v-else
            id="name"
            type="text"
            v-model="budgetName" />
        </div>
        <div class="name-section__block">
          <label for="year">{{ $t('year') }}</label>
          <input
            id="year"
            class="name-section__block--year"
            type="text"
            disabled
            :value="budget.financialYear" />
        </div>
        <div class="name-section__block name-section__block--select">
          <label for="pre-budget">{{ $t('Businessboard.preBudget') }}</label>
          <select
            v-model="preBudgetId"
            id="pre-budget">
            <option
              value=""
              selected>
              {{ $t('Budget.chooseBudget') }}
            </option>
            <option
              :disabled="budget.value === id"
              v-for="budget in budgetSelect"
              :value="budget.value"
              :key="budget.value">
              {{ budget.displayName }}
            </option>
          </select>
        </div>
        <div class="name-section__block name-section__block--select">
          <label for="pre-outcome">{{ $t('Businessboard.preOutcome') }}</label>
          <select
            v-model="preOutcomeYear"
            id="pre-outcome">
            <option
              value=""
              selected>
              {{ $t('Budget.chooseOutcome') }}
            </option>
            <option
              v-for="year in budgetResultsYearSelect"
              :key="year.value"
              :value="year.value">
              {{ year.displayName }}
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="!budget.hasOwnProperty('resultUnitName')"
        style="display: flex; justify-content: end; margin-bottom: 0.4rem">
        <toggle
          :toggled="includeResultFromAllResultUnits"
          @toggle="changeIncludeResultFromAllResultUnits()" />
        <div style="margin-left: 0.4rem">
          {{ $t('includeResultFromAllResultUnits') }}
        </div>
      </div>
      <budget-category
        class="budget-category"
        v-for="(group, index) in budgetGroups"
        :key="index"
        :budgetGroup="group"
        :index="index"
        :preBudgetId="preBudgetId"
        :preBudget="previousBudget"
        :preOutcomeYear="preOutcomeYear"
        :preOutcome="previousOutcome"
        @updatedGroups="updateGroups" />
      <div class="computed-result">
        <div>
          <span>{{ $t('Budget.calcResult') }}</span>
          <span>{{ computedResult }} {{ $t('denomination') }}</span>
        </div>
      </div>
    </div>
    <base-button
      v-if="allowSave"
      class="save-budget"
      :text="$t('save')"
      @click="save()" />
    <div
      v-if="showSpinner"
      class="saving-spinner"></div>
    <modal-window :show="showEditConfirmation">
      <div class="modal__header">
        {{ $t('Budget.importantInfo') }}
      </div>
      <div class="modal__content">
        <p>
          {{ $t('Budget.saveWarning2') }}<br />
          {{ $t('Budget.saveWarning2b') }}
        </p>
        <div class="input checkbox">
          <div class="checkbox--group">
            <input
              type="checkbox"
              id="confirmCheck"
              v-model="confirmCheck" />
            <label for="confirmCheck">
              <span><span></span></span>
              {{ $t('Budget.confirm') }}
            </label>
          </div>
        </div>
      </div>
      <div class="modal__content--actions">
        <base-button
          class="button--background-gray-dark"
          :text="$t('cancel')"
          @click="showEditConfirmation = false">
        </base-button>
        <base-button
          :disabled="!confirmCheck"
          class="no-border transparent"
          :text="'Aktivera redigering'"
          @click="editConfirm" />
      </div>
    </modal-window>
    <modal-window :show="showExportModal">
      <div class="modal__header">
        {{ $t('Budget.export') }}
      </div>
      <div class="modal__content">
        <p>
          {{ $t('Budget.typeOfExport') }}
        </p>
        <div class="input checkbox">
          <div class="checkbox--group">
            <select
              type="select"
              v-model="selectExport">
              <option value="1">{{ $t('Budget.fortNox') }}</option>
              <option value="2">{{ $t('Budget.visma') }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal__content--actions">
        <base-button
          class="button--background-gray-dark"
          :text="$t('cancel')"
          @click="showExportModal = false">
        </base-button>
        <base-button
          :disabled="selectExport < 1"
          class="no-border transparent"
          :text="'Exportera'"
          @click="getExport"
          v-element-loader="{ loaderKey: 'export-budget' }" />
      </div>
    </modal-window>
  </div>
</template>

<script>
import BudgetCategory from '@/components/budget-category';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import ModalWindow from '@/components/modal/modal';
// import Toggle from '@/components/businessboard/toggle';
import Toggle from '@/components/form-elements/switch';
import LocalizationMixin from '../../mixins/localization-mixin.vue';
export default {
  components: {
    BudgetCategory,
    BaseButton,
    ModalWindow,
    Toggle,
  },
  mixins: [LocalizationMixin],
  name: 'EditBudget',

  props: {
    id: {
      type: String,
      default: '0',
    },

    companyId: {
      type: String,
      default: '',
    },
  },
  directives: {
    elementLoader,
  },

  data() {
    return {
      budgetName: '',
      status: '',
      budgetGroups: [],
      showNotes: false,
      note: '',
      tempNote: '',
      preBudgetId: '',
      preOutcomeYear: '',
      statusOK: false,
      statusArr: [],
      establishedStatus: false,
      avoidRouteLeave: false,
      isLoading: true,
      showSpinner: false,
      showEditConfirmation: false,
      editConfirmed: false,
      confirmCheck: false,
      showExportModal: false,
      selectExport: 0,
      includeResultFromAllResultUnits: false,
    };
  },

  methods: {
    updateGroups(group) {
      let index = this.budgetGroups.findIndex(
        budgetGroup => budgetGroup.name === group.name
      );
      this.budgetGroups[index] = group;
      // this.$store.dispatch('businessboardBudget/fetchBudget', {
      //   id: this.$route.params.id
      // })
    },
    saveNote() {
      this.tempNote = this.note;
      this.showNotes = !this.showNotes;
    },

    cancelNote() {
      if (this.note !== '') {
        this.note = this.tempNote;
      } else {
        this.note = this.budget.message;
      }
      this.showNotes = !this.showNotes;
    },

    setStatus(answer) {
      if (answer === 'yes') {
        this.status = 'Established';
        this.statusOK = true;
        this.establishedStatus = false;
      } else {
        this.status = this.budget.status;
      }
    },
    save() {
      // for beforeRouteLeave
      this.budget.budgetGroups = this.budgetGroups;
      this.budget.name = this.budgetName;
      this.budget.status = this.status;
      this.budget.message = this.note;

      this.showSpinner = true;
      this.avoidRouteLeave = true;
      this.$store
        .dispatch('businessboardBudget/postStatusValidation', {
          budgetId: this.id,
          companyId: this.companyId,
          status: this.status,
          financialYearValue: this.budget.financialYear,
          budgetName: this.budgetName,
          companyName: this.budget.companyName,
        })
        .then(() => {
          this.$store
            .dispatch('businessboardBudget/patchBudget', {
              id: this.$route.params.id,
              name: this.budgetName,
              status: this.status,
              budgetGroups: this.budgetGroups,
              message: this.note,
              compareFinancialYear: this.preOutcomeYear,
              compareBudgetId: this.preBudgetId,
            })
            .then(() => {
              this.showSpinner = false;
              // this.$router.push({ path: '/businessboard/data/budget/' + this.companyId })
            });
        });
    },
    deleteBudget() {
      if (confirm(this.$t('Budget.deleteWarning'))) {
        this.$store
          .dispatch('businessboardBudget/removeBudget', { id: this.id })
          .then(() => {
            this.$router.push({
              path: '/businessboard/data/budget/' + this.companyId,
            });
          });
      }
    },
    editConfirm() {
      this.editConfirmed = true;
      this.showEditConfirmation = false;
    },
    exportBudget() {
      if (!this.hasUnsavedChanges || confirm(this.$t('Budget.exportWarning'))) {
        this.showExportModal = true;
      }
    },
    getExport() {
      this.$store
        .dispatch('businessboardBudget/exportBudget', {
          budgetId: this.id,
          exportType: this.selectExport,
          fileName:
            this.budget.companyName +
            '_' +
            this.budgetName +
            ['', '.se', '.xlsx'][this.selectExport],
        })
        .finally(() => {
          this.showExportModal = false;
        });
    },
    changeIncludeResultFromAllResultUnits() {
      this.includeResultFromAllResultUnits =
        !this.includeResultFromAllResultUnits;
      this.isLoading = true;
      this.$store
        .dispatch('businessboardBudget/fetchPreviousOutcome', {
          id: this.id,
          financialYear: this.preOutcomeYear,
          includeResultFromAllResultUnits: this.includeResultFromAllResultUnits,
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  computed: {
    budget() {
      return this.$store.getters['businessboardBudget/budget'];
    },
    previousBudget() {
      return this.$store.getters['businessboardBudget/previousBudget'];
    },
    previousOutcome() {
      return this.$store.getters['businessboardBudget/previousOutcome'];
    },
    budgetStatuses() {
      return this.$store.getters['businessboardBudget/budgetStatus'];
    },
    budgetSelect() {
      return this.$store.getters['businessboardBudget/budgetSelect'];
    },
    budgetResultsYearSelect() {
      return this.$store.getters['businessboardBudget/budgetResultsYearSelect'];
    },
    allowSave() {
      return this.budget.status !== 'Established' || this.editConfirmed;
    },
    computedResult() {
      let sum = 0;
      if (!this.budget.budgetGroups) return sum;
      this.budget.budgetGroups.forEach(element => {
        sum += (element.isCostGroup ? -1 : 1) * element.value;
      });
      return sum.toLocaleString();
    },
    hasUnsavedChanges() {
      return (
        this.budgetGroups !== this.budget.budgetGroups ||
        this.budgetName !== this.budget.name ||
        this.status !== this.budget.status ||
        this.note !== this.budget.message
      );
    },
  },
  watch: {
    status() {
      this.statusOK = false;
      this.budgetSelect.forEach(budget => {
        let year = budget.displayName.split(', ').pop();
        if (
          budget.value !== this.budget.id &&
          year === this.budget.financialYear
        ) {
          this.statusArr.push(budget.status);
        }
      });
      if (
        this.statusArr.includes('Established') &&
        this.status === 'Established' &&
        this.statusOK === false
      ) {
        this.establishedStatus = true;
      } else {
        this.establishedStatus = false;
      }
    },
    preBudgetId() {
      this.isLoading = true;
      this.$store
        .dispatch('businessboardBudget/fetchPreviousBudget', {
          id: this.id,
          compareId: this.preBudgetId,
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    preOutcomeYear() {
      this.isLoading = true;
      this.$store
        .dispatch('businessboardBudget/fetchPreviousOutcome', {
          id: this.id,
          financialYear: this.preOutcomeYear,
          includeResultFromAllResultUnits: this.includeResultFromAllResultUnits,
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  created() {
    this.isLoading = true;
    this.companyId = this.$route.params.companyId;
    this.$store
      .dispatch('businessboardBudget/fetchBudget', {
        id: this.$route.params.id,
      })
      .then(() => {
        this.thisBudget = this.budget;
        this.budgetName = this.budget.name;
        this.budgetGroups = this.budget.budgetGroups;
        this.status = this.budget.status;
        this.note = this.budget.message;
        this.tempNote = this.budget.message;
        this.preOutcomeYear = this.budget.compareFinancialYear;
        this.preBudgetId = this.budget.compareBudgetId;
        this.includeResultFromAllResultUnits =
          this.budget.includeResultFromAllResultUnits;
      })
      .then(() => {
        this.$store.dispatch('businessboardBudget/fetchBudgetStatus');
        this.$store.dispatch(
          'businessboardBudget/fetchBudgetResultsYearSelect'
        );
        this.$store.dispatch('businessboardBudget/fetchBudgetSelect', {
          id: this.companyId,
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  beforeRouteLeave: function (to, from, next) {
    if (this.avoidRouteLeave) {
      next();
    }
    if (this.hasUnsavedChanges) {
      if (confirm(this.$t('Budget.saveWarning3')) === true) {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.businessboard--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .status-block {
    margin: auto 0;
    position: relative;
    span {
      margin-right: 10px;
    }

    select {
      border: none;
    }

    &__status-warning {
      color: red;
      max-width: 400px;
    }

    &__button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        height: 30px;
      }
    }

    &__notes-button {
      border: none;
      background-color: white;
      color: color(blue);
      margin: 10px 30px;
      :hover {
        color: color(blue-dark);
      }
      :active {
        color: color(blue);
      }
      span,
      img {
        vertical-align: middle;
      }
    }

    &__notes-box {
      border: 1px solid #efefef;
      background-color: white;
      position: absolute;
      padding: 10px;
      box-shadow: 4px 4px 4px 0px #00000026;
      z-index: 2;

      textarea {
        border: 1px solid #bfbfbf;
        border-radius: 2px;
        height: 200px;
        width: 300px;
      }
    }
    &__button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        height: 30px;
      }
    }

    &__status-section {
      span,
      img,
      select {
        vertical-align: middle;
      }
    }
  }
}

.name-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  &__block {
    display: flex;
    flex-direction: column;

    input,
    select {
      height: 40px;
      border: 1px solid color(gray);
    }
  }

  &__block--input {
    width: 30%;
  }
  &__block--select {
    width: 20%;
  }

  &__block--year {
    border: none !important;
  }
}

.budget-category {
  margin-bottom: 20px;
}

.save-budget {
  position: fixed;
  bottom: 50px;
  right: 50px;
}
.svg {
  height: 18px;
  margin: 0 5px 0 10px;
}
.inline-right-button {
  float: right;
  margin-left: 1rem;
}
.established-status-warning {
  font-size: 20px;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
  color: red;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.element--is-loading-before:before,
.element--is-loading-after:after {
  position: fixed;
  bottom: 50px;
  right: 50px;
}
.saving-spinner {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 5rem 5rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.modal__content {
  flex-direction: column;
}
.checkbox--group {
  margin: auto;
}
.computed-result {
  background-color: #27645c;
  color: #ffffff;
  padding: 15px;
  > div {
    width: 30%;
    > span {
      font-size: 24px;
      font-weight: bold;
    }
    > span:last-child {
      font-size: 18px;
      line-height: 24px;
      float: right;
    }
  }
}
</style>
