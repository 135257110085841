<template>
  <div :class="{ 'template-diagrams': showAllDiagrams || includeDiagram }">
    <div class="header">
      <diagram-header
        :elementID="templateId + '_economy-company-budget'"
        :include="includeDiagram"
        @includeDiagram="e => (includeDiagram = e)"
        :chosenFormat="fileFormat"
        :diagram="diagram"
        :templateId="templateId"
        :showDiagram="showAllDiagrams || includeDiagram">
        <template #title>
          {{ companyName }}<br />
          <template v-if="budgetDiagram">
            {{ $t('ReportTool.revenueOutcome') }}
            {{ months[budgetDiagram.periodStartMonth] }}-{{
              months[budgetDiagram.periodEndMonth]
            }}
            {{ activeYear }}<br />
            {{ $t('ReportTool.costOutcome') }}
            {{ months[budgetDiagram.periodStartMonth] }}-{{
              months[budgetDiagram.periodEndMonth]
            }}
            {{ activeYear }}<br />
            {{ $t('ReportTool.resultForecast') }} {{ activeYear }}<br />
          </template>
        </template>

        <template #settings>
          <modal-filter
            :titleText="
              $t('ReportTool.comparisonAgainstBudget') + ' ' + companyName
            "
            :comparisonYearCount="4"
            :showPeriod="true"
            :storedFilter="diagram?.settings || '{}'"
            @changeFilter="e => toggleDiagram(e)"
            @saveFilter="e => saveFilter(e)" />
        </template>
      </diagram-header>
    </div>
    <div
      v-if="budgetDiagram && (showAllDiagrams || includeDiagram)"
      class="diagram"
      :id="templateId + '_economy-company-budget'">
      <div :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
        <h1
          v-if="settings.showHeaders"
          class="pieChart-title">
          {{ companyName }}
        </h1>

        <div class="section">
          <div class="divide-charts">
            <h4>
              {{ $t('ReportTool.revenueOutcome') }}
              {{ months[budgetDiagram.periodStartMonth] }}-{{
                months[budgetDiagram.periodEndMonth]
              }}
              {{ activeYear }}
            </h4>

            <span>{{ $t('ReportTool.comparisonCurrentYearBudget') }}</span>
            <div class="chart">
              <donut-chart
                v-if="!isLoading"
                :sum1="budgetDiagram.income.displayValue"
                :sum2="budgetDiagram.income.comparisonValue"
                :showAsPercentage="false" />
              <pie-chart-loader v-if="isLoading" />
            </div>
            <div class="legend">
              <reports-years-legend
                :normalYear="activeYear"
                :years="budgetDiagram.income.comparisonYears"
                :unit="$t('denomination')" />
            </div>
          </div>
          <div class="divide-charts">
            <h4>
              {{ $t('ReportTool.costOutcome') }}
              {{ months[budgetDiagram.periodStartMonth] }}-{{
                months[budgetDiagram.periodEndMonth]
              }}
              {{ activeYear }}
            </h4>
            <span>
              {{ $t('ReportTool.comparisonCurrentYearBudget') }}
            </span>
            <div class="chart">
              <donut-chart
                v-if="!isLoading"
                :sum1="budgetDiagram.costs.displayValue"
                :sum2="budgetDiagram.costs.comparisonValue"
                :inverse="true"
                :showAsPercentage="false" />
              <pie-chart-loader v-if="isLoading" />
            </div>
            <div class="legend">
              <reports-years-legend
                :normalYear="activeYear"
                :years="budgetDiagram.costs.comparisonYears"
                unit="kronor" />
            </div>
          </div>
          <div class="divide-charts">
            <h4>{{ $t('ReportTool.resultForecast') }} {{ activeYear }}</h4>
            <span>{{ $t('ReportTool.comparisonCurrentYearBudget') }}</span>
            <div class="chart">
              <donut-chart
                v-if="!isLoading"
                :sum1="budgetDiagram.forecast.displayValue"
                :sum2="budgetDiagram.forecast.comparisonValue"
                :showAsPercentage="true" />
              <pie-chart-loader v-if="isLoading" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalFilter from '@/components/reports-templates/settings-modal/gamecalculation';
import DiagramHeader from '@/components/reports-templates/diagram-header';
import DonutChart from '@/components/charts/donut-chart-reports';
import ReportsYearsLegend from '@/components/reports-templates/legend/reports-legend-years';
import { COLORS } from '../../charts/pie-chart-colors';
import { MonthsLabelsShort } from '@/core/consts';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import PieChartLoader from '../pie-chart-loader.vue';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixin],
  data: function () {
    return {
      COLORS,
      months: [''].concat(
        MonthsLabelsShort.map(l => this.$t(`MonthsShort.${l}`).toLowerCase())
      ),
    };
  },
  components: {
    ModalFilter,
    DiagramHeader,
    DonutChart,
    ReportsYearsLegend,
    PieChartLoader,
  },
  props: {
    companyName: {
      type: String,
      default: function () {
        this.$t('Businessboard.companies');
      },
    },
    diagram: {},
    templateId: String,
    include: Boolean,
    showAllDiagrams: Boolean,
  },

  computed: {
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
    fileFormat() {
      return this.settings.fileFormat;
    },
    includeDiagram() {
      return this.diagram?.includedInTemplate || false;
    },
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    budgetDiagram() {
      return (
        this.diagram?.data.companies.find(
          d => d.companyName === this.companyName
        )?.budgetDiagram || false
      );
    },
    activeYear() {
      return JSON.parse(this.diagram.settings).Years[0];
    },
  },
  methods: {
    toggleDiagram(e) {
      this.diagramToRender = e.show;
      this.$store.dispatch('businessboardReportingTool/postDiagram', {
        templateId: this.templateId,
        settings: e,
        diagramType: 23,
      });
    },
    saveFilter(e) {
      e.save = true;
      this.toggleDiagram(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
.pieChart {
  width: 20rem;
  height: 20rem;
}

.section {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 16px;
  margin-left: 2rem;
  display: grid;
  grid-template-columns: 23rem 23rem 23rem;
  place-content: center;
  text-align: center;
  h4 {
    font-size: 18px;
    margin: 10px 0;
  }
  span {
    font-size: 16px;
    font-weight: 500;
  }
}

.legend {
  width: 18rem;
  margin: 0rem 0rem 0 1.4rem;

  .year {
    flex-flow: flex-column;
  }
}
.pieChart-title {
  margin-left: 4rem;
  text-align: center;
}

.chart-year {
  font-size: medium;
  font-weight: 600;
}

.divide-charts {
  display: flex;
  flex-direction: column;
  .chart {
    height: 22rem;
    margin-bottom: -4rem;
  }
}
.border-left {
  border-left: 1px solid #dadedf;
}
</style>
