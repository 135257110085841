<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <router-link to="/clubs/">&laquo; {{ $t('back') }}</router-link>
      </div>
      <div class="golfoffice--top">
        <h1>{{ $t('GolfOffice.editClub') }}</h1>
        <h2>{{ club.name }}</h2>
      </div>
      <div class="club__licenses">
        <h2 class="title-variation first-letter">
          <use-svg svg="print-icon"/>
          <span>{{ $tc('license', 2) }}</span>
        </h2>

        <custom-select
          :required="false"
          id="businessboardLicense"
          label="Businessboard"
          @optionSelected="setBusinessboardLicense"
          :selected="selectedBusinessboardVersionIndex"
          :options="businessboardVersions"/>
        <template v-if="club.businessboardVersion">
          <div class="input">
            <label
              for="onTagBusinessboardEndDate"
              class="input__label">{{ $t('endDate') }} {{ $t('for') }} Businessboard {{ $tc('license', 1) }}</label>
            <input
              id="businessboardVersionEndDate"
              class="input__input"
              v-model="businessboardEndDate"
              type="date"
              required="false"
              name="businessboardEndDate"/>
          </div>
        </template>
      </div>

      <div class="club__licenses" v-if="club && club.changeLog && club.changeLog.rows.length > 0">
        <h2 class="title-variation">
          <use-svg svg="print-icon"/>
          Change log
        </h2>
        <p>Här ser man förändringar. Vilket värde som ändrades av vem och när de gjordes.</p>
        <list-table
          v-if="club && club.changeLog"
          :items="club.changeLog.rows"
          :headers="club.changeLog.columns">
        </list-table>
      </div>
      <div class="club__actions sticky sticky__bottom">
        <base-button
          :text="$t('save')"
          @click="saveClub"
          v-element-loader="{ loaderKey: 'golfoffice-club-edit' }" />
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import { formatDateForInputs } from '@/core/helpers.js';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'EditClub',
  components: {
    UseSvg,
    ListTable,
    BaseButton,
    CustomSelect,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: String,
  },
  data() {
    return {
      selectedBusinessboardVersionIndex: undefined,
    };
  },
  methods: {

    setBusinessboardLicense(license) {
      this.selectedBusinessboardVersion(license.value);
      this.club.businessboardVersion = license.value;
    },
    saveClub() {
      let path = 'clubs/patchClub';
      this.$store.dispatch(path, this.club).then(() => {
        this.$router.push({ path: '/clubs' });
        // TODO: Fix translation
        this.$toasted.success('Club saved');
      });
    },
    selectedBusinessboardVersion(version) {
      this.selectedBusinessboardVersionIndex = this.businessboardVersions.findIndex(s => s.value === version);
    },
  },
  computed: {
    club() {
      return this.$store.getters['clubs/club'];
    },
    businessboardVersions () {
      return this.$store.getters['clubs/businessboardVersions'];
    },
    businessboardEndDate: {
      get: function () {
        return formatDateForInputs(this.club.businessboardEndDate);
      },
      set: function (newValue) {
        this.club.businessboardEndDate = newValue;
        return this.club.businessboardEndDate;
      },
    },
  },
  mounted() {
    this.$store.dispatch('clubs/fetchClub', { id: this.id }).then(() => {
      this.$store.dispatch('clubs/fetchBusinessboardVersions').then(() => this.selectedBusinessboardVersion(this.club.businessboardVersion));
    });
  },
};
</script>
<style lang="scss">
.club-switch {
  display: flex;
  .input__label {
    flex-basis: 15%;
  }
}
.club__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  button {
    margin-left: 1rem;
  }
}
.golfoffice--top {
  display: flex;
  justify-content: space-between;
  align-items: center
  h1 {
    margin-top: 0;
  }
}
.club__logotype {
    height: 100%;
    width: 30%;

    @include media('to-medium') {
      width: 100%;
    }

    p {
      margin: 0 0 0.5rem 0;
      @include media('to-medium') {
        margin: 0.5rem 0;
      }
    }
  }
  button {
        margin-right: 0.3rem;
        padding: 0 0.5rem;
      }
  .club__layout--image {
    border: 1px solid color(gray);
    height: 7rem;
    display: flex;
    justify-content: center;
    position: relative;
    img {
      align-self: center;
      height: 80%;
    }
    .club__layout--image-overlay-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .club__layout--image-overlay {
    background-color: rgba(249, 249, 249, 0.5);
  }
</style>
