<template>
  <div class="expand-collapse-variant">
    <div class="expand-collapse-variant__fold">
      <button
        :id="id"
        :aria-expanded="isExpanded ? true : false"
        :aria-controls="isExpanded ? contentId : null"
        class="expand-collapse-variant__label"
        type="button"
        @click="toggleExpand">
        <span class="expand-collapse-variant__title">
          <template v-if="icon !== ''">
            <use-svg :svg="icon" />
          </template>
          <slot name="title"></slot>
        </span>
        <span
          class="expand-collapse-variant__icon"
          :class="{ 'expand-collapse-variant__rotate': isExpanded }">
          <span class="visually-hidden">{{
            isExpanded ? $t('close') : $t('open')
          }}</span>
        </span>
      </button>
      <div
        :id="contentId"
        class="expand-collapse-variant__content"
        v-if="isExpanded"
        :aria-hidden="!isExpanded ? true : false"
        :aria-labelledby="id">
        <div class="expand-collapse-variant__content-inner">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'ExpandCollapseVariant',
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    contentId: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    localExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  watch: {
    localExpanded: [
      {
        handler: 'setIsExpanded',
      },
    ],
  },
  methods: {
    setIsExpanded() {
      this.isExpanded = this.localExpanded;
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
<style lang="scss" scoped>
.expand-collapse-variant {
  list-style: none;
  margin: 2rem 0;

  .expand-collapse-variant__label {
    display: flex;
    width: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    padding: 0;
    justify-content: space-between;
    outline: none;
    font-weight: bold;
    margin: 0;
    background-color: color(gray-light);
    padding: 0.625rem 1rem 0.625rem 0.625rem;
  }
  .expand-collapse-variant__icon {
    color: #0067a5;
    transition: transform 0.2s ease;
  }
  .expand-collapse-variant__rotate {
    transform: rotate(180deg);
  }
  .expand-collapse-variant__icon {
    border: 1px solid color(black);
    border-width: 0 0 2px 2px;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);

    &.expand-collapse-variant__rotate {
      transform: rotate(135deg);
    }
  }
  .expand-collapse-variant__button {
    margin-top: 20px;
  }
  .expand-collapse-variant__title {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: normal;
    .svg {
      margin-right: 0.6rem;
    }
    span {
      margin-left: 0.5rem;
    }
  }
  .expand-collapse-variant__content {
    border: 1px solid color(gray);
    padding: 1.25rem 0.625rem;

    h2 {
      margin: 0;
    }

    button {
      .svg {
        stroke: color(white);
      }
    }
  }
}
</style>
