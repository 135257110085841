<template>
  <div class="outer-container">
    <div
      :class="[
        'last-updated-wrapper',
        { 'last-updated-wrapper_hidden': showScrollToTop },
      ]">
      <div
        class="last-updated"
        v-if="lastUpdate.length > 0">
        <div class="flex-container">
          <div class="card">
            <div class="column">
              <div
                class="head"
                @click="showLastUpdate = !showLastUpdate">
                <use-svg svg="bb-update-icon" />
                <div class="">{{ $t('dataLatestUpdate') }}</div>
                <div>
                  <use-svg
                    svg="arrow-right"
                    :class="{ 'svg--180-negative': showLastUpdate }" />
                </div>
              </div>
              <div
                class="settings"
                v-if="showLastUpdate">
                <div
                  v-for="(update, i) in filteredLastUpdate"
                  :key="i"
                  class="setting">
                  <div>
                    <use-svg
                      v-if="update.type < 3"
                      :svg="
                        [
                          'bb-company-icon',
                          'bb-course-icon',
                          'bb-member-green-icon',
                        ][update.type]
                      " />
                  </div>
                  <div>
                    {{ formattedUpdateName(update) }}
                    {{ timeFormat(update.lastUpdate) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div
                class="head"
                @click="showLastUpdate = !showLastUpdate">
                <div class="">{{ $t('dataPeriod') }}</div>
                <div>
                  <use-svg
                    svg="arrow-right"
                    :class="{ 'svg--180-negative': showLastUpdate }" />
                </div>
              </div>
              <div
                class="settings"
                v-if="showLastUpdate">
                <div
                  v-for="(update, i) in filteredLastUpdate"
                  :key="i"
                  class="setting">
                  <div>
                    {{
                      formatPeriod(
                        update.fromDate,
                        update.toDate,
                        update.type == 2
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="warning-container"
            v-if="showWarning && showLastUpdate">
            <warning
              :message="
                isBalanceAccounts
                  ? $t('Businessboard.balanceAccountNotConnectedLong')
                  : $t('Businessboard.accountNotConnectedLong')
              "
              :rounded="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MonthsLabelsShort } from '@/core/consts';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import UseSvg from '@/components/use-svg';
import Warning from '../warning/warning.vue';

export default {
  name: 'LastUpdated',
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
    Warning,
  },
  props: {
    lastUpdate: {
      type: Array,
      default: () => [],
    },
    showMember: {
      type: Boolean,
      default: false,
    },
    showGame: {
      type: Boolean,
      default: false,
    },
    showEconomy: {
      type: Boolean,
      default: false,
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
    isBalanceAccounts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLastUpdate: true,
      showScrollToTop: false,
    };
  },
  computed: {
    filteredLastUpdate() {
      return this.lastUpdate.filter(update =>
        this.filterUpdateType(update.type)
      );
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollFunction);
  },
  unmounted() {
    window.removeEventListener('scroll', this.scrollFunction);
  },
  methods: {
    formattedUpdateName(update) {
      return update.name ? `${update.name}:` : '';
    },
    filterUpdateType(updateType) {
      if (
        (this.showMember && updateType === 2) ||
        (this.showGame && updateType === 1) ||
        (this.showEconomy && updateType === 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    timeFormat(d) {
      if (!d) {
        return;
      }

      d = new Date(d);

      return `${d.getDate()} ${this.$t(
        `MonthsShort.${MonthsLabelsShort[d.getMonth()]}`
      ).toLowerCase()} ${d.getFullYear()}, ${('0' + d.getHours()).slice(-2)}:${(
        '0' + d.getMinutes()
      ).slice(-2)}`;
    },
    formatPeriod(fromDate, toDate, useOnlyYear = false) {
      const options = useOnlyYear
        ? { year: 'numeric' }
        : { month: 'short', year: 'numeric' };
      const from = new Date(fromDate);
      const to = new Date(toDate);

      const locale = this.$i18n.locale;

      var fromFormatted = from.toLocaleDateString(locale, options);
      var toFormatted = to.toLocaleDateString(locale, options);

      fromFormatted = this.capitalizeFirstLetter(fromFormatted);

      fromFormatted = fromFormatted.replace('.', '');
      toFormatted = toFormatted.replace('.', '');

      fromFormatted = fromFormatted.split(' ').join('/');
      toFormatted = toFormatted.split(' ').join('/');

      return `${fromFormatted}-${toFormatted}`;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.showScrollToTop = true;
        this.showLastUpdate = false;
      } else {
        this.showScrollToTop = false;
        this.showLastUpdate = true;
      }
    },
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-container {
  position: fixed;
  right: 1.3rem;
  top: 4rem;
  padding-bottom: 0.2rem;
  border-radius: 8px;
  max-height: 10rem;
  margin-top: 0.2rem;
  z-index: 1;
  overflow: auto;
  background-color: #27645c;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
}

.last-updated {
  display: flex;
  flex-direction: row;
  color: #27645c;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  cursor: pointer;
  max-height: 9.2rem;
  border-radius: 8px;
  margin-top: 0.5rem;
}
.card {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  height: auto;
  padding: 0.3rem;
  width: 100%;
  overflow: auto;
  border-radius: 8px;
}

.column {
  display: flex;
  flex-direction: column;
}

.head {
  display: flex;
  flex-direction: row;
  padding: 0.2rem;
  background-color: #27645c;
  color: white;
  gap: 0.4rem;
  border-radius: 8px;
  font-weight: 500;
  &:hover {
    background-color: #1f4d3d;
  }
}

.setting {
  display: flex;
  flex-direction: row;
  padding: 0.3rem;
  margin-top: 0.2rem;
  margin-left: 0.3rem;
  border-radius: 8px;
  height: 1.5rem;

  &:hover {
    background-color: #e8e8e8;
  }
}

.settings {
  border-radius: 8px;
  width: 100%;
  background: #eaf6f7;
  margin: 0.2rem;
}

.last-updated-wrapper,
.last-updated-wrapper_hidden {
  position: relative;
  max-height: 100%;
  overflow: auto;
}

.warning-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0.5rem;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
</style>
