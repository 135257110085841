<template>
  <div class="te-collapsible">
    <div class="te-collapsible-summary">
      <div
        v-if="this.titleIcon"
        :class="['te-collapsible-summary-icon', this.titleIcon]"></div>
      <div
        class="te-collapsible-summary-text"
        @click="clicked">
        {{ title }}
      </div>
      <div
        v-if="hasOptions === true"
        class="te-collapsible-button te-collapsible-options-button"
        @click="optionsClicked">
        <span class="tietoevry-icons-settings"></span>
      </div>
      <div
        class="te-collapsible-button te-collapsible-summary-button"
        @click="clicked">
        <span
          v-if="localOpen === true"
          class="tietoevry-icons-arrow-small-down"></span>
        <span
          v-if="localOpen === false"
          class="tietoevry-icons-arrow-small-up"></span>
      </div>
    </div>
    <Transition name="fade">
      <div
        v-if="localOpen === true"
        class="te-collapsible-content">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'TeCollapsible',
  emits: ['optionsClicked'],
  props: {
    title: {
      type: String,
    },
    open: {
      type: Boolean,
    },
    hasOptions: {
      type: Boolean,
    },
    titleIcon: {
      type: String,
    },
  },
  watch: {
    open: function (newValue) {
      this.localOpen = newValue;
    },
  },
  data() {
    return {
      localOpen: this.open,
    };
  },
  methods: {
    clicked() {
      this.localOpen = !this.localOpen;
    },
    optionsClicked() {
      this.$emit('optionsClicked');
    },
  },
};
</script>

<style lang="scss">
.te-collapsible {
  transition: flex 0.2s ease-in-out;
  font-family: 'Tietoevry sans 1';
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--dividers-borders-border-selector-primary, #a9aaac);
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  .te-collapsible-summary {
    pointer-events: all;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-left: 16px;
  }
  .te-collapsible-summary-icon {
    font-size: 16px;
    margin-right: 8px;
  }
  .te-collapsible-summary .te-collapsible-summary-text {
    flex: 1;
    padding: 14px 0px;
  }
  .te-collapsible-content {
    pointer-events: all;
    padding: 14px 16px;
    width: 100%;
  }
  .te-collapsible-summary .te-collapsible-button {
    display: flex;
    align-items: center;
    color: var(--Gray-Gray-1, #64676a);
    align-self: stretch;
    aspect-ratio: 1/1;
    padding-left: 4px;
    padding-right: 4px;
    min-width: 20px;
  }
  .te-collapsible-summary .te-collapsible-button:hover {
    color: var(--Surface-Surface-Selector-Selected, #1861f2);
  }
  .te-collapsible-summary .te-collapsible-options-button span {
    font-size: 20px;
  }
  .te-collapsible-summary .te-collapsible-summary-button span {
    font-size: 30px;
  }
}
/*@keyframes fade {
  0% {
    opacity: 0;
    transform-origin:top left;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform-origin:top left;
    transform: translateY(0%);
  }
}
.fade-enter-active {
  animation: fade 0.15s;
}
.fade-leave-active {
  animation: fade 0.15s reverse;
}*/
</style>
