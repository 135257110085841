<template>
  <div class="golf-office">
    <div class="golf-office--content">
      <template v-if="firstLogin === 'true'">
        <h1>{{ $t('GolfOffice.welcome') }}</h1>
        <h2>{{ $t('choosePassword') }}</h2>
      </template>
      <template v-else>
        <h1>{{ $t('resetPassword') }}</h1>
      </template>
      <p>{{ $t('passwordText') }}</p>
      <ul>
        <li>{{ $t('passwordRule1') }}</li>
        <li>{{ $t('passwordRule2') }}</li>
        <li>{{ $t('passwordRule3') }}</li>
        <li>{{ $t('passwordRule4') }}</li>
      </ul>
      <div class="content__input">
        <div class="content__input-group">
          <input-type
            :label="$t('email')"
            input-id="email"
            v-model="email"
            @keydown.enter="resetPassword"
            autofocus />
            <div
              class="input"
              :class="`${errors.length > 0 ? 'input__error' : ''}`">
                <label
                  for="password"
                  class="input__label">{{ $t('password') }}*</label>
                <input
                  id="password"
                  class="input__input"
                  v-model="password"
                  type="password"
                  required="true"
                  name="password" />
              </div>
              <div
                class="input"
                :class="`${errors.length > 0 ? 'input__error' : ''}`">
                  <label
                    for="repeatedPassword"
                    class="input__label">{{ $t('confirmPassword') }}*</label>
                  <input
                    id="repeatedPassword"
                    class="input__input"
                    v-model="repeatedPassword"
                    type="password"
                    required="true"
                    name="repeatedPassword" />
              </div>
        </div>
        <div v-if="errors.length > 0">
          <p v-for="(error, i) in errors" :key="i">
            {{ error }}
          </p>
        </div>
        <div class="content__input-actions">
          <base-button
            v-if="firstLogin === 'true'"
            :text="$t('choosePassword')"
            @click="resetPassword"
            v-element-loader="{ loaderKey: 'user-reset-password' }" />
          <base-button
            v-else
            :text="$t('resetPassword')"
            @click="resetPassword"
            v-element-loader="{ loaderKey: 'user-reset-password' }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputType from '@/components/form-elements/input';
import BaseButton from '../components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import { validatePassword } from '@/core/helpers';
export default {
  name: 'ResetPassword',
  components: {
    InputType,
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      email: '',
      password: '',
      repeatedPassword: '',
      token: '',
      firstLogin: {
        type: String,
      },
      errors: [],
    };
  },
  methods: {
    resetPassword () {
      this.errors = [];
      if (this.password === this.repeatedPassword) {
        const validate = validatePassword(this.password);
        if (validate === true) {
          this.$store.dispatch('user/resetPassword', { email: this.email, password: this.password, confirmPassword: this.repeatedPassword, code: this.token }).then((data) => {
            if (data.success === true) {
              if(this.firstLogin === 'true') {
                this.$toasted.success('Password choosen');
              } else {
                this.$toasted.success('Password restored');
              }
              this.$router.push({ path: '/' });
            } else {
              this.errors.push(data.message);
            }
          });
        } else {
          this.errors = validate;
        }
      } else {
        this.errors.push('Your new password and confirmed password is not the same');
      }
    },
  },
  created() {
    if(this.$router.currentRoute.params && this.$router.currentRoute.params.token) {
      this.token = this.$router.currentRoute.params.token;
    }
    if(this.$route && this.$route.query && this.$route.query.firstLogin) {
      this.firstLogin = this.$route.query.firstLogin;
    }
  },
};
</script>
<style lang="scss">
.golf-office {
  flex-grow: 1;
}
.golf-office--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;

  ul {
    margin-top: 0;
  }
}
.content__input-actions {
  display: flex;
  button {
    margin-left: auto;
  }
}
</style>
