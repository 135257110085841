import { render, staticRenderFns } from "./settings-modal.vue?vue&type=template&id=89199eee&scoped=true"
import script from "./settings-modal.vue?vue&type=script&lang=js"
export * from "./settings-modal.vue?vue&type=script&lang=js"
import style0 from "./settings-modal.vue?vue&type=style&index=0&id=89199eee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89199eee",
  null
  
)

export default component.exports