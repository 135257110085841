<template>
  <div class="coursedata-create-groups">
    <div class="title">{{ localItem.description }}</div>
    <div
      v-if="localItem.explaination === 'create-groups'"
      class="description"
      title="Help">
      <p>Please setup the group(s) of physical holes representing your club.</p>
      <strong>Examples of setups for groups:</strong>
      <ul>
        <li>
          Your club has three groups of 9 physical holes, e.g. "Forrest",
          "Coast" and "Lake" - then you create three groups with 9 holes.
        </li>
        <li>
          Your club has two groups with 18 physical holes each, e.g. "East" and
          "West" - then you create two groups with 18 holes.
        </li>
        <li>
          Your club has on group with 18 physical holes and one with 9 physical
          holes - then you create 1 with 18 holes and 1 with 9 holes.
        </li>
        <li>
          Your club has 12 physical holes - then you create one group with 12
          holes.
        </li>
      </ul>
    </div>
    <div class="groups-container">
      <course-data-hole-group-view
        v-bind:key="localGroups[0].id"
        :group="localGroups[0]">
      </course-data-hole-group-view>
      <div
        v-for="group in localGroups.slice(1)"
        v-bind:key="group.id"
        class="extra-group">
        <course-data-hole-group-view :group="group">
        </course-data-hole-group-view>
        <div
          class="extra-group-delete tietoevry-icons-trash"
          @click="deleteGroup(group)"></div>
      </div>
    </div>
    <button
      class="te-btn te-btn-sm te-btn-secondary add-group-button"
      @click="addGroup">
      Add another group
    </button>
    <div class="bottom">
      <button
        class="te-btn te-btn-md te-btn-tertiary"
        @click="$emit('cancel')">
        <i class="tietoevry-icons-arrow-small-left"></i>Previous
      </button>

      <button
        class="te-btn te-btn-md te-btn-primary"
        @click="done"
        :disabled="localItem.progress !== 100">
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import { GroupsDataModel } from './holes-datamodel.mjs';
import CourseDataHoleGroupView from './hole-group.vue';
export default {
  name: 'CourseDataHolesCreateGroupsView',
  components: {
    CourseDataHoleGroupView,
  },
  props: {
    item: {
      type: Object,
    },
    groups: {
      type: Array,
    },
  },
  emits: ['progress', 'done', 'cancel'],
  watch: {
    item: {
      handler: function (newVal) {
        this.localItem = newVal;
      },
      deep: true,
    },
    groups: {
      handler: function (newVal) {
        this.updateProgress();
      },
      deep: true,
    },
  },
  data() {
    let localGroups = this.groups;
    if (localGroups.length === 0) {
      localGroups.push({
        name: '',
        shortName: '',
        numberOfHoles: 0,
      });
    }
    return {
      localGroups: localGroups,
      localItem: this.item,
    };
  },
  methods: {
    done(event) {
      this.$emit('done', this.localGroups);
    },
    addGroup(event) {
      this.localGroups.push({
        name: '',
        shortName: '',
        numberOfHoles: 0,
      });
    },
    deleteGroup(group) {
      let index = this.localGroups.findIndex(g => g.id === group.id);
      if (index > -1) {
        this.localGroups.splice(index, 1);
      }
      this.updateProgress();
    },
    updateProgress() {
      this.localItem.progress = GroupsDataModel.getGroupsSetupProgress(
        this.localGroups
      );
    },
  },
};
</script>

<style lang="scss">
.coursedata-create-groups {
  padding: 20px;
  font-family: 'Tietoevry sans 1';
  .groups-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
  }
  .title {
    color: var(--text-text-primary, #1e2224);
    /* Basic Heading 2 */
    font-family: 'Tietoevry sans 1';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    margin-bottom: 8px;
  }
  .description {
    color: var(--text-text-tertiary, #64676a);
    /* Paragraph text */
    font-family: 'Tietoevry sans 1';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 40px;
    border-bottom: 1px solid var(--border-border-tertiary, #d9d9d9);
    ul {
      padding-left: 20px;
    }
  }
  .extra-group {
    padding: 20px;
    border-radius: 8px;
    background: var(--surface-surface-canvas, #f6f6f7);
    position: relative;
    .extra-group-delete {
      position: absolute;
      right: 20px;
      font-size: 18px;
      top: 20px;
      &:hover {
        color: red;
        cursor: pointer;
      }
    }
  }
  .extra-group .holes-in-group .ts-select-cards {
    gap: 8px;
  }
  .add-group-button {
    margin-top: 24px;
  }
  .holes-in-group {
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
    justify-content: flex-start;
    .ts-select-cards {
      justify-content: flex-start;
      gap: 10px;
      .te-select-card {
        width: 34px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .holes-in-group-title {
      color: var(--text-text-primary, #1e2224);
      /* Heading 5 */
      font-family: Tietoevry Sans 1;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
}
</style>
