<template>
  <div class="ontag">
    <div class="ontag--content">
      <h1>Ankomstanmälan</h1>
      <p>Inställningar för ankomstanmälan för bokade sällskapsronder i GIT</p>
      <div class="flex-block">
        <div class="switch-labelbox">
          <label
            class="header_label"
            for="allow-arrival">{{ $t('allowArrival') }} {{ $t('allowArrivalMembers') }}
          </label>
          <div
            class="info-svg-container"
            :title="$t('OnTag.help')"
            @click="toggleShowInfobox">
            <use-svg svg="help-icon" />
          </div>
          <div
            v-if="showInfobox"
            class="info-box">
            <div>
              <button @click="toggleShowInfobox">stäng</button>
            </div>
            Aktivera funktionen om ni vill tillåta era medlemmar att
            ankomstanmäla sig via OnTag appen
          </div>
        </div>
        <div class="switch-contentbox">
          <toggle-switch
            input-id="allow-arrival"
            type="switch"
            :toggled="appSettings.allowArrival"
            @toggle="e => toggle(e, 'allowArrival')" />
          <div
            v-if="appSettings.allowArrival"
            class="switch-active-content">
            <label>{{ $t('OnTag.allowArrivalMinutes') }}</label>
            <select @change="e => selectEvent(e, 'minutesMember')">
              <option
                v-for="option in [180, 150, 120, 90, 60]"
                :value="option"
                :key="option"
                :selected="option == appSettings.minutesMember">
                {{ option }}
              </option>
            </select>
          </div>
          <!--<div>
            {{ $t('OnTag.message') }}
            <textarea
              class="input-group-textarea"
              v-model="appSettings.messageMember">
            </textarea>
          </div>-->
        </div>
      </div>
      <div class="flex-block">
        <div class="switch-labelbox">
          <label
            class="header_label"
            for="allow-arrival">{{ $t('OnTag.allowArrival') }} {{ $t('OnTag.allowArrivalGuests') }}
          </label>
          <div
            class="info-svg-container"
            :title="$t('OnTag.help')"
            @click="toggleShowInfobox2">
            <use-svg svg="help-icon" />
          </div>
          <div
            v-if="showInfobox2"
            class="info-box">
            <div>
              <button @click="toggleShowInfobox2">stäng</button>
            </div>
            Aktivera funktionen om ni vill tillåta gäster att ankomstanmäla sig
            via OnTag appen. Funktionen är då tillgänglig för gäster som har
            betalt greenfee och betalningen har registratas i GIT (0 kr. kvar
            att betala)
          </div>
        </div>
        <div class="switch-contentbox">
          <toggle-switch
            input-id="allow-arrival"
            type="switch"
            :toggled="appSettings.arrivalGuest"
            @toggle="e => toggle(e, 'arrivalGuest')" />
          <div
            v-if="appSettings.arrivalGuest"
            class="switch-active-content">
            <label class="switch">{{ $t('OnTag.allowArrivalMinutes') }}</label>
            <select @change="e => selectEvent(e, 'minutesGuest')">
              <option
                v-for="option in [180, 150, 120, 90, 60]"
                :value="option"
                :key="option"
                :selected="option == appSettings.minutesGuest">
                {{ option }}
              </option>
            </select>
            <!--<div>
              {{ $t('OnTag.messageGuests') }}
              <textarea
                class="input-group-textarea"
                v-model="appSettings.messageGuest">
              </textarea>
            </div>-->
          </div>
        </div>
      </div>
      <div class="flex-block">
        <div class="switch-labelbox">
          <div>
            <div>
              <label
                for="allow-arrival"
                class="input__label header_label">
                {{ $t('OnTag.gps') }}
              </label>
              <div
                class="info-svg-container"
                :title="$t('OnTag.help')"
                @click="toggleShowInfobox3">
                <use-svg svg="help-icon" />
              </div>
              <div
                v-if="showInfobox3"
                class="info-box">
                <div>
                  <button @click="toggleShowInfobox3">stäng</button>
                </div>
                Kontrollera att spelaren befinner sig på klubben (GPS)<br /><br />
                Ankomstanmälan tillåts då endast om spelaren befinner sig inom 1
                500 meter från GPS-punkter som ni anger.<br /><br />
                Har ni flera platser kan ni lägga till flera punkter.
                Ankomstanmälan tillåts då endast om spelaren befinner sig inom 1
                500 meter från en av GPS-punkterna som ni angivit.<br /><br />
                Endast spelare som tillåter lokaliseringstjänster i OnTag-appen
                kan ankomstanmäla sig.
              </div>
            </div>
            <p class="description">
              Tips!<br />
              Använd
              <a
                href="https://maps.google.com/"
                target="_blank">Google Maps</a>
              för att ta reda på era koordinater. Högerklicka på aktuell punkt i
              kartan (förslagsvis klubbhus). Klicka sedan i menyn som visas, på
              koordinaterna för platsen. Dessa kopieras nu till urklipp.
            </p>
          </div>
        </div>
        <div class="switch-contentbox">
          <toggle-switch
            input-id="allow-arrival"
            type="switch"
            :toggled="appSettings.gpsMember"
            @toggle="e => toggle(e, 'gpsMember')" />
          <div
            class="advanced-settings__selectedusers"
            v-for="(coord, index) in appSettings.clubCoordinates"
            :key="index">
            <div class="input">
              <div class="input-column">
                <label v-if="index == 0"> Kommentar </label>
                <input
                  type="text"
                  :name="'user-name-' + index"
                  :id="'user-name-' + index"
                  :disabled="!appSettings.gpsMember"
                  class="input__input"
                  v-model="coord.comment" />
              </div>
              <div class="input-column">
                <label v-if="index == 0"> Latitud koordinater </label>
                <input
                  type="text"
                  :name="'user-name-' + index"
                  :id="'user-name-' + index"
                  :disabled="!appSettings.gpsMember"
                  class="input__input"
                  v-model="coord.latitude" />
              </div>
              <div class="input-column">
                <label v-if="index == 0"> Långitud koordinater </label>
                <input
                  type="text"
                  :name="'user-name-' + index"
                  :id="'user-name-' + index"
                  :disabled="!appSettings.gpsMember"
                  class="input__input"
                  v-model="coord.longitude" />
              </div>
              <div
                v-if="index != 0"
                class="trash-svg"
                :class="{
                  'advanced-settings__selectedusers--disabled':
                    !appSettings.gpsMember,
                }"
                @click="removeCoordinates(index)">
                <use-svg svg="trash-icon" />
              </div>
            </div>
          </div>
          <base-button
            @click="addCoordinates"
            :text="$t('add')"
            class="button--has-icon advanced-settings__selectedusers--button"
            :button-size-small="true"
            :disabled="!appSettings.gpsMember">
            <!-- <use-svg
              svg="plus-icon"
              color="white"/> -->
          </base-button>
        </div>
      </div>
      <div class="club__actions">
        <base-button
          :text="$t('save')"
          @click="saveAppSettings" />
      </div>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/form-elements/switch';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import elementLoader from '@/directives/element-loader';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'AppSettings',
  mixins: [LocalizationMixin],
  components: {
    ToggleSwitch,
    BaseButton,
    UseSvg,
  },

  directives: {
    elementLoader,
  },

  data() {
    return {
      localCoordinates: [],
      showInfobox: false,
      showInfobox2: false,
      showInfobox3: false,
    };
  },
  methods: {
    toggleShowInfobox() {
      this.showInfobox = !this.showInfobox;
    },
    toggleShowInfobox2() {
      this.showInfobox2 = !this.showInfobox2;
    },
    toggleShowInfobox3() {
      this.showInfobox3 = !this.showInfobox3;
    },
    toggle(boolean, param) {
      this.appSettings[param] = boolean;
    },
    saveAppSettings() {
      this.$store
        .dispatch('appSettings/editAppSettings', {
          allowArrival: this.appSettings.allowArrival,
          arrivalGuest: this.appSettings.arrivalGuest,
          gpsMember: this.appSettings.gpsMember,
          messageGuest: this.appSettings.messageGuest,
          messageMember: this.appSettings.messageMember,
          minutesGuest: this.appSettings.minutesGuest,
          minutesMember: this.appSettings.minutesMember,
          clubCoordinates: this.appSettings.clubCoordinates,
        })
        .then(() => {
          this.$toasted.success(this.$t('appSettingsSaved'));
        });
    },
    addCoordinates() {
      this.appSettings.clubCoordinates.push({
        id: '',
        longitude: 0,
        latitude: 0,
        comment: '',
      });
    },
    removeCoordinates(index) {
      this.appSettings.clubCoordinates.splice(index, 1);
    },
    selectEvent(e, v) {
      this.appSettings[v] = parseInt(e.target.value);
    },
  },
  computed: {
    appSettings() {
      return this.$store.getters['appSettings/appSettings'];
    },
  },
  created() {
    this.$store.dispatch('appSettings/fetchAppSettings', null).then(data => {
      if (data) {
        this.localCoordinates = data.clubCoordinates;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.flex-block {
  display: flex;
  flex-direction: row;
  margin-right: 100px;
  justify-content: flex-start;
  margin-bottom: 20px;
  .info-svg-container {
    height: 20px;
    > svg {
      margin-left: 5px;
      height: 20px;
      pointer-events: auto;
      cursor: pointer;
    }
  }
  .switch-labelbox {
    min-width: 35%;
    width: 35%;
    display: flex;
    flex-direction: row;
    position: relative;

    .info-svg-container {
      height: 20px;
    }
    .svg {
      margin-left: 5px;
      height: 20px;
      pointer-events: auto;
      cursor: pointer;
    }

    .info-box {
      background-color: rgb(189, 189, 189);
      border: 1px solid color(gray);
      border-radius: 5px;
      padding: 5px;
      position: absolute;
      left: 0;
      bottom: 50px;
      z-index: 1;
      box-shadow: 1px 1px 5px;
      max-width: 90%;

      button {
        border: none;
        margin: 0px 0px 10px auto;
        font-size: 12px;
        background-color: transparent;
        justify-content: right;
        display: flex;
        z-index: 2;
      }
    }
  }
  .switch-contentbox {
    margin-left: 100px;

    .switch-active-content {
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      select {
        margin-top: 10px;
        width: 75px;
      }
    }

    .input {
      display: flex;
      flex-direction: row;
      margin-top: 10px;

      .input-column {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-right: 10px;
      }

      .trash-svg {
        margin-top: 10px;
      }
    }
  }

  button {
    margin-top: 10px;
  }

  .description {
    font-style: italic;
    font-size: 14px;
  }
}

textarea {
  display: block;
  width: 25rem;
  height: 5rem;
}
.header_label {
  font-size: 1.1rem;
  font-weight: bold;
}
</style>
