<template>
  <div class="legend">
    <div class="head">{{ year1 }}</div>
    <div class="head">{{ year2 }}</div>
    <div></div>
    <div
      class="line"
      style="border-top: 2px solid #00a99d"></div>
    <div
      class="item"
      style="background: #fbebdd"></div>
    <div class="text">{{ $t('gameCapacity') }}</div>

    <div
      class="item"
      :style="'background:' + colors[0]"></div>
    <div
      class="item"
      :style="'background: #FFF;border: 2px solid ' + colors[0]"></div>
    <div class="text">{{ $t('memberFriendly') }}</div>

    <div
      v-if="!hasGolfBox"
      class="item"
      :style="'background:' + colors[1]"></div>
    <div
      v-if="!hasGolfBox"
      class="item"
      :style="'background: #FFF;border: 2px solid ' + colors[1]"></div>
    <div
      v-if="!hasGolfBox"
      class="text">
      {{ $t('memberCompetition') }}
    </div>

    <div
      class="item"
      :style="'background:' + colors[2]"></div>
    <div
      class="item"
      :style="'background: #FFF;border: 2px solid ' + colors[2]"></div>
    <div class="text">{{ $t('guestFriendly') }}</div>

    <div
      v-if="!hasGolfBox"
      class="item"
      :style="'background:' + colors[3]"></div>
    <div
      v-if="!hasGolfBox"
      class="item"
      :style="'background: #FFF;border: 2px solid ' + colors[3]"></div>
    <div
      v-if="!hasGolfBox"
      class="text">
      {{ $t('guestCompetition') }}
    </div>

    <div
      v-if="hasGolfBox"
      class="item"
      :style="'background:' + colors[3]"></div>
    <div
      v-if="hasGolfBox"
      class="item"
      :style="'background: #FFF;border: 2px solid ' + colors[3]"></div>
    <div
      v-if="hasGolfBox"
      class="text">
      {{ $t('competition') }}
    </div>
    <template v-if="showXFactor">
      <div
        class="item"
        :style="'background:' + colors[4]"></div>
      <div
        class="item"
        :style="'background: #FFF;border: 2px solid ' + colors[4]"></div>
      <div class="text">{{ $t('xFactor') }}</div>
    </template>
  </div>
</template>
<script>
import LocalizationMixin from '../../../mixins/localization-mixin.vue';
export default {
  mixins: [LocalizationMixin],
  props: {
    year1: {
      type: Number,
      default: 2023,
    },
    year2: {
      type: Number,
      default: 2022,
    },
    hasGolfBox: {
      type: Boolean,
      default: false,
    },
    showXFactor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: ['#0D3746', '#00A99D', '#ABDED1', '#F08565', '#4B2D88', '#'],
    };
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.legend {
  margin-left: auto;
  width: 30rem;
  padding: 1rem;
  font-size: 12px;
  display: grid;
  grid-template-columns: 4rem 4rem auto;
  font-weight: 400;
  font-size: 18px;
  justify-items: center;
  .head {
    font-weight: 600;
  }
  .line {
    width: 25px;
    margin: auto;
  }
  .item {
    border-radius: 2px;
    width: 25px;
    height: 15px;
  }
  .text {
    justify-self: left;
  }
  > div {
    margin: 5px;
  }
}
</style>
