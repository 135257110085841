<template>
  <div class="scorecard">
    <div
      class="scorecard__standard__title"
      @click="expand">
      <h2>
        {{ item.name }}
        <span v-if="showLink()"><use-svg svg="connected-icon" /></span>
      </h2>
      <span>
        {{ $t('edited') }} {{ formatDateAndTime(item.lastChanged) }}
      </span>
      <use-svg
        v-if="!item.standard && isExpanded"
        class="svg--180-negative scorecard__arrow-down"
        svg="arrow-right" />
      <use-svg
        v-else-if="!item.standard"
        class="scorecard__arrow-up"
        svg="arrow-right" />
    </div>
    <div
      class="scorecard__holder"
      v-if="item.standard || isExpanded">
      <div class="scorecard__content">
        <div class="scorecard__content--image">
          <div
            :class="[
              'scorecard__result--customized-wrapper',
              {
                'alternative-layout':
                  item.template && item.template.alternativeLayout,
              },
            ]">
            <div class="scorecard__result--customized-image">
              <img
                v-if="item.imageOneUrl"
                :src="
                  item.imageOneKey
                    ? item.imageOneUrl + item.imageOneKey
                    : item.imageOneUrl
                " />
            </div>
            <div
              v-if="item.template && !item.template.alternativeLayout"
              class="scorecard__result--customized-image">
              <img
                v-if="item.imageTwoUrl"
                :src="
                  item.imageTwoKey
                    ? item.imageTwoUrl + item.imageTwoKey
                    : item.imageTwoUrl
                " />
            </div>
          </div>
          <div
            :class="[
              'scorecard__result--info-wrapper',
              {
                'alternative-layout':
                  item.template && item.template.alternativeLayout,
              },
            ]">
            <div class="scorecard__result--info-header">
              {{ item.headerText }}
            </div>
            <textarea
              class="scorecard__result--info-text"
              v-if="item.template && !item.template.alternativeLayout"
              v-model="item.informationText"
              readonly></textarea>
          </div>
          <img
            v-if="item.template && item.template.templateUrl"
            :src="item.template.templateUrl"
            :alt="'Score card - ' + type" />
        </div>
        <div class="scorecard__content--first">
          <div
            class="info__content"
            v-if="item.template && item.template.description">
            <ul>
              <li>{{ item.template.description }}</li>
            </ul>
          </div>
          <div class="info__bottom">
            <button
              class="info__bottom"
              @click="editScoreCard">
              <use-svg svg="edit-icon" />
            </button>
            <button
              class="info__bottom"
              @click="duplicateScoreCard(item)">
              <use-svg svg="duplicate-icon" />
            </button>
            <button
              class="info__bottom"
              @click="toggleDeleteScoreCardModal"
              v-if="!item.standard">
              <use-svg svg="trash-icon" />
            </button>
            <modal-window :show="showDeleteScoreCardModal">
              <div class="modal__header">
                {{ $t('deleteScorecard') }}
              </div>
              <div class="modal__content">
                <p v-if="item.courses.length > 0">
                  {{ $t('deleteScorecardConnected', { itemName: item.name }) }}?
                </p>
                <p v-else>
                  {{ $t('deleteScorecardSure', { itemName: item.name }) }}?
                </p>
              </div>
              <div class="modal__content--actions">
                <base-button
                  class="button--background-gray-dark"
                  :text="$t('cancel')"
                  @click="toggleDeleteScoreCardModal">
                </base-button>
                <base-button
                  :text="$t('deleteScorecard')"
                  @click="deleteScoreCard(item.id)" />
              </div>
            </modal-window>
          </div>
        </div>
        <div
          class="scorecard__content--second"
          v-if="item.standard">
          <div class="info__content">
            <p>
              Detta är det kort ni har som standard om ni inte valt att skapa
              anpassade kort. Ni kan ha en bestämd layout för ert standardkort.
              Klicka på pennan till vänster får att komma in i redigerarläget
              för detta scorekort.
            </p>
          </div>
        </div>
        <div
          class="scorecard__form"
          v-if="!item.standard">
          <div
            v-if="
              item &&
              item.courses &&
              courses.length > 0 &&
              (typeName === 'friendly' || typeName === 'neutral')
            ">
            <addable-custom-select
              :options="courses"
              :label="$t('addCourses')"
              :selected="item.courses ? item.courses : []"
              @selectedConnections="setCourses" />
          </div>
          <!-- TODO: Add and verify this when BE has done their part -->
          <div
            v-if="
              item && competitions.length > 0 && typeName === 'competition'
            ">
            <addable-date-filter
              :options="competitions"
              :label="$t('addCompetitions')"
              :modal-title="$t('addCompetitions')"
              :selected="item.competitions ? item.competitions : []"
              @selectedConnections="setCompetitions" />
          </div>
          <!-- TODO: Test and verify categories when BE is done with connections -->
          <div
            v-if="item && categories.length > 0 && typeName === 'competition'">
            <addable-custom-select
              :label="$t('addCategories')"
              :options="categories"
              :selected="
                item.competitionCategories ? item.competitionCategories : []
              "
              @selectedConnections="setCategories" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import ModalWindow from '@/components/modal/modal';
import BaseButton from '@/components/form-elements/base-button';
import AddableCustomSelect from '@/components/addable-custom-select/addable-custom-select';
import AddableDateFilter from '@/components/addable-date-filter/addable-date-filter';

export default {
  name: 'ScoreCard',
  components: {
    UseSvg,
    ModalWindow,
    BaseButton,
    AddableCustomSelect,
    AddableDateFilter,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    type: Number,
    typeName: String,
  },
  data() {
    return {
      isExpanded: false,
      showDeleteScoreCardModal: false,
    };
  },
  methods: {
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    formatDateAndTime(dateAndTime) {
      const dateString = new Date(dateAndTime).toLocaleDateString('sv-SE');
      const timeString = new Date(dateAndTime).toLocaleTimeString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return dateString + ' ' + timeString;
    },
    editScoreCard() {
      this.$router.push({
        name: `edit-${this.typeName}-scorecard`,
        params: { id: this.item.id, typeName: this.typeName, type: this.type },
      });
    },
    toggleDeleteScoreCardModal() {
      this.showDeleteScoreCardModal = !this.showDeleteScoreCardModal;
    },
    deleteScoreCard(id) {
      this.$store
        .dispatch('scorecard/deleteScoreCard', {
          id: id,
        })
        .then(() => {
          this.$toasted.success('Scorecard was deleted');
          this.toggleDeleteScoreCardModal();
          this.isExpanded = false;
        });
    },
    duplicateScoreCard(item) {
      this.$router.push({
        name: `create-${this.typeName}-scorecard`,
        params: {
          type: this.type,
          typeName: this.typeName,
          copyOfScoreCard: {
            name: this.item.name,
            imageOneUrl: this.item.imageOneUrl,
            imageOneKey: this.item.imageOneKey,
            imageTwoUrl: this.item.imageTwoUrl,
            imageTwoKey: this.item.imageTwoKey,
            headerText: this.item.headerText,
            informationText: this.item.informationText,
            template: this.item.template,
          },
        },
      });
    },
    showLink() {
      return (
        this.item &&
        ((this.item && this.item.courses && this.item.courses.length > 0) ||
          (this.item &&
            this.item.competitionCategories &&
            this.item.competitionCategories.length > 0) ||
          (this.item &&
            this.item.competitions &&
            this.item.competitions.length > 0))
      );
    },
    setCourses(selectedCourses) {
      this.$store
        .dispatch('scorecard/editCoursesOnScoreCard', {
          id: this.item.id,
          courseIds:
            selectedCourses.length > 0
              ? selectedCourses.map(course => course.value)
              : [],
        })
        .then(() => {
          this.$emit('fetch', this.item.id);
        });
    },
    // TODO: Verify this when BE has done their part
    setCategories(selectedCategories) {
      let scorecard = this.item;
      scorecard.categories = selectedCategories.map(category => category.value);
      scorecard.competitionIds = scorecard.competitions.map(
        competition => competition.value
      );
      this.$store.dispatch('scorecard/editScoreCard', scorecard);
      // this.$store.dispatch('scorecard/editCategoriesOnScoreCard', {
      //   id: this.item.id,
      //   categories: selectedCategories.map(category => category.value)
      // }).then(() => {
      //   this.$store.dispatch('scorecard/fetchCategories', { id: this.item.id })
      // })
    },
    // TODO: Verify this when BE has done their part
    setCompetitions(selectedCompetitions) {
      let scorecard = this.item;
      scorecard.competitionIds = selectedCompetitions.map(
        competition => competition.value
      );
      scorecard.categories = scorecard.competitionCategories.map(
        category => category.value
      );
      this.$store.dispatch('scorecard/editScoreCard', scorecard);
      // this.$store.dispatch('scorecard/editCompetitionsOnScoreCard', {
      //   id: this.item.id,
      //   competitionIds: selectedCompetitions.map(competition => competition.value)
      // }).then(() => {
      //   this.$store.dispatch('scorecard/fetchCompetitions', { id: this.item.id })
      // })
    },
  },
  computed: {
    courses() {
      return this.$store.getters['scorecard/courses'];
    },
    categories() {
      return this.$store.getters['scorecard/categories'];
    },
    // TODO: Add and verify this when BE has done their part
    competitions() {
      return this.$store.getters['scorecard/competitions'];
    },
  },
  mounted() {
    if (this.typeName === 'friendly' || this.typeName === 'neutral') {
      this.$store.dispatch('scorecard/fetchCourses', { id: this.item.id });
    } else {
      this.$store.dispatch('scorecard/fetchCompetitions', { id: this.item.id });
      this.$store.dispatch('scorecard/fetchCategories', { id: this.item.id });
    }
  },
};
</script>

<style lang="scss">
.scorecard {
  margin-bottom: 1rem;
}
.scorecard__holder {
  display: flex;
  width: 100%;
  background-color: color(gray-lighter);

  .scorecard__result--customized-wrapper {
    position: absolute;
    display: flex;
    left: 0.5rem;
    right: 0.5rem;
    top: 0.5rem;
    justify-content: space-evenly;
    &.alternative-layout {
      right: initial;
      width: 40%;
    }
    .scorecard__result--customized-image {
      width: 100%;
      height: 2rem;
      padding: 0.125rem;
      position: relative;
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      &.highlight {
        background-color: color(white);
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
      }
    }
  }
  .scorecard__result--info-wrapper {
    position: absolute;
    top: 2.5rem;
    width: 6.9rem;
    font-size: 0.4rem;
    &.alternative-layout {
      top: initial;
      bottom: 1rem;
    }
    .scorecard__result--info-header {
      text-align: center;
      // pixels because rem below 6px doesn't work?
      font-size: 5px;
      &.highlight {
        background-color: white;
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
        position: absolute;
        width: 100%;
      }
    }
    .scorecard__result--info-text {
      width: 48%;
      padding: 0.1875rem 0.3125rem 0 0.3125rem;
      word-break: break-word;
      resize: none;
      overflow: hidden;
      border: none;
      height: 60px;
      // pixels because rem below 6px doesn't work?
      font-size: 3.5px;
      &.highlight {
        background-color: white;
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
        position: absolute;
        width: 50%;
      }
    }
  }
}
.scorecard__standard__title {
  cursor: pointer;
  background-color: color(gray-light);
  padding: 1rem;
  @include body-tiny;
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    flex: 1;

    svg {
      color: color(gray-dark);
    }
  }
  span {
    margin-right: 0.5rem;
  }
  svg {
    color: color(gray-dark);
  }
  .scorecard__arrow-up {
    position: relative;
    top: 0.125rem;
  }
  .scorecard__arrow-down {
    position: relative;
    top: -0.25rem;
  }
}
.scorecard__content {
  display: flex;
  padding: 0.5rem;
  width: 100%;
}
.scorecard__form {
  padding: 0.5rem;
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  > div {
    flex: 1 1 auto;
    margin: 0 1.25rem 0.625rem 0;

    &:first-child {
      padding-left: 1.25rem;
      border-left: 1px solid color(gray-dark);
    }

    &:nth-child(2) {
      padding-left: 1.25rem;
      border-left: 1px solid color(gray-dark);
    }
    .addable-custom-select .input .select__wrapper {
      margin-bottom: 0.5rem;
    }
  }
}
.scorecard__content--image {
  background-color: color(gray);
  padding: 0.5rem;
  position: relative;
  img {
    width: 6.875rem;
    height: 18.875rem;
  }
}
.scorecard__content--first {
  display: flex;
  width: 25%;
  flex-direction: column;
  padding: 0.5rem;
  .info__content {
    ul {
      margin: 0;
      padding-left: 1.25rem;
    }
  }
  .info__bottom {
    @include body-tiny;
    display: flex;
    justify-content: flex-end;
    color: color(gray-darkest);
    color: color(gray-dark);
    border: none;
    background: transparent;
    margin-top: auto;
  }
}
.scorecard__content--second {
  padding: 0.5rem;
  width: 75%;

  p {
    margin-top: 0;
  }
}
</style>
