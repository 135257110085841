<template>
  <button
    :type="type"
    class="button"
    :class="[
      hasIconClass,
      iconToRightClass,
      iconOnlyClass,
      backgroundColorClass,
      alignTextClass,
      smallButtonSize,
      modifierClasses,
    ]"
    @click="clickEvent($event)"
    :disabled="disabled">
    <slot />
    <span>{{ text }}</span>
  </button>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  props: {
    type: VueTypes.oneOf(['button', 'submit', 'reset']).def('button'),
    text: VueTypes.string.def('Base Button'),
    buttonSizeSmall: VueTypes.bool.def(false),
    // type (primary, secondary, ..., ...)?
    backgroundColor: VueTypes.string.def('blue'),
    textToLeft: VueTypes.bool.def(false),
    iconToRight: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
    modifierClasses: VueTypes.string.def(''),
  },
  computed: {
    hasIconClass() {
      return this.$slots.default && !this.iconToRight && 'button--has-icon';
    },
    iconToRightClass() {
      return this.iconToRight && 'button--icon-to-right';
    },
    iconOnlyClass() {
      return this.text === '' && 'button--icon-only';
    },
    backgroundColorClass() {
      return (
        this.backgroundColor && `button--background-${this.backgroundColor}`
      );
    },
    alignTextClass() {
      return this.textToLeft && 'button--text-to-left';
    },
    smallButtonSize() {
      return this.buttonSizeSmall && `button--small`;
    },
  },
  methods: {
    clickEvent(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  @include reset-button;
  @include body;

  justify-content: center;

  height: 2.6rem;
  padding: 0 measure(half);

  color: color(white);

  border-radius: measure(sixteenth);
  transition: all 140ms;

  &.button--small {
    height: measure(double);
    padding: 0 measure(half);
  }
}

.button--background-blue {
  background-color: color(blue);

  &:hover {
    background-color: color(blue-dark);
  }

  // TODO: Vill inte deklarera dessa för varje färg utan enbart en gång (avseende just cursor: not-allowed)
  &:disabled {
    cursor: not-allowed;

    background-color: color(blue-lighter);
  }
}

.button--background-red {
  background-color: color(red);

  &:hover {
    background-color: color(red-dark);
  }

  &:disabled {
    cursor: not-allowed;

    background-color: color(red-lighter);
  }
}

.button--background-gray-dark {
  background-color: color(gray-dark);

  &:hover {
    background-color: color(gray-darker);
  }
}

.button--background-none {
  color: black;
  text-decoration: underline;

  background: none;

  &:hover {
    text-decoration: underline;

    background: none;
  }
}

.button--background-bb-primary {
  background-color: color(bb-primary);
  &:hover {
    background-color: color(bb-sec1);
  }
}

.button--background-white {
  color: color(bb-sec1);

  background-color: color(white);
  border: 1px solid color(bb-sec1);
  border-radius: 3px;

  &:hover {
    background-color: color(gray);
  }
}

.button--reset {
  color: color(black);

  background-color: transparent;

  &:hover {
    text-decoration: underline;

    background-color: transparent;
  }
}

.button--has-icon svg {
  margin-right: measure(quarter);
  margin-bottom: measure(sixteenth);
}

.button--icon-to-right {
  padding-right: measure(half);
}

.button--icon-to-right svg {
  order: 2;

  margin-right: 0;
  margin-left: auto;
}

.button--icon-only {
  width: measure(quad);
  height: measure(quad);
  padding: 0;
}

.button--icon-only svg {
  margin-right: 0;
  margin-left: 0;
}

.button--text-to-left {
  justify-content: flex-start;
}

.button--icon-to-left {
  padding-left: measure(half);
}
</style>
