export const MonthsLabelsFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MonthsLabelsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const BusinessBoardVersion = {
  None: 0,
  Basic: 1,
  Premium: 2,
  PremiumWithBudget: 3,
  PremiumWithBudgetGolfBox: 4,
  Professional: 4,
  EconomicBasic: 10,
  EconomicPlus: 11,
};
