<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <div class="header">
      <div class="left_header">
        <span class="h2">
          {{ budgetGroup.name }}
        </span>
        <span class="h2 small">
          {{ $t('total') }}: {{ budgetGroup.value.toLocaleString() }}
          {{ $t('denomination') }}
        </span>
      </div>

      <span class="h3">
        {{ connectionsCompleted(budgetGroup) }}/{{
          connectionsCount(budgetGroup)
        }}
        {{ $t('BudgetCategory.markedAccounts').toLowerCase() }}
      </span>
      <button @click="hideGroup = !hideGroup">
        <use-svg
          svg="arrow-right"
          class="svg" />
      </button>
    </div>
    <div
      v-if="index > 0 ? hideGroup : !hideGroup"
      class="category-content">
      <div class="category-content__actions">
        <button
          class="action-button"
          @click="toggleActions()">
          <img
            src="@/components/svg/tools.svg"
            class="svg"
            alt="" />
          <span>
            {{ $t('BudgetCategory.tools') }}
          </span>
        </button>
        <!-- modal for actions -->
        <div
          class="category-content__actions-box"
          v-if="showActions">
          <div v-if="showButtons">
            <button
              class="buttonActionChoice"
              @click="toggleModalChangeAll()">
              <img
                src="@/components/svg/change-percent.svg"
                class="svg"
                alt="" />
              <span>
                {{ $t('BudgetCategory.changePercentageAll') }}
              </span>
            </button>
            <button
              class="buttonActionChoice"
              @click="toggleModalChangeIndividual()">
              <img
                src="@/components/svg/percent-alt.svg"
                class="svg"
                alt="" />
              <span>
                {{ $t('BudgetCategory.changePercentageSingle') }}
              </span>
            </button>
            <button
              v-if="preBudgetId != ''"
              class="buttonActionChoice"
              @click="toggleModalCopyAllBudget()">
              <img
                src="@/components/svg/copy-all.svg"
                class="svg"
                alt="" />
              <span>
                {{ $t('BudgetCategory.copyAllBudget') }}
              </span>
            </button>
            <button
              v-if="preBudgetId != ''"
              class="buttonActionChoice"
              @click="toggleModalCopyIndividualBudget()">
              <img
                src="@/components/svg/copy-field.svg"
                class="svg"
                alt="" />
              <span>
                {{ $t('BudgetCategory.copySingleBudget') }}
              </span>
            </button>
            <button
              v-if="preOutcomeYear != ''"
              class="buttonActionChoice"
              @click="toggleModalCopyAllOutcome()">
              <img
                src="@/components/svg/copy-all.svg"
                class="svg"
                alt="" />
              <span>
                {{ $t('BudgetCategory.copyAllOutcome') }}
              </span>
            </button>
            <button
              v-if="preOutcomeYear != ''"
              class="buttonActionChoice"
              @click="toggleModalCopyIndividualOutcome()">
              <img
                src="@/components/svg/copy-field.svg"
                class="svg"
                alt="" />
              <span>
                {{ $t('BudgetCategory.copySingleOutcome') }}
              </span>
            </button>
          </div>
          <!-- Show modal for changing percent for all accounts -->
          <div v-if="showChangePercentAll">
            <h3>
              {{ $t('BudgetCatgory.changePercentage') }}
            </h3>
            <span>
              {{ $t('BudgetCategory.percentageInput') }}
            </span>
            <h3>
              {{ $t('BudgetCategory.inputPercentage') }}
            </h3>
            <input
              type="number"
              v-model="percentage" />%
            <div class="category-content__button-container">
              <base-button
                @click="toggleActions()"
                class="button--reset"
                :text="$t('cancel')">
              </base-button>
              <base-button
                v-element-loader="{ loaderKey: 'patch-budget' }"
                :text="$t('save')"
                @click="changeAllValues(budgetGroup)">
              </base-button>
            </div>
          </div>
          <!-- Show modal for changing percent for individual accounts -->
          <div v-if="showChangePercentIndividual">
            <span>
              {{ $t('BudgetCategory.markToIncOrDec') }}
            </span>
            <div class="category-content__button-container">
              <base-button
                @click="toggleActions()"
                class="button--reset"
                :text="$t('cancel')">
              </base-button>
              <base-button
                :text="$t('done')"
                @click="showStep2">
              </base-button>
            </div>
          </div>
          <div v-if="showChangePercentIndividualStep2">
            <h3>
              {{ $t('BudgetCategory.changePercentage') }}
            </h3>
            <span>
              {{ $t('BudgetCategory.percentageInput') }}
            </span>
            <h3>
              {{ $t('BudgetCategory.inputPercentage') }}
            </h3>
            <input
              type="number"
              v-model="percentage" />%
            <div class="category-content__button-container">
              <base-button
                @click="toggleActions()"
                class="button--reset"
                :text="$t('cancel')">
              </base-button>
              <base-button
                :text="$t('save')"
                @click="changeValues(budgetGroup, 'budgetGroup')">
              </base-button>
            </div>
          </div>
          <!-- Show modal for copy all budget fields -->
          <div v-if="showCopyAllBudget">
            <h3>
              {{ $t('BudgetCategory.copyFromBudget') }}
            </h3>
            <span>
              <input
                type="radio"
                name="copyRadio"
                :value="1"
                v-model="copyAllFieldsRadioValue" />
              <h4 class="radio-heading">
                {{ $t('BudgetCategory.copyWithoutChange') }}
              </h4> </span
            ><br />
            <span>
              <input
                type="radio"
                name="copyRadio"
                :value="2"
                v-model="copyAllFieldsRadioValue" />
              <h4 class="radio-heading">
                {{ $t('BudgetCategory.incSlashDec') }}
              </h4>
            </span>
            <br />
            <span>
              {{ $t('BudgetCategory.percentageInput') }}
            </span>
            <h3>
              {{ $t('BudgetCategory.inputPercentage') }}
            </h3>
            <input
              type="number"
              v-model="percentage"
              :disabled="copyAllFieldsRadioValue == 1" />%
            <div class="category-content__button-container">
              <base-button
                @click="toggleActions()"
                class="button--reset"
                :text="$t('cancel')">
              </base-button>
              <base-button
                :text="$t('save')"
                @click="copyValues('all', 'budget')">
              </base-button>
            </div>
          </div>
          <!-- show modal for copy individual budget fields -->
          <div v-if="showCopyIndividualBudgetStep1">
            <span>
              <p>
                {{ $t('BudgetCategory.markBudgetCopy') }}
                <br />
                <br />
                {{ $t('BudgetCategory.clickDone') }}
              </p>
            </span>
            <div class="category-content__button-container">
              <base-button
                @click="toggleActions()"
                class="button--reset"
                :text="$t('cancel')">
              </base-button>
              <base-button
                :text="$t('done')"
                @click="showStep2CopyBudgetFields">
              </base-button>
            </div>
          </div>
          <div v-if="showCopyIndividualBudgetStep2">
            <h3>
              {{ $t('BudgetCategory.copyFromBudget') }}
            </h3>
            <span>
              <input
                type="radio"
                name="copyRadio"
                :value="1"
                v-model="copyAllFieldsRadioValue" />
              <h4 class="radio-heading">
                {{ $t('BudgetCategory.copyWithoutChange') }}
              </h4> </span
            ><br />
            <span>
              <input
                type="radio"
                name="copyRadio"
                :value="2"
                v-model="copyAllFieldsRadioValue" />
              <h4 class="radio-heading">
                {{ $t('BudgetCategory.incSlashDec') }}
              </h4>
            </span>
            <br />
            <span>
              {{ $t('BudgetCategory.percentageInput') }}
            </span>
            <h3>
              {{ $t('BudgetCategory.inputPercentage') }}
            </h3>
            <input
              type="number"
              v-model="percentage"
              :disabled="copyAllFieldsRadioValue == 1" />%
            <div class="category-content__button-container">
              <base-button
                @click="toggleActions()"
                class="button--reset"
                :text="$t('cancel')">
              </base-button>
              <base-button
                :text="$t('save')"
                @click="copyValues('individual', 'budget')">
              </base-button>
            </div>
          </div>
          <!-- show modal for copy all outcome fields -->
          <div v-if="showCopyAllOutcome">
            <h3>
              {{ $t('BudgetCategory.copyFromOutcome') }}
            </h3>
            <span>
              <input
                type="radio"
                name="copyRadio"
                :value="1"
                v-model="copyAllFieldsRadioValue" />
              <h4 class="radio-heading">
                {{ $t('BudgetCategory.copyWithoutChange') }}
              </h4>
            </span>
            <br />
            <span>
              <input
                type="radio"
                name="copyRadio"
                :value="2"
                v-model="copyAllFieldsRadioValue" />
              <h4 class="radio-heading">{{ $t('incSlashDec') }}</h4> </span
            ><br />
            <span>{{ $t('percentageInput') }} </span>
            <h3>{{ $t('inputPercentage') }}</h3>
            <input
              type="number"
              v-model="percentage"
              :disabled="copyAllFieldsRadioValue == 1" />%
            <div class="category-content__button-container">
              <base-button
                @click="toggleActions()"
                class="button--reset"
                :text="$t('cancel')">
              </base-button>
              <base-button
                :text="$t('save')"
                @click="copyValues('all', 'outcome')">
              </base-button>
            </div>
          </div>
          <!-- show modal copy individual OUTCOME field -->
          <div v-if="showCopyIndividualOutcomeStep1">
            <span>
              <p>
                {{ $t('BudgetCategory.markFieldsCopy') }}
                <br />
                <br />
                {{ $t('BudgetCategory.clickDone') }}
              </p>
            </span>
            <div class="category-content__button-container">
              <base-button
                @click="toggleActions()"
                class="button--reset"
                :text="$t('cancel')">
              </base-button>
              <base-button
                :text="$t('done')"
                @click="showStep2CopyOutcomeFields">
              </base-button>
            </div>
          </div>
          <div v-if="showCopyIndividualOutcomeStep2">
            <h3>
              {{ $t('BudgetCategory.copyFromOutcome') }}
            </h3>
            <span>
              <input
                type="radio"
                name="copyRadio"
                :value="1"
                v-model="copyAllFieldsRadioValue" />
              <h4 class="radio-heading">
                {{ $t('BudgetCategory.copyWithoutChange') }}
              </h4>
            </span>
            <br />
            <span>
              <input
                type="radio"
                name="copyRadio"
                :value="2"
                v-model="copyAllFieldsRadioValue" />
              <h4 class="radio-heading">
                {{ $t('BudgetCategory.incSlashDec') }}
              </h4>
            </span>
            <br />
            <span> </span>
            <h3>
              {{ $t('BudgetCategory.inputPercentage') }}
            </h3>
            <input
              type="number"
              v-model="percentage"
              :disabled="copyAllFieldsRadioValue == 1" />%
            <div class="category-content__button-container">
              <base-button
                @click="toggleActions()"
                class="button--reset"
                :text="$t('cancel')">
              </base-button>
              <base-button
                :text="$t('save')"
                @click="copyValues('individual', 'outcome')">
              </base-button>
            </div>
          </div>
        </div>
        <button
          class="action-button"
          @click="showAccountStatus = !showAccountStatus">
          <span
            >▢
            {{ $t('BudgetCategory.handleCheck') }}
          </span>
        </button>
      </div>
      <div class="category-content__column-header">
        <div>
          <span class="h2">
            {{ $t('BudgetCategory.actualBudget') }}
          </span>
          <span class="h3">
            {{ $t('total') }}
            : {{ budgetGroup.value.toLocaleString() }}
            {{ $t('denomination') }}
          </span>
        </div>
        <div
          v-if="
            preBudgetId != '' && this.preBudget && this.preBudget.budgetGroups
          ">
          <span class="h2">
            {{ $t('Businessboard.preBudget') }}
          </span>
          <span class="h3">
            {{ $t('total') }}: {{ preBudgetGroupValue.toLocaleString() }}
            {{ $t('denomination') }}
          </span>
        </div>
        <div v-else></div>
        <div v-if="preOutcomeYear != '' && this.preOutcome.budgetGroups">
          <span class="h2">
            {{ $t('Businessboard.preOutcome') }}
          </span>
          <span class="h3">
            {{ $t('total') }}: {{ preOutcomeValue.toLocaleString() }}
            {{ $t('denomination') }}
          </span>
        </div>
        <div v-else></div>
      </div>
      <hr />
      <div class="category-content__column-wrapper">
        <div
          :class="['category-content__column', shouldDimBudget ? 'dim' : '']">
          <div
            v-for="(resultGroup, index) in budgetGroup.resultGroups"
            :key="index"
            class="category-content__group">
            <div
              @click="toggleResultGroup(index)"
              class="category-content__group-heading">
              <span
                class="category-content__account-counter"
                v-if="showAccountStatus">
                <input
                  @click.stop="e => resultGroupCompleteAll(e, index)"
                  :checked="
                    !resultGroup.accountConnections.some(a => !a.completed)
                  "
                  class="category-content__account-checkbox"
                  type="checkbox" />
                {{
                  resultGroup.accountConnections.reduce(
                    (a, b) => a + (b.completed ? 1 : 0),
                    0
                  )
                }}/{{ resultGroup.accountConnections.length }}
              </span>
              <span class="category-content__account-heading">{{
                resultGroup.name
              }}</span>
              <use-svg
                v-if="resultGroup.accountConnections.length > 0"
                svg="arrow-right"
                class="svg" />
              <span class="category-content__account-heading-right">
                {{
                  resultGroup.accountConnections
                    .reduce(
                      (a, b) => a + b.months.reduce((c, d) => c + d.value, 0),
                      0
                    )
                    .toLocaleString()
                }}
                {{ $t('denomination') }}
              </span>
            </div>
            <div v-if="showResultGroup[index]">
              <!-- TODO: Break into own component -->
              <div
                v-for="(
                  account, accountConnectionId
                ) in resultGroup.accountConnections"
                :key="accountConnectionId"
                class="category-content__account-wrapper"
                :class="{ hide: account.hidden }">
                <div class="account-label">
                  <label for="">
                    {{ account.name }}
                    <div
                      v-if="account.comment"
                      class="comment-indicator">
                      <use-svg
                        svg="pin-icon"
                        class="pin-icon" />
                    </div>
                  </label>
                </div>

                <div class="category-content__account-sum-row">
                  <input
                    v-if="showAccountStatus"
                    v-model="account.completed"
                    class="category-content__account-checkbox"
                    type="checkbox" />
                  <button
                    v-if="showChangePercentIndividual"
                    class="category-content__select-account-button"
                    @click="selectAccount(account)"></button>
                  <div
                    class="category-content__account-sum"
                    :class="selectedAccount(account)">
                    <span>
                      {{ account.value.toLocaleString() }}
                      {{ $t('denomination') }}
                    </span>
                    <div>
                      <button @click="toggle(account.accountConnectionId)">
                        <use-svg
                          svg="arrow-right"
                          class="svg" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="category-content__account-data"
                  v-if="clickedItems.includes(account.accountConnectionId)">
                  <div class="category-content__actions">
                    <button
                      @click="toggleNotes(account.accountConnectionId)"
                      class="category-content__action-button note-button"
                      :class="{
                        'bold-text': showNotes[account.accountConnectionId],
                      }">
                      <img
                        src="@/components/svg/message.svg"
                        class="notes__svg-icon"
                        alt="" />
                      {{ $t('Budget.notes') }}
                    </button>
                    <button
                      class="category-content__action-button"
                      @click="resetValues(index, accountConnectionId)">
                      <span
                        >✖
                        {{ $t('clear') }}
                      </span>
                    </button>

                    <button
                      class="category-content__action-button"
                      @click="
                        toggleActionChangePercent(account.accountConnectionId)
                      ">
                      <use-svg
                        svg="change-percent"
                        class="svg" />
                      <span>{{ $t('BudgetCategory.changePercentage') }} </span>
                    </button>
                    <button
                      class="category-content__action-button"
                      @click="
                        toggleActionDistribute(account.accountConnectionId)
                      ">
                      <img
                        src="@/components/svg/distribute.svg"
                        class="svg"
                        alt="" />
                      <span>
                        {{ $t('BudgetCategory.divide') }}
                      </span>
                    </button>
                    <div
                      v-if="
                        showActionChangePercent.includes(
                          account.accountConnectionId
                        )
                      "
                      class="category-content__action-window">
                      <h3>
                        {{ $t('BudgetCategory.changePercentage') }}
                      </h3>
                      <span>
                        {{ $t('BudgetCategory.percentageInput') }}
                      </span>
                      <h3>
                        {{ $t('BudgetCategory.inputPercentage') }}
                      </h3>
                      <input
                        type="number"
                        v-model="percentage" />%
                      <div class="category-content__button-container">
                        <base-button
                          @click="
                            toggleActionChangePercent(
                              account.accountConnectionId
                            )
                          "
                          class="button--reset"
                          :text="$t('cancel')"></base-button>
                        <base-button
                          :text="$t('save')"
                          @click="
                            changeValues(account, 'account')
                          "></base-button>
                      </div>
                    </div>
                    <div
                      v-if="
                        showActionDistribute.includes(
                          account.accountConnectionId
                        )
                      "
                      class="category-content__action-window">
                      <h3>{{ $t('BudgetCategory.divide') }}</h3>
                      <label for="amount">{{
                        $t('BudgetCategory.totalAmount')
                      }}</label>
                      <input
                        class="category-content__input-amount"
                        id="amount"
                        type="text"
                        v-model="amountToDistribute" />
                      <div>
                        <label>
                          {{ $t('BudgetCategory.template') }}
                        </label>
                        <form>
                          <div class="category-content__distribute-radio-row">
                            <div
                              class="category-content__distribute-radio-button">
                              <input
                                type="radio"
                                name="distribute-radio"
                                :value="1"
                                v-model="distributeType" />
                            </div>
                            <select
                              :disabled="distributeType == 2"
                              name=""
                              id=""
                              class="category-content__template-select"
                              v-model="distributionTemplate">
                              <option
                                value=""
                                selected>
                                Välj mall
                              </option>
                              <option
                                v-for="(
                                  template, value
                                ) in distributionTemplates"
                                :key="value"
                                :value="template.distribution">
                                {{ template.displayName }}
                              </option>
                            </select>
                          </div>
                          <div class="category-content__distribute-radio-row">
                            <div
                              class="category-content__distribute-radio-button">
                              <input
                                type="radio"
                                name="distribute-radio"
                                :value="2"
                                v-model="distributeType" />
                            </div>
                            <div
                              class="category-content__month-select-container">
                              <label for="">
                                {{ $t('from') }}
                              </label>
                              <select
                                name=""
                                id=""
                                class="category-content__month-select"
                                v-model="distributeFromMonth"
                                :disabled="distributeType != 2">
                                <option
                                  v-for="(month, index) in account.months"
                                  :key="index"
                                  :value="index">
                                  {{ month.name }}
                                </option>
                              </select>
                            </div>
                            <div
                              class="category-content__month-select-container">
                              <label for="">
                                {{ $t('to') }}
                              </label>
                              <select
                                name=""
                                id=""
                                class="category-content__month-select"
                                v-model="distributeToMonth"
                                :disabled="distributeFromMonth == undefined">
                                <option
                                  v-for="(month, index) in account.months"
                                  :key="index"
                                  :value="index"
                                  :disabled="index <= distributeFromMonth">
                                  {{ month.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="category-content__button-container">
                        <base-button
                          @click="
                            toggleActionDistribute(account.accountConnectionId)
                          "
                          class="button--reset"
                          :text="$t('cancel')"></base-button>
                        <base-button
                          @click="distributeAmount(account)"
                          :text="$t('save')"></base-button>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      !showNotes[account.accountConnectionId] && account.comment
                    "
                    @click="toggleNotes(account.accountConnectionId)"></div>
                  <div class="category-content__account-inputs">
                    <div
                      v-for="(month, mIndex) in account.months"
                      :key="mIndex"
                      class="category-content__account-input-row">
                      <span>{{ month.name }}</span>
                      <input
                        @change="
                          e =>
                            changeMonthValue(
                              index,
                              accountConnectionId,
                              mIndex,
                              e
                            )
                        "
                        :value="month.value.toLocaleString()" />
                      <span
                        class="month_info"
                        :title="
                          $t('thisNegativeValueCountedAs') +
                          ' ' +
                          (budgetGroup.isCostGroup
                            ? $t('BudgetCategory.revenue').toLowerCase()
                            : $t('BudgetCategory.cost').toLowerCase())
                        "
                        v-if="month.value < 0">
                        i
                      </span>
                    </div>
                    <note
                      :show="showNotes[account.accountConnectionId]"
                      :name="$t('Budget.notes')"
                      :comment="account.comment"
                      @update:comment="
                        val => {
                          account.comment = val;
                        }
                      "
                      :id="account.accountConnectionId" />
                  </div>
                  <!--
                  <base-button
                  @click="accountSum(account, resultGroup)"
                  class="category-content__save">
                    <use-svg
                      svg="update-icon"
                      color="white"
                      class="arrows" />
                  </base-button>
                  -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- previous budget column -->
        <div
          v-if="preBudgetId != '' && preBudget && preBudget.budgetGroups"
          :class="[
            'category-content__column',
            shouldDimPreBudget ? 'dim' : '',
          ]">
          <div
            v-for="(resultGroup, index) in previousBudgetGroup.resultGroups"
            :key="index"
            class="category-content__group">
            <div
              @click="toggleResultGroup(index)"
              class="category-content__group-heading">
              <span class="category-content__account-heading">{{
                resultGroup.name
              }}</span>
              <use-svg
                v-if="resultGroup.accountConnections.length > 0"
                svg="arrow-right"
                class="svg" />
              <span class="category-content__account-heading-right">
                {{
                  resultGroup.accountConnections
                    .reduce(
                      (a, b) => a + b.months.reduce((c, d) => c + d.value, 0),
                      0
                    )
                    .toLocaleString()
                }}
                {{ $t('denomination') }}
              </span>
            </div>
            <div v-if="showResultGroup[index]">
              <!-- TODO: Break into own component -->
              <div
                v-for="(
                  account, accountConnectionId
                ) in resultGroup.accountConnections"
                :key="accountConnectionId"
                class="category-content__account-wrapper"
                :class="{ hide: account.hidden }">
                <div class="account-label">
                  <label for="">
                    {{ account.name }}
                    <div
                      v-if="account.comment"
                      class="comment-indicator">
                      <use-svg
                        svg="pin-icon"
                        class="pin-icon" />
                    </div>
                  </label>
                </div>
                <div class="category-content__account-sum-row">
                  <div
                    :class="[
                      'category-content__account-sum',
                      (showCopyIndividualBudgetStep1 ||
                        showCopyIndividualBudgetStep2) &&
                      selectedFields.includes(account.accountConnectionId)
                        ? 'accountSelected'
                        : '',
                    ]"
                    @click="
                      showCopyIndividualBudgetStep1 &&
                        selectBudgetField(account)
                    ">
                    <span>
                      {{ account.value.toLocaleString() }}
                      {{ $t('denomination') }}
                    </span>
                    <div>
                      <button @click="toggle(account.accountConnectionId)">
                        <use-svg
                          svg="arrow-right"
                          class="svg" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="category-content__account-data"
                  v-if="clickedItems.includes(account.accountConnectionId)">
                  <div class="category-content__account-inputs-spacer"></div>
                  <div
                    v-if="
                      !showNotes[account.accountConnectionId] && account.comment
                    "
                    @click="toggleNotes(account.accountConnectionId)"></div>
                  <div class="category-content__account-inputs">
                    <div
                      v-for="(month, index) in account.months"
                      :key="index"
                      class="category-content__account-input-row">
                      <span>{{ month.name }}</span>
                      <input
                        disabled
                        :value="month.value.toLocaleString()" />
                    </div>
                  </div>
                  <note
                    :show="showNotes[account.accountConnectionId]"
                    :comment="account.comment"
                    :id="account.accountConnectionId"
                    :isDisabled="true" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="category-content__column"></div>
        <!-- previos result column -->
        <div
          v-if="preOutcomeYear != '' && preOutcome.budgetGroups"
          :class="['category-content__column', shouldDimOutcome ? 'dim' : '']">
          <div
            v-for="(resultGroup, index) in previousOutcome.resultGroups"
            :key="index"
            class="category-content__group">
            <div
              @click="toggleResultGroup(index)"
              class="category-content__group-heading">
              <span class="category-content__account-heading">{{
                resultGroup.name
              }}</span>
              <use-svg
                v-if="resultGroup.accountConnections.length > 0"
                svg="arrow-right"
                class="svg" />
              <span class="category-content__account-heading-right">
                {{
                  resultGroup.accountConnections
                    .reduce(
                      (a, b) => a + b.months.reduce((c, d) => c + d.value, 0),
                      0
                    )
                    .toLocaleString()
                }}
                {{ $t('denomination') }}
              </span>
            </div>
            <div v-if="showResultGroup[index]">
              <!-- TODO: Break into own component -->
              <div
                v-for="(
                  account, accountConnectionId
                ) in resultGroup.accountConnections"
                :key="accountConnectionId"
                class="category-content__account-wrapper"
                :class="{ hide: account.hidden }">
                <label for="">{{ account.name }}</label>
                <div class="category-content__account-sum-row">
                  <div
                    :class="[
                      'category-content__account-sum',
                      (showCopyIndividualOutcomeStep1 ||
                        showCopyIndividualOutcomeStep2) &&
                      selectedFields.includes(account.accountConnectionId)
                        ? 'accountSelected'
                        : '',
                    ]"
                    @click="
                      showCopyIndividualOutcomeStep1 &&
                        selectBudgetField(account)
                    ">
                    <span>
                      {{ account.value.toLocaleString() }}
                      {{ $t('denomination') }}
                    </span>
                    <div>
                      <button @click="toggle(account.accountConnectionId)">
                        <use-svg
                          svg="arrow-right"
                          class="svg" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="category-content__account-data"
                  v-if="clickedItems.includes(account.accountConnectionId)">
                  <div class="category-content__account-inputs-spacer"></div>
                  <div class="category-content__account-inputs">
                    <div
                      v-for="(month, index) in account.months"
                      :key="index"
                      class="category-content__account-input-row">
                      <span>{{ month.name }}</span>
                      <input
                        disabled
                        :value="month.value.toLocaleString()" />
                    </div>
                  </div>
                  <div
                    v-if="showNotes[account.accountConnectionId]"
                    class="category-content__account-inputs-note-spacer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="category-content__column"></div>
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import Note from '@/components/note.vue';
export default {
  name: 'BudgetCategory',
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
    BaseButton,
    Note,
  },
  props: {
    budgetGroup: {
      type: Object,
    },
    name: {
      type: String,
    },
    index: {
      type: Number,
    },
    preBudgetId: {
      type: String,
    },
    preBudget: {
      type: Object,
    },
    preOutcomeYear: {
      type: String,
    },
    preOutcome: {
      type: Object,
    },
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      showActions: false,
      showButtons: true,
      showChangePercentAll: false,
      showChangePercentIndividual: false,
      showChangePercentIndividualStep2: false,
      showCopyAllBudget: false,
      showCopyIndividualBudgetStep1: false,
      showCopyIndividualBudgetStep2: false,
      showCopyAllOutcome: false,
      showCopyIndividualOutcomeStep1: false,
      showCopyIndividualOutcomeStep2: false,
      hideGroup: false,
      clickedItems: [],
      showAccountStatus: false,
      showActionChangePercent: [],
      showActionDistribute: [],
      monthValues: [],
      percentage: 0,
      distributeType: 1,
      distributionTemplate: '',
      amountToDistribute: '',
      distributeFromMonth: undefined,
      distributeToMonth: '',
      selectedAccounts: [],
      selectedFields: [],
      copyAllFieldsRadioValue: 1,
      showResultGroup: [],
      showNotes: {},
    };
  },
  methods: {
    toggleNotes(accountConnectionId) {
      if (!(accountConnectionId in this.showNotes)) {
        this.$set(this.showNotes, accountConnectionId, false);
      }

      this.showNotes[accountConnectionId] =
        !this.showNotes[accountConnectionId];
    },
    toggleActions() {
      this.showActions = !this.showActions;
      if (this.showButtons === false) {
        this.showButtons = true;
      }
      if (this.showChangePercentAll) {
        this.showChangePercentAll = false;
      }
      if (this.showChangePercentIndividual) {
        this.showChangePercentIndividual = false;
      }
      if (this.showChangePercentIndividualStep2) {
        this.showChangePercentIndividualStep2 = false;
      }
      if (this.showCopyAllBudget) {
        this.showCopyAllBudget = false;
      }
      if (this.showCopyIndividualBudgetStep1) {
        this.showCopyIndividualBudgetStep1 = false;
      }
      if (this.showCopyIndividualBudgetStep2) {
        this.showCopyIndividualBudgetStep2 = false;
      }
      if (this.showCopyAllOutcome) {
        this.showCopyAllOutcome = false;
      }
      if (this.showCopyIndividualOutcomeStep1) {
        this.showCopyIndividualOutcomeStep1 = false;
      }
      if (this.showCopyIndividualOutcomeStep2) {
        this.showCopyIndividualOutcomeStep2 = false;
      }
      this.selectedAccounts = [];
      this.selectedFields = [];
    },
    toggleModalChangeAll() {
      this.showButtons = false;
      this.showChangePercentAll = true;
    },
    toggleModalChangeIndividual() {
      this.showButtons = false;
      this.showChangePercentIndividual = true;
    },
    toggleModalCopyAllBudget() {
      this.showButtons = false;
      this.showCopyAllBudget = true;
    },
    toggleModalCopyIndividualBudget() {
      this.showButtons = false;
      this.showCopyIndividualBudgetStep1 = true;
    },
    toggleModalCopyAllOutcome() {
      this.showButtons = false;
      this.showCopyAllOutcome = true;
    },
    toggleModalCopyIndividualOutcome() {
      this.showButtons = false;
      this.showCopyIndividualOutcomeStep1 = true;
    },
    selectAccount(a) {
      if (!this.selectedAccounts.includes(a.accountConnectionId)) {
        this.selectedAccounts.push(a.accountConnectionId);
      } else {
        var filteredArray = this.selectedAccounts.filter(function (e) {
          return e !== a.accountConnectionId;
        });
        this.selectedAccounts = filteredArray;
      }
    },
    selectedAccount(a) {
      return {
        accountSelected: this.selectedAccounts.includes(a.accountConnectionId),
      };
    },
    showStep2() {
      this.showChangePercentIndividual = false;
      this.showChangePercentIndividualStep2 = true;
    },
    toggle(accountConnectionId) {
      if (this.clickedItems.includes(accountConnectionId)) {
        this.clickedItems.splice(
          this.clickedItems.indexOf(accountConnectionId),
          1
        );
      } else {
        this.clickedItems.push(accountConnectionId);
      }
    },
    toggleActionChangePercent(accountConnectionId) {
      this.showActionDistribute = [];
      if (this.showActionChangePercent.includes(accountConnectionId)) {
        this.showActionChangePercent.splice(
          this.showActionChangePercent.indexOf(accountConnectionId),
          1
        );
      } else {
        this.showActionChangePercent = [];
        this.percentage = 0;
        this.showActionChangePercent.push(accountConnectionId);
      }
    },
    toggleActionDistribute(accountConnectionId) {
      this.showActionChangePercent = [];
      if (this.showActionDistribute.includes(accountConnectionId)) {
        this.showActionDistribute.splice(
          this.showActionDistribute.indexOf(accountConnectionId),
          1
        );
        this.amountToDistribute = '';
        this.distributeFromMonth = undefined;
        this.distributeToMonth = '';
      } else {
        this.showActionDistribute.push(accountConnectionId);
      }
    },
    changeValues(a, type) {
      if (type === 'account') {
        a.months.forEach(element => {
          element.value =
            Math.ceil(
              (element.value + (element.value * this.percentage) / 100) / 100
            ) * 100;
        });
        this.accountSum(a);
        this.showActionChangePercent = [];
      } else if (type === 'budgetGroup') {
        a.resultGroups.forEach(group => {
          group.accountConnections.forEach(account => {
            if (this.selectedAccounts.includes(account.accountConnectionId)) {
              account.months.forEach(month => {
                month.value =
                  Math.ceil(
                    (month.value + (month.value * this.percentage) / 100) / 100
                  ) * 100;
              });
            }
            this.accountSum(account);
          });
        });
        this.toggleActions(0);
      }
      this.percentage = 0;
    },

    changeAllValues(budgetGroup) {
      budgetGroup.resultGroups.forEach(group => {
        group.accountConnections.forEach(account => {
          account.months.forEach(month => {
            month.value =
              Math.ceil(
                (month.value + (month.value * this.percentage) / 100) / 100
              ) * 100;
          });
          this.accountSum(account);
        });
      });
      this.toggleActions(0);
    },

    distributeAmount(a) {
      if (this.distributeType === 2) {
        let months = a.months;
        let selectedMonths = [];
        months.forEach((element, index) => {
          if (
            index >= this.distributeFromMonth &&
            index <= this.distributeToMonth
          ) {
            selectedMonths.push(element);
          }
        });
        let amount = this.amountToDistribute / selectedMonths.length;
        months.forEach(element => {
          selectedMonths.forEach(month => {
            if (element.month.includes(month.month)) {
              element.value = Math.ceil(amount / 100) * 100;
            }
          });
        });
      } else if (this.distributeType === 1) {
        a.months.forEach((month, index) => {
          this.distributionTemplate.forEach((templateValue, templateIndex) => {
            if (index === templateIndex) {
              month.value =
                Math.ceil(
                  (this.amountToDistribute * templateValue) / 100 / 100
                ) * 100;
            }
          });
        });
      }
      this.accountSum(a);
      this.showActionDistribute = [];
      this.amountToDistribute = '';
      this.distributeFromMonth = undefined;
      this.distributeToMonth = '';
    },

    connectionsCompleted(budgetGroup) {
      let count = 0;
      budgetGroup.resultGroups.forEach(element => {
        element.accountConnections.forEach(account => {
          if (account.completed) {
            count++;
          }
        });
      });
      return count;
    },

    connectionsCount(budgetGroup) {
      let count = 0;
      budgetGroup.resultGroups.forEach(element => {
        count += element.accountConnections.length;
      });
      return count;
    },

    copyValues(type, column) {
      // make cleaner
      this.budgetGroup.resultGroups.forEach(group => {
        group.accountConnections.forEach(account => {
          account.months.forEach(month => {
            let preBg;
            if (column === 'budget') {
              preBg = this.preBudget.budgetGroups.filter(
                bg => this.budgetGroup.name === bg.name
              );
            } else if (column === 'outcome') {
              preBg = this.preOutcome.budgetGroups.filter(
                bg => this.budgetGroup.name === bg.name
              );
            }
            const preAccountConnections = preBg[0].resultGroups.filter(
              g => g.name === group.name
            );
            const preAccount =
              preAccountConnections[0].accountConnections.filter(
                a => a.name === account.name
              );
            const preMonth = preAccount[0].months.filter(
              m => m.name === month.name
            );
            if (type === 'individual') {
              if (
                preAccount[0].accountConnectionId ===
                account.accountConnectionId
              ) {
                if (
                  this.copyAllFieldsRadioValue === 1 &&
                  this.selectedFields.includes(account.accountConnectionId)
                ) {
                  month.value = preMonth[0].value;
                } else if (
                  this.copyAllFieldsRadioValue === 2 &&
                  this.selectedFields.includes(account.accountConnectionId)
                ) {
                  month.value =
                    Math.ceil(
                      (preMonth[0].value +
                        (preMonth[0].value * this.percentage) / 100) /
                        100
                    ) * 100;
                }
              }
            } else if (type === 'all') {
              if (this.copyAllFieldsRadioValue === 1) {
                month.value = preMonth[0].value;
              } else if (this.copyAllFieldsRadioValue === 2) {
                month.value =
                  Math.ceil(
                    (preMonth[0].value +
                      (preMonth[0].value * this.percentage) / 100) /
                      100
                  ) * 100;
              }
            }
          });
          this.accountSum(account);
        });
      });
      this.percentage = 0;
      this.toggleActions(0);
    },
    showStep2CopyBudgetFields() {
      this.showCopyIndividualBudgetStep1 = false;
      this.showCopyIndividualBudgetStep2 = true;
    },
    selectBudgetField(a) {
      if (
        !(
          this.showCopyIndividualBudgetStep1 ||
          this.showCopyIndividualOutcomeStep1
        )
      )
        return;
      if (!this.selectedFields.includes(a.accountConnectionId)) {
        this.selectedFields.push(a.accountConnectionId);
      } else {
        var filteredArray = this.selectedFields.filter(function (e) {
          return e !== a.accountConnectionId;
        });
        this.selectedFields = filteredArray;
      }
    },
    showStep2CopyOutcomeFields() {
      this.showCopyIndividualOutcomeStep1 = false;
      this.showCopyIndividualOutcomeStep2 = true;
    },
    accountSum(account, resultGroup) {
      // var sum = parseInt(account.months.reduce((acc, item) => acc + item.value, 0))
      let sum = 0;
      account.months.forEach(month => {
        sum += parseInt(month.value);
      });
      account.value = sum;

      this.budgetGroupSum();
    },
    budgetGroupSum() {
      let sum = 0;
      this.budgetGroup.resultGroups.forEach(group => {
        group.accountConnections.forEach(account => {
          sum += account.value;
        });
      });
      this.budgetGroup.value = sum;
      this.$emit('updatedGroups', this.budgetGroup);
    },
    toggleResultGroup(index) {
      this.$set(this.showResultGroup, index, !this.showResultGroup[index]);
    },
    changeMonthValue(index, ai, mi, e) {
      this.budgetGroup.resultGroups[index].accountConnections[ai].months[
        mi
      ].value = parseInt(e.target.value.replace(/[^\d^-]/g, '')) || 0;
      this.accountSum(
        this.budgetGroup.resultGroups[index].accountConnections[ai]
      );
    },
    resetValues(index, ai) {
      if (confirm('Vill du sätta noll på alla månader?')) {
        for (
          let mi = 0;
          mi <
          this.budgetGroup.resultGroups[index].accountConnections[ai].months
            .length;
          mi++
        ) {
          this.budgetGroup.resultGroups[index].accountConnections[ai].months[
            mi
          ].value = 0;
          this.accountSum(
            this.budgetGroup.resultGroups[index].accountConnections[ai]
          );
        }
      }
    },
    resultGroupCompleteAll(e, i) {
      for (
        let ai = 0;
        ai < this.budgetGroup.resultGroups[i].accountConnections.length;
        ai++
      ) {
        this.budgetGroup.resultGroups[i].accountConnections[ai].completed =
          e.target.checked;
      }
    },
  },

  computed: {
    previousBudgetGroup() {
      let budget;
      if (this.preBudget) {
        this.preBudget.budgetGroups.forEach(element => {
          if (element.name === this.budgetGroup.name) {
            budget = element;
          }
        });
      }

      return budget;
    },
    preBudgetGroupValue() {
      let value;
      this.preBudget.budgetGroups.forEach(group => {
        if (group.name === this.budgetGroup.name) {
          value = group.value;
        }
      });
      return value;
    },
    previousOutcome() {
      let outcome;
      this.preOutcome.budgetGroups.forEach(element => {
        if (element.name === this.budgetGroup.name) {
          outcome = element;
        }
      });
      return outcome;
    },
    preOutcomeValue() {
      let value;
      this.preOutcome.budgetGroups.forEach(group => {
        if (group.name === this.budgetGroup.name) {
          value = group.value;
        }
      });
      return value;
    },
    distributionTemplates() {
      return this.$store.getters['businessboardBudget/distributionTemplates'];
    },
    shouldDimBudget() {
      /*
        this.showChangePercentAll
        this.showChangePercentIndividual
        this.showChangePercentIndividualStep2
        this.showCopyAllBudget
        this.showCopyIndividualBudgetStep1
        this.showCopyIndividualBudgetStep2
        this.showCopyAllOutcome
        this.showCopyIndividualOutcomeStep1
        this.showCopyIndividualOutcomeStep2
      */
      return (
        this.showCopyIndividualBudgetStep1 ||
        this.showCopyIndividualBudgetStep2 ||
        this.showCopyIndividualOutcomeStep1 ||
        this.showCopyIndividualOutcomeStep2
      );
    },
    shouldDimPreBudget() {
      return (
        this.showChangePercentIndividual ||
        this.showChangePercentIndividualStep2 ||
        this.showCopyIndividualOutcomeStep1 ||
        this.showCopyIndividualOutcomeStep2
      );
    },
    shouldDimOutcome() {
      return (
        this.showChangePercentIndividual ||
        this.showChangePercentIndividualStep2 ||
        this.showCopyIndividualBudgetStep1 ||
        this.showCopyIndividualBudgetStep2
      );
    },
  },
  created() {
    this.$store
      .dispatch('businessboardBudget/fetchDistributionTemplates')
      .then(() => {});
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: color(bb-primary);
  color: color(white);
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left_header {
    width: 30%;

    :last-child {
      float: right;
    }
  }

  .h2 {
    font-size: 24px;
    font-weight: bold;
  }

  .small {
    font-size: 18px;
    line-height: 24px;
  }

  .h3 {
    width: 50%;
    text-align: right;
  }

  button {
    color: white;
    background-color: color(bb-primary);
    margin: auto 0;
    border-radius: 50%;
    border: none;
  }

  .svg {
    margin-top: 10px;
  }
}

.category-content {
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    margin-bottom: 15px;
    margin-top: 10px;
    height: 2.5rem;
    align-items: center;

    .action-button {
      border: none;
      background-color: white;
      color: color(blue);
      margin: 15px 15px;

      :hover {
        color: color(blue-dark);
      }

      :active {
        color: color(blue);
      }

      img {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }

  &__actions-box {
    min-width: 200px;
    max-width: 300px;
    border: 1px solid #efefef;
    background-color: white;
    position: absolute;
    padding: 10px;
    box-shadow: 4px 4px 4px 0px #00000026;
    right: 15%;
    top: 30px;
    z-index: 3;

    .buttonActionChoice {
      border: none;
      background-color: transparent;
      padding: 5px 0px;
      margin: auto 0;

      span {
        margin-left: 10px;
      }

      :hover {
        font-weight: bolder;
      }
    }
  }

  &__column-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5%;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 10px 0px;
    }

    .h2 {
      font-size: 24px;
    }

    .h3 {
      font-weight: bold;
    }
  }

  &__column-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  &__group {
    margin-bottom: 20px;
  }

  &__group-heading {
    cursor: pointer;

    .svg {
      float: right;
      margin-top: 10px;
    }
  }

  &__account-wrapper {
    margin: 10px 0;
  }

  &__account-heading {
    font-size: 18px;
    font-weight: bold;
  }

  &__account-heading-right {
    float: right;
    font-size: 18px;
    margin-right: 1rem;
  }

  &__account-sum-row {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__account-checkbox {
    margin-right: 10px;
  }

  &__select-account-button {
    position: absolute;
    height: 100%;
    width: 100%;
    border: none;
    background-color: transparent;
  }

  &__account-sum {
    width: 100%;
    height: 40px;
    border: 1px solid color(gray);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #efefef;

    span {
      margin: auto 0 auto auto;
      padding-right: 10px;
    }

    div {
      border-left: 1px solid color(gray);
      height: 38px;
      width: 38px;
      background-color: color(gray-light);
      padding: 4px 2px;
    }

    button {
      margin: auto 0;
      border-radius: 50%;
      border: 1px solid color(gray);

      .svg {
        margin-top: 10px;
      }
    }
  }

  &__account-data {
    background-color: color(gray-light);
    padding: 10px 20px;
    min-height: 31.4rem;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid #d8d8d8;
    border-top: none;
  }
  &__account-inputs-spacer {
    height: 70px;
  }

  &__account-inputs-note-spacer {
    height: 255px;
  }

  &__account-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 5px;
    margin: 15px 30px;
  }

  &__action-button {
    border: none;
    background-color: transparent;
    color: color(blue);
    margin-bottom: 15px;

    :hover {
      color: color(blue-dark);
    }

    :active {
      color: color(blue);
    }

    .svg {
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  &__action-window {
    border: 1px solid #efefef;
    max-width: 300px;
    min-height: 100px;
    min-width: 100px;
    background-color: white;
    position: absolute;
    margin-top: 20px;
    padding: 10px;
    right: -100px;
    box-shadow: 4px 4px 4px 0px #00000026;
    z-index: 3;

    input {
      height: 40px;
      border: 1px solid color(gray);
    }

    label {
      font-weight: bold;
    }
  }

  &__input-amount {
    width: 100%;
    margin-bottom: 20px;
  }

  &__distribute-radio-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  &__distribute-radio-button {
    width: 10%;
  }

  &__month-select-container {
    display: flex;
    flex-direction: column;
  }

  &__month-select {
    min-width: 50px;
    margin-right: 20px;
    height: 40px;
    border: 1px solid color(gray);
  }

  &__template-select {
    width: 90%;
    border: 1px solid color(gray);
  }

  &__account-input-row {
    display: grid;
    grid-template-columns: 30% 65% 5%;
    margin-bottom: 10px;
    max-width: 100%;
    overflow: hidden;

    span {
      margin-right: 10px;
      text-align: right;
    }

    input {
      border: 1px solid color(gray);
      height: 25px;
      text-align: right;
    }

    .month_info {
      margin: auto;
      cursor: help;
      height: 1rem;
      width: 1rem;
      display: inline-block;
      text-align: center;
      border: 1px solid black;
      border-radius: 50%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: 10px;
      height: 30px;
    }
  }

  &__save {
    height: 30px;
    margin-right: 0px;
    margin-left: auto;
  }
}

.accountSelected {
  background-color: color(bb-sec4);
}

.radio-heading {
  display: inline-block;
  margin-left: 10px;
}

.copy-svg {
  height: 75%;
  margin: auto 0;
}

.dim {
  opacity: 0.4;
}

.button--reset {
  margin-right: 10px;
}
.comment-indicator {
  position: relative;
  display: inline-flex;
  width: 20px;
  height: 20px;
}

.pin-icon {
  position: absolute;
  width: 100%;
  height: 100%;
}

.account-label {
  display: flex;
  align-items: center;
  justify-items: center;
}

.bold-text span {
  font-weight: bold;
  font-size: 18px;
}

.note-button {
  line-height: 5;
  display: flex;
  align-items: center;
  gap: 3px;
}
</style>
