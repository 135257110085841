<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div class="businessboard--header">
        <modal-window
          :show="showDownloadModal"
          size="small">
          <div class="download_modal_content">
            <div class="download_modal_header">
              <strong>{{ $t('chooseFormatForDownload') }}</strong>
              <div
                class="close"
                @click="showDownloadModal = false">
                ✕
              </div>
            </div>
            <div
              :class="['option', { option_selected: chosenFormat == 0 }]"
              @click="chosenFormat = '0'">
              <strong>JPG</strong>
              <span class="subtitle">({{ $t('whiteBackground') }})</span>
              <div class="checkbox">
                <use-svg
                  v-show="chosenFormat == 0"
                  svg="bb-selected-icon" />
              </div>
            </div>
            <div
              :class="['option', { option_selected: chosenFormat == 1 }]"
              @click="chosenFormat = '1'">
              <strong>PNG</strong>
              <span class="subtitle">({{ $t('transparentBackground') }})</span>
              <div class="checkbox">
                <use-svg
                  v-show="chosenFormat == 1"
                  svg="bb-selected-icon" />
              </div>
            </div>
            <div class="download-button-div">
              <base-button
                @click="download()"
                class="downloadButton"
                :text="$t('download')"
                backgroundColor="bb-primary">
                <img
                  src="@/components/svg/bb-download-icon.svg"
                  class="download-white-icon"
                  alt="" />
              </base-button>
            </div>
          </div>
        </modal-window>
        <span class="h1">{{
          id === 'consolidatedaccounts'
            ? $t('Businessboard.totalbusiness')
            : incomestatement.name
        }}</span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
        <last-updated
          :last-update="dashboard.lastUpdates"
          :show-member="true"
          :show-game="true"
          :show-economy="true"
          :show-warning="haveNonConnectedAccounts" />
      </div>
      <div class="info_text">
        <p v-if="id === 'consolidatedaccounts'">
          {{ $t('accountInfo1') }}
        </p>
        <p v-else>
          {{ $t('accountInfo2') }}
        </p>
      </div>
      <div class="chart_settings">
        <div
          v-if="showChartSettings"
          class="chart_settings_modal">
          <span>{{ $t('diagramSettings') }}</span>
          <hr />
          <template v-if="id === 'consolidateedaccounts'">
            <strong>{{ $t('show') }}</strong>
            <div class="drillDownChoice">
              <div
                :class="['choice', { selected: combineCompanies }]"
                @click="combineCompanies = true">
                {{ $t('totalEstablishment') }}
                <div v-if="combineCompanies">
                  <use-svg svg="bb-selected-icon" />
                </div>
              </div>
              <div
                :class="['choice', { selected: !combineCompanies }]"
                @click="combineCompanies = false">
                {{ $t('dividedByCompany') }}
                <div v-if="!combineCompanies">
                  <use-svg svg="bb-selected-icon" />
                </div>
              </div>
            </div>
          </template>

          <strong>{{ $t('currentYear') }}</strong>
          <custom-select
            @optionSelected="e => selectCurrentYear(e)"
            :selected="prevYears.map(y => y.value).indexOf(currentYear)"
            :options="prevYears">
          </custom-select>

          <strong>{{ $t('periodFiscalYear') }}</strong>
          <div class="period_input">
            <custom-select
              @optionSelected="e => selectColumnPeriod(e)"
              :selected="columnPeriods.map(y => y.value).indexOf(columnPeriod)"
              :options="columnPeriods">
            </custom-select>
          </div>

          <div class="period_grid2">
            <span class="column_section">{{ $t('chooseStartEndMonth') }}</span>
            <div class="selectMonth">
              <div
                v-for="i in 12"
                :key="i"
                :class="{
                  selected: startMonth == i || endMonth == i,
                  highlighted: i > startMonth && i < endMonth,
                }"
                @click="selectMonth(i)">
                {{ periodMonths[i] }}
              </div>
            </div>
          </div>

          <strong>{{ $t('comparisonYear') }}</strong>
          <custom-select
            @optionSelected="e => selectYear(e)"
            :selected="
              prevYears
                .slice(1)
                .map(y => y.value)
                .indexOf(selectedYear)
            "
            :options="prevYears.slice(1)">
          </custom-select>

          <hr />
          <button @click="showYear()">{{ $t('showTemporarily') }}</button>
          <info-icon :description="$t('showTemporarilyInfo')">
            <template #icon>
              <use-svg svg="bb-info-icon" />
            </template>
          </info-icon>
          <button
            v-if="showSaveAndPublish"
            class="btn_primary"
            @click="saveChartFilter()">
            {{ $t('saveAndPublish') }}
          </button>
          <br />
          <button @click="showChartSettings = false">{{ $t('cancel') }}</button>
        </div>
        <base-button
          @click="showChartSettings = !showChartSettings"
          backgroundColor="bb-primary"
          :text="$t('diagramSettings')">
          <use-svg svg="bb-gear-icon" />
        </base-button>
      </div>
      <template v-if="showDashboard">
        <div
          class="charts_section"
          v-if="dashboard.income">
          <div :class="{ modal: maximized1 }">
            <div :class="['chart_section', { maximized: maximized1 }]">
              <div>
                <div class="header">
                  <h3>{{ $t('revenue') }}</h3>
                  <diagram-kebab-menu
                    v-if="dashboard.income"
                    elementID="Intäkter"
                    :enlargeOption="true"
                    @downloadSettings="e => setDownloadSettings(e)"
                    @maximizedSetting="e => setMaximized1(e)" />
                </div>
                <div
                  id="Intäkter"
                  :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
                  <chart-legend :data="dashboard.income"> </chart-legend>
                  <bar-chart
                    v-if="!isLoading"
                    :styles="{ height: maximized1 ? '32rem' : '12rem' }"
                    :fullscreen="maximized1"
                    :data="dashboard.income"
                    :labels="
                      months
                        .slice(1)
                        .map(str => str.charAt(0).toUpperCase() + str.slice(1))
                    " />
                  <bar-chart-loader v-if="isLoading" />
                </div>
              </div>
              <comment
                @postedComment="postedComment"
                :comment="dashboard.income.comment"
                :lastUpdate="lastUpdate"
                :section="0"
                :companyId="this.id"
                :canEdit="showSaveAndPublish" />
            </div>
          </div>
          <div :class="{ modal: maximized2 }">
            <div :class="['chart_section', { maximized: maximized2 }]">
              <div>
                <div class="header">
                  <h3>
                    {{ $t('costs') }}
                  </h3>
                  <diagram-kebab-menu
                    v-if="dashboard.income"
                    elementID="kostnader"
                    :enlargeOption="true"
                    @downloadSettings="e => setDownloadSettings(e)"
                    @maximizedSetting="e => setMaximized2(e)" />
                </div>
                <div
                  id="kostnader"
                  :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
                  <chart-legend :data="dashboard.income"> </chart-legend>
                  <bar-chart
                    v-if="!isLoading"
                    :styles="{ height: maximized2 ? '32rem' : '12rem' }"
                    :fullscreen="maximized2"
                    :data="dashboard.costs"
                    :labels="
                      months
                        .slice(1)
                        .map(str => str.charAt(0).toUpperCase() + str.slice(1))
                    " />
                  <bar-chart-loader v-if="isLoading" />
                </div>
              </div>
              <comment
                @postedComment="postedComment"
                :comment="dashboard.costs.comment"
                :lastUpdate="lastUpdate"
                :section="1"
                :companyId="this.id"
                :canEdit="showSaveAndPublish" />
            </div>
          </div>
          <div class="chart_section">
            <div>
              <diagram-kebab-menu
                elementID="budget"
                :enlargeOption="false"
                @downloadSettings="e => setDownloadSettings(e)" />
            </div>
            <div
              :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
              class="donuts"
              id="budget">
              <div class="donut">
                <div>
                  <strong>
                    {{ $t('revenue') }}<br />
                    {{ $t('outcomeLower') }}<br />
                    {{ months[dashboard.periodStartMonth] }}-{{
                      months[dashboard.periodEndMonth]
                    }}
                  </strong>
                </div>
                <div style="font-size: 12px">
                  {{ `${$t('comparedTo')} ${$t('Businessboard.budget')}` }}
                </div>
                <div class="chart">
                  <donut-chart
                    v-if="!isLoading"
                    :sum1="incomeResultSum"
                    :sum2="incomeBudgetSum" />
                  <pie-chart-loader v-if="isLoading" />
                </div>
                <div class="donut_kr">
                  <use-svg
                    svg="bb-arrow-icon"
                    :class="{
                      'svg--180-negative': incomeResultSum < incomeBudgetSum,
                    }" />
                  {{ krFormat(incomeResultSum, incomeBudgetSum) }}
                </div>
              </div>

              <div class="donut">
                <div>
                  <strong>
                    {{ $t('costs') }}<br />
                    {{ $t('outcomeLower') }}<br />
                    {{ months[dashboard.periodStartMonth] }}-{{
                      months[dashboard.periodEndMonth]
                    }}
                  </strong>
                </div>
                <div style="font-size: 12px">
                  {{
                    `${$t('comparedTo')} ${$t(
                      'Businessboard.budget'
                    ).toLowerCase()}`
                  }}
                </div>
                <div class="chart">
                  <donut-chart
                    v-if="!isLoading"
                    :sum1="costResultSum"
                    :sum2="costBudgetSum"
                    :inverse="true" />
                  <pie-chart-loader v-if="isLoading" />
                </div>
                <div class="donut_kr">
                  <use-svg
                    svg="bb-arrow-icon"
                    :class="{
                      'svg--180-negative': costResultSum > costBudgetSum,
                    }" />
                  {{ krFormat(costResultSum, costBudgetSum) }}
                </div>
              </div>
              <div class="donut">
                <div>
                  <strong>
                    {{ $t('forecastFor') }}<br />
                    {{ $t('resultLower') }}<br />
                    {{ dashboard.filter.currentYear }}
                  </strong>
                </div>
                <div style="font-size: 12px">
                  {{
                    `${$t('comparedTo')} ${$t(
                      'Businessboard.budget'
                    ).toLowerCase()} `
                  }}
                </div>
                <div class="chart">
                  <donut-chart
                    v-if="!isLoading"
                    :sum1="
                      forecastBudgetSum < 0
                        ? 2 * forecastBudgetSum - forecastResultSum
                        : forecastResultSum
                    "
                    :sum2="forecastBudgetSum"
                    :invert="false" />
                  <pie-chart-loader v-if="isLoading" />
                </div>
                <div class="donut_kr">
                  <use-svg
                    svg="bb-arrow-icon"
                    :class="{
                      'svg--180-negative':
                        forecastResultSum < forecastBudgetSum,
                    }" />
                  {{ krFormat(forecastResultSum, forecastBudgetSum) }}
                </div>
              </div>
            </div>
            <comment
              @postedComment="postedComment"
              :comment="dashboard.donutComment"
              :lastUpdate="lastUpdate"
              :section="2"
              :companyId="this.id"
              :canEdit="showSaveAndPublish" />
          </div>
        </div>
      </template>
      <template v-if="storedFilter">
        <modal-filter
          v-if="id === 'consolidatedaccounts'"
          @changeFilter="changeFilter"
          @saveFilter="saveFilter"
          :storedFilter="storedFilter"
          :drillDownRadioValues="[
            [4, $t('month')],
            [2, $t('Businessboard.companies')],
            [5, $t('account')],
          ]"
          :financialYears="financialYears" />
        <modal-filter
          v-else
          @changeFilter="changeFilter"
          @saveFilter="saveFilter"
          :storedFilter="storedFilter"
          :drillDownRadioValues="[
            [3, $t('account')],
            [1, $t('month')],
          ]"
          :financialYears="financialYears" />
      </template>
      <div
        v-if="incomestatement && incomestatement.incomes"
        style="float: right; display: inline; margin-bottom: 0.3rem">
        <base-button
          @click="copyTableData"
          backgroundColor="white"
          :text="$t(copyDataText)">
          <use-svg svg="bb-copy-icon" />
        </base-button>
      </div>
      <div class="section">
        <diagram-kebab-menu
          class="move-kebab-menu"
          v-if="dashboard.incomeGroups"
          elementID="rörelsens-intäkter"
          :enlargeOption="false"
          @downloadSettings="e => setDownloadSettings(e)" />
        <h3>{{ $t('movementRevenue') }}</h3>
        <div
          class="chart"
          v-if="dashboard.incomeGroups">
          <div
            id="rörelsens-intäkter"
            :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
            <pie-filterable
              v-if="!isLoading"
              :styles="{ height: '24rem', maxWidth: '80rem' }"
              :data="dashboard.incomeGroups" />
            <pie-chart-loader v-if="isLoading" />
          </div>
          <div>
            <comment
              @postedComment="postedComment"
              :comment="dashboard.incomeGroups.comment"
              :lastUpdate="lastUpdate"
              :section="3"
              :companyId="this.id"
              :canEdit="showSaveAndPublish" />
          </div>
        </div>
        <section
          class="businessboard--table"
          v-if="incomestatement && incomestatement.incomes">
          <list-table
            :isLoading="isLoading"
            :items="incomestatement.incomes.rows"
            :headers="incomestatement.incomes.columns">
          </list-table>
        </section>
      </div>
      <div class="section">
        <diagram-kebab-menu
          class="move-kebab-menu"
          v-if="dashboard.costGroups"
          elementID="rörelsens-kostnader"
          :enlargeOption="false"
          @downloadSettings="e => setDownloadSettings(e)" />
        <h3>{{ $t('operatingExpenses') }}</h3>
        <div
          class="chart"
          v-if="dashboard.costGroups">
          <div
            id="rörelsens-kostnader"
            :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
            <pie-filterable
              v-if="!isLoading"
              :styles="{ height: '24rem', maxWidth: '80rem' }"
              :data="dashboard.costGroups" />
            <pie-chart-loader v-if="isLoading" />
          </div>
          <div>
            <comment
              @postedComment="postedComment"
              :comment="dashboard.costGroups.comment"
              :lastUpdate="lastUpdate"
              :section="4"
              :companyId="this.id"
              :canEdit="showSaveAndPublish" />
          </div>
        </div>
        <section
          class="businessboard--table"
          v-if="incomestatement && incomestatement.costs">
          <list-table
            :isLoading="isLoading"
            :items="incomestatement.costs.rows"
            :headers="incomestatement.costs.columns">
          </list-table>
        </section>
      </div>

      <div class="section">
        <section
          class="businessboard--table"
          v-if="incomestatement && incomestatement.deprecations">
          <list-table
            :items="incomestatement.deprecations.rows"
            :headers="incomestatement.deprecations.columns">
          </list-table>
        </section>
        <section
          class="businessboard--table"
          v-if="incomestatement && incomestatement.preDispositionResult">
          <list-table
            :isLoading="isLoading"
            :items="incomestatement.preDispositionResult.rows"
            :headers="incomestatement.preDispositionResult.columns">
          </list-table>
        </section>
        <section
          class="businessboard--table"
          v-if="incomestatement && incomestatement.dispositionResult">
          <list-table
            :isLoading="isLoading"
            :items="incomestatement.dispositionResult.rows"
            :headers="incomestatement.dispositionResult.columns">
          </list-table>
        </section>
      </div>
      <div class="section">
        <section
          class="businessboard--table"
          v-if="incomestatement && incomestatement.result">
          <list-table
            :isLoading="isLoading"
            :items="incomestatement.result.rows"
            :headers="incomestatement.result.columns">
          </list-table>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthsLabelsFull, MonthsLabelsShort } from '@/core/consts';

import ListTable from '@/components/list-table/list-table-game-calculation';
import ModalFilter from '@/components/modal/modal-filter-fiscal-premium';
import BarChart from '@/components/charts/test-bar-chart';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import CustomSelect from '@/components/form-elements/custom-select-bb';
import InfoIcon from '@/components/businessboard/tooltip-icon';
import Comment from '@/components/businessboard/comment';
import PieFilterable from '@/components/charts/pie-chart-filterable2';
import DonutChart from '@/components/charts/donut-chart';
import ChartLegend from '@/components/charts/legend-test';
import ModalWindow from '@/components/modal/modal';
import { toPng, toJpeg } from 'html-to-image';
import diagramKebabMenu from '@/components/businessboard/diagram-kebab-menu';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import LocalizationMixinVue from '@/mixins/localization-mixin.vue';
import PieChartLoader from '@/components/reports-templates/pie-chart-loader.vue';
import BarChartLoader from '@/components/reports-templates/table-chart-loader.vue';

export default {
  name: 'BusinessboardIncomestatementPremium',
  mixins: [LocalizationMixinVue],
  components: {
    ListTable,
    ModalFilter,
    BarChart,
    BaseButton,
    UseSvg,
    CustomSelect,
    InfoIcon,
    Comment,
    PieFilterable,
    DonutChart,
    ChartLegend,
    ModalWindow,
    diagramKebabMenu,
    LastUpdated,
    PieChartLoader,
    BarChartLoader,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      isLoading: false,
      storedFilter: {},
      showChartSettings: false,
      maximized1: false,
      maximized2: false,
      showDownloadModal: false,
      diagramId: '',
      chosenFormat: '0',
      prevYears: [
        { displayName: '2024', value: 2024 },
        { displayName: '2023', value: 2023 },
        { displayName: '2022', value: 2022 },
        { displayName: '2021', value: 2021 },
        { displayName: '2020', value: 2020 },
        { displayName: '2019', value: 2019 },
        { displayName: '2018', value: 2018 },
      ],
      currentYear: 2023,
      selectedYear: 2022,
      arcPercentage: 20,
      showDashboard: true,
      incomeResultSum: 0,
      incomeBudgetSum: 0,
      costResultSum: 0,
      costBudgetSum: 0,
      forecastResultSum: 0,
      forecastBudgetSum: 0,
      periodMonths: [''].concat(
        MonthsLabelsFull.map(l => this.$t(`Months.${l}`))
      ),
      months: [''].concat(
        MonthsLabelsShort.map(l => this.$t(`MonthsShort.${l}`))
      ),
      combineCompanies: false,
      columnPeriod: 'accumulated',
      startMonth: 0,
      endMonth: 0,
      copyDataText: 'copyTableData',
      lastUpdate: 0,
      periodCurrentMonthIndex: new Date().getMonth(),
    };
  },
  computed: {
    incomestatement() {
      return this.$store.getters['businessboard/incomestatement'];
    },
    dashboard() {
      return this.$store.getters['businessboard/dashboardIncomestatement'];
    },
    financialYears() {
      return this.$store.getters['businessboardBudget/financialYears'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    haveNonConnectedAccounts() {
      return this.$store.getters['businessboard/havenonconnectedaccounts'];
    },
    columnPeriods() {
      return [
        { value: 'accumulated', displayName: this.$t('accumulated') },
        { value: 'not_fiscal', displayName: this.$t('notFiscalYear') },
      ];
    },
    showSaveAndPublish() {
      return !(
        this.user.roles.BusinessBoard &&
        this.user.roles.BusinessBoard.some(
          e => e.value === 'BusinessBoardBoardMember'
        )
      );
    },
    chartFilter() {
      let filter = {
        CurrentYear: this.currentYear,
        ComparisonYear: this.selectedYear,
        combineCompanies: this.combineCompanies,
      };
      if (this.id !== 'consolidatedaccounts') {
        filter.CompanyExternalId = this.id;
      }
      if (this.columnPeriod === 'not_fiscal') {
        if (this.dashboard.financialYearStartMonth > 0) {
          filter.periodStartMonth =
            ((this.startMonth + this.dashboard.financialYearStartMonth - 2) %
              12) +
            1;
          filter.periodEndMonth =
            ((this.endMonth + this.dashboard.financialYearStartMonth - 2) %
              12) +
            1;
        } else {
          filter.periodStartMonth = this.startMonth;
          filter.periodEndMonth = this.endMonth;
        }
      }
      return filter;
    },
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
  },
  mounted() {
    this.$store.dispatch('businessboardBudget/fetchFinancialYears');
    this.$store.commit('businessboard/dashboardIncomestatement', {});
    if (this.id === 'consolidatedaccounts') {
      this.$store.dispatch('businessboard/postIncomestatementDashboard', {});
      this.$store
        .dispatch('businessboard/fetchIncomestatementFilter')
        .then(() => {
          this.storedFilter =
            this.$store.state.businessboard.incomestatementFilter;
          this.fetch();
        });
    } else {
      this.$store.dispatch('businessboard/postIncomestatementDashboard', {
        CompanyExternalId: this.id,
      });
      this.$store
        .dispatch('businessboard/fetchIncomestatementCompanyFilter', this.id)
        .then(() => {
          this.storedFilter =
            this.$store.state.businessboard.incomestatementCompanyFilter;
          this.fetch();
        });
    }
    this.$store.dispatch('businessboard/fetchHaveNonConnectedAccounts');
  },
  watch: {
    $route: function () {
      this.fetch();
    },
    dashboard: function (newVal, oldVal) {
      this.incomeResultSum = 0;
      this.incomeBudgetSum = 0;
      this.costResultSum = 0;
      this.costBudgetSum = 0;
      this.forecastResultSum = 0;
      this.forecastBudgetSum = 0;

      if (!newVal.income) return;
      for (
        let i = newVal.periodStartMonth - 1;
        i < newVal.periodEndMonth;
        i++
      ) {
        this.incomeResultSum += newVal.income.totalResult.values[i];
        this.incomeBudgetSum += newVal.income.budget.values[i];
        this.costResultSum += newVal.costs.totalResult.values[i];
        this.costBudgetSum += newVal.costs.budget.values[i];
      }
      for (let i = 0; i < 12; i++) {
        this.forecastResultSum +=
          newVal.income.forecast.values[i] - newVal.costs.forecast.values[i];
        this.forecastBudgetSum +=
          newVal.income.budget.values[i] - newVal.costs.budget.values[i];
      }
      newVal.costGroups.labels = newVal.costGroups.labels.splice(0, 11);
      this.combineCompanies = newVal.filter.combineCompanies;
      this.selectedYear = newVal.filter.comparisonYear;
      this.currentYear = newVal.filter.currentYear;
      this.columnPeriod =
        newVal.filter.periodStartMonth === 0 ? 'accumulated' : 'not_fiscal';

      this.startMonth = newVal.periodStartMonth;
      this.endMonth = newVal.periodEndMonth;
      // if only one value is selected

      if (newVal.financialYearStartMonth > 1) {
        this.periodCurrentMonthIndex =
          ((new Date().getMonth() + 12 - newVal.financialYearStartMonth) % 12) +
          1;
      }

      this.lastUpdate = new Date(
        Math.max(...newVal.lastUpdates.map(u => new Date(u.lastUpdate)))
      );
      // roerdering months for use in period filter,
      // when club has broken fiscal year in settings.
      // e.g. if the club's year starts in september,
      // the period filter shuold also do that.
      if (newVal.periodStartMonth === 0 || newVal.periodEndMonth === 0) {
        this.columnPeriod = 'accumulated';
      } else {
        this.columnPeriod =
          newVal.filter.periodStartMonth === 0 ? 'accumulated' : 'not_fiscal';
      }

      if (newVal.financialYearStartMonth) {
        this.months = this.generateMonths(
          newVal.financialYearStartMonth,
          'MonthsShort'
        );
        this.periodMonths = this.generateMonths(
          newVal.financialYearStartMonth,
          'Months'
        );
        this.prevYears = this.generatePrevYears(2018, 2024);
      }
    },
  },
  methods: {
    generateMonths(startMonth, monthsKey) {
      const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(m =>
        this.$t(`${monthsKey}.${m}`)
      );
      return [''].concat(months.slice(startMonth), months.slice(1, startMonth));
    },
    generatePrevYears(startYear, endYear) {
      return Array.from({ length: endYear - startYear + 1 }, (_, i) => {
        const year = endYear - i;
        return { displayName: `${year}/${year + 1}`, value: year };
      });
    },
    timeFormat(d) {
      if (!d) {
        return;
      }

      d = new Date(d);

      return `${d.getDate()} ${this.$t(
        `MonthsShort.${MonthsLabelsShort[d.getMonth()]}`
      ).toLowerCase()} ${d.getFullYear()}, ${('0' + d.getHours()).slice(-2)}:${(
        '0' + d.getMinutes()
      ).slice(-2)}`;
    },
    download() {
      this.showDownloadModal = false;
      if (this.diagramId === '') {
        return;
      }
      const toFile = this.chosenFormat === '0' ? toJpeg : toPng;
      const downloadOptions =
        this.chosenFormat === '0'
          ? { backgroundColor: 'white' }
          : { backgroundColor: 'transparent' };

      toFile(document.getElementById(this.diagramId), downloadOptions)
        .then(
          function (dataUrl) {
            var link = document.createElement('a');

            link.download =
              this.diagramId +
              '.' +
              (this.chosenFormat === '0' ? 'jpg' : 'png');

            link.href = dataUrl;

            link.click();
          }.bind(this)
        )
        .catch(function (error) {
          console.error('Oops, something went wrong!', error);
        });
    },
    changeFilter(filter) {
      this.storedFilter = filter;
      this.fetch();
    },
    saveFilter(filter) {
      this.storedFilter = filter;
      if (this.id === 'consolidatedaccounts') {
        this.$store.dispatch(
          'businessboard/postIncomestatementFilter',
          this.storedFilter
        );
      } else {
        this.$store.dispatch('businessboard/postIncomestatementCompanyFilter', {
          id: this.id,
          ...this.storedFilter,
        });
      }
      this.fetch();
    },
    fetch() {
      this.isLoading = true;
      this.$store
        .dispatch('businessboard/fetchIncomestatement', {
          id: this.id !== 'consolidatedaccounts' ? this.id : '',
          ...this.storedFilter,
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    krFormat(result, budget) {
      return (
        Math.round(result - budget).toLocaleString() +
        ' ' +
        this.$t('denomination')
      );
    },
    postedComment() {
      this.$store.dispatch(
        'businessboard/postIncomestatementDashboard',
        this.chartFilter
      );
    },
    selectYear(e) {
      this.selectedYear = e.value;
    },
    selectCurrentYear(e) {
      this.currentYear = e.value;
    },
    showYear() {
      this.showDashboard = false;
      this.$store
        .dispatch(
          'businessboard/postIncomestatementDashboard',
          this.chartFilter
        )
        .then(() => {
          this.showDashboard = true;
          this.showChartSettings = false;
        });
    },
    saveChartFilter() {
      this.$store
        .dispatch(
          'businessboard/postIncomestatementDashboardFilter',
          this.chartFilter
        )
        .then(() => {
          this.showYear();
        });
    },
    selectMonth(i) {
      // if (i > this.periodCurrentMonthIndex) return;
      if (this.startMonth === 0) {
        // If no start month has been selected, set the start and end month to the selected month
        this.startMonth = i;
        this.endMonth = i;
      } else if (i >= this.endMonth) {
        // move endMonth if it's to a later month
        this.endMonth = i;
      } else if (
        this.startMonth !== 0 &&
        this.endMonth !== 0 &&
        this.startMonth !== i &&
        this.endMonth !== i
      ) {
        // If a range has already been selected and a new month is clicked, reset the start and end month to the selected month
        this.startMonth = i;
        this.endMonth = i;
      }
    },
    copyDate(headers, items) {
      let text = '';
      for (let header of headers) {
        text += header.displayName.replace(/\r?\n|\r/g, ' ');
        for (let item of items) {
          text += '\t' + item[header.value].replace(/\r?\n|\r/g, ' ');
        }
        text += '\n';
      }
      return text;
    },
    copyTableData() {
      let text = '';
      text +=
        this.copyDate(
          this.incomestatement.incomes.columns,
          this.incomestatement.incomes.rows
        ) + '\n';
      text +=
        this.copyDate(
          this.incomestatement.costs.columns,
          this.incomestatement.costs.rows
        ) + '\n';
      text +=
        this.copyDate(
          this.incomestatement.deprecations.columns,
          this.incomestatement.deprecations.rows
        ) + '\n';
      text +=
        this.copyDate(
          this.incomestatement.preDispositionResult.columns,
          this.incomestatement.preDispositionResult.rows
        ) + '\n';
      text +=
        this.copyDate(
          this.incomestatement.dispositionResult.columns,
          this.incomestatement.dispositionResult.rows
        ) + '\n';
      text += this.copyDate(
        this.incomestatement.result.columns,
        this.incomestatement.result.rows
      );

      navigator.clipboard.writeText(text).then(
        () => {
          this.copyDataText = 'copiedToClipboard';
        },
        function (err) {
          this.copyDataText = 'dataCouldNotBeCopied';
          console.error('Async: Could not copy text: ', err);
        }
      );
    },
    setDownloadSettings(e) {
      this.showDownloadModal = e.showDownloadModal;
      this.diagramId = e.id;
      this.chosenFormat = this.settings.fileFormat;
    },
    setMaximized1(e) {
      this.maximized1 = e;
    },
    setMaximized2(e) {
      this.maximized2 = e;
    },
    selectColumnPeriod(e) {
      if (e.value === 'accumulated') {
        let minLastUpdateToDateMonth = new Date(
          Math.min.apply(
            null,
            this.dashboard.lastUpdates.map(x => new Date(x.toDate))
          )
        ).getMonth();
        this.startMonth = 1;
        if (this.dashboard.financialYearStartMonth > 0) {
          this.endMonth =
            ((minLastUpdateToDateMonth +
              13 -
              this.dashboard.financialYearStartMonth) %
              12) +
            1;
        } else {
          this.endMonth = minLastUpdateToDateMonth + 1;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
.businessboard--header {
  height: 3rem;
  margin: 1rem 0;
}
.h1 {
  font-family: 'SoraRegular';
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
}
h3 {
  font-family: 'SoraRegular';
  margin: 1rem 0;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.businessboard {
  // margin: 1rem;
  font-family: 'HindSiliguri';
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(gray-light);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.businessboard--table {
  margin-bottom: 2rem;
}

.charts_section {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 33% 32% 32%;
  grid-gap: 1rem;
  .chart_section {
    padding: 1rem;
    border-radius: 8px;
    background-color: white;
    grid-gap: 1rem;
    height: 42rem;
    .header {
      display: grid;
      grid-template-columns: auto 3rem;
      button {
        border: 1px solid #ccc;
        border-radius: 10px;
        justify-self: flex-end;
      }
      h3 {
        margin-top: 0.6rem;
      }
    }
  }
  .donuts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 4%;
    justify-content: center;
    height: 19rem;
    .donut {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
      .chart {
        margin: 1.2rem 0;
      }
      div {
        margin: 0.6rem 0;
      }
      strong {
        font-family: 'SoraRegular';
      }
    }
    .donut_kr {
      font-size: 14px;
      color: #373d3f;
      position: relative;
      top: -1rem;
      > svg {
        position: relative;
        top: 5px;
        margin-right: 5px;
      }
    }
  }
}
.maximized {
  z-index: 10;
  position: absolute;
  left: 5%;
  top: 5%;
  width: 90%;
  height: 44rem !important;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 7fr 3fr;
  h3 {
    font-size: 2rem;
  }
}
.chart_settings {
  margin: 1rem 0;
}
.chart_settings_modal {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 8px;
  .header {
    display: grid;
    grid-template-columns: auto 3rem;
    span {
      color: #0e3746;
    }
  }
  button {
    padding: 0.5rem;
    border: 1px solid #0e3746;
    border-radius: 3px;
    background: white;
    margin: 0.5rem 0;
  }
  .info_icon {
    margin: 0.5rem;
  }
  .btn_primary {
    background-color: #0e3746;
    color: white;
    float: right;
  }
  .btn_reticent {
    border: 0;
  }
  hr {
    margin-top: 1.5rem;
  }
  strong {
    display: inline-block;
    margin: 1rem 0;
  }
  .column_section {
    color: #555f61;
  }
}
.section {
  all: unset;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 16px;
  .chart {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 2fr 1frb;
  }
  .businessboard--table:last-child {
    margin-bottom: 0;
  }
}
.info_text {
  max-width: 600px;
}
.drillDownChoice {
  display: grid;
  grid-auto-flow: column;
  background: #eaf6f7;
  border-radius: 3px;
  font-weight: 400;
  padding: 4px;
  .choice {
    margin: auto 0;
    text-align: center;
    border: 1px solid #eaf6f7;
    padding: 4px;
    border-radius: 3px;
  }
  .selected {
    background: #abded1;
    border-color: #0e3746;
    font-weight: 500;
    div {
      display: inline-block;
      margin: auto;
      vertical-align: middle;
    }
  }
}
.selectMonth {
  display: grid;
  grid-template-columns: auto auto auto;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px;
  div {
    padding: 4px;
    font-size: 14px;
    line-height: 23px;
    color: #656565;
    text-align: center;
    border-radius: 16px;
    margin: 3px;
    cursor: pointer;
  }
  .selected {
    background-color: #abded1;
    font-weight: bold;
  }
  .highlighted {
    background-color: #eaf6f7;
  }
  .disabled {
    color: #ddd;
    cursor: default;
  }
}

.move-kebab-menu {
  width: 65%;
}
</style>
