<script>
import { Pie } from 'vue-chartjs';
import { COLORS } from './pie-chart-colors';

export default {
  extends: Pie,
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: this.data.labels,
          datasets: [
            {
              data: this.data.values,
              tooltips: this.data.tooltips,
              backgroundColor: COLORS,
            },
          ],
        },
        {
          layout: {
            padding: {
              left: 20,
              right: 0,
              top: 10,
              bottom: 40,
            },
          },
          legend: {
            position: 'right',
            labels: {
              usePointStyle: false,
              boxWidth: 20,
              padding: 15,
              fontSize: 14,
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var percentage = parseFloat(
                  (
                    (dataset.data[tooltipItem.index] /
                      dataset._meta[Object.keys(dataset._meta)[0]].total) *
                    100
                  ).toFixed(0)
                );
                return percentage + '% ' + data.labels[tooltipItem.index];
              },
            },
          },
          maintainAspectRatio: false,
        }
      );
    },
  },
  watch: {
    data() {
      this.render();
    },
  },

  mounted() {
    this.render();
  },
};
</script>
