const state = {
  notifications: [],
  activeLoaders: [],
};

const mutations = {
  ADD_NOTIFICATION (state, payload) {
    state.notifications.push(payload);
  },
  TOGGLE_ACTIVE_LOADERS(state, payload) {
    if (state.activeLoaders.includes(payload)) {
      state.activeLoaders = state.activeLoaders.filter(item => {
        return item !== payload;
      });
    } else {
      state.activeLoaders.push(payload);
    }
  },
};

const actions = {
  addNotification({ commit }, payload) {
    commit('ADD_NOTIFICATION', payload);
  },
  toggleActiveLoaders({ commit }, payload) {
    commit('TOGGLE_ACTIVE_LOADERS', payload);
  },
};

const getters = {
  notifications: state => state.notifications,
  activeLoaders: state => state.activeLoaders,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
