<script>
export default {
  name: 'LocalizationMixin',
  computed: {
    language() {
      return this.$store.getters['businessboard/language'];
    },
  },
  mounted() {
    this.$store.dispatch('businessboard/fetchLanguage').then(() => {
      if (this.language === 0) {
        this.$i18n.locale = 'sv';
      } else if (this.language === 1) {
        this.$i18n.locale = 'en';
      }
    });
  },
};
</script>
