<template>
  <div class="ontag">
    <div class="ontag--content">
      <div>
        <span class="h1">{{ $t('handleCourses') }}</span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
      </div>
      <p>
        För att redigera en slinga med tex idealtid klicka på pilen ute till
        höger på den raden för den slingan som ni vill editera.<br />
        Data hämtas automatiskt från GIT en gång per dygn. För att hämta ny data
        nu, gå in på slingan och klicka på 'Uppdatera'.
      </p>
      <div
        class="ontag--utilitites input-group--inline"
        v-if="courses">
        <div class="pagination-amount">
          <span>{{ $t('show') }}</span>
          <custom-select
            v-if="selectedIndexPageSize !== undefined"
            :required="false"
            @optionSelected="setPageSize"
            :selected="selectedIndexPageSize"
            :options="pageSizeOptions" />
          <span class="row-lines">{{ $t('lines') }}</span>
        </div>
        <div class="search-wrapper">
          <div class="input">
            <label
              for="searchQuery"
              class="input__label label__inline">{{ $t('search') }}</label>
            <input
              id="searchQuery"
              class="input__input input__inline search-input"
              @keyup.enter="getVariatedCoursesResults"
              v-model="searchQuery"
              type="text"
              name="searchQuery" />
          </div>
          <base-button
            class="search-button"
            :text="$t('search')"
            :button-size-small="true"
            v-element-loader="{ loaderKey: 'courses-fetch-courses' }"
            @click="getVariatedCoursesResults" />
        </div>
        <!-- TODO: Update course - BE will clean cache (not in FAS 1) -->
        <!-- <base-button
        class="button--icon-to-left"
        text="Update courses"
        :button-size-small="true"
        @click="updateCourses">
        <use-svg svg="update-icon"/>
      </base-button> -->
        <!-- TODO: Add export when it's time to (not in FAS 1) -->
        <!-- <base-button
        class="button--icon-to-left"
        text="Export to excel"
        :button-size-small="true"
        @click="exportToExcel">
        <use-svg svg="export-icon"/>
      </base-button> -->
      </div>
      <list-table
        :items="courses.rows"
        :headers="courses.columns"
        @variationResults="getVariatedCoursesResults"
        :hide-in-mobile="['numberOfHoles']">
      </list-table>
      <results-pagination
        :first-row-on-page="pagination.firstRowOnPage"
        :last-row-on-page="pagination.lastRowOnPage"
        :row-count="pagination.rowCount"
        :page-size="pagination.pageSize"
        :page-count="pagination.pageCount"
        :current-page="pagination.currentPage"
        :next-page="getNextPage()"
        :prev-page="getPrevPage()"
        store="courses"
        @currentPage="getVariatedCoursesResults" />
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import ResultsPagination from '@/components/results-pagination';
import CustomSelect from '@/components/form-elements/custom-select';
// import UseSvg from '@/components/use-svg'
import elementLoader from '@/directives/element-loader';

export default {
  name: 'HandleCourses',
  components: {
    ListTable,
    BaseButton,
    ResultsPagination,
    CustomSelect,
    // UseSvg
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      isLoading: true,
      selectedIndexPageSize: undefined,
      searchQuery: '',
      currentPage: 0,
      sort: {},
      active: undefined,
      numberOfHoles: undefined,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
    };
  },
  methods: {
    // TODO: Add export when it's time to (not in FAS 1)
    // exportToExcel() {
    // },
    getPrevPage() {
      return this.$store.getters['courses/previousPage'];
    },
    getNextPage() {
      return this.$store.getters['courses/nextPage'];
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedCoursesResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(
        o => parseInt(o.value) === size
      );
      this.selectedIndexPageSize = selectedSize;
    },
    getVariatedCoursesResults(value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (
          checked.length < value.filter.options.length &&
          checked.length > 0
        ) {
          if (checked[0].value === '1') {
            this.active = true;
          } else {
            this.active = false;
          }
        } else {
          this.active = undefined;
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('courses/fetchCourses', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.active': this.active,
        'Filter.numberOfHoles': this.numberOfHoles,
        pageSize: this.pageSize,
        page: this.searchQuery.length > 0 ? '1' : this.currentPage,
      });
    },
  },
  computed: {
    courses() {
      return this.$store.getters['courses/courses'];
    },
    pagination() {
      return this.$store.getters['courses/pagination'];
    },
  },
  created() {
    this.$store
      .dispatch('courses/fetchCourses', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.active': this.active,
        'Filter.numberOfHoles': this.numberOfHoles,
        pageSize: this.pageSize,
        page: this.currentPage,
      })
      .then(() => {
        this.selectedPageSize(this.pagination.pageSize);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>
<style lang="scss">
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 20px;
}
.ontag--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;

  .ontag--utilitites {
    justify-content: flex-end;
    margin: 1.25rem 0 0;
    @include media('to-medium') {
      display: block;
    }

    .select__wrapper {
      width: auto;
      padding: 0 0.625rem;

      button {
        @include media('to-medium') {
          margin: 0;
        }
      }
    }

    .search-wrapper {
      display: flex;

      @include media('to-small') {
        display: block;
      }

      button {
        margin: 0 0 0 0.625rem;
        @include media('to-small') {
          margin: 0;
        }
      }
    }

    .select__trigger {
      margin-left: 0 !important;
    }

    button {
      margin-left: 0.625rem;
      @include media('to-medium') {
        margin: 0.6rem 0;
      }
    }

    .pagination-amount {
      display: flex;
      align-items: baseline;
      flex: 1;
    }
  }
}
</style>
