<template>
  <div>
      <button
        v-bind="$attrs"
        type="button"
        class="switch"
        role="switch"
        :aria-checked="toggled.toString()"
        @click="toggle">
        <span :class="`slider round ${toggled === true ? 'slider__toggled' : ''}`"></span>
      </button>
  </div>
</template>
<script>
const EventName = 'toggle';
export default {
  name: 'ToggleSwitch',
  model: {
    props: 'toggled',
    event: EventName,
  },
  props: {
    toggled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit(EventName, !this.toggled);
    },
  },
};
</script>
<style lang="scss" scoped>
  /* Switch */
    .switch {
      position: relative;
      display: inline-block;
      width: 2.25rem;
      height: 1rem;
      @include reset-button;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      transition: .4s;
      border: 1px solid color(gray);
      &:before {
        position: absolute;
        content: "";
        height: 0.65rem;
        width: 0.65rem;
        left: 2px;
        bottom: 2px;
        background-color: color(gray-dark);
        transition: .4s;
      }

      &.slider__toggled {
          background-color: color(ontag-blue);
          transition: .4s;
          &:before {
            transform: translateX(1.2rem);
            background-color: color(white);
          }
      }
      &.round {
        border-radius: 0.5rem;
        &:before {
          border-radius: 50%;
        }
      }
    }
    /* End switch */
</style>
