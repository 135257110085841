<template>
  <tr class="ageGroup-wrapper row-height">
    <td>
      <div class="display-div">
        <!-- empty -->
      </div>
    </td>
    <td>
      <div class="display-div">
        <p class="ageGroup">{{ getAgeGroupReference(ageGroup) }} år</p>
      </div>
    </td>
    <td>
      <div class="display-div">
        <!-- empty -->
      </div>
    </td>
    <td>
      <div class="display--div">
        <!-- empty -->
      </div>
    </td>
    <td v-if="ageGroup">
      <div class="display-div">
        <div class="display-flex">
          <input
            type="text"
            :value="ageGroup.quantityBudgeted ? ageGroup.quantityBudgeted : 0"
            aria-label="Budgeteratantal"
            :placeholder="ageGroup ? ageGroup.quantityBudgeted : 0"
            @change="
              event =>
                updateAgeGroupQuantity(
                  event.target.value,
                  ageGroup,
                  categoryReference
                )
            " />
          <p class="input-unit">st</p>
        </div>
      </div>
      <div>
        <p
          v-if="showPreviousYear"
          class="previous-year-amount">
          {{
            previousYearData
              ? previousYearData.quantityBudgeted + ' st'
              : 0 + ' st'
          }}
        </p>
      </div>
    </td>
    <template v-for="(fee, index) in feeReference">
      <td :key="'fee' + index">
        <div class="display-div">
          <div class="display-flex">
            <template v-if="checkId(fee)">
              <input
                type="text"
                :value="findAgeGroupFeeAmount(fee)"
                aria-label="Avgifter"
                :placeholder="findAgeGroupFeeAmount(fee)"
                @change="
                  event =>
                    updateAgeGroupFeeAmount(
                      event.target.value,
                      findAgeGroupFeeId(fee),
                      ageGroup
                    )
                " />
            </template>
            <template v-else>
              <input
                type="text"
                :value="0"
                aria-label="Avgifter"
                :placeholder="0"
                @change="
                  event =>
                    updateAgeGroupFeeAmount(
                      event.target.value,
                      fee.id,
                      ageGroup
                    )
                " />
            </template>
            <p class="input-unit">kr</p>
          </div>
        </div>
        <div>
          <p
            v-if="showPreviousYear"
            class="previous-year-amount">
            {{ findAgeGroupFeeAmountFromPreviousYear(fee) + ' kr' }}
          </p>
        </div>
      </td>
    </template>
    <td>
      <div class="display-div">
        <!-- empty -->
      </div>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  components: {},

  props: {
    ageGroup: VueTypes.object.def({}),
    ageGroupReference: VueTypes.array.def([]),
    categoryReference: VueTypes.string.def(''),
    feeReference: VueTypes.array.def([]),
    previousYearData: VueTypes.object.def({}),
    showPreviousYear: VueTypes.bool.def(false),
    typeData: VueTypes.object.def({}),
  },

  data() {
    return {};
  },

  methods: {
    checkId(fee) {
      if (this.ageGroup.fees != null) {
        for (const ageGroupFee of this.ageGroup.fees) {
          if (ageGroupFee.feeColumnReference === fee.id) {
            return true;
          }
        }
      }
      return false;
    },

    findAgeGroupFeeAmount(fee) {
      for (const ageGroupFee of this.ageGroup.fees) {
        if (ageGroupFee.feeColumnReference === fee.id) {
          return ageGroupFee.amount;
        }
      }
    },
    findAgeGroupFeeId(fee) {
      for (const ageGroupFee of this.ageGroup.fees) {
        if (ageGroupFee.feeColumnReference === fee.id) {
          return ageGroupFee.feeColumnReference;
        }
      }
    },
    findAgeGroupFeeAmountFromPreviousYear(fee) {
      if (this.previousYearData != null && this.previousYearData.fees) {
        for (const previousYearFeeData of this.previousYearData.fees) {
          if (previousYearFeeData.feeColumnReference === fee.id) {
            return previousYearFeeData.amount;
          }
        }
      }
      return 0;
    },
    getAgeGroupReference(ageObj) {
      const newAgeObj = ageObj;
      if (newAgeObj !== null) {
        const indexOfAgeGroup = Object.keys(this.ageGroupReference).find(
          id => this.ageGroupReference[id].id === newAgeObj.ageGroupId
        );
        return this.ageGroupReference[indexOfAgeGroup].displayName;
      }
    },

    updateAgeGroupQuantity(quantity, ageGroup, categoryReference) {
      const ageGroupDetails = this.findAgeGroup(ageGroup.ageGroupId);
      ageGroup.quantityBudgeted = quantity;
      const newAgeGroupQuantity = {
        quantity: quantity,
        id: ageGroupDetails.id,
        fromAge: ageGroupDetails.fromAge,
        toAge: ageGroupDetails.toAge,
        categoryReference,
      };
      this.$emit('update-ageGroup-quantity', newAgeGroupQuantity);
    },

    updateAgeGroupFeeAmount(amount, feeColumnReference, ageGroup) {
      const ageGroupDetails = this.findAgeGroup(ageGroup.ageGroupId);
      const newAgeGroupQuantity = [
        {
          amount: Number(amount),
          feeColumnReference: feeColumnReference,
          id: ageGroupDetails.id,
          type: 1,
        },
      ];

      this.$emit('update-agegroup-fee-amount', ...newAgeGroupQuantity);
    },

    findAgeGroup(ageGroupId) {
      const ageGroupDetails = this.ageGroupReference.find(
        ageGroup => ageGroup.id === ageGroupId
      );
      return ageGroupDetails;
    },
  },
};
</script>
<style lang="scss" scoped>
.ageGroup-wrapper {
  box-sizing: border-box;
  border-radius: 3px;

  margin-bottom: 0.2rem;
  padding-bottom: 1rem;
  border-top: 5px solid #f0f5f5;
  border-bottom: 5px solid #f0f5f5;
  background-color: #f0f5f5;

  input {
    max-width: 116px;
    border-radius: 4px;
    text-align: right;
  }

  > td:first-child {
    background-color: #f0f5f5;
  }

  > td {
    background-color: #fff;
  }
  > td:last-child {
    background-color: #f0f5f5;
  }
}
.row-height {
  height: 3rem;
}

.ageGroup {
  margin-top: 0.5rem;
  margin-left: 0.5rem;

  font-weight: 600;
  font-size: 14px;
  line-height: 22.64px;
  color: #000;
  width: 100%;
}

.previous-year-amount {
  text-align: right;
  position: relative;
  top: -0.8rem;

  max-width: 116px;
  height: 0px;
  color: #6f7c80;
  font-size: font(small);
}
.display-flex {
  height: 25px;
  display: flex;
  gap: 0.2rem;
}
.input-unit {
  margin-top: 0.2rem;
}
</style>
