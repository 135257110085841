<template>
  <div>
    <div class="holesponsors-list-table" v-for="(item, index) in items" :key="index">
      <holesponsor
        :item="item"/>
    </div>
  </div>
</template>

<script>
import Holesponsor from '@/components/holesponsor';
export default {
  name: 'HolesponsorsListTable',
  components: {
    Holesponsor,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.list-table {
  border-collapse: collapse;
  width: 100%;
  thead {
    background-color:color(gray-light);
    th {
      text-align: left;
      padding: 0 0.625rem;
      &:first-child {
        padding-left: 0.8rem;
      }
      &:last-child {
        padding-right: 0.8rem;
      }
      .list-table__button {
        border: none;
        background-color: color(gray-light);
        min-height: 2.625rem;
        padding: 0;
        span {
          font-weight: bold;
          margin-right: 0.325rem;
          position: relative;
          top: -2px;
        }
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:nth-child(even) {
        background-color: color(gray-lighter);
      }
      &.checked {
        background-color: color(blue-light);
      }
    td {
      padding: 0.625rem;
      text-align: left;
        &:first-child {
          padding-left: 0.8rem;
        }
        &:last-child {
          padding-right: 0.8rem;
          text-align: right;
        }
      }
    }
  }
}
</style>
