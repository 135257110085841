<template>
  <div class="ontag">
    <div class="ontag--content">
      <div>
        <span class="h1">{{ $t('standardPrinter') }}</span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
      </div>
      <p class="intro">
        Skrivare som ni har i ert nätverk kan ni tilldela olika utskriftsjobb.
      </p>
      <div class="choose-standard-printer">
        <h2 class="title-variation">
          <use-svg svg="print-icon" />
          {{ $t('standardPrinter') }}
        </h2>
        <p>
          Här kan ni ange standardskrivare för olika ändamål. Tex
          tävlingsutskrifter, terminal osv.
        </p>
        <custom-select
          v-if="
            defaultPrinter &&
            defaultPrinter.competitionPrinter &&
            selectedCompetitionPrinterIndex != null
          "
          :required="false"
          :label="$t('competitionPrinter')"
          :selected="selectedCompetitionPrinterIndex"
          @optionSelected="selectCompetitionPrinter"
          :options="printersOptions" />
        <custom-select
          v-if="
            defaultPrinter &&
            defaultPrinter.friendlyPrinter &&
            selectedFriendlyPrinterIndex != null
          "
          :required="false"
          :label="$t('friendlyPrinter')"
          :selected="selectedFriendlyPrinterIndex"
          @optionSelected="selectFriendlyPrinter"
          :options="printersOptions" />
        <custom-select
          v-if="
            defaultPrinter &&
            defaultPrinter.neutralPrinter &&
            selectedNeutralPrinterIndex != null
          "
          :required="false"
          :label="$t('neutralPrinter')"
          :selected="selectedNeutralPrinterIndex"
          @optionSelected="selectNeutralPrinter"
          :options="printersOptions" />
        <template
          v-if="
            defaultPrinter.terminalPrinters &&
            defaultPrinter.terminalPrinters.length > 0
          ">
          <h2 class="title-variation">
            <use-svg svg="tv-icon" />
            {{ $t('printerForTerminal') }}
          </h2>
          <p>Här ställer ni in er skrivare för terminalutskrifter.</p>
          <template v-if="selectedTerminalPrintersIndexes.length > 0">
            <template
              v-for="(printer, index) in defaultPrinter.terminalPrinters">
              <custom-select
                v-if="defaultPrinter.terminalPrinters"
                :key="index"
                :required="false"
                :label="printer.objectDisplayName"
                :selected="selectedTerminalPrintersIndexes[index]"
                @optionSelected="e => selectTerminalPrinters(e, printer)"
                :options="printersOptions" />
            </template>
          </template>
        </template>
        <div v-if="club && club.appPrint">
          <h2 class="title-variation">
            <img
              height="32px"
              src="../../assets/images/phone-dark.svg"
              alt="Phone logo" />
            {{ $t('sweetspotPrinterTitle') }}
          </h2>
          <p>
            {{ $t('sweetspotPrinterDescription') }}
          </p>
          <custom-select
            v-if="
              defaultPrinter &&
              defaultPrinter &&
              defaultPrinter.appPrinter &&
              selectedAppPrinterIndex != null
            "
            :required="false"
            :selected="selectedAppPrinterIndex"
            @optionSelected="selectAppPrinter"
            :options="appPrintersOptions" />
        </div>
        <expand-collapse-variant
          icon="cogs-icon"
          :localExpanded="isExpanded">
          <template slot="title">
            {{ $t('advancedSettings') }}
          </template>
          <template slot="content">
            <div class="courses">
              <h2>
                <div class="input checkbox">
                  <div class="checkbox--group">
                    <input
                      name="handle-printing-for-courses"
                      type="checkbox"
                      v-model="defaultPrinter.useCoursePrinters"
                      @change="showCourse" />
                    <label
for="handle-printing-for-courses"><span><span></span></span>{{ $t('handlePrinterForCourses') }}</label>
                  </div>
                </div>
              </h2>
              <p :class="{ 'not-allowed': !defaultPrinter.useCoursePrinters }">
                Här kan ni ange om utskrift för en specifik bana/slinga skall
                skrivas på en specifik skrivare.
              </p>
              <div
                :class="{ 'not-allowed': !defaultPrinter.useCoursePrinters }">
                <div>
                  <h3>Utskrift från GIT Online</h3>
                  <template
                    v-for="(printer, index) in defaultPrinter.coursePrinters">
                    <custom-select
                      v-if="selectedCoursesPrintersIndexes.length > 0"
                      :disabled="!defaultPrinter.useCoursePrinters"
                      :key="index"
                      :required="false"
                      :label="printer.objectDisplayName"
                      :selected="selectedCoursesPrintersIndexes[index]"
                      @optionSelected="e => selectCoursePrinters(e, printer)"
                      :options="coursesPrintersOptions" />
                  </template>
                </div>
                <!--
              <div>
                <h3>Utskrift från GIT Classic</h3>
                <template v-for="(printer, index) in defaultPrinter.loopPrinters">
                  <custom-select
                    v-if="selectedLoopPrintersIndexes.length > 0"
                    :disabled="!defaultPrinter.useCoursePrinters"
                    :key="index"
                    :required="false"
                    :label="printer.objectDisplayName"
                    :selected="selectedLoopPrintersIndexes[index]"
                    @optionSelected="e => selectLoopPrinters(e, printer)"
                    :options="coursesPrintersOptions" />
                </template>
              </div>
              --></div>
            </div>
            <div class="users">
              <h2>
                <div class="input checkbox">
                  <div class="checkbox--group">
                    <input
                      name="users"
                      type="checkbox"
                      v-model="defaultPrinter.useUserPrinters"
                      @change="showUsers" />
                    <label
for="users"><span><span></span></span>{{ $t('user/computername') }}</label>
                  </div>
                </div>
              </h2>
              <p :class="{ 'not-allowed': !defaultPrinter.useUserPrinters }">
                Här kan ni ange om utskrift från specifik dator (GIT Classic)
                eller användare (GIT Online) skall gå en specifik skrivare. För
                användare anger ni Golf-ID i följande format XXXXXX-XXX.
              </p>
              <div :class="{ 'not-allowed': !defaultPrinter.useUserPrinters }">
                <div>
                  <div
                    class="advanced-settings__selectedusers"
                    v-for="(user, index) in localUsers"
                    :key="index">
                    <div class="input">
                      <input
                        type="text"
                        :name="'user-name-' + index"
                        :id="'user-name-' + index"
                        :disabled="!defaultPrinter.useUserPrinters"
                        class="input__input"
                        @input="e => input(e)"
                        v-model="user.objectDisplayName" />
                    </div>
                    <custom-select
                      :key="index"
                      :required="false"
                      :options="printersOptions"
                      :selected="selectedUsersIndexes[index]"
                      @optionSelected="e => selectUserPrinters(e, user)"
                      :disabled="!defaultPrinter.useUserPrinters" />
                    <div
                      class="advanced-settings__selectedusers--remove"
                      :class="{
                        'advanced-settings__selectedusers--disabled':
                          !defaultPrinter.useUserPrinters,
                      }"
                      @click="removeLocalUser(index)">
                      <use-svg svg="trash-icon" />
                    </div>
                  </div>
                  <base-button
                    @click="addUserFields"
                    :text="$t('add')"
                    class="button--has-icon advanced-settings__selectedusers--button"
                    :button-size-small="true"
                    :disabled="!defaultPrinter.useUserPrinters">
                    <use-svg svg="plus-icon" />
                  </base-button>
                </div>
              </div>
              <br />
              <!-- Sweetspot App Print -->
              <div v-if="club && club.appPrint">
                <h2>
                  <div class="input checkbox">
                    <div class="checkbox--group">
                      <input
                        name="handle-printing-for-appPrint"
                        type="checkbox"
                        v-model="defaultPrinter.useAppPrinters"
                        @change="showAppPrintCourse" />
                      <label
for="handle-printing-for-courses"><span><span></span></span>{{ $t('handlePrinterForAppPrint') }}</label>
                    </div>
                  </div>
                </h2>
                <p :class="{ 'not-allowed': !defaultPrinter.useAppPrinters }">
                  Här kan ni ange om utskrift för en specifik bana/slinga skall
                  skrivas på en specifik skrivare.
                </p>
                <div :class="{ 'not-allowed': !defaultPrinter.useAppPrinters }">
                  <div>
                    <template
                      v-for="(printer, index) in defaultPrinter.appPrinters">
                      <custom-select
                        :disabled="!defaultPrinter.useAppPrinters"
                        :key="index + 'app_print'"
                        :required="false"
                        :label="printer.objectDisplayName"
                        :selected="
                          sweetspotSelectedCoursesPrintersIndexes[index]
                        "
                        @optionSelected="
                          e => selectAppPrintCoursePrinters(e, printer)
                        "
                        :options="appPrintercoursesPrintersOptions" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </expand-collapse-variant>
        <modal-window :show="showModal">
          <div class="modal__header">
            <template
              v-if="
                defaultPrinter.competitionPrinter &&
                defaultPrinter.competitionPrinter.value === ''
              ">
              {{
                $t('standardPrinterMissing', {
                  objectDisplayName:
                    defaultPrinter.competitionPrinter.objectDisplayName,
                })
              }}
            </template>
            <template
              v-else-if="
                defaultPrinter.friendlyPrinter &&
                defaultPrinter.friendlyPrinter.value === ''
              ">
              {{
                $t('standardPrinterMissing', {
                  objectDisplayName:
                    defaultPrinter.friendlyPrinter.objectDisplayName,
                })
              }}
            </template>
            <template
              v-else-if="
                defaultPrinter.neutralPrinter &&
                defaultPrinter.neutralPrinter.value === ''
              ">
              {{
                $t('standardPrinterMissing', {
                  objectDisplayName:
                    defaultPrinter.neutralPrinter.objectDisplayName,
                })
              }}
            </template>
          </div>
          <div class="modal__content">
            <template
              v-if="
                defaultPrinter.competitionPrinter &&
                defaultPrinter.competitionPrinter.value === ''
              ">
              <div>
                <p>
                  {{
                    $t('scorecardsStandardPrinter', {
                      objectDisplayName:
                        defaultPrinter.competitionPrinter.objectDisplayName,
                    })
                  }}.
                </p>
                <p>{{ $t('saveAnyway') }}?</p>
              </div>
            </template>
            <template
              v-else-if="
                defaultPrinter.friendlyPrinter &&
                defaultPrinter.friendlyPrinter.value === ''
              ">
              <div>
                <p>
                  {{
                    $t('scorecardsStandardPrinter', {
                      objectDisplayName:
                        defaultPrinter.friendlyPrinter.objectDisplayName,
                    })
                  }}.
                </p>
                <p>{{ $t('saveAnyway') }}?</p>
              </div>
            </template>
            <template
              v-else-if="
                defaultPrinter.neutralPrinter &&
                defaultPrinter.neutralPrinter.value === ''
              ">
              <div>
                <p>
                  {{
                    $t('scorecardsStandardPrinter', {
                      objectDisplayName:
                        defaultPrinter.neutralPrinter.objectDisplayName,
                    })
                  }}.
                </p>
                <p>{{ $t('saveAnyway') }}?</p>
              </div>
            </template>
            <template v-else-if="!isTerminalPrintersChoosen">
              <div>
                <p>
                  {{ $t('scorecardsPrinters') }}:<br />
                  <template
                    v-for="(
                      terminal, index
                    ) in defaultPrinter.terminalPrinters">
                    <template
                      v-if="
                        terminal.value === '' && terminal.displayName === ''
                      ">
                      <span
:key="index"><strong>{{ terminal.objectDisplayName }}</strong><br/></span>
                    </template>
                  </template>
                  {{ $t('standardPrinterMessageSelected') }}.
                </p>
                <p>{{ $t('saveAnyway') }}?</p>
              </div>
            </template>
            <template v-else-if="!hasUserPrinter">
              <div>
                <p>
                  {{ $t('standardPrinterFor') }}<br />
                  <template
                    v-for="(user, index) in defaultPrinter.userPrinters">
                    <template
                      v-if="user.value === '' && user.displayName === ''">
                      <span
:key="index"><strong>{{ user.objectDisplayName }}</strong><br/></span>
                    </template>
                  </template>
                  {{ $t('standardPrinterMessageSelected') }}.
                </p>
                <p>{{ $t('chooseStandardPrinter') }}.</p>
              </div>
            </template>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="toggleModal">
            </base-button>
            <base-button
              :text="$t('save')"
              @click="saveDefaultPrinter" />
          </div>
        </modal-window>
      </div>
      <div class="scorecard__actions">
        <base-button
          v-element-loader="{ loaderKey: 'edit-settings' }"
          :text="$t('save')"
          @click="canISave" />
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import CustomSelect from '@/components/form-elements/custom-select';
import BaseButton from '@/components/form-elements/base-button';
import ModalWindow from '@/components/modal/modal';
import ExpandCollapseVariant from '@/components/expand-collapse-variant';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'ChooseStandardPrinter',

  directives: {
    elementLoader,
  },

  data() {
    return {
      isExpanded: false,
      localUsers: [],
      localCoursePrinters: [],
      selectedCompetitionPrinterIndex: null,
      selectedFriendlyPrinterIndex: null,
      selectedNeutralPrinterIndex: null,
      selectedAppPrinterIndex: null,
      selectedUsersIndexes: [],
      selectedTerminalPrintersIndexes: [],
      selectedCoursesPrintersIndexes: [],
      sweetspotSelectedCoursesPrintersIndexes: [],
      selectedLoopPrintersIndexes: [],
      showModal: false,
      isTerminalPrintersChoosen: false,
      hasUserPrinter: false,
      isLoading: true,
    };
  },
  components: {
    UseSvg,
    CustomSelect,
    BaseButton,
    ModalWindow,
    ExpandCollapseVariant,
  },
  methods: {
    canISave() {
      if (
        this.defaultPrinter.competitionPrinter.value === '' ||
        this.defaultPrinter.friendlyPrinter.value === '' ||
        this.defaultPrinter.neutralPrinter.value === '' ||
        !this.isTerminalPrintersChoosen ||
        !this.checkUserPrinter()
      ) {
        this.toggleModal();
      } else {
        this.saveDefaultPrinter();
      }
    },
    checkUserPrinter() {
      if (this.defaultPrinter.useUserPrinters) {
        if (this.localUsers.length > 0) {
          if (
            this.localUsers.filter(
              lu => lu.objectDisplayName !== '' || lu.displayName !== ''
            ).length > 0
          ) {
            return false;
          }
        } else {
          return false;
        }
        return true;
      }
      return true;
    },
    saveDefaultPrinter() {
      this.showModal = false;
      this.$store
        .dispatch('printers/editStandardPrinter', {
          useCoursePrinters: this.defaultPrinter.useCoursePrinters,
          useAppPrinters: this.defaultPrinter.useAppPrinters,
          useUserPrinters: this.defaultPrinter.useUserPrinters,
          competitionPrinter: this.defaultPrinter.competitionPrinter,
          friendlyPrinter: this.defaultPrinter.friendlyPrinter,
          neutralPrinter: this.defaultPrinter.neutralPrinter,
          terminalPrinters: this.defaultPrinter.terminalPrinters,
          coursePrinters: this.defaultPrinter.coursePrinters,
          appPrinters: this.defaultPrinter.appPrinters,
          appPrinter: this.defaultPrinter.appPrinter,
          userPrinters: this.defaultPrinter.userPrinters,
          loopPrinters: this.defaultPrinter.loopPrinters,
        })
        .then(() => {
          this.$toasted.success(this.$t('standardprinterSaved'));
          this.selectedUsersIndexes = [];
          this.selectedTerminalPrintersIndexes = [];
          this.selectedCoursesPrintersIndexes = [];
          this.sweeptspotSelectedCoursesPrintersIndexes = [];
          this.selectedLoopPrintersIndexes = [];
          this.$store
            .dispatch('printers/fetchDefaultPrinter', null)
            .then(() => {
              if (this.defaultPrinter) {
                if (
                  this.defaultPrinter.useCoursePrinters ||
                  this.defaultPrinter.useUserPrinters
                ) {
                  this.isExpanded = true;
                } else {
                  this.isExpanded = false;
                }
                this.selectedCompetitionPrinter(
                  this.defaultPrinter.competitionPrinter
                );
                this.selectedFriendlyPrinter(
                  this.defaultPrinter.friendlyPrinter
                );
                this.selectedAppPrinter(this.defaultPrinter.appPrinter);
                this.selectedNeutralPrinter(this.defaultPrinter.neutralPrinter);
                if (this.defaultPrinter.terminalPrinters)
                  this.defaultPrinter.terminalPrinters.map(printer =>
                    this.setTerminalPrintersIndex(printer)
                  );
                if (this.defaultPrinter.coursePrinters)
                  this.defaultPrinter.coursePrinters.map(printer =>
                    this.setCoursesPrintersIndex(printer)
                  );
                if (this.defaultPrinter.appPrinters)
                  this.defaultPrinter.appPrinters.map(printer =>
                    this.setSweetspotCoursesPrintersIndex(printer)
                  );
                if (this.defaultPrinter.loopPrinters)
                  this.defaultPrinter.loopPrinters.map(printer =>
                    this.setLoopPrintersIndex(printer)
                  );
                if (this.defaultPrinter.userPrinters)
                  this.defaultPrinter.userPrinters.map(printer =>
                    this.setUsersPrintersIndex(printer)
                  );
              }
            });
        });
    },
    input(value) {
      if (this.defaultPrinter.userPrinters.some(u => u.value === '')) {
        this.hasUserPrinter = false;
      } else {
        this.hasUserPrinter = true;
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    showCourse(value) {
      this.defaultPrinter.useCoursePrinters = value.target.checked;
    },
    showAppPrintCourse(value) {
      this.defaultPrinter.useAppPrinters = value.target.checked;
    },
    showUsers(value) {
      this.defaultPrinter.useUserPrinters = value.target.checked;
    },
    addUserFields() {
      this.localUsers.push({
        value: '',
        displayName: '',
        objectDisplayName: '',
        objectValue: '',
      });
    },
    removeLocalUser(index) {
      this.localUsers.splice(index, 1);
    },
    selectedCompetitionPrinter(printer) {
      if (printer) {
        this.selectedCompetitionPrinterIndex = this.printersOptions.findIndex(
          o => o.value === printer.value
        );
      }
    },
    selectCompetitionPrinter(printer) {
      this.defaultPrinter.competitionPrinter.value = printer.value;
      this.defaultPrinter.competitionPrinter.displayName = printer.displayName;
    },
    selectAppPrinter(printer) {
      this.defaultPrinter.appPrinter.value = printer.value;
      this.defaultPrinter.appPrinter.displayName = printer.displayName;
    },
    selectedFriendlyPrinter(printer) {
      if (printer) {
        this.selectedFriendlyPrinterIndex = this.printersOptions.findIndex(
          o => o.displayName === printer.displayName
        );
      }
    },
    selectFriendlyPrinter(printer) {
      this.defaultPrinter.friendlyPrinter.value = printer.value;
      this.defaultPrinter.friendlyPrinter.displayName = printer.displayName;
    },
    selectedNeutralPrinter(printer) {
      if (printer) {
        this.selectedNeutralPrinterIndex = this.printersOptions.findIndex(
          o => o.displayName === printer.displayName
        );
      }
    },
    selectedAppPrinter(printer) {
      if (printer) {
        this.selectedAppPrinterIndex = this.printersOptions.findIndex(
          o => o.displayName === printer.displayName
        );
      }
    },
    selectNeutralPrinter(printer) {
      this.defaultPrinter.neutralPrinter.value = printer.value;
      this.defaultPrinter.neutralPrinter.displayName = printer.displayName;
    },
    setTerminalPrintersIndex(printer) {
      this.selectedTerminalPrintersIndexes.push(
        this.printersOptions.findIndex(t => t.value === printer.value)
      );
      if (this.selectedTerminalPrintersIndexes.some(p => p === -1)) {
        this.isTerminalPrintersChoosen = false;
      } else {
        this.isTerminalPrintersChoosen = true;
      }
    },
    selectTerminalPrinters(option, printer) {
      let terminal = this.defaultPrinter.terminalPrinters.find(
        p => p.objectDisplayName === printer.objectDisplayName
      );
      terminal.value = option.value;
      terminal.displayName = option.displayName;

      if (this.defaultPrinter.terminalPrinters.some(p => p.value === '')) {
        this.isTerminalPrintersChoosen = false;
      } else {
        this.isTerminalPrintersChoosen = true;
      }
    },
    setCoursesPrintersIndex(printer) {
      this.selectedCoursesPrintersIndexes.push(
        this.coursesPrintersOptions.findIndex(t => t.value === printer.value)
      );
    },
    setSweetspotCoursesPrintersIndex(printer) {
      this.sweetspotSelectedCoursesPrintersIndexes.push(
        this.coursesPrintersOptions.findIndex(t => t.value === printer.value)
      );
    },
    setLoopPrintersIndex(printer) {
      this.selectedLoopPrintersIndexes.push(
        this.coursesPrintersOptions.findIndex(t => t.value === printer.value)
      );
    },
    selectCoursePrinters(option, printer) {
      let course = this.defaultPrinter.coursePrinters.find(
        p => p.objectDisplayName === printer.objectDisplayName
      );
      course.value = option.value;
      course.displayName = option.displayName;
    },
    selectAppPrintCoursePrinters(option, printer) {
      let course = this.defaultPrinter.appPrinters.find(
        p => p.objectDisplayName === printer.objectDisplayName
      );
      course.value = option.value;
      course.displayName = option.displayName;
    },
    selectLoopPrinters(option, printer) {
      let course = this.defaultPrinter.loopPrinters.find(
        p => p.objectDisplayName === printer.objectDisplayName
      );
      course.value = option.value;
      course.displayName = option.displayName;
    },
    setUsersPrintersIndex(printer) {
      this.selectedUsersIndexes.push(
        this.printersOptions.findIndex(p => p.value === printer.value)
      );
      if (this.selectedUsersIndexes.some(p => p === -1)) {
        this.hasUserPrinter = false;
      } else {
        this.hasUserPrinter = true;
      }
    },
    selectUserPrinters(option, printer) {
      let user = this.defaultPrinter.userPrinters.find(
        p => p.objectDisplayName === printer.objectDisplayName
      );
      user.value = option.value;
      user.displayName = option.displayName;
      user.objectValue = user.objectDisplayName;

      if (this.defaultPrinter.userPrinters.some(p => p.value === '')) {
        this.hasUserPrinter = false;
      } else {
        this.hasUserPrinter = true;
      }
    },
  },
  computed: {
    defaultPrinter() {
      return this.$store.getters['printers/defaultPrinter'];
    },
    printersOptions() {
      return this.$store.getters['printers/printersOptions'].filter(
        printer => printer.displayName.trim().toLowerCase() !== 'ej valt'
      );
    },
    appPrintersOptions() {
      return this.$store.getters['printers/printersOptions'];
    },
    coursesPrintersOptions() {
      return this.$store.getters['printers/coursesPrintersOptions'].filter(
        printer => printer.displayName.trim().toLowerCase() !== 'ej valt'
      );
    },
    appPrintercoursesPrintersOptions() {
      return this.$store.getters['printers/coursesPrintersOptions'];
    },
    club() {
      return this.$store.getters['clubs/club'];
    },
  },
  mounted() {
    this.$store.dispatch('clubs/fetchUserClub');
    this.$store.dispatch('printers/fetchPrintersOptions', null);
    this.$store
      .dispatch('printers/fetchPrintersOptions', {
        getStandardPrinterOption: true,
      })
      .then(() => {
        this.$store
          .dispatch('printers/fetchDefaultPrinter', null)
          .then(data => {
            if (data) {
              this.localUsers = data.userPrinters;
              if (data.useCoursePrinters || data.useUserPrinters) {
                this.isExpanded = true;
              }
              if (this.printersOptions && this.printersOptions.length > 0) {
                this.selectedCompetitionPrinter(data.competitionPrinter);
                this.selectedFriendlyPrinter(data.friendlyPrinter);
                this.selectedNeutralPrinter(data.neutralPrinter);
                this.selectedAppPrinter(data.appPrinter);
                if (data.terminalPrinters) {
                  if (data.terminalPrinters.length === 0) {
                    // Terminal printer isn't choosen, but there are none to choose from.
                    this.isTerminalPrintersChoosen = true;
                  }
                  data.terminalPrinters.map(printer =>
                    this.setTerminalPrintersIndex(printer)
                  );
                } else {
                  // Terminal printer isn't choosen, but there are none to choose from.
                  this.isTerminalPrintersChoosen = true;
                }
                if (data.coursePrinters)
                  data.coursePrinters.map(printer =>
                    this.setCoursesPrintersIndex(printer)
                  );
                if (data.loopPrinters)
                  data.loopPrinters.map(printer =>
                    this.setLoopPrintersIndex(printer)
                  );
                if (data.userPrinters)
                  data.userPrinters.map(printer =>
                    this.setUsersPrintersIndex(printer)
                  );
                if (data.useAppPrinters)
                  data.appPrinters.map(printer =>
                    this.setSweetspotCoursesPrintersIndex(printer)
                  );
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    // .then(() => {
    //   this.$store.dispatch('printers/fetchPrintersOptions', null)
    //   this.$store.dispatch('printers/fetchPrintersOptions', { getStandardPrinterOption: true })
    //     .finally(() => { this.isLoading = false })
    // })
  },
};
</script>
<style lang="scss">
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 20px;
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
}
.intro {
  margin-bottom: 3rem;
}
.choose-standard-printer {
  .select__wrapper {
    width: 40%;

    @include media('to-small') {
      width: 90%;
    }
  }
  .advanced-settings__selectedusers {
    display: flex;
    @include media('to-small') {
      flex-direction: column;
      margin-bottom: 20px;
    }
    .input .input__input {
      width: 90%;
    }
    .advanced-settings__selectedusers--remove {
      position: relative;
      top: 8px;
      left: 10px;
      color: color(gray);
      &.advanced-settings__selectedusers--disabled {
        pointer-events: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .advanced-settings__selectedusers--button {
    stroke: color(white);
  }
  .title-variation {
    svg,
    img {
      margin-right: measure(spacing-s);
    }
  }
}
</style>
