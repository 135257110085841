<template>
  <div class="legend">
    <div
      :class="['row', { hidden: hidden[i] }, { clickable: filterable }]"
      v-for="(label, i) in labels"
      :key="i"
      @click="click(i)">
      <div :style="'background: ' + colors[i] + ';'"></div>
      <div class="label">{{ label }}</div>
    </div>
  </div>
</template>
<script>
import { COLORS } from '@/components/charts/pie-chart-colors';
export default {
  props: {
    labels: {
      type: Array,
      default: () => {},
    },
    colors: { type: Array, default: () => COLORS },
    filterable: { type: Boolean, default: false },
  },
  data() {
    return {
      hidden: [false, false, false, false],
    };
  },
  mounted() {},
  methods: {
    click(i) {
      if (this.filterable) {
        this.$set(this.hidden, i, !this.hidden[i]);
        this.$emit('click', i);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.legend {
  display: flex;
  flex-direction: column;

  padding: 0.5rem;
  margin-left: 30px;

  font-size: 14px;
  text-transform: capitalize;

  .row {
    margin: 0.5rem;

    display: grid;
    grid-template-columns: 15px auto;

    &.clickable {
      cursor: pointer;
    }

    div:first-child {
      justify-self: center;
      margin-right: 10px;
      margin-top: 3px;
      border-radius: 2px;
      width: 15px;
      height: 15px;
    }

    .label {
      line-height: 20px;

      white-space: pre;

      text-wrap: wrap;
    }
  }
  .hidden {
    opacity: 0.5;
  }
}
</style>
