<template>
  <div
    v-if="showDiagram"
    class="diagram_header">
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="label">{{ $t('ReportTool.reportDiagram') }}</div>
    <template v-if="diagram">
      <p
        class="label"
        v-if="displayPeriod">
        Jan - {{ getMonth }}
      </p>
      <div class="slider-container">
        <div
          class="slider"
          :style="{ backgroundColor: include ? '#EAF6F6' : 'white' }"></div>
        <button
          v-if="include"
          @click="includeDiagram()"
          id="checked"
          :class="{ toggled: include }">
          <use-svg
            svg="bb-slider-check-mark"
            color="white"
            stroke="" />
        </button>
        <button
          aria-label="Knapp för att inkludera/exkludera diagrammet"
          v-else
          @click="includeDiagram()"
          id="checked"
          :style="{ backgroundColor: '#DADEDF' }"></button>
      </div>
      <span class="include-diagram-text">{{ $t('includeInTemplate') }}</span>
    </template>
    <div style="flex-basis: 100%; height: 0"></div>
    <div style="display: flex; gap: 1rem; margin-top: 1rem">
      <slot name="settings"></slot>
      <base-button
        v-if="diagram"
        @click="showDownloadModal = !showDownloadModal"
        backgroundColor="white"
        class="button-hover"
        :text="$t('download')">
        <img
          src="@/components/svg/bb-download-icon.svg"
          class="download-icon"
          alt="" />
      </base-button>
      <base-button
        v-if="diagram"
        @click="copy()"
        class="button-hover"
        backgroundColor="white"
        :text="$t('copy')">
        <use-svg svg="bb-copy-icon" />
      </base-button>
    </div>
    <modal-window
      :show="showDownloadModal"
      size="small">
      <div class="download_modal_content">
        <div class="download_modal_header">
          <strong>{{ $t('chooseFormatForDownload') }}</strong>
          <div
            class="close"
            @click="showDownloadModal = false">
            ✕
          </div>
        </div>
        <div
          :class="['option', { option_selected: chosenFormat == 0 }]"
          @click="chosenFormat = '0'">
          <strong>JPG</strong>
          <span class="subtitle">({{ $t('whiteBackground') }})</span>
          <div class="checkbox">
            <use-svg
              v-show="chosenFormat == 0"
              svg="bb-selected-icon" />
          </div>
        </div>
        <div
          :class="['option', { option_selected: chosenFormat == 1 }]"
          @click="chosenFormat = '1'">
          <strong>PNG</strong>
          <span class="subtitle">{{ $t('transparentBackground') }}</span>
          <div class="checkbox">
            <use-svg
              v-show="chosenFormat == 1"
              svg="bb-selected-icon" />
          </div>
        </div>
        <div class="download_button">
          <base-button
            @click="download()"
            backgroundColor="bb-primary"
            :text="$t('download')">
            <img
              src="@/components/svg/bb-download-icon.svg"
              class="download-white-icon"
              alt="" />
          </base-button>
        </div>
      </div>
    </modal-window>
  </div>
</template>
<script>
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import ModalWindow from '@/components/modal/modal';
import { toPng, toJpeg, toBlob } from 'html-to-image';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'BusinessboardReportDiagramHeader',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    UseSvg,
    ModalWindow,
  },
  props: {
    togglePosition: {
      type: Number,
      default: 4,
    },
    diagram: {
      type: Object,
    },
    elementID: {
      type: String,
      default: '',
    },
    include: {
      type: Boolean,
      default: true,
    },
    chosenFormat: String,

    templateId: String,
    showDiagram: Boolean,
  },
  data() {
    return {
      showDownloadModal: false,
      selectedDownloadOption: 0,
      toggle: this.include,
    };
  },
  computed: {
    isLoading() {
      return false;
    },
    getMonth() {
      var date = new Date();
      date.setMonth(this.diagram.monthTo - 1); // jan == 0
      return date.toLocaleString('default', { month: 'short' });
    },
    displayPeriod() {
      return this.diagram.reportType.includes('Game');
    },
  },
  methods: {
    fileName(elementID) {
      let diagramPart = elementID.split('_')[1];
      return {
        'economy-budget':
          this.$t('totalEstablishment') +
          ' (' +
          this.$t('Businessboard.budget').toLowerCase() +
          ')',
        'economy-company-budget':
          this.$t('Businessboard.companies') +
          ' (' +
          this.$t('Businessboard.budget').toLowerCase() +
          ')',
        'economy-company': this.$t('Businessboard.companies'),
        'economy-total': this.$t('totalEstablishment'),
        'game-course-rounds': this.$t('roundsDistributionCourse'),
        'game-greenfee-income': this.$t('greenFeeRevenue'),
        'game-member-rounds': this.$t(
          'ReportTool.roundsDistributionMemberGuestXFactorComma'
        ),
        'game-month-rounds': this.$t('roundsPerMonth'),
        'game-total-rounds': this.$t('totalRounds'),
        'member-income': this.$t('GameCalculation.memberRevenue'),
        'member-total': this.$t('ReportTool.totalMembersAndMemberPerCategory'),
      }[diagramPart];
    },
    download() {
      this.showDownloadModal = false;

      if (this.elementID === '') {
        return;
      }

      const toFile = this.chosenFormat === '0' ? toJpeg : toPng;
      const downloadOptions =
        this.chosenFormat === '0'
          ? { backgroundColor: 'white' }
          : { backgroundColor: 'transparent' };

      toFile(document.getElementById(this.elementID), downloadOptions)
        .then(
          function (dataUrl) {
            var link = document.createElement('a');

            link.download =
              this.fileName(this.elementID) +
              '.' +
              (this.chosenFormat === '0' ? 'jpg' : 'png');

            link.href = dataUrl;

            link.click();
          }.bind(this)
        )
        .catch(function (error) {
          console.error('Oops, something went wrong!', error);
        });
    },

    copy() {
      if (this.elementID === '') return;
      toBlob(document.getElementById(this.elementID))
        .then(function (blob) {
          navigator.clipboard.write([
            // eslint-disable-next-line no-undef
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
        })
        .catch(function (error) {
          console.error('Oops, something went wrong!', error);
        });
    },
    slider() {
      if (this.toggle) {
        this.toggle = false;
        this.togglePosition = 0;
      } else if (!this.toggle || !this.include) {
        this.toggle = true;
        this.togglePosition = 4;
      }
    },
    includeDiagram() {
      this.$store.dispatch('businessboardReportingTool/includeDiagram', {
        templateId: this.templateId,
        diagramType: this.diagram.reportType,
      });
      this.$emit('includeDiagram', this.toggle);
    },

    includeDiagramHandler() {
      this.slider();
      this.includeDiagram();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
.diagram_header {
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-bottom: 10px;
  border-radius: 8px 8px 0 0;
  line-height: 26px;
}
.label {
  background: #f0f5f5;
  border: 1px solid #abded1;
  border-radius: 3px;
  padding: 3px 10px;
  height: 25px;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  color: #0e3746;
  margin: 0px 0px 0px 10px;
  font-family: 'HindSiliguri';
}
.title {
  font-weight: 700;
  font-size: 14px;
}
$toggleWidth: 25px;
$toggleWidth2: 50px;

.slider-container {
  margin-left: auto;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  position: relative;
  width: $toggleWidth2;
  height: $toggleWidth;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: $toggleWidth2;
  height: $toggleWidth;
  border: 1px solid black;
  border-radius: $toggleWidth;
  transition: background-color 0.2s;
}
.toggled {
  margin-left: $toggleWidth;
}

#checked {
  position: absolute;
  background-color: #abded1;
  border-radius: 50%;
  width: $toggleWidth;
  height: $toggleWidth;
  transition: margin-left 0.2s;
  border: 1px solid black;
  svg {
    margin-left: -5px;
    margin-top: 2px;
  }
}

.download-icon {
  margin-right: 0.625rem;
  margin-bottom: 0.125rem;
}

.download-white-icon {
  margin-right: 0.625rem;
  margin-bottom: 0.125rem;
  filter: invert(100%);
}
.button-hover {
  &:hover {
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}
.include-diagram-text {
  margin-left: 1rem;
  line-height: 24px;
  font-weight: 700;
  font-size: 14px;
}
.download_button {
  margin: 0 1rem;
  display: flex;
  flex-flow: column;
  margin-top: 0.5rem;
  ::v-deep .button {
    border-radius: 3px;
  }
}
</style>
