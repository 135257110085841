/* Store module to handle Printer Queue */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  printerQueue: {
    rows: [],
    columns: [],
  },
  printerQueues: {
    rows: [],
    columns: [],
  },
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
};

const mutations = {
  printerQueue(state, payload) {
    state.printerQueue.rows = payload.rows;
    state.printerQueue.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
  printerQueues(state, payload) {
    state.printerQueues.rows = payload.rows;
    state.printerQueues.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
  deletePrinterQueue(state, payload) {
    state.printerQueue.rows = payload;
    state.printerQueue.columns = payload;
    state.pagination.currentPage = 0;
    state.pagination.pageCount = 0;
    state.pagination.pageSize = 20;
    state.pagination.rowCount = 0;
    state.pagination.firstRowOnPage = 0;
    state.pagination.lastRowOnPage = 0;
  },
  deleteMarkedPrinterQueues(state, payload) {
    if (payload.result.succeeded.length > 0) {
      payload.result.succeeded.map(s => {
        Vue.delete(
          state.printerQueue.rows,
          state.printerQueue.rows.findIndex(row => row.id === s.id)
        );
      });
    }
    if (payload.result.failed.length > 0) {
      EventBus.$emit('toast', { type: 'error', msg: payload.message });
    }
  },
};

const getters = {
  printerQueue: state => {
    return state.printerQueue;
  },
  printerQueues: state => {
    return state.printerQueues;
  },
  pagination: state => {
    return state.pagination;
  },
  currentPage: state => {
    return state.pagination.currentPage;
  },
  nextPage: state => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: state => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
};

const actions = {
  fetchPrinterQueue: async (context, params) => {
    let path = 'printqueue';

    if (params && params.id) {
      path += '/' + params.id;
    }

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => {
          if (params[k] !== undefined) {
            return esc(k) + '=' + esc(params[k]);
          }
        })
        .filter(q => q !== undefined)
        .join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('printerQueue', data.result);
    }
  },
  deletePrinterQueue: async (context, params) => {
    let path = 'printqueue/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.delete(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('deletePrinterQueue', data.result);
    }
  },
  deleteMarkedPrinterQueues: async (context, params) => {
    let path = 'printqueue/delete';

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('deleteMarkedPrinterQueues', {
        result: data.result,
        message: data.message,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
