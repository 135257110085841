<template>
  <div
    :title="tooltip"
    @click="$emit('toggle')"
    class="slider-container"
    :style="{ opacity: disabled ? 0.3 : 1 }">
    <div
      class="slider"
      :style="{
        backgroundColor: checked ? '#EAF6F6' : 'white',
        opacity: disabled ? 0.3 : 1,
      }"></div>
    <button
      v-if="checked"
      :disabled="disabled"
      id="checked"
      :class="{ toggled: checked }">
      <use-svg
        height="16"
        width="16"
        svg="bb-slider-check-mark"
        color="white"
        stroke="" />
    </button>
    <button
      aria-label="Knapp för att inkludera/exkludera diagrammet"
      v-else
      id="checked"
      :disabled="disabled"
      :style="{ backgroundColor: '#DADEDF' }"></button>
  </div>
</template>
<script>
import UseSvg from '@/components/use-svg';

export default {
  name: 'BBToggle',
  components: {
    UseSvg,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
$toggleWidth: 20px;
$toggleWidth2: 40px;

.slider-container {
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  position: relative;
  width: $toggleWidth2;
  height: $toggleWidth;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: $toggleWidth2;
  height: $toggleWidth;
  border: 1px solid black;
  border-radius: $toggleWidth;
  transition: background-color 0.2s;
}
.toggled {
  margin-left: $toggleWidth;
}

#checked {
  position: absolute;
  background-color: #abded1;
  border-radius: 50%;
  width: $toggleWidth;
  height: $toggleWidth;
  transition: margin-left 0.2s;
  border: 1px solid black;
  svg {
    margin-left: -6.3px;
    margin-top: 1.1px;
  }
}
</style>
