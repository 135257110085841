<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <h1>{{ $t('Edit category') }}</h1>
      <form
        noValidate
        @submit.prevent="editCategory">
        <div class="content__input-group">
          <div class="input-group">
            <div class="input">
              <label
                for="categoryName"
                class="input__label">{{ $t('category') }}*</label>
              <input
                id="categoryName"
                class="input__input"
                type="text"
                required="true"
                name="categoryName"
                v-model="category.membershipCategoryName" />
            </div>
            <div class="resultgroup-select"></div>
          </div>
          <div class="input-group--inline user__actions">
            <router-link to="/businessboard/data">
              <base-button :text="$t('cancel')"> </base-button>
            </router-link>
            <base-button
              :text="$t('Businessboard.editCategory')"
              type="submit"
              v-element-loader="{ loaderKey: 'patch-category' }"
              :disabled="!canCreate" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'EditCategory',
  components: {
    BaseButton,
  },
  directives: {
    elementLoader,
  },

  props: {
    id: {
      type: String,
      default: '0',
    },
  },

  data() {
    return {
      category: {},
      types: {},
    };
  },

  methods: {
    editCategory() {
      this.$store
        .dispatch('businessboard/patchMembershipCategory', {
          id: this.id,
          exclude: this.category.exclude,
          name: this.category.membershipCategoryName,
        })
        .then(() => {
          this.$router.push({ path: '/businessboard/data' });
        });
    },
  },

  computed: {
    canCreate() {
      return this.category.membershipCategoryName;
    },
  },

  created() {
    this.category =
      this.$store.state.businessboard.membershipcategories.membershipCategories.rows.find(
        r => r.id === this.id
      );
  },
};
</script>

<style lang="scss" scoped>
.golfoffice {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.golfoffice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;

  button {
    margin-left: 1.25rem;
  }
}
</style>
