<template>
  <div
    class="list-table__wrapper"
    v-if="!isLoading">
    <div class="list-table vertical">
      <template v-for="(header, index) in headers">
        <div
          v-if="index === 0 || showTable"
          :key="`table-${index}`"
          :class="[
            index % 2 === 0 ? 'even' : 'odd',
            index === 0 ? 'head' : 'level0',
            {
              clickable: header.drillDown,
              total: header.isTotal,
              highlight: header.rowType === 2,
            },
          ]">
          <div
            @click="header.drillDown ? expandDrilldown(index) : null"
            class="drilldown">
            <th>
              <span
                v-if="index === 0"
                @click="copyData"
                :title="$t(copyDataText)">
                <use-svg svg="bb-copy-icon2" />
              </span>
              {{ header.displayName }}
            </th>
            <div class="drilldown_row">
              <td
                v-for="(item, j) in items"
                :key="j">
                <template v-if="item[header.value] != '-2 133 731 337'">
                  {{ item[header.value] }}
                </template>
              </td>
            </div>
            <td
              class="svg-td"
              v-if="index === 0"
              @click="toggleShow">
              <use-svg
                svg="arrow-right"
                :class="showTable ? 'svg--180-negative' : ''" />
            </td>
            <td
              class="svg-td"
              v-else-if="showTable && header.hasChildren"
              @click="isDrilldownExpanded(index)">
              <use-svg
                svg="arrow-right"
                :class="[
                  isDrilldownExpanded(index) === true
                    ? 'svg--180-negative'
                    : '',
                ]" />
            </td>
            <td v-else></td>
          </div>
          <template
            v-if="
              header.drillDown && showTable && header.drillDown.rows.length > 0
            ">
            <drill-down-table
              :class="[isDrilldownExpanded(index) === true ? 'show' : 'hide']"
              v-for="(dheader, dindex) in header.drillDown.columns"
              :key="`drill-down-table-${index}-${dindex}`"
              :items="header.drillDown.rows"
              :header="dheader"
              :level="1"
              :index="dindex" />
          </template>
        </div>
      </template>
    </div>
  </div>
  <div v-else>
    <list-table-loader />
  </div>
</template>

<script>
import DrillDownTable from './drill-down-table.vue';
import UseSvg from '@/components/use-svg';
import ListTableLoader from '../reports-templates/list-table-loader.vue';

export default {
  name: 'ListTableGameCalculation',
  components: {
    DrillDownTable,
    UseSvg,
    ListTableLoader,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    expandedDrilldowns: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTable: !this.hide,
      copyDataText: 'copyTableData',
    };
  },

  methods: {
    isDrilldownExpanded(index) {
      const place = this.expandedDrilldowns.indexOf(index);
      if (place === -1) {
        return false;
      }
      return true;
    },
    expandDrilldown(index) {
      const place = this.expandedDrilldowns.indexOf(index);
      if (place === -1) {
        this.expandedDrilldowns.push(index);
      } else {
        this.expandedDrilldowns.splice(place, 1);
      }
    },
    toggleShow() {
      this.showTable = !this.showTable;
    },
    copyData() {
      let text = '';
      for (let header of this.headers) {
        text += header.displayName.replace(/\r?\n|\r/g, ' ');
        for (let item of this.items) {
          text += '\t' + item[header.value].replace(/\r?\n|\r/g, ' ');
        }
        text += '\n';
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.copyDataText = 'copiedToClipboard';
        },
        function (err) {
          this.copyDataText = '';
          console.error('Async: Could not copy text: ', err);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.list-table__wrapper {
  overflow-x: auto;
}
.list-table {
  font-size: font(h4);
  display: grid;
  border-collapse: collapse;
  width: 100%;
  th {
    text-align: left;
    padding: 0 0.625rem;
    overflow-x: visible;
    &:first-child {
      padding-left: 0.8rem;
    }
    &:last-child {
      padding-right: 0.8rem;
    }
    .list-table__button {
      border: none;
      background-color: color(gray-light);
      min-height: 2.625rem;
      padding: 0;
      span {
        font-weight: bold;
        margin-right: 0.325rem;
        position: relative;
        top: -2px;
      }
    }
    .list-table__div {
      min-height: 2.625rem;
      span {
        font-weight: bold;
        margin-right: 0.325rem;
        position: relative;
        top: 10px;
      }
    }
  }

  div {
    &.total {
      background-color: #78aea4 !important;
    }
    &.highlight {
      background-color: #dee8f7 !important;
    }
    &.head {
      background-color: color(bb-primary);
      border-radius: 3px;
      span {
        vertical-align: text-top;
        cursor: pointer;
        margin-right: 0.25rem;
      }
      div:first-child {
        color: white;
      }
      td {
        white-space: pre-line;
      }
    }
    &.clickable {
      cursor: pointer;
    }
  }
}
.show {
  display: grid;
}
.hide {
  display: none;
}
.level0 {
  background-color: #f0f5f5;
  border-radius: 3px;
  margin: 2px;
}
.drilldown {
  display: grid;
  grid-template-columns: 15vw auto 30px;
  th {
    padding: 0.525rem;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  th:hover {
    overflow-x: visible;
  }
  td {
    padding: 0.525rem;
  }
  &_row {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    td {
      white-space: pre-line;
      white-space: nowrap;
      padding: 0.525rem;
      text-align: right;
    }
  }
}
.svg-td > svg {
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
}
.button_copy {
  cursor: pointer;
  padding: 0.3rem 1rem;
  margin: 0.3rem 0;
  line-height: 23px;
  float: right;
  border: 1px solid #0e3746;
  border-radius: 3px;
  > svg {
    position: relative;
    top: 3px;
    margin-left: 0.3rem;
  }
}
</style>
