<template>
  <div :class="{ 'template-diagrams': showAllDiagrams || includeDiagram }">
    <div class="header">
      <diagram-header
        :elementID="templateId + '_member-income'"
        :include="includeDiagram"
        @includeDiagram="e => (includeDiagram = e)"
        :chosenFormat="fileFormat"
        :diagram="diagram"
        :templateId="templateId"
        :showDiagram="showAllDiagrams || includeDiagram">
        <template #title>
          {{ $t('GameCalculation.memberRevenue') }}<br />
          <template v-if="diagram">
            {{
              `${$t('outcome')} ${$t('MonthsShort.Jan').toLowerCase()} - ${
                months[diagram.monthTo]
              }`
            }}
            {{ $t('againstAcuBudget').toLowerCase() }} {{ activeYear }}<br />
            {{ $t('forecastAgainst') }} {{ activeYear }}
            {{ $t('yearsBudget').toLowerCase() }}<br />
          </template>
        </template>

        <template #settings>
          <modal-filter
            :titleText="$t('GameCalculation.memberRevenue')"
            :comparisonYearCount="4"
            :showDisplaySettings="false"
            :storedFilter="diagram?.settings || '{}'"
            @changeFilter="e => toggleDiagram(e)"
            @saveFilter="e => saveFilter(e)" />
        </template>
      </diagram-header>
    </div>
    <div
      v-if="diagram && (showAllDiagrams || includeDiagram)"
      class="diagram"
      :id="templateId + '_member-income'">
      <div :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
        <h1
          v-if="settings.showHeaders"
          class="pieChart-title">
          {{ $t('GameCalculation.memberRevenue') }}
        </h1>

        <div class="section">
          <div class="divide-charts">
            <h4>
              {{
                `${$t('outcome')} ${$t('MonthsShort.Jan').toLowerCase()} - ${
                  months[diagram.monthTo]
                }`
              }}
              {{ $t('against') }}<br />
              {{ $t('acuBudget').toLowerCase() }} {{ activeYear }}
            </h4>
            <donut-chart
              v-if="!isLoading"
              :sum1="diagram.data.income"
              :sum2="diagram.data.accumulatedBudget"
              :showAsPercentage="false" />
            <pie-chart-loader v-if="isLoading" />
          </div>
          <div class="divide-charts legend">
            <reports-years-legend
              :years="diagram.data.compareYears"
              :unit="$t('denomination')" />
          </div>
          <div class="divide-charts">
            <h4>
              {{ $t('forecastAgainst') }} {{ activeYear }}
              {{ $t('yearsBudget').toLowerCase() }}<br />&nbsp;
            </h4>
            <div class="border-left">
              <donut-chart
                v-if="!isLoading"
                :sum1="diagram.data.forecast"
                :sum2="diagram.data.yearBudget"
                :showAsPercentage="true" />
              <pie-chart-loader v-if="isLoading" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthsLabelsShort } from '@/core/consts';

import ModalFilter from '@/components/reports-templates/settings-modal/gamecalculation';
import DiagramHeader from '@/components/reports-templates/diagram-header';
import DonutChart from '@/components/charts/donut-chart-reports';
import ReportsYearsLegend from '@/components/reports-templates/legend/reports-legend-years';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import { COLORS } from '../../charts/pie-chart-colors';
import PieChartLoader from '../pie-chart-loader.vue';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixin],
  data: function () {
    return {
      COLORS,
    };
  },
  components: {
    ModalFilter,
    DiagramHeader,
    DonutChart,
    ReportsYearsLegend,
    PieChartLoader,
  },
  props: {
    diagram: {},
    templateId: String,
    include: Boolean,
    showAllDiagrams: Boolean,
  },
  computed: {
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
    fileFormat() {
      return this.$store.getters['businessboardReportingTool/settings']
        .fileFormat;
    },
    includeDiagram() {
      return this.diagram?.includedInTemplate || false;
    },
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    activeYear() {
      return JSON.parse(this.diagram.settings).Years[0];
    },
    months() {
      return [''].concat(
        MonthsLabelsShort.map(l => this.$t(`MonthsShort.${l}`).toLowerCase())
      );
    },
  },
  methods: {
    toggleDiagram(e) {
      this.diagramToRender = e.show;
      this.$store.dispatch('businessboardReportingTool/postDiagram', {
        templateId: this.templateId,
        settings: e,
        diagramType: 2,
      });
    },
    saveFilter(e) {
      e.save = true;
      this.toggleDiagram(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
.pieChart {
  width: 20rem;
  height: 20rem;
}

.section {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 16px;
  margin-left: 2rem;
  display: flex;
  justify-content: space-evenly;
  h4 {
    font-size: 18px;
    text-align: center;
  }
}

.pieChart-title {
  margin-left: 4rem;
  text-align: center;
}

.chart-year {
  font-size: medium;
  font-weight: 600;
}

.divide-charts {
  display: flex;
  flex-direction: column;
}
.legend {
  width: 20rem;
  margin: 0rem 0rem 0 0rem;
}
.border-left {
  border-left: 1px solid #dadedf;
}
</style>
