import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  budgetList: [],
  forecastList: [],
  createType: [],
  financialYears: [],
  budget: {},
  forecast: {},
  budgetStatus: [],
  budgetFilterCompany: {},
  budgetFilterResultUnit: {},
  companyBudgetSelect: {},
  companyForecastSelect: {},
  previousBudget: {},
  previousOutcome: {},
  budgetSelect: [],
  budgetResultsYearSelect: [],
  budgetResultsData: {},
  budgetCalculation: {},
  budgetCalculationResultUnit: {},
  distributionTemplates: [],
  budgetFilter: {},
  budgetCompanyFilter: {},
  budgetResultunitFilter: {},
  displayedBudgets: [],
};

const mutations = {
  budgetList(state, payload) {
    state.budgetList = payload;
  },
  forecastList(state, payload) {
    state.forecastList = payload;
  },
  createType(state, payload) {
    state.createType = payload;
  },
  financialYears(state, payload) {
    state.financialYears = payload;
  },
  budget(state, payload) {
    state.budget = payload;
  },
  forecast(state, payload) {
    state.forecast = payload;
  },
  previousBudget(state, payload) {
    state.previousBudget = payload;
  },
  previousOutcome(state, payload) {
    state.previousOutcome = payload;
  },
  budgetStatus(state, payload) {
    state.budgetStatus = payload;
  },
  budgetFilterCompany(state, payload) {
    state.budgetFilterCompany = payload;
  },
  budgetFilterResultUnit(state, payload) {
    state.budgetFilterResultUnit = payload;
  },
  companyBudgetSelect(state, payload) {
    state.companyBudgetSelect[payload.id] = payload.data;
  },
  companyForecastSelect(state, payload) {
    state.companyForecastSelect[payload.id] = payload.data;
  },
  budgetSelect(state, payload) {
    state.budgetSelect = payload;
  },
  budgetResultsYearSelect(state, payload) {
    state.budgetResultsYearSelect = payload;
  },
  budgetResultsData(state, payload) {
    state.budgetResultsData = payload;
  },
  budgetCalculation(state, payload) {
    state.budgetCalculation = payload;
  },
  budgetCalculationResultUnit(state, payload) {
    state.budgetCalculationResultUnit = payload;
  },
  distributionTemplates(state, payload) {
    state.distributionTemplates = payload;
  },
  budgetFilter(state, payload) {
    state.budgetFilter = payload;
  },
  budgetCompanyFilter(state, payload) {
    state.budgetCompanyFilter = payload;
  },
  budgetResultunitFilter(state, payload) {
    state.budgetResultunitFilter = payload;
  },
  displayedBudgets(state, payload) {
    state.displayedBudgets = payload;
  },
};

const getters = {
  budgetList: state => {
    return state.budgetList;
  },
  forecastList: state => {
    return state.forecastList;
  },
  createType: state => {
    return state.createType;
  },
  financialYears: state => {
    return state.financialYears;
  },
  budget: state => {
    return state.budget;
  },
  forecast: state => {
    return state.forecast;
  },
  previousBudget: state => {
    return state.previousBudget;
  },
  previousOutcome: state => {
    return state.previousOutcome;
  },
  budgetStatus: state => {
    return state.budgetStatus;
  },
  budgetFilterCompany: state => {
    return state.budgetFilterCompany;
  },
  budgetFilterResultUnit: state => {
    return state.budgetFilterResultUnit;
  },
  companyBudgetSelect: state => {
    return state.companyBudgetSelect;
  },
  companyForecastSelect: state => {
    return state.companyForecastSelect;
  },
  budgetSelect: state => {
    return state.budgetSelect;
  },
  budgetResultsYearSelect: state => {
    return state.budgetResultsYearSelect;
  },
  budgetResultsData: state => {
    return state.budgetResultsData;
  },
  budgetCalculation: state => {
    return state.budgetCalculation;
  },
  budgetCalculationResultUnit: state => {
    return state.budgetCalculationResultUnit;
  },
  distributionTemplates: state => {
    return state.distributionTemplates;
  },
  budgetFilter: state => {
    return state.budgetFilter;
  },
  budgetCompanyFilter: state => {
    return state.budgetCompanyFilter;
  },
  budgetResultunitFilter: state => {
    return state.budgetResultunitFilter;
  },
  displayedBudgets: state => {
    return state.displayedBudgets;
  },
};

const actions = {
  fetchCreateType: async context => {
    await fetch(context, 'businessboard/budgetcreatetype', 'createType');
  },

  fetchFinancialYears: async context => {
    await fetch(
      context,
      'businessboard/budgetfinancialyears',
      'financialYears'
    );
  },

  fetchBudgetList: async (context, params) => {
    await fetch(
      context,
      'businessboard/company/' + params.id + '/budget',
      'budgetList'
    );
  },
  fetchForecastList: async (context, params) => {
    await fetch(
      context,
      'businessboard/company/' + params.id + '/forecast',
      'forecastList'
    );
  },

  postBudget: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/budget/' + params.companyId,
      'post-budget',
      'createType'
    );
  },

  fetchBudget: async (context, params) => {
    await fetch(context, 'businessboard/budget/' + params.id, 'budget');
  },
  fetchForecast: async (context, params) => {
    await fetch(context, 'businessboard/forecast/' + params.id, 'forecast');
  },
  fetchPreviousBudget: async (context, params) => {
    if (params.compareId === undefined || params.compareId === '') {
      params.compareId = '0';
    }
    let path =
      'businessboard/budget/' + params.id + '/budget/' + params.compareId;
    // await fetch(context, 'businessboard/budget/' + params.id + '/budget/' + params.compareId, 'previousBudget')
    try {
      let response = await Vue.axios.get(path);
      if (response.data.success === false) {
        if (params.compareId !== '0') {
          EventBus.$emit('toast', {
            type: 'error',
            msg: response.data.message,
            request: path,
          });
        }
        context.commit('previousBudget', {});
      } else {
        context.commit('previousBudget', response.data.result);
      }
    } catch (error) {
      console.error(error, error.response.data);
    }
  },
  fetchPreviousOutcome: async (context, params) => {
    if (params.financialYear === undefined || params.financialYear === '') {
      params.financialYear = '0';
    }
    await create(
      context,
      params,
      'businessboard/budget/' + params.id + '/result',
      'loader-previousOutcome',
      'previousOutcome'
    );
  },
  patchBudget: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/budget/' + params.id,
      'patch-budget'
    );
  },
  patchForecast: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/forecast/' + params.id,
      'patch-forecast'
    );
  },
  fetchBudgetStatus: async context => {
    await fetch(context, 'businessboard/budget/status', 'budgetStatus');
  },
  fetchBudgetFilterCompany: async context => {
    await fetch(
      context,
      'businessboard/budget/filter/company',
      'budgetFilterCompany'
    );
  },
  fetchBudgetFilterResultUnit: async (context, params) => {
    await fetch(
      context,
      'businessboard/budget/filter/resultunit/' + params.id,
      'budgetFilterResultUnit'
    );
  },
  fetchCompanyBudgetSelect: async (context, params) => {
    try {
      let response = await Vue.axios.get(
        'businessboard/company/' + params.id + '/budgetselect'
      );
      context.commit('companyBudgetSelect', {
        id: params.id,
        data: response.data.result,
      });
    } catch (error) {
      console.error(error, error.response.data);
    }
  },
  fetchBudgetSelect: async (context, params) => {
    await fetch(
      context,
      'businessboard/company/' + params.id + '/budgetselect',
      'budgetSelect'
    );
  },
  fetchCompanyForecastSelect: async (context, params) => {
    try {
      let response = await Vue.axios.get(
        'businessboard/company/' + params.id + '/forecastselect'
      );
      context.commit('companyForecastSelect', {
        id: params.id,
        data: response.data.result,
      });
    } catch (error) {
      console.error(error, error.response.data);
    }
  },
  fetchBudgetResultsYearSelect: async context => {
    await fetch(
      context,
      'businessboard/budgetresultselect',
      'budgetResultsYearSelect'
    );
  },
  fetchBudgetResultsData: async (context, params) => {
    await fetch(
      context,
      'businessboard/budget/company/' + params.id + '/result/' + params.year,
      'budgetResultsData'
    );
  },
  postStatusValidation: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/budget/validatestatus',
      'validate-status',
      'validate-status'
    );
  },
  fetchBudgetCalculation: async (context, params) => {
    let path = 'businessboard/budgetcalculation';
    if (params.id) path += '/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.post(path, { columns: params.columns });
      context.commit('budgetCalculation', response.data.result);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  fetchBudgetCalculationResultUnit: async (context, params) => {
    let path = 'businessboard/budgetcalculation/resultunit';
    if (params.id) path += '/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.post(path, { columns: params.columns });
      context.commit('budgetCalculationResultUnit', response.data.result);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
  },
  removeBudget: async (context, params) => {
    await remove('businessboard/budget/' + params.id);
  },
  removeForecast: async (context, params) => {
    await remove('businessboard/forecast/' + params.id);
  },
  fetchDistributionTemplates: async context => {
    await fetch(
      context,
      'businessboard/distributiontemplates',
      'distributionTemplates'
    );
  },
  fetchBudgetFilter: async context => {
    let response = await Vue.axios.get('businessboard/budget/filter');
    context.commit('budgetFilter', response.data);
  },
  postBudgetFilter: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/budget/filter',
      'post-budgetFilter'
    );
  },
  fetchBudgetCompanyFilter: async (context, id) => {
    let response = await Vue.axios.get(
      'businessboard/budget/company/' + id + '/filter'
    );
    context.commit('budgetCompanyFilter', response.data);
  },
  postBudgetCompanyFilter: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/budget/company/' + params.id + '/filter',
      'post-budgetCompanyFilter'
    );
  },
  patchResultUnitsToForecast: async (context, params) => {
    await patch(
      context,
      params,
      'businessboard/forecast/' + params.id + '/resultunit',
      'add-result-units',
      'patch-resultUnitsToForecast'
    );
  },
  fetchBudgetResultunitFilter: async context => {
    let response = await Vue.axios.get(
      'businessboard/budget/resultunit/filter'
    );
    context.commit('budgetResultunitFilter', response.data);
  },
  postBudgetResultunitFilter: async (context, params) => {
    await create(
      context,
      params,
      'businessboard/budget/resultunit/filter',
      'post-budgetResultunitFilter'
    );
  },
  exportBudget: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'export-budget', { root: true });
    let path =
      'businessboard/' + params.budgetId + '/export/' + params.exportType;

    let response = {};
    try {
      response = await Vue.axios.get(path, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'export-budget', { root: true });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', params.fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
  exportForecast: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'export-forecast', {
      root: true,
    });
    let path = 'businessboard/forecast/' + params.budgetId + '/export';

    let response = {};
    try {
      response = await Vue.axios.get(path, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'export-forecast', {
      root: true,
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', params.fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
};
async function patch(context, params, path, loader, commit) {
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  let response = {};
  try {
    response = await Vue.axios.patch(path, params);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
  const data = response.data;
  if (!data.success) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: data.message,
      request: path,
    });
  }
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  if (commit && response && response.data) {
    const data = response.data;
    context.commit(commit, data.result);
  }
}
async function fetch(context, path, commit) {
  try {
    let response = await Vue.axios.get(path);
    context.commit(commit, response.data.result);
  } catch (error) {
    console.error(error, error.response.data);
  }
}

async function create(context, params, path, loader, commit) {
  context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
  try {
    let response = await Vue.axios.post(path, params);
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
    context.commit(commit, response.data.result);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
}
async function remove(path) {
  let response = {};
  try {
    response = await Vue.axios.delete(path);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
  if (response && response.data && response.data.success) {
    EventBus.$emit('toast', {
      type: 'success',
      msg: response.data.message,
      request: path,
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
