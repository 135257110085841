<template>
  <div
    class="back-button"
    @click="$router.go(-1)">
    <span class="back-icon">&#8592;</span>
    {{ buttonText }}
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    to: {
      type: String,
      default: '.',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    buttonText() {
      return this.text ? this.text : this.$t('back');
    },
  },
};
</script>

<style lang="scss" scoped>
.back-button {
  width: fit-content;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 20px;
  color: #fff;
  background-color: color(bb-primary);
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.back-button:hover {
  background-color: color(gray-darkest);
}

.back-icon {
  margin-right: 5px;
}
</style>
