import store from '@/store';
import _ from 'lodash';

const watchers = new WeakMap();

const elementLoader = {
  bind(el, binding) {
    const params = binding.value;
    const elementStyle = window.getComputedStyle(el);
    const elementBefore = window.getComputedStyle(el, ':before').getPropertyValue('content');
    const useThisPseudo = elementBefore === undefined ? 'before' : 'after';

    if (params.loading !== undefined) {
      toggleLoader(binding, el, elementStyle, useThisPseudo, params.loading);
    } else {
      watchers[el] = store.watch((state, getters) => getters['ui/activeLoaders'], configs => {
        const showLoader = _.some(configs, cfg => {
          if (cfg !== params.loaderKey) {
            return false;
          }

          return true;
        });

        toggleLoader(binding, el, elementStyle, useThisPseudo, showLoader);
      });
    }
  },
  componentUpdated(el, binding) {
    const params = binding.value;
    const elementStyle = window.getComputedStyle(el);
    const elementBefore = window.getComputedStyle(el, ':before').getPropertyValue('content');
    const useThisPseudo = elementBefore === undefined ? 'before' : 'after';

    if (params.loading !== undefined) {
      toggleLoader(binding, el, elementStyle, useThisPseudo, params.loading);
    }
  },
  unbind(el) {
    const unwatch = watchers[el];
    if(_.isFunction(unwatch)) {
      unwatch();
      watchers.delete(el);
    }
  },
};

function toggleLoader(binding, el, elementStyle, useThisPseudo, toggle) {
  if (toggle) {
    if (elementStyle.position !== '' || elementStyle.position !== 'static') {
      el.style.position = 'relative';
    }

    if (elementStyle.overflow === undefined || elementStyle.overflow !== 'hidden') {
      el.style.overflow = 'hidden';
    }

    el.classList.add(`element--is-loading-${useThisPseudo}`);
  } else {
    el.classList.remove(`element--is-loading-${useThisPseudo}`);
    el.style.position = '';
    el.style.overflow = '';
  }
}

export default elementLoader;
