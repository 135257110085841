<template>
  <div
    :class="[
      { clickable: header.drillDown },
      'level' + level,
      isDrilldownExpanded(index) ? 'expanded' : 'collapsed',
    ]">
    <div
      @click="header.drillDown ? expandDrilldown(index) : null"
      :class="['drilldown']">
      <th>{{ header.displayName }}</th>
      <div class="drilldown_row">
        <td
          v-for="(item, i) in items"
          :key="i">
          {{ item[header.value] }}
        </td>
      </div>
      <td
        class="svg-td"
        v-if="header.drillDown && header.hasChildren"
        @click="isDrilldownExpanded(index)">
        <use-svg
          svg="arrow-right"
          :class="[
            isDrilldownExpanded(index) === true ? 'svg--180-negative' : '',
          ]" />
      </td>
      <td v-else></td>
    </div>
    <template v-if="header.drillDown">
      <drill-down-table
        :class="[isDrilldownExpanded(index) === true ? 'show' : 'hide']"
        v-for="(dheader, j) in header.drillDown.columns"
        :key="`drill-down-table-${level}-${j}`"
        :items="header.drillDown.rows"
        :header="dheader"
        :level="level + 1" />
    </template>
  </div>
</template>
<script>
import DrillDownTable from './drill-down-table.vue';
import UseSvg from '@/components/use-svg';

export default {
  name: 'DrillDownTable',
  components: {
    DrillDownTable,
    UseSvg,
  },
  props: {
    header: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
    expandedDrilldowns: {
      type: Array,
      default: () => [],
    },
    colspan: {
      type: Number,
      default: 2,
    },
    level: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    isDrilldownExpanded(index) {
      const place = this.expandedDrilldowns.indexOf(index);
      if (place === -1) {
        return false;
      }
      return true;
    },
    expandDrilldown(index) {
      const place = this.expandedDrilldowns.indexOf(index);
      if (place === -1) {
        this.expandedDrilldowns.push(index);
      } else {
        this.expandedDrilldowns.splice(place, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  display: contents;
}
.list-table__wrapper {
  overflow-x: auto;
}
.list-table {
  border-collapse: collapse;
  width: 100%;
  th {
    text-align: left;
    padding: 0 0.625rem;
    font-weight: 400;
    &:first-child {
      padding-left: 0.8rem;
    }
    &:last-child {
      padding-right: 0.8rem;
    }
    .list-table__button {
      border: none;
      background-color: color(gray-light);
      min-height: 2.625rem;
      padding: 0;
      span {
        margin-right: 0.325rem;
        position: relative;
        top: -2px;
      }
    }
    .list-table__div {
      min-height: 2.625rem;
      span {
        margin-right: 0.325rem;
        position: relative;
        top: 10px;
      }
    }
  }
  tr {
    &.even {
      background-color: color(gray-lighter);
    }
    &.head {
      background-color: color(gray-light);
    }
    &.clickable {
      cursor: pointer;
    }
    td {
      white-space: nowrap;
      padding: 0.525rem;
      text-align: right;
      &:first-child {
        padding-left: 0.8rem;
      }
      &:last-child {
        padding-right: 0.8rem;
      }
    }
  }
}
$thw: 15vw;

.drilldown {
  display: grid;
  grid-template-columns: $thw auto 30px;
  th {
    padding: 0.525rem;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  th:hover {
    overflow-x: visible;
  }
  td {
    padding: 0.525rem;
  }
  &_row {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    td {
      white-space: pre-line;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0.525rem;
      text-align: right;
    }
  }
}
.show {
  display: block;
  margin: 2px;
  border-radius: 3px;
}
.show:last-child {
  margin-bottom: 8px;
}
.expanded {
  th {
    font-weight: 700;
  }
}
.collapsed {
  th {
    font-weight: 400;
  }
}
.hide {
  display: none;
}
tr:hover {
  background-color: color(bb-sec5);
}
.level1 {
  margin-left: 30px;
  background-color: #b8d1ce;
  .drilldown {
    grid-template-columns: calc($thw - 30px) auto 28px;
  }
}
.level2 {
  margin-left: 30px;
  background-color: #d7e8e5;
  .drilldown {
    grid-template-columns: calc($thw - 60px) auto 26px;
  }
}
.level3 {
  margin-left: 30px;
  background-color: #f0f5f5;
  .drilldown {
    grid-template-columns: calc($thw - 90px) auto 24px;
  }
}

.level4 {
  margin-left: 30px;
  background-color: color(bb-sec8);
}
.svg-td > svg {
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
}
</style>
