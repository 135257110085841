<template>
  <!-- Textarea -->
  <div
    v-if="type === 'textarea'"
    class="input">
    <label
      :for="inputId"
      :class="[
        `${inlineStyle ? 'input__label label__inline' : 'input__label'}`,
        `first-letter`,
      ]"
      :data-required-suffix="this.requiredSuffix">{{ label }}</label>
    <textarea
      :class="`${
        inlineStyle ? 'input__textarea input__inline' : 'input__textarea'
      }`"
      :name="name"
      :required="required ? true : false"
      ref="textarea"
      v-bind="$attrs"
      v-model="value"
      :placeholder="placeholder"></textarea>
  </div>
  <!-- Checkbox -->
  <div
    v-else-if="type === 'checkbox'"
    class="input input__checkbox">
    <input
      type="checkbox"
      :name="name"
      class="checkbox__input"
      :checked="checked"
      :id="inputId"
      @click="checkboxClicked"
      :disabled="disabled"
      ref="boolvalue"
      @input="input"
      v-bind="$attrs"
      :readonly="readonly" />
    <label
      :for="inputId"
      class="checkbox__label"
      :data-required-suffix="this.requiredSuffix"
      :class="{ 'is-active': checked }">
      {{ label }}
    </label>
  </div>
  <!-- Radiobutton -->
  <div
    v-else-if="type === 'radio'"
    class="input">
    <input
      type="radio"
      class="radio__input"
      :id="inputId"
      :checked="checked"
      :disabled="disabled"
      @click="radioClicked"
      ref="boolvalue"
      :readonly="readonly"
      :name="name"
      @input="input" />
    <label
      class="radio__label"
      :for="inputId"
      :data-required-suffix="this.requiredSuffix"
      :class="{ 'is-active': checked }">
      {{ label }}
    </label>
  </div>
  <!-- Other input types -->
  <div
    v-else
    :class="`input ${cssClasses}`">
    <label
      :for="inputId"
      :class="[
        `${inlineStyle ? 'input__label label__inline' : 'input__label'}`,
        `first-letter`,
      ]"
      :data-required-suffix="this.requiredSuffix">{{ label }} {{ this.requiredSuffix ? this.requiredSuffix : '' }}</label>
    <input
      :id="inputId"
      :class="`${inlineStyle ? 'input__input input__inline' : 'input__input'} ${
        this.hasValue ? 'has-value' : ''
      }`"
      v-model="dataValue"
      :type="type"
      ref="textvalue"
      :disabled="disabled"
      :required="required"
      :name="name"
      @input="input"
      v-bind="$attrs"
      :readonly="readonly"
      :placeholder="placeholder" />
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    inputId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    checked: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    cssClasses: {
      type: String,
      default: '',
    },
    inlineStyle: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataValue: this.value,
      selected: false,
    };
  },
  computed: {
    hasValue() {
      return this.dataValue !== '';
    },
    requiredSuffix() {
      return this.required ? '*' : '';
    },
  },
  watch: {
    value() {
      this.dataValue = this.value;
    },
  },
  methods: {
    getValue() {
      if (this.type === 'text') {
        return this.$refs.textvalue.value;
      } else if (this.type === 'checkbox' || this.type === 'radio') {
        return this.$refs.boolvalue.checked;
      }
      return this.dataValue;
    },
    input(event) {
      this.$emit('input', event.target.value);
    },
    clear() {
      if (this.type === 'checkbox') {
        this.checked = null;
        this.$emit('checked', false);
      } else {
        this.value = '';
        this.$emit('input', '');
      }
    },
    checkboxClicked(event) {
      this.$emit('checked', event.target.checked);
    },
    radioClicked(event) {
      this.$emit('checked', event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped></style>
