// This mixin will handle click outside. Only need to lisen to the 'click-outside' message.
var clickOutside = {
  mounted: function () {
    var event;
    var self = this;

    this.$el.addEventListener('click', function (e) {
      event = e;
    });

    document.body.addEventListener('click', function (e) {
      if (e === event) {
        return;
      }

      self.$emit('click-outside', e);
    });
  },
};

export default clickOutside;
