<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div class="businessboard--header">
        <modal-window
          :show="showDownloadModal"
          size="small">
          <div class="download_modal_content">
            <div class="download_modal_header">
              <div
                class="close"
                @click="showDownloadModal = false">
                ✕
              </div>
            </div>
            <div
              :class="['option', { option_selected: chosenFormat == 0 }]"
              @click="chosenFormat = '0'">
              <strong>JPG</strong>
              <span class="subtitle">({{ $t('whiteBackground') }})</span>
              <div class="checkbox">
                <use-svg
                  v-show="chosenFormat == 0"
                  svg="bb-selected-icon" />
              </div>
            </div>
            <div
              :class="['option', { option_selected: chosenFormat == 1 }]"
              @click="chosenFormat = '1'">
              <strong>PNG</strong>
              <span class="subtitle">{{ $t('transparentBackground') }}</span>
              <div class="checkbox">
                <use-svg
                  v-show="chosenFormat == 1"
                  svg="bb-selected-icon" />
              </div>
            </div>
            <div class="download-button-div">
              <base-button
                @click="download()"
                class="downloadButton"
                :text="$t('chooseFormatForDownload')"
                backgroundColor="bb-primary">
                <img
                  src="@/components/svg/bb-download-icon.svg"
                  class="download-white-icon"
                  alt="" />
              </base-button>
            </div>
          </div>
        </modal-window>
        <span class="h1">{{ $t('GameCalculation.title') }} </span>
        <span
          v-if="isLoading"
          class="element--is-loading">
        </span>
        <last-updated
          :last-update="dashboard.lastUpdates"
          :show-member="true"
          :show-game="true"
          :show-economy="true"
          :show-warning="haveNonConnectedAccounts" />
      </div>
      <div class="info_text">
        {{ $t('GameCalculation.infoText') }}
      </div>
      <div class="control">
        <div class="chart_settings">
          <div
            v-if="showChartSettings"
            class="chart_settings_modal">
            <div class="header">
              <span>{{ $t('diagramSettings') }}</span>
            </div>
            <span>{{ $t('appliesToAllDiagrams') }}</span>
            <hr />
            <strong>{{ $t('currentYear') }}</strong>
            <custom-select
              @optionSelected="e => selectCurrentYear(e)"
              :selected="prevYears.map(y => y.value).indexOf(currentYear)"
              :options="prevYears">
            </custom-select>
            <div class="columns">
              <span class="column_section">{{ $t('period') }}</span>
              <div class="period_input">
                <custom-select
                  @optionSelected="e => (chartPeriod = e.value)"
                  :selected="
                    columnPeriods.map(y => y.value).indexOf(chartPeriod)
                  "
                  :options="translatedColumnPeriods">
                </custom-select>
              </div>
              <div
                class="period_grid2"
                v-if="chartPeriod === 'not_fiscal'">
                <span class="column_section">{{
                  $t('chooseStartEndMonth')
                }}</span>
                <div class="selectMonth">
                  <div
                    v-for="m in 12"
                    :key="m"
                    :class="{
                      selected: chartStartMonth == m || chartEndMonth == m,
                      highlighted: m > chartStartMonth && m < chartEndMonth,
                    }"
                    @click="selectChartMonth(m)">
                    {{ periodMonths[m] }}
                  </div>
                </div>
              </div>
              <hr />
              <div class="add_column">
                <button
                  @click="addRefYear"
                  :disabled="refYears.length >= 4">
                  {{ $t('addReferenceYear') }}
                </button>
              </div>
              <hr />
              <div
                class="column"
                v-for="(column, i) in refYears"
                :key="i">
                <div @click="toggleColumn(i)">
                  <strong>{{ $t('referenceYear') }} {{ i + 1 }}</strong>
                  <span style="float: right; margin: 1rem 0">
                    <use-svg
                      svg="arrow-right"
                      :class="{ 'svg--180-negative': showColumn[i] }" />
                  </span>
                  <span
                    @click.stop="removeColumn(i)"
                    style="float: right; margin: 0.5rem 0">
                    <info-icon :description="$t('Businessboard.delete')">
                      <template #icon>
                        <use-svg svg="bb-trash-icon" />
                      </template>
                    </info-icon>
                  </span>
                </div>
                <div v-show="showColumn[i]">
                  <div class="period_grid">
                    <div
                      @click="selectNormalYear(i)"
                      style="cursor: pointer">
                      <use-svg
                        svg="bb-star-icon"
                        :color="reactiveNormalYear == i ? '#F08565' : 'none'"
                        stroke="#F08565" />
                      <span style="margin: 0.4rem">{{
                        $t('markAsNormalYear')
                      }}</span>
                    </div>
                    <span class="column_section">{{ $t('year') }}</span>
                    <div class="period_input">
                      <custom-select
                        @optionSelected="e => selectYear(i, e)"
                        :selected="
                          prevYears
                            .slice(1)
                            .map(y => y.value)
                            .indexOf(column.yearValue)
                        "
                        :options="prevYears.slice(1)">
                      </custom-select>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <button @click="showChartFilter()">
              {{ $t('showTemporarily') }}
            </button>
            <info-icon :description="$t($t('showTemporarilyInfo'))">
              <template #icon>
                <use-svg svg="bb-info-icon" />
              </template>
            </info-icon>
            <button
              v-if="showSaveAndPublish"
              class="btn_primary"
              @click="saveChartFilter()">
              {{ $t('saveAndPublish') }}
            </button>
            <br />
            <button @click="showChartSettings = false">
              {{ $t('cancel') }}
            </button>
          </div>
          <base-button
            @click="
              showChartSettings = !showChartSettings;
              showTableSettings = false;
            "
            backgroundColor="bb-primary"
            :text="$t('diagramSettings')">
            <use-svg svg="bb-gear-icon" />
          </base-button>
        </div>

        <div class="chart_settings">
          <div
            v-if="showTableSettings"
            class="chart_settings_modal">
            <div class="header">
              <span>{{ $t('tableSettings') }}</span>
            </div>
            <span>{{ $t('appliesToAllTables') }}</span>
            <hr />
            <strong>{{ $t('period') }}</strong>
            <br />
            <span>{{ $t('appliesToAllColumns') }}</span>
            <br />
            <div class="period_input">
              <custom-select
                @optionSelected="e => (columnPeriod = e.value)"
                :selected="
                  columnPeriods.map(y => y.value).indexOf(columnPeriod)
                "
                :options="translatedColumnPeriods">
              </custom-select>
            </div>

            <div
              class="period_grid2"
              v-if="columnPeriod === 'not_fiscal'">
              <span class="column_section">{{
                $t('chooseStartEndMonth')
              }}</span>
              <div class="selectMonth">
                <div
                  v-for="i in 12"
                  :key="i"
                  :class="{
                    selected: startMonth == i || endMonth == i,
                    highlighted: i > startMonth && i < endMonth,
                  }"
                  @click="selectMonth(i)">
                  {{ periodMonths[i] }}
                </div>
              </div>
            </div>
            <hr />
            <button @click="showTableFilter()">
              {{ $t('showTemporarily') }}
            </button>
            <info-icon :description="$t($t('showTemporarilyInfo'))">
              <template #icon>
                <use-svg svg="bb-info-icon" />
              </template>
            </info-icon>
            <button
              v-if="showSaveAndPublish"
              class="btn_primary"
              @click="saveTableFilter()">
              {{ $t('saveAndPublish') }}
            </button>
            <br />
            <button @click="showTableSettings = false">
              {{ $t('cancel') }}
            </button>
          </div>
          <base-button
            @click="
              showTableSettings = !showTableSettings;
              showChartSettings = false;
            "
            backgroundColor="bb-primary"
            :text="$t('tableSettings')">
            <use-svg svg="bb-gear-icon" />
          </base-button>
        </div>
        <div style="float: right; display: inline; margin: 1rem 0">
          <base-button
            @click="copyTableData"
            backgroundColor="white"
            :text="$t(copyDataText)">
            <use-svg svg="bb-copy-icon" />
          </base-button>
        </div>
      </div>
      <div class="intro">
        <div
          class="section"
          v-if="dashboard.top">
          <diagram-kebab-menu
            elementID="Fördelning-gäst-medlem-och-tävling-sällskap"
            :enlargeOption="false"
            @downloadSettings="e => setDownloadSettings(e)" />
          <h4>{{ $t('GameCalculation.diagram1') }}</h4>
          <div
            :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
            id="Fördelning-gäst-medlem-och-tävling-sällskap">
            <pie-filterable
              ref="pie1"
              :styles="{ height: '16rem', width: '16rem' }"
              :data="dashboard.top.pieChart"
              :colors="[
                '#0E3746',
                '#27645C',
                '#00A99D',
                '#ABDED1',
                '#F08565',
              ]" />
            <pie-legend
              :labels="dashboard.top.pieChart.labels"
              @click="e => clickLegend(e, 'pie1')" />
          </div>
          <div>
            <comment
              @postedComment="postedComment"
              :comment="dashboard.top.pieChart.comment"
              :lastUpdate="lastUpdateDate"
              :canEdit="showSaveAndPublish"
              :section="100" />
          </div>
        </div>
        <div class="gap"></div>
        <div
          class="section"
          v-if="dashboard.top">
          <diagram-kebab-menu
            elementID="jämför"
            :enlargeOption="false"
            @downloadSettings="e => setDownloadSettings(e)" />
          <div>
            <div
              :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
              id="jämför"
              style="display: grid; grid-template-columns: repeat(5, auto)">
              <div
                class="circles"
                v-for="(dot, i) in dashboard.top.dots"
                :key="i">
                <h4>{{ dot.name }}</h4>
                <template v-if="i == 0">
                  <div class="circle1">
                    <donut-chart
                      :sum1="dot.value"
                      :sum2="100" />
                  </div>
                  <years-legend
                    :normalYear="normalYear"
                    :years="dot.comparisonYears"
                    :unit="$t('rounds').toLowerCase()" />
                </template>
                <template v-else>
                  <div
                    class="circle"
                    :style="
                      'background-color: ' +
                      section1[i].backgroundColor +
                      ';color:' +
                      section1[i].color +
                      ';'
                    ">
                    {{ dot.value > 0 ? '+' : '' }}{{ dot.value }}
                    {{ $t('denomination') }}
                  </div>
                  <years-legend
                    :normalYear="normalYear"
                    :years="dot.comparisonYears" />
                </template>
              </div>
            </div>
            <comment
              @postedComment="postedComment"
              :comment="dashboard.top.dotComment"
              :lastUpdate="lastUpdateDate"
              :canEdit="showSaveAndPublish"
              :section="101" />
          </div>
        </div>
      </div>
      <div
        class="section"
        v-if="dashboard.courses && dashboard.courses.length > 1">
        <h3>{{ $t('GameCalculation.totalAllCourses') }}</h3>
        <div class="intro">
          <div>
            <h4>{{ $t('GameCalculation.playedRounds') }}</h4>
            <bar-chart
              v-if="!isLoading"
              :styles="{ height: maximized1 ? '32rem' : '12rem' }"
              :fullscreen="maximized1"
              :data="dashboard.courseTotal" />
            <table-chart-loader v-if="isLoading" />
            <comment
              @postedComment="postedComment"
              :comment="dashboard.courseTotal.comment"
              :lastUpdate="lastUpdateDate"
              :canEdit="showSaveAndPublish"
              :section="102" />
          </div>
          <div
            :class="[
              'courses',
              dashboard.courses.length === 3 ? 'courses_three' : 'courses_two',
            ]">
            <template v-for="(course, i) in dashboard.coursesDatas">
              <div
                class="course"
                :key="course.id">
                <h4>{{ course.name }}</h4>
                <div>
                  {{ $t('GameCalculation.distribution') }}<br />{{
                    $t('GameCalculation.friendlyGame').toLowerCase()
                  }}<br />{{ $t('GameCalculation.member').toLowerCase() }} /
                  {{ $t('GameCalculation.guest').toLowerCase() }}
                  <doughnut-chart
                    v-if="!isLoading"
                    :styles="{ height: '10rem', width: '10rem' }"
                    :data="doughnutData1(course)"
                    :colors="['#ABDED1', '#0E3746']" />
                  <pie-chart-loader v-if="isLoading" />
                </div>
                <div>
                  {{ $t('GameCalculation.distribution') }}<br />{{
                    $t('competition').toLowerCase()
                  }}
                  /<br />{{ $t('GameCalculation.friendlyGame').toLowerCase() }}
                  <doughnut-chart
                    v-if="!isLoading"
                    :styles="{ height: '10rem', width: '10rem' }"
                    :data="doughnutData2(course)"
                    :colors="['#D1BBFF', '#4B2D88']" />
                  <pie-chart-loader v-if="isLoading" />
                </div>
              </div>
              <template v-if="i < dashboard.courses.length - 1">
                <template v-if="dashboard.courses.length !== 3 && i % 2 == 1">
                  <div
                    class="horizontal_seperator"
                    :key="i"></div>
                  <div :key="i"></div>
                  <div
                    class="horizontal_seperator"
                    :key="i"></div>
                </template>
                <template v-else>
                  <div
                    class="seperator"
                    :key="i"></div>
                </template>
              </template>
            </template>
            <div class="comment">
              <comment
                @postedComment="postedComment"
                :comment="dashboard.coursesComment"
                :lastUpdate="lastUpdateDate"
                :canEdit="showSaveAndPublish"
                :section="103" />
            </div>
          </div>
        </div>
      </div>
      <section
        v-if="
          gameCalculation.coursesTotal && gameCalculation.courses.length > 1
        "
        class="section">
        <list-table
          :isLoading="isLoading"
          class="businessboard--table"
          :items="gameCalculation.coursesTotal.rows"
          :headers="gameCalculation.coursesTotal.columns">
        </list-table>
      </section>
      <div
        class="section"
        v-for="(course, i) in gameCalculation.courses"
        :key="i">
        <h4>{{ course.columns[0].displayName }}</h4>
        <div
          v-if="dashboard.top"
          class="dashboard-grid">
          <template v-if="dashboard.courses && dashboard.courses.length > 1">
            <div>
              <h4>{{ $t('coverage') }}</h4>
              <div class="donut_big">
                <donut-chart
                  :sum1="
                    dashboard.courses[
                      dashboardCourseIndex(course.columns[0].displayName)
                    ].dot.value
                  "
                  :sum2="100"
                  :subtitle="$t('currentYear2')"
                  :colors="['#C1C7C9', '#4B2D88']" />
                <years-legend
                  :normalYear="normalYear"
                  :years="
                    dashboard.courses[
                      dashboardCourseIndex(course.columns[0].displayName)
                    ].dot.comparisonYears
                  "
                  :unit="$t('rounds').toLowerCase()" />
              </div>
              <div>
                <comment
                  @postedComment="postedComment"
                  :comment="
                    dashboard.courses[
                      dashboardCourseIndex(course.columns[0].displayName)
                    ].dotComment
                  "
                  :lastUpdate="lastUpdateDate"
                  :canEdit="showSaveAndPublish"
                  :section="104" />
              </div>
            </div>
            <div>
              <h4>{{ $t('GameCalculation.diagram1') }}</h4>
              <div style="display: grid; grid-template-columns: auto auto">
                <pie-filterable
                  ref="pie5"
                  :styles="{ height: '18rem', width: '18rem' }"
                  :data="
                    dashboard.courses[
                      dashboardCourseIndex(course.columns[0].displayName)
                    ].pieChart
                  "
                  :colors="[
                    '#0E3746',
                    '#27645C',
                    '#00A99D',
                    '#ABDED1',
                    '#F08565',
                  ]" />
                <pie-legend
                  :labels="
                    dashboard.courses[
                      dashboardCourseIndex(course.columns[0].displayName)
                    ].pieChart.labels
                  "
                  @click="e => clickLegend(e, 'pie5')" />
              </div>
              <div>
                <comment
                  @postedComment="postedComment"
                  :comment="
                    dashboard.courses[
                      dashboardCourseIndex(course.columns[0].displayName)
                    ].pieComment
                  "
                  :lastUpdate="lastUpdateDate"
                  :canEdit="showSaveAndPublish"
                  :section="105" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="dashboard-grid">
              <div>
                <diagram-kebab-menu
                  elementID="stapel-diagram-spelade-ronder"
                  :enlargeOption="false"
                  @downloadSettings="e => setDownloadSettings(e)" />
                <h4>{{ $t('GameCalculation.playedRounds') }}</h4>
                <bar-chart
                  v-if="!isLoading"
                  :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
                  id="stapel-diagram-spelade-ronder"
                  :styles="{ height: maximized1 ? '32rem' : '12rem' }"
                  :fullscreen="maximized1"
                  :data="dashboard.courseTotal" />
                <table-chart-loader v-if="isLoading" />
                <comment
                  @postedComment="postedComment"
                  :comment="dashboard.courseTotal.comment"
                  :lastUpdate="lastUpdateDate"
                  :canEdit="showSaveAndPublish"
                  :section="102" />
              </div>
              <div class="courses">
                <diagram-kebab-menu
                  v-if="dashboard.coursesDatas"
                  elementID="fördelning-diagram"
                  :enlargeOption="false"
                  @downloadSettings="e => setDownloadSettings(e)" />
                <div
                  :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
                  id="fördelning-diagram"
                  class="course">
                  <div>
                    {{ $t('GameCalculation.distribution') }}<br />{{
                      $t('GameCalculation.friendly')
                    }}<br />{{ $t('GameCalculation.member').toLowerCase() }} /
                    {{ $t('GameCalculation.guestGame').toLowerCase() }}
                    <doughnut-chart
                      v-if="!isLoading"
                      :styles="{ height: '10rem', width: '10rem' }"
                      :data="doughnutData1(dashboard.coursesDatas[0])"
                      :colors="['#ABDED1', '#0E3746']" />
                    <pie-chart-loader v-if="isLoading" />
                  </div>
                  <div>
                    {{ $t('GameCalculation.distribution') }}<br />{{
                      $t('competition').toLowerCase()
                    }}
                    /<br />{{
                      $t('GameCalculation.friendlyGame').toLowerCase()
                    }}
                    <doughnut-chart
                      v-if="!isLoading"
                      :styles="{ height: '10rem', width: '10rem' }"
                      :data="doughnutData2(dashboard.coursesDatas[0])"
                      :colors="['#D1BBFF', '#4B2D88']" />
                    <pie-chart-loader v-if="isLoading" />
                  </div>
                </div>
                <div class="comment">
                  <comment
                    @postedComment="postedComment"
                    :comment="dashboard.coursesComment"
                    :lastUpdate="lastUpdateDate"
                    :canEdit="showSaveAndPublish"
                    :section="103" />
                </div>
              </div>
            </div>
          </template>
        </div>
        <section class="businessboard--table">
          <list-table
            :isLoading="isLoading"
            :hide="gameCalculation.courses.length > 1"
            :items="course.rows"
            :headers="course.columns">
          </list-table>
        </section>
      </div>
      <div class="section">
        <h3>{{ $t('GameCalculation.memberRevenue') }}</h3>
        <div
          v-if="dashboard.membershipIncome"
          style="
            display: grid;
            grid-template-columns: auto auto auto;
            column-gap: 2rem;
            margin: 2rem 0.5rem;
          ">
          <div>
            <h4>{{ dashboard.membershipIncome.dot1.name }}</h4>
            <diagram-kebab-menu
              v-if="dashboard.membershipIncome.dot1.name"
              :elementID="dashboard.membershipIncome.dot1.name"
              :enlargeOption="false"
              @downloadSettings="e => setDownloadSettings(e)" />
            <div
              :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
              :id="dashboard.membershipIncome.dot1.name"
              class="circles">
              <div class="circle_div">
                <div class="circle circle_big circle_purple">
                  {{ dashboard.membershipIncome.dot1.value.toLocaleString() }}
                  {{ $t('denomination') }}
                </div>
                <years-legend
                  :normalYear="normalYear"
                  :years="dashboard.membershipIncome.dot1.comparisonYears" />
              </div>
            </div>
          </div>
          <div>
            <h4>{{ dashboard.membershipIncome.dot2.name }}</h4>
            <diagram-kebab-menu
              v-if="dashboard.membershipIncome.dot2.name"
              :elementID="dashboard.membershipIncome.dot2.name"
              :enlargeOption="false"
              @downloadSettings="e => setDownloadSettings(e)" />
            <div
              :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
              :id="dashboard.membershipIncome.dot2.name"
              class="circles">
              <div class="circle_div">
                <div class="circle circle_big circle_turquoise">
                  {{ dashboard.membershipIncome.dot2.value.toLocaleString() }}
                  {{ $t('denomination') }}
                </div>
                <years-legend
                  :normalYear="normalYear"
                  :years="dashboard.membershipIncome.dot2.comparisonYears" />
              </div>
            </div>
          </div>
          <div>
            <h4>{{ dashboard.membershipIncome.dot3.name }}</h4>
            <diagram-kebab-menu
              v-if="dashboard.membershipIncome.dot3.name"
              :elementID="dashboard.membershipIncome.dot3.name"
              :enlargeOption="false"
              @downloadSettings="e => setDownloadSettings(e)" />
            <div
              :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
              :id="dashboard.membershipIncome.dot3.name"
              class="circles">
              <div class="circle_div">
                <div class="circle circle_big circle_turquoise">
                  {{ dashboard.membershipIncome.dot3.value.toLocaleString() }}
                  {{ $t('denomination') }}
                </div>
                <years-legend
                  :normalYear="normalYear"
                  :years="dashboard.membershipIncome.dot3.comparisonYears" />
              </div>
            </div>
          </div>
          <div>
            <comment
              @postedComment="postedComment"
              :comment="dashboard.membershipIncome.comment1"
              :lastUpdate="lastUpdateDate"
              :canEdit="showSaveAndPublish"
              :section="106" />
          </div>
          <div style="grid-column: span 2">
            <comment
              @postedComment="postedComment"
              :comment="dashboard.membershipIncome.comment2"
              :lastUpdate="lastUpdateDate"
              :canEdit="showSaveAndPublish"
              :section="107" />
          </div>
        </div>
        <section
          class="businessboard--table"
          v-if="gameCalculation.memberIncomes">
          <list-table
            :isLoading="isLoading"
            :items="gameCalculation.memberIncomes.rows"
            :headers="gameCalculation.memberIncomes.columns">
          </list-table>
        </section>
      </div>
      <div class="section">
        <h3>{{ $t('GameCalculation.guestRevenue') }}</h3>
        <div
          v-if="dashboard.guestIncome"
          style="
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 2rem;
            margin: 2rem 0.5rem;
          ">
          <div>
            <h4>{{ dashboard.guestIncome.dot1.name }}</h4>
            <diagram-kebab-menu
              v-if="dashboard.guestIncome.dot1.name"
              :elementID="dashboard.guestIncome.dot1.name"
              :enlargeOption="false"
              @downloadSettings="e => setDownloadSettings(e)" />
            <div
              :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
              :id="dashboard.guestIncome.dot1.name"
              class="circles">
              <div class="circle_div">
                <div class="circle circle_big circle_purple">
                  {{ dashboard.guestIncome.dot1.value.toLocaleString() }}
                  {{ $t('denomination') }}
                </div>
                <years-legend
                  :normalYear="normalYear"
                  :years="dashboard.guestIncome.dot1.comparisonYears" />
              </div>
            </div>
            <div>
              <comment
                @postedComment="postedComment"
                :comment="dashboard.guestIncome.comment1"
                :lastUpdate="lastUpdateDate"
                :canEdit="showSaveAndPublish"
                :section="108" />
            </div>
          </div>
          <div>
            <div style="display: grid; grid-template-columns: 1fr 1fr">
              <div>
                <h4>{{ dashboard.guestIncome.dot2.name }}</h4>
                <diagram-kebab-menu
                  v-if="dashboard.guestIncome.dot2.name"
                  :elementID="dashboard.guestIncome.dot2.name"
                  :enlargeOption="false"
                  @downloadSettings="e => setDownloadSettings(e)" />
                <div
                  :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
                  :id="dashboard.guestIncome.dot2.name"
                  class="circles">
                  <div class="circle_div">
                    <div class="circle circle_big circle_turquoise">
                      {{ dashboard.guestIncome.dot2.value.toLocaleString() }}
                      {{ $t('denomination') }}
                    </div>
                    <years-legend
                      :normalYear="normalYear"
                      :years="dashboard.guestIncome.dot2.comparisonYears" />
                  </div>
                </div>
              </div>
              <div>
                <h4>{{ dashboard.guestIncome.dot3.name }}</h4>
                <diagram-kebab-menu
                  v-if="dashboard.guestIncome.dot3.name"
                  :elementID="dashboard.guestIncome.dot3.name"
                  :enlargeOption="false"
                  @downloadSettings="e => setDownloadSettings(e)" />
                <div
                  :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
                  :id="dashboard.guestIncome.dot3.name"
                  class="circles">
                  <div class="circle_div">
                    <div class="circle circle_big circle_turquoise">
                      {{ dashboard.guestIncome.dot3.value.toLocaleString() }}
                      {{ $t('denomination') }}
                    </div>
                    <years-legend
                      :normalYear="normalYear"
                      :years="dashboard.guestIncome.dot3.comparisonYears" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <comment
                @postedComment="postedComment"
                :comment="dashboard.guestIncome.comment2"
                :lastUpdate="lastUpdateDate"
                :canEdit="showSaveAndPublish"
                :section="109" />
            </div>
          </div>
        </div>
        <section
          class="businessboard--table"
          v-if="gameCalculation.guestIncomes">
          <list-table
            :isLoading="isLoading"
            :items="gameCalculation.guestIncomes.rows"
            :headers="gameCalculation.guestIncomes.columns">
          </list-table>
        </section>
      </div>
      <div class="section">
        <h3>{{ $t('GameCalculation.totalRevenue') }}</h3>
        <div
          v-if="dashboard.totalIncome"
          style="
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-gap: 2rem;
            margin: 2rem 0.5rem;
          ">
          <div>
            <h4>{{ $t('GameCalculation.revenueDividedByPosts') }}</h4>
            <diagram-kebab-menu
              v-if="dashboard.totalIncome"
              elementID="intäkter-fördelat-på-poster"
              :enlargeOption="false"
              @downloadSettings="e => setDownloadSettings(e)" />
            <div
              :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
              id="intäkter-fördelat-på-poster"
              style="display: grid; grid-template-columns: 18rem auto">
              <pie-filterable
                ref="pie3"
                :styles="{ height: '14rem', width: '14rem' }"
                :data="dashboard.totalIncome.pie"
                :colors="[
                  '#0E3746',
                  '#27645C',
                  '#00A99D',
                  '#ABDED1',
                  '#F08565',
                  '#4B2D88',
                  '#BAAAF9',
                  '#C5DEE0',
                  '#555F61',
                  '#FBEBDD',
                ]" />
              <pie-legend
                :labels="dashboard.totalIncome.pie.labels"
                @click="e => clickLegend(e, 'pie3')" />
            </div>
            <div>
              <comment
                @postedComment="postedComment"
                :comment="dashboard.totalIncome.comment1"
                :lastUpdate="lastUpdateDate"
                :canEdit="showSaveAndPublish"
                :section="110" />
            </div>
          </div>
          <div>
            <div>
              <h4>{{ dashboard.totalIncome.dot.name }}</h4>
              <diagram-kebab-menu
                v-if="dashboard.totalIncome.dot.name"
                :elementID="dashboard.totalIncome.dot.name"
                :enlargeOption="false"
                @downloadSettings="e => setDownloadSettings(e)" />
              <div
                :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
                :id="dashboard.totalIncome.dot.name"
                class="circles">
                <div class="circle_div">
                  <div class="circle circle_big circle_turquoise">
                    {{ dashboard.totalIncome.dot.value.toLocaleString() }}
                    {{ dashboard.totalIncome.dot.units }}
                  </div>
                  <years-legend
                    :normalYear="normalYear"
                    :years="dashboard.totalIncome.dot.comparisonYears" />
                </div>
              </div>
            </div>
            <div>
              <comment
                @postedComment="postedComment"
                :comment="dashboard.totalIncome.comment2"
                :lastUpdate="lastUpdateDate"
                :canEdit="showSaveAndPublish"
                :section="111" />
            </div>
          </div>
        </div>
        <section
          class="businessboard--table"
          v-if="gameCalculation.totalIncomes">
          <list-table
            :isLoading="isLoading"
            :items="gameCalculation.totalIncomes.rows"
            :headers="gameCalculation.totalIncomes.columns">
          </list-table>
        </section>
      </div>
      <div class="section">
        <h3>{{ $t('GameCalculation.fixedCosts') }}</h3>
        <div
          v-if="dashboard.totalCost"
          style="
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-gap: 2rem;
            margin: 2rem 0.5rem;
          ">
          <div>
            <h4>{{ $t('GameCalculation.costDividedByPosts') }}</h4>
            <diagram-kebab-menu
              v-if="dashboard.totalCost"
              elementID="Kostnader-fördelat-på-poster"
              :enlargeOption="false"
              @downloadSettings="e => setDownloadSettings(e)" />
            <div
              :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
              id="Kostnader-fördelat-på-poster"
              style="display: grid; grid-template-columns: 18rem auto">
              <pie-filterable
                ref="pie4"
                :styles="{ height: '14rem', width: '14rem' }"
                :data="dashboard.totalCost.pie"
                :colors="[
                  '#0E3746',
                  '#27645C',
                  '#00A99D',
                  '#ABDED1',
                  '#F08565',
                  '#4B2D88',
                  '#BAAAF9',
                  '#C5DEE0',
                  '#555F61',
                  '#FBEBDD',
                ]" />
              <pie-legend
                :labels="dashboard.totalCost.pie.labels"
                @click="e => clickLegend(e, 'pie4')" />
            </div>
            <div>
              <comment
                @postedComment="postedComment"
                :comment="dashboard.totalCost.comment1"
                :lastUpdate="lastUpdateDate"
                :canEdit="showSaveAndPublish"
                :section="112" />
            </div>
          </div>
          <div>
            <div>
              <h4>{{ dashboard.totalCost.dot.name }}</h4>
              <diagram-kebab-menu
                v-if="dashboard.totalCost.dot.name"
                :elementID="dashboard.totalCost.dot.name"
                :enlargeOption="false"
                @downloadSettings="e => setDownloadSettings(e)" />
              <div
                :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']"
                :id="dashboard.totalCost.dot.name"
                class="circles">
                <div class="circle_div">
                  <div class="circle circle_big circle_turquoise">
                    {{ dashboard.totalCost.dot.value.toLocaleString() }}
                    {{ $t('denomination') }}
                  </div>
                  <years-legend
                    :normalYear="normalYear"
                    :years="dashboard.totalCost.dot.comparisonYears" />
                </div>
              </div>
            </div>
            <div>
              <comment
                @postedComment="postedComment"
                :comment="dashboard.totalCost.comment1"
                :lastUpdate="lastUpdateDate"
                :canEdit="showSaveAndPublish"
                :section="113" />
            </div>
          </div>
        </div>
        <section
          v-if="gameCalculation.costs"
          class="businessboard--table">
          <list-table
            :isLoading="isLoading"
            :items="gameCalculation.costs.rows"
            :headers="gameCalculation.costs.columns">
          </list-table>
        </section>
      </div>
      <div class="section">
        <section
          v-if="gameCalculation.capacity"
          class="businessboard--table">
          <list-table
            :isLoading="isLoading"
            :items="gameCalculation.capacity.rows"
            :headers="gameCalculation.capacity.columns">
          </list-table>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthsLabelsShort } from '@/core/consts';

import ListTable from '@/components/list-table/list-table-game-calculation';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import CustomSelect from '@/components/form-elements/custom-select-bb';
import InfoIcon from '@/components/businessboard/tooltip-icon';
import Comment from '@/components/businessboard/comment';
import PieFilterable from '@/components/charts/pie-chart-filterable3';
import PieLegend from '@/components/charts/legend-pie';
import YearsLegend from '@/components/charts/legend-years';
import DoughnutChart from '@/components/charts/donut-chart-filterable';
import DonutChart from '@/components/charts/donut-chart2';
import BarChart from '@/components/charts/test-bar-chart2';
import ModalWindow from '@/components/modal/modal';
import { toPng, toJpeg } from 'html-to-image';
import diagramKebabMenu from '@/components/businessboard/diagram-kebab-menu';
import LastUpdated from '../../components/last-updated/last-updated.vue';
import localizationMixin from '../../mixins/localization-mixin.vue';
import TableChartLoader from '../../components/reports-templates/table-chart-loader.vue';
import PieChartLoader from '../../components/reports-templates/pie-chart-loader.vue';

export default {
  name: 'BusinessboardGameCalculation',
  mixins: [localizationMixin],
  components: {
    ListTable,
    BaseButton,
    UseSvg,
    CustomSelect,
    InfoIcon,
    Comment,
    PieFilterable,
    PieLegend,
    DonutChart,
    DoughnutChart,
    BarChart,
    YearsLegend,
    ModalWindow,
    diagramKebabMenu,
    LastUpdated,
    TableChartLoader,
    PieChartLoader,
  },
  data: function () {
    return {
      section1: [
        {},
        { backgroundColor: '#ABDED1', color: '#000' },
        { backgroundColor: '#ABDED1', color: '#000' },
        { backgroundColor: '#4B2D88', color: '#FFF' },
        { backgroundColor: '#4B2D88', color: '#FFF' },
      ],
      currentYear: 2023,
      isLoading: true,
      copyDataText: 'copyTableData',
      storedFilter: {},
      showChartSettings: false,
      showTableSettings: false,
      maximized1: false,
      showDownloadModal: false,
      chosenFormat: '0',
      columnPeriod: 'accumulated',
      startMonth: 0,
      endMonth: 0,
      showColumn: [true, true, true, true],
      reactiveNormalYear: 0,
      refYears: [],
      chartPeriod: 0,
      chartStartMonth: 0,
      chartEndMonth: 0,
      normalYear: 0,
    };
  },
  computed: {
    gameCalculation() {
      return this.$store.getters['businessboard/gameCalculation'];
    },
    haveNonConnectedAccounts() {
      return this.$store.getters['businessboard/havenonconnectedaccounts'];
    },
    prevYears() {
      const currentYear = new Date().getFullYear();
      const years = Array.from({ length: 7 }, (_, i) => currentYear - i);
      return years.map(year => ({ displayName: String(year), value: year }));
    },
    columnPeriods() {
      return [
        { value: 'accumulated', displayName: this.$t('accumulatedFiscalYear') },
        { value: 'fiscal', displayName: this.$t('fiscalYear') },
        { value: 'not_fiscal', displayName: this.$t('notFiscalYear') },
      ];
    },
    financialYears() {
      return this.$store.getters['businessboardBudget/financialYears'];
    },
    lastUpdateDate() {
      return new Date(
        Math.max(...this.dashboard.lastUpdates.map(u => new Date(u.lastUpdate)))
      );
    },
    dashboard() {
      return this.$store.getters['businessboard/dashboardGamecalculation'];
    },
    translatedColumnPeriods() {
      return this.columnPeriods.map(item => ({
        value: item.value,
        displayName: this.$t(item.displayName),
      }));
    },
    periodMonths() {
      return [
        '',
        this.$t('january'),
        this.$t('february'),
        this.$t('march'),
        this.$t('april'),
        this.$t('may'),
        this.$t('june'),
        this.$t('july'),
        this.$t('august'),
        this.$t('september'),
        this.$t('october'),
        this.$t('november'),
        this.$t('december'),
      ];
    },
    chartFilter() {
      let comparisonYears = [];
      for (let year of this.refYears) {
        if (year.yearValue) {
          comparisonYears.push(year.yearValue);
        }
      }
      return {
        NormalYear: comparisonYears[this.reactiveNormalYear],
        ComparisonYears: comparisonYears,
        Period: ['fiscal', 'accumulated', 'not_fiscal'].indexOf(
          this.chartPeriod
        ),
        StartMonth: this.chartStartMonth,
        EndMonth: this.chartEndMonth,
        CurrentYear: this.currentYear,
      };
    },
    user() {
      return this.$store.getters['user/user'];
    },
    showSaveAndPublish() {
      return !(
        this.user.roles.BusinessBoard &&
        this.user.roles.BusinessBoard.some(
          e => e.value === 'BusinessBoardBoardMember'
        )
      );
    },
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
  },
  mounted() {
    this.$store.commit('businessboard/dashboardGamecalculation', {});
    Promise.all([
      this.$store.dispatch('businessboardBudget/fetchFinancialYears'),
      this.$store.dispatch('businessboard/fetchHaveNonConnectedAccounts'),
      this.$store.dispatch('businessboard/postGamecalculationDashboard', {}),
      this.$store.dispatch(
        'businessboard/fetchGameCalculation',
        this.$store.state.businessboard.periodFilter
      ),
    ]).finally(() => {
      this.isLoading = false;
    });
  },
  watch: {
    $route: function () {
      this.fetch();
    },
    dashboard: function (newVal, oldVal) {
      if (newVal.filter) {
        this.currentYear = newVal.filter.currentYear;
        this.normalYear = newVal.filter.normalYear;
        this.chartPeriod = ['fiscal', 'accumulated', 'not_fiscal'][
          newVal.filter.period
        ];
        this.chartStartMonth = newVal.filter.startMonth;
        this.chartEndMonth = newVal.filter.endMonth;
        this.refYears = [];
        for (let year of newVal.filter.comparisonYears) {
          this.refYears.push({
            yearValue: year,
          });
        }
        this.reactiveNormalYear = this.refYears
          .map(y => y.yearValue)
          .indexOf(this.normalYear);
      }
    },
  },
  methods: {
    download() {
      this.showDownloadModal = false;
      if (this.diagramId === '') {
        return;
      }
      const toFile = this.chosenFormat === '0' ? toJpeg : toPng;
      const downloadOptions =
        this.chosenFormat === '0'
          ? { backgroundColor: 'white' }
          : { backgroundColor: 'transparent' };

      toFile(document.getElementById(this.diagramId), downloadOptions)
        .then(
          function (dataUrl) {
            var link = document.createElement('a');

            link.download =
              this.diagramId +
              '.' +
              (this.chosenFormat === '0' ? 'jpg' : 'png');

            link.href = dataUrl;

            link.click();
          }.bind(this)
        )
        .catch(function (error) {
          console.error('Oops, something went wrong!', error);
        });
    },
    setDownloadSettings(e) {
      this.showDownloadModal = e.showDownloadModal;
      this.diagramId = e.id;
      this.chosenFormat = this.settings.fileFormat;
    },
    timeFormat(d) {
      if (!d) {
        return;
      }

      d = new Date(d);

      return `${d.getDate()} ${this.$t(
        `MonthsShort.${MonthsLabelsShort[d.getMonth()]}`
      ).toLowerCase()} ${d.getFullYear()}, ${('0' + d.getHours()).slice(-2)}:${(
        '0' + d.getMinutes()
      ).slice(-2)}`;
    },
    changeFilter(filter) {
      this.isLoading = true;
      this.$store.state.businessboard.periodFilter = filter;
      this.$store
        .dispatch('businessboard/fetchGameCalculation', filter)
        .finally(() => {
          this.isLoading = false;
        });
    },
    copyData(table) {
      let text = '';
      for (let header of table.columns) {
        text += header.displayName.replace(/\r?\n|\r/g, ' ');
        for (let item of table.rows) {
          text += '\t' + item[header.value].replace(/\r?\n|\r/g, ' ');
        }
        text += '\n';
      }
      return text;
    },
    copyTableData() {
      let text = '';
      if (
        this.gameCalculation.coursesTotal &&
        this.gameCalculation.courses.length > 1
      ) {
        text += this.copyData(this.gameCalculation.coursesTotal) + '\n';
      }
      for (let course of this.gameCalculation.courses) {
        text += this.copyData(course) + '\n';
      }
      text += this.copyData(this.gameCalculation.memberIncomes) + '\n';
      text += this.copyData(this.gameCalculation.guestIncomes) + '\n';
      text += this.copyData(this.gameCalculation.totalIncomes) + '\n';
      text += this.copyData(this.gameCalculation.costs) + '\n';
      text += this.copyData(this.gameCalculation.capacity);

      navigator.clipboard.writeText(text).then(
        () => {
          this.copyDataText = 'copiedToClipboard';
        },
        function (err) {
          this.copyDataText = 'dataCouldNotBeCopied';
          console.error('Async: Could not copy text: ', err);
        }
      );
    },
    selectCurrentYear(e) {
      this.currentYear = e.value;
    },
    selectYear(i, e) {
      this.$set(this.refYears[i], 'yearValue', e.value);
    },
    selectChartMonth(m) {
      if (this.chartEndMonth === m) {
        this.chartEndMonth = 0;
      } else if (this.chartStartMonth === m && this.chartEndMonth === 0) {
        this.chartStartMonth = 0;
      } else if (this.chartStartMonth > 0 && this.chartEndMonth > 0) {
        this.chartStartMonth = m;
        this.chartEndMonth = 0;
      } else {
        if (this.chartStartMonth === 0 || m < this.chartStartMonth) {
          this.chartStartMonth = m;
        } else {
          this.chartEndMonth = m;
        }
      }
    },
    addRefYear() {
      this.refYears.push({});
    },
    selectMonth(i) {
      if (this.endMonth === i) {
        this.endMonth = 0;
      } else if (this.startMonth === i && this.endMonth === 0) {
        this.startMonth = 0;
      } else if (this.startMonth > 0 && this.endMonth > 0) {
        this.startMonth = i;
        this.endMonth = 0;
      } else {
        if (this.startMonth === 0 || i < this.startMonth) {
          this.startMonth = i;
        } else {
          this.endMonth = i;
        }
      }
    },
    toggleColumn(i) {
      this.$set(this.showColumn, i, !this.showColumn[i]);
    },
    removeColumn(i) {
      this.refYears.splice(i, 1);
    },
    postedComment() {
      return '';
    },
    saveChartFilter() {
      this.$store
        .dispatch(
          'businessboard/postGamecalculationDashboardFilter',
          this.chartFilter
        )
        .then(() => {
          this.showChartFilter();
        });
    },
    showChartFilter() {
      this.showChartSettings = false;
      this.isLoading = true;
      this.$store
        .dispatch(
          'businessboard/postGamecalculationDashboard',
          this.chartFilter
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveTableFilter() {
      this.showTableFilter();
    },
    showTableFilter() {
      let filter = {
        'Filter.DefaultFilter': this.columnPeriod === 'accumulated',
        'Filter.FinancialYearFilter': this.columnPeriod === 'fiscal',
        'Filter.PeriodFilter': this.columnPeriod === 'not_fiscal',
        'Filter.FromMonth': this.startMonth,
        'Filter.ToMonth': this.endMonth,
        'Filter.year': null,
      };
      this.changeFilter(filter);
      this.showTableSettings = false;
    },
    clickLegend(e, p) {
      // var meta = this.$refs[p].chartdata.getDatasetMeta(0)
      this.$refs[p]._data._chart.getDatasetMeta(0).data[e].hidden =
        !this.$refs[p]._data._chart.getDatasetMeta(0).data[e].hidden;
    },
    selectNormalYear(i) {
      if (this.reactiveNormalYear === i) {
        this.reactiveNormalYear = -1;
      } else {
        this.reactiveNormalYear = i;
      }
    },
    doughnutData1(course) {
      return {
        labels: [
          this.$t('GameCalculation.guest') +
            ' ' +
            (course?.guestPercentage ?? 0) +
            '%',
          this.$t('GameCalculation.member') +
            ' ' +
            (course?.memberPercentage ?? 0) +
            '%',
        ],
        values: [course?.guestPercentage ?? 0, course?.memberPercentage ?? 0],
      };
    },
    doughnutData2(course) {
      return {
        labels: [
          this.$t('competition') +
            ' ' +
            (course?.competitionPercentage ?? 0) +
            '%',
          this.$t('GameCalculation.friendly') +
            ' ' +
            (course?.friendlyPercentage ?? 0) +
            '%',
        ],
        values: [
          course?.competitionPercentage ?? 0,
          course?.friendlyPercentage ?? 0,
        ],
      };
    },
    dashboardCourseIndex(name) {
      return this.dashboard.courses.findIndex(item => item.name === name);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
.businessboard--header {
  height: 3rem;
  margin: 1rem 0;
}
.h1 {
  font-family: 'SoraRegular';
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
}
h3 {
  font-family: 'SoraRegular';
  margin: 1rem 0;
}
h4 {
  font-family: 'SoraRegular';
  margin: 1rem 0;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.businessboard {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(gray-light);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.businessboard--table {
  margin-bottom: 2rem;
}
.chart_settings {
  margin: 1rem 0;
  display: inline-block;
}
.chart_settings_modal {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 8px;
  color: #131516;
  font-size: 90%;
  .header {
    font-size: 120%;
    margin-bottom: 0.4rem;
    color: #0e3746;
  }
  button {
    padding: 0.5rem;
    border: 1px solid #0e3746;
    border-radius: 3px;
    background: white;
    margin: 0.5rem 0;
  }
  .info_icon {
    margin: 0.5rem;
  }
  .btn_primary {
    background-color: #0e3746;
    color: white;
    float: right;
  }
  .btn_reticent {
    border: 0;
  }
  hr {
    margin-top: 1.5rem;
  }
  strong {
    display: inline-block;
    margin: 1rem 0;
  }
  .column_section {
    color: #555f61;
  }
  .columns {
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: calc(100vh - 16.5rem);
  }
  .columns:hover {
    overflow-y: scroll;
  }
  .column {
    cursor: pointer;
  }
  .add_column {
    display: flex;
    justify-content: flex-end;
  }
}
.selectMonth {
  display: grid;
  grid-template-columns: auto auto auto;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px;
  div {
    padding: 4px;
    font-size: 14px;
    line-height: 23px;
    color: #656565;
    text-align: center;
    border-radius: 16px;
    margin: 3px;
    cursor: pointer;
  }
  .selected {
    background-color: #abded1;
    font-weight: bold;
  }
  .highlighted {
    background-color: #eaf6f7;
  }
  .disabled {
    color: #ddd;
    cursor: default;
  }
}
.intro {
  display: grid;
  grid-template-columns: 1fr 2rem 3fr;
}
.section {
  all: unset;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 16px;
  .chart {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 2fr 1fr;
  }
  .businessboard--table:last-child {
    margin-bottom: 0;
  }
}
.info_text {
  max-width: 600px;
}
.circles {
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    text-align: center;
  }
  .circle1 {
    width: 5rem;
    height: 5rem;
    margin: 0.5rem;
  }
  .circle {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    margin: 0.5rem;
    text-align: center;
    font-family: 'SoraRegular';
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    &_big {
      font-size: 24px;
      width: 10rem;
      height: 10rem;
    }
    &_purple {
      background-color: #4b2d88;
      color: white;
    }
    &_turquoise {
      background-color: #abded1;
      color: black;
    }
  }
  .circle_div {
    display: grid;
    grid-template-columns: 11rem 11rem;
  }
}
.courses {
  display: grid;
  min-width: fit-content;
  &_two {
    grid-template-columns: auto 1px auto;
    .comment {
      grid-column-end: span 3;
    }
  }
  &_three {
    grid-template-columns: auto 1px auto 1px auto;
    .comment {
      grid-column-end: span 5;
    }
  }
  // height: 20rem;
  .seperator {
    border-right: 1px solid #dadedf;
    margin: 1rem 0;
  }
  .horizontal_seperator {
    border-top: 1px solid #dadedf;
    margin: 0 2rem;
  }

  .course {
    place-self: center;
    place-items: center;
    display: grid;
    grid-template-columns: 9rem 9rem;
    text-align: center;
    h4 {
      grid-column-end: span 2;
    }
    > div {
      padding: 0.2rem;
    }
  }
}
.donut_big {
  display: grid;
  grid-template-columns: 15rem 13rem;
  padding: 1.5rem 0.6rem;
  place-items: center;
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.control {
  display: grid;
  grid-template-columns: repeat(3, 15rem);
  padding-bottom: 7rem;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10rem;
  margin: 2rem 0.5rem;
}
</style>
