import Vue from 'vue';
import Vuex from 'vuex';
import responsive from './modules/responsive';
import user from './modules/user';
import menu from './modules/menu';
import users from './modules/users';
import scorecard from './modules/scorecard';
import clubs from './modules/clubs';
import printers from './modules/printers';
import courses from './modules/courses';
import printerQueue from './modules/printer-queue';
import appSettings from './modules/app-settings';
import clients from './modules/clients';
import ui from './modules/ui';
import terminals from './modules/terminals';
import onserviceSystemMessages from './modules/onservice-system-messages';
import transactions from './modules/transactions';
import articles from './modules/articles';
import voucher from './modules/voucher';
import holesponsors from './modules/holesponsors';
import businessboard from './modules/businessboard';
import businessboardBudget from './modules/businessboard-budget';
import businessboardReportingTool from './modules/businessboard-reportingtool';
import BusinessBoardBalanceSheet from './modules/businessboard-balancesheet';
import BusinessBoardDrilldown from './modules/businessboard-drilldown';
import scorecardOrder from './modules/scorecard-order';
import ontagStatistics from './modules/ontag-statistics';
import ontagCourseguides from './modules/ontag-courseguides';
import todo from './modules/todo';
import scorecardOrderStatistics from './modules/scorecard-order-statistics';
import balanceReport from './modules/balance-report';
import coursedata from './modules/coursedata';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    responsive,
    user,
    menu,
    users,
    scorecard,
    clubs,
    printers,
    courses,
    printerQueue,
    appSettings,
    clients,
    ui,
    terminals,
    onserviceSystemMessages,
    transactions,
    articles,
    voucher,
    holesponsors,
    businessboard,
    businessboardBudget,
    businessboardReportingTool,
    BusinessBoardBalanceSheet,
    BusinessBoardDrilldown,
    scorecardOrder,
    ontagStatistics,
    ontagCourseguides,
    todo,
    scorecardOrderStatistics,
    balanceReport,
    coursedata,
  },
});
