<template>
  <div class="ontag">
    <div class="ontag--content">
      <h1>Alla beställningar</h1>
      <span
        v-if="isLoading"
        class="element--is-loading"></span>
      <div class="ontag--utilitites input-group--inline">
        <div class="pagination-amount">
          <span>{{ $t('show') }}</span>
          <custom-select
            v-if="selectedIndexPageSize !== undefined"
            :required="false"
            @optionSelected="setPageSize"
            :selected="selectedIndexPageSize"
            :options="pageSizeOptions" />
          <span class="row-lines">{{ $t('lines') }}</span>
        </div>
        <div class="search-wrapper">
          <div class="input">
            <label
              for="searchQuery"
              class="input__label label__inline first-letter">{{ $t('search') }}
            </label>
            <input
              id="searchQuery"
              class="input__input input__inline search-input"
              @keydown="getVariatedOrderResults"
              @search="getVariatedOrderResults"
              v-model="searchQuery"
              type="search"
              name="searchQuery" />
          </div>
          <base-button
            class="search-button"
            :text="$t('search')"
            :button-size-small="true"
            @click="getVariatedOrderResults" />
        </div>
      </div>
      <div class="total-count">
        <p>
          Totalt antal: <strong>{{ scorecardallorders.sum }}</strong>
        </p>
      </div>
      <list-table
        :headers="availableFilters"
        :items="filteredItems"
        @allSelected="getOrdersSelected"
        @variationResults="getVariatedOrderResults">
      </list-table>
      <results-pagination
        :first-row-on-page="pagination.firstRowOnPage"
        :last-row-on-page="pagination.lastRowOnPage"
        :row-count="pagination.rowCount"
        :page-size="pagination.pageSize"
        :page-count="pagination.pageCount"
        :current-page="pagination.currentPage"
        :next-page="getNextPage()"
        :prev-page="getPrevPage()"
        store="clubs"
        @currentPage="getVariatedOrderResults" />
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import ResultsPagination from '@/components/results-pagination';
import CustomSelect from '@/components/form-elements/custom-select';

export default {
  name: 'ScorecardAllOrders',
  components: {
    ListTable,
    BaseButton,
    ResultsPagination,
    CustomSelect,
  },
  directives: {
    elementLoader,
  },

  data() {
    return {
      isLoading: true,
      searchQuery: '',
      selectedIndexPageSize: undefined,
      sort: {},
      allSelected: [],
      filters: {},
      selectedStatuses: [],
      currentPage: 0,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
    };
  },
  methods: {
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedOrderResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(
        o => parseInt(o.value) === size
      );
      this.selectedIndexPageSize = selectedSize;
    },
    getPrevPage() {
      return this.$store.getters['scorecardOrder/previousPage'];
    },
    getNextPage() {
      return this.$store.getters['scorecardOrder/nextPage'];
    },
    getVariatedOrderResults(value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length > 0) {
          this.filters['Filter.' + value.filter.id] = checked.map(a => a.value);
        } else {
          delete this.filters['Filter.' + value.filter.id];
          if (this.isPrintingHouse) {
            this.filters['Filter.statusText'] = ['4', '7'];
          }
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;
      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('scorecardOrder/fetchScorecardAllOrders', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        pageSize: this.pageSize,
        page: this.currentPage,
        ...this.filters,
      });
    },

    getOrdersSelected(value) {
      this.allSelected = value;
    },
  },

  watch: {
    allSelected() {
      this.selectedStatuses = [];
      this.allSelected.forEach(item => {
        const match = this.scorecardallorders.rows.filter(
          post => post.id === item
        );
        this.selectedStatuses = [...this.selectedStatuses, match[0].status];
      });
    },

    selectedStatuses() {
      if (this.selectedStatuses.length === 0) {
        this.activeButtons.export = false;
        this.activeButtons.confirm = false;
      } else if (this.selectedStatuses.every(status => status === 1)) {
        this.activeButtons.confirm = true;
        this.activeButtons.export = true;
      } else if (this.selectedStatuses.every(status => status === 0)) {
        this.activeButtons.export = true;
      } else {
        this.activeButtons.export = false;
        this.activeButtons.confirm = false;
      }
    },
  },

  computed: {
    scorecardallorders() {
      return this.$store.getters['scorecardOrder/scorecardallorders'];
    },
    scorecardstandardorders() {
      return this.$store.getters['scorecardOrder/scorecardstandardorders'];
    },
    pagination() {
      return this.$store.getters['scorecardOrder/pagination'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    isPrintingHouse() {
      return (
        this.user.roles.OnTag &&
        this.user.roles.OnTag.some(e => e.value === 'PrintingHouse')
      );
    },
    filteredItems() {
      if (this.isPrintingHouse) {
        const orders = this.scorecardallorders.rows.filter(
          item => item.status === 4 || item.status === 7
        );
        return orders;
      } else {
        return this.scorecardallorders.rows;
      }
    },
    availableFilters() {
      if (this.isPrintingHouse) {
        return this.scorecardallorders.columns.map(item => {
          if (item.value === 'statusText' && item.filterValues) {
            // Filter the filterValues array to include only items with value 4 or 7
            item.filterValues = item.filterValues.filter(
              valueItem => valueItem.value === '4' || valueItem.value === '7'
            );

            // Update the filterValues array to set checked to true for value 4
            item.filterValues = item.filterValues.map(valueItem => {
              if (valueItem.value === '4') {
                return { ...valueItem, checked: true };
              }
              return valueItem;
            });
          }
          return item;
        });
      } else {
        return this.scorecardallorders.columns;
      }
    },
  },

  created() {
    this.isLoading = true;

    if (this.isPrintingHouse) {
      this.filters['Filter.statusText'] = ['4'];
    }

    this.$store
      .dispatch('scorecardOrder/fetchScorecardAllOrders', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        pageSize: this.pageSize,
        page: this.currentPage,
        ...this.filters,
      })
      .then(() => {
        this.selectedPageSize(this.pagination.pageSize);
        this.isLoading = false;
      });
  },
};
</script>
<style lang="scss" scoped>
.total-count {
  display: inline-block;
  margin-left: auto;
  margin-right: 20px;
  font-size: 20px;
}

.pagination-amount {
  display: flex;
  align-items: baseline;
  flex: 1;
}
</style>
