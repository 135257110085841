<template>
  <div class="course-guide-loops">
    <div
      :class="[
        'course-guide-loops-toolbar',
        scrollAtTop ? '' : 'scrolling-border',
      ]">
      <te-select style="width: 200px">
        <select
          :disabled="localEditLoops"
          placeholder="Choose loop"
          @change="selectLoopChanged">
          <option
            disabled
            selected
            value>
            Select loop
          </option>
          <option
            v-for="loop in this.localLoops"
            :key="loop.id"
            :selected="loop.id === selectedLoopId"
            :value="loop.id">
            {{ loop.name }}
          </option>
        </select>
      </te-select>
      <div class="spacer"></div>
      <!--button class="te-btn te-btn-sm te-btn-secondary">Reverse order</button-->
      <!--button
        v-if="!localEditLoops && mapToolEnabled"
        class="te-btn te-btn-sm te-btn-primary"
        :disabled="isLoading"
        @click="this.showMappingTool">
        Mapping tool
      </button-->
      <button
        v-if="localEditLoops"
        class="te-btn te-btn-sm te-btn-destructive"
        :disabled="isLoading"
        @click="this.resetAllHoles">
        Reset all holes
      </button>
      <button
        v-if="!localEditLoops"
        class="te-btn te-btn-sm te-btn-primary"
        :disabled="isLoading || !selectedLoopId"
        popover-id="start-editing"
        @click="this.toggleEditLoops">
        Edit guide
      </button>
      <button
        v-if="localEditLoops"
        class="te-btn te-btn-sm te-btn-secondary"
        :disabled="isLoading"
        @click="this.cancel">
        cancel
      </button>
      <button
        v-if="localEditLoops"
        class="te-btn te-btn-sm te-btn-primary"
        :disabled="isLoading"
        @click="this.save">
        Save
      </button>
      <te-popover
        class="course-guide-main-popover-help"
        target="start-editing"
        :show="startEditingPopoverVisible"
        title="Add physical holes"
        position="left">
        Click here to start adding holes yo your guide.
        <button
          class="te-btn te-btn-md te-btn-primary"
          style="align-self: flex-end"
          @click="hidePopover('start-editing')">
          Got it
        </button>
      </te-popover>
    </div>
    <div
      class="course-guide-loops-grid-content"
      @scroll.passive="onScroll">
      <div
        :class="getGridClasses()"
        @drop="getCanDropGroup() ? onDrop($event, 'group') : null"
        @dragover="getCanDropGroup() ? onDragOver($event, 'group') : null"
        @dragenter="getCanDropGroup() ? onDragEnter($event, 'group') : null"
        @dragleave="getCanDropGroup() ? onDragLeave($event, 'group') : null">
        <div
          v-for="hole in this.holes"
          :key="'hole' + hole.id"
          @drop="getCanDropHole(hole) ? onHoleDrop($event, hole) : null"
          @dragover="getCanDropHole(hole) ? onHoleDragOver($event, hole) : null"
          @dragenter="
            getCanDropHole(hole) ? onHoleDragEnter($event, hole) : null
          "
          @dragleave="
            getCanDropHole(hole) ? onHoleDragLeave($event, hole) : null
          ">
          <couse-data-holes-grid-item-view
            :key="hole.groupHoleNumber + hole.id"
            :dragHover="getIsHoleHover(hole)"
            :disabled="getIsHoleDisabled(hole)"
            :hole="hole"
            :rendering="isRenderingHoles.includes(hole.physicalHoleId)"
            :tees="getHoleTees(hole, tees)"
            :mapInfo="getMapInfo(physicalHolesDict[hole.physicalHoleId])"
            :compact="localEditLoops"
            :edit="localEditLoops"
            :editHole="localEditLoops"
            :faded="!hole.groupHoleNumber" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeSelect from './components/te-select-field.vue';
import TePopover from './components/te-popover.vue';
import CouseDataHolesGridItemView from './holes-grid-item.vue';
import { analyzeHole } from './holeanalyzer.js';
export default {
  name: 'CourseGuidesLoops',
  components: {
    TeSelect,
    TePopover,
    CouseDataHolesGridItemView,
  },
  emits: [
    'editLoopChanged',
    'loopChanged',
    'loopSelectionChanged',
    'showMappingTool',
  ],
  watch: {
    loops: {
      handler: function (newVal, oldVal) {
        if (this.verbose) {
          // console.log('loops changed', newVal, oldVal);
        }
        this.hasMapInfo = this.hasAnyMapInfo(newVal);
        this.localLoops = JSON.parse(JSON.stringify(newVal));
        this.refreshHoles();
      },
      deep: true,
    },
    groupHolesDict: {
      handler: function (newVal, oldVal) {
        this.refreshHoles();
      },
      deep: true,
    },
    physicalHolesDict: {
      handler: function (newVal, oldVal) {
        this.refreshHoles();
      },
      deep: true,
    },
    editLoops: {
      handler: function (newVal, oldVal) {
        if (this.verbose) {
          // console.log('editLoops changed', newVal, oldVal);
        }
        this.localEditLoops = newVal;
      },
    },
    dropProperties: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.type === 'group') {
          this.slots = this.getSlots(newVal.group);
        } else {
          this.slots = {
            numberOfSlots: 0,
          };
        }
        this.holeDropDisabled = newVal && newVal.type === 'group';
      },
      deep: true,
    },
  },
  props: {
    editLoops: {
      type: Boolean,
      default: true,
    },
    dropProperties: {
      type: Object,
      default: null,
    },
    loops: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isRenderingHoles() {
      return this.$store.getters['coursedata/isRenderingHoles'];
    },
    physicalHolesDict() {
      return this.$store.getters['coursedata/physicalHolesDict'];
    },
    groupHolesDict() {
      return this.$store.getters['coursedata/groupHolesDict'];
    },
    teeColorDict() {
      return this.$store.getters['coursedata/teeColorMap'];
    },
    isLoading() {
      return this.$store.getters['coursedata/isLoading'];
    },
  },
  data() {
    let hasMapInfo = false;
    let selectedLoopId = null;
    if (this.loops && this.loops.length > 0) {
      hasMapInfo = this.hasAnyMapInfo(this.loops);
    }
    return {
      startEditingPopoverVisible: false,
      mapToolEnabled: false,
      scrollAtTop: true,
      selectedLoopId: selectedLoopId,
      localLoops: JSON.parse(JSON.stringify(this.loops)),
      hasMapInfo: hasMapInfo,
      verbose: false,
      holes: [],
      tees: [],
      holeHover: null,
      holeDropDisabled: true,
      groupDragOver: false,
      localEditLoops: this.editLoops,
    };
  },
  methods: {
    hidePopover(id) {
      if (id === 'start-editing') {
        localStorage.setItem('start-editing-popover-shown', true);
      }
      this.startEditingPopoverVisible = false;
    },
    resetAllHoles() {
      if (this.selectedLoopId === null) {
        return;
      }
      for (let l of this.localLoops) {
        if (l.id === this.selectedLoopId) {
          for (let h of l.loopHoles) {
            h.physicalHoleId = null;
            h.groupHoleNumber = null;
            h.groupName = null;
            h.groupId = null;
          }
        }
      }
      this.refreshHoles();
      // for(let h of this.holes) {
      //     h.image = null;
      //     h.physicalHoleId = null;
      //     h.groupHoleNumber = null;
      //     h.groupName = null;
      //     h.groupId = null;
      // }
    },
    hasAnyMapInfo(loops) {
      if (!loops) {
        return false;
      }
      for (let l of loops) {
        for (let h of l.loopHoles) {
          if (
            h.physicalHole &&
            h.physicalHole.geometry &&
            h.physicalHole.geometry !== ''
          ) {
            return true;
          }
        }
      }
      return false;
    },
    canEnableMapTool() {
      let loop = this.getLoop();
      if (!loop) {
        return false;
      }
      for (let h of loop.loopHoles) {
        if (h.physicalHoleId === 0) {
          return false;
        }
      }
      return true;
    },
    showMappingTool() {
      this.$emit('showMappingTool');
    },
    resetSelectedLoop() {
      this.localLoops = JSON.parse(JSON.stringify(this.loops));
      this.refreshHoles();
    },
    refreshHoles() {
      // if (!this.selectedLoopId) {
      //   if (this.loops && this.loops.length > 0) {
      //     this.selectedLoopId = this.loops[0].id;
      //     this.$emit('loopSelectionChanged', this.selectedLoopId);
      //     this.mapToolEnabled = this.canEnableMapTool();
      //   }
      // }
      let holes = this.getHoles(this.localLoops, this.selectedLoopId);
      this.holes = this.convertToGridHoles(holes);
      this.tees = this.getTees(this.localLoops, this.selectedLoopId);
    },
    convertToGridHoles(holes) {
      let a = [];
      if (!this.groupHolesDict || !this.physicalHolesDict) {
        return a;
      }
      for (let hole of holes) {
        let g = this.groupHolesDict[hole.physicalHoleId];
        let h = this.physicalHolesDict[hole.physicalHoleId];
        a.push({
          id: hole.id,
          groupHoleNumber: h ? h.holeNumber : null,
          groupName: g ? g.shortName : null,
          groupId: g ? g.id : null,
          holeNumber: hole.holeNumber,
          physicalHoleId: hole.physicalHoleId,
          image: h ? h.holeImageUrl : null,
          par: hole.par,
          index: hole.index,
        });
      }
      return a;
    },
    selectLoopChanged(event) {
      this.selectedLoopId = parseInt(event.target.value, 10);
      this.tees = this.getTees(this.localLoops, this.selectedLoopId);
      let holes = this.getHoles(this.localLoops, this.selectedLoopId);
      this.holes = this.convertToGridHoles(holes);
      this.mapToolEnabled = this.canEnableMapTool();
      this.$emit('loopSelectionChanged', this.selectedLoopId);
      this.startEditingPopoverVisible =
        localStorage.getItem('start-editing-popover-shown') !== 'true';
    },
    getMapInfo(hole) {
      if (!this.hasMapInfo) {
        return;
      }
      if (!hole) {
        return [];
      }
      let m = analyzeHole(hole, this.tees, this.teeColorDict);
      return m;
    },
    getHole(loopHoleId) {
      let loop = this.getLoop();
      if (!loop) {
        return null;
      }
      return loop.loopHoles.find(h => h.id === loopHoleId);
    },
    getLoop() {
      return this.localLoops.find(l => l.id === this.selectedLoopId);
    },
    getHoleTees(hole, tees) {
      let arr = [];
      if (
        !this.physicalHolesDict[hole.physicalHoleId] ||
        !this.physicalHolesDict[hole.physicalHoleId].teePoints
      ) {
        return arr;
      }
      let teePoints = this.physicalHolesDict[hole.physicalHoleId].teePoints;
      for (let t of tees) {
        if (
          (Array.isArray(teePoints) &&
            teePoints.some(_t => _t.color === t.elementId)) ||
          teePoints[t.elementId]
        ) {
          arr.push({
            id: t.id,
            externalTeeId: t.externalTeeId,
            title: t.teeAlias,
            teeAlias: t.teeAlias,
            defaultGender: t.defaultGender,
            color: t.color,
            name: t.elementId,
          });
        }
      }
      return arr;
    },
    getTees(loops, id) {
      let loop = loops.find(l => l.id === id);
      if (loop && loop.tees) {
        return loop.tees;
      }
      return [];
    },
    getHoles(loops, id) {
      let loop = loops.find(l => l.id === id);
      if (loop) {
        return loop.loopHoles;
      }
      return [];
    },
    // setHoles(id, loops, holes) {
    //   let loop = loops.find((l) => l.id === id);
    //   if(loop) {
    //     loop.holes = holes;
    //   }
    // },
    save() {
      let loop = this.localLoops.find(l => l.id === this.selectedLoopId);
      let payload = {
        loopId: loop.id,
        externalLoopId: loop.externalLoopId,
        loopHoles: [],
      };

      for (let hole of this.holes) {
        payload.loopHoles.push({
          id: hole.id,
          physicalHoleId: hole.physicalHoleId ?? 0,
        });
      }
      this.$store
        .dispatch('coursedata/putUpdateLoop', payload)
        .then(() => {})
        .finally(() => {
          // console.log('completed', payload);
        });
      this.toggleEditLoops();
    },
    updateLoopHole(loopHole, value) {
      let actualHole = this.getHole(loopHole.id);
      if (!value) {
        actualHole.physicalHoleId = null;
        actualHole.groupHoleNumber = null;
        actualHole.groupName = null;
        actualHole.groupId = null;
        actualHole.holeImageUrl = null;
      } else {
        actualHole.physicalHoleId = value.physicalHoleId;
        actualHole.groupHoleNumber = value.groupHoleNumber;
        actualHole.groupName = value.groupName;
        actualHole.groupId = value.groupId;
        actualHole.holeImageUrl = value.holeImageUrl;
      }
      this.refreshHoles();
      // this.$emit('loopChanged', this.localLoops);
    },
    cancel() {
      this.resetSelectedLoop();
      this.toggleEditLoops();
    },
    toggleEditLoops() {
      this.localEditLoops = !this.localEditLoops;
      this.$emit('editLoopChanged', this.localEditLoops);
    },
    getIsHoleHover(hole) {
      if (this.getCanDropGroup()) {
        if (!this.groupDragOver) {
          return false;
        }
        return this.slots[hole.id] !== undefined;
      }
      return this.holeHover === hole.id;
    },
    getIsHoleDisabled(value) {
      if (!this.dropProperties) {
        return false;
      }
      if (this.dropProperties.type === 'group') {
        return !this.getCanDropGroup();
      }
      if (this.dropProperties.type === 'hole') {
        return value.par !== this.dropProperties.hole.par;
      }
      return false;
    },
    getCanDropHole(value) {
      if (!value) {
        return false;
      }
      if (!this.localEditLoops) {
        return false;
      }
      if (!this.dropProperties || !this.dropProperties.hole) {
        return false;
      }
      return value.par === this.dropProperties.hole.par;
    },
    getCanDropGroup() {
      if (!this.localEditLoops) {
        return false;
      }
      if (!this.dropProperties) {
        return false;
      }
      if (this.dropProperties.type !== 'group') {
        return false;
      }
      return this.slots.numberOfSlots > 0;
    },
    onHoleDragEnter(evt, hole) {
      evt.preventDefault();
      this.holeHover = hole.id;
      if (this.verbose) {
        // console.log('onHoleDragEnter', hole, evt);
      }
    },
    onHoleDragLeave(evt, hole) {
      evt.preventDefault();
      if (this.holeHover === hole.id) {
        this.holeHover = null;
      }
      if (this.verbose) {
        // console.log('onHoleDragLeave', hole, evt);
      }
    },
    onHoleDragOver(evt, hole) {
      evt.preventDefault();
    },
    onHoleDrop(evt, hole) {
      evt.preventDefault();
      let h = JSON.parse(evt.dataTransfer.getData('hole'));
      let g = JSON.parse(evt.dataTransfer.getData('group'));
      if (!g || !h) {
        console.error('missing data');
        return;
      }
      // let index = this.holes.findIndex((i) => i.physicalHoleId === h.id);
      // while(index !== -1) {
      //   this.updateLoopHole(this.holes[index], null);
      //   index = this.holes.findIndex((i) => i.physicalHoleId === h.id);
      // }
      this.updateLoopHole(hole, {
        holeImageUrl: h.holeImageUrl,
        physicalHoleId: h.id,
        groupHoleNumber: h.holeNumber,
        groupName: g.shortName,
        groupId: g.id,
      });
      this.holeHover = null;
      if (this.verbose) {
        // console.log('onHoleDrop', hole, evt);
      }
      this.$forceUpdate();
      // this.$emit('loopChanged', this.holes);
    },
    onDragEnter(evt) {
      this.groupDragOver = true;
      evt.preventDefault();
      if (this.verbose) {
        // console.log('onDragEnter', evt);
      }
    },
    onDragLeave(evt) {
      this.groupDragOver = false;
      evt.preventDefault();
      if (this.verbose) {
        // console.log('onDragLeave', evt);
      }
    },
    onDragOver(evt) {
      evt.preventDefault();
    },
    getSlots(group, onlyUnsetHoles) {
      let groupHoles = [...group.holes];
      let result = {
        numberOfSlots: 0,
      };
      let loopHoles = [...this.holes];
      if (loopHoles.findIndex(h => h.groupId === group.id) >= 0) {
        return result;
      }
      // let index = loopHoles.findIndex((h) => h.groupHole === null);
      // onlyUnsetHoles = onlyUnsetHoles || index !== -1;
      onlyUnsetHoles = true;
      for (let i = 0; i < loopHoles.length; i++) {
        let loopHole = loopHoles[i];
        if (onlyUnsetHoles && loopHoles[i].groupHoleNumber) {
          continue;
        }
        for (let j = 0; j < groupHoles.length; j++) {
          let groupHole = groupHoles[j];
          if (loopHole.par !== groupHole.par) {
            groupHoles = [...group.holes];
            result = {
              numberOfSlots: 0,
            };
            break;
          }
          groupHoles.splice(j, 1);
          result[loopHole.id] = {
            holeIndex: i,
            loopHoleId: loopHole.id,
            loopHoleNumber: loopHole.holeNumber,
            physicalHoleId: groupHole.id,
            groupHoleNumber: groupHole.holeNumber,
            groupName: group.shortName,
            holeImageUrl: groupHole.holeImageUrl,
            groupId: group.id,
          };
          result.numberOfSlots += 1;
          break;
        }
      }
      return result;
    },
    onDrop(evt) {
      this.groupDragOver = false;
      evt.preventDefault();
      let g = JSON.parse(evt.dataTransfer.getData('group'));

      if (!g) {
        console.error('missing data');
        return;
      }
      let slots = this.getSlots(g);
      if (this.verbose) {
        // console.log('onDrop', evt, g);
      }
      for (let i in slots) {
        if (i === 'numberOfSlots') {
          continue;
        }
        let slot = slots[i];
        this.updateLoopHole(this.holes[slot.holeIndex], {
          holeImageUrl: slot.holeImageUrl,
          physicalHoleId: slot.physicalHoleId,
          groupHoleNumber: slot.groupHoleNumber,
          groupName: slot.groupName,
          groupId: slot.groupId,
        });
      }
      this.slot = {
        numberOfSlots: 0,
      };
    },
    getGridClasses() {
      if (this.holeDropDisabled) {
        return [
          'course-guide-loops-grid',
          'course-guide-loops-grid-hole-drop-disabled',
          this.localEditLoops ? 'course-guide-loops-grid-compact' : '',
        ];
      }
      return [
        'course-guide-loops-grid',
        this.localEditLoops ? 'course-guide-loops-grid-compact' : '',
      ];
    },
    onScroll(e) {
      this.scrollAtTop = e.target.scrollTop === 0;
    },
  },
  created() {},
  mounted() {
    this.refreshHoles();
    this.mapToolEnabled = this.canEnableMapTool();
  },
};
</script>

<style lang="scss">
.course-guide-loops {
  .scrolling-border {
    z-index: 1;
    box-shadow: 0px 5px 5px -5px #575757;
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  .course-guide-loops-toolbar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 20px 0px 10px 0px;
    margin-right: 18px;
    .spacer {
      flex: 1;
    }
  }
  .course-guide-loops-grid-hole-drop-disabled {
    * {
      pointer-events: none;
    }
  }

  .course-guide-loops-grid-content {
    box-sizing: border-box;
    display: block;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 10px 18px 10px 0px;
  }
  .course-guide-loops-grid {
    flex: 1;
    display: grid;
    justify-content: start;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  .course-guide-loops-grid-compact {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .course-data-loops-holes {
    width: 272px;
    box-sizing: border-box;
    h4 {
      font-family: 'Tietoevry Sans 1';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
@media (max-width: 1400px) {
  .course-guide-loops .course-guide-loops-grid-compact {
    grid-template-columns: repeat(3, 1fr);
  }
  .course-guide-loops .course-guide-loops-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1200px) {
  .course-guide-loops .course-guide-loops-grid-compact {
    grid-template-columns: repeat(2, 1fr);
  }
  .course-guide-loops .course-guide-loops-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
