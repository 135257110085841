<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <router-link to="/clubs/">&laquo; {{ $t('back') }}</router-link>
      </div>
      <div class="golfoffice--top">
        <h1 v-if="id">{{ $t('GolfOffice.editClub') }}</h1>
        <h1 v-else>{{ $t('GolfOffice.addClub') }}</h1>
      </div>
      <div class="club__information">
        <h2 class="title-variation">
          <use-svg svg="print-icon" />
          {{ $t('GolfOffice.customerInformation') }}
        </h2>
        <p>Här ser ni klubbens kontaktuppgifter. Logotype kan laddas upp.</p>
        <div class="input">
          <label
            for="scorecard-name"
            class="input__label">{{ $t('clubName') }}</label>
          <input
            type="text"
            name="scorecard-name"
            id="scorecard-name"
            class="input__input"
            v-model="club.name" />
        </div>
        <div class="input">
          <label
            for="phone-number"
            class="input__label">{{ $t('phoneNumber') }}</label>
          <input
            type="text"
            name="phone-number"
            id="phone-number"
            class="input__input"
            v-model="club.phoneNumber" />
        </div>
        <div class="input">
          <label
            for="email"
            class="input__label">{{ $t('emailPrimary') }}</label>
          <input
            type="text"
            name="email"
            id="email"
            class="input__input"
            v-model="club.email" />
        </div>
        <div class="input">
          <label
            for="emailProductInfo"
            class="input__label">{{ $t('emailProductInfo') }}</label>
          <input
            type="text"
            name="emailProductInfo"
            id="emailProductInfo"
            class="input__input"
            v-model="club.emailProductInfo" />
        </div>
        <div class="input">
          <label
            for="homePageUrl"
            class="input__label">{{ $t('homePageUrl') }}</label>
          <input
            type="text"
            name="homePageUrl"
            id="homePageUrl"
            class="input__input"
            v-model="club.homePageUrl" />
        </div>
        <div>
          <template v-if="club && club.logoUrl && club.logoKey">
            <div class="club__logotype">
              <p>{{ $t('logotype') }}</p>
              <div class="club__layout--image image-1">
                <div
                  @mouseover="hoverOn('logotype')"
                  @mouseleave="hoverOn('')"
                  :class="['club__layout--image-overlay-wrapper']">
                  <template v-if="hover === 'logotype'">
                    <base-button
                      :text="$t('remove')"
                      class="button--has-icon"
                      :button-size-small="true"
                      @click="removeLogotype('logo')">
                      <use-svg svg="trash-icon" />
                    </base-button>
                    <input
                      hidden
                      type="file"
                      id="logotype"
                      name="logotype"
                      @change="e => addFile(e)" />
                    <base-button
                      @click="chooseFile('logotype')"
                      :text="$t('upload')"
                      class="button--has-icon"
                      :button-size-small="true">
                      <use-svg svg="export-icon" />
                    </base-button>
                  </template>
                </div>
                <img :src="club.logoUrl + club.logoKey" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="club__logotype">
              <p>{{ $t('logotype') }}</p>
              <div :class="'club__layout--image'">
                <div :class="['club__layout--image-overlay-wrapper']">
                  <input
                    hidden
                    type="file"
                    id="logotype"
                    name="logotype"
                    @change="e => addFile(e)" />
                  <base-button
                    @click="chooseFile('logotype')"
                    :text="$t('upload')"
                    class="button--has-icon"
                    :button-size-small="true">
                    <use-svg svg="export-icon" />
                  </base-button>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div>
          <template
            v-if="club && club.logoTransparentUrl && club.logoTransparentKey">
            <div class="club__logotype">
              <p>{{ $t('logotypeTransparent') }}</p>
              <div class="club__layout--image image-1">
                <div
                  @mouseover="hoverOn('logotypeTransparent')"
                  @mouseleave="hoverOn('')"
                  :class="['club__layout--image-overlay-wrapper']">
                  <template v-if="hover === 'logotypeTransparent'">
                    <base-button
                      :text="$t('remove')"
                      class="button--has-icon"
                      :button-size-small="true"
                      @click="removeLogotype('logoTransparent')">
                      <use-svg svg="trash-icon" />
                    </base-button>
                    <input
                      hidden
                      type="file"
                      id="logotypeTransparent"
                      name="logotypeTransparent"
                      @change="e => addFile(e)" />
                    <base-button
                      @click="chooseFile('logotypeTransparent')"
                      :text="$t('upload')"
                      class="button--has-icon"
                      :button-size-small="true">
                      <use-svg svg="export-icon" />
                    </base-button>
                  </template>
                </div>
                <img :src="club.logoTransparentUrl + club.logoTransparentKey" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="club__logotype">
              <p>{{ $t('logotypeTransparent') }}</p>
              <div :class="'club__layout--image'">
                <div :class="['club__layout--image-overlay-wrapper']">
                  <input
                    hidden
                    type="file"
                    id="logotypeTransparent"
                    name="logotypeTransparent"
                    @change="e => addFile(e)" />
                  <base-button
                    @click="chooseFile('logotypeTransparent')"
                    :text="$t('upload')"
                    class="button--has-icon"
                    :button-size-small="true">
                    <use-svg svg="export-icon" />
                  </base-button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="club__integrations"
        v-if="club.onTagVersion > 0">
        <h2 class="title-variation">
          <use-svg svg="print-icon" />
          {{ $t('scorecard') }}
        </h2>
        <p>Här ställer man in scorekort</p>
        <div class="address_region">
          <h3>{{ $t('deliveryAddress') }}</h3>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('companyName') }}</label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              class="input__input"
              v-model="club.deliveryAddress.companyName" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('postalAddress') }}</label>
            <input
              type="text"
              name="postalAddress"
              id="postalAddress"
              class="input__input"
              v-model="club.deliveryAddress.postalAddress" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('postalCode') }}</label>
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              class="input__input"
              v-model="club.deliveryAddress.postalCode" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('city') }}</label>
            <input
              type="text"
              name="city"
              id="city"
              class="input__input"
              v-model="club.deliveryAddress.city" />
          </div>
        </div>
        <div class="address_region">
          <h3>{{ $t('invoiceAddress') }}</h3>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('companyName') }}</label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              class="input__input"
              v-model="club.invoiceAddress.companyName" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('orgNumber') }}</label>
            <input
              type="text"
              name="orgNumber"
              id="orgNumber"
              class="input__input"
              v-model="club.invoiceAddress.orgNumber" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('postalAddress') }}</label>
            <input
              type="text"
              name="postalAddress"
              id="postalAddress"
              class="input__input"
              v-model="club.invoiceAddress.postalAddress" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('postalCode') }}</label>
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              class="input__input"
              v-model="club.invoiceAddress.postalCode" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('city') }}</label>
            <input
              type="text"
              name="city"
              id="city"
              class="input__input"
              v-model="club.invoiceAddress.city" />
          </div>
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label">{{ $t('emailInvoice') }}</label>
            <input
              type="text"
              name="email"
              id="email"
              class="input__input"
              v-model="club.invoiceAddress.email" />
          </div>
        </div>
      </div>
      <div class="club__integrations">
        <h2 class="title-variation">
          <use-svg svg="print-icon" />
          {{ $t('integrations') }}
        </h2>
        <p>
          Här ställer man in om klubben kör Git classic, Git Online, Sweetspot
          eller eventuella framtida varianter.
        </p>
        <custom-select
          :required="false"
          :label="$t('bookingSystem')"
          @optionSelected="setBookingSystem"
          :selected="selectedBookingSystemIndex"
          :options="bookingSystems" />
        <custom-select
          :required="false"
          :label="$t('courseInformationSystem')"
          @optionSelected="setCourseInformationSystem"
          :selected="selectedCourseInformationSystemIndex"
          :options="courseSystems" />
      </div>
      <div class="club__actions">
        <base-button
          :text="$t('save')"
          @click="saveClub"
          v-element-loader="{ loaderKey: 'golfoffice-club-edit' }" />
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import elementLoader from '@/directives/element-loader';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'EditClub',
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
    BaseButton,
    CustomSelect,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: String,
  },
  data() {
    return {
      selectedBookingSystemIndex: undefined,
      selectedCourseInformationSystemIndex: undefined,
      hover: false,
    };
  },
  methods: {
    setScoreCardSize(e) {
      this.club.scoreCardSize = e.displayName;
    },
    setBookingSystem(system) {
      this.club.bookingSystem = system.displayName;
    },
    setCourseInformationSystem(system) {
      this.club.courseSystem = system.displayName;
    },
    chooseFile(value) {
      document.getElementById(value).click();
    },
    addFile(event) {
      let image;
      image = event.target.files[0];
      let type = 'Logo';
      if (event.target.id === 'logotypeTransparent') {
        type = 'LogoTransparent';
      } else if (event.target.id === 'scoreCardProof') {
        type = 'ScoreCardProof';
      }
      if (image) {
        this.$store.dispatch('clubs/addLogotype', { image: image, type: type });
      }
    },
    hoverOn(element) {
      this.hover = element;
    },
    removeLogotype(type) {
      this.club[type + 'Url'] = '';
      this.club[type + 'Key'] = '';
    },
    saveClub() {
      this.$store.dispatch('clubs/patchClubInfo', this.club).then(() => {
        this.$toasted.success('Club saved');
      });
    },
    selectedBookingSystem(system) {
      this.selectedBookingSystemIndex = this.bookingSystems.findIndex(
        t => t.displayName === system
      );
    },
    selectedCourseInformationSystem(system) {
      this.selectedCourseInformationSystemIndex = this.courseSystems.findIndex(
        t => t.displayName === system
      );
    },
  },
  computed: {
    club() {
      return this.$store.getters['clubs/club'];
    },
    bookingSystems() {
      return this.$store.getters['clubs/bookingSystems'];
    },
    courseSystems() {
      return this.$store.getters['clubs/courseSystems'];
    },
  },
  mounted() {
    this.$store.dispatch('clubs/fetchClubInfo', { id: this.id }).then(() => {
      this.$store
        .dispatch('clubs/fetchBookingSystems')
        .then(() => this.selectedBookingSystem(this.club.bookingSystem));
      this.$store
        .dispatch('clubs/fetchCourseSystems')
        .then(() =>
          this.selectedCourseInformationSystem(this.club.courseSystem)
        );
    });
  },
};
</script>
<style lang="scss">
.club-switch {
  display: flex;
  .input__label {
    flex-basis: 15%;
  }
}
.club__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  button {
    margin-left: 1rem;
  }
}
.golfoffice--top {
  display: flex;
  justify-content: space-between;
  align-items: center h1 {
    margin-top: 0;
  }
}
.club__logotype {
  height: 100%;
  width: 30%;

  @include media('to-medium') {
    width: 100%;
  }

  p {
    margin: 0 0 0.5rem 0;
    @include media('to-medium') {
      margin: 0.5rem 0;
    }
  }
}
.club__layout--image {
  border: 1px solid color(gray);
  height: 7rem;
  display: flex;
  justify-content: center;
  position: relative;
  img {
    align-self: center;
    height: 80%;
  }
  .club__layout--image-overlay-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin-right: 0.3rem;
      padding: 0 0.5rem;
    }
  }
}
.club__layout--image-overlay {
  background-color: rgba(249, 249, 249, 0.5);
}
</style>
