<template>
  <div class="onservice">
    <div class="onservice--content">
      <div>
        <router-link to="/onservice/terminals">&laquo; {{ $t('back') }}</router-link>
      </div>
      <section class="onservice--terminal" v-if="result && result.settings">
        <div class="onservice--top">
          <h1 class="first-letter">{{ $t('terminal') }}: {{ result.name }}</h1>
          <base-button
            v-if="user.superUser"
            :text="$t('deleteTerminal')"
            background-color="red"
            @click="toggleDeleteTerminalModal"/>
          <modal-window :show="showDeleteModal">
            <h2>{{$t('deleteTerminalQuestion')}} {{result.name}}?</h2>
            <div class="modal__content--actions">
              <base-button
                :text="$t('cancel')"
                @click="toggleDeleteTerminalModal">
              </base-button>
              <base-button
                :text="$t('deleteTerminal')"
                background-color="red"
                @click="deleteTerminal"/>
            </div>
          </modal-window>
        </div>

        <p class="first-letter">{{ $t('type') }}:
          <template v-if="result.settings.type === 0">
            MultiQ
          </template>
          <template v-else-if="result.settings.type === 1">
            Modulo
          </template>
          <template v-else>
            Ingen vald
          </template>
        </p>
        <p class="first-letter">
          {{ $t('status') }}:
          <template v-if="result.active === true">
            <use-svg
              svg="circle-ok"
              class="v-align-text-bottom"/>
            <span class="status--active">aktiv</span>
          </template>
          <template v-else>
            <use-svg
              svg="circle-error"
              class="v-align-text-bottom"/>
          </template>
        </p>
        <section class="terminal--functions">
          <p class="first-letter">
            {{ $t('functions') }}:
          </p>
          <ul class="terminal--functions-list">
            <li :class="`${result.settings.payment.enableSwish ? 'status--true' : 'status--false'}`">Swish</li>
            <li :class="`${result.settings.payment.enableCreditCard ? 'status--true' : 'status--false'}`">Kreditkort</li>
            <li :class="`${result.settings.payment.enablePaperScorecards ? 'status--true' : 'status--false'}`">Pappersscorekort</li>
            <li :class="`${result.settings.payment.enableDigitalScorecards ? 'status--true' : 'status--false'}`">Digitala scorekort</li>
            <li :class="`${result.settings.payment.enableCompetitions ? 'status--true' : 'status--false'}`">Tävling</li>
          </ul>
        </section>
        <div class="input">
          <label for="description" class="input__label first-letter">{{ $t('description') }}</label>
          <input
            type="text"
            name="description"
            id="description"
            class="input__input"
            v-model="result.description"/>
        </div>
        <section class="terminal__image">
          <div class="terminal__image--image">
            <div
              @mouseover="hoverOn('logo')"
              @mouseleave="hoverOn('')"
              class="terminal__image--image-overlay-wrapper">
              <template v-if="hover === 'logo'">
                <base-button
                  v-if="result.settings.logoUrl"
                  :text="$t('remove')"
                  class="button--has-icon"
                  :button-size-small="true"
                  @click="removeFile()">
                  <use-svg svg="trash-icon" />
                </base-button>
                <input
                  hidden
                  type="file"
                  id="file_upload_logo"
                  name="file_upload_logo"
                  @change="e => addFile(e)">
                <base-button
                  @click="chooseFile('logo')"
                  :text="$t('upload')"
                  class="button--has-icon"
                  :button-size-small="true">
                  <use-svg svg="export-icon" />
                </base-button>
              </template>
            </div>
            <img :src="result.settings.logoUrl ? (result.settings.logoUrl + result.settings.logoKey) : result.settings.logoUrl">
          </div>
        </section>
        <div class="onservice--terminal__button">
          <a
            :href="result.terminalUrl"
            class="button"
            target="_blank">
            Gå till terminal
          </a>
        </div>
        <section class="onservice--terminal__arrival">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('arrival') }}
            </template>
            <template slot="content">
              <div class="input-group--inline input--column">
                <custom-select
                  :label="$t('timeLimit')"
                  id="arrivalTimeLimit"
                  @optionSelected="selectedTimeLimit"
                  :options="timeLimitOptions"
                  :selected="timeLimitOptions.findIndex(t => t.value === result.settings.arrival.timeLimit)"
                  :required="false">
                </custom-select>
              </div>
              <div class="input-group input--flex">
                <label
                  for="arrival-no-pay"
                  class="input__label">{{ $t('allowArrivalWithoutPayingFees') }}</label>
                <toggle-switch
                  id="arrival-no-pay"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.arrival.allowArrivalWithoutPayingFees"
                  @toggle="toggle('arrival', 'allowArrivalWithoutPayingFees', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="arrival-cost"
                  class="input__label">{{ $t('allowChangingCost') }}</label>
                <toggle-switch
                  id="arrival-cost"
                  input-id="arrival-cost"
                  type="switch"
                  :toggled="this.result.settings.arrival.allowChangingCost"
                  @toggle="toggle('arrival', 'allowChangingCost', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="arrival-all"
                  class="input__label">{{ $t('allowAllInBooking') }}</label>
                <toggle-switch
                  id="arrival-all"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.arrival.allowAllInBooking"
                  @toggle="toggle('arrival', 'allowAllInBooking', $event)"/>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section v-if="user.superUser" class="onservice--terminal__printing">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('printing') }}
            </template>
            <template slot="content">
              <div class="input-group--inline input--column">
                <label for="printing-printerServerGuid" class="input__label">{{ $t('printerServerGuid') }}</label>
                <input
                  type="text"
                  name="printing-printerServerGuid"
                  id="printing-printerServerGuid"
                  class="input__input"
                  required
                  v-model="result.settings.printing.printerServerGuid"
                  @change="changedInput('printing', 'printerServerGuid', $event.target.value, false)"/>
              </div>
              <div class="input-group--inline input--column">
                <custom-select
                  :label="$t('printerLocation')"
                  id="printing-printerLocation"
                  @optionSelected="selectedPrinterLocation"
                  :options="printerLocationOptions"
                  :selected="printerLocationOptions.findIndex(t => t.value === result.settings.printing.printerLocation)"
                  :required="true">
                </custom-select>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section class="onservice--terminal__payment">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('payment') }}
            </template>
            <template slot="content">
              <div class="input-group input--flex">
                <label
                  for="payment-enabled"
                  class="input__label">{{ $t('enabled') }}</label>
                <toggle-switch
                  id="payment-enabled"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.payment.enabled"
                  @toggle="toggle('payment', 'enabled', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="payment-enableSwish"
                  class="input__label">{{ $t('enableSwish') }}</label>
                <toggle-switch
                  id="payment-enableSwish"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.payment.enableSwish"
                  @toggle="toggle('payment', 'enableSwish', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="payment-enableCreditCard"
                  class="input__label">{{ $t('enableCreditCard') }}</label>
                <toggle-switch
                  id="payment-enableCreditCard"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.payment.enableCreditCard"
                  @toggle="toggle('payment', 'enableCreditCard', $event)"/>
              </div>
              <div class="payment__inactive width-33">
                <span class="payment__inactive--label first-letter">{{ $t('paymentTerminalInactive') }}:</span>
                <span class="payment__inactive--from first-letter">{{ $t('fromClock')}}</span>
                <span class="payment__inactive--to first-letter">{{ $t('toClock') }}</span>
              </div>
              <div class="input-group input--flex width-33">
                <div class="payment--check">
                  <div class="input checkbox">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="inActiveDayMonday"
                        name="inActiveDayMonday"
                        v-model="inActiveDayMonday"
                        @click="totoggleInactiveDay()">
                      <label for="inActiveDayMonday">
                        <span>
                          <span></span>
                        </span>
                        <p class="first-letter payment-label">{{ $t('monday') }}</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="payment--from">
                  <div class="input">
                    <input
                      id="inActiveDayMondayFrom"
                      class="input__input"
                      v-model="inActiveDayMondayFrom"
                      type="time"
                      name="inActiveDayMondayFrom"
                      :disabled="!inActiveDayMonday"/>
                  </div>
                </div>
                <div class="payment--to">
                  <div class="input">
                    <input
                      id="inActiveDayMondayTo"
                      class="input__input"
                      v-model="inActiveDayMondayTo"
                      type="time"
                      name="inActiveDayMondayTo"
                      :disabled="!inActiveDayMonday"/>
                  </div>
                </div>
              </div>
              <div class="input-group input--flex width-33">
                <div class="payment--check">
                  <div class="input checkbox">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="inActiveDayTuesday"
                        name="inActiveDayTuesday"
                        v-model="inActiveDayTuesday"
                        @click="totoggleInactiveDay()">
                      <label for="inActiveDayTuesday">
                        <span>
                          <span></span>
                        </span>
                        <p class="first-letter payment-label">{{ $t('tuesday') }}</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="payment--from">
                  <div class="input">
                    <input
                      id="inActiveDayTuesdayFrom"
                      class="input__input"
                      v-model="inActiveDayTuesdayFrom"
                      type="time"
                      name="inActiveDayTuesdayFrom"
                      :disabled="!inActiveDayTuesday"/>
                  </div>
                </div>
                <div class="payment--to">
                  <div class="input">
                    <input
                      id="inActiveDayTuesdayTo"
                      class="input__input"
                      v-model="inActiveDayTuesdayTo"
                      type="time"
                      name="inActiveDayTuesdayTo"
                      :disabled="!inActiveDayTuesday"/>
                  </div>
                </div>
              </div>
              <div class="input-group input--flex width-33">
                <div class="payment--check">
                  <div class="input checkbox">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="inActiveDayWednesday"
                        name="inActiveDayWednesday"
                        v-model="inActiveDayWednesday"
                        @click="totoggleInactiveDay()">
                      <label for="inActiveDayWednesday">
                        <span>
                          <span></span>
                        </span>
                        <p class="first-letter payment-label">{{ $t('wednesday') }}</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="payment--from">
                  <div class="input">
                    <input
                      id="inActiveDayWednesdayFrom"
                      class="input__input"
                      v-model="inActiveDayWednesdayFrom"
                      type="time"
                      name="inActiveDayWednesdayFrom"
                      :disabled="!inActiveDayWednesday"/>
                  </div>
                </div>
                <div class="payment--to">
                  <div class="input">
                    <input
                      id="inActiveDayWednesdayTo"
                      class="input__input"
                      v-model="inActiveDayWednesdayTo"
                      type="time"
                      name="inActiveDayWednesdayTo"
                      :disabled="!inActiveDayWednesday"/>
                  </div>
                </div>
              </div>
              <div class="input-group input--flex width-33">
                <div class="payment--check">
                  <div class="input checkbox">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="inActiveDayThursday"
                        name="inActiveDayThursday"
                        v-model="inActiveDayThursday"
                        @click="totoggleInactiveDay()">
                      <label for="inActiveDayThursday">
                        <span>
                          <span></span>
                        </span>
                        <p class="first-letter payment-label">{{ $t('thursday') }}</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="payment--from">
                  <div class="input">
                    <input
                      id="inActiveDayThursdayFrom"
                      class="input__input"
                      v-model="inActiveDayThursdayFrom"
                      type="time"
                      name="inActiveDayThursdayFrom"
                      :disabled="!inActiveDayThursday"/>
                  </div>
                </div>
                <div class="payment--to">
                  <div class="input">
                    <input
                      id="inActiveDayThursdayTo"
                      class="input__input"
                      v-model="inActiveDayThursdayTo"
                      type="time"
                      name="inActiveDayThursdayTo"
                      :disabled="!inActiveDayThursday"/>
                  </div>
                </div>
              </div>
              <div class="input-group input--flex width-33">
                <div class="payment--check">
                  <div class="input checkbox">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="inActiveDayFriday"
                        name="inActiveDayFriday"
                        v-model="inActiveDayFriday"
                        @click="totoggleInactiveDay()">
                      <label for="inActiveDayFriday">
                        <span>
                          <span></span>
                        </span>
                        <p class="first-letter payment-label">{{ $t('friday') }}</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="payment--from">
                  <div class="input">
                    <input
                      id="inActiveDayFridayFrom"
                      class="input__input"
                      v-model="inActiveDayFridayFrom"
                      type="time"
                      name="inActiveDayFridayFrom"
                      :disabled="!inActiveDayFriday"/>
                  </div>
                </div>
                <div class="payment--to">
                  <div class="input">
                    <input
                      id="inActiveDayFridayTo"
                      class="input__input"
                      v-model="inActiveDayFridayTo"
                      type="time"
                      name="inActiveDayFridayTo"
                      :disabled="!inActiveDayFriday"/>
                  </div>
                </div>
              </div>
              <div class="input-group input--flex width-33">
                <div class="payment--check">
                  <div class="input checkbox">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="inActiveDaySaturday"
                        name="inActiveDaySaturday"
                        v-model="inActiveDaySaturday"
                        @click="totoggleInactiveDay()">
                      <label for="inActiveDaySaturday">
                        <span>
                          <span></span>
                        </span>
                        <p class="first-letter payment-label">{{ $t('saturday') }}</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="payment--from">
                  <div class="input">
                    <input
                      id="inActiveDaySaturdayFrom"
                      class="input__input"
                      v-model="inActiveDaySaturdayFrom"
                      type="time"
                      name="inActiveDaySaturdayFrom"
                      :disabled="!inActiveDaySaturday"/>
                  </div>
                </div>
                <div class="payment--to">
                  <div class="input">
                    <input
                      id="inActiveDaySaturdayTo"
                      class="input__input"
                      v-model="inActiveDaySaturdayTo"
                      type="time"
                      name="inActiveDaySaturdayTo"
                      :disabled="!inActiveDaySaturday"/>
                  </div>
                </div>
              </div>
              <div class="input-group input--flex width-33">
                <div class="payment--check">
                  <div class="input checkbox">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="inActiveDaySunday"
                        name="inActiveDaySunday"
                        v-model="inActiveDaySunday"
                        @click="totoggleInactiveDay()">
                      <label for="inActiveDaySunday">
                        <span>
                          <span></span>
                        </span>
                        <p class="first-letter payment-label">{{ $t('sunday') }}</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="payment--from">
                  <div class="input">
                    <input
                      id="inActiveDaySundayFrom"
                      class="input__input"
                      v-model="inActiveDaySundayFrom"
                      type="time"
                      name="inActiveDaySundayFrom"
                      :disabled="!inActiveDaySunday"/>
                  </div>
                </div>
                <div class="payment--to">
                  <div class="input">
                    <input
                      id="inActiveDaySundayTo"
                      class="input__input"
                      v-model="inActiveDaySundayTo"
                      type="time"
                      name="inActiveDaySundayTo"
                      :disabled="!inActiveDaySunday"/>
                  </div>
                </div>
              </div>
              <div class="input-group input--flex width-33">
                <div class="payment--check">
                  <div class="input checkbox">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="inActiveDayAll"
                        name="inActiveDayAll"
                        v-model="inActiveDayAll"
                        @click="toggleInactiveDayAll()">
                      <label for="inActiveDayAll">
                        <span>
                          <span></span>
                        </span>
                        <p class="first-letter payment-label">{{ $t('all days') }}</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="payment--from">
                  <div class="input">
                    <input
                      id="inActiveDayAllFrom"
                      class="input__input"
                      v-model="inActiveDayAllFrom"
                      type="time"
                      name="inActiveDayAllFrom"
                      :disabled="!inActiveDayAll"/>
                  </div>
                </div>
                <div class="payment--to">
                  <div class="input">
                    <input
                      id="inActiveDayAllTo"
                      class="input__input"
                      v-model="inActiveDayAllTo"
                      type="time"
                      name="inActiveDayAllTo"
                      :disabled="!inActiveDayAll"/>
                  </div>
                </div>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section class="onservice--terminal__scorecards">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('scorecards') }}
            </template>
            <template slot="content">
              <div class="input-group input--flex">
                <label
                  for="scorecards-paper-enabled"
                  class="input__label">{{ $t('printPaperScorecard') }}</label>
                <toggle-switch
                  id="scorecards-paper-enabled"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.scorecards.enablePaper"
                  @toggle="toggle('scorecards', 'enablePaper', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="scorecards-paper-courses"
                  class="input__label">{{ $t('courses') }}</label>
              </div>
              <div v-for="(course, i) in this.result.settings.courses" :key="course.id">
                <div class="input-group input--flex">
                  <label
                    for="courses-allowScoreCardPrinting"
                    class="input__label">{{ course.name }}</label>
                  <toggle-switch
                    id="courses-allowScoreCardPrinting"
                    input-id="active"
                    type="switch"
                    :toggled="course.allowScoreCardPrinting"
                    @toggle="toggleCourse('courses', i, 'allowScoreCardPrinting', $event)"/>
                </div>
              </div>
              <div class="input-group input--flex">
                <label
                  for="scorecards-show-participants-from-other-bookings"
                  class="input__label">{{ $t('ShowParticipantsFromOtherBookings') }}</label>
                <toggle-switch
                  id="scorecards-show-participants-from-other-bookings"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.scorecards.showParticipantsFromOtherBookings"
                  @toggle="toggle('scorecards', 'showParticipantsFromOtherBookings', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="scorecards-digital-enabled"
                  class="input__label">{{ $t('printDigitalScorecard') }}</label>
                <toggle-switch
                  id="scorecards-digital-enabled"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.scorecards.enableDigital"
                  @toggle="toggle('scorecards', 'enableDigital', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="scorecards-digital-courses"
                  class="input__label">{{ $t('courses') }}</label>
              </div>
              <div v-for="(course, i) in this.result.settings.courses" :key="course.id">
                <div class="input-group input--flex">
                  <label
                    for="courses-allowDigitalScoreCard"
                    class="input__label">{{ course.name }}</label>
                  <toggle-switch
                    id="courses-allowDigitalScoreCard"
                    input-id="active"
                    type="switch"
                    :toggled="course.allowDigitalScoreCard"
                    @toggle="toggleCourse('courses', i, 'allowDigitalScoreCard', $event)"/>
                </div>
              </div>
              <div class="input-group input--flex">
                <label
                  for="scorecards-enableLooseScorecards"
                  class="input__label">{{ $t('enableLooseScorecards') }}</label>
                <toggle-switch
                  id="scorecards-enableLooseScorecards"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.scorecards.enableLooseScorecards"
                  @toggle="toggle('scorecards', 'enableLooseScorecards', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="scorecards-loose-courses"
                  class="input__label">{{ $t('courses') }}</label>
              </div>
              <div v-for="(course, i) in this.result.settings.courses" :key="course.id">
                <div class="input-group input--flex">
                  <label
                    for="courses-allowLooseScoreCard"
                    class="input__label">{{ course.name }}</label>
                  <toggle-switch
                    id="courses-allowLooseScoreCard"
                    input-id="active"
                    type="switch"
                    :toggled="course.allowLooseScoreCard"
                    @toggle="toggleCourse('courses', i, 'allowLooseScoreCard', $event)"/>
                </div>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section class="onservice--terminal__handicapAdjustment">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('handicapAdjustment') }}
            </template>
            <template slot="content">
              <div class="input-group input--flex">
                <label
                  for="handicapAdjustment-enabled"
                  class="input__label">{{ $t('handicapAdjustment') }}</label>
                <toggle-switch
                  id="handicapAdjustment-enabled"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.handicapAdjustment.enabled"
                  @toggle="toggle('handicapAdjustment', 'enabled', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="handicapAdjustment-own-club"
                  class="input__label">{{ $t('handicapAdjustmentOwnClub') }}</label>
                <toggle-switch
                  id="handicapAdjustment-own-club"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.handicapAdjustment.handicapAdjustmentOwnClub"
                  @toggle="toggle('handicapAdjustment', 'handicapAdjustmentOwnClub', $event)"/>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section class="onservice--terminal__booking">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('booking') }}
            </template>
            <template slot="content">
              <div class="input-group input--flex">
                <label
                  for="booking-enableBooking"
                  class="input__label">{{ $t('booking') }}</label>
                <toggle-switch
                  id="booking-enableBooking"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.booking.enableBooking"
                  @toggle="toggle('booking', 'enableBooking', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="booking-enableMyBookings"
                  class="input__label">{{ $t('myBookings') }}</label>
                <toggle-switch
                  id="booking-enableMyBookings"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.booking.enableMyBookings"
                  @toggle="toggle('booking', 'enableMyBookings', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="booking-showUpcomingSlots"
                  class="input__label">{{ $t('showUpcomingSlots') }}</label>
                <toggle-switch
                  id="booking-showUpcomingSlots"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.booking.showUpcomingSlots"
                  @toggle="toggle('booking', 'showUpcomingSlots', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="scorecards-paper-courses"
                  class="input__label">{{ $t('courses') }}</label>
              </div>
              <div v-for="(course, i) in this.result.settings.courses" :key="course.id">
                <div class="input-group input--flex">
                  <label
                    for="courses-showInUpcomingSlots"
                    class="input__label">{{ course.name }}</label>
                  <toggle-switch
                    id="courses-showInUpcomingSlots"
                    input-id="active"
                    type="switch"
                    :toggled="course.showInUpcomingSlots"
                    @toggle="toggleCourse('courses', i, 'showInUpcomingSlots', $event)"/>
                </div>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section class="onservice--terminal__competition">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('competition') }}
            </template>
            <template slot="content">
              <div class="input-group input--flex">
                <label
                  for="competition-enableCompetitions"
                  class="input__label">{{ $t('enabled') }}</label>
                <toggle-switch
                  id="competition-enableCompetitions"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.competition.enableCompetitions"
                  @toggle="toggle('competition', 'enableCompetitions', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="competition-enableMyCompetitions"
                  class="input__label">{{ $t('enableMyCompetitions') }}</label>
                <toggle-switch
                  id="competition-enableMyCompetitions"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.competition.enableMyCompetitions"
                  @toggle="toggle('competition', 'enableMyCompetitions', $event)"/>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section
          v-if="user.superUser"
          class="onservice--terminal__administration">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('administration') }}
            </template>
            <template slot="content">
              <div class="input-group input--flex">
                <label
                  for="administration-enabled"
                  class="input__label">{{ $t('enabled') }}</label>
                <toggle-switch
                  id="administration-enabled"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.administration.enabled"
                  @toggle="toggleAdministration('administration', 'enabled', $event)"/>
              </div>
              <div class="input-group--inline input--column">
                <label for="administration-code" class="input__label">{{ $t('code') }}{{': '}}{{this.result.settings.administration.code}}</label>
              </div>
              <div class="input-group input--flex">
                <label
                  for="administration-enableCardReaderCalibration"
                  class="input__label">{{ $t('enableCardReaderCalibration') }}</label>
                <toggle-switch
                  id="administration-enableCardReaderCalibration"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.administration.enableCardReaderCalibration"
                  @toggle="toggle('administration', 'enableCardReaderCalibration', $event)"/>
              </div>
              {{ $t('tracks') }}
              <div class="input-group--inline input--column">
                <label for="cardReader-timeout" class="input__label">{{ $t('timeout') }}</label>
                <input
                  type="text"
                  name="cardReader-timeout"
                  id="cardReader-timeout"
                  class="input__input"
                  required
                  v-model="result.settings.cardReader.timeout"
                  @change="changedInput('cardReader', 'timeout', $event.target.value, true)"/>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section class="onservice--terminal__payandplay">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('payAndPlay') }}
            </template>
            <template slot="content">
              <div class="input-group input--flex">
                <label
                  for="payAndPlay-enabled"
                  class="input__label">{{ $t('enabled') }}</label>
                <toggle-switch
                  id="payAndPlay-enabled"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.payAndPlay.enabled"
                  @toggle="toggle('payAndPlay', 'enabled', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="payAndPlay-showMessage"
                  class="input__label">{{ $t('showMessage') }}</label>
                <toggle-switch
                  id="payAndPlay-showMessage"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.payAndPlay.showMessage"
                  @toggle="toggle('payAndPlay', 'showMessage', $event)"/>
              </div>
              <div class="input-group--inline input--column">
                <label for="payAndPlay-message" class="input__label">{{ $t('message') }}</label>
                <textarea
                  name="payAndPlay-message"
                  id="payAndPlay-message"
                  class="input__input"
                  resize="none"
                  rows="3"
                  v-model="result.settings.payAndPlay.message"
                  @change="changedInput('payAndPlay', 'message', $event.target.value, false)">
                </textarea>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section class="onservice--terminal__store">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('store') }}
            </template>
            <template slot="content">
              <div class="input-group input--flex">
                <label
                  for="store-enabled"
                  class="input__label">{{ $t('enabled') }}</label>
                <toggle-switch
                  id="store-enabled"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.store.enabled"
                  @toggle="toggle('store', 'enabled', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="store-requireLogin"
                  class="input__label">{{ $t('requireLogin') }}</label>
                <toggle-switch
                  id="store-requireLogin"
                  input-id="active"
                  type="switch"
                  :toggled="!this.result.settings.store.enableAnonymous"
                  @toggle="toggleInvert('store', 'enableAnonymous', $event)"/>
              </div>
              <div class="input-group input--flex">
                <label
                  for="store-showMessage"
                  class="input__label">{{ $t('showMessage') }}</label>
                <toggle-switch
                  id="store-showMessage"
                  input-id="active"
                  type="switch"
                  :toggled="this.result.settings.store.showMessage"
                  @toggle="toggle('store', 'showMessage', $event)"/>
              </div>
              <div class="input-group--inline input--column">
                <label for="store-message" class="input__label">{{ $t('message') }}</label>
                <textarea
                  name="store-message"
                  id="store-message"
                  class="input__input"
                  resize="none"
                  rows="3"
                  v-model="result.settings.store.message"
                  @change="changedInput('store', 'message', $event.target.value, false)"/>
              </div>
              <div class="input-group--inline input--column">
                <label for="store-defaultTax" class="input__label">{{ $t('defaultTax') }}</label>
                <input
                  type="text"
                  name="store-defaultTax"
                  id="store-defaultTax"
                  class="input__input"
                  required
                  v-model="result.settings.store.defaultTax"
                  @change="changedInput('store', 'defaultTax', $event.target.value, true)"/>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section class="onservice--terminal__courses">
          <expand-collapse-variant :localExpanded="false">
            <template slot="title">
              {{ $t('courses') }}
            </template>
            <template slot="content">
              <div v-for="(course, i) in this.result.settings.courses" :key="course.id">
                <h3>{{ course.name }}</h3>
                <div class="input-group--inline input--column">
                  <label for="course-id" class="input__label">{{ $t('id') }}</label>
                  <input
                    type="text"
                    name="course-id"
                    id="course-id"
                    class="input__input"
                    required
                    v-model="course.id"
                    @change="changedCourse('courses', i, 'id', $event.target.value, true)"/>
                </div>
                <div class="input-group--inline input--column">
                  <label for="courses-sortOrder" class="input__label">{{ $t('sortOrder') }}</label>
                  <input
                    type="text"
                    name="courses-sortOrder"
                    id="courses-sortOrder"
                    class="input__input"
                    required
                    v-model="course.sortOrder"
                    @change="changedCourse('courses', i, 'sortOrder', $event.target.value, true)"/>
                </div>
              </div>
            </template>
          </expand-collapse-variant>
        </section>
        <section class="terminal--actions sticky sticky__bottom">
          <base-button
            :text="$t('save')"
            v-element-loader="{ loaderKey: 'terminals-edit-terminal' }"
            @click="saveTerminal" />
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';
import ExpandCollapseVariant from '@/components/expand-collapse-variant';
import ToggleSwitch from '@/components/form-elements/switch';
import CustomSelect from '@/components/form-elements/custom-select';
import elementLoader from '@/directives/element-loader';
import ModalWindow from '@/components/modal/modal';
export default {
  name: 'OnServiceEditTerminal',
  components: {
    UseSvg,
    BaseButton,
    ExpandCollapseVariant,
    ToggleSwitch,
    CustomSelect,
    ModalWindow,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      printerLocationOptions: [
        {
          displayName: 'Kassa',
          value: 1,
        },
        {
          displayName: 'Tidbokning',
          value: 2,
        },
        {
          displayName: 'Cloud',
          value: 3,
        },
      ],
      timeLimitOptions: [
        {
          displayName: '60 min',
          value: 60,
        },
        {
          displayName: '90 min',
          value: 90,
        },
        {
          displayName: '120 min',
          value: 120,
        },
        {
          displayName: '150 min',
          value: 150,
        },
        {
          displayName: '180 min',
          value: 180,
        },
        {
          displayName: '210 min',
          value: 210,
        },
        {
          displayName: '240 min',
          value: 240,
        },
      ],
      hover: '',
      inActiveDayMonday: false,
      inActiveDayMondayFrom: false,
      inActiveDayMondayTo: false,
      inActiveDayTuesday: false,
      inActiveDayTuesdayFrom: false,
      inActiveDayTuesdayTo: false,
      inActiveDayWednesday: false,
      inActiveDayWednesdayFrom: false,
      inActiveDayWednesdayTo: false,
      inActiveDayThursday: false,
      inActiveDayThursdayFrom: false,
      inActiveDayThursdayTo: false,
      inActiveDayFriday: false,
      inActiveDayFridayFrom: false,
      inActiveDayFridayTo: false,
      inActiveDaySaturday: false,
      inActiveDaySaturdayFrom: false,
      inActiveDaySaturdayTo: false,
      inActiveDaySunday: false,
      inActiveDaySundayFrom: false,
      inActiveDaySundayTo: false,
      inActiveDayAll: false,
      inActiveDayAllFrom: false,
      inActiveDayAllTo: false,
      showDeleteModal: false,
    };
  },
  mounted () {
    if (this.id) {
      this.$store.dispatch('terminals/fetchTerminal', { id: this.id });
    }
  },
  computed: {
    result () {
      return this.$store.getters['terminals/terminal'];
    },
    user () {
      return this.$store.getters['user/user'];
    },
  },
  watch: {
    '$route.params.id': function (id) {
      this.$store.commit('terminals/clearTerminal');
      this.$store.dispatch('terminals/fetchTerminal', { id: this.id });
    },
  },
  methods: {
    selectedTimeLimit (e) {
      this.result.settings.arrival.timeLimit = e.value;
    },
    selectedPrinterLocation (e) {
      this.result.settings.printing.printerLocation = e.value;
    },
    toggle (category, input, boolean) {
      this.result.settings[category][input] = boolean;
    },
    toggleInvert (category, input, boolean) {
      this.result.settings[category][input] = !boolean;
    },
    toggleCourse (category, index, input, boolean) {
      this.result.settings[category][index][input] = boolean;
    },
    toggleAdministration (category, input, boolean) {
      this.result.settings[category][input] = boolean;
      if(boolean) {
        this.result.settings.administration.code = (Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000).toString();
      } else {
        this.result.settings.administration.code = '';
      }
    },
    changedInput (category, input, value, number = false) {
      if (number === true) {
        value = parseInt(value, 10);
      }
      this.result.settings[category][input] = value;
    },
    changedCourse (category, index, input, value, number = false) {
      if (number === true) {
        value = parseInt(value, 10);
      }
      this.result.settings[category][index][input] = value;
    },
    chooseFile () {
      document.getElementById('file_upload_logo').click();
    },
    addFile (event) {
      let image = event.target.files[0];
      if (image) {
        this.$store.dispatch('terminals/addImage', { image: image });
      }
    },
    toggleDeleteTerminalModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    deleteTerminal() {
      this.$store.dispatch('terminals/deleteTerminal', {
        id: this.result.id,
      }).then(() => {
        this.$router.push({ path: '/onservice/terminals' });
        // TODO: Fix translation
        this.$toasted.success(this.$t('terminalDeleted'));
      });
    },
    hoverOn (element) {
      this.hover = element;
    },
    removeFile (image) {
      this.result.settings.logoId = '';
      this.result.settings.logoKey = '';
      this.result.settings.logoUrl = '';
    },
    toggleInactiveDayAll () {
      this.inActiveDayAll = !this.inActiveDayAll;
      this.inActiveDayMonday = this.inActiveDayTuesday = this.inActiveDayWednesday = this.inActiveDayThursday = this.inActiveDayFriday = this.inActiveDaySaturday = this.inActiveDaySunday = !this.inActiveDayAll;
      if (!this.inActiveDayAll) {
        this.inActiveDayMonday = this.inActiveDayTuesday = this.inActiveDayWednesday = this.inActiveDayThursday = this.inActiveDayFriday = this.inActiveDaySaturday = this.inActiveDaySunday = this.inActiveDayAll;
      }
    },
    totoggleInactiveDay () {
      if (this.inActiveDayAll === true) {
        this.inActiveDayAll = false;
      }
    },
    saveTerminal () {
      let paymentInactive = [];
      if (this.inActiveDayAll) {
        this.inActiveDayMonday = this.inActiveDayTuesday = this.inActiveDayWednesday = this.inActiveDayThursday = this.inActiveDayFriday = this.inActiveDaySaturday = this.inActiveDaySunday = this.inActiveDayAll;
        this.inActiveDayMondayFrom = this.inActiveDayTuesdayFrom = this.inActiveDayWednesdayFrom = this.inActiveDayThursdayFrom = this.inActiveDayFridayFrom = this.inActiveDaySaturdayFrom = this.inActiveDaySundayFrom = this.inActiveDayAllFrom;
        this.inActiveDayMondayTo = this.inActiveDayTuesdayTo = this.inActiveDayWednesdayTo = this.inActiveDayThursdayTo = this.inActiveDayFridayTo = this.inActiveDaySaturdayTo = this.inActiveDaySundayTo = this.inActiveDayAllTo;
      }
      if (this.inActiveDayMonday) {
        paymentInactive.push({
          'dayOfWeek': 0,
          'fromTime': this.inActiveDayMondayFrom,
          'toTime': this.inActiveDayMondayTo,
        });
      }
      if (this.inActiveDayTuesday) {
        paymentInactive.push({
          'dayOfWeek': 1,
          'fromTime': this.inActiveDayTuesdayFrom,
          'toTime': this.inActiveDayTuesdayTo,
        });
      }
      if (this.inActiveDayWednesday) {
        paymentInactive.push({
          'dayOfWeek': 2,
          'fromTime': this.inActiveDayWednesdayFrom,
          'toTime': this.inActiveDayWednesdayTo,
        });
      }
      if (this.inActiveDayThursday) {
        paymentInactive.push({
          'dayOfWeek': 3,
          'fromTime': this.inActiveDayThursdayFrom,
          'toTime': this.inActiveDayThursdayTo,
        });
      }
      if (this.inActiveDayFriday) {
        paymentInactive.push({
          'dayOfWeek': 4,
          'fromTime': this.inActiveDayFridayFrom,
          'toTime': this.inActiveDayFridayTo,
        });
      }
      if (this.inActiveDaySaturday) {
        paymentInactive.push({
          'dayOfWeek': 5,
          'fromTime': this.inActiveDaySaturdayFrom,
          'toTime': this.inActiveDaySaturdayTo,
        });
      }
      if (this.inActiveDaySunday) {
        paymentInactive.push({
          'dayOfWeek': 6,
          'fromTime': this.inActiveDaySundayFrom,
          'toTime': this.inActiveDaySundayTo,
        });
      }
      this.result.settings.payment.paymentInactive = paymentInactive;
      this.$store.dispatch('terminals/editTerminal', this.result).then(() => {
        this.$router.push({ path: '/onservice/terminals/' });
      });
    },
  },
};
</script>
<style lang="scss">
.onservice--top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.onservice__terminal {
  display: flex;
  flex-direction: column;
}
.terminal--functions {
  margin-top: 1rem;
}
.terminal--functions-list {
  margin-left: 1rem;
  margin-top: 0.4rem;
  padding-left: 1rem;

  li.status--true {
    list-style-image: url('~@/assets/images/li-circle-ok.svg');
  }
  li.status--false {
    list-style-image: url('~@/assets/images/li-circle-error.svg');
  }
}
.terminal--functions-list li:not(:last-child) {
  padding-bottom: 0.75rem;
}
.onservice--terminal__button {
  display: flex;
  a {
    margin-left: auto;
  }
}
.input--column {
  flex-direction: column;
  width: 33%;
}
.status--active {
  color: color(active-color);
  margin-left: 0.5rem;
}
.terminal__image {
  border: 1px solid color(gray);
  display: flex;
  height: 20rem;
  position: relative;
  width: 33%;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .terminal__image--image-overlay-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    button {
      margin-right: 0.3rem;
      padding: 0 0.5rem;
    }
  }
}
.terminal__image--image-overlay-wrapper {
  background-color: rgba(249, 249, 249, 0.5);
}
.terminal--actions {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 1rem;
  }
}
.payment__inactive {
  display: flex;
}
.payment__inactive--from {
  margin-left: auto;
  margin-right: 4rem;
}
.payment__inactive--to {
  margin-right: 2rem;
}
.payment--check {
  display: flex;
}
.payment--from {
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
}
.payment--to {
  display: flex;
}
</style>
