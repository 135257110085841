/* Store module to handle Clubs */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';
import { pad2 } from '@/core/helpers.js';

const state = {
  clubs: {
    rows: [],
    columns: [],
  },
  clubsSelect: {},
  club: {
    logoUrl: '',
    logoKey: '',
  },
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
  bookingSystems: [],
  courseSystems: [],
  onTagVersions: [],
  courseGuideLicenses: [],
  paymentProvider: [],
  regions: [],
  businessboardVersions: [],
  ontagcloudstatistics: {},
};

const mutations = {
  clubs(state, payload) {
    state.clubs.rows = payload.rows;
    state.clubs.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
  clubsSelect(state, payload) {
    state.clubsSelect = payload;
  },
  club(state, payload) {
    state.club = payload;
  },
  saveClub(state, { id, updated }) {
    let club = state.clubs.rows.find(c => c.id === id);
    if (!club) return;
    club.active = updated.active;
    club.name = updated.name;
    club.language = updated.language;
    club.email = updated.email;
    club.phoneNumber = updated.phoneNumber;
    club.bookingSystem = updated.bookingSystem;
    club.courseSystem = updated.courseSystem;
    club.logoUrl = updated.logoUrl;
    club.terminalVersion = updated.terminalVersion;
    club.terminalEndDate = updated.terminalEndDate;
    club.onTagVersion = updated.onTagVersion;
    club.onTagEndDate = updated.onTagEndDate;
    club.digitalScorecards = updated.digitalScorecards;
    club.digitalScorecardsEndDate = updated.digitalScorecardsEndDate;
    club.voucher = updated.voucher;
    club.appPrint = updated.appPrint;
    club.appPrintEndDate = updated.appPrintEndDate;
    club.voucherEndDate = updated.voucherEndDate;
    club.information = updated.information;
  },
  bookingSystems(state, payload) {
    state.bookingSystems = payload;
  },
  courseSystems(state, payload) {
    state.courseSystems = payload;
  },
  onTagVersions(state, payload) {
    state.onTagVersions = payload;
  },
  courseGuideLicenses(state, payload) {
    state.courseGuideLicenses = payload;
  },
  setLogotypeToClub(state, payload) {
    if (payload.type === 'LogoTransparent') {
      Vue.set(state.club, 'logoTransparentUrl', payload.url);
      Vue.set(state.club, 'logoTransparentKey', payload.keyValue);
    } else if (payload.type === 'ScoreCardProof') {
      Vue.set(state.club, 'scoreCardProofUrl', payload.url);
      Vue.set(state.club, 'scoreCardProofKey', payload.keyValue);
    } else {
      // Logo
      Vue.set(state.club, 'logoUrl', payload.url);
      Vue.set(state.club, 'logoKey', payload.keyValue);
    }
  },
  paymentProvider(state, payload) {
    state.paymentProvider = payload;
  },
  regions(state, payload) {
    state.regions = payload;
  },
  businessboardVersions(state, payload) {
    state.businessboardVersions = payload;
  },
  ontagcloudstatistics(state, payload) {
    state.ontagcloudstatistics = payload;
  },
};

const getters = {
  clubs: state => {
    return state.clubs;
  },
  clubsSelect: state => {
    return state.clubsSelect;
  },
  bookingSystems: state => {
    return state.bookingSystems;
  },
  courseSystems: state => {
    return state.courseSystems;
  },
  onTagVersions: state => {
    return state.onTagVersions;
  },
  courseGuideLicenses: state => {
    return state.courseGuideLicenses;
  },
  club: state => {
    return state.club;
  },
  pagination: state => {
    return state.pagination;
  },
  currentPage: state => {
    return state.pagination.currentPage;
  },
  nextPage: state => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: state => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
  paymentProvider: state => {
    return state.paymentProvider;
  },
  regions: state => {
    return state.regions;
  },
  businessboardVersions: state => {
    return state.businessboardVersions;
  },
  ontagcloudstatistics: state => {
    return state.ontagcloudstatistics;
  },
};

const actions = {
  clubsSelect: async (context, params) => {
    let path = 'clubs/select';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    const data = response.data;
    if (data.result) {
      context.commit('clubsSelect', data.result);
    }
  },
  clubsSelectBB: async (context, params) => {
    let path = 'clubs/businessboard/select';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    const data = response.data;
    if (data.result) {
      context.commit('clubsSelect', data.result);
    }
  },
  fetchClubs: async (context, params) => {
    let path = 'clubs';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => {
          if (params[k] !== undefined) {
            if (Array.isArray(params[k])) {
              return params[k].map(a => esc(k) + '=' + esc(a)).join('&');
            }
            return esc(k) + '=' + esc(params[k]);
          }
        })
        .filter(q => q !== undefined)
        .join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('clubs', data.result);
    }
  },
  fetchBookingSystems: async (context, params) => {
    let path = 'clubs/bookingsystems';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('bookingSystems', data.result);
    }
  },
  fetchCourseSystems: async (context, params) => {
    let path = 'clubs/coursesystems';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('courseSystems', data.result);
    }
  },
  fetchOnTagVersions: async (context, params) => {
    let path = 'clubs/ontagversions';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('onTagVersions', data.result);
    }
  },
  fetchCourseGuideLicenses: async (context, params) => {
    let path = 'clubs/courseguidelicenses';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('courseGuideLicenses', data.result);
    }
  },
  fetchClub: async (context, params) => {
    let path = 'clubs/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('club', data.result);
    }
  },
  fetchUserClub: async context => {
    let path = 'clubs/club';

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('club', data.result);
    }
  },
  fetchClubInfo: async (context, params) => {
    let path = 'clubs/' + params.id + '/info';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('club', data.result);
    }
  },
  saveClub: async (context, params) => {
    let loader = 'golfoffice-club-save';

    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
    let path = 'clubs/';

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('saveClub', {
        updated: data.result,
      });
    }
  },
  patchClub: async (context, params) => {
    let loader = 'golfoffice-club-edit';

    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
    let path = 'clubs/' + params.externalId;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('saveClub', {
        id: params.externalId,
        updated: data.result,
      });
    }
  },
  patchClubInfo: async (context, params) => {
    let loader = 'golfoffice-club-edit';

    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });
    let path = 'clubs/' + params.externalId + '/info';

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', loader, { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('saveClub', {
        id: params.externalId,
        updated: data.result,
      });
    }
  },
  addLogotype: async (context, params) => {
    let path = 'clubs/image';
    if (params.type) path += '?type=' + params.type;
    let formData = new FormData();
    formData.append('image', params.image);

    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('setLogotypeToClub', {
        type: params.type,
        url: data.result.url,
        keyValue: data.result.key,
      });
    }
  },
  fetchPaymentProvider: async (context, params) => {
    const path = 'clubs/paymentprovider';

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('paymentProvider', data.result);
    }
  },
  fetchRegions: async (context, params) => {
    const path = 'clubs/regions';

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('regions', data.result);
    }
  },
  fetchBusinessboardVersions: async (context, params) => {
    let path = 'clubs/businessboardversions';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('businessboardVersions', data.result);
    }
  },
  fetchOntagcloudstatistics: async (context, params) => {
    let path = 'clubs/ontagcloudstatistics';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('ontagcloudstatistics', data.result);
    }
  },
  exportClubs: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'clubs-export-clubs', {
      root: true,
    });
    let path = 'clubs/export';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => {
          if (params[k] !== undefined) {
            if (Array.isArray(params[k])) {
              return params[k].map(a => esc(k) + '=' + esc(a)).join('&');
            }
            return esc(k) + '=' + esc(params[k]);
          }
        })
        .filter(q => q !== undefined)
        .join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
        params: params,
      });
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'clubs-export-clubs', {
      root: true,
    });

    let date = new Date();
    const filename =
      'Klubbar-' +
      date.getFullYear().toString() +
      pad2(date.getMonth() + 1) +
      pad2(date.getDate()) +
      pad2(date.getHours()) +
      pad2(date.getMinutes()) +
      pad2(date.getSeconds());

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
