<template>
  <div class="ontag">
    <div
      class="ontag__content ontag__course"
      v-if="course">
      <div>
        <router-link
to="/ontag/handle-courses">&laquo; {{ $t('back') }}</router-link>
      </div>
      <h1>{{ course.name }}</h1>
      <p>
        På denna sida kan ni sätta era önskade idealtider för sällskapsspel på
        varje hål. Även banans hålnamn går att sätta egen touch på t.ex. vid
        specialfall om man döper hål till 2B eller dylikt vid ombyggnationer
        etc.
      </p>
      <div>
        <div class="course--header__actions">
          <div>
            <div
              class="starthole-amount"
              v-if="selectedIndexStartHole !== undefined">
              <span>{{ $t('courseStartHole') }}</span>
              <custom-select
                v-if="selectedIndexStartHole !== undefined"
                :required="false"
                @optionSelected="setStartHole"
                :selected="selectedIndexStartHole"
                :options="courseHolesOptions" />
            </div>
            <div class="input-group courses-switch">
              <label
                for="courseActive"
                class="input__label label__inline">{{ $t('courseActiveOnTag') }}</label>
              <toggle-switch
                input-id="courseActive"
                type="switch"
                class="courses-switch__toggle"
                :toggled="course.active"
                @toggle="courseActiveToggle" />
            </div>
          </div>
          <div class="latest-read">
            <p>
              Senaste inläsningen: <br />
              {{ course.lastUpdate }}
            </p>
            <base-button
              class="refresh-button"
              :text="$t('Refresh')"
              :button-size-small="true"
              v-element-loader="{ loaderKey: 'courses-refresh-courses' }"
              @click="refreshCourse" />
          </div>
        </div>
        <list-table
          v-if="
            course &&
            course.courseHoles &&
            course.courseHoles.rows &&
            course.courseHoles.columns
          "
          :items="course.courseHoles.rows"
          :headers="course.courseHoles.columns"
          :use-cursor="false"
          @changedInput="e => isInputChanged(e)">
          <tr class="courses__additional">
            <td class="uppercase">
              <strong>{{ $t('idealtime') }}</strong>
            </td>
            <td></td>
            <td>
              <strong>{{ getIdealTimeCompetition() }}</strong>
            </td>
            <td>
              <strong>{{ getIdealTimeFriendly() }}</strong>
            </td>
          </tr>
        </list-table>
        <div class="course__actions sticky sticky__bottom">
          <base-button
            :disabled="!canISave"
            :text="$t('save')"
            :button-size-small="true"
            v-element-loader="{ loaderKey: 'courses-save-course' }"
            @click="saveCourse">
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import ToggleSwitch from '@/components/form-elements/switch';
import ListTable from '@/components/list-table/list-table';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'CoursesEdit',
  components: {
    ToggleSwitch,
    ListTable,
    BaseButton,
    CustomSelect,
  },
  props: {
    id: String,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      inputChanged: false,
      toggleChanged: false,
      startHoleChanged: false,
      selectedIndexStartHole: undefined,
      courseHolesOptions: [],
      idealTimeComp: '',
      idealTimeFriend: '',
    };
  },
  methods: {
    courseActiveToggle(boolean) {
      this.toggleChanged = true;
      this.course.active = boolean;
    },
    isInputChanged(boolean) {
      this.inputChanged = boolean;
    },
    saveCourse() {
      this.$store
        .dispatch('courses/editCourse', {
          id: this.course.id,
          name: this.course.name,
          active: this.course.active,
          courseHoles: this.course.courseHoles,
          startHole: this.course.startHole,
        })
        .then(() => {
          this.$router.push({ path: '/ontag/handle-courses/' });
          this.$toasted.success(this.$t('courseSaved'));
        });
    },
    selectedStartHole(start) {
      let selectedStartHole = this.courseHolesOptions.findIndex(
        o => parseInt(o.value) === start
      );
      this.selectedIndexStartHole = selectedStartHole;
    },
    setStartHole(hole) {
      this.startHoleChanged = true;
      this.course.startHole = hole.value;
    },
    calculateIdealTime(min) {
      if (min) {
        let hours = Math.floor(min / 60);
        let minutes = min % 60;
        if (minutes < 10) {
          return hours + ':0' + minutes + ' h';
        } else {
          return hours + ':' + minutes + ' h';
        }
      }
    },
    getIdealTimeCompetition() {
      let time = this.course.courseHoles.rows.map(r =>
        parseInt(r.idealTimeCompetition)
      );
      this.idealTimeComp = time.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );
      return this.calculateIdealTime(this.idealTimeComp);
    },
    getIdealTimeFriendly() {
      let time = this.course.courseHoles.rows.map(r =>
        parseInt(r.idealTimeFriendly)
      );
      this.idealTimeFriend = time.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );
      return this.calculateIdealTime(this.idealTimeFriend);
    },

    refreshCourse() {
      this.$store
        .dispatch('courses/refreshCourse', {
          id: this.course.id,
          name: this.course.name,
          active: this.course.active,
          courseHoles: this.course.courseHoles,
          startHole: this.course.startHole,
        })
        .then(() => {
          this.$store.dispatch('courses/fetchCourse', { id: this.id });
        });
    },
  },
  computed: {
    course() {
      return this.$store.getters['courses/course'];
    },
    canISave() {
      return this.inputChanged || this.toggleChanged || this.startHoleChanged;
    },
  },
  mounted() {
    if (this.id) {
      this.$store
        .dispatch('courses/fetchCourse', { id: this.id })
        .then(() => {
          if (this.course && this.course.courseHoles.rows) {
            for (let i = 1; i <= this.course.courseHoles.rows.length; i++) {
              let row = {
                displayName: i.toString(),
                value: i.toString(),
              };
              this.courseHolesOptions.push(row);
            }
            this.selectedStartHole(this.course.startHole);
          }
        })
        .then(() => {});
    }
  },
  beforeDestroy() {
    if (this.scorecard) {
      this.scorecard.destroy();
    }
  },
};
</script>
<style lang="scss">
.ontag__course {
  .course--header__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .courses-switch {
    display: flex;
  }
  .courses-switch__toggle {
    margin-left: auto;
  }
  .course__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    button {
      margin-left: 1rem;
    }
  }
  .starthole-amount {
    display: flex;
    align-items: baseline;

    // .select__wrapper {
    //   width: 40%;
    //   @include media('media') {
    //     width: 20%;
    //   }
    //   @include media('large') {
    //     width: 15%;
    //   }
    // }
    span {
      margin-right: 1.6rem;
    }
  }
  .list-table {
    th {
      @include media('to-medium') {
        padding-bottom: 0.625rem !important;
      }
    }
  }
  .courses__additional {
    border-top: 1px solid color(gray);
    background-color: color(white) !important;

    td {
      padding: 0.625rem;
      &:last-child {
        text-align: left !important;
      }
    }
  }
  .latest-read {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
      margin-right: 20px;
    }
  }
}
</style>
