<template>
  <div class="drilldown">
    <div class="drillDownChoice">
      <div
        :class="['choice', { selected: drillDownRadioValue == 0 }]"
        @click="DiagramIncluded(0)">
        {{ $t('ReportTool.showIncludedDigrams') }}
        <div v-if="drillDownRadioValue == 0">
          <use-svg svg="bb-selected-icon" />
        </div>
      </div>
      <div
        :class="['choice', { selected: drillDownRadioValue == 1 }]"
        @click="DiagramIncluded(1)">
        {{ $t('ReportTool.showAllAvaiableDiagrams') }}
        <div v-if="drillDownRadioValue == 1">
          <use-svg svg="bb-selected-icon" />
        </div>
      </div>
    </div>
    <div class="info_icon">
      <info-icon :description="$t('ReportTool.description1')">
        <template #icon>
          <use-svg svg="bb-info-icon" />
        </template>
      </info-icon>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import InfoIcon from '@/components/businessboard/tooltip-icon';

export default {
  name: 'ReportsTemplate',
  components: {
    UseSvg,
    InfoIcon,
  },
  props: {
    diagrams: Array,
  },
  data() {
    return {
      drillDownRadioValue:
        this.diagrams.filter(d => d.includedInTemplate).length === 0 ? 1 : 0,
    };
  },
  computed: {},
  methods: {
    DiagramIncluded(v) {
      this.drillDownRadioValue = v;
      this.$emit('DiagramIncluded', v === 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';

.drilldown {
  position: sticky;
  top: 7rem;
  z-index: 8;
  color: #555f61;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  .info_icon {
    margin-left: 0.2rem;
    align-self: center;
  }
}
.drillDownChoice {
  margin-top: 0.5rem;
  width: 40rem;
  display: grid;
  grid-auto-flow: column;
  background: #eaf6f7;
  border-radius: 3px;
  font-weight: 400;
  padding: 4px;
  color: #131516;
  font-weight: 500;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
  .choice {
    margin: auto 0;
    text-align: center;
    border: 1px solid #eaf6f7;
    padding: 4px;
    border-radius: 3px;
  }
  .selected {
    background: #abded1;
    border-color: #0e3746;
    font-weight: 500;
    div {
      display: inline-block;
      margin: auto;
      vertical-align: middle;
    }
  }
}
</style>
