<template>
  <div class="coursedata-hole-add-par">
    <div class="title">{{ localItem.title }}</div>
    <div class="description">{{ localItem.description }}</div>
    <div class="coursedata-grid">
      <div class="tables">
        <draggable
          v-model="localGroup.holes"
          class="table-body"
          handle=".drag-handle"
          ghost-class="ghost-row"
          @end="onDraggableEnd">
          <div
            v-for="hole in localGroup.holes"
            :key="getHoleId(hole)"
            class="row">
            <div class="drag-handle tietoevry-icons-drag-indicator"></div>
            <div class="col hole-heading">Hole {{ hole.holeNumber }}</div>
            <div class="image-container">
              <img
                v-if="hole.holeImageUrl && hole.holeImageUrl != ''"
                class="image"
                :src="hole.holeImageUrl"
                :alt="hole.imageNumber" />
            </div>
            <div class="col par-heading">Par</div>
            <div
              v-for="p in par"
              :key="p + '-' + getHoleId(hole)"
              class="col">
              <input
                type="radio"
                :aria-label="getParAriaLabel(p)"
                :name="getHoleInputName(getHoleId(hole))"
                :checked="isEqual(hole.par, p)"
                :value="p"
                @input="updateProgress($event, hole, p)" />
              <label :for="getHoleInputName(getHoleId(hole))">{{ p }}</label>
            </div>
            <button
              name="remove-hole"
              class="te-btn te-btn-xs te-btn-destructive"
              @click="removeHole(hole)"
              style="margin-left: 10px">
              <span class="hidden">Remove</span>
              <span
                class="tietoevry-icons-trash"
                style="font-size: 120%"></span>
            </button>
          </div>
        </draggable>
      </div>
      <div class="summary">
        <div class="summary-title">Summary</div>
        <div class="summary-content">
          <span> Total par: </span>
          <span>
            {{ totalParCount }}
          </span>
        </div>
        <div class="summary-content">
          <button
            :disabled="this.localGroup.numberOfHoles == 18"
            class="te-btn te-btn-sm te-btn-primary"
            @click="addHole">
            Add hole
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!hideBottom"
      class="bottom">
      <button
        class="te-btn te-btn-md te-btn-tertiary"
        @click="$emit('cancel')">
        <i class="tietoevry-icons-arrow-small-left"></i>Previous
      </button>
      <button
        class="te-btn te-btn-md te-btn-primary"
        @click="$emit('done')"
        :disabled="localItem.progress !== 100">
        Continue
      </button>
    </div>
  </div>
</template>

<script>
// import { GroupsDataModel } from './holes-datamodel.mjs';
import draggable from 'vuedraggable';
export default {
  name: 'CourseDataHolesAddParView',
  components: {
    draggable,
  },
  props: {
    item: {
      type: Object,
    },
    group: {
      type: Object,
    },
    par: {
      type: Array,
    },
    hideBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['progress', 'done', 'cancel', 'changed'],
  watch: {
    group: {
      handler: function (newVal) {
        this.localGroup = newVal;
      },
      deep: true,
    },
    item: {
      handler: function (newVal) {
        this.localItem = newVal;
      },
      deep: true,
    },
  },
  data() {
    let localGroup = this.group;
    return {
      localItem: this.item,
      localGroup: localGroup,
      totalParCount: this.calculateTotalPar(localGroup),
    };
  },
  methods: {
    calculateTotalPar(group) {
      let total = 0;
      for (const hole of group.holes) {
        total += hole.par || 0;
      }
      return total;
    },
    onDraggableEnd() {
      this.resetNames();
    },
    getHoleId(hole) {
      return hole.id ?? hole._id;
    },
    resetNames() {
      let holes = this.localGroup.holes;
      for (let i = 0; i < holes.length; i++) {
        if (holes[i].originalHoleNumber === undefined) {
          holes[i].originalHoleNumber = holes[i].holeNumber;
        }
        holes[i].holeNumber = i + 1;
      }
      this.localGroup.holes = holes;
      this.$emit('changed', this.localGroup, this.localItem);
    },
    removeHole(hole) {
      let holes = this.localGroup.holes;
      let index = holes.indexOf(hole);
      if (index > -1) {
        holes.splice(index, 1);
      }
      this.localGroup.numberOfHoles = holes.length;
      this.localGroup.holes = holes;
      this.resetNames();
      // this.$emit('changed', this.localGroup, this.localItem);
    },
    addHole() {
      let holes = this.localGroup.holes;
      let newHole = {
        _id: Date.now().toString(36) + Math.random().toString(36),
        holeNumber: this.localGroup.numberOfHoles + 1,
        par: 0,
      };
      holes.push(newHole);
      this.localGroup.numberOfHoles = this.localGroup.numberOfHoles + 1;
      this.localGroup.holes = holes;
      this.$emit('changed', this.localGroup, this.localItem);
    },
    isEqual(a, b) {
      return a === b;
    },
    updateProgress(event, hole, par) {
      hole.par = par;
      this.totalParCount = this.calculateTotalPar(this.localGroup);
      this.$emit('changed', this.localGroup, this.localItem);
    },
    getParAriaLabel(par) {
      return `Par ${par}`;
    },
    getHoleInputName(hole) {
      return `hole-${hole}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.coursedata-hole-add-par {
  .hidden {
    display: none;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
  }
  font-family: 'Tietoevry sans 1';
  .title {
    color: var(--text-text-primary, #1e2224);
    /* Basic Heading 2 */
    font-family: 'Tietoevry sans 1';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    margin-bottom: 8px;
  }
  .description {
    color: var(--text-text-tertiary, #64676a);
    /* Paragraph text */
    font-family: 'Tietoevry sans 1';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .coursedata-grid {
    display: flex;
    gap: 28px;
  }
  .tables {
    margin-top: 40px;
    flex-direction: column;
    display: flex;
    gap: 40px;
    flex: 3;
  }

  .summary {
    border-radius: var(--Border-radius-Border-radius-L, 12px);
    border: 1px solid var(--Gray-Gray-8, #ddddde);
    background: var(--Surface-Surface-Primary, #fff);
    height: fit-content;
    position: sticky;
    top: 100px;
    flex: 1;
    .summary-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      border-radius: 12px 12px 0 0;
      overflow: hidden;
      background-color: var(--blue-blue-7);
      padding: 16px;
    }
    .summary-content {
      border-bottom: 1px solid var(--Gray-Gray-8, #ddddde);
    }
    .summary-content:last-child {
      border-bottom: none;
    }
    .summary-content {
      padding: 16px;
      display: flex;
      justify-content: space-between;
    }
  }

  .table-head {
    color: var(--text-text-tertiary, #64676a);
    /* Basic Heading 5 */
    font-family: 'Tietoevry sans 1';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    margin-bottom: 8px;
  }

  .table-body {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--border-radius-border-radius-l, 12px);
    border: 1px solid var(--gray-gray-8, #ddddde);
    overflow: hidden;
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-direction: row;
      flex: 1;
      padding: 12px 8px;
    }
    .row .drag-handle {
      display: inline-block;
      cursor: move;
      font-size: 22px;
      font-weight: bold;
    }
    .row .image-container {
      height: 20px;
      flex: 1;
    }
    .row .image {
      height: 20px;
      aspect-ratio: 1/2;
      position: absolute;
      transition: transform 0.15s;
      &:hover {
        z-index: 9999;
        transform: scale(10);
      }
    }
    .row .par-heading {
      color: var(--text-text-primary, #1e2224);
      /* Basic Heading 4 */
      font-family: 'Tietoevry sans 1';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      padding-left: 5px;
    }
    .row .col {
      justify-content: flex-start;
      align-items: center;
      padding: 0px 10px;
    }
    .row .col input[type='radio'] {
      position: relative;
      top: 2px;
      appearance: none;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      border: 1px solid #86888b;
      transition: 0.2s border ease-in-out;
      margin-right: 4px;
    }

    .row .col input:checked {
      border: 6px solid #1861f2;
    }
    .row:nth-child(odd) {
      background: var(--gray-gray-10, #f6f6f7);
    }
    .row:nth-child(even) {
      background: var(--default-white, #fff);
    }
    .ghost-row {
      visibility: hidden;
    }
    .row {
      .hole-heading {
        width: 80px;
        display: flex;
        align-items: center;
        align-self: stretch;
        color: var(--text-text-primary, #1e2224);
        /* Basic Heading 3 */
        font-family: 'Tietoevry sans 1';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        padding-left: 5px;
      }
    }
  }
}
</style>
