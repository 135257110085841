<template>
  <div
    id="app"
    class="">
    <main-navigation :hide="!loggedIn" />
    <div class="container">
      <side-navigation :hide="!mq('to-small') && !loggedIn" />
      <router-view
        :key="$route.fullPath"
        :class="marginClass"
        :id="$route.params.id" />
    </div>
    <cookie-consent />
    <div class="copyright">
      <img
        src="./assets/images/TE-Lockup-RGB-BLUE.svg"
        height="18"
        alt="tietoEvry logo" />
      &copy; Tietoevry Sweden AB <span class="version">(v.{{ version }})</span>
      <p>
        <a href="https://www.tietoevry.com/en/privacy-notice/">
          Privacy notice
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import mq from '@/mixins/mq.js';
import EventBus from '@/core/event-bus.js';
import CookieConsent from 'vue-cookieconsent-component';
import SideNavigation from './components/navigation/side-navigation.vue';
import MainNavigation from './components/navigation/main-navigation.vue';
export default {
  components: {
    MainNavigation,
    SideNavigation,
    CookieConsent,
  },
  mixins: [mq],
  computed: {
    version() {
      return this.$store.getters['appSettings/version'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    loggedIn() {
      return this.$store.getters['user/loggedIn'];
    },
    marginClass() {
      return this.loggedIn ? 'content' : 'no-margin';
    },
  },
  mounted() {
    const version = require('../package.json').version;
    this.$store.commit('appSettings/setVersion', version);
    if (this.$cookies.isKey('token')) {
      if (this.$cookies.isKey('refresh_token')) {
        this.$store.commit('user/token', this.$cookies.get('token'));
        this.$store.commit(
          'user/refreshToken',
          this.$cookies.get('refresh_token')
        );
        this.$store.dispatch('user/refreshToken');
      }
    }
  },
  methods: {
    showToast(type, msg = 'An error occured') {
      this.$toasted.show(msg, {
        theme: 'toasted-primary',
        type: type,
        position: 'top-right',
        containerClass: 'custom-toasted',
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      });
    },
  },
  created() {
    EventBus.$on('toast', payload => {
      this.showToast(payload.type, `${payload.msg}`);
    });
  },
};
</script>
<style lang="scss">
@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent';
@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom';

#app {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
}
.content {
  margin: 4.375rem 0 1rem 0;

  @include media('small') {
    margin: 4.5rem 1rem 1rem 17.5rem;
  }
}
.no-margin {
  margin: 0;
  @include media('small') {
    margin: 0;
  }
}
.copyright {
  color: color(gray-darker);
  font-size: smaller;
  text-align: center;
  margin-left: 0;

  @include media('small') {
    margin-left: 16.7rem;
  }
  img {
    vertical-align: middle;
  }

  p {
    margin: 0.5rem;
  }
}
</style>
