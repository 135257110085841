<template>
  <td :key="discountType + index">
    <template
      v-if="discountType && discountType.length > 0 && discountType[index]">
      <div class="display-flex">
        <input
          type="text"
          :value="discountType[index].amount ? discountType[index].amount : 0"
          @change="
            event =>
              updateDiscountAmount(
                event,
                discountType[index].feeColumnReference
              )
          "
          class="input_style"
          aria-label="Rabatt"
          :placeholder="discountType[index].amount" />
        <p class="input-unit">kr</p>
      </div>
      <div>
        <p
          v-if="
            showPreviousYear &&
            previousYearDiscount.length > 0 &&
            previousYearDiscount[index]
          "
          class="previous-year-amount">
          {{ previousYearDiscount[index].amount }}
          kr
        </p>
        <p
          v-else-if="showPreviousYear"
          class="previous-year-amount">
          {{ 0 }}
          kr
        </p>
      </div>
    </template>
    <template v-else>
      <div>
        <!-- empty -->
      </div>
    </template>
  </td>
</template>

<script>
export default {
  name: 'discount-row-fees',
  components: {},
  props: {
    discountType: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    previousYearDiscount: {
      type: Array || null,
      default: () => [],
    },
    showPreviousYear: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateDiscountAmount(amount, feeColumnReference) {
      const newDiscountAmount = [
        {
          amount: amount.target.value,
          feeColumnReference: feeColumnReference,
          budgeted: null,
        },
      ];
      this.$emit('update-discount-amount', ...newDiscountAmount);
    },
  },
};
</script>

<style lang="scss" scoped>
.input_style {
  text-align: right;
  margin-top: 0.2rem;

  height: 25px;
  border-radius: 4px;
  max-width: 116px;
}

.previous-year-amount {
  text-align: right;
  position: relative;
  top: -0.5rem;
  right: 0.5rem;

  max-width: 116px;
  height: 0px;
  color: #6f7c80;
  font-size: font(small);
}

.display-flex {
  display: flex;
  gap: 0.2rem;
  margin-left: auto;

  height: 25px;
}
.input-unit {
  margin-top: 0.2rem;
}
</style>
