<template>
  <div
    class="balance-report"
    v-if="!isLoading">
    <div v-if="hasAccess">
      <h1 class="title">
        {{ $t('balanceReport') }}
      </h1>
      <div class="actions">
        <router-link
          v-if="companyId !== 'consolidatedaccounts'"
          :to="settingsUrl">
          <base-button
            class="settings-button"
            background-color="bb-primary"
            :text="$t('editBalanceAccounts')">
            <use-svg svg="bb-gear-icon" />
          </base-button>
        </router-link>
        <router-link
          :to="'/businessboard/balancesheet/consolidatedaccounts/settings'">
          <base-button
            class="settings-button"
            background-color="bb-primary"
            :text="$t('editBalanceGroups')">
            <use-svg svg="bb-gear-icon" />
          </base-button>
        </router-link>
        <base-button
          class="settings-button"
          @click="copyTableData"
          backgroundColor="white"
          :text="$t('copyTableData')">
          <use-svg svg="bb-copy-icon" />
        </base-button>
      </div>
      <last-updated
        :last-update="latestUpdates"
        :show-member="false"
        :show-game="false"
        :show-economy="true"
        :show-warning="notconnectedaccounts.length > 0"
        :is-balance-accounts="true" />

      <div
        class="drill-down-container"
        v-if="drillDowns">
        <div
          v-for="(drillDown, index) in drillDowns"
          :key="index">
          <div v-if="drillDown.loading"><list-table-loader /></div>
          <drilldown-list
            v-else
            :drillDownList="drillDown">
          </drilldown-list>
        </div>
      </div>
    </div>
    <div v-else>
      <missing-license
        licenseType="Business Board Professional"
        :imageUrl="require('@/assets/images/balance-report-preview.png')"
        :previewDescription="$t('professionalDescription')" />
    </div>
  </div>
</template>

<script>
import DrilldownList from '@/components/drilldown/drilldown-list.vue';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg.vue';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import ListTableLoader from '@/components/reports-templates/list-table-loader.vue';
import MissingLicense from '@/components/businessboard/missing-license.vue';
import { BusinessBoardVersion } from '@/core/consts';
import LastUpdated from '@/components/last-updated/last-updated.vue';

export default {
  name: 'BalanceReport',
  components: {
    DrilldownList,
    BaseButton,
    UseSvg,
    ListTableLoader,
    MissingLicense,
    LastUpdated,
  },
  mixins: [LocalizationMixin],
  props: {
    companyId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      drillDowns: [],
      settingsUrl: window.location.pathname + '/settings', // should be like this when we fix the urls
    };
  },
  methods: {
    async fetchDrillDowns(categories) {
      for (var category of categories) {
        const payload = {
          id: category.externalId,
        };
        if (!this.companyId || this.companyId !== 'consolidatedaccounts') {
          payload.companyExternalId = this.companyId;
        }

        const drillDown = { loading: true };
        this.drillDowns.push(drillDown);
        const result = await this.$store.dispatch(
          'balanceReport/fetchBalanceDrillDown',
          payload
        );
        Object.assign(drillDown, result);
        drillDown.loading = false;
      }

      this.drillDowns = this.drillDowns.sort((a, b) => a.order - b.order);
    },
    async fetchTotalAssetsDrillDown() {
      const payload = {};

      if (!this.companyId || this.companyId !== 'consolidatedaccounts') {
        payload.companyExternalId = this.companyId;
      }

      const result = await this.$store.dispatch(
        'balanceReport/fetchBalanceTotalAssetsDrillDown',
        payload
      );

      this.drillDowns.push(result);

      this.drillDowns = this.drillDowns.sort((a, b) => a.order - b.order);
    },
    async fetchCalculatedResultDrillDown() {
      const payload = {};

      if (!this.companyId || this.companyId !== 'consolidatedaccounts') {
        payload.companyExternalId = this.companyId;
      }

      const result = await this.$store.dispatch(
        'balanceReport/fetchBalanceCalculatedResultDrillDown',
        payload
      );

      this.drillDowns.push(result);

      this.drillDowns = this.drillDowns.sort((a, b) => a.order - b.order);
    },
    copyData(drillDownList) {
      let text =
        drillDownList.title.replace(/\r?\n|\r/g, ' ') +
        '\t' +
        drillDownList.header.columns.join('\t') +
        '\n';
      for (let row of drillDownList.rows) {
        text +=
          row.label.replace(/\r?\n|\r/g, ' ') +
          '\t' +
          row.values.join('\t') +
          '\n';
      }
      return text;
    },
    copyTableData() {
      let text = '';
      for (let drillDownList of this.drillDowns) {
        text += this.copyData(drillDownList) + '\n\n';
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.copyDataText = 'copiedToClipboard';
        },
        function (err) {
          this.copyDataText = '';
          console.error('Async: Could not copy text: ', err);
        }
      );
    },
  },
  computed: {
    balanceAccountCategories() {
      return this.$store.getters['balanceReport/balanceAccountCategories'];
    },
    balanceDrillDown() {
      return this.$store.getters['balanceReport/balanceCategoryDrillDown'];
    },
    settings() {
      return this.$store.getters['businessboard/settings'];
    },
    hasAccess() {
      return (
        this.settings.businessBoardVersion === BusinessBoardVersion.Professional
      );
    },
    latestUpdates() {
      return this.$store.getters['businessboard/lastUpdate'];
    },
    accounts() {
      return this.$store.getters['BusinessBoardBalanceSheet/accounts'];
    },
    notconnectedaccounts() {
      return this.accounts?.filter(a => a.balanceAccountGroupId === null) || [];
    },
  },
  watch: {
    async balanceAccountCategories(newVal) {
      await this.fetchDrillDowns(newVal);
    },
  },
  async created() {
    await this.$store.dispatch('businessboard/fetchSettings');
    this.isLoading = false;
    await this.$store.dispatch('balanceReport/fetchBalanceCategories');
    await this.fetchTotalAssetsDrillDown();
    await this.fetchCalculatedResultDrillDown();
    this.$store.dispatch('businessboard/fetchLastUpdate', {
      balanceAccounts: true,
      dataSources: [0],
    });
  },
};
</script>

<style lang="scss" scoped>
.balance-report {
  min-height: 87vh;
  display: flex;
}

.actions {
  display: flex;
  flex-direction: row;
}

.title {
  margin-left: 25px;
  font-size: 35px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.settings-button {
  border-radius: 8px;
  margin: 0 0.5rem;
}

.drill-down-container {
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin: 30px;
}
</style>
