<template>
  <div class="legend">
    <div
      :class="['row']"
      v-for="(label, i) in labels"
      :key="i">
      <div :style="'background: ' + colors[i] + ';'"></div>

      <div class="label">
        {{ label }}
      </div>

      <div class="value-row">
        {{ `${precentage[i]}%` }}, {{ numbers[i].toLocaleString('sv-SE') }}
        {{ type }}
      </div>
    </div>
  </div>
</template>

<script>
import { COLORS } from '../../charts/pie-chart-colors';
export default {
  props: {
    labels: {
      type: Array,
    },
    numbers: {
      type: Array,
    },
    precentage: {
      type: Array,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    colors() {
      if (this.labels.length === 4) {
        return ['#24665b', '#ABDED1', '#0E3746', '#4B2D88'];
      }
      return COLORS;
    },
  },
};
</script>

<style lang="scss" scoped>
.legend {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  max-height: 20rem;
  flex-wrap: wrap;

  .row {
    margin: 0.5rem;
    display: grid;
    grid-template-columns: 15px auto;

    div:first-child {
      justify-self: center;
      margin-right: 10px;
      margin-top: 3px;
      border-radius: 2px;
      width: 15px;
      height: 15px;
    }

    .line {
      width: 15px;
      margin-top: 5px;
    }
  }

  .hidden {
    opacity: 0.5;
  }

  .value-row {
    font-size: medium;
    font-weight: 400;
    width: 15rem;
    padding-top: 0.2rem;
  }

  .label {
    white-space: pre;
    line-height: 20px;
  }
}
</style>
