<template>
  <div
    class="reports-template"
    :class="{ expanded: expanded }">
    <div
      class="reports-template-header"
      @click="expanded = true">
      <div
        class="reports-template-header-edit"
        v-if="expanded">
        <input
          maxlength="50"
          @change="editTemplateName(templateId)"
          class="input--template-title"
          :placeholder="templateName"
          :size="currentTemplateName.length"
          v-model="currentTemplateName"
          ref="templateName" />
        <div
          @click="editFocusOnTemplateName()"
          class="button-hover"
          backgroundColor="white">
          <use-svg
            svg="bb-edit-icon"
            color="white"
            stroke="black" />
        </div>
      </div>
      <div v-else>
        <h2>{{ templateName }}</h2>
      </div>
      <div class="template-toggle-hide">
        <div
          class="delete-template"
          v-if="expanded">
          <div
            @click="deleteTemplate(templateId)"
            backgroundColor="white"
            class="button-hover">
            <img
              src="@/components/svg/bb-trash2-icon.svg"
              class="trash-icon"
              alt="" />
          </div>
        </div>
        <div
          class="template-toggle-hide"
          @click.stop="expanded = !expanded">
          <p>{{ expanded ? $t('showLess') : $t('open') }}</p>
          <button
            :title="expanded ? $t('showLess') : $t('open')"
            :class="{ 'close--button': expanded, 'open--button': !expanded }">
            <use-svg
              svg="arrow-right"
              :class="{ 'svg--180-negative': expanded }" />
          </button>
        </div>
      </div>
    </div>

    <div
      class="reports-template-content"
      v-if="expanded">
      <div>
        <diagram-include-filter
          :diagrams="template.diagrams"
          @DiagramIncluded="e => ChangeDiagramView(e)" />

        <div
          v-if="!clubHasEconomicLicense"
          class="section section_members"
          :class="{ 'show-members-section': showMembersSection }">
          <div @click.stop="showMembersSection = !showMembersSection">
            <div class="section_div">
              <use-svg
                svg="bb-member-icon"
                class="section-icon" />
              <h1>
                {{ $t('ReportTool.members') }} ({{ diagramCount('member') }}
                {{ $t('piece') }})
              </h1>
              <div class="template-toggle-hide">
                <p>{{ showMembersSection ? $t('showLess') : $t('open') }}</p>
                <button class="close--button">
                  <use-svg
                    svg="arrow-right"
                    :class="{ 'svg--180-negative': showMembersSection }" />
                </button>
              </div>
            </div>
          </div>
          <div
            v-show="showMembersSection"
            v-if="template">
            <member-total
              :diagram="
                template.diagrams.find(
                  d => d.reportType === 'MemberTotalAndPerCategory'
                )
              "
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />

            <member-income
              :diagram="
                template.diagrams.find(d => d.reportType === 'MemberIncome')
              "
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />
          </div>
        </div>

        <div
          v-if="!clubHasEconomicLicense"
          class="section section_games"
          :class="{ 'show-games-section': showGamesSection }">
          <div @click.stop="showGamesSection = !showGamesSection">
            <div class="section_div">
              <use-svg
                svg="bb-course-icon-dark"
                class="section-icon" />
              <h1>
                {{ $t('ReportTool.game') }} ({{ diagramCount('game') }}
                {{ $t('piece') }})
              </h1>

              <div class="template-toggle-hide">
                <p>{{ showGamesSection ? $t('showLess') : $t('open') }}</p>
                <button class="close--button">
                  <use-svg
                    svg="arrow-right"
                    :class="{ 'svg--180-negative': showGamesSection }" />
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="showGamesSection"
            v-if="template">
            <game-total-rounds
              :diagram="
                template.diagrams.find(d => d.reportType === 'GameTotalRounds')
              "
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />
            <game-course-rounds
              :diagram="
                template.diagrams.find(d => d.reportType === 'GameCourseRounds')
              "
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />
            <game-member-rounds
              :diagram="
                template.diagrams.find(d => d.reportType === 'GameMemberRounds')
              "
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />
            <game-month-rounds
              :diagram="
                template.diagrams.find(d => d.reportType === 'GameMonthRounds')
              "
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />
            <game-greenfee-income
              :diagram="
                template.diagrams.find(
                  d => d.reportType === 'GameGreenfeeIncome'
                )
              "
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />
          </div>
        </div>

        <div
          class="section section_economy"
          :class="{ 'show-economy-section': showEconomySection }">
          <div @click.stop="showEconomySection = !showEconomySection">
            <div class="section_div">
              <use-svg
                svg="bb-company-icon-dark"
                class="section-icon" />
              <h1>
                {{ $t('ReportTool.economy') }} ({{ diagramCount('economy') }}
                {{ $t('piece') }})
              </h1>

              <div class="template-toggle-hide">
                <p>{{ showEconomySection ? $t('showLess') : $t('open') }}</p>
                <button class="close--button">
                  <use-svg
                    svg="arrow-right"
                    :class="{ 'svg--180-negative': showEconomySection }" />
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="showEconomySection"
            v-if="template">
            <economy-total
              :diagram="
                template.diagrams.find(d => d.reportType === 'EconomyTotal')
              "
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />

            <economy-budget
              :diagram="
                template.diagrams.find(d => d.reportType === 'EconomyBudget')
              "
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />

            <economy-company
              v-for="company in lastUpdate.filter(lu => lu.type === 0)"
              :key="'economy-company' + company.name"
              :diagram="
                template.diagrams.find(d => d.reportType === 'EconomyCompany')
              "
              :companyName="company.name"
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />

            <economy-company-budget
              v-for="company in lastUpdate.filter(lu => lu.type === 0)"
              :key="'economy-company-budget' + company.name"
              :diagram="
                template.diagrams.find(
                  d => d.reportType === 'EconomyCompanyBudget'
                )
              "
              :companyName="company.name"
              :templateId="template.externalId"
              :showAllDiagrams="showAllDiagrams" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import MemberTotal from '@/components/reports-templates/diagrams/member-total';
import MemberIncome from '@/components/reports-templates/diagrams/member-income';
import GameTotalRounds from '@/components/reports-templates/diagrams/game-total-rounds';
import GameCourseRounds from '@/components/reports-templates/diagrams/game-course-rounds';
import GameMemberRounds from '@/components/reports-templates/diagrams/game-member-rounds';
import GameMonthRounds from '@/components/reports-templates/diagrams/game-month-rounds';
import GameGreenfeeIncome from '@/components/reports-templates/diagrams/game-greenfee-income';
import EconomyTotal from '@/components/reports-templates/diagrams/economy-total';
import EconomyBudget from '@/components/reports-templates/diagrams/economy-budget';
import EconomyCompany from '@/components/reports-templates/diagrams/economy-company';
import EconomyCompanyBudget from '@/components/reports-templates/diagrams/economy-company-budget';
import diagramIncludeFilter from './settings-modal/diagram-include-filter';
import LocalizationMixin from '../../mixins/localization-mixin.vue';
import { BusinessBoardVersion } from '@/core/consts';

export default {
  name: 'ReportsTemplate',
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
    MemberTotal,
    MemberIncome,
    GameTotalRounds,
    GameCourseRounds,
    GameMemberRounds,
    GameMonthRounds,
    GameGreenfeeIncome,
    EconomyTotal,
    EconomyBudget,
    EconomyCompany,
    EconomyCompanyBudget,
    diagramIncludeFilter,
  },
  props: {
    templateName: String,
    iconName: String,
    templateId: String,
    template: {},
  },
  data() {
    return {
      expanded: false,
      currentTemplateName: this.templateName,
      showMembersSection: false,
      showGamesSection: false,
      showEconomySection: false,
      showAllDiagrams:
        this.template.diagrams.filter(d => d.includedInTemplate).length === 0,
    };
  },
  computed: {
    lastUpdate() {
      return this.$store.getters['businessboard/lastUpdate'];
    },
    bbSettings() {
      return this.$store.getters['businessboard/settings'];
    },
    clubHasEconomicLicense() {
      return (
        this.bbSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicBasic ||
        this.bbSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicPlus
      );
    },
  },
  methods: {
    ChangeDiagramView(isShowAllDiagrams) {
      this.showAllDiagrams = isShowAllDiagrams;
    },
    editFocusOnTemplateName() {
      this.$nextTick(() => {
        this.$refs.templateName.focus();
      });
    },
    editTemplateName(id) {
      this.templateName = this.currentTemplateName;
      this.$store.dispatch('businessboardReportingTool/updateTemplate', {
        name: this.currentTemplateName,
        id: id,
      });
    },
    deleteTemplate(id) {
      if (confirm('Vill du verkligen ta bort mallen?')) {
        this.$store.dispatch('businessboardReportingTool/deleteTemplate', {
          id: id,
        });
      }
    },
    scrollToAndOpen() {
      this.expanded = true;
      window.scrollTo({
        behavior: 'smooth',
        top: document.body.scrollHeight,
      });
    },
    diagramCount(prefix) {
      let companyDiagramCounter = 0;
      if (prefix === 'economy') {
        if (
          this.template.diagrams.find(
            d =>
              (this.showAllDiagrams || d.includedInTemplate) &&
              d.reportType === 'EconomyCompany'
          )
        ) {
          companyDiagramCounter +=
            this.lastUpdate.filter(lu => lu.type === 0).length - 1;
        }
        if (
          this.template.diagrams.find(
            d =>
              (this.showAllDiagrams || d.includedInTemplate) &&
              d.reportType === 'EconomyCompanyBudget'
          )
        ) {
          companyDiagramCounter +=
            this.lastUpdate.filter(lu => lu.type === 0).length - 1;
        }
      }
      return (
        this.template.diagrams.filter(
          d =>
            (this.showAllDiagrams || d.includedInTemplate) &&
            d.reportType.toLowerCase().startsWith(prefix)
        ).length + companyDiagramCounter
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';

.content {
  display: flex;
  flex-direction: column;
}

.reports-template-header {
  display: flex;
  cursor: pointer;
}
.expanded {
  .reports-template-header {
    position: sticky;
    top: 4.6rem;
    background-color: white;
    z-index: 8;
  }
}
.reports-template {
  margin-left: 2rem;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.2rem;
  font-weight: 600;
  font-family: 'SoraRegular';
  h2 {
    margin: 0.8rem;
  }
}

.section {
  .template-toggle-hide {
    font-size: 16px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-weight: 600;
  border-radius: 8px;
  &_members {
    // border: 1px solid #f08565;
    background: rgba(251, 235, 221, 0.5);
    color: #373d3f;
  }

  &_games {
    background: rgba(234, 246, 246, 0.5);
    // border: 1px solid #00a99d;
    color: #373d3f;
  }

  &_economy {
    background: rgba(245, 244, 248, 0.5);
    // border: 1px solid #4b2d88;
    color: #373d3f;
  }

  .template-toggle-hide {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.template-toggle-hide {
  margin-left: auto;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.close--button {
  background-color: transparent;
  border: none;
  color: black;
}

.open--button {
  background-color: transparent;
  border: none;
  color: black;
  margin-top: 0.2rem;
}
.input--template-title {
  color: #000;
  border: none;
  border-bottom: 0.1rem solid white;
  background-color: transparent;
  font-size: x-large;
  font-weight: 500;
  height: 34px;
}

input::placeholder {
  font-size: x-large;
  color: #000;
  font-weight: 500;
}

.reports-template-header-edit {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.delete-template {
  margin-right: 1rem;
}
.section_div {
  display: flex;
  gap: 1rem;
  cursor: pointer;

  > h1 {
    font-size: 20px;
  }

  .section-icon {
    padding-top: 0.5rem;
    padding-left: 0.7rem;
    height: 45px;
    width: 45px;
  }
}

.close-button {
  display: flex;
  gap: 1rem;

  > svg {
    margin-top: 1rem;
  }
}

.green-member-icon {
  padding-left: 0.4rem;
}

.edit-icon {
  width: 25px;
  height: 25px;
}
.trash-icon {
  width: 16px;
  height: 16px;
}

.button-hover {
  &:hover {
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  width: 34px;
  height: 34px;
  border: 1px solid #c1c7c9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-members-section {
  background: #fbebdd;
}
.show-games-section {
  background: #eaf6f6;
}
.show-economy-section {
  background: #f5f4f8;
}
</style>
