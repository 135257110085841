/* Store module to handle App Settings */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  appSettings: {
    allowArrival: false,
    gpsMember: false,
    arrivalGuest: false,
    gpsGuest: false,
    messageMember: '',
    messageGuest: '',
    minutesMember: 120,
    minutesGuest: 120,
  },
  version: 0,
};

const mutations = {
  appSettings(state, payload) {
    state.appSettings = payload;
  },
  editAppSettings(state, payload) {
    state.appSettings.allowArrival = payload.allowArrival;
  },
  setVersion(state, payload) {
    state.version = payload;
  },
};

const getters = {
  appSettings: state => {
    return state.appSettings;
  },
  version: state => {
    return state.version;
  },
};

const actions = {
  fetchAppSettings: async (context, params) => {
    let path = 'ontagappsettings';

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('appSettings', data.result);
    }
  },
  editAppSettings: async (context, params) => {
    let path = 'ontagappsettings';

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('editAppSettings', data.result);
    }
  },

  editScorecardSurfaceType: async (context, params) => {
    let path =
      'ontagappsettings/scorecardsurfacetype/' + params.scorecardSurfaceType;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('editScorecardSurfaceType', data.result);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
