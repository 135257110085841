<template>
  <div class="legend">
    <div
      class="year"
      v-for="(year, index) in totalPeriodData"
      :key="`key_${index}`">
      <div class="row bold">
        <div>
          <use-svg
            svg="bb-arrow-icon"
            :class="{ 'svg--180-negative': year.value < 0 }" />
        </div>
        <div>
          <template v-if="year.value > 0">+</template>
          {{ year.value.toLocaleString() }} {{ unit }} /
          <template v-if="year.percentage > 0"></template>
          {{ year.percentage.toLocaleString() }}%
        </div>
      </div>
      <div class="row regular">
        <div></div>
        <div v-if="year.month > 0">
          {{ $t('comparedTo') }} {{ getMonth(year.month) }} {{ year.year }}
        </div>
        <div v-else>{{ $t('comparedTo') }} {{ year.year }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
  },
  props: {
    totalPeriodData: {
      type: Array,
      default: () => [],
    },
    budget: {
      type: Object,
      default: () => {},
    },
    normalYear: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: function () {
        this.$t('denomination');
      },
    },
  },
  data() {
    return {
      MonthLabels: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    };
  },
  mounted() {},
  computed: {
    totalDif() {
      return this.currentYearTotal - this.compareYearTotal;
    },
  },
  methods: {
    getMonth(index) {
      return this.$t('Months.' + this.MonthLabels[index - 1]);
    },
  },
};
</script>
<style lang="scss" scoped>
.legend {
  margin-left: 5rem;
  .bold {
    color: #131516;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
  }

  .regular {
    color: #131516;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  .year {
    margin: 0.6rem auto;
    font-size: 14px;
    line-height: 20px;
    .row {
      display: grid;
      grid-template-columns: 2rem auto;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
}
</style>
