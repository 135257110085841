<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <h1>Redigera medlemskapstyp {{type.displayName}}</h1>
        <div class="content__input-group">
          <label for="select-category">Tillhör kategori: </label>
          <select
          v-model="type.categoryId"
          id="select-category">
            <option :value="''">
              Ingen koppling
            </option>
            <option
            v-for="(categorie, i) in membershipTypes.categories"
            :key="i"
            :value="categorie.id">
              {{categorie.name}}
            </option>
          </select>
          <h2>
            {{ $t('Businessboard.ageGroups') }}
          </h2>
          <table class="agegroups-table">
            <tr
            v-for="(group, index) in type.ageGroups"
            :key="index">
              <td>{{group.displayName}} år</td>
              <td>
                <button
                @click="deleteAgeGroup(group)"
                class="delete-button">
                  <use-svg
                  svg="trash-icon"
                  color="black" />
                </button>
              </td>
            </tr>
          </table>
          <table
          v-if="showCreateGroup"
          class="createGroupTable">
            <tr>
              <th>
                {{ $t('from') }}:
              </th>
              <th>
                {{ $t('to') }}:
              </th>
            </tr>
            <tr>
              <td>
                <input
                type="number"
                class="ageInput"
                v-model="fromAge">
              </td>
              <td>
                <input
                type="number"
                class="ageInput"
                v-model="toAge">
              </td>
              <td>
                <base-button
                :text="'spara grupp'"
                :button-size-small="true"
                @click="saveAgeGroup"/>
              </td>
            </tr>
          </table>
          <base-button
          :disabled="showCreateGroup"
          type="button"
          :text="'Skapa åldersgrupp'"
          @click="createAgeGroup"/>
          <div>
            <h3>Historiska namn</h3>
            <em>Ange typens tidigare namn. Viktigt att det tidigare namnet är identiskt med det tidigare namnet i GIT</em>
            <table class="agegroups-table">
            <tr
            v-for="(alias, index) in type.aliases"
            :key="index">
              <td>{{alias}}</td>
              <td>
                <button
                @click="type.aliases.splice(index, 1)"
                class="delete-button">
                  <use-svg
                  svg="trash-icon"
                  color="black" />
                </button>
              </td>
            </tr>
          </table>
          <div class="input-group--inline">
            <input placeholder="Nytt alias" v-model="newAlias"/>
            <base-button
            :text="'Lägg till'"
            @click="type.aliases.push(newAlias)" />
          </div>
          </div>

          <div class="input-group--inline user__actions">
            <router-link to="/businessboard/data">
              <base-button
              :text="$t('cancel')">
              </base-button>
            </router-link>
            <base-button
            :text="$t('save')"
            type="submit"
            @click="saveChanges"
            v-element-loader="{ loaderKey: 'patch-membershiptypes' }"
            :disabled="false"/>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import UseSvg from '@/components/use-svg';

export default {
  name: 'EditType',
  components: {
    BaseButton,
    UseSvg,
  },
  directives: {
    elementLoader,
  },

  props: {
    id: {
      type: String,
      default: '0',
    },
  },

  data() {
    return {
      type: {},
      showCreateGroup: false,
      fromAge: 0,
      toAge: 0,
      newAlias: '',
    };
  },

  methods: {
    saveChanges() {
      if(this.type.categoryId === '') {
        if(confirm('Medlemstypen är inte kopplat till Medlemskategori! Vill du spara ändå?')) {
          this.$store.dispatch('businessboard/patchMembershipType', {
            id: this.type.id,
            membershipCategoryId: this.type.categoryId,
            quantity: this.type.quantity,
            aliases: this.type.aliases,
          }).then(() => {
            this.$router.push({ path: '/businessboard/data' });
          });
        }
      } else {
        this.$store.dispatch('businessboard/patchMembershipType', {
          id: this.type.id,
          membershipCategoryId: this.type.categoryId,
          quantity: this.type.quantity,
          aliases: this.type.aliases,
        }).then(() => {
          this.$router.push({ path: '/businessboard/data' });
        });
      }
    },

    createAgeGroup () {
      this.showCreateGroup = true;
    },

    saveAgeGroup() {
      this.$store.dispatch('businessboard/postMembershipAgeGroup', {
        id: this.id,
        fromAge: this.fromAge,
        toAge: this.toAge,
      }).then((response) => {
        if(response === true) {
          this.$store.dispatch('businessboard/fetchMembershipTypes').then(() => {
            this.type = this.$store.state.businessboard.membershiptypes.types.find(r => r.id === this.id);
            this.showCreateGroup = false;
            this.fromAge = 0;
            this.toAge = 0;
          });
        }
      });
    },

    deleteAgeGroup(group) {
      if(confirm('Vill du ta bort åldersgruppen?')) {
        this.$store.dispatch('businessboard/deleteMembershipAgeGroup', {
          id: group.id, loader: 'delete-agegroup' }).then(() => {
          this.$store.dispatch('businessboard/fetchMembershipTypes').then(() => {
            this.type = this.$store.state.businessboard.membershiptypes.types.find(r => r.id === this.id);
          });
        });
      }
    },
  },

  computed: {
    membershipTypes() {
      return this.$store.getters['businessboard/membershiptypes'];
    },
    // canCreate() {
    //   return this.feeColumn.name
    // }
  },

  created() {
    this.type = this.$store.state.businessboard.membershiptypes.types.find(r => r.id === this.id);
  },
};

</script>

<style lang="scss" scoped>
  .agegroups-table {
    margin: 20px 0px;

    td {
      padding: 0px 30px 10px 0px;
    }
  }
  .ageInput {
    width: 50px;
  }

  .delete-button {
    border: none;
    background-color: transparent;
  }
</style>
