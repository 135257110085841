<template>
  <tr class="discount_row">
    <td>
      <!-- empty -->
    </td>
    <td class="discount_type_div">
      <h3>{{ discountName }}</h3>
      <tooltip-icon
        class="tooltip"
        :description="discountName">
        <template #icon>
          <use-svg
            class="tooltip-icon-black"
            svg="white-info-icon" />
        </template>
      </tooltip-icon>
    </td>
    <td>
      <!-- empty -->
    </td>
    <td>
      <!-- empty -->
    </td>
    <td>
      <!-- empty -->
    </td>
    <template v-for="(fee, index) in feeReference">
      <discount-row-fees
        :key="'discountBudgeted-row' + index"
        :discount-type="discountData"
        :index="index"
        :previous-year-discount="previousYearData ? previousYearData : []"
        :show-previous-year="showPreviousYear"
        @update-discount-amount="e => updateDiscount(e)" />
    </template>
    <td>
      <!-- empty -->
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';

import useSvg from '@/components/use-svg';
import tooltipIcon from '@/components/businessboard/tooltip-icon';
import discountRowFees from './discount-row-fees.vue';

export default {
  name: 'discount-row',
  components: {
    useSvg,
    tooltipIcon,
    discountRowFees,
  },

  props: {
    budgeted: VueTypes.bool.def(false),
    discountData: VueTypes.array,
    discountName: VueTypes.string.def(''),
    feeReference: VueTypes.array,
    previousYearData: VueTypes.array,
    yearData: VueTypes.object,
    showPreviousYear: VueTypes.bool.def(false),
  },
  methods: {
    updateDiscount(newDiscountAmount) {
      const updateDiscountObj = newDiscountAmount;
      updateDiscountObj.budgeted = this.budgeted;
      this.$emit('update-discount', updateDiscountObj);
    },
  },
};
</script>

<style lang="scss" scoped>
.discount_row {
  border-radius: 3px;
  background-color: #dee8f7;
  margin-bottom: 0.2rem;
  width: 100%;
  border: 1px solid #fff;
}
.discount_type_div {
  display: flex;

  > h3 {
    font-weight: 600;
    font-size: large;
    color: #000;
    width: 8rem;
  }

  .tooltip {
    margin-top: 1rem;
  }
}

.tooltip-icon-black {
  filter: invert(100%);
}
</style>
