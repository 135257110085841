<template>
  <div class="onscreen">
    <div class="onscreen--content">
      <h1>OnScreen</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnScreen',
  components: {

  },
};
</script>
<style lang="scss">
 .onscreen {
    margin: 1rem;
    flex-grow: 1;
    width: 100%;
    @include media('small') {
      width: calc((100% / 2) - (1rem * 1));
    }
    @include media('large') {
      width: calc((100% / 3) - (1rem * 2));
    }
 }
 .onscreen--content {
   background-color: color(white);
   border-radius: 0.5rem;
   display: flex;
   flex-direction: column;
   padding: 1rem 1.5rem;
 }
</style>
