/* Store module to handle Menu */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  menu: [],
  isLoading: false,
};

const mutations = {
  menu(state, payload) {
    state.menu = payload;
  },
  isLoading(state, payload) {
    state.isLoading = payload;
  },
};

const getters = {
  menu: state => {
    return state.menu;
  },
  isLoading: state => {
    return state.isLoading;
  },
};

const actions = {
  get: async (context, params) => {
    if (state.isLoading) return;
    const path = 'menu';
    context.commit('isLoading', true);

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('menu', data.result);
    }
    context.commit('isLoading', false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
