<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <h1>{{ $t('Businessboard.settings') }}</h1>
      <div class="businessboard--category">
        <div class="businessboard--title">
          <span class="title">
            {{ $t('Businessboard.fiscalYear') }}
          </span>
        </div>
        <span class="description">
          {{ $t('chooseCalendarYear') }}
        </span>
        <div class="fiscal_year">
          <div class="use_calendar input-group--inline">
            <label for="fiscalSwitch">
              {{ $t('Businessboard.calendar') }}
            </label>
            <toggle-switch
              id="fiscalSwitch"
              name="fiscalSwitch"
              input-id="fiscalSwitch"
              type="switch"
              :toggled="settings.useCalenderYear"
              @toggle="toggleFiscalSwitch" />
          </div>
          <div class="fiscal_start">
            <custom-select
              :label="$t('Businessboard.fiscalstart')"
              :required="false"
              :disabled="settings.useCalenderYear"
              @optionSelected="selectFinancialYearStartMonth"
              :selected="settings.financialYearStartMonth - 1"
              :options="settingsmonths">
            </custom-select>
          </div>
        </div>
        <div class="businessboard--subcategory">
          <div class="businessboard--title">
            <span class="title">{{ $t('Businessboard.fiscalYears') }}</span>
            <router-link to="">
              <span
                class="button"
                @click="addYear">
                {{ $t('Businessboard.addFiscalYear') }}
              </span>
            </router-link>
          </div>
          <template v-if="settings.deviatingFinancialYears">
            <list-table
              @editYearClick="editYear"
              :items="settings.deviatingFinancialYears.rows"
              :headers="settings.deviatingFinancialYears.columns">
            </list-table>
          </template>
        </div>
      </div>
      <div
        class="businessboard--category"
        v-if="settings.businessBoardVersion < 10">
        <div class="businessboard--title">
          <span class="title">
            {{ $t('Businessboard.slotTimes') }}
          </span>
        </div>
        <span class="description">
          {{ $t('setSlotsInfo') }}
        </span>
        <div
          v-for="slot in settings.slotTimes"
          :key="slot.year"
          class="businessboard--subcategory">
          <div
            class="businessboard--settings-year"
            @click="toggleSlotYear(slot.year)">
            <div class="toggle_slot_year">
              {{ slot.year }}
            </div>
            <use-svg
              class="toggle_slot_button"
              svg="arrow-right"
              :class="{ 'svg--180-negative': showSlotYear(slot.year) }" />
          </div>
          <div
            :id="slot.year"
            v-if="showSlotYear(slot.year)">
            <table class="list-table">
              <tbody
                v-for="course in slot.courses"
                :key="course.id">
                <tr>
                  <th></th>
                  <th>{{ $t('MonthsShort.Jan') }}</th>
                  <th>{{ $t('MonthsShort.Feb') }}</th>
                  <th>{{ $t('MonthsShort.Mar') }}</th>
                  <th>{{ $t('MonthsShort.Apr') }}</th>
                  <th>{{ $t('MonthsShort.May') }}</th>
                  <th>{{ $t('MonthsShort.Jun') }}</th>
                  <th>{{ $t('MonthsShort.Jul') }}</th>
                  <th>{{ $t('MonthsShort.Aug') }}</th>
                  <th>{{ $t('MonthsShort.Sep') }}</th>
                  <th>{{ $t('MonthsShort.Oct') }}</th>
                  <th>{{ $t('MonthsShort.Nov') }}</th>
                  <th>{{ $t('MonthsShort.Dec') }}</th>
                  <th></th>
                </tr>
                <tr>
                  <td
                    :class="[
                      'course_name',
                      { changed_slot: changedSlots.includes(course.id) },
                    ]">
                    {{ course.courseName }}
                  </td>
                  <td
                    v-for="month in months"
                    :key="month">
                    <input
                      :value="course[month]"
                      @change="e => monthChange(e, course, month)"
                      size="5" />
                  </td>
                  <td>{{ sumMonths(course) }}</td>
                </tr>
                <tr>
                  <td
                    :class="{ changed_slot: changedSlots.includes(course.id) }">
                    {{ $t('xFactor') }}
                  </td>
                  <td
                    v-for="month in xMonths"
                    :key="month">
                    <input
                      :value="course[month]"
                      @change="e => monthChange(e, course, month)"
                      size="5" />
                  </td>
                  <td>{{ sumXMonths(course) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        class="businessboard--category"
        v-if="settings.resultUnits && settings.resultUnits.length > 0">
        <div class="businessboard--title">
          <span class="title">
            {{ $t('Businessboard.resultUnits') }}
          </span>
        </div>
        <div class="businessboard--subcategory3">
          <template>
            <p>
              {{ $t('updatePageInfo') }}
            </p>
            <table class="list-table">
              <template v-for="company in settings.companies">
                <thead :key="company.id">
                  <th>{{ company.name }}</th>
                  <th>{{ $t('sorting') }}</th>
                  <template v-if="settings.hasBudget">
                    <th>
                      {{ $t('includedInBudgetFor') }}
                    </th>
                  </template>
                  <th aria-hidden="true"></th>
                </thead>
                <tr
                  v-for="(unit, index) in settings.resultUnits.filter(
                    ru => ru.companyId === company.id
                  )"
                  :key="unit.id">
                  <td>{{ unit.name }}</td>
                  <td>
                    <a
                      v-on="
                        enableClick
                          ? { click: () => orderChange(unit, -1) }
                          : {}
                      "
                      v-if="index > 0">
                      <use-svg
                        svg="arrow-right"
                        class="svg--180-negative" />
                    </a>
                    <a
                      v-on="
                        enableClick
                          ? { click: () => orderChange(unit, +1) }
                          : {}
                      "
                      v-if="index + 1 < settings.resultUnits.length">
                      <use-svg svg="arrow-right" />
                    </a>
                  </td>
                  <template v-if="settings.hasBudget">
                    <td>
                      <toggle-switch
                        type="switch"
                        :toggled="unit.include"
                        @toggle="
                          toggleResultUnit(unit.id, company.id, $event)
                        " />
                    </td>
                  </template>
                </tr>
              </template>
            </table>
          </template>
        </div>
      </div>
      <div
        class="businessboard--category"
        v-if="!settings.hasGolfBox">
        <div class="businessboard--title">
          <span class="title">
            {{ $t('apiSettingsEconomic') }}
          </span>
        </div>
        <div class="businessboard--subcategory2">
          <template v-if="settings.showCompanies">
            <div
              v-for="company in settings.companies"
              :key="company.id">
              <custom-select
                :label="company.name"
                :required="false"
                :selected="company.dataSource"
                @optionSelected="e => selectDatasource(company.id, e.value)"
                :options="settingsDatasource">
              </custom-select>
            </div>
          </template>
          <template v-else>
            {{ $t('featureNotIncluded') }}
          </template>
        </div>
      </div>
      <div
        class="businessboard--category"
        v-if="
          !settings.hasGolfBox &&
          settings.businessBoardVersion < 10 &&
          (user.superUser ||
            (user.roles.GolfOffice &&
              user.roles.GolfOffice.some(
                e => e.value === 'BusinessBoardAdmin'
              )))
        ">
        <div class="businessboard--title">
          <span class="title">
            {{ $t('apiSettingsRounds') }}
          </span>
        </div>
        <div class="businessboard--subcategory2">
          <div
            v-for="course in settings.courses"
            :key="course.id">
            <custom-select
              :label="course.name"
              :required="false"
              :selected="course.dataSource"
              @optionSelected="e => selectCourseImport(course.id, e.value)"
              :options="settingsCourseImport">
            </custom-select>
          </div>
        </div>
      </div>
      <div
        class="businessboard--category"
        v-else-if="!settings.showCompanies && !settings.hasGolfBox">
        <div class="businessboard--title">
          <span class="title">
            {{ $t('apiSettingsRounds') }}
          </span>
        </div>
        <div>
          {{ $t('featureNotIncluded') }}
        </div>
      </div>
      <div
        class="businessboard--category"
        v-if="
          settings.hasGolfBox &&
          user.roles.GolfOffice &&
          !user.roles.GolfOffice.some(
            e => e.value === 'BusinessBoardBoardMember'
          )
        ">
        <div class="businessboard--title">
          <span class="title">
            {{ $t('nbrOfCompPlayers') }}
          </span>
        </div>
        <div class="businessboard--subcategory2">
          <div
            v-for="gameSetting in settings.gameSettings"
            :key="gameSetting.id">
            <custom-select
              :label="gameSetting.name"
              :required="false"
              :selected="gameSetting.nbrOfPlayers - 1"
              @optionSelected="
                e => selectGameSetting(gameSetting.courseExternalId, e.value)
              "
              :options="nbrOfPlayers">
            </custom-select>
          </div>
        </div>
      </div>
      <div
        class="businessboard--category"
        v-else-if="!settings.showCompanies">
        <div class="businessboard--title">
          <span class="title">{{ $t('apiSettingsRounds') }}</span>
        </div>
        <div>
          {{ $t('featureNotIncluded') }}
        </div>
      </div>
      <div class="businessboard--category">
        <div class="businessboard--title">
          <span class="title">{{
            $t('Businessboard.DistributionTemplates.Title')
          }}</span>
          <router-link to="">
            <span
              class="button"
              @click="addDistributionTemplate">
              {{ $t('Businessboard.DistributionTemplates.Add') }}
            </span>
          </router-link>
        </div>
        <div class="businessboard--subcategory2">
          <p>
            {{ $t('Businessboard.DistributionTemplates.Info') }}
          </p>
          <list-table
            v-if="settings.distributionTemplates"
            @editDistributionTemplate="editDistributionTemplate"
            :items="settings.distributionTemplates.rows"
            :headers="settings.distributionTemplates.columns">
          </list-table>
        </div>
      </div>

      <div
        v-if="user.superUser && settings.hasBudget"
        class="businessboard--category">
        <div class="businessboard--title">
          <span class="title">
            {{ $t('Businessboard.budgetData') }}
          </span>
        </div>
        <div class="businessboard--subcategory2">
          <p>
            {{ $t('Businessboard.allowBudgetImport') }}
          </p>
          <toggle-switch
            id="fiscalSwitch"
            name="fiscalSwitch"
            input-id="fiscalSwitch"
            type="switch"
            :toggled="settings.importBudgetData"
            @toggle="toggleImportBudgetData" />
        </div>
      </div>

      <modal-window :show="showModal">
        <div class="modal__header">
          <p>
            {{ $t('Businessboard.fiscalYears') }}
          </p>
        </div>
        <p>{{ $t('fiscalYearPeriodInfo') }}</p>
        <div class="modal__content">
          <div class="modal_grid">
            <div class="modal_grid_group">
              <div class="modal_grid_title">
                {{ $t('Businessboard.yearStart') }}
              </div>
              <div class="modal_grid_select">
                <custom-select
                  :label="$t('year')"
                  @optionSelected="selectYearStartYear"
                  :selected="selectYearStartYearIndex"
                  :options="settingsyears">
                </custom-select>
              </div>
              <div class="modal_grid_select">
                <custom-select
                  :label="$t('month')"
                  @optionSelected="selectYearStartMonth"
                  :selected="selectYearStartMonthIndex"
                  :options="startsettingsmonths">
                </custom-select>
              </div>
            </div>
            <div class="modal_grid_group">
              <div class="modal_grid_title">
                {{ $t('Businessboard.yearEnd') }}
              </div>
              <div class="modal_grid_select">
                <custom-select
                  :label="$t('year')"
                  @optionSelected="selectYearEndYear"
                  :selected="selectYearEndYearIndex"
                  :options="settingsyears">
                </custom-select>
              </div>
              <div class="modal_grid_select">
                <custom-select
                  :label="$t('month')"
                  @optionSelected="selectYearEndMonth"
                  :selected="selectYearEndMonthIndex"
                  :options="endsettingsmonths">
                </custom-select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal__content--actions">
          <base-button
            class="button--background-gray-dark"
            :text="$t('cancel')"
            @click="toggleModal">
          </base-button>
          <base-button
            :text="$t('save')"
            :disabled="!canSave"
            v-element-loader="{ loaderKey: 'add-year' }"
            @click="saveYear" />
        </div>
      </modal-window>

      <modal-window
        :show="showModalDT"
        v-if="showModalDT">
        <div class="modal__header">
          <p>
            {{ $t('Businessboard.DistributionTemplates.Create') }}
          </p>
        </div>
        <div class="modal__content">
          <div class="modal_distributiontemplates">
            <div class="input-pair">
              <label>
                {{ $t('Businessboard.DistributionTemplates.Name') }}
              </label>
              <input v-model="distributionTemplate.name" />
            </div>

            <div class="input-pair">
              <label>
                {{ $t('Businessboard.DistributionTemplates.PreSelected') }}
              </label>
              <select
                class="input"
                v-model="distributionTemplate.resultGroupId">
                <option>{{ $t('none') }}</option>
                <optgroup :label="$t('Businessboard.incomeResultGroups')">
                  <option
                    v-for="group in settings.incomeGroups"
                    v-bind:key="group.id"
                    :value="group.id"
                    :disabled="
                      group.isUsed &&
                      group.id !== distributionTemplate.resultGroupId
                    ">
                    {{ group.name }}
                  </option>
                </optgroup>
                <optgroup :label="$t('Businessboard.costResultGroups')">
                  <option
                    v-for="group in settings.costGroups"
                    v-bind:key="group.id"
                    :value="group.id"
                    :disabled="
                      group.isUsed &&
                      group.id !== distributionTemplate.resultGroupId
                    ">
                    {{ group.name }}
                  </option>
                </optgroup>
              </select>
            </div>

            <h4>{{ $t('Businessboard.DistributionTemplates.Percentage') }}</h4>

            <div
              v-for="(k, i) in 12"
              :key="k"
              class="input-pair percentage">
              <label>{{ $t('Months.' + MonthLabels[i]) }}</label>
              <input
                @focus="$event.target.select()"
                :value="distributionTemplate.distribution[i]"
                @input="validPercentage(i, $event.target.value)"
                type="number" />
            </div>
            <span> {{ $t('sum') }}: {{ distributionTemplateSum }} </span>
            <div
              class="error"
              v-if="distributionTemplateSum > 100">
              {{ $t('Businessboard.DistributionTemplates.ErrorSum') }}
            </div>
          </div>
        </div>
        <div class="modal__content--actions">
          <base-button
            v-if="distributionTemplate.id !== ''"
            class="button--background-red"
            :text="$t('delete')"
            v-element-loader="{ loaderKey: 'delete-distributiontemplate' }"
            @click="deleteDT()">
          </base-button>
          <base-button
            class="button--background-gray-dark"
            :text="$t('cancel')"
            @click="showModalDT = false">
          </base-button>
          <base-button
            :text="$t('save')"
            :disabled="
              distributionTemplateSum !== 100 ||
              distributionTemplate.name === ''
            "
            v-element-loader="{ loaderKey: 'add-distributiontemplate' }"
            @click="saveDT" />
        </div>
      </modal-window>

      <section class="terminal--actions sticky sticky__bottom">
        <base-button
          backgroundColor="bb-primary"
          v-if="this.changedSlots.length > 0"
          :text="`${$t('save')} ${this.changedSlots.length} ${$t(
            'slotTimes'
          ).toLowerCase()}`"
          v-element-loader="{ loaderKey: 'save-slots' }"
          @click="saveSlotTimes" />
      </section>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table-businessboard';
import BaseButton from '@/components/form-elements/base-button';
import ToggleSwitch from '@/components/form-elements/switch';
import ModalWindow from '@/components/modal/modal';
import CustomSelect from '@/components/form-elements/custom-select';
import elementLoader from '@/directives/element-loader';
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'Data',
  mixins: [LocalizationMixin],
  components: {
    ListTable,
    BaseButton,
    ToggleSwitch,
    ModalWindow,
    CustomSelect,
    UseSvg,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      showModal: false,
      showModalDT: false,
      enableClick: true,
      distributionTemplate: {},
      newYear: {
        id: '',
        startYear: '',
        startMonth: '',
        endYear: '',
        endMonth: '',
      },
      showYears: [],
      filteredMonths: {
        start: [],
        end: [],
      },
      changedSlots: [],
      months: [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ],
      xMonths: [
        'xFactorJanuary',
        'xFactorFebruary',
        'xFactorMarch',
        'xFactorApril',
        'xFactorMay',
        'xFactorJune',
        'xFactorJuly',
        'xFactorAugust',
        'xFactorSeptember',
        'xFactorOctober',
        'xFactorNovember',
        'xFactorDecember',
      ],
      MonthLabels: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    settings() {
      return this.$store.getters['businessboard/settings'];
    },
    settingsyears() {
      return this.$store.getters['businessboard/settingsyears'];
    },
    settingsmonths() {
      return this.$store.getters['businessboard/settingsmonths'];
    },
    settingsDatasource() {
      return this.$store.getters['businessboard/datasource'];
    },
    settingsCourseImport() {
      return this.$store.getters['businessboard/courseimportype'];
    },
    settingsGameSetting() {
      return this.$store.getters['businessboard/gameSetting'];
    },
    startsettingsmonths() {
      return this.settingsmonths.filter(
        m => !this.filteredMonths.start.includes(m.value)
      );
    },
    endsettingsmonths() {
      return this.settingsmonths.filter(
        m => !this.filteredMonths.end.includes(m.value)
      );
    },
    nothingSelectedText() {
      return this.$t('nothingSelected');
    },
    canSave() {
      if (
        this.newYear.startYear &&
        this.newYear.startMonth &&
        this.newYear.endYear &&
        this.newYear.endMonth
      ) {
        if (this.newYear.startYear === this.newYear.endYear) {
          return this.newYear.startMonth < this.newYear.endMonth;
        }
        return this.newYear.startYear < this.newYear.endYear;
      }
      return false;
    },
    selectYearStartYearIndex() {
      return this.settingsyears.findIndex(
        y => y.value === this.newYear.startYear
      );
    },
    selectYearStartMonthIndex() {
      return this.startsettingsmonths.findIndex(
        m => m.value === this.newYear.startMonth
      );
    },
    selectYearEndYearIndex() {
      return this.settingsyears.findIndex(
        y => y.value === this.newYear.endYear
      );
    },
    selectYearEndMonthIndex() {
      return this.endsettingsmonths.findIndex(
        m => m.value === this.newYear.endMonth
      );
    },
    distributionTemplateSum() {
      return this.distributionTemplate.distribution.reduce((a, b) => a + b, 0);
    },
    nbrOfPlayers() {
      return [
        {
          displayName: this.$t('nothingSelected'),
          value: 0,
        },
        {
          displayName: '2',
          value: 2,
        },
        {
          displayName: '3',
          value: 3,
        },
        {
          displayName: '4',
          value: 4,
        },
      ];
    },
  },
  methods: {
    async orderChange(item, delta) {
      this.enableClick = false;
      item.order = item.order + delta;
      await this.$store.dispatch('businessboard/patchResultunit', item);
      await this.$store.dispatch('businessboard/fetchSettings');
      this.enableClick = true;
    },
    toggleResultUnit(unitId, companyId, e) {
      let unit = {
        id: unitId,
        companyId: companyId,
        include: e,
      };
      this.$store
        .dispatch('businessboard/patchResultunitBudget', unit)
        .then(() => {
          this.$store.dispatch('businessboard/fetchSettings');
        });
    },
    sumMonths(c) {
      let sum = 0;
      for (let m of this.months) {
        sum += c[m];
      }
      return sum;
    },
    sumXMonths(c) {
      let sum = 0;
      for (let m of this.xMonths) {
        sum += c[m];
      }
      return sum;
    },
    sumYear(courses) {
      let sum = 0;
      for (let c of courses) {
        sum += this.sumMonths(c);
        sum += this.sumXMonths(c);
      }
      return sum;
    },
    monthChange(e, course, month) {
      course[month] = parseInt(e.target.value);
      if (!this.changedSlots.includes(course.id)) {
        this.changedSlots.push(course.id);
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
      this.newYear = {
        id: '',
        startYear: '',
        startMonth: '',
        endYear: '',
        endMonth: '',
      };
    },
    patchSettings(useCalenderYear, financialYearStartMonth, importBudgetData) {
      let params = {
        useCalenderYear: useCalenderYear,
        financialYearStartMonth: financialYearStartMonth,
        importBudgetData: importBudgetData,
      };
      this.$store.dispatch('businessboard/patchSettings', params).then(() => {
        this.$store.dispatch('businessboard/fetchSettings');
      });
    },
    toggleFiscalSwitch() {
      this.patchSettings(
        !this.settings.useCalenderYear,
        this.settings.financialYearStartMonth,
        this.settings.importBudgetData
      );
    },
    selectFinancialYearStartMonth(e) {
      this.patchSettings(false, e.value);
    },
    toggleImportBudgetData() {
      this.patchSettings(
        this.settings.useCalenderYear,
        this.settings.financialYearStartMonth,
        !this.settings.importBudgetData
      );
    },
    addYear() {
      this.toggleModal();
    },
    editYear(year) {
      this.newYear = { ...year };
      this.showModal = !this.showModal;
    },
    filter(year, s) {
      this.filteredMonths[s] = [];
      let years = this.settings.deviatingFinancialYears.rows;
      for (let y of years) {
        if (year === y.startYear) {
          let m1 = y.startYear === y.endYear ? y.endMonth : 12;
          for (let m = y.startMonth; m <= m1; m++) {
            this.filteredMonths[s].push(m);
          }
        } else if (year === y.endYear) {
          let m0 = y.startYear === y.endYear ? y.start : 0;
          for (let m = m0; m <= y.startMonth; m++) {
            this.filteredMonths[s].push(m);
          }
        }
      }
    },
    selectYearStartYear(e) {
      this.filter(e.value, 'start');
      this.newYear.startYear = e.value;
    },
    selectYearStartMonth(e) {
      this.newYear.startMonth = e.value;
    },
    selectYearEndYear(e) {
      this.filter(e.value, 'end');
      this.newYear.endYear = e.value;
    },
    selectYearEndMonth(e) {
      this.newYear.endMonth = e.value;
    },
    saveYear() {
      let dispatch =
        'businessboard/' +
        (this.newYear.id === '' ? 'post' : 'patch') +
        'SettingsDeviatingfinancialyear';
      this.$store.dispatch(dispatch, this.newYear).then(() => {
        this.toggleModal();
      });
    },
    toggleSlotYear(y) {
      if (this.showYears.includes(y)) {
        this.showYears = this.showYears.filter(function (item) {
          return item !== y;
        });
      } else {
        this.showYears.push(y);
      }
    },
    showSlotYear(y) {
      return this.showYears.includes(y);
    },
    saveSlotTimes() {
      let promises = [];
      this.$store.dispatch('ui/toggleActiveLoaders', 'save-slots', {
        root: true,
      });

      for (let slot of this.settings.slotTimes) {
        for (let course of slot.courses) {
          if (this.changedSlots.includes(course.id)) {
            promises.push(
              this.$store.dispatch(
                'businessboard/patchSettingsSlottime',
                course
              )
            );
          }
        }
      }
      Promise.all(promises).then(() => {
        this.$store.dispatch('ui/toggleActiveLoaders', 'save-slots', {
          root: true,
        });
        this.changedSlots = [];
        this.$store.dispatch('businessboard/fetchSettings');
      });
    },
    selectDatasource(id, value) {
      var financialdata = {
        id: id,
        dataSource: value,
      };
      this.$store.dispatch(
        'businessboard/patchSettingsFinancialdata',
        financialdata
      );
    },
    selectCourseImport(id, value) {
      var courseData = {
        id: id,
        dataSource: value,
      };
      this.$store.dispatch(
        'businessboard/patchSettingsCourseImport',
        courseData
      );
    },
    selectGameSetting(id, value) {
      var courseData = {
        id: id,
        nbrOfPlayers: value,
      };
      this.$store.dispatch(
        'businessboard/patchSettingsGameSetting',
        courseData
      );
    },
    addDistributionTemplate() {
      this.distributionTemplate = {
        id: '',
        name: '',
        resultGroupId: '',
        distribution: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      };
      this.showModalDT = true;
    },
    validPercentage(i, val) {
      if (val === '') {
        val = 0;
      } else {
        val = parseInt(val);
      }
      if (val < 0) val = 0;
      if (val > 100) val = 100;
      this.$set(this.distributionTemplate.distribution, i, val);
    },
    saveDT() {
      let params = {
        name: this.distributionTemplate.name,
        resultGroup: this.distributionTemplate.resultGroupId,
        january: this.distributionTemplate.distribution[0],
        february: this.distributionTemplate.distribution[1],
        march: this.distributionTemplate.distribution[2],
        april: this.distributionTemplate.distribution[3],
        may: this.distributionTemplate.distribution[4],
        june: this.distributionTemplate.distribution[5],
        july: this.distributionTemplate.distribution[6],
        august: this.distributionTemplate.distribution[7],
        september: this.distributionTemplate.distribution[8],
        october: this.distributionTemplate.distribution[9],
        november: this.distributionTemplate.distribution[10],
        december: this.distributionTemplate.distribution[11],
        id: this.distributionTemplate.id,
      };
      if (this.distributionTemplate.id === '') {
        this.$store
          .dispatch('businessboard/postDistributionTemplate', params)
          .then(() => {
            this.$store.dispatch('businessboard/fetchSettings');
            this.showModalDT = false;
          });
      } else {
        this.$store
          .dispatch('businessboard/patchDistributionTemplate', params)
          .then(() => {
            this.$store.dispatch('businessboard/fetchSettings');
            this.showModalDT = false;
          });
      }
    },
    deleteDT() {
      if (
        confirm(this.$t('Businessboard.DistributionTemplates.Confirm')) === true
      ) {
        this.$store
          .dispatch('businessboard/deleteDistributionTemplate', {
            id: this.distributionTemplate.id,
          })
          .then(() => {
            this.$store.dispatch('businessboard/fetchSettings');
            this.showModalDT = false;
          });
      } else {
      }
    },
    editDistributionTemplate(dt) {
      this.showModalDT = true;
      this.distributionTemplate = { ...dt };
    },
  },
  created() {
    this.showYears.push(new Date().getFullYear());
    this.$store.dispatch('businessboard/fetchSettings').then(() => {
      this.$store.dispatch('businessboard/fetchSettingsMonths');
      this.$store.dispatch('businessboard/fetchSettingsYears');
      this.$store.dispatch('businessboard/fetchDatasource');
      this.$store.dispatch('businessboard/fetchCourseImportType');
    });
  },
};
</script>
<style lang="scss" scoped>
a {
  cursor: pointer;
}
.sum_year {
  color: black;
  margin-right: 0.5rem;
  font-size: 1.2rem;
}
.fiscal_year {
  display: flex;
}
.use_calendar {
  border-right: 1px solid black;
  width: 30%;
  padding: 1rem;
  label {
    margin-right: 1rem;
  }
}
.fiscal_start {
  width: 30%;
  padding: 1rem;
}
.modal_grid {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
}
.modal_grid_group {
  display: grid;
  grid-template-columns: auto auto;
  margin: 0.3rem;
}
.modal_grid_title {
  grid-column-end: span 2;
  text-align: left;
  font-size: font(h3);
}
.modal_grid_select {
  margin: 0.3rem;
}
.save-slot {
  display: flex;
  align-items: center;
  align-content: center;
}
.list-table {
  border-collapse: collapse;
  width: 100%;
  th {
    background-color: color(bb-sec1);
    color: white;
    padding: 0.4rem;
  }
  td {
    padding: 0.2rem;
  }
  .course_name {
    font-weight: bold;
  }
  .changed_slot {
    font-style: italic;
  }
}
.businessboard {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}

.businessboard--settings-resultunitrow {
  padding: 10px 0;
  width: 20%;
  display: flex;
  justify-content: space-between;
}
.businessboard--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;

  .description {
    margin-left: 1rem;
  }
}
.businessboard--category {
  margin: 1rem 0;
  border: 1px solid color(bb-primary);
  .businessboard--subcategory {
    margin: 1rem;
    border: 1px solid color(bb-primary);
  }
  .businessboard--subcategory2 {
    margin: 1rem;
  }
  .businessboard--subcategory3 {
    ul {
      margin: 0px;
      padding: 5px 20px;
      list-style: none;
      li {
        display: flex;
        justify-content: space-between;
        margin-right: 70%;
        a {
          margin-left: 10px;
        }
      }
    }
    tr:nth-child(2n) {
      background-color: color(gray-lighter);
    }
    td:not(:first-child) {
      text-align: center;
    }
    td > div {
      display: inline-block;
    }
  }
}
.businessboard--settings-year {
  padding: 0.3rem;
  background-color: color(bb-primary);
  color: color(white);
  font-size: font(h2);
  display: flex;
  .toggle_slot_year {
    flex: 1;
  }
  .toggle_slot_button {
    height: 2rem;
    margin-right: 0.5rem;
  }
}
.businessboard--title {
  background-color: color(bb-primary);
  color: color(white);
  display: flex;
  margin-bottom: 0.5rem;
  padding: 1rem;
  .title {
    margin-top: 0.1rem;
    font-size: 1.2rem;
    flex: 1;
  }
  .button {
    color: white;
  }
  .button::before {
    content: '+';
  }
}
.terminal--actions {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 1rem;
  }
}
.modal_distributiontemplates {
  text-align: left;
  .input-pair {
    margin: 0.2rem;
    input,
    select {
      background-color: #f9f9f9;
      border-radius: 2px;
      padding: 0.3rem;
      border: 1px solid #d8d8d8;
    }
    label {
      display: inline-block;
      width: 8rem;
    }
  }
  .percentage {
    input {
      width: 5rem;
    }
  }
  .percentage::after {
    content: '%';
  }
  .error {
    color: red;
  }
}
</style>
