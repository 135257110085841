import colors from '@/assets/styles/_variables.scss';

export const COLORS = [
  colors['bb-sec1'],
  colors['bb-sec1-light'],
  colors['new-green'],
  colors['bb-sec4'],
  colors['bb-sec4-light'],
  colors['purple'],
  colors['purple-light'],
  colors['orange'],
  colors['moss'],
  colors['dab'],
];
