<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <router-link to="/businessboard/data">
        <span class="back_button">{{ $t('Businessboard.backToData') }}</span>
      </router-link>
      <h1>{{ group.group }}</h1>
      <template v-if="resultgroupaccounts">
        <list-table
          :items="resultgroupaccounts.rows"
          :headers="resultgroupaccounts.columns">
        </list-table>
      </template>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table-businessboard';

export default {
  name: 'EditGroup',
  components: {
    ListTable,
  },
  directives: {},
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      group: {},
    };
  },
  methods: {},
  computed: {
    resultgroupaccounts() {
      return this.$store.getters['businessboard/resultgroupaccounts'];
    },
  },
  created() {
    this.$store.dispatch('businessboard/fetchResultgroupAccounts', {
      id: this.id,
    });
    this.group =
      this.$store.state.businessboard.data.costResultGroups.rows.find(
        r => r.id === this.id
      );
    if (!this.group) {
      this.group =
        this.$store.state.businessboard.data.incomeResultGroups.rows.find(
          r => r.id === this.id
        );
    }
  },
};
</script>
<style lang="scss" scoped>
.golfoffice {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.golfoffice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  .content__input-group {
    width: 100%;
    @include media('medium') {
      width: calc((100% / 1.2) - (1rem * 2));
    }
    .input__label {
      width: 100%;
    }
    .user__actions {
      justify-content: flex-start;
      @include media('small') {
        justify-content: flex-end;
        // width: 70%;
      }

      a button {
        margin-left: 0;
      }
      .select button {
        margin-left: 0;
      }
    }
  }
}
</style>
