<template>
  <div
    ref="courses"
    class="businessboard">
    <div class="businessboard--content">
      <modal-membership-types-create-year
        :is-modal-open="isModalOpen"
        @closeModal="e => closeCreateNewYearModal(e)" />
      <modal-membership-types-settings
        :is-settings-modal-open="isSettingsModalOpen"
        @closeModal="
          e => {
            isSettingsModalOpen = e;
          }
        " />
      <modal-membership-category-settings />
      <modal-membership-category-group-settings
        v-if="membershipCategories.membershipCategories"
        :items="membershipCategories.membershipCategories.rows"
        :isOpen="isGroupSettingsModalOpen"
        @closeModal="isGroupSettingsModalOpen = false" />
      <div class="businessboard--header">
        <span class="h1">{{ $t('Businessboard.data') }}</span>
        <last-updated
          :last-update="latestUpdates"
          :show-member="!hasEconomicLicense"
          :show-game="!hasEconomicLicense"
          :show-economy="true" />
      </div>
      <div class="intro">
        {{ dataIntroText }}
      </div>

      <div
        v-if="businessboardData.courses && !hasEconomicLicense"
        id="courses"
        class="businessboard--category">
        <div class="businessboard--title">
          <span class="title">{{ $t('Businessboard.courses') }}</span>
          <router-link :to="{ path: '/businessboard/create-course/' }">
            <span class="button">{{ $t('Businessboard.addCourse') }}</span>
          </router-link>
        </div>
        <list-table
          :items="businessboardData.courses.rows"
          :headers="businessboardData.courses.columns">
        </list-table>
        <p>
          {{ $t('courseIntro') }}
        </p>
      </div>
      <div
        v-if="!hasEconomicLicense"
        class="businessboard--header">
        <base-button
          :text="$t('Businessboard.dataImport')"
          @click="toggleModal">
        </base-button>
      </div>
      <modal-course-import
        :show="showModal"
        :isGolfBox="settings.hasGolfBox"
        @toggle-modal="toggleModal"
        @loading="n => showVerify">
      </modal-course-import>
      <modal-member-import
        :show="showMemberModal"
        @toggle-modal="toggleMemberModal"
        @loading="n => fetchData(n)">
      </modal-member-import>
      <div
        v-if="businessboardData.companies"
        id="companies"
        class="businessboard--category">
        <div class="businessboard--title">
          <span class="title">{{ $t('Businessboard.companies') }}</span>
          <router-link :to="{ path: '/businessboard/create-company/' }">
            <span class="button">{{ $t('Businessboard.addCompany') }}</span>
          </router-link>
        </div>
        <list-table
          :items="businessboardData.companies.rows"
          :headers="businessboardData.companies.columns">
        </list-table>
      </div>
      <div
        v-if="businessboardData.competitionPlayersImport && settings.hasGolfBox"
        id="import"
        class="businessboard--category">
        <div class="businessboard--title">
          <span class="title">{{ $t('Businessboard.competitionImport') }}</span>
        </div>
        <list-table
          v-if="showHistory"
          :items="businessboardData.competitionPlayersImport.rows"
          :headers="businessboardData.competitionPlayersImport.columns">
        </list-table>
        <base-button
          class="button--background-none"
          :text="showHistoryText"
          @click="toggleHistory">
        </base-button>
        <base-button
          :text="$t('Businessboard.dataImport')"
          @click="toggleMemberModal">
        </base-button>
        <p>{{ $t('Businessboard.importHelp') }}</p>
      </div>
      {{ revenueGroupText }}
      <div
        v-if="businessboardData.incomeResultGroups"
        id="incomegroups"
        class="businessboard--category">
        <div class="businessboard--title">
          <span class="title">{{
            $t('Businessboard.incomeResultGroups')
          }}</span>
          <router-link
            :to="{
              name: 'businessboard-create-resultgroup',
              params: { costGroup: false },
            }">
            <span class="button">{{
              $t('Businessboard.addIncomeResultGroup')
            }}</span>
          </router-link>
        </div>
        <list-table
          :items="businessboardData.incomeResultGroups.rows"
          :headers="businessboardData.incomeResultGroups.columns">
        </list-table>
      </div>
      {{ costGroupText }}
      <div
        v-if="businessboardData.costResultGroups"
        id="costgroups"
        class="businessboard--category">
        <div class="businessboard--title">
          <span class="title">{{ $t('Businessboard.costResultGroups') }}</span>
          <router-link
            :to="{
              name: 'businessboard-create-resultgroup',
              params: { costGroup: true },
            }">
            <span class="button">{{
              $t('Businessboard.addCostResultGroup')
            }}</span>
          </router-link>
        </div>
        <list-table
          :items="businessboardData.costResultGroups.rows"
          :headers="businessboardData.costResultGroups.columns">
        </list-table>
      </div>
      <!-- Medlemsgrupper -->
      <div
        v-if="membershipCategories.membershipCategories && !hasEconomicLicense"
        id="membershipcategories">
        <div>
          <h3 class="h3-title">
            {{ $t('Businessboard.membershipCategoriesGroups') }}
          </h3>
        </div>
        <div class="flex-child-elements">
          <div class="action-buttons-div">
            <base-button
              background-color="bb-primary"
              :text="$t('Businessboard.settings')"
              @click="isGroupSettingsModalOpen = true">
              <use-svg svg="bb-gear-icon" />
            </base-button>
          </div>
        </div>
        <template v-if="settings.showCompanies">
          <membership-category-group-table
            :items="membershipCategories.membershipCategories.rows" />
        </template>
        <div v-else>{{ $t('featureNotIncluded') }}</div>
      </div>

      <div
        v-if="!hasEconomicLicense && membershipTypeYears"
        id="membershiptypes">
        <div>
          <h3 class="h3-title">
            {{ $t('Businessboard.Data.membershipTitle') }}
          </h3>
          <p class="preamble">
            {{ $t('Businessboard.Data.setupQuantity') }}
          </p>
        </div>
        <div class="flex-child-elements">
          <div class="action-buttons-div">
            <base-button
              background-color="bb-primary"
              :text="$t('Businessboard.settings')"
              @click="openSettingsModal()">
              <use-svg svg="bb-gear-icon" />
            </base-button>
            <base-button
              background-color="white"
              :text="$t('MemberCalculation.addNewYear')"
              @click="openModal()">
              <use-svg svg="black-plus-icon" />
            </base-button>
          </div>
          <div class="slider_div">
            <slider @slider-state="e => setShowPreviousYear(e)" />
            <p>
              {{ $t('Businessboard.Data.showPreviousYear') }}
            </p>
          </div>
        </div>
        <div v-if="membershipTypeYears.years">
          <template
            v-for="(membershipYear, index) in membershipTypeYears.years">
            <membership-calculation-table
              v-if="membershipYear"
              :key="membershipYear + index"
              :membership-year="membershipYear"
              :membership-types="{ ...membershipTypeYears }"
              :show-previous-year="showPreviousYear" />
          </template>
        </div>
        <div v-else>{{ $t('featureNotIncluded') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table-businessboard';
import BaseButton from '@/components/form-elements/base-button';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import ModalCourseImport from '@/components/modal/modal-course-import.vue';
import ModalMemberImport from '@/components/modal/modal-member-import.vue';
import ModalMembershipTypesCreateYear from '@/components/modal/membershipCalculationTypes/modal-membership-types-create-year.vue';
import UseSvg from '@/components/use-svg';

import membershipCalculationTable from '@/components/businessboard/membership-calculation-table/membership-calculation-table';
import membershipCategoryGroupTable from '@/components/businessboard/membership-calculation-table/membership-category-group-table';
import slider from '@/components/slider/slider';
import ModalMembershipTypesSettings from '../../components/modal/membershipCalculationTypes/modal-membership-types-settings.vue';
import ModalMembershipCategorySettings from '../../components/modal/membershipCalculationTypes/modal-membership-category-settings.vue';
import ModalMembershipCategoryGroupSettings from '../../components/modal/membershipCalculationTypes/modal-membership-category-group-settings.vue';
import { BusinessBoardVersion } from '@/core/consts';
import LastUpdated from '@/components/last-updated/last-updated.vue';

export default {
  name: 'Data',
  components: {
    ListTable,
    BaseButton,
    ModalCourseImport,
    ModalMemberImport,
    membershipCalculationTable,
    membershipCategoryGroupTable,
    slider,
    ModalMembershipTypesCreateYear,
    ModalMembershipTypesSettings,
    ModalMembershipCategorySettings,
    ModalMembershipCategoryGroupSettings,
    UseSvg,
    LastUpdated,
  },
  mixins: [LocalizationMixin],

  provide() {
    return {
      lastUpdate: () => {
        return this.latestUpdates;
      },
    };
  },
  data() {
    return {
      hash: this.$route.hash,
      showMemberModal: false,
      showModal: false,
      fileName: '',
      showVerify: false,
      showHistory: false,
      selectedYear: 0,
      showPreviousYear: false,
      previousYear: null,
      isModalOpen: false,
      isSettingsModalOpen: false,
      isGroupSettingsModalOpen: false,
    };
  },
  computed: {
    membershipTypeYears() {
      const membershipTypeYearsData =
        this.$store.getters['businessboard/membershipTypeYears'];
      if (membershipTypeYearsData.years) {
        membershipTypeYearsData.years.sort((a, b) => b.year - a.year);
      }
      return membershipTypeYearsData;
    },

    latestUpdates() {
      return this.$store.getters['businessboard/lastUpdate'];
    },
    businessboardData() {
      return this.$store.getters['businessboard/data'];
    },
    membershipCategories() {
      return this.$store.getters['businessboard/membershipcategories'];
    },
    membershipTypesV2() {
      return this.$store.getters['businessboard/membershiptypesv2'];
    },
    membershipTypes() {
      return this.$store.getters['businessboard/membershiptypes'];
    },
    settings() {
      return this.$store.getters['businessboard/settings'];
    },
    competitionPlayerImports() {
      return this.$store.getters['businessboard/competitionplayers'];
    },
    canSave() {
      return this.fileName !== '';
    },
    showHistoryText() {
      if (this.showHistory) {
        return this.$t('Businessboard.hideHistory');
      } else {
        return this.$t('Businessboard.showHistory');
      }
    },
    hasEconomicLicense() {
      if (!this.settings) {
        return false;
      }
      return (
        this.settings.businessBoardVersion ===
          BusinessBoardVersion.EconomicBasic ||
        this.settings.businessBoardVersion === BusinessBoardVersion.EconomicPlus
      );
    },
    costGroupText() {
      return !this.hasEconomicLicense
        ? this.$t('costGroupIntro')
        : this.$t('Economic.costGroupIntro');
    },
    revenueGroupText() {
      return !this.hasEconomicLicense
        ? this.$t('revenueGroupIntro')
        : this.$t('Economic.revenueGroupIntro');
    },
    dataIntroText() {
      return !this.hasEconomicLicense
        ? this.$t('dataIntro')
        : this.$t('Economic.dataIntro');
    },
  },
  mounted() {
    this.$store.dispatch('businessboard/fetchSettings');
    this.$store.dispatch('businessboard/fetchData', {}).then(() => {
      this.$store.dispatch('businessboard/fetchMembershipCategories');
      this.$store.dispatch('businessboard/fetchMembershipTypes').then(() => {
        window.scrollTo({
          behavior: 'smooth',
          top: document.querySelector(this.$route.hash).offsetTop - 70,
        });
        this.showVerify = false;
      });
      this.$store.dispatch('businessboard/fetchLastUpdate');
    });
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      this.fileName = '';
    },
    toggleMemberModal() {
      this.showMemberModal = !this.showMemberModal;
      this.fileName = '';
    },
    toggleHistory() {
      this.showHistory = !this.showHistory;
    },
    chooseFile(value) {
      document.getElementById('file_upload').click();
    },
    addSelectedFiles(event) {
      let file = document.getElementById('file_upload').files[0];
      if (file) {
        this.fileName = file.name;
      }
    },
    selectYear(e) {
      this.selectedYear = e.value;
    },
    fetchData(n) {
      this.showVerify = n;
      this.$store.dispatch('businessboard/fetchData');
    },

    setShowPreviousYear(showPreviousYear) {
      this.showPreviousYear = showPreviousYear;
    },
    openModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    openSettingsModal() {
      this.isSettingsModalOpen = !this.isSettingsModalOpen;
    },
    closeCreateNewYearModal(e) {
      this.isModalOpen = e;
    },
  },
};
</script>
<style lang="scss" scoped>
html {
  scroll-behavior: smooth;
}
.businessboard--header {
  height: 3rem;
  margin: 1rem 0;
}

.intro {
  margin-bottom: 6rem;
}

.flex-child-elements {
  width: 100%;
  display: inline-flex;
}
.action-buttons-div {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
}

.h3-title {
  color: #131516;
  font-size: font(h2);
  font-weight: font-weight(semibold);
}

.preamble {
  max-width: 30rem;

  font-size: font(h4);
}
.element--is-loading {
  margin-left: measure(spacing-xs);
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.businessboard {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding-left: 50%;
  padding: 1rem 1.5rem;
}

.divide {
  margin-bottom: 10rem;
}

.businessboard--category {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.loading-verification {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 5rem 5rem;
  background-repeat: no-repeat;
  background-position: center center;
  .loading-text {
    font-size: font(h2);
    color: color(white);
  }
}
.modal__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
.modal__content > div {
  padding-right: 15px;
  margin-right: 15px;
  width: 50%;
}
.businessboard--title {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding: measure(spacing-m);
  background-color: color(bb-primary);
  color: color(white);
  .title {
    font-size: 1.2rem;
    flex: 1;
  }
  .button {
    color: color(white);
  }
  .button::before {
    content: '+';
  }
}
div.businessboard--import {
  margin-top: 1rem;
}
div.businessboard--import > * {
  padding-right: 10px;
}
table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

th {
  background-color: #27645c;
  color: white;
}
th,
td {
  width: 150px;
  text-align: center;
  padding: 5px;
}
.slider_div {
  display: flex;
  margin-bottom: measure(spacing-m);
  margin-top: measure(spacing-m);
  margin-left: auto;
  margin-right: measure(spacing-xxl);

  p {
    color: color(darkText);
    line-height: 26px;
    font-weight: font-weight(semibold);
  }
}
</style>
