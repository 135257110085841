<template>
  <div :class="[fileFormat === '0' ? 'opaque-bg' : '']">
    <h1
      v-if="settings.showHeaders"
      class="title">
      {{ $t('roundsPerMonth') }}
    </h1>
    <chart-legend
      :hasGolfBox="businessBoardSettings.hasGolfBox"
      :year1="diagram.data[0].year"
      :year2="diagram.data[1].year"
      :showXFactor="JSON.parse(diagram.settings)['ShowXFactor']">
    </chart-legend>
    <bar-chart
      v-if="!isLoading"
      :styles="{ height: '400px' }"
      :chart-data="
        businessBoardSettings.hasGolfBox ? chartDataGb : chartData
      " />
    <table-chart-loader v-if="isLoading" />
    <div class="legend">
      <reports-legend-bar-chart2
        :normalYear="diagram.data[0].year"
        :memberData="
          businessBoardSettings.hasGolfBox ? competitionData : memberData
        "
        :guestData="businessBoardSettings.hasGolfBox ? friendlyData : guestData"
        unit="st" />
    </div>
  </div>
</template>

<script>
import { MonthsLabelsShort } from '@/core/consts';
import { diagramPattern } from '@/core/helpers';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

import BarChart from '@/components/charts/bar-chart-month2';
import ReportsLegendBarChart2 from '@/components/reports-templates/legend/reports-legend-bar-chart2';
import ChartLegend from '@/components/reports-templates/legend/game-month-rounds2';
import TableChartLoader from './table-chart-loader.vue';

import {
  difference,
  differenceInPercentage,
  summarizeRounds,
} from './utils/calculations';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixin],
  components: {
    BarChart,
    ReportsLegendBarChart2,
    ChartLegend,
    TableChartLoader,
  },
  methods: {
    differenceValue: function (property) {
      return difference(this.diagram.data[0], this.diagram.data[1], property);
    },

    differenceInPercentageValue: function (property) {
      return differenceInPercentage(
        this.diagram.data[0],
        this.diagram.data[1],
        property
      );
    },

    summarizeRoundsValue: function (property) {
      return summarizeRounds(this.diagram.data[0], property);
    },

    capacityData: function (label, color) {
      const dataSet = this.dataSet('capacity', label, color);

      return [
        {
          ...dataSet[0],
          order: 99,
          pointHoverRadius: 0,
          pointRadius: 0,
          type: 'line',
          fill: 'false',
          yAxisID: 'y-lines',
        },
        {
          ...dataSet[1],
          order: 100,
          pointHoverRadius: 0,
          pointRadius: 0,
          type: 'line',
          fill: 'origin',
          backgroundColor: 'rgba(251, 235, 221, 0.7)',
          borderColor: 'rgba(251, 235, 221, 0.9)',
          yAxisID: 'y-lines',
        },
      ];
    },

    dataSet: function (category, label, color) {
      return [
        {
          label: `(${this.diagram.data[0].year}) ${label}`,
          data: this.diagram.data[0].months.map(m => m[category]),
          borderColor: color,
          backgroundColor: color,
          borderWidth: 2,
          pointRadius: 0,
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          stack: 'stack1',
          yAxisID: 'y-bars',
        },
        {
          label: `(${this.diagram.data[1].year}) ${label}`,
          data: this.diagram.data[1].months.map(m => m[category]),
          borderColor: color,
          backgroundColor: diagramPattern(color),
          borderWidth: 1,
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          stack: 'stack2',
          yAxisID: 'y-bars',
        },
      ];
    },
  },
  computed: {
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
    businessBoardSettings() {
      return this.$store.getters['businessboard/settings'];
    },
    memberData: function () {
      return [
        {
          year: this.diagram.data[1].year,
          value: this.differenceValue('memberCompany'),

          percentage: this.differenceInPercentageValue('memberCompany'),
          // month: '',
          rounds: this.summarizeRoundsValue('memberCompany'),
          type: this.$t('memberFriendly'),
          role: 'Member',
        },
        {
          // year: this.diagram.data[1].year,
          value: this.differenceValue('memberCompetition'),
          percentage: this.differenceInPercentageValue('memberCompetition'),
          month: '',
          rounds: this.summarizeRoundsValue('memberCompetition'),
          type: this.$t('memberCompetition'),
          role: 'Member',
        },
      ];
    },

    guestData: function () {
      return [
        {
          year: this.diagram.data[1].year,
          value: this.differenceValue('guestCompany'),
          percentage: this.differenceInPercentageValue('guestCompany'),
          // month: '',
          rounds: this.summarizeRoundsValue('guestCompany'),
          type: this.$t('guestFriendly'),
          role: 'Guest',
        },
        {
          // year: this.diagram.data[1].year,
          value: this.differenceValue('guestCompetition'),
          percentage: this.differenceInPercentageValue('guestCompetition'),
          month: '',
          rounds: this.summarizeRoundsValue('guestCompetition'),
          type: this.$t('guestCompetition'),
          role: 'Guest',
        },
      ];
    },

    friendlyData: function () {
      return [
        {
          year: this.diagram.data[1].year,
          value: this.differenceValue('guestCompany'),
          percentage: this.differenceInPercentageValue('guestCompany'),
          // month: '',
          rounds: this.summarizeRoundsValue('guestCompany'),
          type: this.$t('guestFriendly'),
          role: 'Guest',
        },
        {
          // year: this.diagram.data[1].year,
          value: this.differenceValue('memberCompany'),
          percentage: this.differenceInPercentageValue('memberCompany'),
          month: '',
          rounds: this.summarizeRoundsValue('memberCompany'),
          type: this.$t('memberFriendly'),
          role: 'Member',
        },
      ];
    },

    competitionData: function () {
      return [
        {
          year: this.diagram.data[1].year,
          value: this.differenceValue('competition'),
          percentage: this.differenceInPercentageValue('competition'),
          // month: '',
          rounds: this.summarizeRoundsValue('competition'),
          type: this.$t('competition'),
          role: 'Competition',
        },
      ];
    },
    chartData: function () {
      return {
        labels: this.monthLabels,
        datasets: [
          ...this.capacityData('Spelkapacitet', '#00A99D'),
          ...this.dataSet(
            'memberCompany',
            this.$t('memberFriendly'),
            '#0D3746'
          ),
          ...this.dataSet(
            'memberCompetition',
            this.$t('memberCompetition'),
            '#00A99D'
          ),
          ...this.dataSet('guestCompany', this.$t('guestFriendly'), '#ABDED1'),
          ...this.dataSet(
            'guestCompetition',
            this.$t('guestCompetition'),
            '#F08565'
          ),
          ...this.dataSet('xfactor', this.$t('xFactor'), '#4B2D88'),
        ],
      };
    },
    chartDataGb: function () {
      return {
        labels: this.monthLabels,
        datasets: [
          ...this.capacityData(this.$t('gameCapacity'), '#00A99D'),
          ...this.dataSet(
            'memberCompany',
            this.$t('memberFriendly'),
            '#0D3746'
          ),
          ...this.dataSet('guestCompany', this.$t('guestFriendly'), '#ABDED1'),
          ...this.dataSet('competition', this.$t('competition'), '#F08565'),
          ...this.dataSet('xfactor', this.$t('xFactor'), '#4B2D88'),
        ],
      };
    },
    monthLabels: function () {
      return MonthsLabelsShort.map(l => this.$t(`MonthsShort.${l}`));
    },
  },

  props: { diagram: {}, fileFormat: String },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch('businessboard/fetchSettings');
  },

  created() {},
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';

.title {
  margin-left: 4rem;
  font-size: xx-large;
}
</style>
