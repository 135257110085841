<template>
  <div class="ontag">
    <div
      class="ontag__content scorecard"
      v-if="scorecard && templates && templates.length > 0">
      <h1 v-if="id">{{ $t('edit') }} {{ $t('scorecard') }}</h1>
      <h1 v-else>
        {{ $t('create') }} {{ $t('scorecard') }}: {{ $t(`${typeName}`) }}
      </h1>
      <div class="scorecard__edit">
        <div class="scorecard__result">
          <div
            class="scorecard__result--image"
            :class="[
              {
                'scorecard__result--image__variated':
                  scorecard &&
                  scorecard.template &&
                  !scorecard.template.alternativeLayout,
              },
            ]">
            <div
              v-if="hover"
              class="scorecard__result--image__overlay"></div>
            <template v-if="selectLayout && choosenTemplate">
              <div
                :class="[
                  'scorecard__result--customized-wrapper',
                  {
                    'alternative-layout': scorecard.template.alternativeLayout,
                  },
                ]">
                <div
                  :class="[
                    'scorecard__result--customized-image',
                    { highlight: hover === 'one' },
                  ]">
                  <img
                    v-if="scorecard.imageOneUrl"
                    :src="
                      scorecard.imageOneKey
                        ? scorecard.imageOneUrl + scorecard.imageOneKey
                        : scorecard.imageOneUrl
                    " />
                </div>
                <div
                  v-if="
                    scorecard.template && !scorecard.template.alternativeLayout
                  "
                  :class="[
                    'scorecard__result--customized-image',
                    { highlight: hover === 'two' },
                  ]">
                  <img
                    v-if="scorecard.imageTwoUrl"
                    :src="
                      scorecard.imageTwoKey
                        ? scorecard.imageTwoUrl + scorecard.imageTwoKey
                        : scorecard.imageTwoUrl
                    " />
                </div>
              </div>
              <div
                :class="[
                  'scorecard__result--info-wrapper',
                  {
                    'alternative-layout': scorecard.template.alternativeLayout,
                  },
                ]">
                <div
                  :class="[
                    'scorecard__result--info-header',
                    { highlight: hover === 'header' },
                  ]">
                  {{ scorecard.headerText }}
                </div>
                <textarea
                  :class="[
                    'scorecard__result--info-text',
                    { highlight: hover === 'text' },
                  ]"
                  v-if="
                    scorecard.template && !scorecard.template.alternativeLayout
                  "
                  v-model="scorecard.informationText"
                  readonly></textarea>
              </div>
              <img
                :src="choosenTemplate"
                alt="scorecard" />
            </template>
          </div>
          <!-- Remove v-show when ready -->
          <div
            class="scorecard__result--action"
            v-show="1 === 2">
            <!-- TODO: Fix test print -->
            <base-button
              :button-size-small="true"
              :text="$t('testPrint')"
              @click="toggleTestPrintModal" />
            <modal-window :show="showTestPrintModal">
              <div class="modal__header">
                {{ $t('testPrint') }}
              </div>
              <div class="modal__content">
                <!-- v-if="selectedIndexPrinter !== undefined" -->
                <custom-select
                  :required="false"
                  @optionSelected="setPrinter"
                  :selected="selectedIndexPrinter"
                  :options="printers" />
              </div>
              <div class="modal__content--actions">
                <base-button
                  class="button--background-gray-dark"
                  :text="$t('cancel')"
                  @click="toggleTestPrintModal">
                </base-button>
                <base-button
                  :text="$t('testPrint')"
                  @click="testPrint" />
              </div>
            </modal-window>
          </div>
        </div>
        <div class="scorecard__settings">
          <div class="input">
            <label
              for="scorecard-name"
              class="input__label first-letter">{{ $t('scorecard') }} {{ $t('name') }}*</label>
            <input
              type="text"
              name="scorecard-name"
              id="scorecard-name"
              class="input__input"
              required
              v-model="scorecard.name"
              :disabled="scorecard.standard" />
          </div>
          <div
            class="input-group scorecard-switch"
            v-if="scorecard.showVoucher">
            <label
              for="voucher"
              class="input__label first-letter">{{ $t('printOut') }} {{ $t('with') }} {{ $t('voucher') }}</label>
            <toggle-switch
              input-id="voucher"
              type="switch"
              :toggled="scorecard.useVoucher"
              @toggle="printWithVoucher" />
          </div>
          <div
            class="input"
            v-if="choosenTemplate || (!scorecard.template && !choosenTemplate)">
            <custom-select
              :label="$t('chooseLayout')"
              id="scorecard-layout"
              @optionSelected="selectLayout"
              :required="false"
              :selected="selectedTemplateIndex"
              :options="templates">
            </custom-select>
          </div>
          <div v-if="typeName !== 'competition'">
            <div class="input">
              <custom-select
                :label="$t('chooseHolesToPrint')"
                id="holesToPrint"
                @optionSelected="selectedHoles"
                :options="holestoPrintOptions"
                :selected="
                  scorecard.holesToPrint === undefined
                    ? 17
                    : Number.isInteger(scorecard.holesToPrint)
                    ? scorecard.holesToPrint - 1
                    : scorecard.holesToPrint.split(',').at(-1) - 1
                "
                :required="false">
              </custom-select>
            </div>
          </div>
          <template v-if="scorecard.template || choosenTemplate">
            <p class="first-letter">{{ $t('customize') }}</p>
            <div class="scorecard__layout">
              <template v-if="scorecard.imageOneUrl">
                <div class="scorecard__wrapper">
                  <p class="first-letter">{{ $t('image') }} 1</p>
                  <div class="scorecard__layout--image image-1">
                    <div
                      @mouseover="hoverOn('one')"
                      @mouseleave="hoverOn('')"
                      :class="['scorecard__layout--image-overlay-wrapper']">
                      <template v-if="hover === 'one'">
                        <base-button
                          :text="$t('remove')"
                          class="button--has-icon"
                          :button-size-small="true"
                          @click="removeImage('one')">
                          <use-svg svg="trash-icon" />
                        </base-button>
                        <input
                          hidden
                          type="file"
                          id="file_upload_1"
                          name="file_upload_1"
                          @change="
                            e => addFile('imageOneUrl', 'imageOneKey', e)
                          " />
                        <base-button
                          @click="chooseFile('one')"
                          :text="$t('upload')"
                          class="button--has-icon"
                          :button-size-small="true">
                          <use-svg svg="export-icon" />
                        </base-button>
                      </template>
                    </div>
                    <img
                      :src="
                        scorecard.imageOneKey
                          ? scorecard.imageOneUrl + scorecard.imageOneKey
                          : scorecard.imageOneUrl
                      " />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="scorecard__wrapper">
                  <p class="first-letter">{{ $t('image') }} 1</p>
                  <div :class="'scorecard__layout--image'">
                    <div :class="['scorecard__layout--image-overlay-wrapper']">
                      <input
                        hidden
                        type="file"
                        id="file_upload_1"
                        name="file_upload_1"
                        @change="
                          e => addFile('imageOneUrl', 'imageOneKey', e)
                        " />
                      <base-button
                        @click="chooseFile('one')"
                        :text="$t('upload')"
                        class="button--has-icon"
                        :button-size-small="true">
                        <use-svg svg="export-icon" />
                      </base-button>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="!scorecard.template.alternativeLayout">
                <template v-if="scorecard.imageTwoUrl">
                  <div class="scorecard__wrapper">
                    <p class="first-letter">{{ $t('image') }} 2</p>
                    <div class="scorecard__layout--image image-1">
                      <div
                        @mouseover="hoverOn('two')"
                        @mouseleave="hoverOn('')"
                        :class="['scorecard__layout--image-overlay-wrapper']">
                        <template v-if="hover === 'two'">
                          <base-button
                            :text="$t('remove')"
                            class="button--has-icon"
                            :button-size-small="true"
                            @click="removeImage('two')">
                            <use-svg svg="trash-icon" />
                          </base-button>
                          <input
                            hidden
                            type="file"
                            id="file_upload_2"
                            name="file_upload_2"
                            @change="
                              e => addFile('imageTwoUrl', 'imageTwoKey', e)
                            " />
                          <base-button
                            @click="chooseFile('two')"
                            :text="$t('upload')"
                            class="button--has-icon"
                            :button-size-small="true">
                            <use-svg svg="export-icon" />
                          </base-button>
                        </template>
                      </div>
                      <img
                        :src="
                          scorecard.imageTwoKey
                            ? scorecard.imageTwoUrl + scorecard.imageTwoKey
                            : scorecard.imageTwoUrl
                        " />
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="scorecard__wrapper">
                    <p class="first-letter">{{ $t('image') }} 2</p>
                    <div :class="'scorecard__layout--image'">
                      <div
                        :class="['scorecard__layout--image-overlay-wrapper']">
                        <input
                          hidden
                          type="file"
                          id="file_upload_2"
                          name="file_upload_2"
                          @change="
                            e => addFile('imageTwoUrl', 'imageTwoKey', e)
                          " />
                        <base-button
                          @click="chooseFile('two')"
                          :text="$t('upload')"
                          class="button--has-icon"
                          :button-size-small="true">
                          <use-svg svg="export-icon" />
                        </base-button>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <div class="input">
              <input
                type="text"
                name="scorecard-header"
                id="scorecard-header"
                class="input__input"
                :placeholder="$t('typeHeader')"
                maxlength="42"
                v-model="scorecard.headerText"
                @mouseover="scorecard.headerText ? hoverOn('header') : ''"
                @mouseleave="scorecard.headerText ? hoverOn('') : ''" />
            </div>
            <div
              v-if="!scorecard.template.alternativeLayout"
              class="input">
              <textarea
                name="text"
                id="textarea"
                class="input__textarea"
                :placeholder="$t('typeText')"
                maxlength="465"
                rows="15"
                v-model="scorecard.informationText"
                @mouseover="scorecard.informationText ? hoverOn('text') : ''"
                @mouseleave="scorecard.informationText ? hoverOn('') : ''">
              </textarea>
            </div>
            <template v-if="id !== undefined">
              <div
                class="scorecard__connections"
                v-if="!scorecard.standard">
                <div
                  v-if="
                    courses &&
                    courses.length > 0 &&
                    scorecard &&
                    scorecard.courses &&
                    typeName !== 'competition'
                  ">
                  <addable-custom-select
                    v-if="
                      courses &&
                      courses.length > 0 &&
                      scorecard &&
                      scorecard.courses
                    "
                    :options="courses"
                    :label="$t('addCourses')"
                    :selected="
                      scorecard && scorecard.courses ? scorecard.courses : []
                    "
                    @selectedConnections="setCourses" />
                </div>
                <template v-if="typeName === 'competitions'">
                  <div v-if="competitions">
                    <addable-date-filter
                      :options="competitions"
                      :label="$t('addCompetitions')"
                      modal-title="Add competitions"
                      :selected="
                        scorecard.competitions ? scorecard.competitions : []
                      "
                      @selectedConnections="setCompetitions" />
                  </div>
                  <div v-if="categories">
                    <addable-custom-select
                      :label="$t('addCategories')"
                      :options="categories"
                      :selected="
                        scorecard.competitionCategories
                          ? scorecard.competitionCategories
                          : []
                      "
                      @selectedConnections="setCategories" />
                  </div>
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="scorecard__actions">
        <base-button
          :text="$t('cancel')"
          class="button--background-gray-dark"
          @click="$router.push({ name: `${typeName}-scorecard` })" />
        <base-button
          v-if="id"
          :text="$t('save')"
          v-element-loader="{ loaderKey: 'scorecard-edit-scorecard' }"
          @click="saveScoreCard"
          :disabled="!canCreate" />
        <base-button
          v-else
          :text="$t('create')"
          v-element-loader="{ loaderKey: 'scorecard-create-scorecard' }"
          @click="createScoreCard"
          :disabled="!canCreate" />
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import ToggleSwitch from '@/components/form-elements/switch';
import AddableDateFilter from '@/components/addable-date-filter/addable-date-filter';
import AddableCustomSelect from '@/components/addable-custom-select/addable-custom-select';
import ModalWindow from '@/components/modal/modal';
// import AddableDateFilter from '@/components/addable-date-filter/addable-date-filter'
import elementLoader from '@/directives/element-loader';

export default {
  name: 'ScoreCardEdit',
  components: {
    UseSvg,
    BaseButton,
    CustomSelect,
    ToggleSwitch,
    AddableDateFilter,
    AddableCustomSelect,
    ModalWindow,
    // AddableDateFilter
  },
  props: {
    type: Number,
    typeName: String,
    id: String,
    copyOfScoreCard: Object,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      choosenTemplate: '',
      hover: false,
      alternativeLayout: false,
      showTestPrintModal: false,
      selectedIndexPrinter: 0,
      selectedPrinter: '',
      selectedTemplateIndex: undefined,
      localCopyOfScoreCard: this.copyOfScoreCard,
      holestoPrintOptions: [
        {
          displayName: '1',
          value: 1,
        },
        {
          displayName: '2',
          value: 2,
        },
        {
          displayName: '3',
          value: 3,
        },
        {
          displayName: '4',
          value: 4,
        },
        {
          displayName: '5',
          value: 5,
        },
        {
          displayName: '6',
          value: 6,
        },
        {
          displayName: '7',
          value: 7,
        },
        {
          displayName: '8',
          value: 8,
        },
        {
          displayName: '9',
          value: 9,
        },
        {
          displayName: '10',
          value: 10,
        },
        {
          displayName: '11',
          value: 11,
        },
        {
          displayName: '12',
          value: 12,
        },
        {
          displayName: '13',
          value: 13,
        },
        {
          displayName: '14',
          value: 14,
        },
        {
          displayName: '15',
          value: 15,
        },
        {
          displayName: '16',
          value: 16,
        },
        {
          displayName: '17',
          value: 17,
        },
        {
          displayName: '18',
          value: 18,
        },
      ],
    };
  },
  methods: {
    selectLayout(options) {
      if (options.alternativeLayout) {
        this.scorecard.informationText = '';
      }
      const templateNumber = parseInt(
        options.displayName.replace('Layout ', '')
      );
      let templateCategory = 2;
      if (templateNumber < 6) {
        templateCategory = 1;
      }
      // If switching template categories, some fields needs to be removed
      if (this.scorecard.template) {
        const currentTemplateNumber = parseInt(
          this.scorecard.template.name.replace('Layout ', '')
        );
        let currentTemplateCategory = 2;
        if (currentTemplateNumber < 6) {
          currentTemplateCategory = 1;
        }
        if (templateCategory !== currentTemplateCategory) {
          this.scorecard.imageTwoKey = '';
          this.scorecard.imageTwoUrl = '';
          this.scorecard.informationText = '';
        }
      }
      this.choosenTemplate = options.templateUrl;
      let template = {
        value: options.value,
        name: options.displayName,
        informationText: options.informationText,
        alternativeLayout: options.alternativeLayout,
      };
      this.scorecard.template = template;
    },
    toggleTestPrintModal() {
      this.showTestPrintModal = !this.showTestPrintModal;
    },
    selectedHoles(e) {
      this.scorecard.holesToPrint = e.value;
    },
    chooseFile(value) {
      if (value === 'one') {
        document.getElementById('file_upload_1').click();
      }
      if (value === 'two') {
        document.getElementById('file_upload_2').click();
      }
    },
    addFile(value, key, event) {
      let image;
      if (value === 'imageOneUrl') {
        image = event.target.files[0];
        if (image) {
          this.$store.dispatch('scorecard/addImage', {
            image: image,
            box: value,
            key: key,
          });
        }
      }
      if (value === 'imageTwoUrl') {
        image = event.target.files[0];
        if (image) {
          this.$store.dispatch('scorecard/addImage', {
            image: image,
            box: value,
            key: key,
          });
        }
      }
    },
    hoverOn(element) {
      this.hover = element;
    },
    removeImage(image) {
      if (image === 'one') {
        this.scorecard.imageOneUrl = '';
      }
      if (image === 'two') {
        this.scorecard.imageTwoUrl = '';
      }
    },
    printWithVoucher(boolean) {
      this.scorecard.useVoucher = boolean;
    },
    // TODO: Fix test print
    testPrint() {
      if (this.type === 3) {
        this.$store.dispatch('printers/printNeutralScoreCards', {
          course: this.course,
        });
      }
    },
    setPrinter(printer) {
      this.selectedPrinter = printer.value;
    },
    setCourses(selectedCourses) {
      this.scorecard.courses = selectedCourses;
      // this.scorecard.courseIds = selectedCourses.map(course => course.value)
    },
    setCategories(selectedCategories) {
      this.scorecard.categories = selectedCategories.map(
        category => category.value
      );
    },
    setCompetitions(selectedCompetitions) {
      this.scorecard.competitionIds = selectedCompetitions.map(
        competition => competition.value
      );
    },
    createScoreCard() {
      this.$store
        .dispatch('scorecard/createScoreCard', {
          name: this.scorecard.name,
          // useVoucher: false,
          type: this.type,
          templateId: this.scorecard.template.value
            ? this.scorecard.template.value
            : this.scorecard.template.id,
          imageOneUrl: this.scorecard.imageOneUrl,
          imageTwoUrl: this.scorecard.imageTwoUrl,
          headerText: this.scorecard.headerText,
          informationText: this.scorecard.informationText,
          // courseIds: this.scorecard.courses.map(course => course.value), Can't connect courses until scorecard is created
          // categories: this.scorecard.categories,
          // competitionIds: this.scorecard.competitionIds
        })
        .then(() => {
          this.$router.push({ name: `${this.typeName}-scorecard` });
          this.$toasted.success(this.$t('scorecardCreated'));
          this.$store.dispatch('scorecard/fetchScorecards', {
            type: this.type,
            search: '',
          });
        });
    },
    saveScoreCard() {
      let categories = this.scorecard.competitionCategories.map(
        category => category.value
      );
      if (this.scorecard.categories) {
        categories = [...this.scorecard.categories];
      }
      let competitionIds = this.scorecard.competitions.map(
        competition => competition.value
      );
      if (this.scorecard.competitionIds) {
        competitionIds = [...this.scorecard.competitionIds];
      }
      this.$store
        .dispatch('scorecard/editScoreCard', {
          id: this.scorecard.id,
          name: this.scorecard.name,
          useVoucher: this.scorecard.useVoucher,
          type: this.type,
          templateId: this.scorecard.template.value
            ? this.scorecard.template.value
            : this.scorecard.template.id,
          imageOneUrl: this.scorecard.imageOneUrl,
          imageTwoUrl: this.scorecard.imageTwoUrl,
          headerText: this.scorecard.headerText,
          informationText: this.scorecard.informationText,
          courseIds: this.scorecard.courses.map(course => course.value),
          categories: categories,
          competitionIds: competitionIds,
          holesToPrint: this.scorecard.holesToPrint,
        })
        .then(() => {
          this.$router.push({ name: `${this.typeName}-scorecard` });
          this.$toasted.success(this.$t('scorecardSaved'));
          // this.$store.dispatch('scorecard/fetchScorecards', {
          //   type: this.type,
          //   search: ''
          // }).then(() => {
          // })
        });
    },
    selectedTemplate(template) {
      if (template) {
        let selectedTemplateIndex = this.templates.findIndex(
          t => t.value === template.id
        );
        let selectedTemplate = this.templates.find(
          t => t.displayName === template.name
        ).templateUrl;
        this.choosenTemplate = selectedTemplate;
        this.selectedTemplateIndex = selectedTemplateIndex;
      }
    },
  },
  computed: {
    canCreate() {
      return this.scorecard.name;
    },
    scorecard: {
      get() {
        return this.$store.getters['scorecard/scorecard'];
      },
      set(val) {
        return val;
      },
    },
    templates: {
      cache: false,
      get() {
        return this.$store.getters['scorecard/templates'];
      },
      set(val) {
        return val;
      },
    },
    courses() {
      return this.$store.getters['scorecard/courses'];
    },
    competitions() {
      return this.$store.getters['scorecard/competitions'];
    },
    categories() {
      return this.$store.getters['scorecard/categories'];
    },
    printers() {
      return this.$store.getters['printers/printers'].rows.map(printer => {
        return {
          value: printer.id,
          displayName: printer.printerName,
        };
      });
    },
  },
  mounted() {
    if (this.id) {
      this.$store
        .dispatch('scorecard/fetchScoreCard', { id: this.id })
        .then(() => {
          this.$store
            .dispatch('scorecard/fetchTemplates', { scorecardType: this.type })
            .then(() => {
              if (this.scorecard) {
                this.selectedTemplate(this.scorecard.template);
              }
            });
          this.$store.dispatch('scorecard/fetchCourses', { id: this.id });
          this.$store.dispatch('scorecard/fetchCategories', { id: this.id });
          this.$store.dispatch('scorecard/fetchCompetitions', { id: this.id });
        });
    } else {
      this.$store.commit('scorecard/scorecard', {});
      this.$store.dispatch('scorecard/fetchTemplates', {
        scorecardType: this.type,
      });
      // this.$store.dispatch('scorecard/fetchCategories', null)
      // this.$store.dispatch('scorecard/fetchCompetitions', null)
    }
    if (!this.id && this.localCopyOfScoreCard) {
      this.scorecard.name = this.localCopyOfScoreCard.name;
      this.scorecard.imageOneUrl = this.localCopyOfScoreCard.imageOneUrl;
      this.scorecard.imageOneKey = this.localCopyOfScoreCard.imageOneKey;
      this.scorecard.imageTwoUrl = this.localCopyOfScoreCard.imageTwoUrl;
      this.scorecard.imageTwoKey = this.localCopyOfScoreCard.imageTwoKey;
      this.scorecard.headerText = this.localCopyOfScoreCard.headerText;
      this.scorecard.informationText =
        this.localCopyOfScoreCard.informationText;
      this.scorecard.holesToPrint = this.localCopyOfScoreCard.holesToPrint;
      this.scorecard.template = this.localCopyOfScoreCard.template;
    }
    if (!this.printers.rows || this.printers.rows.length === 0) {
      this.$store.dispatch('printers/fetchAllPrinters', {
        page: 0,
      });
    }
  },
  beforeDestroy() {
    this.scorecard = {};
    this.templates = [];
    this.$store.commit('scorecard/clearScorecard');
  },
};
</script>
<style lang="scss">
.scorecard__edit {
  display: flex;
  width: 100%;
}
.scorecard__result--image {
  background-color: color(gray);
  padding: 1rem 3rem;
  position: relative;
  border-radius: 0.125rem;
  width: 25.5rem;
  height: 50.625rem;
  img {
    height: 100%;
    width: 100%;
  }
  .scorecard__result--image__overlay {
    background-color: rgba(172, 172, 172, 0.9);
    width: 19.5rem;
    height: 48.625rem;
    position: absolute;
    z-index: 1;
  }
  .scorecard__result--customized-wrapper {
    position: absolute;
    display: flex;
    left: 3.75rem;
    right: 3.75rem;
    top: 1.25rem;
    justify-content: space-evenly;

    &.alternative-layout {
      right: initial;
      justify-content: flex-start;
      width: 33%;
    }

    .scorecard__result--customized-image {
      width: 100%;
      height: 5rem;
      position: relative;
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      &.highlight {
        background-color: color(white);
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
      }
    }
  }
  .scorecard__result--info-wrapper {
    position: absolute;
    top: 6.25rem;
    width: 19.5rem;

    &.alternative-layout {
      top: initial;
      bottom: 1.5rem;
      width: 19.5rem;
    }

    .scorecard__result--info-header {
      text-align: center;
      font-size: 14px;
      &.highlight {
        background-color: white;
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
        position: absolute;
        width: 100%;
      }
    }
    .scorecard__result--info-text {
      width: 48%;
      padding: 0 0.3125rem;
      word-break: break-word;
      font-size: 0.59375rem;
      resize: none;
      overflow: hidden;
      border: none;
      height: 150px;
      &.highlight {
        background-color: white;
        padding: 3px;
        border-radius: 10px;
        z-index: 1;
        position: absolute;
        width: 50%;
      }
    }
  }
}
.scorecard__result--action {
  display: flex;
  margin-top: 0.5rem;
  button {
    margin-left: auto;
  }
}
.scorecard__settings {
  margin-left: 2rem;
  width: 100%;
  input {
    width: 100% !important;
  }
  .select__wrapper {
    width: 100% !important;
    margin-bottom: 0.5rem;
  }
}
.scorecard__layout {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  @include media('to-medium') {
    display: block;
  }
}
.scorecard-switch {
  display: flex;
  margin: 2rem 0;
  .input__label {
    flex-basis: 35%;
  }
}
.scorecard__wrapper {
  height: 100%;
  width: 45%;

  @include media('to-medium') {
    width: 100%;
  }

  p {
    margin: 0 0 0.5rem 0;
    @include media('to-medium') {
      margin: 0.5rem 0;
    }
  }
}
.scorecard__layout--image {
  border: 1px solid color(gray);
  height: 7rem;
  display: flex;
  justify-content: center;
  position: relative;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .scorecard__layout--image-overlay-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    button {
      margin-right: 0.3rem;
      padding: 0 0.5rem;
    }
  }
}
.scorecard__layout--image-overlay {
  background-color: rgba(249, 249, 249, 0.5);
}
.scorecard__layout--info {
  display: flex;
  justify-content: space-between;
}
.scorecard__layout--text,
.scorecard__layout--players {
  width: 48%;
}
.scorecard__layout--text {
  .input__textarea {
    width: 100%;
    resize: none;
  }
}
input#scorecard-header {
  width: 100%;
}
.scorecard__connections {
  display: flex;
  justify-content: flex-start;
  min-height: 15rem;
  border-top: 1px solid color(gray-dark);
  > div {
    flex: 1 1 auto;
    margin: 0 1.25rem 0.625rem 0;
    padding-top: 0.625rem;
    &:nth-child(2) {
      padding-left: 1.25rem;
      border-left: 1px solid color(gray-dark);
    }
  }
}
.scorecard__actions {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 1rem;
  }
}
</style>
