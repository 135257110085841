<template>
  <modal-window
    wrapperClass="te-modal"
    :size="size"
    transition="te-modal"
    :show="localShow">
    <div class="te-modal-header">
      <slot name="header"></slot>
      <div
        v-if="showHideButton"
        name="close"
        class="te-modal-close"
        @click="hide()">
        <span class="tietoevry-icons-cancel-large"></span>
      </div>
    </div>
    <div class="te-modal-content"><slot name="content"></slot></div>
    <div class="te-modal-actions">
      <slot name="actions"></slot>
    </div>
  </modal-window>
</template>

<script>
import ModalWindow from '@/components/modal/modal';
export default {
  name: 'TeModal',
  emits: ['hide'],
  components: { ModalWindow },
  props: {
    title: {
      type: String,
      default: 'Missing modal title',
    },
    show: {
      type: Boolean,
    },
    showHideButton: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  watch: {
    show: function (newValue) {
      this.localShow = newValue;
    },
  },
  data() {
    return {
      localShow: this.show,
    };
  },
  methods: {
    hide() {
      this.localShow = false;
      this.$emit('hide', false);
    },
  },
};
</script>

<style lang="scss"></style>
