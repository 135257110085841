<script>
import VueTypes from 'vue-types';

import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: {
    data: VueTypes.shape({
      labels: VueTypes.arrayOf(VueTypes.string),
      values: VueTypes.arrayOf(VueTypes.number),
      datasets: VueTypes.arrayOf(
        VueTypes.shape({
          data: VueTypes.arrayOf(VueTypes.number),
          backgroundColor: VueTypes.arrayOf(VueTypes.string),
          borderWidth: VueTypes.number.def(0),
        })
      ),
    }),
    colors: VueTypes.arrayOf(VueTypes.string),

    displayLegend: VueTypes.bool.def(true),
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: this.data.labels.map((label, index) => {
            /* ***
            In case label is already containg percentage character return the unmodified label
            in other case calculate the actual percentage and return the label */
            return label.indexOf('%') !== -1
              ? label
              : `${label} ${(
                  (this.data.values[index] /
                    this.data.values.reduce((acc, current) => acc + current)) *
                  100
                ).toLocaleString('sv-SE', { maximumFractionDigits: 0 })}%`;
          }),
          datasets: [
            {
              data: this.data.values,
              tooltips: this.data.tooltips,
              backgroundColor: this.colors,
              borderWidth: 0,
            },
          ],
        },
        {
          cutoutPercentage: 70,
          boxWidth: 200,
          radius: '10%',
          width: 400,
          // circumference: Math.PI,
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
          legend: {
            display: this.displayLegend,
            onClick: () => {},
            position: 'bottom',
            align: 'start',
            labels: {
              usePointStyle: false,
              boxWidth: 13,
              boxHeight: 13,
              padding: 15,
              fontSize: 12,
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return `${data.labels[tooltipItem.index]}`;
              },
            },
          },
          maintainAspectRatio: true,
          responsive: true,
        }
      );
    },
  },
  watch: {
    data() {
      this.render();
    },
  },

  mounted() {
    this.render();
  },
};
</script>
