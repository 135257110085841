<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <span class="h1">To do - Invoice addresses</span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
      </div>
      <template v-if="invoiceAddresses">
        <template v-if="Number.isInteger(id)">
          <template v-if="invoiceAddresses[id].type === 'New'">
            <a @click="showNone"> &lt; Tillbaka </a>
            <table>
              <h4>{{ invoiceAddresses[id].type }} invoice address</h4>
              <p>Status: {{ invoiceAddresses[id].lastChanged }}</p>
              <p v-if="invoiceAddresses[id].lastChanged != ''">
                Last update: {{ invoiceAddresses[id].lastChanged }} by
                {{ invoiceAddresses[id].lastChangedBy }}
              </p>
              <p>Comment (Mandatory if Paused)</p>
              <textarea v-model="invoiceAddresses[id].comment"></textarea>
              <h3>CRM data</h3>
              <h4>Customer legal entity name:</h4>
              <p>{{ invoiceAddresses[id].companyName }}</p>
              <h4>Accountname:</h4>
              <p>{{ invoiceAddresses[id].companyName }}</p>
              <h4>Parent account:</h4>
              <p>{{ invoiceAddresses[id].parentAccount }}</p>
              <h4>Account key Customer Code:</h4>
              <p>{{ invoiceAddresses[id].accountKeyCustomerCode }}</p>
              <h4>Tax organizationnumber:</h4>
              <p>{{ invoiceAddresses[id].taxOrganizationNumber }}</p>
              <h4>DUNS Number:</h4>
              <p>{{ invoiceAddresses[id].dunsNumber }}</p>
              <h3>Bill to/Ship to:</h3>
              <h4>Country:</h4>
              <p>{{ invoiceAddresses[id].country }}</p>
              <h4>Address Line 1:</h4>
              <p>{{ invoiceAddresses[id].postalAddress }}</p>
              <h4>Address Line 2-3:</h4>
              <p>{{ invoiceAddresses[id].addressLine23 }}</p>
              <h4>Country Code:</h4>
              <p>{{ invoiceAddresses[id].countryCode }}</p>
              <h4>Postal Code:</h4>
              <p>{{ invoiceAddresses[id].postalCode }}</p>
              <h4>City:</h4>
              <p>{{ invoiceAddresses[id].city }}</p>
              <h4>Global Location Number:</h4>
              <p>{{ invoiceAddresses[id].globalLocationNumber }}</p>
              <hr />
              <h4>Responsible team:</h4>
              <p>{{ invoiceAddresses[id].responsibleTeam }}</p>
              <hr />
              <h4>Invoice Language:</h4>
              <p>{{ invoiceAddresses[id].invoiceLanguage }}</p>
              <template v-if="invoiceAddresses[id].email">
                <h4>Invoice language:</h4>
                <p>To Email(s)</p>
              </template>
              <template v-else>
                <h4>Invoice language:</h4>
                <p>To invoice Hotel</p>
                <h4>e-Invoice:</h4>
                <p>No</p>
              </template>
              <h4>Invoice with attachments:</h4>
              <p>Yes</p>
              <h4>Customer Invoice email address(es)/Primary email address:</h4>
              <p>{{ invoiceAddresses[id].email }}</p>
              <h4>VAT:</h4>
              <p>{{ invoiceAddresses[id].vat }}</p>
              <h4>Payment terms:</h4>
              <p>{{ invoiceAddresses[id].paymentTerms }}</p>
              <h4>Add Invoicing Person(s):</h4>
              <p>{{ invoiceAddresses[id].reference }}</p>
              <hr />
              <p>Please enter project number in TERP</p>
              <input v-model="invoiceAddresses[id].terpId" /><br />
              <br />
              <p>
                Task done:
                {{
                  invoiceAddresses[id].doneDate == null
                    ? ''
                    : invoiceAddresses[id].doneDate
                }}
                {{ invoiceAddresses[id].doneBy }}
              </p>
              <p>
                Task paused:
                {{
                  invoiceAddresses[id].pausedDate == null
                    ? ''
                    : invoiceAddresses[id].pausedDate
                }}
                {{ invoiceAddresses[id].pausedBy }}
              </p>
              <p>
                Task waited:
                {{
                  invoiceAddresses[id].waitedDate == null
                    ? ''
                    : invoiceAddresses[id].waitedDate
                }}
                {{ invoiceAddresses[id].waitedBy }}
              </p>
              <br />
            </table>
          </template>
          <template v-if="invoiceAddresses[id].type == 'Changed'">
            <a @click="showNone"> &lt; Tillbaka </a>
            <section>
              <h4>
                #{{ invoiceAddresses[id].id }}
                {{ invoiceAddresses[id].type }} invoice address
              </h4>
              <p>Status: {{ invoiceAddresses[id].status }}</p>
              <p>
                Last updated: {{ invoiceAddresses[id].lastChanged }} by
                {{ invoiceAddresses[id].lastChangedBy }}
              </p>
              <p>Project number in TERP: {{ invoiceAddresses[id].terpId }}</p>
              <p>Comment (Mandatory if Paused)</p>
              <textarea v-model="invoiceAddresses[id].comment"></textarea>
              <h4>Tax organizationnumber:</h4>
              <p>{{ invoiceAddresses[id].taxOrganizationNumber }}</p>
              <h4>Customer legal entity name:</h4>
              <p>{{ invoiceAddresses[id].companyName }}</p>
              <h4>Address Line 1:</h4>
              <p>{{ invoiceAddresses[id].postalAddress }}</p>
              <h4>Postal Code:</h4>
              <p>{{ invoiceAddresses[id].postalCode }}</p>
              <h4>City:</h4>
              <p>{{ invoiceAddresses[id].city }}</p>
              <h4>E-mail address for invoice</h4>
              <p>{{ invoiceAddresses[id].email }}</p>
              <h4>Referens</h4>
              <p>{{ invoiceAddresses[id].reference }}</p>
              <br />
            </section>
          </template>
          <template v-if="handleTask == true && Number.isInteger(id)">
            <div class="input-group--inline">
              <base-button
                class="button--background-gray-dark"
                @click="showNone"
                text="Cancel">
              </base-button>
              <base-button
                class="button--background-red"
                :disabled="setDisabledPauseButton(id)"
                @click="pause(true)"
                :text="
                  'Pause' +
                  (id < invoiceAddresses.length - 1 ? ' and next' : '')
                ">
              </base-button>
              <base-button
                class="button--background-blue"
                :disabled="setDisabledDoneButton(this.id)"
                @click="done(true)"
                :text="
                  'Done' + (id < invoiceAddresses.length - 1 ? ' and next' : '')
                ">
              </base-button>
              <base-button
                class="button--background-blue"
                :disabled="setDisabledWaitButton(this.id)"
                @click="waitingForCrm(true)"
                :text="
                  'Waiting for CRM process' +
                  (id < invoiceAddresses.length - 1 ? ' and next' : '')
                ">
              </base-button>
            </div>
          </template>
          <template v-if="handleTask == false && Number.isInteger(id)">
            <div class="input-group--inline">
              <base-button
                class="button--background-gray-dark"
                @click="showNone"
                text="Cancel">
              </base-button>
              <base-button
                class="button--background-red"
                :disabled="setDisabledPauseButton(this.id)"
                @click="pause(false)"
                :text="'Pause'">
              </base-button>
              <base-button
                class="button--background-blue"
                :disabled="setDisabledDoneButton(this.id)"
                @click="done(false)"
                :text="'Done'">
              </base-button>
              <base-button
                class="button--background-blue"
                :disabled="setDisabledWaitButton(this.id)"
                @click="waitingForCrm(false)"
                :text="'Waiting for CRM process'">
              </base-button>
            </div>
          </template>
        </template>
        <template v-else>
          <section class="todos_top-container">
            <input
              type="text"
              placeholder="Search" />
            <base-button
              text="Handle tasks"
              @click="handleTasks(0)">
            </base-button>
            No of events in list: {{ invoiceAddresses.length }}
          </section>
          <table>
            <thead>
              <th>Id</th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Status"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'status')"
                  :options="filterValues('status')">
                </multi-select>
              </th>
              <th>Club name</th>
              <th>Company name</th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Type"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'type')"
                  :options="filterValues('type')">
                </multi-select>
              </th>
              <th>Project number</th>
              <th>Date</th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Last updated by"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'lastChangedBy')"
                  :options="filterValues('lastChangedBy')">
                </multi-select>
              </th>
              <th></th>
            </thead>
            <tr
              v-for="(invoice, i) in invoiceAddresses"
              v-bind:key="i">
              <td>{{ invoice.id }}</td>
              <td>{{ invoice.status }}</td>
              <td>{{ invoice.clubName }}</td>
              <td>{{ invoice.companyName }}</td>
              <td>{{ invoice.type }}</td>
              <td>{{ invoice.terpId }}</td>
              <td>{{ invoice.lastChanged }}</td>
              <td>{{ invoice.lastChangedBy }}</td>
              <td @click="showInvoice(i, invoice.id)">
                <use-svg
                  svg="arrow-right"
                  class="svg--90-negative" />
              </td>
            </tr>
          </table>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import MultiSelect from '@/components/form-elements/multi-select';
import UseSvg from '@/components/use-svg';

export default {
  name: 'OnTagCourseGuides',
  components: {
    BaseButton,
    MultiSelect,
    UseSvg,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      filter: {
        status: ['Paused', 'Waiting for process in CRM', 'To do'],
        type: [],
        lastChangedBy: [],
      },
      sortBy: 'changedDate',
      handleTask: false,
    };
  },
  computed: {
    _invoiceAddresses() {
      return this.$store.getters['todo/invoiceAddresses'];
    },
    invoiceAddresses() {
      let ia = this._invoiceAddresses;
      for (let f of ['status', 'type', 'lastChangedBy']) {
        if (this.filter[f].length > 0) {
          ia = ia.filter(a => {
            return this.filter[f].includes(a[f]);
          });
        }
      }
      return ia.sort((a, b) =>
        this.sortBy in a
          ? this.sortBy in b
            ? a[this.sortBy] > b[this.sortBy]
              ? 1
              : -1
            : 1
          : -1
      );
    },
    invoiceAddress() {
      return this.$store.getters['todo/invoiceAddress'];
    },
    isLoading() {
      return this.$store.getters['todo/isLoading'];
    },
  },
  created() {
    if (this.id) {
      this.$store.dispatch('todo/getInvoiceAddress', { id: this.id });
    } else {
      this.$store.dispatch('todo/getInvoiceAddresses');
    }
  },
  methods: {
    toggleModal() {
      this.isLoading = !this.isLoading;
    },
    showInvoice(id) {
      this.id = id;
    },
    showNone() {
      this.$store.dispatch('todo/getInvoiceAddresses');
      this.id = false;
      this.handleTask = false;
    },
    waitingForCrm(next) {
      this.$store
        .dispatch('todo/patchInvoiceAddress', {
          id: this.invoiceAddresses[this.id].externalId,
          status: 'Waiting for process in CRM',
          terpId: this.invoiceAddresses[this.id].terpId,
          comment: this.invoiceAddresses[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.id = false;
            this.$store.dispatch('todo/getInvoiceAddresses');
          }
        });
    },
    pause(next) {
      this.$store
        .dispatch('todo/patchInvoiceAddress', {
          id: this.invoiceAddresses[this.id].externalId,
          status: 'Paused',
          terpId: this.invoiceAddresses[this.id].terpId,
          comment: this.invoiceAddresses[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.id = false;
            this.$store.dispatch('todo/getInvoiceAddresses');
          }
        });
    },
    done(next) {
      this.$store
        .dispatch('todo/patchInvoiceAddress', {
          id: this.invoiceAddresses[this.id].externalId,
          status: 'Done',
          terpId: this.invoiceAddresses[this.id].terpId,
          comment: this.invoiceAddresses[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.id = false;
            this.$store.dispatch('todo/getInvoiceAddresses');
          }
        });
    },
    filterValues(prop) {
      return [...new Set(this._invoiceAddresses.map(a => a[prop]))].map(a => {
        return {
          displayName: a,
          value: a,
          checked: this.filter[prop].includes(a),
        };
      });
    },
    filterResults(e, prop) {
      this.filter[prop] = e.options
        .filter(option => option.checked)
        .map(o => o.value);
    },
    handleTasks(id) {
      if (id < this.invoiceAddresses.length - 1) {
        this.id = id;
        this.handleTask = true;
      } else {
        this.handleTask = false;
        this.showInvoice(id);
      }
    },
    setDisabledPauseButton(id) {
      let status = this.invoiceAddresses[id].status.toLowerCase();
      if (
        status === 'cancelled' ||
        status === 'paused' ||
        !this.invoiceAddresses[id].comment
      ) {
        return true;
      }
    },
    setDisabledDoneButton(id) {
      let status = this.invoiceAddresses[id].status.toLowerCase();
      if (this.invoiceAddresses[id].type === 'Changed') {
        return false;
      }
      if (
        status === 'done' ||
        status === 'cancelled' ||
        !this.invoiceAddresses[id].terpId
      ) {
        return true;
      }
    },
    setDisabledWaitButton(id) {
      let status = this.invoiceAddresses[id].status.toLowerCase();
      if (status === 'waiting for process in CRM' || status === 'cancelled') {
        return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.todos_top-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 12px;
  margin-bottom: 12px;
}

table {
  border-collapse: collapse;
  th {
    padding: 0 0.625rem;
    text-align: left;
    background-color: color(blue-light);
  }
  tr {
    &.pointer {
      cursor: auto;
    }
    &:nth-child(even) {
      background-color: color(gray-lighter);
    }
    &.checked {
      background-color: color(blue-light);
    }
  }
  td {
    padding: 0.625rem;
    &:last-child {
      padding-right: 0;
      cursor: pointer;
    }
  }
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
  margin-bottom: 20px;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 20px;
}
.ontag {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.ontag__content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  &.dashboard {
    background-color: transparent;
    border-radius: 0;
  }
}
.input-group--inline {
  button {
    margin: 0.5rem;
  }
}
</style>
