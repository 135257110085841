var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"type-wrapper"},[_c('td',[_c('div',{staticClass:"icon-div"},[_c('div',{on:{"click":function($event){return _vm.showCategorySettings()}}},[_c('use-svg',{attrs:{"svg":"bb-gear-icon-table"}})],1)])]),_c('td',[_c('h3',[_vm._v(_vm._s(_vm.typeName))])]),_c('td',[_c('div',{staticClass:"move-child-center"},[_c('p',[_vm._v(_vm._s(_vm.typeData.aliases.length ? 'Ja' : 'Nej'))])])]),_c('td',[(_vm.typeData.categoryId)?_c('div',{staticClass:"group-label-div"},[_c('span',{staticClass:"group-label"},[_vm._v(" "+_vm._s(_vm.getCategoryReference(_vm.typeData.categoryId))+" ")])]):_vm._e()]),(_vm.typeData.ageGroups.length > 0)?_c('td',{staticClass:"input-and-previous-year"},[_c('p',{staticClass:"move-child-right"},[_vm._v(" "+_vm._s(_vm.typeDataSum(_vm.typeData.ageGroups) + ' st')+" ")]),(_vm.showPreviousYear)?_c('p',{staticClass:"type-row-previous-year-amount"},[_vm._v(" "+_vm._s(_vm.previousYearData !== null ? _vm.previousYearData.quantityBudgeted + ' st' : 0 + ' st')+" ")]):_vm._e()]):_c('td',{staticClass:"input-and-previous-year-budgeted"},[_c('div',{class:{
        'display-budgeted': !_vm.showPreviousYear,
        'display-budgeted-previous-year': _vm.showPreviousYear,
      }},[_c('input',{attrs:{"type":"text","aria-label":"Budgeterat antal","placeholder":_vm.typeData && _vm.typeData.quantityBudgeted
            ? _vm.typeData.quantityBudgeted
            : 0},domProps:{"value":_vm.typeData.quantityBudgeted ? _vm.typeData.quantityBudgeted : 0},on:{"change":event => _vm.updateQuantity(event, _vm.typeData.categoryId)}}),_c('p',{staticClass:"budgeted-input-unit"},[_vm._v("st")])]),_c('div',[(_vm.showPreviousYear)?_c('p',{staticClass:"previous-year-amount"},[_vm._v(" "+_vm._s(_vm.previousYearData !== null ? _vm.previousYearData.quantityBudgeted + ' st' : 0 + ' st')+" ")]):_vm._e()])]),_vm._l((_vm.feeReference),function(fee,index){return _c('td',{key:'total' + index,staticClass:"input-and-previous-year"},[(_vm.typeData.ageGroups.length === 0)?[_c('div',{staticClass:"display-flex"},[(_vm.checkId(fee))?[_c('input',{staticClass:"display-flex",attrs:{"type":"text","aria-label":"Avgifter","placeholder":_vm.findTypeFeeAmount(fee)},domProps:{"value":_vm.findTypeFeeAmount(fee)},on:{"change":event =>
                _vm.updateTypeFeeAmount(event.target.value, _vm.findTypeFeeId(fee))}})]:[_c('input',{staticClass:"display-flex",attrs:{"type":"text","aria-label":"Avgifter","placeholder":0},domProps:{"value":0},on:{"change":event => _vm.updateTypeFeeAmount(event.target.value, fee.id)}})],_c('p',{staticClass:"input-unit"},[_vm._v("kr")])],2),_c('div',[(_vm.showPreviousYear)?_c('p',{staticClass:"previous-year-amount-fees"},[_vm._v(" "+_vm._s(_vm.findTypeFeeAmountFromPreviousYear(fee) + ' kr')+" ")]):_vm._e()])]:_vm._e()],2)}),_c('td')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }