<template>
  <svg
    viewBox="0 -50 100 100"
    width="100%"
    height="100%"
    transform="rotate(-90)">
    <text
      x="0"
      y="-54"
      dominant-baseline="middle"
      text-anchor="middle"
      font-size="14px"
      font-weight="600"
      transform="rotate(90)"
      font-family="SoraRegular">
      {{percentage(sum1, sum2)}}
    </text>
    <path
      :d="'M 85,0 a30,30 0 1,1 0,-0.1'"
      style="fill:none; stroke:#F08565; stroke-width:11.8"/>
    <template v-if="inverse">
      <path
        :d="'M 85,0 a30,30 0 '+arcPosition(percentage2(sum1, sum2)[1], 30)"
        style="fill:none; stroke:#ABDED1; stroke-width:12"/>
      <path
        :d="'M 88,0 a33,33 0 '+arcPosition(percentage2(sum1, sum2)[0], 33)"
        style="fill:none; stroke:#00A99D; stroke-width:12.4"/>
    </template>
    <template v-else>
      <path
        :d="'M 85,0 a30,30 0 '+arcPosition(percentage1(sum1, sum2)[0], 30)"
        style="fill:none; stroke:#ABDED1; stroke-width:12"/>
      <path
        :d="'M 88,0 a33,33 0 '+arcPosition(percentage1(sum1, sum2)[1], 33)"
        style="fill:none; stroke:#00A99D; stroke-width:12.4"/>
    </template>
  </svg>
</template>
<script>
export default {
  props: {
    sum1: {
      type: Number,
      default: 0.0,
    },
    sum2: {
      type: Number,
      default: 0.0,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
  methods: {
    percentage(a, b) {
      let d = a / b;
      if (d < 1) {
        return '-' + Math.round(100 - 100 * d) + '%';
      }
      return '+' + Math.round(100 * d - 100) + '%';
    },
    percentage1(a, b) {
      let d = a / b;
      if (d < 1) {
        return [Math.max(0, 100 * d), 0];
      }
      return [99.99, Math.min(100 * d - 100, 99.99)];
    },
    percentage2(a, b) {
      let d = a / b;
      if (d > 1) {
        return [0, 100 - 100 * d];
      }
      return [100 * d - 100, 99.99];
    },
    arcPosition(p, q) {
      let a = 1;
      let b = 1;
      let c = -0.1;
      let d = 0;

      let r = p / 100 * 2 * Math.PI;
      c = q * Math.cos(r) - q;
      d = q * Math.sin(r);
      if (p === '100' || p === '-100') {
        d = 0.001;
      }
      if (p < 50 && p > -50) {
        a = 0;
      }
      if (p < 0) {
        b = 0;
      }
      return `${a},${b} ${c},${d}`;
    },
  },
};
</script>
