var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer-container"},[_c('div',{class:[
      'last-updated-wrapper',
      { 'last-updated-wrapper_hidden': _vm.showScrollToTop },
    ]},[(_vm.lastUpdate.length > 0)?_c('div',{staticClass:"last-updated"},[_c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"head",on:{"click":function($event){_vm.showLastUpdate = !_vm.showLastUpdate}}},[_c('use-svg',{attrs:{"svg":"bb-update-icon"}}),_c('div',{},[_vm._v(_vm._s(_vm.$t('dataLatestUpdate')))]),_c('div',[_c('use-svg',{class:{ 'svg--180-negative': _vm.showLastUpdate },attrs:{"svg":"arrow-right"}})],1)],1),(_vm.showLastUpdate)?_c('div',{staticClass:"settings"},_vm._l((_vm.filteredLastUpdate),function(update,i){return _c('div',{key:i,staticClass:"setting"},[_c('div',[(update.type < 3)?_c('use-svg',{attrs:{"svg":[
                        'bb-company-icon',
                        'bb-course-icon',
                        'bb-member-green-icon',
                      ][update.type]}}):_vm._e()],1),_c('div',[_vm._v(" "+_vm._s(_vm.formattedUpdateName(update))+" "+_vm._s(_vm.timeFormat(update.lastUpdate))+" ")])])}),0):_vm._e()]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"head",on:{"click":function($event){_vm.showLastUpdate = !_vm.showLastUpdate}}},[_c('div',{},[_vm._v(_vm._s(_vm.$t('dataPeriod')))]),_c('div',[_c('use-svg',{class:{ 'svg--180-negative': _vm.showLastUpdate },attrs:{"svg":"arrow-right"}})],1)]),(_vm.showLastUpdate)?_c('div',{staticClass:"settings"},_vm._l((_vm.filteredLastUpdate),function(update,i){return _c('div',{key:i,staticClass:"setting"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPeriod( update.fromDate, update.toDate, update.type == 2 ))+" ")])])}),0):_vm._e()])]),(_vm.showWarning && _vm.showLastUpdate)?_c('div',{staticClass:"warning-container"},[_c('warning',{attrs:{"message":_vm.isBalanceAccounts
                ? _vm.$t('Businessboard.balanceAccountNotConnectedLong')
                : _vm.$t('Businessboard.accountNotConnectedLong'),"rounded":true}})],1):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }