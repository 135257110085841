var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.row.values.join('_'),staticClass:"drill-down-row",style:({
    backgroundColor: _vm.getBackgroundColor(_vm.indentLevel),
    color: _vm.getTextColor(),
    gridTemplateColumns: `370px repeat(${_vm.row.values.length}, ${
      200 - _vm.indentLevel
    }px)`,
    border: `2px solid ${_vm.getBackgroundColor(_vm.indentLevel)}`,
  }),on:{"click":function($event){$event.stopPropagation();return _vm.toggleChildren.apply(null, arguments)}}},[_c('div',{staticClass:"drill-down-label"},[_c('div',{style:({ marginLeft: `${_vm.indentLevel * 10}px` })},[(_vm.row.children && _vm.row.children.length)?_c('span',{staticClass:"arrow",class:{
          'arrow-down': _vm.showChildren,
          'arrow-white': _vm.row.drillDownType === 1,
        }}):_c('span',{staticClass:"arrow-placeholder"}),_vm._v(" "+_vm._s(_vm.row.label)+" ")])]),_vm._l((_vm.row.values),function(value,index){return _c('div',{key:index,staticClass:"drill-down-value",style:({ gridColumnStart: 2 + index, gridColumnEnd: 3 + index })},[_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")])}),(_vm.showChildren)?_c('div',{staticClass:"children",staticStyle:{"grid-column":"1 / -1"}},_vm._l((_vm.row.children),function(childRow,childIndex){return _c('drill-down-row',{key:`child_${childIndex}`,attrs:{"row":childRow,"indentLevel":_vm.indentLevel + 1}})}),1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }