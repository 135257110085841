<template>
  <div
    class="warning"
    :class="{ 'rounded-corners': rounded }">
    <div class="icon">
      <use-svg
        :height="25"
        :width="25"
        class="warning-icon"
        svg="bb-warning-icon" />
    </div>
    <span class="text">
      {{ message }}
    </span>
  </div>
</template>

<script>
import useSvg from '../use-svg.vue';
export default {
  name: 'Warning',
  components: {
    useSvg,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.warning {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbebdd;
  border: #d73c3c solid 1px;
  padding: 0.4rem;
}

.rounded-corners {
  border-radius: 8px;
}

.warning-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.text {
  text-align: left;
  font-weight: 500;
  color: black;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
</style>
