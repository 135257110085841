<template>
  <div class="ontag">
    <div class="clubspecific-change">
      <div
        class="routeBack"
        @click="routeBack">
        &lt; Tillbaka
      </div>
      <!-- <div class="clubspecific-change__background-container">
      </div> -->
      <div class="clubspecific-change__background"></div>
      <div class="clubspecific-change__info">
        <h1>Skapa eller ändra er scorekortsdesign</h1>
        <p>
          För att göra en ändring på scorekorten behöver vi få in ett
          designförslag. Dvs ungefär hur ni vill det ska se ut. Vi kan hjälpa
          till att ta fram en ny layout / designförslag mot ersättning,
          alternativt att ni själva gör det
        </p>
        <h2 class="clubspecific-change__info-heading">Kostnad</h2>
        <p>
          Kostnaden för ändringen brukar ligga på ca 3 000 - 5 000 kronor ex.
          moms. I det ingår tillverkning av de klichéer som används för tryck av
          era scorekort och är unika för er layout.
        </p>
        <p>
          Från och med 2023 har vi valt att inte erbjuda färg på voucherns
          insida då detta skulle innebära en extra kostnad.
        </p>
        <h2 class="clubspecific-change__info-heading">
          Så här gör du en ändring
        </h2>
        <p>
          För att beställa en ändring skickar ni följande information till oss
          via kontakta oss-funktionen här nedanför:
        </p>
        <ul>
          <li>Antal scorekort</li>
          <li>Önskad leveransvecka</li>
          <li>Beskrivning eller skiss på ändringen</li>
          <li>
            Grafiskt material på nya delar (tex. logga eller bakgrundsbild) i
            hög upplösning, minst 300 dpi i JPG, PDF, EPS eller
            Photoshop-format.
          </li>
        </ul>
        <h2 class="clubspecific-change__info-heading">PPT-mallar</h2>
        <p>
          Innehåller information om scorekortets mått och en mall för din skiss
        </p>
        <div>
          <span>3-delat 110 x 273 mm</span>
          <button class="clubspecific-change__download-button">
            <a
              href="/Mall_3-delat.pptx"
              download="PPT-mall 3-delat">
              <div>PPT-mall 3-delat</div>
              <div class="download-svg"></div>
            </a>
          </button>
        </div>
        <div>
          <span>4-delat 110 x 362 mm</span>
          <button class="clubspecific-change__download-button">
            <a
              href="/Mall_4-delat.pptx"
              download="PPT-mall 4-delat">
              <div>PPT-mall 4-delat</div>
              <div class="download-svg"></div>
            </a>
          </button>
        </div>
        <h2>När kan du senast göra ändringar?</h2>
        <table class="clubspecific-change__week-table">
          <thead>
            <tr>
              <th>LEVERANSVECKA</th>
              <th>DEADLINE FÖR BESTÄLLNING MED ÄNDRAD ELLER NY DESIGN</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="week in deliveryweeks"
              :key="week.id">
              <td>v. {{ week.week }}, {{ week.year }}</td>
              <td>
                {{
                  new Date(week.deadlineForChange).toLocaleDateString('sv-SE')
                }}
              </td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScorecardClubspecificChange',
  methods: {
    routeBack() {
      this.$router.go(-1);
    },
    loadWidget() {
      window.fwSettings = { widget_id: 44000004027 };
      const n = function () {
        n.q.push(arguments);
      };
      n.q = [];
      window.FreshworksWidget = n;
      let fdwidgetScript = document.createElement('script');
      fdwidgetScript.id = 'widgetScript';
      fdwidgetScript.setAttribute(
        'src',
        'https://widget.freshworks.com/widgets/44000004027.js'
      );
      fdwidgetScript.onload = this.fillWidget();
      document.head.appendChild(fdwidgetScript);
    },
    fillWidget() {
      let name = this.user.firstName + ' ' + this.user.lastName;
      window.FreshworksWidget('identify', 'ticketForm', {
        type: '\u00D6vrigt',
        subject:
          'Egendesignade scorekort via GolfOffice' +
          (this.user.club === undefined ? '' : ' från ' + this.user.club.name),
        name: name,
        email: this.user.email,
        custom_fields: {
          cf_klubbnamn: this.user.club === undefined ? '' : this.user.club.name,
        },
      });
      window.FreshworksWidget('hide', 'ticketForm', ['type', 'subject']);
    },
  },

  computed: {
    deliveryweeks() {
      return this.$store.getters['scorecardOrder/deliveryweeks'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
  },
  watch: {
    // if this page is reloaded user object is not loaded yet. wait for it before loading widget
    user() {
      this.loadWidget();
    },
  },
  mounted() {
    this.$store.dispatch('scorecardOrder/fetchDeliveryWeeks');
    if (Object.keys(this.user).length > 0) {
      if (document.getElementById('widgetScript') === null) {
        this.loadWidget();
      } else {
        // the widgetscript has been loaded before, but probably unmounted on destroy
        window.FreshworksWidget('boot');
        window.FreshworksWidget('show');
        this.fillWidget();
      }
    }
  },
  destroyed() {
    // unmount widget when leaving the page
    window.FreshworksWidget('destroy');
    // also remove script?
    // document.getElementById('widgetScript').outerHTML = '';
  },
};
</script>

<style lang="scss" scoped>
.routeBack:hover {
  cursor: pointer;
  font-weight: bold;
}
.content {
  margin: 4.375rem 0 1rem 0;
  @include media('small') {
    margin: 3.3rem 0rem 1rem 15.7rem;
  }
}
.clubspecific-change {
  background-color: color(lightSand);
  padding: 40px 30px;
  position: relative;
  width: 100%;
  &__background {
    background-image: url('../../assets/images/scorecard-clubspecific-example.png');
    background-repeat: no-repeat;
    // background-position: center;
    background-size: cover;
    height: 80vh;
    width: 100vw;
    margin: measure(spacing-l);
    border-radius: 3px;
    // padding: measure(spacing-xl);
    position: absolute;
    @include media('small') {
      width: calc(100vw - 380px);
    }
  }
  &__info {
    position: relative;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 3px;
    max-width: 55%;
    margin: 50px 0 0 70px;
    padding: 55px 70px;
    z-index: 2;

    ul li {
      padding: measure(spacing-xxs) 0;
    }
  }
  &__info-heading {
    margin-bottom: 0;

    + p {
      margin-top: measure(spacing-xxs);
    }
  }
  &__download-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid color(jord);
    border-radius: 3px;
    padding: measure(spacing-xs) measure(spacing-l);
    background-color: color(jord);
    color: white;
    margin: measure(spacing-xxs) measure(spacing-m) measure(spacing-xl) 0;
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    &:active {
      border: 1px solid color(jord);
      box-shadow: none;
    }
    img {
      margin-left: measure(spacing-xs);
    }

    a {
      color: white;
      display: flex;
    }
  }
  &__week-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    background-color: #fafafa;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    thead {
      border-left: measure(spacing-s) solid color(dis);
      border-right: measure(spacing-s) solid color(dis);
      background-color: color(dis);
      font-size: 12px;
      color: color(gray-darkest);
      tr {
        text-align: left;
        :last-child {
          text-align: right;
          max-width: 50px;
        }
        th {
          padding: measure(spacing-m) 0;
        }
      }
    }
    tbody {
      border-left: measure(spacing-s) solid transparent;
      border-right: measure(spacing-s) solid transparent;
      border-bottom: measure(spacing-s) solid transparent;
      font-size: 16px;
      font-weight: bold;

      tr:last-child td {
        border-bottom: none;
        padding: 0px;
      }

      tr {
        :last-child {
          text-align: right;
        }
        td {
          padding: measure(spacing-m) 0;
          border-bottom: 1px solid color(dis);
        }
      }
    }
  }
}
.download-svg {
  margin-left: measure(spacing-xs);
  background-color: white;
  height: 18px;
  width: 18px;
  mask: url(../../assets/images/download.svg) no-repeat center / contain;
  -webkit-mask: url(../../assets/images/download.svg) no-repeat center / contain;
}
</style>
