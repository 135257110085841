<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div>
        <router-link
:to="{ path: '/businessboard/data/budget/' + company_id }">&laquo; {{ $t('back') }}</router-link>
      </div>
      <div class="businessboard--header">
        <span class="h1">{{ $t('Businessboard.createBudget') }}</span>
      </div>

      <div class="wrapper">
        <div class="input-section">
          <div>
            <label for="name">{{ $t('Budget.name') }}</label>
            <input
              id="name"
              type="text"
              v-model="budgetName" />
          </div>
          <div>
            <label for="year">{{ $t('year') }}</label>
            <select
              id="year"
              v-model="financialYear">
              <option
                disabled
                selected
                :value="0">
                {{ $t('Budget.yyyy') }}
              </option>
              <option
                v-for="year in financialYears"
                :key="year.value"
                :value="year.value">
                {{ year.displayName }}
              </option>
            </select>
          </div>
        </div>
        <div class="radio-section">
          <div class="radio-section__opt-container">
            <input
              name="new-budget"
              type="radio"
              :value="0"
              v-model="budgetType" />
            <div>
              <p>
                <span>{{ $t('Budget.blankBudget') }}</span><br />
                <span>{{ $t('Budget.createBlankBudget') }}</span>
              </p>
            </div>
          </div>

          <div class="radio-section__opt-container">
            <input
              name="new-budget"
              type="radio"
              :value="1"
              v-model="budgetType" />
            <div>
              <p>
                <span>{{ $t('Budget.basedOnPrevious') }}</span><br />
                <span>{{ $t('Budget.basedOnPrevious2') }}</span>
              </p>
              <div
                class="budget-select-container"
                v-if="budgetType == 1">
                <span>{{ $t('Budget.budgets') }}</span><br />
                <select
                  v-model="selectedBudget"
                  class="budget-select-input">
                  <option
                    v-for="budget in budgetSelect"
                    :value="budget.value"
                    :key="budget.value">
                    {{ budget.displayName }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="radio-section__opt-container">
            <input
              name="new-budget"
              type="radio"
              :value="2"
              v-model="budgetType" />
            <div>
              <p>
                <span>{{ $t('Budget.basedOnPreviousOutcome') }} </span><br />
                <span>{{ $t('Budget.basedOnPreviousOutcome2') }}</span>
              </p>
              <div
                class="budget-select-container"
                v-if="budgetType == 2">
                <span>{{ $t('year') }}</span><br />
                <select
                  v-model="selectedBudget"
                  class="budget-select-input">
                  <option
                    v-for="year in budgetResultsYearSelect"
                    :key="year.value"
                    :value="year.value">
                    {{ year.displayName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <base-button
          class="button--background-blue"
          :text="$t('create')"
          :disabled="
            budgetName == '' || financialYear == 0 || budgetType === null
          "
          @click="createBudget">
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/form-elements/base-button';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'CreateBudget',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
  },

  data() {
    return {
      budgetName: '',
      budgetType: null,
      financialYear: 0,
      company_id: '',
      selectedBudget: '',
      budgetNames: [],
    };
  },

  methods: {
    createBudget() {
      if (this.budgetNames.includes(this.budgetName)) {
        alert(this.t('Budget.nameExists'));
      } else {
        this.$store
          .dispatch('businessboardBudget/postBudget', {
            companyId: this.company_id,
            name: this.budgetName,
            year: this.financialYear,
            type: this.budgetType,
            id: this.budgetType === 0 ? '' : this.selectedBudget,
          })
          .then(() => {
            this.$router.push({
              path: '/businessboard/data/budget/' + this.company_id,
            });
          });
      }
    },
  },

  computed: {
    createType() {
      return this.$store.getters['businessboardBudget/createType'];
    },
    financialYears() {
      return this.$store.getters['businessboardBudget/financialYears'];
    },
    budgetSelect() {
      return this.$store.getters['businessboardBudget/budgetSelect'];
    },
    budgetResultsYearSelect() {
      return this.$store.getters['businessboardBudget/budgetResultsYearSelect'];
    },
  },
  watch: {
    selectedBudget() {},
  },
  created() {
    this.company_id = this.$route.params.id;
    this.$store.dispatch('businessboardBudget/fetchCreateType');
    this.$store.dispatch('businessboardBudget/fetchFinancialYears');
    this.$store.dispatch('businessboardBudget/fetchBudgetResultsYearSelect');
    this.$store.dispatch('businessboardBudget/fetchBudgetSelect', {
      id: this.company_id,
    });
    this.budgetSelect.forEach(element => {
      this.budgetNames.push(element.displayName.split(',')[0]);
    });
  },
};
</script>

<style lang="scss" scoped>
.businessboard--header {
  margin: 1rem 0 0;
}

.input-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  div {
    display: flex;
    flex-direction: column;

    input,
    select {
      height: 35px;
      margin-right: 20px;
    }
  }
}

.radio-section {
  display: flex;
  flex-direction: column;
  margin-left: 30px;

  &__opt-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;

    input {
      height: 20px;
      width: 20px;
    }

    div {
      margin: 0px 30px;
      p {
        margin: 0;
        :first-child {
          font-weight: bolder;
        }
      }
    }
  }

  .budget-select-container {
    margin: 30px 0;
    .budget-select-input {
      min-width: 300px;
      height: 35px;
    }
  }
}
</style>
