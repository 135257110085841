var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.type === 'textarea')?_c('div',{staticClass:"input"},[_c('label',{class:[
      `${_vm.inlineStyle ? 'input__label label__inline' : 'input__label'}`,
      `first-letter`,
    ],attrs:{"for":_vm.inputId,"data-required-suffix":this.requiredSuffix}},[_vm._v(_vm._s(_vm.label))]),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],ref:"textarea",class:`${
      _vm.inlineStyle ? 'input__textarea input__inline' : 'input__textarea'
    }`,attrs:{"name":_vm.name,"required":_vm.required ? true : false,"placeholder":_vm.placeholder},domProps:{"value":(_vm.value)},on:{"input":function($event){if($event.target.composing)return;_vm.value=$event.target.value}}},'textarea',_vm.$attrs,false))]):(_vm.type === 'checkbox')?_c('div',{staticClass:"input input__checkbox"},[_c('input',_vm._b({ref:"boolvalue",staticClass:"checkbox__input",attrs:{"type":"checkbox","name":_vm.name,"id":_vm.inputId,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"checked":_vm.checked},on:{"click":_vm.checkboxClicked,"input":_vm.input}},'input',_vm.$attrs,false)),_c('label',{staticClass:"checkbox__label",class:{ 'is-active': _vm.checked },attrs:{"for":_vm.inputId,"data-required-suffix":this.requiredSuffix}},[_vm._v(" "+_vm._s(_vm.label)+" ")])]):(_vm.type === 'radio')?_c('div',{staticClass:"input"},[_c('input',{ref:"boolvalue",staticClass:"radio__input",attrs:{"type":"radio","id":_vm.inputId,"disabled":_vm.disabled,"readonly":_vm.readonly,"name":_vm.name},domProps:{"checked":_vm.checked},on:{"click":_vm.radioClicked,"input":_vm.input}}),_c('label',{staticClass:"radio__label",class:{ 'is-active': _vm.checked },attrs:{"for":_vm.inputId,"data-required-suffix":this.requiredSuffix}},[_vm._v(" "+_vm._s(_vm.label)+" ")])]):_c('div',{class:`input ${_vm.cssClasses}`},[_c('label',{class:[
      `${_vm.inlineStyle ? 'input__label label__inline' : 'input__label'}`,
      `first-letter`,
    ],attrs:{"for":_vm.inputId,"data-required-suffix":this.requiredSuffix}},[_vm._v(_vm._s(_vm.label)+" "+_vm._s(this.requiredSuffix ? this.requiredSuffix : ''))]),((_vm.type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.dataValue),expression:"dataValue"}],ref:"textvalue",class:`${_vm.inlineStyle ? 'input__input input__inline' : 'input__input'} ${
      this.hasValue ? 'has-value' : ''
    }`,attrs:{"id":_vm.inputId,"disabled":_vm.disabled,"required":_vm.required,"name":_vm.name,"readonly":_vm.readonly,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.dataValue)?_vm._i(_vm.dataValue,null)>-1:(_vm.dataValue)},on:{"input":_vm.input,"change":function($event){var $$a=_vm.dataValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.dataValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.dataValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.dataValue=$$c}}}},'input',_vm.$attrs,false)):((_vm.type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.dataValue),expression:"dataValue"}],ref:"textvalue",class:`${_vm.inlineStyle ? 'input__input input__inline' : 'input__input'} ${
      this.hasValue ? 'has-value' : ''
    }`,attrs:{"id":_vm.inputId,"disabled":_vm.disabled,"required":_vm.required,"name":_vm.name,"readonly":_vm.readonly,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.dataValue,null)},on:{"input":_vm.input,"change":function($event){_vm.dataValue=null}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.dataValue),expression:"dataValue"}],ref:"textvalue",class:`${_vm.inlineStyle ? 'input__input input__inline' : 'input__input'} ${
      this.hasValue ? 'has-value' : ''
    }`,attrs:{"id":_vm.inputId,"disabled":_vm.disabled,"required":_vm.required,"name":_vm.name,"readonly":_vm.readonly,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.dataValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.dataValue=$event.target.value},_vm.input]}},'input',_vm.$attrs,false))])
}
var staticRenderFns = []

export { render, staticRenderFns }