<template>
  <div class="golf-office">
    <div class="golf-office--content">
      <div class="content__input">
        <template v-if="firstLogin === 'true'">
          <h1>{{ $t('Businessboard.welcome') }}</h1>
          <br />
          <h2>{{ $t('choosePassword') }}</h2>
        </template>
        <template v-else>
          <h3>{{ $t('resetPassword') }}</h3>
        </template>
        <p>{{ $t('passwordText') }}</p>
        <ul>
          <li>{{ $t('passwordRule1') }}</li>
          <li>{{ $t('passwordRule2') }}</li>
          <li>{{ $t('passwordRule3') }}</li>
          <li>{{ $t('passwordRule4') }}</li>
        </ul>
        <div class="content__input-group">
          <input-type
            :label="$t('email')"
            input-id="email"
            v-model="email"
            @keydown.enter="resetPassword"
            autofocus />
          <div
            class="input"
            :class="`${errors.length > 0 ? 'input__error' : ''}`">
            <label
              for="password"
              class="input__label">{{ $t('password') }}*</label>
            <input
              id="password"
              class="input__input"
              v-model="password"
              type="password"
              required="true"
              name="password" />
          </div>
          <div
            class="input"
            :class="`${errors.length > 0 ? 'input__error' : ''}`">
            <label
              for="repeatedPassword"
              class="input__label">{{ $t('confirmPassword') }}*</label>
            <input
              id="repeatedPassword"
              class="input__input"
              v-model="repeatedPassword"
              type="password"
              required="true"
              name="repeatedPassword" />
          </div>
        </div>
        <div v-if="errors.length > 0">
          <p
            v-for="(error, i) in errors"
            :key="i">
            {{ error }}
          </p>
        </div>
        <div class="content__input-actions">
          <base-button
            v-if="firstLogin === 'true'"
            :text="$t('choosePassword')"
            @click="resetPassword"
            v-element-loader="{ loaderKey: 'user-reset-password' }" />
          <base-button
            v-else
            :text="$t('resetPassword')"
            @click="resetPassword"
            v-element-loader="{ loaderKey: 'user-reset-password' }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputType from '@/components/form-elements/input';
import BaseButton from '../components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import { validatePassword } from '@/core/helpers';
export default {
  name: 'ResetPasswordBB',
  components: {
    InputType,
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      email: '',
      password: '',
      repeatedPassword: '',
      token: '',
      firstLogin: {
        type: String,
      },
      errors: [],
    };
  },
  methods: {
    resetPassword() {
      this.errors = [];
      if (this.password === this.repeatedPassword) {
        const validate = validatePassword(this.password);
        if (validate === true) {
          this.$store
            .dispatch('user/resetPassword', {
              email: this.email,
              password: this.password,
              confirmPassword: this.repeatedPassword,
              code: this.token,
            })
            .then(data => {
              if (data.success === true) {
                if (this.firstLogin === 'true') {
                  this.$toasted.success('Password choosen');
                } else {
                  this.$toasted.success('Password restored');
                }
                this.$router.push({ path: '/businessboard' });
              } else {
                this.errors.push(data.message);
              }
            });
        } else {
          this.errors = validate;
        }
      } else {
        this.errors.push(
          'Your new password and confirmed password is not the same'
        );
      }
    },
  },
  created() {
    if (
      this.$router.currentRoute.params &&
      this.$router.currentRoute.params.token
    ) {
      this.token = this.$router.currentRoute.params.token;
    }
    if (this.$route && this.$route.query && this.$route.query.firstLogin) {
      this.firstLogin = this.$route.query.firstLogin;
    }
  },
};
</script>
<style lang="scss" scoped>
.golf-office {
  flex-grow: 1;
}
.golf-office--content {
  background-color: color(bb-primary);
  display: flex;
  flex-direction: column;
  align-items: center; /* add this line */
  padding: 1rem 1.5rem;
  height: 100vh;
  width: 100vw;
  border-radius: 0;
  background-image: url('../assets/images/bb-logo-background.svg');
}
.content__input {
  padding: 5rem;
  align-self: center;
  justify-self: center;
  margin-top: 5rem;
  padding-top: 5.144375rem;
  flex-shrink: 0;
  background-color: #eaf6f6;
  padding-bottom: 9.2875rem;
  border-radius: 0.5rem;
  margin-bottom: 3rem;
  height: 42rem;
  button {
    margin-top: 2rem;
    background-color: color(bb-primary);
  }
}
.content__input-group {
  font-weight: 600;
  .input :last-child {
    width: 25rem;
    height: 1.875rem;
    border-radius: 0.1875rem;
    border: 1px solid #373d3f;
    background: var(--white, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }
}
.colored-text {
  color: #0e3746;
}
</style>
