<template>
  <div class="legend">
    <div class="table">
      <div class="table-divider">
        <h1 class="legend-title">
          {{ $t('comparedTo') }} {{ memberData[0].year }}
        </h1>
        <div
          v-for="(data, index) in memberData"
          :key="`key_${index}`">
          <div
            :class="'table-grid' + index"
            class="table-grid">
            <div class="bold">{{ data.type }}</div>
            <div class="regular right_align">
              {{ data.rounds.toLocaleString('sv-SE') }} {{ unit }}
            </div>
            <div class="right_align">
              <use-svg
                svg="bb-arrow-icon"
                :class="{ 'svg--180-negative': data.value < 0 }" />
            </div>
            <div>
              <template v-if="data.value > 0">+</template>
              {{ data.value.toLocaleString('sv-SE') }} {{ unit }} /
              {{ displayPercentage(data.percentage) }}
            </div>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="table-divider">
        <h1 class="legend-title">
          {{ $t('comparedTo') }} {{ memberData[0].year }}
        </h1>
        <div
          v-for="(data, index) in guestData"
          :key="`key_${index}`">
          <div
            :class="'table-grid' + index"
            class="table-grid">
            <div class="bold">{{ data.type }}</div>
            <div class="regular right_align">
              {{ data.rounds.toLocaleString('sv-SE') }} {{ unit }}
            </div>
            <div class="right_align">
              <use-svg
                svg="bb-arrow-icon"
                :class="{ 'svg--180-negative': data.value < 0 }" />
            </div>
            <div>
              <template v-if="data.value > 0">+</template>
              {{ data.value.toLocaleString('sv-SE') }} {{ unit }} /
              {{ displayPercentage(data.percentage) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
  },
  props: {
    memberData: {
      type: Array,
      default: () => [],
    },
    guestData: {
      type: Array,
      default: () => [],
    },
    normalYear: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: function () {
        this.$t('denomination');
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    displayPercentage(data) {
      if (isNaN(data)) {
        return this.$t('invalidNumberCompare');
      }
      return data.toLocaleString('sv-SE') + '%';
    },
  },
};
</script>
<style lang="scss" scoped>
.legend {
  padding-top: 4rem;
  padding-bottom: 1rem;

  .bold {
    color: #131516;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    width: 150px;
  }

  .regular {
    color: #131516;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  .right_align {
    text-align: right;
    svg {
      margin: 5px 10px 0 0;
    }
  }

  .table {
    display: flex;
    margin: 0.6rem auto;
    font-size: 16px;
    line-height: 26px;

    .row {
      display: flex;
    }

    .help-position {
      margin-top: 1rem;
    }

    .table-divider {
      margin: auto;
    }

    .table-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 2fr;
      padding: 0.5rem;
    }
    .table-grid0 {
      border-bottom: 1px solid #dadedf;
    }
  }

  .legend-title {
    font-size: large;
    text-align: right;
  }

  .border {
    border-left: 1px solid #dadedf;
  }
}
</style>
