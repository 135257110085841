<template>
  <div class="onservice">
    <div class="onservice--content">
      <h1 class="first-letter">{{ $t('systemMessages') }}</h1>
      <section class="terminals--actions">
        <div class="input-group--inline">
          <div class="pagination-amount">
            <span class="first-letter">{{ $t('show') }}</span>
            <custom-select
              v-if="selectedIndexPageSize !== undefined"
              :required="false"
              @optionSelected="setPageSize"
              :selected="selectedIndexPageSize"
              :options="pageSizeOptions" />
            <span class="row-lines">{{ $t('lines') }}</span>
          </div>
          <div class="search-wrapper">
            <div class="input">
              <label
                for="searchQuery"
                class="input__label label__inline first-letter">{{ $t('search') }}</label>
              <input
                id="searchQuery"
                class="input__input input__inline search-input"
                @keyup.enter="getVariatedResults"
                v-model="searchQuery"
                type="text"
                name="searchQuery" />
            </div>
            <base-button
              class="search-button"
              :text="$t('search')"
              :button-size-small="true"
              @click="getVariatedResults" />
            <router-link
              :to="{ path: '/onservice/systemmessages/create-system-message' }">
              <base-button
                class="first-letter"
                :text="$t('createSystemMessage')"
                :button-size-small="true" />
            </router-link>
          </div>
        </div>
      </section>
      <section>
        <list-table
          :items="results.rows"
          :headers="results.columns"
          @variationResults="getVariatedResults"
          :hide-in-mobile="['lastLogin']">
        </list-table>
        <results-pagination
          :first-row-on-page="pagination.firstRowOnPage"
          :last-row-on-page="pagination.lastRowOnPage"
          :row-count="pagination.rowCount"
          :page-size="pagination.pageSize"
          :page-count="pagination.pageCount"
          :current-page="pagination.currentPage"
          :next-page="getNextPage()"
          :prev-page="getPrevPage()"
          store="systemMessages"
          @currentPage="getVariatedResults" />
      </section>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import ResultsPagination from '@/components/results-pagination';
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
export default {
  name: 'OnServiceSystemMessages',
  components: {
    ListTable,
    ResultsPagination,
    BaseButton,
    CustomSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedIndexPageSize: 0,
      searchQuery: '',
      sort: {},
      currentPage: 0,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
    };
  },
  created() {
    this.$store
      .dispatch('onserviceSystemMessages/fetchSystemMessages', {
        pageSize: this.pageSize,
        page: this.currentPage,
      })
      .then(() => {
        this.selectedPageSize(this.pagination.pageSize);
      });
  },
  computed: {
    results() {
      return this.$store.getters['onserviceSystemMessages/systemMessages'];
    },
    pagination() {
      return this.$store.getters['onserviceSystemMessages/pagination'];
    },
  },
  methods: {
    toggleArticleChangePrice(boolean) {
      this.articleChangePrice = boolean;
    },
    toggleArticleChangePriceLoggedOut(boolean) {
      this.articleChangePriceLoggedOut = boolean;
    },
    toggleArticleActive(boolean) {
      this.articleActive = boolean;
    },
    setPageSize(size) {
      // let pageSize = {
      //   pageSize: size.value
      // }
      this.currentPage = 1;
      // this.getVariatedClubResults(pageSize)
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(
        o => parseInt(o.value) === size
      );
      this.selectedIndexPageSize = selectedSize;
    },
    getPrevPage() {
      return this.$store.getters['onserviceSystemMessages/previousPage'];
    },
    getNextPage() {
      return this.$store.getters['onserviceSystemMessages/nextPage'];
    },
    getVariatedResults(value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if (value.page) this.currentPage = value.page;

      this.$store.dispatch('onserviceSystemMessages/fetchSystemMessages', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        pageSize: this.pageSize,
        page: this.currentPage,
      });
    },
  },
};
</script>
<style lang="scss">
.onservice {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.onservice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.terminals--actions {
  .search-wrapper {
    display: flex;

    @include media('to-small') {
      display: block;
    }

    button {
      margin: 0 0 0 0.625rem;
      @include media('to-small') {
        margin: 0;
      }
    }
  }
}
.pagination-amount {
  display: flex;
  align-items: baseline;
  flex: 1;
  .row-lines {
    margin-left: 0.625rem;
  }
}
</style>
