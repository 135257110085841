<template>
  <div class="modal-wrapper">
    <h4>
      {{ $t('MemberCalculation.addAgeGroup') }}
    </h4>
    <div>
      <div class="display-flex distance-bottom">
        <label class="semibold">
          {{ $t('from') }}
        </label>
        <input
          class="input-style"
          id="fromAge"
          v-model="fromAge"
          name="fromAge"
          type="number" />

        <label class="semibold">
          {{ $t('to') }}
        </label>
        <input
          class="input-style"
          id="toAge"
          v-model="toAge"
          name="toAge"
          type="number" />
      </div>
      <div>
        <div class="display-flex">
          <div
            class="select-checkbox"
            @click="applyToAllMemberTypes = !applyToAllMemberTypes">
            <use-svg
              v-if="applyToAllMemberTypes"
              svg="bb-selected-icon" />
          </div>
          <label>
            {{ $t('MemberCalculation.useInAllMemberCategories') }}
          </label>
        </div>
        <base-button
          class="position-create-button"
          backgroundColor="white"
          :text="$t('add')"
          @click="createAgeGroup()">
          <use-svg
            class="plus-icon"
            svg="plus-icon"
            stroke="black" />
        </base-button>
      </div>
    </div>
    <div class="divider" />
    <template v-for="(ageGroup, index) in membershipTypeYears.ageGroups">
      <div :key="index">
        <div class="border">
          <div class="display-flex">
            <p>{{ ageGroup.displayName }} {{ $t('year') }}</p>
            <div class="delete-icon rounded">
              <div
                class="hovering"
                @click="deleteAgeGroup(ageGroup)">
                <use-svg
                  svg="bb-trash-icon"
                  color="white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'manage-age-groups',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    UseSvg,
  },
  props: {},
  data() {
    return {
      feeColumnName: '',
      fromAge: '',
      toAge: '',
      applyToAllMemberTypes: false,
    };
  },
  computed: {
    membershipTypeYears() {
      return this.$store.getters['businessboard/membershipTypeYears'];
    },
  },

  methods: {
    createAgeGroup() {
      this.$store
        .dispatch('businessboard/postMembershipAgeGroup', {
          fromAge: Number(this.fromAge),
          toAge: Number(this.toAge),
          applyToAllMemberTypes: this.applyToAllMemberTypes,
        })
        .then(response => {
          if (response === true) {
            this.$store
              .dispatch('businessboard/fetchMembershipTypes')
              .then(() => {
                this.$store.commit(
                  'businessboard/membershipcalculationinprogress',
                  false
                );
              });
          }
        });
    },
    isAgeGroupInAMembershipcategory(choosenAgeGroup) {
      for (const year of this.membershipTypeYears.years) {
        for (const type of year.types) {
          if (type.ageGroups.length > 0)
            for (const ageGroup of type.ageGroups) {
              if (ageGroup.ageGroupId === choosenAgeGroup.id) {
                return true;
              }
            }
        }
      }
      return false;
    },
    deleteAgeGroup(ageGroup) {
      if (this.isAgeGroupInAMembershipcategory(ageGroup)) {
        alert(
          `Åldersgruppen ${ageGroup.displayName} år används i en medlemskategori och kan därför inte tas bort.`
        );
      } else {
        if (confirm('Vill du ta bort åldersgruppen?')) {
          this.$store.dispatch('businessboard/deleteMembershipAgeGroup', {
            ...ageGroup,
          });
        }
        this.$store.dispatch('businessboard/fetchMembershipTypes').then(() => {
          this.$store.commit(
            'businessboard/membershipcalculationinprogress',
            false
          );
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
}
.display-flex {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;

  width: 100%;
}

.delete-icon {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;
  justify-content: center;
}
.divider {
  margin-bottom: measure(spacing-l);
}
.border {
  border-top: solid 1px #dadedf;
  border-bottom: solid 1px #dadedf;

  width: 100%;
}

.input-style {
  border: solid 1px #000;

  width: 100px;
  border-radius: 4px;
  height: 41px;
}

.rounded {
  padding: measure(spacing-xs);
  margin-left: auto;

  border-radius: 8px;
  border: solid 1px #c1c7c9;

  height: 30px;
}

.hovering {
  &:hover {
    border-radius: 4px;
    background-color: #eee;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  }
}

.semibold {
  font-weight: font-weight(semibold);
}

.distance-bottom {
  margin-bottom: 2rem;
}

.position-create-button {
  margin-left: auto;
  margin-top: measure(spacing-l);
}

.select-checkbox {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 4px;
}

.plus-icon {
  width: 15px;
  height: 15px;
}
</style>
