export const difference = (data1, data2, property) => {
  return (
    data1.months.reduce((acc, v) => acc + v[property], 0) -
    data2.months.reduce((acc, v) => acc + v[property], 0)
  );
};

export const differenceInPercentage = (data1, data2, property) => {
  const v1 = data1.months.reduce((acc, v) => acc + v[property], 0);
  const v2 = data2.months.reduce((acc, v) => acc + v[property], 0);

  var dif = v1 - v2;
  const res = (dif / v2) * 100;
  return res.toLocaleString('sv-SE', { maximumFractionDigits: 0 });
};

export const calculatePercentageDifference = (value1, value2) => {
  const difference = value1 - value2;
  const percentageDifference = (difference / Math.abs(value2)) * 100;
  return percentageDifference.toFixed(2);
};

export const percentageIncrease = (newValue, oldValue) => {
  const increase = ((newValue - oldValue) / oldValue) * 100;
  return increase.toLocaleString('sv-SE', { maximumFractionDigits: 2 });
};

export const summarizeRounds = (data, property) => {
  return data.months.reduce((acc, v) => acc + v[property], 0);
};
