<template>
  <tr class="type-wrapper">
    <td>
      <div class="icon-div">
        <div @click="showCategorySettings()">
          <use-svg svg="bb-gear-icon-table" />
        </div>
      </div>
    </td>
    <td>
      <h3>{{ typeName }}</h3>
    </td>
    <td>
      <div class="move-child-center">
        <p>{{ typeData.aliases.length ? 'Ja' : 'Nej' }}</p>
      </div>
    </td>
    <td>
      <div
        v-if="typeData.categoryId"
        class="group-label-div">
        <span class="group-label">
          {{ getCategoryReference(typeData.categoryId) }}
        </span>
      </div>
    </td>
    <td
      class="input-and-previous-year"
      v-if="typeData.ageGroups.length > 0">
      <p class="move-child-right">
        {{ typeDataSum(typeData.ageGroups) + ' st' }}
      </p>
      <p
        v-if="showPreviousYear"
        class="type-row-previous-year-amount">
        {{
          previousYearData !== null
            ? previousYearData.quantityBudgeted + ' st'
            : 0 + ' st'
        }}
      </p>
    </td>
    <td
      v-else
      class="input-and-previous-year-budgeted">
      <div
        :class="{
          'display-budgeted': !showPreviousYear,
          'display-budgeted-previous-year': showPreviousYear,
        }">
        <input
          type="text"
          :value="typeData.quantityBudgeted ? typeData.quantityBudgeted : 0"
          @change="event => updateQuantity(event, typeData.categoryId)"
          aria-label="Budgeterat antal"
          :placeholder="
            typeData && typeData.quantityBudgeted
              ? typeData.quantityBudgeted
              : 0
          " />
        <p class="budgeted-input-unit">st</p>
      </div>
      <div>
        <p
          v-if="showPreviousYear"
          class="previous-year-amount">
          {{
            previousYearData !== null
              ? previousYearData.quantityBudgeted + ' st'
              : 0 + ' st'
          }}
        </p>
      </div>
    </td>
    <td
      class="input-and-previous-year"
      v-for="(fee, index) in feeReference"
      v-bind:key="'total' + index">
      <template v-if="typeData.ageGroups.length === 0">
        <div class="display-flex">
          <template v-if="checkId(fee)">
            <input
              type="text"
              :value="findTypeFeeAmount(fee)"
              @change="
                event =>
                  updateTypeFeeAmount(event.target.value, findTypeFeeId(fee))
              "
              class="display-flex"
              aria-label="Avgifter"
              :placeholder="findTypeFeeAmount(fee)" />
          </template>
          <template v-else>
            <input
              :value="0"
              type="text"
              @change="event => updateTypeFeeAmount(event.target.value, fee.id)"
              class="display-flex"
              aria-label="Avgifter"
              :placeholder="0" />
          </template>
          <p class="input-unit">kr</p>
        </div>
        <div>
          <p
            v-if="showPreviousYear"
            class="previous-year-amount-fees">
            {{ findTypeFeeAmountFromPreviousYear(fee) + ' kr' }}
          </p>
        </div>
      </template>
    </td>
    <td>
      <!-- empty -->
    </td>
  </tr>
</template>

<script>
import useSvg from '@/components/use-svg';
export default {
  components: {
    useSvg,
  },

  props: {
    typeName: {
      type: String,
      default: '',
    },

    typeData: {
      type: Object,
      default: () => [],
    },
    categoryReference: {
      type: Array,
      default: () => [],
    },

    ageGroupReference: {
      type: Array,
      default: () => [],
    },
    feeReference: {
      type: Array,
      default: () => [],
    },
    previousYearData: {
      type: Object || null,
      default: () => [],
    },
    showPreviousYear: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {};
  },

  methods: {
    checkId(fee) {
      if (this.typeData.fees != null) {
        for (const typeFee of this.typeData.fees) {
          if (typeFee.feeColumnReference === fee.id) {
            return true;
          }
        }
      }
      return false;
    },

    findTypeFeeAmount(fee) {
      for (const typeFee of this.typeData.fees) {
        if (typeFee.feeColumnReference === fee.id) {
          return typeFee.amount;
        }
      }
    },
    findTypeFeeId(fee) {
      for (const typeFee of this.typeData.fees) {
        if (typeFee.feeColumnReference === fee.id) {
          return typeFee.feeColumnReference;
        }
      }
    },
    findTypeFeeAmountFromPreviousYear(fee) {
      return (
        this.previousYearData?.fees?.find(f => f.feeColumnReference === fee.id)
          ?.amount || 0
      );
    },
    getCategoryReference(categoryId) {
      return this.categoryReference.find(r => r.id === categoryId).name;
    },
    getTotalFeeTypeAmount(feeRef, typeData) {
      if (typeData !== null && typeData.length > 0) {
        for (const type of typeData) {
          if (type.feeColumnReference === feeRef.id) {
            return type.amount;
          } else {
            return 0;
          }
        }
      } else {
        return 0;
      }
    },
    updateQuantity(quantity, categoryId) {
      const newQuantity = [
        {
          quantity: Number(quantity.target.value),
          categoryId: categoryId,
          ageGroups: this.typeData.ageGroups,
          aliases: this.typeData.aliases,
          id: this.typeData.id,
        },
      ];

      this.$emit('update-quantity', ...newQuantity);
    },

    updateTypeFeeAmount(amount, feeColumnReference) {
      const newFeeAmount = [
        {
          amount: Number(amount),
          feeColumnReference: feeColumnReference,
          id: this.typeData.id,
          type: 0,
        },
      ];
      this.$emit('update-type-fee-amount', ...newFeeAmount);
    },
    showCategorySettings() {
      this.$store.commit(
        'businessboard/membershipCategorySettingsModalModel',
        this.typeData
      );
    },
    typeDataSum(ageGroups) {
      return ageGroups.reduce((acc, group) => {
        return acc + parseInt(group.quantityBudgeted);
      }, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-div {
  text-align: center;
  > div {
    display: inline-block;
    cursor: pointer;
  }
}

.type-wrapper {
  margin-bottom: 0.2rem;
  padding-bottom: 1rem;
  height: 3rem;

  border-radius: 4px;
  background-color: #f0f5f5;
  border-bottom: 3px solid #fff;
  width: 100%;

  input {
    max-width: 116px;
    border-radius: 4px;
    text-align: right;
  }
}
.move-child-right {
  text-align: right;
  max-width: 116px;
}
.move-child-center {
  text-align: center;
  margin-right: 2rem;
}

.previous-year-amount {
  position: relative;
  margin-right: 1rem;
  margin-top: -0.1rem;
  text-align: right;

  max-width: 116px;
  height: 0px;
  color: #6f7c80;
  font-size: font(small);
}
.previous-year-amount-fees {
  position: relative;
  margin-right: 1rem;
  margin-top: -0.2rem;
  text-align: right;

  max-width: 116px;
  height: 0px;
  color: #6f7c80;
  font-size: font(small);
}

.type-row-previous-year-amount {
  text-align: right;
  margin-top: -1rem;
  display: block;

  max-width: 116px;
  color: #6f7c80;
  font-size: font(small);
  height: 0px;
}

.group-label-div {
  top: 0.5rem;
}

.group-label {
  padding: 3px 10px;
  height: 25px;

  border-radius: 4px;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  font-family: 'HindSiliguri';

  background: #f0f5f5;
  border: 1px solid #27645c;
  color: #0e3746;
}

.display-flex {
  height: 25px;
  display: inline-flex;
  gap: 0.2rem;
  margin-top: -0.4rem;
}
.display-budgeted {
  height: 25px;
  display: inline-flex;
  gap: 0.2rem;
  margin-left: auto;
}

.display-budgeted-previous-year {
  height: 25px;
  position: relative;
  display: inline-flex;
  gap: 0.2rem;
  margin-left: auto;
  top: -0.3rem;
}

.input-unit {
  margin-top: -0.2rem;
}
.budgeted-input-unit {
  margin-top: 0.3rem;
}

.input-and-previous-year {
  align-items: center;
}
</style>
