<template>
  <div class="onservice">
    <div class="onservice--content">
      <router-link
to="/onservice/store/articles">&laquo; {{ $t('back') }}</router-link>
      <h1 v-if="id !== ''">Redigera artikel</h1>
      <h1 v-else>Skapa artikel</h1>
      <section class="article">
        <input-type
          :label="$t('name')"
          type="text"
          ref="articleName"
          input-id="articleName"
          :required="true" />
        <div class="input">
          <label
            for="article-category"
            class="input__label first-letter">{{ $t('category') }} *</label>
          <custom-select
            v-if="selectedIndex !== null"
            id="role"
            @optionSelected="e => selectOption(e)"
            :required="true"
            :selected="selectedIndex"
            :options="select">
          </custom-select>
        </div>
        <input-type
          :label="$t('price')"
          type="number"
          ref="articlePrice"
          input-id="articlePrice"
          :required="true" />
        <input-type
          :label="`${$t('vat')} (%)`"
          type="number"
          ref="articleVat"
          input-id="articleVat"
          :required="true" />
        <div class="input-group input--flex">
          <label
            for="articleChangePrice"
            class="input__label">Ändra pris</label>
          <toggle-switch
            id="articleChangePrice"
            name="articleChangePrice"
            input-id="articleChangePrice"
            type="switch"
            :toggled="articleChangePrice"
            @toggle="toggleArticleChangePrice" />
        </div>
        <div class="input-group input--flex">
          <label
            for="articleChangePricePublic"
            class="input__label">Ändra pris ej inloggad</label>
          <toggle-switch
            id="articleChangePricePublic"
            name="articleChangePricePublic"
            input-id="articleChangePricePublic"
            type="switch"
            :toggled="articleChangePricePublic"
            @toggle="toggleArticleChangePricePublic" />
        </div>
        <div
          class="input-group input--flex"
          v-if="isGreenFee">
          <label
            for="articleEntitlesScorecard"
            class="input__label first-letter">{{ $t('entitles scorecard') }}</label>
          <toggle-switch
            id="articleEntitlesScorecard"
            name="articleEntitlesScorecard"
            input-id="articleEntitlesScorecard"
            type="switch"
            :toggled="articleEntitlesScorecard"
            @toggle="toggleArticleEntitlesScorecard" />
        </div>
        <div class="input-group input--flex">
          <label
            for="article-active"
            class="input__label">Aktiv</label>
          <toggle-switch
            id="articleActive"
            name="articleActive"
            input-id="articleActive"
            type="switch"
            :toggled="articleActive"
            @toggle="toggleArticleActive" />
        </div>
        <div class="input-group">
          <div class="input checkbox">
            <div class="checkbox--group">
              <input
                type="checkbox"
                id="articleActiveMonday"
                name="articleActiveMonday"
                v-model="articleActiveMonday"
                @click="toggleActiveDay()" />
              <label for="articleActiveMonday">
                <span>
                  <span></span>
                </span>
                <p class="first-letter">{{ $t('mon') }}</p>
              </label>
              <input
                type="checkbox"
                id="articleActiveTuesday"
                name="articleActiveTuesday"
                v-model="articleActiveTuesday"
                @click="toggleActiveDay()" />
              <label for="articleActiveTuesday">
                <span>
                  <span></span>
                </span>
                <p class="first-letter">{{ $t('tue') }}</p>
              </label>
              <input
                type="checkbox"
                id="articleActiveWednesday"
                name="articleActiveWednesday"
                v-model="articleActiveWednesday"
                @click="toggleActiveDay()" />
              <label for="articleActiveWednesday">
                <span>
                  <span></span>
                </span>
                <p class="first-letter">{{ $t('wed') }}</p>
              </label>
              <input
                type="checkbox"
                id="articleActiveThursday"
                name="articleActiveThursday"
                v-model="articleActiveThursday"
                @click="toggleActiveDay()" />
              <label for="articleActiveThursday">
                <span>
                  <span></span>
                </span>
                <p class="first-letter">{{ $t('thur') }}</p>
              </label>
              <input
                type="checkbox"
                id="articleActiveFriday"
                name="articleActiveFriday"
                v-model="articleActiveFriday"
                @click="toggleActiveDay()" />
              <label for="articleActiveFriday">
                <span>
                  <span></span>
                </span>
                <p class="first-letter">{{ $t('fri') }}</p>
              </label>
              <input
                type="checkbox"
                id="articleActiveSaturday"
                name="articleActiveSaturday"
                v-model="articleActiveSaturday"
                @click="toggleActiveDay()" />
              <label for="articleActiveSaturday">
                <span>
                  <span></span>
                </span>
                <p class="first-letter">{{ $t('sat') }}</p>
              </label>
              <input
                type="checkbox"
                id="articleActiveSunday"
                name="articleActiveSunday"
                v-model="articleActiveSunday"
                @click="toggleActiveDay()" />
              <label for="articleActiveSunday">
                <span>
                  <span></span>
                </span>
                <p class="first-letter">{{ $t('sun') }}</p>
              </label>
              <div class="vr">&nbsp;</div>
              <input
                type="checkbox"
                id="articleActiveAll"
                name="articleActiveAll"
                v-model="articleActiveAll"
                @click="toggleAllActive" />
              <label for="articleActiveAll">
                <span>
                  <span></span>
                </span>
                <p class="first-letter">{{ $t('all days') }}</p>
              </label>
            </div>
          </div>
        </div>
      </section>
      <section class="article--actions">
        <base-button
          :text="$t('cancel')"
          class="button--background-gray-dark"
          @click="$router.push('/onservice/store/articles')" />
        <base-button
          v-if="id !== ''"
          text="Ta bort"
          class="button--background-red"
          @click="deleteArticle" />
        <base-button
          text="Spara"
          @click="saveArticle" />
      </section>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import ToggleSwitch from '@/components/form-elements/switch';
import InputType from '@/components/form-elements/input';
export default {
  name: 'OnServiceArticle',
  components: {
    BaseButton,
    CustomSelect,
    ToggleSwitch,
    InputType,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    categoryId: {
      type: String,
      default: '',
    },
    selected: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectLoaded: null,
      selectedIndex: null,
      selectedCategory: null,
      articleCategoryId: null,
      articleChangePrice: false,
      articleChangePricePublic: false,
      articleEntitlesScorecard: false,
      articleActive: false,
      articleActiveMonday: false,
      articleActiveTuesday: false,
      articleActiveWednesday: false,
      articleActiveThursday: false,
      articleActiveFriday: false,
      articleActiveSaturday: false,
      articleActiveSunday: false,
      articleActiveAll: false,
      isGreenFee: false,
    };
  },
  computed: {
    select() {
      return this.$store.getters['articles/categories'];
    },
  },
  mounted() {
    if (this.id !== '') {
      this.$store.dispatch('articles/fetchArticle', this.id).then(() => {
        this.setupArticle();
        this.setupCategory();
      });
    } else {
      this.setupCategory();
    }
  },
  methods: {
    selectOption(e) {
      this.selectedCategory = e.id;
    },
    toggleArticleChangePrice(boolean) {
      this.articleChangePrice = boolean;
    },
    toggleArticleChangePricePublic(boolean) {
      this.articleChangePricePublic = boolean;
    },
    toggleArticleEntitlesScorecard(boolean) {
      this.articleEntitlesScorecard = boolean;
    },
    toggleArticleActive(boolean) {
      this.articleActive = boolean;
    },
    toggleAllActive() {
      this.articleActiveAll = !this.articleActiveAll;
      this.articleActiveMonday =
        this.articleActiveTuesday =
        this.articleActiveWednesday =
        this.articleActiveThursday =
        this.articleActiveFriday =
        this.articleActiveSaturday =
        this.articleActiveSunday =
          this.articleActiveAll;
    },
    toggleActiveDay() {
      if (this.articleActiveAll === true) {
        this.articleActiveAll = false;
      }
    },
    setupArticle() {
      const article = this.$store.getters['articles/article'](this.id);
      this.articleCategoryId = article.categoryId;
      this.$refs.articleName.dataValue = article.name;
      this.$refs.articlePrice.dataValue = article.price;
      this.$refs.articleVat.dataValue = article.vat;
      this.articleChangePrice = article.changePrice;
      this.articleChangePricePublic = article.changePricePublic;
      this.articleEntitlesScorecard = article.entitlesScorecard;
      this.articleActive = article.active !== 0;
      this.articleActiveMonday = article.activeMonday;
      this.articleActiveTuesday = article.activeTuesday;
      this.articleActiveWednesday = article.activeWednesday;
      this.articleActiveThursday = article.activeThursday;
      this.articleActiveFriday = article.activeFriday;
      this.articleActiveSaturday = article.activeSaturday;
      this.articleActiveSunday = article.activeSunday;
      if (
        this.articleActiveMonday &&
        this.articleActiveTuesday &&
        this.articleActiveWednesday &&
        this.articleActiveThursday &&
        this.articleActiveFriday &&
        this.articleActiveSaturday &&
        this.articleActiveSunday
      ) {
        this.articleActiveAll = true;
      }
    },
    setupCategory() {
      this.selectedIndex = this.selected;
      if (this.id !== '') {
        this.selectedIndex = this.select.findIndex(
          c => c.id === this.articleCategoryId
        );
      }
      this.selectedCategory = this.categoryId
        ? this.categoryId
        : this.articleCategoryId;
      const category = this.$store.getters['articles/category'](
        this.selectedCategory
      );
      this.isGreenFee = this.select.find(
        c => c.id === this.selectedCategory
      ).isGreenFee;
      this.$refs.articleVat.dataValue = category.vat;
    },
    saveArticle() {
      let params = {
        name: this.$refs.articleName.dataValue,
        categoryId: this.selectedCategory,
        price: this.$refs.articlePrice.dataValue,
        vat: this.$refs.articleVat.dataValue,
        changePrice: this.articleChangePrice,
        changePricePublic: this.articleChangePricePublic,
        active: this.articleActive,
        activeMonday: this.articleActiveMonday,
        activeTuesday: this.articleActiveTuesday,
        activeWednesday: this.articleActiveWednesday,
        activeThursday: this.articleActiveThursday,
        activeFriday: this.articleActiveFriday,
        activeSaturday: this.articleActiveSaturday,
        activeSunday: this.articleActiveSunday,
        entitlesScorecard: this.articleEntitlesScorecard,
      };
      if (this.id !== '') {
        this.$store
          .dispatch('articles/editArticle', { id: this.id, params: params })
          .then(boolean => {
            if (boolean === true) {
              this.$toasted.success('Article was updated');
              this.$router.push('/onservice/store/articles');
            }
          });
      } else {
        this.$store.dispatch('articles/createArticle', params).then(boolean => {
          if (boolean === true) {
            this.$toasted.success('Article was created');
            this.$router.push('/onservice/store/articles');
          }
        });
      }
    },
    deleteArticle() {
      this.$store
        .dispatch('articles/deleteArticle', {
          id: this.id,
          categoryId: this.selectedCategory,
        })
        .then(boolean => {
          if (boolean === true) {
            this.$toasted.success('Article was deleted');
            this.$router.push('/onservice/store/articles');
          }
        });
    },
  },
};
</script>
<style lang="scss">
.onservice {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.onservice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.article--actions {
  display: flex;
  margin-left: auto;
}
.article--actions button:not(:first-child) {
  margin-left: 1rem;
}
.article {
  p.first-letter {
    display: block;
    height: 1.375rem;
    margin: 0.25rem 0.5rem 0.25rem;
  }
}
</style>
