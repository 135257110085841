<script>
import VueTypes from 'vue-types';

import { HorizontalBar } from 'vue-chartjs';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
export default {
  extends: HorizontalBar,
  mixins: [LocalizationMixin],
  props: {
    labels: VueTypes.arrayOf(VueTypes.string),
    data: VueTypes.shape({
      currentYear: VueTypes.arrayOf(VueTypes.number),
      lastYear: VueTypes.arrayOf(VueTypes.number),
    }),
    tooltips: VueTypes.shape({
      currentYear: VueTypes.arrayOf(VueTypes.string),
      lastYear: VueTypes.arrayOf(VueTypes.string),
    }),
  },
  data: function () {
    return {
      chartdata: {
        labels: this.labels,
        datasets: [
          {
            label: this.$t('currentYear2'),
            backgroundColor: '#27645C',
            data: this.data.currentYear,
            tooltips: this.tooltips.currentYear,
            categoryPercentage: 0.5,
          },
          {
            label: this.$t('previousYear'),
            backgroundColor: '#9CE0D1',
            data: this.data.lastYear,
            tooltips: this.tooltips.lastYear,
            categoryPercentage: 0.5,
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label +=
                data.datasets[tooltipItem.datasetIndex].tooltips[
                  tooltipItem.index
                ];
              return label;
            },
          },
        },
        maintainAspectRatio: false,
      },
    };
  },

  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
