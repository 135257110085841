<template>
  <div :class="['te-select', 'te-select-' + size]">
    <label
      v-if="this.title"
      :for="name">{{ this.title }}</label>
    <div class="te-select-field-wrapper">
      <slot></slot>
      <span
        class="te-select-arrow-icon tietoevry-icons-arrow-small-down"></span>
    </div>
    <div class="te-select-error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'TeselectField',
  props: {
    title: {
      type: String,
    },
    size: {
      type: String,
      default: 'sm',
    },
    error: {
      type: String,
    },
  },
};
</script>
<style lang="scss">
.te-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  gap: 4px;
  align-self: stretch;
  label {
    color: var(--text-text-primary, #1e2224);
    /* Heading 5 */
    font-family: 'Tietoevry sans 1';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  .te-select-field-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    width: 100%;
    position: relative;
    background: var(--surface-surface-form-default, #fff);
  }
  .te-select-arrow-icon {
    pointer-events: none;
    font-size: 20px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  select {
    all: unset;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    padding: 8px;
    overflow: hidden;
    color: var(--text-text-secondary, #313538);
    font-family: 'Tietoevry sans 1';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    border-radius: 4px;
    outline: 1px solid var(--dividers-borders-border-selector-primary, #a9aaac);
    background: transparent;
  }
  &.te-select-sm select {
    padding: 6px;
  }
  &.te-select-md select {
    padding: 8px;
  }
  &.te-select-lg select {
    padding: 10px;
  }
  select::after {
    font-family: 'tietoevry-icons';
    content: '\ea22';
    color: #1e2224;

    width: 20px;
    height: 20px;
  }
  select::placeholder {
    color: var(--text-text-tertiary, #64676a);
  }
  select:hover:not([disabled]):not(:invalid),
  select:focus-visible:not([disabled]):not(:invalid) {
    outline-color: var(--dividers-borders-border-selector-hover, #1861f2);
  }
  select:focus-visible:not([disabled]):not(:invalid) {
    box-shadow: 0px 0px 0px 4px rgba(24, 97, 242, 0.4);
  }
  select:active:not([disabled]) {
    outline-color: var(--blue-blue-2, #0c4fd4);
  }
  select:disabled {
    opacity: 0.4;
  }
  select:invalid {
    outline-color: var(--surface-surface-form-warning, rgba(216, 47, 51, 1));
  }
  select:focus-visible:invalid:not([disabled]) {
    box-shadow: 0px 0px 0px 4px rgba(216, 47, 51, 0.4);
  }
  .te-select-error {
    color: var(--surface-surface-form-warning, #d82f33);
    /* Paragraph text */
    font-family: 'Tietoevry sans 1';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .te-select-error {
    display: none;
  }
  .te-select-field:invalid + .te-select-error:not(:empty) {
    display: block;
  }
}
</style>
