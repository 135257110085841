import { render, staticRenderFns } from "./data.vue?vue&type=template&id=7f2c1727&scoped=true"
import script from "./data.vue?vue&type=script&lang=js"
export * from "./data.vue?vue&type=script&lang=js"
import style0 from "./data.vue?vue&type=style&index=0&id=7f2c1727&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f2c1727",
  null
  
)

export default component.exports