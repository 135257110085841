<template>
  <div class="legend">
    <div
      class="categories"
      v-for="(category, i) in categories"
      :key="i">
      <div class="bold">
        <div></div>
        <div>{{ category.name }}</div>
      </div>
      <div class="regular">
        <div></div>
        <div>{{ category.value.toLocaleString('sv-SE') }} {{ unit }}</div>
      </div>
      <div class="row regular">
        <div>
          <use-svg
            svg="bb-arrow-icon"
            :class="{ 'svg--180-negative': category.percentage < 0 }" />
        </div>
        <div>
          <template v-if="category.comparison.value > 0">+</template>
          {{ category.comparison.toLocaleString('sv-SE') }} {{ unit }} /
          <template v-if="category.percentage > 0">+</template>
          {{ category.percentage.toLocaleString() }}%
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UseSvg from '@/components/use-svg';
import LocalizationMixin from '../../../mixins/localization-mixin.vue';
export default {
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: function () {
        this.$t('denomination');
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.legend {
  margin: 1rem 1rem 0 1rem;
  color: #131516;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  .bold {
    font-weight: 700;
  }

  .regular {
    font-weight: 400;
    text-align: right;
  }
  .categories {
    margin: 0.6rem auto;
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    flex-wrap: wrap;
    border-bottom: 1px solid #dadedf;
    padding: 0.5rem;
    .row {
      display: flex;
      justify-self: end;
      width: 9.8rem;
      svg {
        display: block;
        margin: auto;
        margin-right: 0.5rem;
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
