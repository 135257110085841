<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <h1>{{ $t('Businessboard.addGroup') }}</h1>
      <form
        noValidate
        @submit.prevent="createGroup">
        <div class="content__input-group">
          <div class="input-group">
            <div class="input">
              <label
                for="displayName"
                class="input__label">{{ $t('Businessboard.displayName') }}*</label>
              <input
                id="displayName"
                class="input__input"
                v-model="displayName"
                type="text"
                required="true"
                name="displayName" />
            </div>
            <div class="input">
              <label
                for="groupName"
                class="input__label">{{ $t('Businessboard.groupName') }}*</label>
              <input
                id="groupName"
                class="input__input"
                v-model="groupName"
                type="text"
                required="true"
                name="groupName" />
            </div>
            <div
              v-if="!this.costGroup && !hasEconomicLicense"
              class="resultgroup-select">
              <custom-select
                :label="$t('Businessboard.resultGroup')"
                id="group"
                @optionSelected="selectOption"
                :required="false"
                :options="resultgroupinclude">
              </custom-select>
            </div>
            <div
              v-if="this.costGroup && !hasEconomicLicense"
              class="resultgroup-select">
              <label class="input__label">{{
                $t('Businessboard.resultGroup')
              }}</label>
              <toggle-switch
                input-id="includedInCalculation"
                type="switch"
                :toggled="this.included"
                @toggle="includedInCalculation" />
            </div>
          </div>
          <div class="input-group--inline user__actions">
            <router-link to="/businessboard/data">
              <base-button :text="$t('cancel')"> </base-button>
            </router-link>
            <base-button
              :text="$t('save')"
              type="submit"
              v-element-loader="{ loaderKey: 'create-group' }"
              :disabled="!canCreate" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select';
import elementLoader from '@/directives/element-loader';
import ToggleSwitch from '@/components/form-elements/switch';
import { BusinessBoardVersion } from '@/core/consts';

export default {
  name: 'EditGroup',
  components: {
    BaseButton,
    CustomSelect,
    ToggleSwitch,
  },
  directives: {
    elementLoader,
  },
  props: {
    costGroup: {
      type: Boolean,
    },
  },
  data() {
    return {
      groupName: '',
      resultGroup: '',
      displayName: '',
      included: true,
    };
  },
  methods: {
    createGroup() {
      this.$store
        .dispatch('businessboard/createGroup', {
          name: this.groupName,
          costGroup: this.costGroup,
          includedInCalculation: this.resultGroup,
          displayName: this.displayName,
        })
        .then(() => {
          this.$router.push({ path: '/businessboard/data' });
        });
    },
    selectOption(e) {
      this.resultGroup = e.value;
    },
    includedInCalculation(boolean) {
      this.included = boolean;
      this.resultGroup = boolean;
    },
  },
  computed: {
    resultgroupinclude() {
      return this.$store.getters['businessboard/resultgroupinclude'];
    },
    canCreate() {
      return this.groupName;
    },
    businessBoardSettings() {
      return this.$store.getters['businessboard/settings'];
    },
    hasEconomicLicense() {
      if (!this.businessBoardSettings) {
        return false;
      }
      return (
        this.businessBoardSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicBasic ||
        this.businessBoardSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicPlus
      );
    },
  },
  created() {
    if (this.costGroup) {
      this.resultGroup = true;
      this.included = true;
    } else {
      this.$store.dispatch('businessboard/fetchResultgroupInclude');
    }
  },
};
</script>
<style lang="scss" scoped>
.golfoffice {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.golfoffice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  .content__input-group {
    width: 100%;
    @include media('medium') {
      width: calc((100% / 1.2) - (1rem * 2));
    }
    .input__label {
      width: 100%;
    }
    .user__actions {
      justify-content: flex-start;
      @include media('small') {
        justify-content: flex-end;
        // width: 70%;
      }

      a button {
        margin-left: 0;
      }
    }
    .resultgroup-select button {
      margin-left: 0;
    }
  }
}
</style>
