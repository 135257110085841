var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{key:_vm.discountType + _vm.index},[(_vm.discountType && _vm.discountType.length > 0 && _vm.discountType[_vm.index])?[_c('div',{staticClass:"display-flex"},[_c('input',{staticClass:"input_style",attrs:{"type":"text","aria-label":"Rabatt","placeholder":_vm.discountType[_vm.index].amount},domProps:{"value":_vm.discountType[_vm.index].amount ? _vm.discountType[_vm.index].amount : 0},on:{"change":event =>
            _vm.updateDiscountAmount(
              event,
              _vm.discountType[_vm.index].feeColumnReference
            )}}),_c('p',{staticClass:"input-unit"},[_vm._v("kr")])]),_c('div',[(
          _vm.showPreviousYear &&
          _vm.previousYearDiscount.length > 0 &&
          _vm.previousYearDiscount[_vm.index]
        )?_c('p',{staticClass:"previous-year-amount"},[_vm._v(" "+_vm._s(_vm.previousYearDiscount[_vm.index].amount)+" kr ")]):(_vm.showPreviousYear)?_c('p',{staticClass:"previous-year-amount"},[_vm._v(" "+_vm._s(0)+" kr ")]):_vm._e()])]:[_c('div')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }