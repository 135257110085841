<template>
  <div>
    <div class="action-buttons-div">
      <base-button
        background-color="bb-primary"
        :text="$t('Businessboard.settings')"
        @click="isOpen = true">
        <use-svg svg="bb-gear-icon" />
      </base-button>
    </div>
    <div
      v-if="isOpen"
      class="modal--wrapper">
      <div class="modal--header">
        <p class="breadcrumb">
          {{ $t('groupsForBalanceReport') }}
        </p>
      </div>
      <div class="modal--content">
        <div class="modal--body">
          <h3>
            {{ $t('editBalanceGroups') }}
          </h3>
          <div class="header">
            <div></div>
            <div>
              {{ $t('group') }}
            </div>
            <div>{{ $t('belongsToCategory') }}</div>
            <div>
              {{ $t('hide') }}
            </div>
          </div>
          <draggable
            v-model="groups"
            @change="changeOrder"
            @start="drag = true"
            @end="drag = false">
            <div
              v-for="group in groups"
              :key="group.id"
              class="row">
              <div class="dragIcon"></div>
              <input
                class="text-input"
                type="text"
                :value="group.name"
                @change="e => changeName(group, e)" />
              <div style="width: 10rem">
                {{ group.balanceAccountCategoryName }}
              </div>
              <toggle
                :checked="group.hide"
                :disabled="group.balanceAccounts.length > 0"
                :tooltip="group.hide ? $t('excluded') : $t('included')"
                @toggle="changeHide(group)" />
            </div>
          </draggable>

          <div class="buttons">
            <base-button
              backgroundColor="bb-primary"
              :text="$t('done')"
              @click="isOpen = false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import Toggle from '@/components/businessboard/toggle';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import draggable from 'vuedraggable';

export default {
  name: 'modal-membership-types-settings',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    UseSvg,
    Toggle,
    draggable,
  },
  props: {},

  data() {
    return {
      newGroupName: '',
      drag: false,
      isOpen: false,
    };
  },
  watch: {},
  computed: {
    groups: {
      get() {
        return this.$store.getters['BusinessBoardBalanceSheet/groups'];
      },
      set(val) {
        this.$store.state.BusinessBoardBalanceSheet.groups = val;
      },
    },
  },
  methods: {
    addGroup(name) {},
    changeName(item, e) {
      item.name = e.target.value;
      this.patchItem(item);
    },
    changeHide(item) {
      item.hide = !item.hide;
      this.patchItem(item);
    },
    changeOrder: function (e) {
      let item = e.moved.element;
      item.order = e.moved.newIndex;
      this.patchItem(item);
    },
    deleteGroup(id) {},
    patchItem(group) {
      this.$store.dispatch('BusinessBoardBalanceSheet/patchGroup', group);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.modal--wrapper {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 50rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;
  border-radius: 8px;
  max-height: 100%;
  overflow-y: scroll;
}

.modal--content {
  width: 45rem;
  margin-left: 2rem;
}
.modal--header {
  background-color: #fff;
  margin-left: 2rem;
}
.modal--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #131516;
}
.breadcrumb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7c80;
}
.delete-icon {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;
  justify-content: center;
}
.rounded {
  padding: measure(spacing-xs);
  margin-left: auto;

  border-radius: 8px;
  border: solid 1px #c1c7c9;

  height: 30px;
}
.buttons {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.2rem;
  padding: 0.3rem;
  input {
    border: solid 1px #000;
    border-radius: 3px;
    padding: 0.4rem 0.6rem;
    width: 15rem;
  }
  div {
    margin: 0.2rem;
    margin-right: 3rem;
  }
}
.row:first-child {
  border-top: solid 1px #dadedf;
}
.row {
  border-bottom: solid 1px #dadedf;
}
.dragIcon {
  display: inline-block;
  cursor: move;
  width: 16.622px;
  height: 6.244px;
  border-top: solid 1px #dadedf;
  border-bottom: solid 1px #dadedf;
}
.header {
  font-weight: bold;
  display: grid;
  grid-auto-columns: 5.3rem 15.6rem 13.7rem 4rem;
  grid-auto-flow: column;
}
</style>
