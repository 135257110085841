<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <h1>{{ $t('Businessboard.addFeeColumn') }}</h1>
      <form noValidate @submit.prevent="createFeeColumn">
        <div class="content__input-group">
          <div class="input-group">
            <div class="input">
              <label
                for="feeColumnName"
                class="input__label">{{ $t('Businessboard.feeColumnName') }}*</label>
              <input
                id="feeColumnName"
                class="input__input"
                v-model="feeColumnName"
                type="text"
                required="true"
                name="feeColumnName"/>
            </div>
            <!-- <div class="exclude-select">
              <p>Exkludera</p>
              <select v-model="selectedOption">
                <option :value="true">Ja</option>
                <option :value="false">Nej</option>
              </select>
            </div> -->
          </div>
          <div class="input-group--inline user__actions">
            <router-link to="/businessboard/data">
              <base-button
              :text="$t('cancel')">
              </base-button>
            </router-link>
              <base-button
                :text="$t('save')"
                type="submit"
                v-element-loader="{ loaderKey: 'create-feecolumn' }"
                :disabled="!canCreate"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'EditFeeColumn',
  components: {
    BaseButton,
  },

  directives: {
    elementLoader,
  },

  data() {
    return {
      feeColumnName: '',
    };
  },

  methods: {
    createFeeColumn() {
      this.$store.dispatch('businessboard/createMembershipFeeColumn', {
        name: this.feeColumnName,
      }).then(() => {
        this.$router.push({ path: '/businessboard/data' });
      });
    },
  },

  computed: {
    canCreate() {
      return this.feeColumnName;
    },
  },
};

</script>

<style lang="scss" scoped>
.exclude-select {
  width: 100%;

  p {
    margin-bottom: 3.2px;
  }
  select {
    width: 100%;
    border-color: color(gray);
    height: 33px;
    background-color: color(gray-lighter);
  }
}
</style>
