/* Store module to handle todo-menu */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  invoiceAddresses: [],
  invoiceAddress: {},
  combinedTodos: [],
  combinedTodo: {},
  costTodos: [],
  costTodo: {},
  fundingTodos: [],
  fundingTodo: {},
  isLoading: false,
};

const mutations = {
  invoiceAddresses(state, payload) {
    state.invoiceAddresses = payload;
  },
  invoiceAddress(state, payload) {
    state.invoiceAddress = payload;
  },
  combinedTodos(state, payload) {
    state.combinedTodos = payload;
  },
  combinedTodo(state, payload) {
    state.combinedTodo = payload;
  },
  costTodos(state, payload) {
    state.costTodos = payload;
  },
  costTodo(state, payload) {
    state.costTodo = payload;
  },
  fundingTodos(state, payload) {
    state.fundingTodos = payload;
  },
  fundingTodo(state, payload) {
    state.fundingTodo = payload;
  },
  isLoading(state, payload) {
    state.isLoading = payload;
  },
};

const getters = {
  invoiceAddresses: state => {
    return state.invoiceAddresses;
  },
  invoiceAddress: state => {
    return state.invoiceAddress;
  },
  combinedTodos: state => {
    return state.combinedTodos;
  },
  combinedTodo: state => {
    return state.combinedTodo;
  },
  costTodos: state => {
    return state.costTodos;
  },
  costTodo: state => {
    return state.costTodo;
  },
  fundingTodos: state => {
    return state.fundingTodos;
  },
  fundingTodo: state => {
    return state.fundingTodo;
  },
  isLoading: state => {
    return state.isLoading;
  },
};

const actions = {
  getInvoiceAddresses: async (context, params) => {
    fetch(context, 'todo/invoiceAddresses', 'invoiceAddresses');
  },
  getInvoiceAddress: async (context, params) => {
    fetch(context, 'todo/invoiceAddresses/' + params.id, 'invoiceAddress');
  },
  patchInvoiceAddress: async (context, params) => {
    await patch(context, 'todo/invoiceAddresses/' + params.id, params);
  },
  getCombinedTodos: async (context, params) => {
    fetch(context, 'todo/combinedTodos', 'combinedTodos');
  },
  getCombinedTodo: async (context, params) => {
    fetch(context, 'todo/combinedTodos/' + params.id, 'combinedTodo');
  },
  patchCombinedTodo: async (context, params) => {
    await patch(context, 'todo/combinedTodos/' + params.id, params);
  },
  getCostTodos: async (context, params) => {
    fetch(context, 'todo/costTodos', 'costTodos');
  },
  getCostTodo: async (context, params) => {
    fetch(context, 'todo/costTodos/' + params.id, 'costTodo');
  },
  patchCostTodo: async (context, params) => {
    await patch(context, 'todo/costTodos/' + params.id, params);
  },
  getFundingTodos: async (context, params) => {
    fetch(context, 'todo/fundingTodos', 'fundingTodos');
  },
  getFundingTodo: async (context, params) => {
    fetch(context, 'todo/fundingTodos/' + params.id, 'fundingTodo');
  },
  patchFundingTodo: async (context, params) => {
    await patch(context, 'todo/fundingTodos/' + params.id, params);
  },
};

async function fetch(context, path, commit) {
  context.commit('isLoading', true);
  let response = {};
  try {
    response = await Vue.axios.get(path);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
  if (response && response.status === 200) {
    const data = response.data;
    context.commit(commit, data.result);
  }
  context.commit('isLoading', false);
}

async function patch(context, path, body) {
  context.commit('isLoading', true);
  try {
    await Vue.axios.patch(path, body);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: error.response.data.message,
      request: path,
    });
  }
  context.commit('isLoading', false);
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
