<template>
  <tr class="table-row">
    <td>
      <!-- empty -->
    </td>
    <td>
      <!-- empty -->
    </td>
    <td>
      <!-- empty -->
    </td>
    <td>
      <!-- empty -->
    </td>
    <td class="total-amount">
      <p class="total-amount-current-year">
        Totalt:
        {{
          this.$store.getters['businessboard/membershipcalculationinprogress']
            ? '---'
            : totalAmountCurrentYear.toLocaleString('sv-SE')
        }}
        st
        <span @click="fetch()"><use-svg svg="bb-update-icon" /></span>
      </p>
      <p
        v-if="showPreviousYear"
        class="previous-year-amount">
        {{ totalAmountPreviousYear }} st
      </p>
    </td>
    <template v-for="(fee, index) in feeReference">
      <td :key="index">
        <!-- empty -->
      </td>
    </template>
    <td>
      <!-- empty -->
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';
import UseSvg from '@/components/use-svg';

export default {
  components: {
    UseSvg,
  },

  props: {
    feeReference: VueTypes.array.def([]),
    showPreviousYear: VueTypes.bool.def(false),
    totalAmountCurrentYear: VueTypes.number.def(0),
    totalAmountPreviousYear: VueTypes.number.def(0),
  },
  methods: {
    fetch() {
      this.$store.dispatch('businessboard/fetchMembershipTypes');
    },
  },
};
</script>

<style lang="scss">
.table-row {
  background-color: #373d3f;
  height: 3.125rem;
  align-items: center;
  border-radius: 4px;
  width: 100%;
}
.total-amount {
  color: #fff;
  width: 100%;
  height: 100%;
  align-items: center;
  display: block;

  .total-amount-current-year {
    width: 100%;
    margin: 0;

    line-height: 1.75rem;
    text-align: left;
  }
  .previous-year-amount {
    text-align: center;
    margin-top: 0;

    font-size: font(small);
    color: #fff;
    width: 50%;
  }
}
</style>
