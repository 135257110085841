<template>
  <div :class="[fileFormat === '0' ? 'opaque-bg' : '']">
    <h1
      v-if="settings.showHeaders"
      class="pieChart-title">
      {{ title }}
    </h1>
    <div class="section">
      <div class="divide-charts">
        <pie-filterable
          v-if="!isLoading"
          class="pieChart"
          ref="pie3"
          :data="diagram.data.currentYearData.courses[0].pieChart"
          :colors="colors" />
        <pie-chart-loader v-if="isLoading" />
        <div>
          <h2 class="chart-year">{{ diagram.data.currentYearData.year }}</h2>
          <reports-legend-pie
            :labels="diagram.data.currentYearData.courses[0].pieChart.labels"
            :numbers="diagram.data.currentYearData.courses[0].pieChart.values"
            :percentage="
              diagram.data.currentYearData.courses[0].pieChart.tooltips
            "
            :type="$t('rounds').toLowerCase()" />
        </div>
      </div>

      <div class="divide-charts">
        <pie-filterable
          v-if="!isLoading"
          class="pieChart"
          :data="diagram.data.comparisonYearData[0].courses[0].pieChart"
          :colors="colors" />
        <pie-chart-loader v-if="isLoading" />
        <div>
          <h2 class="chart-year">
            {{ diagram.data.comparisonYearData[0].year }}
          </h2>
          <reports-legend-pie
            :labels="
              diagram.data.comparisonYearData[0].courses[0].pieChart.labels
            "
            :numbers="
              diagram.data.comparisonYearData[0].courses[0].pieChart.values
            "
            :percentage="
              diagram.data.comparisonYearData[0].courses[0].pieChart.tooltips
            "
            type="ronder" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieFilterable from '@/components/charts/pie-chart-filterable3';
import ReportsLegendPie from '@/components/reports-templates/legend/reports-legend-pie';
import LocalizationMixinVue from '../../mixins/localization-mixin.vue';
import { COLORS } from '@/components/charts/pie-chart-colors';
import PieChartLoader from '@/components/reports-templates/pie-chart-loader';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixinVue],
  components: {
    PieFilterable,
    ReportsLegendPie,
    PieChartLoader,
  },
  props: {
    diagram: {},
    title: String,
    fileFormat: String,
  },
  data() {
    return {};
  },
  computed: {
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    colors() {
      return COLORS;
    },
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';

.pieChart {
  width: 20rem;
  height: 20rem;
}

.section {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 16px;
  margin-left: 2rem;
  display: flex;
  gap: 4rem;
}
.pieChart-title {
  margin-left: 4rem;
}
.chart-year {
  font-size: medium;
  font-weight: 600;
}
.divide-charts {
  display: flex;
  width: 50%;
}
</style>
