<template>
  <div class="kebab-menu">
    <div class="kebab">
      <template v-if="showMenu">
        <div
          v-if="enlargeOption"
          @click="maximizedClicked"
          class="hovering">
          <info-icon
            :description="`${
              maximized ? $t('reduceDiagram') : $t('enlargeDiagram')
            }`">
            <template #icon>
              <use-svg
                class="smaller-icon"
                svg="bb-enlarge-icon" />
            </template>
          </info-icon>
        </div>
        <div
          @click="copyClicked"
          class="hovering">
          <info-icon :description="$t('copy')">
            <template #icon>
              <use-svg
                class="smaller-icon"
                svg="bb-copy-icon" />
            </template>
          </info-icon>
        </div>
        <div
          @click="downloadSettings()"
          class="hovering">
          <info-icon :description="$t('download')">
            <template #icon>
              <img
                src="@/components/svg/bb-download-icon.svg"
                class="smaller-icon"
                alt="" />
            </template>
          </info-icon>
        </div>
        <div @click="closeKebab">✕</div>
      </template>
      <template v-else>
        <div @click="showMenu = true">
          <use-svg svg="bb-kebab-icon" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

import UseSvg from '@/components/use-svg';
import InfoIcon from '@/components/businessboard/tooltip-icon';

export default {
  name: 'diagram-header-menu',

  inject: ['openModal', 'copy'],

  mixins: [LocalizationMixin],

  components: {
    UseSvg,
    InfoIcon,
  },

  props: {
    enlargeOption: {
      type: Boolean,
      default: false,
    },
    diagramId: VueTypes.number,
  },

  data() {
    return {
      showMenu: false,
      showDownloadModal: false,
      maximized: false,
    };
  },

  computed: {
    downloadSettingsObj() {
      return {
        id: this.elementID,
        showDownloadModal: this.showDownloadModal,
      };
    },
  },

  methods: {
    closeKebab() {
      this.showMenu = false;
      this.isEditing = false;
    },
    downloadSettings() {
      this.openModal(this.diagramId);
    },
    copyClicked() {
      this.copy(this.diagramId);
    },
    maximizedClicked() {
      this.maximimize(this.diagramId);
    },
  },
};
</script>

<style lang="scss" scoped>
.kebab-menu {
  margin-top: 1rem;
  position: relative;
  display: grid;
  grid-template-rows: min-content min-content auto;
}

.kebab {
  margin-top: -1rem;
  background-color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid color(gray-dark);
  border-radius: 8px;
  display: grid;
  grid-auto-flow: column;
  div,
  svg {
    cursor: pointer;
  }
  > div {
    width: 1.5rem;
    text-align: center;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    display: flex;
  }
}

.smaller-icon {
  width: 13px;
  height: 13px;
}

.hovering {
  &:hover {
    border-radius: 8px;
    background-color: #eaf6f6;
  }
}
</style>
