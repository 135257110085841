var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('div',{staticClass:"list-table__wrapper"},[_c('div',{staticClass:"list-table vertical"},[_vm._l((_vm.headers),function(header,index){return [(index === 0 || _vm.showTable)?_c('div',{key:`table-${index}`,class:[
          index % 2 === 0 ? 'even' : 'odd',
          index === 0 ? 'head' : 'level0',
          {
            clickable: header.drillDown,
            total: header.isTotal,
            highlight: header.rowType === 2,
          },
        ]},[_c('div',{staticClass:"drilldown",on:{"click":function($event){header.drillDown ? _vm.expandDrilldown(index) : null}}},[_c('th',[(index === 0)?_c('span',{attrs:{"title":_vm.$t(_vm.copyDataText)},on:{"click":_vm.copyData}},[_c('use-svg',{attrs:{"svg":"bb-copy-icon2"}})],1):_vm._e(),_vm._v(" "+_vm._s(header.displayName)+" ")]),_c('div',{staticClass:"drilldown_row"},_vm._l((_vm.items),function(item,j){return _c('td',{key:j},[(item[header.value] != '-2 133 731 337')?[_vm._v(" "+_vm._s(item[header.value])+" ")]:_vm._e()],2)}),0),(index === 0)?_c('td',{staticClass:"svg-td",on:{"click":_vm.toggleShow}},[_c('use-svg',{class:_vm.showTable ? 'svg--180-negative' : '',attrs:{"svg":"arrow-right"}})],1):(_vm.showTable && header.hasChildren)?_c('td',{staticClass:"svg-td",on:{"click":function($event){return _vm.isDrilldownExpanded(index)}}},[_c('use-svg',{class:[
                _vm.isDrilldownExpanded(index) === true
                  ? 'svg--180-negative'
                  : '',
              ],attrs:{"svg":"arrow-right"}})],1):_c('td')]),(
            header.drillDown && _vm.showTable && header.drillDown.rows.length > 0
          )?_vm._l((header.drillDown.columns),function(dheader,dindex){return _c('drill-down-table',{key:`drill-down-table-${index}-${dindex}`,class:[_vm.isDrilldownExpanded(index) === true ? 'show' : 'hide'],attrs:{"items":header.drillDown.rows,"header":dheader,"level":1,"index":dindex}})}):_vm._e()],2):_vm._e()]})],2)]):_c('div',[_c('list-table-loader')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }