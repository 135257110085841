<template>
  <div
    v-if="isOpen"
    class="modal--wrapper">
    <div class="modal--header">
      <p class="breadcrumb">
        {{ $t('MemberCalculation.addSlashRemoveGroup') }}
      </p>
      <h2 class="modal--title">
        {{ $t('Businessboard.settings') }}
      </h2>
    </div>
    <div class="modal--content">
      <div class="modal--body">
        <h3>
          {{ $t('MemberCalculation.addSlashRemoveGroup') }}
        </h3>
        <add-text @add="e => addGroup(e)" />
        <draggable
          v-model="items"
          @change="changeOrder"
          @start="drag = true"
          @end="drag = false">
          <div
            v-for="item in items"
            :key="item.id"
            class="row">
            <div class="dragIcon"></div>
            <input
              class="text-input"
              type="text"
              :value="item.membershipCategoryName"
              @change="e => changeName(item, e)" />
            <toggle
              :checked="!item.exclude"
              :disabled="item.membershipTypeCount > 0"
              :tooltip="item.exclude ? 'Exkluderad' : 'Inkluderad'"
              @toggle="changeExclude(item)" />
            <div
              :style="
                item.membershipTypeCount > 0 ? 'opacity: .3' : 'cursor: pointer'
              "
              class="hovering delete-icon rounded"
              @click="deleteGroup(item.id)">
              <use-svg
                svg="bb-trash-icon"
                color="white" />
            </div>
          </div>
        </draggable>

        <div class="buttons">
          <base-button
            backgroundColor="bb-primary"
            :text="$t('done')"
            @click="$emit('closeModal')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import Toggle from '@/components/businessboard/toggle';
import AddText from '@/components/businessboard/add-text';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import draggable from 'vuedraggable';

export default {
  name: 'modal-membership-types-settings',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    UseSvg,
    Toggle,
    AddText,
    draggable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      newGroupName: '',
      drag: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    addGroup(name) {
      this.$store
        .dispatch('businessboard/createMembershipCategory', {
          name: name,
          exclude: false,
        })
        .then(() => {
          this.$store.dispatch('businessboard/fetchMembershipCategories');
        });
    },
    changeName(item, e) {
      item.membershipCategoryName = e.target.value;
      this.patchItem(item);
    },
    changeExclude(item) {
      item.exclude = !item.exclude;
      this.patchItem(item);
    },
    changeOrder: function (e) {
      let item = e.moved.element;
      item.order = e.moved.newIndex;
      this.patchItem(item);
    },
    deleteGroup(id) {
      this.$store
        .dispatch('businessboard/deleteMembershipCategory', { id: id })
        .then(() => {
          this.$store.dispatch('businessboard/fetchMembershipCategories');
        });
    },
    patchItem(item) {
      this.$store
        .dispatch('businessboard/patchMembershipCategory', {
          id: item.id,
          exclude: item.exclude,
          name: item.membershipCategoryName,
          order: item.order,
        })
        .then(() => {
          this.$store.dispatch('businessboard/fetchMembershipCategories');
        });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.modal--wrapper {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 30rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;
  border-radius: 8px;
}

.modal--content {
  width: 25rem;
  margin-left: 2rem;
}
.modal--header {
  background-color: #fff;
  margin-left: 2rem;
}
.modal--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #131516;
}
.breadcrumb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7c80;
}
.delete-icon {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;
  justify-content: center;
}
.rounded {
  padding: measure(spacing-xs);
  margin-left: auto;

  border-radius: 8px;
  border: solid 1px #c1c7c9;

  height: 30px;
}
.buttons {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem;
  padding: 0.3rem;
  input {
    border: solid 1px #000;
    border-radius: 3px;
    padding: 0.4rem 0.6rem;
    width: 60%;
  }
  div {
    margin: 0.2rem;
  }
}
.row:first-child {
  border-top: solid 1px #dadedf;
}
.row {
  border-bottom: solid 1px #dadedf;
}
.dragIcon {
  display: inline-block;
  cursor: move;
  width: 16.622px;
  height: 6.244px;
  border-top: solid 1px #dadedf;
  border-bottom: solid 1px #dadedf;
}
</style>
