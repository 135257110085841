/* Store module to handle User */
import Vue from 'vue';
import { checkIfEmptyObject } from '@/core/helpers.js';
import EventBus from '@/core/event-bus.js';

const state = {
  user: {},
  token: '',
  refreshToken: '',
};

const mutations = {
  user(state, payload) {
    state.user = payload;
  },
  token(state, payload) {
    state.token = payload;
  },
  refreshToken(state, payload) {
    state.refreshToken = payload;
  },
};

const getters = {
  user: state => {
    return state.user;
  },
  loggedIn: state => {
    return !checkIfEmptyObject(state.user);
  },
  name: state => {
    return state.user.name;
  },
  id: state => {
    return state.user.id;
  },
  locale: state => {
    return state.user.locale;
  },
  token: state => {
    return state.token;
  },
  refreshToken: state => {
    return state.refreshToken;
  },
  roles: state => {
    if (state.user) {
      return state.user.roles;
    }

    return null;
  },
};

const actions = {
  login: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'home-login', { root: true });
    const path = 'authentication/authenticate';

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'home-login', { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('user', data.result.user);
      context.commit('token', data.result.token);
      context.commit('refreshToken', data.result.refreshToken);
      context.dispatch('menu/get', null, { root: true });
      return {
        token: data.result.token,
        refreshToken: data.result.refreshToken,
      };
    }
  },
  changePassword: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'user-change-password', {
      root: true,
    });
    let id;
    if (params && params.id) {
      id = params.id;
    } else {
      id = context.getters.id;
    }
    const path = `users/${id}/password`;

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'user-change-password', {
      root: true,
    });

    const data = response.data;
    return data;
  },
  forgotPassword: async (context, params) => {
    const path = 'authentication/forgotpassword';

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      return data;
    }
  },
  resetPassword: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'user-reset-password', {
      root: true,
    });
    const path = 'authentication/resetpassword';

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'user-reset-password', {
      root: true,
    });

    if (response && response.data) {
      const data = response.data;
      return data;
    }
  },
  refreshToken: async (context, params) => {
    const path = 'authentication/refresh';

    params = {
      token: context.getters['token'],
      refreshToken: context.getters['refreshToken'],
    };

    if (!context.getters['token'] && !context.getters['refreshToken']) {
      if (!Vue.$cookies.get('token') && !Vue.$cookies.get('refreshToken')) {
        return Promise.reject(new Error('Logged out'));
      }
      params = {
        token: Vue.$cookies.get('token'),
        refreshToken: Vue.$cookies.get('refreshToken'),
      };
    }

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      return Promise.reject(new Error(error));
    }

    const data = response.data;
    if (data) {
      context.commit('token', data.result.token);
      context.commit('refreshToken', data.result.refreshToken);
      Vue.$cookies.set('token', data.result.token, '7d');
      Vue.$cookies.set('refresh_token', data.result.refreshToken, '7d');
      context.dispatch('getUser');

      return data.result.token;
    }
  },
  getUser: async (context, params) => {
    const path = 'authentication/user';

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      return Promise.reject(new Error(error));
    }

    const data = response.data;
    context.commit('user', data.result);
    context.dispatch('menu/get', null, { root: true });
    if (data.result && !data.result.club) {
      if (data.result.superUser) {
        context.dispatch('clubs/clubsSelect', null, { root: true });
      }
      if (
        data.result.roles.GolfOffice.some(e => e.value === 'BusinessBoardAdmin')
      ) {
        context.dispatch('clubs/clubsSelectBB', null, { root: true });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
