<template>
  <div class="onservice">
    <div class="onservice--content">
      <section class="onservice--system-messages">
        <h1 class="first-letter">{{ $t('systemMessage') }}</h1>
        <div
          class="input">
          <label
            for="fromDate"
            class="input__label">{{ $t('from') }}</label>
          <input
            id="fromDate"
            class="input__input"
            v-model="fromDate"
            type="date"
            required="true"
            name="fromDate"/>
        </div>
        <div
          class="input">
          <label
            for="toDate"
            class="input__label first-letter">{{ $t('to') }}</label>
          <input
            id="toDate"
            class="input__input"
            v-model="toDate"
            type="date"
            required="false"
            name="toDate"/>
        </div>
        <div class="input-group--inline input--column">
          <label for="message" class="input__label">{{ $t('message') }}</label>
          <textarea
            name="message"
            id="message"
            class="input__input"
            resize="none"
            rows="3"
            v-model="message">
          </textarea>
        </div>
        <div class="input-group--inline message__actions">
              <router-link to="/onservice/systemMessages">
                <base-button
                :text="$t('cancel')">
                </base-button>
              </router-link>
                <base-button
                  :text="$t('createMessage')"
                  @click="saveMessage"
                  v-element-loader="{ loaderKey: 'create-message' }"
                  :disabled="!canCreate"/>
      </div>
      </section>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'OnServiceCreateSystemMessage',
  components: {
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      fromDate: '',
      toDate: '',
      message: '',
    };
  },
  mounted () {
    if (this.id) {
      this.$store.dispatch('onserviceSystemMessages/fetchSystemMessage', { id: this.id });
    }
  },
  computed: {
    canCreate() {
      return this.fromDate &&
        this.message;
    },
  },
  methods: {
    saveMessage () {
      this.$store.dispatch('onserviceSystemMessages/createSystemMessage', {
        fromDate: this.fromDate,
        toDate: this.toDate,
        message: this.message,
      }).then(() => {
        this.$router.push({ path: '/onservice/systemmessages' });
      });
    },
  },
};
</script>
<style lang="scss">
.onservice--functions-list {
  margin-left: 0.5rem;
  padding-left: 1rem;

  li.status--true {
    list-style-image: url('~@/assets/images/li-circle-ok.svg');
  }
  li.status--false {
    list-style-image: url('~@/assets/images/li-circle-error.svg');
  }
}
.onservice--functions-list li:not(:last-child) {
  padding-bottom: 0.75rem;
}
.input--column {
  flex-direction: column;
  width: 33%;
}
.status--active {
  color: color(active-color);
  margin-left: 0.5rem;
}
.message__actions {
  justify-content: flex-start;
  @include media('small') {
    justify-content: flex-end;
  }

  a button {
    margin-left: 0;
  }

  button {
    margin-left: 1.25rem;
  }
}
</style>
