var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'coursdata-holes-grid-item',
    'coursedata-holes-grid-item-can-drop',
    this.dragHover ? 'coursedata-holes-grid-item-placeholder-drag-hover' : '',
    this.faded ? 'coursedata-holes-grid-item-incomplete' : '',
    this.compact ? 'coursdata-holes-grid-item-compact' : '',
  ],attrs:{"edit":_vm.edit,"editHole":_vm.editHole,"editIndex":_vm.editIndex,"tabindex":"0"}},[_c('div',{staticClass:"coursedata-holes-grid-item-header"},[_c('div',{staticClass:"coursedata-holes-grid-item-header-info"},[(_vm.localHole.holeNumber)?_c('div',{class:'coursedata-holes-grid-item-hole-info coursedata-holes-grid-item-hole-top-hole' +
          (_vm.editHole
            ? ' coursedata-holes-grid-item-hole-top-hole-noborder'
            : '')},[_c('div',{staticClass:"coursedata-holes-grid-item-hole-info-label"},[_vm._v("Hole")]),_c('div',{staticClass:"coursedata-holes-grid-item-hole-info-value"},[_vm._v(" "+_vm._s(_vm.loopName(_vm.localHole.holeNumber))+" ")])]):_vm._e(),_c('div',{class:'coursedata-holes-grid-item-hole-center-hole' +
          (_vm.editHole ? ' coursedata-holes-grid-placeholder' : '')},[_c('div',{staticClass:"coursedata-holes-grid-item-hole-center-hole-content"},[_c('span',{staticClass:"coursedata-holes-grid-item-hole-center-prefix"},[_vm._v(_vm._s(_vm.hole.groupName))]),_vm._v(_vm._s(_vm.holeName(_vm.localHole))+" ")])]),(_vm.localHole.par)?_c('div',{staticClass:"coursedata-holes-grid-item-hole-info"},[_c('div',{staticClass:"coursedata-holes-grid-item-hole-info-label"},[_vm._v("Par")]),_c('div',{staticClass:"coursedata-holes-grid-item-hole-info-value"},[_vm._v(" "+_vm._s(_vm.localHole.par)+" ")])]):_vm._e(),(_vm.localHole.index)?_c('div',{class:'coursedata-holes-grid-item-hole-info' +
          (_vm.editIndex ? ' coursedata-holes-grid-item-placeholder' : '')},[_c('div',{staticClass:"coursedata-holes-grid-item-hole-info-label"},[_vm._v("Index")]),_c('div',{staticClass:"coursedata-holes-grid-item-hole-info-value"},[_vm._v(" "+_vm._s(_vm.localHole.index)+" ")])]):(_vm.editIndex)?_c('div',{class:'coursedata-holes-grid-item-hole-info' +
          (_vm.editIndex ? ' coursedata-holes-grid-item-placeholder' : '')},[_c('div',{staticClass:"coursedata-holes-grid-item-hole-info-label"},[_vm._v("Index")]),_c('div',{staticClass:"coursedata-holes-grid-item-hole-info-value"},[_vm._v(" "+_vm._s(_vm.localHole.index)+" ")])]):_vm._e()]),(_vm.rendering === true)?_c('div',{staticClass:"coursedata-holes-grid-item-header-rendering-image"}):(_vm.localHole.image)?_c('img',{staticClass:"coursedata-holes-grid-item-header-image",attrs:{"src":_vm.localHole.image,"alt":"a"}}):_vm._e()]),(_vm.compact === false && _vm.tees && _vm.tees.length > 0)?_c('div',{staticClass:"coursedata-holes-grid-item-body"},_vm._l((_vm.localTees),function(tee){return _c('div',{key:tee.name,staticClass:"coursedata-holes-grid-item-tee"},[_c('div',{class:'coursedata-holes-grid-item-tee-image' + ' ' + 'tee-' + tee.name}),_c('div',{staticClass:"coursedata-holes-grid-item-tee-name"},[_vm._v(_vm._s(tee.title))]),(tee.distance)?_c('div',{staticClass:"coursedata-holes-grid-item-tee-length"},[_vm._v(" "+_vm._s(tee.distance)+" m ")]):_vm._e()])}),0):_vm._e(),(_vm.compact === false && _vm.mapInfo && _vm.mapInfo.length > 0)?_c('div',{staticClass:"coursedata-holes-grid-item-footer"},_vm._l((_vm.mapInfo),function(item){return _c('div',{key:item.label,staticClass:"coursedata-holes-grid-item-footer-item"},[_c('div',[_vm._v(_vm._s(item.label))]),_c('div',{class:['coursedata-holes-grid-item-footer-icon', item.icon]})])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }