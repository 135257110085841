<template>
  <div>
    <div class="action-buttons-div">
      <base-button
        background-color="bb-primary"
        :text="groupId === '' ? $t('connectAccount') : $t('changeConnection')"
        @click="isModalOpen = true">
      </base-button>
    </div>
    <div
      v-if="isModalOpen"
      class="modal--wrapper">
      <div class="modal--content">
        <div class="modal--header">
          <h2 class="modal--title">{{ $t('connectAccounts') }} "{{ name }}"</h2>
        </div>
        <div>
          {{ $t('chooseCategory') }}
          <custom-select
            @optionSelected="e => selectCategory(e)"
            :selected="categories.indexOf(selectedCategory)"
            :options="categories">
          </custom-select>
          {{ $t('chooseGroup') }}
          <custom-select
            @optionSelected="e => selectGroup(e)"
            :selected="''"
            :options="groups">
          </custom-select>
        </div>
        <div class="modal--body">
          <div style="display: flex; gap: 0.5rem">
            <base-button
              background-color="white"
              :text="$t('cancel')"
              @click="isModalOpen = false">
            </base-button>
            <base-button
              background-color="bb-primary"
              :text="$t('connect')"
              @click="connectAccount">
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import CustomSelect from '@/components/form-elements/custom-select-bb';

export default {
  name: 'modal-membership-types-settings',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    CustomSelect,
  },
  props: {
    groupId: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isModalOpen: false,
      selectedCategory: {},
      selectedGroup: {},
    };
  },
  watch: {
    groupId(newGroup) {},
  },
  computed: {
    categories() {
      let cats = this.$store.getters['BusinessBoardBalanceSheet/categories'];
      return cats.map(c => {
        return {
          value: c.externalId,
          displayName: c.name,
        };
      });
    },
    groups() {
      return this.$store.getters['BusinessBoardBalanceSheet/groups']
        .filter(
          g =>
            g.balanceAccountCategoryName === this.selectedCategory.displayName
        )
        .map(g => {
          return {
            value: g.id,
            displayName: g.name,
          };
        });
    },
  },
  methods: {
    selectCategory(e) {
      this.selectedCategory = e;
    },
    selectGroup(e) {
      this.selectedGroup = e;
    },
    connectAccount() {
      this.$store
        .dispatch('BusinessBoardBalanceSheet/patchAccount', {
          accountId: this.id,
          groupId: this.selectedGroup.value,
        })
        .then(() => {
          this.$emit('done');
          this.isModalOpen = false;
        });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.modal--wrapper {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 50rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;
  border-radius: 8px;
}

.modal--content {
  width: 25rem;
  margin-left: 2rem;
}
.modal--header {
  background-color: #fff;
  margin-left: 2rem;
}
.modal--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #131516;
}
.breadcrumb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7c80;
}
.delete-icon {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;
  justify-content: center;
}
.rounded {
  padding: measure(spacing-xs);
  margin-left: auto;

  border-radius: 8px;
  border: solid 1px #c1c7c9;

  height: 30px;
}
.buttons {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem;
  padding: 0.3rem;
  input {
    border: solid 1px #000;
    border-radius: 3px;
    padding: 0.4rem 0.6rem;
    width: 60%;
  }
  div {
    margin: 0.2rem;
  }
}
.row:first-child {
  border-top: solid 1px #dadedf;
}
.row {
  border-bottom: solid 1px #dadedf;
}
.dragIcon {
  display: inline-block;
  cursor: move;
  width: 16.622px;
  height: 6.244px;
  border-top: solid 1px #dadedf;
  border-bottom: solid 1px #dadedf;
}
</style>
