<template>
  <te-modal
    v-if="localGroup"
    size="big"
    class="create-group-modal"
    :show="localShow"
    @hide="cancel">
    <template v-slot:header>Hole placement</template>
    <template v-slot:content>
      <te-stepper
        :items="items"
        :currentItem="currentItem">
        <course-data-hole-group-view
          v-if="currentItem == items[0] && localGroup"
          class="create-group-stepper-content"
          :shortNameError="shortNameError"
          :shortNamePattern="shortNamePattern"
          :namePattern="namePattern"
          :nameError="nameError"
          :group="localGroup"
          @changed="groupChanged" />
        <course-data-holes-add-par-view
          v-if="currentItem == items[1]"
          class="create-group-stepper-content"
          :item="items[1]"
          :par="par"
          :group="localGroup"
          :hideBottom="true"
          @changed="groupChanged" />
      </te-stepper>
    </template>
    <template v-slot:actions>
      <button
        v-if="currentItem == items[1]"
        class="te-btn te-btn-md te-btn-secondary"
        @click="previous()">
        <i class="tietoevry-icons-arrow-small-left"></i>Previous
      </button>
      <button
        v-if="currentItem == items[0] && localGroup.id && canDelete"
        class="te-btn te-btn-md te-btn-destructive"
        @click="deleteGroup()">
        Delete
      </button>
      <div style="flex: 1"></div>
      <button
        v-if="currentItem == items[1]"
        :disabled="createGroupButtonDisabled"
        class="te-btn te-btn-md te-btn-primary"
        @click="createGroup()">
        {{ localGroup.id ? 'Save' : 'Create' }}
      </button>
      <button
        v-if="currentItem == items[0]"
        :disabled="nextButtonDisabled"
        class="te-btn te-btn-md te-btn-primary"
        @click="next()">
        Next
      </button>
    </template>
  </te-modal>
</template>

<script>
import CourseDataHolesAddParView from './holes-add-par.vue';
import TeStepper from './components/te-stepper.vue';
import TeModal from './components/te-modal.vue';
import CourseDataHoleGroupView from './hole-group.vue';

export default {
  name: 'CourseGuidesAddGroupModal',
  components: {
    TeModal,
    CourseDataHolesAddParView,
    TeStepper,
    CourseDataHoleGroupView,
  },
  emits: ['done', 'cancel', 'deleted'],
  watch: {
    groups: {
      handler: function (newVal, oldVal) {
        this.localGroups = newVal;
        this.update();
      },
      deep: true,
    },
    group: {
      handler: function (newVal, oldVal) {
        this.localGroup = newVal;
        this.update();
        this.updateCanDelete();
      },
      deep: true,
    },
    show: function (newValue) {
      this.localShow = newValue;
      this.currentItem = this.items[0];
      this.update();
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    groups: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    loops() {
      return this.$store.getters['coursedata/loops'];
    },
  },
  data() {
    let g = this.group;
    let canDelete = false;
    if (!g) {
      g = {
        name: '',
        shortName: '',
        numberOfHoles: 0,
        holes: [],
      };
    }
    return {
      currentItem: null,
      localShow: this.show,
      shortNameError: null,
      canDelete: canDelete,
      nameError: null,
      createGroupButtonDisabled: true,
      nextButtonDisabled: true,
      localGroup: g,
      par: [3, 4, 5, 6],
      items: [
        {
          id: 1,
          title: 'Step 1',
          progress: 0,
          description: 'Add group name, short name and number of holes.',
        },
        {
          id: 2,
          title: 'Step 2',
          progress: 0,
          description: 'Add par for each hole.',
        },
      ],
    };
  },
  methods: {
    updateCanDelete() {
      let g = this.localGroup;
      if (!g || !g.holes) {
        this.canDelete = false;
        return;
      }
      if (!this.loops || this.loops.length === 0) {
        this.canDelete = true;
        return;
      }

      for (let hole of g.holes) {
        for (let loop of this.loops) {
          let f = loop.loopHoles.find(h => h.physicalHoleId === hole.id);
          if (f) {
            this.canDelete = false;
            return;
          }
        }
      }
      this.canDelete = true;
    },
    groupChanged(group) {
      if (group) {
        this.localGroup = group;
      }
      let v = 100 / 3;
      let p = this.localGroup.name.length > 0 ? v : 0;
      p += this.localGroup.shortName.length > 0 ? v : 0;
      p += this.localGroup.numberOfHoles > 0 ? v : 0;
      this.items[0].progress = p;
      this.nextButtonDisabled = !this.canCreateGroup();
      let p2 = 0;
      if (this.localGroup.numberOfHoles && this.localGroup.numberOfHoles > 0) {
        let v2 = 100 / this.localGroup.numberOfHoles;
        for (let h of this.localGroup.holes) {
          if (h.par !== 0) {
            p2 += v2;
          }
        }
      }
      this.createGroupButtonDisabled = p2 < 100;
      this.items[1].progress = p2;
    },
    progressChanged(progress) {},
    previous() {
      this.currentItem = this.items[0];
    },
    next() {
      this.currentItem = this.items[1];
    },
    addGroup(evt) {
      this.nextButtonDisabled = true;
    },
    canCreateGroup() {
      if (!this.localGroup) {
        return false;
      }
      if (
        !(
          this.localGroup.name.length > 0 &&
          this.localGroup.shortName.length > 0 &&
          this.localGroup.numberOfHoles > 0
        )
      ) {
        return false;
      }
      for (let group of this.groups) {
        if (group.id === this.localGroup.id) {
          continue;
        }
        if (group.name.toLowerCase() === this.localGroup.name.toLowerCase()) {
          return false;
        }
        if (
          group.shortName.toLowerCase() ===
          this.localGroup.shortName.toLowerCase()
        ) {
          return false;
        }
      }
      return true;
    },
    cancel() {
      this.$emit('cancel', this.localGroup);
    },
    deleteGroup() {
      if (!this.localGroup.id) {
        return;
      }
      this.$emit('deleted', this.localGroup);
    },
    createGroup() {
      this.$emit('done', this.localGroup);
    },
    update() {
      if (!this.localGroup) {
        return;
      }
      this.nameError = 'Group name already exists';
      this.shortNameError = 'Short name already exists';
      if (this.groups.length === 0) {
        this.namePattern = '.*';
        this.shortNamePattern = '.*';
      } else {
        this.namePattern =
          '^(?!(' +
          this.groups
            .filter(g => g.id !== this.localGroup.id)
            .map(g => `${g.name}`)
            .join('|') +
          ')$).*$';
        this.shortNamePattern =
          '^(?!(' +
          this.groups
            .filter(g => g.id !== this.localGroup.id)
            .map(g => `${g.shortName}`)
            .join('|') +
          ')$).*$';
      }
      this.groupChanged();
    },
  },
  mounted() {
    this.update();
    this.currentItem = this.items[0];
  },
};
</script>

<style lang="scss">
.create-group-modal {
  display: flex;

  padding: 40px;
  .modal__container {
    top: unset;
    align-items: flex-start;
    display: flex;
    height: 100%;
  }
  .te-modal {
    flex: 1;
    background: var(--gray-gray-10-f-6-f-6-f-7, #f6f6f7);
  }
}
.create-group-stepper-content {
  padding: 26px 24px 24px 24px;
}
</style>
