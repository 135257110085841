<template>
  <div class="course-guide-holes">
    <course-guides-add-group-modal
      :show="modalCreateGroupsVisible"
      :groups="localGroups"
      :group="selectedGroup"
      @cancel="hideModal"
      @hide="hideModal"
      @deleted="groupModalDeleted"
      @done="groupModalDone" />
    <div
      :class="[
        'course-guide-holes-toolbar',
        scrollAtTop ? '' : 'scrolling-border',
      ]">
      <div class="spacer"></div>
      <button
        class="te-btn te-btn-sm te-btn-primary"
        @click="this.addGroup">
        Add group
      </button>
    </div>
    <div
      class="course-guide-holes-content"
      @scroll.passive="onScroll">
      <te-collapsible
        class="course-guide-holes-collapsible"
        v-for="group in localGroups"
        :open="false"
        :key="group.id"
        :hasOptions="true"
        @optionsClicked="startEditGroup(group)"
        :title="group.name">
        <div class="course-guide-holes-grid">
          <couse-data-holes-grid-item-view
            v-for="hole in group.holes"
            :key="hole.id"
            :rendering="isRenderingHoles.includes(hole.id)"
            :mapInfo="getMapInfo(hole)"
            :hole="convertToGridItem(hole, group)" />
        </div>
      </te-collapsible>
    </div>
  </div>
</template>

<script>
import TeCollapsible from './components/te-collapsible.vue';
import CouseDataHolesGridItemView from './holes-grid-item.vue';
import CourseGuidesAddGroupModal from './course-guides-add-group-modal.vue';
import { analyzeHole } from './holeanalyzer.js';
export default {
  name: 'CourseGuidesHoles',
  components: {
    TeCollapsible,
    CourseGuidesAddGroupModal,
    CouseDataHolesGridItemView,
  },
  emits: [],
  watch: {
    groups: {
      handler: function (newVal, oldVal) {
        this.localGroups = newVal;
      },
      deep: true,
    },
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    tees: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let h = this.hasAnyMapInfo(this.groups);
    return {
      scrollAtTop: true,
      localGroups: this.groups,
      hasMapInfo: h,
      modalCreateGroupsVisible: false,
      selectedGroup: null,
      par: [3, 4, 5, 6],
      addGroupStemItems: [
        {
          id: 1,
          title: 'Step 1',
          progress: 0,
          description: 'Add group name, short name and number of holes.',
        },
        {
          id: 2,
          title: 'Step 2',
          progress: 0,
          description: 'Add par for each hole.',
        },
      ],
    };
  },
  computed: {
    isRenderingHoles() {
      return this.$store.getters['coursedata/isRenderingHoles'];
    },
    isLoading() {
      return this.$store.getters['coursedata/isLoading'];
    },
  },
  methods: {
    hasAnyMapInfo(groups) {
      for (let g of groups) {
        for (let h of g.holes) {
          if (h.geometry) {
            return true;
          }
        }
      }
      return false;
    },
    hideModal() {
      this.modalCreateGroupsVisible = false;
      this.selectedGroup = null;
    },
    convertToGridItem(hole, group) {
      return {
        id: hole.id,
        groupName: group.shortName,
        groupHoleNumber: hole.holeNumber,
        surroundings: hole.surroundings,
        par: hole.par,
        image: hole.holeImageUrl,
      };
    },
    getMapInfo(hole) {
      if (!this.hasMapInfo) {
        return [];
      }
      return analyzeHole(hole);
    },
    groupModalDeleted(group) {
      this.hideModal();
      this.$store.dispatch('coursedata/deleteCourseGuideGroup', {
        holeGroupId: group.id,
      });
    },
    groupModalDone(group) {
      console.warn('GROUP ADD/EDIT NOT IMPLEMENTED');
      if (!group.id) {
        group.id = Math.floor(Math.random() * 199999);
        for (let h of group.holes) {
          h.id = Math.floor(Math.random() * 199999);
        }
        this.$store.dispatch('coursedata/postCreateHoleGroupsAndHoles', {
          holeGroups: [group],
        });
      } else {
        this.$store.dispatch('coursedata/putUpdateholegroup', group);
      }
      this.modalCreateGroupsVisible = false;
      this.selectedGroup = null;
    },
    addGroup() {
      this.selectedGroup = {
        name: '',
        shortName: '',
        numberOfHoles: 0,
        holes: [],
      };
      this.modalCreateGroupsVisible = true;
    },
    startEditGroup(group) {
      let g = JSON.parse(JSON.stringify(group));
      g.numberOfHoles = g.holes.length;
      this.selectedGroup = g;
      this.modalCreateGroupsVisible = true;
    },
    onScroll(e) {
      this.scrollAtTop = e.target.scrollTop === 0;
    },
  },
  created() {},
};
</script>

<style lang="scss">
.course-guide-holes {
  .scrolling-border {
    z-index: 1;
    box-shadow: 0px 5px 5px -5px #575757;
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  .course-guide-holes-toolbar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 20px 0px 10px 0px;
    margin-right: 18px;
    .spacer {
      flex: 1;
    }
  }
  .course-guide-holes-content {
    transition: box-shadow 0.2s ease-in-out;
    box-sizing: border-box;
    display: block;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 18px;
  }
  .course-guide-holes-collapsible {
    margin-bottom: 10px;
  }
  .course-guide-holes-grid {
    flex: 1;
    display: grid;
    justify-content: start;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
  .create-group-modal {
    .te-modal {
      background: var(--gray-gray-10-f-6-f-6-f-7, #f6f6f7);
    }
  }
}
@media (max-width: 1400px) {
  .course-guide-holes .course-guide-holes-grid-compact {
    grid-template-columns: repeat(3, 1fr);
  }
  .course-guide-holes .course-guide-holes-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1200px) {
  .course-guide-holes .course-guide-holes-grid-compact {
    grid-template-columns: repeat(2, 1fr);
  }
  .course-guide-holes .course-guide-holes-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
