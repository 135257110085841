<template>
  <div class="legend">
    <div class="year">
      <div class="total-rounds">
        <p class="bold">{{ $t('totalRounds') }} {{ normalYear.year }}</p>

        <p>
          {{ `${normalYear.totalNumberOfRounds.toLocaleString('sv-SE')}` }}
        </p>
      </div>

      <div class="row bold">
        <div>
          <use-svg
            svg="bb-arrow-icon"
            :class="{ 'svg--180-negative': differenceValue < 1 }" />
        </div>
        <div>
          {{
            `
            ${differenceValue > 0 ? '+' : ''}
            ${differenceValue}${unit}
            /
            ${differenceValue > 0 ? '+' : ''}
              ${differenceValueInPercentage}%
            `
          }}
        </div>
      </div>

      <div class="row regular">
        <div></div>
        {{ $t('comparedTo') }} {{ comparitativeYear.year }}
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import { difference, differenceInPercentage } from '../utils/calculations';
import LocalizationMixin from '../../../mixins/localization-mixin.vue';

export default {
  mixins: [LocalizationMixin],
  components: {
    UseSvg,
  },

  props: {
    comparitativeYear: [],
    normalYear: {},
    unit: {
      type: String,
      default: function () {
        this.$t('denomination');
      },
    },
  },

  data() {
    return {};
  },

  methods: {},

  computed: {
    differenceValue: function () {
      return difference(
        this.normalYear,
        this.comparitativeYear,
        'totalRounds'
      ).toLocaleString('sv-SE');
    },
    differenceValueInPercentage: function () {
      return differenceInPercentage(
        this.normalYear,
        this.comparitativeYear,
        'totalRounds'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.legend {
  margin-left: 5rem;
  border-top: 1px solid #dadedf;
  width: 80%;
  padding-top: 2rem;

  .bold {
    color: #131516;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
  }

  .regular {
    color: #131516;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }

  .year {
    display: flex;
    margin: 0.6rem auto;
    font-size: 14px;
    line-height: 20px;

    .row {
      display: grid;
      grid-template-columns: 2rem auto;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
}

.total-rounds {
  display: flex;
  font-size: 16px;
  gap: 2rem;
  line-height: 26px;
  margin-top: -1rem;
  padding-right: 8rem;
}
</style>
