<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    labels: {
      type: Array,
      default: () => { return []; },
    },
    data: {
      type: Object,
      default: () => {},
    },
    tooltips: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      chartdata: {
        labels: this.labels,
        datasets: [
          {
            label: 'Standard 3-delat',
            backgroundColor: '#2596be',
            data: this.data.standardThree,
            tooltips: this.tooltips.standardThree,
            categoryPercentage: 0.5,
            order: 1,
          },
          {
            label: 'Standard 4-delat',
            backgroundColor: '#9925be',
            data: this.data.standardFour,
            tooltips: this.tooltips.standardFour,
            categoryPercentage: 0.5,
            order: 1,
          },
          {
            label: 'Klubbunika 3-delat',
            backgroundColor: '#be4d25',
            data: this.data.customerThree,
            tooltips: this.tooltips.customerThree,
            categoryPercentage: 0.5,
            order: 1,
          },
          {
            label: 'Klubbunika 4-delat',
            backgroundColor: '#49be25',
            data: this.data.customerFour,
            tooltips: this.tooltips.customerFour,
            categoryPercentage: 0.5,
            order: 1,
          },
          {
            label: 'Total',
            backgroundColor: '#F7882F',
            data: this.data.total,
            tooltips: this.tooltips.total,
            categoryPercentage: 0,
            order: 1,
          },
        ],
      },
      options: {
        legend: {
          labels: {
            filter: function(label) {
              if (label.text !== 'Total') return true;
            },
          },
        },
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += data.datasets[tooltipItem.datasetIndex].tooltips[tooltipItem.index];
              return label;
            },
          },
        },
        maintainAspectRatio: false,
      },
    };
  },

  mounted () {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
