<!-- Printer Queues for Evry users to be able to see all printers for all users -->
<template>
  <div class="ontag">
    <div class="ontag--content">
      <h1 class="first-letter">{{ $t('printerQueueAllPrinters') }}</h1>
        <div class="ontag--utilitites input-group--inline" v-if="printerQueue">
          <div class="pagination-amount">
            <span class="first-letter">{{ $t('show') }}</span>
              <custom-select
                v-if="selectedIndexPageSize !== undefined"
                :required="false"
                @optionSelected="setPageSize"
                :selected="selectedIndexPageSize"
                :options="pageSizeOptions"/>
            <span class="row-lines">{{ $t('lines') }}</span>
          </div>
          <div class="search-wrapper">
            <div class="input">
              <label
                for="searchQuery"
                class="input__label label__inline first-letter">{{ $t('search') }}</label>
              <input
                id="searchQuery"
                class="input__input input__inline search-input"
                @keyup.enter="getVariatedPrinterQueueResults"
                v-model="searchQuery"
                type="text"
                name="searchQuery"/>
            </div>
            <base-button
              class="search-button"
              :text="$t('search')"
              :button-size-small="true"
              @click="getVariatedPrinterQueueResults" />
          </div>
        </div>
        <list-table
          :items="printerQueue.rows"
          :headers="printerQueue.columns"
          @variationResults="getVariatedPrinterQueueResults">
        </list-table>
        <results-pagination
          :first-row-on-page="pagination.firstRowOnPage"
          :last-row-on-page="pagination.lastRowOnPage"
          :row-count="pagination.rowCount"
          :page-size="pagination.pageSize"
          :page-count="pagination.pageCount"
          :current-page="pagination.currentPage"
          :next-page="getNextPage()"
          :prev-page="getPrevPage()"
          store="printerQueue"
          @currentPage="getVariatedPrinterQueueResults"/>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table';
import BaseButton from '@/components/form-elements/base-button';
import ResultsPagination from '@/components/results-pagination';
import CustomSelect from '@/components/form-elements/custom-select';

export default {
  name: 'PrinterQueues',
  components: {
    ListTable,
    BaseButton,
    ResultsPagination,
    CustomSelect,
  },
  data() {
    return {
      selectedIndexPageSize: undefined,
      searchQuery: '',
      sort: {},
      currentPage: 0,
      status: undefined,
      pageSizeOptions: [
        {
          value: '10',
          displayName: '10',
        },
        {
          value: '20',
          displayName: '20',
        },
        {
          value: '40',
          displayName: '40',
        },
        {
          value: '80',
          displayName: '80',
        },
      ],
      showDeleteMarkedPrinterJobsModal: false,
      showEmptyPrinterQueueModal: false,
    };
  },
  methods: {
    getPrevPage () {
      return this.$store.getters['users/previousPage'];
    },
    getNextPage () {
      return this.$store.getters['users/nextPage'];
    },
    getVariatedPrinterQueueResults (value) {
      if (value.sort) this.sort = value.sort;

      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length < value.filter.options.length && checked.length > 0) {
          if (checked[0].value === '1') {
            this.status = true;
          } else {
            this.status = false;
          }
        } else {
          this.status = undefined;
        }
      }

      if (value.pageSize) this.pageSize = value.pageSize;

      if(value.page) this.currentPage = value.page;

      this.$store.dispatch('printerQueue/fetchPrinterQueue', {
        sortColumn: this.sort.id,
        sortDirection: this.sort.direction,
        search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
        'Filter.status': this.status,
        pageSize: this.pageSize,
        page: this.searchQuery.length > 0 ? '1' : this.currentPage,
      });
    },
    setPageSize(size) {
      let pageSize = {
        pageSize: size.value,
      };
      this.currentPage = 1;
      this.getVariatedPrinterQueueResults(pageSize);
    },
    selectedPageSize(size) {
      let selectedSize = this.pageSizeOptions.findIndex(o => parseInt(o.value) === size);
      this.selectedIndexPageSize = selectedSize;
    },
    toggleDeleteMarkedPrinterJobsModal () {
      this.showDeleteMarkedPrinterJobsModal = !this.showDeleteMarkedPrinterJobsModal;
    },
  },
  computed: {
    printerQueue () {
      return this.$store.getters['printerQueue/printerQueue'];
    },
    pagination () {
      return this.$store.getters['printerQueue/pagination'];
    },
  },
  created() {
    this.$store.dispatch('printerQueue/fetchPrinterQueue', {
      sortColumn: this.sort.id,
      sortDirection: this.sort.direction,
      search: this.searchQuery.length > 0 ? this.searchQuery : undefined,
      'Filter.status': this.status,
      pageSize: this.pageSize,
      page: this.currentPage,
    }).then(() => {
      this.selectedPageSize(this.pagination.pageSize);
    });
  },
};
</script>
<style lang="scss">

</style>
