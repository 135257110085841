import Vue from 'vue';

const state = {
  scorecardOrderStatistics: [],
};

const mutations = {
  scorecardOrderStatistics(state, payload) {
    state.scorecardOrderStatistics = payload;
  },
};

const getters = {
  scorecardOrderStatistics: state => {
    return state.scorecardOrderStatistics;
  },
};

const actions = {
  fetchScorecardOrderStatistics: async context => {
    await fetch(
      context,
      'scorecardOrderStatistics/',
      'scorecardOrderStatistics'
    );
  },
};

async function fetch(context, path, commit) {
  try {
    let response = await Vue.axios.get(path);
    context.commit(commit, response.data.result);
  } catch (error) {
    console.error(error, error.response.data);
    // EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
