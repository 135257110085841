<template>
  <thead>
    <tr class="table-header">
      <th class="header-row">
        <p>{{ tableYear }}</p>
      </th>
      <th
        :class="{
          'member-category-padding header-row': !this.show,
          'header-row': this.show,
        }">
        <p>
          {{ $t('MemberCalculation.memberCategory') }}
        </p>
      </th>
      <th class="header-row">
        <div class="list-table__div">
          {{ $t('MemberCalculation.aliasExists') }}
          <tooltip-icon :description="$t('MemberCalculation.aliasExists')">
            <template #icon>
              <use-svg svg="white-info-icon" />
            </template>
          </tooltip-icon>
        </div>
      </th>

      <th class="header-row">
        <div class="list-table__div">
          {{ $t('MemberCalculation.groupLink') }}
          <tooltip-icon :description="$t('MemberCalculation.groupLink')">
            <template #icon>
              <use-svg svg="white-info-icon" />
            </template>
          </tooltip-icon>
        </div>
      </th>
      <th class="header-row">
        <div class="list-table__div">
          {{ $t('MemberCalculation.budgetQuantity') }}
          <tooltip-icon :description="$t('MemberCalculation.budgetQuantity')">
            <template #icon>
              <use-svg svg="white-info-icon" />
            </template>
          </tooltip-icon>
        </div>
      </th>
      <template v-for="(header, index) in headers">
        <th
          class="header-row"
          :key="index">
          <div class="list-table__div">
            {{ header.name }}
            <tooltip-icon :description="header.name">
              <template #icon>
                <use-svg svg="white-info-icon" />
              </template>
            </tooltip-icon>
          </div>
        </th>
      </template>
      <th
        @click="showTable()"
        class="show-table">
        <use-svg
          svg="arrow-right"
          :class="show ? 'svg--180-negative' : ''" />
      </th>
    </tr>
  </thead>
</template>

<script>
import useSvg from '@/components/use-svg';
import tooltipIcon from '@/components/businessboard/tooltip-icon';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
export default {
  name: 'membership-calculation-table-header',
  mixins: [LocalizationMixin],
  components: {
    useSvg,
    tooltipIcon,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    tableYear: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  methods: {
    showTable() {
      this.show = !this.show;
      this.$emit('show-table', this.show);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  height: 3.125rem;
  background-color: #373d3f;
  border-radius: 4px;
}

.header-row {
  color: #fff;
}
.member-category-padding {
  padding-right: 3rem;
}
.show-table {
  margin: 1rem;
  color: #fff;
}

thead {
  th {
    text-align: left;
    padding: 0 0.425rem;
    &:first-child {
      padding-left: 0.8rem;
    }
  }
  .list-table__div {
    max-height: 2.625rem;
    align-items: center;
  }
}
</style>
