<template>
  <div :class="getStepStyleClass()">
    <div
      v-if="this.stepStyle === 'horizontal'"
      class="te-stepper-items">
      <div
        v-for="item in localItems"
        :key="item.id"
        :selected="getIsSelected(item)"
        :progress="item.progress"
        class="te-step-item">
        <div class="te-step-item-title">
          {{ item.title }}
        </div>
        <div class="te-step-item-substeps">
          <div
            v-for="subitem in getProgressItems(item)"
            :key="subitem.id"
            :description="subitem.shortTitle ?? subitem.title"
            class="te-step-item-details">
            <div class="te-step-item-description">
              {{ subitem.description }}
              <span
                v-if="subitem.progress === 100"
                class="golf-specific-icons-circle-check-fill te-step-item-completed"></span>
            </div>
            <div class="te-step-item-progress-bar">
              <div
                class="te-step-item-progress"
                :style="{ width: getProgress(subitem.progress) }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="this.stepStyle === 'horizontal'"
      class="te-stepper-content">
      <slot></slot>
    </div>

    <div v-if="this.stepStyle === 'vertical'">
      <div
        v-for="item in localItems"
        :key="item.id"
        :selected="getIsSelected(item)"
        :progress="item.progress"
        class="te-step-item">
        <div class="te-step-item-progress">
          <div
            class="te-step-item-progress-circle"
            :progress="item.progress"></div>
          <div
            class="te-step-item-progress-line"
            :progress="item.progress"></div>
        </div>
        <div class="te-step-item-details">
          <div class="te-step-item-title">
            {{ item.title }}
          </div>
          <div class="te-step-item-description">
            {{ item.description }}
          </div>
          <div
            v-if="getIsSelected(item)"
            class="te-step-item-content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeStepper',
  watch: {
    items: {
      handler: function (newVal, oldVal) {
        this.localItem = newVal;
      },
      deep: true,
    },
  },
  props: {
    stepStyle: {
      type: String,
      default: 'horizontal',
    },
    items: {
      type: Array,
    },
    currentItem: {
      type: Object,
    },
  },
  data() {
    if (this.stepStyle === 'vertical') {
      for (let i of this.items) {
        if (i.items) {
          console.warn(
            'TeStepper: vertical stepper does not support subitems at the moment'
          );
          break;
        }
      }
    }
    return {
      localItems: this.items,
    };
  },
  methods: {
    getProgress(progress) {
      return progress + '%';
    },
    getIsSelected(item) {
      if (this.currentItem === undefined || this.currentItem === null) {
        return false;
      }
      return item.id === this.currentItem.id;
    },
    getProgressItems(item) {
      if (
        item.items !== undefined &&
        item.items !== null &&
        item.items.length > 0
      ) {
        return item.items;
      }
      return [item];
    },
    getStepStyleClass() {
      if (this.stepStyle === 'vertical') {
        return 'te-stepper te-stepper-vertical';
      }
      return 'te-stepper te-stepper-horizontal';
    },
  },
};
</script>

<style lang="scss">
.te-stepper {
  font-family: 'Tietoevry sans 1';
}
.te-stepper.te-stepper-vertical {
  display: flex;
  flex-direction: column;
  .te-step-item {
    display: flex;
    flex-direction: row;
    gap: 16px;
    .te-step-item-content {
      /* Paragraph text */
      font-family: 'Tietoevry Sans 1';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      padding-top: 16px;
    }
    .te-step-item-progress {
      width: 32px;
      min-width: 32px;
      max-width: 32px;
      display: flex;
      flex-direction: column;
      .te-step-item-progress-circle {
        transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        position: relative;
        aspect-ratio: 1/1;
        width: 100%;
        border-radius: 50%;
        border: 2px solid var(--Gray-Gray-7, #cbcccd);
      }
      .te-step-item-progress-circle[progress='100'] {
        background: var(--Surface-Surface-CTA-Primary, #1861f2);
        border-color: var(--Surface-Surface-CTA-Primary, #1861f2);
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          padding-top: 4px;
          transform: translate(-50%, -50%);
          font-family: 'tietoevry-icons' !important;
          font-weight: 100;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 22px;
          color: #fff;
          content: '\ea99';
          text-align: center;
          width: 100%;
          height: 100%;
        }
      }
      .te-step-item-progress-line {
        transition: background-color 0.25s;
        width: 2px;
        flex: 1;
        margin: auto;
        background: var(--Gray-Gray-8, #ddddde);
      }
      .te-step-item-progress-line[progress='100'] {
        background: var(--Surface-Surface-CTA-Primary, #1861f2);
      }
    }
    .te-step-item-progress .te-step-item-progress-circle::after {
      color: #fff;
    }
    .te-step-item-details {
      padding-bottom: 20px;
      .te-step-item-title {
        color: var(--Text-Text-Tertiary, #64676a);
        /* Caps label */
        font-family: 'Tietoevry Sans 1';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
      .te-step-item-description {
        color: var(--Text-Text-Tertiary, #64676a);
        /* Paragraph text */
        font-family: 'Tietoevry Sans 1';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    &:last-child {
      .te-step-item-progress-line {
        display: none;
      }
      .te-step-item-details {
        padding-bottom: 0px;
      }
    }
    &[progress='100'] {
      .te-step-item-details .te-step-item-title {
        color: var(--Text-Text-Primary, #1e2224);
      }
    }
    &[selected] {
      .te-step-item-progress-line[progress='100'] {
        background: var(--Gray-Gray-8, #ddddde);
      }
      .te-step-item-details .te-step-item-title {
        color: var(--Text-Text-Link, #1861f2);
      }
    }

    &[selected]:not([progress='100'])
      .te-step-item-progress
      .te-step-item-progress-circle {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: var(--Surface-Surface-CTA-Primary, #1861f2);
      }
      border-color: var(--Surface-Surface-CTA-Primary, #1861f2);
    }
  }
}
.te-stepper.te-stepper-horizontal {
  width: 100%;
  //padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  .te-stepper-items:after {
    content: '';
    position: absolute;
    bottom: 0px;
    height: 8px;
    width: 100%;
    background-color: #fff;
    z-index: -1;
    border-radius: 12px 12px 0px 0px;
  }
  .te-step-item:first-of-type {
    .te-step-item-details:first-of-type .te-step-item-progress-bar {
      border-radius: 12px 0px 0px 0px;
      overflow: hidden;
    }
  }
  .te-step-item:last-of-type {
    .te-step-item-details:last-of-type .te-step-item-progress-bar {
      border-radius: 0px 12px 0px 0px;
      overflow: hidden;
    }
  }
  .te-stepper-items {
    position: relative;
    width: 100%;
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 12px;
    .te-step-item {
      flex: 1;
      opacity: 0.6;
      display: flex;
      transition: flex 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      align-self: stretch;
      justify-items: flex-end;
      color: var(--text-text-tertiary, #64676a);
      .te-step-item-progress-bar {
        width: 100%;
        margin-top: 12px;
        display: block;
        height: 8px;
        align-items: center;
        justify-self: stretch;
        align-self: flex-end;
        background: var(--gray-gray-8, #ddddde);
        .te-step-item-progress {
          transition: width 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          display: block;
          height: 100%;
          background-color: #1861f2;
        }
      }
      .te-step-item-title {
        display: flex;
        flex: 1;
        justify-self: flex-end;
        align-items: flex-end;
        font-family: 'Tietoevry sans 1';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.6px;
        text-transform: uppercase;

        height: 17px;
      }
      .te-step-item-completed {
        margin-left: 5px;
        display: inline-flex;
        transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        font-size: 12px;
        color: #26d07c;
        width: 17px;
        height: 17px;
      }
      .te-step-item-substeps {
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;
        gap: 6px;
      }
      .te-step-item-details {
        display: flex;
        flex-direction: column;
        flex: 1;
        .te-step-item-description {
          display: flex;
          flex: 1;
          justify-self: flex-end;
          align-items: flex-end;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 16px;
          color: var(--text-text-primary, #1e2224);
          /* Label */
          font-family: 'Tietoevry sans 1';
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 133.333% */
        }
      }
    }
    .te-step-item-first {
      border-radius: 12px 0px 0px 0px;
      overflow: hidden;
    }
    .te-step-item-last {
      border-radius: 0px 12px 0px 0px;
      overflow: hidden;
    }
    .te-step-item[selected] {
      opacity: 1;
      flex: 3;
    }
  }
  .te-stepper-content {
    background-color: var(--surface-surface-primary, #fff);
    border-radius: 0px 0px 12px 12px;
  }
}
.te-stepper.te-stepper-horizontal
  .te-stepper-items
  .te-step-item
  .te-step-item-details[progress='0']
  .te-step-item-description {
  color: var(--text-text-tertiary, #64676a) !important;
}
</style>
