<template>
  <div class="ontag">
    <div class="clubspecific-order">
      <router-link :to="{ path: '/ontag/orderscorecard/order' }">
        <div class="cancel">&lt; Tillbaka till startsidan</div>
      </router-link>
      <div class="clubspecific-order__no-size-container">
        <div class="clubspecific-order__no-size-info">
          <h1>
            Stärk ert varumärke eller lyft fram era sponsorer med en egen
            design.
          </h1>
          <p>
            För att skapa en egen design på ert scorekort så hör ni av er till
            oss via knappen nedan “Kontakta oss”. Om ni redan har en design
            bifogar ni er skiss via formuläret. Efter det hör vi av oss för
            färdigställande.
          </p>
          <p>Mer info och mallar hittar ni här.</p>
          <a
            data-v-327bcb9a=""
            data-v-545f03a3=""
            href="/ontag/orderscorecard/clubspecific/changedesign ">
            Skapa eller ändra scorekortsdesign
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScorecardClubspecificNone',
  data() {
    return {};
  },
  methods: {
    loadWidget() {
      window.fwSettings = { widget_id: 44000004027 };
      const n = function () {
        n.q.push(arguments);
      };
      n.q = [];
      window.FreshworksWidget = n;
      let fdwidgetScript = document.createElement('script');
      fdwidgetScript.id = 'widgetScript';
      fdwidgetScript.setAttribute(
        'src',
        'https://widget.freshworks.com/widgets/44000004027.js'
      );
      fdwidgetScript.onload = this.fillWidget();
      document.head.appendChild(fdwidgetScript);
    },
    fillWidget() {
      let name = this.user.firstName + ' ' + this.user.lastName;
      window.FreshworksWidget('identify', 'ticketForm', {
        type: '\u00D6vrigt',
        subject:
          'Egendesignade scorekort via GolfOffice' +
          (this.user.club === undefined ? '' : ' från ' + this.user.club.name),
        name: name,
        email: this.user.email,
        custom_fields: {
          cf_klubbnamn: this.user.club === undefined ? '' : this.user.club.name,
        },
      });
      window.FreshworksWidget('hide', 'ticketForm', ['type', 'subject']);
    },
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
  watch: {
    // if this page is reloaded user object is not loaded yet. wait for it before loading widget
    user() {
      this.loadWidget();
    },
  },
  created() {},
  mounted() {
    if (Object.keys(this.user).length > 0) {
      if (document.getElementById('widgetScript') === null) {
        this.loadWidget();
      } else {
        // the widgetscript has been loaded before, but probably unmounted on destroy
        window.FreshworksWidget('boot');
        window.FreshworksWidget('show');
        this.fillWidget();
      }
    }
  },
  destroyed() {
    // unmount widget when leaving the page
    window.FreshworksWidget('destroy');
    // also remove script?
    // document.getElementById('widgetScript').outerHTML = '';
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 4.375rem 0 1rem 0;
  @include media('small') {
    margin: 3.3rem 0rem 1rem 15.7rem;
  }
}
.clubspecific-order {
  background-color: color(lightSand);
  padding: 40px 30px;

  &__no-size-container {
    background-image: url('../../assets/images/scorecard-clubspecific-example.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 80vh;
    width: 100%;
    max-width: 1200px;
    margin: measure(spacing-l) auto;
    border-radius: 3px;
    padding: measure(spacing-xl);
  }

  &__no-size-info {
    background-color: white;
    opacity: 0.95;
    border-radius: 3px;
    width: 75%;
    padding: 55px 70px;
  }
}

.clubspecific-order__no-size-info {
  a {
    text-decoration: underline;
  }

  a:active {
    font-weight: 600;
  }
}
</style>
