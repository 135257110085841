<template>
  <div class="ontag">
    <div class="clubspecific-order">
      <div
        class="cancel"
        @click="cancelOrder">
        &lt; {{ $t('backToStartPage') }}
      </div>
      <span
        v-if="isLoading"
        class="element--is-loading"></span>
      <div class="clubspecific-order__main-container">
        <div class="clubspecific-order__left-section">
          <div class="clubspecific-order__img-container">
            <img
              class="clubspecific-order__img"
              :src="scorecardorder.scorecardProofUrl"
              alt="" />
            <img
              v-if="scorecardorder.clubScorecardSize == 1"
              class="clubspecific-order__img"
              src="../../assets/images/sc-3p-blank.png"
              alt="scorecard blank" />
            <img
              v-else-if="scorecardorder.clubScorecardSize == 2"
              class="clubspecific-order__img"
              src="../../assets/images/sc-4p-blank.png"
              alt="scorecard blank" />
          </div>
          <modal-window
            :show="showModal"
            size="">
            <div class="modal__content">
              <img
                class="clubspecific-order__img"
                :src="scorecardorder.scorecardProofUrl"
                alt="" />
            </div>
            <div class="modal__content--actions">
              <base-button
                class="button--background-gray-dark"
                :text="$t('cancel')"
                @click="showModal = !showModal">
              </base-button>
            </div>
          </modal-window>
          <div class="clubspecific-order__left-buttons-container">
            <a :href="scorecardorder.scorecardProofUrl">
              <button class="clubspecific-order__download-button">
                <div>{{ $t('download') }}</div>
                <img src="../../assets/images/download.svg" />
              </button>
            </a>
            <router-link
              :to="{ path: '/ontag/orderscorecard/clubspecific/changedesign' }">
              <button class="clubspecific-order__edit-button">
                <div>{{ $t('changeDesign') }}</div>
                <img src="../../assets/images/pen.svg" />
              </button>
            </router-link>
            <button
              class="clubspecific-order__download-button"
              @click="toggleModal">
              <div>{{ $t('showScorecard') }}</div>
              <img src="../../assets/images/zoom.svg" />
            </button>
          </div>
        </div>
        <div
          v-if="club"
          class="clubspecific-order__right-section">
          <div class="clubspecific-order__step-container">
            <div class="clubspecific-order__step">
              <strong>1</strong> {{ $t('yourOrder') }}
            </div>
            <div class="clubspecific-order__step">
              <strong>2</strong> {{ $t('yourInfo') }}
            </div>
            <div class="clubspecific-order__step">
              <strong>3</strong> {{ $t('confirmation') }}
            </div>
          </div>
          <div class="clubspecific-order__order-detail-container">
            <h2 class="clubspecific-order--bold">{{ $t('yourDesign') }}</h2>
            <p v-if="scorecardorder.clubScorecardSize == 1">
              {{ $t('threeParted') }}, 110 x 273 mm
            </p>
            <p v-else-if="scorecardorder.clubScorecardSize == 2">
              {{ $t('fourParted') }}, 110 x 362 mm
            </p>
            <div class="clubspecific-order__quantity-container">
              <div class="clubspecific-order__quantity">
                <h4 class="clubspecific-order--bold">
                  {{ $t('amount') }} {{ $t('boxes') }}
                </h4>
                1 000 {{ $t('piecesSlashBox') }} <br />
                {{ $t('orderInfoMin') }}
                <br />
                {{ $t('orderInfoEven') }}
              </div>
              <div class="clubspecific-order__quantity-button-container">
                <div
                  :class="{
                    'clubspecific-order__quantity-container-disabled':
                      quantity <= 6,
                  }"
                  class="clubspecific-order__quantity-button"
                  id="decrease"
                  @click="decreaseValue"
                  value="Decrease Value">
                  -
                </div>
                <input
                  min="6"
                  type="number"
                  id="number"
                  v-model="quantity" />
                <div
                  @click="increaseValue"
                  class="clubspecific-order__quantity-button"
                  id="increase"
                  value="Increase Value">
                  +
                </div>
              </div>
            </div>
            <h4 class="clubspecific-order__heading">
              {{ $t('outsideCapsChoice') }}
            </h4>
            <div class="clubspecific-order__radio-button-container">
              <label>
                <div class="clubspecific-order__radio-option">
                  <input
                    type="radio"
                    name="radio"
                    :value="1"
                    v-model="surfaceTypeValue" />
                  <span>
                    {{ $t('mat') }} {{ $t('scorecardSurface') }}
                    {{
                      club.scoreCardSurfaceType === 1
                        ? $t('standardScorecardSurface')
                        : ''
                    }}
                  </span>
                  <a class="tooltip">
                    <img src="../../assets/images/info.svg" />
                    <span class="tooltiptext">
                      {{ $t('matSurfaceDescription') }}
                    </span>
                  </a>
                </div>
              </label>

              <label>
                <div class="clubspecific-order__radio-option">
                  <input
                    type="radio"
                    name="radio"
                    :value="2"
                    v-model="surfaceTypeValue" />
                  <span>
                    {{ $t('blank') }} {{ $t('scorecardSurface') }}
                    {{
                      club.scoreCardSurfaceType !== 1
                        ? $t('standardScorecardSurface')
                        : ''
                    }}
                  </span>
                  <a class="tooltip">
                    <img src="../../assets/images/info.svg" />
                    <span class="tooltiptext">
                      {{ $t('blankSurfaceDescription') }}
                    </span>
                  </a>
                </div>
              </label>
            </div>

            <h4 class="clubspecific-order__heading">
              {{ $t('deliveryWeek') }}
            </h4>
            <div class="clubspecific-order__choice-container">
              <div
                v-for="week in deliveryweeks"
                :key="week.id"
                class="clubspecific-order__choice-outer"
                :style="[
                  week.deadline <= today
                    ? { display: 'none' }
                    : { display: 'block' },
                ]">
                <div
                  v-if="week.deadline >= today"
                  class="clubspecific-order__choice">
                  <button
                    v-if="week.deadline >= today"
                    :style="[
                      deliveryWeekSpecificId === week.id
                        ? { backgroundColor: '#304834', color: 'white' }
                        : {},
                    ]"
                    @click="selectDeliveryWeek(week)">
                    <p class="clubspecific-order--bold">
                      {{ $t('weekShort') }} {{ week.week }}
                    </p>
                    <p class="clubspecific-order__deadline">
                      {{ $t('orderBy') }} {{ week.deadlineText }}
                    </p>
                  </button>
                </div>
                <div class="clubspecific-order__choice-week-info">
                  <div v-if="week.week === 13">
                    {{ $t('midsummerWeek') }}
                  </div>
                </div>
              </div>
              <div v-if="user.superUser">
                <div
                  v-for="week in deliveryweeks"
                  :key="week.id"
                  class="clubspecific-order__choice-outer"
                  :style="[
                    week.deadline > today
                      ? { display: 'none' }
                      : { display: 'block' },
                  ]">
                  <div class="clubspecific-order__choice">
                    <button
                      v-if="week.deadline < today"
                      :style="[
                        deliveryWeekSpecificId === week.id
                          ? { backgroundColor: '#304834', color: 'white' }
                          : {},
                      ]"
                      @click="selectDeliveryWeek(week)">
                      <p class="clubspecific-order--bold">
                        {{ $t('weekShort') }} {{ week.week }}
                      </p>
                      <p class="clubspecific-order__deadline">
                        ({{ $t('deadlinePassed') }}) {{ week.deadlineText }}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="clubspecific-order__your-order-container">
              <h3>{{ $t('yourOrder') }}</h3>
              <table>
                <tr v-if="scorecardorder.clubScorecardSize == 1">
                  <td>
                    {{ $t('threeParted') }} <br />
                    110 x 273 mm
                  </td>
                  <td>
                    {{ (quantity * 1000).toLocaleString() }} {{ $t('piece')
                    }}<br />
                    /
                    <strong v-if="quantity > 1">
                      {{ quantity }} {{ $t('boxes') }}
                    </strong>
                    <strong v-else-if="quantity === 1">
                      {{ quantity }} {{ $t('box') }}
                    </strong>
                  </td>
                  <td>
                    <span
                      class="clubspecific-order__edit-unitCost"
                      v-if="user.superUser && editUnitCost === true">
                      <span @click="editUnitCost = false">x</span>
                      <input
                        @keyup="calculateCostWithoutShipping"
                        v-model="unitPrice"
                        type="number" />
                      {{ $t('priceSlashAmount') }}
                    </span>
                    <span
                      v-else
                      @click="editUnitCost = true">
                      {{ unitPrice }} {{ $t('priceSlashAmount') }}
                    </span>
                  </td>
                  <td>{{ costWithoutShipping.toLocaleString() }} kr</td>
                </tr>
                <tr v-else-if="scorecardorder.clubScorecardSize == 2">
                  <td>
                    {{ $t('fourParted') }} <br />
                    110 x 362 mm
                  </td>
                  <td>
                    {{ (quantity * 1000).toLocaleString() }} {{ $t('piece')
                    }}<br />
                    /
                    <strong v-if="quantity > 1">
                      {{ quantity }} {{ $t('boxes') }}
                    </strong>
                    <strong v-else-if="quantity === 1">
                      {{ quantity }} {{ $t('box') }}
                    </strong>
                  </td>
                  <td>
                    <span
                      class="clubspecific-order__edit-unitCost"
                      v-if="user.superUser && editUnitCost === true">
                      <span @click="editUnitCost = false">x</span>
                      <input
                        @keyup="calculateTotalCost"
                        v-model="unitPrice"
                        type="text" />
                      {{ $t('priceSlashAmount') }}
                    </span>
                    <span
                      v-else
                      @click="editUnitCost = true">
                      {{ unitPrice }} {{ $t('priceSlashAmount') }}
                    </span>
                  </td>
                  <td>{{ costWithoutShipping.toLocaleString() }} kr</td>
                </tr>
              </table>
              <div v-if="deliveryWeek === 0">
                <p class="clubspecific-order__delivery-info-text">
                  {{ $t('sentBy') }} 3 {{ $t('weekdays') }}
                </p>
                <span class="font-grey">{{ $t('signingInformation') }} </span>
              </div>
              <div v-else-if="deliveryWeek > 0">
                <p class="clubspecific-order__delivery-info-text">
                  {{ $t('delivery') }} {{ $t('week') }} {{ deliveryWeek }}
                </p>
                <span class="font-grey">{{ $t('signingInformation') }} </span>
              </div>
            </div>
            <div
              v-if="markNextOrderChanged && user.superUser"
              class="clubspecific-order__change-price-container">
              <div class="clubspecific-order__change-price">
                <label>
                  {{ $t('customerPriceForChange') }}
                </label>
                <input
                  v-model="changePrice"
                  type="number" /><span
                  class="clubspecific-order__change-price-suffix">
                  {{ $t('priceExclVat') }}
                </span>
              </div>
              <div class="clubspecific-order__change-price">
                <label>
                  {{ $t('costForChange') }}
                </label>
                <input
                  v-model="changeCost"
                  type="number" /><span
                  class="clubspecific-order__change-price-suffix">
                  {{ $t('priceExclVat') }}
                </span>
              </div>
            </div>
            <div class="clubspecific-order__approval">
              <input
                v-model="approval"
                type="checkbox" />
              <span>{{ $t('approveCorrecture') }}</span>
            </div>
            <div class="clubspecific-order__cost-container">
              <div>
                <span v-if="postalCodeValid">
                  {{ $t('freightCostToZip') }}
                  {{
                    scorecardorderaddresses.deliveryAddress.postalCode
                      .replace(/\W/g, '')
                      .replace(/(...)/, '$1 ')
                  }}
                </span>
                <span v-else>{{ $t('shippingNextStep') }}</span>
                <a :class="[postalCodeValid ? 'tooltip' : 'tooltip--notext']">
                  <img src="../../assets/images/info.svg" />
                  <span
                    v-if="postalCodeValid"
                    class="tooltiptext">
                    {{ $t('changeShippingNextStep') }}
                  </span>
                </a>
              </div>
              <div v-if="postalCodeValid">
                <span
                  class="clubspecific-order__edit-shippingCost"
                  v-if="user.superUser && editShippingCost === true">
                  <span @click="editShippingCost = false">x</span>
                  <input
                    @keyup="calculateTotalCost"
                    v-model="customerShippingCost"
                    type="number" />
                  kr
                </span>
                <span
                  v-else
                  @click="editShippingCost = true">
                  {{ customerShippingCost.toLocaleString() }} kr
                </span>
              </div>
            </div>
            <div class="clubspecific-order__cost-container">
              <div>
                <h2>{{ $t('sum') }}</h2>
                <span class="clubspecific-order__cost-container-sum-text">{{
                  $t('vatExcluded')
                }}</span>
              </div>
              <div v-if="postalCodeValid">
                <h2>{{ this.totalCost.toLocaleString() }} kr</h2>
              </div>
              <div v-else>
                <h2>{{ costWithoutShipping.toLocaleString() }} kr</h2>
              </div>
            </div>
            <div class="clubspecific-order__flow-button-container">
              <div class="clubspecific-order__cancel-button">
                <button @click="cancelOrder">
                  {{ $t('cancel') }}
                </button>
              </div>
              <router-link
                :disabled="
                  deliveryWeekSpecificId === '' ||
                  deliveryWeek === undefined ||
                  quantity < 4 ||
                  !approval
                "
                :to="{ path: '/ontag/orderscorecard/clubspecific-step-2' }"
                class="clubspecific-order__continue-button">
                <button
                  @click="setSelectedState"
                  :disabled="
                    surfaceTypeValue === 0 ||
                    deliveryWeekSpecificId === '' ||
                    deliveryWeek === undefined ||
                    quantity < 4 ||
                    !approval
                  ">
                  Fortsätt
                </button>
              </router-link>
            </div>
            <div class="clubspecific-order__text-block">
              <p>{{ $t('scorecardInformation') }}</p>
            </div>
            <div class="clubspecific-order__collapse-container">
              <collapsible
                class="clubspecific-order__collapse"
                :isOpen="false">
                <div
                  @click="toggleCollapse('details')"
                  slot="trigger"
                  class="clubspecific-order__collapse-trigger">
                  <strong>{{ $t('details') }} </strong>
                  <span
                    class="clubspecific-order__expand"
                    v-if="!collapsed.includes('details')">
                    +
                  </span>
                  <span
                    class="clubspecific-order__expand"
                    v-else>
                    -
                  </span>
                </div>
                <div class="clubspecific-order__collapse-content">
                  <table>
                    <tr>
                      <td class="font-grey">{{ $t('paperCaps') }}</td>
                      <td>{{ $t('waterResistant') }}</td>
                    </tr>
                    <tr>
                      <td class="font-grey">{{ $t('insideCaps') }}</td>
                      <td>
                        {{ $t('mat') }}, {{ $t('white') }} {{ $t('surface') }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-grey">{{ $t('printCaps') }}</td>
                      <td>{{ $t('flexPrint') }}</td>
                    </tr>
                  </table>
                </div>
              </collapsible>
              <collapsible
                class="clubspecific-order__collapse"
                :isOpen="false">
                <div
                  @click="toggleCollapse('terms')"
                  slot="trigger"
                  class="clubspecific-order__collapse-trigger">
                  <strong>{{ $t('terms') }}</strong>
                  <span
                    class="clubspecific-order__expand"
                    v-if="!collapsed.includes('terms')">
                    +
                  </span>
                  <span
                    class="clubspecific-order__expand"
                    v-else>
                    -
                  </span>
                </div>
                <div class="clubspecific-order__collapse-content">
                  <span class="clubspecific-order__terms-row font-grey">
                    <div class="clubspecific-order__pdf-svg-container">
                      <img src="../../assets/images/pdf.svg" />
                    </div>
                    <a
                      :href="terms.termsUrl"
                      target="_blank">
                      {{ $t('ourTerms') }}&nbsp;
                    </a>
                    ({{ $t('opensInNewTab') }})
                  </span>
                </div>
              </collapsible>
            </div>
          </div>
        </div>
      </div>
      <div class="clubspecific-order__more-scorecards-container">
        <h2 class="clubspecific-order--bold">{{ $t('moreScorecard') }}</h2>
        <router-link :to="{ path: '/ontag/orderscorecard/standard' }">
          <button class="clubspecific-order__bottom-button">
            <img src="../../assets/images/sc-4p-2023.jpg" />
          </button>
        </router-link>
        <p>
          <strong>{{ $t('standardScorecard') }}</strong>
          <br />
          <span>{{ $t('surfaceInfo') }}</span>
        </p>
      </div>
      <div class="clubspecific-order__background-wave">
        <img
          src="../../assets/images/wave-background.svg"
          alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// import UseSvg from '@/components/use-svg'
import 'vue-collapsible-component/lib/vue-collapsible.css';
import Collapsible from 'vue-collapsible-component';
import LocalizationMixin from '../../mixins/localization-mixin.vue';
import ModalWindow from '@/components/modal/modal';
import BaseButton from '@/components/form-elements/base-button';

export default {
  name: 'ScorecardClubspecificOrder',
  mixins: [LocalizationMixin],
  components: {
    // UseSvg,
    Collapsible,
    ModalWindow,
    BaseButton,
  },

  data() {
    return {
      isLoading: true,
      collapsed: [],
      quantity: 6,
      deliveryWeek: {},
      changePrice: 0,
      changeCost: 0,
      deliveryWeekSpecificId: '',
      postalCode: 0,
      postalCodeFull: 0,
      postalCodeValid: false,
      costWithoutShipping: 0,
      customerShippingCost: 0,
      kolli: 0,
      totalCost: undefined,
      editUnitCost: false,
      editShippingCost: false,
      unitPrice: 0,
      unitCostPrice: 0,
      approval: false,
      surfaceTypeValue: undefined,
      changeOfSurface: false,
      showModal: false,
    };
  },

  methods: {
    toggleCollapse(item) {
      if (!this.collapsed.includes(item)) {
        this.collapsed.push(item);
      } else {
        this.collapsed.pop(item);
      }
    },

    increaseValue() {
      let value = parseInt(document.getElementById('number').value, 10);
      value = isNaN(value) ? 0 : value;
      value = value + 2;
      document.getElementById('number').value = value;
      this.quantity = value;
    },
    decreaseValue() {
      let value = parseInt(document.getElementById('number').value, 10);
      value = isNaN(value) ? 0 : value;
      if (this.quantity > 4) {
        value = value - 2;
      }
      document.getElementById('number').value = value;
      this.quantity = value;
    },
    selectDeliveryWeek(week) {
      this.deliveryWeekSpecificId = week.id;
      this.deliveryWeek = week.week;
    },
    calculateCostWithoutShipping() {
      this.costWithoutShipping =
        parseFloat(this.unitPrice) * (this.quantity * 1000);
      this.calculateTotalCost();
    },
    calculateShippingCost() {
      let cost = 0;
      let halfPallet = false;
      let fullPallet = false;
      let numberOfFullPallets = 0;
      let count = this.quantity;
      let loopCount = this.quantity;

      if (count > 0) {
        while (count !== 0) {
          if (count % 54 === 0) {
            numberOfFullPallets = numberOfFullPallets + 1;
            loopCount = this.quantity - numberOfFullPallets * 54;
          }
          count = count - 1;
        }
      }

      if (loopCount >= 7 && loopCount <= 16) {
        halfPallet = true;
      } else if ((loopCount >= 17 && loopCount <= 54) || loopCount === 0) {
        fullPallet = true;
      }

      if (loopCount === 1 || loopCount === 2) {
        this.kolli = 1;
      } else if (loopCount === 3 || loopCount === 4) {
        this.kolli = 2;
      } else if (loopCount === 5 || loopCount === 6) {
        this.kolli = 3;
      } else if (loopCount === 0) {
        this.kolli = 0;
      }

      if (loopCount >= 0 && loopCount <= 6) {
        let kolliPrice =
          this.scorecardordershippingprice.packagePriceExVAT * this.kolli;
        if (numberOfFullPallets > 0) {
          if (this.postalCode >= 10 && this.postalCode <= 19) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip10to19PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip10to19PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 20 && this.postalCode <= 27) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip20to27PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip20to27PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 28 && this.postalCode <= 39) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip28to39PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip28to39PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 40 && this.postalCode <= 51) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip40to51PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip40to51PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 52 && this.postalCode <= 57) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip52to57PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip52to57PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 58 && this.postalCode <= 61) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip58to61PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip58to61PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (parseInt(this.postalCode) === 62) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip62PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip62PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 63 && this.postalCode <= 76) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip63to76PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip63to76PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 77 && this.postalCode <= 82) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip77to82PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip77to82PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 83 && this.postalCode <= 88) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip83to88PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip83to88PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 89 && this.postalCode <= 93) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip89to93PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip89to93PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 94 && this.postalCode <= 98) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip94to98PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip94to98PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          }
        } else {
          cost =
            kolliPrice +
            (kolliPrice / 100) * this.scorecardordershippingprice.freight;
        }
      } else if (this.postalCode >= 10 && this.postalCode <= 19 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip10to19PriceHalf +
          this.scorecardordershippingprice.zip10to19PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip10to19PriceHalf +
            this.scorecardordershippingprice.zip10to19PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 10 && this.postalCode <= 19 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip10to19PriceFull +
          this.scorecardordershippingprice.zip10to19PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip10to19PriceFull +
            this.scorecardordershippingprice.zip10to19PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 20 && this.postalCode <= 27 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip20to27PriceHalf +
          this.scorecardordershippingprice.zip20to27PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip20to27PriceHalf +
            this.scorecardordershippingprice.zip20to27PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (
        this.postalCode >= 20 &&
        this.postalCode <= 27 &&
        fullPallet &&
        loopCount !== 0
      ) {
        cost =
          this.scorecardordershippingprice.zip20to27PriceFull +
          this.scorecardordershippingprice.zip20to27PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip20to27PriceFull +
            this.scorecardordershippingprice.zip20to27PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 28 && this.postalCode <= 39 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip28to39PriceHalf +
          this.scorecardordershippingprice.zip28to39PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip28to39PriceHalf +
            this.scorecardordershippingprice.zip28to39PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 28 && this.postalCode <= 39 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip28to39PriceFull +
          this.scorecardordershippingprice.zip28to39PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip28to39PriceFull +
            this.scorecardordershippingprice.zip28to39PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 40 && this.postalCode <= 51 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip40to51PriceHalf +
          this.scorecardordershippingprice.zip40to51PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip40to51PriceHalf +
            this.scorecardordershippingprice.zip40to51PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 40 && this.postalCode <= 51 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip40to51PriceFull +
          this.scorecardordershippingprice.zip40to51PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip40to51PriceFull +
            this.scorecardordershippingprice.zip40to51PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 52 && this.postalCode <= 57 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip52to57PriceHalf +
          this.scorecardordershippingprice.zip52to57PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip52to57PriceHalf +
            this.scorecardordershippingprice.zip52to57PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 52 && this.postalCode <= 57 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip52to57PriceFull +
          this.scorecardordershippingprice.zip52to57PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip52to57PriceFull +
            this.scorecardordershippingprice.zip52to57PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 58 && this.postalCode <= 61 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip58to61PriceHalf +
          this.scorecardordershippingprice.zip58to61PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip58to61PriceHalf +
            this.scorecardordershippingprice.zip58to61PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 58 && this.postalCode <= 61 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip58to61PriceFull +
          this.scorecardordershippingprice.zip58to61PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip58to61PriceFull +
            this.scorecardordershippingprice.zip58to61PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (parseInt(this.postalCode) === 62 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip62PriceHalf +
          this.scorecardordershippingprice.zip62PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip62PriceHalf +
            this.scorecardordershippingprice.zip62PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (parseInt(this.postalCode) === 62 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip62PriceFull +
          this.scorecardordershippingprice.zip62PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip62PriceFull +
            this.scorecardordershippingprice.zip62PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 63 && this.postalCode <= 76 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip63to76PriceHalf +
          this.scorecardordershippingprice.zip63to76PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip63to76PriceHalf +
            this.scorecardordershippingprice.zip63to76PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 63 && this.postalCode <= 76 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip63to76PriceFull +
          this.scorecardordershippingprice.zip63to76PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip63to76PriceFull +
            this.scorecardordershippingprice.zip63to76PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 77 && this.postalCode <= 82 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip77to82PriceHalf +
          this.scorecardordershippingprice.zip77to82PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip77to82PriceHalf +
            this.scorecardordershippingprice.zip77to82PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 77 && this.postalCode <= 82 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip77to82PriceFull +
          this.scorecardordershippingprice.zip77to82PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip77to82PriceFull +
            this.scorecardordershippingprice.zip77to82PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 83 && this.postalCode <= 88 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip83to88PriceHalf +
          this.scorecardordershippingprice.zip83to88PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip83to88PriceHalf +
            this.scorecardordershippingprice.zip83to88PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 83 && this.postalCode <= 88 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip83to88PriceFull +
          this.scorecardordershippingprice.zip83to88PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip83to88PriceFull +
            this.scorecardordershippingprice.zip83to88PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 89 && this.postalCode <= 93 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip89to93PriceHalf +
          this.scorecardordershippingprice.zip89to93PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip89to93PriceHalf +
            this.scorecardordershippingprice.zip89to93PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 89 && this.postalCode <= 93 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip89to93PriceFull +
          this.scorecardordershippingprice.zip89to93PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip89to93PriceFull +
            this.scorecardordershippingprice.zip89to93PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 94 && this.postalCode <= 98 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip94to98PriceHalf +
          this.scorecardordershippingprice.zip94to98PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip94to98PriceHalf +
            this.scorecardordershippingprice.zip94to98PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 94 && this.postalCode <= 98 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip94to98PriceFull +
          this.scorecardordershippingprice.zip94to98PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip94to98PriceFull +
            this.scorecardordershippingprice.zip94to98PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      }
      this.customerShippingCost = Math.round(cost);
      this.scorecardordertempstate.customerShippingCost =
        this.customerShippingCost;
    },

    calculateTotalCost() {
      let cost = parseFloat(this.unitPrice) * (this.quantity * 1000);
      cost = cost + parseInt(this.customerShippingCost);
      this.totalCost = Math.round(cost);
      this.scorecardordertempstate.totalCost = this.totalCost;
      this.scorecardordertempstate.customerShippingCost =
        this.customerShippingCost;
    },

    cancelOrder() {
      if (this.deliveryWeekSpecificId !== 'undefined' || this.quantity !== 4) {
        if (confirm(this.$t('confirmCancel'))) {
          this.restoreState();
          this.$router.push('/ontag/orderscorecard/order');
        }
      } else {
        this.$router.push('/ontag/orderscorecard/order');
      }
    },

    toggleModal() {
      this.showModal = !this.showModal;
    },
    setSelectedState() {
      if (this.scorecardorder.clubScorecardSize === '1') {
        this.scorecardordertempstate.scorecardType = 3;
      } else if (this.scorecardorder.clubScorecardSize === '2') {
        this.scorecardordertempstate.scorecardType = 4;
      }
      this.scorecardordertempstate.surfaceType = this.surfaceTypeValue;
      this.scorecardordertempstate.changeOfSurface = this.changeOfSurface;
      this.scorecardordertempstate.quantity = this.quantity;
      this.scorecardordertempstate.changePrice = this.changePrice;
      this.scorecardordertempstate.changeCost = this.changeCost;
      this.scorecardordertempstate.deliveryWeekSpecificId =
        this.deliveryWeekSpecificId;
      this.scorecardordertempstate.deliveryWeek = this.deliveryWeek;
      this.scorecardordertempstate.unitPrice = this.unitPrice;
      this.scorecardordertempstate.unitCostPrice = this.unitCostPrice;
      this.scorecardordertempstate.totalCost = this.totalCost;
      this.scorecardordertempstate.customerShippingCost =
        this.customerShippingCost;
    },
    restoreState() {
      this.scorecardordertempstate.quantity = 1;
      this.scorecardordertempstate.deliveryWeekSpecificId = '';
      this.scorecardordertempstate.deliveryWeek = undefined;
      this.scorecardordertempstate.unitPrice = 0;
      this.scorecardordertempstate.unitCostPrice = 0;
      this.scorecardordertempstate.totalCost = 0;
      this.scorecardordertempstate.customerShippingCost = 0;
      this.scorecardordertempstate.surfaceType = undefined;
      this.scorecardordertempstate.changeOfSurface = false;
    },
  },
  computed: {
    today() {
      let today = new Date();
      today.setUTCHours(0, 0, 0, 0);
      let now = today.toISOString().split('.')[0];
      return now;
    },
    scorecardordertempstate() {
      return this.$store.getters['scorecardOrder/scorecardordertempstate'];
    },
    scorecardorder() {
      return this.$store.getters['scorecardOrder/scorecardorder'];
    },
    scorecardordershippingprice() {
      return this.$store.getters['scorecardOrder/scorecardordershippingprice'];
    },
    scorecardorderaddresses() {
      return this.$store.getters['scorecardOrder/scorecardorderaddresses'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    terms() {
      return this.$store.getters['scorecardOrder/terms'];
    },
    deliveryweeks() {
      return this.$store.getters['scorecardOrder/deliveryweeks'];
    },
    markNextOrderChanged() {
      return this.$store.getters['scorecardOrder/markNextOrderChanged'];
    },
    clubScorecardSize() {
      return this.$store.getters['club/clubs'];
    },
    club() {
      return this.$store.getters['clubs/club'];
    },
  },
  watch: {
    quantity() {
      if (this.quantity < 6) {
        this.quantity = 6;
        return;
      }

      if (this.quantity % 2 !== 0) {
        this.quantity = Math.ceil(this.quantity / 2) * 2;
      }

      this.scorecardordertempstate.quantity = this.quantity;
      this.scorecardorder.kolli = this.kolli;
      this.calculateCostWithoutShipping();
      this.calculateShippingCost();
      this.calculateTotalCost();
    },
    deliveryWeek() {
      this.scorecardorder.deliveryWeek = this.deliveryWeek;
    },
    unitPrice() {
      this.calculateCostWithoutShipping();
    },
    surfaceTypeValue() {
      // Watcher som är till för changeOfSurface (tbc)
      if (this.surfaceTypeValue !== this.club.scoreCardSurfaceType) {
        this.changeOfSurface = true;
      } else {
        this.changeOfSurface = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch('clubs/fetchUserClub').then(() => {
      if (
        this.scorecardordertempstate.surfaceType === undefined ||
        this.scorecardordertempstate.surfaceType === 0
      ) {
        this.surfaceTypeValue = this.club.scoreCardSurfaceType;
      } else {
        this.surfaceTypeValue = this.scorecardordertempstate.surfaceType;
      }
    });
    this.$store
      .dispatch('scorecardOrder/fetchScorecardOrder')
      .then(() => {
        this.$store.dispatch('scorecardOrder/fetchTerms');
        this.$store
          .dispatch('scorecardOrder/fetchScorecardOrderAddresses')
          .then(() => {
            if (this.scorecardorderaddresses.deliveryAddress.postalCode) {
              this.postalCode =
                this.scorecardorderaddresses.deliveryAddress.postalCode.slice(
                  0,
                  2
                );
              this.postalCodeFull =
                this.scorecardorderaddresses.deliveryAddress.postalCode;
              if (this.postalCodeFull > 9999 && this.postalCodeFull < 100000) {
                this.postalCodeValid = true;
              }
            }
          });
        if (this.scorecardorder.clubScorecardSize === '1') {
          this.unitPrice =
            this.scorecardorder.priceSettings.threePartPriceCustomerSpecific;
          this.unitCostPrice =
            this.scorecardorder.priceSettings.threePartCostPriceCustomerSpecific;
        } else if (this.scorecardorder.clubScorecardSize === '2') {
          this.unitPrice =
            this.scorecardorder.priceSettings.fourPartPriceCustomerSpecific;
          this.unitCostPrice =
            this.scorecardorder.priceSettings.fourPartCostPriceCustomerSpecific;
        }
        this.$store.dispatch('scorecardOrder/fetchDeliveryWeeks');
        this.$store
          .dispatch('scorecardOrder/fetchScorecardOrderShippingPrice')
          .then(() => {
            this.calculateShippingCost();
            this.calculateTotalCost();
          })
          .then(() => {
            this.$store
              .dispatch('scorecardOrder/fetchMarkNextOrderChanged')
              .then(() => {});
          });
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.deliveryWeek = this.scorecardordertempstate.deliveryWeek;
    this.changePrice = this.scorecardordertempstate.changePrice;
    this.changeCost = this.scorecardordertempstate.changeCost;
    this.deliveryWeekSpecificId =
      this.scorecardordertempstate.deliveryWeekSpecificId;
    if (this.scorecardordertempstate.quantity >= 4) {
      this.quantity = this.scorecardordertempstate.quantity;
    } else {
      this.quantity = 4;
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 4.375rem 0 1rem 0;
  @include media('small') {
    margin: 3.3rem 0rem 1rem 15.7rem;
  }
}
.clubspecific-order {
  // background-color: color(lightSand);
  background: linear-gradient(to top, white 500px, color(lightSand) 500px);
  padding: 40px 30px;
  &__main-container {
    display: flex;
    margin: 20px auto 0px;
    max-width: 1200px;
    font-size: 14px;
  }

  &__left-section {
    border-radius: 3px;
    width: 60%;
    height: 100%;
    background-color: white;
    padding: 66px measure(spacing-xxl);
  }
  &__img-container {
    width: 100%;
  }
  &__img {
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.15);
    width: 100%;
    margin: measure(spacing-s) 0px;
  }
  &__change-price-container {
    margin-bottom: 5px;
    align-items: center;
    justify-content: flex-start;
  }
  &__change-price {
    margin-bottom: 5px;
  }
  &__change-price-suffix {
    font-size: 11px;
  }

  &__left-buttons-container {
    display: flex;

    a:hover {
      text-decoration: none;
    }
  }
  &__download-button,
  &__edit-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid color(jord);
    border-radius: 3px;
    padding: measure(spacing-xs) measure(spacing-l);
    background-color: transparent;
    margin-right: measure(spacing-m);
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    &:active {
      border: 1px solid color(jord);
      box-shadow: none;
    }
    img {
      margin-left: measure(spacing-xs);
    }
  }
  &__img-select-container {
    display: flex;
    justify-content: flex-start;
    margin-top: measure(spacing-l);
  }

  &__img-select {
    width: 25%;
    margin-right: measure(spacing-l);
    height: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    img {
      width: 100%;
    }
  }

  &__right-section {
    width: 40%;
    margin: 0px 18px;
  }

  &__step-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    :first-child {
      border: 1px solid black;
    }
  }

  &__step {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    width: 32%;
    text-align: center;
    padding: measure(spacing-xs);
    border-radius: 2px;

    :first-child {
      border: none;
    }
  }

  &__order-detail-container {
    background-color: white;
    padding: 42px 38px;
    margin-top: 30px;
    border-radius: 3px;
  }

  &__choice-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__radio-button-container {
    display: flex;
    flex-direction: column;
    label > div > input {
      width: 25px;
      height: 25px;
      border: 2px solid black;
      border-radius: 3px;
      text-align: unset;
      margin-left: 0px;
      margin-top: 5px;
      margin-right: 0px;
    }
  }
  &__radio-option {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
      margin-left: 10px;
      margin-top: 2px;
    }
  }

  &__heading {
    margin: measure(spacing-xxl) 0px measure(spacing-s);
  }
  &__choice-outer {
    margin: 0 0 measure(spacing-s) 0;
  }
  &__choice {
    max-width: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color(lightSand);
    border-radius: 3px;
    line-height: 24px;
    border: none;
    font-size: 14px;
    border-radius: 3px;

    button {
      padding: measure(spacing-s);
      width: 100%;
      border: none;
      border-radius: 3px;
      background-color: transparent;
      text-align: left;
    }

    h4 {
      margin: 0;
    }

    p {
      margin: 0;
    }
    p:first-child {
      margin-bottom: measure(spacing-xs);
    }
    span {
      background-color: color(info-background);
      margin-right: 10px;
    }
  }

  &__choice:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  &__choice-week-info {
    margin-top: measure(spacing-xs);
    text-align: center;
    font-size: 10px;
    min-height: 12px;
  }
  &__deadline {
    font-size: 10px;
  }
  &__quantity-container {
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      margin: 0 0 10px;
    }
    @include media('large') {
      flex-direction: row;
    }
  }

  &__quantity-container-disabled {
    opacity: 0.3;
  }
  &__quantity {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

    @include media('large') {
      // width: 30%;
      text-align: left;
      margin-right: 20px;
      margin-bottom: 0px;
    }
  }

  &__quantity-button-container {
    display: flex;
    align-items: center;
  }

  input {
    margin: 0px 10px;
    width: 80px;
    height: 36px;
    border: 2px solid black;
    border-radius: 3px;
    text-align: center;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &__quantity-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    height: 50px;
    width: 50px;
    font-size: 25px;
    border-radius: 50px;
  }

  &__quantity-button:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &__quantity-button--disabled {
    background: #0399e469;
  }

  &__quantity-button:hover {
    cursor: pointer;
  }

  &__your-order-container {
    padding: 16px;
    margin: measure(spacing-l) 0px measure(spacing-xxl);
    border: 1px solid color(soluppgang);
    border-radius: 3px;

    h3 {
      margin: 0 0 12px;
    }

    table {
      width: 100%;
      line-height: 20px;
      tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        td {
        }
      }

      input {
        border: 1px solid black;
        width: 40px;
      }
    }
  }

  &__edit-unitCost {
    position: relative;
    span {
      position: absolute;
      top: -26px;
      left: 50px;
      cursor: pointer;
    }
  }
  &__edit-shippingCost {
    position: relative;
    span {
      position: absolute;
      top: -15px;
      left: 90px;
      cursor: pointer;
    }
  }

  &__delivery-info-text {
    margin-top: measure(spacing-l);
  }

  &__approval {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin: measure(spacing-xl) 0px;

    input {
      width: 18px;
      height: 18px;
      margin: measure(spacing-m) measure(spacing-m) measure(spacing-m) 0;
      accent-color: color(jord);
    }
  }
  &__cost-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    :first-child {
      display: flex;
      align-items: flex-end;
      text-align: right;
    }

    h2 {
      margin: 0px;
    }
    img {
      margin-left: measure(spacing-s);
    }

    img:hover {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      cursor: pointer;
    }
  }

  &__cost-container-sum-text {
    margin-left: measure(spacing-xxs);
  }

  &__flow-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__cancel-button {
    width: 30%;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid black;
    background-color: transparent;
    button {
      padding: 14px;
      border: none;
      width: 100%;
      background-color: transparent;
    }
  }

  &__cancel-button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__continue-button {
    width: 70%;
    color: white;
    border-radius: 3px;
    border: 1px solid black;
    button {
      background-color: color(jord);
      border: none;
      width: 100%;
      // background-color: transparent;
      color: white;
      display: flex;
      justify-content: center;
      padding: 14px;
    }

    button:disabled {
      background: rgba(55, 54, 54, 0.5);
      cursor: not-allowed;
      border: none;
    }
  }

  &__continue-button:hover {
    text-decoration: none;
  }
  &__continue-button[disabled] {
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.507);
  }

  &__continue-button:hover:not([disabled]) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__text-block {
    font-size: 16px;
    margin: measure(spacing-xl) 0px;
  }

  &__collapse-container {
    border-top: 1px solid black;
  }

  &__collapse {
    border-bottom: 1px solid black;
    padding: 14px 0px;
  }

  &__collapse-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__collapse-content {
    padding-top: measure(spacing-l);
    table {
      tr {
        :first-child {
          padding-right: 20px;
        }
        td {
          padding: 6px 0px;
        }
      }
    }
  }

  &__pdf-svg-container {
    width: 16px;
    margin-right: measure(spacing-xs);

    img {
      width: 100%;
    }
  }

  &__terms-row {
    display: flex;
    align-items: center;

    a {
      color: color(link);
      text-decoration: underline;
    }
  }

  &__expand {
    font-size: 24px;
  }

  &__more-scorecards-container {
    display: flex;
    flex-direction: column;
    line-height: 30px;
    max-width: 512px;
    margin: 50px auto;

    h2 {
      text-align: center;
    }
  }
  &__bottom-button {
    max-height: 246px;
    max-width: 512px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    padding: 0px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    :hover {
      transition: 0.3s;
      // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.508);
      transform: scale(1.01);
    }

    :active {
      transform: scale(1.02);
    }
    img {
      width: 100%;
      margin-top: 30px;
    }
  }

  &__background-wave {
    width: calc(100% + 60px);
    margin: 0px 0px -40px -30px;
    max-height: 200px;
    img {
      margin-bottom: -20px;
      width: 100%;
      overflow: hidden;
    }
  }
}

.clubspecific-order--bold {
  font-weight: bold;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip--notext img:hover {
  cursor: default;
  filter: none;
}

/* Tooltip text */
.tooltip .tooltiptext,
.tooltip .tooltiptexttip {
  visibility: hidden;
  width: 120px;
  background-color: color(info-background);
  color: black;
  text-align: center;
  padding: 6px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 1;
  bottom: 166%;
  left: 50%;
  margin-left: -60px;
  border: 1px solid color(soluppgang);
}
.tooltip .tooltiptexttip {
  width: 20px;
  height: 20px;
  background-color: color(info-background);
  z-index: 2;
  margin-left: -10px;
  bottom: 130%;
  border-radius: 0px;
  transform: rotate(45deg);
  padding: 0px;
  border: none;
  border-bottom: 1px solid color(soluppgang);
  border-right: 1px solid color(soluppgang);
  box-shadow: none;
}
.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptexttip {
  visibility: visible;
}

.cancel:hover {
  cursor: pointer;
  font-weight: bold;
}

.modal {
  &__wrapper {
    padding: measure(spacing-xxl);
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: transparent;
      border: none;
    }

    button:hover {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
  }
  &__cancel-button {
    width: 30%;
    margin-right: 10px;
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid black;
    button {
      padding: 14px;
      border: none;
      width: 100%;
      background-color: transparent;
    }
  }

  &__cancel-button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &__continue-button {
    width: 30%;
    color: white;
    border-radius: 3px;
    border: 1px solid black;
    button {
      background-color: color(jord);
      border: none;
      width: 100%;
      // background-color: transparent;
      color: white;
      display: flex;
      justify-content: center;
      padding: 14px;
    }
    button:disabled {
      background: rgba(55, 54, 54, 0.5);
      cursor: not-allowed;
    }
  }

  &__continue-button:hover {
    text-decoration: none;
  }

  &__continue-button:hover:not([disabled]) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__continue-button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__top-buttons-container {
    display: flex;
    width: 50%;
    justify-content: space-around;
    align-items: center;
    margin: measure(spacing-m) auto;
    button {
      width: 40px;
      height: 40px;
      border: 1px solid black;
      border-radius: 3px;
      background-color: transparent;
    }
  }

  &__weeks-container {
    table {
      border-collapse: separate;
      border-spacing: 0 8px;
      margin: auto;

      :first-child:not(td) {
        background-color: white;
      }
      th {
        font-weight: normal;
      }
      tr,
      thead {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        border: 2px solid color(lightSand);
        margin: 9px;
        background-color: color(lightSand);
        border-radius: 3px;
        td {
          font-size: 16px;
          padding: measure(spacing-xs) measure(spacing-l);
        }
      }
      .selectable:hover {
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-color: #304834;
      }
      .selected {
        border-color: #304834;
        background-color: #304834;
        color: white;
      }
      .disabled {
        border-color: #ccc;
        background-color: #ccc;
        color: white;
      }
    }
  }
}

.font-grey {
  color: color(middleGray);
}
</style>
