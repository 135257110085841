<template>
  <div class="empty-content">
    <div class="empty-heading">
      <h1>You don’t have any holes set up</h1>
      <div class="te-paragraph-text-l">
        Please select setup for your club to start.
      </div>
    </div>
    <te-select-cards
      multiselect="true"
      v-bind:items="this.items"
      @select="this.selectionChanged"></te-select-cards>
    <div class="empty-help">
      <span class="tietoevry-icons-help"></span>&nbsp; You can change setup from
      “Standard holes” to “Custom” later in settings for holes.
    </div>
    <button
      :disabled="canContinue()"
      @click="$emit('submit', getSelectedId())"
      class="te-btn te-btn-md te-btn-primary">
      Continue
    </button>
  </div>
</template>

<script>
import { any } from 'vue-types';
import TeSelectCards from './components/te-select-cards.vue';

export default {
  name: 'CourseDataHolesEmptyView',
  emits: ['submit'],
  props: {
    selectedItem: {
      type: any,
    },
  },
  watch: {
    selectedItem: function (newVal, oldVal) {
      for (let i of this.items) {
        i.selected = i.id === newVal;
      }
      this.currentlySelected = newVal;
    },
  },
  data() {
    return {
      currentlySelected: this.selectedItem,
      items: [
        {
          id: 1,
          title: 'Standard setup',
          selected: this.selectedItem === 1,
          disabled: false,
          description: 'When your club has 18 physical holes.',
        },
        {
          id: 2,
          title: 'Custom setup',
          selected: this.selectedItem === 2,
          disabled: false,
          description: 'When your club have fewer or more than 18 holes.',
        },
      ],
    };
  },
  methods: {
    canContinue() {
      return !this.items.find(i => {
        return i.id === this.currentlySelected;
      });
    },
    getSelectedId() {
      return this.currentlySelected;
    },
    selectionChanged(selection, all) {
      if (!selection[0]) {
        this.currentlySelected = 0;
      } else {
        this.currentlySelected = selection[0].id;
      }
    },
  },
  components: { TeSelectCards },
};
</script>
<style lang="scss">
.empty-content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 30px;
  width: 100%;
  .empty-heading {
    display: flex;
    width: 100%;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
  .empty-help {
    display: flex;
    justify-content: center;
    color: var(--text-text-secondary, #313538);
    font-family: 'Tietoevry sans 1';
    font-size: 10.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
</style>
