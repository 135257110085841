<template>
  <section class="search-results__pagination">
    <div class="search-results__info">
      {{ $t('shows') }} {{ firstRowOnPage }} {{ $t('to').toLowerCase() }}
      {{ lastRowOnPage }} {{ $t('of') }} {{ rowCount }}
      {{ $tc('post', rowCount) }}
    </div>
    <ul class="search-results__pagination--num">
      <li
        class="search-results__pagination--previous"
        :class="{ disabled: !prevPage }">
        <button
          :disabled="!prevPage"
          @click="prev">
          <use-svg
            svg="arrow-right"
            class="svg--90" />
        </button>
      </li>
      <li
        v-for="(page, i) in pages"
        :key="i"
        :class="[
          `search-results__pagination--${page}`,
          { active: currentPage === page },
        ]">
        <button @click="selectPage(page)">
          {{ page }}
        </button>
      </li>
      <li
        class="search-results__pagination--next"
        :class="{ disable: !nextPage }">
        <button
          :disabled="!nextPage"
          @click="next">
          <use-svg
            svg="arrow-right"
            class="svg--90-negative" />
        </button>
      </li>
    </ul>
  </section>
</template>

<script>
// import EventBus from '@/core/event-bus.js'
import UseSvg from '@/components/use-svg';
import { generatePageRange } from '@/core/helpers.js';

export default {
  name: 'ResultsPagination',
  components: {
    UseSvg,
  },
  props: {
    prevPage: Boolean,
    nextPage: Boolean,
    firstRowOnPage: Number,
    lastRowOnPage: Number,
    pageSize: Number,
    rowCount: Number,
    currentPage: Number,
    store: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      page: this.currentPage,
    };
  },
  computed: {
    pages() {
      return generatePageRange(
        this.page,
        Math.ceil(this.rowCount / this.pageSize)
      );
    },
  },
  methods: {
    selectPage(page) {
      if (!isNaN(page)) {
        this.page = page;
        this.$emit('currentPage', { page: page });
      }
    },
    prev() {
      if (this.prevPage) {
        this.page = this.page - 1;
        this.$emit('currentPage', { page: this.page });
      }
    },
    next() {
      if (this.nextPage) {
        this.page = this.page + 1;
        this.$emit('currentPage', { page: this.page });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-results__pagination {
  display: flex;
  background-color: color(white);
  margin: 1rem 0;

  .search-results__info {
    width: 100%;
    color: color(gray-darker);
  }
}

.search-results__pagination--num {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  justify-content: flex-end;
  li {
    width: 1.8125rem;
    height: 2.125rem;
    background-color: color(gray-light);
    margin-right: 0.6rem;
    border-radius: 3px;

    button {
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      color: color(gray-darker);
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  li.active {
    button {
      background-color: color(ontag-blue);
      color: color(white);
    }
  }
  li.search-results__pagination--previous {
    margin-right: 0.6rem;
    background-color: color(gray);
    button {
      svg {
        position: relative;
        left: -0.1875rem;
        top: 0.125rem;
      }
    }
  }
  li.search-results__pagination--next {
    margin-right: 0;
    background-color: color(gray);
    button {
      svg {
        position: relative;
        right: -0.1875rem;
      }
    }
  }
}
</style>
