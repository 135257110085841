<template>
  <div>
    <div class="diagramboxes">
      <diagram-box
        class="diagrambox"
        :heading-translation-key="'ReportTool.totalMemberAmount'"
        :with-diagram-tools="false"
        :diagram-id="data.members.diagramId"
        :comment="data.members.comment">
        <dot-and-doughnut-diagram
          :data="data.members"
          :settings="settings"
          :color-keys="data.colors" />
      </diagram-box>

      <diagram-box
        class="diagrambox"
        :heading-translation-key="'MemberCalculation.membershipDistribution'"
        :with-diagram-tools="true"
        :diagram-id="data.memberTypes.diagramId"
        :comment="data.memberTypes.comment">
        <pie-chart-with-legend
          :data="data.memberTypes.types.map(t => t.num)"
          :labels="
            data.memberTypes.types.map(
              t => `${t.memberTypeName}, ${t.percentage}%, ${t.num}`
            )
          "
          :percentage="data.memberTypes.types.map(t => t.percentage)"
          :unit-translation-key="'members'" />
      </diagram-box>
      <!-- This barchart should be used later in the development -->
      <!-- <diagram-box
        class="diagrambox"
        :headingTranslationKey="'MemberCalculation.membershipHistory'"
        :withDiagramTools="true"
        :diagramId="data.memberHistory.diagramId"
        :comment="data.memberHistory.comment">
        <bar-chart-with-custom-legend
          :diagram="data.memberHistory"
          :labelTranslationKeys="data.memberHistory.labelTranslationKeys"
          :legends="legends" />
      </diagram-box> -->
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

// import BarChartWithCustomLegend from '@/components/businessboard/member-calculation/bar-chart-with-custom-legend';
import DiagramBox from '@/components/businessboard/member-calculation/diagram-box';
import DotAndDoughnutDiagram from '@/components/businessboard/member-calculation/dot-and-doughnut-diagram';
import PieChartWithLegend from '@/components/businessboard/pie-chart-with-legend';

import { commentTypeDefinition } from '@/components/businessboard/consts';

export default {
  components: {
    // BarChartWithCustomLegend, To be used later in the development
    DiagramBox,
    DotAndDoughnutDiagram,
    PieChartWithLegend,
  },

  mixins: [LocalizationMixin],

  props: {
    data: VueTypes.shape({
      members: VueTypes.shape({
        dataMonth: VueTypes.number,
        comparedToBudget: VueTypes.shape({
          num: VueTypes.number,
          percentage: VueTypes.number,
        }),
        comparedToCurrentMonthLastYear: VueTypes.shape({
          num: VueTypes.number,
          percentage: VueTypes.percentage,
        }),
        comparedToLastMonth: VueTypes.shape({
          num: VueTypes.number,
          percentage: VueTypes.number,
        }),
        diagramId: VueTypes.number,
        juniorMembersComparedToLastYear: VueTypes.shape({
          num: VueTypes.number,
          percentage: VueTypes.number,
        }),
        juniorMembersInPercentage: VueTypes.number,
        seniorMembersComparedToLastYear: VueTypes.shape({
          num: VueTypes.number,
          percentage: VueTypes.number,
        }),
        totalMembers: VueTypes.number,
        colorKeys: VueTypes.arrayOf(VueTypes.string),
        comment: commentTypeDefinition,
        labels: VueTypes.arrayOf(VueTypes.string),
      }),
      memberTypes: VueTypes.shape({
        comment: commentTypeDefinition,
        diagramId: VueTypes.number,
        types: VueTypes.arrayOf({
          memberTypeName: VueTypes.string,
          num: VueTypes.number,
          percentage: VueTypes.number,
        }),

        data: VueTypes.arrayOf(VueTypes.number),
      }),
      memberHistory: VueTypes.shape({
        comment: commentTypeDefinition,
        comparisonYear: VueTypes.shape({
          actual: VueTypes.arrayOf(VueTypes.number),
          budgeted: VueTypes.number,
          year: VueTypes.number,
        }),
        currentYear: VueTypes.shape({
          actual: VueTypes.arrayOf(VueTypes.number),
          budgeted: VueTypes.number,
          year: VueTypes.number,
        }),
        diagramId: VueTypes.number,
        labels: VueTypes.arrayOf(VueTypes.string),
      }),
      legends: VueTypes.arrayOf({
        style: VueTypes.string,
        type: VueTypes.string,
      }),
    }),
    legends: VueTypes.arrayOf({}),
    settings: VueTypes.shape({
      currentYear: VueTypes.number,
      comparisonYear: VueTypes.number,
      imageFormat: VueTypes.number,
      persist: VueTypes.bool,
    }),
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.diagramboxes {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-gap: 1rem;

  margin: 1rem 0;
}
</style>
