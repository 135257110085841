<template>
  <div class="onservice">
    <div class="onservice--content">
      <router-link to="/onservice/transactions">&laquo; {{ $t('back') }}</router-link>
      <h1 class="first-letter">{{ $t('transaction') }}</h1>
      <section class="transactions--table" v-if="result && result.id">
        <div class="transaction__title">
          <h2 class="transaction__title--title first-letter">{{ $t('articles') }}</h2>
        </div>
        <div class="category__articles">
          <list-table-transactions
            :items="createItems()"
            :headers="createHeaders()">
          </list-table-transactions>
          <div class="category__articles--total">
            <p>
              <b>Totalt:</b>
              <span class="amount">{{ result.sum }}{{ $t('denomination') }}</span>
              <span class="vat">{{ result.whereofVat }}{{ $t('denomination') }}</span>
            </p>
          </div>
        </div>
      </section>
      <section class="transaction--list" v-if="result && result.id">
        <div
          class="transaction__value"
          v-for="(value, key) in getResultWithoutArticles()"
          :key="key">
          <template>
            <p>
              <b class="first-letter inline">{{ $t(key) }}: </b>{{ value }}
            </p>
          </template>
        </div>
      </section>
      <section class="transaction--actions">
        <base-button
          :text="$t('cancel')"
          class="button--background-gray-dark"
          @click="$router.go(-1)" />
      </section>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import ListTableTransactions from '@/components/list-table/list-table-transactions';
export default {
  name: 'OnServiceTransaction',
  components: {
    BaseButton,
    ListTableTransactions,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  mounted () {
    this.$store.dispatch('transactions/fetchTransaction', { id: this.id });
  },
  computed: {
    result () {
      return this.$store.getters['transactions/transaction'];
    },
  },
  methods: {
    createItems () {
      const items = [];
      this.result.articles.forEach(article => {
        const item = {
          article: article.name,
          price: article.price,
          whereofVat: article.vatAmount,
        };
        items.push(item);
      });

      return items;
    },
    createHeaders () {
      return [
        {
          checkbox: false,
          displayName: this.$t('Article'),
          filter: false,
          input: false,
          inputMaxLength: 0,
          order: false,
          routerLink: false,
          value: 'article',
        },
        {
          checkbox: false,
          displayName: this.$t('Price'),
          filter: false,
          input: false,
          inputMaxLength: 0,
          order: false,
          routerLink: false,
          value: 'price',
        },
        {
          checkbox: false,
          displayName: this.$t('WhereofVat'),
          filter: false,
          input: false,
          inputMaxLength: 0,
          order: false,
          routerLink: false,
          value: 'whereofVat',
        },
      ];
    },
    getResultWithoutArticles () {
      const notAllowed = ['articles', 'sum', 'whereofVat'];
      return Object.keys(this.result)
        .filter(key => !notAllowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.result[key];
          return obj;
        }, {});
    },
  },
  beforeDestroy () {
    this.$store.commit('transactions/clearTransaction');
  },
};
</script>
<style lang="scss">
.onservice {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.onservice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.transaction--actions {
  display: flex;
  margin-left: auto;
}
.transaction--actions button:not(:first-child) {
  margin-left: 1rem;
}
.transaction__title {
  background-color: color(gray-light);
  display: flex;
}
.transaction__title--title {
  margin: 0;
  padding: 0.875rem;
}
.category__articles--total {
  background-color: color(blue-light);
  display: inline-block;
  padding: 0 1rem;
  .amount {
    margin-left: 0.5rem;
  }
  .vat {
    margin-left: 0.5rem;
  }
}
.transaction--list {
  display: flex;
  flex-wrap: wrap;
}
.transaction__value {
  width: 100%;
  @include media('small') {
    width: 60%;
    &:nth-child(odd) {
      width: 30%;
    }
  }
}
</style>
