<template>
  <div>
    <div>
      <strong>{{ $t(labelTranslationKey) }}</strong>
      <span style="float: right"> </span>
    </div>
    <div>
      <div class="radio__row">
        <div>
          <div class="period_input">
            <custom-select
              :options="options"
              :selected="selectedIndex"
              @optionSelected="e => this.selectYear(setting, e.value)">
            </custom-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

import CustomSelect from '@/components/form-elements/custom-select';

export default {
  name: 'GolfOfficeFrontEndSelectYear',

  mixins: [LocalizationMixin],

  components: { CustomSelect },

  props: {
    labelTranslationKey: VueTypes.string.required,
    options: VueTypes.arrayOf(
      VueTypes.shape({ value: VueTypes.number, displayName: VueTypes.string })
    ),
    selectedIndex: VueTypes.number,
    setting: VueTypes.string,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {
    selectYear(setting, e) {
      this.$emit('optionSelected', { setting: setting, value: e });
    },
  },
};
</script>

<style lang="scss" scoped>
.radio__row {
  padding: 0.5rem 0;
  text-align: left;
}
.period_input {
  padding: 0 0.5rem 0 0.5rem;
  input,
  select {
    background-color: #f9f9f9;
    border-radius: 2px;
    padding: 0.3rem;
    border: 1px solid #d8d8d8;
  }
}
</style>
