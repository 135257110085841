<template>
  <div class="legend">
    <div
      :class="['row']"
      v-for="(label, i) in labels"
      :key="i">
      <div :style="'background: ' + colors[i] + ';'"></div>

      <div class="label">
        {{ labelTranslations(label) }}
      </div>

      <div class="value-row">
        {{ percentage[i].split(/\s/)[0] }},
        {{ numbers[i].toLocaleString('sv-SE') }} {{ type }}
      </div>
    </div>
  </div>
</template>

<script>
import { COLORS } from '@/components/charts/pie-chart-colors';
export default {
  props: {
    labels: {
      type: Array,
    },
    numbers: {
      type: Array,
    },
    percentage: {
      type: Array,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    colors() {
      return COLORS;
      /*
      if (this.labels.length === 4) {
        return ['#24665b', '#ABDED1', '#0E3746', '#4B2D88'];
      }
      return [
        '#0E3746',
        '#ABDED1',
        '#00A99D',
        '#27645C',
        '#F08565',
        '#4B2D88',
        '#BAAAF9',
        '#C5DEE0',
        '#555F61',
        '#FBEBDD',
      ];
      */
    },
  },
  methods: {
    labelTranslations: label => {
      switch (label) {
        case 'TotalGuests':
          return 'Gäst';
        case 'TotalMembers':
          return 'Medlem';
        case 'XFactor':
          return 'X-faktor';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.legend {
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  max-height: 20rem;
  flex-wrap: wrap;
  height: 100%;
  .row {
    margin: 0.5rem;
    display: grid;
    grid-template-columns: 15px auto;

    div:first-child {
      justify-self: center;
      margin-right: 10px;
      margin-top: 3px;
      border-radius: 2px;
      width: 15px;
      height: 15px;
    }

    .line {
      width: 15px;
      margin-top: 5px;
    }
  }

  .hidden {
    opacity: 0.5;
  }

  .value-row {
    font-size: medium;
    font-weight: 400;
    width: 15rem;
    padding-top: 0.2rem;
  }

  .label {
    white-space: pre;
    line-height: 20px;
  }
}
</style>
