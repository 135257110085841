<template>
  <div class="add-text-combo">
    <input
      class="text-input"
      id="newGroupName"
      v-model="newGroupName"
      name="newGroupName"
      type="text"
      @keyup.enter="add()" />
    <base-button
      backgroundColor="white"
      :text="$t('add')"
      @click="add()">
      <use-svg
        class="plus-icon"
        svg="plus-icon"
        stroke="black" />
    </base-button>
  </div>
</template>
<script>
import UseSvg from '@/components/use-svg';
import BaseButton from '@/components/form-elements/base-button';

export default {
  name: 'BBAddText',
  components: {
    UseSvg,
    BaseButton,
  },
  props: {},
  data() {
    return {
      newGroupName: '',
    };
  },
  methods: {
    add() {
      this.$emit('add', this.newGroupName);
      this.newGroupName = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.text-input {
  border: solid 1px #000;
  border-radius: 3px;
  padding-left: 0.5rem;
}
.add-text-combo {
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 8rem;
  margin-bottom: 2rem;
}
.plus-icon {
  width: 15px;
  height: 15px;
}
</style>
