<template>
  <div class="legend">
    <div>
      <div class="row">
        <div class="line" style="border-top: 1px solid #00A99D"></div>
        <div>{{ data.totalResult.label }}</div>
      </div>
      <div class="row">
        <div class="line" style="border-top: 1px dashed #F08565"></div>
        <div>{{ data.forecast.label }}</div>
      </div>
      <div class="row">
        <div style="background: #FBEBDD;border-radius: 2px;width:12px;height:12px;"></div>
        <div>{{ data.budget.label }}</div>
      </div>
    </div>
    <div>
      <div class="row" v-for="(company, i) in data.companyData" :key="i">
        <div :style="'background:'+colors[i]+';border-radius: 2px;width:12px;height:12px;'"></div>
        <div>{{ company.label }}</div>
      </div>
    </div>
    <div>
      <div class="row" v-for="(company, i) in data.comparisonYearCompanyData" :key="i">
        <div :style="'background: #FFF;border: 1px solid '+colors[i]+';border-radius: 2px;width:12px;height:12px;'"></div>
        <div>{{ company.label }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      colors: ['#27645C', '#ABDED1'],
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
  .legend {
    padding: 1rem;
    font-size: 12px;
    display: grid;
    grid-template-columns: auto auto auto;
    .row {
      margin: .2rem;
      display: grid;
      grid-template-columns: 15px auto;
      grid-gap: 3px;
      div:first-child {
        justify-self: center;
      }
      .line {
        width:15px;
        margin-top: 5px;
      }
    }
  }
</style>
