import { render, staticRenderFns } from "./gamecalculation.vue?vue&type=template&id=0da8a070&scoped=true"
import script from "./gamecalculation.vue?vue&type=script&lang=js"
export * from "./gamecalculation.vue?vue&type=script&lang=js"
import style0 from "./gamecalculation.vue?vue&type=style&index=0&id=0da8a070&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da8a070",
  null
  
)

export default component.exports