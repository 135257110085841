<script>
import { Bar } from 'vue-chartjs';
import LocalizationMixin from '../../mixins/localization-mixin.vue';
export default {
  extends: Bar,
  mixins: [LocalizationMixin],
  props: {
    labels: {
      type: Array,
      default: () => {
        return [];
      },
    },
    data: {
      type: Object,
      default: () => {},
    },
    tooltips: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      chartdata: {
        labels: this.labels,
        datasets: [
          {
            label: this.$t('Businessboard.budget'),
            fill: false,
            lineTension: 0,
            data: this.data.budget,
            borderColor: '#0399E4',
            backgroundColor: '#0399E4',
            tooltips: this.tooltips.budget,
            type: 'line',
          },
          {
            label: this.$t('currentYear2'),
            backgroundColor: '#27645C',
            data: this.data.currentYear,
            tooltips: this.tooltips.currentYear,
            categoryPercentage: 0.5,
            order: 1,
          },
          {
            label: this.$t('previousYear'),
            backgroundColor: '#9CE0D1',
            data: this.data.lastYear,
            tooltips: this.tooltips.lastYear,
            categoryPercentage: 0.5,
            order: 1,
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label +=
                data.datasets[tooltipItem.datasetIndex].tooltips[
                  tooltipItem.index
                ];
              return label;
            },
          },
        },
        maintainAspectRatio: false,
      },
    };
  },

  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
