<template>
  <div class="ontag">
    <div class="ontag__content">
      <router-link to="/ontag/orderscorecard/settings">&laquo; {{ $t('back') }}</router-link>
      <h1>Skapa leveransvecka</h1>
      <form class="flex" @submit.prevent="createDeliveryWeek">
        <h3>Vecka *</h3>
        <input
        type="number"
        step="1"
        pattern="^[-/d]/d*$"
        v-model="week"
        required="true"/>
        <div>
        <input
        type="radio"
        name="currentYear"
        :value="currentYear"
        v-model="year"/>
        {{currentYear}}
        </div>
        <div>
        <input
        type="radio"
        name="nextYear"
        :value="nextYear"
        v-model="year"/>
        {{nextYear}}
        </div>
        <label for="deadline">Deadline</label>
        <input
          name="deadline"
          class="input__input"
          type="date"
          :min="date"
          required="true"
          v-model="deadline"/>
          <label for="deadlineForChange">Deadline för ändring</label>
        <input
          name="deadlineForChange"
          class="input__input"
          type="date"
          :min="date"
          required="true"
          v-model="deadlineForChange"/>
          <label for="scorecard-forecast">Prognos antal scorekort</label>
          <div>
            <input
            name="scorecard-forecast"
            type="number"
            step="1"
            pattern="^[-/d]/d*$"
            v-model="scorecardForecast"
            required="false"/>
          </div>
          <div>
            <label for="transaction-purchase">Transaktionsmånad inköp/frakt:</label>
          <div>
        <input
        type="radio"
        name="transaction-year-purchase-1"
        :value="currentYear"
        v-model="purchaseYear"/>
        {{currentYear}}
        </div>
        <div>
        <input
        type="radio"
        name="transaction-year-purchase-2"
        :value="nextYear"
        v-model="purchaseYear"/>
        {{nextYear}}
        </div>
          <select
          name="transaction-purchase"
          v-model="purchaseMonth">
            <option value="1">Januari</option>
            <option value="2">Februari</option>
            <option value="3">Mars</option>
            <option value="4">April</option>
            <option value="5">Maj</option>
            <option value="6">Juni</option>
            <option value="7">Juli</option>
            <option value="8">Augusti</option>
            <option value="9">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          </div>
          <div>
            <label for="transaction-cost">Transaktionsmånad fakturering/intäkt:</label>
            <div>
        <input
        type="radio"
        name="year"
        :value="currentYear"
        v-model="revenueYear"/>
        {{currentYear}}
        </div>
        <div>
        <input
        type="radio"
        name="year"
        :value="nextYear"
        v-model="revenueYear"/>
        {{nextYear}}
        </div>
          <select
          name="transaction-cost"
          v-model="revenueMonth">
            <option value="1">Januari</option>
            <option value="2">Februari</option>
            <option value="3">Mars</option>
            <option value="4">April</option>
            <option value="5">Maj</option>
            <option value="6">Juni</option>
            <option value="7">Juli</option>
            <option value="8">Augusti</option>
            <option value="9">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          </div>
          <div class="input-group--inline user__actions">
            <router-link to="/ontag/orderscorecard/settings">
              <base-button
              :text="$t('cancel')">
              </base-button>
            </router-link>
            <base-button
            :text="$t('save')"
            type="submit"
            v-element-loader="{ loaderKey: 'create-deliveryweek' }"
            :disabled="!canCreate"/>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'CreateDeliveryWeek',
  components: {
    BaseButton,
  },

  directives: {
    elementLoader,
  },

  data() {
    return {
      date: new Date().toISOString().slice(0, 10),
      currentYear: new Date().getFullYear(),
      nextYear: new Date().getFullYear() + 1,
      week: 0,
      weekOk: false,
      year: 0,
      deadline: '',
      deadlineForChange: '',
      purchaseMonth: 0,
      revenueMonth: 0,
      revenueYear: 0,
      purchaseYear: 0,
      scorecardForecast: 0,
    };
  },

  computed: {
    canCreate() {
      if (this.week > 0 && this.week < 53 && this.week) {
        return this.week &&
          this.year &&
          this.deadline;
      } else {
        return false;
      }
    },
  },

  methods: {
    createDeliveryWeek() {
      this.$store.dispatch('scorecardOrder/createDeliveryWeek', {
        week: this.week,
        year: this.year,
        deadline: this.deadline,
        deadlineForChange: this.deadlineForChange,
        purchaseMonth: this.purchaseMonth,
        revenueMonth: this.revenueMonth,
        revenueYear: this.revenueYear,
        purchaseYear: this.purchaseYear,
        scorecardForecast: this.scorecardForecast,
      }).then(() => {
        this.$router.push({ path: '/ontag/orderscorecard/settings' });
      });
    },
  },
};
</script>
<style lang="scss" scoped>

.button {
  margin-right: 10px;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  margin-bottom: 15px;
}

.flex > label, input, select {
  margin-bottom: 0.4rem;
  margin-top: 0.2rem;
}

</style>
