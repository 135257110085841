<template>
  <div :class="{ 'template-diagrams': showAllDiagrams || includeDiagram }">
    <div class="header">
      <diagram-header
        :elementID="templateId + '_game-greenfee-income'"
        :chosenFormat="fileFormat"
        :diagram="diagram"
        :templateId="templateId"
        :showDiagram="showAllDiagrams || includeDiagram">
        <template #title>
          {{ $t('greenFeeRevenue') }}<br />
          <date-range
            v-if="diagram"
            :text="$t('outcome')"
            :monthTo="diagram.monthTo"
            :actualYear="activeYear" />
          <br />
          {{ $t('againstAcuBudget') }} {{ activeYear }} <br />
          {{ $t('forecastAgainst') }} {{ activeYear }}
          {{ $t('yearsBudget').toLowerCase() }}
        </template>

        <template #settings>
          <modal-filter
            :titleText="titleText"
            :comparisonYearCount="4"
            :showDisplaySettings="false"
            :storedFilter="diagram?.settings || '{}'"
            @changeFilter="e => toggleDiagram(e)"
            @saveFilter="e => saveFilter(e)" />
        </template>
      </diagram-header>
    </div>
    <div
      v-if="diagram && (showAllDiagrams || includeDiagram)"
      class="diagram"
      :id="templateId + '_game-greenfee-income'">
      <div :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
        <h1
          v-if="settings.showHeaders"
          class="pieChart-title">
          {{ $t('greenFeeRevenue') }}
        </h1>
        <div class="section">
          <div class="divide-charts">
            <h4>
              <!-- Utfall jan-{{ months[diagram.monthTo] }} {{ $t('against') }}<br /> -->
              <date-range
                :text="$t('outcome')"
                :actualYear="activeYear"
                :monthTo="diagram.monthTo" />
              {{ $t('acuBudget').toLowerCase() }} {{ activeYear }}
            </h4>
            <donut-chart
              v-if="!isLoading"
              :sum1="diagram.data.income"
              :sum2="diagram.data.accumulatedBudget"
              :showAsPercentage="false" />
            <pie-chart-loader v-if="isLoading" />
          </div>
          <div class="divide-charts legend">
            <reports-years-legend
              :years="diagram.data.compareYears"
              :unit="$t('crowns')" />
          </div>
          <div class="divide-charts">
            <h4>
              {{ $t('forecastAgainst') }} {{ activeYear }}
              {{ $t('yearsBudget').toLowerCase() }}<br />&nbsp;
            </h4>
            <div class="border-left">
              <donut-chart
                v-if="!isLoading"
                :sum1="diagram.data.forecast"
                :sum2="diagram.data.yearBudget"
                :showAsPercentage="true" />
              <pie-chart-loader v-if="isLoading" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthsLabelsShort } from '@/core/consts';

import ModalFilter from '@/components/reports-templates/settings-modal/gamecalculation';
import DiagramHeader from '@/components/reports-templates/diagram-header';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
import DonutChart from '@/components/charts/donut-chart';
import ReportsYearsLegend from '@/components/reports-templates/legend/reports-legend-years';
import DateRange from './components/date-range.vue';
import PieChartLoader from '../pie-chart-loader.vue';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixin],
  components: {
    ModalFilter,
    DiagramHeader,
    DonutChart,
    ReportsYearsLegend,
    DateRange,
    PieChartLoader,
  },
  props: {
    diagram: {},
    templateId: String,
    include: Boolean,
    showAllDiagrams: Boolean,
  },
  data() {
    return {
      months: [''].concat(
        MonthsLabelsShort.map(l => this.$t(`MonthsShort.${l}`).toLowerCase())
      ),
    };
  },
  computed: {
    titleText() {
      return this.$t('greenFeeRevenue');
    },
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
    fileFormat() {
      return this.settings.fileFormat;
    },
    includeDiagram() {
      return this.diagram?.includedInTemplate || false;
    },
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    activeYear() {
      if (!this.diagram) return new Date().getFullYear();
      return JSON.parse(this.diagram.settings).Years[0];
    },
  },
  methods: {
    toggleDiagram(e) {
      this.diagramToRender = e.show;
      this.$store.dispatch('businessboardReportingTool/postDiagram', {
        templateId: this.templateId,
        settings: e,
        diagramType: 14,
      });
    },
    saveFilter(e) {
      e.save = true;
      this.toggleDiagram(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
.pieChart {
  width: 20rem;
  height: 20rem;
}

.section {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 16px;
  margin-left: 2rem;
  display: flex;
  justify-content: space-evenly;
  h4 {
    font-size: 18px;
    text-align: center;
  }
}

.pieChart-title {
  margin-left: 4rem;
  text-align: center;
}

.chart-year {
  font-size: medium;
  font-weight: 600;
}

.divide-charts {
  display: flex;
  flex-direction: column;
}
.legend {
  width: 20rem;
}

.border-left {
  border-left: 1px solid #dadedf;
}
</style>
