<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: ['chartData'],
  mounted() {
    this.render();
  },
  watch: {
    chartData: function (newVal, oldVal) {
      this.render();
    },
  },
  methods: {
    render() {
      this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              id: 'y-bars',
              stacked: true,
              gridLines: {
                display: true,
              },
              ticks: {
                callback: function (value, index, values) {
                  return value.toLocaleString('sv-SE', {
                    maximumFractionDigits: 0,
                  });
                },
                min: 0,
              },
            },
            {
              display: false,
              ticks: {
                min: 0,
              },
              id: 'y-lines',
              stacked: false,
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
          position: 'right',
          labels: {
            usePointStyle: false,
            boxWidth: 20,
            fontSize: 12,
          },
        },
        tooltips: false,
      });
    },
  },
};
</script>

<style scoped>
.bar-chart-container {
  height: 400px;
}
</style>
