<template>
  <div
    v-if="isSettingsModalOpen"
    class="modal--wrapper">
    <div class="modal--content">
      <div class="modal--header">
        <p class="breadcrumb">
          {{ $t('MemberCalculation.memberFeeTitle') }}
        </p>
        <h2 class="modal--title">
          {{ $t('Businessboard.Data.settingsForMembershipCategory') }}
          {{ category.displayName }}
        </h2>
      </div>
      <div class="modal--body">
        <h4>{{ $t('Businessboard.Data.connectToCatgoryGroup') }}</h4>
        <custom-select
          @optionSelected="e => selectGroup(e)"
          :selected="groups.findIndex(g => g.value === category.categoryId)"
          :options="groups">
        </custom-select>

        <h4>{{ $t('Businessboard.Data.connectToAlias') }}</h4>
        <div class="subtitle">{{ $t('Businessboard.Data.subtitleAlias') }}</div>
        <add-text @add="e => addAlias(e)" />
        <div>
          <template v-for="(alias, index) in aliases">
            <div
              :key="index"
              class="border">
              <div class="display-flex space-between">
                <p>
                  {{ alias }}
                </p>
                <div class="display-flex-icons">
                  <div
                    class="hovering delete-icon rounded"
                    @click="deleteAlias(index)">
                    <use-svg
                      svg="bb-trash-icon"
                      color="white" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <h4>{{ $t('Businessboard.Data.connectToAgeGroup') }}</h4>
        <template v-for="ageGroup in ageGroups">
          <div
            :key="ageGroup.id"
            class="age-group-row">
            <div class="display-flex">
              <div
                class="select-checkbox"
                @click="selectAgeGroup(ageGroup.id)">
                <use-svg
                  v-if="selectedAgeGroups.includes(ageGroup.id)"
                  svg="bb-selected-icon" />
              </div>
              <label class="member-label">
                {{ ageGroup.fromAge }}-{{ ageGroup.toAge }}
                {{ $t('year').toLowerCase() }}</label>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="buttons">
      <base-button
        backgroundColor="white"
        :text="$t('close')"
        @click="closeModal()" />
      <base-button
        backgroundColor="bb-primary"
        :text="$t('save')"
        @click="saveChanges()" />
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/form-elements/custom-select-bb';
import BaseButton from '@/components/form-elements/base-button';
import UseSvg from '@/components/use-svg';
import AddText from '@/components/businessboard/add-text';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'modal-membership-types-settings',
  mixins: [LocalizationMixin],
  components: {
    CustomSelect,
    BaseButton,
    UseSvg,
    AddText,
  },
  props: {},

  data() {
    return {
      selectedGroup: '',
      selectedAgeGroups: [],
      aliases: [],
      aliasName: '',
    };
  },
  watch: {
    category: function (newValue) {
      if (newValue === {}) return;
      this.selectedGroup = newValue.categoryId;
      this.aliases = newValue.aliases;
      this.selectedAgeGroups =
        newValue.ageGroups?.map(ag => ag.ageGroupId) || [];
    },
  },
  computed: {
    ageGroups() {
      return this.$store.getters['businessboard/membershipTypeYears'].ageGroups;
    },
    groups() {
      return [].concat(
        [{ value: '', displayName: this.$t('noGroup') }],
        this.$store.getters['businessboard/membershipTypeYears'].categories.map(
          c => {
            return { value: c.id, displayName: c.name };
          }
        )
      );
    },
    category() {
      return this.$store.state.businessboard
        .membershipCategorySettingsModalModel;
    },
    isSettingsModalOpen() {
      return (
        this.$store.state.businessboard.membershipCategorySettingsModalModel
          .id !== undefined
      );
    },
  },

  methods: {
    closeModal() {
      this.$store.state.businessboard.membershipCategorySettingsModalModel = {};
    },
    selectGroup(e) {
      this.selectedGroup = e.value;
    },
    selectAgeGroup(id) {
      let index = this.selectedAgeGroups.indexOf(id);
      if (index > -1) {
        this.selectedAgeGroups.splice(index, 1);
      } else {
        this.selectedAgeGroups.push(id);
      }
    },
    saveChanges() {
      this.$store
        .dispatch('businessboard/patchMembershipType', {
          id: this.category.id,
          membershipCategoryId: this.selectedGroup,
          aliases: this.aliases,
          ageGroups: this.selectedAgeGroups,
        })
        .then(() => {
          this.$store.dispatch('businessboard/fetchMembershipTypes');
          this.$store.dispatch('businessboard/fetchMembershipCategories');
          this.closeModal();
        });
    },
    deleteAlias(index) {
      this.aliases.splice(index, 1);
    },
    addAlias(name) {
      this.aliases.push(name);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.modal--wrapper {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 30rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;
  border-radius: 8px;
}
.modal--content {
  padding: 0 2rem;
  max-height: 85vh;
  overflow-y: scroll;
}
.modal--header {
  background-color: #fff;
}
.modal--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #131516;
}
.breadcrumb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7c80;
}
.display-flex {
  display: flex;
  gap: 0.7rem;
}
.space-between {
  justify-content: space-between;
}
.select-checkbox {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 4px;
  margin-left: 0.3rem;
}
.age-group-row {
  margin: 1rem 0.5rem;
}
.display-flex-icons {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;
  justify-self: end;
}
.border:first-child {
  border-top: solid 1px #dadedf;
}
.border {
  border-bottom: solid 1px #dadedf;

  width: 100%;
}
.delete-icon {
  display: inline-flex;
  gap: measure(spacing-m);
  align-items: center;
  justify-content: center;
}
.rounded {
  padding: measure(spacing-xs);
  margin-left: auto;

  border-radius: 8px;
  border: solid 1px #c1c7c9;

  height: 30px;
}
.subtitle {
  color: var(--gr3, #555f61);
  margin: 1rem 0;
}
.buttons {
  margin: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}
</style>
