<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <span class="h1">OnTag Dashboard</span>
        <span v-if="isLoading" class="element--is-loading"></span>
      </div>
      <section>
        <h3>Nyckeltal - utrullning av OnTag Cloud</h3>
        Antal klubbar som kör Classic: {{ statistics.onTagClassicCount }} st / {{ statistics.onTagClassicPercentage }} %<br/>
        <span class="left-margin">Varav har påbörjat bytet till Cloud: {{ statistics.onTagCloudRolloutCount }} st / {{ statistics.onTagCloudRolloutPercentage }} %<br/></span>
        Antal klubbar som kör Cloud: {{ statistics.onTagCloudCount }} st / {{ statistics.onTagCloudPercentage }} %
        <!-- <h3>Annonsscorekort</h3>
        Antal klubbar som anmält intresse: {{ statistics.clubScorecardInterest }}<br/>
        Summa scorekort: {{ statistics.clubScorecardInterestTotal }}

        <h3>Intresse för att vara referens</h3>
        Antal klubbar som anmält intresse som referensklubb: {{ statistics.clubReferenceCount }} -->
        <div class="flex">
              <div class="bar" v-if="scorecardOrderSalesStatisticsData">
                  <span class="title">Sålda scorekort</span>
                  <bar-chart-sales
                  :styles="{height: '16rem'}"
                  :labels="['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']"
                  :data='scorecardOrderSalesStatisticsData.data'
                  :tooltips='scorecardOrderSalesStatisticsData.tooltips'/>
              </div>
                <div v-if="scorecardOrderSalesStatisticsData" class="totalCount">
                  <h3>Innevarande år</h3>
                   <p>
                    <span>Standard scorekort 3-delade</span>
                    <span>{{ standardThreeTotalSalesCurrentY }} st</span>
                   </p>
                   <p>
                    <span>Standard scorekort 4-delade</span>
                    <span>{{ standardFourTotalSalesCurrentY }} st</span>
                   </p>
                   <p>
                    <span>Klubbunika scorekort 3-delade</span>
                    <span>{{ customerThreeTotalSalesCurrentY }} st</span>
                   </p>
                   <p>
                    <span>Klubbunika scorekort 4-delade</span>
                    <span>{{ customerFourTotalSalesCurrentY }} st</span>
                   </p>
                   <hr>
                   <p>
                    <span><b>Total</b></span>
                    <span><b>{{ totalSalesCurrentY }} st</b></span>
                   </p>
                </div>
                <div class="bar" v-if="scorecardOrderRevenueStatisticsData">
                  <span class="title">Intäkter scorekort</span>
                  <bar-chart-revenue
                  :styles="{height: '16rem'}"
                  :labels="['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']"
                  :data='scorecardOrderRevenueStatisticsData.data'
                  :tooltips='scorecardOrderRevenueStatisticsData.tooltips'/>
                </div>
                <div v-if="scorecardOrderRevenueStatisticsData" class="totalCount">
                  <h3>Innevarande år</h3>
                   <p>
                    <span>Standard scorekort</span>
                    <span>{{ standardRevenueTotalCurrentY }} kr</span>
                   </p>
                   <p>
                    <span>Klubbunika scorekort</span>
                    <span>{{ customerRevenueTotalCurrentY  }} kr</span>
                   </p>
                   <hr>
                   <p>
                    <span><b>Total</b></span>
                    <span><b>{{ totalRevenueCurrentY }} kr</b></span>
                   </p>
                </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BarChartSales from '../../components/charts/bar-chart-scorecard-order-sales-statistics.vue';
import BarChartRevenue from '../../components/charts/bar-chart-scorecard-order-revenue-statistics.vue';

export default {
  name: 'OntagAdminDashboard',
  components: {
    BarChartSales,
    BarChartRevenue,
  },
  data () {
    return {
      isLoading: true,
      scorecardOrderSalesStatisticsData: false,
      scorecardOrderRevenueStatisticsData: false,
      standardThreeTotalSalesCurrentY: false,
      standardFourTotalSalesCurrentY: false,
      customerThreeTotalSalesCurrentY: false,
      customerFourTotalSalesCurrentY: false,
      standardRevenueTotalCurrentY: false,
      customerRevenueTotalCurrentY: false,
      totalRevenueCurrentY: false,
      totalSalesCurrentY: false,
    };
  },
  computed: {
    statistics () {
      return this.$store.getters['clubs/ontagcloudstatistics'];
    },
    scorecardOrderStatistics () {
      return this.$store.getters['scorecardOrderStatistics/scorecardOrderStatistics'];
    },
  },
  created () {
    this.$store.dispatch('scorecardOrderStatistics/fetchScorecardOrderStatistics')
      .then(() => {
        this.parseSales(this.$store.state.scorecardOrderStatistics.scorecardOrderStatistics);
        this.parseRevenue(this.$store.state.scorecardOrderStatistics.scorecardOrderStatistics);
      });
    this.$store.dispatch('clubs/fetchOntagcloudstatistics').then(() => {
    }).finally(() => {
      this.isLoading = false;
    });
  },
  methods: {
    fetchScorecardOrderStatistics () {
      this.$store.dispatch('scorecardOrderStatistics/fetchScorecardOrderStatistics');
    },
    parseSales2(fields, data) {
      var d = {};
      var t = {};
      d.standardThree = fields.map(function (y) {
        var v = data[0].currentYearData.find(x => x.name === y);
        return v ? v.value : 0;
      });
      d.standardFour = fields.map(function (y) {
        var v = data[1].currentYearData.find(x => x.name === y);
        return v ? v.value : '';
      });
      d.customerThree = fields.map(function (y) {
        var v = data[2].currentYearData.find(x => x.name === y);
        return v ? v.value : '';
      });
      d.customerFour = fields.map(function (y) {
        var v = data[3].currentYearData.find(x => x.name === y);
        return v ? v.value : '';
      });
      d.total = fields.map(function (y) {
        var v = 0;
        data.forEach((stat) => {
          v += stat.currentYearData.find(x => x.name === y).value;
        });
        return v;
      });
      t = d;
      return { data: d, tooltips: t };
    },
    parseRevenue2(fields, data) {
      var d = {};
      var t = {};
      d.customerSpecific = fields.map(function (y) {
        var v = data[0].currentYearData.find(x => x.name === y);
        return v ? v.value : 0;
      });
      d.standard = fields.map(function (y) {
        var v = data[1].currentYearData.find(x => x.name === y);
        return v ? v.value : '';
      });
      d.total = fields.map(function (y) {
        var v = 0;
        data.forEach((stat) => {
          v += stat.currentYearData.find(x => x.name === y).value;
        });
        return v;
      });
      t = d;
      return { data: d, tooltips: t };
    },
    parseSales(scorecardOrderStatistics) {
      this.scorecardOrderSalesStatisticsData = this.parseSales2(
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], scorecardOrderStatistics.scorecardSales);
      this.standardThreeTotalSalesCurrentY = scorecardOrderStatistics.scorecardSales[0].currentYearTotal.toLocaleString('sv');
      this.standardFourTotalSalesCurrentY = scorecardOrderStatistics.scorecardSales[1].currentYearTotal.toLocaleString('sv');
      this.customerThreeTotalSalesCurrentY = scorecardOrderStatistics.scorecardSales[2].currentYearTotal.toLocaleString('sv');
      this.customerFourTotalSalesCurrentY = scorecardOrderStatistics.scorecardSales[3].currentYearTotal.toLocaleString('sv');
      scorecardOrderStatistics.scorecardSales.forEach((sales) => {
        this.totalSalesCurrentY += sales.currentYearTotal;
      });
      this.totalSalesCurrentY = this.totalSalesCurrentY.toLocaleString('sv');
    },
    parseRevenue(scorecardOrderStatistics) {
      this.scorecardOrderRevenueStatisticsData = this.parseRevenue2(
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], scorecardOrderStatistics.scorecardRevenue);
      this.standardRevenueTotalCurrentY = scorecardOrderStatistics.scorecardRevenue[0].currentYearTotal.toLocaleString('sv');
      this.customerRevenueTotalCurrentY = scorecardOrderStatistics.scorecardRevenue[1].currentYearTotal.toLocaleString('sv');
      scorecardOrderStatistics.scorecardRevenue.forEach((sales) => {
        this.totalRevenueCurrentY += sales.currentYearTotal;
      });
      this.totalRevenueCurrentY = this.totalRevenueCurrentY.toLocaleString('sv');
    },
  },
};
</script>
<style lang="scss">
.h1 {
    display: inline-block;
    font-size: font(h1);
    font-weight: bold;
    vertical-align: top;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .element--is-loading {
    margin-left: .5rem;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 20px;
  }
.ontag {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.ontag__content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  &.dashboard {
    background-color: transparent;
    border-radius: 0;
  }
}

.flex {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
}

.bar {
    width: 50%;
    height: 19rem;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 5px 10px 8px #ccc;
    margin: 15px;
    padding: 1rem;
  }

.totalCount {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
  text-align: center;
  padding-left: 30px;
}
  .title {
    font-size: 1.1rem;
  }
  .left-margin {
    margin-left: .7rem;
  }
  .totalCount > div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 24px;
  p {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    :first-child {
        :nth-child(2) {
          color: color(blue-dark)
      }
    }
    :nth-child(2) {
      font-weight: bolder;
    }

    span {
    display: flex;
    flex-direction: column;
    text-align: center;
     :first-child {
      margin-bottom: 20px;
     }
    }
  }
}
</style>
