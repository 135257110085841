<template>
  <div class="renderChange">
    <div
      v-if="legend.label.length > 0"
      class="legend">
      <div
        class="legend-color"
        :style="`background: ${colors[legend.color]}`" />

      <div>
        {{ legend.label }}
      </div>
    </div>

    <div class="renderChange-data">
      <div>
        <img
          src="@/components/svg/bb-arrow-icon.svg"
          :class="`icon ${change.value < 0 ? 'icon--180-negative' : ''}`"
          :alt="`${change.value < 0 ? 'Arrow down' : 'Arrow up'}`" />
      </div>

      <div>
        <div class="bold">
          {{
            `
            ${change.value > 0 ? '+' : ''}
            ${change.value.toLocaleString('sv-SE')} / ${
              !isNaN(change.percentage)
                ? `${change.percentage.toLocaleString()}%`
                : this.$t('invalidNumberCompare')
            }
        `
          }}
        </div>

        <div class="comparison-label">
          {{ `${$t('comparedTo')} ${change.comparisonLabel}` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalizationMixin from '@/mixins/localization-mixin.vue';

import VueTypes from 'vue-types';

import colors from '@/assets/styles/_variables.scss';

export default {
  name: 'GolfOfficeFrontEndRenderChange',

  components: {},

  mixins: [LocalizationMixin],

  props: {
    change: VueTypes.shape({
      value: VueTypes.number,
      percentage: VueTypes.number,
      comparisonLabel: VueTypes.string,
    }).def({ value: 999, percentage: 50, comparisonLabel: 'budget 1984' }),
    legend: VueTypes.shape({
      color: VueTypes.string,
      label: VueTypes.string,
    }).def({ color: '', label: '' }),
  },

  data() {
    return {
      colors: colors,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.renderChange {
  display: flex;
  flex-direction: column;

  font-family: 'HindSiliguri', sans-serif;
  line-height: measure(spacing-l);

  .legend {
    display: flex;

    margin-bottom: measure('spacing-s');
    margin-left: measure('spacing-s');

    .legend-color {
      align-self: center;

      width: measure('quarter');
      height: measure('quarter');

      margin-right: measure('quarter');
    }
  }

  &-data {
    display: flex;
  }

  .icon {
    width: measure(double);
    height: measure(double);
    margin-right: measure(spacing-xs);

    &.icon--180-negative {
      transform: rotate(-180deg);
    }
  }

  .bold {
    font-weight: 900;
  }
}
</style>
