import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  balanceCategories: [],
  balanceAccounts: [],
  balanceAccountGroups: [],
  haveNonConnectedAccounts: false,
  balanceCategoryDrillDown: [],
};

const mutations = {
  balancecategories(state, payload) {
    state.balanceCategories = payload;
  },
  balancegroups(state, payload) {
    state.balanceGroups = payload;
  },
  balanceaccounts(state, payload) {
    state.balanceAccounts = payload;
  },
  balancecategorydrilldown(state, payload) {
    state.balanceCategoryDrillDown = payload;
  },
  balancetotalassetsdrilldown(state, payload) {
    state.balanceTotalAssetsDrillDown = payload;
  },
  balancecalculatedresultdrilldown(state, payload) {
    state.balanceCalculatedResultDrillDown = payload;
  },
};

const getters = {
  balanceAccountCategories: state => {
    return state.balanceCategories;
  },
  balanceAccounts: state => {
    return state.balanceAccounts;
  },
  balanceAccountGroups: state => {
    return state.balanceAccountGroups;
  },
  balanceCategoryDrillDown: state => {
    return state.balanceCategoryDrillDown;
  },
  balanceTotalAssetsDrillDown: state => {
    return state.balanceTotalAssetsDrillDown;
  },
  balanceCalculatedResultDrillDown: state => {
    return state.balanceCalculatedResultDrillDown;
  },
  haveNonConnectedAccounts: state => {
    return state.haveNonConnectedAccounts;
  },
};

const actions = {
  fetchBalanceAccounts: context => {
    const path = 'businessboard/balancereport/accounts';
    return fetchData(context, path, 'balanceaccounts');
  },
  fetchBalanceGroups: context => {
    const path = 'businessboard/balancereport/groups';
    return fetchData(context, path, 'balancegroups');
  },
  fetchBalanceCategories: context => {
    const path = 'businessboard/balancereport/categories';
    return fetchData(context, path, 'balancecategories');
  },
  fetchHaveNonConnectedAccounts: context => {
    const path = 'businessboard/balancereport/havenonconnectedaccounts';
    return fetchData(context, path, 'havenonconnectedaccounts');
  },
  fetchBalanceDrillDown: (context, params) => {
    let path = `businessboard/balancereport/${params.id}/drillDown`;
    if (params.companyExternalId) {
      path += `/${params.companyExternalId}`;
    }
    return fetchData(context, path, 'balancecategorydrilldown');
  },
  fetchBalanceTotalAssetsDrillDown: (context, params) => {
    let path = `businessboard/balancereport/drilldown/totalassets`;
    if (params.companyExternalId) {
      path += `/${params.companyExternalId}`;
    }
    return fetchData(context, path, 'balancetotalassetsdrilldown');
  },
  fetchBalanceCalculatedResultDrillDown: (context, params) => {
    let path = `businessboard/balancereport/drilldown/calculatedresult`;
    if (params.companyExternalId) {
      path += `/${params.companyExternalId}`;
    }
    return fetchData(context, path, 'balancecalculatedresultdrilldown');
  },
};

async function fetchData(context, path, mutationName) {
  let response = {};
  try {
    response = await Vue.axios.get(path);
  } catch (error) {
    EventBus.$emit('toast', {
      type: 'error',
      msg: `${error.response.data.message || 'Error message missing'}`,
      request: path,
    });
    return null;
  }
  if (response && response.status === 200) {
    const data = response.data;
    context.commit(mutationName, data);
    return data;
  }
  return null;
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
