import Vue from 'vue';
import VueRouter from 'vue-router';
import Toasted from 'vue-toasted';

// GolfOffice
import Home from '@/views/home.vue';
import HomeBB from '@/views/home-bb.vue';
import ForgotPassword from '@/views/forgot-password.vue';
import ForgotPasswordBB from '@/views/forgot-password-bb.vue';
import ResetPassword from '@/views/reset-password.vue';
import ResetPasswordBB from '@/views/reset-password-bb.vue';
import Users from '@/views/golf-office/users.vue';
import CreateUser from '@/views/golf-office/create-user.vue';
import EditUser from '@/views/golf-office/edit-user.vue';
import UserSettings from '@/views/user/user-settings.vue';
import Clubs from '@/views/golf-office/clubs.vue';
import EditClub from '@/views/golf-office/edit-club.vue';
import EditClubLight from '@/views/golf-office/edit-club-light.vue';
import EditClubBB from '@/views/golf-office/edit-club-bb.vue';
import TodoCombined from '@/views/golf-office/todo/combined.vue';
import TodoEvent from '@/views/golf-office/todo/event.vue';
import TodoInvoiceAddress from '@/views/golf-office/todo/invoiceaddress.vue';
import TodoRevenueCost from '@/views/golf-office/todo/revenue-cost.vue';

import CourseDataHoles from '@/views/coursedata/main.vue';

// OnCourse
import OnCourse from '@/views/on-course/on-course-dashboard.vue';

// OnScreen
import OnScreen from '@/views/on-screen/on-screen-dashboard.vue';

// OnService
import OnService from '@/views/on-service/on-service-dashboard.vue';
import OnServiceTerminals from '@/views/on-service/on-service-terminals.vue';
import OnServiceCreateTerminal from '@/views/on-service/on-service-create-terminal.vue';
import OnServiceEditTerminal from '@/views/on-service/on-service-edit-terminal.vue';
import OnServiceEditCategory from '@/views/on-service/on-service-edit-category.vue';
import OnServiceArticles from '@/views/on-service/on-service-articles.vue';
import OnServiceArticle from '@/views/on-service/on-service-article.vue';
import OnServiceTransactions from '@/views/on-service/on-service-transactions.vue';
import OnServiceTransaction from '@/views/on-service/on-service-transaction.vue';
import OnServiceSystemMessages from '@/views/on-service/on-service-system-messages.vue';
import OnServiceCreateSystemMessage from '@/views/on-service/on-service-create-system-message.vue';
import OnServiceEditSystemMessage from '@/views/on-service/on-service-edit-system-message.vue';

// OnTag
import Ontag from '@/views/on-tag/on-tag-dashboard.vue';
import OntagAdminDashboard from '@/views/on-tag/admin-dashboard.vue';
import OntagDigitalDashboard from '@/views/on-tag/digital-dashboard.vue';
import PrinterQueue from '@/views/on-tag/printer-queue.vue';
import PrinterQueues from '@/views/on-tag/printer-queues.vue';
import AllPrinters from '@/views/on-tag/all-printers.vue';
import PrinterEdit from '@/views/on-tag/printer-edit.vue';
import ChooseStandardPrinter from '@/views/on-tag/choose-standard-printer.vue';
import HandleCourses from '@/views/on-tag/handle-courses.vue';
import CoursesEdit from '@/views/on-tag/courses-edit.vue';
import PrintNeutralScorecards from '@/views/on-tag/print-neutral-scorecards.vue';
// import ScoreCardFriendly from '@/views/on-tag/score-card-friendly.vue'
// import ScoreCardCompetition from '@/views/on-tag/score-card-competition.vue'
// import ScoreCardNeutral from '@/views/on-tag/score-card-neutral.vue'
import ScoreCardEdit from '@/views/on-tag/score-card-edit.vue';
import ScoreCardList from '@/views/on-tag/score-card-list.vue';
import AppSettings from '@/views/on-tag/app-settings.vue';
import HoleSponsors from '@/views/on-tag/hole-sponsors.vue';
import OnTagClients from '@/views/on-tag/on-tag-clients.vue';
import VoucherEdit from '@/views/on-tag/voucher-edit.vue';
import VoucherList from '@/views/on-tag/voucher-list.vue';
import GreenMarking from '@/views/on-tag/greenmarking.vue';
import ScorecardOrderOld from '@/views/on-tag/scorecard-order-old.vue';
import ScorecardOrder from '@/views/on-tag/scorecard-order.vue';
import ScorecardOrderStandard from '@/views/on-tag/scorecard-order-standard.vue';
import ScorecardOrderStandardStep2 from '@/views/on-tag/scorecard-order-standard-step2.vue';
import ScorecardOrderClubspecificNone from '@/views/on-tag/scorecard-order-clubspecific-none.vue';
import ScorecardOrderClubspecificChangeDesign from '@/views/on-tag/scorecard-order-clubspecific-change.vue';
import ScorecardOrderClubspecific from '@/views/on-tag/scorecard-order-clubspecific.vue';
import ScorecardOrderClubspecificStep2 from '@/views/on-tag/scorecard-order-clubspecific-step2.vue';
import ScorecardOrderConfirmation from '@/views/on-tag/scorecard-order-confirmation.vue';
import ScorecardMakeOrder from '@/views/on-tag/scorecard-make-order.vue';
import ScorecardStandardOrders from '@/views/on-tag/scorecard-standard-orders.vue';
import ScorecardCustomerOrders from '@/views/on-tag/scorecard-clubspecific-orders.vue';
import ScorecardAllOrders from '@/views/on-tag/scorecard-all-orders.vue';
import ScorecardEditOrder from '@/views/on-tag/scorecard-edit-order.vue';
import ScorecardOrderSettings from '@/views/on-tag/scorecard-order-settings.vue';
import CreateScorecardDeliveryWeek from '@/views/on-tag/create-deliveryweek.vue';
import EditScorecardDeliveryWeek from '@/views/on-tag/edit-deliveryweek.vue';
import OnTagCourseGuides from '@/views/on-tag/course-guides.vue';

// Businessboard
import BusinessboardLandingPage from '@/views/businessboard/landing-page.vue';
import BusinessboardData from '@/views/businessboard/data.vue';
import BusinessboardCreateCourse from '@/views/businessboard/create-course.vue';
import BusinessboardRenameCourse from '@/views/businessboard/rename-course.vue';
import BusinessboardEditCourse from '@/views/businessboard/edit-course.vue';
import BusinessboardGameCalculation from '@/views/businessboard/game-calculation.vue';
import BusinessboardRevertCourseImport from '@/views/businessboard/revert-course.vue';
import BusinessboardCreateCompany from '@/views/businessboard/create-company.vue';
import BusinessboardRenameCompany from '@/views/businessboard/rename-company.vue';
import BusinessboardEditCompany from '@/views/businessboard/edit-company.vue';
import BusinessboardDeleteResultgroup from '@/views/businessboard/delete-group.vue';
import BusinessboardCreateResultgroup from '@/views/businessboard/create-group.vue';
import BusinessboardEditResultgroup from '@/views/businessboard/edit-group.vue';
import BusinessboardResultgroupAccounts from '@/views/businessboard/group-accounts.vue';
import BusinessboardSettings from '@/views/businessboard/settings.vue';
import BusinessboardSettingsDeleteYear from '@/views/businessboard/delete-year.vue';
import BusinessboardIncomestatement from '@/views/businessboard/incomestatement.vue';
import BusinessboardIncomestatementPremium from '@/views/businessboard/incomestatement-premium.vue';
import BusinessboardResultUnitCalculation from '@/views/businessboard/resultunit-calculation.vue';
import BusinessboardResultUnitCalculationPremium from '@/views/businessboard/resultunit-calculation-premium.vue';
import BusinessboardResultUnitLandingPage from '@/views/businessboard/resultunit-landing-page.vue';
import BusinessboardDashboard from '@/views/businessboard/dashboard.vue';
import BusinessboardGreenfeeSimulation from '@/views/businessboard/greenfeesimulation.vue';
import BusinessboardCreateCategory from '@/views/businessboard/create-category.vue';
import BusinessboardDeleteCategory from '@/views/businessboard/delete-category.vue';
import BusinessboardEditCategory from '@/views/businessboard/edit-category.vue';
import BusinessboardCreateFeeColumn from '@/views/businessboard/create-feecolumn.vue';
import BusinessboardDeleteFeeColumn from '@/views/businessboard/delete-feecolumn.vue';
import BusinessboardEditFeeColumn from '@/views/businessboard/edit-feecolumn.vue';
import BusinessboardEditMembershipType from '@/views/businessboard/edit-membershiptype.vue';
import BusinessboardMembershipCategoryTypes from '@/views/businessboard/category-types.vue';
import BusinessboardMembershipCalculationTypes from '@/views/businessboard/membership-calculation-types.vue';
import BusinessboardMembershipCalculationCategories from '@/views/businessboard/membership-calculation-categories.vue';
import BusinessboardMembershipCalculationIncomes from '@/views/businessboard/membership-calculation-incomes.vue';
import BusinessboardBudget from '@/views/businessboard/budget.vue';
import BusinessboardCreateBudget from '@/views/businessboard/create-budget.vue';
import BusinessboardEditBudget from '@/views/businessboard/edit-budget.vue';
import BusinessboardBudgetCompany from '@/views/businessboard/budget-company.vue';
import BusinessboardBudgetResultUnit from '@/views/businessboard/budget-resultunit.vue';
import BusinessboardBudgetResultUnitTotal from '@/views/businessboard/budget/resultunit-total.vue';
import BusinessboardBudgetResultUnitUnit from '@/views/businessboard/budget/resultunit-unit.vue';
import BusinessboardForecast from '@/views/businessboard/forecast.vue';
import BusinessboardEditForecast from '@/views/businessboard/edit-forecast.vue';
import BusinessboardReports from '@/views/businessboard/reports.vue';
import BusinessboardRevertGamesPerCourseImport from '@/views/businessboard/revert-gamespercourse.vue';
import BusinessboardBalanceReport from '@/views/businessboard/balance-report.vue';
import BusinessboardBalanceSheetSettings from '@/views/businessboard/balance-sheet/settings.vue';

Vue.use(VueRouter);
Vue.use(Toasted, { containerClass: 'custom-toasted', duration: 6000 });

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/businessboard',
    name: 'HomeBB',
    component: HomeBB,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/businessboard/forgot-password',
    name: 'forgot-password-bb',
    component: ForgotPasswordBB,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/reset-password/:token/:firstLogin',
    name: 'choose-password',
    component: ResetPassword,
  },
  {
    path: '/businessboard/reset-password/:token',
    name: 'reset-password',
    component: ResetPasswordBB,
  },
  {
    path: '/businessboard/reset-password/:token/:firstLogin',
    name: '/businessboard/choose-password',
    component: ResetPasswordBB,
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/users/create-user/',
    name: 'create-user',
    component: CreateUser,
  },
  {
    path: '/users/edit-user/:id',
    name: 'edit-user',
    component: EditUser,
  },
  // Club Management
  {
    path: '/clubs',
    name: 'clubs',
    component: Clubs,
  },
  {
    path: '/clubs/edit-club/:id',
    name: 'edit-club',
    component: EditClub,
  },
  {
    path: '/clubs/edit-club/:id/info',
    name: 'edit-club-light',
    component: EditClubLight,
  },
  {
    path: '/businessboard/clubs/edit-club/:id',
    name: 'edit-club-bb',
    component: EditClubBB,
  },
  {
    path: '/clubs/create-club',
    name: 'create-club',
    component: EditClub,
  },
  {
    path: '/oncourse',
    name: 'oncourse-dashboard',
    component: OnCourse,
  },
  {
    path: '/onscreen',
    name: 'onscreen-dashboard',
    component: OnScreen,
  },
  {
    path: '/ontag/ontag-dashboard',
    name: 'ontag-dashboard',
    component: Ontag,
  },
  {
    path: '/ontag/ontag-admin-dashboard',
    name: 'ontag-admin-dashboard',
    component: OntagAdminDashboard,
  },
  {
    path: '/ontag/ontag-digital-dashboard',
    name: 'ontag-digital-dashboard',
    component: OntagDigitalDashboard,
  },
  // Friendly Scorecard
  {
    path: '/ontag/scorecard/friendly-scorecard',
    name: 'friendly-scorecard',
    component: ScoreCardList,
    props: { type: 1, typeName: 'friendly' },
  },
  {
    path: '/ontag/scorecard/friendly-scorecard/create',
    name: 'create-friendly-scorecard',
    component: ScoreCardEdit,
    props: true,
  },
  {
    path: '/ontag/scorecard/friendly-scorecard/:id',
    name: 'edit-friendly-scorecard',
    component: ScoreCardEdit,
    props: true,
  },
  // Competition Scorecard
  {
    path: '/ontag/scorecard/competition-scorecard',
    name: 'competition-scorecard',
    component: ScoreCardList,
    props: { type: 2, typeName: 'competition' },
  },
  {
    path: '/ontag/scorecard/competition-scorecard/create',
    name: 'create-competition-scorecard',
    component: ScoreCardEdit,
    props: true,
  },
  {
    path: '/ontag/scorecard/competition-scorecard/:id',
    name: 'edit-competition-scorecard',
    component: ScoreCardEdit,
    props: true,
  },
  // Neutral Scorecard
  {
    path: '/ontag/scorecard/neutral-scorecard',
    name: 'neutral-scorecard',
    component: ScoreCardList,
    props: { type: 3, typeName: 'neutral' },
  },
  {
    path: '/ontag/scorecard/neutral-scorecard/create',
    name: 'create-neutral-scorecard',
    component: ScoreCardEdit,
    props: true,
  },
  {
    path: '/ontag/scorecard/neutral-scorecard/:id',
    name: 'edit-neutral-scorecard',
    component: ScoreCardEdit,
    props: true,
  },
  // Handle courses
  {
    path: '/ontag/handle-courses/',
    name: 'handle-courses',
    component: HandleCourses,
  },
  {
    path: '/ontag/handle-courses/edit-course/:id',
    name: 'edit-course',
    component: CoursesEdit,
  },
  {
    path: '/ontag/print-queues/',
    name: 'printer-queues',
    component: PrinterQueues,
  },
  {
    path: '/ontag/print-queues/printer-queue/:id',
    name: 'printer-queue',
    component: PrinterQueue,
  },
  // All printers
  {
    path: '/ontag/printers/all-printers/',
    name: 'all-printers',
    component: AllPrinters,
  },
  {
    path: '/printers/edit-printer/:id',
    name: 'edit-printer',
    component: PrinterEdit,
  },
  {
    path: '/ontag/printers/choose-standard-printer/',
    name: 'choose-standard-printer',
    component: ChooseStandardPrinter,
  },
  // Print Neutral Scorecards
  {
    path: '/ontag/print-neutral-scorecards/',
    name: 'print-neutral-scorecards',
    component: PrintNeutralScorecards,
  },
  {
    path: '/ontag/ontag-app/settings',
    name: 'ontag-app-settings',
    component: AppSettings,
  },
  {
    path: '/ontag/ontag-app/holesponsors',
    name: 'ontag-holesponsors',
    component: HoleSponsors,
  },
  {
    path: '/ontag/ontag-clients',
    name: 'otc',
    component: OnTagClients,
  },
  {
    path: '/ontag/ontag-app/distancemarkings',
    name: 'ot-dst',
    component: GreenMarking,
  },
  // Voucher
  {
    path: '/ontag/voucher/friendly-voucher',
    name: 'friendly-voucher',
    component: VoucherList,
    props: { type: 1, typeName: 'friendly' },
  },
  {
    path: '/ontag/voucher/friendly-voucher/create',
    name: 'create-friendly-voucher',
    component: VoucherEdit,
    props: true,
  },
  {
    path: '/ontag/voucher/friendly-voucher/:id',
    name: 'edit-friendly-voucher',
    component: VoucherEdit,
    props: true,
  },
  {
    path: '/ontag/voucher/competition-voucher',
    name: 'competition-voucher',
    component: VoucherList,
    props: { type: 2, typeName: 'competition' },
  },
  {
    path: '/ontag/voucher/competition-voucher/create',
    name: 'create-competition-voucher',
    component: VoucherEdit,
    props: true,
  },
  {
    path: '/ontag/voucher/competition-voucher/:id',
    name: 'edit-competition-voucher',
    component: VoucherEdit,
    props: true,
  },
  {
    path: '/ontag/voucher/neutral-voucher',
    name: 'neutral-voucher',
    component: VoucherList,
    props: { type: 3, typeName: 'neutral' },
  },
  {
    path: '/ontag/voucher/neutral-voucher/create',
    name: 'create-neutral-voucher',
    component: VoucherEdit,
    props: true,
  },
  {
    path: '/ontag/voucher/neutral-voucher/:id',
    name: 'edit-neutral-voucher',
    component: VoucherEdit,
    props: true,
  },
  {
    path: '/user-settings/',
    name: 'user-settings',
    component: UserSettings,
  },

  // Order scorecard
  {
    path: '/ontag/orderscorecard',
    name: 'scorecard-order',
    component: ScorecardOrder,
  },
  {
    path: '/ontag/orderscorecard/order',
    name: 'scorecard-order',
    component: ScorecardOrder,
  },
  {
    path: '/ontag/orderscorecard/standard',
    name: 'scorecard-order-standard',
    component: ScorecardOrderStandard,
  },
  {
    path: '/ontag/orderscorecard/standard-step-2',
    name: 'scorecard-order-standard',
    component: ScorecardOrderStandardStep2,
  },
  {
    path: '/ontag/orderscorecard/clubspecific-step-2',
    name: 'scorecard-order-clubspecific',
    component: ScorecardOrderClubspecificStep2,
  },
  {
    path: '/ontag/orderscorecard/clubspecific',
    name: 'scorecard-order-clubspecific',
    component: ScorecardOrderClubspecific,
  },
  {
    path: '/ontag/orderscorecard/clubspecificnone',
    name: 'scorecard-order-clubspecific-none',
    component: ScorecardOrderClubspecificNone,
  },
  {
    path: '/ontag/orderscorecard/clubspecific/changedesign',
    name: 'scorecard-order-clubspecific-changedesign',
    component: ScorecardOrderClubspecificChangeDesign,
  },
  {
    path: '/ontag/orderscorecard/confirmation',
    name: 'scorecard-order-confirmation',
    component: ScorecardOrderConfirmation,
  },
  {
    path: '/ontag/orderscorecard/order-old',
    name: 'scorecard-order-old',
    component: ScorecardOrderOld,
  },
  {
    path: '/ontag/orderscorecard/make-order-3-piece',
    name: 'scorecard-make-order',
    component: ScorecardMakeOrder,
    props: { scorecardType: 1 },
  },
  {
    path: '/ontag/orderscorecard/make-order-4-piece',
    name: 'scorecard-make-order',
    component: ScorecardMakeOrder,
    props: { scorecardType: 2 },
  },
  {
    path: '/ontag/orderscorecard/make-order-clubspecific-3-piece',
    name: 'scorecard-make-order',
    component: ScorecardMakeOrder,
    props: { scorecardType: 3 },
  },
  {
    path: '/ontag/orderscorecard/make-order-clubspecific-4-piece',
    name: 'scorecard-make-order',
    component: ScorecardMakeOrder,
    props: { scorecardType: 4 },
  },
  {
    path: '/ontag/orderscorecard/standardorders',
    name: 'scorecard-standard-orders',
    component: ScorecardStandardOrders,
  },
  {
    path: '/ontag/orderscorecard/customerorders',
    name: 'scorecard-customer-orders',
    component: ScorecardCustomerOrders,
  },
  {
    path: '/ontag/orderscorecard/orders',
    name: 'scorecard-all-orders',
    component: ScorecardAllOrders,
  },
  {
    path: '/ontag/orderscorecard/edit-order/:id',
    name: 'scorecard-edit-order',
    component: ScorecardEditOrder,
  },
  {
    path: '/ontag/orderscorecard/settings',
    name: 'scorecard-order-settings',
    component: ScorecardOrderSettings,
  },
  {
    path: '/ontag/orderscorecard/create-deliveryweek',
    name: 'scorecard-create-deliveryweek',
    component: CreateScorecardDeliveryWeek,
  },
  {
    path: '/ontag/orderscorecard/settings/edit-deliveryweek/:id',
    name: 'scorecard-edit-deliveryweek',
    component: EditScorecardDeliveryWeek,
  },
  {
    path: '/ontag/course-guides',
    name: 'ontag-course-guides',
    component: OnTagCourseGuides,
  },
  {
    path: '/courseguides/edit-course-guide/:clubId/:loopId',
    name: 'ontag-course-guides-edit',
    component: OnTagCourseGuides,
    props: true,
  },
  // OnService
  {
    path: '/onservice',
    name: 'onservice',
    component: OnService,
  },
  {
    path: '/onservice/onservice-dashboard',
    name: 'onservice-dashboard',
    component: OnService,
  },
  {
    path: '/onservice/terminals',
    name: 'onservice-terminals',
    component: OnServiceTerminals,
  },
  {
    path: '/onservice/systemmessages',
    name: 'onservice-system-messages',
    component: OnServiceSystemMessages,
  },
  {
    path: '/onservice/systemmessages/create-system-message',
    name: 'onservice-create-system-message',
    component: OnServiceCreateSystemMessage,
  },
  {
    path: '/terminals/edit-system-message/:id',
    name: 'onservice-edit-system-message',
    component: OnServiceEditSystemMessage,
  },
  {
    path: '/onservice/terminals/edit-system-message/:id',
    name: 'onservice-edit-system-message',
    component: OnServiceEditSystemMessage,
  },
  {
    path: '/onservice/terminals/create-terminal',
    name: 'onservice-create-terminal',
    component: OnServiceCreateTerminal,
  },
  {
    path: '/terminals/edit-terminal/:id',
    name: 'onservice-edit-terminal',
    component: OnServiceEditTerminal,
  },
  {
    path: '/onservice/terminals/terminals/:id',
    name: 'onservice-edit-terminal',
    component: OnServiceEditTerminal,
  },
  {
    path: '/onservice/store/category/create',
    name: 'onservice-edit-category',
    component: OnServiceEditCategory,
  },
  {
    path: '/onservice/store/category/edit/:id',
    name: 'onservice-edit-category',
    component: OnServiceEditCategory,
    props: true,
  },
  {
    path: '/onservice/store/articles',
    name: 'onservice-articles',
    component: OnServiceArticles,
  },
  {
    path: '/onservice/store/articles/create',
    name: 'onservice-create-article',
    component: OnServiceArticle,
    props: true,
  },
  {
    path: '/edit-article/:id',
    name: 'onservice-edit-article',
    component: OnServiceArticle,
    props: true,
  },
  {
    path: '/onservice/transactions',
    name: 'onservice-transactions',
    component: OnServiceTransactions,
  },
  {
    path: '/onservice/transactions/:id',
    name: 'onservice-transaction',
    component: OnServiceTransaction,
    props: true,
  },
  // Businessboard
  {
    path: '/businessboard',
    name: 'businessboard',
    component: BusinessboardLandingPage,
  },
  {
    path: '/businessboard/data',
    name: 'businessboard-data',
    component: BusinessboardData,
  },
  {
    path: '/businessboard/create-category',
    name: 'businessboard-create-category',
    component: BusinessboardCreateCategory,
  },
  {
    path: '/data/company/delete-membershipcategory/:id',
    name: 'businessboard-delete-membershipcategory',
    component: BusinessboardDeleteCategory,
  },
  {
    path: '/businessboard/edit-category/:id',
    name: 'businessboard-edit-membershipcategory',
    component: BusinessboardEditCategory,
  },
  {
    path: '/businessboard/create-course',
    name: 'businessboard-create-course',
    component: BusinessboardCreateCourse,
  },
  {
    path: '/businessboard/create-course/:id',
    name: 'businessboard-rename-course',
    component: BusinessboardRenameCourse,
  },
  {
    path: '/data/course/edit-course/:id',
    name: 'businessboard-edit-course',
    component: BusinessboardEditCourse,
  },
  {
    path: '/businessboard/gamecalculation',
    name: 'businessboard-game-calculation',
    component: BusinessboardGameCalculation,
  },
  {
    path: '/data/course/data/revert/:id',
    name: 'businessboard-revert-course',
    component: BusinessboardRevertCourseImport,
  },
  {
    path: '/golfbox/gamespercourse/revert/:id',
    name: 'businessboard-revert-gamespercourse',
    component: BusinessboardRevertGamesPerCourseImport,
  },
  {
    path: '/businessboard/create-company',
    name: 'businessboard-create-company',
    component: BusinessboardCreateCompany,
  },
  {
    path: '/businessboard/create-company/:id',
    name: 'businessboard-rename-company',
    component: BusinessboardRenameCompany,
  },
  {
    path: '/data/company/edit-company/:id',
    name: 'businessboard-edit-company',
    component: BusinessboardEditCompany,
  },
  {
    path: '/data/company/edit-company/',
    name: 'businessboard-edit-company',
    component: BusinessboardEditCompany,
    props: route => ({
      id: route.query.state,
      showImport: true,
      code: route.query.code,
    }),
  },
  {
    path: '/data/resultgroup/delete-resultgroup/:id',
    name: 'businessboard-delete-resultgroup',
    component: BusinessboardDeleteResultgroup,
  },
  {
    path: '/businessboard/create-group',
    props: true,
    name: 'businessboard-create-resultgroup',
    component: BusinessboardCreateResultgroup,
  },
  {
    path: '/businessboard/edit-group/:id',
    name: 'businessboard-edit-resultgroup',
    component: BusinessboardEditResultgroup,
  },
  {
    path: '/businessboard/group/:id',
    name: 'businessboard-resultgroupaccounts',
    component: BusinessboardResultgroupAccounts,
  },
  {
    path: '/businessboard/settings',
    name: 'businessboard-settings',
    component: BusinessboardSettings,
  },
  {
    path: '/businessboard/delete-financialyear/:id',
    name: 'businessboard-settings-deleteyear',
    component: BusinessboardSettingsDeleteYear,
  },
  {
    path: '/businessboard/incomestatement/:id',
    name: 'businessboard-incomestatement',
    component: BusinessboardIncomestatement,
  },
  {
    path: '/businessboard/incomestatement-premium/:id',
    name: 'businessboard-incomestatement-premium',
    component: BusinessboardIncomestatementPremium,
  },
  {
    path: '/businessboard/balancesheet/:companyId?/settings',
    name: 'businessboard-balance-report',
    component: BusinessboardBalanceSheetSettings,
    props: true,
  },
  {
    path: '/businessboard/resultunit/:id',
    name: 'businessboard-resultunitcalculation',
    component: BusinessboardResultUnitCalculation,
  },
  {
    path: '/businessboard/resultunit-premium/:companyid/:id',
    name: 'businessboard-resultunitcalculation-premium',
    component: BusinessboardResultUnitCalculationPremium,
  },
  {
    path: '/businessboard/resultunit-premium/:id',
    name: 'businessboard-resultunitcalculation-premium',
    component: BusinessboardResultUnitCalculationPremium,
  },
  {
    path: '/businessboard/resultunit',
    name: 'businessboard-resultunit',
    component: BusinessboardResultUnitLandingPage,
  },
  {
    path: '/businessboard/businessboard-dashboard',
    name: 'businessboard-dashboard',
    component: BusinessboardDashboard,
  },
  {
    path: '/businessboard/greenfeesimulation',
    name: 'greenfee-simulation',
    component: BusinessboardGreenfeeSimulation,
  },
  {
    path: '/businessboard/create-feecolumn',
    name: 'businessboard-create-feecolumn',
    component: BusinessboardCreateFeeColumn,
  },
  {
    path: '/businessboard/delete-feecolumn/:id',
    name: 'businessboard-delete-feecolumn',
    component: BusinessboardDeleteFeeColumn,
  },
  {
    path: '/businessboard/edit-feecolumn/:id',
    name: 'businessboard-edit-feecolumn',
    component: BusinessboardEditFeeColumn,
  },
  {
    path: '/businessboard/edit-membershiptype/:id',
    name: 'businessboard-edit-membershiptype',
    component: BusinessboardEditMembershipType,
  },
  {
    path: '/businessboard/category/:id',
    name: 'businessboard-membershipcategorytypes',
    component: BusinessboardMembershipCategoryTypes,
  },
  {
    path: '/businessboard/membershipcalculation/membershiptypes',
    name: 'businessboard-membershipcalculation-types',
    component: BusinessboardMembershipCalculationTypes,
  },
  {
    path: '/businessboard/membershipcalculation/membershipcategories',
    name: 'businessboard-membershipcalculation-categories',
    component: BusinessboardMembershipCalculationCategories,
  },
  {
    path: '/businessboard/membershipcalculation/incomes',
    name: 'businessboard-membershipcalculation-incomes',
    component: BusinessboardMembershipCalculationIncomes,
  },
  {
    path: '/businessboard/data/budget/:id',
    name: 'businessboard-budget',
    component: BusinessboardBudget,
    props: true,
  },
  {
    path: '/businessboard/data/budget/:id/create-budget',
    name: 'businessboard-create-budget',
    component: BusinessboardCreateBudget,
  },
  {
    path: '/budget/edit-budget/:companyId/:id',
    name: 'businessboard-edit-budget',
    component: BusinessboardEditBudget,
    props: true,
  },
  {
    path: '/businessboard/budgetcompany/:id',
    name: 'businessboard-budget-company',
    component: BusinessboardBudgetCompany,
  },
  {
    path: '/businessboard/budgetresultunit/:companyId/:id',
    name: 'businessboard-budget-resultunit',
    component: BusinessboardBudgetResultUnit,
  },
  {
    path: '/businessboard/budgetresultunit/:id',
    name: 'businessboard-budget-resultunit',
    component: BusinessboardBudgetResultUnit,
  },
  {
    path: '/businessboard/budgetresultunit-total',
    name: 'businessboard-budget-resultunit-total',
    component: BusinessboardBudgetResultUnitTotal,
  },
  {
    path: '/businessboard/budgetresultunit-unit/:companyId/:id',
    name: 'businessboard-budget-resultunit-unit',
    component: BusinessboardBudgetResultUnitUnit,
  },
  {
    path: '/businessboard/data/forecast/:id',
    name: 'businessboard-forecast',
    component: BusinessboardForecast,
    props: true,
  },
  {
    path: '/forecast/edit-forecast/:companyId/:id',
    name: 'businessboard-edit-forecast',
    component: BusinessboardEditForecast,
    props: true,
  },
  {
    path: '/businessboard/reports',
    name: 'businessboard-reports',
    component: BusinessboardReports,
  },
  {
    path: '/businessboard/balancesheet/:companyId',
    name: 'businessboard-balance-sheet',
    component: BusinessboardBalanceReport,
    props: true,
  },
  {
    path: '/todo/combined',
    name: 'todo-combined',
    component: TodoCombined,
  },
  {
    path: '/todo/event',
    name: 'todo-event',
    component: TodoEvent,
  },
  {
    path: '/todo/invoiceaddress',
    name: 'todo-invoiceaddress',
    component: TodoInvoiceAddress,
  },
  {
    path: '/todo/invoiceaddress/:id',
    name: 'todo-invoiceaddress',
    component: TodoInvoiceAddress,
  },
  {
    path: '/todo/revenue-cost',
    name: 'todo-revenue-cost',
    component: TodoRevenueCost,
  },
  {
    path: '/coursedata/holes',
    name: 'coursedata-holes',
    component: CourseDataHoles,
  },
  {
    path: '/coursedata/holes/mappingtool',
    name: 'coursedata-holes',
    component: CourseDataHoles,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash)?.offsetTop - 70,
        behavior: 'smooth',
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
