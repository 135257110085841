<template>
  <div :class="{ 'template-diagrams': showAllDiagrams || includeDiagram }">
    <div class="header">
      <diagram-header
        :elementID="templateId + '_game-course-rounds'"
        :include="includeDiagram"
        @includeDiagram="e => (includeDiagram = e)"
        :chosenFormat="fileFormat"
        :diagram="diagram"
        :templateId="templateId"
        :showDiagram="showAllDiagrams || includeDiagram">
        <template #title> {{ $t('roundsDistributionCourse') }} </template>

        <template #settings>
          <modal-filter
            :titleText="$t('roundsDistributionCourse')"
            :comparisonYearCount="1"
            :showDisplaySettings="false"
            :showCourses="true"
            :storedFilter="diagram?.settings || '{}'"
            @changeFilter="e => toggleDiagram(e)"
            @saveFilter="e => saveFilter(e)" />
        </template>
      </diagram-header>
    </div>
    <div
      v-if="diagram && (showAllDiagrams || includeDiagram)"
      class="diagram"
      :id="templateId + '_game-course-rounds'">
      <game-course-diagram-data :diagram="diagram" />
    </div>
  </div>
</template>

<script>
import ModalFilter from '@/components/reports-templates/settings-modal/gamecalculation';
import DiagramHeader from '@/components/reports-templates/diagram-header';
import GameCourseDiagramData from '@/components/reports-templates/game-course-diagram-data';
import LocalizationMixin from '../../../mixins/localization-mixin.vue';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixin],
  data: function () {
    return {};
  },
  components: {
    ModalFilter,
    DiagramHeader,
    GameCourseDiagramData,
  },
  props: {
    diagram: {},
    templateId: String,
    include: Boolean,
    showAllDiagrams: Boolean,
  },
  computed: {
    isLoading() {
      return false;
    },
    fileFormat() {
      return this.$store.getters['businessboardReportingTool/settings']
        .fileFormat;
    },
    includeDiagram() {
      return this.diagram?.includedInTemplate || false;
    },
  },
  methods: {
    toggleDiagram(e) {
      this.diagramToRender = e.show;
      this.$store.dispatch('businessboardReportingTool/postDiagram', {
        templateId: this.templateId,
        settings: e,
        diagramType: 11,
      });
    },
    saveFilter(e) {
      e.save = true;
      this.toggleDiagram(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
</style>
