<script>
import { Bar } from 'vue-chartjs';

import { diagramPattern } from '@/core/helpers';

export default {
  extends: Bar,
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    labels: Array,
  },
  data: function () {
    return {
      chartdata: {
        labels: this.labels,
        datasets: [
          {
            label: this.data.totalResult.label,
            fill: false,
            lineTension: 0.5,
            data: this.data.totalResult.values,
            borderColor: '#00A99D',
            backgroundColor: diagramPattern('#00A99D'),
            tooltips: this.data.totalResult.tooltips,
            borderWidth: 1,
            type: 'line',
            pointRadius: 0,
            stack: 'stack 5',
            order: 1,
            yAxisID: 'y-lines',
          },
          {
            label: this.data.forecast.label,
            fill: false,
            lineTension: 0.5,
            data: this.data.forecast.values,
            borderColor: '#FFA99D',
            backgroundColor: diagramPattern('#FFA99D'),
            tooltips: this.data.forecast.tooltips,
            borderWidth: 1,
            borderDash: [2, 2],
            type: 'line',
            pointRadius: 0,
            stack: 'stack 3',
            order: 2,
            yAxisID: 'y-lines',
          },
          {
            label: this.data.budget.label,
            lineTension: 0.5,
            data: this.data.budget.values,
            borderColor: '#FBEBDD',
            backgroundColor: '#FBEBDD',
            tooltips: this.data.budget.tooltips,
            type: 'line',
            pointRadius: 0,
            fill: 'origin',
            stack: 'stack 4',
            order: 5,
            yAxisID: 'y-lines',
          },
        ],
      },
      options: {
        legendCallback: function (chart) {
          return chart;
        },
        legend: {
          display: false,
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            boxWidth: 10,
            fontSize: 8,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              id: 'y-bars',
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                callback: function (value, index, values) {
                  return value.toLocaleString('sv-SE', {
                    style: 'currency',
                    currency: 'SEK',
                    maximumFractionDigits: 0,
                  });
                },
                min: 0,
                max: 0,
              },
            },
            {
              display: false,
              ticks: {
                min: 0,
                max: 0,
              },
              id: 'y-lines',
              stacked: false,
              gridLines: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: '#fff',
          titleFontColor: '#000',
          bodyFontColor: '#000',
          bodySpacing: 6,
          borderColor: '#000',
          borderWidth: 1,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label +=
                data.datasets[tooltipItem.datasetIndex].tooltips[
                  tooltipItem.index
                ];
              return label;
            },
          },
        },
        maintainAspectRatio: false,
      },
    };
  },
  methods: {},
  watch: {
    fullscreen: function (newValue) {
      this.$nextTick(() => {
        this.options.legend.labels.boxWidth = newValue ? 20 : 10;
        this.options.legend.labels.fontSize = newValue ? 12 : 8;
        this.options.scales.yAxes[0].display = newValue;
        this.renderChart(this.chartdata, this.options);
      });
    },
  },
  mounted() {
    let colors = ['#27645C', '#9CE0D1'];
    let arr = [].concat(
      this.data.totalResult.values,
      this.data.forecast.values,
      this.data.budget.values
    );
    let m = 0;
    for (let i = 0; i < 12; i++) {
      if (this.data.totalResult.values[i] !== 0) {
        m = i + 1;
        this.chartdata.datasets[1].data[i - 1] = null;
      }
    }
    this.chartdata.datasets[0].data = this.chartdata.datasets[0].data.splice(
      0,
      m
    );
    for (let i = 0; i < this.data.companyData.length; i++) {
      arr = arr.concat(this.data.companyData[i].values);
      this.chartdata.datasets.push({
        label: this.data.companyData[i].label,
        backgroundColor: colors[i % colors.length],
        borderColor: colors[i % colors.length],
        borderWidth: 1,
        barPercentage: 1,
        data: this.data.companyData[i].values.splice(0, m),
        tooltips: this.data.companyData[i].tooltips,
        categoryPercentage: 0.8,
        order: 3,
        stack: 'stack1',
        yAxisID: 'y-bars',
      });
    }
    for (let i = 0; i < this.data.comparisonYearCompanyData.length; i++) {
      arr = arr.concat(this.data.comparisonYearCompanyData[i].values);
      this.chartdata.datasets.push({
        label: this.data.comparisonYearCompanyData[i].label,
        backgroundColor: diagramPattern(colors[i % colors.length]),
        borderColor: colors[i % colors.length],
        barPercentage: 1,
        borderWidth: 1,
        data: this.data.comparisonYearCompanyData[i].values,
        tooltips: this.data.comparisonYearCompanyData[i].tooltips,
        categoryPercentage: 0.8,
        order: 4,
        stack: 'stack2',
        yAxisID: 'y-bars',
      });
    }
    for (let i = 0; i < this.options.scales.yAxes.length; i++) {
      this.options.scales.yAxes[i].ticks.min = Math.min(...arr);
      this.options.scales.yAxes[i].ticks.max = Math.max(...arr);
    }
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
