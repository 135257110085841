var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"businessboard"},[_c('div',{staticClass:"businessboard--content"},[_c('div',{staticClass:"businessboard--header"},[_c('span',{staticClass:"h1"},[_vm._v(" "+_vm._s(_vm.id === 'totalaccounting' ? _vm.$t('Businessboard.totalaccounting') : _vm.resultunitcalculation.name)+" ")]),(_vm.isLoading)?_c('span',{staticClass:"element--is-loading"}):_vm._e()]),(_vm.id === 'totalaccounting')?_c('p',{staticClass:"intro"},[_vm._v(" "+_vm._s(_vm.$t('totalResultInfo1'))+" ")]):_c('p',{staticClass:"intro"},[_vm._v(" "+_vm._s(_vm.$t('totalResultInfo2'))+" ")]),_c('div',[_c('last-updated',{attrs:{"last-update":_vm.latestUpdates,"show-member":true,"show-game":true,"show-economy":true,"show-warning":_vm.haveNonConnectedAccounts}})],1),(_vm.storedFilter)?[(_vm.id === 'totalaccounting')?_c('modal-filter',{attrs:{"storedFilter":_vm.storedFilter,"drillDownRadioValues":[
          [6, _vm.$t('Businessboard.companies')],
          [7, _vm.$t('resultGroup')],
        ],"financialYears":_vm.financialYears},on:{"changeFilter":_vm.changeFilter,"saveFilter":_vm.saveFilter}}):_c('modal-filter',{attrs:{"storedFilter":_vm.storedFilter,"drillDownRadioValues":[
          [5, _vm.$t('acccount')],
          [1, _vm.$t('resultGroup')],
        ],"financialYears":_vm.financialYears},on:{"changeFilter":_vm.changeFilter,"saveFilter":_vm.saveFilter}})]:_vm._e(),(_vm.resultunitcalculation && _vm.resultunitcalculation.incomes)?_c('section',{staticClass:"businessboard--table"},[_c('list-table',{attrs:{"isLoading":_vm.isLoading,"items":_vm.resultunitcalculation.incomes.rows,"headers":_vm.resultunitcalculation.incomes.columns}})],1):_vm._e(),(_vm.resultunitcalculation && _vm.resultunitcalculation.costs)?_c('section',{staticClass:"businessboard--table"},[_c('list-table',{attrs:{"isLoading":_vm.isLoading,"items":_vm.resultunitcalculation.costs.rows,"headers":_vm.resultunitcalculation.costs.columns}})],1):_vm._e(),(_vm.resultunitcalculation && _vm.resultunitcalculation.result)?_c('section',{staticClass:"businessboard--table"},[_c('list-table',{attrs:{"isLoading":_vm.isLoading,"items":_vm.resultunitcalculation.result.rows,"headers":_vm.resultunitcalculation.result.columns}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }