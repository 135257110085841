<template>
  <div :class="{ 'template-diagrams': showAllDiagrams || includeDiagram }">
    <div class="header">
      <diagram-header
        :elementID="templateId + '_game-member-rounds'"
        :include="includeDiagram"
        @includeDiagram="e => (includeDiagram = e)"
        :chosenFormat="fileFormat"
        :diagram="diagram"
        :templateId="templateId"
        :showDiagram="showAllDiagrams || includeDiagram">
        <template #title>
          {{ diagramName }}
        </template>
        <template #settings>
          <modal-filter
            :titleText="diagramName"
            :comparisonYearCount="1"
            :showDisplaySettings="false"
            :showCourses="true"
            :storedFilter="diagram?.settings || '{}'"
            @changeFilter="e => toggleDiagram(e)"
            @saveFilter="e => saveFilter(e)" />
        </template>
      </diagram-header>
    </div>
    <div
      v-if="diagram && (showAllDiagrams || includeDiagram)"
      class="diagram"
      :id="templateId + '_game-member-rounds'">
      <game-member-course-rounds
        :title="diagramName"
        :diagram="diagram"
        :fileFormat="fileFormat" />
    </div>
  </div>
</template>
<script>
import ModalFilter from '@/components/reports-templates/settings-modal/gamecalculation';
import DiagramHeader from '@/components/reports-templates/diagram-header';
import GameMemberCourseRounds from '@/components/reports-templates/game-member-course-rounds';

export default {
  name: 'BusinessboardReports',
  components: {
    ModalFilter,
    DiagramHeader,
    GameMemberCourseRounds,
  },
  props: {
    diagram: {},
    templateId: String,
    include: Boolean,
    showAllDiagrams: Boolean,
  },
  data: function () {
    return {
      diagramName: this.$t('ReportTool.roundsDistributionMemberGuestXFactor'),
    };
  },
  computed: {
    isLoading() {
      return false;
    },
    courses() {
      return this.diagram.data.courses.map(c => c.name);
    },
    fileFormat() {
      return this.$store.getters['businessboardReportingTool/settings']
        .fileFormat;
    },
    includeDiagram() {
      return this.diagram?.includedInTemplate || false;
    },
  },
  methods: {
    toggleDiagram(e) {
      this.diagramToRender = e.show;
      this.$store.dispatch('businessboardReportingTool/postDiagram', {
        templateId: this.templateId,
        settings: e,
        diagramType: 12,
      });
    },
    saveFilter(e) {
      e.save = true;
      this.toggleDiagram(e);
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
</style>
