<template>
  <div class="golf-office">
    <div class="golf-office--content">
      <h1>{{ $t('GolfOffice.welcome') }}</h1>
      <div class="content__input">
        <template v-if="!user.username">
          <div class="content__input-group">
            <input-type
              :label="$t('username')"
              input-id="userName"
              ref="userName"
              @keyup.native.enter="login"
              autofocus />
            <input-type
              :label="$t('password')"
              input-id="password"
              ref="password"
              type="password"
              @keyup.native.enter="login" />
          </div>
          <div class="content__input-actions">
            <p>
              <router-link
                to="forgot-password"
                class="underline">
                {{ $t('forgotPassword') }}
              </router-link>
            </p>
            <base-button
              :text="$t('login')"
              v-element-loader="{ loaderKey: 'home-login' }"
              @click="login" />
          </div>
        </template>
        <template v-else>
          <p>
            {{ $t('GolfOffice.loggedInAs') }} <span>{{ user.firstName }}</span>
            <span> {{ user.lastName }}</span>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import InputType from '@/components/form-elements/input';
import BaseButton from '../components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import { mapActions } from 'vuex';
import mq from '@/mixins/mq.js';

export default {
  name: 'Home',
  components: {
    InputType,
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  mixins: [mq],
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    language() {
      return this.$store.getters['businessboard/language'];
    },
    loggedIn() {
      return this.$store.getters['user/loggedIn'];
    },
  },
  methods: {
    ...mapActions('ui', ['toggleActiveLoaders']),
    login() {
      if (
        this.$refs.userName.dataValue.length > 1 &&
        this.$refs.password.dataValue.length > 1
      ) {
        this.$store
          .dispatch('user/login', {
            username: this.$refs.userName.dataValue,
            password: this.$refs.password.dataValue,
          })
          .then(data => {
            if (data) {
              this.$cookies.set('token', data.token, '7d');
              this.$cookies.set('refresh_token', data.refreshToken, '7d');
              if (this.user && !this.user.club && this.user.superUser) {
                this.$store.dispatch('clubs/clubsSelect', null);
              }
              this.$cookies.remove('language');
              if (this.user.club && this.user.club.id) {
                this.$store.dispatch('businessboard/fetchSettings');

                this.$store.dispatch('businessboard/fetchLanguage').then(() => {
                  if (this.language === '0') {
                    this.$i18n.locale = 'sv';
                  } else if (this.language === '1') {
                    this.$i18n.locale = 'en';
                  }
                });
              }
            }
          });
      }
    },
  },
  mounted() {
    if (this.loggedIn && this.user.club && this.user.club.id) {
      this.$store.dispatch('businessboard/fetchSettings');
    }
  },
};
</script>
<style lang="scss" scoped>
.golf-office {
  flex-grow: 1;
}
.golf-office--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  margin-left: 1rem;
}
.content__input-actions {
  display: flex;
  button {
    margin-left: auto;
  }
}

.golf-office content {
  margin: 4.375rem 0 1rem 0;

  @include media('small') {
    margin: 5.7rem 1rem 1rem 16.7rem;
  }
}
</style>
