<template>
  <div :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
    <h1
      v-if="settings.showHeaders"
      class="pieChart-title">
      {{ $t('totalRounds') }}
    </h1>
    <div class="section">
      <div class="section_div">
        <div class="pieStats">
          <h4>{{ totalRoundsNumber }}</h4>
          {{ `${diagram.data.courses[0].dot.units} ${diagram.data.year}` }}
        </div>
        <div style="grid-row-start: 1; grid-column-start: 1">
          <pie-filterable
            v-if="!isLoading"
            class="pieChart"
            ref="pie3"
            :data="summarizedPieChart"
            :colors="COLORS" />
          <pie-chart-loader v-if="isLoading" />
        </div>
        <pie-legend
          class="pie-legend"
          :legend="summarizedPieChart" />
        <div class="legend-container">
          <reports-years-legend
            :years="summarizedComparisonYears"
            :unit="$t('rounds').toLowerCase()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieFilterable from '@/components/charts/pie-chart-filterable3';
import PieLegend from '@/components/charts/legend-pie2';
import ReportsYearsLegend from '@/components/reports-templates/legend/reports-legend-years';
import LocalizationMixin from '../../mixins/localization-mixin.vue';
import { sum } from 'lodash';
import { COLORS } from '@/components/charts/pie-chart-colors';
import PieChartLoader from '@/components/reports-templates/pie-chart-loader';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixin],
  components: {
    PieFilterable,
    PieLegend,
    ReportsYearsLegend,
    PieChartLoader,
  },
  props: { diagram: {} },
  data() {
    return {
      normalYear: 2022,
      COLORS,
    };
  },
  computed: {
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
    totalRoundsNumber() {
      const courses = this.diagram.data.courses;
      const totalRounds = courses.reduce((sum, course) => {
        return sum + this.sumCourseValues(course);
      }, 0);
      return totalRounds.toLocaleString('sv-SE');
    },
    summarizedPieChart() {
      const allLabels = [];
      const allValues = [];

      this.diagram.data.courses.forEach(course => {
        course.pieChart.labels.forEach((label, index) => {
          const labelIndex = allLabels.indexOf(label);
          if (labelIndex === -1) {
            allLabels.push(label);
            allValues.push(course.pieChart.values[index]);
          } else {
            allValues[labelIndex] += course.pieChart.values[index];
          }
        });
      });

      return {
        labels: allLabels,
        values: allValues,
      };
    },
    summarizedValues() {
      let totalValues = 0;

      this.diagram.data.courses.forEach(course => {
        course.dot.comparisonYears.forEach(yearData => {
          totalValues += yearData.value;
        });
      });

      return totalValues;
    },
    summarizedComparisonYears() {
      const allYears = [];

      this.diagram.data.courses.forEach(course => {
        course.dot.comparisonYears.forEach(yearData => {
          const existingYear = allYears.find(y => y.year === yearData.year);
          if (existingYear) {
            existingYear.value += yearData.value;
            existingYear.percentage += yearData.percentage;
          } else {
            allYears.push({ ...yearData });
          }
        });
      });

      return allYears;
    },
  },
  methods: {
    sumCourseValues(course) {
      return sum(course.pieChart.values);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';

.pieChart {
  height: 20rem;
  width: 20rem;
}

.section {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 16px;
  margin-left: 2rem;

  .section_div {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
  }
}

.pieStats {
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 5;
  margin-top: 7rem;
  width: 20rem;
  color: #fff;
  text-align: center;

  > h4 {
    font-size: 40px;
    margin: 0;
  }
}

.pieChart-title {
  margin-left: 5rem;
}

.pie-legend {
  font-size: large;
}
.legend-container {
}
</style>
