<template>
  <div class="ontag">
    <div class="ontag__content">
      <div>
        <span
          v-if="combinedTodo.externalId"
          class="h1">Tasks for budget and event</span>
        <span
          v-else
          class="h1">Combined tasks for event and budget</span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
      </div>
      <template v-if="combinedTodos">
        <template v-if="combinedTodo.externalId">
          <a @click="showNone"> &lt; Back to list </a>
          <section>
            <p>Status: {{ combinedTodo.status }}</p>
            <p>Order no. {{ combinedTodo.orderNumber }}</p>
            <p>Customer in TERP {{ combinedTodo.customer }}</p>
            <p>Club name {{ combinedTodo.clubName }}</p>
            <p>Project no. in TERP {{ combinedTodo.terpId }}</p>
            <h3>Budget</h3>
            <table class="budgetTable">
              <thead>
                <th>Year</th>
                <th>Month</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Currency</th>
              </thead>
              <tr
                v-for="(budget, i) in combinedTodo.budgets"
                v-bind:key="i">
                <td>{{ budget.year }}</td>
                <td>{{ budget.month }}</td>
                <td>{{ budget.type }}</td>
                <td>{{ budget.value }}</td>
                <td>{{ budget.currency }}</td>
              </tr>
            </table>
            <h3>Event</h3>
            <table class="eventTable">
              <thead>
                <th>Eventline</th>
                <th>Task</th>
                <th>Eventdate</th>
                <th>Event Type</th>
                <th>Currency</th>
                <th>Description</th>
                <th>Unitprice</th>
                <th>Unit of measurment</th>
                <th>Quantity</th>
                <th>Invoice amount</th>
                <th>Revenue amount</th>
              </thead>
              <tr
                v-for="(event, i) in combinedTodo.events"
                v-bind:key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ event.task }}</td>
                <td>{{ event.eventDateText }}</td>
                <td>{{ event.eventType }}</td>
                <td>{{ event.currency }}</td>
                <td>{{ event.description }}</td>
                <td>{{ event.unitCustomerPrice }}</td>
                <td>{{ event.unitOfMeasure }}</td>
                <td>{{ event.quantity }}</td>
                <td>{{ event.invoiceAmount }}</td>
                <td>{{ event.revenueAmount }}</td>
              </tr>
            </table>
            <p>TE order comment</p>
            <br />
            <br />
            <label>Comment (Mandatory input if Delete or Pause)</label>
            <br />
            <textarea v-model="combinedTodo.comment"></textarea>
            <br />
            <p>
              Task done:
              {{ combinedTodo.doneDate == null ? '' : combinedTodo.doneDate }}
              {{ combinedTodo.doneBy }}
            </p>
            <p>
              Task paused:
              {{
                combinedTodo.pausedDate == null ? '' : combinedTodo.pausedDate
              }}
              {{ combinedTodo.pausedBy }}
            </p>
            <p>
              Task deleted:
              {{
                combinedTodo.deletedDate == null ? '' : combinedTodo.deletedDate
              }}
              {{ combinedTodo.deletedBy }}
            </p>
            <p>
              Task cancelled
              {{
                combinedTodo.cancelDate == null ? '' : combinedTodo.cancelDate
              }}
            </p>
            <template v-if="handleTask == true && combinedTodo.externalId">
              <div class="input-group--inline">
                <a @click="showNone"> &lt; Previous </a>
                <base-button
                  class="button--background-gray-dark"
                  @click="showNone"
                  text="Cancel">
                </base-button>
                <base-button
                  class="button--background-red"
                  :disabled="setDisabledPauseButton(id)"
                  @click="setAsPause(true)"
                  :text="
                    'Pause' + (id < combinedTodos.length - 1 ? ' and next' : '')
                  ">
                </base-button>
                <base-button
                  class="button--background-red"
                  :disabled="setDisabledDeleteButton(id)"
                  @click="setAsDelete(true)"
                  :text="
                    'Delete' +
                    (id < combinedTodos.length - 1 ? ' and next' : '')
                  ">
                </base-button>
                <base-button
                  class="button--background-red"
                  @click="setAsTodo(true)"
                  :disabled="setDisabledTodoButton(id)"
                  :text="
                    'Set as todo' +
                    (id < combinedTodos.length - 1 ? ' and next' : '')
                  ">
                </base-button>
                <base-button
                  class="button--background-blue"
                  :disabled="setDisabledDoneButton(id)"
                  @click="setAsDone(true)"
                  :text="
                    'Done' + (id < combinedTodos.length - 1 ? ' and next' : '')
                  ">
                </base-button>
              </div>
            </template>
            <template v-if="handleTask == false && combinedTodo.externalId">
              <div class="input-group--inline">
                <a @click="showNone"> &lt; Previous </a>
                <base-button
                  class="button--background-gray-dark"
                  @click="showNone"
                  text="Cancel">
                </base-button>
                <base-button
                  class="button--background-red"
                  :disabled="setDisabledPauseButton(id)"
                  @click="setAsPause(false)"
                  :text="'Pause'">
                </base-button>
                <base-button
                  class="button--background-red"
                  :disabled="setDisabledDeleteButton(id)"
                  @click="setAsDelete(false)"
                  :text="'Delete'">
                </base-button>
                <base-button
                  class="button--background-red"
                  @click="setAsTodo(false)"
                  :disabled="setDisabledTodoButton(id)"
                  text="Set as todo">
                </base-button>
                <base-button
                  class="button--background-blue"
                  :disabled="setDisabledDoneButton(id)"
                  @click="setAsDone(false)"
                  :text="'Done'">
                </base-button>
              </div>
            </template>
          </section>
        </template>
        <template v-else>
          <section class="todos_top-container">
            <input
              type="text"
              placeholder="Search" />
            <base-button
              text="Handle tasks"
              @click="handleTasks(0)">
            </base-button>
            No of events in list: {{ combinedTodos.length }}
            <br />
            Sum of revenue in the list: {{ sumOfRevenue() }} kr
            <br />
            Sum of cost in the list: {{ sumOfCost() }} kr
            <div class="todos_top-container__showShipped">
              <input
                type="checkbox"
                id="showShipped"
                v-model="showShipped" />
              <label for="showShipped">{{ $t('showOnlyShipped') }}</label>
            </div>
          </section>
          <table>
            <thead>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Status"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'status')"
                  :options="filterValues('status')">
                </multi-select>
              </th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Projectnumber"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'projectNumber')"
                  :options="filterValues('projectNumber')">
                </multi-select>
              </th>
              <th>Customer</th>
              <th>Club name</th>
              <th>Order no.</th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Revenue month"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'revenueMonth')"
                  :options="filterValues('revenueMonth')">
                </multi-select>
              </th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Revenue year"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'revenueYear')"
                  :options="filterValues('revenueYear')">
                </multi-select>
              </th>
              <th>Revenue</th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Cost month"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'costMonth')"
                  :options="filterValues('costMonth')">
                </multi-select>
              </th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Cost year"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'costYear')"
                  :options="filterValues('costYear')">
                </multi-select>
              </th>
              <th>Cost</th>
              <th>
                <multi-select
                  :use-icon="true"
                  icon-name="filter-icon"
                  label="Last updated"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, 'lastUpdateBy')"
                  :options="filterValues('lastUpdateBy')">
                </multi-select>
              </th>
              <th></th>
            </thead>
            <tr
              v-for="(combinedTodo, i) in combinedTodos"
              v-bind:key="i">
              <td>{{ combinedTodo.status }}</td>
              <td>{{ combinedTodo.terpId }}</td>
              <td>{{ combinedTodo.customer }}</td>
              <td>{{ combinedTodo.clubName }}</td>
              <td>{{ combinedTodo.orderNumber }}</td>
              <td>{{ combinedTodo.revenueMonth }}</td>
              <td>{{ combinedTodo.revenueYear }}</td>
              <td>{{ combinedTodo.revenue }}</td>
              <td>{{ combinedTodo.costMonth }}</td>
              <td>{{ combinedTodo.costYear }}</td>
              <td>{{ combinedTodo.cost }}</td>
              <td>
                {{
                  combinedTodo.lastChanged == '' ||
                  combinedTodo.lastChangedBy == ''
                    ? ''
                    : combinedTodo.lastChanged +
                      ' by ' +
                      combinedTodo.lastChangedBy
                }}
              </td>
              <td @click="showCombinedTodo(combinedTodo.externalId, i)">
                <use-svg
                  svg="arrow-right"
                  class="svg--90-negative" />
              </td>
            </tr>
          </table>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import MultiSelect from '@/components/form-elements/multi-select';
import UseSvg from '@/components/use-svg';

export default {
  name: 'OnTagCourseGuides',
  components: {
    BaseButton,
    MultiSelect,
    UseSvg,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      filter: {
        status: ['Paused', 'To do', 'Deleted', 'Done'],
        projectNumber: [],
        revenueMonth: [],
        revenueYear: [],
        costMonth: [],
        costYear: [],
        lastUpdateBy: [],
      },
      sortBy: 'changedDate',
      handleTask: false,
      showShipped: false,
    };
  },
  computed: {
    _combinedTodos() {
      return this.$store.getters['todo/combinedTodos'];
    },
    combinedTodos() {
      let ct = this._combinedTodos;
      for (let f of [
        'status',
        'projectNumber',
        'revenueMonth',
        'revenueYear',
        'costMonth',
        'costYear',
        'lastUpdateBy',
      ]) {
        if (this.filter[f].length > 0) {
          ct = ct.filter(a => {
            return this.filter[f].includes(a[f]);
          });
        }
      }

      if (this.showShipped) {
        ct = ct.filter(a => a.isShipped);
      }

      return ct.sort((a, b) =>
        this.sortBy in a
          ? this.sortBy in b
            ? a[this.sortBy] > b[this.sortBy]
              ? 1
              : -1
            : 1
          : -1
      );
    },
    combinedTodo() {
      return this.$store.getters['todo/combinedTodo'];
    },
    isLoading() {
      return this.$store.getters['todo/isLoading'];
    },
  },
  created() {
    if (this.id) {
      this.$store.dispatch('todo/getCombinedTodo', {
        id: this.combinedTodos[this.id].externalId,
      });
    } else {
      this.$store.dispatch('todo/getCombinedTodos');
    }
  },
  methods: {
    handleTasks(id) {
      if (id < this.combinedTodos.length - 1) {
        this.showCombinedTodo(this.combinedTodos[id].externalId, id);
        this.handleTask = true;
      } else {
        this.showCombinedTodo(this.combinedTodos[id].externalId, id);
        this.handleTask = false;
        this.$store.dispatch('todo/getCombinedTodos');
      }
    },
    toggleModal() {
      this.isLoading = !this.isLoading;
    },
    setAsDone(next) {
      this.$store
        .dispatch('todo/patchCombinedTodo', {
          id: this.combinedTodos[this.id].externalId,
          status: 'Done',
          comment: this.combinedTodos[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.combinedTodo.externalId = false;
            this.$store.dispatch('todo/getCombinedTodos');
          }
        });
    },
    setAsTodo(next) {
      this.$store
        .dispatch('todo/patchCombinedTodo', {
          id: this.combinedTodos[this.id].externalId,
          status: 'To do',
          comment: this.combinedTodos[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.combinedTodo.externalId = false;
            this.$store.dispatch('todo/getCombinedTodos');
          }
        });
    },
    setAsPause(next) {
      this.$store
        .dispatch('todo/patchCombinedTodo', {
          id: this.combinedTodos[this.id].externalId,
          status: 'Paused',
          comment: this.combinedTodos[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.combinedTodo.externalId = false;
            this.$store.dispatch('todo/getCombinedTodos');
          }
        });
    },
    setAsDelete(next) {
      this.$store
        .dispatch('todo/patchCombinedTodo', {
          id: this.combinedTodos[this.id].externalId,
          status: 'Deleted',
          comment: this.combinedTodos[this.id].comment,
        })
        .then(() => {
          if (next) {
            this.id += 1;
            this.handleTasks(this.id);
          } else {
            this.combinedTodo.externalId = false;
            this.$store.dispatch('todo/getCombinedTodos');
          }
        });
    },
    sumOfRevenue() {
      if (this.combinedTodos) {
        let myArray = this.combinedTodos.map(revenues => revenues.revenue);
        let sumOfArray = myArray.reduce((pv, cv) => pv + cv, 0);
        return sumOfArray.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
    },
    sumOfCost() {
      if (this.combinedTodos) {
        let myArray = this.combinedTodos.map(costs => costs.cost);
        let sumOfArray = myArray.reduce((pv, cv) => pv + cv, 0);
        return sumOfArray.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
    },
    showCombinedTodo(id, i) {
      this.$store.dispatch('todo/getCombinedTodo', { id: id });
      this.id = i;
    },

    setDisabledDeleteButton(id) {
      let status = this.combinedTodos[id].status.toLowerCase();
      if (
        status === 'deleted' ||
        status === 'cancelled' ||
        !this.combinedTodo.comment
      ) {
        return true;
      }
    },
    setDisabledPauseButton(id) {
      let status = this.combinedTodos[id].status.toLowerCase();
      if (
        status === 'cancelled' ||
        status === 'paused' ||
        !this.combinedTodo.comment
      ) {
        return true;
      }
    },
    setDisabledDoneButton(id) {
      let status = this.combinedTodos[id].status.toLowerCase();
      if (status === 'done' || status === 'cancelled') {
        return true;
      }
    },
    setDisabledTodoButton(id) {
      let status = this.combinedTodos[id].status.toLowerCase();
      if (
        status === 'to do' ||
        status === 'cancelled' ||
        !this.combinedTodo.comment
      ) {
        return true;
      }
    },
    showNone() {
      this.$store.dispatch('todo/getCombinedTodos');
      this.combinedTodo.externalId = '';
      this.handleTask = false;
    },
    filterValues(prop) {
      return [...new Set(this._combinedTodos.map(a => a[prop]))].map(a => {
        return {
          displayName: a,
          value: a,
          checked: this.filter[prop].includes(a),
        };
      });
    },
    filterResults(e, prop) {
      this.filter[prop] = e.options
        .filter(option => option.checked)
        .map(o => o.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.todos_top-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 12px;
  margin-bottom: 12px;

  &__showShipped {
    display: flex;
    align-items: center;
    gap: 8px;

    input {
      width: 20px;
      height: 20px;
    }
  }
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: auto;
  th {
    padding: 0 0.625rem;
    text-align: left;
    background-color: color(blue-light);
  }
  tr {
    &.pointer {
      cursor: auto;
    }
    &:nth-child(even) {
      background-color: color(gray-lighter);
    }
    &.checked {
      background-color: color(blue-light);
    }
  }
  td {
    padding: 0.625rem;
    &:last-child {
      padding-right: 0;
      cursor: pointer;
    }
  }
}
.budgetTable {
  width: 50%;
}
.eventTable {
  width: 100%;
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
  margin-bottom: 20px;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 20px;
}
.ontag {
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.ontag__content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  &.dashboard {
    background-color: transparent;
    border-radius: 0;
  }
}
.input-group--inline {
  button {
    margin: 0.5rem;
  }
}
</style>
