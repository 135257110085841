<template>
  <div class="golf-office">
    <div class="golf-office--content">
      <img
        src="../assets/images/bb-logo-icon.svg"
        alt="Business Board"
        width="387.82"
        height="75.4" />
      <div class="content__input">
        <div>
          <router-link
            class="colored-text"
            to="/businessboard/">&laquo; {{ $t('back') }}</router-link>
        </div>
        <h1 class="colored-text">{{ $t('forgotPassword') }}</h1>
        <p class="colored-text">{{ $t('GolfOffice.resetPasswordInfo') }}</p>
        <div class="content__input-group">
          <input-type
            :label="$t('email')"
            input-id="email"
            v-model="email"
            @input="checkEmailValidity" />
          <div
            v-if="failedEmailValidiation && this.email"
            class="input__error--message">
            {{ $t('GolfOffice.errorMail') }}
          </div>
          <!-- Honeypot below -->
          <input-type
            class="ohnohoney"
            label="Website Email"
            input-id="website-email"
            v-model="websiteEmail" />
        </div>
        <base-button
          :text="$t('resetPassword')"
          @click="forgotPassword"
          :disabled="email.length <= 0 || failedEmailValidiation" />
      </div>
    </div>
  </div>
</template>

<script>
import InputType from '@/components/form-elements/input';
import BaseButton from '../components/form-elements/base-button';
import LocalizationMixin from '@/mixins/localization-mixin.vue';
export default {
  name: 'ForgotPassword',
  mixins: [LocalizationMixin],
  components: {
    InputType,
    BaseButton,
  },
  data() {
    return {
      email: '',
      websiteEmail: '',
      failedEmailValidiation: false,
    };
  },
  methods: {
    forgotPassword() {
      if (this.email.length > 0) {
        if (this.websiteEmail.length > 0) {
          this.$router.push({ path: '/businessboard/' });
        } else {
          this.$store
            .dispatch('user/forgotPassword', { email: this.email })
            .then(() => {
              this.$toasted.success(this.$t('resetMessage'));
            });
        }
      }
    },
    checkEmailValidity() {
      if (!this.email.includes('@')) {
        this.failedEmailValidiation = true;
      } else {
        this.failedEmailValidiation = false;
      }
    },
    toLogin() {
      this.$router.push({ path: '/businessboard/' });
    },
  },
};
</script>
<style lang="scss" scoped>
.golf-office {
  flex-grow: 1;
}
.golf-office--content {
  background-color: color(bb-primary);
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  height: 100vh;
  width: 100vw;
  border-radius: 0;
  background-image: url('../assets/images/bb-logo-background.svg');
}
.content__input {
  padding: 5rem;
  align-self: center;
  justify-self: center;
  margin-top: 5rem;
  padding-top: 5.144375rem;
  flex-shrink: 0;
  background-color: #eaf6f6;
  width: 36.563rem;
  height: 26.832rem;
  padding-bottom: 9.2875rem;
  border-radius: 0.5rem;
  width: 62.85431rem;
  height: 24.62081rem;
  margin-bottom: 3rem;
  button {
    margin-top: 2rem;
    background-color: color(bb-primary);
  }
}
.content__input-group {
  font-weight: 600;
  .input :last-child {
    width: 25rem;
    height: 1.875rem;
    border-radius: 0.1875rem;
    border: 1px solid #373d3f;
    background: var(--white, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }
}
.colored-text {
  color: #0e3746;
}
</style>
