import { render, staticRenderFns } from "./game-course-option2.vue?vue&type=template&id=5c0f9c37&scoped=true"
import script from "./game-course-option2.vue?vue&type=script&lang=js"
export * from "./game-course-option2.vue?vue&type=script&lang=js"
import style0 from "./game-course-option2.vue?vue&type=style&index=0&id=5c0f9c37&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0f9c37",
  null
  
)

export default component.exports