export function analyzeHole(hole, tees, colors) {
  let s = hole.surroundings;
  if (s) {
    s = JSON.parse(hole.surroundings);
    if (s && s.json) {
      s = JSON.parse(s.json);
    }
  }
  let arr = [];
  if (!hasElement(s, 'green')) {
    arr.push({
      topic: 'map',
      property: 'surroundings',
      elementName: 'green',
      label: 'Missing a green',
      icon: 'tietoevry-icons-alert',
    });
  }
  if (!hasElement(s, 'tee')) {
    arr.push({
      topic: 'map',
      property: 'surroundings',
      elementName: 'tee',
      label: 'Missing a tee cut',
      icon: 'tietoevry-icons-alert',
    });
  }
  let greenPoints = hole.greenPoints;
  if (!greenPoints || !(greenPoints.center_green || greenPoints.center)) {
    arr.push({
      topic: 'map',
      property: 'greenPoints',
      key: 'center',
      elementName: 'greencenter',
      label: 'Missing center green',
      icon: 'tietoevry-icons-alert',
    });
  }
  if (!greenPoints || !(greenPoints.front_green || greenPoints.front)) {
    arr.push({
      topic: 'map',
      property: 'greenPoints',
      elementName: 'greenfront',
      key: 'front',
      label: 'Missing front green',
      icon: 'tietoevry-icons-alert',
    });
  }
  if (!greenPoints || !(greenPoints.back_green || greenPoints.back)) {
    arr.push({
      topic: 'map',
      property: 'greenPoints',
      key: 'back',
      elementName: 'greenback',
      label: 'Missing back green',
      icon: 'tietoevry-icons-alert',
    });
  }
  if (tees) {
    for (let t of tees) {
      if (!t.elementId && colors) {
        t.elementId = colors[t.externalTeeId];
      }
      if (!t.elementId) {
        console.warn(
          `Tee "${t.id}" does not have an elementId set to it`,
          tees,
          colors
        );
        continue;
      }
      if (!hasTee(hole, t.elementId)) {
        arr.push({
          topic: 'map',
          property: 'teePoints',
          key: t.elementId,
          elementName: 'tee' + t.elementId,
          label: `Missing a tee point for ${t.teeAlias}`,
          icon: 'tietoevry-icons-alert',
        });
      }
    }
  }
  return arr;
}
function hasTee(hole, elementId) {
  if (!hole.teePoints) {
    return false;
  }
  if (Array.isArray(hole.teePoints)) {
    return hole.teePoints.some(p => p.color === elementId);
  }
  return hole.teePoints[elementId] !== undefined;
}
function hasElement(target, elementName) {
  if (!target) {
    return false;
  }
  if (target.features) {
    return target.features.some(f => f.properties.elementName === elementName);
  }
  let r = new RegExp('\\"elementName\\":\\"' + elementName + '\\"', 'gm');
  return r.test(target);
}
