<template>
  <div class="legend">
    <div class="head">{{ year1 }}</div>
    <div class="head">{{ year2 }}</div>
    <div></div>
    <div
      class="line"
      style="border-top: 2px solid #00a99d"></div>
    <div
      class="item"
      style="background: #fbebdd"></div>
    <div class="text">Spelkapacitet</div>
    <div
      class="item"
      :style="'background:' + colors[0]"></div>
    <div
      class="item"
      :style="'background: #FFF;border: 2px solid ' + colors[0]"></div>
    <div class="text">{{ $t('totalRounds') }}</div>
  </div>
</template>
<script>
import LocalizationMixin from '../../../mixins/localization-mixin.vue';
export default {
  mixins: [LocalizationMixin],
  props: {
    year1: {
      type: Number,
      default: 2023,
    },
    year2: {
      type: Number,
      default: 2022,
    },
  },
  data() {
    return {
      colors: ['#27645C', '#ABDED1'],
    };
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.legend {
  margin-left: auto;
  width: 30rem;
  padding: 1rem;
  font-size: 12px;
  display: grid;
  grid-template-columns: 4rem 4rem auto;
  font-weight: 400;
  font-size: 18px;
  justify-items: center;
  .head {
    font-weight: 600;
  }
  .line {
    width: 25px;
    margin: auto;
  }
  .item {
    border-radius: 2px;
    width: 25px;
    height: 15px;
  }
  .text {
    justify-self: left;
  }
  > div {
    margin: 5px;
  }
}
</style>
