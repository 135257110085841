<template>
  <div :class="{ 'notes-container': true, show: show }">
    <div v-if="show">
      <div class="notes__textarea-container">
        <use-svg
          svg="pin-icon"
          class="notes__pin-icon" />
        <textarea
          v-model="localComment"
          class="notes__textarea"
          :disabled="isDisabled">
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';

export default {
  name: 'Note',
  components: { UseSvg },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    comment: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localComment: this.comment,
    };
  },
  watch: {
    comment(newComment) {
      this.localComment = newComment;
    },
    localComment(newLocalComment) {
      this.$emit('update:comment', newLocalComment);
    },
  },
  mounted() {
    this.localComment = this.comment;
  },
};
</script>
<style lang="scss" scoped>
.notes-container {
  display: flex;
  flex-direction: column;
}

.notes__textarea-container {
  position: relative;
  max-width: 200px;
  .notes__pin-icon {
    position: absolute;
    top: 11px;
    left: 50%;
    transform: rotate(5deg);
  }
}

.notes__textarea {
  border: 1px solid #d2b48c;
  border-radius: 4px;
  font-size: 18px;
  padding: 0px;
  background-color: #fff8dc;
  color: #333;
  opacity: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  resize: none;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
}

.notes-container.show .notes__textarea {
  opacity: 1;
  min-height: 200px;
  width: 100%;
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  margin-bottom: 30px;
  margin-top: 20px;
}

.notes__action-button {
  border: none;
  color: color(blue);

  :hover {
    color: color(blue-dark);
  }

  :active {
    color: color(blue);
  }

  img {
    vertical-align: middle;
    margin-right: 5px;
  }
}
</style>
