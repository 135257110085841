<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <p>{{ $t('Businessboard.deleteFeeColumnConfirm', {id: id}) }}</p>
          <div class="input-group--inline user__actions">
              <base-button
              :text="$t('no')"
              @click="back">
              </base-button>
              <base-button
                :text="$t('yes')"
                @click="deleteFeeColumn"
                v-element-loader="{ loaderKey: 'delete-feecolumn' }"/>
          </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'DeleteFeeColumn',
  components: {
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
    };
  },
  methods: {
    back () {
      window.history.back();
    },
    deleteFeeColumn() {
      this.$store.dispatch('businessboard/deleteMembershipFeeColumn', { id: this.id, loader: 'delete-feecolumn' })
        .then(() => { this.back(); });
    },
  },
};

</script>
<style lang="scss" scoped>
  .golfoffice {
    // margin: 1rem;
    flex-grow: 1;
    width: 100%;
    @include media('small') {
      width: calc((100% / 2) - (1rem * 1));
    }
    @include media('large') {
      width: calc((100% / 3) - (1rem * 2));
    }
  }
  .golfoffice--content {
    background-color: color(white);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;

    button {
      margin-left: 1.25rem;
    }
  }
</style>
