<template>
  <div class="ontag">
    <div class="ontag--content">
      <h1>{{ $t('settings') }}</h1>
      <!-- <p v-if="$i18n.availableLocales.length > 1">Språk ska in här:
        <span class="nav--club__lang">
          <select v-model="$i18n.locale">
            <option v-for="(lang, i) in $i18n.availableLocales" :key="i" :value="lang">{{ lang }}</option>
          </select>
        </span>
      </p> -->
      <section class="password">
        <h3>{{ $t('changePassword') }}</h3>
        <p>{{ $t('passwordText') }}</p>
        <ul>
          <li>{{ $t('passwordRule1') }}</li>
          <li>{{ $t('passwordRule2') }}</li>
          <li>{{ $t('passwordRule3') }}</li>
          <li>{{ $t('passwordRule4') }}</li>
        </ul>
        <div class="input-group">
          <label for="oldPassword">{{ $t('currentPassword') }}</label>
          <input
            type="password"
            name="oldPassword"
            id="oldPassword"
            ref="oldPassword" />
        </div>
        <div class="input-group">
          <label for="password">{{ $t('newPassword') }}</label>
          <input
            type="password"
            name="password"
            id="password"
            ref="password" />
        </div>
        <div class="input-group">
          <label for="repeatedPassword">{{ $t('confirmNewPassword') }}</label>
          <input
            type="password"
            name="repeatedPassword"
            id="repeatedPassword"
            ref="repeatedPassword" />
        </div>
        <div v-if="errors.length > 0">
          <p
            v-for="(error, i) in errors"
            :key="i">
            {{ error }}
          </p>
        </div>
        <base-button
          :text="$t('changePassword')"
          v-element-loader="{ loaderKey: 'user-change-password' }"
          @click="changePassword" />
      </section>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import { validatePassword } from '@/core/helpers';
import elementLoader from '@/directives/element-loader';
export default {
  name: 'UserSettings',
  components: {
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    changePassword() {
      this.errors = [];
      if (this.$refs.oldPassword.value !== '') {
        if (this.$refs.password.value === this.$refs.repeatedPassword.value) {
          const validate = validatePassword(this.$refs.password.value);
          if (validate === true) {
            this.$store
              .dispatch('user/changePassword', {
                oldPassword: this.$refs.oldPassword.value,
                password: this.$refs.password.value,
                repeatedPassword: this.$refs.repeatedPassword.value,
              })
              .then(data => {
                if (data.success === true) {
                  this.$toasted.success('Password has been changed');
                  this.$refs.oldPassword.value = '';
                  this.$refs.password.value = '';
                  this.$refs.repeatedPassword.value = '';
                } else {
                  this.errors.push(data.message);
                }
              });
          } else {
            this.errors = validate;
          }
        } else {
          this.errors.push(
            'Your new password and confirmed password is not the same'
          );
        }
      }
    },
  },
};
</script>
<style lang="scss">
.ontag--content {
  background-color: color(white);
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
}
.password {
  display: flex;
  flex-direction: column;
  // width: 70%;
  button {
    margin-left: auto;
  }
}
.input-group {
  width: 100%;
  label {
    display: inline-block;
    width: 30%;
  }
  input {
    // width: 70%;
  }
}
</style>
