<template>
  <div class="holesponsor">
    <div class="holesponsor__standard__title" @click="expand">
      <h2>{{item.name}}</h2>
      <use-svg v-if="isExpanded" class="svg--180-negative holesponsor__arrow-down" svg="arrow-right" />
      <use-svg v-else class="holesponsor__arrow-up" svg="arrow-right" />
    </div>
    <div class="holesponsor__holder" v-if="isExpanded">
      <section class="holesponsor__actions">
        <base-button
          text="Använd standard"
          :disabled="!holesSelected"
          @click="toggleDeleteHolesponsorsModal" />
        <input
          hidden
          type="file"
          id="file_upload"
          name="file_upload"
          @change="e => addSelectedFiles(item.id, e)">
        <base-button
          text="Välj fil för markerade"
          :disabled="!holesSelected"
          @click="chooseSelectedFiles('file_upload')" />
      </section>
      <section class="holesponsor__header">
        <div class="holesponsor__header--select">
          <div class="input checkbox">
            <div class="checkbox--group">
              <input
                type="checkbox"
                id="selectAllHoles"
                name="selectAllHoles"
                ref="selectAllHoles"
                @click="toggleSelectAllHoles">
              <label for="selectAllHoles">
                <span>
                  <span></span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="holesponsor__header--name">
          <p class="first-letter">{{ $t('hole') }}</p>
        </div>
        <div class="holesponsor__header--image">
          <p class="first-letter">Sponsorbild</p>
        </div>
        <div class="holesponsor__header--url">
          <p class="first-letter">Webbadress</p>
        </div>
        <div class="holesponsor__header--edit">
          <p class="first-letter">{{ $t('edit') }}</p>
        </div>
      </section>
      <section v-for="(hole, i) in item.holes" :key="hole.holeNumber">
        <div class="holesponsor__content" :class="{'odd': i % 2 > 0}">
          <div class="holesponsor__content--select">
            <div class="input checkbox">
              <div class="checkbox--group">
                <input
                  type="checkbox"
                  :id="`selectHole${hole.holeNumber}`"
                  :name="`selectHole${hole.holeNumber}`"
                  :ref="`selectHole${hole.holeNumber}`"
                  @click="toggleSelectHole(hole.holeNumber)"/>
                <label :for="`selectHole${hole.holeNumber}`">
                  <span>
                    <span></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="holesponsor__content--name">
            <p class="first-letter hole-label">{{ $t('hole') }} {{ hole.holeNumber }}</p>
          </div>
          <div class="holesponsor__content--image">
            <img v-if="hole.sponsorImageUrl" :src="hole.sponsorImageKey ? (hole.sponsorImageUrl + hole.sponsorImageKey) : hole.sponsorImageUrl">
            <input
              hidden
              type="file"
              :id="`file_upload_${hole.holeNumber}`"
              :name="`file_upload_${hole.holeNumber}`"
              @change="e => addFile(item.id, hole.holeNumber, e)">
            <base-button
              :text="$t('Choose file')"
              @click="chooseFile(`file_upload_${hole.holeNumber}`)" />
          </div>
          <div class="holesponsor__content--url">
            <div class="input">
              <input
                type="text"
                name="holesponsor-url"
                id="holesponsor-url"
                class="input__input"
                v-model="hole.sponsorUrl">
            </div>
          </div>
          <div class="holesponsor__content--edit">
            <button
              class="button--icon-only no-border transparent"
              @click="toggleDeleteHolesponsorModal(hole.id)">
              ✖
            </button>
          </div>
        </div>
      </section>
    </div>
    <modal-window :show="showDeleteHolesponsorsModal">
      <div class="modal__header">
        Använd standardloggor
      </div>
      <div class="modal__content">
        <p class="first-letter">{{ $t('are you sure') }}?</p>
      </div>
      <div class="modal__content--actions">
        <base-button
          class="button--background-gray-dark"
          :text="$t('cancel')"
          @click="toggleDeleteHolesponsorsModal">
        </base-button>
        <base-button
          class="no-border transparent"
          text="Använd standard"
          @click="deleteHolesponsors"/>
      </div>
    </modal-window>
    <modal-window :show="showDeleteHolesponsorModal">
      <div class="modal__header">
        Använd standardlogga
      </div>
      <div class="modal__content">
        <p class="first-letter">{{ $t('are you sure') }}?</p>
      </div>
      <div class="modal__content--actions">
        <base-button
          class="button--background-gray-dark"
          :text="$t('cancel')"
          @click="toggleDeleteHolesponsorModal">
        </base-button>
        <base-button
          class="no-border transparent"
          text="Använd standard"
          @click="deleteHolesponsor"/>
      </div>
    </modal-window>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import ModalWindow from '@/components/modal/modal';
import BaseButton from '@/components/form-elements/base-button';

export default {
  name: 'holesponsor',
  components: {
    UseSvg,
    ModalWindow,
    BaseButton,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isExpanded: false,
      showDeleteHolesponsorModal: false,
      showDeleteHolesponsorsModal: false,
      idToDelete: null,
      holesSelected: false,
    };
  },
  methods: {
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    formatDateAndTime (dateAndTime) {
      const dateString = new Date(dateAndTime).toLocaleDateString('sv-SE');
      const timeString = new Date(dateAndTime).toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
      return dateString + ' ' + timeString;
    },
    toggleSelectAllHoles () {
      this.item.holes.forEach(hole => {
        this.$refs[`selectHole${hole.holeNumber}`][0].checked = this.$refs.selectAllHoles.checked;
      });
      this.holesSelected = this.$refs.selectAllHoles.checked;
    },
    toggleSelectHole (item) {
      this.holesSelected = this.$refs[`selectHole${item}`][0].checked;
    },
    editHolesponsor () {
      this.$router.push({ name: `edit-${this.typeName}-holesponsor`, params: { id: this.item.id, typeName: this.typeName, type: this.type } });
    },
    toggleDeleteHolesponsorModal (id) {
      this.idToDelete = id;
      this.showDeleteHolesponsorModal = !this.showDeleteHolesponsorModal;
    },
    toggleDeleteHolesponsorsModal () {
      this.showDeleteHolesponsorsModal = !this.showDeleteHolesponsorsModal;
    },
    deleteHolesponsor () {
      const holeIndex = this.item.holes.findIndex(hole => hole.id === this.idToDelete);
      this.item.holes[holeIndex].sponsorImageKey = this.item.defaultSponsorImageKey;
      this.item.holes[holeIndex].sponsorImageUrl = this.item.defaultSponsorImageUrl;
      this.item.holes[holeIndex].sponsorUrl = this.item.defaultSponsorUrl;
      this.toggleDeleteHolesponsorModal();
    },
    deleteHolesponsors () {
      const holeNums = this.item.holes.length;
      for (let i = 0; i < holeNums; i += 1) {
        const hole = this.item.holes[i];
        if (this.$refs[`selectHole${hole.holeNumber}`][0].checked === true) {
          hole.sponsorImageKey = this.item.defaultSponsorImageKey;
          hole.sponsorImageUrl = this.item.defaultSponsorImageUrl;
          hole.sponsorUrl = this.item.defaultSponsorUrl;
        }
      }
      this.$refs.selectAllHoles.checked = false;
      this.toggleSelectAllHoles();
      this.toggleDeleteHolesponsorsModal();
    },
    chooseFile (value) {
      document.getElementById(value).click();
    },
    addFile (courseId, holeNumber, event) {
      let image;
      image = event.target.files[0];
      if (image) {
        this.$store.dispatch('holesponsors/addImages', { image: image, courseId, holes: [holeNumber] });
      }
    },
    chooseSelectedFiles (value) {
      const holeNums = this.item.holes.length;
      for (let i = 0; i < holeNums; i += 1) {
        const hole = this.item.holes[i];
        if (this.$refs[`selectHole${hole.holeNumber}`][0].checked === true) {
          document.getElementById('file_upload').click();
          return;
        }
      }
    },
    addSelectedFiles (courseId, event) {
      let holes = [];
      const holeNums = this.item.holes.length;
      for (let i = 0; i < holeNums; i += 1) {
        const hole = this.item.holes[i];
        if (this.$refs[`selectHole${hole.holeNumber}`][0].checked === true) {
          holes.push(hole.holeNumber);
        }
      }
      let image;
      image = event.target.files[0];
      if (image) {
        this.$store.dispatch('holesponsors/addImages', { image: image, courseId, holes });
      }
      this.$refs.selectAllHoles.checked = false;
      this.toggleSelectAllHoles();
    },
  },
};
</script>

<style lang="scss">
  .holesponsor {
    border: 1px solid color(gray-light);
    margin-bottom: 1rem;
  }
  .holesponsor__holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: color(white);
    .odd {
      background-color: color(gray-lighter);
    }

  }
  .holesponsor__standard__title {
    cursor: pointer;
    background-color: color(gray-light);
    padding: 1rem;
    @include body-tiny;
    display: flex;
    align-items: center;

    h2 {
      margin: 0;
      flex: 1;

      svg {
        color: color(gray-dark);
      }
    }
    span {
      margin-right: 0.5rem;
    }
    svg {
      color: color(gray-dark);
    }
    .holesponsor__arrow-up {
      position: relative;
      top: 0.125rem;
    }
    .holesponsor__arrow-down {
      position: relative;
      top: -0.25rem;
    }
  }
  .holesponsor__actions {
    display: flex;
    margin: 1rem 0 1rem auto;

    button:first-child {
      margin-right: 1rem;
    }
  }
  .holesponsor__header {
    background-color: color(blue-light);
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
  }
  .holesponsor__content {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    width: 100%;
  }
  .holesponsor__header--select,
  .holesponsor__content--select {
    align-items: center;
    display: flex;
  }
  .holesponsor__header--name,
  .holesponsor__content--name {
    align-items: center;
    display: flex;
    width: 10%;
  }
  .holesponsor__header--image,
  .holesponsor__content--image {
    align-items: center;
    display: flex;
    width: 40%;

    img {
      border: 1px solid color(gray);
      margin-right: 1rem;
      max-width: 80%;
      width: 205px;
      height: 74px;
      object-fit: contain;
    }
  }
  .holesponsor__header--url,
  .holesponsor__content--url {
    align-items: center;
    display: flex;
    width: 30%;
    .input {
      width: 100%;
    }
  }
  .holesponsor__header--edit,
  .holesponsor__content--edit {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 7%;
  }
</style>
