<template>
  <div class="select__wrapper">
    <span
      v-if="label"
      class="select__label"
      :data-required-suffix="this.requiredSuffix">{{label}}{{this.requiredSuffix}}</span>
    <div
      class="select"
      :id="id"
      :class="{'is-open': isOpen}">
      <button
        @click="toggleSelect('click', $event)"
        @keydown="triggerKeyPress($event)"
        ref="trigger"
        class="select__trigger"
        aria-haspopup="listbox"
        aria-expanded="false"
        :disabled="disabled">
        <span class="select__container">
          <span
            class="select__active"
            v-if="options[selectedIndex]">{{options[selectedIndex].displayName}}</span>
        </span>
        <div
          class="select__arrow"
          :class="{'is-open': isOpen}"></div>
      </button>
      <div class="select-list__wrapper">
        <ul
          class="select__list"
          @keydown="listKeyPress($event)"
          ref="list"
          role="listbox"
          tabindex="-1">
            <li
              class="select__option"
              :class="[{'is-selected': index === selectedIndex}, {'disabled': option && option.values && option.values.length === 0}]"
              :aria-selected="index === selectedIndex ? true : false"
              :id="id + '-option-' + index"
              :key="index"
              :data-value="option.value"
              v-for="(option, index) in options"
              @click="clickItem(index)"
              role="option">{{option.displayName}}
                <div
                  v-if="option.description"
                  class="select-option__desc">{{option.description}}
                </div>
                <div
                style="float:right"
                  v-if="index === selectedIndex">
                  <use-svg svg="bb-selected-icon" />
                </div>
              </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutside from '@/mixins/click-outside';
import UseSvg from '@/components/use-svg';

export default {
  name: 'CustomSelect',
  mixins: [clickOutside],
  components: {
    UseSvg,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    selected: {
      type: Number,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    visibleLabel: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      readyToOpen: true,
      selectedIndex: 0,
      currentIndex: 0,
      maxIndex: 0,
    };
  },
  computed: {
    requiredSuffix() {
      return this.required
        ? '*'
        : '';
    },
  },
  methods: {
    /* Handle select list toggle */
    toggleSelect(type, event) {
      if (event) {
        event.preventDefault();
      }
      if (this.isOpen) {
        this.isOpen = false;
        this.$refs.trigger.setAttribute('aria-expanded', 'false');

        if (this.selectedIndex !== this.currentIndex) { /* Don't emit unless new option is selected */
          this.currentIndex = this.selectedIndex;
          this.$emit('optionSelected', this.options[this.currentIndex]);
        }

        this.readyToOpen = false;
        setTimeout(() => { this.readyToOpen = true; }, 200);
      } else if (!this.isOpen) {
        if (this.readyToOpen) {
          this.isOpen = true;
          this.$refs.trigger.setAttribute('aria-expanded', 'true');
          this.$nextTick(() => {
            this.$refs.list.focus();
          });
        }
      }
    },
    /* Handle option click */
    clickItem(index) {
      this.selectedIndex = index;
      this.toggleSelect('item', event);
    },
    /* Handle trigger keypress */
    triggerKeyPress(event) {
      switch (event.keyCode) {
        case 40: /* Arrow down */
        case 27: /* Escape */
        case 32: /* Space */
          event.preventDefault();
          this.toggleSelect('triggerKey', event);
          break;
        default:
          break;
      }
    },
    /* Handle list keypress */
    listKeyPress(event) {
      switch (event.keyCode) {
        case 40: /* Arrow down */
          event.preventDefault();
          if (this.selectedIndex === this.maxIndex) {
            /* If selected option is at bottom, start from top */
            this.selectedIndex = 0;
          } else {
            this.selectedIndex = this.selectedIndex + 1;
          }
          break;
        case 38: /* Arrow up */
          event.preventDefault();
          if (this.selectedIndex === 0) {
            /* If selected option is at top, start from bottom */
            this.selectedIndex = this.maxIndex;
          } else {
            this.selectedIndex = this.selectedIndex - 1;
          }
          break;
        case 32: /* Space */
        case 27: /* Escape */
        case 13: /* Enter */
          event.preventDefault();
          this.toggleSelect('listKey', event);
          break;
        default:
          break;
      }
    },
    resetSelect(values) {
      this.localOptions = values;
      this.selectedIndex = -1;
      this.currentIndex = -1;
    },
  },
  watch: {
    selected() {
      this.selectedIndex = this.selected;
      this.currentIndex = this.selectedIndex;
      this.maxIndex = this.options.length - 1;
    },
    selectedIndex() {
      this.$refs.list.setAttribute('aria-activedescendant', this.id + '-option-' + this.selectedIndex);
    },
  },
  mounted() {
    this.selectedIndex = this.selected;
    this.currentIndex = this.selectedIndex;
    this.maxIndex = this.options.length - 1;
  },
  created() {
    this.$on('click-outside', function () {
      if(this.isOpen) {
        this.isOpen = false;
        this.$refs.trigger.setAttribute('aria-expanded', 'false');
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.select__wrapper {
  margin-bottom: 1rem;
  // width: 70%;
}
.select {
  display: inline-block;
  position: relative;
  border-radius: 3px;
  height: 33px;
  border: 1px solid #000000;
  width: 100%;

  &:focus-within,
  &:focus-within .select__list {
    border-color: color(gray);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &.is-open {
    z-index: 10;
    .select__list {
      display: block;
    }
  }
}
.select__arrow {
  border: 1px solid color(black);
  border-width: 0 0 2px 2px;
  width: 8px;
  height: 8px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  left: 4px;
  top: -1px;

  &.is-open {
    transform: rotate(135deg);
    top: 1px;
  }
}
.select__trigger {
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: #ABDED1;
  padding: 0 15px;
  height: 100%;
  cursor: pointer;
  width: 100%;
  &[disabled] {
    background-color: color(gray);
    cursor: not-allowed;
  }
}
.select__label {
  display: block;
  margin: 1px 0 2px 0;
}
.select-list__wrapper {
  margin-top: 12px;
  position: relative;
}
.select__list {
  display:none;
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid color(gray);
  width: calc(100% + 2px);
  left: -1px;
  background-color: color(gray-lighter);
  outline: none;
}
.select__option {
  padding: 10px 15px;
  cursor: pointer;
  border-top: 1px solid color(gray);

  &.disabled {
    pointer-events: none;
    color: color(gray);
  }

  &.is-selected {
    font-weight: bold;
  }

  &:hover {
    background-color: #EAF6F7;
  }

  &:first-child {
    border-top: none;
    &:before {
      content: '';
      position: absolute;
      right: 10px;
      top: -6px;
      z-index: 1;
      border: 1px solid color(gray);
      border-width: 0 0 1px 1px;
      width: 10px;
      height: 10px;
      transform: rotate(135deg);
      background-color: color(gray-lighter);
    }
    &:hover {
      &:before {
        background-color: #EAF6F7;
      }
    }
  }
}
</style>
