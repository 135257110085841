<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <router-link to="/businessboard/data">
        <span class="back_button">{{ $t('Businessboard.backToData') }}</span>
      </router-link>
      <template v-if="course">
        <div class="businessboard--header">
          <h1 class="title">
            {{ course.name }}
            <router-link :to="'/businessboard/create-course/' + id">
              <use-svg
                svg="edit-icon"
                class="title--icon" />
            </router-link>
          </h1>
        </div>
        <template v-if="course.imports">
          <list-table
            :items="course.imports.rows"
            :headers="course.imports.columns">
          </list-table>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table-businessboard';
import elementLoader from '@/directives/element-loader';
import UseSvg from '@/components/use-svg';

export default {
  name: 'CreateCourse',
  components: {
    ListTable,
    UseSvg,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      courseName: '',
      gitName: '',
      showinfo: false,
    };
  },
  methods: {
    chooseFile(value) {
      document.getElementById('file_upload').click();
    },
    fetchData() {
      this.$store.dispatch('businessboard/fetchSettings').then(() => {
        if (this.settings.hasGolfBox) {
          this.$store.dispatch('businessboard/fetchCourseGb', { id: this.id });
        } else {
          this.$store.dispatch('businessboard/fetchCourse', { id: this.id });
        }
      });
    },
  },
  computed: {
    gitCourse() {
      return this.$store.getters['businessboard/course'];
    },
    courseGb() {
      return this.$store.getters['businessboard/coursegb'];
    },
    settings() {
      return this.$store.getters['businessboard/settings'];
    },
    course() {
      if (this.settings.hasGolfBox) {
        return this.courseGb;
      } else {
        return this.gitCourse;
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss" scoped>
.loading-verification {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 5rem 5rem;
  background-repeat: no-repeat;
  background-position: center center;
  .loading-text {
    font-size: font(h2);
    color: color(white);
  }
}
.info {
  text-decoration: underline;
  cursor: pointer;
}
.businessboard {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  .content__input-group {
    width: 100%;
    @include media('medium') {
      width: calc((100% / 1.2) - (1rem * 2));
    }

    .user__actions {
      justify-content: flex-start;
      @include media('small') {
        justify-content: flex-end;
        // width: 70%;
      }
      a button {
        margin-left: 0;
      }
      button {
        margin-left: 1.25rem;
      }
    }
  }
  .title--icon {
    height: 20px;
    width: 20px;
  }
}
.businessboard--header {
  margin: 1rem;
}
.back_button::before {
  content: '<<';
}
.input__button {
  margin: auto;
  margin-top: 1rem;
}

.button__group {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
</style>
