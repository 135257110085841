/* Store module to handle Scorecard */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  vouchers: [],
  voucherType: null,
  voucher: {
    type: 0,
    digitalImageUrl: '',
    digitalImageKey: '',
    paperImageUrl: '',
    paperImageKey: '',
    upperText: '',
    lowerText: '',
    webLink: '',
    showOnSharedScorecard: true,
    courseIds: [],
    competitionIds: [],
    categories: [],
  },
  courses: [],
  competitions: [],
  categories: [],
};

const mutations = {
  vouchers (state, payload) {
    state.vouchers = payload;
  },
  voucher (state, payload) {
    state.voucher = payload;
  },
  voucherType (state, payload) {
    state.voucherType = payload;
  },
  setImageOnVoucher (state, payload) {
    let image = payload.image;
    let url = payload.url;
    let key = payload.key;
    let keyValue = payload.keyValue;
    Vue.set(state.voucher, image, url);
    Vue.set(state.voucher, key, keyValue);
  },
  createVoucher (state, payload) {
    state.vouchers.push(payload);
  },
  copyVoucher (state, payload) {
    state.voucher = state.vouchers.find(voucher => voucher.id === payload);
  },
  editVoucher (state, payload) {
    state.vouchers = [
      ...state.vouchers.filter(voucher => voucher.id !== payload.id), payload,
    ];
  },
  updateVouchers (state, payload) {
    let voucher = state.vouchers.find(voucher => voucher.id === payload.id);
    if (voucher) {
      voucher = payload;
    }
  },
  courses (state, payload) {
    state.courses = payload;
  },
  competitions (state, payload) {
    state.competitions = payload;
  },
  categories (state, payload) {
    state.categories = payload;
  },
  deleteVoucher (state, payload) {
    state.vouchers = [
      ...state.vouchers.filter(voucher => voucher.id !== payload),
    ];
  },
};

const getters = {
  vouchers: state => {
    return state.vouchers;
  },
  voucher: state => {
    return state.voucher;
  },
  voucherById: state => id => {
    return state.vouchers.find(voucher => voucher.id === id);
  },
  courses: state => {
    return state.courses;
  },
  competitions: state => {
    return state.competitions;
  },
  categories: state => {
    return state.categories;
  },
};

const actions = {
  fetchVouchers: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'voucher-fetch-vouchers', { root: true });
    let path = `vouchers/type/${params.type}`;

    if (params.search.length > 0) {
      path += '?search=' + params.search;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'voucher-fetch-vouchers', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('vouchers', data.result);
      context.commit('voucherType', params.type);
    }
  },
  fetchVoucher: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'voucher-fetch-voucher', { root: true });
    let path = `vouchers/${params.id}`;

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'voucher-fetch-voucher', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('voucher', data.result);
    }
  },
  addImage: async (context, params) => {
    let path = `vouchers/${params.type}/image`;
    let formData = new FormData();
    formData.append('image', params.image);

    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('setImageOnVoucher', {
        image: params.box,
        url: data.result.url,
        key: params.key,
        keyValue: data.result.key,
        type: params.type,
      });
    }
  },
  createVoucher: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'voucher-create-voucher', { root: true });
    const path = 'vouchers';

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'voucher-create-voucher', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('createVoucher', data.result);
    }
  },
  editVoucher: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'voucher-edit-voucher', { root: true });
    const path = `vouchers/${params.id}`;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'voucher-edit-voucher', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      // context.commit('editVoucher', data.result)
      context.commit('updateVouchers', data.result);
    }
  },
  fetchCourses: async (context, params) => {
    let path = `vouchers/courses`;

    if (params && params.id) {
      path += `?voucherId=${params.id}`;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('courses', data.result);
    }
  },
  fetchCompetitions: async (context, params) => {
    let path = 'vouchers/competitions';

    if (params && params.id) {
      path += `?voucherId=${params.id}`;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('competitions', data.result);
    }
  },
  fetchCategories: async (context, params) => {
    let path = 'vouchers/categories';

    if (params && params.id) {
      path += `?voucherId=${params.id}`;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('categories', data.result);
    }
  },
  deleteVoucher: async (context, params) => {
    let path = `vouchers/${params.id}`;

    let response = {};
    try {
      response = await Vue.axios.delete(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.status === 200) {
      context.commit('deleteVoucher', params.id);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
