<script>
import { Pie } from 'vue-chartjs';
import { COLORS } from './pie-chart-colors';

export default {
  extends: Pie,
  props: {
    courses: {
      type: Array,
    },
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: this.courses.map(c => c.name),
          datasets: [
            {
              data: this.courses.map(c => c.numberOfRounds),
              backgroundColor: COLORS,
            },
          ],
        },
        {
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 20,
              bottom: 20,
            },
          },
          legend: {
            display: false,
            position: 'right',
            labels: {
              usePointStyle: false,
              boxWidth: 20,
              padding: 15,
              fontSize: 14,
            },
          },
          tooltips: false,
          maintainAspectRatio: true,
          responsive: true,
        }
      );
    },
  },
  watch: {
    courses() {
      this.render();
    },
  },
  mounted() {
    this.render();
  },
};
</script>
