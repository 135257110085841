<template>
  <div :class="['circles', settings.fileFormat === '0' ? 'opaque-bg' : '']">
    <h1
      v-if="settings.showHeaders"
      class="circles-title">
      {{ $t('totalRounds') }}
    </h1>
    <div class="circle_div">
      <div class="circle circle_big circle_purple">
        <div>
          <h1>{{ totalRoundsNumber }}</h1>
          <p>
            {{ `${diagram.data.year}` }}
          </p>
        </div>
      </div>
      <reports-years-legend unit="ronder" />
    </div>
  </div>
</template>

<script>
import ReportsYearsLegend from '@/components/reports-templates/legend/reports-legend-years';
import { sum } from 'lodash';
import LocalizationMixinVue from '../../mixins/localization-mixin.vue';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixinVue],
  components: {
    ReportsYearsLegend,
  },
  props: { diagram: {} },
  computed: {
    totalRoundsNumber() {
      const courses = this.diagram.data.courses;
      const totalRounds = courses.reduce((sum, course) => {
        return sum + this.sumCourseValues(course);
      }, 0);
      return totalRounds.toLocaleString('sv-SE');
    },
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
  },
  methods: {
    sumCourseValues(course) {
      return sum(course.pieChart.values);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
.circles {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  margin-left: 1rem;
  h4 {
    text-align: center;
  }

  .circle {
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    margin: 0.5rem;
    text-align: center;
    font-family: 'SoraRegular';
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    &_big {
      font-size: 24px;
      width: 18rem;
      height: 18rem;
    }

    &_purple {
      background-color: #4b2d88;
      color: white;
    }

    div {
      margin-top: -1rem;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      margin-top: -2rem;
    }
  }

  .circle_div {
    display: grid;
    grid-template-columns: 23rem auto;
    margin-left: 3rem;
    height: 21rem;
  }
}

.circles-title {
  margin-left: 5rem;
}
</style>
