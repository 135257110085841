<template>
  <div
    :edit="edit"
    :editHole="editHole"
    :editIndex="editIndex"
    tabindex="0"
    :class="[
      'coursdata-holes-grid-item',
      'coursedata-holes-grid-item-can-drop',
      this.dragHover ? 'coursedata-holes-grid-item-placeholder-drag-hover' : '',
      this.faded ? 'coursedata-holes-grid-item-incomplete' : '',
      this.compact ? 'coursdata-holes-grid-item-compact' : '',
    ]">
    <div class="coursedata-holes-grid-item-header">
      <div class="coursedata-holes-grid-item-header-info">
        <div
          v-if="localHole.holeNumber"
          :class="
            'coursedata-holes-grid-item-hole-info coursedata-holes-grid-item-hole-top-hole' +
            (editHole
              ? ' coursedata-holes-grid-item-hole-top-hole-noborder'
              : '')
          ">
          <div class="coursedata-holes-grid-item-hole-info-label">Hole</div>
          <div class="coursedata-holes-grid-item-hole-info-value">
            {{ loopName(localHole.holeNumber) }}
          </div>
        </div>
        <div
          :class="
            'coursedata-holes-grid-item-hole-center-hole' +
            (editHole ? ' coursedata-holes-grid-placeholder' : '')
          ">
          <div class="coursedata-holes-grid-item-hole-center-hole-content">
            <span class="coursedata-holes-grid-item-hole-center-prefix">{{
              hole.groupName
            }}</span>{{ holeName(localHole) }}
          </div>
        </div>
        <div
          v-if="localHole.par"
          class="coursedata-holes-grid-item-hole-info">
          <div class="coursedata-holes-grid-item-hole-info-label">Par</div>
          <div class="coursedata-holes-grid-item-hole-info-value">
            {{ localHole.par }}
          </div>
        </div>
        <div
          v-if="localHole.index"
          :class="
            'coursedata-holes-grid-item-hole-info' +
            (editIndex ? ' coursedata-holes-grid-item-placeholder' : '')
          ">
          <div class="coursedata-holes-grid-item-hole-info-label">Index</div>
          <div class="coursedata-holes-grid-item-hole-info-value">
            {{ localHole.index }}
          </div>
        </div>
        <div
          v-else-if="editIndex"
          :class="
            'coursedata-holes-grid-item-hole-info' +
            (editIndex ? ' coursedata-holes-grid-item-placeholder' : '')
          ">
          <div class="coursedata-holes-grid-item-hole-info-label">Index</div>
          <div class="coursedata-holes-grid-item-hole-info-value">
            {{ localHole.index }}
          </div>
        </div>
      </div>
      <div
        v-if="rendering === true"
        class="coursedata-holes-grid-item-header-rendering-image"></div>
      <img
        v-else-if="localHole.image"
        class="coursedata-holes-grid-item-header-image"
        :src="localHole.image"
        alt="a" />
    </div>
    <div
      v-if="compact === false && tees && tees.length > 0"
      class="coursedata-holes-grid-item-body">
      <div
        v-for="tee in localTees"
        v-bind:key="tee.name"
        class="coursedata-holes-grid-item-tee">
        <div
          :class="
            'coursedata-holes-grid-item-tee-image' + ' ' + 'tee-' + tee.name
          "></div>
        <div class="coursedata-holes-grid-item-tee-name">{{ tee.title }}</div>
        <div
          v-if="tee.distance"
          class="coursedata-holes-grid-item-tee-length">
          {{ tee.distance }} m
        </div>
      </div>
    </div>
    <div
      v-if="compact === false && mapInfo && mapInfo.length > 0"
      class="coursedata-holes-grid-item-footer">
      <div
        class="coursedata-holes-grid-item-footer-item"
        v-for="item in mapInfo"
        v-bind:key="item.label">
        <div>{{ item.label }}</div>
        <div
          :class="['coursedata-holes-grid-item-footer-icon', item.icon]"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouseDataHolesGridItemView',
  emits: ['submit'],
  props: {
    hole: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    editHole: {
      type: Boolean,
      default: false,
    },
    editIndex: {
      type: Boolean,
      default: false,
    },
    dragHover: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    mapInfo: {
      type: Array,
      default: () => [],
    },
    tees: {
      type: Array,
      default: () => [],
    },
    faded: {
      type: Boolean,
      default: false,
    },
    rendering: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    hole: {
      handler(newValue) {
        this.localHole = newValue;
      },
      deep: true,
    },
    tees: {
      handler(newValue) {
        this.localTees = newValue;
      },
      deep: true,
    },
  },
  data() {
    return {
      localHole: this.hole,
      localTees: this.tees,
    };
  },
  methods: {
    holeName(hole) {
      let holeNumber = hole.groupHoleNumber;
      if (!holeNumber) return undefined;
      let h = holeNumber < 10 ? '0' + holeNumber : holeNumber;
      return h;
    },
    loopName(hole) {
      return hole < 10 ? '0' + hole : hole;
    },
  },
  components: {},
};
</script>
<style lang="scss">
@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.coursdata-holes-grid-item {
  transition: box-shadow 0.1s ease-in-out;
  pointer-events: all;
  font-family: 'Tietoevry Sans 1';
  min-width: 240px;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-border-radius-m, 8px);
  overflow: hidden;
  background: var(--surface-surface-primary, #fff);
  /* Modal shadow - Small */
  box-shadow: 0px 4px 9px 0px rgba(20, 75, 110, 0.12);
  * {
    pointer-events: none;
  }
  .coursedata-holes-grid-item-header {
    transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
    display: flex;
    min-height: 180px;
    padding: 10px;
    justify-content: center;
    gap: 10px;
    font-size: 18px;
    align-self: stretch;
    align-items: stretch;
    background: var(--green-rough, #304834);
    color: var(--text-text-inverted, #fdfdfd);
    .coursedata-holes-grid-item-header-info {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-items: flex-end;
      .coursedata-holes-grid-item-hole-top-hole {
        display: flex;
        align-items: flex-start;
        text-align: right;
        font-style: normal;
        font-weight: 400; /* 133.333% */
        align-items: center;
        border-bottom: 1px solid #ffffff;
      }
      .coursedata-holes-grid-item-hole-top-hole-noborder {
        border-bottom: none;
      }
      .coursedata-holes-grid-item-hole-center-hole {
        align-self: flex-end;
        justify-self: flex-end;
        font-size: 70px;
        font-style: normal;
        font-weight: 400;
        margin-top: auto;
        border-bottom: 1px solid #ffffff;
        flex: 1;
        display: flex;
        width: 100%;
        align-items: baseline;
        .coursedata-holes-grid-item-hole-center-hole-content {
          margin-top: auto;
          margin-left: auto;
        }
        .coursedata-holes-grid-item-hole-center-prefix {
          display: inline-block;
          font-size: 70%;
          margin-right: 2px;
        }
      }
      .coursedata-holes-grid-item-hole-center-hole
        + .coursedata-holes-grid-item-hole-info {
        padding-top: 4px;
      }
      .coursedata-holes-grid-item-hole-info {
        display: flex;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        .coursedata-holes-grid-item-hole-info-label {
          text-align: left;
          flex: 1;
        }
        .coursedata-holes-grid-item-hole-info-value {
          text-align: right;
        }
      }
    }
    .coursedata-holes-grid-item-header-image {
      aspect-ratio: 1/2;
      max-width: 80px;
      border-radius: var(--border-radius-border-radius-m, 8px);
      flex: 2;
      background-color: rgba(255, 255, 255, 0.5);
      align-self: flex-start;
    }
    .coursedata-holes-grid-item-header-rendering-image {
      aspect-ratio: 1/2;
      max-width: 80px;
      border-radius: var(--border-radius-border-radius-m, 8px);
      flex: 2;
      align-self: flex-start;
      background: linear-gradient(
        0,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.5)
      );
      background-size: 200% 200%;
      animation: gradient 4s ease infinite;
    }
  }
  .coursedata-holes-grid-item-body {
    display: flex;
    padding: var(--paddings-padding-m, 16px);
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    gap: var(--paddings-padding-s, 8px);
    align-self: stretch;
    font-size: 12px;
    line-height: 16px;
    .coursedata-holes-grid-item-tee {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      flex: 1;
      .coursedata-holes-grid-item-tee-image {
        height: 20px;
      }
      color: var(--default-black, #000);
      font-weight: 400;

      .coursedata-holes-grid-item-tee-name {
        flex: 1;
        color: var(--default-black, #000);
        font-weight: 700;
        line-height: 16px;
      }
      .coursedata-holes-grid-item-tee-length {
        color: var(--default-black, #000);
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  .coursedata-holes-grid-item-body + .coursedata-holes-grid-item-footer {
    border-top: 1px solid var(--dividers-borders-divider-primary, #cbcccd);
  }
  .coursedata-holes-grid-item-footer {
    font-size: 12px;
    color: var(--text-text-secondary, #313538);
    flex: 1;
    flex-direction: column;
    margin: 0 10px 8px 10px;
    display: flex;
    padding: 12px 0px 8px 0px;
    align-items: flex-start;
    align-self: stretch;

    gap: var(--paddings-padding-s, 8px);
    .tietoevry-icons-info {
      color: #0a41ae;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: -1px;
    }
    .coursedata-holes-grid-item-footer-icon {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    .tietoevry-icons-alert {
      color: orangered;
    }
    .tietoevry-icons-alert-2 {
      color: red;
    }
    .coursedata-holes-grid-item-footer-item {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      justify-items: center;
      align-self: stretch;
    }
  }
  &[disabled] {
    opacity: 0.6;
    .coursedata-holes-grid-item-header {
      color: var(--gray-grus-00, #bec1c8);
      background: #fff;
    }
  }
  &[selected] {
    outline: 3px solid var(--dividers-borders-border-selector-hover, #1861f2);
  }
  &[edit] {
    user-select: none;
  }
}
.coursdata-holes-grid-item.coursdata-holes-grid-item-compact {
  min-width: 180px;
  .coursedata-holes-grid-item-header {
    padding: 8px;
    min-height: 156px;
    font-size: 16px;
  }
  .coursedata-holes-grid-item-header-image {
    max-width: 70px;
  }
  .coursedata-holes-grid-item-header
    .coursedata-holes-grid-item-header-info
    .coursedata-holes-grid-item-hole-center-hole {
    font-size: 50px;
  }
}
.coursedata-holes-grid-item-incomplete {
  .coursedata-holes-grid-item-header {
    background: #626562;
  }
}
.coursedata-holes-grid-placeholder {
  border: 2px dotted var(--dividers-borders-divider-cta-primary, #fff) !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.coursedata-holes-grid-item-placeholder {
  user-select: none;
  cursor: move;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;

  padding-right: 8px;
  padding-left: 5px;
  border-radius: 5px;
  border: 2px dotted var(--dividers-borders-divider-cta-primary, #fff) !important;
  background: rgba(255, 255, 255, 0.2);
  min-height: 24px;
  align-items: center;
}
.coursedata-holes-grid-item-placeholder-drag-hover {
  .coursedata-holes-grid-placeholder,
  .coursedata-holes-grid-item-placeholder {
    transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
    background: rgba(255, 255, 255, 0.5);
  }
  .coursedata-holes-grid-item-header {
    background: var(--green-rough, #304834);
  }
  box-shadow: 0px 4px 9px 0px rgba(48, 72, 52, 1);
}
</style>
