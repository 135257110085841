<template>
  <div class="list-table-container">
    <div
      v-for="i in 1"
      :key="i"
      class="list-table">
      <div class="table-row">
        <div class="table-item skeleton-loader black border-radius"></div>
        <div class="table-item skeleton-loader"></div>
        <div class="table-item skeleton-loader border-radius"></div>
      </div>
      <div class="table-row">
        <div class="table-item skeleton-loader black border-radius"></div>
        <div class="table-item skeleton-loader black"></div>
        <div class="table-item skeleton-loader black border-radius"></div>
      </div>
      <div class="table-row">
        <div class="table-item skeleton-loader border-radius"></div>
        <div class="table-item skeleton-loader"></div>
        <div class="table-item skeleton-loader black border-radius"></div>
      </div>
      <div class="table-row">
        <div class="table-item skeleton-loader black border-radius"></div>
        <div class="table-item skeleton-loader black"></div>
        <div class="table-item skeleton-loader black border-radius"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListTableLoader',
  props: {
    columns: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style scoped>
@keyframes pulsate {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.list-table-container {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
}
.list-table {
  display: flex;
  flex-direction: column;
  width: 50rem;
  animation: expand 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  position: relative;
  margin: 1rem;
}

.skeleton-loader {
  height: 2rem;
  animation: pulsate 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
  transition: opacity 1s cubic-bezier(0.8, 0, 0.2, 1);
}

@keyframes expand {
  0% {
    box-shadow: 0 0 0 0 rgba(39, 100, 92, 0.4);
  }
  50% {
    box-shadow: 0 0 0 20px rgba(39, 100, 92, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(39, 100, 92, 0.4);
  }
}

.header-item {
  overflow-x: visible;
}

.table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  background-color: #fafafa;
  position: relative;
}

.border-raiuds {
  border-radius: 3px;
}

.table-item {
  padding: 0 0.625rem;
}

.black {
  background-color: lightgrey;
}

@keyframes expand {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
