<template>
  <div class="chart_settings">
    <div
      v-if="showModal"
      class="chart_settings_modal">
      <div class="header">
        <span>{{ $t('settingsFor') }} ”{{ titleText }}”</span>
      </div>
      <template v-if="showMemberTotal">
        <div
          :class="['course', { course_selected: showXFactor }]"
          @click="selectXFactor()">
          <div class="course_checkbox">
            <use-svg
              v-show="showXFactor"
              svg="bb-selected-icon" />
          </div>
          <span>{{ $t('showPerMember') }}</span>
        </div>

        <div class="column">
          <div @click="toggleColumn(0)">
            <strong>{{ $t('currentYear') }}</strong>
            <span style="float: right">
              <use-svg
                svg="arrow-right"
                :class="{ 'svg--180-negative': showColumn[0] }" />
            </span>
          </div>
          <div v-show="showColumn[0]">
            <div class="radio__row">
              <div class="period_grid">
                <span class="column_section">{{ $t('year') }}</span>
                <div class="period_input">
                  <custom-select
                    @optionSelected="e => selectYear(0, e)"
                    :selected="
                      memberTotalYearOptions
                        .map(y => y.value)
                        .indexOf(selectedYears[0])
                    "
                    :options="memberTotalYearOptions.slice(0, -1)">
                  </custom-select>
                </div>
              </div>
              <div class="period_grid">
                <span class="column_section">{{ $t('month') }}</span>
                <div class="period_input period_month">
                  <custom-select
                    @optionSelected="e => selectCurrentYearMonth(e)"
                    :selected="currentYearMonth - 1"
                    :options="
                      periodMonths.slice(1).map(m => {
                        return {
                          value: periodMonths.indexOf(m),
                          displayName: m,
                        };
                      })
                    ">
                  </custom-select>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="column">
          <div @click="toggleColumn(1)">
            <strong>{{ $t('comparisonYear') }}</strong>
            <span style="float: right">
              <use-svg
                svg="arrow-right"
                :class="{ 'svg--180-negative': showColumn[1] }" />
            </span>
          </div>
          <div v-show="showColumn[1]">
            <div class="radio__row">
              <div class="period_grid">
                <span class="column_section">{{ $t('year') }}</span>
                <div class="period_input">
                  <custom-select
                    @optionSelected="e => selectYear(1, e)"
                    :selected="
                      memberTotalYearOptions
                        .slice(1)
                        .map(y => y.value)
                        .indexOf(selectedYears[1])
                    "
                    :options="memberTotalYearOptions.slice(1)">
                  </custom-select>
                </div>
              </div>
              <div class="period_grid">
                <span class="column_section">{{ $t('month') }}</span>
                <div class="period_input period_month">
                  <custom-select
                    @optionSelected="e => selectCompareYearMonth(e)"
                    :selected="compareYearMonth - 1"
                    :options="
                      periodMonths.slice(1).map(m => {
                        return {
                          value: periodMonths.indexOf(m),
                          displayName: m,
                        };
                      })
                    ">
                  </custom-select>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </template>
      <div
        class="drilldown"
        v-if="showDisplaySettings">
        {{ $t('show') }}
        <info-icon description="beskrivning av 'Visa'">
          <template #icon>
            <use-svg svg="bb-info-icon" />
          </template>
        </info-icon>
        <div class="drillDownChoice">
          <div
            :class="['choice', { selected: drillDownRadioValue == d[0] }]"
            v-for="d in drillDownRadioValues"
            :key="d[0]"
            @click="drillDownRadioValue = d[0]">
            {{ d[1] }}
            <div v-if="drillDownRadioValue == d[0]">
              <use-svg svg="bb-selected-icon" />
            </div>
          </div>
        </div>
      </div>
      <template v-if="!showMemberTotal">
        <div class="columns">
          <br />
          <div
            class="column"
            v-if="showCourses">
            <span class="column_section">{{ $t('include') }}</span>
            <div
              :class="[
                'course',
                { course_selected: selectedCourses.includes(course.id) },
              ]"
              v-for="course in courses"
              :key="course.id"
              @click="selectCourse(course.id)">
              <div class="course_checkbox">
                <use-svg
                  v-show="selectedCourses.includes(course.id)"
                  svg="bb-selected-icon" />
              </div>
              <span>{{ course.name }}</span>
            </div>
            <div
              :class="['course', { course_selected: showXFactor }]"
              @click="selectXFactor">
              <div class="course_checkbox">
                <use-svg
                  v-show="getXFactor"
                  svg="bb-selected-icon" />
              </div>
              <span>{{ $t('xFactor') }}</span>
            </div>
          </div>
          <hr />
          <div class="column">
            <div @click="toggleColumn(0)">
              <strong>{{ $t('currentYear') }}</strong>
              <span style="float: right">
                <use-svg
                  svg="arrow-right"
                  :class="{ 'svg--180-negative': showColumn[0] }" />
              </span>
            </div>
            <div v-show="showColumn[0]">
              <div class="radio__row">
                <div class="period_grid">
                  <span class="column_section">{{ $t('year') }}</span>
                  <div class="period_input">
                    <custom-select
                      @optionSelected="e => selectYear(0, e)"
                      :selected="
                        yearOptions.map(y => y.value).indexOf(selectedYears[0])
                      "
                      :options="yearOptions.slice(0, -1)">
                    </custom-select>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div
            class="column"
            v-for="i in comparisonYearCount"
            :key="i">
            <div @click="toggleColumn(i)">
              <strong>
                {{ $t('comparisonYear') }}
                <template v-if="comparisonYearCount > 1">{{ i }}</template>
              </strong>
              <span style="float: right">
                <use-svg
                  svg="arrow-right"
                  :class="{ 'svg--180-negative': showColumn[i] }" />
              </span>
            </div>
            <div v-show="showColumn[i]">
              <div class="radio__row">
                <div class="period_grid">
                  <span class="column_section">{{ $t('year') }}</span>
                  <div class="period_input">
                    <custom-select
                      @optionSelected="e => selectYear(i, e)"
                      :selected="
                        yearOptions
                          .slice(1)
                          .map(y => y.value)
                          .indexOf(selectedYears[i])
                      "
                      :options="yearOptions.slice(1)">
                    </custom-select>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <template v-if="showPeriod">
            <div class="column">
              <strong> Period (appliceras på alla valda år) </strong>
              <div class="period_input">
                <custom-select
                  @optionSelected="e => (columnPeriod = e.value)"
                  :selected="periods.map(y => y.value).indexOf(columnPeriod)"
                  :options="periods">
                </custom-select>
              </div>
              <div
                class="period_grid2"
                v-if="columnPeriod === 'not_default'">
                <span class="column_section">Välj start/slutmånad</span>
                <div class="selectMonth">
                  <div
                    v-for="i in 12"
                    :key="'period' + i"
                    :class="{
                      selected: startMonth == i || endMonth == i,
                      highlighted:
                        i > startMonth &&
                        i < endMonth &&
                        !isFutureMonth(selectedYears[0], i),
                      disabled: isFutureMonth(selectedYears[0], i),
                    }"
                    @click="
                      !isFutureMonth(selectedYears[0], i) && selectMonth(i)
                    ">
                    {{ periodMonths[i] }}
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </template>
        </div>
      </template>
      <div class="modal__content--actions">
        <button @click="changeFilter">{{ $t('showTemporarily') }}</button>
        <info-icon :description="$t('showTemporarilyInfo')">
          <template #icon>
            <use-svg svg="bb-info-icon" />
          </template>
        </info-icon>
        <button
          class="btn_primary"
          v-if="showSaveAndPublish"
          @click="saveFilter"
          :disabled="!canSave">
          {{ $t('saveAndPublish') }}
        </button>
        <br />
        <button @click="toggleModal">{{ $t('cancel') }}</button>
      </div>
    </div>
    <base-button
      backgroundColor="white"
      class="button-hover"
      :text="$t('settings')"
      @click="showModal = !showModal">
      <use-svg
        svg="bb-edit-icon"
        color="white"
        stroke="black" />
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/components/form-elements/base-button';
import CustomSelect from '@/components/form-elements/custom-select-bb';
import UseSvg from '@/components/use-svg';
import InfoIcon from '@/components/businessboard/tooltip-icon';
import LocalizationMixin from '../../../mixins/localization-mixin.vue';

export default {
  name: 'Modal',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    CustomSelect,
    UseSvg,
    InfoIcon,
  },
  props: {
    financialYears: {
      type: Array,
      default: () => [],
    },
    storedFilter: {
      type: String,
    },
    titleText: {
      type: String,
      default: function () {
        return this.$t('diagram');
      },
    },
    drillDownRadioValues: {
      type: Array,
      default: function () {
        return [
          [0, this.$t('total')],
          [1, this.$t('distributionGuestSlashMember')],
        ];
      },
    },
    comparisonYearCount: {
      type: Number,
      default: 4,
    },
    showDisplaySettings: {
      type: Boolean,
      default: false,
    },
    showCourses: {
      type: Boolean,
      default: false,
    },
    showPeriod: {
      type: Boolean,
      default: false,
    },
    showMemberTotal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      showColumn: [true, false, false, false, false, false],
      drillDownRadioValue: 1,
      selectedCourses: [],
      showXFactor: true,
      selectedYears: [],
      startMonth: 0,
      endMonth: 0,
      currentYearMonth: 0,
      compareYearMonth: 0,
      periodCurrentMonthIndex: new Date().getMonth() + 1,
      periodCurrentYear: new Date().getFullYear(),
      columnPeriod: 'default',
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    getXFactor() {
      return this.showXFactor;
    },
    periods() {
      return [
        { value: 'default', displayName: this.$t('accumulated') },
        { value: 'not_default', displayName: this.$t('notFiscalYear') },
      ];
    },
    filter() {
      let filter = {
        show: this.drillDownRadioValue,
        courses: this.courses.filter(c => this.selectedCourses.includes(c.id)),
        years: this.selectedYears,
        showXFactor: this.showXFactor,
        currentYearMonth: this.currentYearMonth,
        compareYearMonth: this.compareYearMonth,
      };
      if (this.columnPeriod === 'not_default') {
        /* broken fiscal year stuff
        if (this.dashboard.financialYearStartMonth > 0) {
          filter.periodStartMonth =
            ((this.startMonth + this.dashboard.financialYearStartMonth - 2) %
              12) +
            1;
          filter.periodEndMonth =
            ((this.endMonth + this.dashboard.financialYearStartMonth - 2) %
              12) +
            1;
        } else {
          */
        filter.periodStartMonth = this.startMonth ?? 0;
        filter.periodEndMonth = this.endMonth ?? 12;
        // }
      }

      return filter;
    },
    canSave() {
      return true;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    yearOptions() {
      let currentYear = new Date().getFullYear();
      return [
        {
          displayName: this.$t('currentYear2') + ' (' + currentYear + ')',
          value: currentYear,
        },
        {
          displayName: this.$t('year') + ' 1 (' + (currentYear - 1) + ')',
          value: currentYear - 1,
        },
        {
          displayName: this.$t('year') + ' 2 (' + (currentYear - 2) + ')',
          value: currentYear - 2,
        },
        {
          displayName: this.$t('year') + ' 3 (' + (currentYear - 3) + ')',
          value: currentYear - 3,
        },
        {
          displayName: this.$t('year') + ' 4 (' + (currentYear - 4) + ')',
          value: currentYear - 4,
        },
        { displayName: this.$t('nothing'), value: 0 },
      ];
    },
    periodMonths() {
      return [
        '',
        this.$t('january'),
        this.$t('february'),
        this.$t('march'),
        this.$t('april'),
        this.$t('may'),
        this.$t('june'),
        this.$t('july'),
        this.$t('august'),
        this.$t('september'),
        this.$t('october'),
        this.$t('november'),
        this.$t('december'),
      ];
    },
    memberTotalYearOptions() {
      return this.yearOptions.filter(y => y.value >= 2022 || y.value === 0);
    },
    showSaveAndPublish() {
      return !(
        this.user.roles.BusinessBoard &&
        this.user.roles.BusinessBoard.some(
          e => e.value === 'BusinessBoardBoardMember'
        )
      );
    },
  },
  mounted() {
    this.courses =
      this.$store.state.businessboardReportingTool.templates[0].availableCourses;
    if (this.storedFilter === undefined) return;
    let settings = JSON.parse(this.storedFilter);
    if (Object.keys(settings).length > 0) {
      this.drillDownRadioValue = settings.Show;
      this.selectedCourses = settings.Courses.map(c => c.Id);
      this.selectedYears = settings.Years;
      this.showXFactor = settings.ShowXFactor;
      this.currentYearMonth = settings.CurrentYearMonth;
      this.compareYearMonth = settings.CompareYearMonth;
    } else {
      this.selectedCourses = this.courses.map(c => c.id);
      let year = new Date().getFullYear();
      this.selectedYears = [year, year - 1, year - 2, year - 3, year - 4].slice(
        0,
        this.comparisonYearCount + 1
      );
      this.currentYearMonth = new Date().getMonth();
      this.compareYearMonth = new Date().getMonth();
    }
  },
  watch: {
    storedFilter: function (newVal, oldVal) {},
  },
  methods: {
    isFutureMonth(year, month) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      return (
        year > currentYear || (year === currentYear && month > currentMonth)
      );
    },
    selectCourse(course) {
      const index = this.selectedCourses.indexOf(course);
      if (index > -1) {
        this.selectedCourses.splice(index, 1);
      } else {
        this.selectedCourses.push(course);
      }
    },
    selectXFactor() {
      this.showXFactor = !this.showXFactor;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    selectYear(i, e) {
      this.selectedYears[i] = e.value;
    },
    changeFilter() {
      this.toggleModal();
      this.$emit('changeFilter', this.filter);
    },
    saveFilter() {
      this.toggleModal();
      this.$emit('saveFilter', this.filter);
    },
    toggleColumn(i) {
      this.$set(this.showColumn, i, !this.showColumn[i]);
    },
    selectMonth(i) {
      // If the selected month is in the future, do nothing
      if (this.isFutureMonth(this.selectedYears[0], i));
      else if (this.startMonth === i && this.endMonth === 0) {
        // If the selected month is the same as the current start month and the end month is 0, reset the start month to 0
        this.startMonth = 0;
      }
      // If both start and end months are set, reset them to start a new range with the selected month
      else if (this.startMonth > 0 && this.endMonth > 0) {
        this.startMonth = i;
        this.endMonth = 0;
      } else {
        // If the start month is not set, set both start and end months to the selected month
        if (this.startMonth === 0) {
          this.startMonth = i;
          this.endMonth = i;
        }
        // If the selected month is before the start month, set both start and end months to the selected month
        else if (i < this.startMonth) {
          this.startMonth = i;
          this.endMonth = i;
        }
        // Otherwise, set the end month to the selected month to complete the range
        else {
          this.endMonth = i;
        }
      }
    },
    selectCompareYearMonth(e) {
      this.compareYearMonth = e.value;
    },
    selectCurrentYearMonth(e) {
      this.currentYearMonth = e.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.period_input {
  padding: 0 0.5rem 0 0.5rem;
  input,
  select {
    background-color: #f9f9f9;
    border-radius: 2px;
    padding: 0.3rem;
    border: 1px solid #d8d8d8;
  }
}
.period_month::v-deep {
  .select-list__wrapper {
    position: static;
  }
  .select {
    position: static;
  }
  .select__list {
    top: 5rem;
    left: 1rem;
    z-index: 20;
    width: 23rem;
  }
}
.radio__row {
  padding: 0.5rem 0;
  text-align: left;
}
.columns {
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: calc(100vh - 21.5rem);
}
.columns:hover {
  overflow-y: scroll;
  max-height: calc(100vh - 21.5rem);
}
.column {
  .column_section {
    display: inline-block;
    color: #555f61;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }
  strong {
    line-height: 40px;
  }
  span {
    margin: 0.5rem;
  }
}
input[type='radio'] {
  margin: 1rem;
}
.course {
  cursor: pointer;
  display: flex;
  padding: 8px;
  border-radius: 3px;
  span {
    line-height: 20px;
    margin: 0 0.5rem;
  }
  &_selected {
    background-color: #eaf6f7;
  }
  &_checkbox {
    width: 20px;
    height: 20px;
    display: inline-flex;
    border: 1px solid #131516;
    border-radius: 2px;
    svg {
      margin: auto;
    }
  }
}
.chart_settings_modal {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 8px;
  .header {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: auto 3rem;
    span {
      color: #0e3746;
    }
  }
  button {
    padding: 0.5rem;
    border: 1px solid #0e3746;
    border-radius: 3px;
    background: white;
    margin: 0.5rem;
  }
  .btn_primary {
    background-color: #0e3746;
    color: white;
    float: right;
  }
  .btn_reticent {
    border: 0;
  }
}
.drillDownChoice {
  display: grid;
  grid-auto-flow: column;
  background: #eaf6f7;
  border-radius: 3px;
  font-weight: 400;
  padding: 4px;
  .choice {
    margin: auto 0;
    text-align: center;
    border: 1px solid #eaf6f7;
    padding: 4px;
    border-radius: 3px;
  }
  .selected {
    background: #abded1;
    border-color: #0e3746;
    font-weight: 500;
    div {
      display: inline-block;
      margin: auto;
      vertical-align: middle;
    }
  }
}
.button-hover {
  &:hover {
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}

.selectMonth {
  display: grid;
  grid-template-columns: auto auto auto;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px;
  div {
    padding: 4px;
    font-size: 14px;
    line-height: 23px;
    color: #656565;
    text-align: center;
    border-radius: 16px;
    margin: 3px;
    cursor: pointer;
  }
  .selected {
    background-color: #abded1;
    font-weight: bold;
  }
  .highlighted {
    background-color: #eaf6f7;
  }
  .disabled {
    color: #ddd;
    cursor: default;
  }
}
</style>
