<template>
  <div class="loader"></div>
</template>

<script>
export default {
  name: 'PieChartLoader',
};
</script>

<style scoped>
@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.loader {
  border: 24px solid #f3f3f3;
  border-top: 24px solid #24655d;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 30px;
  animation: spin 2s linear infinite, pulsate 2s infinite;
}
</style>
