<template>
  <div v-if="!hide">
    <div
      v-if="!user.username && position === 'menu'"
      class="side-navigation">
      <tree-navigation
        v-for="(page, i) in staticPages"
        :nodes="page.subPages"
        :depth="1"
        :key="i" />
    </div>
    <div v-else>
      <template v-if="position === 'menu'">
        <div
          class="side-navigation"
          :class="{ 'side-navigation__is-loading': menuIsLoading }"
          v-if="pages.length > 0">
          <tree-navigation
            v-for="(page, i) in pages"
            :nodes="page.subPages"
            :depth="1"
            :key="i" />
        </div>
      </template>
      <div v-if="user.username && position === 'header'">
        <div
          class="side-navigation-nav--info"
          :class="{ 'side-navigation-nav--info__open': showInfo }"
          @click="toggleInfo">
          <div class="side-navigation-nav--user">
            <span
              class="side-navigation-nav--user__name"
              v-if="user.club && user.club.name">
              <strong>{{ user.club.name }}</strong>
            </span>
            <span
              class="side-navigation-nav--user__name"
              v-if="selectedClub && selectedClub != 'Ej valt'">
              <strong>{{ selectedClub }}</strong>
            </span>
            <span> {{ user.firstName }} {{ user.lastName }} </span>
          </div>
          <div class="side-navigation-nav--user__logo">
            <img
              v-if="user.club && user.club.logoUri"
              :src="user.club.logoUri" />
          </div>
          <div
            class="side-navigation-nav--info__arrow"
            :class="{ 'side-navigation-nav--info__rotate': showInfo }"></div>
        </div>
        <div
          class="side-navigation-nav--box"
          v-if="showInfo">
          <div class="side-navigation-nav--box__content">
            <ul>
              <li
                v-if="
                  user.superUser ||
                  (user.roles.GolfOffice &&
                    user.roles.GolfOffice.some(
                      e => e.value === 'BusinessBoardAdmin'
                    ))
                ">
                <input
                  list="clubs"
                  name="golfclub"
                  id="golfclub-select"
                  class="side-navigation--golfclub-select"
                  @click="
                    () => {
                      selectedClub = '';
                    }
                  "
                  @change="selectGolfclub"
                  v-model="selectedClub" />
                <datalist id="clubs">
                  <option
                    v-for="club in clubs"
                    :key="club.value">
                    {{ `${club.displayName}` }}
                  </option>
                </datalist>
              </li>
              <li
                v-for="(info, index) in userInfo[0].subPages"
                :key="index"
                @click="toggleInfo">
                <router-link
:to="info.routeUrl"><strong>{{ $t(info.name) }}</strong></router-link>
              </li>
              <li @click="logOut">
                <router-link :to="hasEconomicLicense ? '/businessboard' : '/'">
                  <strong class="first-letter">{{ $t('logOut') }}</strong>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutside from '@/mixins/click-outside';
import TreeNavigation from './tree-navigation';
import { BusinessBoardVersion } from '@/core/consts';
// import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'SideNavigation',
  components: {
    TreeNavigation,
  },
  mixins: [clickOutside],
  props: {
    position: {
      type: String,
      default: 'menu',
    },
    hide: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Array,
      default: function () {
        return [
          {
            name: 'Mina sidor',
            subPages: [
              {
                name: 'settings',
                routeUrl: '/user-settings/',
              },
            ],
          },
        ];
      },
    },
    staticPages: {
      type: Array,
      default: () => [],
    },
    // staticPages: {
    //   type: Array,
    //   default: () => [
    //     {
    //       name: 'GolfOffice',
    //       routeUrl: '/',
    //       subPages: [
    //         {
    //           name: 'OnCourse',
    //           routeUrl: '/oncourse/'
    //         },
    //         {
    //           name: 'OnScreen',
    //           routeUrl: '/onscreen/'
    //         },
    //         {
    //           name: 'OnTag',
    //           routeUrl: '/ontag/'
    //         },
    //         {
    //           name: 'OnService',
    //           routeUrl: '/onservice',
    //           subPages: [
    //             {
    //               name: 'OnService Dashboard',
    //               routeUrl: '/onservice-dashboard'
    //             },
    //             {
    //               name: 'Terminaler',
    //               routeUrl: '/terminals',
    //               subPages: [
    //                 {
    //                   name: 'Terminal 1',
    //                   routeUrl: '/3452345-234-234-234'
    //                 }
    //               ]
    //             },
    //             {
    //               name: 'Butik',
    //               routeUrl: '/store',
    //               subPages: [
    //                 {
    //                   name: 'Artiklar',
    //                   routeUrl: '/articles'
    //                 },
    //                 {
    //                   name: 'Transaktioner',
    //                   routeUrl: '/transactions'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]
    // }
  },
  data() {
    return {
      showInfo: false,
      selectedClub: '',
    };
  },
  computed: {
    menuIsLoading() {
      return this.$store.getters['menu/isLoading'];
    },
    pages() {
      return this.$store.getters['menu/menu'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    clubs() {
      return this.$store.getters['clubs/clubsSelect'];
    },
    businessBoardSettings() {
      return this.$store.getters['businessboard/settings'];
    },
    hasEconomicLicense() {
      return (
        this.businessBoardSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicBasic ||
        this.businessBoardSettings.businessBoardVersion ===
          BusinessBoardVersion.EconomicPlus
      );
    },
  },
  methods: {
    closeMobileMenu() {
      this.$emit('close', false);
    },
    toggleInfo() {
      this.showInfo = !this.showInfo;
    },
    getLogo(name) {
      let path = '';
      try {
        path = require(`../../assets/images/${name.toLowerCase()}-no-text.svg`);
      } catch (e) {
        console.error(e);
      }
      return path;
    },
    logOut() {
      this.$store.commit('user/user', {});
      this.$cookies.remove('token');
      this.$cookies.remove('refresh_token');
      this.selectedClub = '';
    },
    selectGolfclub() {
      let club = this.clubs.find(
        c => c.displayName.trim() === this.selectedClub.trim()
      );
      this.$cookies.remove('language');
      if (club) {
        this.$store.dispatch('users/setClub', { id: club.value }).then(() => {
          this.setLanguage();
          this.$store.dispatch('businessboard/fetchSettings');
        });
      }
    },
    setLanguage() {
      this.$store.dispatch('businessboard/fetchLanguage').then(() => {
        if (this.language === '0') {
          this.$i18n.locale = 'sv';
        } else if (this.language === '1') {
          this.$i18n.locale = 'en';
        }
      });
    },
  },
  created() {
    this.$on('click-outside', function () {
      if (this.showInfo) {
        this.toggleInfo();
      }
    });
  },

  watch: {
    selectedClub(newClub) {
      localStorage.selectedClub = newClub;
    },
    clubs(c) {
      if (localStorage.selectedClub) {
        this.selectedClub = localStorage.selectedClub;
        this.selectGolfclub();
      }
    },
  },

  beforeDestroy() {
    localStorage.clear();
  },
};
</script>

<style lang="scss" scoped>
.side-navigation {
  background-color: color(white);
  width: 17.625rem;
  height: 100vh;
  padding: 0.7rem;
  // border-right: 0.0625rem solid color(gray-dark);
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.5);
  // position: relative;
  // top: -5.1875rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // scrollbar-width: none; /* Firefox */
  // -ms-overflow-style: none;  /* Internet Explorer 10+ */
  overflow-y: scroll;

  @include media('small') {
    display: block;
    padding-top: 4.475rem;
  }

  @include media('to-small') {
    padding-top: 0.7rem;
  }
  &__is-loading {
    opacity: 0.2;
  }
  a {
    &:visited {
      color: color(black);
    }
  }
  .svg {
    vertical-align: middle;
  }
  .side-navigation--user {
    display: block;
    @include media('small') {
      display: none;
    }
  }
  .side-navigation__link {
    padding: 0.5rem 0 0.4375rem 0;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      span {
        padding-left: 1rem;
      }
    }
    &:last-child {
      border-bottom: 0.0625rem solid color(gray-light);
    }
  }
  .side-navigation--golfclub-select {
    padding: 0.5rem;
    width: 100%;
  }
  .side-navigation__panel-one {
    padding-left: 0.625rem;
    .expand-collapse {
      border-bottom: none;
    }
  }
  .side-navigation__level-one {
    // font-weight: bold;
    margin: 0;
    padding: 0.5rem 0 0.4375rem 0;
    border-bottom: 0.0625rem solid color(gray-light);
  }
  .side-navigation--list {
    color: color(menu-white);
    list-style-type: none;
    margin: 0;
    padding: 0 0.3rem;
    li {
      padding: 0.5rem 0;
      font-size: 0.9375rem;
      position: relative;
      i {
        border: solid white;
        border-width: 0 0.1875rem 0.1875rem 0;
        display: inline-block;
        padding: 0.1875rem;
        margin-left: auto;
        transform: rotate(45deg);
        position: absolute;
        right: 0.125rem;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  // -webkit-border-radius: 10px;
  // border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
//   .side-navigation::-webkit-scrollbar { /* WebKit */
//     width: 0;
//     height: 0;
// }

.side-navigation-nav--info {
  cursor: pointer;
  display: flex;
  padding: 0 0.625rem;

  &.side-navigation-nav--info__open {
    box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.5);
  }

  .side-navigation-nav--user {
    margin-left: auto;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .side-navigation-nav--info__name {
    @include body-bold;
    text-transform: uppercase;
  }
  .side-navigation-nav--user__logo {
    // margin-left: auto;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 42px;
    width: 42px;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }
  .side-navigation-nav--info__arrow {
    border: 0.0625rem solid color(black);
    transition: transform 0.2s ease;
    border-width: 0 0 0.1252rem 0.1252rem;
    width: 0.5rem;
    height: 0.5rem;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    right: 0;
    top: 0.9375rem;

    &.side-navigation-nav--info__rotate {
      transform: rotate(135deg);
      top: 1.25rem;
    }
  }
}
.side-navigation-nav--box {
  position: absolute;
  right: 0;
  background-color: color(white);
  padding: 0 0.625rem 0.625rem 0.625rem;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.5);
  width: 100%;

  .side-navigation-nav--box__content {
    ul {
      padding-left: 0;
      list-style-type: none;
      margin: 0;

      li {
        padding: 0.5rem 0 0.4375rem 0;
        border-bottom: 0.0625rem solid color(gray-light);
      }
    }
  }
}
.navigation-logo {
  height: 2.5rem;
}
</style>
