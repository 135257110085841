<template>
  <div>
    <div class="action-buttons-div">
      <slot name="open" />
    </div>
    <div
      v-if="isOpen"
      class="modal--wrapper"
      :style="'width:' + width">
      <div class="modal--header">
        <p class="breadcrumb">{{ breadcrumb }}</p>
      </div>
      <div class="modal--content">
        <div class="modal--body">
          <h3 class="modal--title">{{ title }}</h3>
          <slot name="content" />
          <div style="padding: 1rem 0">
            <slot name="close" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'balance-modal-wrapper',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    breadcrumb: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '50rem',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.modal--wrapper {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;
  border-radius: 8px;
  max-height: calc(100% - 5rem);
  overflow-y: scroll;
}

.modal--content {
  width: 35rem;
  margin-left: 2rem;
}
.modal--header {
  background-color: #fff;
  margin-left: 2rem;
}
.modal--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #131516;
}
.breadcrumb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7c80;
}
</style>
