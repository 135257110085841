<template>
  <div class="ontag">
    <div class="standard-order">
      <div
        class="cancel"
        @click="cancelOrder">
        &lt; {{ $t('backToStartPage') }}
      </div>
      <span
        v-if="isLoading"
        class="element--is-loading"></span>
      <div class="standard-order__main-container">
        <div class="standard-order__left-section">
          <div class="standard-order__img-container">
            <!-- <img :src='images[0].src' alt=""/> -->
            <img
              v-if="scorecardType === 0"
              src="../../assets/images/sc-3&4p-2023.jpg"
              alt="" />
            <img
              v-else-if="scorecardType === 1"
              src="../../assets/images/sc-3p-2023.jpg"
              alt="" />
            <img
              v-else-if="scorecardType === 2"
              src="../../assets/images/sc-4p-2023.jpg"
              alt="" />
          </div>
          <!-- <div class="standard-order__img-select-container">
            <div v-for="(img, index) in images" :index="index" :key="index">
              <div v-if="img.src !== images[0].src">
                <div class="standard-order__img-select">
                  <img :src="img.src" @click="changeImgIndex(img)" alt="">
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="standard-order__right-section">
          <div class="standard-order__step-container">
            <div class="standard-order__step">
              <strong>1</strong> {{ $t('yourOrder') }}
            </div>
            <div class="standard-order__step">
              <strong>2</strong> {{ $t('yourInfo') }}
            </div>
            <div class="standard-order__step">
              <strong>3</strong> {{ $t('confirmation') }}
            </div>
          </div>
          <div class="standard-order__order-detail-container">
            <h2 class="standard-order--bold">{{ $t('standardScorecard') }}</h2>
            <div class="standard-order__choice-container">
              <div class="standard-order__choice">
                <h4 class="standard-order--bold">{{ $t('format') }}</h4>
              </div>
              <button
                :disabled="isLoading"
                @click="setScorecardType(2)"
                :style="[
                  scorecardType === 2
                    ? { backgroundColor: '#304834', color: 'white' }
                    : {},
                ]"
                class="standard-order__choice">
                {{ $t('fourParted') }}<br />
                110 X 362 mm
              </button>
              <button
                :disabled="isLoading"
                @click="setScorecardType(1)"
                :style="[
                  scorecardType === 1
                    ? { backgroundColor: '#304834', color: 'white' }
                    : {},
                ]"
                class="standard-order__choice">
                {{ $t('threeParted') }}<br />
                110 X 273 mm
              </button>
            </div>
            <div
              :class="{
                'standard-order__quantity-container-disabled':
                  scorecardType == undefined,
              }"
              class="standard-order__quantity-container">
              <div class="standard-order__quantity">
                <h4 class="standard-order--bold">
                  {{ $t('amount') }} {{ $t('boxes') }}
                </h4>
                1 000 {{ $t('piecesSlashBox') }}<br />
                {{ $t('minAmount') }}: 1 {{ $t('box') }}
              </div>
              <div class="standard-order__quantity-button-container">
                <div
                  :class="{
                    'standard-order__quantity-container-disabled':
                      quantity <= 1,
                  }"
                  class="standard-order__quantity-button"
                  id="decrease"
                  @click="decreaseValue"
                  value="Decrease Value">
                  -
                </div>
                <input
                  min="1"
                  type="number"
                  id="number"
                  v-model="quantity" />
                <div
                  @click="increaseValue"
                  class="standard-order__quantity-button"
                  id="increase"
                  value="Increase Value">
                  +
                </div>
              </div>
            </div>
            <div class="standard-order__choice-container">
              <div class="standard-order__choice">
                <h4 class="standard-order--bold">{{ $t('delivery') }}</h4>
              </div>
              <button
                @click="deliveryWeek = 0"
                :style="[
                  deliveryWeek === 0
                    ? { backgroundColor: '#304834', color: 'white' }
                    : {},
                ]"
                class="standard-order__choice">
                {{ $t('asap') }}
              </button>
              <button
                @click="toggleModal"
                :style="[
                  deliveryWeek !== 0 && deliveryWeek !== undefined
                    ? { backgroundColor: '#304834', color: 'white' }
                    : {},
                ]"
                class="standard-order__choice">
                <span
                  :style="[
                    deliveryWeek !== 0 && deliveryWeek !== undefined
                      ? { backgroundColor: '#304834', color: 'white' }
                      : {},
                  ]">{{ $t('chooseWeek') }}</span>
                <img
                  v-if="deliveryWeek === 0 || deliveryWeek === undefined"
                  src="../../assets/images/calendar.svg" />
                <img
                  v-else
                  src="../../assets/images/calendar-white.svg" />
              </button>
            </div>
            <div
              v-if="scorecardType !== 0"
              class="standard-order__your-order-container">
              <h3>{{ $t('yourOrder') }}</h3>
              <table>
                <tr v-if="scorecardType === 1">
                  <td>
                    {{ $t('threeParted') }} <br />
                    110 x 273 mm
                  </td>
                  <td>
                    {{ (quantity * 1000).toLocaleString() }} {{ $t('piece')
                    }}<br />
                    /
                    <strong
v-if="quantity > 1">{{ quantity }} {{ $t('boxes') }}</strong>
                    <strong
v-else-if="quantity === 1">{{ quantity }} {{ $t('box') }}</strong>
                  </td>
                  <td>
                    <span
                      class="standard-order__edit-unitCost"
                      v-if="user.superUser && editUnitCost === true">
                      <span @click="editUnitCost = false">x</span>
                      <input
                        @keyup="calculateCostWithoutShipping"
                        v-model="unitPrice"
                        type="number" />
                      {{ $t('crownsSlashAmount') }}
                    </span>
                    <span
                      v-else
                      @click="editUnitCost = true">{{ unitPrice }} {{ $t('crownsSlashAmount') }}</span>
                  </td>
                  <td>
                    {{ costWithoutShipping.toLocaleString() }} {{ $t('sek') }}
                  </td>
                </tr>
                <tr v-else-if="scorecardType === 2">
                  <td>
                    {{ $t('fourParted') }} <br />
                    110 x 362 mm
                  </td>
                  <td>
                    {{ (quantity * 1000).toLocaleString() }} {{ $t('piece')
                    }}<br />
                    /
                    <strong
v-if="quantity > 1">{{ quantity }} {{ $t('boxes') }}</strong>
                    <strong
v-else-if="quantity === 1">{{ quantity }} {{ $t('box') }}</strong>
                  </td>
                  <td>
                    <span
                      class="standard-order__edit-unitCost"
                      v-if="user.superUser && editUnitCost === true">
                      <span @click="editUnitCost = false">x</span>
                      <input
                        @keyup="calculateTotalCost"
                        v-model="unitPrice"
                        type="text" />
                      {{ $t('crownsSlashAmount') }}
                    </span>
                    <span
                      v-else
                      @click="editUnitCost = true">{{ unitPrice }} {{ $t('crownsSlashAmount') }}</span>
                  </td>
                  <td>
                    {{ costWithoutShipping.toLocaleString() }} {{ $t('sek') }}
                  </td>
                </tr>
              </table>
              <div v-if="deliveryWeek === 0">
                <p class="standard-order__delivery-info-text">
                  {{ $t('sentBy') }} 3 {{ $t('weekdays') }}
                </p>
                <span class="font-grey">{{ $t('signingInformation') }} </span>
              </div>
              <div v-else-if="deliveryWeek > 0">
                <p class="standard-order__delivery-info-text">
                  {{ $t('delivery') }} {{ $t('week') }} {{ deliveryWeek }}
                </p>
                <span class="font-grey">{{ $t('signingInformation') }} </span>
              </div>
            </div>
            <div
              :style="[
                scorecardType === 0
                  ? { marginTop: '30px' }
                  : { marginTop: '0px' },
              ]"
              class="standard-order__cost-container">
              <div>
                <span
v-if="postalCodeValid">{{ $t('freightCostToZip') }}
                  {{
                    scorecardorderaddresses.deliveryAddress.postalCode
                      .replace(/\W/g, '')
                      .replace(/(...)/, '$1 ')
                  }}</span>
                <span v-else>{{ $t('shippingNextStep') }}</span>
                <a :class="[postalCodeValid ? 'tooltip' : 'tooltip--notext']">
                  <img src="../../assets/images/info.svg" />
                  <span
                    v-if="postalCodeValid"
                    class="tooltiptext">
                    {{ $t('changeShippingNextStep') }}
                  </span>
                </a>
              </div>
              <div v-if="postalCodeValid">
                <span
                  class="standard-order__edit-shippingCost"
                  v-if="user.superUser && editShippingCost === true">
                  <span @click="editShippingCost = false">x</span>
                  <input
                    @keyup="calculateTotalCost"
                    v-model="customerShippingCost"
                    type="number" />
                  {{ $t('sek') }}
                </span>
                <span
                  v-else
                  @click="editShippingCost = true">{{ customerShippingCost.toLocaleString() }}
                  {{ $t('sek') }}</span>
              </div>
            </div>
            <div
              v-if="scorecardType !== 0"
              class="standard-order__cost-container">
              <div>
                <h2>{{ $t('sum') }}</h2>
                <span class="standard-order__cost-container-sum-text">{{
                  $t('vatExcluded')
                }}</span>
              </div>
              <div v-if="postalCodeValid">
                <h2>{{ this.totalCost.toLocaleString() }} {{ $t('sek') }}</h2>
              </div>
              <div v-else>
                <h2>
                  {{ costWithoutShipping.toLocaleString() }} {{ $t('sek') }}
                </h2>
              </div>
            </div>
            <div class="standard-order__flow-button-container">
              <div class="standard-order__cancel-button">
                <button @click="cancelOrder">
                  {{ $t('cancel') }}
                </button>
              </div>
              <router-link
                :disabled="scorecardType === 0 || deliveryWeek === undefined"
                :to="{ path: '/ontag/orderscorecard/standard-step-2' }"
                class="standard-order__continue-button">
                <button
                  @click="setSelectedState"
                  :disabled="
                    scorecardType === 0 ||
                    deliveryWeek === undefined ||
                    quantity < 1
                  ">
                  {{ $t('continue') }}
                </button>
              </router-link>
            </div>
            <div class="standard-order__text-block">
              <p>{{ $t('scorecardInformation') }}</p>
            </div>
            <div class="standard-order__collapse-container">
              <collapsible
                class="standard-order__collapse"
                :isOpen="false">
                <div
                  @click="toggleCollapse('details')"
                  slot="trigger"
                  class="standard-order__collapse-trigger">
                  <strong>{{ $t('details') }} </strong>
                  <span
                    class="standard-order__expand"
                    v-if="!collapsed.includes('details')">+</span>
                  <span
                    class="standard-order__expand"
                    v-else>-</span>
                </div>
                <div class="standard-order__collapse-content">
                  <table>
                    <tr>
                      <td class="font-grey">{{ $t('paperCaps') }}</td>
                      <td>{{ $t('waterResistant') }}</td>
                    </tr>
                    <tr>
                      <td class="font-grey">{{ $t('insideCaps') }}</td>
                      <td>
                        {{ $t('mat') }}, {{ $t('white') }} {{ $t('surface') }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-grey">{{ $t('outsideCaps') }}</td>
                      <td>{{ $t('highBlank') }}, {{ $t('fiveColorPrint') }}</td>
                    </tr>
                    <tr>
                      <td class="font-grey">{{ $t('printCaps') }}</td>
                      <td>{{ $t('flexPrint') }}</td>
                    </tr>
                  </table>
                </div>
              </collapsible>
              <collapsible
                class="standard-order__collapse"
                :isOpen="false">
                <div
                  @click="toggleCollapse('terms')"
                  slot="trigger"
                  class="standard-order__collapse-trigger">
                  <strong>{{ $t('terms') }}</strong>
                  <span
                    class="standard-order__expand"
                    v-if="!collapsed.includes('terms')">+</span>
                  <span
                    class="standard-order__expand"
                    v-else>-</span>
                </div>
                <div class="standard-order__collapse-content">
                  <span class="standard-order__terms-row font-grey">
                    <div class="standard-order__pdf-svg-container">
                      <img src="../../assets/images/pdf.svg" />
                    </div>
                    <a
                      :href="terms.termsUrl"
                      target="_blank">
                      {{ $t('ourTerms') }}&nbsp;
                    </a>
                    ( {{ $t('opensInNewTab') }})
                  </span>
                </div>
              </collapsible>
            </div>
          </div>
        </div>
      </div>
      <div class="standard-order__more-scorecards-container">
        <h2 class="standard-order--bold">{{ $t('moreScorecard') }}</h2>

        <div class="standard-order__type">
          <router-link :to="{ path: '/ontag/orderscorecard/clubspecific' }">
            <button class="standard-order__type-img">
              <img
                v-if="scorecardorder.clubScorecardSize == 0"
                src="../../assets/images/scorecard-clubspecific-example.png"
                alt="" />
              <div
                v-else
                class="standard-order__type-img-container">
                <img
                  class="standard-order__type-img-clubspecific"
                  :src="scorecardorder.scorecardProofUrl" />
              </div>
            </button>
          </router-link>
          <div class="standard-order__type-description">
            <h3>{{ $t('scorecardOwnDesign') }}</h3>
          </div>
        </div>
      </div>
      <div class="standard-order__background-wave">
        <img
          src="../../assets/images/wave-background.svg"
          alt="" />
      </div>
      <modal-window
        :show="showModal"
        @close-modal="!showModal">
        <div class="modal__wrapper">
          <div class="modal__heading">
            <h2 class="standard-order--bold">{{ $t('chooseDeliveryWeek') }}</h2>
            <h2>
              <button @click="toggleModal">&nbsp;</button>
            </h2>
          </div>
          <div class="modal__top-buttons-container">
            <button @click="changeMonth(-1)">&lt;</button>
            <span>
              {{ monthNames[monthIndex % 12] }}
            </span>
            <button @click="changeMonth(1)">></button>
          </div>
          <div class="modal__weeks-container">
            <table>
              <thead style="border-color: white">
                <th>{{ $t('deliveryWeek') }}</th>
                <th>{{ $t('mon') }}</th>
                <th>{{ $t('tue') }}</th>
                <th>{{ $t('wed') }}</th>
                <th>{{ $t('thur') }}</th>
                <th>{{ $t('fri') }}</th>
                <th>{{ $t('sat') }}</th>
                <th>{{ $t('sun') }}</th>
              </thead>
              <tr
                v-for="tr in 5"
                :key="tr"
                :class="['modal__weeks-container_week', weekTrStyle(tr)]"
                @click="selectDeliveryWeek(tr)">
                <td
                  class="week"
                  style="font-weight: bold">
                  {{ $t('week') }}&nbsp;{{
                    getWeekNumber(firstDayInTable + (tr - 1) * 7)
                  }}
                </td>
                <td
                  v-for="td in 7"
                  :key="td"
                  :class="{
                    firstDayInMonth:
                      getDateNumber(firstDayInTable + (tr - 1) * 7 + td - 1) ===
                      1,
                  }">
                  {{ getDateNumber(firstDayInTable + (tr - 1) * 7 + td - 1) }}
                </td>
              </tr>
              <tr style="background-color: white; border: 0">
                <td style="grid-column: 1/9">
                  {{ $t('signingInformation') }}
                </td>
              </tr>
            </table>
          </div>
          <div class="modal__button-container">
            <div class="modal__cancel-button">
              <button @click="toggleModal('cancel')">
                {{ $t('cancel') }}
              </button>
            </div>
            <div class="modal__continue-button">
              <button @click="toggleModal('weekChoice')">
                {{ $t('choose') }}
              </button>
            </div>
          </div>
        </div>
      </modal-window>
    </div>
  </div>
</template>

<script>
// import UseSvg from '@/components/use-svg'
import 'vue-collapsible-component/lib/vue-collapsible.css';
import Collapsible from 'vue-collapsible-component';
import ModalWindow from '@/components/modal/modal';
import LocalizationMixin from '../../mixins/localization-mixin.vue';
export default {
  name: 'ScorecardStandardOrder',
  mixins: [LocalizationMixin],
  components: {
    // UseSvg,
    Collapsible,
    ModalWindow,
  },

  data() {
    let d = new Date();
    return {
      isLoading: true,
      collapsed: [],
      scorecardType: 0,
      quantity: 1,
      deliveryWeek: undefined,
      deliveryWeekChoice: undefined,
      postalCode: 0,
      postalCodeFull: 0,
      postalCodeValid: false,
      costWithoutShipping: 0,
      customerShippingCost: 0,
      kolli: 0,
      totalCost: undefined,
      images: [
        { src: require('../../assets/images/sc-3&4p-2022.png') },
        { src: require('../../assets/images/scorecard-preview.jpg') },
      ],
      showModal: false,
      currentDate: d.getDate(),
      currentWeek: 0,
      currentMonth: '',
      currentMonthIndex: d.getMonth(),
      currentYear: d.getFullYear(),
      editUnitCost: false,
      editShippingCost: false,
      unitPrice: 0,
      firstDayInTable: 0,
      monthNames: [
        'Januari',
        'Februari',
        'Mars',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'Augusti',
        'September',
        'Oktober',
        'November',
        'December',
      ],
      monthIndex: d.getMonth(),
    };
  },

  methods: {
    setScorecardType(type) {
      this.scorecardType = type;

      if (type === 1) {
        this.unitPrice = this.scorecardorder.priceSettings.threePartPriceOne;
      } else if (type === 2) {
        this.unitPrice = this.scorecardorder.priceSettings.fourPartPriceOne;
      }
      this.scorecardType = type;
    },
    getWeekNumber(dayOfYear) {
      return Math.ceil((dayOfYear % 365) / 7);
    },
    getDateNumber(dayOfYear) {
      let d = new Date(this.currentYear, 0, dayOfYear);
      return d.getDate();
    },
    changeMonth(delta) {
      if (this.monthIndex + delta > this.currentMonthIndex + 16) return;
      if (this.monthIndex + delta < this.currentMonthIndex) return;
      let currentdate = new Date(this.currentYear, this.monthIndex + delta, 1);
      var oneJan = new Date(this.currentYear, 0, 1);
      var numberOfDays = Math.ceil(
        (currentdate - oneJan) / (24 * 60 * 60 * 1000)
      );
      this.firstDayInTable = numberOfDays - currentdate.getDay() + 2;
      this.monthIndex += delta;
    },
    weekTrStyle(tr) {
      if (
        this.deliveryWeekChoice ===
        this.getWeekNumber(this.firstDayInTable + (tr - 1) * 7)
      ) {
        return 'selected';
      }
      let currentdate = new Date();
      var oneJan = new Date(currentdate.getFullYear(), 0, 1);
      var numberOfDays = Math.floor(
        (currentdate - oneJan) / (24 * 60 * 60 * 1000)
      );
      // note to self: the code after "||" can be deleted later
      if (
        this.firstDayInTable + (tr - 1) * 7 <= numberOfDays + 1 ||
        (this.getWeekNumber(this.firstDayInTable + (tr - 1) * 7) < 8 &&
          this.monthIndex < 2 &&
          this.currentYear === 2024)
      ) {
        return 'disabled';
      }
      return 'selectable';
    },
    selectDeliveryWeek(tr) {
      if (this.weekTrStyle(tr) === 'disabled') return;
      this.deliveryWeekChoice = this.getWeekNumber(
        this.firstDayInTable + (tr - 1) * 7
      );
    },
    changeImgIndex(clickedImg) {
      this.images.forEach(img => {
        if (img === clickedImg) {
          this.images.splice(img, 1);
          this.images.unshift(img);
        }
      });
    },
    toggleCollapse(item) {
      if (!this.collapsed.includes(item)) {
        this.collapsed.push(item);
      } else {
        this.collapsed.pop(item);
      }
    },
    increaseValue() {
      let value = parseInt(document.getElementById('number').value, 10);
      value = isNaN(value) ? 0 : value;
      if (this.scorecardType !== undefined) {
        value = value + 1;
      }
      document.getElementById('number').value = value;
      this.quantity = value;
    },
    decreaseValue() {
      let value = parseInt(document.getElementById('number').value, 10);
      value = isNaN(value) ? 0 : value;
      if (this.quantity > 1 && this.scorecardType !== undefined) {
        value = value - 1;
      }
      document.getElementById('number').value = value;
      this.quantity = value;
    },
    calculateCostWithoutShipping() {
      this.costWithoutShipping =
        parseFloat(this.unitPrice) * (this.quantity * 1000);
      this.calculateTotalCost();
    },
    calculateShippingCost() {
      let cost = 0;
      let halfPallet = false;
      let fullPallet = false;
      let numberOfFullPallets = 0;
      let count = this.quantity;
      let loopCount = this.quantity;

      if (count > 0) {
        while (count !== 0) {
          if (count % 54 === 0) {
            numberOfFullPallets = numberOfFullPallets + 1;
            loopCount = this.quantity - numberOfFullPallets * 54;
          }
          count = count - 1;
        }
      }

      if (loopCount >= 7 && loopCount <= 16) {
        halfPallet = true;
      } else if ((loopCount >= 17 && loopCount <= 54) || loopCount === 0) {
        fullPallet = true;
      }

      if (loopCount === 1 || loopCount === 2) {
        this.kolli = 1;
      } else if (loopCount === 3 || loopCount === 4) {
        this.kolli = 2;
      } else if (loopCount === 5 || loopCount === 6) {
        this.kolli = 3;
      } else if (loopCount === 0) {
        this.kolli = 0;
      }

      if (loopCount >= 0 && loopCount <= 6) {
        let kolliPrice =
          this.scorecardordershippingprice.packagePriceExVAT * this.kolli;
        if (numberOfFullPallets > 0) {
          if (this.postalCode >= 10 && this.postalCode <= 19) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip10to19PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip10to19PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 20 && this.postalCode <= 27) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip20to27PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip20to27PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 28 && this.postalCode <= 39) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip28to39PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip28to39PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 40 && this.postalCode <= 51) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip40to51PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip40to51PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 52 && this.postalCode <= 57) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip52to57PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip52to57PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 58 && this.postalCode <= 61) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip58to61PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip58to61PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (parseInt(this.postalCode) === 62) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip62PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip62PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 63 && this.postalCode <= 76) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip63to76PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip63to76PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 77 && this.postalCode <= 82) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip77to82PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip77to82PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 83 && this.postalCode <= 88) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip83to88PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip83to88PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 89 && this.postalCode <= 93) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip89to93PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip89to93PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          } else if (this.postalCode >= 94 && this.postalCode <= 98) {
            cost =
              kolliPrice +
              this.scorecardordershippingprice.zip94to98PriceFull *
                numberOfFullPallets +
              ((kolliPrice +
                this.scorecardordershippingprice.zip94to98PriceFull *
                  numberOfFullPallets) /
                100) *
                this.scorecardordershippingprice.freight;
          }
        } else {
          cost =
            kolliPrice +
            (kolliPrice / 100) * this.scorecardordershippingprice.freight;
        }
      } else if (this.postalCode >= 10 && this.postalCode <= 19 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip10to19PriceHalf +
          this.scorecardordershippingprice.zip10to19PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip10to19PriceHalf +
            this.scorecardordershippingprice.zip10to19PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 10 && this.postalCode <= 19 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip10to19PriceFull +
          this.scorecardordershippingprice.zip10to19PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip10to19PriceFull +
            this.scorecardordershippingprice.zip10to19PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 20 && this.postalCode <= 27 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip20to27PriceHalf +
          this.scorecardordershippingprice.zip20to27PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip20to27PriceHalf +
            this.scorecardordershippingprice.zip20to27PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (
        this.postalCode >= 20 &&
        this.postalCode <= 27 &&
        fullPallet &&
        loopCount !== 0
      ) {
        cost =
          this.scorecardordershippingprice.zip20to27PriceFull +
          this.scorecardordershippingprice.zip20to27PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip20to27PriceFull +
            this.scorecardordershippingprice.zip20to27PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 28 && this.postalCode <= 39 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip28to39PriceHalf +
          this.scorecardordershippingprice.zip28to39PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip28to39PriceHalf +
            this.scorecardordershippingprice.zip28to39PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 28 && this.postalCode <= 39 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip28to39PriceFull +
          this.scorecardordershippingprice.zip28to39PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip28to39PriceFull +
            this.scorecardordershippingprice.zip28to39PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 40 && this.postalCode <= 51 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip40to51PriceHalf +
          this.scorecardordershippingprice.zip40to51PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip40to51PriceHalf +
            this.scorecardordershippingprice.zip40to51PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 40 && this.postalCode <= 51 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip40to51PriceFull +
          this.scorecardordershippingprice.zip40to51PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip40to51PriceFull +
            this.scorecardordershippingprice.zip40to51PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 52 && this.postalCode <= 57 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip52to57PriceHalf +
          this.scorecardordershippingprice.zip52to57PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip52to57PriceHalf +
            this.scorecardordershippingprice.zip52to57PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 52 && this.postalCode <= 57 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip52to57PriceFull +
          this.scorecardordershippingprice.zip52to57PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip52to57PriceFull +
            this.scorecardordershippingprice.zip52to57PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 58 && this.postalCode <= 61 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip58to61PriceHalf +
          this.scorecardordershippingprice.zip58to61PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip58to61PriceHalf +
            this.scorecardordershippingprice.zip58to61PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 58 && this.postalCode <= 61 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip58to61PriceFull +
          this.scorecardordershippingprice.zip58to61PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip58to61PriceFull +
            this.scorecardordershippingprice.zip58to61PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (parseInt(this.postalCode) === 62 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip62PriceHalf +
          this.scorecardordershippingprice.zip62PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip62PriceHalf +
            this.scorecardordershippingprice.zip62PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (parseInt(this.postalCode) === 62 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip62PriceFull +
          this.scorecardordershippingprice.zip62PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip62PriceFull +
            this.scorecardordershippingprice.zip62PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 63 && this.postalCode <= 76 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip63to76PriceHalf +
          this.scorecardordershippingprice.zip63to76PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip63to76PriceHalf +
            this.scorecardordershippingprice.zip63to76PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 63 && this.postalCode <= 76 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip63to76PriceFull +
          this.scorecardordershippingprice.zip63to76PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip63to76PriceFull +
            this.scorecardordershippingprice.zip63to76PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 77 && this.postalCode <= 82 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip77to82PriceHalf +
          this.scorecardordershippingprice.zip77to82PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip77to82PriceHalf +
            this.scorecardordershippingprice.zip77to82PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 77 && this.postalCode <= 82 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip77to82PriceFull +
          this.scorecardordershippingprice.zip77to82PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip77to82PriceFull +
            this.scorecardordershippingprice.zip77to82PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 83 && this.postalCode <= 88 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip83to88PriceHalf +
          this.scorecardordershippingprice.zip83to88PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip83to88PriceHalf +
            this.scorecardordershippingprice.zip83to88PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 83 && this.postalCode <= 88 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip83to88PriceFull +
          this.scorecardordershippingprice.zip83to88PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip83to88PriceFull +
            this.scorecardordershippingprice.zip83to88PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 89 && this.postalCode <= 93 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip89to93PriceHalf +
          this.scorecardordershippingprice.zip89to93PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip89to93PriceHalf +
            this.scorecardordershippingprice.zip89to93PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 89 && this.postalCode <= 93 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip89to93PriceFull +
          this.scorecardordershippingprice.zip89to93PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip89to93PriceFull +
            this.scorecardordershippingprice.zip89to93PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 94 && this.postalCode <= 98 && halfPallet) {
        cost =
          this.scorecardordershippingprice.zip94to98PriceHalf +
          this.scorecardordershippingprice.zip94to98PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip94to98PriceHalf +
            this.scorecardordershippingprice.zip94to98PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      } else if (this.postalCode >= 94 && this.postalCode <= 98 && fullPallet) {
        cost =
          this.scorecardordershippingprice.zip94to98PriceFull +
          this.scorecardordershippingprice.zip94to98PriceFull *
            numberOfFullPallets +
          ((this.scorecardordershippingprice.zip94to98PriceFull +
            this.scorecardordershippingprice.zip94to98PriceFull *
              numberOfFullPallets) /
            100) *
            this.scorecardordershippingprice.freight;
      }
      this.customerShippingCost = Math.round(cost);
      this.scorecardordertempstate.customerShippingCost =
        this.customerShippingCost;
    },

    calculateTotalCost() {
      let cost = parseFloat(this.unitPrice) * (this.quantity * 1000);
      cost = cost + parseInt(this.customerShippingCost);
      this.totalCost = Math.round(cost);
      this.scorecardordertempstate.totalCost = this.totalCost;
      this.scorecardordertempstate.customerShippingCost =
        this.customerShippingCost;
    },

    cancelOrder() {
      if (
        this.scorecardType !== 0 ||
        this.deliveryWeek !== undefined ||
        this.quantity !== 1
      ) {
        if (confirm(this.$t('confirmCancel'))) {
          this.restoreState();
          this.$router.push('/ontag/orderscorecard/order');
        }
      } else {
        this.$router.push('/ontag/orderscorecard/order');
      }
    },

    toggleModal(choice) {
      if (choice === 'cancel') {
        this.deliveryWeekChoice = this.deliveryWeek;
      } else if (choice === 'weekChoice') {
        this.deliveryWeek = this.deliveryWeekChoice;
      }
      this.changeMonth(0);
      this.showModal = !this.showModal;
    },
    setSelectedState() {
      this.scorecardordertempstate.scorecardType = this.scorecardType;
      this.scorecardordertempstate.quantity = this.quantity;
      this.scorecardordertempstate.deliveryWeek = this.deliveryWeek;
      this.scorecardordertempstate.unitPrice = this.unitPrice;
      this.scorecardordertempstate.totalCost = this.totalCost;
      this.scorecardordertempstate.customerShippingCost =
        this.customerShippingCost;
    },
    restoreState() {
      this.scorecardordertempstate.scorecardType = 0;
      this.scorecardordertempstate.quantity = 1;
      this.scorecardordertempstate.deliveryWeek = undefined;
      this.scorecardordertempstate.unitPrice = 0;
      this.scorecardordertempstate.totalCost = 0;
      this.scorecardordertempstate.customerShippingCost = 0;
    },
  },
  computed: {
    scorecardordertempstate() {
      return this.$store.getters['scorecardOrder/scorecardordertempstate'];
    },
    scorecardorder() {
      return this.$store.getters['scorecardOrder/scorecardorder'];
    },
    scorecardordershippingprice() {
      return this.$store.getters['scorecardOrder/scorecardordershippingprice'];
    },
    scorecardorderaddresses() {
      return this.$store.getters['scorecardOrder/scorecardorderaddresses'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    terms() {
      return this.$store.getters['scorecardOrder/terms'];
    },
    marknextorderchanged() {
      return this.$store.getters['scorecardOrder/markNextOrderChanged'];
    },
  },
  watch: {
    scorecardType() {
      this.scorecardordertempstate.scorecardType = this.scorecardType;
      this.calculateShippingCost();
      this.calculateTotalCost();
      this.scorecardorder.quantity = this.quantity;
      this.scorecardorder.kolli = this.kolli;
      this.calculateCostWithoutShipping();
    },
    quantity() {
      this.scorecardordertempstate.quantity = this.quantity;
      this.scorecardorder.kolli = this.kolli;
      this.calculateCostWithoutShipping();
      this.calculateShippingCost();
      this.calculateTotalCost();
    },
    deliveryWeek() {
      this.scorecardorder.deliveryWeek = this.deliveryWeek;
    },
    // customerShippingCost() {
    //   this.scorecardorder.freightCost = this.customerShippingCost
    // }

    unitPrice() {
      this.calculateCostWithoutShipping();
    },
  },
  mounted() {
    this.$store
      .dispatch('scorecardOrder/fetchScorecardOrder')
      .then(() => {
        this.$store.dispatch('scorecardOrder/fetchTerms');
        this.$store
          .dispatch('scorecardOrder/fetchScorecardOrderAddresses')
          .then(() => {
            if (this.scorecardorderaddresses.deliveryAddress.postalCode) {
              this.postalCode =
                this.scorecardorderaddresses.deliveryAddress.postalCode.slice(
                  0,
                  2
                );
              this.postalCodeFull =
                this.scorecardorderaddresses.deliveryAddress.postalCode;
              if (this.postalCodeFull > 9999 && this.postalCodeFull < 100000) {
                this.postalCodeValid = true;
              }
            }
          });
        this.$store
          .dispatch('scorecardOrder/fetchScorecardOrderShippingPrice')
          .then(() => {
            this.calculateShippingCost();
          });
        this.scorecardType = this.scorecardordertempstate.scorecardType;
        this.quantity = this.scorecardordertempstate.quantity;
        this.deliveryWeek = this.scorecardordertempstate.deliveryWeek;
        if (this.scorecardordertempstate.unitPrice === 0) {
          if (this.scorecardType === 1) {
            this.unitPrice =
              this.scorecardorder.priceSettings.threePartPriceOne;
          } else if (this.scorecardType === 2) {
            this.unitPrice = this.scorecardorder.priceSettings.fourPartPriceOne;
          }
        } else {
          this.unitPrice = this.scorecardordertempstate.unitPrice;
        }
        this.totalCost = this.scorecardordertempstate.totalCost;
        this.customerShippingCost =
          this.scorecardordertempstate.customerShippingCost;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 4.375rem 0 1rem 0;
  @include media('small') {
    margin: 3.3rem 0rem 1rem 15.7rem;
  }
}
.standard-order {
  // background-color: color(lightSand);
  background: linear-gradient(to top, white 500px, color(lightSand) 500px);
  padding: 40px 30px;

  &__main-container {
    display: flex;
    margin: 20px auto 0px;
    max-width: 1200px;
    font-size: 14px;
  }

  &__left-section {
    width: 60%;
  }
  &__img-container {
    width: 100%;

    img {
      width: 100%;
      border-radius: 3px;
    }
  }

  &__img-select-container {
    display: flex;
    justify-content: flex-start;
    margin-top: measure(spacing-l);
  }

  &__img-select {
    width: 25%;
    margin-right: measure(spacing-l);
    height: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    img {
      width: 100%;
    }
  }

  &__right-section {
    width: 40%;
    margin: 0px 18px;
  }

  &__step-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    :first-child {
      border: 1px solid black;
    }
  }

  &__step {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    width: 32%;
    text-align: center;
    padding: measure(spacing-xs);
    border-radius: 2px;

    :first-child {
      border: none;
    }
  }

  &__order-detail-container {
    background-color: white;
    padding: 42px 38px;
    margin-top: 30px;
    border-radius: 3px;
  }

  &__choice-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    :first-child {
      background-color: transparent;
      justify-content: flex-start;
      padding-left: 0px;
    }
  }

  &__choice {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color(info-background);
    padding: 10px;
    text-align: center;
    width: 30%;
    border-radius: 3px;
    line-height: 24px;
    border: none;
    font-size: 14px;

    h4 {
      margin: 0;
    }

    span {
      background-color: color(info-background);
      margin-right: 10px;
    }
  }

  &__choice:hover:not(:first-child) {
    // border: 1px solid #304834;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &__quantity-container {
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      margin: 0 0 10px;
    }
    @include media('large') {
      flex-direction: row;
    }
  }

  &__quantity-container-disabled {
    opacity: 0.3;
  }
  &__quantity {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

    @include media('large') {
      width: 30%;
      text-align: left;
      margin-right: 20px;
      margin-bottom: 0px;
    }
  }

  &__quantity-button-container {
    display: flex;
    align-items: center;
  }

  input {
    margin: 0px 10px;
    width: 80px;
    height: 36px;
    border: 2px solid black;
    border-radius: 3px;
    text-align: center;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &__quantity-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    height: 50px;
    width: 50px;
    font-size: 25px;
    border-radius: 50px;
  }

  &__quantity-button:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &__quantity-button--disabled {
    background: #0399e469;
  }

  &__quantity-button:hover {
    cursor: pointer;
  }

  &__your-order-container {
    padding: 16px;
    margin: 40px 0px;
    border: 1px solid color(soluppgang);
    border-radius: 3px;

    h3 {
      margin: 0 0 12px;
    }

    table {
      width: 100%;
      line-height: 20px;
      tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        td {
        }
      }

      input {
        border: 1px solid black;
        width: 40px;
      }
    }
  }

  &__edit-unitCost {
    position: relative;
    span {
      position: absolute;
      top: -26px;
      left: 50px;
      cursor: pointer;
    }
  }
  &__edit-shippingCost {
    position: relative;
    span {
      position: absolute;
      top: -15px;
      left: 90px;
      cursor: pointer;
    }
  }

  &__delivery-info-text {
    margin-top: measure(spacing-l);
  }

  &__cost-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    :first-child {
      display: flex;
      align-items: flex-end;
      text-align: right;
    }

    h2 {
      margin: 0px;
    }
    img {
      margin-left: measure(spacing-s);
    }

    img:hover {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      cursor: pointer;
    }
  }

  &__cost-container-sum-text {
    margin-left: measure(spacing-xxs);
  }

  &__flow-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__cancel-button {
    width: 30%;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid black;
    background-color: transparent;
    button {
      padding: 14px;
      border: none;
      width: 100%;
      background-color: transparent;
    }
  }

  &__cancel-button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__continue-button {
    width: 70%;
    color: white;
    border-radius: 3px;
    border: 1px solid black;
    button {
      background-color: color(jord);
      border: none;
      width: 100%;
      // background-color: transparent;
      color: white;
      display: flex;
      justify-content: center;
      padding: 14px;
    }

    button:disabled {
      background: rgba(55, 54, 54, 0.5);
      cursor: not-allowed;
    }
  }

  &__continue-button:hover {
    text-decoration: none;
  }
  &__continue-button[disabled] {
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.507);
  }

  &__continue-button:hover:not([disabled]) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__text-block {
    font-size: 16px;
    margin: measure(spacing-xl) 0px;
  }

  &__collapse-container {
    border-top: 1px solid black;
  }

  &__collapse {
    border-bottom: 1px solid black;
    padding: 14px 0px;
  }

  &__collapse-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__collapse-content {
    padding-top: measure(spacing-l);
    table {
      tr {
        :first-child {
          padding-right: 20px;
        }
        td {
          padding: 6px 0px;
        }
      }
    }
  }

  &__pdf-svg-container {
    width: 16px;
    margin-right: measure(spacing-xs);

    img {
      width: 100%;
    }
  }

  &__terms-row {
    display: flex;
    align-items: center;

    a {
      color: color(link);
      text-decoration: underline;
    }
  }

  &__expand {
    font-size: 24px;
  }

  &__more-scorecards-container {
    display: flex;
    flex-direction: column;
    line-height: 30px;
    max-width: 512px;
    margin: 50px auto;

    h2 {
      text-align: center;
    }
  }

  &__bottom-button {
    max-height: 246px;
    max-width: 512px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    padding: 0px;
    :hover {
      transition: 0.3s;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.508);
      transform: scale(1.01);
    }

    :active {
      transform: scale(1.02);
    }
    img {
      width: 100%;
    }
  }

  &__background-wave {
    width: calc(100% + 60px);
    margin: 0px 0px -40px -30px;
    max-height: 200px;
    img {
      margin-bottom: -20px;
      width: 100%;
      overflow: hidden;
    }
  }
  &__type {
    margin: 20px;
  }

  &__type-block {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    background: linear-gradient(color(lightSand) 30%, white 30%);
  }

  &__type-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }

  &__type-img-clubspecific {
    width: 90%;
  }

  &__type-img {
    height: 246px;
    width: 512px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    padding: 0px;

    :hover {
      transition: 0.3s;
      transform: scale(1.01);
    }

    :active {
      transform: scale(1.02);
    }

    img {
      max-width: 100%;
    }
  }
}

.standard-order--bold {
  font-weight: bold;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip--notext img:hover {
  cursor: default;
  filter: none;
}

/* Tooltip text */
.tooltip .tooltiptext,
.tooltip .tooltiptexttip {
  visibility: hidden;
  width: 120px;
  background-color: color(info-background);
  color: black;
  text-align: center;
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 1;
  bottom: 166%;
  left: 50%;
  margin-left: -60px;
  border: 1px solid color(soluppgang);
}
.tooltip .tooltiptexttip {
  width: 20px;
  height: 20px;
  background-color: color(info-background);
  z-index: 2;
  margin-left: -10px;
  bottom: 130%;
  border-radius: 0px;
  transform: rotate(45deg);
  padding: 0px;
  border: none;
  border-bottom: 1px solid color(soluppgang);
  border-right: 1px solid color(soluppgang);
  box-shadow: none;
}
.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptexttip {
  visibility: visible;
}

.cancel:hover {
  cursor: pointer;
  font-weight: bold;
}

.modal {
  &__wrapper {
    padding: measure(spacing-xxl);
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      // background-color: transparent;
      border: none;
      background-color: color(jord);
      mask: url(../../assets/images/close.svg) no-repeat center / contain;
      -webkit-mask: url(../../assets/images/close.svg) no-repeat center /
        contain;
    }

    button:hover {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
  }
  &__cancel-button {
    width: 30%;
    margin-right: 10px;
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid black;
    button {
      padding: 14px;
      border: none;
      width: 100%;
      background-color: transparent;
    }
  }

  &__cancel-button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &__continue-button {
    width: 30%;
    color: white;
    border-radius: 3px;
    border: 1px solid black;
    button {
      background-color: color(jord);
      border: none;
      width: 100%;
      // background-color: transparent;
      color: white;
      display: flex;
      justify-content: center;
      padding: 14px;
    }
    button:disabled {
      background: rgba(55, 54, 54, 0.5);
      cursor: not-allowed;
    }
  }

  &__continue-button:hover {
    text-decoration: none;
  }

  &__continue-button:hover:not([disabled]) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__continue-button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__top-buttons-container {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    margin: measure(spacing-m) auto;
    button {
      margin: 0px measure(spacing-l);
      width: 40px;
      height: 40px;
      border: 1px solid black;
      border-radius: 3px;
      background-color: transparent;
    }
  }

  &__weeks-container {
    table {
      border-collapse: separate;
      border-spacing: 0 8px;
      margin: auto;

      :first-child:not(td) {
        background-color: white;
      }
      th {
        font-weight: normal;
      }
      tr,
      thead {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        // border: 2px solid color(lightSand);
        margin: 9px;
        background-color: color(lightSand);
        border-radius: 3px;
        td {
          font-size: 16px;
          padding: measure(spacing-s) measure(spacing-l);
        }
      }

      .selectable {
        color: color(middleGray);

        .week {
          color: black;
        }
      }
      .selectable:hover {
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-color: #304834;
      }
      .selected {
        border-color: #304834;
        background-color: #304834;
        color: white;
      }
      .disabled {
        border-color: #ccc;
        background-color: #ccc;
        color: white;
      }
    }
  }
}

.firstDayInMonth {
  border-left: 10px solid white;
  padding-right: calc((measure(spacing-l) + 10px)) !important;
}
.font-grey {
  color: color(middleGray);
}
</style>
