<template>
  <div>
    <div class="header row">
      <div>
        {{ $t('Businessboard.groupName') }}
      </div>
      <div>
        {{ $t('MemberCalculation.linkedCategories') }}
      </div>
    </div>
    <div
      v-for="item in items"
      :key="item.id"
      class="data row">
      <div>
        {{ item.membershipCategoryName }}
      </div>
      <div class="subtle">
        {{ item.membershipTypes.join(', ') }}
      </div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  components: {},
  mixins: [LocalizationMixin],
  props: {
    items: VueTypes.array.def([]),
  },
  data() {
    return {};
  },

  computed: {},

  mounted() {},

  methods: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.header {
  background: #373d3f;
  color: var(--white, #fff);
  padding: 22px 16px;
  font-size: 17px;
}
.data {
  background: var(--tabellgrn1, #f0f5f5);
  color: #000;
  padding: 12px 16px;
  font-size: 16px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-radius: 3px;
  font-weight: 600;
  vertical-align: middle;
  margin: 2px;
  .subtle {
    color: var(--gr1, #131516);
    font-size: 14px;
    font-weight: 300;
  }
}
</style>
