<template>
  <svg
    :width="`${svgData.width}px`"
    :height="`${svgData.height}px`"
    :viewBox="svgData.viewBox"
    :aria-labelledby="svgData.name"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    class="svg">
    <title
      :id="description"
      lang="sv">
      {{ description }} svg
    </title>
    <g
      class="svg__color"
      :fill="defaultColor"
      fill-rule="evenodd">
      <component
        :is="componentName"
        :stroke="defaultStroke" />
    </g>
  </svg>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    svg: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: undefined,
    },
    height: {
      type: [Number, String],
      default: undefined,
    },
    viewbox: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
    stroke: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    svgs: {
      'arrow-right': { width: 15, height: 13, viewBox: '-1 -2 15 13' },
      'circle-error': { width: 25, height: 25, viewBox: '0 0 25 25' },
      'circle-ok': { width: 25, height: 25, viewBox: '0 0 25 25' },
      'circle-yellow': { width: 25, height: 25, viewBox: '0 0 25 25' },
      'cogs-icon': { width: 30, height: 25, viewBox: '0 0 512 408' },
      'connected-icon': { width: 28, height: 13, viewBox: '0 0 28 13' },
      'edit-icon': { width: 15, height: 16, viewBox: '0 0 15 16' },
      'export-icon': { width: 16, height: 16, viewBox: '0 0 16 16' },
      'duplicate-icon': { width: 16, height: 16, viewBox: '0 0 16 16' },
      'filter-icon': { width: 15, height: 13, viewBox: '0 0 15 13' },
      'help-icon': { width: 26, height: 26, viewBox: '0 0 26 26' },
      'help-icon-alt': { width: 26, height: 26, viewBox: '0 0 26 26' },
      'li-circle-icon': { width: 7, height: 7, viewBox: '0 0 7 7' },
      'menu-icon': { width: 32, height: 32, viewBox: '0 0 32 32' },
      'pen-icon': { width: 14, height: 14, viewBox: '0 0 14 14' },
      'plus-icon': { width: 11, height: 11, viewBox: '0 0 11 11' },
      'print-icon': { width: 25, height: 25, viewBox: '0 0 25 25' },
      'trash-icon': { width: 14, height: 16, viewBox: '0 0 14 16' },
      'tv-icon': { width: 25, height: 20, viewBox: '0 0 25 20' },
      'unsorted-icon': { width: 10, height: 16, viewBox: '0 0 10 16' },
      'update-icon': { width: 16, height: 16, viewBox: '0 0 16 16' },
      'revert-icon': { width: 20, height: 20, viewBox: '0 0 20 20' },
      'pin-icon': { width: 10, height: 20, viewBox: '0 0 5 15' },
      published: { width: 16, height: 16, viewBox: '0 0 16 16' },
      archived: { width: 18, height: 16, viewBox: '0 0 18 16' },
      'change-percent': { width: 18, height: 16, viewBox: '0 0 18 16' },
      distribute: { width: 18, height: 16, viewBox: '0 0 18 16' },
      calendar: { width: 22, height: 20, viewBox: '0 0 22 20' },
      'check-mark': { width: 20, height: 30, viewBox: '0 0 20 30' },
      'bb-gear-icon': { width: 20, height: 20, viewBox: '0 0 20 20' },
      'bb-gear-icon-table': { width: 30, height: 30, viewBox: '0 0 30 30' },
      'bb-quote-icon': { width: 20, height: 20, viewBox: '0 0 20 20' },
      'bb-circle-arrow': {
        width: 31,
        height: 31,
        viewBox: '0 0 31 31',
        fill: '#ABDED1',
      },
      'bb-enlarge-icon': { width: 15, height: 15, viewBox: '0 0 13 14' },
      'bb-arrow-icon': { width: 20, height: 20, viewBox: '0 0 20 20' },
      'bb-arrow2-icon': { width: 13, height: 13, viewBox: '0 0 13 13' },
      'bb-warning-icon': { width: 20, height: 20, viewBox: '0 0 20 20' },
      'bb-info-icon': { width: 20, height: 20, viewBox: '0 0 20 20' },
      'bb-copy-icon': { width: 20, height: 20, viewBox: '0 0 20 20' },
      'bb-copy-icon2': { width: 30, height: 30, viewBox: '0 0 30 30' },
      'bb-company-icon': { width: 20, height: 11, viewBox: '0 0 20 11' },
      'bb-company-icon-dark': { width: 20, height: 11, viewBox: '0 0 20 11' },
      'bb-course-icon': { width: 12, height: 14, viewBox: '0 0 12 14' },
      'bb-course-icon-dark': { width: 12, height: 14, viewBox: '0 0 12 14' },
      'bb-trash-icon': { width: 12, height: 16, viewBox: '0 0 12 14' },
      'bb-kebab-icon': { width: 15, height: 15, viewBox: '-6 0 15 15' },
      'bb-update-icon': { width: 16, height: 16, viewBox: '0 0 16 16' },
      'bb-edit-icon': {
        width: 14,
        height: 16,
        viewBox: '-1 -1 15 17',
        fill: 'none',
      },
      'bb-star-icon': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        fill: 'none',
      },
      'bb-selected-icon': { width: 16, height: 16, viewBox: '0 0 16 16' },
      'black-plus-icon': { width: 20, height: 20, viewBox: '0 0 21 21' },
      'bb-member-icon': { width: 10, height: 16, viewbox: '0 0 10 16' },
      'bb-download-icon': { width: 16, height: 16, viewbox: '0 0 16 16' },
      'bb-trash2-icon': { width: 16, height: 16, viewbox: '0 0 16 16' },
      'bb-member-green-icon': { width: 16, height: 16, viewbox: '0 0 16 16' },
      'bb-slider-check-mark': { width: 20, height: 20, viewBox: '0 0 32 32' },
      'white-info-icon': { width: 24, height: 24, viewbox: '0 0 32 32' },
    },
  }),
  computed: {
    componentName() {
      return this.svg;
    },
    currentSvg() {
      return this.svgs[this.svg];
    },
    svgData() {
      return _.merge(
        {},
        this.currentSvg,
        _.pick(this.$props, _.keys(this.currentSvg))
      );
    },
    viewboxValues() {
      return this.viewbox ? this.viewbox : this.svg.viewBox;
    },
    defaultColor() {
      if (this.svgData.defaultColor && !this.color) {
        return this.svgData.defaultColor;
      } else if (this.color === undefined) {
        return 'currentColor';
      } else {
        return this.color;
      }
    },
    defaultStroke() {
      if (this.svgData.defaultStroke && !this.stroke) {
        return this.svgData.defaultStroke;
      } else if (this.stroke === undefined) {
        return 'currentStroke';
      } else {
        return this.stroke;
      }
    },
  },
};
</script>

<style lang="scss">
.svg {
  display: inline-block;
  vertical-align: baseline;
  pointer-events: none;
}

.svg--90 {
  transform: rotate(90deg);
}

.svg--90-negative {
  transform: rotate(-90deg);
}

.svg--180 {
  transform: rotate(180deg);
}

.svg--180-negative {
  transform: rotate(-180deg);
}

.svg--270 {
  transform: rotate(270deg);
}

.svg--270-negative {
  transform: rotate(-270deg);
}

.svg--flip {
  transform: scale(-1);
}

.v-align-text-bottom {
  vertical-align: text-bottom;
}
</style>
