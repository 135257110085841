<template>
  <div class="golfoffice">
    <!-- TODO:
      - Fix Reset password, find it below
    -->
    <div class="golfoffice--content">
      <div>
        <router-link to="/users/">&laquo; {{ $t('back') }}</router-link>
      </div>
      <div class="golfoffice--top">
        <h1>{{ $t('editUser') }}</h1>
        <base-button
          :text="$t('deleteUser')"
          background-color="red"
          @click="toggleDeleteUserModal" />
        <modal-window :show="showDeleteModal">
          <h2>
            {{ $t('deleteUserQuestion') }} {{ user.firstName }}
            {{ user.lastName }}?
          </h2>
          <div class="modal__content--actions">
            <base-button
              :text="$t('cancel')"
              @click="toggleDeleteUserModal">
            </base-button>
            <base-button
              :text="$t('deleteUser')"
              background-color="red"
              @click="deleteUser" />
          </div>
        </modal-window>
      </div>
      <p>
        Här kan ni administrera en användare. Byta namn, roll och återställa
        lösenord. Se till att användaren är aktiv eller inte samt ändra aktiva
        datum för den användaren.
      </p>
      <h2 class="sub-title">User information</h2>
      <form noValidate>
        <div class="content__input-group">
          <div class="input-group">
            <div class="input-group">
              <div class="input">
                <label
                  for="firstName"
                  class="input__label">First name*</label>
                <input
                  id="firstName"
                  class="input__input"
                  v-model="user.firstName"
                  type="text"
                  required="true"
                  name="firstName" />
              </div>
              <div class="input">
                <label
                  for="lastName"
                  class="input__label">Last name*</label>
                <input
                  id="lastName"
                  class="input__input"
                  v-model="user.lastName"
                  type="text"
                  required="true"
                  name="lastName" />
              </div>
              <template v-if="this.user.clubId">
                <template v-for="(product, key, index) in roles">
                  <custom-select
                    :label="'Choose role for ' + key"
                    :id="'role_' + key"
                    @optionSelected="e => selectedRole(e, key)"
                    :options="product"
                    :selected="selectedIndex[index]"
                    :key="index"
                    :required="false">
                  </custom-select>
                </template>
              </template>
              <div
                v-if="user.clubName"
                class="input">
                <label
                  for="clubName"
                  class="input__label">Club name</label>
                <input
                  id="clubName"
                  class="input__input"
                  v-model="user.clubName"
                  type="text"
                  name="clubName"
                  readonly />
              </div>
              <div
                v-if="user.useEmailAsUsername"
                class="input"
                :class="`${hasError ? 'input__error' : ''}`">
                <label
                  for="email"
                  class="input__label">Email*</label>
                <input
                  id="email"
                  class="input__input"
                  v-model="user.email"
                  type="email"
                  required="true"
                  name="Email"
                  @input="checkEmailValidity" />
                <div
                  v-if="
                    failedEmailValidiation &&
                    this.user.email &&
                    user.useEmailAsUsername
                  "
                  class="input__error--message">
                  Nope! You must use and @ in your email
                </div>
              </div>
              <div
                v-if="!user.useEmailAsUsername"
                class="input">
                <label
                  for="userName"
                  class="input__label">Username*</label>
                <input
                  id="userName"
                  class="input__input"
                  v-model="user.username"
                  type="text"
                  required="true"
                  name="userName" />
              </div>
              <div class="input-group">
                <label
                  for="userName"
                  class="input__label">Active</label>
                <toggle-switch
                  label="Active"
                  input-id="userActive"
                  type="switch"
                  :toggled="user.active"
                  @toggle="isActive" />
              </div>
              <div
                class="input"
                v-if="user.active">
                <label
                  for="activeTo"
                  class="input__label">Active until</label>
                <input
                  id="activeTo"
                  class="input__input"
                  v-model="date"
                  type="date"
                  required="false"
                  name="activeTo" />
              </div>
              <div v-if="showBusinessBoardResultUnits">
                <div
                  v-for="company in businessBoardResultUnits"
                  :key="company.id">
                  <h3>{{ company.name }}</h3>
                  <div
                    v-for="resultunit in company.resultUnits"
                    :key="resultunit.id">
                    <input
                      type="checkbox"
                      :value="resultunit.id"
                      v-model="user.businessBoardResultUnits" />
                    {{ resultunit.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="content__input-group">
        <div
          class="input-group--inline"
          v-if="user.useEmailAsUsername">
          <base-button
            text="Reset Password"
            modifier-classes="button--reset"
            @click="resetPasswordLink" />
        </div>
        <div class="input-group--inline user__actions">
          <router-link to="/users">
            <base-button text="Cancel"> </base-button>
          </router-link>
          <base-button
            text="Save user"
            type="submit"
            @click="saveUser"
            :disabled="!canSave" />
        </div>
      </div>
      <div
        class="content__input-group"
        v-if="!user.useEmailAsUsername">
        <h2 class="sub-title">Reset Password</h2>
        <div class="input-group">
          <div
            class="input"
            :class="`${hasError ? 'input__error' : ''}`">
            <label
              for="password"
              class="input__label">Password*</label>
            <input
              id="password"
              class="input__input"
              v-model="user.password"
              type="password"
              required="true"
              name="password" />
          </div>
          <div
            class="input"
            :class="`${hasError ? 'input__error' : ''}`">
            <label
              for="confirmPassword"
              class="input__label">Confirm Password*</label>
            <input
              id="confirmPassword"
              class="input__input"
              v-model="user.confirmPassword"
              type="password"
              required="true"
              name="confirmPassword"
              @input="checkIfPasswordsMatch" />
            <div
              v-if="hasError"
              class="input__error--message">
              Nope passwords doesn't match
            </div>
          </div>
          <div class="input-group--inline user__actions">
            <base-button
              text="Reset password"
              type="submit"
              @click="resetPassword"
              :disabled="!canResetPassword" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalWindow from '@/components/modal/modal';
import ToggleSwitch from '@/components/form-elements/switch';
import CustomSelect from '@/components/form-elements/custom-select';
import BaseButton from '@/components/form-elements/base-button';
import { checkIfEmptyObject, formatDateForInputs } from '@/core/helpers.js';

export default {
  name: 'EditUser',
  components: {
    CustomSelect,
    BaseButton,
    ToggleSwitch,
    ModalWindow,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      isMatchingPasswords: null,
      failedEmailValidiation: false,
      hasError: false,
      mode: '',
      dataId: this.id,
      showDeleteModal: false,
      selectedRoles: {},
      selectedIndex: [],
      showBusinessBoardResultUnits: false,
    };
  },
  methods: {
    checkEmailValidity() {
      if (!this.user.email.includes('@')) {
        this.failedEmailValidiation = true;
      } else {
        this.failedEmailValidiation = false;
      }
    },
    checkIfPasswordsMatch() {
      if (this.user.password === this.user.confirmPassword) {
        this.isMatchingPasswords = true;
        this.hasError = false;
      } else {
        this.isMatchingPasswords = false;
        this.hasError = true;
      }
    },
    saveUser() {
      if (this.user.useEmailAsUsername) {
        this.$store
          .dispatch('users/saveUser', {
            id: this.user.id,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            email: this.user.email,
            roles: this.selectedRoles,
            active: this.user.active,
            activeTo: this.user.activeTo,
            useEmailAsUsername: this.user.useEmailAsUsername,
            businessBoardResultUnits: this.user.businessBoardResultUnits,
          })
          .then(() => {
            this.$router.push({ path: '/users/' });
            // TODO: Fix translation
            this.$toasted.success('User is saved');
          });
      } else {
        this.$store
          .dispatch('users/saveUser', {
            id: this.user.id,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            username: this.user.username,
            roles: this.selectedRoles,
            active: this.user.active,
            activeTo: this.user.activeTo,
            useEmailAsUsername: this.user.useEmailAsUsername,
            businessBoardResultUnits: this.user.businessBoardResultUnits,
          })
          .then(() => {
            this.$router.push({ path: '/users/' });
            // TODO: Fix translation
            this.$toasted.success('User is saved');
          });
      }
    },
    toggleDeleteUserModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    deleteUser() {
      this.$store
        .dispatch('users/deleteUser', {
          id: this.user.id,
        })
        .then(() => {
          this.$router.push({ path: '/users/' });
          // TODO: Fix translation
          this.$toasted.success(this.$t('userDeleted'));
        });
    },
    selectedRole(e, product) {
      this.selectedRoles[product] = [];
      this.selectedRoles[product].push({
        value: e.value,
        displayName: e.displayName,
      });
      if (
        product === 'BusinessBoard' &&
        e.value === 'BusinessBoardAreaManager'
      ) {
        this.$store.dispatch('businessboard/fetchResultUnits', {
          id: this.user.clubId,
        });
        this.showBusinessBoardResultUnits = true;
      } else {
        this.showBusinessBoardResultUnits = false;
      }
    },
    isActive(boolean) {
      this.user.active = boolean;
    },
    setSelectedIndexes(product) {
      if (this.user.roles[product] && this.user.roles[product][0]) {
        this.selectedIndex.push(
          this.roles[product].findIndex(
            p => p.value === this.user.roles[product][0].value
          )
        );
        this.selectedRole(this.user.roles[product][0], product);
      } else {
        this.selectedIndex.push(0);
      }
    },
    resetPasswordLink() {
      this.$store
        .dispatch('user/forgotPassword', { email: this.user.email })
        .then(() => {
          this.$router.push({ path: '/users/' });
          this.$toasted.success(
            'A link for resetting the password has been sent to ' +
              this.user.firstName +
              ' ' +
              this.user.lastName +
              ' via e-mail'
          );
        });
    },
    resetPassword() {
      this.$store
        .dispatch('user/changePassword', {
          id: this.user.id,
          password: this.user.password,
          repeatedPassword: this.user.confirmPassword,
        })
        .then(data => {
          if (data.success === true) {
            this.$toasted.success('Password has been changed');
            this.user.password = '';
            this.user.confirmPassword = '';
          }
        });
    },
  },
  computed: {
    canSave() {
      if (this.user.useEmailAsUsername) {
        return this.user.firstName && this.user.lastName && this.user.email;
      } else {
        return this.user.firstName && this.user.lastName && this.user.username;
      }
    },
    canResetPassword() {
      if (!this.user.useEmailAsUsername) {
        return (
          this.user.firstName &&
          this.user.lastName &&
          !checkIfEmptyObject(this.user.roles) &&
          this.user.username &&
          this.user.password &&
          this.user.confirmPassword &&
          this.isMatchingPasswords
        );
      } else {
        return false;
      }
    },
    user() {
      return this.$store.getters['users/user'](this.id);
    },
    date: {
      get: function () {
        return formatDateForInputs(this.user.activeTo);
      },
      set: function (newValue) {
        this.user.activeTo = newValue;
        return this.user.activeTo;
      },
    },
    roles() {
      return this.$store.getters['users/roles'];
    },
    businessBoardResultUnits() {
      return this.$store.getters['businessboard/resultunits'];
    },
  },
  created() {
    this.$store.dispatch('users/fetchUser', { id: this.id }).then(() => {
      if (this.user.clubId) {
        this.$store
          .dispatch('users/fetchRoles', {
            clubId: this.user.clubId,
            withEmail: this.user.useEmailAsUsername,
          })
          .then(() => {
            Object.keys(this.roles).map(product => {
              this.setSelectedIndexes(product);
            });
          });
      }
    });
  },
};
</script>
<style lang="scss">
.golfoffice {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.golfoffice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  .golfoffice--top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin-top: 0;
    }
  }
  .content__input-group {
    width: 100%;
    @include media('medium') {
      width: calc((100% / 1.2) - (1rem * 2));
    }

    .user__actions {
      justify-content: flex-start;
      @include media('small') {
        justify-content: flex-end;
        // width: 70%;
      }

      a button {
        margin-left: 0;
      }

      button {
        margin-left: 1.25rem;
      }
    }
  }

  .sub-title {
    margin-bottom: 0;
  }
}
</style>
