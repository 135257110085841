<template>
  <div class="list-table__wrapper">
    <table class="list-table">
      <thead>
        <tr>
          <template v-for="(header, index) in headers">
            <th
              :key="index"
              v-if="
                hideInMobile.includes(header.value) && mq('to-large')
                  ? false
                  : true
              ">
              <component
                :is="header.order ? 'button' : 'div'"
                :class="[
                  { 'button list-table__button': header.order },
                  { 'list-table__div': !header.order },
                  { resultText: header.value === 'resultText' },
                ]"
                @click.stop="header.order && sortResults(header.value)">
                <div v-if="header.checkbox">
                  <div
                    class="input checkbox"
                    @click="selectAll">
                    <div class="checkbox--group">
                      <input
                        type="checkbox"
                        id="selectAll"
                        name="selectAll"
                        v-model="allSelected" />
                      <label
for="selectAll"><span><span></span></span></label>
                    </div>
                  </div>
                </div>
                <div v-else-if="header.order">
                  <span>{{ header.displayName }}</span>
                  <use-svg svg="unsorted-icon" />
                </div>
                <multi-select
                  v-else-if="header.filter"
                  :use-icon="true"
                  icon-name="filter-icon"
                  :label="header.displayName"
                  :id="'list-table__filter'"
                  @optionSelected="e => filterResults(e, header.value)"
                  :options="header.filterValues">
                </multi-select>
                <span v-else>{{ header.displayName }}</span>
              </component>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="index"
          :class="[
            { checked: selected.find(s => s === item.id) },
            { pointer: addCursor },
            { budget: item.type === 1 },
            { budgetGroup: item.type === 2 },
            { resultUnitSum: item.type === 3 },
            { totalSum: item.type === 4 },
          ]"
          @mouseover="useCursor ? (addCursor = true) : (addCursor = false)"
          @mouseout="useCursor ? (addCursor = false) : (addCursor = false)"
          v-show="
            !(item.type == 1 && roles.includes('BusinessBoardAreaManager'))
          ">
          <template v-for="(header, indexColumn) in headers">
            <td
              v-if="
                hideInMobile.includes(header.value) && mq('to-large')
                  ? false
                  : true
              "
              :class="[header.routerLink ? 'router-link' : header.value]"
              :key="indexColumn">
              <template v-if="indexColumn === 0 && shouldShowCheckboxes(item)">
                <input
                  type="checkbox"
                  v-model="checkedItems"
                  :id="item.id"
                  :value="item.id" />
              </template>
              <template v-if="header.value === 'checkbox'">
                <div class="input checkbox">
                  <div class="checkbox--group">
                    <input
                      type="checkbox"
                      :value="item.id"
                      v-model="selected"
                      @change="select" />
                    <label
:for="item.id"><span><span></span></span></label>
                  </div>
                </div>
              </template>
              <template v-else-if="header.value === 'status'">
                <template v-if="item.status === 1">
                  <use-svg svg="circle-ok" />
                  <span class="status--text">{{ item.statusText }}</span>
                </template>
                <template v-else-if="item.status === 0">
                  <use-svg svg="circle-error" />
                  <span class="status--text">{{ item.statusText }}</span>
                </template>
                <template v-else>
                  <span class="status--text">{{ item.status }}</span>
                </template>
              </template>
              <template v-else-if="header.value === 'active'">
                <template v-if="item.active">
                  <use-svg svg="circle-ok" />
                  <span class="status--text">{{ item.activeText }}</span>
                </template>
                <template v-else>
                  <use-svg svg="circle-error" />
                  <span class="status--text">{{ item.activeText }}</span>
                </template>
              </template>
              <template v-else-if="header.value === 'accountCount'">
                {{ item.accountCount }}
                <router-link :to="'/businessboard/group/' + item.id">
                  {{ $t('Businessboard.showAccounts') }}
                </router-link>
              </template>
              <template v-else-if="header.value === 'membershipTypeCount'">
                {{ item.membershipTypeCount }}
                <router-link :to="'/businessboard/category/' + item.id">
                  {{ $t('Businessboard.showAccounts') }}
                </router-link>
              </template>
              <template v-else-if="header.value === 'exclude'">
                <toggle-switch
                  :id="item.id"
                  type="switch"
                  :disabled="item.accountCount > 0"
                  :toggled="item.exclude"
                  @toggle="e => toggleExcludeSwitch(item)" />
              </template>
              <template v-else-if="header.value === 'resultGroupId'">
                <div class="input-group--inline">
                  <select
                    :disabled="checkedItems.length > 0"
                    :id="item.id + '-select'"
                    @change="e => selectEvent(e, item)">
                    <option
                      v-for="option in item.costAccount
                        ? selectOptions.costGroups
                        : selectOptions.incomeGroups"
                      :value="option.value"
                      :key="option.value"
                      :selected="option.value == item.resultGroupId">
                      {{ option.displayName }}
                    </option>
                  </select>
                </div>
              </template>
              <template v-else-if="header.value === 'costAccount'">
                <template v-if="item.costAccount">
                  {{ $t('Businessboard.costAccountTrue') }}
                </template>
                <template v-else>
                  {{ $t('Businessboard.costAccountFalse') }}
                </template>
              </template>
              <template v-else-if="header.value === 'displayName'">
                <template v-if="item.standard">
                  <use-svg svg="pin-icon" />
                </template>
                <span>{{ item.displayName }}</span>
              </template>
              <template v-else-if="header.value === 'distribution'">
                {{ getDistributionString(item.distribution) }}
              </template>
              <template v-else-if="header.value === 'financialYear'">
                {{ item.financialYear }}
              </template>

              <template v-else-if="header.routerLink">
                <template
                  v-if="
                    header.value === '/data/course/data/revert' ||
                    (header.value === '/golfbox/gamespercourse/revert' &&
                      item.id != null &&
                      item.id.length > 0)
                  ">
                  <router-link
                    v-if="item.id"
                    :to="header.value + '/' + item.id">
                    <use-svg svg="revert-icon" />
                  </router-link>
                </template>
                <template
                  v-else-if="
                    header.value === '/data/resultgroup/delete-resultgroup/'
                  ">
                  <table class="groupsbuttons">
                    <tr>
                      <td>
                        <a
                          @click="orderChange(item, -1)"
                          v-if="!item.locked && index > 0">
                          <use-svg
                            svg="arrow-right"
                            class="svg--180-negative" />
                        </a>
                      </td>
                      <td>
                        <a
                          @click="orderChange(item, +1)"
                          v-if="
                            !item.locked &&
                            index + 1 < items.filter(i => !i.locked).length
                          ">
                          <use-svg svg="arrow-right" />
                        </a>
                      </td>
                      <template>
                        <td>
                          <router-link
                            :to="'/businessboard/edit-group/' + item.id">
                            <use-svg svg="edit-icon" />
                          </router-link>
                        </td>
                        <td v-if="!item.standard">
                          <router-link
                            v-if="item.accountCount == 0"
                            :to="
                              header.value + item.id + '?name=' + item.group
                            ">
                            <use-svg svg="trash-icon" />
                          </router-link>
                        </td>
                      </template>
                    </tr>
                  </table>
                </template>
                <template
                  v-else-if="
                    header.value === '/data/company/delete-membershipcategory/'
                  ">
                  <table class="groupsbuttons">
                    <tr>
                      <td>
                        <a
                          v-on="
                            enableClick
                              ? { click: () => orderChangeCategory(item, -1) }
                              : {}
                          "
                          v-if="index > 0">
                          <use-svg
                            svg="arrow-right"
                            class="svg--180-negative" />
                        </a>
                      </td>
                      <td>
                        <!-- <a @click="orderChangeCategory(item, +1)" v-if="!item.locked && index+1 < items.filter(i => !i.locked).length"> -->
                        <a
                          v-on="
                            enableClick
                              ? { click: () => orderChangeCategory(item, +1) }
                              : {}
                          "
                          v-if="
                            index + 1 <
                            membershipCategories.membershipCategories.rows
                              .length
                          ">
                          <use-svg svg="arrow-right" />
                        </a>
                      </td>
                      <template>
                        <td>
                          <router-link
                            :to="'/businessboard/edit-category/' + item.id">
                            <use-svg svg="edit-icon" />
                          </router-link>
                        </td>
                        <td v-if="item.accountCount == 0 && !item.standard">
                          <router-link :to="header.value + item.id">
                            <use-svg svg="trash-icon" />
                          </router-link>
                        </td>
                      </template>
                    </tr>
                  </table>
                </template>
                <template
                  v-else-if="
                    header.value === '/businessboard/edit-financialyear/'
                  ">
                </template>
                <template
                  v-else-if="
                    header.value === '/businessboard/delete-financialyear/'
                  ">
                  <span
                    class="click-icon"
                    @click="editYearClick(item)">
                    <use-svg svg="edit-icon" />
                  </span>
                  <router-link :to="header.value + item.id">
                    <use-svg svg="trash-icon" />
                  </router-link>
                </template>
                <template
                  v-else-if="
                    header.value === '/springboard/edit-distributiontemplate/'
                  ">
                  <span
                    class="click-icon"
                    @click="$emit('editDistributionTemplate', item)">
                    <use-svg
                      svg="arrow-right"
                      class="svg--90-negative" />
                  </span>
                </template>
                <template
                  v-else-if="
                    (header.value === '/budget/edit-budget/' ||
                      header.value === '/forecast/edit-forecast/') &&
                    item.id
                  ">
                  <router-link
                    :to="{
                      path: header.value + companyId + '/' + item.id,
                    }">
                    <use-svg
                      svg="arrow-right"
                      class="svg--90-negative" />
                  </router-link>
                </template>
                <template v-else-if="item.id">
                  <router-link :to="{ path: header.value + item.id }">
                    <use-svg
                      svg="arrow-right"
                      class="svg--90-negative" />
                  </router-link>
                </template>
              </template>
              <template
                v-else-if="
                  header.value === 'lastLogin' ||
                  header.value === 'activeChanged' ||
                  header.value === 'statusChanged' ||
                  header.value === 'printTime' ||
                  header.value === 'date'
                ">
                {{
                  item[header.value]
                    ? formatDateAndTime(item[header.value])
                    : ''
                }}
              </template>
              <template v-else-if="header.input">
                <div class="input">
                  <input
                    v-if="header.inputMaxLength === 0"
                    :id="header.value + '-input-' + index"
                    class="input__input"
                    v-model="item[header.value]"
                    type="number"
                    :name="header.value + '-input-' + index"
                    @input="e => changedInput(true)" />
                  <input
                    v-else
                    :id="header.value + '-input-' + index"
                    class="input__input"
                    v-model="item[header.value]"
                    type="text"
                    :name="header.value + '-input-' + index"
                    maxlength="3"
                    @input="e => changedInput(true)" />
                  <span
                    v-if="header.unit"
                    class="input__unit">{{ header.unit }}</span>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </td>
          </template>
        </tr>
        <slot />
      </tbody>
    </table>
  </div>
</template>

<script>
import mq from '@/mixins/mq.js';
import UseSvg from '@/components/use-svg';
import MultiSelect from '@/components/form-elements/multi-select';
import ToggleSwitch from '@/components/form-elements/switch';
import CustomSelect from '@/components/form-elements/custom-select';

export default {
  name: 'ListTable',
  mixins: [mq],
  components: {
    UseSvg,
    MultiSelect,
    ToggleSwitch,
    CustomSelect,
  },
  props: {
    companyId: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    hideInMobile: {
      type: Array,
      default: () => [],
    },
    useCursor: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enableClick: true,
      allSelected: false,
      selected: [],
      checked: false,
      sortDirection: 'asc',
      addCursor: true,
      max: 3,
      selectedAccountType: '',
    };
  },
  methods: {
    selectAll() {
      this.selected = [];
      if (!this.allSelected) {
        let visibleItems = this.items.slice(0, this.pageSize);
        for (let i in visibleItems) {
          this.selected.push(visibleItems[i].id);
        }
      }
      this.$emit('allSelected', this.selected);
    },
    select() {
      if (this.selected.length === this.items.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
      this.$emit('allSelected', this.selected);
    },
    resetSelectAll() {
      this.allSelected = false;
    },
    formatDateAndTime(dateAndTime) {
      const dateString = new Date(dateAndTime).toLocaleDateString('sv-SE');
      const timeString = new Date(dateAndTime).toLocaleTimeString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return dateString + ' ' + timeString;
    },
    // TODO: Add routeToLink on tr-click
    routeToLink(url, id) {
      this.$router.push({ path: `${url}${id}` });
    },
    changedInput(value) {
      this.$emit('changedInput', value);
    },
    sortResults(column) {
      if (this.sortDirection === 'desc') {
        this.sortDirection = 'asc';
      } else {
        this.sortDirection = 'desc';
      }
      this.$emit('variationResults', {
        sort: { id: column, direction: this.sortDirection },
      });
    },
    filterResults(e, column) {
      this.$emit('variationResults', {
        filter: { id: column, options: e.options },
      });
    },
    toggleExcludeSwitch(item) {
      item.exclude = !item.exclude;
      if (item.group) {
        item.name = item.group;
        this.$store.dispatch('businessboard/patchGroup', item);
      } else {
        this.$store.dispatch('businessboard/patchMembershipCategory', item);
        item.name = item.membershipCategoryName;
      }
    },
    orderChange(item, delta) {
      item.order = item.order + delta;
      item.name = item.group;
      this.$store
        .dispatch('businessboard/patchGroup', item)
        .then(this.$store.dispatch('businessboard/fetchData'));
    },
    async orderChangeCategory(item, delta) {
      this.enableClick = false;
      item.order = item.order + delta;
      item.name = item.membershipCategoryName;
      await this.$store.dispatch('businessboard/patchMembershipCategory', item);
      await this.$store.dispatch('businessboard/fetchMembershipCategories');
      this.enableClick = true;
    },
    selectEvent(e, account) {
      let i = this.checkedItems.indexOf(account.id);
      if (i > -1) {
        this.checkedItems.splice(i, 1);
      }

      account.resultGroupId = e.target.value;
      this.$emit('selectEvent', account);
    },
    editYearClick(year) {
      this.$emit('editYearClick', year);
    },
    getDistributionString(distribution) {
      return distribution.join('%/') + '%';
    },
    shouldShowCheckboxes(item) {
      return (
        this.isSelectable &&
        (this.checkedItems.length === 0 ||
          (this.items.find(i => i.id === this.checkedItems[0]) &&
            item.costAccount ===
              this.items.find(i => i.id === this.checkedItems[0]).costAccount))
      );
    },
  },
  computed: {
    roles() {
      return (
        this.$store.state.user.user.roles?.BusinessBoard?.map(x => x.value) ??
        []
      );
    },
    selectOptions() {
      return this.$store.state.businessboard.resultgroupselect;
    },
    membershipCategories() {
      return this.$store.getters['businessboard/membershipcategories'];
    },
    checkedItems: {
      get() {
        return this.$store.state.businessboard.listTableCheckedItems;
      },
      set(value) {
        this.$store.commit('businessboard/listTableCheckedItems', value);
      },
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
a {
  cursor: pointer;
}
.input-group--inline {
  margin: 0;
}
.list-table__wrapper {
  overflow-x: auto;
}
.list-table {
  font-size: font(h4);
  .multi-select {
    color: white !important;
  }
  border-collapse: collapse;
  width: 100%;
  thead {
    th {
      background-color: color(bb-sec5);
      text-align: left;
      padding: 0 0.625rem;
      &:first-child {
        padding-left: 0.8rem;
      }
      &:last-child {
        padding-right: 0.8rem;
      }
      .list-table__button {
        border: none;
        background-color: color(bb-primary);
        min-height: 2.625rem;
        padding: 0;
        span {
          font-weight: bold;
          margin-right: 0.325rem;
          position: relative;
          top: -2px;
        }
      }
      .list-table__div {
        min-height: 2.625rem;
        span {
          font-weight: bold;
          margin-right: 0.325rem;
          position: relative;
          top: 10px;
        }
      }
    }
  }
  tbody {
    border-collapse: collapse;
    tr {
      &.pointer {
        cursor: auto;
      }
      &:nth-child(even) {
        background-color: color(gray-lighter);
      }
      &.checked {
        background-color: color(blue-light);
      }
      td {
        padding: 0.625rem;
        text-align: left;
        &:first-child {
          padding-left: 0.8rem;
        }
        &:last-child {
          padding-right: 0.8rem;
          &.router-link {
            text-align: right;
          }
        }
        .input {
          display: flex;
          align-items: baseline;
          margin-bottom: 0;

          .input__unit {
            margin-left: 10px;
          }

          .input__input {
            width: 80%;
            @include media('large') {
              width: 40%;
            }
          }
        }
        .svg {
          margin-right: 0.2rem;
        }
        .status--text {
          vertical-align: super;
        }
        .click-icon {
          cursor: pointer;
        }
      }
    }
  }
}
.groupsbuttons {
  td {
    padding: 0 !important;
    width: 1.2rem;
  }
}

.budget {
  background-color: color(bb-sec6) !important;
}
.resultUnitSum {
  border-left: 0px solid white;
  border-left-width: 30px;
}
.totalSum {
  background-color: color(bb-sec9) !important;
}
.budgetGroup {
  border-left: 0px solid white;
  border-left-width: 30px;
}
.resultText {
  text-align: right !important;
}
</style>
