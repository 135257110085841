<template>
  <div>
    <div class="selectData--div">
      <div class="section">
        <div
          class="select--checkbox cursor-pointer"
          @click="handleClick">
          <use-svg
            v-if="displayField"
            svg="bb-selected-icon" />
        </div>
        <label
          class="cursor-pointer"
          @click="handleClick">
          {{ fee.name }}
        </label>
      </div>
      <div
        class="display--amount"
        v-if="displayField">
        <div class="amount--div">
          <input
            type="text"
            inputmode="numeric"
            :value="0"
            @change="e => changeFeeValues(e.target.value)" />
          %
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UseSvg from '@/components/use-svg';

export default {
  name: 'modifyFieldUsingInput',
  components: { UseSvg },
  props: {
    fee: {
      type: Object,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      displayField: false,
    };
  },

  computed: {},
  methods: {
    handleClick() {
      this.displayField = !this.displayField;
      if (!this.displayField) this.changeFeeValues(0);
    },

    changeFeeValues(precentage) {
      for (const type of this.$store.state.businessboard.membershipTypeYearCopy
        .types) {
        let foundFee = type.fees.find(
          f => f.feeColumnReference === this.fee.id
        );
        if (foundFee) {
          foundFee.amount += Number(
            (foundFee.amount * (precentage / 100)).toFixed(0)
          );
        }
        for (const ageGroup of type.ageGroups) {
          let foundFee = ageGroup.fees.find(
            f => f.feeColumnReference === this.fee.id
          );
          if (foundFee) {
            foundFee.amount += Number(
              (foundFee.amount * (precentage / 100)).toFixed(0)
            );
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.selectData--div {
  display: flex;
}
.section {
  display: flex;
  gap: 1rem;
  padding: 0.3rem;

  width: 100%;

  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
  }
}
.display--amount {
  width: 35%;
  padding: 0.3rem;
  .amount--div {
    float: right;

    input {
      width: 3rem;
    }
  }
}

.select--checkbox {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 4px;
}
</style>
