/* Store module to handle Scorecard */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  scorecards: [],
  scorecardsType: null,
  scorecard: {
    imageOneUrl: '',
    imageOneKey: '',
    imageTwoUrl: '',
    imageTwoKey: '',
  },
  templates: [],
  courses: [],
  competitions: [],
  categories: [],
};

const mutations = {
  scorecards (state, payload) {
    state.scorecards = payload;
  },
  scorecard (state, payload) {
    state.scorecard = payload;
  },
  filters (state, payload) {
    state.filters = payload;
  },
  templates (state, payload) {
    state.templates = payload;
  },
  courses (state, payload) {
    state.courses = payload;
  },
  competitions (state, payload) {
    state.competitions = payload;
  },
  categories (state, payload) {
    state.categories = payload;
  },
  pagination (state, payload) {
    state.pagination = payload;
  },
  createScoreCard (state, payload) {
    state.scorecards.push(payload);
  },
  editScoreCard (state, payload) {
    state.scorecards = [
      ...state.scorecards.filter(scorecard => scorecard.id !== payload.id), payload,
    ];
  },
  deleteScoreCard (state, id) {
    Vue.delete(state.scorecards, state.scorecards.findIndex(user => user.id === id));
  },
  setImageOnScoreCard (state, payload) {
    let image = payload.image;
    let url = payload.url;
    let key = payload.key;
    let keyValue = payload.keyValue;
    Vue.set(state.scorecard, image, url);
    Vue.set(state.scorecard, key, keyValue);
  },
  editCoursesOnScoreCard (state, payload) {
    let scorecard = state.scorecards.find(scorecard => scorecard.id === payload.id);
    if (scorecard) {
      scorecard.courses = payload.courses;
    }
  },
  editCategoriesOnScoreCard (state, payload) {
    let scorecard = state.scorecards.find(scorecard => scorecard.id === payload.id);
    if (scorecard) {
      scorecard.competitionCategories = payload.competitionCategories;
    }
  },
  editCompetitionsOnScoreCard (state, payload) {
    let scorecard = state.scorecards.find(scorecard => scorecard.id === payload.id);
    if (scorecard) {
      scorecard.competitions = payload.competitions;
    }
  },
  updateScorecards (state, payload) {
    let scorecard = state.scorecards.find(scorecard => scorecard.id === payload.id);
    if (scorecard) {
      scorecard = payload;
    }
  },
  clearScorecard (state, payload) {
    state.scorecard = {
      imageOneUrl: '',
      imageOneKey: '',
      imageTwoUrl: '',
      imageTwoKey: '',
    };
  },
  scorecardsType (state, payload) {
    state.scorecardsType = payload;
  },
};

const getters = {
  scorecard: state => {
    return state.scorecard;
  },
  scorecards: state => {
    return state.scorecards;
  },
  templates: state => {
    return state.templates;
  },
  courses: state => {
    return state.courses;
  },
  competitions: state => {
    return state.competitions;
  },
  categories: state => {
    return state.categories;
  },
  pagination: state => {
    return state.pagination;
  },
  scorecardsType: state => {
    return state.scorecardsType;
  },
};

const actions = {
  fetchScorecards: async (context, params) => {
    if (params.type !== context.getters['scorecardsType']) {
      context.commit('scorecards', []);
    }
    context.dispatch('ui/toggleActiveLoaders', 'scorecard-fetch-scorecards', { root: true });
    let path = `scorecards/type/${params.type}`;

    if (params.search.length > 0) {
      path += '?search=' + params.search;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
      context.dispatch('ui/toggleActiveLoaders', 'scorecard-fetch-scorecards', { root: true });
    }
    context.dispatch('ui/toggleActiveLoaders', 'scorecard-fetch-scorecards', { root: true });

    if(response && response.status === 200) {
      const data = response.data;
      context.commit('scorecards', data.result);
      context.commit('scorecardsType', params.type);
    }
  },
  fetchScoreCard: async (context, params) => {
    const path = `scorecards/${params.id}`;

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if(response && response.status === 200) {
      const data = response.data;
      context.commit('scorecard', data.result);
    }
  },
  fetchTemplates: async (context, params) => {
    let path = 'scorecards/templates';

    if (params && params.scorecardType) {
      path += `/${params.scorecardType}`;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if(response && response.status === 200) {
      const data = response.data;
      context.commit('templates', data.result);
    }
  },
  fetchCourses: async (context, params) => {
    let path = `scorecards/courses`;

    if (params && params.id) {
      path += `?scorecardId=${params.id}`;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if(response && response.status === 200) {
      const data = response.data;
      context.commit('courses', data.result);
    }
  },
  fetchCompetitions: async (context, params) => {
    let path = 'scorecards/competitions';

    if (params && params.id) {
      path += `?scorecardId=${params.id}`;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if(response && response.status === 200) {
      const data = response.data;
      context.commit('competitions', data.result);
    }
  },
  fetchCategories: async (context, params) => {
    let path = 'scorecards/categories';

    if (params && params.id) {
      path += `?scorecardId=${params.id}`;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if(response && response.status === 200) {
      const data = response.data;
      context.commit('categories', data.result);
    }
  },
  createScoreCard: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'scorecard-create-scorecard', { root: true });
    const path = 'scorecards';

    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'scorecard-create-scorecard', { root: true });

    if(response && response.status === 200) {
      const data = response.data;
      context.commit('createScoreCard', data.result);
    }
  },
  editScoreCard: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'scorecard-edit-scorecard', { root: true });
    const path = 'scorecards/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'scorecard-edit-scorecard', { root: true });

    if(response && response.status === 200) {
      const data = response.data;
      // context.commit('editScoreCard', data.result)
      context.commit('updateScorecards', data.result);
    }
  },
  deleteScoreCard: async (context, params) => {
    const path = 'scorecards/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.delete(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if(response && response.status === 200) {
      context.commit('deleteScoreCard', params.id);
    }
  },
  addImage: async (context, params) => {
    const path = 'scorecards/image';
    let formData = new FormData();
    formData.append('image', params.image);

    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if(response && response.status === 200) {
      const data = response.data;
      context.commit('setImageOnScoreCard', {
        image: params.box,
        url: data.result.url,
        key: params.key,
        keyValue: data.result.key,
      });
    }
  },
  editCoursesOnScoreCard: async (context, params) => {
    const path = 'scorecards/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if(response && response.status === 200) {
      const data = response.data;
      context.commit('editCoursesOnScoreCard', data.result);
    }
  },
  editCategoriesOnScoreCard: async (context, params) => {
    const path = 'scorecards/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if(response && response.status === 200) {
      const data = response.data;
      context.commit('editCategoriesOnScoreCard', data.result);
    }
  },
  editCompetitionsOnScoreCard: async (context, params) => {
    const path = 'scorecards/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if(response && response.status === 200) {
      const data = response.data;
      context.commit('editCompetitionsOnScoreCard', data.result);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
