<template>
  <div class="ontag">
    <div class="ontag__content voucher">
      <h1 class="first-letter">{{ $t('voucher') }} {{ $t(`${typeName}`) }}</h1>
      <p v-if="typeName === 'friendly'">Här lägger ni upp er bild för er voucher som ni önskar att ha på sällskapsrundor.</p>
      <p v-else-if="typeName === 'competition'">Här lägger ni upp er bild för er voucher som ni önskar att ha på tävlingar.</p>
      <p v-else>Här lägger ni upp er bild för er voucher som ni önskar att ha på neutral utskrift.</p>
      <div class="voucher__standard">
        <voucher
          v-if="standardVoucher"
          :item="standardVoucher"
          :type="type"
          :type-name="typeName"/>
      </div>
      <hr>
      <div class="voucher-list__actions">
        <div class="search-wrapper">
          <div class="input">
            <label
              for="searchQuery"
              class="input__label label__inline">{{ $t('search') }}</label>
            <input
              id="searchQuery"
              class="input__input input__inline search-input"
              @keyup.enter="getVariatedUserResults"
              v-model="searchQuery"
              type="text"
              name="searchQuery"/>
          </div>
          <base-button
            class="search-button"
            :text="$t('search')"
            :button-size-small="true"
            v-element-loader="{ loaderKey: 'voucher-fetch-vouchers' }"
            @click="getVariatedUserResults" />
        </div>
        <div class="voucher__create">
          <base-button
            :text="$t('createVoucher')"
            @click="$router.push({ name: `create-${typeName}-voucher`, params: { type: type, typeName: typeName }})"
            :button-size-small="true"/>
        </div>
      </div>
      <div class="voucher__list" v-if="vouchers.length > 0">
        <h3 class="first-letter">{{ $t('customizedVouchers') }}</h3>
        <voucher-list-table
          :items="vouchers"
          :type="type"
          :type-name="typeName"
          @fetch="fetch" />
      </div>
    </div>
  </div>
</template>

<script>
import Voucher from '@/components/voucher';
import VoucherListTable from '@/components/voucher-list-table/voucher-list-table';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'VoucherCardList',
  components: {
    Voucher,
    VoucherListTable,
    BaseButton,
  },
  props: {
    type: Number,
    typeName: String,
  },
  directives: {
    elementLoader,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    getVariatedUserResults() {
      this.$store.dispatch('voucher/fetchVouchers', {
        type: this.type,
        search: this.searchQuery.length > 0 ? this.searchQuery : '',
      });
    },
    fetch() {
      this.vouchers = {};
      this.standardVoucher = {};
      this.$store.dispatch('voucher/fetchVouchers', {
        type: this.type,
        search: this.searchQuery.length > 0 ? this.searchQuery : '',
      });
    },
  },
  computed: {
    vouchers: {
      get: function () {
        return this.$store.getters['voucher/vouchers'].filter(voucher => !voucher.standard);
      },
      set: function (val) {
        return val;
      },
    },
    standardVoucher: {
      get: function () {
        return this.$store.getters['voucher/vouchers'].find(voucher => voucher.standard === true);
      },
      set: function (val) {
        return val;
      },
    },
  },
  watch: {
    type: function () {
      this.fetch();
    },
  },
  created () {
    this.fetch();
  },
  beforeDestroy () {
    this.vouchers = {};
  },
};
</script>
<style lang="scss">
  .voucher__standard {
    margin-top: 1rem;
  }
  hr {
    width: 100%;
    border-top: 1px solid color(gray);
  }
  .voucher-list__actions {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-start;
    input {
      margin-right: 1rem;
    }
    .search-wrapper {
      display: flex;
      button {
        margin-left: 0;
      }
    }
    .voucher__create button {
      margin-left: 1rem;
    }
  }
  .voucher__list h3 {
    margin-top: 0;
  }
</style>
