/* Store module to handle courseguides */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  courseguides: [],
  loop: [],
  isLoading: false,
};

const mutations = {
  courseguides (state, payload) {
    state.courseguides = payload;
  },
  isLoading (state, payload) {
    state.isLoading = payload;
  },
};

const getters = {
  courseguides: state => {
    return state.courseguides;
  },
  isLoading: state => {
    return state.isLoading;
  },
};

const actions = {
  get: async (context, params) => {
    let path = 'courseguides';
    if (params && params.clubId && params.loopId) {
      path += '/' + params.clubId + '/' + params.loopId;
    }
    context.commit('isLoading', true);

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    if (response && response.status === 200) {
      const data = response.data;
      context.commit('courseguides', data.result);
    }
    context.commit('isLoading', false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
