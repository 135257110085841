<template>
  <modal-window :show="show">
    <div>
      <div class="modal__header">
        <p>{{ $t('Businessboard.dataImport') }}</p>
      </div>
      <p>{{ importText }}</p>
      <div class="modal__content">
        <div class="input">
          <input
            hidden
            type="file"
            id="file_upload"
            name="file_upload"
            @change="addSelectedFiles" />
          <base-button
            class="input__button"
            :text="$t('Businessboard.fileUpload')"
            @click="chooseFile('file_upload')"
            :button-size-small="true" />
          <p>{{ fileName }}</p>
        </div>
      </div>
      <div class="modal__content--actions">
        <base-button
          class="button--background-gray-dark"
          :text="$t('cancel')"
          @click="toggleModal">
        </base-button>
        <base-button
          :text="$t('Businessboard.import')"
          :disabled="!canSave"
          @click="uploadFile(fileType)"
          v-element-loader="{ loaderKey: 'import-course' }" />
      </div>
    </div>
  </modal-window>
</template>
<script>
import BaseButton from '@/components/form-elements/base-button';
import ModalWindow from '@/components/modal/modal';
import elementLoader from '@/directives/element-loader';
import LocalizationMixin from '../../mixins/localization-mixin.vue';

export default {
  name: 'ModalCourseImport',
  mixins: [LocalizationMixin],
  components: {
    BaseButton,
    ModalWindow,
  },
  directives: {
    elementLoader,
  },
  props: {
    course: {
      type: Object,
      required: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    isGolfBox: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: 'Excel',
    },
  },
  data: function () {
    return {
      fileName: '',
    };
  },
  methods: {
    toggleModal() {
      this.fileName = '';
      this.$emit('toggle-modal');
    },
    addSelectedFiles(event) {
      let file = document.getElementById('file_upload').files[0];
      if (file) {
        this.fileName = file.name;
      }
    },
    canSave() {
      return this.fileName !== '';
    },
    chooseFile(value) {
      document.getElementById('file_upload').click();
    },
    uploadFile(fileFormat) {
      let file = document.getElementById('file_upload').files[0];
      if (file) {
        var postPath = 'businessboard/courseImport' + fileFormat;

        if (this.isGolfBox) {
          postPath = 'businessboard/courseImportGb';
        }
        if (this.fileType === 'Excel' && !this.isGolfBox) {
          this.$store.dispatch(postPath, { file: file }).then(() => {
            this.toggleModal();
            this.$emit('loading', false);
          });
        } else {
          this.$store
            .dispatch(postPath, { file: file })
            .then(() => {
              this.toggleModal();
              this.$emit('loading', false);
            });
        }
      }
    },
  },
  computed: {
    importLabel() {
      if (this.fileFormat !== 'Excel') {
        if (this.course && this.course.name) {
          return this.$t('Businessboard.dataImportLabel', {
            name: this.course.name,
          });
        }
      }

      return this.$t('importText');
    },

    importText() {
      if (this.course) {
        return this.$t('chooseFileToImport') + ' ' + this.course.name;
      } else {
        return this.$t('chooseFileToImportAll');
      }
    },
    settings() {
      return this.$store.getters['businessboard/settings'];
    },
  },
};
</script>
<style lang="scss" scoped>
.saving-spinner {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 5rem 5rem;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
