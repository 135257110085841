<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div class="businessboard--header">
        <span class="h1">
          {{
            id === 'consolidatedaccounts'
              ? $t('Businessboard.totalbusiness')
              : budgetCalculation.name
          }}
        </span>
        <span
          v-if="isLoading"
          class="element--is-loading"></span>
      </div>
      <p
        class="info"
        v-if="id === 'consolidatedaccounts'">
        {{ $t('Company.inputInfo3') }}
      </p>
      <p
        class="info"
        v-else>
        {{ $t('Company.inputInfo4') }}
      </p>
      <div class="last-update-container">
        <last-updated
          :last-update="latestUpdates"
          :show-member="false"
          :show-game="false"
          :show-economy="true" />
      </div>

      <template v-if="storedFilter && !isLoading">
        <modal-filter
          v-if="id === 'consolidatedaccounts'"
          @changeFilter="changeFilter"
          @saveFilter="saveFilter"
          :storedFilter="storedFilter"
          :companies="budgetFilterCompany.companies"
          :financialYears="budgetFilterCompany.financialYears" />
        <modal-filter
          v-else
          @changeFilter="changeFilter"
          @saveFilter="saveFilter"
          :storedFilter="storedFilter"
          :companies="budgetFilterCompany.companies.filter(x => x.value === id)"
          :financialYears="budgetFilterCompany.financialYears" />
      </template>
      <section
        class="businessboard--table"
        v-if="budgetCalculation && budgetCalculation.incomes">
        <list-table
          :isLoading="isLoading"
          :items="budgetCalculation.incomes.rows"
          :headers="budgetCalculation.incomes.columns">
        </list-table>
      </section>
      <section
        class="businessboard--table"
        v-if="budgetCalculation && budgetCalculation.costs">
        <list-table
          :isLoading="isLoading"
          :items="budgetCalculation.costs.rows"
          :headers="budgetCalculation.costs.columns">
        </list-table>
      </section>
      <section
        class="businessboard--table"
        v-if="budgetCalculation && budgetCalculation.deprecations">
        <list-table
          :isLoading="isLoading"
          :items="budgetCalculation.deprecations.rows"
          :headers="budgetCalculation.deprecations.columns">
        </list-table>
      </section>
      <section
        class="businessboard--table"
        v-if="budgetCalculation && budgetCalculation.preDispositionResult">
        <list-table
          :isLoading="isLoading"
          :items="budgetCalculation.preDispositionResult.rows"
          :headers="budgetCalculation.preDispositionResult.columns">
        </list-table>
      </section>
      <section
        class="businessboard--table"
        v-if="budgetCalculation && budgetCalculation.dispositionResult">
        <list-table
          :isLoading="isLoading"
          :items="budgetCalculation.dispositionResult.rows"
          :headers="budgetCalculation.dispositionResult.columns">
        </list-table>
      </section>
      <section
        class="businessboard--table"
        v-if="budgetCalculation && budgetCalculation.result">
        <list-table
          :isLoading="isLoading"
          :items="budgetCalculation.result.rows"
          :headers="budgetCalculation.result.columns">
        </list-table>
      </section>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table-game-calculation';
import ModalFilter from '@/components/modal/modal-filter-budget';
import LastUpdated from '@/components/last-updated/last-updated.vue';

export default {
  name: 'BusinessboardBudgetCalculation',
  components: {
    ListTable,
    ModalFilter,
    LastUpdated,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      isLoading: true,
      storedFilter: false,
    };
  },
  computed: {
    budgetCalculation() {
      return this.$store.getters['businessboardBudget/budgetCalculation'];
    },
    budgetFilterCompany() {
      return this.$store.getters['businessboardBudget/budgetFilterCompany'];
    },
    latestUpdates() {
      return this.$store.getters['businessboard/lastUpdate'];
    },
  },
  mounted() {
    this.$store.dispatch('businessboard/fetchLastUpdate');
    this.$store.dispatch('businessboardBudget/fetchBudgetFilterCompany');
    if (this.id === 'consolidatedaccounts') {
      this.$store.dispatch('businessboardBudget/fetchBudgetFilter').then(() => {
        this.storedFilter = this.$store.state.businessboardBudget.budgetFilter;
        this.fetch();
      });
    } else {
      this.$store
        .dispatch('businessboardBudget/fetchBudgetCompanyFilter', this.id)
        .then(() => {
          this.storedFilter =
            this.$store.state.businessboardBudget.budgetCompanyFilter;
          this.fetch();
        });
    }
  },
  watch: {
    $route: function () {
      this.fetch();
    },
  },
  methods: {
    changeFilter(filter) {
      this.storedFilter = filter;
      this.fetch();
    },
    saveFilter(filter) {
      this.storedFilter = filter;
      if (this.id === 'consolidatedaccounts') {
        this.$store.dispatch(
          'businessboardBudget/postBudgetFilter',
          this.storedFilter
        );
      } else {
        this.$store.dispatch('businessboardBudget/postBudgetCompanyFilter', {
          id: this.id,
          ...this.storedFilter,
        });
      }
      this.fetch();
    },
    fetch() {
      this.isLoading = true;
      this.$store
        .dispatch('businessboardBudget/fetchBudgetCalculation', {
          id: this.id !== 'consolidatedaccounts' ? this.id : '',
          ...this.storedFilter,
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.businessboard--header {
  height: 3rem;
  margin: 1rem 0;
}
.h1 {
  display: inline-block;
  font-size: font(h1);
  font-weight: bold;
  vertical-align: top;
}
.element--is-loading {
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.businessboard {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.businessboard--table {
  margin-bottom: 2rem;
}

.last-update-container {
  margin-bottom: 1rem;
}
</style>
