<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <div class="businessboard--header">
        <router-link to="/businessboard/data">
          <span class="back_button">{{ $t('Businessboard.backToData') }}</span>
        </router-link>
        <h1>
          {{ companyName }}
          <router-link :to="'/businessboard/create-company/' + id">
            <use-svg
              svg="edit-icon"
              class="title--icon" />
          </router-link>
        </h1>
        <h2>Data</h2>
        <template v-if="history.rows && history.rows.length > 0">
          <p>
            {{ $t('Businessboard.historyLastUploadedAt') }}:
            {{ history.rows[0].uploadedAtString }}
          </p>
          <p>
            {{ $t('Businessboard.historyLastFileName') }}:
            {{ history.rows[0].fileName }}
          </p>
          <p @click="toggleShowHistory">
            <span class="show_history">{{ $t('Businessboard.history') }}</span>
            <use-svg
              svg="arrow-right"
              :class="{ 'svg--180-negative': showHistory }" />
          </p>
          <template v-if="showHistory">
            <list-table
              :items="history.rows"
              :headers="history.columns">
            </list-table>
          </template>
        </template>
        <div>
          <p>
            <b>
              {{
                importTimeout
                  ? $t('Businessboard.importLocked', {
                      time: cookieTimeout,
                    })
                  : ''
              }}
            </b>
          </p>

          <p v-if="importTimeout && !showVerify">
            <b>
              {{ $t('Businessboard.importLockedInfo') }}
            </b>
          </p>
          <base-button
            :text="$t('Businessboard.dataImport')"
            @click="toggleModal"
            :disabled="importTimeout || showVerify">
          </base-button>
          <br />
          <p>
            {{ $t('Company.outcomeImport') }}
          </p>
          <base-button
            v-if="showApiImport"
            :text="$t('Businessboard.fetchFromApi')"
            @click="toggleShowApiImport()"
            :disabled="importTimeout || showVerify">
          </base-button>
        </div>
        <hr />
        <div v-if="showPremiumContent">
          <h2>Budget</h2>
          <template v-if="budgetHistory.rows && budgetHistory.rows.length > 0">
            <p>
              {{ $t('Businessboard.historyLastUploadedAt') }}:
              {{ budgetHistory.rows[0].uploadedAtString }}
            </p>
            <p>
              {{ $t('Businessboard.historyLastFileName') }}:
              {{ budgetHistory.rows[0].fileName }}
            </p>
            <p @click="toggleShowBudgetHistory">
              <span class="show_history">{{
                $t('Businessboard.history')
              }}</span>
              <use-svg
                svg="arrow-right"
                :class="{ 'svg--180-negative': showHistory }" />
            </p>
            <template v-if="showBudgetHistory">
              <list-table
                :items="budgetHistory.rows"
                :headers="budgetHistory.columns">
              </list-table>
            </template>
          </template>
          <div class="input-group--inline import__actions">
            <div>
              <base-button
                :disabled="importTimeout || showVerify"
                :text="'Budgetimport'"
                @click="toggleShowBudgetImport">
              </base-button>
              <p>
                {{ $t('Company.budgetImport') }}
                <template v-if="showPremiumBudgetContent">
                  <br />{{ $t('Company.budgetInfo') }}
                </template>
              </p>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="businessboard--accounts">
        <h2>{{ $t('Businessboard.accountconnections') }}</h2>
        {{ $t('Company.accountInfo') }}
        <p
          @click="toggleShowResultAccounts"
          class="show_resultAccounts">
          <span class="show_resultAccounts_label">{{
            $t('Businessboard.resultAccounts')
          }}</span>
          <use-svg
            svg="arrow-right"
            :class="{ 'svg--180-negative': showResultAccounts }" />
        </p>
        <template v-if="showResultAccounts">
          <div>
            <div class="input-group input--flex">
              <label
                for="hideWithoutTransactions"
                class="input__label first-letter">
                {{ $t('hideWithoutTransactions') }}
              </label>
              <toggle-switch
                id="hideWithoutTransactions"
                name="hideWithoutTransactions"
                input-id="hideWithoutTransactions"
                type="switch"
                :toggled="hideWithoutTransactions"
                @toggle="toggleHideWithoutTransactions" />
            </div>
            <template
              v-if="
                accountConnections.notConnectedAccounts &&
                accountConnections.notConnectedAccounts.rows.length > 0
              ">
              <p class="not_connected">
                {{ $t('Businessboard.accountnotconnected') }}
                <template v-if="checkedItemsL.length > 0">
                  <span style="margin-left: 3rem">{{
                    $t('Company.connectAccounts')
                  }}</span>
                  <select v-model="selectedBatchGroup">
                    <option
                      v-for="option in checkedItemsL.length > 0 &&
                      (accountConnections.notConnectedAccounts.rows.find(
                        i => i.id === checkedItemsL[0]
                      ).costAccount
                        ? selectOptions.costGroups
                        : selectOptions.incomeGroups)"
                      :value="option.value"
                      :key="option.value"
                      :selected="false">
                      {{ option.displayName }}
                    </option>
                  </select>
                  <button
                    class="button"
                    @click="selectBatchEvent">
                    {{ $t('Company.perform') }}
                  </button>
                </template>
              </p>
              <list-table
                @variationResults="getVariatedResults"
                @selectEvent="selectEvent"
                :isSelectable="true"
                :items="accountConnections.notConnectedAccounts.rows"
                :headers="accountConnections.notConnectedAccounts.columns">
              </list-table>
            </template>
            <template v-if="accountConnections.connectedAccounts">
              <list-table
                @variationResults="getVariatedResults"
                @selectEvent="selectEvent"
                :items="accountConnections.connectedAccounts.rows"
                :headers="accountConnections.connectedAccounts.columns">
              </list-table>
            </template>
          </div>
        </template>

        <modal-window :show="showModal">
          <div class="modal__header">
            <p>{{ $t('Businessboard.dataImport') }}</p>
          </div>
          <p>{{ $t('Company.importSie4') }}</p>
          <div class="modal__content">
            <div class="input">
              <span>{{
                $t('Businessboard.dataImportLabel', { name: companyName })
              }}</span>
              <input
                hidden
                type="file"
                id="file_upload"
                name="file_upload"
                @change="addSelectedFiles" />
              <base-button
                class="input__button"
                :text="$t('Businessboard.fileUpload')"
                @click="chooseFile('file_upload')"
                :button-size-small="true" />
              <p>{{ fileName }}</p>
            </div>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="toggleModal">
            </base-button>
            <base-button
              :text="$t('Businessboard.import')"
              :disabled="!canSave"
              @click="uploadFile"
              v-element-loader="{ loaderKey: 'import-company' }" />
          </div>
        </modal-window>
        <modal-window :show="showNextModal || showNextApiModal">
          <div class="modal__header">
            <br />
            <p>
              {{ $t('choosePeriodToImport') }}
            </p>
          </div>
          <div class="modal__content">
            <div class="input">
              <custom-select
                class="input__select"
                id="month"
                @optionSelected="e => selectMonth(e, index)"
                :options="months"
                v-model="selectedMonth">
              </custom-select>
            </div>
            <span>
              {{ $t('periodImportInfo') }}
            </span>
          </div>

          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="toggleNextModal()">
            </base-button>
            <base-button
              v-if="showNextModal"
              :text="$t('Businessboard.import')"
              @click="uploadFile"
              v-element-loader="{ loaderKey: 'import-company' }" />
          </div>
        </modal-window>
        <modal-window :show="showImport">
          <div class="modal__header">
            <p>{{ $t('Businessboard.dataImport') }}</p>
          </div>
          <p>{{ $t('Company.inputInfo') }}</p>
          <div class="modal__content">
            <div class="input">
              <div class="input">
                <label for="role">{{ $t('fiscalYear') }}</label>
                <custom-select
                  class="input__select"
                  id="role"
                  @optionSelected="e => selectOption(e, index)"
                  :required="false"
                  :options="financialYears">
                </custom-select>
              </div>
              <p></p>
              <div
                class="input"
                v-if="isEndDateCurrentOrPreviousYear">
                <p>
                  {{ $t('choosePeriodToImport') }}
                  <em>({{ $t('optional').toLowerCase() }})</em>
                </p>
                <label for="month">{{ $t('month') }}</label>
                <custom-select
                  class="input__select"
                  id="month"
                  @optionSelected="e => selectMonth(e, index)"
                  :options="months"
                  v-model="selectedMonth">
                </custom-select>
              </div>
            </div>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="toggleShowImport">
            </base-button>
            <base-button
              :text="$t('Businessboard.import')"
              :disabled="!canImport"
              @click="importData"
              v-element-loader="{ loaderKey: 'import-company' }" />
          </div>
        </modal-window>
        <modal-window :show="showBudgetImportModal">
          <div class="modal__header">
            <p>{{ $t('Businessboard.budgetImport') }}</p>
          </div>
          <p>{{ $t('Company.inputInfo2') }}</p>
          <div class="modal__content">
            <div class="input">
              <span>{{
                $t('Businessboard.budgetImportLabel', { name: companyName })
              }}</span>
              <input
                hidden
                type="file"
                id="file_upload"
                name="file_upload"
                @change="addSelectedFiles" />
              <base-button
                class="input__button"
                :text="$t('Businessboard.fileUpload')"
                @click="chooseFile('file_upload')"
                :button-size-small="true" />
              <p>{{ fileName }}</p>
            </div>
          </div>
          <div class="modal__content--actions">
            <base-button
              class="button--background-gray-dark"
              :text="$t('cancel')"
              @click="toggleShowBudgetImport">
            </base-button>
            <base-button
              :text="$t('Businessboard.import')"
              :disabled="!canSave"
              @click="uploadBudget"
              v-element-loader="{ loaderKey: 'import-company' }" />
          </div>
        </modal-window>
        <section class="terminal--actions sticky sticky__bottom">
          <base-button
            v-if="showRefresh"
            :text="$t('Refresh')"
            v-element-loader="{ loaderKey: 'refresh-accounts' }"
            @click="refresh" />
        </section>
      </div>
      <div
        v-if="showVerify"
        class="loading-verification">
        <span class="loading-text">
          {{ $t('Businessboard.importToast') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/list-table/list-table-businessboard';
import BaseButton from '@/components/form-elements/base-button';
import ToggleSwitch from '@/components/form-elements/switch';
import elementLoader from '@/directives/element-loader';
import ModalWindow from '@/components/modal/modal';
import UseSvg from '@/components/use-svg';
import CustomSelect from '@/components/form-elements/custom-select';

export default {
  name: 'CreateCompany',
  components: {
    BaseButton,
    ToggleSwitch,
    ListTable,
    ModalWindow,
    UseSvg,
    CustomSelect,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
    showImport: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      companyName: '',
      showApiImport: false,
      financialDataSource: 0,
      showModal: false,
      showHistory: false,
      showBudgetHistory: false,
      showResultAccounts: true,
      showVerify: false,
      fileName: '',
      showRefresh: false,
      hideWithoutTransactions: true,
      financialYear: '',
      showPremiumContent: false,
      showPremiumBudgetContent: false,
      showBudgetImportModal: false,
      selectedBatchGroup: '',
      uploadedFile: null,
      showNextModal: false,
      showNextApiModal: false,
      selectedMonth: null,
      financialYearMonthRange: '',
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      this.fileName = '';
    },
    toggleNextModal() {
      this.showNextModal = !this.showNextModal;
      this.fileName = '';
    },
    toggleShowImport() {
      this.showImport = !this.showImport;
      this.financialYear = '';
    },
    toggleShowBudgetImport() {
      this.showBudgetImportModal = !this.showBudgetImportModal;
      this.fileName = '';
    },
    selectMonth(e) {
      this.selectedMonth = e.value;
    },
    onFileChange(event) {
      this.uploadedFile = event.target.files[0];
    },
    setImportTimeout() {
      var timeout = 60 * 3 * 1000;
      var expirationTime = this.getTimeoutFormat(timeout);
      const url = window.location.href;
      const [lastPart] = url.split('/').reverse();
      const cookieValue = `${lastPart} ${expirationTime}`;
      this.$cookies.set(lastPart, cookieValue, '3min');
    },
    toggleShowApiImport() {
      location.href = this.getFinancialDataUrl() + this.id;
    },
    getTimeoutFormat(timeout) {
      var date = new Date().getTime() + timeout;
      var expirationDate = new Date(date);
      var hours = expirationDate.getHours().toString().padStart(2, '0');
      var minutes = expirationDate.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    getFinancialDataUrl() {
      if (this.financialDataSource === 1) {
        return process.env.VUE_APP_FORTNOX_AUTH_URL;
      } else if (this.financialDataSource === 2) {
        return process.env.VUE_APP_VISMA_AUTH_URL;
      } else {
        return '';
      }
    },
    toggleShowHistory() {
      this.showHistory = !this.showHistory;
    },
    toggleShowBudgetHistory() {
      this.showBudgetHistory = !this.showBudgetHistory;
    },
    toggleShowResultAccounts() {
      this.showResultAccounts = !this.showResultAccounts;
    },
    toggleHideWithoutTransactions() {
      this.hideWithoutTransactions = !this.hideWithoutTransactions;
      this.refresh();
    },
    parseEndDate(displayName) {
      // temp fix, should change backend model to the end year
      var dates = displayName.split('/');

      if (dates.length === 2) {
        let endDateString = dates[1];
        let endDate = new Date(endDateString);

        if (!isNaN(endDate)) {
          return endDate;
        }
      }
    },
    chooseFile(value) {
      document.getElementById('file_upload').click();
    },
    addSelectedFiles(event) {
      let file = document.getElementById('file_upload').files[0];
      if (file) {
        this.fileName = file.name;
        this.uploadedFile = file;
      }
    },
    async uploadFile() {
      var file = this.uploadedFile;
      if (!file) return;

      if (this.showModal) {
        this.toggleModal();
      } else if (this.showBudgetImportModal) {
        this.toggleShowBudgetImport();
      }

      this.showVerify = true;

      const payload = {
        file,
        id: this.id,
      };

      if (this.showNextModal) {
        this.toggleNextModal();
        payload.month = this.selectedMonth;
      }
      this.setImportTimeout();
      const response = await this.$store.dispatch(
        'businessboard/companyImport',
        payload
      );

      if (response) {
        // custom resultCode that means the user should be prompted to select a month
        if (response.resultCode === 230) {
          this.financialYearMonthRange = response.financialYearMonthRange;
          this.selectedMonth = this.financialYearMonthRange.split(';')[1];
          this.showNextModal = true;
          this.clearCookie();
        } else if (response.success) {
          this.setImportTimeout();
          await this.fetchCompanyData();
        } else {
          this.clearCookie();
        }
      }

      this.showVerify = false;
    },

    async fetchCompanyData() {
      await this.$store.dispatch(
        'businessboard/fetchCompanyAccountConnections',
        {
          id: this.id,
          'Filter.HideWithoutTransactions': this.hideWithoutTransactions,
        }
      );

      await this.$store.dispatch('businessboard/fetchCompanyHistory', {
        id: this.id,
      });
    },
    async uploadBudget() {
      let file = document.getElementById('file_upload').files[0];
      if (file) {
        if (this.showModal === true) {
          this.toggleModal();
        } else if (this.showBudgetImportModal === true) {
          this.toggleShowBudgetImport();
        }
        this.showVerify = true;
        this.setImportTimeout();
        var success = await this.$store.dispatch('businessboard/budgetImport', {
          file,
          id: this.id,
        });
        if (success) {
          this.setImportTimeout();
          await this.$store.dispatch(
            'businessboard/fetchCompanyBudgetHistory',
            { id: this.id }
          );
          await this.$store.dispatch(
            'businessboard/fetchCompanyAccountConnections',
            {
              id: this.id,
              'Filter.HideWithoutTransactions': this.hideWithoutTransactions,
            }
          );
          await this.$store.dispatch('businessboard/fetchCompanyHistory', {
            id: this.id,
          });
        } else {
          this.clearCookie();
        }
      }
      this.showVerify = false;
    },
    clearCookie() {
      this.$cookies.remove(this.companyId);
    },
    async importData() {
      let model = {};
      model.accessToken = this.code;
      model.financialYearId = this.financialYear;
      this.showImport = false;
      this.showVerify = true;

      const payload = {
        model: model,
        id: this.id,
      };

      var endDate = this.parseEndDate(
        this.financialYears.find(g => g.value === this.financialYear)
          .displayName
      );

      const endMonth = new Date(endDate).getMonth() + 1;

      if (!this.selectedMonth || this.selectedMonth === 0) {
        payload.month = endMonth;
      } else {
        payload.month = this.selectedMonth;
      }

      this.setImportTimeout();
      const response = await this.$store.dispatch(
        'businessboard/importFromApi',
        payload
      );
      if (response.success) {
        await this.$store.dispatch(
          'businessboard/fetchCompanyAccountConnections',
          {
            id: this.id,
            'Filter.HideWithoutTransactions': this.hideWithoutTransactions,
          }
        );
        await this.$store.dispatch('businessboard/fetchCompanyHistory', {
          id: this.id,
        });
      } else {
        this.clearCookie();
      }
      this.showVerify = false;
    },
    selectEvent(account) {
      this.$store
        .dispatch('businessboard/patchCompanyAccountConnections', account)
        .then(() => {
          this.showRefresh = true;
        });
    },
    formatDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based in JavaScript
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    selectBatchEvent() {
      Promise.all(
        this.checkedItemsL.map(element => {
          let account = this.accountConnections.notConnectedAccounts.rows.find(
            a => a.id === element
          );
          account.resultGroupId = this.selectedBatchGroup;
          return this.$store.dispatch(
            'businessboard/patchCompanyAccountConnections',
            account
          );
        })
      ).then(() => {
        this.$store.commit('businessboard/listTableCheckedItems', []);
        this.selectedBatchGroup = '';
        this.refresh();
      });
    },
    getVariatedResults(value) {
      let cost;
      if (value.filter) {
        let checked = value.filter.options.filter(f => f.checked);
        if (checked.length === 1) {
          cost = checked[0].value === 'true';
          this.$store.dispatch('businessboard/fetchCompanyAccountConnections', {
            id: this.id,
            'Filter.CostAccount': cost,
            'Filter.HideWithoutTransactions': this.hideWithoutTransactions,
          });
        } else {
          this.$store.dispatch('businessboard/fetchCompanyAccountConnections', {
            id: this.id,
            'Filter.HideWithoutTransactions': this.hideWithoutTransactions,
          });
        }
      }
    },
    refresh() {
      this.$store.dispatch('ui/toggleActiveLoaders', 'refresh-accounts', {
        root: true,
      });

      this.$store
        .dispatch('businessboard/fetchCompanyAccountConnections', {
          id: this.id,
          'Filter.HideWithoutTransactions': this.hideWithoutTransactions,
        })
        .then(() => {
          this.$store.dispatch('ui/toggleActiveLoaders', 'refresh-accounts', {
            root: true,
          });
          this.showRefresh = false;
        });
    },
    selectOption(e) {
      this.financialYearMonthRange = e.displayName.replace('/', ';');
      this.financialYear = e.value;
    },
    selectedIndex() {
      return this.financialYears.findIndex(g => g.value === this.financialYear);
    },
    checkedItems(e) {
      this.checkedItemsL = e;
    },

    async fetchFinancialData() {
      await this.$store.dispatch(
        'businessboard/fetchCompanyAccountConnections',
        {
          id: this.id,
          'Filter.HideWithoutTransactions': this.hideWithoutTransactions,
        }
      );
      await this.$store.dispatch('businessboard/fetchCompanyHistory', {
        id: this.id,
      });
      await this.$store.dispatch('businessboard/fetchCompanyBudgetHistory', {
        id: this.id,
      });
      await this.$store.dispatch('businessboard/fetchResultgroupSelect');
      if (this.code && this.showImport) {
        await this.$store.dispatch('businessboard/fetchFinancialYearSelect', {
          id: this.id,
          model: { accessToken: this.code },
        });
      }
      const company = this.$store.state.businessboard.data.companies.rows.find(
        r => r.id === this.id
      );
      this.companyName = company.companyName;
      this.showApiImport = company.apiImportActive;
      this.showPremiumContent = company.showPremiumContent;
      this.showPremiumBudgetContent = company.showPremiumBudgetContent;
      this.financialDataSource = company.financialDataSource;
      this.showBudgetImport = company.showBudgetImport;
    },
  },
  computed: {
    accountConnections() {
      return this.$store.getters['businessboard/companyaccountconnections'];
    },
    resultgroupselect() {
      return this.$store.getters['businessboard/resultgroupselect'];
    },
    history() {
      return this.$store.getters['businessboard/companyhistory'];
    },
    budgetHistory() {
      return this.$store.getters['businessboard/companybudgethistory'];
    },
    isEndDateCurrentOrPreviousYear() {
      var year = this.financialYears.find(
        item => item.value === this.financialYear
      );
      if (!year) return false;
      var endDate = this.parseEndDate(year.displayName);
      if (endDate) {
        const endDateYear = new Date(endDate).getFullYear();
        const currentYear = new Date().getFullYear();
        return endDateYear === currentYear || endDateYear === currentYear - 1;
      }
      return false;
    },
    // financialYears() {
    //   // for testing in dev
    //   const data = [
    //     { value: '1336', displayName: '2023-01-01/2024-05-05' },
    //     { value: '2124', displayName: '2022-05-05/2022-05-06' },
    //   ];
    //   return data;
    // },
    canCreate() {
      return false;
    },
    canSave() {
      return this.fileName !== '';
    },
    canImport() {
      return this.financialYear !== '';
    },
    financialYears() {
      return this.$store.getters['businessboard/financialyearselect'];
    },
    selectOptions() {
      return this.$store.state.businessboard.resultgroupselect;
    },
    checkedItemsL() {
      return this.$store.state.businessboard.listTableCheckedItems;
    },
    companyId() {
      const url = window.location.href;
      const [lastPart] = url.split('/').reverse();
      return lastPart;
    },
    importTimeout() {
      // check if cookie exists for current company
      const key = this.companyId;
      const isKey = this.$cookies.isKey(key);
      if (!isKey) {
        return false;
      }
      const cookieValue = this.$cookies.get(key);
      const [cookieCompanyId] = cookieValue.split(' ');

      if (this.companyId === cookieCompanyId) {
        return true;
      } else {
        return false;
      }
    },
    cookieTimeout() {
      const cookieValue = this.$cookies.get(this.companyId);
      const [expirationTime] = cookieValue.split(' ').reverse();
      return decodeURIComponent(expirationTime);
    },
    months() {
      if (this.financialYearMonthRange === '') return;

      let split = this.financialYearMonthRange.split(';');
      let fromYear = parseInt(split[0].split('-')[0]);
      let fromMonth = parseInt(split[0].split('-')[1]);
      let toYear = parseInt(split[1].split('-')[0]);
      let toMonth = parseInt(split[1].split('-')[1]);

      let list = [];
      while (
        fromYear < toYear ||
        (fromYear === toYear && fromMonth <= toMonth)
      ) {
        list.push({
          value: fromYear + '-' + fromMonth,
          displayName: fromYear + ' ' + this.$t('Months.' + fromMonth),
        });
        fromMonth += 1;
        if (fromMonth === 13) {
          fromYear += 1;
          fromMonth = 1;
        }
      }
      return list;
    },
  },
  created() {
    if (!this.$store.state.businessboard.data.companies) {
      this.$store.dispatch('businessboard/fetchData', {}).then(() => {
        this.fetchFinancialData();
      });
    } else {
      this.fetchFinancialData();
    }
  },
};
</script>
<style lang="scss">
.loading-verification {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Ccircle fill='none' stroke='%23517FD0' stroke-width='6' stroke-dasharray='45,40' cx='25' cy='25' r='15'/%3E%3CanimateTransform attributeName='transform' dur='1s' type='rotate' from='0 0 0 ' to='360 0 0' repeatCount='indefinite'/%3E%3C/svg%3E");
  background-size: 5rem 5rem;
  background-repeat: no-repeat;
  background-position: center center;
  .loading-text {
    font-size: font(h2);
    color: color(white);
  }
}
.businessboard {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  .businessboard--accounts td,
  th {
    white-space: nowrap;
  }
  .businessboard--accounts tr th:first-child {
    width: 100%;
  }
  .not_connected {
    background-color: color(bb-sec3);
    color: white;
    padding: 1rem;
    margin: 0;
  }
  .show_history {
    text-decoration: underline;
    margin-right: 0.2rem;
    cursor: pointer;
  }
  .show_resultAccounts {
    font-size: font(h3);
    margin-right: 0.2rem;
    cursor: pointer;
    display: flex;
    .show_resultAccounts_label {
      flex: 1;
    }
  }
  .title--icon {
    height: 20px;
    width: 20px;
  }
}
.businessboard--header {
  margin: 1rem;
}
.import__actions {
  justify-content: flex-start;
  button {
    margin-right: 1.25rem;
  }
}
.back_button::before {
  content: '<<';
}
.input__button {
  margin: auto;
  margin-top: 1rem;
}
.input__select {
  min-width: 230px;
}
.refresh {
  @include button;
}
.spin {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.modal__content {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  align-items: center;
  margin: auto;
}
</style>
