<template>
  <div class="course-guide-groups-list">
    <te-collapsible
      v-for="group in localGroups"
      :key="group.id"
      :title="group.name"
      titleIcon="golf-specific-icons-drag-handle cursor-move"
      class="course-guide-groups-list-group"
      draggable="true"
      v-on:dragstart.native="startDrag($event, group)"
      v-on:dragend.native="endDrag($event)">
      <div class="course-guide-groups-list-group-items">
        <div
          v-for="hole in group.holes"
          :key="hole.id"
          draggable="true"
          @dragstart="startDragHole($event, hole, group)"
          @dragend="endDrag($event)"
          class="course-guide-groups-list-group-item cursor-move">
          <div class="course-guide-groups-list-group-item-title">
            <span
              class="golf-specific-icons-drag-handle"
              style="font-size: 15px"></span>
            {{ hole.holeNumber }}
          </div>
          <div class="course-guide-groups-list-group-item-description">
            <div
              class="loop"
              v-if="holeMap[hole.id]">
              Loop hole: {{ holeMap[hole.id] }}
            </div>
            <div class="par">Par: {{ hole.par }}</div>
          </div>
        </div>
      </div>
    </te-collapsible>
  </div>
</template>

<script>
import TeCollapsible from './components/te-collapsible.vue';
export default {
  name: 'CourseGuidesGroupsList',
  components: {
    TeCollapsible,
  },
  emits: ['courseDataDragChanged'],
  watch: {
    groups: {
      handler: function (newVal, oldVal) {
        this.localGroups = newVal;
        this.holeMap = this.createHoleMap(this.localGroups, this.localLoop);
        this.$forceUpdate();
      },
      deep: true,
    },
    loop: {
      handler: function (newVal, oldVal) {
        this.localLoop = newVal;
        this.holeMap = this.createHoleMap(this.localGroups, this.localLoop);
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    loop: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      holeMap: this.createHoleMap(this.groups, this.loop),
      localLoop: this.loop,
      localGroups: this.groups,
    };
  },
  methods: {
    createHoleMap(groups, loop) {
      if (!groups) {
        return {};
      }
      let m = {};
      for (let g of groups) {
        for (let h of g.holes) {
          m[h.id] = this.getLoopHole(loop, h);
        }
      }
      return m;
    },
    getLoopHole(loop, hole) {
      if (!loop || !loop.holes) {
        return null;
      }
      let l = loop.holes.find(loop => loop.physicalHoleId === hole.id);

      if (l) {
        return l.holeNumber;
      }
      return null;
    },
    startDrag(evt, group) {
      evt.stopPropagation();
      evt.dataTransfer.effectAllowed = 'link';
      evt.dataTransfer.setData('group', JSON.stringify(group));
      this.$emit('courseDataDragChanged', {
        type: 'group',
        group: group,
      });
    },
    startDragHole(evt, hole, group) {
      evt.stopPropagation();
      evt.dataTransfer.effectAllowed = 'link';
      evt.dataTransfer.setData('hole', JSON.stringify(hole));
      evt.dataTransfer.setData('group', JSON.stringify(group));
      this.$emit('courseDataDragChanged', {
        type: 'hole',
        hole: hole,
        group: group,
      });
    },
    endDrag(evt) {
      this.holeMap = this.createHoleMap(this.localGroups, this.localLoop);
      this.$emit('courseDataDragChanged', null);
    },
  },
  created() {},
};
</script>

<style lang="scss">
.cursor-move {
  cursor: move;
}
.course-guide-groups-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .course-guide-groups-list-group {
    background: var(--Surface-Surface-Primary, #fff);
    .course-guide-groups-list-group-items {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;
      .course-guide-groups-list-group-item {
        user-select: none;
        overflow: hidden;
        display: flex;
        padding: 8px var(--Paddings-Padding-S, 8px);
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--dividers-borders-divider-primary, #cbcccd);
        background: var(--Surface-Surface-Primary, #fff);
        flex-direction: row;
        color: var(--Default-Black, #000);
        font-family: 'Tietoevry Sans 1';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        justify-content: space-between;
        .course-guide-groups-list-group-item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          text-align: left;
          margin-right: 20px;
          gap: 2px;
        }
        .course-guide-groups-list-group-item-description {
          display: flex;
          text-align: right;
          flex-direction: row;
          flex: 1;
          gap: 8px;
          color: var(--Gray-Gray-1, #64676a);
          .loop {
            justify-self: flex-start;
            flex: 2;
            text-align: left;
          }
          .par {
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
