<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <div v-if="confirmedCreatedUser">
        <h1>{{ $t('GolfOffice.userCreated') }}</h1>
        <router-link to="/users">
          <base-button text="Go to users"> </base-button>
        </router-link>
      </div>
      <div v-else>
        <h1>{{ $t('GolfOffice.createUser') }}</h1>
        <p>
          Här väljer ni först en roll, det finns lite olika alternativ med olika
          behörigheter. Sedan fyller ni i Förnamn, efternamn och e-mail. Ni kan
          även sätta datum för hur länge en användare skall vara aktiv.
        </p>
        <form
          noValidate
          @submit.prevent="createUser">
          <div class="content__input-group">
            <div class="input-group">
              <template v-for="(product, key, index) in golfOfficeRoles">
                <custom-select
                  :label="$t('GolfOffice.chooseRole') + ' ' + key"
                  :id="'role_' + key"
                  @optionSelected="e => selectedGolfOfficeRole(e, key)"
                  :options="product"
                  :key="index"
                  :required="false">
                </custom-select>
              </template>
              <div
                class="input-group"
                v-if="showRadioButtons">
                <div class="input">
                  <input
                    class="radio__input"
                    id="withEmail"
                    v-model="emailOption"
                    @change="emailOptionChange"
                    type="radio"
                    value="withEmail" />
                  <label
                    for="withEmail"
                    class="radio__label">
                    <span> <span></span> </span>{{ $t('GolfOffice.withEmail') }}</label>
                </div>
                <div class="input">
                  <input
                    class="radio__input"
                    id="withoutEmail"
                    v-model="emailOption"
                    @change="emailOptionChange"
                    type="radio"
                    value="withoutEmail" />
                  <label
                    for="withoutEmail"
                    class="radio__label">
                    <span>
                      <span></span>
                    </span>
                    {{ $t('GolfOffice.withoutEmail') }}</label>
                </div>
              </div>
              <template v-if="emailOption != ''">
                <div class="input-group">
                  <template v-if="showClubs">
                    <div
                      class="input"
                      v-if="user.club === undefined && clubs">
                      <label
                        for="golfclub-select"
                        class="input__label">{{ $t('clubName') }}</label>
                      <input
                        required="true"
                        type="text"
                        list="clubs"
                        v-if="user.club === undefined && clubs"
                        name="golfclub"
                        id="golfclub-select"
                        class="input__input"
                        v-model="clubName"
                        @change="selectedClub" />
                      <datalist id="clubs">
                        <option
                          v-for="club in clubs"
                          :key="club.value">
                          {{ `${club.displayName}` }}
                        </option>
                      </datalist>
                    </div>
                  </template>
                  <template v-for="(product, key, index) in roles">
                    <custom-select
                      :label="$t('GolfOffice.chooseRole') + ' ' + key"
                      :id="'role_' + key"
                      @optionSelected="e => selectedRole(e, key)"
                      :options="product"
                      :key="index"
                      :required="false">
                    </custom-select>
                  </template>
                  <div class="input">
                    <label
                      for="firstName"
                      class="input__label">{{ $t('firstName') }}*</label>
                    <input
                      id="firstName"
                      class="input__input"
                      v-model="firstName"
                      type="text"
                      required="true"
                      name="firstName" />
                  </div>
                  <div class="input">
                    <label
                      for="lastName"
                      class="input__label">{{ $t('lastName') }}*</label>
                    <input
                      id="lastName"
                      class="input__input"
                      v-model="lastName"
                      type="text"
                      required="true"
                      name="lastName" />
                  </div>
                  <div
                    class="input"
                    v-if="emailOption === 'withEmail'">
                    <label
                      for="email"
                      class="input__label">{{ $t('email') }}*</label>
                    <input
                      id="email"
                      class="input__input"
                      v-model="email"
                      type="email"
                      required="true"
                      name="Email"
                      @input="checkEmailValidity" />
                    <div
                      v-if="failedEmailValidiation && this.email"
                      class="input__error--message">
                      {{ $t('GolfOffice.emailMustInclude') }}
                    </div>
                  </div>
                  <div
                    class="input"
                    v-if="emailOption === 'withoutEmail'">
                    <label
                      for="userName"
                      class="input__label">{{ $t('username') }}*</label>
                    <input
                      id="userName"
                      class="input__input"
                      v-model="username"
                      type="text"
                      required="true"
                      name="userName" />
                  </div>
                  <div
                    class="input"
                    v-if="emailOption === 'withoutEmail'">
                    <label
                      for="password"
                      class="input__label">{{ $t('password') }}*</label>
                    <input
                      id="password"
                      class="input__input"
                      v-model="password"
                      type="password"
                      required="true"
                      name="password" />
                  </div>
                  <div
                    class="input"
                    :class="`${hasPasswordError ? 'input__error' : ''}`"
                    v-if="emailOption === 'withoutEmail'">
                    <label
                      for="repeatedPassword"
                      class="input__label">{{ $t('confirmPassword') }}*</label>
                    <input
                      id="repeatedPassword"
                      class="input__input"
                      v-model="repeatedPassword"
                      type="password"
                      required="true"
                      name="repeatedPassword"
                      @input="checkIfPasswordsMatch" />
                    <div
                      v-if="hasPasswordError"
                      class="input__error--message">
                      {{ $t('passwordDontMatch') }}
                    </div>
                  </div>
                  <div class="input">
                    <label
                      for="activeTo"
                      class="input__label">{{ $t('GolfOffice.activeUntil') }}</label>
                    <input
                      id="activeTo"
                      class="input__input"
                      v-model="activeTo"
                      type="date"
                      required="false"
                      name="activeTo" />
                  </div>
                  <div v-if="showBusinessBoardResultUnits">
                    <div
                      v-for="company in businessBoardResultUnits"
                      :key="company.id">
                      <h3>{{ company.name }}</h3>
                      <div
                        v-for="resultunit in company.resultUnits"
                        :key="resultunit.id">
                        <input
                          type="checkbox"
                          :value="resultunit.id"
                          v-model="selectedResultUnits" />
                        {{ resultunit.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="input-group--inline user__actions">
              <router-link to="/users">
                <base-button :text="$t('cancel')"> </base-button>
              </router-link>
              <base-button
                :text="$t('GolfOffice.createUser')"
                type="submit"
                v-element-loader="{ loaderKey: 'create-user' }"
                :disabled="!canCreate" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/form-elements/custom-select';
import BaseButton from '@/components/form-elements/base-button';
import { checkIfEmptyObject } from '@/core/helpers.js';
import elementLoader from '@/directives/element-loader';

export default {
  name: 'CreateUser',
  components: {
    CustomSelect,
    BaseButton,
  },
  directives: {
    elementLoader,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      emailOption: '',
      showRadioButtons: false,
      isMatchingPasswords: null,
      failedEmailValidiation: false,
      hasPasswordError: false,
      dataId: this.id,
      firstName: '',
      lastName: '',
      email: '',
      selectedRoles: {},
      clubName: '',
      clubId: '',
      username: '',
      password: '',
      repeatedPassword: '',
      activeTo: '',
      confirmedCreatedUser: false,
      hasRoles: false,
      showClubs: true,
      showBusinessBoardResultUnits: false,
      selectedResultUnits: [],
    };
  },
  methods: {
    emailOptionChange() {
      if (this.user.club && this.user.club.id) {
        this.$store.dispatch('users/fetchRoles', {
          clubId: this.user.club.id,
          withEmail: this.emailOption === 'withEmail',
        });
      } else if (this.clubId) {
        this.$store.dispatch('users/fetchRoles', {
          clubId: this.clubId,
          withEmail: this.emailOption === 'withEmail',
        });
      }
    },
    formatDateAndTime(dateAndTime) {
      const dateString = new Date(dateAndTime).toLocaleDateString('sv-SE');
      const timeString = new Date(dateAndTime).toLocaleTimeString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return dateString + ' ' + timeString;
    },
    isActive(event) {
      if (this.emailOption === 'withEmail') {
        this.emailOption = 'withoutEmail';
      } else {
        this.emailOption = 'withEmail';
      }
    },
    checkIfPasswordsMatch() {
      if (this.password === this.repeatedPassword) {
        this.isMatchingPasswords = true;
        this.hasPasswordError = false;
      } else {
        this.isMatchingPasswords = false;
        this.hasPasswordError = true;
      }
    },
    checkEmailValidity() {
      if (!this.email.includes('@')) {
        this.failedEmailValidiation = true;
      } else {
        this.failedEmailValidiation = false;
      }
    },
    createUser() {
      if (this.emailOption === 'withEmail') {
        this.$store
          .dispatch('users/createUser', {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            roles: this.selectedRoles,
            clubId: this.showClubs
              ? this.clubId
                ? this.clubId
                : this.user.club.clubId
              : null,
            active: true,
            activeTo: this.activeTo,
            useEmailAsUsername: true,
            businessBoardResultUnits: this.selectedResultUnits,
          })
          .then(() => {
            this.$router.push({ path: '/users/' });
            this.$toasted.success(this.$t('GolfOffice.userCreated'));
          });
      } else {
        this.$store
          .dispatch('users/createUser', {
            firstName: this.firstName,
            lastName: this.lastName,
            username: this.username,
            password: this.password,
            repeatedPassword: this.repeatedPassword,
            roles: this.selectedRoles,
            clubId: this.clubId ? this.clubId : this.user.club.clubId,
            active: true,
            activeTo: this.activeTo,
            useEmailAsUsername: false,
          })
          .then(() => {
            this.$router.push({ path: '/users/' });
            this.$toasted.success(this.$t('GolfOffice.userCreated'));
          });
      }
    },
    selectedGolfOfficeRole(e, product) {
      this.clubName = '';
      this.clubId = '';
      this.hasRoles = false;
      this.selectedRoles = {};
      this.$store.state.users.roles = [];
      if (e.value === 'GkUser') {
        this.emailOption = '';
        this.showRadioButtons = true;
        this.showClubs = true;
      } else {
        this.selectedRoles[product] = [];
        this.selectedRoles[product].push({
          value: e.value,
          displayName: e.displayName,
        });
        this.emailOption = 'withEmail';
        this.showRadioButtons = false;
        this.showClubs = false;
      }
    },
    selectedRole(e, product) {
      this.hasRoles = false;
      this.selectedRoles[product] = [];
      this.selectedRoles[product].push({
        value: e.value,
        displayName: e.displayName,
      });
      if (
        product === 'BusinessBoard' &&
        e.value === 'BusinessBoardAreaManager'
      ) {
        this.$store.dispatch('businessboard/fetchResultUnits', {
          id: this.clubId || this.user.club.id,
        });
        this.showBusinessBoardResultUnits = true;
      } else {
        this.showBusinessBoardResultUnits = false;
      }
      for (var property in this.selectedRoles) {
        if (this.selectedRoles.hasOwnProperty(property)) {
          this.showClubs = true;
          if (
            this.selectedRoles[property].find(
              r => ['Administrator', 'Support'].indexOf(r.value) !== -1
            )
          ) {
            this.showClubs = false;
          }
        }
      }
      this.hasRoles = true;
    },
    selectedClub(option) {
      option = this.clubs.find(c => c.displayName === option.target.value);
      if (option) {
        this.clubId = option.value;
        this.clubName = option.displayName;
        if (this.clubId !== '') {
          this.$store.dispatch('users/fetchRoles', {
            clubId: this.clubId,
            withEmail: this.emailOption === 'withEmail',
          });
          this.$store.dispatch('clubs/fetchClub', { id: this.clubId });
        }
      } else {
        this.clubName = '';
      }
    },
  },
  computed: {
    canCreate() {
      if (this.emailOption === 'withEmail') {
        if (
          this.firstName &&
          this.lastName &&
          this.email &&
          !this.failedEmailValidiation
        ) {
          if (this.showClubs) {
            if (this.clubName || (this.user.club && this.user.club.name)) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
        // return this.firstName &&
        //   this.lastName &&
        //   this.email &&
        //   !this.failedEmailValidiation &&
        //   !checkIfEmptyObject(this.selectedRoles) &&
        //   (this.clubName || (this.user.club && this.user.club.name))
      } else {
        return (
          this.firstName &&
          this.lastName &&
          !checkIfEmptyObject(this.selectedRoles) &&
          (this.clubName || (this.user.club && this.user.club.name)) &&
          this.username &&
          this.password &&
          this.repeatedPassword &&
          this.isMatchingPasswords
        );
      }
    },
    roles() {
      return this.$store.getters['users/roles'];
    },
    golfOfficeRoles() {
      return this.$store.getters['users/golfOfficeRoles'];
    },
    clubs() {
      return this.$store.getters['clubs/clubsSelect'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    businessBoardResultUnits() {
      return this.$store.getters['businessboard/resultunits'];
    },
  },
  created() {
    if (this.user.club && this.user.club.id) {
      this.$store.dispatch('users/fetchRoles', {
        clubId: this.user.club.id,
        withEmail: this.emailOption === 'withEmail',
      });
      this.$store.state.users.golfOfficeRoles = [];
      this.showRadioButtons = true;
    } else if (this.user.superUser) {
      this.$store.dispatch('users/fetchGolfOfficeRoles');
    }
  },
};
</script>
<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.golfoffice {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.golfoffice--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  .content__input-group {
    width: 100%;
    @include media('medium') {
      width: calc((100% / 1.2) - (1rem * 2));
    }

    .user__actions {
      justify-content: flex-start;
      @include media('small') {
        justify-content: flex-end;
        // width: 70%;
      }

      a button {
        margin-left: 0;
      }

      button {
        margin-left: 1.25rem;
      }
    }
  }
}
</style>
