var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"diagramboxes"},[_c('diagram-box',{staticClass:"diagrambox",attrs:{"heading-translation-key":'ReportTool.totalMemberAmount',"with-diagram-tools":false,"diagram-id":_vm.data.members.diagramId,"comment":_vm.data.members.comment}},[_c('dot-and-doughnut-diagram',{attrs:{"data":_vm.data.members,"settings":_vm.settings,"color-keys":_vm.data.colors}})],1),_c('diagram-box',{staticClass:"diagrambox",attrs:{"heading-translation-key":'MemberCalculation.membershipGroupDistribution',"with-diagram-tools":true,"diagram-id":_vm.data.memberGroups.diagramId,"comment":_vm.data.memberGroups.comment}},[_c('pie-chart-with-legend',{attrs:{"data":_vm.data.memberGroups.categories.map(t => t.num),"labels":_vm.data.memberGroups.categories.map(
            t =>
              `${t.memberCategoryName}, ${
                t.percentage
              }%, ${t.num.toLocaleString('sv-SE')}`
          ),"percentage":_vm.data.memberGroups.categories.map(t => t.percentage),"unit-translation-key":'members'}})],1),_c('diagram-box',{staticClass:"diagrambox",attrs:{"heading-translation-key":'MemberCalculation.gameRoundsDistribution',"with-diagram-tools":true,"diagram-id":_vm.data.gameRounds.diagramId,"comment":_vm.data.gameRounds.comment}},[_c('pie-chart-with-legend',{attrs:{"data":_vm.data.gameRounds.categories.map(t => t.num),"labels":_vm.data.gameRounds.categories.map(
            t =>
              `${t.memberCategoryName}, ${
                t.percentage
              }%, ${t.num.toLocaleString('sv-SE')}`
          ),"percentage":_vm.data.gameRounds.categories.map(t => t.percentage),"unit-translation-key":'members'}})],1),_c('diagram-box',{staticClass:"diagrambox",attrs:{"heading-translation-key":'MemberCalculation.revenueDistribution',"with-diagram-tools":true,"diagram-id":_vm.data.incomes.diagramId,"comment":_vm.data.incomes.comment}},[_c('pie-chart-with-legend',{attrs:{"data":_vm.data.incomes.categories.map(t => t.num),"labels":_vm.data.incomes.categories.map(
            t =>
              `${t.memberCategoryName}, ${
                t.percentage
              }%, ${t.num.toLocaleString('sv-SE')}`
          ),"percentage":_vm.data.incomes.categories.map(t => t.percentage),"unit-translation-key":'sek'}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }