import VueTypes from 'vue-types';

export const commentTypeDefinition = VueTypes.shape({
  updateTime: VueTypes.string,
  author: VueTypes.string,
  text: VueTypes.string,
  lastUpdate: VueTypes.string,
  section: VueTypes.number,
  companyId: VueTypes.string,
  canEdit: VueTypes.bool,
  id: VueTypes.string,
});
