<template>
  <div class="template-diagrams">
    <!-- <div :class="{ 'template-diagrams': showAllDiagrams || includeDiagram }"> -->
    <div class="header">
      <diagram-header
        :elementID="templateId + '_game-total-rounds'"
        :include="includeDiagram"
        @includeDiagram="e => (includeDiagram = e)"
        :chosenFormat="fileFormat"
        :diagram="diagram"
        :templateId="templateId"
        :showDiagram="showAllDiagrams || includeDiagram">
        <template #title> {{ $t('totalRounds') }} </template>
        <template #settings>
          <modal-filter
            :titleText="$t('totalRounds')"
            :comparisonYearCount="4"
            :showDisplaySettings="true"
            :showCourses="true"
            :storedFilter="diagram?.settings || '{}'"
            @changeFilter="e => toggleDiagram(e)"
            @saveFilter="e => saveFilter(e)" />
        </template>
      </diagram-header>
    </div>
    <div
      v-if="diagram && (showAllDiagrams || includeDiagram)"
      class="diagram"
      :id="templateId + '_game-total-rounds'">
      <game-course-option2
        v-if="JSON.parse(diagram.settings)['Show'] === 0"
        :diagram="diagram" />
      <game-course-option1
        v-if="JSON.parse(diagram.settings)['Show'] === 1"
        :diagram="diagram" />
    </div>
  </div>
</template>
<script>
import ModalFilter from '@/components/reports-templates/settings-modal/gamecalculation';
import DiagramHeader from '@/components/reports-templates/diagram-header';
import GameCourseOption1 from '@/components/reports-templates/game-course-option1';
import GameCourseOption2 from '@/components/reports-templates/game-course-option2';
import LocalizationMixinVue from '../../../mixins/localization-mixin.vue';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixinVue],
  components: {
    ModalFilter,
    DiagramHeader,
    GameCourseOption1,
    GameCourseOption2,
  },
  props: {
    diagram: {},
    templateId: String,
    include: Boolean,
    showAllDiagrams: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    fileFormat() {
      return this.$store.getters['businessboardReportingTool/settings']
        .fileFormat;
    },
    includeDiagram() {
      return this.diagram?.includedInTemplate || false;
    },
  },
  methods: {
    toggleDiagram(e) {
      this.diagramToRender = e.show;
      this.$store.dispatch('businessboardReportingTool/postDiagram', {
        templateId: this.templateId,
        settings: e,
        diagramType: 10,
      });
    },
    saveFilter(e) {
      e.save = true;
      this.toggleDiagram(e);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';
</style>
