<script>
import { Bar } from 'vue-chartjs';

import { MonthsLabelsShort } from '@/core/consts';
import { diagramPattern } from '@/core/helpers';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  extends: Bar,

  mixins: [LocalizationMixin],

  props: {
    data: {
      type: Object,
      default: () => {},
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: function () {
        return MonthsLabelsShort.map(l => this.$t(`MonthsShort.${l}`));
      },
    },
  },

  data: function () {
    return {
      chartdata: {
        labels: this.labels,
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              id: 'y-bars',
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                callback: function (value, index, values) {
                  return value.toLocaleString('sv-SE', {
                    style: 'currency',
                    currency: 'SEK',
                    maximumFractionDigits: 0,
                  });
                },
                suggestedMin: 0,
                suggestedMax: 0,
              },
            },
            {
              display: true,
              ticks: {
                suggestedMin: 0,
                suggestedMax: 0,
              },
              id: 'y-lines',
              stacked: false,
              gridLines: {
                display: false,
              },
            },
          ],
        },
        tooltips: false,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    render() {
      this.chartdata.datasets = [
        {
          label: this.data.totalResult.label,
          fill: false,
          lineTension: 0.5,
          data: this.data.totalResult.values,
          borderColor: '#00A99D',
          backgroundColor: diagramPattern('#00A99D'),
          borderWidth: 1,
          type: 'line',
          pointRadius: 0,
          stack: 'stack 5',
          order: 1,
          yAxisID: 'y-lines',
        },
        {
          label: this.data.forecast.label,
          fill: false,
          lineTension: 0.5,
          data: this.data.forecast.values,
          borderColor: '#FFA99D',
          backgroundColor: diagramPattern('#FFA99D'),
          borderWidth: 1,
          borderDash: [2, 2],
          type: 'line',
          pointRadius: 0,
          stack: 'stack 3',
          order: 2,
          yAxisID: 'y-lines',
        },
        {
          label: this.data.budget.label,
          lineTension: 0.5,
          data: this.data.budget.values,
          borderColor: '#FBEBDD',
          backgroundColor: '#FBEBDD',
          type: 'line',
          pointRadius: 0,
          fill: 'origin',
          stack: 'stack 4',
          order: 5,
          yAxisID: 'y-lines',
        },
      ];

      let colors = ['#27645C', '#9CE0D1'];
      let arr = [].concat(
        this.data.totalResult.values,
        this.data.forecast.values,
        this.data.budget.values
      );
      let m = 0;
      for (let i = 0; i < 12; i++) {
        if (this.data.totalResult.values[i] !== 0) {
          m = i + 1;
          this.chartdata.datasets[1].data[i - 1] = null;
        }
      }
      this.chartdata.datasets[0].data = this.chartdata.datasets[0].data.splice(
        0,
        m
      );
      for (let i = 0; i < this.data.companyData.length; i++) {
        arr = arr.concat(this.data.companyData[i].values);
        this.chartdata.datasets.push({
          label: this.data.companyData[i].label,
          backgroundColor: colors[i % colors.length],
          borderColor: colors[i % colors.length],
          borderWidth: 1,
          barPercentage: 1,
          data: this.data.companyData[i].values.splice(0, m),
          tooltips: this.data.companyData[i].tooltips,
          categoryPercentage: 0.8,
          order: 3,
          stack: 'stack1',
          yAxisID: 'y-bars',
        });
      }
      for (let i = 0; i < this.data.comparisonYearCompanyData.length; i++) {
        arr = arr.concat(this.data.comparisonYearCompanyData[i].values);
        this.chartdata.datasets.push({
          label: this.data.comparisonYearCompanyData[i].label,
          backgroundColor: diagramPattern(colors[i % colors.length]),
          borderColor: colors[i % colors.length],
          barPercentage: 1,
          borderWidth: 1,
          data: this.data.comparisonYearCompanyData[i].values,
          tooltips: this.data.comparisonYearCompanyData[i].tooltips,
          categoryPercentage: 0.8,
          order: 4,
          stack: 'stack2',
          yAxisID: 'y-bars',
        });
      }
      for (let i = 0; i < this.options.scales.yAxes.length; i++) {
        this.options.scales.yAxes[i].ticks.suggestedMin = Math.min(...arr);
        this.options.scales.yAxes[i].ticks.suggestedMax = Math.max(...arr);
      }
      this.renderChart(this.chartdata, this.options);
    },
  },
  watch: {
    data: function (newValue) {
      this.render();
    },
  },
  mounted() {
    this.render();
  },
};
</script>
