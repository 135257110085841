<template>
  <div :class="[fileFormat === '0' ? 'opaque-bg' : '']">
    <h1
      v-if="settings.showHeaders"
      class="title">
      {{ $t('roundsPerMonth') }}
    </h1>
    <chart-legend
      :year1="diagram.data[0].year"
      :year2="diagram.data[1].year">
    </chart-legend>
    <bar-chart
      v-if="!isLoading"
      :styles="{ height: '400px' }"
      :chart-data="chartData" />
    <table-chart-loader v-if="isLoading" />
    <div class="legend">
      <reports-legend-bar-chart
        :normalYear="diagram.data[0]"
        :comparitativeYear="diagram.data[1]"
        unit="st" />
    </div>
  </div>
</template>

<script>
import { diagramPattern } from '@/core/helpers';

import BarChart from '@/components/charts/bar-chart-month';
import ReportsLegendBarChart from '@/components/reports-templates/legend/reports-legend-bar-chart';
import ChartLegend from '@/components/reports-templates/legend/game-month-rounds';
import LocalizationMixinVue from '@/mixins/localization-mixin.vue';
import TableChartLoader from './table-chart-loader.vue';

export default {
  name: 'BusinessboardReports',
  mixins: [LocalizationMixinVue],
  components: {
    BarChart,
    ReportsLegendBarChart,
    ChartLegend,
    TableChartLoader,
  },
  props: { diagram: {}, fileFormat: String },
  computed: {
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
    chartData: function () {
      return {
        labels: [
          this.$t('MonthsShort.Jan'),
          this.$t('MonthsShort.Feb'),
          this.$t('MonthsShort.Mar'),
          this.$t('MonthsShort.Apr'),
          this.$t('MonthsShort.May'),
          this.$t('MonthsShort.Jun'),
          this.$t('MonthsShort.Jul'),
          this.$t('MonthsShort.Aug'),
          this.$t('MonthsShort.Sep'),
          this.$t('MonthsShort.Oct'),
          this.$t('MonthsShort.Nov'),
          this.$t('MonthsShort.Dec'),
        ],
        datasets: [
          {
            ...this.dataSet(
              this.diagram.data[0],
              'capacity',
              'Spelkapacitet',
              '#32a99d'
            ),
            pointHoverRadius: 0,
            pointRadius: 0,
            type: 'line',
            order: 99,
            borderWidth: 3,
            yAxisID: 'y-lines',
          },
          {
            ...this.dataSet(
              this.diagram.data[1],
              'capacity',
              'Spelkapacitet',
              '#32a99d'
            ),
            backgroundColor: 'rgba(251, 235, 221, 0.7)',
            borderColor: 'rgba(251, 235, 221, 0.9)',
            fill: 'origin',
            pointHoverRadius: 0,
            pointRadius: 0,
            type: 'line',
            order: 100,
            yAxisID: 'y-lines',
          },
          ...this.dataSetTuple(
            this.diagram.data[0],
            this.diagram.data[1],
            'totalRounds',
            this.$t('totalRounds'),
            '#0D3746'
          ),
        ],
      };
    },
  },
  data() {
    return {};
  },
  methods: {
    dataSetTuple(data1, data2, category, label, color) {
      return [
        {
          label: `(${data1.year}) ${label}`,
          data: data1.months.map(m => m[category]),
          borderColor: color,
          backgroundColor: color,
          borderWidth: 1,
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          stack: 'stack1',
          yAxisID: 'y-bars',
        },
        {
          label: `(${data2.year}) ${label}`,
          data: data2.months.map(m => m[category]),
          borderColor: color,
          backgroundColor: diagramPattern(color),
          borderWidth: 1,
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          stack: 'stack2',
          yAxisID: 'y-bars',
        },
      ];
    },

    dataSet(data, category, label, color, pattern = false) {
      return {
        label: `(${data.year}) ${label}`,
        borderColor: color,
        borderWidth: 1,
        backgroundColor: pattern ? diagramPattern(color) : color,
        data: data.months.map(m => m[category]),
        // type: 'line',
        fill: false,
        pointRadius: 0,
        categoryPercentage: 0.9,
        barPercentage: 0.8,
        // order: 98,
      };
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';

.title {
  margin-left: 4rem;
  font-size: xx-large;
}
.legend {
  padding-top: 2rem;
}
</style>
