/* Store module to handle Users */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  users: {
    rows: [],
    columns: [],
  },
  clubId: '',
  roles: [],
  golfOfficeRoles: [],
  user: {},
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
  settings: {},
};

const mutations = {
  users(state, payload) {
    state.users.rows = payload.rows;
    state.users.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
  user(state, payload) {
    state.user = payload;
  },
  roles(state, payload) {
    state.roles = payload;
  },
  golfOfficeRoles(state, payload) {
    state.golfOfficeRoles = payload;
  },
  createUser(state, payload) {
    state.users.rows.push(payload);
  },
  deleteUser(state, id) {
    Vue.delete(
      state.users.rows,
      state.users.rows.findIndex(user => user.id === id)
    );
  },
  saveUser(state, { id, updated }) {
    let user = state.users.rows.find(user => user.id === id);
    if (!user) return;
    user.username = updated.username;
    user.firstName = updated.firstName;
    user.lastName = updated.lastName;
    user.email = updated.email;
    user.role = updated.role;
    user.active = updated.active;
    user.activeTo = updated.activeTo;
  },
  clubId(state, payload) {
    state.clubId = payload;
  },
  settings(state, payload) {
    state.settings = payload;
  },
};

const getters = {
  users: state => {
    return state.users;
  },
  user:
    state =>
    (id = '0') => {
      return state.user;
    },
  roles: state => {
    return state.roles;
  },
  golfOfficeRoles: state => {
    return state.golfOfficeRoles;
  },
  pagination: state => {
    return state.pagination;
  },
  currentPage: state => {
    return state.pagination.currentPage;
  },
  nextPage: state => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: state => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
  clubId: state => {
    return state.clubId;
  },
  settings: state => {
    return state.settings;
  },
};

const actions = {
  fetchUsers: async (context, params) => {
    let path = 'users';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => {
          if (params[k] !== undefined) {
            return esc(k) + '=' + esc(params[k]);
          }
        })
        .filter(q => q !== undefined)
        .join('&');
      path += '?' + query;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    const data = response.data;
    context.commit('users', data.result);
  },
  fetchGolfOfficeRoles: async (context, params) => {
    const path = 'users/roles';
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('golfOfficeRoles', data.result);
    }
  },
  fetchRoles: async (context, params) => {
    const path =
      'users/roles/' + params.clubId + '?withEmail=' + params.withEmail;
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('roles', data.result);
    }
  },
  fetchUser: async (context, params) => {
    const path = 'users/' + params.id;
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('user', data.result);
    }
  },
  createUser: async (context, params) => {
    context.dispatch('ui/toggleActiveLoaders', 'create-user', { root: true });
    const path = 'users';
    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      context.dispatch('ui/toggleActiveLoaders', 'create-user', { root: true });
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
      throw new Error(error.response.data.message);
    }
    context.dispatch('ui/toggleActiveLoaders', 'create-user', { root: true });
    if (response && response.data) {
      const data = response.data;
      context.commit('createUser', data.result);
    }
  },
  saveUser: async (context, params) => {
    const path = 'users/' + params.id;
    let response = {};
    try {
      response = await Vue.axios.patch(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
      throw new Error(error.response.data.message);
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('saveUser', {
        id: params.id,
        updated: data.result,
      });
    }
  },
  deleteUser: async (context, params) => {
    const path = 'users/' + params.id;
    let response = {};
    try {
      response = await Vue.axios.delete(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
      throw new Error(error.response.data.message);
    }

    if (response && response.status === 200) {
      context.commit('deleteUser', params.id);
    }
  },
  setClub: async (context, params) => {
    const path = 'users/club';
    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
      throw new Error(error.response.data.message);
    }

    if (response && response.status === 200) {
      context.commit('clubId', params.id);
      context.dispatch('menu/get', null, { root: true });
    }
  },
  setSettings: async (context, params) => {
    const path = 'users/settings';
    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
      throw new Error(error.response.data.message);
    }
    if (response && response.status === 200) {
      context.commit('settings', params);
    }
  },
  getSettings: async context => {
    const path = 'users/settings';
    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      context.commit('settings', JSON.parse(response.data));
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
