<template>
  <div class="slider-container">
    <div class="slider" />
    <button
      @click="sliderState()"
      :class="{
        isChecked: this.sliderChecked,
        notChecked: !this.sliderChecked,
      }">
      <use-svg
        :class="{
          visibleSvg: this.sliderChecked,
          hiddenSvg: !this.sliderChecked,
        }"
        svg="bb-slider-check-mark"
        color="white"
        stroke="" />
    </button>
  </div>
</template>

<script>
import useSvg from '@/components/use-svg';

export default {
  name: 'slider',
  components: { useSvg },

  props: {},

  data() {
    return {
      sliderChecked: false,
    };
  },
  methods: {
    ChangeSliderState() {
      if (this.sliderChecked) {
        this.sliderChecked = false;
      } else {
        this.sliderChecked = true;
      }
    },
    sliderState() {
      this.ChangeSliderState();
      this.$emit('slider-state', this.sliderChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  position: relative;

  width: 100px;
  height: 50px;

  font-weight: 600;
  font-size: 16px;
}

.slider {
  top: 0;
  left: 0;

  width: 50%;
  height: 50%;

  background-color: #f0f5f5;
  transition: width 0.3s ease;
  border-radius: 40%;
  border: solid 1px #000;
}

.isChecked {
  margin-left: 1.8rem;
  position: absolute;
  padding-left: 0.1rem;
  padding-top: 0.05rem;

  border-radius: 50%;
  width: 25px;
  height: 25px;

  background-color: #abded1;
}

.notChecked {
  position: absolute;
  margin-left: 0rem;
  padding-left: 0.1rem;
  padding-top: 0.05rem;

  width: 25px;
  height: 25px;
  border: solid 1px #000;
  border-radius: 50%;

  background-color: gray;
}

.hiddenSvg {
  visibility: hidden;

  width: 20px;
  height: 20px;
}
.visibleSvg {
  visibility: visible;

  width: 20px;
  height: 20px;
}
</style>
