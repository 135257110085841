<template>
  <div class="golfoffice">
    <div class="golfoffice--content">
      <router-link to="/businessboard/data">
        <span class="back_button">{{ $t('Businessboard.backToData') }}</span>
      </router-link>
      <h1>{{ category.membershipCategoryName }}</h1>
      <table class="types-table">
        <tr>
          <th>
            {{ $t('Company.connectedTypesToCategory') }}
            {{ category.membershipCategoryName }}:
          </th>
        </tr>
        <tr
          v-for="(type, index) in membershipCategory"
          :key="index">
          <td>
            {{ type.name }}
          </td>
        </tr>
      </table>
      <template> </template>
    </div>
  </div>
</template>

<script>
import LocalizationMixin from '@/mixins/localization-mixin.vue';
export default {
  name: 'categoryTypes',
  mixins: [LocalizationMixin],
  components: {},
  directives: {},
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      category: {},
      types: [],
    };
  },
  methods: {},
  computed: {
    membershipCategory() {
      return this.$store.getters['businessboard/membershipcategory'];
    },
  },
  created() {
    this.category =
      this.$store.state.businessboard.membershipcategories.membershipCategories.rows.find(
        r => r.id === this.id
      );
    this.$store.dispatch('businessboard/fetchMembershipCategory', {
      id: this.id,
    });
  },
};
</script>
<style lang="scss" scoped>
.types-table {
  display: inline-block;
  tr {
    height: 40px;

    &:nth-child(2n) {
      background-color: color(bb-sec5);
    }
  }
}
// .golfoffice {
//   // margin: 1rem;
//   flex-grow: 1;
//   width: 100%;
//   @include media('small') {
//     width: calc((100% / 2) - (1rem * 1));
//   }
//   @include media('large') {
//     width: calc((100% / 3) - (1rem * 2));
//   }
// }
// .golfoffice--content {
//   background-color: color(white);
//   border-radius: 0.5rem;
//   display: flex;
//   flex-direction: column;
//   padding: 1rem 1.5rem;
//   .content__input-group {
//     width: 100%;
//     @include media('medium') {
//       width: calc((100% / 1.2) - (1rem * 2));
//     }
//     .input__label {
//       width: 100%;
//     }
//     .user__actions {
//       justify-content: flex-start;
//       @include media('small') {
//         justify-content: flex-end;
//         // width: 70%;
//       }

//       a button {
//         margin-left: 0;
//       }
//       .select button {
//         margin-left: 0;
//       }
//     }
//   }
// }
</style>
