<template>
  <div class="golf-office">
    <div :class="contentClass">
      <template v-if="!loggedIn">
        <img
          src="../assets/images/bb-logo-icon.svg"
          alt="Business Board"
          width="387.82"
          height="75.4" />
        <div class="flex-container">
          <h1 class="login-title">{{ $t('Businessboard.welcome') }}</h1>
          <div class="login-container">
            <div class="content__input">
              <input-type
                :label="$t('username')"
                input-id="userName"
                ref="userName"
                @keyup.native.enter="login"
                autofocus />
              <input-type
                :label="$t('password')"
                input-id="password"
                ref="password"
                type="password"
                @keyup.native.enter="login" />
              <div class="content__input-actions">
                <base-button
                  :text="$t('login')"
                  v-element-loader="{ loaderKey: 'home-login' }"
                  class="login-button"
                  @click="login" />
                <p>
                  <router-link
                    to="/businessboard/forgot-password"
                    class="underline">
                    {{ $t('forgotPassword') }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="settings.businessBoardVersion > 0">
          <div class="login-as-container">
            <p class="login-as">
              {{ $t('GolfOffice.loggedInAs') }}
              <span>{{ user.firstName }}</span>
              <span> {{ user.lastName }}</span>
            </p>
            <h1 class="login-title">{{ $t('Businessboard.welcome') }}</h1>
            <div class="login-prompt">
              <use-svg svg="bb-circle-arrow"> </use-svg>
              <span>
                {{ $t('Businessboard.startByClicking') }}
              </span>
            </div>
          </div>
        </template>
        <template v-else>
          <landing-page />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import InputType from '@/components/form-elements/input';
import BaseButton from '@/components/form-elements/base-button';
import elementLoader from '@/directives/element-loader';
import { mapActions } from 'vuex';
import UseSvg from '@/components/use-svg.vue';
import LandingPage from '@/views/businessboard/landing-page.vue';

export default {
  name: 'Home-bb',
  components: {
    InputType,
    BaseButton,
    UseSvg,
    LandingPage,
  },
  directives: {
    elementLoader,
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    loggedIn() {
      return this.$store.getters['user/loggedIn'];
    },
    backgroundImage() {
      return this.loggedIn
        ? 'none'
        : 'url(../assets/images/bb-logo-background.svg)';
    },
    marginClass() {
      return this.loggedIn ? 'menu-margin' : 'no-margin';
    },
    contentClass() {
      return this.loggedIn ? 'golf-office--content' : 'golf-office--content-bg';
    },
    language() {
      return this.$store.getters['businessboard/language'];
    },
    settings() {
      return this.$store.getters['businessboard/settings'];
    },
  },
  methods: {
    ...mapActions('ui', ['toggleActiveLoaders']),
    login() {
      if (
        this.$refs.userName.dataValue.length > 1 &&
        this.$refs.password.dataValue.length > 1
      ) {
        this.$store
          .dispatch('user/login', {
            username: this.$refs.userName.dataValue,
            password: this.$refs.password.dataValue,
          })
          .then(data => {
            if (data) {
              this.$cookies.set('token', data.token, '7d');
              this.$cookies.set('refresh_token', data.refreshToken, '7d');
              if (this.user && !this.user.club && this.user.superUser) {
                this.$store.dispatch('clubs/clubsSelect', null);
              }
              if (this.user.club && this.user.club.id) {
                this.$store.dispatch('businessboard/fetchSettings');
                this.$cookies.remove('language');
                this.$store.dispatch('businessboard/fetchLanguage');
              }
            }
          });
      }
    },
  },
  mounted() {
    this.isLoading = true;
    if (this.loggedIn && this.user.club && this.user.club.id) {
      this.$store.dispatch('businessboard/fetchLanguage').then(() => {
        if (this.language === '0') {
          this.$i18n.locale = 'sv';
        } else if (this.language === '1') {
          this.$i18n.locale = 'en';
        }
      });
      this.$store.dispatch('businessboard/fetchSettings').then(() => {
        if (!this.settings || this.settings.businessBoardVersion === 0) {
          this.$router.push({ path: '/businessboard/landing' });
        }
      });
    } else {
      var locale = (navigator.language || navigator.userLanguage).split('-')[0];
      var locales = this.$i18n.availableLocales;
      if (locales.includes(locale)) {
        this.$i18n.locale = locale;
      } else {
        this.$i18n.locale = 'en';
      }
    }
    this.isLoading = false;
  },
};
</script>
<style lang="scss" scoped>
.golf-office {
  flex-grow: 1;
}

.golf-office--content {
  border-radius: 0;
  background-color: color(bb-primary);
  height: 86vh;
  width: 100vw;
}
.golf-office--content-bg {
  @extend .golf-office--content;
  background-image: url('../assets/images/bb-logo-background.svg');
  padding: 1rem 1.5rem;
  height: 100vh;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  gap: 8rem;
  justify-content: center;
  padding-top: 5rem;
  padding: 1rem 1.5rem;
  color: white;
}
.login-title {
  color: white;
  font-size: 56px;
}
.content__input-group {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 25rem;
  flex-direction: column;
  margin-bottom: 5rem;
}

.content__input-actions {
  padding-top: 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  flex-wrap: nowrap;
  button {
    margin: 0;
  }
  a {
    color: color(green-fog);
  }
}
.login-container {
  padding-top: 5.144375rem;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: #0e3746;
  width: 36.5rem;
  height: 28.5rem;
  top: 585px; // convert to rem
  left: 689px; // convert to rem
  padding-bottom: 9.2875rem;
  padding-left: 5rem;
  text-wrap: nowrap;
  padding-right: 7rem;
}
.login-button {
  background: color(green-fog);
  border-radius: 3px;
  color: #131516;
}

.login-as-container {
  padding-top: 5.144375rem;
  font-weight: 300;
  font-size: 14px;
  color: color(green-fog);
  justify-self: flex-start;
  span {
    font-weight: 500;
    font-size: 16px;
  }
}

.login-prompt {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    color: color(green-fog);
    font-weight: 500;
  }
}
</style>
