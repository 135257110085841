/* Store module to handle Clubs */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  holesponsors: [],
};

const mutations = {
  holesponsors (state, payload) {
    state.holesponsors = payload;
  },
  setImageOnCourseHole (state, payload) {
    const courseIndex = state.holesponsors.findIndex(course => course.id === payload.course);
    Vue.set(state.holesponsors[courseIndex].holes[payload.hole - 1], 'sponsorImageKey', payload.image.key);
    Vue.set(state.holesponsors[courseIndex].holes[payload.hole - 1], 'sponsorImageUrl', payload.image.url);
  },
};

const getters = {
  holesponsors: state => {
    return state.holesponsors;
  },
};

const actions = {
  fetchHolesponsors: async (context) => {
    let path = 'ontagappsettings/holesponsors';

    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('holesponsors', data.result);
    }
  },
  addImages: async (context, params) => {
    let path = 'ontagappsettings/holesponsors/image';

    let formData = new FormData();
    formData.append('image', params.image);
    let response = {};
    try {
      response = await Vue.axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }

    if (response && response.status === 200) {
      const data = response.data;
      params.holes.forEach(hole => {
        context.commit('setImageOnCourseHole', { image: data.result, course: params.courseId, hole });
      });
    }
  },
  save: async (context, params) => {
    let path = 'ontagappsettings/holesponsors';

    const courses = {
      courses: params,
    };

    context.dispatch('ui/toggleActiveLoaders', 'holesponsors-save-holesponsors', { root: true });

    let response = {};
    try {
      response = await Vue.axios.patch(path, courses);
    } catch (error) {
      EventBus.$emit('toast', { type: 'error', 'msg': error.response.data.message, 'request': path });
    }
    context.dispatch('ui/toggleActiveLoaders', 'holesponsors-save-holesponsors', { root: true });

    if (response && response.status === 200) {
      const data = response.data;
      context.commit('holesponsors', data.result);

      return true;
    }

    return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
