<template>
  <div class="businessboard">
    <div class="businessboard--content">
      <back-button />
      <div class="last-update-container">
        <last-updated
          :last-update="latestUpdates"
          :show-member="false"
          :show-game="false"
          :show-economy="true"
          :show-warning="notconnectedaccounts.length > 0"
          :is-balance-accounts="true" />
      </div>
      <template v-if="companyId === 'consolidatedaccounts'">
        <div>
          <span class="h1"> {{ $t('groupsForBalanceReport') }} </span>
          <span
            v-if="isLoading"
            class="element--is-loading"></span>
          <modal-group-settings />

          <div>
            <div class="header row">
              <div>
                {{ $t('groupName') }}
                <info-icon :description="$t('groupName')">
                  <template #icon>
                    <use-svg svg="white-info-icon" />
                  </template>
                </info-icon>
              </div>
              <div>{{ $t('belongsToCategory') }}</div>
              <div>{{ $t('linkedAccounts') }}</div>
            </div>
            <div
              v-for="item in groups"
              :key="item.id"
              class="data row">
              <div>
                {{ item.name }}
              </div>
              <div class="subtle">
                <div class="group-label-div">
                  <span class="group-label">
                    {{ item.balanceAccountCategoryName }}
                  </span>
                </div>
              </div>
              <modal-group-connected-accounts
                v-if="item.balanceAccounts.length > 0"
                :title="item.name"
                :accounts="item.balanceAccounts" />
              <div
                v-else
                class="subtle">
                {{ $t('noLinkedAccounts') }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <span class="h1"> {{ $t('balanceAccounts') }} </span>
          <span
            v-if="isLoading"
            class="element--is-loading"></span>
          <p class="info">
            {{ $t('info') }}
          </p>
          <div style="float: right">
            <toggle
              :checked="!showAccountsWithoutTransactions"
              :tooltip="
                showAccountsWithoutTransactions ? $t('show') : $t('hide')
              "
              @toggle="toggleHide" />
            {{ $t('hideBalanceAccountsWithoutTransactions') }}
          </div>
          <modal-auto-connect-accounts :companyId="companyId" />

          <div>
            <warning
              v-if="notconnectedaccounts.length > 0"
              :message="$t('notLinkedBalanceAccounts')"
              :rounded="true" />
            <div
              v-for="item in notconnectedaccounts"
              :key="item.id"
              class="data row2">
              <div>
                {{ item.name }}
              </div>
              <modal-connect-account
                @done="fetchAccounts"
                :name="item.name"
                :id="item.id" />
            </div>
          </div>

          <div>
            <div class="header row4">
              <div>{{ $t('linkedBalanceAccounts') }}</div>
              <div>
                <div>
                  <multi-select
                    @optionSelected="e => filterResults(e, 'status')"
                    :options="categoryOptions">
                  </multi-select>
                  {{ $t('category') }}
                  <info-icon :description="$t('category')">
                    <template #icon>
                      <use-svg svg="white-info-icon" />
                    </template>
                  </info-icon>
                </div>
              </div>
              <div>
                {{ $t('group') }}
                <info-icon :description="$t('group')">
                  <template #icon>
                    <use-svg svg="white-info-icon" />
                  </template>
                </info-icon>
              </div>
              <div></div>
            </div>
            <div
              v-for="item in connectedaccounts"
              :key="item.id"
              class="data row4">
              <div>
                {{ item.name }}
              </div>
              <div class="subtle">
                <div class="group-label-div">
                  <span class="group-label">
                    {{
                      groups.find(g => g.id === item.balanceAccountGroupId)
                        .balanceAccountCategoryName
                    }}
                  </span>
                </div>
              </div>
              <div class="subtle">
                <div class="group-label-div">
                  <span class="group-label">
                    {{
                      groups.find(g => g.id === item.balanceAccountGroupId).name
                    }}
                  </span>
                </div>
              </div>
              <modal-connect-account
                @done="fetchAccounts"
                :name="item.name"
                :groupId="item.balanceAccountGroupId"
                :id="item.id" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import UseSvg from '@/components/use-svg';
import InfoIcon from '@/components/businessboard/tooltip-icon';
import ModalGroupSettings from '@/components/modal/balance-sheet/group-settings.vue';
import ModalGroupConnectedAccounts from '@/components/modal/balance-sheet/group-connected-accounts.vue';
import ModalAutoConnectAccounts from '@/components/modal/balance-sheet/auto-connect-accounts.vue';
import ModalConnectAccount from '@/components/modal/balance-sheet/connect-account.vue';
import Warning from '@/components/warning/warning.vue';
import MultiSelect from '@/components/form-elements/multi-select-bb';
import Toggle from '@/components/businessboard/toggle';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import LocalizationMixin from '@/mixins/localization-mixin';
import BackButton from '@/components/form-elements/back-button.vue';

export default {
  name: 'BusinessboardBlanceSheetSettings',
  components: {
    UseSvg,
    InfoIcon,
    ModalGroupSettings,
    ModalGroupConnectedAccounts,
    ModalAutoConnectAccounts,
    ModalConnectAccount,
    Warning,
    MultiSelect,
    Toggle,
    LastUpdated,
    BackButton,
  },
  props: {
    companyId: {
      type: String,
      default: '',
    },
  },
  mixins: [LocalizationMixin],
  data() {
    return {
      isModalOpen: false,
      accountsfilter: [],
      showAccountsWithoutTransactions: true,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['BusinessBoardBalanceSheet/isLoading'];
    },
    accounts() {
      return this.$store.getters['BusinessBoardBalanceSheet/accounts'];
    },
    groups() {
      return this.$store.getters['BusinessBoardBalanceSheet/groups'];
    },
    categories() {
      return this.$store.getters['BusinessBoardBalanceSheet/categories'];
    },
    notconnectedaccounts() {
      return (
        this.accounts?.filter(
          a =>
            a.balanceAccountGroupId === null &&
            (this.showAccountsWithoutTransactions || a.hasTransactions)
        ) || []
      );
    },
    connectedaccounts() {
      if (this.accountsfilter.length > 0)
        return (
          this.accounts?.filter(
            a =>
              a.balanceAccountGroupId !== null &&
              this.accountsfilter.includes(
                this.groups.find(g => g.id === a.balanceAccountGroupId)
                  .balanceAccountCategoryName
              ) &&
              (this.showAccountsWithoutTransactions || a.hasTransactions)
          ) || []
        );
      else
        return (
          this.accounts?.filter(a => a.balanceAccountGroupId !== null) || []
        );
    },
    categoryOptions() {
      return this.categories.map(c => {
        return { displayName: c.name, value: c.externalId };
      });
    },
    latestUpdates() {
      return this.$store.getters['businessboard/lastUpdate'];
    },
  },
  mounted() {
    if (this.companyId !== 'consolidatedaccounts')
      this.$store.dispatch(
        'BusinessBoardBalanceSheet/fetchAccounts',
        this.companyId
      );
    this.$store.dispatch('BusinessBoardBalanceSheet/fetchGroups');
    this.$store.dispatch('BusinessBoardBalanceSheet/fetchCategories');
    this.$store.dispatch('businessboard/fetchLastUpdate', {
      balanceAccounts: true,
      dataSources: [0],
    });
  },
  watch: {},
  methods: {
    filterResults(e, s) {
      this.accountsfilter = e.options
        .filter(o => o.checked)
        .map(o => o.displayName);
    },
    fetchAccounts() {
      this.$store.dispatch(
        'BusinessBoardBalanceSheet/fetchAccounts',
        this.companyId
      );
    },
    toggleHide() {
      this.showAccountsWithoutTransactions =
        !this.showAccountsWithoutTransactions;
    },
  },
};
</script>
<style lang="scss" scoped>
.businessboard {
  // margin: 1rem;
  flex-grow: 1;
  width: 100%;
  @include media('small') {
    width: calc((100% / 2) - (1rem * 1));
  }
  @include media('large') {
    width: calc((100% / 3) - (1rem * 2));
  }
}
.businessboard--content {
  background-color: color(white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}
.businessboard--table {
  margin-bottom: 2rem;
}

.last-update-container {
  margin-bottom: 1rem;
}

.header {
  background: #373d3f;
  color: var(--white, #fff);
  padding: 22px 16px;
  font-size: 17px;
}
.data {
  background: var(--tabellgrn1, #f0f5f5);
  color: #000;
  padding: 12px 16px;
  font-size: 16px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 3px;
  font-weight: 600;
  vertical-align: middle;
  margin: 2px;
  .subtle {
    color: var(--gr1, #131516);
    font-size: 14px;
    font-weight: 300;
  }
}
.row2 {
  display: grid;
  grid-template-columns: 4fr 1fr;
  border-radius: 3px;
  font-weight: 600;
  vertical-align: middle;
  margin: 2px;
  .subtle {
    color: var(--gr1, #131516);
    font-size: 14px;
    font-weight: 300;
  }
}
.row4 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  border-radius: 3px;
  font-weight: 600;
  vertical-align: middle;
  margin: 2px;
  .subtle {
    color: var(--gr1, #131516);
    font-size: 14px;
    font-weight: 300;
  }
}
.group-label-div {
  top: 0.5rem;
}

.group-label {
  padding: 3px 10px;
  height: 25px;

  border-radius: 4px;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  font-family: 'HindSiliguri';

  background: #f0f5f5;
  border: 1px solid #27645c;
  color: #0e3746;
}

.modal--wrapper {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 4.6rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;
  border-radius: 8px;
}

.modal--content {
  width: 21rem;
  margin-left: 2rem;
}
.modal--header {
  background-color: #fff;
}
.modal--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #131516;
}

.action--buttons {
  display: flex;
  gap: 1rem;
  margin: 2rem 0rem 0rem 1rem;
}

.breadcrumb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7c80;
}

.dropdown--text {
  margin-bottom: 6rem;
}

.bubble {
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
