/* Store module to handle Printers */
import Vue from 'vue';
import EventBus from '@/core/event-bus.js';

const state = {
  defaultPrinter: [],
  printersOptions: [],
  coursesPrintersOptions: [],
  printers: {
    rows: [],
    columns: [],
  },
  printer: {},
  pagination: {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
};

const mutations = {
  defaultPrinter(state, payload) {
    state.defaultPrinter = payload;
  },
  printersOptions(state, payload) {
    state.printersOptions = payload;
  },
  coursesPrintersOptions(state, payload) {
    state.coursesPrintersOptions = payload;
  },
  printers(state, payload) {
    state.printers.rows = payload.rows;
    state.printers.columns = payload.columns;
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageCount = payload.pageCount;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.rowCount = payload.rowCount;
    state.pagination.firstRowOnPage = payload.firstRowOnPage;
    state.pagination.lastRowOnPage = payload.lastRowOnPage;
  },
  printer(state, payload) {
    state.printer = payload;
  },
};

const getters = {
  defaultPrinter: state => {
    return state.defaultPrinter;
  },
  printersOptions: state => {
    return state.printersOptions;
  },
  printersOptionsById: state => id => {
    if (id !== undefined) {
      return state.printersOptions.find(printer => printer.value === id);
    }

    return state.printersOptions.find(printer => printer.default === true);
  },
  coursesPrintersOptions: state => {
    return state.coursesPrintersOptions;
  },
  printers: state => {
    return state.printers;
  },
  printer: state => {
    return state.printer;
  },
  pagination: state => {
    return state.pagination;
  },
  nextPage: state => {
    let hasNextPage = state.pagination.currentPage + 1;
    if (hasNextPage > state.pagination.pageCount) {
      return false;
    }
    return true;
  },
  previousPage: state => {
    let hasPreviousePage = state.pagination.currentPage - 1;
    if (hasPreviousePage <= 0) {
      return false;
    }
    return true;
  },
};

const actions = {
  fetchDefaultPrinter: async (context, params) => {
    const path = 'printers/defaultprinter';
    context.dispatch('ui/toggleActiveLoaders', 'edit-settings', { root: true });
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'edit-settings', { root: true });

    if (response && response.data) {
      const data = response.data;
      context.commit('defaultPrinter', data.result);
      return data.result;
    }
  },
  fetchPrintersOptions: async (context, params) => {
    let path = 'printers/select';

    if (params && params.getStandardPrinterOption) {
      path += '?addDefault=' + params.getStandardPrinterOption;
    }

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      if (params && params.getStandardPrinterOption) {
        context.commit('coursesPrintersOptions', data.result);
      } else {
        context.commit('printersOptions', data.result);
      }
    }
  },
  fetchAllPrinters: async (context, params) => {
    let path = 'printers';

    if (params) {
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => {
          if (params[k] !== undefined) {
            return esc(k) + '=' + esc(params[k]);
          }
        })
        .filter(q => q !== undefined)
        .join('&');
      path += '?' + query;
    }

    context.dispatch('ui/toggleActiveLoaders', 'printers-fetch-all', {
      root: true,
    });
    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'printers-fetch-all', {
      root: true,
    });

    if (response && response.data) {
      const data = response.data;
      context.commit('printers', data.result);
    }
  },
  fetchPrinter: async (context, params) => {
    let path = 'printers/' + params.id;

    let response = {};
    try {
      response = await Vue.axios.get(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('printer', data.result);
    }
  },
  editStandardPrinter: async (context, params) => {
    const path = 'printers/defaultprinter';
    let response = {};
    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    if (response && response.data) {
      const data = response.data;
      context.commit('defaultPrinter', data.result);
    }
  },
  testPrint: async (context, params) => {
    const path = `printers/${params.id}/testprint`;
    let response = {};
    try {
      response = await Vue.axios.post(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      console.error('data', data);
      return true;
    }
  },
  restartPrinter: async (context, params) => {
    const path = `printers/${params.id}/restart`;
    let response = {};
    try {
      response = await Vue.axios.get(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      return true;
    }
  },
  patchPrinter: async (context, params) => {
    const path = `printers/${params.id}`;
    let response = {};
    try {
      response = await Vue.axios.patch(path, params.data);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      const data = response.data;
      context.commit('printer', data.result);
    }
  },
  printNeutralScoreCards: async (context, params) => {
    const path = 'neutral/print';
    let response = {};

    try {
      response = await Vue.axios.post(path, params);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }

    if (response && response.data) {
      if (response.data.success === true) {
        return true;
      }
    }

    return false;
  },
  refreshPrinters: async (context, params) => {
    const path = 'printers/refresh';
    let response = {};

    context.dispatch('ui/toggleActiveLoaders', 'printers-refresh', {
      root: true,
    });
    try {
      response = await Vue.axios.post(path);
    } catch (error) {
      EventBus.$emit('toast', {
        type: 'error',
        msg: error.response.data.message,
        request: path,
      });
    }
    context.dispatch('ui/toggleActiveLoaders', 'printers-refresh', {
      root: true,
    });

    if (response && response.data) {
      if (response.data.success === true) {
        return true;
      }
    }

    return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
