<template>
  <div :class="[settings.fileFormat === '0' ? 'opaque-bg' : '']">
    <h1
      v-if="settings.showHeaders"
      class="pieChart-title">
      {{ $t('roundsDistributionCourse') }}
    </h1>

    <div class="section">
      <div class="divide-charts">
        <pie-filterable
          v-if="!isLoading"
          class="pieChart"
          ref="pie3"
          :courses="diagram.data[0].courses"
          :colors="COLORS" />
        <pie-chart-loader v-if="isLoading" />
        <div>
          <h2 class="chart-year">{{ diagram.data[0].year }}</h2>

          <reports-legend-pie
            :labels="diagram.data[0].courses.map(c => c.name)"
            :numbers="diagram.data[0].courses.map(c => c.numberOfRounds)"
            :precentage="diagram.data[0].courses.map(c => c.percentage)"
            :type="$t('rounds').toLowerCase()" />
        </div>
      </div>

      <div class="divide-charts">
        <pie-filterable
          v-if="!isLoading"
          class="pieChart"
          ref="pie3"
          :courses="diagram.data[1].courses"
          :colors="COLORS" />
        <pie-chart-loader v-if="isLoading" />
        <div>
          <h2 class="chart-year">{{ diagram.data[1].year }}</h2>

          <reports-legend-pie
            :labels="diagram.data[1].courses.map(c => c.name)"
            :numbers="diagram.data[1].courses.map(c => c.numberOfRounds)"
            :precentage="diagram.data[1].courses.map(c => c.percentage)"
            :type="$t('rounds').toLowerCase()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieFilterable from '@/components/charts/pie-chart-game-course';
import ReportsLegendPie from '@/components/reports-templates/legend/reports-legend-game-course';
import LocalizationMixin from '../../mixins/localization-mixin.vue';
import { COLORS } from '../charts/pie-chart-colors';
import PieChartLoader from '@/components/reports-templates/pie-chart-loader';

export default {
  name: 'GameCourseDiagramData',
  mixins: [LocalizationMixin],
  components: {
    PieFilterable,
    ReportsLegendPie,
    PieChartLoader,
  },
  props: { diagram: {} },
  data() {
    return {
      COLORS,
    };
  },
  computed: {
    settings() {
      return this.$store.getters['businessboardReportingTool/settings'];
    },
    isLoading() {
      return this.$store.getters['businessboardReportingTool/isLoading'];
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/components/reports-templates/scss/shared.scss';

.pieChart {
  width: 20rem;
  height: 20rem;
}

.section {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 16px;
  margin-left: 2rem;
  display: flex;
  gap: 4rem;
}

.pieChart-title {
  margin-left: 4rem;
}

.chart-year {
  font-size: medium;
  font-weight: 600;
}

.divide-charts {
  display: flex;
  width: 50%;
}
</style>
