<template>
  <div class="drill-down-grid">
    <div
      class="row"
      :style="{
        gridTemplateColumns: `370px ${'200px '.repeat(
          drillDownList.header.columns.length
        )}`,
        display: 'grid',
        gridAutoFlow: 'column',
      }">
      <div class="drill-down-title">
        <span
          style="cursor: pointer; margin-right: 0.5rem"
          @click="copyTableData"
          :title="$t('copyData')">
          <use-svg svg="bb-copy-icon2" />
        </span>
        <span>{{ drillDownList.title ?? 'Title' }}</span>
      </div>
      <div
        v-for="(header, index) in drillDownList.header.columns"
        :key="index"
        class="drill-down-header">
        {{ header }}
      </div>
    </div>

    <div
      v-for="(row, index) in drillDownList.rows"
      :key="index">
      <drill-down-row
        :row="row"
        :indentLevel="0">
      </drill-down-row>
    </div>
  </div>
</template>

<script>
import DrillDownRow from './drilldown-row.vue';
import UseSvg from '@/components/use-svg';

export default {
  name: 'DrillDownList',
  props: {
    drillDownList: {
      type: Object,
      required: true,
    },
  },
  components: {
    DrillDownRow,
    UseSvg,
  },
  methods: {
    copyTableData() {
      let text =
        this.drillDownList.title.replace(/\r?\n|\r/g, ' ') +
        '\t' +
        this.drillDownList.header.columns.join('\t') +
        '\n';
      for (let row of this.drillDownList.rows) {
        text +=
          row.label.replace(/\r?\n|\r/g, ' ') +
          '\t' +
          row.values.join('\t') +
          '\n';
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.copyDataText = 'copiedToClipboard';
        },
        function (err) {
          this.copyDataText = '';
          console.error('Async: Could not copy text: ', err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: grid;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  background-color: #27645c;
  color: white;
  font-family: 'SoraLight', sans-serif;
  font-size: 18px;
  border-radius: 8px;
  margin-left: -15px; /* to align with drill-down-row */
  margin-right: 5px;
}

.drill-down-header {
  font-weight: bold;
  text-align: left;
  max-width: 170px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 15px;
  font-weight: 600;
  font-size: 15px;
  white-space: pre-line;
}

.drill-down-title {
  font-weight: bold;
  padding: 15px;
  margin-left: 10px;
  font-size: clamp(16px, 18px, 20px);
  position: relative;
  display: flex;
  align-items: center;
}

.drill-down-title::after {
  content: '';
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: white;
}

.drill-down-grid {
  display: grid;
}
</style>
