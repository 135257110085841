<template>
  <div
    :class="`circle circle_big ${
      this.color === 'purple' ? 'circle_purple' : ''
    }`">
    <div class="data-content">
      <h1>
        {{ amount.toLocaleString('sv-SE') }}
      </h1>
      <p class="sub">{{ $t(this.labelTranslationKey) }}</p>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

import LocalizationMixin from '@/mixins/localization-mixin.vue';

export default {
  name: 'GolfOfficeFrontEndDotChart',

  mixins: [LocalizationMixin],

  props: {
    amount: VueTypes.number.isRequired,
    labelTranslationKey: VueTypes.string.isRequired,
    color: VueTypes.string.def('purple'),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.circle {
  width: 85%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  margin: 0.5rem;
  text-align: center;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  &_big {
    font-size: 24px;
  }

  &_purple {
    background-color: color(bb-sec2);
    color: white;
  }

  .data-content {
    margin-top: -1rem;
  }

  .sub {
    font-size: 12px;
    font-weight: 400;
    margin-top: measure('spacing-xl') * -1;
  }
}
</style>
